import React from "react"
import {DigitalDocumentFieldSetUp} from "types/tasks"
import {Signature, DateField, Initials, Text, Checkbox} from "./parts"

type Props = {
    field: any
    onChangeRemarkField: (fieldId: string, comment: string) => void
}

export function ObservationField(props: Props) {
    const {field, onChangeRemarkField} = props

    switch (field.type) {
        case DigitalDocumentFieldSetUp.SIGNATURE:
            return <Signature field={field} onChangeRemarkField={onChangeRemarkField} />
        case DigitalDocumentFieldSetUp.DATE_FIELD:
            return <DateField field={field} onChangeRemarkField={onChangeRemarkField} />
        case DigitalDocumentFieldSetUp.INITIAL:
            return <Initials field={field} onChangeRemarkField={onChangeRemarkField} />
        case DigitalDocumentFieldSetUp.TEXT:
            return <Text field={field} onChangeRemarkField={onChangeRemarkField} />
        case DigitalDocumentFieldSetUp.CHECKBOX:
            return <Checkbox field={field} onChangeRemarkField={onChangeRemarkField} />
        default:
            return null
    }
}
