import {get} from "lodash"
import BaseService from "./Base"
import {Auth} from "types/auth"

export default class StudentServicesService extends BaseService {
    public async getAttendance(params = {}): Promise<any> {
        const response = await this.post("/v1/academic-stats/student-attendances", params)
        return get(response, "data.data", {})
    }

    public async getActiveStudents(params = {}): Promise<any> {
        const response = await this.post("/v1/academic-stats/active-students", params)
        return get(response, "data.data", {})
    }

    public async getEnroll(params = {}): Promise<any> {
        const response = await this.post("/v1/academic-stats/student-enrolls", params)
        return get(response, "data.data", {})
    }

    public async getLeaveOfAbsence(params = {}): Promise<any> {
        const response = await this.post("/v1/academic-stats/student-loa", params)
        return get(response, "data.data", {})
    }

    public async getDrop(params = {}): Promise<any> {
        const response = await this.post("/v1/academic-stats/student-drops", params)
        return get(response, "data.data", {})
    }

    public async getSAP(params = {}): Promise<any> {
        const response = await this.post("/v1/academic-stats/sap-statuses", params)
        return get(response, "data.data", {})
    }

    public async getActionsNeededSEV(params = {}): Promise<any> {
        const response = await this.post("/v1/academic-stats/actions-needed-sev", params)
        return get(response, "data.data", {})
    }

    public async getActionsNeededSAP(params = {}): Promise<any> {
        const response = await this.post("/v1/academic-stats/actions-needed-sap", params)
        return get(response, "data.data", {})
    }

    public async getStudents(params = {}): Promise<any> {
        const response = await this.post("/v1/student-services/student/list", params)
        return get(response, "data", {})
    }

    public async getStudentsStats(params = {}): Promise<any> {
        const response = await this.post("/v1/student-services/student/stats/list", params)
        return get(response, "data.data", {})
    }

    public async getTermCourseStats(params = {}): Promise<any> {
        const response = await this.post("/v1/academic-plans/student-stats", params)
        return get(response, "data.data", {})
    }

    public async enrollStudent(params: {
        studentProfileId: number
        studentStatusIdToAssign: number
        notes?: string
    }): Promise<any> {
        const response = await this.post("/v1/students-state-transitions/enroll-student", params)
        return get(response, "data.data", {})
    }

    public async markStudentAsAlumniOrStudent(params: {
        studentProfileId: number
        studentStatusIdToAssign: number
        degreeConferredDate: string | null
        degreeConferredStatus: Auth.DegreeConferredStatus
    }) {
        const response = await this.post(
            "/v1/students-state-transitions/update-student-degree-conferred-status",
            params
        )
        return get(response, "data.data", {})
    }

    public async markStudentAsAttending(params: {
        studentProfileId: number
        studentStatusIdToAssign: number
        date?: string
        notes?: string
    }): Promise<any> {
        const response = await this.post("/v1/students-state-transitions/mark-student-as-attending", params)
        return get(response, "data.data", {})
    }

    public async markStudentAsNotAttending(params: {
        studentProfileId: number
        studentStatusIdToAssign: number
        date?: string
        notes?: string
    }): Promise<any> {
        const response = await this.post("/v1/students-state-transitions/mark-student-as-not-attending", params)
        return get(response, "data.data", {})
    }

    public async cancelStudentEnrollment(params: {
        studentProfileId: number
        studentStatusIdToAssign: number
        notes?: string
    }): Promise<any> {
        const response = await this.post("/v1/students-state-transitions/cancel-student-enrollment", params)
        return get(response, "data.data", {})
    }
}
