/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useRef, useState} from "react"
import {useTranslation} from "react-i18next"
import {Checkbox, Popover} from "antd"
import {Icon} from "components/Icon"
import {BasePopup} from "components/popup"
import {BaseInput} from "components/inputs"
import {BaseButton} from "components/buttons"
import styles from "./DailyAttendancePopup.module.css"
import {attendanceService, profileService} from "services"
import {Course} from "types/courses"
import {clone, cloneDeep, get} from "lodash"
import {convertTZ, getUserPhotoUrl, handleError, toastWarning} from "helpers"
import moment from "moment-timezone"
import {BaseLoading} from "components"
import {useModel} from "hooks"
import classNames from "classnames"
import {AttendanceEvent, AttendanceEventType} from "types/attendance"
import cx from "classnames"
import {BaseTimePicker} from "components/DateTimePicker"
import {v4 as uuidv4} from "uuid"
import {toDateOnly} from "sections/academics/instructional/common/utils"

const optionEvents: Array<{name: string; value: AttendanceEventType}> = [
    {
        name: "Checkin",
        value: AttendanceEventType.Checkin
    },
    {
        name: "Checkout",
        value: AttendanceEventType.Checkout
    },
    {
        name: "In",
        value: AttendanceEventType.BreakEnd
    },
    {
        name: "Out",
        value: AttendanceEventType.BreakStart
    }
]

type Props = {
    isShow: boolean
    studentProfileIds?: number[]
    selectedAttendance?: any
    getListAttendances?: () => void
    onClose: () => void
}

export function DailyAttendancePopup(props: Props) {
    const {isShow, onClose, studentProfileIds = [], getListAttendances, selectedAttendance} = props
    const timezone = get(selectedAttendance, ["student", "timezone"], moment.tz.guess())
    const deleteEventIds = useRef<number[]>([])
    const {t} = useTranslation(["studentServices"])
    const [listEvent, setListEvent] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isAttended, setIsAttended] = useState(null)
    const [showPopover, setShowPopover] = useState({})
    const [isApproved, setIsApproved] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isExcused, setIsExcused] = useState(false)
    const [studentInfo, setStudentInfo] = useState<any>()
    const [dateData, setDateData] = useState({
        checkInTime: "",
        checkOutTime: "",
        checkInDate: ""
    })
    const model = useModel()
    const timeFormat = model.getUserTimeFormat()
    const dateFormat = model.getUserDateFormat()

    const avatarUrl = studentInfo?.photo ? getUserPhotoUrl(studentInfo?.photo, 512) : "/image/DefaultAvatar.png"

    const getStudentInfo = useCallback(async () => {
        const {data: student} = await profileService.getOne({
            id: studentProfileIds[0],
            type: "student"
        })
        setStudentInfo(student)
    }, [studentProfileIds])

    const getAttendanceDetail = useCallback(async () => {
        setIsLoading(true)
        try {
            const params = {
                attendanceIds: [selectedAttendance.id]
            }
            const {data} = await attendanceService.getDailyAttendanceList({
                range: {
                    page: 1,
                    pageSize: 1
                },
                ...params
            })
            if (data?.length) {
                const attendance = get(data, [0])
                const events = (attendance.events ?? []).sort(function (left, right) {
                    return moment.utc(left.createdAt).diff(moment.utc(right.createdAt))
                })
                const checkinnEvent = events.find((item) => item.eventType === AttendanceEventType.Checkin)
                const checkoutEvent = events.find((item) => item.eventType === AttendanceEventType.Checkout)
                setDateData({
                    ...dateData,
                    checkInTime: checkinnEvent
                        ? moment(convertTZ(new Date(checkinnEvent.createdAt), timezone)).format(timeFormat)
                        : "",
                    checkOutTime: checkoutEvent
                        ? moment(convertTZ(new Date(checkoutEvent.createdAt), timezone)).format(timeFormat)
                        : "",
                    checkInDate: checkinnEvent
                        ? moment(convertTZ(new Date(checkinnEvent.createdAt), timezone)).format(dateFormat)
                        : ""
                })
                setListEvent(events)
            }
        } catch (err) {
            handleError(err)
        } finally {
            setIsLoading(false)
        }
    }, [studentProfileIds, selectedAttendance, timezone])

    useEffect(() => {
        studentProfileIds.length && getStudentInfo()
        console.log(selectedAttendance)
        if (isShow && selectedAttendance && studentProfileIds?.length && selectedAttendance?.attendanceId) {
            getAttendanceDetail()
            const isVerified = selectedAttendance.isVerified === "yes"
            setIsApproved(isVerified)
            setIsAttended(true)
            setIsExcused(isExcused)
        }
    }, [isShow, studentProfileIds, selectedAttendance])

    useEffect(() => {
        if (!isShow) {
            setStudentInfo(undefined)
            setListEvent([])
            deleteEventIds.current = []
        }
    }, [isShow])

    const renderStudentInfo = () => {
        return (
            <div className={styles.studentWrapper}>
                <div className={styles.imgWrapper}>
                    <img src={avatarUrl} className={styles.avatar} alt="" />
                </div>
                <div>
                    <p className={styles.studentName}>{studentInfo?.fullName}</p>
                    <div className={styles.flexContainer}>
                        <div className={styles.columnContainer}>
                            <div className={styles.labelContainer}>
                                <span className={styles.idLabel}>{t("popup.userId")}: </span>
                                <span className={styles.idValue}>{studentInfo?.userId}</span>
                            </div>
                            <div className={styles.labelContainer}>
                                <span className={styles.idLabel}>{t("popup.enrollmentId")}: </span>
                                <span className={styles.idValue}>{studentInfo?.customProfileId}</span>
                            </div>
                        </div>

                        <div className={styles.labelContainer}></div>
                    </div>
                </div>
            </div>
        )
    }

    const renderTitle = (eventType: string) => {
        if (eventType === "check-out") {
            return t("popup.checkout")
        } else if (eventType === "check-in") {
            return t("popup.checkin")
        } else if (eventType === "break-start") {
            return "Out"
        } else if (eventType === "break-end") {
            return "In"
        } else if (eventType === "lessonStart") {
            return "Lesson Start"
        } else if (eventType === "lessonEnd") {
            return "Lesson End"
        } else if (eventType === "attendance-check") {
            return "In"
        } else {
            return "Select"
        }
    }

    const renderIconAttendance = (eventType: string, status: string) => {
        if (eventType === "check-out") {
            return (
                <Icon
                    icon="CHECKOUT_FILL"
                    color={status === "success" ? "#1E90FF" : "#E6E6E6"}
                    className={styles.checkOutIcon}
                />
            )
        } else if (eventType === "check-in") {
            return (
                <Icon
                    icon="CHECKIN_FILL"
                    color={status === "success" ? "#1E90FF" : "#E6E6E6"}
                    className={styles.rightIcon}
                />
            )
        } else if (eventType === "break-start") {
            return <Icon icon="ATTENDANCE_OUT" color={"#DF1642"} className={styles.rightIcon} />
        } else if (eventType === "break-end") {
            return <Icon icon="ATTENDANCE_IN" color={"#18A957"} className={styles.rightIcon} />
        } else if (eventType === "attendance-check") {
            return (
                <Icon
                    icon="ATTENDANCE_IN"
                    color={status === "success" ? "#18A957" : "#E6E6E6"}
                    className={styles.rightIcon}
                />
            )
        } else {
            return <div className={styles.rightIcon} />
        }
    }

    const onPressDeleteEvent = (event: AttendanceEvent) => {
        typeof event.id === "number" && deleteEventIds.current.push(event.id)
        const events = listEvent.filter((item) => item.id !== event.id)
        setListEvent([...events])
    }

    const onAddEvent = (e) => {
        let newListEvent = clone(listEvent)
        newListEvent.push({
            status: "success",
            id: uuidv4()
        })
        setListEvent(newListEvent)
    }

    const renderAddEvent = () => {
        return (
            <div className={styles.buttonAddEvent}>
                <div onClick={onAddEvent} className={styles.addEventContainer}>
                    <span>Add event</span>
                </div>
            </div>
        )
    }

    const renderPopoverSelectEvent = (event: AttendanceEvent) => {
        const checkIn = listEvent.find(
            (item) => item.eventType === AttendanceEventType.Checkin && item.status === "success"
        )
        const checkOut = listEvent.find(
            (item) => item.eventType === AttendanceEventType.Checkout && item.status === "success"
        )
        let options = cloneDeep(optionEvents)
        if (event?.eventType) {
            options = options.filter((item) => item.value !== event.eventType)
        }
        if (checkIn) {
            options = options.filter((item) => item.value !== AttendanceEventType.Checkin)
        }
        if (checkOut) {
            options = options.filter((item) => item.value !== AttendanceEventType.Checkout)
        }
        return (
            <div className={styles.selectContainer}>
                {options.map((option) => (
                    <div
                        onClick={() => {
                            event.eventType = option.value
                            setListEvent([...listEvent])
                            showPopover[event.id] = false
                            setShowPopover({...showPopover})
                        }}
                        className={cx(styles.popupContainer, styles.hoverBackgroundGrey)}>
                        {renderIconAttendance(option.value, "success")}
                        <span style={{width: 120}} className={styles.infoLabel}>
                            {renderTitle(option.value)}
                        </span>
                    </div>
                ))}
            </div>
        )
    }

    const renderCheckInItem = (data: any) => {
        const event = listEvent.find((item) => item.id === data.id)
        const isRenderDelete = event?.status === "success"
        return (
            <div key={event?.id ?? uuidv4()}>
                <div className={styles.infoWrapper}>
                    {event?.status === "success" ? (
                        <>
                            <BaseTimePicker
                                style={{width: 130, marginRight: 24}}
                                value={data?.createdAt ? moment(convertTZ(new Date(data?.createdAt), timezone)) : ""}
                                format={timeFormat}
                                allowClear={false}
                                onChange={(date) => {
                                    event.createdAt = moment(date).tz(timezone, true)
                                    setListEvent([...listEvent])
                                }}
                                placeholder="Time"
                            />
                        </>
                    ) : (
                        <div style={{backgroundColor: "transparent"}} className={styles.hourLabel} />
                    )}
                    {event?.status === "success" ? (
                        <Popover
                            placement="right"
                            color="white"
                            visible={showPopover[event.id]}
                            arrowContent={null}
                            onVisibleChange={(visible) => {
                                showPopover[event.id] = visible
                                setShowPopover({...showPopover})
                            }}
                            content={() => renderPopoverSelectEvent(event)}
                            trigger="click">
                            <div className={styles.popupContainer}>
                                {renderIconAttendance(data?.eventType, data.status)}
                                <span
                                    style={{width: 80, color: data?.eventType ? "black" : "rgba(0,0,0,0.3)"}}
                                    className={styles.infoLabel}>
                                    {renderTitle(data?.eventType)}
                                </span>
                            </div>
                        </Popover>
                    ) : (
                        <span style={{width: 80}} className={styles.infoLabel}>
                            {renderTitle(data?.eventType)}
                        </span>
                    )}
                    {event?.status === "success" ? (
                        <BaseInput
                            value={event?.message ?? ""}
                            onChange={(text) => {
                                event.message = text
                                setListEvent([...listEvent])
                            }}
                            placeholder={t("popup.notes")}
                            className={styles.inputWidth}
                        />
                    ) : (
                        <div className={styles.inputWidth} />
                    )}
                    {isRenderDelete ? (
                        <div onClick={() => onPressDeleteEvent(event)}>
                            <Icon icon="DELETE" className={styles.deleteIcon} />
                        </div>
                    ) : null}
                </div>
            </div>
        )
    }

    const onSave = useCallback(async () => {
        if ((isAttended === null || isAttended === undefined) && isApproved) {
            return toastWarning("You need to select Yes or No to verify this attendance")
        }
        setIsSubmitting(true)
        try {
            const params: any = {
                attendanceId: selectedAttendance.attendanceId,
                status:
                    isAttended === true
                        ? "approved"
                        : isAttended === false
                        ? "rejected"
                        : selectedAttendance.atendanceStatus,
                isVerified: !!isApproved
            }
            if (params.status === "approved") {
                params.isExcusedAbsence = false
            } else {
                params.isExcusedAbsence = !!isExcused
            }
            const listEventAdd = listEvent
                .filter((item) => typeof item.id !== "number")
                .map((item) => {
                    const createdAt = moment(item.createdAt)
                    const startDate = moment(convertTZ(new Date(selectedAttendance?.date), timezone))
                    createdAt.set({
                        month: startDate.month(),
                        date: startDate.date(),
                        year: startDate.year()
                    })
                    return {
                        message: item.message ?? "",
                        eventType: item.eventType,
                        status: "success",
                        attendanceId: selectedAttendance.attendanceId,
                        createdAt: createdAt.toISOString()
                    }
                })
            const events = listEvent
                .filter((item) => typeof item.id === "number")
                .map((item) => {
                    const createdAt = moment(item.createdAt)
                    const startDate = moment(convertTZ(new Date(selectedAttendance?.date), timezone))
                    createdAt.set({
                        month: startDate.month(),
                        date: startDate.date(),
                        year: startDate.year()
                    })
                    return {
                        id: item.id,
                        message: item.message ?? "",
                        eventType: item.eventType,
                        createdAt: createdAt.toISOString()
                    }
                })
            if (events?.length) {
                params.events = events
            }
            if (deleteEventIds.current?.length) {
                params.deleteEventIds = deleteEventIds.current
            }
            if (listEventAdd.length) {
                params.addEvents = listEventAdd
            }
            await attendanceService.updateDailyAttendance(params)
            getListAttendances && getListAttendances()
            setStudentInfo(undefined)
            deleteEventIds.current = []
            setListEvent([])
            onClose()
        } catch (err) {
            handleError(err)
        } finally {
            setIsSubmitting(false)
        }
    }, [getListAttendances, isApproved, selectedAttendance, isAttended, isExcused, listEvent, timezone])

    const checkInEvent = listEvent.find(
        (event) => event.eventType === AttendanceEventType.Checkin && event.status === "success"
    )
    const checkOutEvent = listEvent.find(
        (event) => event.eventType === AttendanceEventType.Checkout && event.status === "success"
    )
    const otherEvents = listEvent.filter(
        (event) => event.eventType !== AttendanceEventType.Checkout && event.eventType !== AttendanceEventType.Checkin
    )

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="PERSON_FILL" width="70vw">
            <div className={styles.popupWrapper}>
                <p className={styles.title}>{t("attendance.title")}</p>
                {selectedAttendance?.date ? (
                    <div>
                        <div className={styles.timeWrapper}>
                            <span>{moment(toDateOnly(selectedAttendance?.date)).format(dateFormat)}</span>
                        </div>
                    </div>
                ) : null}
                <hr className={styles.divisor} />
                {renderStudentInfo()}
                {selectedAttendance?.attendanceId ? (
                    <>
                        {renderCheckInItem({
                            status: "failure",
                            eventType: AttendanceEventType.Checkin,
                            ...checkInEvent
                        })}
                        {otherEvents.map(renderCheckInItem)}
                        {renderCheckInItem({
                            status: "failure",
                            eventType: AttendanceEventType.Checkout,
                            ...checkOutEvent
                        })}
                        {renderAddEvent()}
                        <div className={styles.approveAttendWrap} onClick={() => setIsApproved(!isApproved)}>
                            <span className={styles.checkLabel}>{t("popup.approveAttendance")}</span>
                            <div
                                className={classNames(styles.unchecked, {
                                    [styles.checked]: isApproved
                                })}>
                                <Icon icon="TICK" className={styles.attendanceTickIcon} color="#e5e5e5" />
                            </div>
                        </div>
                        <div className={styles.saveContainer}>
                            <BaseButton loading={isSubmitting} onClick={onSave} title={t("popup.save")} />
                        </div>
                    </>
                ) : (
                    <div className={styles.emptyView}>
                        <span className={styles.textEmpty}>Attendance is empty</span>
                    </div>
                )}
                <BaseLoading isShow={isLoading} />
            </div>
        </BasePopup>
    )
}
