import BaseService from "./Base"
import {get} from "lodash"

class UserEducationalBackgroundService extends BaseService {
    getAll = async (params = {}) => {
        const response = await this.post("/v1/user-educational-background/list", params)
        return get(response, "data", [])
    }

    delete = async (params = {}) => {
        const response = await this.post("/v1/user-educational-background/delete", params)
        return get(response, "data", {})
    }

    create = async (params = {}) => {
        const response = await this.post("/v1/user-educational-background/create", params)
        return get(response, "data.data", {})
    }

    update = async (id, data, params = {}) => {
        data.userEducationalBackgroundId = id
        const response = await this.post("/v1/user-educational-background/edit", data, params)
        return get(response, "data", {})
    }
}
export default UserEducationalBackgroundService
