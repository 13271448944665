import {TotalHoursData} from "types/students"
import styles from "./WeeklyPerformance.module.css"
import {useMemo} from "react"
import {formatDecimal} from "helpers"

type PerformanceItem = {
    title: string
    emoji: string
    description: string
}

const PERFORMANCE_DICTIONARY: {[key: string]: PerformanceItem} = {
    trying: {
        title: "Room for Improvement",
        emoji: "😲",
        description: "Plan to make-up work!"
    },
    good: {
        title: "Keep Striving",
        emoji: "😎",
        description: "Consistency is key!"
    },
    perfect: {
        title: "You did it!",
        emoji: "🥳",
        description: "You're Crushing It!"
    }
}

type Props = {
    totalHoursData: TotalHoursData
}

export function WeeklyPerformance(props: Props) {
    const {totalHoursData} = props

    const data = useMemo(() => {
        if (!totalHoursData) return
        const totalMissedHours = Math.max(totalHoursData.hoursScheduled - totalHoursData.totalHoursCompleted, 0)
        const missedPercentage =
            totalHoursData.hoursScheduled > 0 ? (totalMissedHours / totalHoursData.hoursScheduled) * 100 : 0
        let status: string = "trying"
        if (missedPercentage <= 20) {
            status = "good"
        }
        if (missedPercentage <= 2) {
            status = "perfect"
        }

        return {
            performance: PERFORMANCE_DICTIONARY[status],
            description:
                totalMissedHours > 0
                    ? `You've missed ${formatDecimal(totalMissedHours, 0)} hours`
                    : "You haven't missed lessons"
        }
    }, [totalHoursData])

    if (!totalHoursData) return null

    return (
        <div className={styles.container}>
            <span className={styles.title}>{data.performance.title}</span>
            <span className={styles.emoji}>{data.performance.emoji}</span>
            <span className={styles.description}>{data.performance.description}</span>
            <span className={styles.moreDesc}>{data.description}</span>
        </div>
    )
}
