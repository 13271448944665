import BaseService from "./Base"
import {get} from "lodash"

class WithdrawalCancellationService extends BaseService {
    getAllActive = async (params = {}) => {
        const response = await this.post("/v1/cancel-and-drop-reasons/list/active", params)
        return get(response, "data", [])
    }

    getAll = async (params = {}) => {
        const response = await this.post("/v1/cancel-and-drop-reasons/settings/list", params)
        return get(response, "data", [])
    }

    delete = async (params = {}) => {
        const response = await this.post("/v1/cancel-and-drop-reasons/settings/delete", params)
        return get(response, "data", {})
    }

    create = async (params = {}) => {
        const response = await this.post("/v1/cancel-and-drop-reasons/settings/create", params)
        return get(response, "data.data", {})
    }

    update = async (id, data, params = {}) => {
        data.reasonId = id
        const response = await this.post("/v1/cancel-and-drop-reasons/settings/edit", data, params)
        return get(response, "data", {})
    }
}
export default WithdrawalCancellationService
