import React, {useContext} from "react"
import {BaseButton} from "components"
import {AddressElement, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js"
import {StudentPaymentContext} from "context/StudentPaymentContext"

type CheckoutFormProps = {
    studentPayment: any
}

export const CheckoutForm = ({studentPayment}: CheckoutFormProps) => {
    const stripe = useStripe()
    const elements = useElements()

    const {setPaymentStatus, setPaymentError} = useContext(StudentPaymentContext)

    const handleSubmit = React.useCallback(
        async (event) => {
            event.preventDefault()

            if (!stripe || !elements) {
                return
            }

            try {
                setPaymentStatus("processing")
                setPaymentError(undefined)

                // const address = elements.getElement("address")
                // const addressValue = await address.getValue()
                // const payerFullName = addressValue.value.name // TODO

                const {paymentId} = studentPayment
                const {error} = await stripe.confirmPayment({
                    elements,
                    confirmParams: {
                        return_url: `${window.location.origin}?paymentId=${paymentId}`
                    }
                })
                if (error) throw error
                setPaymentStatus("confirmed")
            } catch (error: any) {
                // handleError(error)
                setPaymentStatus("failed")
                if (error.type === "card_error" || error.type === "validation_error") {
                    setPaymentError(error.message || "")
                } else {
                    setPaymentError(error.message || "An unexpected error occurred.")
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [stripe, elements, studentPayment]
    )

    return (
        <form onSubmit={handleSubmit}>
            <AddressElement options={{mode: "billing"}} />
            <PaymentElement />
            <BaseButton
                size="small"
                title="PAY"
                disabled={!stripe || !elements}
                htmlType="submit"
                className="w-full mt-24"
            />
            {/*
            <div className={cx(styles.textGraySmall, "mt-8")}>
                By confirming the payment you allow {model.clientSetting?.name} to charge your account got his payment
                and future payments in accordance with their terms
            </div>
            */}
        </form>
    )
}
