import {Node, NodeProps} from "reactflow"
import {IconType} from "components"
import {NodeType} from "components/FlowChart/FlowChart.constants"

export enum WorkflowParticipantType {
    Role = "role",
    Department = "department",
    Users = "users"
}

export interface ProcessWorkflow {
    flow: string
}

export enum ProcessWorkflowNodeType {
    ActionCreateActivity = "action_create_activity",
    ActionSendEmail = "action_send_email",
    ActionSendMessage = "action_send_message",
    ActionCreateCalendarEvent = "action_create_calendar_event"
}

export const getWorkflowNodeTypeIcon = (type: ProcessWorkflowNodeType): IconType => {
    switch (type) {
        case ProcessWorkflowNodeType.ActionCreateActivity:
            return "CHECKMARK_CIRCLE"
        case ProcessWorkflowNodeType.ActionSendEmail:
            return "EMAIL_FILL"
        case ProcessWorkflowNodeType.ActionSendMessage:
            return "MESSAGE_CIRCLE"
        case ProcessWorkflowNodeType.ActionCreateCalendarEvent:
            return "CALENDAR_INPUT"
        default:
            return "TYPE_LINE"
    }
}

export type ProcessWorkflowStepNodeData = {name: string}

export type ProcessWorkflowActionNodeData = {
    type: ProcessWorkflowNodeType
    data?: Record<string, any>
}

/**
 * Assert class for given type.
 */
export function assertClassFromType<T extends any>(node: NodeProps, type: NodeType): node is NodeProps<T> {
    return node.type === type
}
