import React from "react"
import {useTranslation} from "react-i18next"
import classNames from "classnames"
import {Menu, Dropdown, Button} from "antd"
import {Icon} from "components/Icon"
import {UserType, TaskSubtype, ParticipantType, TaskType} from "types/tasks"
import styles from "./TaskSignerHeader.module.css"
import {isEmpty} from "lodash"

export function TaskSignerHeader(props) {
    const {taskId, subtype, signer, index, onClickEditSigner, onClickDeleteSigner, type, isEditingParticipants} = props
    const {t} = useTranslation(["tasks"])

    const menu = (
        <Menu className={styles.menuActionWrap}>
            <Menu.Item key="edit" className={styles.menuActionItem} onClick={() => onClickEditSigner(signer)}>
                <Icon className={styles.menuActionIcon} icon="EDIT_LINE" />
                <span className={styles.menuActionTitle}>Edit</span>
            </Menu.Item>
            {(type !== TaskType.FROM_LIBRARY ||
                ![
                    ParticipantType.PrimarySigner,
                    ParticipantType.NonPrimarySigner,
                    ParticipantType.UserDataOnly
                ].includes(signer.participantType)) && (
                <Menu.Item key="delete" className={styles.menuActionItem} onClick={() => onClickDeleteSigner(signer)}>
                    <Icon className={styles.menuActionIcon} icon="DELETE" />
                    <span className={styles.menuActionTitle}>Delete</span>
                </Menu.Item>
            )}
        </Menu>
    )

    const renderTitleByUserType = () => {
        switch (signer.userType) {
            case UserType.STAFF:
                return "Staff"
            case UserType.STUDENT:
                return "Student"
            case UserType.OTHERS:
                return "Others"
            default:
                return ""
        }
    }

    const renderIconByUserType = () => {
        switch (signer.userType) {
            case UserType.STAFF:
                return <Icon icon="WORK" className={styles.headerIcon} color="#FFF" />
            case UserType.STUDENT:
                return <Icon icon="ACADEMIC" className={styles.headerIcon} color="#FFF" />
            case UserType.OTHERS:
                return <Icon icon="PERSON" className={styles.headerIcon} color="#FFF" />
            default:
                return ""
        }
    }

    const renderPrimaryInfo = () => {
        if (signer.participantType === ParticipantType.PrimarySigner) {
            return <p className={styles.header__primary}>{t("taskDetail.signers.primary")}</p>
        }
        if (signer.participantType === ParticipantType.NonPrimarySigner) {
            return (
                <p className={classNames(styles.header__primary, styles.header__nonPrimary)}>
                    {t("taskDetail.signers.nonPrimary")}
                </p>
            )
        }
        return (
            <p className={classNames(styles.header__primary, styles.header__nonPrimary)}>
                {t("taskDetail.signers.userDataOnly")}
            </p>
        )
    }

    return (
        <>
            <div
                className={classNames(styles.headerWrap, {
                    [styles.mb0]: !!taskId || subtype === TaskSubtype.LIBRARY
                })}>
                <div className={styles.headerWrapBody}>
                    <div className={styles.headerIconWrap}>{renderIconByUserType()}</div>
                    <div className={styles.headerInfoWrap}>
                        <p className={styles.headerInfo__userType}>
                            {renderTitleByUserType()}
                            {subtype === TaskSubtype.LIBRARY &&
                                signer.userType === UserType.STAFF &&
                                !isEmpty(signer.studentStaffContactRole) && (
                                    <span className={styles.roleInfo}>({signer.studentStaffContactRole.name})</span>
                                )}
                        </p>
                        <p className={styles.headerInfo__label}>{signer.label}</p>
                    </div>
                    <div className={styles.headerIndexWrap}>
                        <p className={styles.header__index}>{index + 1}</p>
                    </div>
                    {renderPrimaryInfo()}
                </div>
                {((!taskId && !signer.isSelecting) || (subtype === TaskSubtype.LIBRARY && isEditingParticipants)) && (
                    <div className={styles.menuWrap}>
                        <Dropdown overlay={menu} trigger={["click"]}>
                            <Button
                                className={styles.iconBtn}
                                icon={<Icon icon="MORE_VERTICAL" className={styles.moreIcon} color="#939393" />}
                            />
                        </Dropdown>
                    </div>
                )}
            </div>
            {!taskId && subtype !== TaskSubtype.LIBRARY && <div className={styles.divider}></div>}
        </>
    )
}
