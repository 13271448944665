import {get} from "lodash"
import BaseService from "./Base"

const basePath: string = `/v1/settings/loan-enrollment-level`

class LoanEnrollmentLevelsService extends BaseService {
    async list(params) {
        const response = await this.post(`${basePath}/list`, params)
        return get(response, "data", [])
    }

    async create(data = {}) {
        const response = await this.post(`${basePath}/create`, data)
        return get(response, "data.data", {})
    }

    async update(id, data, params = {}) {
        data.loanEnrollmentLevelId = id
        const response = await this.post(`${basePath}/edit`, data, params)
        return get(response, "data", {})
    }

    async delete(loanEnrollmentLevelIds: number[]) {
        const response = await this.post(`${basePath}/delete`, {loanEnrollmentLevelIds})
        return get(response, "data", {})
    }

    async getMaxId(body = {}) {
        const response = await this.post(`${basePath}/get-max-id`, body)
        return get(response, "data", [])
    }
}

export default LoanEnrollmentLevelsService
