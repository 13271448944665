import BaseService from "./Base"
import {get} from "lodash"

class UserServiceSessionsAttendedService extends BaseService {
    getAll = async (params = {}) => {
        const response = await this.post("/v1/user-service-sessions-attended/list", params)
        return get(response, "data", [])
    }

    delete = async (params = {}) => {
        const response = await this.post("/v1/user-service-sessions-attended/delete", params)
        return get(response, "data", {})
    }

    create = async (params = {}) => {
        const response = await this.post("/v1/user-service-sessions-attended/create", params)
        return get(response, "data.data", {})
    }

    update = async (id, data, params = {}) => {
        data.userServiceSessionsAttendedId = id
        const response = await this.post("/v1/user-service-sessions-attended/edit", data, params)
        return get(response, "data", {})
    }
}
export default UserServiceSessionsAttendedService
