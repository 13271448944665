/* eslint-disable react-hooks/exhaustive-deps */
import {Doughnut} from "react-chartjs-2"
import styles from "./ProgressChart.module.css"
import cx from "classnames"
import {TotalHoursData} from "types/students"
import {useEffect, useMemo, useState} from "react"
import {Auth} from "types/auth"
import {userService} from "services"
import {handleError, store} from "helpers"
import {BaseLoading} from "components"

type Props = {
    totalHoursData: TotalHoursData
}

export function ProgressChart({totalHoursData}: Props) {
    const locale = store.get("locale") || "en"
    const labelKey = locale === "en" ? "english" : "spanish"
    const [isLoading, setIsLoading] = useState(false)
    const [studentLabelInfo, setStudentLabelInfo] = useState<Auth.StudentLabelInfo>(null)

    useEffect(() => {
        getLabels()
    }, [])

    const getLabels = async () => {
        try {
            setIsLoading(true)
            const studentStats = await userService.getLabels()
            setStudentLabelInfo(studentStats)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const {
        totalHours,
        totalHoursCompleted,
        totalDistEdHours,
        totalHoursRemaining,
        totalHoursAttempted,
        hoursScheduled,
        totalTransferHours
    } = totalHoursData

    const progressPercentage: number = useMemo(() => {
        const total = totalTransferHours + totalHours
        const completed = totalHoursCompleted + totalTransferHours
        return total > 0 ? Number(((completed * 100) / total).toFixed(2)) : 0
    }, [totalTransferHours, totalHours, totalHoursCompleted, totalTransferHours])

    const data = () => {
        if (!studentLabelInfo) return {}

        let data: number[] = [],
            colors: string[] = []

        if (totalTransferHours > 0) {
            data.push(totalTransferHours)
            colors.push("#0f4880")
        }

        if (studentLabelInfo["academics.distant_edu_hours"].isActive) {
            data.push(totalDistEdHours)
            colors.push("#11763d")
        }

        if (studentLabelInfo["academics.completed_hours"].isActive) {
            const _totalHoursCompleted =
                totalHoursCompleted - (studentLabelInfo["academics.distant_edu_hours"].isActive ? totalDistEdHours : 0)
            data.push(_totalHoursCompleted)
            colors.push("#18a957")
        }

        if (studentLabelInfo["academics.remaining_hours"].isActive) {
            data.push(totalHoursRemaining)
            colors.push("#939393")
        }

        return {
            datasets: [
                {
                    label: "Total Hours",
                    data,
                    backgroundColor: colors,
                    weight: 1
                },
                {weight: 0.3},
                {
                    label: "Progress",
                    data: [progressPercentage, 100 - progressPercentage],
                    backgroundColor: ["#1e90ff", "transparent"],
                    weight: 0.8
                }
            ]
        }
    }

    const plugins = [
        {
            beforeDraw: function (chart) {
                var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx
                ctx.restore()

                ctx.font = "0.8em Poppins"
                ctx.textBaseline = "top"
                let text = "Total hours",
                    textX = Math.round((width - ctx.measureText(text).width) / 2),
                    textY = height / 3
                ctx.fillText(text, textX, textY)

                ctx.font = "bold 1.2em Poppins"
                text = `${totalHours}`
                textX = Math.round((width - ctx.measureText(text).width) / 2)
                textY = height / 3 + 16
                ctx.fillText(text, textX, textY)

                ctx.font = "0.8em Poppins"
                text = "Progress"
                textX = Math.round((width - ctx.measureText(text).width) / 2)
                textY = height / 2 + 10
                ctx.fillText(text, textX, textY)

                ctx.font = "bold 1.2em Poppins"
                text = `${progressPercentage}%`
                textX = Math.round((width - ctx.measureText(text).width) / 2)
                textY = height / 2 + 26
                ctx.fillText(text, textX, textY)

                ctx.save()
            },
            afterDraw: function (chart) {
                var ctx = chart.ctx
                ctx.save()

                const pointerRadius = 5,
                    circleRadius = 20,
                    angle = Math.PI / 180
                const innerRadius = chart.innerRadius
                const outerRadius = chart.outerRadius
                const xCenter = chart.width / 2
                const yCenter = chart.height / 2

                ctx.translate(xCenter, yCenter)
                let targetPointerRotation = 0

                // draw starting point
                ctx.rotate(angle * targetPointerRotation)
                ctx.beginPath()
                ctx.fillStyle = "black"
                ctx.roundRect(
                    0 - pointerRadius / 2,
                    -outerRadius - pointerRadius,
                    pointerRadius,
                    circleRadius,
                    pointerRadius
                )
                ctx.fill()
                ctx.closePath()

                // draw scheduled hours
                if (studentLabelInfo["academics.scheduled_hours"].isActive) {
                    targetPointerRotation = (hoursScheduled / totalHours) * 360
                    ctx.rotate(angle * targetPointerRotation)
                    ctx.beginPath()
                    ctx.fillStyle = "#e95c7b"
                    ctx.roundRect(
                        0 - circleRadius / 2,
                        -outerRadius - circleRadius / 4,
                        circleRadius,
                        circleRadius,
                        circleRadius
                    )
                    ctx.fill()
                    ctx.closePath()
                }

                // draw attempted hours
                if (studentLabelInfo["academics.attempted_hours"].isActive) {
                    targetPointerRotation = (totalHoursAttempted / totalHours) * 360
                    ctx.rotate(angle * targetPointerRotation)
                    ctx.beginPath()
                    ctx.fillStyle = "#ff8a38"
                    ctx.roundRect(
                        0 - circleRadius / 2,
                        -outerRadius - circleRadius / 4,
                        circleRadius,
                        circleRadius,
                        circleRadius
                    )
                    ctx.fill()
                    ctx.closePath()
                }

                ctx.restore()
            }
        }
    ]

    const options = {
        tooltips: {enabled: false},
        legend: {display: false},
        plugins: {datalabels: {display: false}},
        cutoutPercentage: 82,
        maintainAspectRatio: false,
        width: "220px",
        height: "220px",
        elements: {
            arc: {
                borderWidth: 0
            }
        },
        layout: {
            padding: 10
        }
    }

    if (isLoading || !studentLabelInfo) {
        return (
            <div className={styles.loadingWrapper}>
                <BaseLoading isShow />
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <div className={styles.labelContainer}>
                {studentLabelInfo["academics.total_hours"].isActive && (
                    <div className={styles.row}>
                        <span className={styles.label}>{studentLabelInfo["academics.total_hours"][labelKey]}:</span>
                        <span className={styles.value}>{totalHours}</span>
                    </div>
                )}
                <div className={styles.labelDetailContainer}>
                    {studentLabelInfo["academics.completed_hours"].isActive && (
                        <div className={styles.row}>
                            <div className={styles.labelWrap}>
                                <div className={cx(styles.icon)} />
                                <span className={styles.label}>
                                    {studentLabelInfo["academics.completed_hours"][labelKey]}:
                                </span>
                            </div>
                            <span className={styles.value}>{totalHoursCompleted}</span>
                        </div>
                    )}
                    {studentLabelInfo["academics.distant_edu_hours"].isActive && (
                        <div className={styles.row}>
                            <div className={styles.labelWrap}>
                                <div className={cx(styles.icon, styles.iconDistant)} />
                                <span className={styles.label}>
                                    {studentLabelInfo["academics.distant_edu_hours"][labelKey]}:
                                </span>
                            </div>
                            <span className={styles.value}>{totalDistEdHours}</span>
                        </div>
                    )}
                    {studentLabelInfo["academics.scheduled_hours"].isActive && (
                        <div className={styles.row}>
                            <div className={styles.labelWrap}>
                                <div className={cx(styles.icon, styles.iconScheduled)} />
                                <span className={styles.label}>
                                    {studentLabelInfo["academics.scheduled_hours"][labelKey]}:
                                </span>
                            </div>
                            <span className={styles.value}>{hoursScheduled}</span>
                        </div>
                    )}
                    {studentLabelInfo["academics.attempted_hours"].isActive && (
                        <div className={styles.row}>
                            <div className={styles.labelWrap}>
                                <div className={cx(styles.icon, styles.iconAttempt)} />
                                <span className={styles.label}>
                                    {studentLabelInfo["academics.attempted_hours"][labelKey]}:
                                </span>
                            </div>
                            <span className={styles.value}>{totalHoursAttempted}</span>
                        </div>
                    )}
                    {studentLabelInfo["academics.remaining_hours"].isActive && (
                        <div className={styles.row}>
                            <div className={styles.labelWrap}>
                                <div className={cx(styles.icon, styles.iconRemaining)} />
                                <span className={styles.label}>
                                    {studentLabelInfo["academics.remaining_hours"][labelKey]}:
                                </span>
                            </div>
                            <span className={styles.value}>{totalHoursRemaining}</span>
                        </div>
                    )}
                    {totalTransferHours > 0 && (
                        <div className={styles.row}>
                            <div className={styles.labelWrap}>
                                <div className={cx(styles.icon, styles.iconTransferHour)} />
                                <span className={styles.label}>Transfer Hours:</span>
                            </div>
                            <span className={styles.value}>{totalTransferHours}</span>
                        </div>
                    )}
                </div>
                <div className={styles.divider} />
                <div className={styles.row}>
                    <div className={styles.labelWrap}>
                        <div className={cx(styles.icon, styles.iconProgress)} />
                        <span className={styles.label}>Progress</span>
                    </div>
                    <span className={styles.value}>{progressPercentage}%</span>
                </div>
            </div>
            <div style={{width: 230, height: 230}}>
                <Doughnut data={data} options={options} plugins={plugins} />
            </div>
        </div>
    )
}
