/* eslint-disable react-hooks/exhaustive-deps */
import {FieldOperator, FilterKey, FilterList} from "types/filter"
import {Card} from "../Card"
import {useCallback} from "react"
import {studentServicesService} from "services"
import {Bar} from "react-chartjs-2"
import {v4 as uuidV4} from "uuid"
import {useTranslation} from "react-i18next"
import {useQuery} from "@tanstack/react-query"
import {handleError} from "helpers"
import {useModel} from "hooks"
import {STATES_OPTIONS} from "types/students"
import {Auth} from "types/auth"
import moment from "moment"

const MONTH_LABELS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

type Props = {
    loadKey: string
    filters?: FilterList
    chartOptions?: any
    goToStudentTab: () => void
}

export function Drop(props: Props) {
    const {loadKey, filters, chartOptions, goToStudentTab} = props
    const {t} = useTranslation(["studentServices"])
    const model = useModel()
    const filterKey = FilterKey.AcademicsPage_Analytics_Students

    const {data, isFetching} = useQuery({
        queryKey: ["student-drop", loadKey],
        enabled: !!loadKey,
        queryFn: async () => {
            const data = await studentServicesService.getDrop({filters})
            return {
                labels: data.map((x) => {
                    const timeLabels = x.yearMonth.split("-")
                    return `${MONTH_LABELS[parseInt(timeLabels[1]) - 1]} ${timeLabels[0]}`
                }),
                datasets: [
                    {
                        data: data.map((x) => x.noStudents),
                        label: "",
                        backgroundColor: "#e95c7b"
                    }
                ]
            }
        },
        onError: (err) => {
            handleError(err)
        }
    })

    const datasetKeyProvider = useCallback(() => uuidV4(), [filters])

    const renderChart = () => {
        if (!data) return null
        return <Bar data={data} options={chartOptions} datasetKeyProvider={datasetKeyProvider} />
    }

    const goToDetails = useCallback(() => {
        let filters: any = {}
        filters.state = {
            operator: FieldOperator.Equal,
            value: [STATES_OPTIONS.find((x) => x.id === Auth.StudentState.PermanentOut)]
        }
        filters.withdrawalDateRange = {
            operator: FieldOperator.After,
            value: moment().add(-12, "month").startOf("month").format("YYYY-MM-DD")
        }

        model.updateStorageFilter(filterKey, {
            filters
        })
        goToStudentTab()
    }, [goToStudentTab])

    return (
        <Card
            key={loadKey}
            iconName="PERSON_REMOVE"
            iconColor="var(--error-300)"
            title={t("drop")}
            renderChart={renderChart}
            isLoading={isFetching}
            linkedDetails
            goToDetails={goToDetails}
        />
    )
}
