import BaseService from "./Base"
import {ApiResponse} from "types/api"
import {AxiosResponse} from "axios"

class UserProfileServiceV3 extends BaseService {
    update = async (params) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/users/profile/edit", params)
        if (!response.success) {
            throw response
        }
        return response.data
    }
}
export default UserProfileServiceV3
