import React from "react"
import classes from "./InfoBox.module.css"
import cx from "classnames"

export type InfoBoxProps = {
    className?: string
    title: string
    description: string
}

export const InfoBox = ({className, title, description}: InfoBoxProps) => {
    return (
        <div className={cx(classes.root, className)}>
            <span className={classes.title}>{title}</span>
            <span className={classes.description}>{description}</span>
        </div>
    )
}
