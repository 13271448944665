import {Popover} from "antd"
import {Icon} from "components/Icon"
import React from "react"
import styles from "./item.module.css"

type Props = {
    field: any
}

export function RemarkIndicator(props: Props) {
    const {field} = props

    const renderRemarkComment = <div className={styles.remarkComment}>{field.remarkComment}</div>

    return (
        <div className={styles.remarkCommentWrap}>
            <Popover color="#ffe55b" content={renderRemarkComment} trigger="click">
                <div className={styles.remarkIndicator}>
                    <Icon icon="STICKY_NOTE" className={styles.remarkIndicatorIcon} color="#FF8A38" />
                </div>
            </Popover>
        </div>
    )
}
