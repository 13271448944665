import {Timestamps} from "./common"
import {Auth} from "./auth"

export enum ApprovalStatus {
    Pending = "pending",
    Approved = "approved",
    Rejected = "rejected"
}

export type ISIRTableData = {
    id: number
    studentEmail: string
    isirCode: string
    isirJson: string
    createdByProfileId: number
    updatedByProfileId: number
} & Timestamps

export type ISIRFileTableData = {
    id: number
    fileName: string
    isProcessed: boolean
    processedDate: Date
} & Timestamps

export type StudentCCodeItemTableData = {
    id: number
    isirCode: string
    cCodeId: number
    taskId: number
    documentId: number | null
    approvalStatus: ApprovalStatus
    approvalStatusUpdatedAt: string | null
    approvalSignatureId: number | null
} & Timestamps

export type CCodeTableData = {
    id: number
    isirCode: string
    cCode: string
    commentText: string
    createdByProfileId: number
    updatedByProfileId: number
} & Timestamps

export type ISIRShortDetails = {
    id: number
    studentEmail: string
    isirJson: string
}

export type ISIRDetails = ISIRTableData &
    Partial<{
        createdByProfile: Auth.UserShortDetails
        updatedByProfile: Auth.UserShortDetails
    }>

export type CCodeDetails = CCodeTableData &
    Partial<{
        createdByProfile: Auth.UserShortDetails
        updatedByProfile: Auth.UserShortDetails
    }>

export type CreateRecordISIRApiPayload = {
    isirCode: string
    studentEmail: string
    isirJson: string
    isDefault?: boolean
    fileName: string
}

export enum StudentCCodeItemLinkedEntities {
    CCodeItem = "cCodeItem",
    Document = "document",
    Signature = "signature",
    User = "user"
}
