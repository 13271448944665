import React, {useCallback, useState} from "react"
import cx from "classnames"
import {useModel, useVisible} from "hooks"
import {BaseButton, Icon} from "components"
import {studentServicesService, userServiceV3} from "services"
import {handleError} from "helpers"
import {useTranslation} from "react-i18next"
import {PermissionsRequired} from "components/PermissionsRequired"
import {Permissions} from "types/permission"
import styles from "./AdmissionPanelInfo.module.css"
import EditableStudentField from "components/EditableStudentField"
import {Auth} from "types/auth"
import {NewStudentStatusSelectFilter} from "components/NewStudentStatusSelect"
import {ConfirmPopup} from "uiKit"

interface AdmissionPanelInfoProps {
    student: Auth.DepartmentStudent
    setStudent: React.Dispatch<React.SetStateAction<Auth.DepartmentStudent>>
    handleChangeAdmissionAcceptance?: () => void
    reloadStudent: () => Promise<void>
}

export function AdmissionPanelInfo(props: AdmissionPanelInfoProps) {
    const {student, setStudent, reloadStudent, handleChangeAdmissionAcceptance} = props
    const admissionDetailVisible = useVisible(true)
    const cancelAdmissionsConfirmPopup = useVisible(false)
    const acceptingAdmissionsConfirmPopup = useVisible(false)
    const [newStudentStatus, setNewStudentStatus] = useState(null)
    const cancelEnrollConfirmPopup = useVisible(false)
    const enrollConfirmPopup = useVisible(false)
    const [isLoading, setIsLoading] = useState(false)
    const {t} = useTranslation(["common", "studentServices"])
    const model = useModel()

    const [isUpdatingAcceptanceAdmission, setUpdatingAcceptanceAdmission] = useState(false)

    const handleEnrollConfirm = useCallback(async () => {
        try {
            setIsLoading(true)
            await studentServicesService.enrollStudent({
                studentProfileId: student.profileId,
                studentStatusIdToAssign: newStudentStatus?.statusId
            })

            setStudent((prev) => ({
                ...prev,
                status: newStudentStatus
            }))
            setNewStudentStatus(null)
            // reload expanded section if active
            handleChangeAdmissionAcceptance?.()
            enrollConfirmPopup.close()
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }, [student.profileId, newStudentStatus, setStudent, handleChangeAdmissionAcceptance, enrollConfirmPopup])

    const handleCancelEnrollment = useCallback(async () => {
        try {
            setIsLoading(true)
            await studentServicesService.cancelStudentEnrollment({
                studentProfileId: student.profileId,
                studentStatusIdToAssign: newStudentStatus?.statusId
            })
            setStudent((prev) => ({
                ...prev,
                status: newStudentStatus
            }))
            setNewStudentStatus(null)
            // reload expanded section if active
            handleChangeAdmissionAcceptance?.()
            cancelEnrollConfirmPopup.close()
        } catch (error) {
            handleError(error, true)
        } finally {
            setIsLoading(false)
        }
    }, [student.profileId, newStudentStatus, setStudent, handleChangeAdmissionAcceptance, cancelEnrollConfirmPopup])

    const changeAdmissionsApplicationAcceptanceStatus = async (isAccepted: boolean) => {
        try {
            setUpdatingAcceptanceAdmission(true)

            if (isAccepted) {
                await userServiceV3.acceptAdmissionsApplication({
                    studentProfileId: +student.profileId,
                    studentStatusIdToAssign: newStudentStatus?.statusId
                })
            } else {
                await userServiceV3.cancelAdmissionsApplication({
                    studentProfileId: +student.profileId,
                    studentStatusIdToAssign: newStudentStatus?.statusId
                })
            }
            setStudent((prev) => ({
                ...prev,
                acceptanceDate: isAccepted ? new Date().toISOString() : null,
                status: newStudentStatus
            }))
            acceptingAdmissionsConfirmPopup.close()
            cancelAdmissionsConfirmPopup.close()

            setNewStudentStatus(null)

            // reload expanded section if active
            handleChangeAdmissionAcceptance?.()
        } catch (error) {
            handleError(error)
        } finally {
            setUpdatingAcceptanceAdmission(false)
        }
    }

    const renderNewStudentStatusSelect = (state: Auth.StudentState) => {
        if (!model.clientSetting.isNewStudentStatusesVisible) return null
        return (
            <NewStudentStatusSelectFilter
                value={newStudentStatus}
                onChange={setNewStudentStatus}
                isMulti={false}
                forState={state}
            />
        )
    }

    const renderAction = () => {
        if (student.profileState === Auth.StudentState.TemporaryOut) {
            return (
                <div className={styles.fullyEnrollWrap}>
                    <button className={`${styles.enrolledBtn} ${styles.permanentOutColor}`}>
                        <Icon icon="CHECKED" color="white" />
                        {t("studentServices:studentInfo.temporaryOut")}
                    </button>
                </div>
            )
        }
        if ([Auth.StudentState.PermanentOut, Auth.StudentState.NeverAttended].includes(student.profileState)) {
            return (
                <div className={styles.fullyEnrollWrap}>
                    <button className={`${styles.enrolledBtn} ${styles.permanentOutColor}`}>
                        <Icon icon="CHECKED" color="white" />
                        {t("studentServices:studentInfo.permanentOut")}
                    </button>
                </div>
            )
        }
        if (model.isStudent()) {
            if ([Auth.StudentState.Enrollment, Auth.StudentState.Student].includes(student.profileState)) {
                return (
                    <div className={styles.fullyEnrollWrap}>
                        <button className={`${styles.enrolledBtn} ${styles.enrolledColor}`}>
                            <Icon icon="CHECKED" />
                            {t("studentServices:studentInfo.fullyEnrolled")}
                        </button>
                    </div>
                )
            }
            if (student.profileState === Auth.StudentState.Applicant) {
                return (
                    <div className={styles.admissionStatusPanelBtn}>
                        <Icon icon="CHECKMARK_CIRCLE" color="#18A957" className={styles.checkPanelIcon} />
                        <span className={styles.admissionStatusPanelBtn__text}>Application accepted</span>
                    </div>
                )
            }

            return null
        }

        return (
            <>
                {student.profileState === Auth.StudentState.Applicant && (
                    <div className={styles.fullyEnrollWrap}>
                        <div className={styles.admissionStatusPanelBtn}>
                            <Icon icon="CHECKED" />
                            <span className={styles.admissionStatusPanelBtn__text}>Application accepted</span>
                        </div>

                        <PermissionsRequired
                            permissions={{
                                staff: [
                                    Permissions.Staff.Admissions.Students.AdmissionsStudentDetails.Edit,
                                    Permissions.Staff.StudentServicesAndAcademicAffairs.StudentServices.Students
                                        .StudentDetail.Edit,
                                    Permissions.Staff.AcademicAffairs.StudentServices.Students.StudentDetail.Edit
                                ]
                            }}>
                            <PermissionsRequired
                                permissions={{
                                    staff: [Permissions.Staff.Users.Students.StatusesOf.Prospect.Edit]
                                }}>
                                <BaseButton
                                    title={t("common:action.cancel")}
                                    variant="secondary"
                                    onClick={cancelAdmissionsConfirmPopup.open}
                                    loading={isUpdatingAcceptanceAdmission}
                                />
                            </PermissionsRequired>
                        </PermissionsRequired>
                    </div>
                )}
                {model.isStudent() &&
                    [Auth.StudentState.Enrollment, Auth.StudentState.Student].includes(student.profileState) && (
                        <div className={cx(styles.enrolledBtn, styles.enrolledColor)}>
                            <Icon icon="CHECKED" />
                            {t("studentServices:studentInfo.fullyEnrolled")}
                        </div>
                    )}
                <PermissionsRequired
                    permissions={{
                        staff: [Permissions.Staff.Admissions.Students.AdmissionsStudentDetails.Edit]
                    }}>
                    <>
                        {[Auth.StudentState.Enrollment, Auth.StudentState.Student].includes(student.profileState) ? (
                            <div className={styles.fullyEnrollWrap}>
                                <div className={cx(styles.enrolledBtn, styles.enrolledColor)}>
                                    <Icon icon="CHECKED" />
                                    {t("studentServices:studentInfo.fullyEnrolled")}
                                </div>
                                {student.profileState === Auth.StudentState.Enrollment ? (
                                    <PermissionsRequired
                                        permissions={{
                                            staff: [
                                                Permissions.Staff.Admissions.Students.AdmissionsStudentDetails.Edit,
                                                Permissions.Staff.StudentServicesAndAcademicAffairs.StudentServices
                                                    .Students.StudentDetail.Edit,
                                                Permissions.Staff.AcademicAffairs.StudentServices.Students.StudentDetail
                                                    .Edit
                                            ]
                                        }}>
                                        <PermissionsRequired
                                            permissions={{
                                                staff: [Permissions.Staff.Users.Students.StatusesOf.Applicant.Edit]
                                            }}>
                                            <BaseButton
                                                title={t("common:action.cancel")}
                                                variant="secondary"
                                                onClick={cancelEnrollConfirmPopup.open}
                                            />
                                        </PermissionsRequired>
                                    </PermissionsRequired>
                                ) : null}
                            </div>
                        ) : [
                              Auth.StudentState.PermanentOut,
                              Auth.StudentState.NeverAttended,
                              Auth.StudentState.TemporaryOut
                          ].includes(student.profileState) ? null : (
                            <>
                                {student.profileState === Auth.StudentState.Prospect ? (
                                    <PermissionsRequired
                                        permissions={{
                                            staff: [
                                                Permissions.Staff.Admissions.Students.AdmissionsStudentDetails.Edit,
                                                Permissions.Staff.StudentServicesAndAcademicAffairs.StudentServices
                                                    .Students.StudentDetail.Edit,
                                                Permissions.Staff.AcademicAffairs.StudentServices.Students.StudentDetail
                                                    .Edit
                                            ]
                                        }}>
                                        <PermissionsRequired
                                            permissions={{
                                                staff: [Permissions.Staff.Users.Students.StatusesOf.Applicant.Edit]
                                            }}>
                                            <BaseButton
                                                variant={"primary"}
                                                title={t("common:message.acceptAdmissionsApplicationTitle")}
                                                onClick={() => {
                                                    acceptingAdmissionsConfirmPopup.open()
                                                }}
                                                loading={isUpdatingAcceptanceAdmission}
                                                className={styles.acceptApplicationBtn}
                                            />
                                        </PermissionsRequired>
                                    </PermissionsRequired>
                                ) : null}
                                {student.profileState === Auth.StudentState.Applicant && (
                                    <PermissionsRequired
                                        permissions={{
                                            staff: [
                                                Permissions.Staff.Admissions.Students.AdmissionsStudentDetails.Edit,
                                                Permissions.Staff.StudentServicesAndAcademicAffairs.StudentServices
                                                    .Students.StudentDetail.Edit,
                                                Permissions.Staff.AcademicAffairs.StudentServices.Students.StudentDetail
                                                    .Edit
                                            ]
                                        }}>
                                        <PermissionsRequired
                                            permissions={{
                                                staff: [Permissions.Staff.Users.Students.StatusesOf.Enrollment.Edit]
                                            }}>
                                            <BaseButton
                                                title={t("studentServices:studentInfo.enrollStudent")}
                                                className={styles.enrolledBtn}
                                                onClick={enrollConfirmPopup.open}
                                            />
                                        </PermissionsRequired>
                                    </PermissionsRequired>
                                )}
                            </>
                        )}
                    </>
                </PermissionsRequired>
            </>
        )
    }

    const getUserData = useCallback(
        async (profileId: number) => {
            reloadStudent()
        },
        [reloadStudent]
    )

    return (
        <div className={styles.admissionPanelWrap}>
            <div className={styles.headingWrap}>
                <div className={styles.headingTitle}>Admission Details</div>
                <button
                    className={cx(styles.collapseButton, {[styles.collapsed]: admissionDetailVisible.isVisible})}
                    onClick={() => admissionDetailVisible.toggle()}>
                    <Icon icon="ARROW_DOWN" color="#666666" className={styles.headingIcon} />
                </button>
            </div>
            {admissionDetailVisible.isVisible && (
                <>
                    <EditableStudentField {...{field: "initialInquiryDate", student, getUserData}} />
                    <EditableStudentField {...{field: "applicationCompletedDate", student, getUserData}} />
                    <EditableStudentField {...{field: "acceptanceDate", student, getUserData}} />

                    {renderAction()}
                </>
            )}
            <ConfirmPopup
                isVisible={cancelAdmissionsConfirmPopup.isVisible}
                title={t("common:message.cancelAdmissionsApplicationTitle")}
                description={t("common:message.cancelAdmissionsApplication", {
                    userName: `${student.firstName} ${student.lastName}`
                })}
                onClose={() => {
                    setNewStudentStatus(null)
                    cancelAdmissionsConfirmPopup.close()
                }}
                loading={isUpdatingAcceptanceAdmission}
                onConfirm={() => changeAdmissionsApplicationAcceptanceStatus(false)}>
                {renderNewStudentStatusSelect(Auth.StudentState.Prospect)}
            </ConfirmPopup>

            <ConfirmPopup
                isVisible={acceptingAdmissionsConfirmPopup.isVisible}
                title={t("common:message.acceptAdmissionsApplicationTitle")}
                description={t("common:message.acceptAdmissionsApplication", {
                    userName: `${student.firstName} ${student.lastName}`
                })}
                onClose={() => {
                    setNewStudentStatus(null)
                    acceptingAdmissionsConfirmPopup.close()
                }}
                loading={isUpdatingAcceptanceAdmission}
                onConfirm={() => changeAdmissionsApplicationAcceptanceStatus(true)}>
                {renderNewStudentStatusSelect(Auth.StudentState.Applicant)}
            </ConfirmPopup>
            <ConfirmPopup
                isVisible={cancelEnrollConfirmPopup.isVisible}
                title={t("studentServices:cancelEnrollTitle")}
                description={t("studentServices:cancelEnrollDesc", {
                    userName: `${student.firstName} ${student.lastName}`
                })}
                onClose={() => {
                    setNewStudentStatus(null)
                    cancelEnrollConfirmPopup.close()
                }}
                loading={isLoading}
                onConfirm={handleCancelEnrollment}>
                {renderNewStudentStatusSelect(Auth.StudentState.Applicant)}
            </ConfirmPopup>

            <ConfirmPopup
                isVisible={enrollConfirmPopup.isVisible}
                title={t("studentServices:enrollConfirmTitle")}
                description={t("studentServices:enrollConfirmDescription", {
                    userName: `${student.firstName} ${student.lastName}`
                })}
                onClose={() => {
                    setNewStudentStatus(null)
                    enrollConfirmPopup.close()
                }}
                loading={isLoading}
                onConfirm={handleEnrollConfirm}>
                {renderNewStudentStatusSelect(Auth.StudentState.Enrollment)}
            </ConfirmPopup>
        </div>
    )
}
