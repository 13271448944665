import {get} from "lodash"
import BaseService from "./Base"

class EnrollmentRosterService extends BaseService {
    getFileList = async (params): Promise<{total: number; data: any[]}> => {
        const response = await this.post("/v3/enrollment-roster/file-list", params)
        return get(response, "data", [])
    }

    getStudentList = async (params): Promise<{total: number; data: any[]}> => {
        const response = await this.post("/v3/enrollment-roster/student-list", params)
        return get(response, "data", [])
    }

    updateProcessedData = async (data, params = {}) => {
        const response = await this.post("/v3/enrollment-roster/update-processed-student", data, params)
        return get(response, "data", {})
    }

    exportStudentData = async (params = {}) => {
        const response = await this.post("/v3/enrollment-roster/export-processed-student", params)
        return get(response, "data", [])
    }
}

export default EnrollmentRosterService
