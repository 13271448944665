/* eslint-disable react-hooks/exhaustive-deps */
import {KlassappTableHOC} from "HOC"
import React, {useCallback, useEffect} from "react"
import {withTranslation} from "react-i18next"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {v4 as uuidV4} from "uuid"
import styles from "./PassFailForm.module.css"
import {BaseInput} from "components"
import {cloneDeep} from "lodash"
import {GradingSystemDetail} from "types/gradingSystem"

let dataPassFailForm = []

const PassFailForm = (props) => {
    const {t} = props
    const {
        setInitialDataForm,
        initialDataForm,
        setNewDataForm,
        newDataForm,
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        data,
        isHideMenuActions,
        menuActions,
        allFields,
        fields,
        tableHeaderActions,
        orderField,
        isLoading,
        isShowTableHeaderAction
    } = props
    const {responseData}: {responseData: GradingSystemDetail} = props

    const getFields = () => {
        return [t("academics:settings.passFail"), t("academics:settings.grade"), ""]
    }

    const getPageTitle = () => {
        return "Grade"
    }

    const getColumns = () => {
        return [
            {
                title: t("academics:settings.passFail"),
                field: "passFail"
            },
            {
                title: t("academics:settings.grade"),
                field: "low",
                classNameHeader: styles.columnLowHigh,
                style: {width: "50px"}
            },
            {
                title: "",
                field: "high",
                classNameHeader: styles.columnLowHigh,
                style: {width: "50px"}
            }
        ]
    }

    const getData = async () => {
        dispatch({isLoading: true})
        try {
            const idPass = uuidV4()
            const idFail = uuidV4()
            const newData = [
                {
                    id: idPass,
                    passFail: renderFailPass(true),
                    low: renderInput("low", "Low", idPass, "", false),
                    high: renderInput("high", "High", idPass, "", false),
                    highValue: "",
                    lowValue: ""
                },
                {
                    id: idFail,
                    passFail: renderFailPass(false),
                    low: renderInput("low", "Low", idFail, "", false),
                    high: renderInput("high", "High", idFail, "", false),
                    lowValue: "",
                    highValue: ""
                }
            ]
            dataPassFailForm = [...newData]
            setNewDataForm([...newData])
            setInitialDataForm(cloneDeep(newData))
        } catch (e) {
            console.log("e")
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClickDeleteMulti = async () => {}

    const onClickDuplicateMulti = async () => {}

    const onClickEdit = (editItem) => {
        const newDataPassFail = data.map((item) => {
            if (editItem.id !== item.id) return item
            return {
                ...item,
                low: renderInput("low", "Low", item.id, item.lowValue, true),
                high: renderInput("high", "High", item.id, item.highValue, true)
            }
        })
        dataPassFailForm = [...newDataPassFail]
        setNewDataForm([...newDataPassFail])
    }

    const onClickDelete = async () => {}

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "getMenuActions", func: getMenuActions},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickDuplicateMulti", func: onClickDuplicateMulti},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDelete},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [data, newDataForm, initialDataForm])

    const onClickRowItem = (row) => {}

    const renderFailPass = useCallback((isPass: boolean) => {
        return (
            <div>
                <div style={{background: isPass ? "green" : "red"}} className={styles.passFail}>
                    <span className={styles.passFailText}>{isPass ? "Pass" : "Fail"}</span>
                </div>
            </div>
        )
    }, [])

    const getMenuActions = () => {
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: onClickEdit
            }
        ]
    }

    const renderInput = (field: string, nameField: string, id: string, value: string, isEditing: boolean) => {
        if (!isEditing) {
            return (
                <div className={styles.titleWrap}>
                    <span className={styles.title}>{value}</span>
                </div>
            )
        }
        return (
            <BaseInput
                type="number"
                placeholder={nameField}
                value={value}
                min={0}
                onChange={(text) => onChangeInput(id, field, text)}
            />
        )
    }

    const onChangeInput = (id, field, value) => {
        const changeDataForm = dataPassFailForm.map((item) => {
            if (item.id !== id) {
                return item
            }
            const newValue = {
                ...item,
                [field]: renderInput(field, field === "high" ? "High" : "Low", item.id, value, true),
                highValue: field === "high" ? value : item.highValue,
                lowValue: field === "low" ? value : item.lowValue
            }
            return newValue
        })
        dataPassFailForm = changeDataForm
        setNewDataForm(changeDataForm)
    }

    useEffect(() => {
        if (responseData && responseData.gradingPassFail && responseData.gradingPassFail.length) {
            const passFail = responseData.gradingPassFail
            const newData = [
                {
                    id: passFail[0].id,
                    passFail: renderFailPass(passFail[0].passFail),
                    low: renderInput("low", "Low", passFail[0].id.toString(), `${passFail[0].numericLow}`, false),
                    high: renderInput("high", "High", passFail[0].id.toString(), `${passFail[0].numericHigh}`, false),
                    highValue: passFail[0].numericHigh,
                    lowValue: passFail[0].numericLow
                },
                {
                    id: passFail[1].id,
                    passFail: renderFailPass(passFail[1].passFail),
                    low: renderInput("low", "Low", passFail[1].id.toString(), `${passFail[1].numericLow}`, false),
                    high: renderInput("high", "High", passFail[1].id.toString(), `${passFail[1].numericHigh}`, false),
                    lowValue: passFail[1].numericLow,
                    highValue: passFail[1].numericHigh
                }
            ]
            dataPassFailForm = [...newData]
            setNewDataForm([...newData])
            setInitialDataForm(cloneDeep(newData))
        } else {
            getData()
        }
    }, [responseData])

    useEffect(() => {
        dispatch({data: initialDataForm, total: initialDataForm.length})
        dataPassFailForm = initialDataForm
    }, [initialDataForm])

    useEffect(() => {
        dispatch({data: [...newDataForm], total: newDataForm.length})
    }, [newDataForm])

    return (
        <>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                isShowSubHeader={true}
                subHeaderColumns={[{title: "LOW"}, {title: "HIGH"}]}
                isLoading={isLoading}
                menuActions={isHideMenuActions ? [] : menuActions}
                allFields={allFields}
                isShowCheckedColumn={false}
                classNameActionMenu={styles.colorColumn}
                orderField={orderField}
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </>
    )
}

export default KlassappTableHOC(withTranslation(["academics", "common", "course"])(PassFailForm))
