import {get} from "lodash"
import BaseService from "./Base"
import {PermissionDetails, ProfilePermissionsDetails} from "@edular/permissions"

class RolePermissionService extends BaseService {
    async getPermissionsByProfile(profileId) {
        const response = await this.post(`/v1/profile-permissions/list`, {profileId})
        return get(response, "data", {})
    }

    async getMyPermissions(): Promise<ProfilePermissionsDetails> {
        const response = await this.post(`/v1/profile-permissions/my`, {})
        return get(response, "data.data", {})
    }

    async getPermissionGroups(params = {}) {
        const response = await this.post("/v1/permission-groups/list", params)
        return get(response, "data", [])
    }

    async getPermissionGroup(id, params = {}) {
        const response = await this.post("/v1/permission-groups/list", {
            ...params,
            linkedEntities: true,
            filter: {permissionGroupIds: [id]}
        })
        return get(response, "data.data[0]", {})
    }

    async createPermissionGroup(params = {}) {
        const response = await this.post("/v1/permission-groups/create", params)
        return get(response, "data.data", {})
    }

    async updatePermissionGroup(id, data, params = {}) {
        data.permissionGroupId = id
        const response = await this.post(`/v1/permission-groups/edit`, data, params)
        return get(response, "data", {})
    }

    async deletePermissionGroup(permissionGroupIds) {
        const response = await this.post(`/v1/permission-groups/delete`, {permissionGroupIds})
        return get(response, "data", {})
    }

    async getRoles(params = {}) {
        const response = await this.post("/v2/roles/list", params)
        return get(response, "data", [])
    }

    async getRole(id, params = {}) {
        const response = await this.post("/v2/roles/list", {
            ...params,
            filter: {roleIds: [id]}
        })
        return get(response, "data.data[0]", {})
    }

    async createRole(params = {}) {
        const response = await this.post("/v2/roles/create", params)
        return get(response, "data.data", {})
    }

    async updateRole(id, data, params = {}) {
        data.roleId = id
        const response = await this.post(`/v2/roles/edit`, data, params)
        return get(response, "data", {})
    }

    async deleteRole(roleIds) {
        const response = await this.post(`/v2/roles/delete`, {roleIds})
        return get(response, "data", {})
    }

    async getDepartmentPermissions(params = {}) {
        const response = await this.post(`/v1/department-permissions/list`, params)
        return get(response, "data.data", {})
    }

    async getDepartmentPermissionList(ids: number[]): Promise<PermissionDetails[]> {
        const response = await this.post(`/v1/department-permissions/get`, {ids})
        return get(response, "data.data", {})
    }
}

export default RolePermissionService
