import React from "react"
import classNames from "classnames"
import styles from "./SelfPay.module.css"
import {useTranslation} from "react-i18next"

export function SelfPay(props) {
    const {t} = useTranslation(["student"])
    return (
        <div className={styles.wrap}>
            <p className={styles.title}>{t("student.workspace.selfPay")}</p>
            <div className={styles.divider}></div>
            <div className={styles.body}>
                <div className={styles.headerItem}>
                    <p className={styles.item__title}></p>
                    <p className={classNames(styles.item__students, styles.headerItem__title)}>STUDENTS</p>
                    <p className={classNames(styles.item__cost, styles.headerItem__title)}>TOTAL</p>
                </div>
                <div className={styles.item}>
                    <p className={styles.item__title}>0-30 days</p>
                    <p className={styles.item__students}>5</p>
                    <p className={styles.item__cost}>12,232.00</p>
                </div>
                <div className={styles.item}>
                    <p className={styles.item__title}>30 - 60 days</p>
                    <p className={styles.item__students}>12</p>
                    <p className={styles.item__cost}>8,990.00</p>
                </div>
                <div className={styles.item}>
                    <p className={styles.item__title}>60 - 90</p>
                    <p className={styles.item__students}>6</p>
                    <p className={styles.item__cost}>12,232.00</p>
                </div>
                <div className={styles.item}>
                    <p className={styles.item__title}>&gt; 90 </p>
                    <p className={styles.item__students}>6</p>
                    <p className={styles.item__cost}>12,232.00</p>
                </div>
            </div>
        </div>
    )
}
