import React from "react"
import {Row, Col} from "antd"
import {Icon} from "components/Icon"
import {BaseButton} from "components/buttons"
import styles from "./AttendanceHeader.module.css"
import {roundTwoDigits} from "helpers"
type AttendanceHeaderType = {
    attendanceStats: {
        actionNeeded: number
        missedHours: number
        attendedHours: number
        scheduledHours: number
    }
}
export function AttendanceHeader(props: AttendanceHeaderType) {
    const {attendanceStats} = props

    return (
        <div className={styles.wrap}>
            <Row gutter={[20, 20]}>
                <Col span={6}>
                    <div className={styles.itemWrap}>
                        <div className={styles.iconWrap} style={{background: "#e9f4ff"}}>
                            <Icon className={styles.icon} icon="CLOCK" />
                        </div>
                        <div className={styles.infoWrap}>
                            <p className={styles.infoTitle}>Total hours:</p>
                            <p className={styles.infoValue}>{roundTwoDigits(attendanceStats?.attendedHours ?? 0)}</p>
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.itemWrap}>
                        <div className={styles.iconWrap} style={{background: "#f9d0d9"}}>
                            <Icon icon="PERSON_REMOVE" color="#E95C7B" />
                        </div>
                        <div className={styles.infoWrap}>
                            <p className={styles.infoTitle}>Missed Hours</p>
                            <p className={styles.infoValue}>{roundTwoDigits(attendanceStats?.missedHours ?? 0)}</p>
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.itemWrap}>
                        <div className={styles.iconWrap} style={{background: "#fff1d7"}}>
                            <Icon icon="ALERT" />
                        </div>
                        <div className={styles.infoWrap}>
                            <p className={styles.infoTitle}>Action Needed</p>
                            <p className={styles.infoValue}>{attendanceStats?.actionNeeded ?? 0}</p>
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.exportBtnWrap}>
                        <BaseButton title="Export" variant="secondary" />
                    </div>
                </Col>
            </Row>
        </div>
    )
}
