import React, {useState} from "react"
import classNames from "classnames"
import styles from "./Header.module.css"
import {SideBar} from "./parts/SideBar"
import {CollapseButton} from "./parts/CollapseButton"

interface Props {
    hidden?: boolean
}

export function Header(props: Props) {
    const [isOpenMenu, setIsOpenMenu] = useState(true)
    const {hidden} = props

    const onToggleSideBar = () => {
        setIsOpenMenu(!isOpenMenu)
    }

    if (hidden) {
        return null
    }

    return (
        <header
            className={classNames(styles.header, {
                [styles.collapsed]: !isOpenMenu
            })}>
            <CollapseButton onClick={onToggleSideBar} type="list" />
            {isOpenMenu && (
                <div className={styles.wrapper}>
                    <SideBar />
                </div>
            )}
        </header>
    )
}
