/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useCallback} from "react"
import {useTranslation, withTranslation} from "react-i18next"
import {Col, Row, Checkbox} from "antd"
import debounce from "debounce-promise"
import cx from "classnames"
import groupBy from "lodash/groupBy"
import moment from "moment"
import {Model} from "Model"
import {handleError, toastError} from "helpers"
import {Screen} from "components/ui"
import {BaseLoading} from "components/Loading"
import {BaseInput} from "components/inputs"
import {FormLabel} from "components/Form"
import {KlassDropdown, KlassDropAsyncPaginate} from "components/Select"
import {BaseButton, SecondaryButton} from "components/buttons"
import {BaseDatePicker, BaseTimePicker} from "components/DateTimePicker"
import {DepartmentSubunit} from "types/departments"
import styles from "./Concerns.module.css"
import {campusesService, concernsService, concernSettingsService, departmentSubunitService} from "services"

type Props = {
    model: Model
    history: any
    data?
}

type Fields = {
    isAnonymous: boolean
    departmentSubunitIds?: number[]
    departmentSubunits?: any[]
    campusIds?: number[]
    campuses?: any[]
    personIds?: number[]
    persons?: any[]
    incidentDate: string
    incidentTime: string
    code: string
    title: string
    description: string
}

const initState: Fields = {
    isAnonymous: false,
    departmentSubunitIds: [],
    departmentSubunits: [],
    campusIds: [],
    campuses: [],
    personIds: [],
    persons: [],
    incidentDate: null,
    incidentTime: null,
    code: `CN1`,
    title: null,
    description: null
}

function ConcernCreate(props: Props) {
    const {t} = useTranslation(["common", "concern"])
    const [isLoading, setIsLoading] = useState(false)
    const [inputFields, setInputFields] = useState(initState)
    const [departmentSubunitIds, setDepartmentSubunitIds] = useState([])
    const [departmentSubunits, setDepartmentSubunits] = useState([])
    const [advisors, setAdvisors] = useState([])

    useEffect(() => {
        getMaxId()
    }, [])

    useEffect(() => {
        setInputFields((prevState) => ({
            ...prevState,
            departmentSubunits: [],
            departmentSubunitIds: [],
            persons: [],
            personIds: []
        }))
        setDepartmentSubunitIds([])
        setAdvisors([])

        if (inputFields.campusIds.length) {
            getDepartmentSubunitsByCampus()
        }
    }, [inputFields.campusIds])

    useEffect(() => {
        setDepartmentSubunits([])

        if (departmentSubunitIds.length) {
            getDepartmentSubunits()
        }
    }, [departmentSubunitIds])

    useEffect(() => {
        setInputFields((prevState) => ({
            ...prevState,
            persons: [],
            personIds: []
        }))
        setAdvisors([])

        if (inputFields.departmentSubunitIds.length) {
            getDepartmentAdvisors()
        }
    }, [inputFields.departmentSubunitIds])

    const getMaxId = async () => {
        try {
            const {data: maxId} = await concernsService.getMaxId()
            setInputFields((prevState) => ({...prevState, code: `CN${maxId + 1}`}))
        } catch (error) {
            handleError(error)
        }
    }

    const onCampusSearch = async (campus: string, loadedOptions) => {
        try {
            const {data: campuses, total} = await campusesService.getAll({
                fields: ["id", "name"],
                filter: {
                    search: campus
                },
                range: {
                    limit: 20,
                    offset: loadedOptions.length
                }
            })
            return {
                options: campuses,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const getParamsDepartmentSubunits = () => {
        const params = {
            filter: {
                campusIds: inputFields.campusIds
            },
            sort: {
                orderBy: "departmentSubunitId",
                orderDir: "asc"
            }
        }
        return params
    }

    const getDepartmentSubunitsByCampus = async () => {
        try {
            const params = getParamsDepartmentSubunits()
            const {data} = await concernSettingsService.listDepartmentSubunits(params)

            let departmentSubunitIds: number[] = []
            data.forEach((el) => {
                if (departmentSubunitIds.indexOf(el.departmentSubunitId) < 0) {
                    departmentSubunitIds.push(el.departmentSubunitId)
                }
            })
            setDepartmentSubunitIds(departmentSubunitIds)
        } catch (error) {
            handleError(error)
        }
    }

    const getDepartmentSubunits = async () => {
        try {
            const {data: subunits} = await departmentSubunitService.getSubunits({
                filter: {subunitIds: departmentSubunitIds},
                linkedEntities: true
            })
            let newData = Object.entries(groupBy(subunits, "departmentId")).map(([departmentId, subunits]) => ({
                label: subunits[0]?.department?.name,
                options: subunits
            }))
            setDepartmentSubunits(newData)
        } catch (error) {
            handleError(error)
        }
    }

    const getParamsAdvisors = () => {
        const params = {
            filter: {
                campusIds: inputFields.campusIds,
                departmentSubunitIds: inputFields.departmentSubunitIds
            },
            sort: {
                orderBy: "profileId",
                orderDir: "asc"
            }
        }
        return params
    }

    const getDepartmentAdvisors = async () => {
        try {
            const params = getParamsAdvisors()
            const {data} = await concernSettingsService.listPersons(params)

            let newData = data.map((item) => ({
                ...item,
                id: item.profileId,
                name: item.fullName
            }))

            setAdvisors(newData)
        } catch (error) {
            handleError(error)
        }
    }

    const validateBeforeSubmit = () => {
        const attrs = {
            code: {type: "string", errorMessage: t("common:validation.cantEmpty", {field: "Code"})},
            title: {type: "string", errorMessage: t("common:validation.cantEmpty", {field: "Title"})},
            description: {type: "string", errorMessage: t("common:validation.cantEmpty", {field: "Description"})}
        }
        for (let [key, value] of Object.entries(attrs)) {
            if (!inputFields[key]) {
                toastError(value.errorMessage)
                return false
            } else if (
                (value.type === "string" && inputFields[key].trim() === "") ||
                (value.type === "array" && inputFields[key].length < 1) ||
                (value.type === "object" && Object.keys(inputFields[key]).length < 1)
            ) {
                toastError(value.errorMessage)
                return false
            }
        }
        return true
    }

    const submitDataFormat = async () => {
        const {departmentSubunits, campuses, persons, ...rest} = inputFields

        let submitData: any = {
            ...rest,
            incidentDate: rest.incidentDate ? moment(rest.incidentDate).format("YYYY-MM-DD") : null,
            incidentTime: rest.incidentTime ? moment(rest.incidentTime).format("HH:mm") : null
        }

        return submitData
    }

    const onClickSave = async () => {
        setIsLoading(true)

        try {
            if (validateBeforeSubmit()) {
                const data = await submitDataFormat()

                await concernsService.create(data)

                props.history.goBack()
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const onClickCancelBtn = props.history.goBack

    const delayTime = 500
    const debounceCampuses = useCallback(debounce(onCampusSearch, delayTime), [])

    return (
        <Screen
            htmlTitle={t("concern:form.title")}
            header={{
                title: t("concern:form.title"),
                backUrl: "/concerns"
            }}>
            <BaseLoading isShow={isLoading} />

            <Row gutter={[40, 32]}>
                <Col span={8}>
                    <div className={styles.isAnonymous}>
                        <span className={styles.smsText}>{t("concern:form.label.anonymous")}</span>
                        <Checkbox
                            checked={inputFields.isAnonymous}
                            onChange={(e) =>
                                setInputFields((prevState) => ({...prevState, isAnonymous: e.target.checked}))
                            }
                        />
                    </div>
                </Col>
                <Col span={16} />
                <Col span={8}>
                    <FormLabel label={t("concern:form.label.campus")} />
                    <KlassDropAsyncPaginate
                        loadOptions={debounceCampuses}
                        value={inputFields.campuses}
                        valueKey="id"
                        onChange={(selectedValues) =>
                            setInputFields((prevState) => ({
                                ...prevState,
                                campuses: selectedValues ?? [],
                                campusIds: selectedValues?.map((selectedValue) => selectedValue.id) ?? []
                            }))
                        }
                        isMulti
                        placeholder="Campus"
                    />
                </Col>
                <Col span={8}>
                    <FormLabel label={t("concern:form.label.department")} />
                    <KlassDropdown
                        options={departmentSubunits}
                        value={inputFields.departmentSubunits}
                        valueKey="subunitId"
                        onChange={(subunits?: DepartmentSubunit[]) =>
                            setInputFields((prevState) => ({
                                ...prevState,
                                departmentSubunits: subunits ?? [],
                                departmentSubunitIds: subunits?.map((subunit) => subunit.subunitId) ?? []
                            }))
                        }
                        isMulti
                        isClearable={true}
                        cacheOptions={false}
                        placeholder="Select"
                    />
                </Col>
                <Col span={8}>
                    <FormLabel label={`${t("concern:form.label.selectPerson")} ${t("concern:form.label.optional")}`} />
                    <KlassDropdown
                        isMulti
                        placeholder="Select"
                        value={inputFields.persons}
                        valueKey="profileId"
                        options={advisors}
                        onChange={(selectedValues) =>
                            setInputFields((prevState) => ({
                                ...prevState,
                                persons: selectedValues ?? [],
                                personIds: selectedValues?.map((el) => el.profileId) ?? []
                            }))
                        }
                    />
                </Col>
                <Col span={8}>
                    <FormLabel
                        label={`${t("concern:form.label.specificDateOfIncident")} ${t(
                            "concern:form.label.ifApplicable"
                        )}`}
                    />
                    <BaseDatePicker
                        placeholder="Date"
                        format="YYYY-MM-DD"
                        value={inputFields.incidentDate}
                        onChange={(date) => setInputFields((prevState) => ({...prevState, incidentDate: date}))}
                    />
                </Col>
                <Col span={8}>
                    <FormLabel
                        label={`${t("concern:form.label.specificTimeOfIncident")} ${t(
                            "concern:form.label.ifApplicable"
                        )}`}
                    />
                    <BaseTimePicker
                        placeholder="Time"
                        format="HH:mm"
                        value={inputFields.incidentTime}
                        onChange={(time) => setInputFields((prevState) => ({...prevState, incidentTime: time}))}
                    />
                </Col>
            </Row>

            <div className={cx(styles.formGroup, styles.borderTop)}>
                <Row gutter={[40, 32]}>
                    <Col span={8}>
                        <FormLabel label={t("concern:form.label.id")} />
                        <BaseInput
                            placeholder="ID"
                            value={inputFields.code}
                            onChange={(value) => setInputFields((prevState) => ({...prevState, code: value}))}
                            disabled
                        />
                    </Col>
                    <Col span={16}>
                        <FormLabel label={t("concern:form.label.title")} />
                        <BaseInput
                            placeholder="Name"
                            value={inputFields.title}
                            onChange={(value) => setInputFields((prevState) => ({...prevState, title: value}))}
                        />
                    </Col>
                    <Col span={24}>
                        <FormLabel label={t("concern:form.label.description")} />
                        <BaseInput
                            placeholder="Description"
                            value={inputFields.description}
                            onChange={(value) => setInputFields((prevState) => ({...prevState, description: value}))}
                        />
                    </Col>
                </Row>
            </div>

            <div className={styles.actionBar}>
                <div className={styles.concernActions}>
                    <SecondaryButton
                        title={t("common:action.cancel")}
                        className={styles.cancelBtn}
                        onClick={onClickCancelBtn}
                    />
                    <BaseButton
                        title={t("concern:buttons.fileConcern")}
                        uppercase
                        loading={isLoading}
                        onClick={onClickSave}
                    />
                </div>
            </div>
        </Screen>
    )
}

export default withTranslation(["settings", "common", "concern"])(ConcernCreate)
