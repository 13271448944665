import React from "react"
import {Icon} from "components/Icon"
import styles from "./PrimaryDescription.module.css"

export function PrimaryDescription(props) {
    const {isPrimary} = props

    return (
        <div className={styles.primaryDescriptionWrap}>
            <div className={styles.primaryDescription__iconWrap}>
                <Icon icon="INFO" className={styles.primaryDescription__icon} />
            </div>
            <p className={styles.primaryDescription__text}>
                {isPrimary
                    ? "Primary signer function allows you to select multiple signers"
                    : "Non Primary signer function allows you to select single signer"}
            </p>
        </div>
    )
}
