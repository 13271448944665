import {BaseLoading, Icon, IconType} from "components"
import styles from "./Card.module.css"

type Props = {
    title: string
    iconName: IconType
    isLoading?: boolean
    iconColor?: string
    iconStyle?: string
    renderChart: () => JSX.Element
    linkedDetails?: boolean
    goToDetails?: () => void
}

export function Card(props: Props) {
    const {
        title,
        iconName,
        isLoading = false,
        iconColor,
        iconStyle,
        renderChart,
        linkedDetails = false,
        goToDetails
    } = props

    return (
        <div className={styles.cardWrap}>
            <div className={styles.headerContainer}>
                <div className={styles.headerWrap}>
                    <Icon icon={iconName} color={iconColor} className={iconStyle} />
                    <span className={styles.title}>{title}</span>
                </div>
                {linkedDetails && (
                    <div className={styles.linkedDetails} onClick={goToDetails}>
                        Details
                    </div>
                )}
            </div>
            <div className={styles.divider} />
            {renderChart()}
            <BaseLoading isShow={isLoading} />
        </div>
    )
}
