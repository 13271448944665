/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react"
import {isEmpty} from "lodash"
import {KlassappTableProps} from "types/common"
import classNames from "classnames"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {handleError, handleErrorMessage} from "helpers"
import styles from "./FinAidNoticeTable.module.css"
import {noticeService} from "services"
import {NoticeTypes} from "../FinAidNoticeDetail"
import {useHistory} from "react-router-dom"

interface PageProps {
    filterObject: any
    filterFormSearch: string
    isSubmitFilter: boolean
    onChangeFilterComplete: any
}

type Props = KlassappTableProps & PageProps

function FinAidNoticeTable(props: Props) {
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        tableHeaderActions,
        isLoading,
        isShowTableHeaderAction,
        isSubmitFilter,
        filterObject,
        filterFormSearch,
        onChangeFilterComplete
    } = props

    const history = useHistory()

    const getPageTitle = () => {
        return "Notice"
    }

    useEffect(() => {
        getData()
    }, [filterFormSearch])

    const getParams = () => {
        const {page, pageSize, orderField} = props
        const params: any = {
            range: {
                page,
                pageSize
            },
            filter: {
                ...filterObject,
                text: filterFormSearch
            },
            linkedObjects: true
        }
        if (!isEmpty(orderField)) {
            params.orderBy = orderField.field
            params.orderDir = orderField.order
        }
        return params
    }

    const getData = async () => {
        const params = getParams()
        dispatch({isLoading: true})
        try {
            const {data, total} = await noticeService.getList(params)
            if (!data) {
                handleErrorMessage("No notice found")
                return false
            }

            const newData = data.map((item) => {
                let newData: any = {
                    id: item.noticeId,
                    type: item.type,
                    typeText: NoticeTypes[item.type.toUpperCase()].toUpperCase(),
                    description: item.description,
                    name: item.name,
                    code: item.code,
                    createdAt: item.createdAt,
                    statusHtml: renderStatusHtml(item.isRead)
                }

                return newData
            })

            dispatch({data: newData, total})
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const getColumns = () => {
        const format = "MM/DD/YYYY"

        return [
            {
                title: "",
                field: "statusHtml",
                style: {minWidth: "120px"}
            },
            {
                title: "Type",
                field: "typeText",
                style: {minWidth: "120px"}
            },
            {
                title: "Description",
                field: "description",
                style: {minWidth: "350px"}
            },
            {
                title: "ID",
                field: "code",
                style: {minWidth: "200px"}
            },
            {
                title: "Name",
                field: "name",
                style: {minWidth: "200px"}
            },
            {
                title: "Date",
                field: "createdAt",
                fieldType: "date",
                format,
                style: {minWidth: "200px"}
            }
        ]
    }

    const getFields = () => {
        return ["", "Type", "Description", "ID", "Name", "Date"]
    }

    const onClickRowItem = async (inputData) => {
        history.push({
            pathname: `/financial-aid/staff/notice/detail`,
            search: `?id=${inputData.id}`
        })
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [])

    useEffect(() => {
        if (isSubmitFilter) {
            getData()
            onChangeFilterComplete()
        }
    }, [isSubmitFilter])

    useEffect(() => {
        getData()
    }, [page, pageSize])

    useEffect(() => {
        props.updateTableHeaderActions()
    }, [data])

    const renderStatusHtml = (status: boolean) => {
        const statusString: string = status ? "read" : "unread"
        return (
            <div
                className={classNames(
                    styles.status,
                    `${statusString === "read" ? styles.status__read : styles.status__unread}`
                )}>
                {statusString}
            </div>
        )
    }

    return (
        <div>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                actions={tableHeaderActions}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                data={data}
                isLoading={isLoading}
                fields={fields}
                allFields={allFields}
                menuActions={[]}
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </div>
    )
}

export default KlassappTableHOC(FinAidNoticeTable)
