import {get} from "lodash"
import BaseService from "./Base"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {EventInvitee} from "types/calendar"

class CalendarService extends BaseService {
    private viewOtherUserId = null

    constructor() {
        super(process.env.REACT_APP_CALENDAR_API_BASE_URL)
    }

    setViewOtherUserId(viewOtherUserId: number | null) {
        this.viewOtherUserId = viewOtherUserId
    }

    private addViewOtherUserIdQueryParam(path: string) {
        return `${path}${this.viewOtherUserId ? `?viewOtherUserId=${this.viewOtherUserId}` : ""}`
    }

    getMyPrimaryCalendar = async (data, params = {}) => {
        const response = await this.post(`/calendars/get-my-primary`, data, params)
        return get(response, "data", {})
    }

    async getAllCalendars(data, params = {}) {
        const response = await this.post(`/calendars/get-all`, data, params)
        return get(response, "data.data", [])
    }

    async getCalendar(data, params = {}) {
        const response = await this.post(`/calendars/${data.Id}/get`, data, params)
        return get(response, "data", [])
    }

    async addCalendar(data, params = {}) {
        const response = await this.post(`/calendars/add`, data, params)
        return get(response, "data", [])
    }

    async updateCalendar(data, params = {}) {
        const response = await this.post(`/calendars/${data.Id}/edit`, data, params)
        return get(response, "data", [])
    }

    async deleteCalendar(data, params = {}) {
        const response = await this.post(`/calendars/${data.Id}/remove`, data, params)
        return get(response, "data", [])
    }

    async deleteEvent(id, data, params = {}) {
        const response = await this.post(this.addViewOtherUserIdQueryParam(`/objects/${id}/remove`), data, params)
        return get(response, "data", [])
    }

    /**
     const payload = {
     sort: {
     orderBy: "start_at_utc",
     order: "ASC"
     },
     filter: {
     calendar_ids: [+data.Id],
     ...filter
     // from: "2019-10-01T11:18:07.000Z",
     // to: "2021-10-25T11:18:07.000Z",
     // types: ["event", "appointment_slot"]
     },
     pagination: {
     page: 1,
     pageSize: 100000
     }
     }
     */
    getAll = async (payload, params = {}) => {
        const response = await this.post(this.addViewOtherUserIdQueryParam(`/objects/get-all`), payload, params)
        return get(response, "data", [])
    }

    getAllInvitesCalendar = async (payload, params = {}) => {
        const response = await this.post(
            this.addViewOtherUserIdQueryParam(`/invites-calendar/get-all`),
            payload,
            params
        )
        return get(response, "data", [])
    }

    async removeAllObjectsFromCalendar(data, params) {
        const response = await this.post(`/calendar/${data.Id}/objects/remove-all`, data, params)
        return get(response, "data", [])
    }

    async addObject(data, params = {}): Promise<any[]> {
        try {
            const response = await this.post(this.addViewOtherUserIdQueryParam(`/objects/add`), data, params)
            return get(response, "data.data", [])
        } catch (e: any) {
            if (JSON.stringify(e.response).includes("overlap")) {
                // eslint-disable-next-line
                throw "overlap"
            } else {
                // eslint-disable-next-line
                throw "internal_error"
            }
        }
    }

    async getObject(objectId, data = {}, params = {}) {
        const response = await this.post(this.addViewOtherUserIdQueryParam(`/objects/${objectId}/get`), data, params)
        return get(response, "data.data", {})
    }

    async updateObject(objectId, data, params = {}) {
        const response = await this.post(this.addViewOtherUserIdQueryParam(`/objects/${objectId}/edit`), data, params)
        return get(response, "data", [])
    }

    async getAllSpreadObject(data, params = {}) {
        const response = await this.post(`/objects/get-all`, data, params)
        return get(response, "data", [])
    }

    async book(bookId, data, params = {}) {
        const response = await this.post(`/objects/${bookId}/book`, data, params)
        return get(response, "data", [])
    }

    async unbook(bookId, data = {}, params = {}) {
        const response = await this.post(`/objects/${bookId}/book`, data, params)
        return get(response, "data", [])
    }

    async getAllInvites(data = {}, params = {}) {
        const response = await this.post(this.addViewOtherUserIdQueryParam(`/invites/get-all`), data, params)
        return get(response, "data", [])
    }

    async addInvites(data = {}, params = {}) {
        const response = await this.post(`/invites/add`, data, params)
        return get(response, "data", [])
    }

    async removeInvites(data = {}, params = {}) {
        const response = await this.post(`/invites/remove`, data, params)
        return get(response, "data", [])
    }

    async updateStatus(data) {
        const response = await this.post(this.addViewOtherUserIdQueryParam(`/invites/update-status`), data)
        return get(response, "data", [])
    }

    async userMerge(data) {
        const response = await this.post(`/user-merge`, data)
        return get(response, "data", [])
    }

    async startEvent(object_id: number): Promise<void> {
        const response = await this.post("/objects/start-event", {object_id})
        return get(response, "data.data", null)
    }

    async toggleCheckIn(object_id: number, user_id: number): Promise<void> {
        const response = await this.post("/invites/check-in", {object_id, user_id})
        return get(response, "data.data", null)
    }

    async getAllEventInvitees(object_id: number): Promise<EventInvitee[]> {
        const response = await this.post(`/invites/users`, {
            filter: {object_id},
            sort: {orderBy: "invite_id", order: "ASC"},
            pagination: {page: 1, pageSize: UNLIMITED_PAGE_SIZE}
        })
        return get(response, "data.data", [])
    }
}

export default CalendarService
