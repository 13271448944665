/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react"
import {withTranslation} from "react-i18next"
import {BaseInput} from "components/inputs"
import {BaseButton, SecondaryButton, AddItemCircleButton} from "components/buttons"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableProps} from "types/common"
import {getFullName, handleError} from "helpers"
import {settingExternshipSiteService, majorService, userService} from "services"
import {KlassappTableHOC} from "HOC"
import styles from "./ExternshipSites.module.css"
import {BasePopup} from "components/popup"
import {FormLabel} from "components/Form"
import {ConfirmPopupChildren} from "uiKit"
import {useVisible} from "hooks"
import GooglePlacesAutocomplete from "components/GooglePlacesAutocomplete"
import ProgramSelect from "components/ProgramSelect"
import debounce from "debounce-promise"
import {KlassDropAsyncPaginate} from "components/Select"

type PageProps = {
    t: Function
}

type Props = KlassappTableProps & PageProps

const DEFAULT_SELECTED_COORDINATORS = {adminProfiles: null, adminProfileIds: null}

function ExternshipSites(props: Props) {
    const [newItemData, setNewItemData] = useState<any>(null)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [deletedItem, setDeletedItem] = useState<any>(null)
    const [selectedCoordinators, setSelectedCoordinators] = useState(DEFAULT_SELECTED_COORDINATORS)

    const confirmPopup = useVisible()
    const formPopup = useVisible()

    const {t, isLoading} = props

    useEffect(() => {
        props.dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickDuplicateMulti", func: onClickDuplicateMulti},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDeleteConfirm}
        ])
        getData()
    }, [])

    const getPageTitle = () => {
        return props.t("settings.externshipSites.externshipSite")
    }

    const getFields = () => {
        const {t} = props
        return [
            t("settings.externshipSites.name"),
            t("settings.externshipSites.address"),
            t("settings.externshipSites.city"),
            t("settings.externshipSites.state"),
            t("settings.externshipSites.country"),
            t("settings.externshipSites.postCode"),
            t("settings.externshipSites.latitude"),
            t("settings.externshipSites.longitude"),
            t("settings.externshipSites.program"),
            t("settings.externshipSites.coordinators")
        ]
    }

    const getColumns = () => {
        const {t} = props

        return [
            {
                title: t("settings.externshipSites.name"),
                field: "name",
                style: {minWidth: "200px"}
            },
            {
                title: t("settings.externshipSites.address"),
                field: "address",
                style: {minWidth: "200px"}
            },
            {
                title: t("settings.externshipSites.city"),
                field: "city",
                style: {minWidth: "200px"}
            },
            {
                title: t("settings.externshipSites.state"),
                field: "state",
                style: {minWidth: "200px"}
            },
            {
                title: t("settings.externshipSites.country"),
                field: "country",
                style: {minWidth: "200px"}
            },
            {
                title: t("settings.externshipSites.postCode"),
                field: "postCode",
                style: {minWidth: "200px"}
            },
            {
                title: t("settings.externshipSites.latitude"),
                field: "latitude",
                style: {minWidth: "200px"}
            },
            {
                title: t("settings.externshipSites.longitude"),
                field: "longitude",
                style: {minWidth: "200px"}
            },
            {
                title: t("settings.externshipSites.program"),
                field: "program",
                style: {minWidth: "200px"}
            },
            {
                title: t("settings.externshipSites.coordinators"),
                field: "coordinatorNames",
                style: {minWidth: "200px"}
            }
        ]
    }

    const getParams = () => {
        const {page, pageSize} = props
        const params = {
            range: {
                page,
                pageSize
            },
            filter: {}
        }
        return params
    }

    const getData = async () => {
        props.dispatch({isLoading: true})
        const params = getParams()

        try {
            const [{data, total}, {data: majors}] = await Promise.all([
                settingExternshipSiteService.getAllExternshipSites(params),

                majorService.getAll({
                    sort: {
                        orderBy: "name",
                        orderDir: "ASC"
                    },
                    range: {
                        page: 1,
                        pageSize: 99999999
                    }
                })
            ])
            const newData = data.map((item) => {
                return {
                    ...item,
                    programs: majors.filter((major) => (item.programId ?? []).indexOf(major.id) >= 0),
                    coordinatorNames: item.coordinatorDetails
                        ?.map((coordinatorDetail) => coordinatorDetail.fullName)
                        .join(", ")
                }
            })
            props.dispatch({data: newData, total})
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
        }
    }

    async function loadUserOptions(search: string, loadedOptions) {
        try {
            const params = {
                filters: {
                    type: "staff",
                    search
                },
                range: {
                    pageSize: 20,
                    page: Math.ceil(loadedOptions.length / 20) + 1
                }
            }
            const {data, total} = await userService.searchUsers(params)
            const staffUsers = data.map((staff) => ({
                profileId: staff.profileId,
                firstName: staff.firstName,
                lastName: staff.lastName,
                fullName: getFullName(staff)
            }))
            return {
                options: staffUsers,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onClickDeleteMulti = async () => {
        const checkedItems = props.data.filter((item) => item.isChecked)
        const payload = checkedItems.map((item) => item.id)
        try {
            props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await settingExternshipSiteService.deleteExternshipSite(payload)
            await getData()
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
        }
    }

    const onClickDuplicateMulti = async () => {
        const {data} = props
        const payload = data
            .filter((item) => item.isChecked)
            .map(({name, address, postCode, programId}) => ({
                name: `${name} (Copy)`,
                address,
                postCode,
                programId
            }))
        try {
            props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await settingExternshipSiteService.duplicateExternshipSite(payload)
            await getData()
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
        }
    }

    const onClickEdit = (editItem) => {
        setNewItemData({
            ...editItem
        })

        setSelectedCoordinators({
            adminProfiles: editItem.coordinatorDetails,
            adminProfileIds: editItem.coordinatorProfileIds
        })
        formPopup.open()
    }

    const onClickDeleteConfirm = async (deletedItem) => {
        setDeletedItem(deletedItem)
        confirmPopup.open()
    }

    const onClickDelete = async () => {
        try {
            props.dispatch({isLoading: true})
            await settingExternshipSiteService.deleteExternshipSite([deletedItem.id])
            setDeletedItem(null)
            confirmPopup.close()
            await getData()
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
        }
    }

    const onClickAddItem = () => {
        formPopup.open()
    }

    const onClosePopup = () => {
        setNewItemData(null)
        setSelectedCoordinators(DEFAULT_SELECTED_COORDINATORS)
        formPopup.close()
    }

    const onChange = (key, value) => {
        setNewItemData((prev) => ({
            ...prev,
            [key]: value
        }))
    }

    const onClickSave = async () => {
        const {id, name, address, city, state, country, postCode, latitude, longitude, programs} = newItemData
        const submitData = {
            name,
            address,
            city,
            state,
            country,
            postCode,
            latitude,
            longitude,
            programId: (programs ?? []).map((program) => program.id),
            coordinatorProfileId: selectedCoordinators.adminProfileIds ?? []
        }

        if (!newItemData.id) {
            await createExternshipSite(submitData)
        } else {
            await updateExternshipSite(id, submitData)
        }
        setNewItemData(null)
        setSelectedCoordinators(DEFAULT_SELECTED_COORDINATORS)
        formPopup.close()
        getData()
    }

    const createExternshipSite = async (data) => {
        try {
            props.dispatch({isLoading: true})
            setIsSubmitting(true)
            await settingExternshipSiteService.createExternshipSite({...data})
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
            setIsSubmitting(false)
        }
    }

    const updateExternshipSite = async (id, data) => {
        try {
            props.dispatch({isLoading: true})
            setIsSubmitting(true)
            await settingExternshipSiteService.updateExternshipSite(id, data)
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
            setIsSubmitting(false)
        }
    }

    const onSelectGoogleAddress = (selectedGoogleAddress: google.maps.places.PlaceResult) => {
        let city = null
        let state = null
        let country = null
        let postCode = null

        selectedGoogleAddress?.address_components?.forEach((el) => {
            if (el?.types?.indexOf("locality") >= 0) {
                city = el.long_name
            }
            if (el?.types?.indexOf("administrative_area_level_2") >= 0 && (city ?? "").trim() !== "") {
                city = el.long_name
            }
            if (el?.types?.indexOf("administrative_area_level_1") >= 0) {
                state = el.long_name
            }
            if (el?.types?.indexOf("country") >= 0) {
                country = el.long_name
            }
            if (el?.types?.indexOf("postal_code") >= 0) {
                postCode = el.long_name
            }
        })

        setNewItemData((prev) => ({
            ...prev,
            address: selectedGoogleAddress?.formatted_address,
            city,
            state,
            country,
            postCode,
            latitude: selectedGoogleAddress?.geometry?.location?.lat(),
            longitude: selectedGoogleAddress?.geometry?.location?.lng()
        }))
    }

    const debounceUsers = debounce(loadUserOptions, 500)

    return (
        <div className={styles.wrapper}>
            <KlassappTableHeader
                isShowAction={props.isShowTableHeaderAction}
                actions={props.tableHeaderActions}
                page={props.page}
                total={props.total}
                defaultPageSize={props.pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
            />
            <KlassappTable
                columns={props.columns}
                data={props.data}
                menuActions={props.isHideMenuActions ? [] : props.menuActions}
                isLoading={props.isLoading}
                fields={props.fields}
                allFields={props.allFields}
                isShowCheckedColumn={false}
                onClickRowItem={() => {}}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                className={styles.tableScrollHidden}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />

            {!newItemData && <AddItemCircleButton onClick={onClickAddItem} />}

            <BasePopup
                isShow={formPopup.isVisible}
                onClose={onClosePopup}
                isShowLeftSide={true}
                leftIcon="EDIT"
                className={styles.modalWrapper}>
                <div className={styles.modalRoot}>
                    <div className={styles.modalTitle}>
                        {newItemData?.id ? "Edit Externship Site Details" : "Create Externship Site"}
                    </div>

                    <div className={styles.formGroup}>
                        <FormLabel label={t("settings.externshipSites.name")} />
                        <BaseInput
                            value={newItemData?.name}
                            placeholder={t("settings.externshipSites.name")}
                            onChange={(value) => onChange("name", value)}
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <FormLabel label={t("settings.externshipSites.program")} />
                        <ProgramSelect
                            isClearable={true}
                            isActive={true}
                            isMulti={true}
                            value={newItemData?.programs ?? null}
                            onChange={(options) => onChange("programs", options)}
                        />
                    </div>

                    <div className={styles.formGroupBlock}>
                        <FormLabel label={t("externship:externship.externshipCoordinator")} />
                        <div className={styles.directorWrap}>
                            <KlassDropAsyncPaginate
                                value={selectedCoordinators.adminProfiles}
                                onChange={(options) => {
                                    setSelectedCoordinators({
                                        adminProfiles: options,
                                        adminProfileIds: options?.map((el) => el.profileId)
                                    })
                                }}
                                valueKey="profileId"
                                labelKey="fullName"
                                loadOptions={debounceUsers}
                                isMulti={true}
                            />
                        </div>
                    </div>

                    <div className={styles.formGroupBlock}>
                        <FormLabel label={t("settings.externshipSites.searchAddress")} />
                        <GooglePlacesAutocomplete onSelectGoogleAddress={onSelectGoogleAddress} />
                    </div>

                    <div className={styles.formGroupBlock}>
                        <FormLabel label={t("settings.externshipSites.address")} />
                        <BaseInput
                            value={newItemData?.address}
                            placeholder={t("settings.externshipSites.address")}
                            onChange={(value) => onChange("address", value)}
                            disabled={true}
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <FormLabel label={t("settings.externshipSites.city")} />
                        <BaseInput
                            value={newItemData?.city}
                            placeholder={t("settings.externshipSites.city")}
                            onChange={(value) => onChange("city", value)}
                            disabled={true}
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <FormLabel label={t("settings.externshipSites.state")} />
                        <BaseInput
                            value={newItemData?.state}
                            placeholder={t("settings.externshipSites.state")}
                            onChange={(value) => onChange("state", value)}
                            disabled={true}
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <FormLabel label={t("settings.externshipSites.country")} />
                        <BaseInput
                            value={newItemData?.country}
                            placeholder={t("settings.externshipSites.country")}
                            onChange={(value) => onChange("country", value)}
                            disabled={true}
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <FormLabel label={t("settings.externshipSites.postCode")} />
                        <BaseInput
                            value={newItemData?.postCode}
                            placeholder={t("settings.externshipSites.postCode")}
                            onChange={(value) => onChange("postCode", value)}
                            disabled={true}
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <FormLabel label={t("settings.externshipSites.latitude")} />
                        <BaseInput
                            value={newItemData?.latitude}
                            placeholder={t("settings.externshipSites.latitude")}
                            onChange={(value) => onChange("latitude", value)}
                            disabled={true}
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <FormLabel label={t("settings.externshipSites.longitude")} />
                        <BaseInput
                            value={newItemData?.longitude}
                            placeholder={t("settings.externshipSites.longitude")}
                            onChange={(value) => onChange("longitude", value)}
                            disabled={true}
                        />
                    </div>

                    <div className={styles.buttonWrap}>
                        <SecondaryButton
                            title={props.t("common:action.cancel")}
                            onClick={onClosePopup}
                            className={styles.cancelBtn}
                        />
                        <BaseButton
                            title={props.t("common:action.save").toUpperCase()}
                            onClick={onClickSave}
                            loading={isSubmitting}
                        />
                    </div>
                </div>
            </BasePopup>

            <ConfirmPopupChildren
                isVisible={confirmPopup.isVisible}
                onClose={confirmPopup.close}
                onConfirm={onClickDelete}
                title={t("settings.siteSupervisors.confirmation")}
                isSubmitting={isLoading}>
                <div className={styles.confirmBody}>{t("settings.siteSupervisors.deleteConfirmMessage")}</div>
            </ConfirmPopupChildren>
        </div>
    )
}

export default KlassappTableHOC(withTranslation(["settings", "common"])(ExternshipSites))
