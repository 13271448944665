import React from "react"
import {Button, ButtonProps} from "antd"
import classNames from "classnames"
import styles from "./SecondaryButton.module.css"

interface SecondaryButtonProps extends ButtonProps {
    bold?: boolean
    uppercase?: boolean
}

export function SecondaryButton(props: SecondaryButtonProps) {
    const {title, className, uppercase = true, bold = true, ...rest} = props

    return (
        <Button
            className={classNames(styles.btn, className, {
                [styles.uppercase]: uppercase,
                [styles.bold]: bold
            })}
            {...rest}>
            {title}
            {props.children}
        </Button>
    )
}
