import React from "react"
import {Row, Col} from "antd"
import {StatusCardItem} from "./StatusCardItem"
import styles from "./StatusCards.module.css"
import {useTranslation} from "react-i18next"

export function StatusCards(props) {
    const {t} = useTranslation(["student"])
    return (
        <div className={styles.wrap}>
            <Row gutter={[24, 12]}>
                <Col span={6}>
                    <StatusCardItem item={{title: t("student.workspace.new"), total: "0", cost: "0.00", isUp: true}} />
                </Col>
                <Col span={6}>
                    <StatusCardItem
                        item={{title: t("student.workspace.notBudgeted"), total: "0", cost: "0.00", isUp: false}}
                    />
                </Col>
                <Col span={6}>
                    <StatusCardItem
                        item={{title: t("student.workspace.order"), total: "0", cost: "0.00", isUp: false}}
                    />
                </Col>
                <Col span={6}>
                    <StatusCardItem
                        item={{title: t("student.workspace.disbursed"), total: "0", cost: "0.00", isUp: true}}
                    />
                </Col>
            </Row>
        </div>
    )
}
