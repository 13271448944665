import React from "react"
import classNames from "classnames"
import styles from "../StudentsTable.module.css"
import {Column} from "sections/StudentAccount/Staff/parts/Students/parts/StudentsTable/types"

interface RowItemProps<T> {
    leftColumns: Array<{field: string; title: string}>
    rightColumns: Array<{field: string; title: string}>
    visibleColumns: string[]
    columnData: {[key: string]: {width: number}}
    leftWidth: number
    record: T
    render: (column: Column<T>, record: T) => React.ReactNode
    onRowClick?: (record: T) => void
}

export function RowItem<T>({
    leftColumns,
    rightColumns,
    visibleColumns,
    columnData,
    leftWidth,
    record,
    render,
    onRowClick
}: RowItemProps<T>) {
    const handleRowClick = React.useCallback(() => onRowClick?.(record), [record, onRowClick])

    return (
        <div className={classNames(styles.rowGroup, styles.rowHeaderGroup)} onClick={handleRowClick}>
            <div className={styles.rowItem}>
                <div className={styles.stickyCell} style={{width: leftWidth}}>
                    {leftColumns.map(
                        (column) =>
                            visibleColumns.includes(column.field) && (
                                <div
                                    key={column.field}
                                    className={classNames(styles.statusGroupWrap, styles.cell)}
                                    style={{width: columnData[column.field]?.width}}>
                                    <div className={styles.itemWrap}>{render(column, record)}</div>
                                </div>
                            )
                    )}
                </div>

                <div style={{width: leftWidth, minWidth: leftWidth}} />

                {rightColumns.map(
                    (column) =>
                        visibleColumns.includes(column.field) && (
                            <div key={column.field}>
                                <div
                                    className={classNames(styles.cell)}
                                    style={{width: columnData[column.field]?.width}}>
                                    <div className={styles.itemWrap}>{render(column, record)}</div>
                                </div>
                            </div>
                        )
                )}
            </div>
        </div>
    )
}
