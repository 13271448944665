/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from "react"
import {useTranslation} from "react-i18next"
import groupBy from "lodash/groupBy"
import {Descendant} from "slate"
import {Text} from "slate"
import escapeHtml from "escape-html"
import moment from "moment"
import {BaseInput} from "components/inputs"
import {KlassDropdown} from "components/Select"
import {INITIAL_RTE_CONTENT} from "components/RichTextEditor/slate-custom-types"
import {EmailTemplate, TemplateType} from "types/settings/email-template"
import {Auth} from "types/auth"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {getFullName, handleError, getBaseUrl, getFullAddress} from "helpers"
import {useModel} from "hooks"
import {settingEmailTemplatesService, userServiceV3, studentStaffContactsServiceV3} from "services"
import styles from "./TemplateSelection.module.css"
import {Communication} from "types/communication"
import {BaseDepartmentId} from "types/departments"

type Props = {
    withEmail: boolean
    withSMS: boolean
    setValue: Function
    selectedTemplate
    setSelectedTemplate: Function
    descendants: Descendant[]
    setDescendants: Function
    setSubject: Function
    subject: string
    selectedUserId?: number
    setLoading: Function
    serializeWithoutUser: boolean
}

export function TemplateSelection(props: Props) {
    const {
        withEmail,
        withSMS,
        setValue,
        selectedTemplate,
        setSelectedTemplate,
        descendants,
        setDescendants,
        setSubject,
        subject,
        selectedUserId,
        setLoading,
        serializeWithoutUser
    } = props

    const {t} = useTranslation(["chat", "common"])
    const model = useModel()

    const userDateFormat = model.getUserDateFormat()
    const [templates, setTemplates] = useState([])
    const [userData, setUserData] = useState<any>({
        userDetails: null,
        profile: null,
        campusDetails: null,
        admissionAdvisor: null,
        finAidAdvisor: null,
        academicAdvisor: null
    })

    useEffect(() => {
        if (withEmail || withSMS) {
            loadTemplates()
        }
    }, [withEmail, withSMS])

    useEffect(() => {
        if ((withEmail || withSMS) && selectedUserId) {
            loadUserData()
        }
    }, [withEmail, withSMS, selectedUserId])

    useEffect(() => {
        if ((withEmail || withSMS) && (selectedUserId || serializeWithoutUser)) {
            serializeUserDataInTemplate()
        }
    }, [withEmail, withSMS, selectedUserId, serializeWithoutUser, JSON.stringify(descendants)])

    const loadUserData = async () => {
        try {
            setLoading(true)
            const userDetails = await getUser(selectedUserId)
            if (!userDetails) {
                return
            }

            const profile = userDetails.profiles?.find((profile) => !!profile.active)
            const campusDetails: any = profile?.campuses?.length ? profile.campuses[0] : null
            let admissionAdvisor: any = null,
                finAidAdvisor: any = null,
                academicAdvisor: any = null

            if (profile && campusDetails) {
                const [{data: allAdmissionAdvisors}, {data: allFinAidAdvisors}, {data: allAcademicAdvisor}] =
                    await Promise.all([
                        studentStaffContactsServiceV3.getStaffs({
                            range: {
                                pageSize: 1,
                                page: 1
                            },
                            filter: {
                                profileIds: [profile.id],
                                departmentId: BaseDepartmentId.Admissions,
                                search: "",
                                campusIds: [campusDetails.id]
                            }
                        }),

                        studentStaffContactsServiceV3.getStaffs({
                            range: {
                                pageSize: 1,
                                page: 1
                            },
                            filter: {
                                profileIds: [profile.id],
                                departmentId: BaseDepartmentId.FinancialAid,
                                search: "",
                                campusIds: [campusDetails.id]
                            }
                        }),
                        studentStaffContactsServiceV3.getStaffs({
                            range: {
                                pageSize: 1,
                                page: 1
                            },
                            filter: {
                                profileIds: [profile.id],
                                departmentId: BaseDepartmentId.Academics,
                                search: "",
                                campusIds: [campusDetails.id]
                            }
                        })
                    ])
                if (allAdmissionAdvisors?.length) {
                    admissionAdvisor = allAdmissionAdvisors[0]
                }

                if (allFinAidAdvisors?.length) {
                    finAidAdvisor = allFinAidAdvisors[0]
                }

                if (allAcademicAdvisor?.length) {
                    academicAdvisor = allAcademicAdvisor[0]
                }
            }

            setUserData({
                userDetails,
                profile,
                campusDetails,
                admissionAdvisor,
                finAidAdvisor,
                academicAdvisor
            })
        } catch (err) {
            handleError(err)
        } finally {
            setLoading(false)
        }
    }

    const serializeUserDataInTemplate = () => {
        let text: string = ""
        descendants?.forEach(
            (descendant) =>
                (text += serialize(
                    descendant,
                    userData.userDetails,
                    userData.profile,
                    userData.campusDetails,
                    userData.admissionAdvisor,
                    userData.finAidAdvisor,
                    userData.academicAdvisor
                ))
        )

        setValue(text)
    }

    const loadTemplates = async () => {
        try {
            let templateTypes: string[] = [TemplateType.Email, TemplateType.SMS]
            if (!withEmail) {
                templateTypes = templateTypes.filter((templateType) => templateType !== TemplateType.Email)
            }
            if (!withSMS) {
                templateTypes = templateTypes.filter((templateType) => templateType !== TemplateType.SMS)
            }
            const {data} = await settingEmailTemplatesService.list({
                range: {page: 1, pageSize: UNLIMITED_PAGE_SIZE},
                filter: {templateTypes},
                linkedObjects: false
            })

            const dataFormated = data.map(({title, ...rest}) => ({...rest, title: `${title} (${rest.code})`}))

            setTemplates(
                Object.entries(groupBy(dataFormated, "templateType")).map(([label, options]) => ({
                    label,
                    options
                }))
            )
        } catch (err) {
            handleError(err)
        }
    }

    const getUser = async (userId: number) => {
        try {
            const data = await userServiceV3.getOne(userId)
            if (data) {
                return data
            }
            return null
        } catch (error) {
            handleError(error)
        }
    }

    const onChangeTemplate = (option?: EmailTemplate) => {
        let newDescendants = option?.template ? JSON.parse(option.template) : INITIAL_RTE_CONTENT
        const newSubject: string = option?.template ? option.subject || "" : ""

        newDescendants = newDescendants.map(normalizeDescendants).filter((el) => el)

        setDescendants(newDescendants)
        setSubject(newSubject)
        setSelectedTemplate(option)
    }

    const normalizeDescendants = (node) => {
        if (node?.children?.length) {
            node.children = node.children.map(normalizeDescendants).filter((el) => el)
        }
        if (node.type === "field-input") {
            return null
        }
        return node
    }

    const serialize = (
        node,
        chatRoomUserDetails,
        profile,
        campusDetails,
        admissionAdvisor,
        finAidAdvisor,
        academicsAdvisor
    ) => {
        if (Text.isText(node)) {
            if (selectedTemplate?.editorType === Communication.EditorType.Html) {
                return node.text
            }

            let string = escapeHtml(node.text)
            if (node.bold) {
                string = `<strong>${string}</strong>`
            }
            if (node.italic) {
                string = `<i>${string}</i>`
            }
            if (node.underline) {
                string = `<u>${string}</u>`
            }
            if (node.code) {
                string = `<code>${string}</code>`
            }
            return string
        }

        const children = node.children
            .map((n) =>
                serialize(
                    n,
                    chatRoomUserDetails,
                    profile,
                    campusDetails,
                    admissionAdvisor,
                    finAidAdvisor,
                    academicsAdvisor
                )
            )
            .join("")

        let attrs = ""
        if (node.align) {
            attrs += ` align="${node.align}"`
        }

        if (node.type === "quote") {
            return `<blockquote${attrs}><p>${children}</p></blockquote>`
        } else if (node.type === "block-quote") {
            return `<blockquote${attrs}><p>${children}</p></blockquote>`
        } else if (node.type === "paragraph") {
            if (selectedTemplate?.editorType === Communication.EditorType.Html) {
                return children
            }
            return `<p${attrs}>${children}</p>`
        } else if (node.type === "link") {
            return `<a href="${escapeHtml(node.url)}" target="_blank" ${attrs}>${children}</a>`
        } else if (node.type === "heading-one") {
            return `<h1${attrs}>${children}</h1>`
        } else if (node.type === "heading-two") {
            return `<h2${attrs}>${children}</h2>`
        } else if (node.type === "heading-three") {
            return `<h3${attrs}>${children}</h3>`
        } else if (node.type === "heading-four") {
            return `<h4${attrs}>${children}</h4>`
        } else if (node.type === "heading-five") {
            return `<h5${attrs}>${children}</h5>`
        } else if (node.type === "heading-six") {
            return `<h6${attrs}>${children}</h6>`
        } else if (node.type === "image") {
            return `<img${attrs} src="${node.url}" />`
        } else if (node.type === "numbered-list") {
            return `<ol${attrs}>${children}</ol>`
        } else if (node.type === "bulleted-list") {
            return `<ul${attrs}>${children}</ul>`
        } else if (node.type === "list-item") {
            return `<li${attrs}>${children}</li>`
        } else if (node.type === "field-label") {
            if (serializeWithoutUser) {
                return `[${node.field}]`
            }

            if (
                chatRoomUserDetails[node.field] &&
                [
                    "firstName",
                    "middleName",
                    "lastName",
                    "gender",
                    "ssn",
                    "address",
                    "city",
                    "state",
                    "postalCode",
                    "country",
                    "customUserId"
                ].indexOf(node.field) >= 0
            ) {
                return chatRoomUserDetails[node.field]
            } else if (chatRoomUserDetails.birthDate && node.field === "birthDate") {
                return moment(chatRoomUserDetails.birthDate).format(userDateFormat)
            } else if (node.field === "fullName") {
                return getFullName(chatRoomUserDetails)
            } else if (chatRoomUserDetails?.emails && node.field === "email") {
                return chatRoomUserDetails.emails?.filter((email) => !!email.isPrimary)?.map((email) => email.email)
            } else if (chatRoomUserDetails?.phones && node.field === "phone") {
                return chatRoomUserDetails.phones?.filter((phone) => !!phone.isPrimary)?.map((phone) => phone.phone)
            } else if (profile?.campus && node.field === "campuses") {
                return profile.campus
            } else if (campusDetails?.email && node.field === "campusEmail") {
                return campusDetails.email
            } else if (campusDetails?.phone && node.field === "campusPhone") {
                return campusDetails.phone
            } else if (campusDetails && node.field === "campusAddress") {
                return getFullAddress(campusDetails)
            } else if (profile?.currentMajor?.name && node.field === "program") {
                return profile.currentMajor.name
            } else if (
                profile?.customProfileId &&
                ["customProfileId", "enrollmentStatus", "efc", "dependencyStatus", "awardYear"].indexOf(node.field) >= 0
            ) {
                return profile.customProfileId
            } else if (profile?.startDate && node.field === "start_date") {
                return profile.startDate
            } else if (profile?.currentTerm?.name && node.field === "term") {
                return profile.currentTerm.name
            } else if (profile?.startingTerm?.name && node.field === "startTerm") {
                return profile.startingTerm.name
            } else if (chatRoomUserDetails?.collegeDetails[0]?.graduationDate && node.field === "graduationDate") {
                return moment(chatRoomUserDetails.collegeDetails[0].graduationDate).format(userDateFormat)
            } else if (node.field === "documentsFolder") {
                return `<a target="_blank" href="${getBaseUrl()}/users/${chatRoomUserDetails.id}?tab=${
                    Auth.UserDetailTab.Documents
                }">Documents Folder</a>`
            } else if (admissionAdvisor && node.field === "admissionAdvisor") {
                return `${getFullName(admissionAdvisor)}`
            } else if (admissionAdvisor && node.field === "admissionAdvisorEmail") {
                return admissionAdvisor.advisorEmail
            } else if (admissionAdvisor && node.field === "admissionAdvisorPhone") {
                return admissionAdvisor.advisorPhone
            } else if (finAidAdvisor && node.field === "finAidAdvisor") {
                return `${getFullName(finAidAdvisor)}`
            } else if (finAidAdvisor && node.field === "finAidAdvisorEmail") {
                return finAidAdvisor.advisorEmail
            } else if (finAidAdvisor && node.field === "finAidAdvisorPhone") {
                return finAidAdvisor.advisorPhone
            } else if (academicsAdvisor && node.field === "academicAdvisor") {
                return `${getFullName(academicsAdvisor)}`
            } else if (academicsAdvisor && node.field === "academicAdvisorEmail") {
                return academicsAdvisor.advisorEmail
            } else if (academicsAdvisor && node.field === "academicAdvisorPhone") {
                return academicsAdvisor.advisorPhone
            }
        } else if (node.type === "field-input") {
            // do nothing
        } else if (node.type === "embed-video") {
            return `<iframe src="${node.videoId}" allowFullScreen></iframe>`
        } else {
            return children
        }
    }

    return (
        <>
            {(withEmail || withSMS) && (
                <>
                    <div className={styles.subjectWrapper}>
                        <label>{t("chat.selectTemplate")}</label>
                        <KlassDropdown
                            {...props}
                            placeholder={t("common:selectField.placeholder")}
                            options={templates}
                            defaultOptions={true}
                            labelKey="title"
                            valueKey="emailTemplateId"
                            onChange={onChangeTemplate}
                            value={selectedTemplate}
                            className={styles.subjectWrapperDropdown}
                        />
                    </div>
                    {withEmail && (
                        <div className={styles.subjectWrapper}>
                            <label>{t("chat.emailSubject")}</label>
                            <BaseInput value={subject} onChange={setSubject} />
                        </div>
                    )}
                </>
            )}
        </>
    )
}
