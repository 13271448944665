import {convertTZ} from "helpers"
import {forEach, isEmpty} from "lodash"
import moment, {Moment} from "moment"
import {ScheduleEvent} from "types/terms"
import {Major} from "types/major"

const parseTimeToDate = (time: string): Moment => {
    const [hour, minute, second] = time.split(":")
    return moment()
        .startOf("date")
        .set("hour", +hour)
        .set("minute", +minute)
        .set("second", +second)
}

export const generateCourseEvents = (
    events: Major.EventSequence[],
    eventData: any,
    totalCourseDay: number,
    // if in major version, we don't sent workdays
    workdays?: any[],
    additionalData?: any
) => {
    forEach(events, (event) => {
        let startDate = null
        let endDate = null
        const groupEventIds = events.filter((item) => item.daysOfCourse === event.daysOfCourse).map((event) => event.id)
        if (!isEmpty(workdays) && event.startAts) {
            startDate = moment(event.startAts)
            endDate = moment(event.endAts)
        } else {
            startDate = parseTimeToDate(event.startTime)
            endDate = parseTimeToDate(event.endTime)
            if (endDate.isBefore(startDate)) {
                endDate = endDate.add(1, "day")
            }
        }
        const hour = startDate.get("hour")
        const daysOfCourse = (event.daysOfCourse || "").split(",").map((item) => +item)
        event.dayCount = daysOfCourse.length
        event.startTime = startDate.format()
        event.endTime = endDate.format()
        forEach(daysOfCourse, (day) => {
            const startsAt = startDate.clone()
            let nextHour = hour
            while (true) {
                if (endDate.isSameOrBefore(startsAt)) {
                    break
                }
                const isSameDate =
                    isEmpty(workdays) || moment(startsAt).isSame(moment(workdays[day + totalCourseDay - 1]), "date")
                const eventKey = `${day + totalCourseDay}_${startsAt.format("HH:mm")}`
                nextHour += 1
                startsAt.set("hour", nextHour).set("minute", 0)
                if (isSameDate) {
                    eventData[eventKey] = [
                        ...(eventData[eventKey] || []),
                        {...event, ...additionalData, isLastEvent: endDate.isSameOrBefore(startsAt), groupEventIds}
                    ]
                }
            }
        })
    })
}

export const generateCalendarEventsFromScheduleEvents = (events: ScheduleEvent[], additionalData?: any) => {
    return events.map((event) => {
        const {object_id, start_at_utc, duration, description, json_data, start_at_timezone} = event as any
        const startAts = moment(convertTZ(new Date(start_at_utc), start_at_timezone))
        const endAts = startAts.clone().add(duration, "seconds")
        return {
            id: object_id,
            type: json_data.eventType,
            daysOfCourse: json_data.daysOfCourse,
            method: json_data.eventMethod,
            scheduleId: json_data.schedule_id,
            startAts,
            endAts,
            startTime: startAts.format("HH:mm"),
            endTime: endAts.format("HH:mm"),
            notes: description,
            submoduleId: json_data.sub_module_id,
            submoduleTitle: null,
            ...additionalData
        }
    })
}
