/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react"
import cx from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import {BasePopup} from "components/popup"
import {BaseTimePicker} from "components/DateTimePicker"
import {Icon, IconType} from "components/Icon"
import {BaseButton} from "components/buttons"
import {FormLabel} from "components/Form"
import {BaseInput} from "components/inputs"
import {AcademicInstructional} from "types/academicInstructional"
import {attendanceService} from "services"
import {convertTZ, handleError, isInvalidValue, toastError} from "helpers"
import {LessonInfo, StudentInfo} from "../Common"
import styles from "./CheckAttendancePopup.module.css"
import {cloneDeep} from "lodash"
import {AttendanceEventType} from "types/attendance"
import moment from "moment-timezone"
import {getTimeZones} from "@vvo/tzdb"
import {useTranslation} from "react-i18next"
import {useModel} from "hooks"

type Props = {
    isShow: boolean
    lessonDetail: AcademicInstructional.LessonDetail
    students: AcademicInstructional.LessonStudent[]
    onClose: () => void
    timezone: string
    onCheckAttendanceSuccess: () => void
}

export function CheckAttendancePopup(props: Props) {
    const [checkAttendanceStatus, setCheckAttendanceStatus] = useState(null)
    const [checkedStudentIds, setCheckedStudentIds] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const {isShow, onClose, lessonDetail, students, onCheckAttendanceSuccess, timezone} = props
    const [listStudent, setListStudent] = useState<AcademicInstructional.LessonStudent[]>(cloneDeep(students))
    const [data, setData] = useState({
        selectTime: moment(),
        message: "",
        selectedTimezone: timezone
    })
    const model = useModel()
    const {t} = useTranslation(["calendar", "common"])
    const timeFormat = model.getUserTimeFormat()
    const dateFormat = model.getUserDateFormat()
    const [errors, setErrors] = useState({selectTime: undefined})

    const onChangeCheckAttendanceStatus = (status) => {
        if (status === AcademicInstructional.CheckAttendanceStatus.CheckOut) {
            const newListStudent = listStudent.map((item) => {
                const existCheckinEvent = item.events.find((item) => item.eventType === AttendanceEventType.Checkin)
                if (!existCheckinEvent) {
                    item.disabled = true
                } else {
                    item.disabled = false
                }
                return item
            })
            setCheckedStudentIds([])
            setListStudent(newListStudent)
        } else {
            const newListStudent = listStudent.map((item) => {
                item.disabled = false
                return item
            })
            setListStudent(newListStudent)
        }
        setCheckAttendanceStatus(status)
    }

    const onToggleCheck = (userId) => {
        if (checkedStudentIds.includes(userId)) {
            const newCheckedStudentIds = checkedStudentIds.filter((item) => item !== userId)
            setCheckedStudentIds(newCheckedStudentIds)
        } else {
            const newCheckedStudentIds = [...checkedStudentIds]
            newCheckedStudentIds.push(userId)
            setCheckedStudentIds(newCheckedStudentIds)
        }
    }

    const onChangeMessage = useCallback((text) => {
        setData((prev) => ({
            ...prev,
            message: text
        }))
    }, [])

    useEffect(() => {
        setListStudent(cloneDeep(students))
    }, [students])

    useEffect(() => {
        if (timezone) {
            setData((prev) => ({...prev, selectedTimezone: timezone}))
        }
    }, [timezone])

    const onSelectAll = () => {
        if (students.length === checkedStudentIds.length) {
            setCheckedStudentIds([])
        } else {
            const newCheckedStudentIds = students.map((item) => {
                return item.student.userId
            })
            setCheckedStudentIds(newCheckedStudentIds)
        }
    }

    const onSelectData = useCallback((value, key) => {
        const newErrors = {...errors}
        if (!isInvalidValue(value)) {
            delete newErrors[key]
        }
        setErrors(newErrors)
        setData((prev) => ({
            ...prev,
            [key]: value
        }))
    }, [])

    const onClickCheck = async () => {
        if (!data.selectTime) {
            toastError("Please select time.")
            return
        }

        if (!checkAttendanceStatus) {
            toastError("Please select one of these options (Attendance Check or Checkout)")
            return
        }

        if (checkedStudentIds.length === 0) {
            toastError("Please select at least one student.")
            return
        }
        try {
            setIsSubmitting(true)
            let createdAt: moment.Moment = moment(data.selectTime).tz(timezone, true).utc()
            const studentSelected = students.filter((student) => checkedStudentIds.includes(student.student.userId))
            const studentNotSelected = students.filter((student) => !checkedStudentIds.includes(student.student.userId))
            const attendanceSuccess = studentSelected.map((student) => ({
                lessonId: lessonDetail.id,
                profileId: student.student.profileId,
                attendanceType: "manual",
                message: data.message ?? "",
                eventType: AttendanceEventType.BreakEnd,
                status: "success",
                createdAt: createdAt.format()
            }))
            const attendanceFailed = studentNotSelected.map((student) => ({
                lessonId: lessonDetail.id,
                profileId: student.student.profileId,
                attendanceType: "manual",
                message: data.message ?? "",
                eventType: AttendanceEventType.BreakStart,
                status: "success",
                createdAt: createdAt.format()
            }))

            await attendanceService.checkAttendanceManually([...attendanceSuccess, ...attendanceFailed])
            setCheckedStudentIds([])
            setCheckAttendanceStatus(null)
            onCheckAttendanceSuccess()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitting(false)
        }
    }

    const renderCheckInfoItem = (status) => {
        let title = ""
        let icon: IconType = "CHECKOUT_FILL"
        switch (status) {
            case AcademicInstructional.CheckAttendanceStatus.CheckIn:
                title = "CheckIn"
                icon = "CHECKIN_FILL"
                break
            case AcademicInstructional.CheckAttendanceStatus.AttendanceCheck:
                title = "Attendance Check"
                icon = "CHECK_CIRCLE"
                break
            case AcademicInstructional.CheckAttendanceStatus.CheckOut:
                title = "Checkout"
                icon = "CHECKOUT_FILL"
                break
            default:
                break
        }
        const isActive = checkAttendanceStatus === status
        return (
            <div
                className={cx(styles.checkInfoItem, {
                    [styles.checkInfoItem__active]: isActive
                })}
                onClick={() => onChangeCheckAttendanceStatus(status)}>
                <span
                    className={cx(styles.checkInfoItem__title, {
                        [styles.checkInfoItem__title__active]: isActive
                    })}>
                    {title}
                </span>
                <Icon icon={icon} className={cx(styles.checkInfoItem__icon)} color={isActive ? "#fff" : "#1E90FF"} />
            </div>
        )
    }

    const timezoneData = moment.tz(timezone).utcOffset()
    const offset = timezoneData / 60

    return (
        <BasePopup isShow={isShow} onClose={onClose} width="70vw" leftIcon="PERSON_DONE">
            <div className={styles.wrap}>
                <p className={styles.title}>Check Attendance</p>
                <PerfectScrollbar className={styles.body}>
                    <LessonInfo lessonDetail={lessonDetail} />
                    <div className={styles.lessonTime}>
                        <span>
                            {moment(convertTZ(new Date(lessonDetail.startsAt), timezone)).format(
                                `${dateFormat}, ${timeFormat}`
                            )}{" "}
                            - {moment(convertTZ(new Date(lessonDetail.endsAt), timezone)).format(timeFormat)}
                        </span>
                    </div>
                    <div className={styles.lessonSettingWrap}>
                        <div className={styles.lessonSetting__startTime}>
                            <BaseTimePicker
                                value={data.selectTime}
                                onChange={(newValue) => onSelectData(newValue, "selectTime")}
                                error={errors.selectTime}
                                placeholder="Select time"
                                className={styles.selectTime}
                                format={timeFormat}
                            />
                            <div className={styles.zone}>
                                <span>
                                    {moment.tz(timezone).format("z")}{" "}
                                    {`UTC${offset > 0 ? `+${Math.abs(offset)}` : `-${Math.abs(offset)}`}`}
                                </span>
                            </div>
                        </div>
                        <div className={styles.checkInfoWrap}>
                            {/* {renderCheckInfoItem(AcademicInstructional.CheckAttendanceStatus.CheckIn)} */}
                            {renderCheckInfoItem(AcademicInstructional.CheckAttendanceStatus.AttendanceCheck)}
                            {renderCheckInfoItem(AcademicInstructional.CheckAttendanceStatus.CheckOut)}
                        </div>
                        <div>
                            <FormLabel label="Current lesson content (optional)" />
                            <BaseInput placeholder={"Content"} onChange={onChangeMessage} value={data.message} />
                        </div>
                    </div>
                    <div className={styles.listStudents}>
                        <div className={styles.listStudentHeader}>
                            <div className={styles.totalStudent}>
                                <span className={styles.totalStudent__count}>{students.length}</span>
                                <span className={styles.totalStudent__title}>Students</span>
                            </div>
                            <div onClick={onSelectAll} className={styles.rightHeader}>
                                <span className={styles.rightHeader__title}>Attendance</span>
                                <Icon icon="SELECT_ALL" />
                            </div>
                        </div>
                        <div className={styles.studentsBody}>
                            {listStudent.map((student) => (
                                <StudentInfo
                                    student={student}
                                    disabled={!!student.disabled}
                                    key={student.student.userId}
                                    checkedStudentIds={checkedStudentIds}
                                    onToggleCheck={onToggleCheck}
                                />
                            ))}
                        </div>
                    </div>
                </PerfectScrollbar>
                <div className={styles.action}>
                    <BaseButton title="Check" variant="primary" onClick={onClickCheck} loading={isSubmitting} />
                </div>
            </div>
        </BasePopup>
    )
}
