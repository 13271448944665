import React from "react"
import styles from "./StudentProgress.module.css"

type Props = {
    title?: string
    total: number
    value: number
    unit?: string
}

export function TotalProgressCount(props: Props) {
    const {title, total, value, unit} = props
    const percent = total ? Math.round((value / total) * 100) : 0
    const percentNumber = (percent || 0) <= 100 ? percent : 100
    return (
        <div className={styles.progressWrap}>
            <p className={styles.progressTitle}>{title ?? "Total Progress"}</p>
            <div className={styles.progressBarWrap} style={{backgroundColor: "var(--black-100)"}}>
                <div
                    className={styles.progressBarValue}
                    style={{width: `${percentNumber || 0}%`, backgroundColor: "var(--black-300)"}}>
                    <p className={styles.progressBarNumber}>
                        {value || 0}
                        {unit}
                    </p>
                </div>
            </div>
        </div>
    )
}
