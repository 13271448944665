import {get} from "lodash"
import BaseService from "./Base"
import {Settings} from "types/settings"

type GetCustomFieldParams = {
    filter?: Partial<{customFieldIds: number[]; modules: Settings.CustomFieldModule[]}>
    range?: {page: number; pageSize: number}
}

type CreateCustomField = {
    label: string
    fieldType: Settings.CustomFieldType
    module: Settings.CustomFieldModule
}

type EditCustomField = {
    customFieldId: number
    label?: string
    fieldType?: Settings.CustomFieldType
    module?: Settings.CustomFieldModule
}

export default class FieldsService extends BaseService {
    async getAll(params = {}) {
        const response = await this.post("/v1/settings/labels/list", params)
        return get(response, "data", [])
    }

    async edit(params) {
        const response = await this.post("/v1/settings/labels/update", params)
        return get(response, "data", [])
    }

    async getCustomFields(params: GetCustomFieldParams): Promise<{data: Settings.CustomField[]; total: number}> {
        const response = await this.post("/v1/settings/custom-fields/list", params)
        return get(response, "data", [])
    }

    async createCustomField(payload: CreateCustomField): Promise<{data: Settings.CustomField}> {
        const response = await this.post("/v1/settings/custom-fields/create", payload)
        return get(response, "data", [])
    }

    async editCustomField(payload: EditCustomField): Promise<{data: Settings.CustomField}> {
        const response = await this.post("/v1/settings/custom-fields/edit", payload)
        return get(response, "data", [])
    }

    async deleteCustomField(customFieldIds: number[]): Promise<{data: Settings.CustomField}> {
        const response = await this.post("/v1/settings/custom-fields/delete", {customFieldIds})
        return get(response, "data", [])
    }
}
