import React from "react"
import moment from "moment"
import {useTranslation} from "react-i18next"
import {TaskType} from "types/tasks"
import {getFullName} from "helpers"
import styles from "./TaskDetailHeader.module.css"
import {useModel} from "hooks/useModel"

export function TaskDetailHeader(props) {
    const {t} = useTranslation(["tasks"])
    const {type, taskData} = props
    const model = useModel()
    const dateFormat = model.getUserDateFormat()

    const renderTitleByType = () => {
        switch (type) {
            case TaskType.DIGITAL_DOCUMENT:
                return t("taskDetail.type.digitalDocument")
            case TaskType.SCAN_UPLOAD:
                return t("taskDetail.type.scanUpload")
            case TaskType.FROM_LIBRARY:
                return t("taskDetail.type.library")
            case TaskType.FORM:
                return t("taskDetail.type.form")
            case TaskType.CUSTOM:
                return t("taskDetail.type.custom")
            default:
                return t("taskDetail.type.digitalDocument")
        }
    }

    const renderCreateTaskHeader = () => {
        return (
            <div className={styles.wrap}>
                <p className={styles.taskDetail}>{t("taskDetail.taskDetail")}</p>
                <p className={styles.title}>{renderTitleByType()}</p>
            </div>
        )
    }

    const renderTaskDetailHeader = () => {
        return (
            <div className={styles.studentViewHeader}>
                <div className={styles.studentViewHeaderItem}>
                    <p className={styles.studentViewHeaderItem__title}>Assigned By</p>
                    <p className={styles.studentViewHeaderItem__value}>{getFullName(taskData.createdByUser)}</p>
                </div>
                <div className={styles.studentViewHeaderItem}>
                    <p className={styles.studentViewHeaderItem__title}>Requested On</p>
                    <p className={styles.studentViewHeaderItem__value}>
                        {moment(taskData.createdAt).format(dateFormat)}
                    </p>
                </div>
            </div>
        )
    }

    if (taskData.id) {
        return renderTaskDetailHeader()
    }
    return renderCreateTaskHeader()
}
