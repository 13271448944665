import React from "react"
import Select, {components} from "react-select"
import {ReactComponent as Arrow} from "./Icon_Chevron_Down.svg"

const customStyle = {
    control: (provided, state) => ({
        ...provided,

        borderRadius: "6px",
        borderColor: "transparent",
        border: "none",
        boxShadow: "0 4px 24px 0 rgba(48, 49, 51, 0.1)",
        paddingLeft: "8px",
        width: "290px"
    }),

    container: (provided) => ({
        ...provided,
        height: "40px"
    }),

    indicatorSeparator: (base) => ({
        ...base,
        display: "none"
    }),

    dropdownIndicator: (provided, state) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        width: "40px",
        height: "48px",
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
        justifyContent: "center",
        cursor: "pointer"
    }),

    placeholder: (provided) => ({
        ...provided,
        fontSize: "18px",
        color: "var(--black-200)"
    }),
    menu: (styles) => ({
        ...styles,
        width: "290px"
    })
}

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <Arrow />
        </components.DropdownIndicator>
    )
}

export function BaseSelect(props) {
    const customProps = {styles: customStyle, ...props}
    return <Select autosize={false} components={{DropdownIndicator}} {...customProps} />
}
