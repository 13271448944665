/* eslint-disable react-hooks/exhaustive-deps */
///* eslint-disable no-param-reassign */
import {useCallback, useEffect, useRef, useState} from "react"
import ChatWsV2 from "../chatWsV2"
import {Communication} from "types/communication"

const userTypingTimeout = 5000

export default function useChatTypings(roomId: number) {
    const [typings, setTypings] = useState<{[key: number]: any}>({})
    const clearTypingsTimeoutIds = useRef<any>({})

    useEffect(() => {
        const typingUnsubscribe = ChatWsV2.registerTyingEvent(roomId, (data) => {
            handleTypingEvent(data)
        })

        return () => {
            typingUnsubscribe()
        }
    }, [roomId])

    const handleTypingEvent = useCallback((data: Communication.TypingEvent) => {
        const {fromUserId} = data
        setUserTyping(fromUserId)
    }, [])

    function setUserTyping(userId: number) {
        const currentTimeout = clearTypingsTimeoutIds.current[`id-${userId}`]
        if (currentTimeout) {
            clearTimeout(currentTimeout)
        }
        setTypings((prev) => ({
            ...prev,
            [userId]: true
        }))
        const timeoutId = setTimeout(() => {
            setTypings((prev) => {
                delete prev[userId]
                return {
                    ...prev
                }
            })
        }, userTypingTimeout)
        clearTypingsTimeoutIds.current[`id-${userId}`] = timeoutId
    }

    return {
        typings
    }
}
