import {AxiosResponse} from "axios"
import {ApiListResponse, ApiResponse} from "types/api"
import {ListTermProgramCoaPayload, TermProgramCoaDetails, TermProgramCoaPayloadForUpdate} from "types/coa"
import BaseService from "./Base"

export default class TermProgramCoaService extends BaseService {
    getOne = async (termProgramId: number): Promise<TermProgramCoaDetails | null> => {
        const {data: response}: AxiosResponse<ApiListResponse<TermProgramCoaDetails[]>> = await this.post(
            "/v1/term-program-coa/list",
            {
                filter: {
                    termProgramIds: [termProgramId]
                },
                linkedEntities: true
            }
        )

        if (!response.success) {
            throw response
        }

        return response.data[0] ?? null
    }

    list = async (payload: ListTermProgramCoaPayload): Promise<TermProgramCoaDetails[]> => {
        const {data: response}: AxiosResponse<ApiListResponse<TermProgramCoaDetails[]>> = await this.post(
            "/v1/term-program-coa/list",
            payload
        )

        if (!response.success) {
            throw response
        }

        return response.data
    }

    edit = async (payload: TermProgramCoaPayloadForUpdate) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/term-program-coa/edit", payload)

        if (!response.success) {
            throw response
        }
    }
}
