/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useEffect, useState} from "react"
import debounce from "lodash/debounce"

export function useDebouncedValue<T>(nextValue: T, debounceMs: number = 500) {
    const [value, setValue] = useState<T>(nextValue)

    const setValueDebounced = useCallback(
        debounce((nextValue: T) => {
            setValue(nextValue)
        }, debounceMs),
        [debounceMs]
    )

    useEffect(() => {
        setValueDebounced(nextValue)
    }, [nextValue, setValueDebounced])

    return value
}
