import React from "react"
import {BasePopup} from "components/popup"
import {getFullName} from "helpers"
import styles from "./RemindPopup.module.css"

export function RemindPopup(props) {
    const {isShow, onClose, userInfo, taskData} = props

    return (
        <BasePopup isShow={isShow} onClose={onClose} width="60vw" leftIcon="BULB">
            <div className={styles.wrap}>
                <p className={styles.title}>Tasks reminder</p>
                <div className={styles.body}>
                    <p className={styles.userInfo}>
                        {getFullName(userInfo || {})}{" "}
                        <span className={styles.userInfoId}>{userInfo?.userId || userInfo?.id}</span>
                    </p>
                    <p className={styles.body__title}>Has been reminded about task</p>
                    <div className={styles.taskInfo}>
                        <p className={styles.taskName}>{taskData?.name}</p>
                        <p className={styles.taskCode}>{taskData?.code}</p>
                    </div>
                </div>
            </div>
        </BasePopup>
    )
}
