import moment, {Moment} from "moment"
import {Day} from "types/calendar"

export function formatDate(date, format = "MM/DD/YYYY") {
    return moment(date).format(format)
}

export function formatAmOrPM(date: Date): string {
    let hours = date.getHours()
    let minutes = date.getMinutes().toString()
    const amOrPm = hours >= 12 ? "PM" : "AM"
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = Number(minutes) < 10 ? "0" + minutes : minutes
    return hours + ":" + minutes + " " + amOrPm
}

export function format24Hours(amPMTime: string): string {
    const amPm = amPMTime.toLowerCase().match(/pm/g)
    let result = amPMTime.toLowerCase().replace("am", "").replace("pm", "")
    if (amPm) {
        let hour = amPMTime.substring(0, amPMTime.indexOf(":"))
        hour = (parseInt(hour) + 12).toString()
        result = `${hour}:${result.substring(result.indexOf(":") + 1, result.length - 1)}`
        result = result.startsWith("24") ? result.replace("24", "12") : result
    } else {
        result = result.startsWith("12") ? result.replace("12", "00") : result
    }
    return result.trim()
}

export function getGMTLiteral(date: Date): string {
    const time = date.toString()
    const gmtIndex = time.indexOf("GMT")
    return time.substring(gmtIndex, time.toString().length)
}

export function formatRateListDate(day: Date) {
    const ye = new Intl.DateTimeFormat("en", {year: "numeric"}).format(day)
    const mo = new Intl.DateTimeFormat("en", {month: "numeric"}).format(day)
    const da = new Intl.DateTimeFormat("en", {day: "2-digit"}).format(day)
    return `${ye}-${mo}-${da}`
}

export function getDateInWeekString(date) {
    const dateInWeekIndex = moment(date).isoWeekday()
    switch (dateInWeekIndex) {
        case Day.Monday:
            return "Monday"
        case Day.Tuesday:
            return "Tuesday"
        case Day.Wednesday:
            return "Wednesday"
        case Day.Thursday:
            return "Thursday"
        case Day.Friday:
            return "Friday"
        case Day.Saturday:
            return "Saturday"
        case Day.Sunday:
            return "Sunday"
        default:
            return ""
    }
}

export function formatMessageDate(date) {
    return moment(date).format("dddd, MMMM DD, YYYY hh:mm A")
}

export function displaySince(date, format: string) {
    if (moment().diff(moment(date), "days") > 7) {
        return moment(date).format(format)
    }

    return moment(date).fromNow()
}

export function displaySinceWithDayInWeek(date, format: string) {
    if (moment().diff(moment(date), "days") > 7) {
        return moment(date).format(format)
    }
    if (moment().isSame(date, "day")) {
        return "Today"
    }

    return getDateInWeekString(date)
}

export function generateWorkdays(
    dayCount: number,
    startDate: string | Moment,
    dayOfWeeks: Day[],
    holidays: any[]
): Moment[] {
    const workdaysTerm = []
    for (let i = 0; ; i += 1) {
        if (workdaysTerm.length === dayCount) {
            break
        }
        const day = moment(startDate).add(i, "day")
        const weekday = day.isoWeekday()
        const isHoliday = holidays.some((holiday) => {
            const start = moment(toDateOnly(holiday.start_at_utc)).tz(holiday.start_at_timezone, true)
            const end = start.clone().add(holiday.duration, "seconds")
            return (
                moment(toDateOnly(day.format())).tz(holiday.start_at_timezone, true).isBetween(start, end) ||
                moment(toDateOnly(day.format())).tz(holiday.start_at_timezone, true).endOf("date").isBetween(start, end)
            )
        })
        if (dayOfWeeks.includes(weekday) && !isHoliday) {
            workdaysTerm.push(day)
        }
    }
    return workdaysTerm
}

export function getDaysBetween(startDate: Date, endDate: Date): Date[] {
    let arr: Date[] = []
    for (let currentDate = startDate; currentDate <= endDate; currentDate.setDate(currentDate.getDate() + 1)) {
        arr.push(new Date(currentDate))
    }
    return arr
}

export const toHoursMinutes = (durationInMinutes: number) => {
    const result: string[] = []
    const hours = Math.floor(durationInMinutes / 60)
    const minutes = Math.floor(durationInMinutes) % 60

    if (hours) {
        result.push(`${hours}h`)
    }
    if (minutes || result.length === 0) {
        result.push(`${minutes}m`)
    }
    return result.join(" ")
}

export const toDateOnly = (datestr: string | Date) => {
    const dt = new Date(datestr)
    return new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)
}
