/* eslint-disable react-hooks/exhaustive-deps */
import {Col, Row} from "antd"
import styles from "./StageMapping.module.css"
import {FormLabel} from "components/Form"
import {getFieldLabel, handleError, toastSuccess} from "helpers"
import {useModel} from "hooks"
import {Settings} from "types/settings"
import {BaseButton, BaseLoading} from "components"
import {useEffect, useState} from "react"
import {AcademicStageType, Retention} from "types/retention"
import {retentionService} from "services"
import {NewStudentStatusSelectFilter} from "components/NewStudentStatusSelect"

export function StageMapping() {
    const model = useModel()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState(false)
    const [academicStages, setAcademicStages] = useState<Retention.AcademicStage>({})

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        try {
            setIsLoading(true)
            const stage = await retentionService.getAcademicStage()
            if (stage) {
                setAcademicStages(stage)
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const onChangeData = (key, value) => {
        academicStages[key] = value
        setAcademicStages({...academicStages})
    }

    const onSubmit = async () => {
        try {
            setIsSubmitting(true)
            let payload: any = {}
            Object.entries(academicStages).forEach(([stageName, statuses]) => {
                payload[stageName] = statuses.map((x) => x.statusId)
            })
            await retentionService.updateAcademicStage(payload)
            toastSuccess("Update mapping successfully.")
        } catch (e) {
            handleError(e)
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <div>
            <div className={styles.title}>Statuses Mapping</div>
            <Row gutter={[16, 16]} className={styles.statusWrap}>
                <Col span={12}>
                    <div>
                        <FormLabel
                            label={getFieldLabel(model, Settings.AcademicLabel.AcademicStage1, "Active Students")}
                            className={styles.fieldName}
                        />
                        <NewStudentStatusSelectFilter
                            isMulti
                            value={academicStages[AcademicStageType.Active]}
                            onChange={(option: any) => onChangeData(AcademicStageType.Active, option ?? [])}
                        />
                    </div>
                </Col>
                <Col span={12}>
                    <div>
                        <FormLabel
                            label={getFieldLabel(model, Settings.AcademicLabel.AcademicStage2, "In probation")}
                            className={styles.fieldName}
                        />
                        <NewStudentStatusSelectFilter
                            isMulti
                            value={academicStages[AcademicStageType.Probation]}
                            onChange={(option: any) => onChangeData(AcademicStageType.Probation, option ?? [])}
                        />
                    </div>
                </Col>
                <Col span={12}>
                    <div>
                        <FormLabel
                            label={getFieldLabel(model, Settings.AcademicLabel.AcademicStage3, "LOA")}
                            className={styles.fieldName}
                        />
                        <NewStudentStatusSelectFilter
                            isMulti
                            value={academicStages[AcademicStageType.LOA]}
                            onChange={(option: any) => onChangeData(AcademicStageType.LOA, option ?? [])}
                        />
                    </div>
                </Col>
                <Col span={12}>
                    <div>
                        <FormLabel
                            label={getFieldLabel(model, Settings.AcademicLabel.AcademicStage4, "Graduate")}
                            className={styles.fieldName}
                        />
                        <NewStudentStatusSelectFilter
                            isMulti
                            value={academicStages[AcademicStageType.Graduate]}
                            onChange={(option: any) => onChangeData(AcademicStageType.Graduate, option ?? [])}
                        />
                    </div>
                </Col>
                <Col span={24}>
                    <div className={styles.doneBtn}>
                        <BaseButton title="Save" loading={isSubmitting} onClick={onSubmit} className={styles.saveBtn} />
                    </div>
                </Col>
            </Row>
            <BaseLoading isShow={isLoading} />
        </div>
    )
}
