import React from "react"
import styles from "./CloseButton.module.css"
import {ReactComponent as Icon} from "./close.svg"
type ButtonType = "primary" | "secondary"

interface Props {
    onClick: (event) => void
    type?: ButtonType
}

export function CloseButton({onClick, type}: Props) {
    return (
        <button onClick={onClick} className={styles.button}>
            <Icon className={styles.icon} width="16" height="16" />
        </button>
    )
}
