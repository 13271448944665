import * as XLSX from "xlsx-js-style"
import {ExportExcelPayload} from "./export-table"

export type ExportXLSXPayload = {
    filename: string
    sheets: XLSXSheet[]
}

export type XLSXSheet = {
    name?: string
    heading?: XLSXCell[][]
    colsWidth?: number[]
    data: any[]
}

export type XLSXCell = {
    v: string // cell value
    t?: string // cell data type
    s?: any // cell style
}

export const exportExcel = ({columns, rows, worksheet = "Worksheet", filename}: ExportExcelPayload) => {
    const style = {font: {bold: true}}
    const sheetHeader: XLSXCell[] = columns.map((x) => {
        return {
            v: x,
            s: style
        }
    })

    const heading: XLSXCell[][] = [sheetHeader]
    const sheets: XLSXSheet[] = [
        {
            name: worksheet,
            heading,
            data: rows
        }
    ]

    exportXlsx({
        filename,
        sheets
    })
}

export const exportXlsx = (payload: ExportXLSXPayload) => {
    const {filename, sheets} = payload
    const workbook = XLSX.utils.book_new()

    sheets.forEach((x, index) => {
        const worksheet = XLSX.utils.json_to_sheet([])
        if (x.heading) {
            XLSX.utils.sheet_add_aoa(worksheet, x.heading)
        }
        if (x.colsWidth) {
            worksheet["!cols"] = x.colsWidth.map((y) => {
                return {
                    width: y
                }
            })
        }

        XLSX.utils.sheet_add_json(worksheet, x.data, {
            origin: `A${(x.heading?.length || 0) + 1}`,
            skipHeader: !!x.heading
        })
        XLSX.utils.book_append_sheet(workbook, worksheet, x.name || `Sheet${index + 1}`)
    })

    XLSX.writeFile(workbook, `${filename}.xlsx`)
}
