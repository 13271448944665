import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/react"
import {I18nextProvider} from "react-i18next"
import * as serviceWorker from "./serviceWorker"
import {App} from "./App"
import "./assets/css/index.css"
import "react-perfect-scrollbar/dist/css/styles.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import {i18n} from "./config"
import {PublicClientApplication} from "@azure/msal-browser"
import {MsalProvider} from "@azure/msal-react"
import {msalConfig} from "config/azureAuthConfig"
import {authService} from "services"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [],
    environment: process.env.REACT_APP_NODE_ENV,
    tracesSampleRate: 0,
    enabled: process.env.NODE_ENV !== "development",
    debug: process.env.NODE_ENV === "development",
    release: process.env.REACT_APP_COMMIT
})

const NotSsoApp = (
    <I18nextProvider i18n={i18n}>
        <App />
    </I18nextProvider>
)
let MainElement = NotSsoApp

/**
 * It is recommend initializing PublicClientApplication outside of the root component to ensure it is not re-initialized on re-renders
 */
authService
    .getAuthClients()
    .then((data) => {
        const [client] = data.clients
        if (!client) {
            return
        }

        const {client_id, tenant} = client
        const msalInstance = new PublicClientApplication(msalConfig(client_id, tenant))
        MainElement = (
            <I18nextProvider i18n={i18n}>
                <MsalProvider instance={msalInstance}>
                    <App ssoEnabled />
                </MsalProvider>
            </I18nextProvider>
        )
    })
    .catch((e) => {
        console.log("Index authService.getAuthClients, sso not working", e)
    })
    .finally(() => {
        ReactDOM.render(MainElement, document.getElementById("root"))
    })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
