import React from "react"

export interface Column<T = any> {
    field: string
    title: string
    titleHtml?: React.ReactNode
    backgroundColor?: string
    render?: (column: Column<T>, record: T) => React.ReactNode
    renderText?: (column: Column<T>, record: T) => string | null | undefined
    editable?: {
        render: (column: Column<T>, record: T) => React.ReactNode
    }
}

export interface ColumnData {
    min: number
    max: number
    width: number
}

export const DEFAULT_COLUMN_DATA: ColumnData = {
    min: 80,
    max: 200,
    width: 120
}
