/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {Checkbox} from "antd"
import {Icon} from "components/Icon"
import {BasePopup} from "components/popup"
import {BaseInput} from "components/inputs"
import {BaseButton} from "components/buttons"
import styles from "./LessonAttendancePopup.module.css"
import {attendanceService, courseService, profileService} from "services"
import {Course} from "types/courses"
import {clone, get, head} from "lodash"
import {convertTZ, getUserPhotoUrl, handleError, roundTwoDigits, toastWarning} from "helpers"
import {BaseLoading} from "components"
import {useModel, useVisible} from "hooks"
import {AttendanceEventType, LessonStatus} from "types/attendance"
import {StartBreakPopup} from "sections/academics/instructional/InstructionalLessonDetail/parts/AttendanceTab/parts"
import moment from "moment-timezone"

type Props = {
    isShow: boolean
    studentProfileIds?: number[]
    courseId?: number
    termId?: number
    attendanceMessage?: string
    lessonId?: number
    attendedHours?: number
    attendanceDate?: string
    classroomAttendanceType?: Course.AttendanceTrackingType
    status?: LessonStatus
    getListAttendances?: () => void
    onClose: () => void
}

export function LessonAttendancePopup(props: Props) {
    const {
        isShow,
        onClose,
        studentProfileIds = [],
        courseId,
        attendanceMessage,
        getListAttendances,
        attendedHours,
        attendanceDate,
        termId,
        lessonId,
        status,
        classroomAttendanceType
    } = props
    const isActivityAttendance = classroomAttendanceType === Course.AttendanceTrackingType.Activity
    const {t} = useTranslation(["studentServices"])
    const [courseInfo, setCourseInfo] = useState<Course.CourseTableItem>()
    const [listEvent, setListEvent] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [studentInfo, setStudentInfo] = useState<any>()
    const startBreakPopup = useVisible(false)
    const timezone = courseInfo?.timezone ?? moment.tz.guess()

    const [dateData, setDateData] = useState({
        checkInTime: "",
        checkOutTime: "",
        checkInDate: ""
    })
    const model = useModel()
    const timeFormat = model.getUserTimeFormat()
    const dateFormat = model.getUserDateFormat()

    const avatarUrl = studentInfo?.photo ? getUserPhotoUrl(studentInfo?.photo, 512) : "/image/DefaultAvatar.png"

    const getStudentInfo = useCallback(async () => {
        const {data: student} = await profileService.getOne({
            id: studentProfileIds[0],
            type: "student"
        })
        setStudentInfo(student)
    }, [studentProfileIds])

    const getCourseInfo = async (courseId: number, termId: number) => {
        const paramsGetCourse: any = {
            filter: {
                courseIds: [courseId]
            },
            range: {page: 1, pageSize: 1}
        }
        if (termId) {
            paramsGetCourse.filter.termIds = [termId]
        }
        const {data} = await courseService.getAll(paramsGetCourse)
        const course = head(data)
        setCourseInfo(course)
    }

    useEffect(() => {
        if (courseId && termId) {
            getCourseInfo(courseId, termId)
            getAttendanceDetail()
        }
    }, [termId, courseId, studentProfileIds])

    const getAttendanceDetail = useCallback(async () => {
        setIsLoading(true)
        try {
            const {data} = await attendanceService.getLessonActivity({
                lessonId: lessonId,
                termIds: [termId],
                courseId: courseId
            })
            if (data?.length && studentProfileIds.length) {
                const studentAttendance = data.find((activity) => activity?.student?.profileId === studentProfileIds[0])
                const events = get(studentAttendance, ["events"], []).sort(function (left, right) {
                    return moment.utc(left.createdAt).diff(moment.utc(right.createdAt))
                })
                const checkInEvent = events.find((event) => event.eventType === AttendanceEventType.Checkin)
                const checkOutEvent = events.find((event) => event.eventType === AttendanceEventType.Checkout)
                setDateData({
                    ...dateData,
                    checkInTime: checkInEvent?.createdAt
                        ? moment(convertTZ(new Date(checkInEvent.createdAt), courseInfo?.timezone)).format(timeFormat)
                        : "",
                    checkOutTime: checkOutEvent?.createdAt
                        ? moment(convertTZ(new Date(checkOutEvent.createdAt), courseInfo?.timezone)).format(timeFormat)
                        : "",
                    checkInDate: checkInEvent?.createdAt
                        ? moment(convertTZ(new Date(checkInEvent.createdAt), courseInfo?.timezone)).format(dateFormat)
                        : ""
                })
                setListEvent(events)
            }
        } catch (err) {
            handleError(err)
        } finally {
            setIsLoading(false)
        }
    }, [studentProfileIds, termId, courseId, lessonId])

    useEffect(() => {
        studentProfileIds.length && getStudentInfo()
    }, [isShow, studentProfileIds, courseId, termId])

    useEffect(() => {
        if (!isShow) {
            setStudentInfo(undefined)
            setListEvent([])
        }
    }, [isShow])

    const onClickBreak = async (time: moment.Moment) => {
        startBreakPopup.close()
        setIsLoading(true)
        const createdAt = time ? moment().tz(timezone, true).utc() : moment(time).tz(timezone, true).utc()
        try {
            const params = [
                {
                    lessonId: lessonId,
                    profileId: model.profileId,
                    attendanceType: "manual",
                    message: "",
                    eventType: AttendanceEventType.BreakStart,
                    status: "success",
                    createdAt: createdAt
                }
            ]
            await attendanceService.checkAttendanceManually(params)
            getAttendanceDetail()
        } catch (err) {
            handleError(err)
        } finally {
            setIsLoading(false)
        }
    }

    const onClickEndBreak = async () => {
        setIsLoading(true)
        const createdAt = moment().tz(timezone, true).utc()
        try {
            const params = [
                {
                    lessonId: lessonId,
                    profileId: model.profileId,
                    attendanceType: "manual",
                    message: "",
                    eventType: AttendanceEventType.BreakEnd,
                    status: "success",
                    createdAt: createdAt
                }
            ]
            await attendanceService.checkAttendanceManually(params)
            getAttendanceDetail()
        } catch (err) {
            handleError(err)
        } finally {
            setIsLoading(false)
        }
    }

    const renderStudentInfo = () => {
        return (
            <div className={styles.studentWrapper}>
                <div className={styles.imgWrapper}>
                    <img src={avatarUrl} className={styles.avatar} alt="" />
                </div>
                <div>
                    <p className={styles.studentName}>{studentInfo?.fullName}</p>
                    <div className={styles.flexContainer}>
                        <div className={styles.columnContainer}>
                            <div className={styles.labelContainer}>
                                <span className={styles.idLabel}>{t("popup.userId")}: </span>
                                <span className={styles.idValue}>{studentInfo?.userId}</span>
                            </div>
                            <div className={styles.labelContainer}>
                                <span className={styles.idLabel}>{t("popup.enrollmentId")}: </span>
                                <span className={styles.idValue}>{studentInfo?.customProfileId}</span>
                            </div>
                        </div>

                        <div className={styles.columnContainer}>
                            <div className={styles.labelContainer}>
                                <span className={styles.idLabel}>{t("popup.course")}: </span>
                                <span className={styles.courseValue}>{courseInfo?.name}</span>
                            </div>
                            <div className={styles.labelContainer}>
                                <span className={styles.idLabel}>ATTENDED MINUTES:</span>
                                <span className={styles.courseValue}>{roundTwoDigits(attendedHours ?? 0) * 60}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderTitle = (eventType: string) => {
        if (eventType === "check-out") {
            return t("popup.checkout")
        } else if (eventType === "check-in") {
            return t("popup.checkin")
        } else if (eventType === "break-start") {
            return t("popup.breakStart")
        } else if (eventType === "break-end") {
            return t("popup.breakEnd")
        } else {
            return t("popup.attendanceCheck")
        }
    }

    const renderIconAttendance = (eventType: string) => {
        if (eventType === "check-out") {
            return <Icon icon="CHECKOUT_FILL" className={styles.rightIcon} />
        } else if (eventType === "check-in") {
            return <Icon icon="CHECKIN_FILL" className={styles.rightIcon} />
        } else if (eventType === "break-start") {
            return <Icon icon="CHECK_CIRCLE" className={styles.rightIcon} />
        } else if (eventType === "break-end") {
            return <Icon icon="CHECK_CIRCLE" className={styles.rightIcon} />
        } else {
            return <Icon icon="CHECK_CIRCLE" className={styles.rightIcon} />
        }
    }

    const renderCheckInItem = (data: any, index: number) => {
        return (
            <div key={index.toString()} className={styles.infoWrapper}>
                <span className={styles.hourLabel}>
                    {data?.createdAt
                        ? moment(convertTZ(new Date(data?.createdAt), courseInfo?.timezone)).format(timeFormat)
                        : ""}
                </span>
                {/* <div className={styles.iconWrapper}>
                    <Icon icon="FACE_SCAN_FILL" color="var(--success-400-base)" className={styles.iconWidth} />
                </div> */}
                {renderIconAttendance(data?.eventType)}
                <span className={styles.infoLabel}>{renderTitle(data?.eventType)}</span>
                <BaseInput
                    value={listEvent[index]?.message ?? ""}
                    disabled
                    placeholder={t("popup.notes")}
                    className={styles.inputWidth}
                />
            </div>
        )
    }

    const {checkInTime, checkOutTime, checkInDate} = dateData
    const isShowEndBreak = listEvent?.length
        ? listEvent[listEvent.length - 1].eventType === AttendanceEventType.BreakStart
        : false

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="PERSON_FILL" width="70vw">
            <div className={styles.popupWrapper}>
                <p className={styles.title}>{t("attendance.title")}</p>
                {!isActivityAttendance && checkInDate ? (
                    <div>
                        <div className={styles.timeWrapper}>
                            <span>{`${checkInDate}${checkInTime ? ", " + checkInTime : ""}${
                                checkOutTime !== "" ? "-" : ""
                            }${checkOutTime}`}</span>
                        </div>
                    </div>
                ) : null}
                {isActivityAttendance ? (
                    <div>
                        <div className={styles.timeWrapper}>
                            <span>{`${moment(convertTZ(new Date(attendanceDate), courseInfo?.timezone)).format(
                                dateFormat
                            )}, ${moment(convertTZ(new Date(attendanceDate), courseInfo?.timezone)).format(
                                timeFormat
                            )}`}</span>
                        </div>
                    </div>
                ) : null}
                <hr className={styles.divisor} />
                {renderStudentInfo()}
                {listEvent?.length ? (
                    <>{listEvent.map(renderCheckInItem)}</>
                ) : !isActivityAttendance ? (
                    <div className={styles.emptyView}>
                        <span className={styles.textEmpty}>Attendance is empty</span>
                    </div>
                ) : null}
                {!isActivityAttendance && status !== LessonStatus.Ended && status !== LessonStatus.Inactive ? (
                    <>
                        {!isShowEndBreak ? (
                            <BaseButton
                                style={{width: "20%", alignSelf: "center"}}
                                title="Break"
                                variant="primary"
                                onClick={startBreakPopup.open}
                            />
                        ) : (
                            <BaseButton
                                style={{width: "20%", alignSelf: "center"}}
                                title="End Break"
                                variant="primary"
                                onClick={onClickEndBreak}
                            />
                        )}
                    </>
                ) : null}
                <StartBreakPopup
                    isShow={startBreakPopup.isVisible}
                    onClose={startBreakPopup.close}
                    onStartBreak={onClickBreak}
                    timezone={timezone}
                />
                <BaseLoading isShow={isLoading} />
            </div>
        </BasePopup>
    )
}
