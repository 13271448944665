import React, {useMemo, useRef, useState} from "react"
import {Button, Checkbox} from "antd"
import classNames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import {Icon} from "components/Icon"
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd"
import {KlassappTableProps} from "types/common"
import {checkIsFirefox} from "helpers"
import {TableRowItem, EmptyRow, LoadingRow, ColumnPicker} from "./parts"
import styles from "./KlassappTable.module.css"
import {KlassappTableContext} from "./KlassappTableContext"

const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
}

function BodyTable(props) {
    const {tableData, isLoading, columns, isDraggable, classNameActionMenu, keyRender, emptyText, ...rest} = props

    if (isLoading) {
        return (
            <tbody className={styles.bodyTable}>
                <LoadingRow colSpan={columns.length + 2} />
            </tbody>
        )
    }
    if (!tableData.length) {
        return (
            <tbody className={styles.bodyTable}>
                <EmptyRow colSpan={columns.length + 2} emptyText={emptyText} />
            </tbody>
        )
    }

    if (isDraggable) {
        return (
            <Droppable droppableId="table">
                {(droppableProvided) => (
                    <tbody
                        ref={droppableProvided.innerRef}
                        className={styles.bodyTable}
                        {...droppableProvided.droppableProps}>
                        {tableData.map((item, index: number) => (
                            <Draggable draggableId={`${item[keyRender]}`} index={index} key={item[keyRender]}>
                                {(provided, snapshot) => (
                                    <TableRowItem
                                        provided={provided}
                                        snapshot={snapshot}
                                        key={item[keyRender]}
                                        data={item}
                                        columns={columns}
                                        isDraggable={isDraggable}
                                        keyRender={keyRender}
                                        {...rest}
                                    />
                                )}
                            </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                    </tbody>
                )}
            </Droppable>
        )
    }
    return (
        <tbody className={styles.bodyTable}>
            {tableData.map((item) => (
                <TableRowItem
                    classNameActionMenu={classNameActionMenu}
                    key={item[keyRender]}
                    keyRender={keyRender}
                    data={item}
                    columns={columns}
                    isDraggable={isDraggable}
                    {...rest}
                />
            ))}
        </tbody>
    )
}

export function KlassappTable(props: KlassappTableProps) {
    const {
        className,
        tableClassName,
        tableRowParent,
        isShowCheckedColumnHeader = true,
        onToggleCheckBox,
        parentColumns = [],
        subHeaderColumns = [],
        columns,
        menuActions,
        data,
        isLoading,
        fields,
        allFields,
        unselectableFields,
        isShowCheckedColumn,
        isShowColumnPicker,
        isShowHeader = true,
        isShowSubHeader = false,
        isDraggable,
        orderField,
        onClickRowItem,
        onDoubleClickRowItem,
        classNameActionMenu,
        onChangeFields,
        onChangeAllFields,
        onDraggableColumn,
        onUpdateTableData,
        onUpdateRowData,
        onToggleCheckHeader,
        onClickSortColumn,
        onDraggableEnd,
        icon,
        expandable,
        keyRender = "id",
        colSpanAll,
        emptyText,
        expandableKey,
        defaultExpandable,
        expandIndent = 24
    } = props

    const colsPickerRef = useRef(null)
    const [isShowScrollTable, setIsShowScrollTable] = useState(true)
    const [isShowChooseCols, setIsShowChooseCols] = useState(false)

    const isChecked = useMemo(() => {
        return data.length && data.every((row) => !!row?.isChecked)
    }, [data])

    const onToggleCheck = (checked) => {
        if (onToggleCheckHeader) {
            onToggleCheckHeader(checked)
        } else {
            const newTableData = data.map((item) => {
                item.isChecked = checked
                return item
            })
            onUpdateTableData(newTableData)
        }
    }

    const onDragEnd = (result) => {
        const {destination, source} = result || {}
        if (!destination || destination.index === source.index) {
            return
        }
        if (destination.index === source.index) {
            return
        }
        if (onDraggableEnd) {
            onDraggableEnd(result)
        } else {
            const newTableData = reorder(data, source.index, destination.index)
            onUpdateTableData(newTableData)
        }
    }

    const onShowChooseCols = () => {
        setIsShowChooseCols(true)
    }

    const renderOrderIcon = (field) => {
        if (!orderField || orderField.field !== field) {
            return null
        }
        return orderField.order === "asc" ? (
            <Icon icon="SORT_DOWN_SOLID" className={styles.sortIcon} />
        ) : (
            <Icon icon="SORT_UP_SOLID" className={styles.sortIcon} />
        )
    }

    const renderTableContent = () => {
        return (
            <table className={classNames(styles.table, tableClassName)}>
                {isShowHeader && (
                    <thead className={styles.tableHead}>
                        {parentColumns.length ? (
                            <tr className={tableRowParent}>
                                {isShowCheckedColumn && (
                                    <th className={`${styles.textLeft} ${styles.tableCellHeader}`}></th>
                                )}
                                {!!expandable?.expandedRowRender && !expandable?.expandColumnSequence && (
                                    <th className={`${styles.textLeft} ${styles.tableCellHeader}`}></th>
                                )}
                                {parentColumns.map((col) => (
                                    <th
                                        className={`${styles.textLeft} ${styles.tableCellHeader} ${col.classNameHeader}`}
                                        colSpan={col.span}>
                                        {col.title}
                                    </th>
                                ))}
                                {isShowColumnPicker ? (
                                    <th className={`${styles.textLeft} ${styles.tableCellHeader}`}></th>
                                ) : menuActions && menuActions.length ? (
                                    <th className={`${styles.textLeft} ${styles.tableCellHeader}`}></th>
                                ) : null}
                            </tr>
                        ) : null}
                        <tr className={styles.tableRowHeader}>
                            {isShowCheckedColumn && (
                                <th
                                    className={`${styles.tableCell} ${styles.tableCellHeader} ${styles.expandIconCell}`}>
                                    {isShowCheckedColumnHeader && (
                                        <Checkbox
                                            checked={isChecked}
                                            onChange={(event) => onToggleCheck(event.target.checked)}
                                        />
                                    )}
                                </th>
                            )}
                            {!!expandable?.expandedRowRender && !expandable?.expandColumnSequence && (
                                <th
                                    className={`${styles.tableCell} ${styles.tableCellHeader} ${styles.expandIconCell}`}></th>
                            )}
                            {columns.map((column, index) => {
                                const canSortable = column.sortable && onClickSortColumn
                                const orderField = column.orderField || column.field
                                const title = column.titleHtml || column.title
                                return (
                                    fields.includes(column.title) && (
                                        <th
                                            key={index}
                                            className={classNames(
                                                styles.tableCell,
                                                styles.tableCellHeader,
                                                column.classNameHeader,
                                                canSortable ? styles.cursor : ""
                                            )}
                                            style={column.headerStyle}
                                            onClick={canSortable ? () => onClickSortColumn(orderField) : () => {}}>
                                            {!column.isHideTitle && title}
                                            {canSortable && renderOrderIcon(orderField)}
                                        </th>
                                    )
                                )
                            })}
                            {isShowColumnPicker ? (
                                <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>
                                    <div className={styles.flexEnd}>
                                        <Button
                                            className={styles.plusIconBtn}
                                            icon={<Icon icon={icon || "COLUMNS"} className={styles.iconPlus} />}
                                            onClick={onShowChooseCols}
                                        />
                                    </div>
                                </th>
                            ) : menuActions && menuActions.length ? (
                                <th
                                    className={`${styles.tableCell} ${styles.tableCellHeader} ${classNameActionMenu}`}></th>
                            ) : null}
                        </tr>
                    </thead>
                )}
                {isShowSubHeader && (
                    <thead className={styles.tableSubHead}>
                        <tr className={styles.tableRowHeader}>
                            <th
                                className={`${styles.tableCell} ${styles.tableCellSubHeader} ${styles.expandIconCell}`}></th>
                            {subHeaderColumns.map((column, index) => {
                                const canSortable = column.sortable && onClickSortColumn
                                const orderField = column.orderField || column.field

                                return (
                                    <th
                                        key={index}
                                        className={classNames(
                                            styles.tableCell,
                                            styles.tableCellSubHeader,
                                            column.classNameHeader,
                                            canSortable ? styles.cursor : ""
                                        )}
                                        style={column.headerStyle}
                                        onClick={canSortable ? () => onClickSortColumn(orderField) : () => {}}>
                                        {column.titleHtml || column.title}
                                        {canSortable && renderOrderIcon(orderField)}
                                    </th>
                                )
                            })}
                            {isShowColumnPicker ? null : menuActions && menuActions.length ? (
                                <th
                                    className={`${styles.tableCell} ${styles.tableCellSubHeader} ${classNameActionMenu}`}></th>
                            ) : null}
                        </tr>
                    </thead>
                )}
                <BodyTable
                    columns={columns}
                    tableData={data}
                    menuActions={menuActions}
                    classNameActionMenu={classNameActionMenu}
                    fields={fields}
                    isLoading={isLoading}
                    isShowCheckedColumn={isShowCheckedColumn}
                    isShowColumnPicker={isShowColumnPicker}
                    onClickRowItem={onClickRowItem}
                    onToggleCheckBox={onToggleCheckBox}
                    onDoubleClickRowItem={onDoubleClickRowItem}
                    onUpdateRowData={onUpdateRowData}
                    isDraggable={isDraggable}
                    expandable={expandable}
                    colSpanAll={colSpanAll}
                    keyRender={keyRender}
                    expandableKey={expandableKey}
                    defaultExpandable={defaultExpandable}
                    expandIndent={expandIndent}
                    emptyText={emptyText}
                />
            </table>
        )
    }

    const renderTable = () => {
        const isFirefox = checkIsFirefox()
        return (
            <KlassappTableContext.Provider value={{isShowScrollTable, setIsShowScrollTable}}>
                <div className={classNames(styles.root, {"hidden-scroll": !isShowScrollTable})}>
                    {isFirefox ? (
                        <div
                            className={classNames(
                                "custom-scroll",
                                isShowScrollTable ? styles.tableFirefoxScroll : styles.tableScrollHidden,
                                className
                            )}>
                            {renderTableContent()}
                        </div>
                    ) : (
                        <PerfectScrollbar
                            className={classNames(
                                "custom-scroll",
                                isShowScrollTable ? styles.tableScroll : styles.tableScrollHidden,
                                className
                            )}>
                            {renderTableContent()}
                        </PerfectScrollbar>
                    )}
                    {isShowChooseCols && (
                        <div ref={colsPickerRef}>
                            <ColumnPicker
                                fields={fields}
                                allFields={allFields}
                                unselectableFields={unselectableFields}
                                onChangeFields={onChangeFields}
                                onChangeAllFields={onChangeAllFields}
                                setIsShowChooseCols={setIsShowChooseCols}
                                onDraggableColumn={onDraggableColumn}
                            />
                        </div>
                    )}
                </div>
            </KlassappTableContext.Provider>
        )
    }

    if (isDraggable) {
        return (
            <DragDropContext onDragEnd={onDragEnd} style={{overflow: "auto"}}>
                {renderTable()}
            </DragDropContext>
        )
    }

    return renderTable()
}
