import {get} from "lodash"
import BaseService from "./Base"
import {ContactStrategy} from "types/contact-strategy"
import {Workflow} from "types/workflow"

class ContactStrategyService extends BaseService {
    getAll = async (params = {}): Promise<{total: number; data: ContactStrategy[]}> => {
        const response = await this.post("/v1/contact-strategy/list", params)
        return get(response, "data")
    }

    getOne = async (id: number, linkedEntities = true): Promise<ContactStrategy[]> => {
        const response = await this.post(`/v1/contact-strategy/get`, {id, linkedEntities})
        return get(response, "data.data")
    }

    create = async (data: Partial<ContactStrategy>): Promise<ContactStrategy> => {
        const response = await this.post(`/v1/contact-strategy/create`, data)
        return get(response, "data.data")
    }

    update = async (data: Partial<ContactStrategy>): Promise<ContactStrategy> => {
        const response = await this.post(`/v1/contact-strategy/edit`, data)
        return get(response, "data.data")
    }

    remove = async (ids: number[]): Promise<void> => {
        const response = await this.post(`/v1/contact-strategy/delete`, {ids})
        return get(response, "data")
    }

    duplicate = async (ids: Workflow["id"][]): Promise<void> => {
        const response = await this.post(`/v1/contact-strategy/duplicate`, {ids})
        return get(response, "data")
    }

    changeDay = async (contactStrategyId: number, profileId: number, day: number) => {
        const response = await this.post(`/v1/contact-strategy/change-day`, {contactStrategyId, profileId, day})
        return get(response, "data.data")
    }
}

export default ContactStrategyService
