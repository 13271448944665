import React, {Component} from "react"
import {KlassDropdown as Dropdown} from "components/Select"

type SelectComponentsProps = {[key in string]: any}

type KlassDropdownProps = SelectComponentsProps & {
    options: any[]
    value?: any
    onChange?: any
    placeholder?: string
    valueKey?: string
    labelKey?: string
    hasEmptyOption?: boolean
    readOnly?: boolean
    isMulti?: boolean
    stylesCustom?: any
    closeMenuOnSelect?: boolean
}

export class KlassDropdown extends Component<KlassDropdownProps, {}> {
    public render() {
        return <Dropdown {...this.props} />
    }
}
