import {Course} from "./courses"
import {ApprovalStatus} from "./isir"
import {Major} from "./major"
import {AddDropVariant, Schedule, ScheduleEvent} from "./terms"

export namespace AcademicPlans {
    export enum TabKey {
        List = "list",
        Term = "term"
    }

    export enum AcademicPlanCourseReviewStatus {
        PendingReview = "pending_review",
        Approved = "approved",
        Registered = "registered",
        Rejected = "rejected",
        Dropped = "dropped"
    }

    export enum AcademicPlanCourseStatus {
        NotStarted = "not_started",
        Finished = "finished",
        Loa = "loa",
        Failed = "failed",
        Completed = "completed",
        Inprogress = "in_progress",
        Registered = "registered",
        Incomplete = "incomplete",
        LoaIncomplete = "loa-incomplete",
        Dropped = "dropped",
        Suspended = "suspended"
    }

    export enum TermDraggable {
        TermSectionEmpty = "termSectionEmpty"
    }

    export enum UpdateEventType {
        Add = "Add",
        Update = "Update",
        Delete = "Delete"
    }

    export enum ChangeRequestType {
        DROP_COURSE = "drop_course",
        REGISTER_COURSE = "register_course",
        REMOVE_COURSE = "remove_course"
    }

    export enum ChangeRequestStatus {
        PENDING = "pending",
        APPROVED = "approved",
        REJECTED = "rejected"
    }

    export enum EnrollmentStatus {
        InProgress = "in_progress",
        NotStarted = "not_started"
    }

    export type Section = {
        academicPlanSectionId: number
        sectionTitle: string
        sectionId: number
        rootSectionId: number
        courseUnit: Course.Unit
        sectionMinimum: number
        credits: SectionCredits
        courses: SectionCourse[]
    }

    export type SectionCredits = {
        total: number
        completed: number
        minimum: number
    }

    export enum SapReason {
        LowGpa = "lowGpa",
        AboveMaxTimeFrame = "aboveMaxTimeFrame",
        LowAttendance = "lowAttendance",
        BelowRateOfProgress = "belowRateOfProgress"
    }

    export type ChangeRequest = {
        academicPlanCourseId: number
        type: string
        status: string
        previousId: number
        approverProfileId: number
        studentProfileId: number
        createdAt: string
        updatedAt: string
        deletedAt: string
    }

    export type SectionCourse = {
        academicPlanCourseId: number
        courseId: number
        courseName: string
        courseCode: string
        grade: number
        firstDayOfCourse?: string
        lastDayOfCourse?: string
        termId: number
        termName: string
        scheduleName: string
        credits: number
        status: string
        registeredDate: string
        registeredScheduleId: number
        courseInstructor: string
        courseTrack: string
        prerequisiteCourses: PrerequisiteCourses[]
        reviewStatus: string
        updatedAt: string
        droppedDate: string
        addDropVariant: AddDropVariant
        addDropDate?: string
        addDropHours?: string
        isPassed: number
        isVerified: number
        available: SectionCourseAvailable
        changeRequests: ChangeRequest[]
    }

    export type SectionCourseAvailable = {
        current: number
        total: number
    }

    export type Term = {
        id: number
        templateTermName: string
        templateTermId: number
        termId: number
        position: number
        credit: number
        eachCredit: number
        createdAt: string
        updatedAt: string
        title: string
        code: string
        registration_start_date: string
        registration_end_date: string
        start_date: string
        end_date: string
        add_drop_date: string
        each: number
        sections: TermSection[]
        isCurrentSelect?: boolean
    }

    export type TermSection = {
        id: number
        sectionTitle: string
        sectionId: number
        rootSectionId: number
        studentProfileId: number
        createdAt: string
        updatedAt: string
        deletedAt: null
        courses: TermCourse[]
    }

    export type PrerequisiteCourses = {
        courseId: number
        prerequisiteCourseId: number
    }

    export type TermCourse = {
        academicPlanCourseId: number
        courseId: number
        courseCode: string
        courseName: string
        credit: number
        firstDayOfCourse?: string
        lastDayOfCourse?: string
        role?: Course.AcademicPlanCourseRole
        grade: number
        courseTrack: string
        courseInstructor: string
        selectedScheduleId?: number
        courseTotalUnits: Course.Unit
        registeredDate: string
        registeredScheduleId: number
        reviewStatus: string
        status: string
        updatedAt: string
        droppedDate: string
        isPassed: number
        isVerified: number
        changeRequests: ChangeRequest[]
        prerequisiteCourses: PrerequisiteCourses[]
        schedules: CourseSchedule[]
    }

    export type CourseSchedule = Schedule & {available: CourseScheduleAvailable; campusIds: number[]}

    export type CourseScheduleAvailable = {
        current: number
        total: number
    }

    export type AvailableTerm = {
        id: number
        name: string
        code: string
        sap: number
        registration_start_date: string
        registration_end_date: string
        start_date: string
        end_date: string
        courses: AvailableTermCourse[]
    }

    export type AvailableTermCourse = {
        id: number
        name: string
        type: string
        code: string
        totalQuantity: number
        totalUnits: string
        academicTrackId: number
        instructor: CourseInstructor
    }

    export type CourseInstructor = {
        id: number
        profileId: number
        firstName: string
        lastName: string
    }

    export type UpdateEvents = {
        type: AcademicPlans.UpdateEventType
        changedEvents: ScheduleEvent[]
        schedule: Schedule
    }

    export type TermCourseStats = {
        attendancePercentage: number
        code: string
        endDate: string
        gpa: number
        name: string
        id: number
        makeUpHours: number
        maxTimeframe: number
        type: Major.Unit
        missedAttendanceHours: number
        startDate: string
        termId: number
        title: string
        totalHours: number
        totalHoursAttempted: number
        totalHoursCompleted: number
        totalHoursRemaining: number
        totalProgress: number
        totalTransferHours: number
    }

    export type SapAction = {
        academicSapActionId?: number
        termId: number
        studentProfileId: number
        date: string
        studentRefusedToSign: boolean
        actionPlan: string
        courseId: number
        scheduleId?: number
        approvalSignatureId: number
        studentSignatureId: number | null
        approvalStatus: ApprovalStatus
    }

    export enum SapActionType {
        ActionPlan = "actionPlan",
        Meeting = "meeting"
    }

    export type SapMeeting = {
        academicSapMeetingId?: number
        studentProfileId: number
        date: string
        title: string
        description: string
        studentRefusedToSign: boolean
        approvalSignatureId: number
        studentSignatureId: number
        approvalStatus: ApprovalStatus
    }

    export enum SapStatus {
        New = "new",
        Satisfactory = "satisfactory",
        Warning = "warning",
        Probation = "probation",
        Suspension = "suspension",
        "sap-met" = "SAP Met",
        "sap-not-met" = "SAP Not Met",
        "sap-not-evaluated" = "SAP Not Evaluated"
    }

    export enum MakeupStudentType {
        MakeupAttendance = "makeup_attendance",
        OtherReasons = "other_reasons"
    }

    export type AddStudentInfo = {
        profileId: number
        isRegisterCheck: boolean
        registeredDate: any
    }
}
