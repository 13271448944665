/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import {useTranslation} from "react-i18next"
import {settingStatusService} from "services"
import {KlassDropdownAsyncProps} from "../Select"
import {BaseDepartmentId} from "types/departments"
import orderBy from "lodash/orderBy"
import {StudentDepartmentStatus} from "types/workflow"
import {KlassDropdown} from "../inputs"
import {pick} from "lodash"

type Props = Partial<KlassDropdownAsyncProps> & {
    departmentId?: number | number[]
    shortly?: boolean
}

const StudentStatusSelect: React.FC<Props> = ({departmentId, shortly, ...props}) => {
    const {t} = useTranslation(["workflow", "common"])
    const [statuses, setStatuses] = React.useState<StudentDepartmentStatus[]>([])

    const statusGroups = React.useMemo(
        () =>
            (Object.values(BaseDepartmentId).filter((v) => typeof v === "number") as number[])
                .map((departmentId) => {
                    let options = orderBy(
                        statuses.filter((status) => status.stage?.departmentId === departmentId),
                        ["stage.state", "priority"],
                        ["asc", "asc"]
                    ).map((status) => ({
                        ...pick(status, ["statusId", "name", "stageId"]),
                        stage: status.stage && pick(status.stage, ["stageId", "departmentId", "name"]),
                        departmentId: status.stage?.departmentId
                    }))
                    if (shortly) {
                        options = options.map(({statusId, name, stage}) => ({
                            statusId,
                            name,
                            departmentId: stage?.departmentId
                        })) as any[]
                    }
                    return {
                        label: t(`workflow.statusDepartment.${departmentId}`),
                        options
                    }
                })
                .filter((group) => group.options.length > 0),
        [t, statuses]
    )

    React.useEffect(() => {
        ;(async function loadStatuses() {
            settingStatusService
                .getAllStatuses({
                    sort: {orderBy: "priority", orderDir: "asc"},
                    filter: {
                        departmentIds:
                            departmentId != null
                                ? Array.isArray(departmentId)
                                    ? departmentId
                                    : [departmentId]
                                : (Object.values(BaseDepartmentId).filter((v) => typeof v === "number") as number[])
                    },
                    linkedEntities: true
                })
                .then(({data: statuses}) => {
                    setStatuses(statuses)
                })
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <KlassDropdown
            {...props}
            options={statusGroups}
            valueKey="statusId"
            labelKey="name"
            getOptionLabel={(option) => (
                <span>
                    {!!(option.departmentId || option.stage?.departmentId) && (
                        <span className="mr-4">
                            [{t(`common:moduleAbbrev.${option.departmentId || option.stage?.departmentId}`)}]
                        </span>
                    )}
                    <span>{option.name}</span>
                </span>
            )}
        />
    )
}

export default StudentStatusSelect
