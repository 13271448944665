/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"

function ActivitiesTab(props) {
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        data,
        tableHeaderActions,
        orderField,
        isLoading,
        isLoadedTableFuncs,
        isShowTableHeaderAction
    } = props

    const defaultFields = ["ID", "Name", "Date", "Activity Name"]

    const [columnsData] = useState([
        {
            title: "ID",
            field: "id",
            isFixed: true,
            width: 0.1
        },
        {
            title: "Name",
            field: "fullname",
            isFixed: true,
            isMainColumn: true,
            width: 0.2
        },
        {
            title: "Date",
            field: "fullname",
            isFixed: true,
            isMainColumn: true,
            width: 0.2
        },
        {
            title: "Activity Name",
            field: "fullname",
            isFixed: true,
            isMainColumn: true,
            width: 0.2
        }
    ])
    const [fieldsData] = useState(defaultFields)

    const getFields = () => {
        return fieldsData
    }

    const getPageTitle = () => {
        return "Activities"
    }

    const getColumns = () => {
        return columnsData
    }

    const getData = async () => {
        try {
            // dispatch({isLoading: true})
        } catch (e) {
            console.log(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClickRowItem = (row) => {}

    const onClickDeleteMulti = async () => {}

    const onClickDuplicateMulti = async () => {}

    const onClickEdit = () => {}

    const onClickDelete = async () => {}

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickDuplicateMulti", func: onClickDuplicateMulti},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDelete},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [fieldsData, columnsData, data])

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getData()
        }
    }, [isLoadedTableFuncs, page, pageSize, props.search, props.filter])

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        props.updateTableHeaderActions()
    }, [data])

    return (
        <>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fieldsData}
                allFields={fieldsData}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columnsData}
                fields={fieldsData}
                data={data}
                menuActions={[]}
                isLoading={isLoading}
                allFields={fieldsData}
                orderField={orderField}
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
            />
        </>
    )
}

export default KlassappTableHOC(ActivitiesTab)
