import * as QueryString from "query-string"

export function getUrlParams() {
    return QueryString.parse(window.location.search)
}

export function changeActiveTabOnUrl(history, activeKey) {
    const searchParams = getUrlParams()
    searchParams.tab = activeKey
    const location = history.location
    location.search = QueryString.stringify(searchParams)
    history.replace({...location})
}

export function getQueryStringFromSearchParams(searchParams) {
    let queryString = ""
    const searchParamOfKeys = Object.keys(searchParams)
    searchParamOfKeys.forEach((key, index) => {
        queryString += `${key}=${searchParams[key]}`
        if (index !== searchParamOfKeys.length - 1) {
            queryString += "&"
        }
    })
    return queryString
}
