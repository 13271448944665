import React, {useMemo, useState} from "react"
import {BasePopup} from "uiKit"
import {FormLabel} from "components/Form"
import {BaseInput} from "components/inputs"
import {BaseButton} from "components/buttons"
import {userPersonalContactsServiceV3} from "services"
import styles from "./EditInfoPopup.module.css"
import {getValuesChangeTwoObj, handleError, toastError, toastSuccess} from "helpers"
import {pick} from "lodash"
import {EditContactInfo} from "./OthersSigner"

type Props = {
    isShow: boolean
    editingUser: EditContactInfo
    onClose: () => void
    onSubmitSuccess: (newUserInfo: EditContactInfo) => void
}

export function EditInfoPopup(props: Props) {
    const {isShow, editingUser, onClose, onSubmitSuccess} = props
    const [user, setUser] = useState({...editingUser})
    const [isSubmitting, setIsSubmitting] = useState(false)

    const isChanged = useMemo(() => {
        const newData = pick(user, ["firstName", "lastName", "email"])
        const oldData = pick(editingUser, ["firstName", "lastName", "email"])
        return !!getValuesChangeTwoObj(newData, oldData)
    }, [user, editingUser])

    const onChangeField = (key, value) => {
        user[key] = value
        setUser({...user})
    }

    const validateData = () => {
        if (!user.firstName) {
            toastError("Please enter first name")
            return false
        }
        if (!user.lastName) {
            toastError("Please enter last name")
            return false
        }
        if (!user.email) {
            toastError("Please enter email")
            return false
        }
        return true
    }

    const saveUser = async () => {
        if (!validateData()) {
            return
        }
        try {
            setIsSubmitting(true)
            await userPersonalContactsServiceV3.update(user.contactId, {
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email
            })
            toastSuccess("Updated successfully")
            setIsSubmitting(false)
            onSubmitSuccess(user)
        } catch (error) {
            setIsSubmitting(false)
            handleError(error)
        }
    }

    return (
        <BasePopup isShow={isShow} onClose={onClose} width="70vw">
            <div className={styles.wrap}>
                <p className={styles.title}>Update Contact Information</p>
                <div className={styles.body}>
                    <div className={styles.formItem}>
                        <FormLabel label="First name" />
                        <BaseInput
                            value={user.firstName}
                            onChange={(value) => onChangeField("firstName", value)}
                            placeholder="First name"
                        />
                    </div>
                    <div className={styles.formItem}>
                        <FormLabel label="Last name" />
                        <BaseInput
                            value={user.lastName}
                            onChange={(value) => onChangeField("lastName", value)}
                            placeholder="Last name"
                        />
                    </div>
                    <div className={styles.formItem}>
                        <FormLabel label="Email" />
                        <BaseInput
                            value={user.email}
                            onChange={(value) => onChangeField("email", value)}
                            placeholder="Email"
                        />
                    </div>
                </div>
                <div className={styles.actions}>
                    <BaseButton title="Cancel" variant="secondary" onClick={onClose} />
                    <BaseButton title="Save" loading={isSubmitting} isActive={isChanged} onClick={saveUser} />
                </div>
            </div>
        </BasePopup>
    )
}
