import React from "react"
import styles from "./CreditBox.module.css"
import {Icon} from "components/Icon"

export const CreditBox = ({title, amount, ...props}) => (
    <div className={styles.balanceBox} {...props}>
        <div className={styles.balanceBoxTitle}>{title}</div>
        <div>
            <Icon className={styles.balanceBoxIcon} icon="DOLLAR_SIGN" color="#FFF"></Icon>
            <span className={styles.balanceBoxBody}>{amount}</span>
        </div>
    </div>
)
