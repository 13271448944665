/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react"
import {useTranslation} from "react-i18next"
import {BasePopup} from "components/popup"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {KlassappTableProps} from "types/common"
import {courseService} from "services"
import styles from "./PrerequisitesRequired.module.css"

type Props = KlassappTableProps & {
    isShow: boolean
    onClose: () => void
    courseIds: number[]
}

function PrerequisitesRequired(props: Props) {
    const {isShow, onClose, courseIds} = props

    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        tableHeaderActions,
        orderField,
        isLoading,
        menuActions,
        isHideMenuActions,
        isLoadedTableFuncs,
        isShowTableHeaderAction
    } = props
    const {t} = useTranslation(["course"])

    const getFields = () => {
        return [t("course.code"), t("course.name"), t("course.instructor"), t("course.hours")]
    }

    const getPageTitle = () => {
        return ""
    }

    const getColumns = () => {
        return [
            {
                title: t("course.code"),
                field: "code"
            },
            {
                title: t("course.name"),
                field: "name",
                style: {width: "250px"}
            },
            {
                title: t("course.instructor"),
                field: "instructor"
            },
            {
                title: t("course.hours"),
                field: "total"
            }
        ]
    }

    const getData = async () => {
        dispatch({isLoading: true})
        try {
            const {data: courses, total} = await courseService.getAll({
                filter: {
                    courseIds
                }
            })
            const newCourses = courses.map((course: any) => {
                course.id = course.courseId
                return course
            })
            dispatch({data: newCourses, total})
        } catch (e) {
            console.log(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getData()
        }
    }, [isLoadedTableFuncs, page, pageSize, orderField?.field, orderField?.order])

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
    }, [])

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="FILE_TEXT" leftIconColor="#fff" width="60vw">
            <div className={styles.wrap}>
                <p className={styles.title}>Prerequisites</p>
                <KlassappTableHeader
                    isShowAction={isShowTableHeaderAction}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={props.onChangePage}
                    onChangeRowPerPage={props.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={props.onChangeFields}
                    onChangeAllFields={props.onChangeAllFields}
                    onDraggableColumn={props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    fields={fields}
                    data={data}
                    menuActions={isHideMenuActions ? [] : menuActions}
                    isLoading={isLoading}
                    allFields={allFields}
                    orderField={orderField}
                    onClickRowItem={() => {}}
                    onChangeFields={props.onChangeFields}
                    onUpdateRowData={props.onUpdateRowData}
                    onUpdateTableData={props.onUpdateTableData}
                    onClickSortColumn={props.onClickSortColumn}
                    onDraggableColumn={props.onDraggableColumn}
                    onChangeAllFields={props.onChangeAllFields}
                />
            </div>
        </BasePopup>
    )
}

export default KlassappTableHOC(PrerequisitesRequired)
