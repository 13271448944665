import React, {useContext, useEffect} from "react"
import cx from "classnames"
import {BaseButton} from "components"
import moment from "moment"
import {studentAccountService, studentLedgerService} from "services"
import {formatCurrency} from "helpers"
import styles from "sections/shared/student/StudentAccountPanelInfo/StudentAccountPanelInfo.module.css"
import {StudentPaymentContext} from "context/StudentPaymentContext"
import {StudentChargeStatus} from "types/student-account/student-ledger"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {Auth} from "types/auth"

interface Props {
    student?: Auth.DepartmentStudent
}

const StudentAccountPanelInfo = ({student}: Props) => {
    const {paymentPopup, setStudentId} = useContext(StudentPaymentContext)
    const [amountDue, setAmountDue] = React.useState(0)
    const [amountPastDue, setAmountPastDue] = React.useState(0)

    useEffect(() => {
        setStudentId(student?.profileId)
        ;(async function loadDueCharges() {
            setAmountDue(0)
            setAmountPastDue(0)
            if (!student?.profileId) return
            try {
                const {data: incompletedCharges} = await studentLedgerService.getCharges({
                    filter: {
                        profileIds: [student.profileId],
                        statuses: [StudentChargeStatus.Pending, StudentChargeStatus.InProgress]
                    },
                    range: {
                        pageSize: UNLIMITED_PAGE_SIZE,
                        page: 1
                    }
                })
                const {items: planItems, paymentPlans} = await studentAccountService.getIncompletePaymentPlans({
                    profileId: student.profileId
                })

                let amountDue = 0,
                    amountPastDue = 0
                const chargeIdsFromPaymentPlan: number[] = []

                for (const item of planItems) {
                    if (item.paidAmount < item.amount) {
                        if (moment().isAfter(item.dueDate)) {
                            amountPastDue += Math.max(0, item.amount - item.paidAmount)
                        } else if (moment().add(20, "day").isAfter(item.dueDate)) {
                            amountDue += Math.max(0, item.amount - item.paidAmount)
                        }
                        const paymentPlan = paymentPlans.find((plan) => plan.paymentPlanId === item.paymentPlanId)
                        chargeIdsFromPaymentPlan.push(
                            ...(paymentPlan?.charges ?? [])
                                .filter((charge) => charge.status !== StudentChargeStatus.Completed)
                                .map((charge) => charge.chargeId)
                        )
                    }
                }

                for (const charge of incompletedCharges) {
                    if (chargeIdsFromPaymentPlan.includes(charge.chargeId)) continue
                    if (!charge.dueDate) continue
                    if (moment().isAfter(charge.dueDate)) {
                        amountPastDue += Math.max(0, charge.amount - charge.paidAmount)
                    } else if (moment().add(20, "day").isAfter(charge.dueDate)) {
                        amountDue += Math.max(0, charge.amount - charge.paidAmount)
                    }
                }

                setAmountDue(amountDue)
                setAmountPastDue(amountPastDue)
            } catch (error) {
                console.error(error)
                setAmountDue(0)
                setAmountPastDue(0)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [student?.profileId])

    return (
        <div>
            <div className={styles.studentWrap}>
                <div className={styles.headingWrap}>
                    <div className={styles.headingTitle}>Amount Due</div>
                </div>
                <div className={cx(styles.infoBlock, {[styles.due]: !!amountDue})}>
                    <div className={styles.contentWrap}>
                        <div className={cx(styles.contentTitle, styles.dark)}>Amount Due</div>
                        <div className={cx(styles.contentValue, {[styles.due]: !!amountDue})}>
                            {formatCurrency(amountDue)}
                        </div>
                    </div>
                    {!!amountPastDue && (
                        <div className={styles.contentWrap}>
                            <div className={cx(styles.contentTitle, styles.dark)}>Past Due</div>
                            <div className={cx(styles.contentValue, {[styles.due]: amountPastDue})}>
                                {formatCurrency(amountPastDue)}
                            </div>
                        </div>
                    )}
                    <div className={styles.contentWrap}>
                        <BaseButton size="small" title="Make Payment" onClick={paymentPopup.open} className="w-full" />
                    </div>
                </div>
            </div>
            {/*
            <div className={styles.studentWrap}>
                <div className={styles.headingWrap}>
                    <div className={styles.headingTitle}>Student Account Details</div>
                </div>
                <div className={styles.contentWrap}>
                    <div className={styles.contentTitle}>Student Billing Rate</div>
                    <div className={styles.contentValue}>-</div>
                </div>
                <div className={styles.contentWrap}>
                    <div className={styles.contentTitle}>Prev Year(s) Outstanding Balance</div>
                    <div className={styles.contentValue}>{formatCurrency(0)}</div>
                </div>
                <div className={styles.contentWrap}>
                    <div className={styles.contentTitle}>Current Year Outstanding Balance</div>
                    <div className={styles.contentValue}>{formatCurrency(2400)}</div>
                </div>
                <div className={styles.contentWrap}>
                    <div className={styles.contentTitle}>Ay Future Outstanding Balance</div>
                    <div className={styles.contentValue}>{formatCurrency(12400)}</div>
                </div>
                <div className={styles.contentWrap}>
                    <div className={styles.contentTitle}>Total Outstanding Balance</div>
                    <div className={cx(styles.contentValue, styles.active)}>-</div>
                </div>
            </div>
            */}
        </div>
    )
}

export default StudentAccountPanelInfo
