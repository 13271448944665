import BaseService from "./Base"
import {ApiListResponse, ApiResponse} from "types/api"
import {AxiosResponse} from "axios"
import {Group} from "types/groups"

class GroupsService extends BaseService {
    getAll = async (body = {}) => {
        const {data: response}: AxiosResponse<ApiListResponse<Array<Group>>> = await this.post("/v1/groups/list", body)
        if (!response.success) {
            throw response
        }

        return {
            data: response.data,
            total: response.total
        }
    }

    getDetails = async (groupId: number): Promise<any | null> => {
        const {data: response}: AxiosResponse<ApiResponse<any>> = await this.post("/v1/groups/details", {
            groupId
        })

        if (!response.success) {
            throw response
        }

        return response.data
    }

    create = async (payload: any) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/groups/create", payload)
        if (!response.success) {
            throw response
        }
    }

    update = async (payload: any) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/groups/edit", payload)

        if (!response.success) {
            throw response
        }
    }

    deleteBulk = async (groupIds: number[]) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/groups/delete", {
            groupIds
        })

        if (!response.success) {
            throw response
        }
    }
}

export default GroupsService
