import React from "react"
import cx from "classnames"
import {Icon, IconType} from "components/Icon"
import styles from "./TabHeader.module.css"

type Props = {
    title: string
    icon?: IconType
    indicator?: JSX.Element
}

export function TabHeader(props: Props) {
    const {title, icon, indicator} = props

    return (
        <div className={styles.tabHeader}>
            {icon && <Icon className={cx(styles.tabIcon, "tabIcon")} icon={icon} />}
            <span className={cx(styles.tabTitle, "tabTitle")}>{title}</span>
            {indicator}
        </div>
    )
}
