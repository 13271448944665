import {get} from "lodash"
import {BaseService} from "services"

export default class MediaPrivacyService extends BaseService {
    async list(body = {}) {
        const response = await this.post(`/v1/settings/media-privacies/list`, body)
        return get(response, "data", [])
    }

    async update(body = {}) {
        const response = await this.post("/v1/settings/media-privacies/update", body)
        return get(response, "data", [])
    }
}
