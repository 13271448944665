import BaseService from "./Base"
import {get} from "lodash"

const basePath: string = "/v1/users/profile/campus"

class ProfileCampusService extends BaseService {
    create = async (params = {}) => {
        const response = await this.post(`${basePath}/create`, params)
        return get(response, "data.data", {})
    }

    getAll = async (params = {}) => {
        const response = await this.post(`${basePath}/list`, params)
        return get(response, "data", {})
    }
}
export default ProfileCampusService
