import {get} from "lodash"
import BaseService from "./Base"
import {DisbursementLog, Disbursement} from "types/fin-aid/disbursement"
import {DeepPartial} from "types/common"
import {UserDocument} from "types/user-documents"

class DisbursementService extends BaseService {
    list = async (params = {}): Promise<{total: number; data: Disbursement[]}> => {
        const response = await this.post("/v1/disbursements/list", params)
        return get(response, "data")
    }

    getOne = async (disbursementId: number): Promise<Disbursement> => {
        const response = await this.get(`/v1/disbursements/${disbursementId}`)
        return get(response, "data.data")
    }

    create = async (data: DeepPartial<Disbursement>): Promise<Disbursement> => {
        const response = await this.post("/v1/disbursements/create", data)
        return get(response, "data.data", {})
    }

    update = async (data: DeepPartial<Disbursement>): Promise<Disbursement> => {
        const response = await this.post("/v1/disbursements/edit", data)
        return get(response, "data.data", {})
    }

    bulkDelete = async (ids: number[]): Promise<void> => {
        await this.post(`/v1/disbursements/delete`, {ids})
    }

    requestDisbursement = async (id: number): Promise<string> => {
        const response = await this.post("/v1/disbursements/request", {id})
        return get(response, "data.data", "")
    }

    getStats = async (): Promise<any> => {
        const response = await this.get(`/v1/disbursements/stats`)
        return get(response, "data.data")
    }

    getPeriods = async (): Promise<Array<{start: string; end: string}>> => {
        const response = await this.get(`/v1/disbursements/periods`)
        return get(response, "data.data", [])
    }

    logList = async (params = {}): Promise<{total: number; data: DisbursementLog[]}> => {
        const response = await this.post("/v1/disbursement-logs/list", params)
        return get(response, "data")
    }

    responsesList = async (params = {}): Promise<{total: number; data: UserDocument[]}> => {
        const response = await this.post("/v1/disbursements/responses/list", params)
        return get(response, "data.data", {})
    }

    responsesUpload = async (files: File[]): Promise<UserDocument[]> => {
        const formData = new FormData()
        for (const file of files) {
            formData.append(`files`, file)
        }
        const response = await this.post("/v1/disbursements/responses/upload", formData)
        return get(response, "data.data", [])
    }
}

export default DisbursementService
