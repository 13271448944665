import {get} from "lodash"
import BaseService from "./Base"
import {StudentMarketingFieldsDetail} from "types/admissions/student-marketing-fields"

class StudentMarketingFieldsService extends BaseService {
    async getStudentMarketingFields(studentProfileId: number): Promise<StudentMarketingFieldsDetail> {
        const response = await this.post("/v1/admissions/student-marketing-fields/list", {studentProfileId})
        return get(response, "data.data", {})
    }
}

export default StudentMarketingFieldsService
