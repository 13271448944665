import React from "react"
import {useTranslation} from "react-i18next"
import {departmentsService, settingActivityService} from "services"
import {KlassDropdown, KlassDropdownAsyncProps} from "../Select"
import {handleError} from "helpers"
import {UNLIMITED_PAGE_SIZE} from "data/constants"

type Props = Partial<KlassDropdownAsyncProps> & {
    departmentId?: number
}

const DepartmentActivitySelect: React.FC<Props> = ({departmentId, ...props}) => {
    const {t} = useTranslation(["common"])
    const [activities, setActivities] = React.useState<any[]>([])

    React.useEffect(() => {
        if (!departmentId) {
            setActivities([])
            return
        }
        ;(async function loadActivities() {
            try {
                let moduleDepartmentId
                const moduleDepartment = await departmentsService.getOne(departmentId)
                moduleDepartmentId = moduleDepartment?.useActivitiesFromDepartmentId
                const {data: activities} = await settingActivityService.getAllActivities({
                    filter: {
                        departmentIds: moduleDepartmentId ? [moduleDepartmentId] : [departmentId],
                        isActive: true
                    },
                    range: {
                        page: 1,
                        pageSize: UNLIMITED_PAGE_SIZE
                    },
                    sort: {
                        orderBy: "name",
                        orderDir: "asc"
                    }
                })
                setActivities(activities.map((activity) => ({...activity, id: activity.activityId})))
            } catch (error) {
                handleError(error)
                setActivities([])
            }
        })()
    }, [departmentId])

    return (
        <KlassDropdown
            placeholder={props.placeholder || t("selectField.placeholder")}
            options={activities}
            {...props}
        />
    )
}

export default DepartmentActivitySelect
