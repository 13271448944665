/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useReducer} from "react"
import {useTranslation} from "react-i18next"
import {Radio} from "antd"
import classNames from "classnames"
import {keyBy} from "lodash"
import {BaseButton, SecondaryButton} from "components/buttons"
import {FormLabel} from "components/Form"
import {KlassDropdown, KlassDropAsyncPaginate} from "components/Select"
import {ParticipantType, SelectStaffFrom, TaskSubtype, UserType} from "types/tasks"
import {handleError, getFullName, toastError} from "helpers"
import {studentStaffContactRolesServiceV3, studentStaffContactsServiceV3} from "services"
import {PrimaryDescription} from "../Common"
import styles from "./StaffSignerSelecting.module.css"
import {getFirstProfile} from "helpers/task"
import {Auth} from "types/auth"

const ignoreRoles = ["staff", "instructor"]

function reducer(state, action) {
    return {...state, ...action}
}

export function StaffSignerSelecting(props) {
    const {signer, subtype, onCancelSelecting, onSaveSelecting, onUpdateSignerItem, primarySigner, onSearchChange} =
        props
    const initialState = {
        roles: [],
        studentStaffContactRole: null,
        isSaving: false
    }
    const [{roles, studentStaffContactRole, isSaving}, dispatch] = useReducer(reducer, initialState)
    const {t} = useTranslation(["tasks", "common"])

    useEffect(() => {
        dispatch({studentStaffContactRole: signer.studentStaffContactRole})
        getStaffContactByRole()
    }, [])

    const getStaffContactByRole = async () => {
        try {
            const {data} = await studentStaffContactRolesServiceV3.getAll()
            const roles = data.filter((role) => {
                return !ignoreRoles.some((ignoreRole) => role.name.toLowerCase().includes(ignoreRole))
            })
            dispatch({roles})
        } catch (error) {
            handleError(error)
        }
    }

    const getStaffsFromContactRole = async () => {
        try {
            dispatch({isSaving: true})
            let profileIds = []
            if (primarySigner.userType === UserType.STUDENT) {
                profileIds = (primarySigner.students || [])
                    .map((student) => student.profileId)
                    .filter((profileId) => profileId)
            } else if (primarySigner.userType === UserType.STAFF) {
                profileIds = (primarySigner.staffs || [])
                    .map((staff) => staff.profileId)
                    .filter((profileId) => profileId)
            }
            const studentStaffContactRoleIds = studentStaffContactRole?.studentStaffContactRoleId
                ? [studentStaffContactRole.studentStaffContactRoleId]
                : []
            let withDirectorContact = false
            if (
                studentStaffContactRole?.name &&
                studentStaffContactRole.name.toLowerCase().includes("campus director")
            ) {
                withDirectorContact = true
            }
            const {data} = await studentStaffContactsServiceV3.getAll({
                filter: {
                    profileIds,
                    studentStaffContactRoleIds,
                    isDefault: true,
                    withDirectorContact
                },
                linkedEntities: true
            })
            const dataByProfileId = keyBy(data, "profileId")
            return profileIds.map((profileId) => {
                if (dataByProfileId[profileId]) {
                    return dataByProfileId[profileId]
                }
                return {profileId}
            })
        } catch (error) {
            handleError(error)
            return []
        } finally {
            dispatch({isSaving: false})
        }
    }

    const onChangeStaffContactRole = (newValue) => {
        dispatch({studentStaffContactRole: newValue})
    }

    const onChangeSelectedType = (value) => {
        onUpdateSignerItem(signer.id, {...signer, selectedType: value})
    }

    const onChangeSelectedStaffs = (newStaffs, isPrimary) => {
        const staffs = isPrimary ? newStaffs : [newStaffs]
        onUpdateSignerItem(signer.id, {...signer, staffs})
    }

    const onClickSave = async () => {
        if (signer.selectedType === SelectStaffFrom.NO_DEFAULT) {
            onSaveSelecting({...signer})
        } else if (
            signer.participantType !== ParticipantType.PrimarySigner &&
            signer.selectedType === SelectStaffFrom.ROLE
        ) {
            if (!studentStaffContactRole || !studentStaffContactRole.studentStaffContactRoleId) {
                toastError(t("common:validation.hasToBeSelect", {field: "Relationship"}))
                return
            }
            let staffs = []
            if (primarySigner && subtype !== TaskSubtype.LIBRARY) {
                staffs = await getStaffsFromContactRole()
            }
            onSaveSelecting({...signer, staffs, studentStaffContactRole})
        } else {
            if (!signer.staffs || !signer.staffs.length) {
                toastError(t("common:validation.hasToBeSelect", {field: "Staff"}))
                return
            }
            const staffs = signer.staffs.map((staff) => {
                const activeProfile = getFirstProfile(staff, Auth.UserProfileType.Staff)
                return {
                    ...staff,
                    profileId: activeProfile?.id,
                    activeProfile
                }
            })
            onSaveSelecting({...signer, staffs, studentStaffContactRole: undefined})
        }
    }

    const getRelationShipByPrimary = (userType) => {
        switch (userType) {
            case UserType.STUDENT:
                return "Relationship to Student"
            case UserType.STAFF:
                return "Relationship to Staff"
            case UserType.OTHERS:
                return "Relationship to Others"
            default:
                return "Relationship"
        }
    }

    const renderContent = () => {
        if (subtype === TaskSubtype.LIBRARY && signer.selectedType === SelectStaffFrom.NO_DEFAULT) {
            return null
        }
        if (signer.participantType !== ParticipantType.PrimarySigner && signer.selectedType === SelectStaffFrom.ROLE) {
            return (
                <>
                    <FormLabel label={getRelationShipByPrimary(primarySigner?.userType)} />
                    <KlassDropdown
                        options={roles}
                        valueKey="studentStaffContactRoleId"
                        value={studentStaffContactRole}
                        onChange={onChangeStaffContactRole}
                        placeholder="Relationship"
                    />
                </>
            )
        }
        return (
            <>
                <FormLabel label="Select Staff" />
                <KlassDropAsyncPaginate
                    value={signer.staffs}
                    onChange={(newValue) =>
                        onChangeSelectedStaffs(newValue, signer.participantType === ParticipantType.PrimarySigner)
                    }
                    getOptionLabel={(option: any) => getFullName(option)}
                    loadOptions={(input, loadedOptions) => onSearchChange("staffUsers", input, loadedOptions)}
                    isMulti={signer.participantType === ParticipantType.PrimarySigner}
                />
            </>
        )
    }

    const renderPrimaryInfo = () => {
        if (subtype === TaskSubtype.LIBRARY && signer.selectedType === SelectStaffFrom.NO_DEFAULT) {
            return null
        }
        return (
            <div className={styles.primaryDescWrap}>
                <PrimaryDescription isPrimary={signer.participantType === ParticipantType.PrimarySigner} />
            </div>
        )
    }

    return (
        <div className={styles.wrap}>
            <div className={styles.chooseTypeWrap}>
                <Radio.Group
                    onChange={(event) => onChangeSelectedType(event.target.value)}
                    value={signer.selectedType}
                    className={styles.radioBtnWrap}>
                    {subtype === TaskSubtype.LIBRARY && (
                        <Radio
                            value={SelectStaffFrom.NO_DEFAULT}
                            className={classNames(styles.radioBtn__item, {
                                [styles.radioBtn__active]: signer.selectedType === SelectStaffFrom.NO_DEFAULT
                            })}>
                            No Default
                        </Radio>
                    )}
                    <Radio
                        value={SelectStaffFrom.DIRECTORY}
                        className={classNames(styles.radioBtn__item, {
                            [styles.radioBtn__active]: signer.selectedType === SelectStaffFrom.DIRECTORY
                        })}>
                        Select from directory
                    </Radio>
                    {signer.participantType !== ParticipantType.PrimarySigner && (
                        <Radio
                            value={SelectStaffFrom.ROLE}
                            className={classNames(styles.radioBtn__item, {
                                [styles.radioBtn__active]: signer.selectedType === SelectStaffFrom.ROLE
                            })}>
                            Select by relationship
                        </Radio>
                    )}
                </Radio.Group>
            </div>
            {renderPrimaryInfo()}
            {renderContent()}
            <div className={styles.actionBtnWrap}>
                <SecondaryButton
                    title={t("common:action.cancel")}
                    className={styles.cancelBtn}
                    onClick={() => onCancelSelecting(signer)}
                />
                <BaseButton title={t("common:action.save")} onClick={onClickSave} loading={isSaving} />
            </div>
        </div>
    )
}
