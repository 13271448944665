import React from "react"
import {forEach, get, isEmpty, isNumber, keys, range} from "lodash"
import moment from "moment"
import {Major} from "types/major"
import styles from "./CourseItem.module.css"
import {getScheduleEventColor} from "helpers/calendar"
import {Tooltip} from "antd"
import {useModel} from "hooks"
import {Auth} from "types/auth"

type Props = {
    dateRange: number
    times: string[]
    eventData: Record<string, Major.EventSequence[]>
    prevCourseDay?: number
    isMultiCourse?: boolean
    onClickAddEvent: (event: Major.EventSequence, {time, day}: {time: string; day: number}) => void
}

export function TimeContent(props: Props) {
    const {times, dateRange, eventData, prevCourseDay, onClickAddEvent} = props
    const model = useModel()
    const format = model.user?.timeFormat === Auth.TimeFormat.Time_12 ? "hh:mm A" : "HH:mm"

    const getCellColor = ({day, timeStr}) => {
        if (!isNumber(prevCourseDay)) {
            return null
        }
        const [hour] = timeStr.split(":")
        const events = []
        keys(eventData).forEach((eventKey) => {
            const [eventDay, eventTime] = eventKey.split("_")
            const [eventHour, eventMinute] = eventTime.split(":")
            const eventsInfo =
                +(prevCourseDay + day) === +eventDay &&
                +eventHour === +hour &&
                eventData[`${prevCourseDay + day}_${hour}:${eventMinute}`]
            if (eventsInfo) {
                forEach(eventsInfo, (event: any) => {
                    const courseModule = get(event, "type")
                    const color = getScheduleEventColor(courseModule)
                    event.color = color
                    event.offset = +eventMinute / 60
                    if (event.isLastEvent) {
                        const endTime = moment(event.endTime)
                            .startOf("date")
                            .set("hour", +eventHour)
                            .set("minute", +eventMinute)
                        const offsetMinute = moment(event.endTime).diff(moment(endTime), "minutes")
                        event.offsetHeight = (offsetMinute - 60) / 60
                    }
                    events.push(event)
                })
            }
        })
        return events
    }

    const renderEvent = (event, {time, day}) => {
        return (
            <div
                className={styles.timeCellItem__event}
                style={{
                    background: event.color,
                    top: `${32 * event.offset}px`,
                    height: event.offsetHeight ? `${32 + 32 * event.offsetHeight}px` : "32px"
                }}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onClickAddEvent(event, {time, day})
                }}
            />
        )
    }

    return (
        <div>
            {times.map((time, index) => {
                const timeStr = moment(time).format("HH:mm")
                return (
                    <div key={index} className={styles.timeWrap}>
                        <div className={styles.timeCellWrap}>
                            {range(1, dateRange).map((day) => {
                                const events = getCellColor({timeStr, day})
                                if (isEmpty(events)) {
                                    return (
                                        <div
                                            key={`${timeStr}_${day}_${index}`}
                                            className={styles.timeCellItem}
                                            style={{background: "#fff"}}
                                            onClick={() => onClickAddEvent(null, {time, day})}
                                        />
                                    )
                                }
                                return (
                                    <div
                                        key={`${timeStr}_${day}_${index}`}
                                        className={styles.timeCellItem}
                                        onClick={() => onClickAddEvent(null, {time, day})}>
                                        {events.map((event: any, index) => {
                                            const {courseCode, courseName, startTime, endTime} = event
                                            const timeInfo = `${moment(startTime).format(format)}-${moment(
                                                endTime
                                            ).format(format)}`
                                            return (
                                                <Tooltip
                                                    title={`[${courseCode}] ${courseName}, ${timeInfo}`}
                                                    key={`${event.id}_${timeStr}_${day}_${index}`}>
                                                    {renderEvent(event, {time, day})}
                                                </Tooltip>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
