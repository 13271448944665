import React from "react"
import {BasePopup} from "components/popup"
import {BaseButton} from "components/buttons"
import styles from "./popup.module.css"

export function ReorderFundsPopup(props) {
    const {isShow, onClose} = props

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="DOLLAR_SIGN" leftIconColor="#fff" width="70vw">
            <div className={styles.wrap}>
                <p className={styles.title}>Reorder Funds</p>
                <div className={styles.bodyWrap}>
                    <p>Please confirm reorder</p>
                </div>
                <div className={styles.buttonWrap}>
                    <BaseButton title="Send" />
                </div>
            </div>
        </BasePopup>
    )
}
