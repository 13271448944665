import React, {useCallback, useEffect, useState} from "react"
import styles from "./LessonTable.module.css"
import TermSelect from "components/TermSelect"
import {KlassDropAsyncPaginate, KlassDropdown} from "components/Select"
import ListLessons from "../AddMakeupAttendance/ListLessons"
import {Schedule, TermDetails} from "types/terms"
import {Course} from "types/courses"
import {formatCodeName, handleError} from "helpers"
import {Auth} from "types/auth"
import {courseScheduleService, instructionalService} from "services"
import debounce from "debounce-promise"

type LessonTableProps = {
    selectedStudent: Auth.UserProfile
    setListLessons: (
        lessons: Array<{
            scheduleId: number
            courseId: number
            termId: number
            id: number
            isChecked: boolean
            calendarEventId: number
        }>
    ) => void
}

const LessonTable = (props: LessonTableProps) => {
    const {selectedStudent, setListLessons} = props
    const [filters, setFilters] = useState<{
        term: TermDetails
        course: Course.Course
        schedule: Schedule
    }>({
        term: undefined,
        course: undefined,
        schedule: undefined
    })
    const [schedules, setSchedules] = useState([])
    const searchCourse = useCallback(
        async (search: string = "", loadedOptions) => {
            try {
                const pageSize = 20
                const page = Math.ceil(loadedOptions.length / pageSize) + 1
                const {data, total} = await instructionalService.getMyCourse({
                    range: {page, pageSize},
                    filter: {
                        search,
                        termIds: [filters.term?.id],
                        isPracticalOrClinicalServices: false
                    }
                })
                return {
                    options: data.map((item) => ({
                        ...item,
                        name: item.courseName,
                        code: item.courseCode
                    })),
                    hasMore: loadedOptions.length < total
                }
            } catch (e) {
                handleError(e)
                return {
                    options: [],
                    hasMore: false
                }
            }
        },
        [filters.term]
    )
    const getSchedules = useCallback(async () => {
        try {
            const {data} = await courseScheduleService.courseScheduleGet({
                filter: {
                    term_id: filters.term?.id,
                    course_id: filters.course.courseId
                }
            })
            setSchedules(data)
        } catch (e) {
            handleError(e)
        }
    }, [filters.term, filters.course])

    useEffect(() => {
        if (filters.course) {
            getSchedules()
        }
    }, [getSchedules, filters.course])
    const onSearchCourse = debounce(searchCourse, 300)

    return (
        <>
            <div className={styles.filterLesson}>
                <div className={styles.selectItem}>
                    <span className={styles.selectLabel}>SELECT TERM</span>
                    <TermSelect
                        placeholder={"SELECT"}
                        value={filters.term}
                        stylesCustom={{
                            container: (provided, state) => {
                                return {
                                    ...provided,
                                    width: "100%"
                                }
                            }
                        }}
                        onChange={(newValue) => setFilters({...filters, term: newValue})}
                    />
                </div>
                <div className={styles.selectItem}>
                    <span className={styles.selectLabel}>SELECT COURSE</span>
                    <KlassDropAsyncPaginate
                        key={filters?.term?.id}
                        stylesCustom={{
                            container: (provided, state) => {
                                return {
                                    ...provided,
                                    width: "100%"
                                }
                            }
                        }}
                        isDisabled={!filters.term}
                        isClearable
                        getOptionLabel={formatCodeName}
                        value={filters.course}
                        valueKey="courseId"
                        onChange={(value) => {
                            setFilters({
                                ...filters,
                                schedule: undefined,
                                course: value
                            })
                        }}
                        labelKey="courseName"
                        loadOptions={filters.term ? onSearchCourse : undefined}
                        placeholder="Select"
                    />
                </div>
                <div className={styles.selectItem}>
                    <span className={styles.selectLabel}>SELECT SCHEDULE</span>
                    <KlassDropdown
                        key={`${filters.term?.id} ${filters.course?.courseId}`}
                        options={schedules}
                        value={filters.schedule}
                        labelKey="schedule_suffix"
                        stylesCustom={{
                            container: (provided, state) => {
                                return {
                                    ...provided,
                                    width: "100%"
                                }
                            }
                        }}
                        isDisabled={!filters.course}
                        onChange={(value) => setFilters({...filters, schedule: value})}
                        isClearable
                    />
                </div>
            </div>
            <ListLessons
                term={filters.term}
                course={filters.course}
                schedule={filters.schedule}
                studentProfileId={selectedStudent?.id}
                setListLessons={setListLessons}
                timezone={selectedStudent?.timezone}
            />
        </>
    )
}

export default LessonTable
