import {get} from "lodash"
import BaseService from "./Base"

export type FieldDetail = {
    name: string
    type: string
    isRequired: boolean
    description: string
    options?: Array<string | number>
    example?: string
}

type LeadsImportAPIDocs = {
    examples: Array<{description: string; code: string; language?: string}>
    fields: FieldDetail[]
    clientUid: string
}

class LeadsService extends BaseService {
    async getDocs(): Promise<LeadsImportAPIDocs> {
        const response = await this.get("/v1/leads/create/docs")
        return get(response, "data.data", {})
    }
}

export default LeadsService
