import React, {useState} from "react"
import {Tooltip} from "antd"
import {observer} from "mobx-react"
import {useHistory} from "react-router-dom"
import {isEmpty} from "lodash"
import {useMsal} from "@azure/msal-react"
import classNames from "classnames"
import {useTranslation} from "react-i18next"
import {Icon, IconType} from "components/Icon"
import {useModel} from "hooks/useModel"
import {useCurrentProfilePermissions} from "hooks"
import {Permissions} from "types/permission"
import {useEdularModulesContext, Modules} from "@edular/modules"
import {Language, UserIcon} from "./parts"
import styles from "./InfoBar.module.css"

interface Item {
    title: string
    icon: IconType
    attentionCount?: number
    to?: string
    action?: string
}

interface Props {
    hidden?: boolean
    photo: any
}

export const InfoBar = observer((props: Props) => {
    const model = useModel()
    const {isModuleEnable} = useEdularModulesContext()
    const history = useHistory()
    const {instance, accounts} = useMsal()
    const currentProfilePermissions = useCurrentProfilePermissions()

    const {hidden} = props
    const {t} = useTranslation(["sidebar"])
    const {user} = model
    if (!user) {
        return null
    }

    let {photo} = user
    if (photo) {
        photo = photo[64]
    }
    const userData = {
        userId: user?.id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        image: photo
    }

    const getKeysByRole = (): Item[] => {
        const canShowTasks =
            isEmpty(model.departments) || !model.isStaff()
                ? true
                : model.departments.some((department) => {
                      return currentProfilePermissions.departmentTasks.hasPermissionsToView(department.id)
                  })
        return [
            {
                title: t("menu.concerns"),
                icon: "QUESTION_OPEN",
                to: "/concerns"
            },
            isModuleEnable(Modules.ActivitiesAndFollowups) && {
                title: t("menu.activities"),
                icon: "CHECKMARK_CIRCLE",
                to: "/activities"
            },
            {
                title: t("menu.Notification"),
                icon: "BELL",
                to: "/notifications",
                attentionCount: model?.chatOptions?.unreadNotification || 0
            },
            isModuleEnable(Modules.Chat) && {
                title: t("menu.Messages"),
                icon: "MESSAGE_CIRCLE",
                to: "/communication",
                attentionCount: model?.chatOptions?.unreadCount || 0
            },
            isModuleEnable(Modules.Tasks) &&
                canShowTasks && {
                    title: t("menu.Tasks"),
                    icon: "TASK_FILL",
                    to: "/tasks"
                },
            currentProfilePermissions.hasPermissions({
                staff: [Permissions.Staff.Resources.MediaLibrary.General.View],
                student: [Permissions.Student.Resources.MediaLibrary.View],
                others: [Permissions.Others.Resources.MediaLibrary.View]
            }) && {
                title: t("menu.Favorite"),
                icon: "STAR_FILL",
                to: "/favorite"
            },
            isModuleEnable(Modules.Calendar) && {
                title: t("menu.Calendar"),
                icon: "CALENDAR_FILL_INPUT",
                to: "/calendar"
            },
            model.clientSetting.isSupportChatEnabled && {
                title: t("menu.supportHub"),
                icon: "SUPPORT_HUB",
                to: "/supports"
            },
            {
                title: t("menu.Logout"),
                icon: "LOGOUT",
                action: "logout"
            }
        ].filter((item: any) => item) as Item[]
    }

    const handleClick = async (item: Item) => {
        if (item.action === "logout") {
            await model.logout()
            const [currentAccount] = accounts
            if (currentAccount) {
                await instance.logoutRedirect({account: currentAccount, postLogoutRedirectUri: window.location.origin})
            } else {
                window.location.href = "/"
            }

            return
        }
        history.push(item.to)
    }
    if (hidden) {
        return null
    }

    return (
        <div className={styles.wrapper}>
            <div className={classNames(styles.button, styles.buttonTransparent)}>
                <Language />
            </div>
            <ul className={styles.list}>
                {getKeysByRole().map((item: Item) => {
                    return <Li item={item} key={item.title} onClick={handleClick} />
                })}
            </ul>
            <div className={styles.divider}></div>
            <button className={classNames(styles.button, styles.buttonTransparent)}>
                <UserIcon {...userData} />
            </button>
        </div>
    )
})

function onKeyDown(event) {
    const element: HTMLAnchorElement = event.currentTarget
    if (event.keyCode === 13 || event.keyCode === 32) {
        removeFocus(element)
    }
}

function removeFocus(element: HTMLElement) {
    if (element) {
        element.focus()
        element.blur()
    }
}

const Li = observer(({item, onClick}: {item: Item; onClick: (item: Item) => void}) => {
    return (
        <li className={styles.item} onClick={(e) => onClick(item)}>
            <Tooltip placement="right" title={item.title}>
                <button
                    className={styles.button}
                    onMouseDown={(e) => {
                        e.preventDefault()
                        const element: HTMLAnchorElement = e.currentTarget as unknown as HTMLAnchorElement
                        removeFocus(element)
                    }}
                    onKeyUp={(e) => {
                        onKeyDown(e)
                    }}>
                    {item.attentionCount > 0 && (
                        <div className={styles.buttonAttention}>
                            {item.attentionCount > 99 ? "+99" : item.attentionCount}
                        </div>
                    )}
                    <Icon className={styles.iconItem} icon={item.icon} color="var(--primary-400-base)" />
                </button>
            </Tooltip>
        </li>
    )
})
