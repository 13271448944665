import React from "react"
import {Icon} from "components/Icon"
import {TaskDueDate} from "./TaskDueDate"
import styles from "./Tasks.module.css"
import {tasks} from "./rawData"

export class Tasks extends React.Component {
    render() {
        return (
            <div className={styles.wrap}>
                <div className={styles.header}>
                    <Icon icon="CLIPBOARD_FILL" color="#62B1FF" className={styles.headerIcon} />
                    <p className={styles.headerTitle}>Tasks</p>
                </div>
                <div className={styles.body}>
                    {tasks.map((task) => (
                        <div key={task.id} className={styles.item}>
                            <p className={styles.itemTitle}>{task.name}</p>
                            <TaskDueDate date={task.date} />
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}
