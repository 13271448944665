import React from "react"
import styles from "./ProgressReportPdf.module.css"
import {getFullName} from "helpers"
import moment from "moment"
import {useModel} from "hooks"

type OfficialTranscriptSignatureProps = {
    signatureBase64?: string
}

const OfficialTranscriptSignature = (props: OfficialTranscriptSignatureProps) => {
    const {signatureBase64} = props
    const model = useModel()

    return (
        <>
            {signatureBase64 ? (
                <div className={styles.signatureBottom}>
                    <div style={{flex: 1, display: "flex"}} />
                    <div className={styles.signatureBlock}>
                        <div className={styles.signatureForm}>
                            <div className={styles.signature}>
                                <span className={styles.signatureText}>Authorized Signature</span>
                                <div className={styles.signatureName}>
                                    <img src={signatureBase64} alt="signature" className={styles.signatureImage} />
                                    <span className={styles.signatureText}>{getFullName(model.user)}</span>
                                </div>
                            </div>
                            <span className={styles.signatureText}>
                                Date {moment().format(model.getUserDateFormat())}
                            </span>
                        </div>
                        <hr className={styles.line} style={{height: 0.5}} />
                    </div>
                </div>
            ) : null}
        </>
    )
}

export default OfficialTranscriptSignature
