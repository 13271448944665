import {BaseEditor, Descendant, Text} from "slate"
import {HistoryEditor} from "slate-history"
import {ReactEditor} from "slate-react"
import escapeHtml from "escape-html"
import {isEqual} from "lodash"

import {embedVideoLink} from "../../helpers"

export type EmptyText = {
    text: string
}

export type CustomText = EmptyText & {
    bold?: boolean
    italic?: boolean
    underline?: boolean
    code?: boolean
}

export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor

export type BlockQuoteElement = {
    type: "block-quote"
    align?: string
    children: Descendant[]
}

export type BulletedListElement = {
    type: "bulleted-list"
    align?: string
    children: Descendant[]
}

export type NumberedListElement = {
    type: "numbered-list"
    align?: string
    children: Descendant[]
}

export type CheckListItemElement = {
    type: "check-list-item"
    align?: string
    checked: boolean
    children: Descendant[]
}

export type EditableVoidElement = {
    type: "editable-void"
    align?: string
    children: EmptyText[]
}

export type HeadingElement = {
    type: "heading"
    align?: string
    children: Descendant[]
}

export type HeadingOneElement = {
    type: "heading-one"
    align?: string
    children: Descendant[]
}

export type HeadingTwoElement = {
    type: "heading-two"
    align?: string
    children: Descendant[]
}

export type TextAlignElement = {
    type: "left" | "right" | "center" | "justify"
    align?: string
    children: Descendant[]
}

export type LinkElement = {type: "link"; align?: string; url: string; children: Descendant[]}

export type EmbedVideoElement = {type: "embed-video"; align?: string; videoId: string; children: Descendant[]}

export type ListItemElement = {type: "list-item"; align?: string; children: Descendant[]}

export type MentionElement = {
    type: "mention"
    align?: string
    character: string
    children: CustomText[]
}

export type ParagraphElement = {
    type: "paragraph"
    align?: string
    children: Descendant[]
}

export type TableElement = {type: "table"; align?: string; children: TableRowElement[]}

export type TableRowElement = {type: "table-row"; align?: string; children: TableCellElement[]}

export type TableCellElement = {type: "table-cell"; align?: string; children: CustomText[]}

export type TitleElement = {type: "title"; align?: string; children: Descendant[]}

export type ImageElement = {
    type: "image"
    align?: string
    url: string
    children: EmptyText[]
}

export type FieldLabelElement = {
    type: "field-label"
    align?: string
    field: string
    id?: string
    value?: string
    children: EmptyText[]
}

export type FieldInputElement = {
    type: "field-input"
    align?: string
    input: "text" | "date" | "time"
    id?: string
    value?: any
    children: EmptyText[]
}

export type FinAidTableElement = {
    type: "fin-aid-table"
    align?: string
    table: "costs" | "grants" | "loans" | "disbursements"
    id?: string
    children: EmptyText[]
}

export type CustomElement =
    | BlockQuoteElement
    | BulletedListElement
    | NumberedListElement
    | CheckListItemElement
    | EditableVoidElement
    | HeadingElement
    | HeadingOneElement
    | HeadingTwoElement
    | TextAlignElement
    | ImageElement
    | LinkElement
    | EmbedVideoElement
    | ListItemElement
    | MentionElement
    | ParagraphElement
    | TableElement
    | TableRowElement
    | TableCellElement
    | TitleElement
    | FieldLabelElement
    | FieldInputElement
    | FinAidTableElement

export const TEXT_TYPES = [
    "paragraph",
    "heading",
    "heading-one",
    "heading-two",
    "block-quote",
    "link",
    "embed-video",
    "mention",
    "title"
]

export const LIST_TYPES = ["numbered-list", "bulleted-list"]

export const ELEMENT_TAGS = {
    A: (el) => ({type: "link", url: el.getAttribute("href")}),
    BLOCKQUOTE: () => ({type: "quote"}),
    H1: () => ({type: "heading-one"}),
    H2: () => ({type: "heading-two"}),
    H3: () => ({type: "heading-three"}),
    H4: () => ({type: "heading-four"}),
    H5: () => ({type: "heading-five"}),
    H6: () => ({type: "heading-six"}),
    IMG: (el) => ({type: "image", url: el.getAttribute("src")}),
    IFRAME: (el) => ({type: "embed-video", videoId: embedVideoLink(el.getAttribute("src"))}),
    LI: () => ({type: "list-item"}),
    OL: () => ({type: "numbered-list"}),
    P: () => ({type: "paragraph"}),
    PRE: () => ({type: "code"}),
    UL: () => ({type: "bulleted-list"}),
    TABLE: () => ({type: "table"}),
    TBODY: () => ({type: "tbody"}),
    THEAD: () => ({type: "thead"}),
    TR: () => ({type: "table-row"}),
    TD: () => ({type: "table-cell"}),
    TH: () => ({type: "table-cell-header"}),
    HEADER: () => ({type: "header"}),
    SECTION: () => ({type: "section"})
}

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
export const TEXT_TAGS = {
    CODE: () => ({code: true}),
    DEL: () => ({strikethrough: true}),
    EM: () => ({italic: true}),
    I: () => ({italic: true}),
    S: () => ({strikethrough: true}),
    STRONG: () => ({bold: true}),
    U: () => ({underline: true})
}

export const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"]

declare module "slate" {
    interface CustomTypes {
        Editor: CustomEditor
        Element: CustomElement
        Text: CustomText
    }
}

export const INITIAL_RTE_CONTENT: Descendant[] = [
    {
        type: "paragraph",
        children: [{text: ""}]
    }
]

export const isEmptyContent = (content?: Descendant[]): boolean => {
    return !content || !content.length || isEqual(content, INITIAL_RTE_CONTENT)
}

export const replaceFieldValues = (nodes: Descendant[], values: Record<string, string>): Descendant[] => {
    return nodes.map((node) => {
        if (Text.isText(node)) {
            return node
        }

        if (node.type === "field-label") {
            return {
                ...node,
                value: values[node.field] || node.value
            }
        }

        if (node.children?.length) {
            return {
                ...node,
                children: replaceFieldValues(node.children, values)
            } as CustomElement
        }

        return node
    })
}

const serializePlainTextNode = (node: Descendant): string => {
    if (Text.isText(node)) {
        return escapeHtml(node.text)
    }

    const children = node.children.map(serializePlainTextNode).join("")

    switch (node.type) {
        case "field-label":
        case "field-input":
            return `${node.value}`
        default:
            return children
    }
}

export const serializePlainText = (nodes: Descendant[]): string => {
    return nodes.map(serializePlainTextNode).join("\n")
}

const serializeHtmlNode = (node: Descendant): string => {
    if (Text.isText(node)) {
        let string = escapeHtml(node.text)
        if (node.bold) {
            string = `<strong>${string}</strong>`
        }
        if (node.italic) {
            string = `<i>${string}</i>`
        }
        if (node.underline) {
            string = `<u>${string}</u>`
        }
        if (node.code) {
            string = `<code>${string}</code>`
        }
        return string
    }

    const children = node.children.map(serializeHtmlNode).join("")

    switch (node.type) {
        case "block-quote":
            return `<blockquote><p>${children}</p></blockquote>`
        case "paragraph":
            return `<p>${children}</p>`
        case "heading-one":
            return `<h1>${children}</h1>`
        case "heading-two":
            return `<h2>${children}</h2>`
        case "link":
            return `<a href="${escapeHtml(node.url)}">${children}</a>`
        case "embed-video":
            return `<div>
                <iframe
                src={'${embedVideoLink(node.videoId)}'}
                frameBorder="0"
                ></iframe>
                ${children}
            </div>`
        case "image":
            return `<img src="${node.url}" alt="" />`
        case "field-label":
        case "field-input":
            return `<span>${node.value || ""}</span>`
        case "fin-aid-table":
            return `<div>[${node.table}]</div>`
        default:
            return children
    }
}

export const serializeHtml = (nodes: Descendant[]): string => {
    return nodes.map(serializeHtmlNode).join("")
}
