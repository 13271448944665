import React, {useMemo} from "react"
import {useTranslation} from "react-i18next"
import {KlassDropdown, KlassDropdownAsyncProps} from "../Select"
import {LedgerAccount, LedgerAccountType} from "types/student-account/ledger-accounts"
import styles from "./LedgerAccountSelect.module.css"
import {useAllLedgerAccounts} from "hooks/useAllLedgerAccounts"

type Props = Partial<KlassDropdownAsyncProps> & {
    type?: LedgerAccountType | LedgerAccountType[]
    active?: boolean
}

const LedgerAccountSelect: React.FC<Props> = ({type, active, value, ...props}) => {
    const {t} = useTranslation(["studentAccount", "common"])
    const {ledgerAccounts} = useAllLedgerAccounts()

    const options = useMemo(
        () =>
            ledgerAccounts.filter((account) => {
                if (active != null) {
                    if (active === account.isDisabled) return false
                }
                if (type != null) {
                    if (!(Array.isArray(type) ? type : [type]).includes(account.type)) return false
                }
                return true
            }),
        [ledgerAccounts, type, active]
    )

    const optionGroups = React.useMemo(
        () =>
            [
                {
                    label: t(`settings.ledgerAccounts.chartOfAccounts.typeLabel.${LedgerAccountType.Charges}`),
                    options: options.filter((account) => account.type === LedgerAccountType.Charges)
                },
                {
                    label: t(`settings.ledgerAccounts.chartOfAccounts.typeLabel.${LedgerAccountType.Payments}`),
                    options: options.filter((account) => account.type === LedgerAccountType.Payments)
                }
            ].filter((group) => group.options.length > 0),
        [t, options]
    )

    return (
        <KlassDropdown
            {...props}
            placeholder={props.placeholder || t("common:selectField.placeholder")}
            options={optionGroups}
            valueKey="accountId"
            labelKey="name"
            value={typeof value === "number" ? options.find((option) => option.accountId === value) : value}
            getOptionLabel={(option: LedgerAccount) => (
                <span>
                    {option.name}
                    {/*{!!option.fundType && (
                        <span className={styles.fundType}>
                            {t(`settings.ledgerAccounts.chartOfAccounts.fundTypeLabel.${option.fundType}`)}
                        </span>
                    )}*/}
                    {[
                        "ninetyTenFedFundsPercentage",
                        "ninetyTenNonFedFundsPercentage",
                        "ninetyTenOtherPercentage",
                        "ninetyTenExcludePercentage"
                    ].map((fundType) => {
                        if (!option[fundType]) return null
                        return (
                            <span className={styles.fundType}>
                                [{option[fundType]}%{" "}
                                {t(`settings.ledgerAccounts.chartOfAccounts.fundTypeColumnLabel.${fundType}`)}]
                            </span>
                        )
                    })}
                </span>
            )}
        />
    )
}

export default LedgerAccountSelect
