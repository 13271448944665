/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react"
import moment from "moment"
import {useTranslation} from "react-i18next"
import {Button, Row, Col, Checkbox} from "antd"
import {KlassappTableHeader, KlassappTable} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {Icon} from "components/Icon"
import styles from "./VerificationDetail.module.css"
import {cCodes} from "./dummyData"

function VerificationItem(props) {
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        tableHeaderActions,
        isLoading,
        isShowTableHeaderAction,
        studentVerification
    } = props
    const {t} = useTranslation(["financialAid"])

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickRowItem", func: onClickRow}
        ])
    }, [])

    useEffect(() => {
        getData()
    }, [])

    function onClickRow() {}

    async function getData() {
        const newData = cCodes.map((cCode) => ({
            ...cCode,
            approval: renderApprovalHtml(cCode),
            upload: renderUploadHtml(cCode),
            studentVisible: renderCheckboxHtml(cCode)
        }))
        dispatch({data: newData, total: newData.length})
    }

    function renderApprovalHtml(cCode) {
        switch (cCode.approvalStatus) {
            case "pending":
                return (
                    <div className={styles.titleLink} onClick={() => {}}>
                        {t("verification.review")}
                    </div>
                )
        }
    }

    function renderUploadHtml(cCode) {
        return (
            <div className={styles.titleLink} onClick={() => {}}>
                {t("verification.upload")}
            </div>
        )
    }

    function renderCheckboxHtml(cCode) {
        return (
            <div className={styles.centerContent}>
                <Checkbox checked={cCode.visibleStudent} onChange={({target: {checked}}) => {}}></Checkbox>
            </div>
        )
    }
    function getFields() {
        return [
            t("verification.cCode"),
            t("verification.description"),
            t("verification.task"),
            t("verification.approval"),
            t("verification.upload"),
            t("verification.studentVisible")
        ]
    }

    function getPageTitle() {
        return t("aptitudeTest.title")
    }

    function getColumns() {
        return [
            {
                title: t("verification.cCode"),
                field: "cCode",
                style: {minWidth: "150px"}
            },
            {
                title: t("verification.description"),
                field: "description",
                style: {minWidth: "300px"}
            },
            {
                title: t("verification.task"),
                field: "task",
                style: {minWidth: "150px"}
            },
            {
                title: t("verification.approval"),
                field: "approval",
                style: {minWidth: "150px"}
            },
            {
                title: t("verification.upload"),
                field: "upload",
                style: {minWidth: "150px"}
            },
            {
                title: t("verification.studentVisible"),
                field: "studentVisible",
                style: {minWidth: "150px"}
            }
        ]
    }
    return (
        <div>
            <div className={styles.header}>
                <div className={styles.isirName}>
                    <span>{studentVerification.name}</span>
                </div>
                <Button shape="circle" className={styles.fileButton} icon={<Icon icon="FILE_TEXT" />} />
            </div>
            <Row gutter={16} className={styles.cardsWrapper}>
                <Col span={4}>
                    <div className={styles.cards}>
                        <p>{t("verification.date")}</p>
                        <span className={styles.cardDetail}>
                            {studentVerification.date ? moment(studentVerification.date).format("MM/DD/YYYY") : ""}
                        </span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className={styles.cards}>
                        <p>{t("verification.awardYear")}</p>
                        <span className={styles.cardDetail}>{studentVerification.awardYear}</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className={styles.cards}>
                        <p>{t("verification.ombNumber")}</p>
                        <span className={styles.cardDetail}>{studentVerification.ombNumber}</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className={styles.cards}>
                        <p>{t("verification.efcNumber")}</p>
                        <span className={styles.cardDetail}>{studentVerification.efc}</span>
                    </div>
                </Col>
                <Col span={4}>
                    <div className={styles.cards}>
                        <p>{t("verification.dependency")}</p>
                        <span className={styles.cardDetail}>{studentVerification.dependecyStatus}</span>
                    </div>
                </Col>
            </Row>

            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                isLoading={isLoading}
                allFields={allFields}
                onClickRowItem={onClickRow}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </div>
    )
}

export const VerificationDetail = KlassappTableHOC(VerificationItem)
