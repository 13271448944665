import React from "react"
import styles from "./TermStep.module.css"
import {TermStepItem} from "./TermStepItem"
import {AcademicPlans} from "types/academicPlans"
import moment from "moment"

type Props = {
    studentId: number
    terms: AcademicPlans.TermCourseStats[]
    onChangeActiveTerm: (term: AcademicPlans.TermCourseStats) => void
}

export function TermStep(props: Props) {
    const {terms, onChangeActiveTerm} = props
    return (
        <div className={styles.wrap}>
            {terms.map((term, index) => (
                <TermStepItem
                    onChangeActiveTerm={onChangeActiveTerm}
                    key={`${term.id}_${index}`}
                    index={index}
                    term={term}
                    isLastCompletedItem={moment().isAfter(term.endDate)}
                />
            ))}
        </div>
    )
}
