import React from "react"
import {Button} from "antd"
import cx from "classnames"
import styles from "./EnableButton.module.css"

export function EnableButton(props) {
    const {title, className, isActive = true, ...rest} = props

    return (
        <Button className={cx(styles.baseBtn, {[styles.isActive]: isActive}, className)} {...rest}>
            {title}
            {props.children}
        </Button>
    )
}
