import {Auth} from "types/auth"
import {Settings} from "types/settings"
import {getFieldLabel, isFieldActive} from "./common"

export const getUserPhotoUrl = (photo: Auth.UserPhotoDetails | null, size: Auth.UserPhotoSize | undefined = 256) =>
    photo ? photo[size] : null

export const getFullName = (user) => {
    if (!user) return ""
    return [user.firstName, user.middleName, user.lastName, user.nameSuffix].filter(Boolean).join(" ")
}

export const getFullNameOrPreferredName = (user) => {
    if (!user) return ""
    return [
        user.firstName,
        user.middleName,
        user.lastName,
        user.nameSuffix,
        user.nickNamePreferedName ? `[ ${user.nickNamePreferedName} ]` : false
    ]
        .filter(Boolean)
        .join(" ")
}

export const getFullNameWithEnrollmentId = (user) => {
    if (!user) return ""
    return [user.firstName, user.middleName, user.lastName, `(${user.customProfileId})`].filter(Boolean).join(" ")
}

export const getShortName = (user) => {
    if (!user || !user.firstName) return ""
    return [`${user.firstName.slice(0, 1)}.`, user.lastName].filter(Boolean).join(" ")
}

export const getStringByValueType = (value) => {
    if (typeof value === "boolean") {
        return value ? "yes" : "no"
    }
    return value
}

export const getTotalHourStatistics = (
    model,
    studentStats: Auth.StudentStatistics,
    programVersionTotalClockHours = 0
) => {
    const totalHoursStatistics: any[] = []
    const totalHoursCompleted = studentStats?.totalHoursCompleted || 0
    const totalDistEdHours = studentStats?.totalDistEdHours || 0
    const totalHoursAttempted = studentStats?.totalHoursAttempted || 0
    const totalTransferHours = studentStats?.totalTransferHours || 0
    const hoursScheduled = studentStats?.hoursScheduled || 0
    const totalMajorHours = studentStats?.totalProgramHours ?? programVersionTotalClockHours
    const totalHourRemaining = studentStats?.totalHoursRemaining || 0 // totalMajorHours - totalHoursCompleted - totalTransferHours

    totalHoursStatistics.push({
        title: getFieldLabel(model, Settings.AcademicLabel.TransferHours, "Transfer Hours"),
        hour: totalTransferHours,
        color: "#0f4880",
        isShow: isFieldActive(model, Settings.AcademicLabel.TransferHours)
    })
    totalHoursStatistics.push({
        title: getFieldLabel(model, Settings.AcademicLabel.TotalHoursCompleted, "Total hours completed"),
        hour: totalHoursCompleted,
        color: "#18a957",
        isShow: isFieldActive(model, Settings.AcademicLabel.TotalHoursCompleted)
    })
    totalHoursStatistics.push({
        title: getFieldLabel(model, Settings.AcademicLabel.AttemptedHours, "Total hours attempted"),
        hour: totalHoursAttempted,
        color: "#ff8a38",
        isShow: isFieldActive(model, Settings.AcademicLabel.AttemptedHours)
    })
    totalHoursStatistics.push({
        title: getFieldLabel(model, Settings.AcademicLabel.RemainingHours, "Total hours remaining"),
        hour: totalHourRemaining,
        color: "#939393",
        isShow: isFieldActive(model, Settings.AcademicLabel.RemainingHours)
    })
    totalHoursStatistics.push({
        title: getFieldLabel(model, Settings.AcademicLabel.DistantEduHours, "Total DistEd Hours"),
        hour: totalDistEdHours,
        color: "#11763d",
        isSub: true,
        isShow: isFieldActive(model, Settings.AcademicLabel.DistantEduHours)
    })
    totalHoursStatistics.push({
        title: getFieldLabel(model, Settings.AcademicLabel.ScheduledHours, "Scheduled Hours"),
        hour: hoursScheduled,
        color: "#fff",
        isShow: isFieldActive(model, Settings.AcademicLabel.ScheduledHours)
    })
    totalHoursStatistics.push({
        title: getFieldLabel(model, Settings.AcademicLabel.TotalMajorHours, "Total Major Hours"),
        hour: totalMajorHours,
        color: "#0f4880",
        isShow: isFieldActive(model, Settings.AcademicLabel.TotalMajorHours)
    })
    return totalHoursStatistics
}

export const getStudentTotalProgress = (studentStats: Auth.StudentStatistics) => {
    const {totalTransferHours = 0, totalHoursCompleted = 0, totalProgramHours = 0} = studentStats
    if (totalProgramHours > 0) {
        return Number((((totalHoursCompleted + totalTransferHours) * 100) / totalProgramHours).toFixed(2))
    }
    return 0
}

export const getTotalPracticalWorkHours = (studentStats: Auth.StudentStatistics) => {
    return [
        {title: "Activities completed", hour: 54, color: "#666"},
        {title: "Expected to complete", hour: 62, color: "#939393"},
        {title: " Activities remaining", hour: 46, color: " #e5e5e5"}
    ]
}

export const getFullAddress = (data) => {
    if (!data) return ""
    return [data.address, data.city, data.state, data.country, data.postalCode].filter(Boolean).join(", ")
}
