/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useMemo, useRef, useState} from "react"
import {FixedKlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import styles from "./CourseGradesTab.module.css"
import {academicCourseGradesService} from "services"
import {cloneDeep, get, groupBy, mean} from "lodash"
import moment from "moment"
import {Col, Dropdown, Row} from "antd"
import PopupVerifyGrade from "./VerifyGradePopup"
import {BaseButton, BaseInput, Icon, SecondaryButton} from "components"
import {SAPAlertFilter} from "./CourseGradesTab"
import {useModel, useVisible} from "hooks"
import {
    capitalize,
    getFullName,
    handleError,
    isPositiveFloat,
    preventMinus,
    toastError,
    toastSuccess,
    formatDecimalWithoutRound
} from "helpers"
import ListCourseGrade from "./ListCourseGrade"
import {useTranslation} from "react-i18next"
import cx from "classnames"
import {AddDropVariant} from "types/terms"
import {getDateOnly} from "sections/academics/instructional/common/utils"
import {AcademicInstructional} from "types/academicInstructional"
import {PopupEditCourseGrade} from "./PopupEditCourseGrade"

function CourseGradeTable(props) {
    const [listCourseGrade, setListCourseGrade] = useState([])
    const listCourseGradeOriginalValue = useRef([])
    const showPopupEditGrade = useVisible()
    const [selectedCourseGrade, setSelectedCourseGrade] = useState<{
        date: string
        academicCourseGradeStudentId?: number
        studentProfileId: number
        grade: number
        termId: number
        academicCourseGradeId: number
        maxPointValue: number
        scheduleId?: number
        courseId: number
        notes?: string
    }>()
    const [isShowConfirmVerify, setIsShowConfirmVerify] = useState(false)
    const listCourseGradeValue = useRef([])
    const [summaryData, setSummaryData] = useState({
        studentInClass: 0,
        sapPass: 0,
        sapFail: 0,
        numberOfGrades: 0,
        numberOfAffectedGpa: 0,
        numberOfNotAffectedGpa: 0
    })
    const {t} = useTranslation(["common"])
    const model = useModel()

    const {
        setGpa,
        students,
        dispatch,
        courseId,
        dispatchFunc,
        page,
        total,
        pageSize,
        data,
        scheduleId,
        tableHeaderActions,
        orderField,
        isLoading,
        termIds,
        isShowTableHeaderAction,
        courseInfo,
        isLoadedTableFuncs,
        listMode
    } = props

    const renderDate = (payload: {title: string; background: string; width: number}) => {
        return (
            <div style={{width: payload.width}}>
                <div className={styles.dot} style={{background: payload.background}} />
                <span className={styles.date}>{payload.title}</span>
            </div>
        )
    }

    const renderTextHtml = (payload: {title: string; width: number}) => {
        return (
            <div
                style={{
                    width: payload.width
                }}>
                <span className={styles.headerTableText}>{payload.title}</span>
            </div>
        )
    }

    const renderButtonVerified = (field: string, id: string, value: boolean) => {
        if (value) {
            return (
                <div className={styles.isVerified}>
                    <Icon icon="CHECKED" />
                </div>
            )
        }
        return null
    }

    const renderInput = (
        field: string,
        nameField: string,
        id: string,
        value: string,
        type: "number" | "text" = "text",
        rightText?: string,
        isEditting?: boolean
    ) => {
        if (isEditting) {
            return (
                <div className={styles.inputValue}>
                    <BaseInput
                        type={type}
                        disabledArrow
                        className={styles.inputGrade}
                        placeholder={nameField}
                        value={value}
                        min={type === "number" ? 0 : undefined}
                        onKeyPress={type === "number" ? preventMinus : undefined}
                        onChange={(text) => {
                            if (type === "number") {
                                if (isPositiveFloat(text)) {
                                    onChangeInput(id, field, text, rightText)
                                } else {
                                    return toastError(`Invalid value`)
                                }
                            } else {
                                onChangeInput(id, field, text, rightText)
                            }
                        }}
                    />
                    <p style={{color: "black", marginBottom: 0}}>{rightText}</p>
                </div>
            )
        }
        return (
            <div>
                <span className={styles.valueLabel}>
                    {value}
                    {rightText}
                </span>
            </div>
        )
    }

    const renderButtonEdit = (id: string, value: boolean) => {
        return (
            <div className={styles.buttonSave}>
                <SecondaryButton
                    title={!value ? "Edit" : "Cancel"}
                    onClick={() => onChangeInput(id, "isEditting", !value)}
                    className={styles.editButton}
                />
            </div>
        )
    }

    const defaultFields = [
        "TOTAL",
        "WEIGHTED AVG",
        "QUALITY POINTS",
        "ALPHA",
        "GRADE (GPA)",
        "PASS/FAIL",
        "VERIFIED",
        ""
    ]

    const defaultColumns = [
        {
            title: "TOTAL",
            titleHtml: renderTextHtml({title: "TOTAL", width: 130}),
            field: "total",
            width: 0.14,
            maxWidth: "130px"
        },
        {
            title: "WEIGHTED AVG",
            titleHtml: renderTextHtml({title: "WEIGHTED AVG", width: 145}),
            field: "weightedAvg",
            width: 0.14,
            maxWidth: "145px"
        },
        {
            title: "QUALITY POINTS",
            titleHtml: renderTextHtml({title: "QUALITY POINTS", width: 90}),
            field: "qualityPointsHtml",
            width: 0.08,
            maxWidth: "90px"
        },
        {
            title: "ALPHA",
            titleHtml: renderTextHtml({title: "ALPHA", width: 90}),
            field: "alpha",
            width: 0.08,
            maxWidth: "90px"
        },
        {
            title: "GRADE (GPA)",
            titleHtml: renderTextHtml({title: "GRADE (GPA)", width: 205}),
            field: "gpa",
            width: 0.15,
            maxWidth: "205px"
        },
        {
            title: "PASS/FAIL",
            titleHtml: renderTextHtml({title: "PASS/FAIL", width: 160}),
            field: "passed",
            width: 0.11,
            maxWidth: "160px"
        },
        {
            title: "VERIFIED",
            titleHtml: renderTextHtml({title: "VERIFIED", width: 90}),
            field: "isVerified",
            width: 0.08,
            maxWidth: "90px"
        },
        {
            title: "",
            titleHtml: renderTextHtml({title: "", width: 80}),
            field: "isEditting",
            width: 0.09,
            maxWidth: "80px"
        }
    ]

    const [columnsData, setColumns] = useState([
        {
            title: "ID",
            field: "idHtml",
            isFixed: true,
            width: 0.05
        },
        {
            title: "Name",
            field: "fullname",
            isFixed: true,
            width: 0.3
        },
        ...defaultColumns
    ])
    const [fieldsData, setFields] = useState(["ID", "Name", ...defaultFields])

    const onChangeInput = (id, field, value, rightText = "") => {
        const changeDataForm = listCourseGradeValue.current.map((item) => {
            if (item.id !== id) {
                return item
            }
            let newValue: any = {
                ...item
            }
            if (field === "passed") {
                newValue = {
                    ...newValue,
                    [field]: renderPassFail(field, item.id, value, item.isEdittingValue),
                    [`${field}Value`]: value
                }
            } else if (field === "isVerified") {
                newValue = {
                    ...newValue,
                    [field]: renderButtonVerified(field, item.id, value),
                    [`${field}Value`]: value
                }
            } else if (field === "isEditting") {
                if (!value) {
                    const originalValue = listCourseGradeOriginalValue.current.find((ov) => ov.id === item.id)
                    newValue = cloneDeep(originalValue)
                }
                newValue = {
                    ...newValue,
                    [field]: renderButtonEdit(item.id, value),
                    [`${field}Value`]: value,
                    weightedAvg: renderInput(
                        "weightedAvg",
                        "WeightedAvg",
                        item.id,
                        newValue.weightedAvgValue.toString(),
                        "number",
                        "/100",
                        value
                    ),
                    alpha: renderInput("alpha", "Alpha", item.id, newValue.alphaValue?.toString(), "text", "", value),
                    total: renderInput(
                        "total",
                        "total",
                        item.id,
                        newValue.totalValue,
                        "number",
                        `/${newValue.maxTotalValue}`,
                        value
                    ),
                    gpa: renderInput("gpa", "Gpa", item.id, newValue.gpaValue?.toString(), "number", "", value),
                    passed: renderPassFail("passed", item.id, newValue.passedValue, value)
                }
            } else {
                const isNumber = field === "weightedAvg" || field === "total" || field === "gpa"
                newValue = {
                    ...newValue,
                    [field]: renderInput(
                        field,
                        field,
                        item.id,
                        value,
                        isNumber ? "number" : "text",
                        rightText,
                        item.isEdittingValue
                    ),
                    [`${field}Value`]: value
                }
            }
            return newValue
        })
        listCourseGradeValue.current = cloneDeep(changeDataForm)
        dispatch({data: changeDataForm, total: changeDataForm.length})
    }

    const onClickCancelBtn = async () => {
        listCourseGradeValue.current = cloneDeep(listCourseGradeOriginalValue.current)
        dispatch({
            data: cloneDeep(listCourseGradeOriginalValue.current),
            total: listCourseGradeOriginalValue.current.length
        })
    }

    const onSave = async () => {
        dispatch({isLoading: true})
        try {
            const payload = listCourseGradeValue.current
                .filter((item) => item.isEdittingValue)
                .map((item) => ({
                    academicStudentStatsId: item.academicStudentStatsId,
                    weightedAvg: item.weightedAvgValue ? parseFloat(item.weightedAvgValue) : 0,
                    courseId: courseId,
                    passFail: item.passedValue,
                    gpa: item.gpaValue ? parseFloat(item.gpaValue) : null,
                    alphabetical: item.alphaValue,
                    totalGrade: item.totalValue ? parseFloat(item.totalValue) : 0,
                    maxTotalGrade: item.maxTotalValue ? parseFloat(item.maxTotalValue) : 0,
                    isVerified: !!item.isVerifiedValue,
                    studentProfileId: item.studentProfileId,
                    termId: termIds?.length ? termIds[0] : undefined,
                    scheduleId: scheduleId
                }))
            await academicCourseGradesService.addManualGrade({
                listGrades: payload
            })
            getData()
            toastSuccess("Updated")
        } catch (err) {
            handleError(err)
        } finally {
            dispatch({isLoading: false})
        }
    }

    useEffect(() => {
        if (listMode) {
            onClickCancelBtn()
        }
    }, [listMode])

    const getFields = () => {
        return fieldsData
    }

    const getPageTitle = () => {
        return "Course"
    }

    const getColumns = () => {
        return columnsData
    }

    const renderName = (item) => {
        const droppedDate = moment(item.droppedDate).startOf("day")
        const addDropDate = moment(item.addDropDate).startOf("day")
        const isDropBeforeAddDrop =
            item.addDropVariant === AddDropVariant.Date
                ? droppedDate.isSameOrBefore(addDropDate)
                : droppedDate.isSameOrBefore(moment(item.addDropHours))

        return (
            <div className={styles.name}>
                <div className={styles.nameStudent}>
                    <span>{getFullName(item)}</span>
                </div>
                {!!item.isMakeup && (
                    <div className={styles.icon}>
                        <Icon icon="PLUS_PERSON" color="#666" />
                    </div>
                )}
                {!!item.isDropped && (
                    <p className={styles.droppedText} style={{color: isDropBeforeAddDrop ? "#1890ff" : "red"}}>
                        DD (
                        {getDateOnly({
                            date: item.droppedDate,
                            placeholder: true,
                            dateFormat: model.getUserDateFormat()
                        })}
                        )
                    </p>
                )}
                {!!item.isLoa && (
                    <p className={styles.droppedText} style={{color: "#1890ff"}}>
                        LOA
                    </p>
                )}
            </div>
        )
    }

    const getData = async () => {
        dispatch({isLoading: true})
        try {
            let filter: any = {
                courseIds: courseId ? [courseId] : []
            }
            if (scheduleId) {
                filter.scheduleId = scheduleId
            }
            if (props.filter) {
                filter = {
                    ...filter,
                    ...props.filter,
                    affectGpa: props.filter?.affectGpa?.value,
                    spaAlerts: props.filter?.spaAlerts?.value
                }
                if (filter?.startDate) {
                    filter.startDate = moment.utc(filter.startDate).format("YYYY-MM-DD")
                }
                if (filter?.campus) {
                    filter.campus = get(filter, ["campus"], []).map((item) => item.id)
                }
                const profileId = get(model, "user.profiles[0].id")
                if (!model.isStaffOrAdmin() && profileId) {
                    filter.studentProfileIds = [profileId]
                }
                if (students?.length) {
                    filter.studentProfileIds = students.map((item) => {
                        const student = item.student
                        const profileId = student?.profileId
                        return profileId
                    })
                }
            }
            if (termIds?.length) {
                filter.termIds = termIds
            }
            let response: any = {}
            try {
                response = await academicCourseGradesService.getCourseGradeStudents({
                    filter: filter,
                    range: {
                        page,
                        pageSize: 5000
                    }
                })
            } catch (err) {}
            let {
                courseGrades = [],
                sapPass = 0,
                sapFail = 0,
                numberOfGrades = 0,
                numberOfAffectedGpa = 0,
                numberOfNotAffectedGpa = 0,
                studentInClass: numOfStudents = 0,
                listDates = [],
                gpaTotal = 0
            } = response
            let studentInClass = numOfStudents ?? 0

            const parseDateMonth = (date) => moment(date).format("MM/DD")
            let arrayCourseGrade = []
            let listStudent = courseGrades.map((item) => {
                const newStudentData: any = {}
                const groupDataProfileId = item?.courseGrade
                arrayCourseGrade = [...arrayCourseGrade, ...cloneDeep(item?.courseGrade)]
                for (let i = 0; i < groupDataProfileId.length; i++) {
                    const titleDate = parseDateMonth(groupDataProfileId[i]?.date)
                    const academicCourseGradeId = groupDataProfileId[i]?.academicCourseGradeId
                    newStudentData[`${titleDate}${academicCourseGradeId}`] = renderText(
                        `${groupDataProfileId[i].grade}/${groupDataProfileId[i].maxPointValue}`,
                        true,
                        {
                            date: groupDataProfileId[i]?.date,
                            academicCourseGradeStudentId: groupDataProfileId[i].academicCourseGradeStudentId,
                            studentProfileId: item.studentProfileId,
                            grade: groupDataProfileId[i].grade,
                            termId: groupDataProfileId[i].termId,
                            scheduleId: groupDataProfileId[i].scheduleId,
                            courseId: groupDataProfileId[i].courseId,
                            notes: groupDataProfileId[i].notes,
                            maxPointValue: groupDataProfileId[i].maxPointValue,
                            academicCourseGradeId: groupDataProfileId[i].academicCourseGradeId
                        }
                    )
                }
                newStudentData.id = item.id
                newStudentData.qualityPointsHtml = renderText(item.qualityPoints)
                newStudentData.academicStudentStatsId = item.academicStudentStatsId
                newStudentData.passedValue = item.passFail
                newStudentData.passed = renderPassFail("passed", item.id, newStudentData.passedValue)
                newStudentData.gpaValue =
                    parseFloat(item?.gpa ?? 0) >= 0 ? formatDecimalWithoutRound(item?.gpa ?? 0) : "0"
                newStudentData.gpa = renderInput("gpa", "Gpa", item.id, newStudentData.gpaValue?.toString(), "number")
                newStudentData.idHtml = renderText(item.id)
                newStudentData.alphaValue = item.alpha
                newStudentData.isVerifiedValue = !!item.isVerified
                if (item.isVerified) {
                    newStudentData.isDisableCheckBox = true
                }
                newStudentData.isVerified = renderButtonVerified("isVerified", item.id, newStudentData.isVerifiedValue)
                newStudentData.isEdittingValue = false
                newStudentData.isEditting = renderButtonEdit(item.id, newStudentData.isEdittingValue)
                newStudentData.alpha = renderInput(
                    "alpha",
                    "Alpha",
                    item.id,
                    newStudentData.alphaValue?.toString(),
                    "text"
                )
                newStudentData.totalValue = !item?.total ? 0 : get(item, ["total"], 0)
                newStudentData.maxTotalValue = item.totalMax
                newStudentData.total = renderInput(
                    "total",
                    "total",
                    item.id,
                    newStudentData.totalValue,
                    "number",
                    `/${newStudentData.maxTotalValue}`
                )
                const weightedAvgValue = `${
                    parseFloat(item?.weightedAvg ?? 0) >= 0 ? formatDecimalWithoutRound(item?.weightedAvg ?? 0) : 0
                }`
                newStudentData.weightedAvgValue = weightedAvgValue
                newStudentData.weightedAvg = renderInput(
                    "weightedAvg",
                    "WeightedAvg",
                    item.id,
                    weightedAvgValue.toString(),
                    "number",
                    "/100"
                )
                newStudentData.fullname = renderText(item.fullName)
                newStudentData.studentProfileId = item.studentProfileId

                return newStudentData
            })
            const groupedCourseGrades = []
            Object.entries(groupBy(arrayCourseGrade, "academicCourseGradeId")).forEach(([k, v]) => {
                const firstGrade = v[0]
                const _item = {
                    id: k,
                    title: firstGrade.title, // Title
                    lessonId: firstGrade.lessonId, // Lesson/Manual
                    gradingItem: firstGrade.gradingItem, // Type
                    date: firstGrade.date, // Date
                    instructors: firstGrade.instructors, // Instructor
                    avgPoints: mean(v.map((x) => x.grade)), // Average points
                    maxPoints: firstGrade.maxPointValue * v.length // Max points = Max point value * total of students
                }
                groupedCourseGrades.push(_item)
            })

            setListCourseGrade(groupedCourseGrades)
            const listStudentCourseGradesId = listStudent.map((item) => item.studentProfileId)

            for (let i = 0; i < students.length; i++) {
                const student = students[i]?.student
                const profileId = student?.profileId
                if (!listStudentCourseGradesId.includes(profileId)) {
                    listStudent.push({
                        fullname: renderName(student),
                        idHtml: renderText(student.customProfileId),
                        id: student.profileId,
                        isMakeup: student.isMakeup,
                        isDropped: student.isDropped,
                        droppedDate: student.droppedDate,
                        addDropDate: student.addDropDate,
                        addDropHours: student.addDropHours,
                        addDropVariant: student.addDropVariant
                    })
                    studentInClass += 1
                } else {
                    const studentData = listStudent.find((item) => item.studentProfileId === profileId)
                    studentData.isMakeup = student.isMakeup
                    studentData.isDropped = student.isDropped
                    studentData.droppedDate = student.droppedDate
                    studentData.addDropDate = student.addDropDate
                    studentData.addDropHours = student.addDropHours
                    studentData.addDropVariant = student.addDropVariant
                    studentData.fullname = renderName(student)
                    studentData.idHtml = renderText(student.customProfileId)
                }
            }
            setSummaryData({
                sapPass,
                sapFail,
                numberOfGrades: numberOfGrades,
                numberOfAffectedGpa: numberOfAffectedGpa,
                numberOfNotAffectedGpa: numberOfNotAffectedGpa,
                studentInClass
            })
            if (props.filter?.spaAlerts?.value !== SAPAlertFilter.All) {
                const isPass = props.filter?.spaAlerts?.value === SAPAlertFilter.Pass
                listStudent = listStudent.filter((item) => item.passedValue === isPass)
            }
            listCourseGradeValue.current = cloneDeep(listStudent)
            listCourseGradeOriginalValue.current = cloneDeep(listStudent)
            dispatch({data: listStudent, total: listStudent.length})
            listDates = listDates.sort(function (a, b) {
                return a.date - b.date
            })
            listDates = listDates.map((item) => {
                const titleDate = moment.unix(item.date).clone().format("MM/DD")
                return {
                    title: titleDate,
                    titleHtml: renderDate({title: titleDate, background: item.color, width: 100}),
                    field: `${titleDate}${item.academicCourseGradeId}`,
                    width: 0.1,
                    maxWidth: "100px"
                }
            })
            setFields(["ID", "Name", ...listDates.map((item) => item.title), ...defaultFields])
            setColumns([
                {
                    title: "ID",
                    field: "id",
                    isFixed: true,
                    width: 0.05
                },
                {
                    title: "Name",
                    field: "fullname",
                    isFixed: true,
                    isMainColumn: true,
                    width: 0.3
                },
                ...listDates,
                ...defaultColumns
            ])
        } catch (e) {
            console.log(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClickRowItem = (row) => {}

    const onClickDeleteMulti = async () => {}

    const onClickDuplicateMulti = async () => {}

    const onClickEdit = () => {}

    const onClickDelete = async () => {}

    const renderText = (
        text: string,
        editable?: boolean,
        data?: {
            date: string
            academicCourseGradeStudentId?: number
            studentProfileId: number
            grade: number
            termId: number
            academicCourseGradeId: number
            maxPointValue: number
            scheduleId?: number
            courseId: number
            notes?: string
        }
    ) => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                <span className={styles.textGrade}>{text}</span>
                {editable && (
                    <div
                        onClick={() => {
                            setSelectedCourseGrade(data)
                            showPopupEditGrade.open()
                        }}
                        className={styles.editButton}
                        style={{marginLeft: 2}}>
                        <Icon icon="PENCIL" className={styles.iconEdit} />
                    </div>
                )}
            </div>
        )
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickDuplicateMulti", func: onClickDuplicateMulti},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDelete},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [fieldsData, columnsData, data])

    useEffect(() => {
        if (isLoadedTableFuncs && scheduleId && students?.length) {
            getData()
        }
    }, [isLoadedTableFuncs, page, pageSize, props.search, props.filter, scheduleId, students])

    useEffect(() => {
        props.updateTableHeaderActions()
    }, [data])

    const onCancelConfirm = () => {
        setIsShowConfirmVerify(false)
    }

    const onShowConfirm = () => {
        setIsShowConfirmVerify(true)
    }

    const onUpdateRowData = (updatedData) => {
        const newData = cloneDeep(listCourseGradeValue.current).map((item) => {
            if (item.id === updatedData.id) {
                return {
                    ...item,
                    isChecked: updatedData.isChecked
                }
            }
            return item
        })
        listCourseGradeValue.current = newData
        dispatch({data: newData})
    }

    const headerActions = () => {
        const actions: any = [
            {
                title: (
                    <div className={styles.actionWrap}>
                        <BaseButton
                            title="Verify"
                            variant="secondary"
                            className={styles.verifybtn}
                            onClick={onShowConfirm}
                            loading={false}
                        />
                    </div>
                ) as any,
                icon: null,
                action: () => {}
            }
        ]
        return actions
    }

    const renderPassFailName = (value: AcademicInstructional.PassFailStatus) => {
        if (value) {
            if (value === AcademicInstructional.PassFailStatus.Incomplete) {
                return "I (Incomplete)"
            } else if (value === AcademicInstructional.PassFailStatus.LoaIncomplete) {
                return "LOA-I"
            }
            return capitalize(value)
        }
        return ""
    }

    const renderPassFail = (
        field: string,
        id: string,
        value: AcademicInstructional.PassFailStatus,
        isEditting?: boolean
    ) => {
        if (isEditting) {
            return (
                <Dropdown
                    trigger={["click"]}
                    placement="bottomLeft"
                    menu={{
                        items: [
                            AcademicInstructional.PassFailStatus.Pass,
                            AcademicInstructional.PassFailStatus.Fail,
                            AcademicInstructional.PassFailStatus.Withdrawn,
                            AcademicInstructional.PassFailStatus.Incomplete,
                            AcademicInstructional.PassFailStatus.LoaIncomplete
                        ].map((status) => {
                            return {
                                key: status,
                                label: (
                                    <Row
                                        style={{width: 150}}
                                        align="middle"
                                        gutter={8}
                                        onClick={() => onChangeInput(id, field, status)}>
                                        <Col
                                            flex={1}
                                            style={{
                                                color:
                                                    status === AcademicInstructional.PassFailStatus.Pass
                                                        ? "green"
                                                        : [
                                                              AcademicInstructional.PassFailStatus.LoaIncomplete,
                                                              AcademicInstructional.PassFailStatus.Incomplete
                                                          ].includes(status)
                                                        ? "orange"
                                                        : "red"
                                            }}>
                                            {renderPassFailName(status)}
                                        </Col>
                                    </Row>
                                )
                            }
                        })
                    }}>
                    <span
                        className={cx(styles.dropDownPassFail, {
                            [styles.dropDownPass]: value === AcademicInstructional.PassFailStatus.Pass,
                            [styles.dropDownIncomplete]: [
                                AcademicInstructional.PassFailStatus.LoaIncomplete,
                                AcademicInstructional.PassFailStatus.Incomplete
                            ].includes(value)
                        })}>
                        {renderPassFailName(value)}
                    </span>
                </Dropdown>
            )
        }
        return (
            <div>
                <span
                    className={cx(styles.valueLabel, {
                        [styles.dropDownPass]: value === AcademicInstructional.PassFailStatus.Pass,
                        [styles.dropDownFail]: [
                            AcademicInstructional.PassFailStatus.Fail,
                            AcademicInstructional.PassFailStatus.Withdrawn
                        ].includes(value),
                        [styles.dropDownIncomplete]: [
                            AcademicInstructional.PassFailStatus.LoaIncomplete,
                            AcademicInstructional.PassFailStatus.Incomplete
                        ].includes(value)
                    })}>
                    {renderPassFailName(value)}
                </span>
            </div>
        )
    }

    const SummaryData = useMemo(() => {
        const {studentInClass, numberOfGrades, sapPass, sapFail, numberOfAffectedGpa} = summaryData
        return (
            <Row gutter={[40, 24]}>
                <Col span={6}>
                    <div className={styles.summaryDataContainer}>
                        <Icon className={styles.iconSummary} icon={"STUDENTS_IN_CLASS"} />
                        <div className={styles.summaryDataContent}>
                            <span className={styles.titleSummary}>STUDENT IN CLASS:</span>
                            <span className={styles.numberSummary}>{studentInClass}</span>
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.gradeItems}>
                        <div className={styles.numberOfGrades}>
                            <Icon className={styles.iconSummary} icon={"PARTICIPATION"} />
                            <div className={styles.summaryDataContent}>
                                <span className={styles.titleSummary}>GRADE ITEMS:</span>
                                <div className={styles.numberOfGradesFrom}>
                                    <div className={styles.gradeItem} style={{flex: 0.7}}>
                                        <span className={styles.subTitleSummary}>Total:</span>
                                        <span className={styles.numberSummary} style={{marginTop: 0}}>
                                            {numberOfGrades}
                                        </span>
                                    </div>
                                    <div className={styles.gradeItem}>
                                        <span className={styles.subTitleSummary}>Affect GPA:</span>
                                        <span className={styles.numberSummary} style={{marginTop: 0}}>
                                            {numberOfAffectedGpa}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.summaryDataContainer}>
                        <Icon className={styles.iconSummary} icon={"SAP_PASS"} />

                        <div className={styles.summaryDataContent}>
                            <span className={styles.titleSummary}>PASS:</span>
                            <span className={styles.numberSummary}>{sapPass}</span>
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.summaryDataContainer}>
                        <Icon className={styles.iconSummary} icon={"SAP_FAIL"} />
                        <div className={styles.summaryDataContent}>
                            <span className={styles.titleSummary}>FAIL:</span>
                            <span className={styles.numberSummary}>{sapFail}</span>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }, [summaryData])

    const isShowSaveButton = !!data.find((item) => item.isEdittingValue)

    if (listMode) {
        return (
            <>
                {SummaryData}
                <ListCourseGrade
                    scheduleId={scheduleId}
                    courseId={courseId}
                    termIds={termIds}
                    listCourseGrade={listCourseGrade}
                />
            </>
        )
    } else {
        return (
            <>
                {SummaryData}
                {isShowSaveButton ? (
                    <div className={styles.actionButton}>
                        <SecondaryButton
                            title={t("common:action.cancel")}
                            onClick={onClickCancelBtn}
                            className={styles.cancelButton}
                        />
                        <BaseButton title="Save" onClick={onSave} />
                    </div>
                ) : null}
                <KlassappTableHeader
                    page={page}
                    isShowAction={!!data.find((item) => item.isChecked)}
                    actions={headerActions()}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={props.onChangePage}
                    onChangeRowPerPage={props.onChangeRowPerPage}
                    fields={fieldsData}
                    allFields={fieldsData}
                    onChangeFields={props.onChangeFields}
                />
                <FixedKlassappTable
                    columns={columnsData}
                    fields={fieldsData}
                    data={data}
                    menuActions={[]}
                    isLoading={isLoading}
                    allFields={fieldsData}
                    isShowCheckedColumn={!isShowSaveButton}
                    onUpdateRowData={onUpdateRowData}
                    isShowCheckedColumnHeader={false}
                    orderField={orderField}
                    onClickRowItem={onClickRowItem}
                    onChangeFields={props.onChangeFields}
                    onUpdateTableData={props.onUpdateTableData}
                />
                <PopupVerifyGrade
                    termIds={termIds}
                    courseId={courseId}
                    scheduleId={scheduleId}
                    data={data}
                    isShowConfirmVerify={isShowConfirmVerify}
                    onCancelConfirm={onCancelConfirm}
                    refreshData={getData}
                />
                <PopupEditCourseGrade
                    isShow={showPopupEditGrade.isVisible}
                    onRefresh={getData}
                    onClose={showPopupEditGrade.close}
                    courseGradesStudent={selectedCourseGrade}
                />
            </>
        )
    }
}

export default KlassappTableHOC(CourseGradeTable)
