/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useCallback} from "react"
import {useTranslation} from "react-i18next"
import {get, head} from "lodash"
import {useHistory, useLocation} from "react-router-dom"
import {Tabs} from "antd"
import {instructionalService, studentService} from "services"
import {getFullName, handleError} from "helpers"
import {Screen} from "components/ui"
import {useActiveTab, useModel} from "hooks"
import {BaseDepartmentId} from "types/departments"
import {Attendance} from "sections/Attendance"
import {Documents} from "sections/Documents"
import {TabHeader} from "components/Tab"
import {Tasks} from "sections/shared/student"
import {AcademicPlan} from "sections/StudentServices/StudentServicesStudent/parts"
import {AdvisorTitle, ModuleStudentHeader} from "uiKit"
import {AcademicsStudentDegreeAudit} from "./DegreeAudit"
import {AcademicsStudentTerms} from "./Terms"
import {Academic} from "./Academic"
import {Workspace} from "./Workspace"
import {Auth} from "types/auth"
import {Icon} from "components/Icon"
import styles from "./AcademicsStudent.module.css"
import {RegistrarStaffTab} from "sections/academics/registrar/RegistrarStaff/RegistrarStaff"
import {StudentServicesPanelInfo} from "sections/StudentServices/StudentServicesPanelInfo"
import {BaseLoading} from "components"
import {AcademicPlans} from "types/academicPlans"
import {SAP} from "./SAP"
import qs from "query-string"

const {TabPane} = Tabs

export enum RegistrarStudentTab {
    Workspace = "workspace",
    Academic = "academic",
    Attendance = "attendance",
    TermCourses = "term-courses",
    DegreeAudit = "degree-audit",
    AcademicPlan = "academic-plan",
    Documents = "documents",
    Tasks = "tasks",
    SAP = "sap"
}

export default function AcademicsStudent(props) {
    const departmentId = BaseDepartmentId.Academics
    const [student, setStudent] = useState<Auth.DepartmentStudent | undefined>()
    const [activeCourseId, setActiveCourseId] = useState(null)
    const [activeScheduleId, setActiveScheduleId] = useState(null)
    const [courseInfo, setCourseInfo] = useState(null)
    const [activeTerm, setActiveTerm] = useState<AcademicPlans.TermCourseStats>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [backUrl, setBackUrl] = useState<string | null>(
        `/academics/registrar/staff?tab=${RegistrarStaffTab.Students}`
    )
    const {
        match: {
            params: {profileId}
        }
    } = props
    const {t} = useTranslation(["academics", "common"])
    const [activeTabKey, onChangeTab] = useActiveTab(RegistrarStudentTab.Workspace)
    const model = useModel()
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        if (location.state?.["backUrlFromPage"]) {
            setBackUrl(location.state?.["backUrlFromPage"])
        }
        const {backUrl} = qs.parse(location.search)
        if (backUrl) {
            setBackUrl(backUrl as string)
        }
        const isStudent = model.isStudent()
        if (isStudent) {
            setBackUrl(null)
        }
    }, [location])

    async function getStudentDetail() {
        try {
            setIsLoading(true)
            const {
                data: [student]
            } = await studentService.getDepartmentStudents({
                filter: {
                    profileIds: [+profileId],
                    departmentId
                }
            })
            setStudent(student)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getStudentDetail()
    }, [profileId])

    const onClickCourseId = (courseId: number, scheduleId: number) => {
        setActiveCourseId(courseId)
        setActiveScheduleId(scheduleId)
    }

    const onClickCustomBackButton = () => {
        setActiveCourseId(null)
    }

    const renderCustomBackButton = () => {
        return (
            <div className={styles.backBtnWrap} onClick={onClickCustomBackButton}>
                <Icon icon="BACK" color="#FF349B" className={styles.backIcon} />
            </div>
        )
    }

    const getCourseInfo = useCallback(async () => {
        if (activeCourseId && activeTerm) {
            try {
                setIsLoading(true)
                const params: any = {
                    range: {page: 1, pageSize: 1},
                    filter: {isPracticalOrClinicalServices: false, courseIds: [activeCourseId]}
                }
                if (profileId) {
                    params.filter.studentProfileIds = [+profileId]
                }
                if (activeTerm?.termId) {
                    params.filter.termIds = [activeTerm.termId]
                }
                if (activeScheduleId) {
                    params.filter.scheduleIds = [activeScheduleId]
                }
                const {data} = await instructionalService.getInstructionalMyCourse(params)
                setCourseInfo(head(data))
            } catch (error) {
                handleError(error)
            } finally {
                setIsLoading(false)
            }
        }
    }, [activeCourseId, profileId, activeTerm, activeScheduleId])

    useEffect(() => {
        getCourseInfo()
    }, [activeCourseId, profileId, activeTerm])

    if (!student) {
        return (
            <Screen
                htmlTitle={t("student.title")}
                header={{
                    title: getFullName(student) || "-",
                    code: get(student, "customUserId", ""),
                    backUrl,
                    customBackButton: activeCourseId && renderCustomBackButton
                }}>
                {isLoading ? <BaseLoading isShow /> : <p>Information not found</p>}
            </Screen>
        )
    }

    return (
        <Screen
            htmlTitle={t("student.title")}
            header={{
                titleClassName: styles.screenTitle,
                title: getFullName(student) || "-",
                code:
                    activeTabKey === RegistrarStudentTab.TermCourses && activeCourseId
                        ? courseInfo?.scheduleSuffix
                        : get(student, "customProfileId", ""),
                backUrl,
                customBackButton: activeCourseId && renderCustomBackButton
            }}
            rightPanel={{
                type: "student",
                data: {
                    student,
                    departmentId,
                    advisorTitle: AdvisorTitle.advisors,
                    renderDepartmentInfo: (props) => (
                        <StudentServicesPanelInfo
                            tab={activeTabKey}
                            student={student}
                            reloadStudent={(newProfileId) =>
                                history.replace(`/academics/registrar/student/${newProfileId}?tab=${activeTabKey}`)
                            }
                            {...props}
                        />
                    )
                }
            }}>
            <ModuleStudentHeader
                activeModule={Auth.ModuleNavigation.Academics}
                userId={student?.userId}
                customUserId={student?.customUserId}
                profileId={+profileId}
                isBackButtonActive={!!backUrl}
            />
            <Tabs className="fullwidth klassTab" activeKey={activeTabKey} onChange={onChangeTab} tabPosition="top">
                <TabPane
                    tab={<TabHeader icon="PROFILE" title={t("registrar.workspace")} />}
                    key={RegistrarStudentTab.Workspace}>
                    <Workspace studentId={+profileId} departmentId={departmentId} />
                </TabPane>
                <TabPane
                    tab={<TabHeader icon="ACADEMIC" title={t("registrar.academic")} />}
                    key={RegistrarStudentTab.Academic}>
                    {activeTabKey === RegistrarStudentTab.Academic ? (
                        <Academic studentId={+profileId} userId={student?.userId} departmentId={departmentId} />
                    ) : null}
                </TabPane>
                <TabPane
                    tab={<TabHeader icon="PERSON_DONE" title={t("registrar.attendance")} />}
                    key={RegistrarStudentTab.Attendance}>
                    <Attendance studentProfileId={profileId} isShowAllMissedAttendance departmentId={departmentId} />
                </TabPane>
                <TabPane
                    tab={<TabHeader icon="COURSE" title={t("registrar.termCourse")} />}
                    key={RegistrarStudentTab.TermCourses}>
                    {activeTabKey === RegistrarStudentTab.TermCourses ? (
                        <AcademicsStudentTerms
                            courseId={activeCourseId}
                            scheduleId={activeScheduleId}
                            onChangeCourseId={onClickCourseId}
                            studentId={+profileId}
                            activeTerm={activeTerm}
                            setActiveTerm={setActiveTerm}
                        />
                    ) : null}
                </TabPane>
                <TabPane
                    tab={<TabHeader icon="GRADE" title={t("registrar.degreeAudit")} />}
                    key={RegistrarStudentTab.DegreeAudit}>
                    {activeTabKey === RegistrarStudentTab.DegreeAudit ? (
                        <AcademicsStudentDegreeAudit studentId={+profileId} departmentId={departmentId} />
                    ) : null}
                </TabPane>
                <TabPane
                    tab={<TabHeader icon="BULB" title={t("registrar.academicPlan")} />}
                    key={RegistrarStudentTab.AcademicPlan}>
                    {activeTabKey === RegistrarStudentTab.AcademicPlan ? (
                        <AcademicPlan studentId={+profileId} departmentId={departmentId} />
                    ) : null}
                </TabPane>
                <TabPane
                    tab={<TabHeader icon="FILE_TEXT" title={t("common:tasks.title")} />}
                    key={RegistrarStudentTab.Tasks}>
                    <Tasks
                        studentId={+profileId}
                        departmentId={BaseDepartmentId.Academics}
                        history={history}
                        model={model}
                        fromPage={{
                            backUrl: `/academics/registrar/student/${profileId}?tab=${RegistrarStudentTab.Tasks}`
                        }}
                    />
                </TabPane>
                <TabPane
                    tab={<TabHeader icon="FILE_TEXT" title={t("registrar.documents")} />}
                    key={RegistrarStudentTab.Documents}>
                    <Documents
                        backUrl={`/academics/registrar/student/${profileId}?tab=${RegistrarStudentTab.Documents}`}
                        studentId={+profileId}
                        departmentId={departmentId}
                        model={model}
                        userId={student.userId}
                        profileId={+profileId}
                        {...props}
                    />
                </TabPane>
                <TabPane tab={<TabHeader icon="FILE_TEXT" title="SAP" />} key={RegistrarStudentTab.SAP}>
                    {activeTabKey === RegistrarStudentTab.SAP && (
                        <SAP studentId={+profileId} departmentId={BaseDepartmentId.Academics} />
                    )}
                </TabPane>
            </Tabs>
        </Screen>
    )
}
