/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo, useState} from "react"
import styles from "./ExportPDFFrame.module.css"
import {useModel} from "hooks"
import jsPDF from "jspdf"
import {
    fontBold,
    fontRegular
} from "sections/FinancialAid/FinancialAidStudent/parts/CollegeFinancingPlan/CFPStepDetail/font"
import moment from "moment"
import {convertImgToBase64, getFullName, handleError} from "helpers"
import {Branding} from "types/branding"
import {clientsService} from "services"
import {Auth} from "types/auth"
import {ExportPdfPayload} from "helpers/export-table"
import {useQuery} from "@tanstack/react-query"
import {DegreeAuditProgressData} from "types/degreeAudit"

export enum ExportPdfFrameType {
    TermCourseSchedule = "TermCourseSchedule",
    AcademicPlanSection = "AcademicPlanSection"
}

type Props = {
    id: any
    type: ExportPdfFrameType
    pageTitle: string
    progressData?: DegreeAuditProgressData
    pageSubTitles?: string[]
    fileName: string
    student?: Auth.DepartmentStudent
    payload?: ExportPdfPayload & {
        terms: Array<{
            termId: number
            termName: string
            courses: Array<any>
        }>
    }
    isExporting: boolean
    onSuccessExported: () => void
}

export function ExportPDFFrame(props: Props) {
    const {
        id,
        pageTitle,
        pageSubTitles,
        fileName,
        student,
        isExporting,
        onSuccessExported,
        payload,
        type,
        progressData
    } = props
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const [client, setClient] = useState<any | undefined>()
    const [logoBase64, setLogoBase64] = useState("")

    useEffect(() => {
        if (isExporting) {
            handleDownload()
            onSuccessExported()
        }
    }, [isExporting])

    const handleDownload = async () => {
        try {
            const element = window.document.getElementById(`pdf-${id}`)
            element.style.visibility = "visible"

            const pdfWidth = 297
            const elementWidth = element.clientWidth
            const widthScaleFactor = pdfWidth / elementWidth

            const pdf = new jsPDF("l", "mm", "a4")

            pdf.addFileToVFS("Poppins-regular.ttf", fontRegular)
            pdf.addFont("Poppins-regular.ttf", "Poppins", "normal", "normal")
            pdf.addFileToVFS("Poppins-bold.ttf", fontBold)
            pdf.addFont("Poppins-bold.ttf", "Poppins", "normal", "bold")

            pdf.setFont("Poppins")

            pdf.html(element, {
                image: {
                    type: "jpeg",
                    quality: 100
                },
                html2canvas: {
                    scale: widthScaleFactor,
                    allowTaint: true,
                    letterRendering: true,
                    svgRendering: true
                },
                autoPaging: "text",
                margin: [10, 0, 10, 0],
                x: 0,
                y: 0,
                width: pdfWidth,
                windowWidth: elementWidth
            }).then(() => {
                pdf.save(`${fileName}_${moment().format("YYYY-MM-DD")}.pdf`)
                element.style.visibility = "hidden"
            })
        } catch (error) {
            handleError(error)
        }
    }

    useEffect(() => {
        const logo = model.clientSetting?.branding?.[Branding.BrandingType.OfficialLogoSquare]
        if (logo) {
            convertImgToBase64(`${logo}?t=${Date.now()}`).then(setLogoBase64)
        }
    }, [model.clientSetting?.branding])

    useQuery(["client-details"], () => clientsService.getClientDetail(), {
        onSuccess: (resp) => {
            setClient(resp)
        },
        onError: (err) => {
            handleError(err)
        }
    })

    const getWidthColumn = (index: number) => {
        if (index === 0) {
            return "40px"
        }
        if (index === 1) {
            return "100px"
        }
        if (index === 2) {
            return "85px"
        }
        if (index === 3) {
            return "40px"
        }
        if (index === 5) {
            return "55px"
        }
        if (index === 6) {
            return `80px`
        }

        if (index === 11) {
            return "50px"
        }

        if (index === 10) {
            return "40px"
        }

        if ([7, 4, 8, 9].includes(index)) {
            return "85px"
        }

        return "auto"
    }

    const rightSection = useMemo(() => {
        if (type === ExportPdfFrameType.TermCourseSchedule) {
            return (
                <>
                    {student ? (
                        <>
                            <b>{getFullName(student)}</b>
                            <span>{student?.address}</span>
                            <span>
                                {student?.city}, {student?.state} {student?.postalCode}
                            </span>
                            <div className={styles.date}>
                                <b>Enrollment Id:</b>
                                <span>{student.customProfileId}</span>
                            </div>
                        </>
                    ) : null}
                    <div className={styles.date}>
                        <b>Date:</b>
                        <span>{moment().format(userDateFormat)}</span>
                    </div>
                </>
            )
        }
        if (type === ExportPdfFrameType.AcademicPlanSection) {
            return (
                <>
                    {student ? (
                        <>
                            <div className={styles.date}>
                                <b>Student:</b>
                                <b>{getFullName(student)}</b>
                            </div>
                            <span>{student?.address}</span>
                            <span>
                                {student?.city}, {student?.state} {student?.postalCode}
                            </span>
                        </>
                    ) : null}

                    {progressData ? (
                        <>
                            <div className={styles.date}>
                                <b>Degree Requirement:</b>
                                <span>{progressData.degreeRequirement ?? 0}</span>
                            </div>
                            <div className={styles.date}>
                                <b>Completed Hours:</b>
                                <span>{progressData.completed ?? 0}</span>
                            </div>
                            <div className={styles.date}>
                                <b>Transfer Hours:</b>
                                <span>{progressData.totalTransferApplied ?? 0}</span>
                            </div>
                            <div className={styles.date}>
                                <b>Previously Completed Hours:</b>
                                <span>{progressData.totalPreviousCompletedApplied ?? 0}</span>
                            </div>
                            <div className={styles.date}>
                                <b>Remaining Hours:</b>
                                <span>
                                    {Math.max(
                                        (progressData.degreeRequirement ?? 0) - (progressData.appliedToDegree ?? 0),
                                        0
                                    )}
                                </span>
                            </div>
                        </>
                    ) : null}
                </>
            )
        }
        return null
    }, [student, userDateFormat])

    const leftSection = useMemo(() => {
        if (type === ExportPdfFrameType.TermCourseSchedule) {
            return null
        }
        if (type === ExportPdfFrameType.AcademicPlanSection) {
            return (
                <>
                    <div className={styles.date}>
                        <b>School:</b>
                        <span>{student.campuses[0] ?? ""}</span>
                    </div>
                    <div className={styles.date}>
                        <b>Program:</b>
                        <span>{student.programName ?? ""}</span>
                    </div>
                    <div className={styles.date}>
                        <b>Enrollment Id:</b>
                        <span>{student.customProfileId}</span>
                    </div>
                    <div className={styles.date}>
                        <b>Date:</b>
                        <span>{moment().format(userDateFormat)}</span>
                    </div>
                </>
            )
        }
        return null
    }, [student, userDateFormat])

    return (
        <div className={styles.pdfPage} id={`pdf-${id}`}>
            <div className={styles.heading}>
                <div className={styles.clientSection}>
                    <img className={styles.logo} alt="" src={logoBase64} />
                    <div className={styles.clientInfo}>
                        <span className={styles.clientName}>{model.clientSetting?.name}</span>
                        <span>{client?.college_address}</span>
                        <span>
                            {client?.college_city}, {client?.college_state} {client?.postal_code}
                        </span>
                        {(client?.college_email || client?.college_phone) && (
                            <span>
                                {client?.college_email}&nbsp;&nbsp;&nbsp;{client?.college_phone}
                            </span>
                        )}
                        {leftSection}
                    </div>
                </div>
                <div className={styles.titleWrap}>
                    <span className={styles.pageTitle}>{pageTitle}</span>
                    {pageSubTitles?.map((title) => (
                        <span className={styles.pageSubTitle}>{title}</span>
                    ))}
                </div>
                <div className={styles.studentSection}>{rightSection}</div>
            </div>
            <div className={styles.tableContainer}>
                {payload?.terms?.map((item) => {
                    return (
                        <>
                            <span className={styles.tableTitle}>{item.termName}</span>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        {(payload?.columns || []).map((title, index) => {
                                            return (
                                                <th
                                                    key={index}
                                                    className={styles.tableHeaderText}
                                                    style={{
                                                        width: getWidthColumn(index),
                                                        maxWidth: getWidthColumn(index),
                                                        minWidth: getWidthColumn(index)
                                                    }}>
                                                    {title}
                                                </th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {item.courses.map((row, index) => {
                                        return (
                                            <tr key={index}>
                                                {row.map((v, ic) => {
                                                    return (
                                                        <td
                                                            key={ic}
                                                            className={styles.tableColumn}
                                                            style={{
                                                                width: getWidthColumn(ic),
                                                                maxWidth: getWidthColumn(ic),
                                                                minWidth: getWidthColumn(ic)
                                                            }}>
                                                            {v}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </>
                    )
                })}
            </div>
        </div>
    )
}
