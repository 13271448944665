/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import styles from "./ProgressReportPdf.module.css"
import {degreeAuditService} from "services"
import {StatusTerm} from "../CurrentProgress/CurrentProgress"
import {formatDecimalWithoutRound, handleError, roundTwoDigits} from "helpers"
import {AcademicPlans} from "types/academicPlans"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {GeneralSettingField} from "types/settings/general-setting"
import {Course} from "types/courses"
import {uniqBy} from "lodash"
import {toDateOnly} from "sections/academics/instructional/common/utils"
import {useModel} from "hooks"
import moment from "moment"
import {Spin} from "antd"

type CourseClockProps = {
    termId: number
    profileId: number
    status?: StatusTerm
    transcriptType: GeneralSettingField
    listOfSubmodules: string[]
    setListOfSubmodules: (value: string[]) => void
    setTotalHoursSubModules?: (value: number) => void
}

const CourseClock = (props: CourseClockProps) => {
    const {termId, profileId, status, transcriptType, setListOfSubmodules, listOfSubmodules, setTotalHoursSubModules} =
        props
    const [listData, setListData] = useState([])
    const model = useModel()
    const [loading, setLoading] = useState(true)

    const getParams = () => {
        const params: any = {
            studentProfileId: +profileId,
            range: {
                page: 1,
                pageSize: UNLIMITED_PAGE_SIZE
            }
        }
        if (termId) {
            params.termIds = [termId]
        }
        return params
    }

    const getData = async () => {
        try {
            const params = getParams()
            setLoading(true)
            const {data, total} = await degreeAuditService.getDetailCurrentProgress(params)
            const checkEarnedHours = (data) => {
                if (data.status === AcademicPlans.AcademicPlanCourseReviewStatus.Dropped) {
                    return "Withdrawn"
                } else if (data.status === AcademicPlans.AcademicPlanCourseStatus.Suspended) {
                    return "Suspended"
                } else if (data.status === AcademicPlans.AcademicPlanCourseReviewStatus.Registered) {
                    return data.earnedHours
                } else {
                    return ""
                }
            }
            let listOfSubmodules: Course.Submodule[] = []
            for (let course of data) {
                listOfSubmodules = listOfSubmodules.concat(course.submodules)
            }
            listOfSubmodules = uniqBy(listOfSubmodules, "title")
            setListOfSubmodules(listOfSubmodules.map((submodule) => submodule.title))
            const listData = data.map((item) => ({
                ...item,
                completedDate: moment(toDateOnly(item.lastDayOfCourse)).format(model.getUserDateFormat()),
                alpha: typeof item?.alpha === "string" ? item?.alpha : "",
                earnedHours: checkEarnedHours(item)
            }))
            if (transcriptType === GeneralSettingField.ProgressReportSubmodulesBreakDown && setTotalHoursSubModules) {
                setTotalHoursSubModules(
                    listData.reduce((total, item) => {
                        if (item.isPassed && item.isVerified) {
                            return total + (item?.credit ?? 0)
                        }
                        return total
                    }, 0)
                )
            }
            setLoading(false)
            setListData(listData)
        } catch (err) {
            setLoading(false)
            handleError(err)
        }
    }

    useEffect(() => {
        if (profileId) {
            getData()
        }
    }, [termId, profileId, status, setTotalHoursSubModules])

    const totalTermGrade = listData.reduce((total, item) => {
        if (item?.totalGpa !== "" && typeof item.totalGpa == "number" && item?.totalGpa >= 0) {
            total += item.totalGpa
        }
        return total
    }, 0)

    const termAvg = formatDecimalWithoutRound(totalTermGrade >= 0 ? totalTermGrade / listData.length : 0)
    const totalEarnedHours = listData.reduce((total, item) => {
        if (typeof item.earnedHours === "number" && item.earnedHours) {
            total += roundTwoDigits(item.earnedHours)
        }
        return total
    }, 0)

    const totalEarnedHoursValue = formatDecimalWithoutRound(totalEarnedHours ?? 0)

    if (transcriptType === GeneralSettingField.ProgressReportSubmodulesBreakDown) {
        return (
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th className={styles.tableHeaderText} style={{width: "15%", fontSize: 11}}>
                            Code
                        </th>
                        <th className={styles.tableHeaderText} style={{width: "25%", fontSize: 11}}>
                            Class Name
                        </th>
                        <th className={styles.tableHeaderText} style={{width: "5%", fontSize: 11}}>
                            Grade
                        </th>
                        <th className={styles.tableHeaderText} style={{width: "10%", fontSize: 11}}>
                            End Date
                        </th>
                        {transcriptType === GeneralSettingField.ProgressReportSubmodulesBreakDown &&
                            listOfSubmodules.map((title) => (
                                <th className={styles.tableHeaderText} style={{fontSize: 11}}>
                                    {title}
                                </th>
                            ))}
                        <th className={styles.tableHeaderText} style={{width: "5%", fontSize: 11}}>
                            Total Hours
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {listData.map((data, index) => {
                        return (
                            <tr key={index.toString()}>
                                <td className={styles.tableColumn}>{data.codeForTranscript}</td>
                                <td className={styles.tableColumn}>{`${data.nameForTranscript}${
                                    data.role === Course.AcademicPlanCourseRole.ClassAssistance ? " (CA)" : ""
                                }`}</td>{" "}
                                <td className={styles.tableColumn}>{data?.alpha}</td>
                                <td className={styles.tableColumn}>{data?.completedDate}</td>
                                {transcriptType === GeneralSettingField.ProgressReportSubmodulesBreakDown &&
                                    listOfSubmodules.map((title) => {
                                        const submodule = data.submodules.find((item) => item.title === title)
                                        return <td className={styles.tableColumn}>{submodule?.quantity ?? 0}</td>
                                    })}
                                <td className={styles.tableColumn}>
                                    {data.isPassed && data.isVerified ? data?.credit : ""}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }
    return (
        <div className={styles.tableContainerCourse}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th className={styles.tableHeaderText} style={{width: "15%"}}>
                            Course id
                        </th>
                        <th className={styles.tableHeaderText} style={{width: "25%"}}>
                            Name
                        </th>
                        <th className={styles.tableHeaderText} style={{width: "25%"}}>
                            Term
                        </th>
                        <th className={styles.tableHeaderText} style={{width: "10%"}}>
                            Completed Date
                        </th>
                        <th className={styles.tableHeaderText} style={{width: "15%"}}>
                            Completed Hours
                        </th>
                        <th className={styles.tableHeaderText} style={{width: "5%"}}>
                            Quality Points
                        </th>
                        <th className={styles.tableHeaderText} style={{width: "5%"}}>
                            Alphabetical
                        </th>
                        <th className={styles.tableHeaderText} style={{width: "5%"}}>
                            Grade
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {listData.map((data, index) => {
                        return (
                            <tr key={index.toString()}>
                                <td className={styles.tableColumn}>{data.codeForTranscript}</td>
                                <td className={styles.tableColumn}>{`${data.nameForTranscript}${
                                    data.role === Course.AcademicPlanCourseRole.ClassAssistance ? " (CA)" : ""
                                }`}</td>{" "}
                                <td className={styles.tableColumn}>{data.nameTerm}</td>
                                <td className={styles.tableColumn}>{data.completedDate}</td>
                                <td className={styles.tableColumn}>{roundTwoDigits(data.earnedHours)}</td>
                                <td className={styles.tableColumn}>{data.qualityPoints}</td>
                                <td className={styles.tableColumn}>{data.alpha}</td>
                                <td className={styles.tableColumn}>{data.totalGpa}</td>
                            </tr>
                        )
                    })}
                    <tr>
                        <td className={styles.termAvg}>TOTAL</td>
                        <td className={styles.termAvg}></td>
                        <td className={styles.termAvg} />
                        <td className={styles.termAvg} />
                        <td className={styles.termAvg}>{totalEarnedHoursValue}</td>
                        <td className={styles.termAvg} />
                        <td className={styles.termAvg} />
                        <td className={styles.termAvg}>{termAvg}</td>
                    </tr>
                </tbody>
            </table>
            {loading && <Spin />}
        </div>
    )
}

export default CourseClock
