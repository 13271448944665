/* eslint-disable react-hooks/exhaustive-deps */
import {CareerServices} from "types/careerServices"
import styles from "./BoardLicense.module.css"
import {Button, Col, Row} from "antd"
import {PlusOutlined} from "@ant-design/icons"
import {DisplayMode} from "types/common"
import {FormLabel} from "components/Form"
import {KlassDropdown} from "components/Select"
import {BaseInput} from "components"
import {BaseDatePicker} from "components/DateTimePicker"
import moment from "moment"

type Props = {
    student: CareerServices.StudentDetail
    displayMode?: DisplayMode
    isLoading: boolean
    isSubmitting: boolean
    updateStudent: (_student: CareerServices.StudentDetail) => void
}

const BoardStatusOptions = Object.entries(CareerServices.BoardStatus).map(([_, value]) => ({
    id: value,
    name: value.charAt(0).toUpperCase() + value.substring(1)
}))

export function BoardLicense(props: Props) {
    const {displayMode = "normal", student, updateStudent} = props
    const isFullDisplay = displayMode === "normal"

    const addNewRecord = () => {
        const _student = {...student}
        _student.boardLicenses.push({
            boardLicense: CareerServices.BoardStatus.None
        })
        updateStudent(_student)
    }

    const onRemoveRecord = (index) => {
        const _student = {...student}
        _student.boardLicenses.splice(index, 1)
        updateStudent(_student)
    }

    const onChangeRecordData = (index, key, value) => {
        const _student = {...student}
        _student.boardLicenses[index][key] = value
        updateStudent(_student)
    }

    return (
        <div className={styles.boardWrap}>
            <span className={styles.boardTitle}>Board License</span>
            {student.boardLicenses?.map((item, index) => (
                <Row className={styles.field} gutter={[16, 16]} key={index}>
                    <Col span={isFullDisplay ? 7 : 24}>
                        <FormLabel label="Board License" />
                        <KlassDropdown
                            value={item.boardLicense && BoardStatusOptions.filter((x) => x.id === item.boardLicense)[0]}
                            placeholder="Select"
                            onChange={(option: any) => onChangeRecordData(index, "boardLicense", option.id)}
                            options={BoardStatusOptions}
                        />
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <FormLabel label="Board Date" />
                        <BaseDatePicker
                            value={item.boardDate ? moment(item.boardDate) : null}
                            onChange={(_value) => onChangeRecordData(index, "boardDate", _value)}
                            placeholder="Board Date"
                        />
                    </Col>
                    <Col span={isFullDisplay ? 8 : 22}>
                        <FormLabel label="License Name" />
                        <BaseInput
                            value={item.licenseName}
                            onChange={(_value) => onChangeRecordData(index, "licenseName", _value)}
                            placeholder="License Name"
                        />
                    </Col>
                    <Col span={isFullDisplay ? 1 : 2}>
                        <div className={styles.removeBoard} onClick={() => onRemoveRecord(index)}></div>
                    </Col>
                </Row>
            ))}
            {/* <Col span={isFullDisplay ? 8 : 24}>
                        <FormLabel label="Board Status" />
                        <KlassDropdown
                            value={
                                student.boardStatus && BoardStatusOptions.filter((x) => x.id === student.boardStatus)[0]
                            }
                            placeholder="Select"
                            onChange={(option: any) => onChangeData("boardStatus", option.id)}
                            options={BoardStatusOptions}
                            isDisabled={isDisabled}
                        />
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <FormLabel label="License Name" />
                        <BaseInput
                            value={student.licenseName}
                            onChange={(_value) => onChangeData("licenseName", _value)}
                            placeholder="License Name"
                            disabled={isDisabled}
                        />
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <FormLabel label="Board Date" />
                        <BaseDatePicker
                            value={student.boardDate ? moment(student.boardDate) : null}
                            onChange={(_value) => onChangeData("boardDate", _value)}
                            placeholder="Board Date"
                            disabled={isDisabled}
                        />
                    </Col> */}
            <div className={styles.addBtnWrap}>
                <Button
                    size="middle"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={addNewRecord}
                    className={styles.addBtn}>
                    New record
                </Button>
            </div>
        </div>
    )
}
