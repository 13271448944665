import React from "react"
import {v4 as uuidv4} from "uuid"
import {withTranslation} from "react-i18next"
import {isEmpty} from "lodash"
import {BaseInput} from "components/inputs"
import {AddItemCircleButton} from "components/buttons"
import {Icon} from "components/Icon"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableProps, Order} from "types/common"
import {getPositionMoveInObject, handleError, checkPermission, toastError, toastWarning} from "helpers"
import {settingStageService} from "services"
import {KlassappTableHOC} from "HOC"
import {BaseDepartmentId} from "types/departments"
import {PermissionsRequired} from "components/PermissionsRequired"
import {getPermissionsByDepartment} from "sections/Settings/helper"
import {Model} from "Model"
import {PermissionUserType} from "types/permission"
import {ActionButton} from "./ActionButton"
import styles from "./Stages.module.css"

interface PageProps {
    t: Function
    departmentId: BaseDepartmentId
    model: Model
}

type Props = KlassappTableProps & PageProps

interface State {
    newItemData: any
    oldItemData: any
    isSubmitting: boolean
    permissions: PermissionUserType
}

class Stages extends React.Component<Props, State> {
    state = {
        newItemData: null,
        oldItemData: null,
        isSubmitting: false,
        permissions: {staff: []}
    }

    componentDidMount() {
        this.getPermissions()
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getData},
            {key: "getFields", func: this.getFields},
            {key: "getTableHeaderActions", func: this.getTableHeaderActions},
            {key: "getMenuActions", func: this.getMenuActions},
            {key: "getColumns", func: this.getColumns},
            {key: "onClickDeleteMulti", func: this.onClickDeleteMulti},
            {key: "onClickDuplicateMulti", func: this.onClickDuplicateMulti},
            {key: "onClickEdit", func: this.onClickEdit},
            {key: "onClickDelete", func: this.onClickDelete}
        ])
        this.props.dispatch({orderField: {field: "position", order: Order.Asc}})
        this.getData()
    }

    getPermissions = () => {
        const permissions = getPermissionsByDepartment(this.props.departmentId)
        this.setState({permissions})
    }

    getPageTitle = () => {
        return this.props.t("settings.stages.stage")
    }

    getFields = () => {
        const {t} = this.props
        return [t("settings.stages.name"), t("settings.stages.order")]
    }

    getTableHeaderActions = (isShowDuplicateBtn = true, checkedData = []) => {
        const canWrite = checkPermission(this.state.permissions, this.props.model)
        if (!canWrite) {
            return []
        }
        const hasLockedItem = (checkedData || []).some((item) => item.isLock)
        const {t, onClickShowConfirmModal} = this.props
        const actions = []
        if (!hasLockedItem) {
            actions.push({
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            })
        }
        if (isShowDuplicateBtn) {
            actions.push({
                title: t("common:action.duplicate"),
                icon: "DUPLICATE",
                action: () => this.onClickDuplicateMulti()
            })
        }
        return actions
    }

    canShowDeleteIcon = (data) => {
        return checkPermission(this.state.permissions, this.props.model) && !data.isLock
    }

    canShowEditIcon = () => {
        return checkPermission(this.state.permissions, this.props.model)
    }

    getMenuActions = () => {
        const {t} = this.props
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: this.onClickEdit,
                canShow: this.canShowEditIcon
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: this.onClickDelete,
                canShow: this.canShowDeleteIcon
            }
        ]
    }

    getData = async () => {
        const {dispatch, page, pageSize, orderField} = this.props
        dispatch({isLoading: true})
        const params = this.getParams()
        try {
            const {data, total} = await settingStageService.getAllStages(params)
            let totalPage = Math.ceil(total / pageSize)
            totalPage = totalPage <= 0 ? 1 : totalPage
            const newData = data.map((item, index) => {
                item.id = item.stageId
                item.nameHtml = this.renderNameHtml(item)
                if ((orderField.order || params.sort.orderDir) === Order.Asc) {
                    item.order = (page - 1) * pageSize + index + 1
                } else if (totalPage !== page) {
                    item.order = (totalPage - page) * pageSize + data.length - index - 1
                } else {
                    item.order = data.length - index
                }
                return item
            })

            dispatch({data: newData, total})
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    renderNameHtml = (item) => {
        return (
            <div className={styles.titleWrap}>
                {item.isLock ? (
                    <div className={styles.lockIcon}>
                        <Icon icon="LOCK_CHECKMARK" />
                    </div>
                ) : (
                    ""
                )}
                <span className={styles.title}>{item.name}</span>
            </div>
        )
    }

    getParams = () => {
        const {page, pageSize, departmentId, orderField} = this.props
        const params = {
            range: {
                page,
                pageSize
            },
            sort: {
                orderBy: "position",
                orderDir: Order.Asc
            },
            filter: {
                departmentIds: [departmentId]
            }
        }
        if (!isEmpty(orderField)) {
            params.sort = {
                orderBy: orderField.field,
                orderDir: orderField.order
            }
        }
        return params
    }

    getColumns = () => {
        const {t} = this.props

        return [
            {
                title: t("settings.stages.name"),
                field: "nameHtml",
                style: {minWidth: "200px"},
                sortable: true,
                orderField: "name"
            },
            {
                title: t("settings.stages.order"),
                field: "order",
                style: {minWidth: "200px"},
                sortable: true,
                orderField: "position"
            }
        ]
    }

    onClickDeleteMulti = async () => {
        const {dispatch, t} = this.props
        const checkedItems = this.props.data.filter((stage) => stage.isChecked)
        const lockedStages = checkedItems.filter((stage) => stage.isLock)
        if (lockedStages.length) {
            toastError(t("common:message.cantDeleteLockedItem"))
            return
        }
        const payload = checkedItems.map((item) => item.id)
        try {
            this.props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await settingStageService.deleteStage(payload)
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    onClickDuplicateMulti = async () => {
        const {data, dispatch, departmentId} = this.props
        const payload = data
            .filter((item) => item.isChecked)
            .map(({name, state}) => ({
                name: `${name} (Copy)`,
                state,
                departmentId
            }))
        try {
            dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await settingStageService.duplicateStage(payload)
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    onClickEdit = (editItem) => {
        const {newItemData} = this.state
        if (newItemData) {
            toastWarning(this.props.t("common:validation.saveBeforeEdit"))
            return
        }
        this.setState(
            {
                newItemData: {
                    ...editItem,
                    nameData: editItem.name,
                    stateData: editItem.state
                },
                oldItemData: {...editItem}
            },
            () => {
                const newData = this.props.data.map((item) => {
                    if (item.id === editItem.id) {
                        item.nameHtml = this.renderNameInput()
                        return item
                    }
                    return item
                })
                const {dispatch} = this.props
                dispatch({data: newData})
            }
        )
    }

    onClickDelete = async (deletedItem) => {
        const {dispatch, t} = this.props
        if (deletedItem.isLock) {
            toastError(t("common:message.cantDeleteLockedItem"))
            return
        }
        if (deletedItem.isForm) {
            this.setState({newItemData: null, oldItemData: null})
            await this.getData()
            return
        }
        try {
            dispatch({isLoading: true})
            this.setState({newItemData: null, oldItemData: null})
            await settingStageService.deleteStage([deletedItem.id])
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    onDraggableEnd = (result) => {
        const {destination, source} = result || {}
        if (!destination || destination.index === source.index) {
            return
        }
        if (destination.index === source.index) {
            return
        }
        const {id} = this.props.data[source.index]
        const newPosition = getPositionMoveInObject(
            this.props.data,
            "position",
            source.index,
            destination.index,
            this.props.orderField.order === Order.Asc
        )
        this.updateStage(id, {position: newPosition})
    }

    onChangeNewItemData = (key, value) => {
        const {newItemData} = this.state
        const {data} = this.props
        newItemData[key] = value
        const newData = data.map((item) => {
            if (item.id === newItemData.id) {
                item.nameHtml = this.renderNameInput()
                return item
            }
            return item
        })
        this.setState({newItemData})
        this.props.dispatch({data: newData})
    }

    renderNameInput = () => {
        const newItemData = this.state.newItemData || {}
        return (
            <BaseInput
                placeholder="Name"
                value={newItemData.nameData}
                onChange={(value) => this.onChangeNewItemData("nameData", value)}
            />
        )
    }

    getMaxOrderInList = () => {
        const orders = this.props.data.length ? this.props.data.map((item) => item.order) : [0]
        return Math.max(...orders)
    }

    onClickAddItem = () => {
        const order = this.getMaxOrderInList() + 1
        const newItem = {
            id: uuidv4(),
            nameHtml: this.renderNameInput(),
            order,
            isForm: true
        }
        const {data} = this.props
        data.push(newItem)
        this.setState({newItemData: newItem, oldItemData: null})
        this.props.dispatch({data})
    }

    validateDataBeforeSubmit = () => {
        const {nameData} = this.state.newItemData
        if (!nameData) {
            toastError(this.props.t("common:validation.cantEmpty", {field: "The Stage Name"}))
            return false
        }
        return true
    }

    onClickSave = async () => {
        if (!this.validateDataBeforeSubmit()) {
            return
        }
        const {nameData, stateData, isForm, id} = this.state.newItemData
        const {departmentId} = this.props
        if (isForm) {
            const submitData = {
                name: nameData,
                state: stateData,
                departmentId
            }
            await this.createStage(submitData)
        } else {
            const submitData = {name: nameData, state: stateData}
            await this.updateStage(id, submitData)
        }
        this.setState({newItemData: null, oldItemData: null}, () => {
            this.getData()
        })
        this.props.dispatch({isShowTableHeaderAction: false})
    }

    createStage = async (data) => {
        const {dispatch} = this.props
        try {
            this.setState({isSubmitting: true})
            dispatch({isLoading: true})
            await settingStageService.createStage(data)
        } catch (e) {
            handleError(e)
        } finally {
            this.setState({isSubmitting: false})
            dispatch({isLoading: false})
        }
    }

    updateStage = async (id, data) => {
        const {dispatch} = this.props
        try {
            this.setState({isSubmitting: true})
            dispatch({isLoading: true})
            await settingStageService.updateStage(id, data)
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            this.setState({isSubmitting: false})
            dispatch({isLoading: false})
        }
    }

    onClickCancel = () => {
        const {data, isLoading} = this.props
        const {oldItemData, isSubmitting} = this.state
        if (isLoading || isSubmitting) {
            return
        }
        const newData = data
            .filter((item) => !item.isForm)
            .map((item) => {
                if (oldItemData && oldItemData.id === item.id) {
                    item.nameHtml = this.renderNameHtml(oldItemData)
                    return item
                }
                return item
            })
        this.setState({newItemData: null, oldItemData: null})
        this.props.dispatch({data: newData})
    }

    render() {
        const {newItemData, isSubmitting, permissions} = this.state
        const {
            page,
            total,
            pageSize,
            columns,
            data,
            menuActions,
            allFields,
            fields,
            tableHeaderActions,
            isLoading,
            isHideMenuActions,
            isShowTableHeaderAction,
            orderField
        } = this.props

        return (
            <div className={styles.wrapper}>
                <KlassappTableHeader
                    isShowAction={isShowTableHeaderAction}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.props.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    data={data}
                    menuActions={isHideMenuActions ? [] : menuActions}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    isShowCheckedColumn
                    isDraggable={!newItemData && orderField.field === "position"}
                    orderField={orderField}
                    onClickRowItem={() => {}}
                    onChangeFields={this.props.onChangeFields}
                    onUpdateRowData={this.props.onUpdateRowData}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onClickSortColumn={this.props.onClickSortColumn}
                    onDraggableEnd={this.onDraggableEnd}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onChangeAllFields={this.props.onChangeAllFields}
                />
                <PermissionsRequired permissions={permissions}>
                    {!newItemData ? (
                        !isLoading && <AddItemCircleButton onClick={this.onClickAddItem} />
                    ) : (
                        <ActionButton
                            isSubmitting={isSubmitting}
                            onClickCancel={this.onClickCancel}
                            onClickSave={this.onClickSave}
                        />
                    )}
                </PermissionsRequired>
            </div>
        )
    }
}

export default KlassappTableHOC(withTranslation(["settings", "common"])(Stages))
