/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-param-reassign */
import {useCallback, useEffect, useRef, useState} from "react"
import ChatWsV2 from "../chatWsV2"
import {Communication} from "types/communication"

const typingDuration = 5000 // 5s

export default function useRoomTypings(enabled = true, user) {
    const [typings, setTypings] = useState<{[key: number]: any}>({})
    const clearTypingsTimeoutIds = useRef<any>({})

    useEffect(() => {
        if (!enabled) {
            return () => {}
        }
        const unsubscribeTypings = ChatWsV2.registerTypingsEvent((data: Communication.TypingEvent) => {
            setUserTyping(data)
        })
        return () => {
            unsubscribeTypings()
        }
    }, [enabled])

    const setUserTyping = useCallback(
        (data: Communication.TypingEvent) => {
            const isFromMe = data.fromUserId === user.id
            if (isFromMe) {
                return
            }
            const currentTimeout = clearTypingsTimeoutIds.current[`id-${data.fromUserId}`]
            if (currentTimeout) {
                clearTimeout(currentTimeout)
            }
            setTypings((prev) => ({
                ...prev,
                [data.roomId]: {
                    ...prev[data.roomId],
                    [data.fromUserId]: true
                }
            }))

            const timeoutId = setTimeout(() => {
                setTypings((prev) => {
                    if (prev[data.roomId]) {
                        delete prev[data.roomId][data.fromUserId]
                        if (Object.keys(prev[data.roomId]).length === 0) {
                            delete prev[data.roomId]
                        }
                    }
                    return {
                        ...prev
                    }
                })
            }, typingDuration)
            clearTypingsTimeoutIds.current[`id-${data.fromUserId}`] = timeoutId
        },
        [user]
    )

    return {typings}
}
