/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import moment from "moment"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import {studentActivityLogService} from "services"
import {KlassappTableHOC} from "HOC"
import {getFullName, handleError} from "helpers"
import styles from "sections/Workflow/WorkflowTriggersTable/WorkflowTriggersTable.module.css"
import {useModel} from "hooks"
import {Icon} from "components"
import {Col, Row, Spin} from "antd"
import {DisplayMode, KlassappTableProps} from "types/common"
import UserSelect from "components/UserSelect"
import WorkflowSelect from "components/WorkflowSelect"
import ContactStrategySelect from "components/ContactStrategySelect/ContactStrategySelect"
import {ExportButton, ExportFileType} from "components/ui"
import {exportCsv, exportExcel} from "helpers/export-table"
import {ActivityLogType, getWorkflowActionText, StudentActivityLog} from "types/student-activity-log"
import {get} from "lodash"
import {Auth} from "types/auth"
import classNames from "classnames"
import ActivityLogDetail from "sections/users/Detail/parts/StudentLogs/parts/ActivityLogs/ActivityLogDetail"

interface Props extends KlassappTableProps {
    displayMode?: DisplayMode
    profileId?: number
    departmentId?: number
}

const INITIAL_FILTER = {
    profiles: [],
    contactStrategies: [],
    workflows: []
}

function WorkflowTriggersTable(props: Props) {
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        tableHeaderActions,
        isLoading,
        isShowTableHeaderAction,
        getCurrentData,
        getCurrentPage,
        getCurrentPageSize,
        profileId,
        departmentId,
        displayMode = "normal"
    } = props

    const {t} = useTranslation(["workflow", "common"])
    const model = useModel()
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const isFullDisplay = displayMode === "normal"

    // region search/filter

    const [search, setSearch] = useState("")
    const [filter, setFilter] = useState(INITIAL_FILTER)
    const [appliedFilter, setAppliedFilter] = useState(INITIAL_FILTER)

    const onChangeFilter = React.useCallback((key: keyof typeof filter, value: any) => {
        setFilter((prev) => ({...prev, [key]: value}))
    }, [])

    const onClearFilter = React.useCallback(() => {
        setFilter(INITIAL_FILTER)
        setAppliedFilter(INITIAL_FILTER)
    }, [])

    const onApplyFilter = React.useCallback(() => {
        setAppliedFilter(filter)
    }, [filter])

    const onSearchInput = React.useCallback(
        (search = "") => {
            setSearch(search)
        },
        [search]
    )

    // endregion search/filter

    const getData = React.useCallback(async () => {
        try {
            dispatch({isLoading: true})
            const page = getCurrentPage()
            const pageSize = getCurrentPageSize()
            const filterParam: Record<string, any> = {
                search,
                type: [
                    ActivityLogType.AutomatedWorkflowActionTriggered,
                    ActivityLogType.ProcessWorkflowActionTriggered,
                    ActivityLogType.ContactStrategyActionTriggered
                ]
            }
            if (profileId) {
                filterParam.profileId = profileId
            }
            if (departmentId) {
                // TODO
            }
            if (appliedFilter.profiles?.length) {
                filterParam.profileId = appliedFilter.profiles.map((item) => item.profileId)
            }
            if (appliedFilter.workflows?.length) {
                filterParam.targetId = appliedFilter.workflows.map((item) => item.id)
                filterParam.type = [
                    ActivityLogType.AutomatedWorkflowActionTriggered,
                    ActivityLogType.ProcessWorkflowActionTriggered
                ]
            }
            if (appliedFilter.contactStrategies?.length) {
                filterParam.targetId = appliedFilter.contactStrategies.map((item) => item.contactStrategyId)
                filterParam.type = [ActivityLogType.ContactStrategyActionTriggered]
            }

            const {data: items, total} = await studentActivityLogService.getActivityLogs({
                filter: filterParam,
                range: {
                    offset: (page - 1) * pageSize,
                    limit: pageSize
                },
                linkedEntities: true
            })

            dispatch({
                data: items.map((item) => ({
                    ...item,
                    id: item.activityLogId
                })),
                total
            })

            if (total < (page - 1) * pageSize) {
                props.onChangePage(1)
            }
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }, [search, appliedFilter, profileId, departmentId])

    const getFields = () => {
        return [
            t("workflowTriggers.column.studentId"),
            t("workflowTriggers.column.studentName"),
            t("workflowTriggers.column.workflow"),
            t("workflowTriggers.column.action"),
            t("workflowTriggers.column.dateTime")
        ]
    }

    const getColumns = React.useCallback(() => {
        return [
            {
                title: t("workflowTriggers.column.logs"),
                field: "activityLogId",
                style: {width: 40},
                render: (activityLogId, record: StudentActivityLog) => (
                    <div
                        onClick={(e) => {
                            window.open(
                                `/users/${record.profile?.userId}?tab=${Auth.UserDetailTab.ActivityLog}&logid=${activityLogId}&logtime=${record.createdAt}`,
                                "_blank"
                            )
                            e.stopPropagation()
                        }}>
                        <Icon icon="CHECKMARK_CIRCLE_1" className={styles.logsIcon} />
                    </div>
                )
            },
            {
                title: t("workflowTriggers.column.studentId"),
                field: "profile.customProfileId"
            },
            {
                title: t("workflowTriggers.column.studentName"),
                field: "profile",
                render: (profile) => getFullName(profile)
            },
            {
                title: t("workflowTriggers.column.workflow"),
                field: "type",
                render: (type, record: StudentActivityLog) => {
                    const isWorkflow = [
                        ActivityLogType.AutomatedWorkflowActionTriggered,
                        ActivityLogType.ProcessWorkflowActionTriggered
                    ].includes(type)
                    return (
                        <a
                            href={
                                isWorkflow
                                    ? `/settings/workflows/${record.targetId}`
                                    : `/settings/contact-strategy/${record.targetId}`
                            }
                            target="_blank"
                            rel="noopener noreferrer">
                            {isWorkflow ? record.data?.workflowName : record.data?.contactStrategyName}
                        </a>
                    )
                }
            },
            {
                title: t("workflowTriggers.column.action"),
                field: "data",
                render: (_, record: StudentActivityLog) => getWorkflowActionText(record)
            },
            {
                title: t("workflowTriggers.column.dateTime"),
                field: "createdAt",
                style: {minWidth: 100},
                render: (value) => moment(value).format(userDateTimeFormat)
            }
        ]
    }, [])

    const handleRowClick = React.useCallback((item: StudentActivityLog) => {}, [])

    useEffect(() => {
        dispatch({isClassComponent: false, isHideMenuActions: true})
        dispatchFunc([
            {key: "getPageTitle", func: () => ""},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickRowItem", func: handleRowClick}
        ])
    }, [getColumns, handleRowClick])

    useEffect(() => {
        getData()
    }, [page, pageSize, search, appliedFilter])

    const handleExport = React.useCallback((type: ExportFileType) => {
        const columns = [
            {
                title: t("workflowTriggers.column.dateTime"),
                field: "createdAt",
                fieldType: "dateUtc",
                format: model.getUserDateFormat()
            },
            {
                title: t("workflowTriggers.column.studentId"),
                field: "profile.customProfileId"
            },
            {
                title: t("workflowTriggers.column.studentName"),
                fieldType: "custom",
                render: (item: StudentActivityLog) => getFullName(item.profile)
            },
            {
                title: t("workflowTriggers.column.workflow"),
                fieldType: "custom",
                render: (item: StudentActivityLog) => {
                    const isWorkflow = [
                        ActivityLogType.AutomatedWorkflowActionTriggered,
                        ActivityLogType.ProcessWorkflowActionTriggered
                    ].includes(item.type)
                    return (isWorkflow ? item.data?.workflowName : item.data?.contactStrategyName) ?? ""
                }
            },
            {
                title: t("workflowTriggers.column.action"),
                fieldType: "custom",
                render: (item: StudentActivityLog) => getWorkflowActionText(item)
            }
        ]
        const payload = {
            filename: t("workflowTriggers.title"),
            columns: columns.map((col) => col.title),
            rows: getCurrentData().map((item) =>
                columns.map((col) => {
                    if (col.fieldType === "custom") {
                        return col.render(item)
                    }
                    const value = get(item, col.field)
                    if (col.fieldType === "dateUtc") {
                        return value ? moment.utc(value).format(model.getUserDateTimeFormat()) : ""
                    }
                    return value ?? ""
                })
            )
        }
        if (type === "csv") {
            exportCsv(payload)
        } else if (type === "excel") {
            exportExcel(payload)
        }
    }, [])

    return (
        <div>
            <BaseNewFilter
                filter={filter}
                onClick={onApplyFilter}
                onClickClear={onClearFilter}
                onSearchInput={onSearchInput}
                searchValue={search}
                className="mt-20"
                showRightFilter={displayMode === "normal"}
                renderRightFilter={() =>
                    model.isStaffOrAdmin() && (
                        <div className={styles.createBtnWrap}>
                            <ExportButton onSelect={handleExport} availableFileTypes={["csv", "excel"]} />
                        </div>
                    )
                }>
                <Row gutter={[24, 24]}>
                    {!profileId && (
                        <>
                            <Col span={isFullDisplay ? 12 : 24}>
                                <UserSelect
                                    isMulti={true}
                                    placeholder="Select students"
                                    type={Auth.UserProfileType.Student}
                                    value={filter.profiles}
                                    onChange={(options) => onChangeFilter("profiles", options)}
                                />
                            </Col>
                            <Col span={isFullDisplay ? 12 : 24} />
                        </>
                    )}
                    <Col span={isFullDisplay ? 12 : 24}>
                        <WorkflowSelect
                            isMulti={true}
                            placeholder="Select workflows"
                            value={filter.workflows}
                            onChange={(options) => onChangeFilter("workflows", options)}
                        />
                    </Col>
                    <Col span={isFullDisplay ? 12 : 24}>
                        <ContactStrategySelect
                            isMulti={true}
                            placeholder="Select contact strategies"
                            value={filter.contactStrategies}
                            onChange={(options) => onChangeFilter("contactStrategies", options)}
                        />
                    </Col>
                </Row>
            </BaseNewFilter>

            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                actions={tableHeaderActions}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                displayMode={displayMode}
            />

            {displayMode === "normal" ? (
                <KlassappTable
                    columns={columns}
                    data={data}
                    isLoading={isLoading}
                    menuActions={[]}
                    fields={fields}
                    allFields={allFields}
                    isShowCheckedColumn={false}
                    onClickRowItem={props.onClickRowItem}
                    onChangeFields={props.onChangeFields}
                    onUpdateRowData={props.onUpdateRowData}
                    onUpdateTableData={props.onUpdateTableData}
                    onDraggableColumn={props.onDraggableColumn}
                    onChangeAllFields={props.onChangeAllFields}
                    expandable={{
                        rowExpandable: (item: StudentActivityLog) => true,
                        expandedRowRender: (item: StudentActivityLog, expanded: boolean) =>
                            !expanded ? null : <ActivityLogDetail item={item} />
                    }}
                />
            ) : isLoading ? (
                <Spin />
            ) : (
                data.map((item: StudentActivityLog) => <LogItem {...{item}} />)
            )}
        </div>
    )
}

export default KlassappTableHOC(WorkflowTriggersTable)

const LogItem: React.FC<{item: StudentActivityLog}> = ({item}) => {
    const model = useModel()
    const userDateTimeFormat = model.getUserDateTimeFormat()

    const [isExpanded, setExpanded] = useState(false)

    const toggleExpanded = useCallback(() => setExpanded((prev) => !prev), [])

    const isWorkflow = [
        ActivityLogType.AutomatedWorkflowActionTriggered,
        ActivityLogType.ProcessWorkflowActionTriggered
    ].includes(item.type)

    return (
        <div key={item.activityLogId} className={styles.rowWrap}>
            <div className={styles.rowTitleWrap} onClick={toggleExpanded}>
                <Icon icon="WORKFLOW" className={styles.logsIcon} />
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                        isWorkflow
                            ? `/settings/workflows/${item.targetId}`
                            : `/settings/contact-strategy/${item.targetId}`
                    }>
                    {isWorkflow ? item.data?.workflowName : item.data?.contactStrategyName}
                </a>
                <div className="flex-1" />
                <div className={classNames(styles.expandIcon, {[styles.expanded]: isExpanded})}>
                    <Icon icon="ARROW_DOWN" />
                </div>
            </div>
            <div className={styles.rowFieldWrap}>
                <div className={styles.rowFieldTitle}>Date completed</div>
                <div className={styles.rowFieldValue}>{moment(item.createdAt).format(userDateTimeFormat)}</div>
            </div>
            <div className={styles.rowFieldWrap}>
                <div className={styles.rowFieldTitle}>Action</div>
                <div className={styles.rowFieldValue}>{getWorkflowActionText(item)}</div>
            </div>
            {isExpanded && <ActivityLogDetail item={item} />}
        </div>
    )
}
