/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef, useState, useEffect} from "react"
import {Checkbox} from "antd"
import {v4 as uuidV4} from "uuid"
import {get} from "lodash"
import cx from "classnames"
import {Icon} from "components/Icon"
import {BaseInput} from "components/inputs"
import {KlassDropdown} from "components/Select"
import {FormLabel} from "components/Form"
import {DigitalDocumentFieldSetUp} from "types/tasks"
import {AddItemCircleButton} from "components/buttons/Button"
import {initialTypes} from "sections/Tasks/data"
import {getContentPositionStyle} from "./utils"
import styles from "./FieldActionItem.module.css"

export function InitialForm(props) {
    const {
        field,
        definedUsers,
        scaleInfo,
        totalPage,
        previousFieldInfo,
        canEditable,
        activeField,
        onClickDelete,
        onChangeField,
        onChangeFieldValue,
        onAddNewFieldValue,
        onRemoveFieldValue
    } = props
    const [style, setStyle] = useState({})
    const bodyRef = useRef(null)

    useEffect(() => {
        if (!bodyRef || !bodyRef.current) {
            return
        }
        const style = getContentPositionStyle(bodyRef, field, scaleInfo, totalPage)
        setStyle(style)
    }, [bodyRef])

    const onChangeData = (key, value) => {
        if (!canEditable) {
            return
        }
        field[key] = value
        onChangeField(field)
    }

    const onChangeFieldValueData = (parentId, id, key, value) => {
        if (!canEditable) {
            return
        }
        onChangeFieldValue(parentId, id, {[key]: value})
    }

    const onRemoveFieldItem = (parentId, id) => {
        if (field.fieldValues.length === 1) {
            onClickDelete(field)
        } else {
            onRemoveFieldValue(parentId, id)
        }
    }

    const onClickAddItem = () => {
        const left = field.placeInformation.left - previousFieldInfo.width - 10
        const newFieldValue = {
            id: uuidV4(),
            label: "",
            type: DigitalDocumentFieldSetUp.INITIAL,
            parentId: field.id,
            parentSignatureTypeId: get(field, "signatureTypes[0].id", null),
            signatureTypes: field.signatureTypes,
            placeInformation: {
                width: previousFieldInfo.width,
                height: previousFieldInfo.height,
                top: field.placeInformation.top,
                left: left > 0 ? left : 0
            }
        }
        onAddNewFieldValue(field.id, newFieldValue)
    }

    return (
        <div ref={bodyRef} className={styles.bodyContent} style={style}>
            <div className={styles.bodyHeader}>
                <p className={styles.bodyHeaderTitle}>Initials</p>
                {canEditable && (
                    <div className={styles.deleteBtn} onClick={() => onClickDelete(field)}>
                        <Icon icon="DELETE" className={styles.deleteIcon} />
                    </div>
                )}
            </div>
            <div className={styles.formItem}>
                <FormLabel label="Label" />
                <BaseInput
                    placeholder="Label"
                    value={field.label}
                    onChange={(newValue) => onChangeData("label", newValue)}
                />
            </div>
            <div className={styles.formItem}>
                <FormLabel label="Signers" />
                <KlassDropdown
                    options={definedUsers}
                    labelKey="label"
                    value={field.signatureTypes}
                    onChange={(newValue) => onChangeData("signatureTypes", newValue)}
                    isMulti
                />
            </div>
            <div className={styles.formItem}>
                <Checkbox
                    checked={field.required}
                    onChange={({target: {checked}}) => onChangeData("required", checked)}>
                    <span>Required</span>
                </Checkbox>
            </div>
            <div className={styles.formItem}>
                <FormLabel label="Initial type" />
                <KlassDropdown
                    options={initialTypes}
                    value={field.initialType}
                    onChange={(newValue) => onChangeData("initialType", newValue)}
                />
            </div>
            <div className={styles.divider}></div>
            {field.fieldValues.map((item, index) => (
                <div key={item.id} className={styles.itemWrap}>
                    <div className={styles.itemHeader}>
                        <FormLabel
                            label={`Option ${index + 1}`}
                            className={cx(styles.itemHeaderTitle, {
                                [styles.itemHeaderTitle__active]:
                                    activeField?.id === item.id || (field.id === activeField?.id && index === 0)
                            })}
                        />
                        {canEditable && (
                            <div className={styles.itemHeaderIcon} onClick={() => onRemoveFieldItem(field.id, item.id)}>
                                <Icon icon="DELETE" className={styles.deleteIcon} />
                            </div>
                        )}
                    </div>
                    <BaseInput
                        placeholder="Label"
                        value={item.label}
                        onChange={(newValue) => onChangeFieldValueData(field.id, item.id, "label", newValue)}
                    />
                </div>
            ))}
            {canEditable && (
                <div className={styles.formItem}>
                    <AddItemCircleButton onClick={onClickAddItem} />
                </div>
            )}
        </div>
    )
}
