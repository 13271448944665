/* eslint-disable react-hooks/exhaustive-deps */
import React, {useReducer} from "react"
import {Status, StudentFilterV2, StudentForm, StudentListTable} from "sections/shared/students"
import {checkPermission} from "helpers"
import {Permissions} from "types/permission"
import {AppliedFilter, FieldOperator, FilterKey} from "types/filter"
import {useModel} from "hooks"
import {cloneDeep, isEmpty, sumBy} from "lodash"
import {BaseDepartmentId} from "types/departments"
import styles from "./FinancialAidStaffVerificationTab.module.css"
import {CardItem} from "./parts/CardItem"
import {useQuery} from "@tanstack/react-query"
import {finAidService, generalSettingsService, isirService} from "services"
import {Order} from "types/common"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {VerificationStatus} from "types/fin-aid/verification-status"
import {GeneralSettingField} from "types/settings/general-setting"
import {FAStatusBy} from "types/fin-aid/fin-aid"
import {Col, Row} from "antd"
import moment from "moment"
import {KlassDropdown} from "components/Select"
import TermSelect from "components/TermSelect"

const initialFilter: AppliedFilter = {
    search: "",
    filters: {
        includeArchive: {operator: FieldOperator.Equal, value: false}
    }
}

type Props = {}

interface State {
    activeStudent: any
    staffs: any
    statsInfo: any
    studentForm: StudentForm
    studentFormSearch: string
    isExporting: boolean
    exportFileType: any
}

const currentYear = moment().year()
const yearOptions = [1, 0, -1, -2, -3].map((index) => ({
    id: currentYear + index,
    name: `${currentYear + index} - ${currentYear + index + 1}`
}))

export function FinancialAidStaffVerificationTab(props: Props) {
    const model = useModel()
    const filterKey = FilterKey.FinAidPage_Verifications
    const storageFilter = model.getStorageFilter(filterKey)
    const departmentId = BaseDepartmentId.FinancialAid
    const initialState: State = {
        activeStudent: {},
        staffs: [],
        statsInfo: [],
        studentFormSearch: !isEmpty(storageFilter) ? storageFilter.search : "",
        studentForm: !isEmpty(storageFilter?.filters) ? storageFilter.filters : {},
        isExporting: false,
        exportFileType: null
    }

    function reducer(state, action) {
        return {...state, ...action}
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const canShowAllStudents = React.useMemo(
        () => checkPermission({staff: [Permissions.Staff.FinancialAid.Director.View]}, model),
        [model]
    )

    const canHaveAccessOthers = React.useMemo(
        () => checkPermission({staff: [Permissions.Staff.FinancialAid.Students.PermissionsToAccessOthers.View]}, model),
        [model]
    )

    const statisticsQuery = useQuery(["statistics"], async () => await isirService.getStudentCCodeStatistics(), {
        initialData: []
    })

    const faStatusByQuery = useQuery(
        ["fa-status-by"],
        async () => {
            const [setting] = await generalSettingsService.getSettings([GeneralSettingField.FaStatusBy])
            return setting?.value ?? FAStatusBy.AwardYear
        },
        {
            initialData: FAStatusBy.AwardYear
        }
    )

    const verificationStatusesQuery = useQuery(
        ["verification-statuses", faStatusByQuery.data, state.studentForm?.faAwardYear, state.studentForm?.faTerm?.id],
        async () => {
            const {data} = await finAidService.getAllVerificationStatuses({
                range: {
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE
                },
                sort: {
                    orderBy: "priority",
                    orderDir: Order.Asc
                },
                withItemCount: true,
                filter: {
                    awardYear:
                        faStatusByQuery.data === FAStatusBy.AwardYear ? state.studentForm?.faAwardYear : undefined,
                    termId: faStatusByQuery.data === FAStatusBy.Term ? state.studentForm?.faTerm?.id : undefined
                }
            })
            return data as VerificationStatus[]
        },
        {}
    )

    const renderFinancialInfo = () => {
        const faStatusBy = faStatusByQuery.data
        return (
            <Row gutter={8} align="middle">
                <Col>{!faStatusBy || faStatusBy === FAStatusBy.AwardYear ? "Award Year" : "Term"}:</Col>
                <Col>
                    {!faStatusBy || faStatusBy === FAStatusBy.AwardYear ? (
                        <KlassDropdown
                            className={styles.dropdownWrapper}
                            isClearable={false}
                            placeholder="Award Year"
                            options={yearOptions}
                            value={yearOptions.find((option) => option.id === state.studentForm.faAwardYear)}
                            onChange={(option) => {
                                const newStudentForm = cloneDeep({
                                    ...state.studentForm,
                                    faAwardYear: option.id
                                })
                                model.updateStorageFilter(filterKey, {filters: newStudentForm})
                                dispatch({studentForm: newStudentForm})
                            }}
                        />
                    ) : (
                        <TermSelect
                            className={styles.dropdownWrapper}
                            isClearable={false}
                            placeholder="Term"
                            value={state.studentForm.faTerm}
                            onChange={(option) => {
                                const newStudentForm = cloneDeep({...state.studentForm, faTerm: option})
                                model.updateStorageFilter(filterKey, {filters: newStudentForm})
                                dispatch({studentForm: newStudentForm})
                            }}
                        />
                    )}
                </Col>
            </Row>
        )
    }

    const onApplyFilter = (payload: AppliedFilter) => {
        const {filters, search} = payload
        dispatch({studentForm: {...state.studentForm, ...filters}, studentFormSearch: search})
    }

    return (
        <div>
            <div className={styles.statusInfoWrap}>
                <div className={styles.statusColItem}>
                    <CardItem label="All" count={sumBy(statisticsQuery.data, "itemCount")} />
                </div>
                <div className={styles.statusColItem}>
                    <CardItem
                        label="Pending"
                        count={
                            statisticsQuery.data.find((record) => record.approvalStatus === "pending")?.itemCount ?? 0
                        }
                    />
                </div>
                <div className={styles.statusColItem}>
                    <CardItem
                        label="Approved"
                        count={
                            statisticsQuery.data.find((record) => record.approvalStatus === "approved")?.itemCount ?? 0
                        }
                    />
                </div>
                <div className={styles.statusColItem}>
                    <CardItem
                        label="Rejected"
                        count={
                            statisticsQuery.data.find((record) => record.approvalStatus === "rejected")?.itemCount ?? 0
                        }
                    />
                </div>
            </div>
            <div className={styles.statusInfoWrap}>
                <div className={styles.statusColItem}>
                    <CardItem label="All" count={sumBy(verificationStatusesQuery.data ?? [], "itemCount")} />
                </div>
                {verificationStatusesQuery.data?.map((status) => (
                    <div key={status.statusId} className={styles.statusColItem}>
                        <CardItem label={status.name} count={status.itemCount || 0} />
                    </div>
                ))}
            </div>
            <div className="mt-24">
                <StudentFilterV2
                    status={Status.finAidStatus}
                    canShowAllStudents={canShowAllStudents}
                    canHaveAccessOthers={canHaveAccessOthers}
                    filterKey={FilterKey.FinAidPage_Verifications}
                    departmentId={departmentId}
                    viewType="standard"
                    showExport={false}
                    hideLeadRecordAction={true}
                    hideLeadSource={true}
                    hideShowDueOnly={true}
                    hideChecklist={true}
                    renderFinancialInfo={renderFinancialInfo}
                    initialFilter={initialFilter}
                    onApplyFilter={onApplyFilter}
                    onClearFilter={() => onApplyFilter({filters: {}, search: ""})}
                />
                <StudentListTable
                    departmentId={departmentId}
                    studentForm={state.studentForm}
                    studentFormSearch={state.studentFormSearch}
                    updateState={dispatch}
                    canShowAllStudents={canShowAllStudents}
                    canHaveAccessOthers={canHaveAccessOthers}
                    filterMemoryKey={FilterKey.FinAidPage_Verifications}
                    exportFileType={state.exportFileType}
                    isFinancialVerificationTab={true}
                    useNewFilter={true}
                />
            </div>
        </div>
    )
}
