import {get} from "lodash"
import BaseService from "./Base"

const basePath: string = `/v1/settings/disbursement-settings`

class DisbursementSettingsService extends BaseService {
    async list(params) {
        const response = await this.post(`${basePath}/list`, params)
        return get(response, "data", [])
    }

    async create(data = {}) {
        const response = await this.post(`${basePath}/create`, data)
        return get(response, "data.data", {})
    }

    async update(id, data, params = {}) {
        data.disbursementSettingId = id
        const response = await this.post(`${basePath}/edit`, data, params)
        return get(response, "data", {})
    }

    async delete(disbursementSettingIds: number[]) {
        const response = await this.post(`${basePath}/delete`, {disbursementSettingIds})
        return get(response, "data", {})
    }

    async duplicate(disbursementSettingId: number) {
        const response = await this.post(`${basePath}/duplicate`, {disbursementSettingId})
        return get(response, "data", {})
    }

    async getMaxId(body = {}) {
        const response = await this.post(`${basePath}/get-max-id`, body)
        return get(response, "data", [])
    }
}

export default DisbursementSettingsService
