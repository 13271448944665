import React from "react"
import {Row, Col} from "antd"
import cx from "classnames"
import {AcademicInstructional} from "types/academicInstructional"
import styles from "./StudentDetail.module.css"
import {getFullName} from "helpers"

type Props = {
    studentDetail: AcademicInstructional.StudentInfo
}

export function StudentDetail(props: Props) {
    const {studentDetail} = props

    const renderStudentAvatar = () => {
        let studentPhoto = "/image/DefaultAvatar.png"
        if (studentDetail?.photo) {
            studentPhoto = studentDetail.photo["64"]
        }
        return (
            <div className={styles.avatar}>
                <img src={studentPhoto} alt="" />
            </div>
        )
    }

    return (
        <div className={styles.lessonInfoWrap}>
            <div className={styles.rowInfo}>
                {renderStudentAvatar()}
                <div className={styles.info}>
                    <div className={styles.lessonHeader}>
                        <span className={styles.lessonName}>{getFullName(studentDetail)}</span>
                    </div>
                    <div className={styles.line} />
                    <div className={styles.lessonInfoBody}>
                        <Row>
                            <Col span={5}>
                                <span className={styles.lessonInfo__title}>USER ID:</span>
                            </Col>
                            <Col span={10}>
                                <span className={cx(styles.lessonInfo__value, styles.capitalize)}>
                                    {studentDetail.customProfileId}
                                </span>
                            </Col>
                            <Col span={4}>
                                <span className={styles.lessonInfo__title}>Enrolement ID:</span>
                            </Col>
                            <Col span={3}>
                                <span className={styles.lessonInfo__value}>{studentDetail?.customProfileId}</span>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}
