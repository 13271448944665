/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import {useTranslation} from "react-i18next"
import {KlassDropdown, KlassDropdownProps} from "../Select"
import moment from "moment"
import {toDateOnly} from "sections/academics/instructional/common/utils"
import {sortBy, uniqBy} from "lodash"
import {useModel} from "hooks"
import {useDisbursementPeriods} from "hooks/useDisbursementPeriods"

type Props = Partial<KlassDropdownProps> & {}

const PaymentPeriodStartDateSelect: React.FC<Props> = ({...props}) => {
    const {t} = useTranslation(["common"])
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()

    const {periods} = useDisbursementPeriods()

    const paymentPeriodOptions = React.useMemo(() => {
        const options = periods.map((period) => {
            const date = toDateOnly(period.start)
            return {
                id: moment(date).format("YYYY-MM-DD"),
                name: moment(date).format(userDateFormat)
            }
        })
        return sortBy(uniqBy(options, "id"), "id")
    }, [periods])

    return (
        <KlassDropdown
            {...props}
            placeholder={props.placeholder || t("selectField.placeholder")}
            options={paymentPeriodOptions}
        />
    )
}

export default PaymentPeriodStartDateSelect
