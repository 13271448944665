import {useModel} from "hooks/useModel"
import {useCallback, useEffect} from "react"

interface Props {
    title: string
}

const HTMLTitle = (props: Props) => {
    const model = useModel()
    const setTitle = useCallback(
        (title) => {
            document.title = `${model.getStringCounter()}${title || "KlassApp"}`
        },
        [model]
    )
    useEffect(() => {
        setTitle(props.title)
    }, [props.title, setTitle])
    return null
}
export default HTMLTitle
