import React, {useReducer} from "react"
import {Row, Col} from "antd"
import debounce from "debounce-promise"
import {KlassDropAsyncPaginate, KlassDropdown} from "components/Select"
import {BaseDatePicker, BaseRangePicker} from "components/DateTimePicker"
import {BaseFilter} from "uiKit"
import styles from "./Filter.module.css"
import {majorService} from "services"
import {formatCodeName, handleError} from "helpers"
import {useTranslation} from "react-i18next"

function reducer(state, action) {
    return {...state, ...action}
}

export function Filter(props) {
    const {t} = useTranslation(["studentServices", "common"])

    const initialState = {
        filter: {
            search: "",
            campuses: [],
            programs: [],
            date: null
        }
    }

    const [{filter}, dispatchState] = useReducer(reducer, initialState)

    const onChangeFilter = (key, value) => {
        const newFilter = {...filter}
        newFilter[key] = value
        dispatchState({filter: newFilter})
    }

    const onProgramSearch = async (search = "", loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const {data: programs, total} = await majorService.getAll({
                filter: {
                    search
                },
                range: {
                    page,
                    pageSize
                }
            })
            return {
                options: programs,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            handleError(error)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const debouncePrograms = debounce(onProgramSearch, 300)

    return (
        <BaseFilter isShowFilterClear>
            <Row gutter={[40, 32]}>
                <Col span={12}>
                    <BaseDatePicker placeholder="Date Range" value="" getOptionLabel={formatCodeName} />
                </Col>
                <Col span={12}>
                    <KlassDropdown options={[]} value={[]} placeholder="Campus" />
                </Col>
                <Col span={12}>
                    <KlassDropAsyncPaginate
                        value={filter.programs}
                        onChange={(newValue) => onChangeFilter("programs", newValue)}
                        loadOptions={debouncePrograms}
                        isMulti
                        placeholder={t("filter.program")}
                        getOptionLabel={formatCodeName}
                    />
                </Col>
                <Col span={12}></Col>
                <Col span={12}>
                    <p className={styles.filter__title}>Student Status</p>
                    <BaseRangePicker placeholder={["Start Date", "End Date"]} value="" />
                </Col>
                <Col span={12}>
                    <p className={styles.filter__title}>Transactions</p>
                    <BaseRangePicker placeholder={["Start Date", "End Date"]} value="" />
                </Col>
            </Row>
        </BaseFilter>
    )
}
