import {Communication} from "types/communication"

// eslint-disable-next-line
let status: Communication.SocketStatus = Communication.SocketStatus.Unknown
const callbacks: {[key: string]: any} = []
const reconnectTimeout = 1 * 1000
let socketUnsubcribe: any = null

const WS_API_CHAT_URL = process.env.REACT_APP_CHAT_API_V2_BASE_URL
const WEBSOCKET_PROTOCOL = process.env.REACT_APP_WEBSOCKET_PROTOCOL
let heartBeatId = null

let domain = WS_API_CHAT_URL.split("//")[1]
let ws: WebSocket

/**
 * Init websocket and listeners.
 * @param getParams those params will be used to send to server when first connect
 */
const initialize = (params) => {
    ws = new WebSocket(`${WEBSOCKET_PROTOCOL}${domain}/ws`)

    ws.onopen = () => {
        const packet = {
            command: Communication.SocketCommand.Auth,
            payload: params
        }
        try {
            setStatus(Communication.SocketStatus.Connected)
            ws.send(JSON.stringify(packet))
            heartBeat()
        } catch (error) {}
    }

    ws.onmessage = (event: any) => {
        const dataObj = JSON.parse(event.data)
        const {command, data} = dataObj
        if (command === Communication.SocketCommand.NewMessage) {
            notify("messages", data)
            notify("sidebar-messages", data)
            const {roomId} = data
            notify(`message-${roomId}`, data)
        }
        if (command === Communication.SocketCommand.Typing) {
            notify("typings", data)
            const {roomId} = data
            notify(`typing-${roomId}`, data)
        }
        if (command === "reaction") {
            const {roomId} = data
            notify(`reaction-${roomId}`, data)
        }
        if (command === Communication.SocketCommand.EditMessage) {
            const {roomId} = data
            notify(`updated-message-${roomId}`, data)
        }
        if (command === Communication.SocketCommand.RoomCreated) {
            notify(`room-created`, data)
        }
        if (command === Communication.SocketCommand.RoomUnread) {
            notify(`room-unread`, data)
        }
        if (command === Communication.SocketCommand.TotalUnread) {
            notify(`total-unread`, data)
        }
        if (command === Communication.SocketCommand.RoomUpdated) {
            notify(`room-updated`, data)
        }
        if (command === Communication.SocketCommand.RoomRemoved) {
            notify(`room-removed`, data)
        }
        if (command === Communication.SocketCommand.NewNotification) {
            notify("sidebar-notifications", data)
            notify(`new-notification`, data)
        }
        if (command === Communication.SocketCommand.UpdateMessageReadStatus) {
            const {roomId} = data
            notify(`updated-message-read-${roomId}`, data)
        }
        if (command === Communication.SocketCommand.ThreadCreated) {
            notify(`scheduled-thread-created`, data)
            notify(`sidebar-thread-created`, data)
            const {parentRoomId} = data
            notify(`thread-created-${parentRoomId}`, data)
        }
        if (
            [
                Communication.SocketCommand.UpdatePoll,
                Communication.SocketCommand.PollClosed,
                Communication.SocketCommand.UpdatePollVoters
            ].includes(command)
        ) {
            const {roomId} = data
            notify(`poll-${roomId}`, data)
        }
        if (command === Communication.SocketCommand.UpdateMessagePinned) {
            const {messageId, roomId} = data
            notify(`message-pin-status-${messageId}`, data)
            notify(`room-pin-message-${roomId}`, data)
        }
        if (command === Communication.SocketCommand.OnlineStatus) {
            notify(`room-user-online`, data)
        }
        if (command === Communication.SocketCommand.SupportTicketUpdated) {
            const {support} = data
            notify(`sidebar-support-updated`, support)
            notify(`support-updated-${support.roomId}`, support)
        }
    }

    ws.onclose = (error) => {
        setStatus(Communication.SocketStatus.Disconnected)
        if (error.code !== 1000) {
            // Try to reconnect
            setTimeout(() => {
                initialize(params)
            }, reconnectTimeout)
        }
    }

    ws.onerror = (_error) => {
        ws.close()
        setStatus(Communication.SocketStatus.Unknown)
    }

    socketUnsubcribe = () => {
        ws.close(1000, "done")
        // Remove all listeners
        Object.keys(callbacks).forEach((key) => {
            callbacks[key] = undefined
        })
        clearInterval(heartBeatId)
    }

    function heartBeat() {
        if (status === "connected") {
            clearInterval(heartBeatId)
            const packet = {
                command: Communication.SocketCommand.Ping,
                payload: {}
            }
            ws.send(JSON.stringify(packet))
            heartBeatId = setTimeout(heartBeat, 30000)
        }
    }

    return () => {
        if (socketUnsubcribe) {
            socketUnsubcribe()
        }
    }
}

function sendPacket(data) {
    if (status === "connected") {
        ws.send(JSON.stringify(data))
    }
}
const setStatus = (wsStatus: Communication.SocketStatus) => {
    status = wsStatus
    // const listener = callbacks.stateChange;
    // if (listener) {
    //   listener(wsStatus);
    // }
}

// const registerOnStateChange = (
//   callback: OnStateChangeCallback,
// ): (() => void) => {
//   callbacks.stateChange = callback;
//   callback(status);
//   return () => {
//     callbacks.stateChange = undefined;
//   };
// };

const registerOnNewMessage = (roomId: number | string, callback: (message: Communication.Message) => void) =>
    registerListener(`message-${roomId}`, callback)

const registerOnNewMessages = (callback: (message: any) => void) => registerListener("messages", callback)
const registerOnSidebarNewMessages = (callback: (message: any) => void) =>
    registerListener("sidebar-messages", callback)

const registerOnRoomUnread = (callback: (data: any) => void) => registerListener("room-unread", callback)
const registerOnTotalUnread = (callback: (data: any) => void) => registerListener("total-unread", callback)
const registerOnRoomUpdated = (callback: (data: any) => void) => registerListener("room-updated", callback)

const registerTyingEvent = (roomId: number, callback: (value: Communication.TypingEvent) => void) =>
    registerListener(`typing-${roomId}`, callback)

const registerTypingsEvent = (callback: (value: Communication.TypingEvent) => void) =>
    registerListener("typings", callback)

const registerOnUpdatedMessage = (roomId: number | string, callback: (data: any) => void) =>
    registerListener(`updated-message-${roomId}`, callback)

const registerOnUpdatedMessageReadStatus = (roomId: number | string, callback: (data: any) => void) =>
    registerListener(`updated-message-read-${roomId}`, callback)

const registerOnMessagePinStatus = (messageId: number, callback: (data: any) => void) =>
    registerListener(`message-pin-status-${messageId}`, callback)

const registerOnRoomPinMessage = (roomId: number | string, callback: (data: any) => void) =>
    registerListener(`room-pin-message-${roomId}`, callback)

const registerOnRoomCreated = (callback: (data: any) => void) => registerListener(`room-created`, callback)

const registerOnRoomRemoved = (callback: (data: any) => void) => registerListener(`room-removed`, callback)

const registerOnNewNotification = (callback: (data: any) => void) => registerListener(`new-notification`, callback)
const registerOnSidebarNewNotification = (callback: (data: any) => void) =>
    registerListener(`sidebar-notifications`, callback)

const registerOnScheduledThreadCreated = (callback: (data: any) => void) =>
    registerListener(`scheduled-thread-created`, callback)

const registerOnSidebarThreadCreated = (callback: (data: any) => void) =>
    registerListener(`sidebar-thread-created`, callback)

const registerOnThreadCreated = (roomId: number | string, callback: (data: any) => void) =>
    registerListener(`thread-created-${roomId}`, callback)

const registerOnUpdatePoll = (roomId: number | string, callback: (data: any) => void) =>
    registerListener(`poll-${roomId}`, callback)

const registerOnRoomUserOnline = (callback: (data: any) => void) => registerListener(`room-user-online`, callback)

const registerOnSidebarSupportUpdated = (callback: (data: any) => void) =>
    registerListener(`sidebar-support-updated`, callback)

const registerOnSupportUpdated = (roomId: number | string, callback: (data: any) => void) =>
    registerListener(`support-updated-${roomId}`, callback)

const registerListener = (name: string, callback: any) => {
    callbacks[name] = callback
    return () => {
        callbacks[name] = null
    }
}

const notify = (name: string, data: any) => {
    const listener = callbacks[name]

    if (listener) {
        listener(data)
    }
}

const ChatWsV2 = {
    initialize,
    registerOnNewMessage,
    registerOnNewMessages,
    registerOnSidebarNewMessages,
    registerTyingEvent,
    registerTypingsEvent,
    registerOnUpdatedMessage,
    registerOnRoomCreated,
    registerOnRoomRemoved,
    sendPacket,
    registerOnRoomUnread,
    registerOnTotalUnread,
    registerOnRoomUpdated,
    registerOnMessagePinStatus,
    registerOnUpdatedMessageReadStatus,
    registerOnScheduledThreadCreated,
    registerOnSidebarThreadCreated,
    registerOnThreadCreated,
    registerOnUpdatePoll,
    registerOnNewNotification,
    registerOnSidebarNewNotification,
    registerOnRoomPinMessage,
    registerOnRoomUserOnline,
    registerOnSidebarSupportUpdated,
    registerOnSupportUpdated
}

export default ChatWsV2
