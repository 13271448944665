import React, {FC, memo} from "react"
import {LessonAttendanceStatus} from "types/attendance"
import {IconCheckCircleLine, IconAlertTriangleFill, IconCrossCircleLine} from "components/ui/icons"

export type LessonAttendanceStatusIconProps = {
    status: LessonAttendanceStatus
}

const LessonAttendanceStatusIcon: FC<LessonAttendanceStatusIconProps> = memo(({status}) => {
    if (!status || status === LessonAttendanceStatus.Missed) {
        return <IconCrossCircleLine />
    }
    if (status === LessonAttendanceStatus.NeedAction) {
        return <IconAlertTriangleFill />
    }
    if (status === LessonAttendanceStatus.Verified) {
        return <IconCheckCircleLine color="var(--success-400-base)" />
    }
    if (status === LessonAttendanceStatus.Attended) {
        return <IconCheckCircleLine color="var(--primary-400-base)" />
    }

    return null
})

export {LessonAttendanceStatusIcon}
