import React from "react"
import {Icon, IconType} from "components/Icon"
import styles from "./CartItem.module.css"

type Props = {
    icon: IconType
    iconBackgroundColor: string
    iconColor: string
    title: string
    subTitle?: string
    count: number
}

export function CartItem(props: Props) {
    const {icon, iconColor, iconBackgroundColor, title, subTitle, count} = props

    return (
        <div className={styles.cardWrap}>
            <div className={styles.titleWrap}>
                <div className={styles.iconWrapper} style={{background: iconBackgroundColor}}>
                    <Icon icon={icon} color={iconColor} className={styles.icon} />
                </div>
                <div>
                    <p className={styles.cardTitle}>{title}</p>
                    <p className={styles.cardSubTitle}>{subTitle}</p>
                </div>
            </div>
            <p className={styles.cardValue}>{count}</p>
        </div>
    )
}
