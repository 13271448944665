/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react"
import moment from "moment"
import {useModel, useVisible} from "hooks"
import {isEmpty} from "lodash"
import {KlassappTableProps} from "types/common"
import {isirService, userServiceV3, profileService, profileCampusService, campusesService} from "services"
import {useLocation} from "react-router-dom"
import {KlassappTableHeader, KlassappTable} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {Button} from "antd"
import styles from "./ISIR.module.css"
import {handleError, toastSuccess, checkPermission, handleErrorMessage} from "helpers"
import {Permissions} from "types/permission"
import {DEPENDENCY_STATUS_OPTIONS} from "types/students"
import {useTranslation} from "react-i18next"
import cx from "classnames"
import {Model} from "Model"
import {BaseButton} from "components/buttons"
import {Icon} from "components/Icon"
import {ActivityPopup} from "sections/shared/student/ActivityPopup"
import {routing} from "helpers/routing"
import {FinancialAidStudentTabs} from "../../../FinancialAidStudent/FinancialAidStudent"
import {FinancialAidStaffTabs} from "sections/FinancialAid/FinancialAidStaff"

const verificationStatus = {
    y: "Verification",
    n: "New",
    "*": "Verification"
}

const genderReference = {
    "1": "male",
    "2": "female"
}

const maritalStatusReference = {
    "1": "single",
    "2": "married",
    "3": "separated",
    "4": "divorced"
}

const reportKeys = [
    "awardYear",
    "schoolCode",
    "firstName",
    "lastName",
    "ssn",
    "dob",
    "gender",
    "dependencyStatus",
    "maritalStatus",
    "fti",
    "primaryEfc",
    "email",
    "phoneNumber",
    "streetAddress",
    "city",
    "state",
    "postalCode"
]

const reportColumnIndex = {
    awardYear: "award year",
    schoolCode: "school code",
    firstName: "first name",
    lastName: "last name",
    ssn: "ssn",
    dob: "dob",
    gender: "gender",
    dependencyStatus: "dependency status",
    maritalStatus: "marital status",
    fti: "fti",
    primaryEfc: "primary efc",
    email: "email",
    phoneNumber: "phone number",
    streetAddress: "street address",
    city: "city",
    state: "state/province/territory",
    postalCode: "postal code"
}

type PageProps = {
    history?: any
    studentId: number
    departmentId: number
    model: Model
}

type Props = KlassappTableProps & PageProps

function ISIRReport({
    history,
    studentId,
    dispatch,
    dispatchFunc,
    page,
    total,
    pageSize,
    columns,
    data,
    allFields,
    fields,
    tableHeaderActions,
    isShowTableHeaderAction,
    isLoading,
    orderField,
    departmentId,
    isHideMenuActions,
    onChangePage,
    onChangeRowPerPage,
    onChangeFields,
    onUpdateRowData,
    onUpdateTableData,
    onClickSortColumn,
    onDraggableColumn,
    onChangeAllFields
}: Props) {
    const location = useLocation()
    const backUrl: string =
        history.location.state?.backUrl ?? routing.finAid.staff.index(FinancialAidStaffTabs.Students)
    const query = new URLSearchParams(location.search)
    const isirCodeWithoutPrefix: string = query.get("isirCode")

    const {t} = useTranslation(["common", "financialAid"])

    const model = useModel()

    const [reportStatus, setReportStatus] = useState<string>("") // "not_found" | "mismatch" | "matched" | "locked"
    const activityPopup = useVisible(false)
    const [studentEmail, setStudentEmail] = useState<string[]>([])
    const [isirReport, setIsirReport] = useState<any>(null)
    const [isirReportSelected, setIsirReportSelected] = useState<any>(null)
    const [isDefaultReport, setIsDefaultReport] = useState<boolean>(false)
    const [systemData, setSystemData] = useState<any>(null)
    const [reportDifferentKeys, setReportDifferentKeys] = useState<string[]>(null)
    const [userId, setUserId] = useState<any>(null)
    const [isirCode, setISIRCode] = useState<any>(`ISIR [${isirCodeWithoutPrefix ?? ""}]`)
    const [selectedISIRCode, setSelectedISIRCode] = useState<string>(null)
    const [schoolCodeData, setSchoolCodeData] = useState<any>(null)

    useEffect(() => {
        dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickRowItem", func: onClickShowDetail}
        ])

        if (studentId) {
            getProfileCampusData()
            getSystemData({profileId: [Number(studentId)]})
        } else if ((isirCodeWithoutPrefix ?? "").trim() !== "") {
            getListISIR({isirCode: isirCodeWithoutPrefix})
        }
    }, [])

    useEffect(() => {
        if (studentEmail?.length) {
            getListISIR({emails: studentEmail})
            if (!systemData) {
                getSystemData({emails: [studentEmail]})
            }
        }
    }, [studentEmail])

    useEffect(() => {
        if (isirReport && systemData) {
            const systemDataSchoolCode: string[] = systemData.schoolCode.split(", ")
            if (systemDataSchoolCode.indexOf(isirReport.schoolCode) >= 0) {
                setSystemData({
                    ...systemData,
                    schoolCode: isirReport.schoolCode
                })
            }
        }
    }, [isirReport])

    useEffect(() => {
        compareData()
    }, [isirReport, systemData])

    const getPageTitle = () => {
        return t("financialAidStaff.isir")
    }

    const getFields = () => {
        return [
            t("financialAid:isir.type"),
            t("financialAid:isir.id"),
            t("financialAid:isir.awardYear"),
            t("financialAid:isir.schoolCode"),
            t("financialAid:isir.verification"),
            t("financialAid:isir.email"),
            t("financialAid:isir.status"),
            t("financialAid:isir.default")
        ]
    }

    const getColumns = () => {
        return [
            {
                title: t("financialAid:isir.type"),
                field: "type",
                classNameHeader: "",
                classNameBody: "",
                style: {minWidth: "80px"},
                sortable: true
            },
            {
                title: t("financialAid:isir.id"),
                field: "isirCode",
                classNameHeader: "",
                classNameBody: "",
                style: {minWidth: "140px"},
                sortable: true
            },
            {
                title: t("financialAid:isir.awardYear"),
                field: "awardYear",
                classNameHeader: "",
                classNameBody: "",
                style: {minWidth: "140px"},
                sortable: true
            },
            {
                title: t("financialAid:isir.schoolCode"),
                field: "schoolCode",
                classNameHeader: "",
                classNameBody: "",
                style: {minWidth: "140px"},
                sortable: true
            },
            {
                title: t("financialAid:isir.verification"),
                field: "verificationHtml",
                classNameHeader: "",
                classNameBody: "",
                style: {minWidth: "140px"},
                sortable: true
            },
            {
                title: t("financialAid:isir.email"),
                field: "email",
                classNameHeader: "",
                classNameBody: "",
                style: {minWidth: "200px"},
                sortable: true
            },
            {
                title: t("financialAid:isir.status"),
                field: "statusHtml",
                classNameHeader: "",
                classNameBody: "",
                style: {minWidth: "180px", paddingTop: "1em"}
            },
            {
                title: t("financialAid:isir.default"),
                field: "defaultHtml",
                classNameHeader: "",
                classNameBody: "",
                style: {minWidth: "120px", paddingTop: "1em"}
            }
        ]
    }

    const getProfileCampusData = async () => {
        try {
            const {data} = await profileCampusService.getAll({
                filter: {
                    profileId: [Number(studentId)]
                },
                linkedObjects: true
            })

            if (data.length) {
                let schoolCodes: string[] = []
                for (let i = 0; i < data.length; i++) {
                    if (data[i].schoolCodes) {
                        for (let j = 0; j < data[i].schoolCodes.length; j++) {
                            schoolCodes.push(data[i].schoolCodes[j].code)
                        }
                    }
                }

                setSchoolCodeData(schoolCodes)
            }
        } catch (e) {
            handleError(e)
        }
    }

    const getSystemData = async (filter) => {
        try {
            // fetch user data
            const {data} = await userServiceV3.getAll({
                filter,
                range: {
                    limit: 1,
                    offset: 0
                },
                linkedObjects: true
            })

            if (data.length) {
                // fetch student fin-aid package data
                let profileDetails: any = null
                for (let i = 0; i < data[0].profiles.length; i++) {
                    if (data[0].profiles[i].type === "student") {
                        profileDetails = data[0].profiles[i]

                        if (data[0].profiles[i].active === 1) {
                            profileDetails = data[0].profiles[i]
                        }
                    }
                }

                let schoolCodes: string[] = []
                if (profileDetails && profileDetails.campuses) {
                    for (let i = 0; i < profileDetails.campuses.length; i++) {
                        if (profileDetails.campuses[i].schoolCodes) {
                            for (let j = 0; j < profileDetails.campuses[i].schoolCodes.length; j++) {
                                schoolCodes.push(profileDetails.campuses[i].schoolCodes[j].code)
                            }
                        }
                    }
                }

                let emails: string[] = []
                for (let i = 0; i < data[0].emails.length; i++) {
                    emails.push(data[0].emails[i].email)
                }

                const systemDataObj = {
                    awardYear: profileDetails ? profileDetails.awardYear || "-" : "-",
                    schoolCode: schoolCodes.length ? schoolCodes.join(", ") : "-",
                    firstName: data[0].firstName || "-",
                    lastName: data[0].lastName || "-",
                    ssn: data[0].ssn || "-",
                    dob: data[0].birthDate || "-",
                    gender: data[0].gender?.toLowerCase() || "-",
                    dependencyStatus: profileDetails ? profileDetails.dependencyStatus || "-" : "-",
                    maritalStatus: (data[0].maritalStatus || "").toLowerCase() || "-",
                    fti: profileDetails ? profileDetails.fti || "-" : "-",
                    primaryEfc: profileDetails ? profileDetails.efc || "-" : "-",
                    email: emails,
                    phoneNumber: "-",
                    country: data[0].country || "-",
                    streetAddress: data[0].address || "-",
                    city: data[0].city || "-",
                    state: data[0].state || "-",
                    postalCode: data[0].postalCode || "-"
                }

                for (let i = 0; i < data[0].phones.length; i++) {
                    if (data[0].phones[i].isPrimary) {
                        systemDataObj.phoneNumber = data[0].phones[i].phone
                    }
                }

                if (data[0].isISIRConfirmed) {
                    setReportStatus("locked")
                }

                if (isirReport) {
                    if (schoolCodes.indexOf(isirReport.schoolCode) >= 0) {
                        systemDataObj.schoolCode = isirReport.schoolCode
                    }
                }

                setSystemData(systemDataObj)
                setStudentEmail(emails)
                setUserId(data[0].id)
            }
        } catch (e) {
            handleError(e)
        }
    }

    const getParams = () => {
        const params: any = {
            range: {
                page,
                pageSize
            },
            filter: {}
        }
        if (studentEmail?.length) {
            params.filter = {
                ...params.filter,
                studentEmail
            }
        }
        if (!isEmpty(orderField)) {
            params.orderBy = orderField.field
            params.orderDir = orderField.order
        }
        return params
    }

    const getListISIR = async ({isirCode, emails}: Partial<{isirCode: string; emails: string[]}>) => {
        const params = getParams()
        if ((isirCode ?? "").trim() === "" && !emails?.length && (isirCodeWithoutPrefix ?? "").trim() === "") {
            handleErrorMessage("Cannot fetch ISIR data")
            return false
        }

        if (isirCode) {
            params.filter = {
                ...params.filter,
                isirCode
            }
        } else if (isirCodeWithoutPrefix) {
            params.filter = {
                ...params.filter,
                isirCode: isirCodeWithoutPrefix
            }
        }
        if (emails?.length) {
            params.filter = {
                ...params.filter,
                studentEmail: emails
            }
        }
        if (schoolCodeData) {
            params.filter = {
                ...params.filter,
                schoolCode: schoolCodeData
            }
        }
        dispatch({isLoading: true})
        try {
            const {data: isirs, total} = await isirService.getList(params)
            let defaultIsir: any = null
            const newISIR = isirs.map((isir) => {
                let isirJSON = JSON.parse(isir.isirJson)

                let newData: any = {
                    type: t("financialAid:financialAidStaff.isir"),
                    id: isir.id,
                    isirCode: isir.isirCode,
                    awardYear: isirJSON.awardYear,
                    schoolCode: isirJSON.studentHighSchoolCode,
                    email: isir.studentEmail,
                    verificationHtml: renderVerificationHtml(isir.isDefault),
                    statusHtml: renderStatus(isirJSON.verificationFlag),
                    isDefault: isir.isDefault,
                    defaultHtml: renderDefault(isir.isDefault),
                    isirData: isir
                }

                if (isir.isDefault) {
                    defaultIsir = newData
                }

                return newData
            })

            dispatch({data: newISIR, total})
            if (defaultIsir || newISIR[0]) {
                onClickShowDetail(defaultIsir ?? newISIR[0])
            }
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const renderStatus = (status: string) => {
        const statusString: string = status ? verificationStatus[status.toLowerCase()] : ""
        return statusString ? (
            <p className={cx(styles.status, styles[`status-${statusString.toLowerCase()}`])}>{statusString}</p>
        ) : (
            ""
        )
    }

    const renderDefault = (isDefault: boolean) => {
        const className = isDefault ? styles.bgP4B : styles.bgB2
        return <p className={cx(styles.reportBox, styles.reportBoxSmall, className)}>Default</p>
    }

    const renderVerificationHtml = (isDefault: boolean) => {
        return isDefault ? (
            <p className={cx(styles.reportBox, styles.reportBoxSmall)}>
                <Icon icon="FILE_CHECK" color="#fff" className={cx(styles.reportSVG, styles.bgSc4B)} />
            </p>
        ) : (
            ""
        )
    }

    const onClickShowDetail = (data) => {
        const {isirData} = data
        const {isirJson} = isirData
        const isirJSON = JSON.parse(isirJson)

        setISIRCode(`ISIR [${data.isirCode}]`)
        setSelectedISIRCode(data.isirCode)

        const isirDependencyStatus = DEPENDENCY_STATUS_OPTIONS.filter((el) => el.id === isirJSON.dependencyStatusData)

        const newIsirReportData = {
            awardYear: isirJSON.awardYear,
            schoolCode: isirJSON.studentHighSchoolCode,
            firstName: isirJSON.studentFirstName,
            lastName: isirJSON.studentLastName,
            ssn: isirJSON.studentSSN,
            dob: moment(isirJSON.studentDateOfBirth).toISOString(),
            gender: genderReference[isirJSON.studentGender] ? genderReference[isirJSON.studentGender] : "other",
            dependencyStatus: isirDependencyStatus[0] ? isirDependencyStatus[0].name : "",
            maritalStatus: maritalStatusReference[isirJSON.studentMaritalStatus]
                ? maritalStatusReference[isirJSON.studentMaritalStatus]
                : "",
            fti: isirJSON.fti,
            primaryEfc: isirJSON.primaryEFC,
            email: isirJSON.studentEmailAddress,
            phoneNumber: isirJSON.studentPermanentHomePhone,
            streetAddress: isirJSON.address,
            city: isirJSON.city,
            state: isirJSON.state,
            postalCode: isirJSON.zip
        }

        setIsirReport(newIsirReportData)
        setIsirReportSelected(isirJSON)
        if (isirData.isDefault) {
            setIsDefaultReport(true)
        } else {
            setIsDefaultReport(false)
        }
    }

    const compareData = async () => {
        if (reportStatus === "locked") {
            return
        }
        if (!isirReport) {
            return
        }
        if (!systemData) {
            setReportStatus("not_found")
            return
        }

        let differentKeys = []
        for (let reportKey of reportKeys) {
            if (
                reportKey === "email" &&
                (systemData[reportKey] ?? [])
                    .map((el) => el.trim().toLowerCase())
                    .indexOf((isirReport[reportKey] ?? "").trim().toLowerCase()) < 0
            ) {
                differentKeys.push(reportKey)
            } else if (
                reportKey !== "email" &&
                (isirReport[reportKey] ?? "").trim().toLowerCase() !==
                    (systemData[reportKey] ?? "").trim().toLowerCase()
            ) {
                differentKeys.push(reportKey)
            }
        }

        if (differentKeys.length) {
            setReportDifferentKeys(differentKeys)
            setReportStatus("mismatch")
        } else {
            setReportDifferentKeys(null)
            setReportStatus("matched")
        }
    }

    const setDefault = async (inputData) => {
        try {
            const data = {
                isDefault: true,
                studentEmail: inputData.email
            }
            await isirService.edit(inputData.id, data)
            getListISIR({emails: studentEmail})
        } catch (e) {
            handleError(e)
        }
    }

    const createLeadRecord = async () => {
        dispatch({isLoading: true})
        try {
            // create user
            const submitData: any = getDataSubmit()
            const {
                data: [{id}]
            } = await userServiceV3.create(submitData)

            setUserId(id)

            // create user profile
            const newProfile = {
                active: false,
                userId: id,
                type: "student",
                awardYear: isirReportSelected.awardYear,
                dependencyStatus: isirReportSelected.dependencyStatusData,
                fti: isirReportSelected.fti,
                efc: isirReportSelected.primaryEFC
            }
            const resultProfile = await profileService.create([newProfile])
            const profileId: number = resultProfile.data[0].id

            // create entry for profile-campus mapping for managing school code
            if (isirReportSelected.studentHighSchoolCode) {
                // fetch campuses based on school code
                let campusIds: number[] = []
                const resultCampus = await campusesService.getAll({
                    filter: {
                        schoolCodes: [isirReportSelected.studentHighSchoolCode]
                    }
                })
                if (resultCampus.data) {
                    resultCampus.data.forEach((item) => {
                        campusIds.push(item.id)
                    })
                }

                // create profile-campus mapping entries
                if (campusIds.length) {
                    let objectProfileCampusMapping = campusIds.map((campusId) => ({
                        profileId,
                        campusId
                    }))
                    await profileCampusService.create(objectProfileCampusMapping)
                }
            }

            let studentEmailNew: string[] = studentEmail
            if (submitData.emails[0]) {
                studentEmailNew = [submitData.emails[0].email]
            }
            getSystemData({profileId: [profileId]})
            setStudentEmail(studentEmailNew)

            toastSuccess(t("common:message.createSuccess"))

            history.push({
                pathname: routing.finAid.student.isirDetail(profileId),
                search: `?tab=${FinancialAidStudentTabs.ISIR}&isirCode=${isirCodeWithoutPrefix}&profileId=${profileId}`,
                state: {
                    backUrl
                }
            })
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const getDataSubmit = () => {
        const dataSubmit = {
            emails: [
                {
                    email: isirReportSelected.studentEmailAddress,
                    isPrimary: true
                }
            ],
            phones: [
                {
                    phone: isirReportSelected.studentPermanentHomePhone,
                    isPrimary: true
                }
            ],
            firstName: isirReportSelected.studentFirstName,
            lastName: isirReportSelected.studentLastName,
            middleName: isirReportSelected.studentMiddleNameInitial,
            gender: genderReference[isirReportSelected.studentGender]
                ? genderReference[isirReportSelected.studentGender]
                : "other",
            birthDate: moment(isirReportSelected.studentDateOfBirth).toISOString(),
            ssn: isirReportSelected.studentSSN,
            maritalStatus: maritalStatusReference[isirReportSelected.studentMaritalStatus]
                ? maritalStatusReference[isirReportSelected.studentMaritalStatus]
                : "",
            address: isirReportSelected.address,
            postalCode: isirReportSelected.zip,
            city: isirReportSelected.city,
            state: isirReportSelected.state,
            highSchoolState: isirReportSelected.studentHighSchoolState
        }
        return dataSubmit
    }

    const lockUserData = async () => {
        let canUpdateUserISIRConfirmation = true
        canUpdateUserISIRConfirmation = checkPermission(
            {
                staff: [Permissions.Staff.Users.IsirConfirmed.Edit]
            },
            model
        )
        if (!canUpdateUserISIRConfirmation) {
            handleErrorMessage("You don't have access to update isir confirmation")
            return false
        }

        const object = {
            userId,
            isISIRConfirmed: true
        }
        await userServiceV3.updateISIRConfirmed(object)

        setReportStatus("locked")
    }

    const redirectISIR = async () => {
        if (!selectedISIRCode) {
            handleErrorMessage("Please select an ISIR to redirect")
        }

        if (process.env.REACT_APP_ISIR_WEB_BASE_URL as string) {
            const url = new URL(`/`, process.env.REACT_APP_ISIR_WEB_BASE_URL as string)
            url.searchParams.append("isirCode", selectedISIRCode)
            url.searchParams.append("xProfileId", model.profileId.toString())
            url.searchParams.append("token", model.authToken)
            url.searchParams.append("pdf", "")

            window.open(url.href, "_blank")
        } else {
            handleErrorMessage("Cannot open ISIR PDF. Please try again later.")
        }
    }

    const setDefaultAction = {
        title: "Default",
        icon: "EDIT",
        action: setDefault
    }

    return (
        <div>
            {["mismatch", "matched"].indexOf(reportStatus) >= 0 && (
                <div
                    className={cx(styles.infoContainer, {
                        [styles.bW4B]: reportStatus === "mismatch",
                        [styles.bS4B]: reportStatus === "matched"
                    })}>
                    <span
                        className={cx(styles.infoTitle, {
                            [styles.bgW4B]: reportStatus === "mismatch",
                            [styles.bgS4B]: reportStatus === "matched"
                        })}>
                        {true && t(`financialAid:isir.reportInfoTitle.${reportStatus}`)}
                    </span>
                    <span className={cx(styles.infoDescription)}>
                        {true && t(`financialAid:isir.reportInfoDescription.${reportStatus}`)}
                    </span>
                    {reportStatus === "mismatch" && (
                        <BaseButton
                            title={t("financialAid:isir.createActivity")}
                            uppercase
                            onClick={activityPopup.open}
                        />
                    )}
                    {reportStatus === "matched" && (
                        <BaseButton title={t("financialAid:isir.lockUserData")} uppercase onClick={lockUserData} />
                    )}
                </div>
            )}

            <table className={styles.reportTable}>
                <tbody>
                    <tr className={styles.reportTR}>
                        <td className={styles.reportTD}></td>
                        <td className={cx(styles.reportTD, styles.reportColumn)}>
                            {isDefaultReport && (
                                <span
                                    className={cx(
                                        styles.reportBoxItem,
                                        styles.reportBox,
                                        styles.reportBoxSmall,
                                        styles.bgP4B
                                    )}>
                                    Default
                                </span>
                            )}
                        </td>
                        <td className={cx(styles.reportTD, styles.reportColumn)}></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>
                            {reportStatus === "not_found" && (
                                <div
                                    className={cx(styles.noSystemDataContainer, {
                                        [styles.noSystemDataContainerUpdate]: isDefaultReport
                                    })}>
                                    <div
                                        className={cx(styles.infoContainer, styles.infoContainerNotFound, styles.bW4B)}>
                                        <span className={cx(styles.infoTitle, styles.bgW4B)}>
                                            {t(`financialAid:isir.reportInfoTitle.${reportStatus}`)}
                                        </span>
                                        <span className={cx(styles.infoDescription)}>
                                            {t(`financialAid:isir.reportInfoDescription.${reportStatus}`)}
                                        </span>
                                        <BaseButton
                                            title={t("financialAid:isir.createLeadRecord")}
                                            uppercase
                                            onClick={createLeadRecord}
                                        />
                                    </div>
                                </div>
                            )}
                        </td>
                    </tr>
                    <tr className={cx(styles.reportTR, styles.reportTRInfo, styles.bgU)}>
                        <td className={styles.reportTD}></td>
                        <td className={cx(styles.reportTD, styles.reprotInfoContainer, styles.reportColumn)}>
                            <span className={cx(styles.reportBoxItem, styles.reportBox, styles.bgB3)}>{isirCode}</span>
                            <Button
                                onClick={redirectISIR}
                                shape="circle"
                                className={styles.fileButton}
                                icon={<Icon icon="FILE_TEXT" />}
                            />
                            {isDefaultReport && (
                                <Icon icon="FILE_CHECK" color="#fff" className={cx(styles.reportSVG, styles.bgSc4B)} />
                            )}
                        </td>
                        <td className={cx(styles.reportTD, styles.reprotInfoContainer, styles.reportColumn)}>
                            <span
                                className={cx(styles.reportBoxItem, styles.reportBox, {
                                    [styles.bgP5]: ["not_found", "locked"].indexOf(reportStatus) >= 0,
                                    [styles.bgW4B]: reportStatus === "mismatch",
                                    [styles.bgS4B]: reportStatus === "matched"
                                })}>
                                SYSTEM
                            </span>
                            {reportStatus === "mismatch" && (
                                <Icon icon="ALERT" className={cx(styles.reportSVG, styles.iconFull)} />
                            )}
                            {reportStatus === "matched" && (
                                <Icon icon="CHECKED" color="#fff" className={cx(styles.reportSVG, styles.bgS1)} />
                            )}
                            {reportStatus === "locked" && (
                                <Icon icon="LOCK_CHECK" color="#fff" className={cx(styles.reportSVG, styles.bgP5)} />
                            )}
                        </td>
                    </tr>
                    {reportKeys.map((item, index) => (
                        <tr key={`${item}_${index}`} className={styles.reportTR}>
                            <td
                                className={cx(styles.reportTD, {
                                    [styles.bgW1]: reportDifferentKeys && reportDifferentKeys.indexOf(item) >= 0
                                })}>
                                <span className={cx(styles.reportCell, styles.reportCellIndex)}>
                                    {reportColumnIndex[item].toUpperCase()}
                                </span>
                            </td>
                            <td
                                className={cx(styles.reportTD, styles.reportColumn, styles.reportColumnFixed, {
                                    [styles.bgW1]: reportDifferentKeys && reportDifferentKeys.indexOf(item) >= 0
                                })}>
                                <span className={cx(styles.reportCell, styles.reportCellISIR)}>
                                    {isirReport && isirReport[item]?.trim() !== "" ? isirReport[item] : "-"}
                                </span>
                            </td>
                            <td
                                className={cx(styles.reportTD, styles.reportColumn, {
                                    [styles.reportColumnInfo]: reportStatus === "locked",
                                    [styles.reportColumnWarning]: reportStatus === "mismatch",
                                    [styles.reportColumnSuccess]: reportStatus === "matched",
                                    [styles.bgW1]: reportDifferentKeys && reportDifferentKeys.indexOf(item) >= 0
                                })}>
                                <span className={cx(styles.reportCell, styles.reportCellSystem)}>
                                    {systemData &&
                                    (item === "email"
                                        ? (systemData[item] ?? []).length
                                        : (systemData[item] ?? "").trim() !== "")
                                        ? item === "email"
                                            ? systemData[item].join(", ")
                                            : systemData[item]
                                        : "-"}
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div>
                <KlassappTableHeader
                    isShowAction={isShowTableHeaderAction}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={onChangePage}
                    onChangeRowPerPage={onChangeRowPerPage}
                />
                <KlassappTable
                    columns={columns}
                    menuActions={isHideMenuActions || reportStatus === "locked" ? [] : [setDefaultAction]}
                    data={data}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    orderField={orderField}
                    isShowCheckedColumn={false}
                    onClickRowItem={reportStatus === "locked" ? () => {} : onClickShowDetail}
                    onChangeFields={onChangeFields}
                    onUpdateRowData={onUpdateRowData}
                    onUpdateTableData={onUpdateTableData}
                    onClickSortColumn={onClickSortColumn}
                    onDraggableColumn={onDraggableColumn}
                    onChangeAllFields={onChangeAllFields}
                />
            </div>

            {!!activityPopup.isVisible && (
                <ActivityPopup
                    isShow={activityPopup.isVisible}
                    onClose={activityPopup.close}
                    departmentId={departmentId}
                />
            )}
        </div>
    )
}

export default KlassappTableHOC(ISIRReport)
