import React, {useMemo} from "react"

type PDFViewerOptions = {
    view?: string
    toolbar?: boolean
    zoom?: string
}

type PDFViewerProps = {
    fileSrc: string
    options?: PDFViewerOptions
    additionalAttributes?: any
    size?: {width?: string; height?: string}
}

const DEFAULT_TOOLBAR_OPTIONS = {
    view: "fit"
}

function Iframe(props) {
    return <div dangerouslySetInnerHTML={{__html: props.iframe ? props.iframe : ""}} />
}

const serializeToolbarOptions = (options: PDFViewerOptions): string =>
    Object.entries(options)
        .map(([key, value]) => [key, typeof value === "boolean" ? Number(!!value) : String(value)])
        .map((entry) => entry.join("="))
        .join("&")

export function PDFViewer({
    fileSrc,
    options: additionalOptions = {},
    additionalAttributes = {},
    size = {width: "100%", height: "1150px"}
}: PDFViewerProps) {
    const options: PDFViewerOptions = useMemo(
        () => ({...DEFAULT_TOOLBAR_OPTIONS, ...additionalOptions}),
        [additionalOptions]
    )

    const serializedOptions = useMemo(() => serializeToolbarOptions(options), [options])

    const newAdditionalAttributes: string = Object.keys(additionalAttributes)
        .map((key) => `${key}="${additionalAttributes[key]}"`)
        .join(" ")

    return (
        <Iframe
            iframe={`<iframe src="${fileSrc}#${serializedOptions}" style="width:${size.width || "100%"};height:${
                size.height || "1150px"
            };" ${newAdditionalAttributes}>`}
        />
    )
}
