import {useMemo, useState} from "react"
import styles from "./SaveFilterForm.module.css"
import {FilterList, SavedFilter} from "types/filter"
import {handleError, toastError, toastSuccess} from "helpers"
import {savedFilterService} from "services"
import {BaseButton, BaseInput} from "components"
import {Checkbox} from "antd"

type Props = {
    filters: FilterList
    onValidateFilters: () => boolean
    onSaved?: () => void
    onCancel?: () => void
}

export function SaveFilterForm(props: Props) {
    const {filters, onValidateFilters, onSaved, onCancel} = props
    const [isFilterSaving, setIsFilterSaving] = useState(false)
    const [filterName, setFilterName] = useState("")
    const [isPublicFilter, setIsPublicFilter] = useState(false)
    const [isInputError, setIsInputError] = useState(false)

    const hasPermissionToPublicFilter = useMemo(() => {
        return true
    }, [])

    const handleSaveFilter = async () => {
        setIsInputError(false)
        if (!filterName) {
            setIsInputError(true)
            toastError("Please input filter name")
            return
        }
        if (Object.keys(filters).length <= 0) {
            toastError("No filter selected")
            return
        }
        if (!onValidateFilters()) {
            toastError("Please check your filter before saving")
            return
        }
        try {
            setIsFilterSaving(true)
            const payload: Partial<SavedFilter> = {
                name: filterName,
                isPublic: isPublicFilter,
                content: JSON.stringify(filters)
            }
            await savedFilterService.create(payload)
            toastSuccess("Saved filter")
            onSaved?.()
        } catch (err) {
            handleError(err)
        } finally {
            setIsFilterSaving(false)
        }
    }

    return (
        <div className={styles.container}>
            <BaseInput
                placeholder="type filter name"
                className={styles.inputFilterName}
                value={filterName}
                onChange={setFilterName}
                error={isInputError}
            />
            {hasPermissionToPublicFilter && (
                <>
                    <div className={styles.divider} />
                    <div className={styles.publicWrap}>
                        <Checkbox checked={isPublicFilter} onChange={(e) => setIsPublicFilter(e.target.checked)} />
                        <span className={styles.publicLabel}>Available for all users</span>
                    </div>
                </>
            )}
            <div className={styles.divider} />
            <BaseButton title="Cancel" variant="secondary" onClick={onCancel} disabled={isFilterSaving} />
            <BaseButton title="Save Filter" onClick={handleSaveFilter} loading={isFilterSaving} />
            <div className={styles.divider} />
        </div>
    )
}
