import React from "react"
import classNames from "classnames"
import {useTranslation} from "react-i18next"
import {AddCircleButton} from "./AddCircleButton"
import styles from "./AddItemCircleButton.module.css"

export type AddItemCircleButtonProps = {
    title: string
    className?: string
    onClick?: any
    disabled?: boolean
}

export function AddItemCircleButton(props: AddItemCircleButtonProps) {
    const {title, className, onClick, disabled, ...rest} = props
    const {t} = useTranslation(["common"])

    return (
        <div className={classNames(styles.buttonWrap, className)}>
            <span
                className={classNames(styles.addItemText, {[styles.disabled]: !!disabled})}
                onClick={disabled ? undefined : onClick}>
                {title || t("action.addItem")}
            </span>
            <AddCircleButton className={styles.addCircleBtn} onClick={onClick} disabled={disabled} {...rest} />
        </div>
    )
}

AddItemCircleButton.defaultProps = {
    title: "Add Item"
}
