/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react"
import classNames from "classnames"
import {Icon} from "components/Icon"
import styles from "./RejectedTasks.module.css"
import {handleError} from "helpers"
import {taskService} from "services"
import moment from "moment"
import {useModel} from "hooks"

type RejectedSubTask = {
    id: number
    code: string
    rejectedAt: string
    reason: string
    rejectedBy: number
    rejectedByUser: string
}

export function RejectedTasks(props) {
    const model = useModel()
    const [isCollapseBody, setIsCollapseBody] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [rejectedSubTasks, setRejectedSubTasks] = useState<RejectedSubTask[]>([])
    const {taskData} = props

    useEffect(() => {
        getRejectedSubtasks()
    }, [])

    const getRejectedSubtasks = async () => {
        try {
            setIsLoading(true)
            const {data} = await taskService.getRejectedSubtasks({taskId: +taskData.id})
            setRejectedSubTasks(data)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const onToggleShowBody = () => {
        setIsCollapseBody(!isCollapseBody)
    }

    if (isLoading || !rejectedSubTasks.length) {
        return null
    }

    const userDateTimeFormat = model.getUserDateTimeFormat()

    return (
        <div className={styles.wrap}>
            <div className={styles.header}>
                <div className={styles.header__icon}>
                    <Icon icon="CROSS_CIRCLE_FILL" color="#fff" />
                </div>
                <p className={styles.title}>{rejectedSubTasks.length} tasks have been rejected</p>
                <div className={styles.collapse} onClick={onToggleShowBody}>
                    <Icon icon={!isCollapseBody ? "ARROW_DOWN" : "ARROW_RIGHT"} color="#fff" />
                </div>
            </div>
            <div
                className={classNames(styles.body, {
                    [styles.body__show]: !isCollapseBody
                })}>
                {rejectedSubTasks.map((subtask) => (
                    <div className={styles.item} key={subtask.id}>
                        <p className={styles.item__title}>
                            Task <span className={styles.item__titleHighlight}>{subtask.code}</span> has been rejected
                            by
                            <span> {subtask.rejectedByUser}</span>{" "}
                            {subtask.rejectedAt && (
                                <span> at {moment(subtask.rejectedAt).format(userDateTimeFormat)}</span>
                            )}
                        </p>
                        <p className={styles.description}>{subtask.reason}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}
