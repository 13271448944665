export const cancelledData = [
    {
        id: "1",
        major: "Mathematics",
        courseId: "MT120-A",
        courseName: "Algorythmics",
        lessonDate: "22/02/2021",
        time: "14:45-16:00",
        campus: "New York",
        description: "School closure"
    },
    {
        id: "2",
        major: "Mathematics",
        courseId: "MT120-A",
        courseName: "Algorythmics",
        lessonDate: "22/02/2021",
        time: "14:45-16:00",
        campus: "New York",
        description: "School closure"
    },
    {
        id: "3",
        major: "Mathematics",
        courseId: "MT120-A",
        courseName: "Algorythmics",
        lessonDate: "22/02/2021",
        time: "14:45-16:00",
        campus: "New York",
        description: "School closure"
    }
]
