import React from "react"
import {Tabs} from "antd"
import {useTranslation} from "react-i18next"
import {useActiveTab} from "hooks"
import {Screen} from "components/ui"
import {CareerServicesReadinessChecklist, CheckList} from "sections/Settings"
import {GeneralSettings} from "./parts/GeneralSettings"
import {BaseDepartmentId} from "types/departments"
import {Model} from "Model"
import GradingSystem from "./parts/GradingSystem/GradingSystem"
import AICourseScheduling from "./parts/AICourseScheduling/AICourseScheduling"
import Retention from "./parts/Retention/Retention"
import {TabHeader} from "components/Tab"

const {TabPane} = Tabs

export enum AcademicsTab {
    General = "general",
    Checklist = "checklist",
    AICourseScheduling = "ai-course-scheduling",
    GradingSystem = "grading-system",
    Retention = "retention"
}

type Props = {
    departmentId: BaseDepartmentId
    model: Model
}

export default function Academics(props: Props) {
    const {t} = useTranslation("academics")
    const [activeTabKey, onChangeTab] = useActiveTab(AcademicsTab.General)
    const {model, departmentId} = props

    const getTitle = () => {
        switch (departmentId) {
            case BaseDepartmentId.StudentServices:
                return t("settings.studentServices")
            case BaseDepartmentId.Academics:
                return t("settings.title")
            case BaseDepartmentId.StudentAccount:
                return t("settings.studentAccount")
            case BaseDepartmentId.CareerServices:
                return t("settings.careerServices")
            default:
                return ""
        }
    }

    const getTabTitle = () => {
        switch (departmentId) {
            case BaseDepartmentId.StudentServices:
                return t("settings.studentServicesCheckList")
            case BaseDepartmentId.Academics:
                return t("settings.academicCheckList")
            case BaseDepartmentId.StudentAccount:
                return t("settings.studentAccountCheckList")
            case BaseDepartmentId.CareerServices:
                return t("settings.careerServicesCheckList")
            default:
                return ""
        }
    }

    const title = getTitle()

    return (
        <Screen htmlTitle={title} header={{title}}>
            <Tabs className="fullwidth klassTab" activeKey={activeTabKey} onChange={onChangeTab}>
                <TabPane
                    tab={<TabHeader title={t("settings.generalSettings")} icon="DOOR_OPEN" />}
                    key={AcademicsTab.General}>
                    <GeneralSettings model={model} departmentId={departmentId} />
                </TabPane>
                <TabPane tab={<TabHeader title={getTabTitle()} icon="UNORDER_LIST" />} key={AcademicsTab.Checklist}>
                    {departmentId === BaseDepartmentId.CareerServices ? (
                        <CareerServicesReadinessChecklist />
                    ) : (
                        <CheckList {...props} departmentId={departmentId} model={model} />
                    )}
                </TabPane>
                <TabPane
                    tab={<TabHeader title={t("settings.aiCourseScheduling.title")} icon="RESCHEDULE" />}
                    key={AcademicsTab.AICourseScheduling}>
                    <AICourseScheduling {...props} model={model} departmentId={departmentId} />
                </TabPane>
                {departmentId === BaseDepartmentId.Academics ? (
                    <>
                        <TabPane
                            tab={<TabHeader title={t("settings.gradingSystem")} icon="GRADE" />}
                            key={AcademicsTab.GradingSystem}>
                            <GradingSystem {...props} model={model} departmentId={departmentId} />
                        </TabPane>
                        <TabPane
                            tab={<TabHeader title={t("settings.retention")} icon="UNORDER_LIST" />}
                            key={AcademicsTab.Retention}>
                            <Retention {...props} model={model} departmentId={departmentId} />
                        </TabPane>
                    </>
                ) : null}
            </Tabs>
        </Screen>
    )
}
