import React, {useMemo} from "react"
import {get} from "lodash"
import {getFullName} from "helpers"
import {Checkbox} from "antd"
import {BaseDatePicker} from "components/DateTimePicker"
import {Icon} from "components/Icon"
import styles from "./StudentInfo.module.css"
import {AcademicPlans} from "types/academicPlans"

type Props = {
    student: any
    checkedStudents: AcademicPlans.AddStudentInfo[]
    index: number
    updateStudentRow: (studentId: number, key: string, value: any) => void
}

export function StudentInfo(props: Props) {
    const {student, checkedStudents, index, updateStudentRow} = props

    const isChecked = useMemo(
        () => checkedStudents.some((item) => item.profileId === student.profileId),
        [student, checkedStudents]
    )

    const isRegisterCheck = useMemo(() => {
        const data = checkedStudents.find((item) => item.profileId === student.profileId)
        if (data) {
            return get(data, "isRegisterCheck")
        }
        return false
    }, [student, checkedStudents])

    const registeredDate = useMemo(() => {
        const data = checkedStudents.find((item) => item.profileId === student.profileId)
        if (data) {
            return get(data, "registeredDate")
        }
        return null
    }, [student, checkedStudents])

    const renderStudentInfo = () => {
        let studentPhoto = "/image/DefaultAvatar.png"
        if (student?.photo) {
            studentPhoto = JSON.parse(student.photo)["64"]
        }
        return (
            <div className={styles.studentInfo}>
                <img src={studentPhoto} className={styles.studentItem__img} alt="" />
                <span className={styles.studentItem__info}>
                    {student.customProfileId} {getFullName(student)}
                </span>
            </div>
        )
    }

    const renderRegisterCheckbox = () => {
        return (
            <div className={styles.registerWrap}>
                <div onClick={() => updateStudentRow(student.profileId, "isRegisterCheck", !isRegisterCheck)}>
                    <Icon
                        icon="CHECKED"
                        className={styles.checkedIcon}
                        color={isRegisterCheck ? "#18a957" : "#e5e5e5"}
                    />
                </div>
                {isRegisterCheck && (
                    <BaseDatePicker
                        value={registeredDate}
                        onChange={(value) => updateStudentRow(student.profileId, "registeredDate", value)}
                    />
                )}
            </div>
        )
    }

    return (
        <div className={styles.studentItem} style={{background: index % 2 !== 0 ? "#fbfbfb" : "white"}}>
            <div className={styles.checkboxCol}>
                <Checkbox
                    checked={isChecked}
                    onChange={(event) => {
                        updateStudentRow(student.profileId, "isChecked", event.target.checked)
                    }}
                />
            </div>
            {renderStudentInfo()}
            {renderRegisterCheckbox()}
        </div>
    )
}
