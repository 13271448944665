import {get} from "lodash"
import {DepartmentSubunit} from "types/departments"
import BaseService from "./Base"

class DepartmentSubunitService extends BaseService {
    getSubunits = async (params = {}): Promise<{total: number; data: DepartmentSubunit[]}> => {
        const response = await this.post("/v1/department-subunits/list", params)
        return get(response, "data")
    }

    getSubunit = async (subunitId: number, params = {}): Promise<DepartmentSubunit> => {
        const response = await this.get(`/v1/department-subunits/${subunitId}`, params)
        return get(response, "data.data")
    }

    createSubunit = async (data: Partial<DepartmentSubunit>): Promise<DepartmentSubunit> => {
        const response = await this.post(`/v1/department-subunits/create`, data)
        return get(response, "data.data")
    }

    updateSubunit = async (data: Partial<DepartmentSubunit>): Promise<DepartmentSubunit> => {
        const response = await this.post(`/v1/department-subunits/edit`, data)
        return get(response, "data.data")
    }

    deleteSubunits = async (ids: number[]): Promise<void> => {
        const response = await this.post(`/v1/department-subunits/delete`, {ids})
        return get(response, "data")
    }
}

export default DepartmentSubunitService
