import React, {useEffect, useState} from "react"
import cx from "classnames"
import {Icon} from "components/Icon"
import {Major} from "types/major"
import {Calendar} from "../"
import styles from "./TermItem.module.css"

type Props = {
    term: Major.TermSequence
    termDetail: any
    workSchedule: any
    index: number
    isInAcademicPlan: boolean
}

export function TermCalendarItem(props: Props) {
    const [isShowBody, setIsShowBody] = useState(true)
    const {term, termDetail, isInAcademicPlan, workSchedule, index} = props

    const onToggleShowBody = () => {
        setIsShowBody(!isShowBody)
    }

    const renderLeftHeader = () => {
        const title = term.scheduledTermId && !isInAcademicPlan ? "Term Start Cohort" : "Term"
        return (
            <div className={styles.leftHeader}>
                <div className={styles.termHeader}>
                    <span className={styles.termTitle}>{title}</span>
                    <span className={styles.termInfo}>
                        {term.scheduledTermId || isInAcademicPlan ? term.name : index + 1}
                    </span>
                </div>
                <div className={cx(styles.iconWrap, {[styles.iconCollapse]: isShowBody})} onClick={onToggleShowBody}>
                    <Icon icon="CHEVRON_DOWN" color="#666" />
                </div>
            </div>
        )
    }

    return (
        <div key={term.id} className={styles.termItemWrap}>
            <div className={styles.header}>{renderLeftHeader()}</div>
            {isShowBody && <Calendar term={term} termDetail={termDetail} workSchedule={workSchedule} />}
        </div>
    )
}
