import mitt, {Handler} from "mitt"

const emitter = mitt()

export function emitEvent(event: string, data: any): void {
    emitter.emit(event, data)
}

export function onEvent(event: string, handler: Handler<any>): void {
    emitter.on(event, handler)
}

export function offEvent(event: string, handler: Handler<any>): void {
    emitter.off(event, handler)
}
