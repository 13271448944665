/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback} from "react"
import {Tabs} from "antd"
import {useActiveTab, useModel} from "hooks"
import {TabHeader} from "components/Tab"
import {AcademicAffairsStudentList, Analytics} from "./parts"
import {FilterKey} from "types/filter"
import {BaseDepartmentId} from "types/departments"

const {TabPane} = Tabs

const ACADEMIC_AFFAIRS_WORKSPACE_TABS = {
    ANALYTICS: "analytics",
    DETAILS: "details"
}

type Props = {
    departmentId: BaseDepartmentId
}

export function Workspace({departmentId}: Props) {
    const [activeTab, onChangeTab] = useActiveTab(ACADEMIC_AFFAIRS_WORKSPACE_TABS.ANALYTICS, "type")
    const model = useModel()

    const onSwitchTabPane = (activeKey?: string) => {
        if (activeKey === ACADEMIC_AFFAIRS_WORKSPACE_TABS.ANALYTICS) {
            // Remove temporary filter when switching analytic tab
            const filterKey = FilterKey.AcademicsPage_Students
            const oldFilter = model.getStorageFilter(filterKey)
            if (oldFilter && oldFilter.filters) {
                let {filters} = oldFilter
                delete filters.retentionLevels
                delete filters.sapCheckpoints
                model.updateStorageFilter(filterKey, {
                    filters
                })
            }
        }
        onChangeTab(activeKey)
    }

    const goToInternalStudentTab = useCallback(() => {
        onChangeTab(ACADEMIC_AFFAIRS_WORKSPACE_TABS.DETAILS)
    }, [])

    return (
        <Tabs className="fullwidth klassSubTab" activeKey={activeTab} onChange={onSwitchTabPane}>
            <TabPane key={ACADEMIC_AFFAIRS_WORKSPACE_TABS.ANALYTICS} tab={<TabHeader title="Analytics" />}>
                {activeTab === ACADEMIC_AFFAIRS_WORKSPACE_TABS.ANALYTICS && (
                    <Analytics goToStudentTab={goToInternalStudentTab} departmentId={departmentId} />
                )}
            </TabPane>
            <TabPane key={ACADEMIC_AFFAIRS_WORKSPACE_TABS.DETAILS} tab={<TabHeader title="Students List" />}>
                {activeTab === ACADEMIC_AFFAIRS_WORKSPACE_TABS.DETAILS && <AcademicAffairsStudentList />}
            </TabPane>
        </Tabs>
    )
}
