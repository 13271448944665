import styles from "./JobItem.module.css"
import {displaySince, handleError} from "helpers"
import {useEffect, useState} from "react"
import cx from "classnames"
import {ReactComponent as LikeIcon} from "./assets/like.svg"
import {ReactComponent as FilledLikeIcon} from "./assets/like-filled.svg"
import {ReactComponent as DislikeIcon} from "./assets/dislike.svg"
import {ReactComponent as FilledDislikeIcon} from "./assets/dislike-filled.svg"
import {CareerServices} from "types/careerServices"
import {careerService} from "services"
import {useModel} from "hooks"

const EMPLOYMENT_TYPE = {
    "full-time": "Full time",
    "part-time": "Part time",
    contract: "Contract"
}

const SOURCE_TYPE = {
    system: "Career Services",
    employer: "Employer offer",
    external: "External site offer"
}

type Props = {
    job: CareerServices.Job
    onClick?: (item: CareerServices.Job) => void
    allowNavigate: boolean
}

export function JobItem(props: Props) {
    const {job, onClick, allowNavigate} = props
    const [isHovering, setIsHovering] = useState(false)
    const [reactionStat, setReactionStat] = useState<CareerServices.ReactionStat>()
    const model = useModel()
    const isStudent = model.isStudent()

    useEffect(() => {
        setReactionStat({
            totalLikes: job.totalLikes,
            totalDislikes: job.totalDislikes,
            isLiked: job.isLiked,
            isDisliked: job.isDisliked
        })
    }, [job])

    const handleMouseOver = () => {
        setIsHovering(true)
    }

    const handleMouseOut = () => {
        setIsHovering(false)
    }

    const handleReaction = async (e, type: CareerServices.ReactionType) => {
        try {
            e.preventDefault()
            e.stopPropagation()
            if (
                (type === CareerServices.ReactionType.Like && reactionStat.isLiked) ||
                (type === CareerServices.ReactionType.Dislike && reactionStat.isDisliked)
            )
                return
            const payload = {
                jobId: job.id,
                reactionType: type
            }
            const _reactionStat = await careerService.reactJob(payload)
            if (_reactionStat) {
                setReactionStat(_reactionStat)
            }
        } catch (error) {
            handleError(error)
        }
    }

    return (
        <div
            className={cx(styles.jobItem, {[styles.jobItemHover]: isHovering && allowNavigate})}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={() => onClick(job)}>
            <div className={styles.jobLogo}>
                {job.employer.logo && <img src={JSON.parse(job.employer.logo)["32"]} alt="Company logo" />}
            </div>
            <div className={styles.jobDescription}>
                <div className={styles.jobTitle}>{job.title}</div>
                <div className={styles.jobCompany}>{job.employer.companyName}</div>
                <div className={styles.tagWrap}>
                    {job.employmentType && (
                        <div className={cx(styles.tagItem, styles.jobTag)}>{EMPLOYMENT_TYPE[job.employmentType]}</div>
                    )}
                    {job.salary && <div className={cx(styles.tagItem, styles.jobTag)}>{job.salary}</div>}
                    {job.categories?.map((item) => {
                        return (
                            <div key={item.id} className={cx(styles.tagItem, styles.categoryItem)}>
                                {item.name}
                            </div>
                        )
                    })}
                </div>
                <div className={styles.jobLocation}>
                    <div className={styles.locationIcon}></div>
                    <div className={styles.location}>{job.location}</div>
                </div>
            </div>
            <div className={styles.jobInfo}>
                <div className={styles.sourceAndReactionWrap}>
                    <div className={cx(styles.tagItem, styles.jobTag)}>{SOURCE_TYPE[job.sourceType]}</div>
                    {reactionStat && isStudent && (
                        <>
                            <div
                                className={styles.reactionContainer}
                                onClick={(e) => handleReaction(e, CareerServices.ReactionType.Like)}>
                                {reactionStat.isLiked ? (
                                    <FilledLikeIcon width={20} height={20} />
                                ) : (
                                    <LikeIcon width={20} height={20} />
                                )}
                                <div className={styles.count}>({reactionStat.totalLikes})</div>
                            </div>
                            <div
                                className={styles.reactionContainer}
                                onClick={(e) => handleReaction(e, CareerServices.ReactionType.Dislike)}>
                                {reactionStat.isDisliked ? (
                                    <FilledDislikeIcon width={20} height={20} />
                                ) : (
                                    <DislikeIcon width={20} height={20} />
                                )}
                                <div className={styles.count}>({reactionStat.totalDislikes})</div>
                            </div>
                        </>
                    )}
                </div>
                <div className={styles.jobCreated}>{displaySince(job.createdAt, "MM/DD/YYYY hh:mm")}</div>
            </div>
        </div>
    )
}
