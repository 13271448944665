/* eslint-disable react-hooks/exhaustive-deps */
import debounce from "debounce-promise"
import React from "react"
import {useTranslation} from "react-i18next"
import {campusesService} from "services"
import {KlassDropAsyncPaginate, KlassDropdownAsyncProps} from "../Select"
import {formatCodeName} from "helpers"

type Props = Partial<KlassDropdownAsyncProps> & {}

const CampusSelect: React.FC<Props> = ({...props}) => {
    const {t} = useTranslation(["common"])

    const search = React.useCallback(
        debounce(async (search: string = "", loadedOptions) => {
            let params = {
                fields: ["id", "name", "code"],
                filter: {
                    search,
                    ...(!!props.filterByCampusIds?.length && {id: props.filterByCampusIds})
                },
                range: {
                    limit: 20,
                    offset: loadedOptions.length
                }
            }
            const {data, total} = await campusesService.getAll(params)
            return {
                options: data,
                hasMore: loadedOptions.length < total
            }
        }, 500),
        []
    )

    return (
        <KlassDropAsyncPaginate
            {...props}
            placeholder={props.placeholder || t("selectField.placeholder")}
            loadOptions={search}
            valueKey="id"
            labelKey="name"
            getOptionLabel={formatCodeName}
        />
    )
}

export default CampusSelect
