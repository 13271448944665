import jsPDF from "jspdf"
import {
    fontBold,
    fontRegular
} from "sections/FinancialAid/FinancialAidStudent/parts/CollegeFinancingPlan/CFPStepDetail/font"
import {convertSvgElementToDataUrl} from "./svg"

export const generatePdfFromHtml = async (element: HTMLElement): Promise<jsPDF> => {
    return new Promise(async (resolve, reject) => {
        const pdf = new jsPDF({
            orientation: "portrait",
            format: "letter"
        })

        pdf.addFileToVFS("Poppins-regular.ttf", fontRegular)
        pdf.addFont("Poppins-regular.ttf", "Poppins", "normal", "normal")
        pdf.addFileToVFS("Poppins-bold.ttf", fontBold)
        pdf.addFont("Poppins-bold.ttf", "Poppins", "normal", "bold")

        pdf.setFont("Poppins")

        const svgElements = element.querySelectorAll("svg")
        for (const svgElement of svgElements) {
            try {
                const parentElement = svgElement.parentElement
                if (parentElement) {
                    const parentWidth = parentElement.clientWidth
                    const parentHeight = parentElement.clientHeight

                    const pngDataUrl = await convertSvgElementToDataUrl(svgElement)
                    const img = new Image()

                    img.style.width = `${parentWidth}px`
                    img.style.height = `${parentHeight}px`

                    //@ts-ignore
                    img.src = pngDataUrl

                    svgElement.parentNode.replaceChild(img, svgElement)
                } else {
                    console.error("Parent element not found for SVG element.")
                }
            } catch (error) {
                console.error("Error converting SVG to PNG:", error)

                reject(reject)
            }
        }

        await pdf.html(element, {
            image: {
                type: "jpeg",
                quality: 100
            },
            html2canvas: {
                scale: 0.3,
                allowTaint: true,
                letterRendering: true,
                svgRendering: true
            },
            margin: [10, 20, 0, 40]
        })
        resolve(pdf)
    })
}
