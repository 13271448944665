import {get} from "lodash"
import BaseService from "./Base"
import {Import} from "types/import-users"

class ImportService extends BaseService {
    async list(params): Promise<{total: number; data: Import.Metadata[]}> {
        const response = await this.post("/v1/data-import/list", params)
        return get(response, "data", [])
    }

    async listSchemas(params): Promise<{total: number; data: any}> {
        const response = await this.post("/v1/data-import/list-schemas", params)
        return get(response, "data", [])
    }

    async handleImportCreated(params): Promise<{importedRows: number; failedRows: number}> {
        const response = await this.post("/v1/data-import/create", params)
        return get(response, "data.data", {})
    }

    async retryImportProcessing(id: number): Promise<void> {
        await this.post("/v1/data-import/retry", {id})
    }

    async getDetails(params): Promise<Import.Metadata> {
        const response = await this.post("/v1/data-import/get", params)
        return get(response, "data.data", {})
    }
}

export default ImportService
