import {useCallback} from "react"
import {useQuery, useQueryClient} from "@tanstack/react-query"
import {phasesService} from "services"
import {UNLIMITED_PAGE_SIZE} from "data/constants"

const queryCacheKey = "all-phases"
const INITIAL_DATA = []

export const useAllPhases = () => {
    const queryClient = useQueryClient()

    const query = useQuery(
        [queryCacheKey],
        async () => {
            const {data: phases} = await phasesService.getAll({
                filter: {isActive: true},
                sort: {orderBy: "name", orderDir: "asc"},
                range: {
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE
                }
            })
            return phases.map((item) => ({...item, id: item.phaseId}))
        },
        {
            refetchOnMount: false
        }
    )

    return {
        phases: query.data ?? INITIAL_DATA,
        invalidatePhasesCache: useCallback(() => {
            queryClient.invalidateQueries({queryKey: [queryCacheKey]})
        }, [queryClient])
    }
}
