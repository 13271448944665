import React, {createContext} from "react"

export const ActiveJobContext = createContext<any>({})

type Props = {
    children: React.ReactNode
    value: any
}

export const ActiveJobProvider: any = ({children, value}: Props) => {
    return <ActiveJobContext.Provider value={value}>{children}</ActiveJobContext.Provider>
}
