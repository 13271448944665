/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react"
import {useTranslation} from "react-i18next"
import classNames from "classnames"
import {isEmpty} from "lodash"
import {Icon} from "components/Icon"
import {SecondaryButton} from "components/buttons"
import {BaseFilterContext} from "./BaseFilterContext"
import styles from "./BaseFilter.module.css"

export type BaseFilterProps = {
    onClick?: any
    className?: string
    filter?: any
    children?: JSX.Element
    isShowFilterClear?: boolean
    onClickClear?: any
}

export function BaseFilter(props: BaseFilterProps) {
    const {children, onClick, className, filter = {}, isShowFilterClear, onClickClear} = props
    const {t} = useTranslation(["common"])
    const [isShowFilter, setIsShowFilter] = React.useState(false)
    const [count, setCount] = React.useState(0)

    useEffect(() => {
        getCount()
    }, [filter])

    const getCount = () => {
        let result = 0
        Object.values(filter).forEach((value) => {
            if ((typeof value === "boolean" && value) || !isEmpty(value)) {
                result += 1
            }
        })
        setCount(result)
    }

    const onClickFilter = () => {
        if (isShowFilter && onClick) {
            onClick()
        }
        setIsShowFilter(!isShowFilter)
    }

    const onClickClearFilter = () => {
        if (isShowFilter && onClickClear) {
            onClickClear()
        }
    }

    const onClickClose = () => {
        setIsShowFilter(false)
    }

    return (
        <BaseFilterContext.Provider value={{isShowFilter, setIsShowFilter}}>
            <div className={classNames(styles.wrap, className)}>
                {isShowFilter && (
                    <div className={styles.filterBody}>
                        <div className={styles.iconWrap}>
                            <div className={styles.closeIconWrap} onClick={onClickClose}>
                                <Icon icon="CLOSE" className={styles.closeIcon} />
                            </div>
                        </div>
                        {children}
                    </div>
                )}
                <div className={classNames(styles.filterBtnWrap, {[styles.filterBtnWrap__apply]: isShowFilter})}>
                    <div className={styles.line}></div>
                    {isShowFilter && isShowFilterClear && (
                        <SecondaryButton
                            title="Clear"
                            className={`${styles.filterBtn} ${styles.filterBtnClear}`}
                            onClick={onClickClearFilter}
                        />
                    )}
                    <SecondaryButton
                        title={
                            isShowFilter
                                ? t("common:action.apply")
                                : `${t("common:action.filter")} ${!!count ? `(${count})` : ""}`
                        }
                        className={styles.filterBtn}
                        onClick={onClickFilter}
                    />
                </div>
            </div>
        </BaseFilterContext.Provider>
    )
}
