import {Link} from "react-router-dom"
import Linkify from "react-linkify"

const linkDecorator = (decoratedHref, decoratedText, key) => {
    const origin = window.location.origin
    if (decoratedText.startsWith(origin)) {
        let newValue = decoratedText
        if (decoratedText.includes("/tasks/detail?")) {
            newValue = "Click here to view the task details"
        }
        if (decoratedText.includes("/tasks/submit?")) {
            newValue = "Click here to open the task"
        }
        return (
            <Link
                key={key}
                to={decoratedHref.replace(origin, "")}
                onClick={(e) => {
                    e.stopPropagation()
                }}>
                {newValue}
            </Link>
        )
    }
    return (
        <a
            key={key}
            href={decoratedHref}
            target={"_blank"}
            rel="noopener noreferrer"
            onClick={(e) => {
                e.stopPropagation()
            }}>
            {decoratedText}
        </a>
    )
}

export const LinkifyContent = ({content}: {content: string}) => {
    return <Linkify componentDecorator={linkDecorator}>{content}</Linkify>
}
