import React from "react"
import {withTranslation} from "react-i18next"
import {keyBy, get, isEmpty, map, size} from "lodash"
import debounce from "debounce-promise"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {ParticipantType, SelectStaffFrom, UserType} from "types/tasks"
import {getFullName, handleError} from "helpers"
import {KlassappTableDropdownAsyncPaginate} from "uiKit"
import {KlassappTableProps} from "types/common"
import {userServiceV3} from "services"
import {BaseButton} from "components/buttons"
import {Auth} from "types/auth"
import styles from "./StaffSigner.module.css"
import {Checkbox, Col, Row} from "antd"
import {getFirstProfile} from "helpers/task"
import {MAP_ADVISOR_ROLE_MODULE_FILTER} from "sections/users/Detail/parts/Directory/parts"

type PageProps = {
    t: Function
    history?: any
    signer: any
    primarySigner: any
    forceDeleteKey: number
    resetNonPrimarySignerId: string
    onUpdateSignerItem: Function
    onClickEditSigner: (signerData: any) => void
    onDeleteMultiUserSigner: (signer: any, userIds?: number[]) => void
    isWorkflowAction?: boolean
    workflowTargetType?: Auth.UserProfileType
}

type Props = KlassappTableProps & PageProps

type State = {
    selectedStaff: any
}

class StaffSigner extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            selectedStaff: {}
        }
        this.onSearchChange = debounce(this.onSearchChange.bind(this), 300)
    }

    componentDidMount() {
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getData},
            {key: "getFields", func: this.getFields},
            {key: "getColumns", func: this.getColumns},
            {key: "onClickRowItem", func: this.onClickRowItem},
            {key: "onClickDeleteMulti", func: this.onClickDeleteMulti},
            {key: "getTableHeaderActions", func: this.getTableHeaderActions}
        ])
        this.props.dispatch({isLoading: true})
        this.getData()
    }

    componentDidUpdate(prevProps) {
        if (
            (prevProps.forceDeleteKey !== this.props.forceDeleteKey &&
                this.props.signer.participantType !== ParticipantType.PrimarySigner) ||
            (prevProps.resetNonPrimarySignerId !== this.props.resetNonPrimarySignerId &&
                this.props.signer.id === this.props.resetNonPrimarySignerId)
        ) {
            this.props.dispatch({data: [], total: 0})
        } else if (prevProps.signer.staffs !== this.props.signer.staffs) {
            this.getData()
        }
    }

    getPageTitle = () => {
        return "Staff"
    }

    getFields = () => {
        const {signer, primarySigner} = this.props
        if (
            signer.participantType !== ParticipantType.PrimarySigner &&
            primarySigner &&
            signer.selectedType === SelectStaffFrom.ROLE
        ) {
            return ["Primary", "Staff", "Role", "Title", "Campus"]
        }
        return ["Name", "ID", "Title", "Campus"]
    }

    getTableHeaderActions = () => {
        const {t, onClickShowConfirmModal} = this.props
        return [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            }
        ]
    }

    onClickDeleteMulti = () => {
        const {signer, data, onDeleteMultiUserSigner} = this.props
        if (signer.participantType === ParticipantType.PrimarySigner) {
            const checkedUserIds = data.filter((user) => user.isChecked).map((user) => user.id)
            const newSigner = {...signer}
            newSigner.staffs = signer.staffs.filter((item) => !checkedUserIds.includes(item.id))
            onDeleteMultiUserSigner(newSigner, checkedUserIds)
        } else {
            const newSigner = {...signer}
            newSigner.staffs = []
            onDeleteMultiUserSigner(newSigner)
        }
    }

    getKeyFromUserType = (userType) => {
        switch (userType) {
            case UserType.STUDENT:
                return "students"
            case UserType.STAFF:
                return "staffs"
            case UserType.OTHERS:
                return "others"
            default:
                return "students"
        }
    }

    getData = async () => {
        const {dispatch, signer, primarySigner} = this.props
        dispatch({isLoading: true})
        try {
            if (
                signer.participantType !== ParticipantType.PrimarySigner &&
                primarySigner &&
                signer.selectedType === SelectStaffFrom.ROLE
            ) {
                const signerStaffs = map(signer.staffs, (staff) => ({...staff, userId: staff.profile?.userId}))
                const key = this.getKeyFromUserType(primarySigner.userType)
                const newData = map(primarySigner[key], (user: any) => {
                    user[`${signer.id}_primaryHtml`] = this.renderPrimaryHtml(user)
                    user[`${signer.id}_staffHtml`] = this.renderStaffHtml(user, signerStaffs)
                    user[`${signer.id}_roleHtml`] = this.renderRoleHtml()
                    user[`${signer.id}_title`] = this.renderTitle(user, signerStaffs)
                    user[`${signer.id}_campus`] = this.renderStaffCampuses(user, signerStaffs)
                    return user
                })
                dispatch({data: newData, total: size(primarySigner[key])})
            } else {
                const newData = map(signer.staffs, (item: any) => {
                    item.nameHtml = getFullName(item)
                    item.staffId = item.id
                    item.title = get(item.profiles, "[0].title", "")
                    item.campus = get(item.profiles, "[0].campus", "")
                    return item
                })
                dispatch({data: newData, total: size(signer.staffs)})
            }
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    renderPrimaryHtml = (user) => {
        const avatarSrc = user.photo?.original
        return (
            <div className={styles.nameWrap}>
                {avatarSrc && <img src={avatarSrc} className={styles.avatar} alt="" />}
                <p className={styles.name}>
                    {getFullName(user)} {user.id}
                </p>
            </div>
        )
    }

    renderTitle = (user, signerStaffs) => {
        const {selectedStaff} = this.state
        const profileId = get(user, "profiles[0].id", "")
        const staffsKeyByProfileId = keyBy(signerStaffs, "profileId")
        const contactProfile = get(staffsKeyByProfileId[profileId], "contactProfile", null)
        if (!contactProfile) {
            return get(selectedStaff[user.id], "profiles[0].title", "")
        }
        const signerStaffsKeyByUserId = keyBy(signerStaffs, "userId")
        return get(signerStaffsKeyByUserId, [user.id, "contactProfile", "title"], "")
    }

    renderStaffCampuses = (user, signerStaffs) => {
        const {selectedStaff} = this.state
        const profileId = get(user, "profiles[0].id", "")
        const staffsKeyByProfileId = keyBy(signerStaffs, "profileId")
        const contactProfile = get(staffsKeyByProfileId[profileId], "contactProfile", null)
        if (!contactProfile) {
            return get(selectedStaff[user.id], "profiles[0].campus", "")
        }
        const signerStaffsKeyByUserId = keyBy(signerStaffs, "userId")
        const campuses = get(signerStaffsKeyByUserId, [user.id, "contactProfile", "campuses"], null) || []
        return campuses.map((role) => role.name).join(", ")
    }

    getStaffs = async (search = "", loadedOptions) => {
        try {
            const studentStaffContactRoleId = this.props.signer?.studentStaffContactRole?.studentStaffContactRoleId
            const {data: staffs, total} = await userServiceV3.getAll({
                filter: {
                    type: [Auth.UserProfileType.Staff],
                    search,
                    isAccessDisabled: false,
                    notState: [Auth.UserProfileState.ExEmployee],
                    active: 1,
                    ...(studentStaffContactRoleId && {
                        isModuleAdvisor: MAP_ADVISOR_ROLE_MODULE_FILTER[studentStaffContactRoleId]
                    })
                },
                range: {
                    limit: 20,
                    offset: loadedOptions.length
                },
                linkedObjects: true
            })
            return {
                options: staffs,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            handleError(error)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    onChangeStaff = (userId, newValue) => {
        const {selectedStaff} = this.state
        const {signer, onUpdateSignerItem, data} = this.props
        selectedStaff[userId] = newValue
        const staffs = signer.staffs.map((staff) => {
            staff.userId = staff.profile?.userId
            return staff
        })
        const staffsKeyByUserId = keyBy(staffs, "userId")
        const newSignerStaffs = data.map((user) => {
            const contactProfile = get(staffsKeyByUserId[user.id], "contactProfile", null)
            if (contactProfile) {
                return staffsKeyByUserId[user.id]
            }
            const staffInfo = selectedStaff[user.id] || {}
            const activeProfile = getFirstProfile(staffInfo, Auth.UserProfileType.Staff)
            return {
                primaryUserId: user.id,
                primaryProfileId: user.profileId,
                id: staffInfo.id,
                profileId: activeProfile?.id,
                activeProfile,
                firstName: staffInfo.firstName,
                lastName: staffInfo.lastName,
                title: get(staffInfo.profiles, "[0].title", ""),
                campus: get(staffInfo.profiles, "[0].campus", "")
            }
        })
        onUpdateSignerItem(signer.id, {...signer, staffs: newSignerStaffs})
        this.setState({selectedStaff}, () => {
            const newData = this.props.data.map((user) => {
                if (userId === user.id) {
                    user[`${signer.id}_staffHtml`] = this.renderStaffHtml(user, signer.staffs)
                    user[`${signer.id}_title`] = this.renderTitle(user, newSignerStaffs)
                    user[`${signer.id}_campus`] = this.renderStaffCampuses(user, newSignerStaffs)
                }
                return user
            })
            this.props.dispatch({data: newData})
        })
    }

    onSearchChange = async (input, loadedOptions) => {
        return await this.getStaffs(input, loadedOptions)
    }

    renderStaffHtml = (user, signerStaffs) => {
        const {selectedStaff} = this.state
        const profileId = get(user, "profiles[0].id", "")
        const staffsKeyByProfileId = keyBy(signerStaffs, "profileId")
        const contactProfile = get(staffsKeyByProfileId[profileId], "contactProfile", null)
        if (isEmpty(contactProfile)) {
            return (
                <KlassappTableDropdownAsyncPaginate
                    value={selectedStaff[user.id]}
                    getOptionLabel={(option: any) => getFullName(option)}
                    onChange={(newValue) => this.onChangeStaff(user.id, newValue)}
                    loadOptions={this.onSearchChange}
                />
            )
        }
        const avatarSrc = contactProfile.photo?.original
        return (
            <div className={styles.nameWrap}>
                {avatarSrc && <img src={avatarSrc} className={styles.avatar} alt="" />}
                <p className={styles.name}>{getFullName(contactProfile)}</p>
            </div>
        )
    }

    renderNameHtml = (item) => {
        return (
            <div className={styles.nameWrap}>
                <img src="/image/DefaultAvatar.png" className={styles.avatar} alt="" />
                <p className={styles.name}>{item.name}</p>
            </div>
        )
    }

    renderRoleHtml = () => {
        return this.props.signer.studentStaffContactRole?.name
    }

    getParams = () => {
        const {page, pageSize} = this.props
        const params = {
            range: {
                page,
                pageSize
            }
        }
        return params
    }

    getColumns = () => {
        const {signer, primarySigner} = this.props
        if (
            signer.participantType !== ParticipantType.PrimarySigner &&
            primarySigner &&
            signer.selectedType === SelectStaffFrom.ROLE
        ) {
            return [
                {
                    title: "Primary",
                    field: `${signer.id}_primaryHtml`,
                    style: {width: "200px"}
                },
                {
                    title: "Staff",
                    field: `${signer.id}_staffHtml`,
                    style: {width: "200px"}
                },
                {
                    title: "Role",
                    field: `${signer.id}_roleHtml`,
                    style: {width: "200px"}
                },
                {
                    title: "Title",
                    field: `${signer.id}_title`,
                    style: {width: "200px"}
                },
                {
                    title: "Campus",
                    field: `${signer.id}_campus`,
                    style: {maxWidth: "450px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden"}
                }
            ]
        }
        return [
            {
                title: "Name",
                field: "nameHtml",
                style: {width: "200px"}
            },
            {
                title: "ID",
                field: "staffId",
                style: {width: "200px"}
            },
            {
                title: "Title",
                field: "title",
                style: {width: "200px"}
            },
            {
                title: "Campus",
                field: "campus",
                style: {maxWidth: "450px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden"}
            }
        ]
    }

    onClickRowItem = (data) => {}

    render() {
        const {
            page,
            total,
            pageSize,
            columns,
            data,
            allFields,
            fields,
            orderField,
            isLoading,
            isShowTableHeaderAction,
            tableHeaderActions,
            signer,
            primarySigner,
            t,
            onClickEditSigner,
            onUpdateSignerItem,
            isWorkflowAction,
            workflowTargetType
        } = this.props

        if (!!isWorkflowAction && signer.selectedType === SelectStaffFrom.ROLE) {
            return (
                <span>
                    Relationship to Student: <b>{signer.studentStaffContactRole?.name}</b>
                </span>
            )
        }

        if (!data.length) {
            const type = primarySigner?.userType || primarySigner?.type
            let isActive =
                signer.id === primarySigner?.id ||
                primarySigner?.useWorkflowAllTargets ||
                primarySigner?.useWorkflowSingleTarget
            if (!isActive && primarySigner && !primarySigner.isSelecting && !primarySigner.isForm) {
                switch (type) {
                    case UserType.STUDENT:
                        isActive = !!(primarySigner.students || []).length
                        break
                    case UserType.STAFF:
                        isActive = !!(primarySigner.staffs || []).length
                        break
                    case UserType.OTHERS:
                        isActive = !!(primarySigner.others || []).length
                        break
                    default:
                        break
                }
            }
            return (
                <div className="text-center">
                    {!!isWorkflowAction && workflowTargetType === Auth.UserProfileType.Staff && (
                        <Row gutter={24} justify="center" align="middle">
                            {signer.id === primarySigner?.id ? (
                                <>
                                    <Col>
                                        <Checkbox
                                            checked={signer.useWorkflowAllTargets}
                                            onChange={(event) => {
                                                const checked = event.target.checked
                                                onUpdateSignerItem(signer.id, {
                                                    ...signer,
                                                    useWorkflowAllTargets: checked,
                                                    useWorkflowSingleTarget: false,
                                                    staffs: checked ? [] : signer.staffs
                                                })
                                            }}>
                                            Group all staffs in one task
                                        </Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox
                                            checked={signer.useWorkflowSingleTarget}
                                            onChange={(event) => {
                                                const checked = event.target.checked
                                                onUpdateSignerItem(signer.id, {
                                                    ...signer,
                                                    useWorkflowSingleTarget: checked,
                                                    useWorkflowAllTargets: false,
                                                    staffs: checked ? [] : signer.staffs
                                                })
                                            }}>
                                            Send each staff with individual task
                                        </Checkbox>
                                    </Col>
                                </>
                            ) : (
                                <Col>
                                    <Checkbox
                                        checked={signer.useWorkflowAllTargets}
                                        onChange={(event) => {
                                            const checked = event.target.checked
                                            onUpdateSignerItem(signer.id, {
                                                ...signer,
                                                useWorkflowAllTargets: checked,
                                                useWorkflowSingleTarget: false,
                                                staffs: checked ? [] : signer.staffs
                                            })
                                        }}>
                                        Use workflow target audience
                                    </Checkbox>
                                </Col>
                            )}
                        </Row>
                    )}

                    {!signer.useWorkflowAllTargets && !signer.useWorkflowSingleTarget && (
                        <div className={styles.selectWrap}>
                            <BaseButton
                                title={t("tasks.select")}
                                variant="secondary"
                                onClick={() => onClickEditSigner(signer)}
                                isActive={isActive}
                            />
                        </div>
                    )}
                </div>
            )
        }

        return (
            <div className={styles.wrap}>
                <KlassappTableHeader
                    isShowAction={isShowTableHeaderAction}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.props.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    data={data}
                    menuActions={[]}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    orderField={orderField}
                    isShowCheckedColumn
                    onClickRowItem={this.onClickRowItem}
                    onChangeFields={this.props.onChangeFields}
                    onUpdateRowData={this.props.onUpdateRowData}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onClickSortColumn={this.props.onClickSortColumn}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onChangeAllFields={this.props.onChangeAllFields}
                />
            </div>
        )
    }
}

export default KlassappTableHOC(withTranslation(["tasks", "common"])(StaffSigner))
