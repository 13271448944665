import React, {FC} from "react"
import {AttendanceEventStatus, AttendanceEventType} from "types/attendance"
import styles from "./AttendanceEventIcon.module.css"
import {IconAlertTriangleFill, IconCheckCircleLine, IconCheckmarkCircleFill} from "components/ui/icons"
import cx from "classnames"

export type AttendanceEventIconProps = {
    className?: string
    type: AttendanceEventType
    status?: AttendanceEventStatus | null
}

const AttendanceEventIcon: FC<AttendanceEventIconProps> = ({className, status, type}) => {
    const isSuccess = status && status === AttendanceEventStatus.Success
    const isFailure = status && status === AttendanceEventStatus.Failure

    if ([AttendanceEventType.Checkin, AttendanceEventType.Checkout].includes(type)) {
        return (
            <div
                className={cx(
                    styles.root,
                    {
                        [styles.rootSuccess]: isSuccess,
                        [styles.rootFailure]: isFailure
                    },
                    className
                )}>
                <IconCheckmarkCircleFill />
            </div>
        )
    }
    if (isFailure) {
        return (
            <div className={cx(styles.root, styles.rootWarning, className)}>
                <IconAlertTriangleFill />
            </div>
        )
    }

    return (
        <div
            className={cx(
                styles.root,
                {
                    [styles.rootSuccess]: isSuccess,
                    [styles.rootFailure]: isFailure
                },
                className
            )}>
            <IconCheckCircleLine />
        </div>
    )
}

export {AttendanceEventIcon}
