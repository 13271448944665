export namespace CourseEvent {
    export enum CourseEventType {
        Classroom = "classroom",
        Lab = "lab",
        Test = "test",
        Others = "others"
    }

    export enum Method {
        Online = "online",
        InClass = "in_class",
        Hybrid = "hybrid"
    }
}
