import {get} from "lodash"
import {
    ParamsGetCurrentProgress,
    ParamsGetCurrentProgressDetail,
    ResponseGetCurrentProgress,
    ResponseGetDegreeAuditProgress,
    ResponseGetDetailCurrentProgress
} from "types/degreeAudit"
import BaseService from "./Base"

class DegreeAuditService extends BaseService {
    async getDetailCurrentProgress(params: ParamsGetCurrentProgress): Promise<ResponseGetDetailCurrentProgress> {
        const url = "/v3/degree-audit/current-progress/detail"
        const response = await this.post(url, params)
        return get(response, "data", {})
    }
    async getTableCurrentProgress(params: ParamsGetCurrentProgressDetail): Promise<ResponseGetCurrentProgress> {
        const url = "/v3/degree-audit/current-progress/table"
        const response = await this.post(url, params)
        return get(response, "data", {})
    }
    async getProgress(params: ParamsGetCurrentProgress): Promise<ResponseGetDegreeAuditProgress> {
        const url = "/v3/degree-audit/progress"
        const response = await this.post(url, params)
        return get(response, "data", {})
    }
}

export default DegreeAuditService
