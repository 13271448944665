import {get} from "lodash"
import BaseService from "./Base"
import {ChatMessagePayload, Communication} from "types/communication"

class ChatServiceV2 extends BaseService {
    private viewOtherUserId = null

    constructor() {
        super(process.env.REACT_APP_CHAT_API_V2_BASE_URL)
    }

    setViewOtherUserId(viewOtherUserId: number | null) {
        this.viewOtherUserId = viewOtherUserId
    }

    private addViewOtherUserIdQueryParam(path: string) {
        return `${path}${this.viewOtherUserId ? `?viewOtherUserId=${this.viewOtherUserId}` : ""}`
    }

    async getSidebar(roomTypeName: string, params): Promise<{data: any; total?: number}> {
        let roomType: string = roomTypeName
        if (roomTypeName === Communication.RoomTypes.DirectMessage) roomType = "direct-message"
        if (
            [Communication.RoomTypes.OpenedSupportHub, Communication.RoomTypes.ClosedSupportHub].includes(
                roomTypeName as Communication.RoomTypes
            )
        )
            roomType = "support"

        const response = await this.post(this.addViewOtherUserIdQueryParam(`/rooms/sidebar/${roomType}`), params)
        return get(response, "data", [])
    }

    async getDepartmentCampuses(filter: {
        search?: string
        departmentId: number
        roomsPageSize: number
        unread?: boolean
    }): Promise<Communication.DepartmentCampus[]> {
        const response = await this.post(this.addViewOtherUserIdQueryParam("/rooms/department"), {filter})
        return get(response, "data.data", [])
    }

    async getDepartmentCampusRooms(
        filter: {
            departmentId: number
            campusId: number
            search?: string
            unread?: boolean
        },
        range: {
            page: number
            pageSize: number
        }
    ): Promise<{
        total: number
        data: Communication.DepartmentRoom[]
    }> {
        const response = await this.post(this.addViewOtherUserIdQueryParam("/rooms/department-campus"), {
            filter,
            range
        })
        return get(response, "data", {})
    }

    async getRoomMembers(params): Promise<{data: Communication.RoomUser[]; total?: number}> {
        const response = await this.post(this.addViewOtherUserIdQueryParam(`/rooms/members`), params)
        return get(response, "data", [])
    }

    async getPinned(params): Promise<{data: Communication.Message[]; total?: number}> {
        const response = await this.post(this.addViewOtherUserIdQueryParam(`/messages/pinned`), params)
        return get(response, "data", [])
    }

    async editRoomNotificationsSettings(params) {
        const response = await this.post("/rooms/notification-settings/edit", params)
        return get(response, "data.data", {})
    }

    async getLatestMessages(params): Promise<{data: Communication.Message[]}> {
        const response = await this.post(this.addViewOtherUserIdQueryParam(`/messages/latest`), params)
        return get(response, "data", [])
    }

    async getFragmentMessages(params): Promise<{data: Communication.Message[]}> {
        const response = await this.post(this.addViewOtherUserIdQueryParam(`/messages/fragment`), params)
        return get(response, "data", [])
    }

    async getThreads(params): Promise<{data: Communication.Message[]; total: number}> {
        const response = await this.post(this.addViewOtherUserIdQueryParam(`/rooms/threads`), params)
        return get(response, "data", [])
    }

    async getScheduledThreads(params): Promise<{data: Communication.Message[]; total: number}> {
        const response = await this.post(this.addViewOtherUserIdQueryParam(`/rooms/threads/scheduled`), params)
        return get(response, "data", [])
    }

    async getFollowUps(params): Promise<{data: Communication.FollowUpMessage[]; total: number}> {
        const response = await this.post(this.addViewOtherUserIdQueryParam(`/follow-up/list`), params)
        return get(response, "data", [])
    }

    async pinMessage(params) {
        const response = await this.post("/messages/pin", params)
        return get(response, "data", {})
    }

    async unreadMessage(params) {
        const response = await this.post("/messages/mark-as-unread", params)
        return get(response, "data", {})
    }

    async reactMessage(params) {
        const response = await this.post("/messages/reaction", params)
        return get(response, "data", {})
    }

    async getPreSignedUrl(params) {
        const response = await this.post("/upload/create", params)
        return get(response, "data.data", {})
    }

    async addDepartmentCampusRoom(departmentId: number, campusId: number, userId: number): Promise<Communication.Room> {
        const response = await this.post("/rooms/department/add", {departmentId, campusId, userId})
        return get(response, "data.data", null)
    }

    async addListDepartmentCampusRoom(
        params: {departmentId: number; campusId: number; userId: number}[]
    ): Promise<Communication.Room[]> {
        const response = await this.post("/rooms/department/add", params)
        return get(response, "data.data", [])
    }

    async addRoom(params): Promise<Communication.Room> {
        const response = await this.post("/rooms/add", params)
        return get(response, "data.data", {})
    }

    async deleteRoom(params) {
        const response = await this.post("/rooms/delete", params)
        return get(response, "data.data", {})
    }

    async createThread(params) {
        const response = await this.post("/rooms/thread/add", params)
        return get(response, "data.data", {})
    }

    async editThread(params) {
        const response = await this.post("/rooms/thread/edit", params)
        return get(response, "data.data", {})
    }

    async doneFollowUp(params) {
        const response = await this.post("/follow-up/open", params)
        return get(response, "data.data", {})
    }

    async followUp(params) {
        const response = await this.post("/follow-up/add", params)
        return get(response, "data.data", {})
    }

    async addMessage(payload: ChatMessagePayload) {
        const response = await this.post(`/messages/add`, payload)
        return get(response, "data.data")
    }

    async deleteMessage(params) {
        const response = await this.post("/messages/delete", params)
        return get(response, "data", {})
    }

    async publishThread(params) {
        const response = await this.post("/rooms/threads/post", params)
        return get(response, "data.data", {})
    }

    async getTotalUnread() {
        const response = await this.post("/rooms/total-unread", {})
        return get(response, "data", {})
    }

    async summaryNotifications() {
        const response = await this.post("/notifications/summary", {})
        return get(response, "data", [])
    }

    async listNotifications(params): Promise<{data: Communication.NotificationItem[]; total?: number}> {
        const response = await this.post("/notifications/list", params)
        return get(response, "data", [])
    }

    async markReadNotification(params) {
        const response = await this.post("/notifications/mark-as-read", params)
        return get(response, "data", [])
    }

    async searchMessages(params) {
        const response = await this.post("/messages/search", params)
        return get(response, "data", [])
    }

    async roomEdit(params) {
        const response = await this.post("/rooms/edit", params)
        return get(response, "data", {})
    }

    async removeMember(params) {
        const response = await this.post("/rooms/members/remove", params)
        return get(response, "data", {})
    }

    async addMembers(params) {
        const response = await this.post("/rooms/members/add", params)
        return get(response, "data", {})
    }

    async getRoom(params) {
        const response = await this.post("/rooms/get-one", params)
        return get(response, "data.data", {})
    }

    async createPoll(params) {
        const response = await this.post("/polls/add", params)
        return get(response, "data.data", {})
    }

    async stopPoll(params = {}) {
        const response = await this.post("/polls/stop", params)
        return get(response, "data.data", {})
    }

    async getSettings() {
        const response = await this.post("/user-settings/me", {})
        return get(response, "data", {})
    }

    async updateSettings(params) {
        const response = await this.post("/user-settings/edit", params)
        return get(response, "data.data", {})
    }

    async sendMessageToMultipleRooms(params) {
        const response = await this.post("/messages/add-multiple-rooms", params)
        return get(response, "data.data", {})
    }

    async createSupport(params) {
        const response = await this.post("/support/add", params)
        return get(response, "data.data", {})
    }

    async updateSupportStatus(params) {
        const response = await this.post("/support/update-status", params)
        return get(response, "data.data", {})
    }
}

export default ChatServiceV2
