import {BaseButton, BaseInput, BaseLoading, BasePopup} from "components"
import {useCallback, useEffect, useState} from "react"
import styles from "./EditGpaPopup.module.css"
import {FormLabel} from "components/Form"
import {handleError} from "helpers"
import {useTranslation} from "react-i18next"
import {academicCourseGradesService} from "services"

type Props = {
    isShow: boolean
    onClose: () => void
    courseId: number
    gpa?: number
    termId: number
    academicStudentStatsId?: number
    academicTermStatsId?: number
    studentProfileId: number
    onRefreshGpa: () => Promise<void>
    sapDate?: string
    scheduleId: number
}

export function EditGpaPopup(props: Props) {
    const {
        isShow,
        onClose,
        gpa,
        onRefreshGpa,
        termId,
        courseId,
        scheduleId,
        studentProfileId,
        academicStudentStatsId,
        academicTermStatsId
    } = props
    const [isLoading, setIsLoading] = useState(false)
    const [editedGpa, setEditedGpa] = useState<number>()
    const {t} = useTranslation(["common"])

    useEffect(() => {
        if (gpa >= 0) {
            setEditedGpa(gpa)
        } else {
            setEditedGpa(undefined)
        }
    }, [gpa])

    const onSubmit = useCallback(async () => {
        try {
            setIsLoading(true)
            const newGpaValue = editedGpa ? parseFloat(editedGpa.toString()) : null
            if (!courseId) {
                const payload = [
                    {
                        academicTermStatsId: academicTermStatsId,
                        gpa: newGpaValue,
                        studentProfileId: studentProfileId,
                        termId
                    }
                ]
                await academicCourseGradesService.updateGpa({
                    listGrades: payload
                })
            } else {
                const payload = [
                    {
                        academicStudentStatsId: academicStudentStatsId,
                        gpa: newGpaValue,
                        studentProfileId: studentProfileId,
                        termId,
                        courseId,
                        scheduleId
                    }
                ]
                await academicCourseGradesService.updateGpa({
                    listGrades: payload
                })
            }
            await onRefreshGpa()
            setIsLoading(false)
            onClose()
        } catch (err) {
            handleError(err)
            setIsLoading(false)
        }
    }, [
        courseId,
        onRefreshGpa,
        onClose,
        academicTermStatsId,
        editedGpa,
        studentProfileId,
        termId,
        academicStudentStatsId,
        scheduleId
    ])

    const onChangeGpaValue = useCallback((text) => {
        setEditedGpa(text)
    }, [])

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="FILE_LINE_ALT" width="50vw">
            <div className={styles.wrap}>
                <p className={styles.title}>Edit GPA</p>
                <FormLabel label="Edit GPA" />
                <BaseInput onChange={onChangeGpaValue} placeholder={"GPA Value"} disabledArrow value={editedGpa} />
                <div className={styles.submitContainer}>
                    <BaseButton
                        title={isLoading ? t("common:action.saving") : t("common:action.save")}
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>
                <BaseLoading isShow={isLoading} />
            </div>
        </BasePopup>
    )
}
