import React from "react"
import styles from "./ListEvents.module.css"
import {BaseButton} from "components"
import {PersonalCalendar} from "sections/calendar/parts"
import {PanelExpandedSection} from "uiKit/PanelExpandedSection"

type DisplayPart = "list" | "create"

type Props = {
    onNavigate?: (displayMode: DisplayPart) => void
    onClose?: () => void
}

export function ListEvents(props: Props) {
    const {onClose, onNavigate} = props

    return (
        <PanelExpandedSection title="Calendar" onClose={onClose}>
            <div className={styles.actionWrap}>
                <BaseButton
                    onClick={() => onNavigate("create")}
                    title="Add calendar event"
                    className={styles.addAction}
                />
            </div>
            <div className={styles.calendarWrap}>
                <PersonalCalendar displayMode="panel" />
            </div>
        </PanelExpandedSection>
    )
}
