import React from "react"
import {Draggable, Droppable} from "react-beautiful-dnd"
import {Menu, Dropdown, Button} from "antd"
import {AcademicPlans} from "types/academicPlans"
import {Icon} from "components/Icon"
import {handleError} from "helpers"
import {useVisible} from "hooks"
import {Auth} from "types/auth"
import {academicPlansService} from "services"
import {TermChooseCoursesPopup} from "../Popup"
import {CourseItem} from "./CourseItem"
import styles from "./SectionItem.module.css"
import {isEmpty} from "lodash"
import {Course} from "types/courses"

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: 0,
    marginBottom: 12,
    background: isDragging ? "lightgreen" : "transparent",
    ...draggableStyle
})

const getBackground = (isDraggingOver, canRegister) => {
    if (canRegister) {
        return isDraggingOver ? "var(--warning-300)" : "var(--warning-100)"
    }
    return isDraggingOver ? "lightblue" : "#a5d3ff"
}

const getListStyle = (isDraggingOver, canRegister) => ({
    background: getBackground(isDraggingOver, canRegister),
    padding: grid
})

type Props = {
    termItem: AcademicPlans.Term
    studentId: number
    student: Auth.DepartmentStudent
    index: number
    termSection: AcademicPlans.TermSection
    canTermRegister: boolean
    conflictEvents: any[]
    availableCourses: any[]
    registeringTerm: AcademicPlans.Term
    reloadView: () => void
    updateEvents: (data: AcademicPlans.UpdateEvents[]) => void
    onRegisterCourseSuccess: () => void
}

export function SectionItem(props: Props) {
    const {
        termItem,
        studentId,
        student,
        termSection,
        canTermRegister,
        conflictEvents,
        availableCourses,
        registeringTerm,
        reloadView,
        updateEvents,
        onRegisterCourseSuccess
    } = props
    const addCoursesPopup = useVisible(false)
    const {courses} = termSection
    let totalCredit = 0
    let totalEach = 0
    courses.forEach((course) => {
        if (course.courseTotalUnits === Course.Unit.Hour) {
            totalCredit += course.credit || 0
        } else {
            totalEach += course.credit || 0
        }
    })

    const removeSection = async () => {
        try {
            await academicPlansService.removeSection({
                studentProfileId: studentId,
                academicPlanSectionId: termSection.id
            })
            reloadView()
        } catch (error) {
            handleError(error)
        }
    }

    const addCourses = () => {
        addCoursesPopup.open()
    }

    const renderHeader = () => {
        const menu = (
            <Menu className={styles.menuActionWrap}>
                <Menu.Item key="edit" className={styles.menuActionItem} onClick={addCourses}>
                    <Icon className={styles.menuActionIcon} icon="PLUS_CIRCLE" />
                    <span className={styles.menuActionTitle}>Add Courses</span>
                </Menu.Item>
                {isEmpty(courses) && (
                    <Menu.Item key="delete" className={styles.menuActionItem} onClick={removeSection}>
                        <Icon className={styles.menuActionIcon} icon="DELETE" />
                        <span className={styles.menuActionTitle}>Remove</span>
                    </Menu.Item>
                )}
            </Menu>
        )

        return (
            <div className={styles.termCourseSectionHeader}>
                <p className={styles.termCourseSectionHeader__title}>{termSection.sectionTitle}</p>
                <div className={styles.termCourseSectionHeader__action}>
                    <div className={styles.sectionHourInfo}>
                        <span className={styles.sectionHourInfoTitle}>Credit</span>
                        <span className={styles.sectionHourInfoValue}>{totalCredit}</span>
                    </div>
                    <div className={styles.sectionHourInfo}>
                        <span className={styles.sectionHourInfoTitle}>Each</span>
                        <span className={styles.sectionHourInfoValue}>{totalEach}</span>
                    </div>
                    <Dropdown overlay={menu} trigger={["click"]}>
                        <Button className={styles.iconBtn} icon={<Icon icon="EDIT_LINE" />} />
                    </Dropdown>
                </div>
            </div>
        )
    }

    return (
        <div>
            <Droppable droppableId={`${termItem.id}_${termSection.id}`}>
                {(provided, snapshot) => (
                    <div>
                        {renderHeader()}
                        <div className={styles.termCourseSectionBody}>
                            <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver, canTermRegister)}>
                                {courses.map((termCourse, index) => {
                                    const unavailableScheduleIds = courses
                                        .filter(
                                            (course) =>
                                                course.courseId === termCourse.courseId &&
                                                (course.reviewStatus ===
                                                    AcademicPlans.AcademicPlanCourseReviewStatus.Dropped ||
                                                    course.reviewStatus ===
                                                        AcademicPlans.AcademicPlanCourseReviewStatus.Registered)
                                        )
                                        .map((course) => course.registeredScheduleId)

                                    return (
                                        <Draggable
                                            key={termCourse.courseId}
                                            isDragDisabled={
                                                termCourse.reviewStatus ===
                                                    AcademicPlans.AcademicPlanCourseReviewStatus.Registered ||
                                                termCourse.reviewStatus ===
                                                    AcademicPlans.AcademicPlanCourseReviewStatus.Dropped
                                            }
                                            draggableId={`${termItem.id}_${termSection.id}_${termCourse.academicPlanCourseId}`}
                                            index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}>
                                                    <CourseItem
                                                        key={index}
                                                        studentId={studentId}
                                                        student={student}
                                                        unavailableScheduleIds={unavailableScheduleIds}
                                                        termItem={termItem}
                                                        termCourse={termCourse}
                                                        termSection={termSection}
                                                        canTermRegister={canTermRegister}
                                                        conflictEvents={conflictEvents}
                                                        availableCourses={availableCourses}
                                                        registeringTerm={registeringTerm}
                                                        reloadView={reloadView}
                                                        updateEvents={updateEvents}
                                                        onRegisterCourseSuccess={onRegisterCourseSuccess}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                                {provided.placeholder}
                            </div>
                        </div>
                    </div>
                )}
            </Droppable>
            {addCoursesPopup.isVisible && (
                <TermChooseCoursesPopup
                    isShow={addCoursesPopup.isVisible}
                    onClose={addCoursesPopup.close}
                    section={termSection}
                    academicPlanTermId={termItem.id}
                    studentId={studentId}
                    onSaveSuccess={reloadView}
                />
            )}
        </div>
    )
}
