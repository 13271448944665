import React, {Component, Suspense} from "react"
import {createBrowserHistory} from "history"
import cx from "classnames"
import {Router, Route, Switch, useLocation} from "react-router-dom"
import * as queryString from "query-string"
import {observer} from "mobx-react"
import {EdularModulesProvider} from "@edular/modules"
import {Page404} from "components/Page404"
import {InternetConnectionStatus} from "components/InternetConnectionStatus"
import MagicLink from "sections/Public/MagicLink/MagicLink"
import AutoLogin from "sections/Public/AutoLogin/AutoLogin"
import {StoreProvider} from "stores"
import {ModelProvider} from "context/ModelContext"
import {PrivateRoutes, CommonRoutes, StaffRoutes, FinAidRoutes} from "./routes"
import styles from "./App.module.css"
import {BaseLoading, InfoBar} from "./components"
import {Header} from "./components/Header"
import {Model} from "./Model"
import {Gate} from "./lib/gate/Gate"
import {GlobalErrorBoundary} from "./GlobalErrorBoundary"
import {Redirector} from "sections/Redirector"
import {Closing} from "sections/Closing/Closing"
import {Unsubscribe} from "sections/Unsubscribe/Unsubscribe"
import "text-security/text-security.css"
import * as Sentry from "@sentry/react"
import {store} from "helpers"
import {StudentPaymentProvider} from "context/StudentPaymentContext"
import {ReactQueryClientProvider} from "providers"
import {ChatProviderV2} from "context/ChatContextV2"

const LoginPage = React.lazy(() => import("sections/Login/LoginPage"))
const ResetPage = React.lazy(() => import("sections/Login/ResetPage"))
const CFPIframe = React.lazy(
    () => import("sections/FinancialAid/FinancialAidStudent/parts/CollegeFinancingPlan/CollegeFinancingPlanIframe")
)

const history = createBrowserHistory()
const gate = new Gate()
const model = new Model(gate, history)

const NoMagicLinkWrapper = ({children}) => {
    const location = useLocation()

    return <>{!location.pathname.startsWith("/auth/magic-link/") && children}</>
}

type State = {
    isLoading: boolean
    isLoadingPermissions: boolean
    isLoggedIn: boolean
}

@observer
export class App extends Component<{ssoEnabled?: boolean}, State> {
    constructor(props) {
        super(props)
        model.isSsoEnabled = props.ssoEnabled
        model.loadSession()
        model.generateSlug()
        model.loadSocket()

        this.state = {
            isLoading: false,
            isLoadingPermissions: false,
            isLoggedIn: model.isLoggedIn
        }
    }

    componentDidMount() {
        this.loadData()
        const queryParams: any = queryString.parse(window.location.search)

        if (!queryParams.token) {
            queryParams.token = gate.getToken()
        }

        store.set("token", queryParams.token)

        if (queryParams.submitTask === "1" || window.location.pathname === "/redirect") {
            model.menu.sidebar = false
            model.menu.infoBar = false
            model.isSubmittingTaskFromEmail = true
        }
    }

    async componentDidUpdate(_, prevState) {
        if (model.isLoggedIn && !prevState.isLoggedIn && !this.state.isLoadingPermissions) {
            model.loadUserInfo()
            model.loadSession()
            model.loadSocket()
            this.loadMyPermission()
            model.loadFieldLabels()
            model.loadSmsPhoneNumbers().catch(console.error)
            this.setState({isLoggedIn: true})
        }
    }

    loadData = async () => {
        try {
            this.setState({isLoading: true})
            await Promise.all([model.loadUserInfo(), model.loadSettings(), this.loadMyPermission()])
            model.loadDateFormat()
            model.loadDateTimeFormat()
            model.loadAllowedDocumentFormats()
            if (model.isLoggedIn) {
                model.loadFieldLabels()
                model.loadSmsPhoneNumbers()
            }
        } catch (error) {
        } finally {
            this.setState({isLoading: false})
        }
    }

    loadMyPermission = async () => {
        try {
            this.setState({isLoadingPermissions: true})
            const {isLoggedIn} = model

            if (isLoggedIn) {
                await Promise.all([model.loadMyPermissions(), model.loadDepartments()])
            }
            model.updateUserTimeZone()
        } catch (error) {
            model.logout()
            window.location.href = "/"
        } finally {
            this.setState({isLoadingPermissions: false})
        }
    }

    public render() {
        const {isLoading} = this.state
        const {isLoggedIn, photo, myPermissions} = model

        if (isLoading || (isLoggedIn && !myPermissions)) {
            return <BaseLoading isShow={true} />
        }

        return (
            <ReactQueryClientProvider>
                <StoreProvider>
                    <ModelProvider model={model}>
                        <div className={cx(styles.wrapper, {[styles.public_wrapper]: !isLoggedIn})}>
                            <Router history={history}>
                                <EdularModulesProvider>
                                    <NoMagicLinkWrapper>
                                        <InfoBar hidden={!isLoggedIn || !model.menu.infoBar} photo={photo} />
                                        <Header hidden={!isLoggedIn || !model.menu.infoBar} />
                                    </NoMagicLinkWrapper>
                                    <div
                                        className={cx(styles.bodyWrap, {
                                            [styles.bodySubmitTask]: !model.menu.sidebar
                                        })}>
                                        <Sentry.ErrorBoundary
                                            fallback={(errorData) => <GlobalErrorBoundary {...errorData} />}>
                                            <Suspense fallback={<BaseLoading isShow={true} />}>
                                                <StudentPaymentProvider isLoggedIn={isLoggedIn}>
                                                    <Switch>
                                                        <Route
                                                            exact
                                                            path="/redirect"
                                                            render={() => <Redirector model={model} />}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/login"
                                                            render={(props) => <LoginPage {...props} />}
                                                        />
                                                        <Route exact path="/login/reset" render={() => <ResetPage />} />
                                                        <Route exact path="/cfp/:cfpId" render={() => <CFPIframe />} />
                                                        <Route
                                                            exact
                                                            path="/auth/magic-link/:email/:code"
                                                            render={() => <MagicLink model={model} />}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/auto-login/:email/:code"
                                                            render={() => <AutoLogin model={model} />}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/closing"
                                                            render={(props) => <Closing model={model} {...props} />}
                                                        />
                                                        <Route
                                                            exact
                                                            path="/unsubscribe/:token"
                                                            render={(props) => <Unsubscribe model={model} {...props} />}
                                                        />
                                                        <PrivateRoutes isLoggedIn={isLoggedIn} path="/*">
                                                            <ChatProviderV2>
                                                                <InternetConnectionStatus />
                                                                <CommonRoutes model={model} />
                                                                <StaffRoutes model={model} />
                                                                <FinAidRoutes model={model} />
                                                            </ChatProviderV2>
                                                        </PrivateRoutes>
                                                        <Route path="*" render={(props) => <Page404 {...props} />} />
                                                    </Switch>
                                                </StudentPaymentProvider>
                                            </Suspense>
                                        </Sentry.ErrorBoundary>
                                    </div>
                                </EdularModulesProvider>
                            </Router>
                        </div>
                    </ModelProvider>
                </StoreProvider>
            </ReactQueryClientProvider>
        )
    }
}
