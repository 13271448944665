/* eslint-disable react-hooks/exhaustive-deps */
import {KlassappTableHOC} from "HOC"
import styles from "./ImportList.module.css"
import {useModel} from "hooks"
import {useEffect, useState} from "react"
import {handleError, truncateWithEllipses} from "helpers"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import {Col, Row} from "antd"
import {FormLabel} from "components/Form"
import {importService} from "services"
import {KlassDropdown} from "components/Select"
import {BaseButton} from "components"
import {useHistory} from "react-router-dom"
import {Auth} from "types/auth"
import {BaseRangePicker} from "components/DateTimePicker"
import moment from "moment"
import {ReactComponent as UploadIcon} from "./assets/upload.svg"
import {ReactComponent as FailedIcon} from "./assets/failed.svg"
import {ImportType, Import} from "types/import-users"
import cx from "classnames"
import {KlassappTableProps} from "types/common"

const USER_TYPE_OPTIONS = [
    {id: Auth.UserProfileType.Staff, name: "Staff"},
    {id: Auth.UserProfileType.Student, name: "Student"},
    {id: Auth.UserProfileType.Others, name: "Others"}
]

const STATUS_OPTIONS = [
    {id: Import.ImportStatus.Pending, name: "Pending"},
    {id: Import.ImportStatus.Processing, name: "Processing"},
    {id: Import.ImportStatus.Completed, name: "Completed"}
]

const INITIAL_FILTERS = {
    statuses: [],
    userTypes: [],
    dateRange: null
}

type Props = KlassappTableProps & {
    importType: ImportType
}

export function ImportPage({
    data,
    page,
    total,
    pageSize,
    columns,
    fields,
    allFields,
    isLoading,
    dispatch,
    dispatchFunc,
    onChangePage,
    onChangeRowPerPage,
    onChangeFields,
    onDraggableColumn,
    onChangeAllFields,
    onUpdateRowData,
    onUpdateTableData,
    importType
}: Props) {
    const model = useModel()
    const history = useHistory()
    const [filter, setFilter] = useState(INITIAL_FILTERS)
    const [search, setSearch] = useState("")

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
    }, [])

    useEffect(() => {
        getData()
    }, [search, page, pageSize])

    const getFilterParams = () => {
        let filterParams: any = {
            search,
            types: [importType]
        }
        if (filter.userTypes?.length) {
            filterParams.userTypes = filter.userTypes.map((x) => x.id)
        }
        if (filter.statuses?.length) {
            filterParams.statuses = filter.statuses.map((x) => x.id)
        }
        if (filter.dateRange?.length) {
            filterParams.dateRange = filter.dateRange.map((x) => x.format("YYYY-MM-DD"))
        }
        return filterParams
    }

    async function getData() {
        try {
            dispatch({isLoading: true})
            const filterParams = getFilterParams()

            const {data, total} = await importService.list({
                range: {page, pageSize},
                filter: filterParams
            })

            const formattedData = data.map((x) => {
                return {
                    ...x,
                    description: truncateWithEllipses(x.description ?? "", 80, 80),
                    userTypeHtml: USER_TYPE_OPTIONS.find((y) => y.id === x.userType)?.name,
                    statusHtml: renderStatusHtml(x),
                    summaryHtml: renderSummaryHtml(x)
                }
            })

            dispatch({data: formattedData, total})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    function getColumns() {
        return [
            {
                title: "Date",
                field: "createdAt",
                fieldType: "date",
                format: model.getUserDateFormat(),
                headerStyle: {minWidth: "100px"}
            },
            {
                title: "Name",
                field: "name",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Description",
                field: "description"
            },
            importType !== ImportType.User
                ? null
                : {
                      title: "User's Type",
                      field: "userTypeHtml",
                      headerStyle: {minWidth: "100px"}
                  },
            {
                title: "Status",
                field: "statusHtml",
                headerStyle: {minWidth: "100px"}
            },
            {
                title: "Summary",
                field: "summaryHtml",
                headerStyle: {minWidth: "150px"}
            }
        ].filter(Boolean)
    }

    function getFields() {
        return [
            "Date",
            "Name",
            "Description",
            importType !== ImportType.User ? null : "User's Type",
            "Status",
            "Summary"
        ].filter(Boolean)
    }

    const renderSummaryHtml = (item) => {
        if (item.status !== Import.ImportStatus.Completed) return null
        return (
            <div className={styles.summaryWrap}>
                <div className={styles.itemWrap}>
                    <UploadIcon />
                    <span>{`${item.importedRows || 0} imported`}</span>
                </div>
                <div className={styles.itemWrap}>
                    <FailedIcon />
                    <span>{`${item.failedRows || 0} failed`}</span>
                </div>
            </div>
        )
    }

    const renderStatusHtml = (item) => {
        return (
            <span className={cx(styles.statusWrap, styles[`status-${item.status}`])}>
                {STATUS_OPTIONS.find((x) => x.id === item.status)?.name}
            </span>
        )
    }

    const onClickRowItem = (row) => {
        history.push(`/data-imports/${importType}/${row.id}`)
    }

    const onClickClearFilter = () => {
        setFilter(INITIAL_FILTERS)
    }

    const onChangeFilter = (key, value) => {
        const newFilter = {...filter}
        newFilter[key] = value
        setFilter(newFilter)
    }

    const onSearchInput = (search) => {
        setSearch(search)
    }

    const onApplyFilter = () => {
        getData()
    }

    return (
        <div>
            <BaseNewFilter
                filter={filter}
                onClick={onApplyFilter}
                onClickClear={onClickClearFilter}
                onSearchInput={onSearchInput}
                renderRightFilter={() => (
                    <div className={styles.actionWrap}>
                        <BaseButton
                            title="New Import"
                            onClick={() => {
                                history.push(`/data-imports/${importType}`)
                            }}
                            className={styles.addButton}
                        />
                    </div>
                )}>
                <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <FormLabel label="Status" />
                        <KlassDropdown
                            value={filter.statuses}
                            placeholder="Select"
                            onChange={(option: any) => onChangeFilter("statuses", option)}
                            options={STATUS_OPTIONS}
                            isMulti
                        />
                    </Col>
                    <Col span={12}>
                        <FormLabel label="Date Range" />
                        <BaseRangePicker
                            placeholder={["Date From", "To"]}
                            value={filter.dateRange ? [moment(filter.dateRange[0]), moment(filter.dateRange[1])] : null}
                            onChange={(value) => {
                                if (value) {
                                    onChangeFilter("dateRange", [moment(value[0]), moment(value[1])])
                                } else {
                                    onChangeFilter("dateRange", null)
                                }
                            }}
                        />
                    </Col>
                    {importType === ImportType.User && (
                        <Col span={12}>
                            <FormLabel label="User's Type" />
                            <KlassDropdown
                                value={filter.userTypes}
                                placeholder="Select"
                                onChange={(option: any) => onChangeFilter("userTypes", option)}
                                options={USER_TYPE_OPTIONS}
                                isMulti
                            />
                        </Col>
                    )}
                </Row>
            </BaseNewFilter>
            <Row className={styles.marginTop}>
                <Col span={24}>
                    <KlassappTableHeader
                        page={page}
                        total={total}
                        defaultPageSize={pageSize}
                        onChangePage={onChangePage}
                        onChangeRowPerPage={onChangeRowPerPage}
                        fields={fields}
                        allFields={allFields}
                        onChangeFields={onChangeFields}
                        onChangeAllFields={onChangeAllFields}
                        onDraggableColumn={onDraggableColumn}
                    />
                    <KlassappTable
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        fields={fields}
                        allFields={allFields}
                        onChangeFields={onChangeFields}
                        onUpdateRowData={onUpdateRowData}
                        onUpdateTableData={onUpdateTableData}
                        onClickRowItem={onClickRowItem}
                        onChangeAllFields={onChangeAllFields}
                    />
                </Col>
            </Row>
        </div>
    )
}

export const ImportList = KlassappTableHOC(ImportPage)
