import {useModel} from "./useModel"
import linkify from "linkify-it"
import {useHistory} from "react-router-dom"

export default function useNotification() {
    const model = useModel()
    const history = useHistory()

    const checkPermission = () => {
        if (!("Notification" in window) || !("permission" in Notification)) {
            return false
        }
        if (Notification.permission === "denied" || Notification.permission === "default") {
            return false
        }
        return true
    }

    const newMessageNotification = async (message) => {
        if (model.user.id !== message.from_user_id && checkPermission()) {
            const {
                json_data: {type}
            } = message

            let payload = message.payload
            if ((payload || "").length > 0) {
                const split = payload.split(/(\[@[^:]+:[^\]]+\])/gi)
                const res = []
                for (let index = 0; index < split.length; index++) {
                    if (index % 2 === 1) {
                        const [, user] = split[index].match(/\[(@[^:]+):([^\]]+)\]/i)
                        res.push(user)
                    } else {
                        res.push(split[index])
                    }
                }
                payload = res.join("")
            }

            if (type !== "text" && !payload) {
                payload = "Sent you a file"
            }

            const user = await model.getOneUser(message.from_user_id)
            const defaultAvatar = `${window.location.origin}/image/DefaultAvatar.png`
            // source: https://notificationsounds.com/message-tones/appointed-529
            // disable audio for multiple tabs
            // const audio = new Audio("https://notificationsounds.com/storage/sounds/file-sounds-1076-appointed.mp3")
            // audio.play()
            const notification = new Notification(user?.fullName, {
                body: payload,
                tag: message.message_id,
                icon: user.photo ? user.photo["64"] : defaultAvatar
            })
            notification.onclick = () => {
                const urls = linkify().match(message.payload)
                if (urls) {
                    const {url} = urls[0]
                    const origin = window.location.origin
                    if (url.startsWith(origin)) {
                        window.location.href = url
                        return
                    }
                }
                history.push(`/communication?room=${message.room_id}`)
            }
        }
    }

    const newMessageNotificationV2 = async (message) => {
        if (model.user.id !== message.fromUserId && checkPermission()) {
            let payload = message.content
            if ((payload || "").length > 0) {
                const split = payload.split(/(\[@[^:]+:[^\]]+\])/gi)
                const res = []
                for (let index = 0; index < split.length; index++) {
                    if (index % 2 === 1) {
                        const [, user] = split[index].match(/\[(@[^:]+):([^\]]+)\]/i)
                        res.push(user)
                    } else {
                        res.push(split[index])
                    }
                }
                payload = res.join("")
            }

            if (message.type !== "message" && !payload) {
                payload = "Sent you a file"
            }

            const user = await model.getOneUser(message.fromUserId)
            const defaultAvatar = `${window.location.origin}/image/DefaultAvatar.png`

            const notification = new Notification(user?.fullName, {
                body: payload,
                tag: message.messageId,
                icon: user.photo ? user.photo["64"] : defaultAvatar
            })
            notification.onclick = () => {
                const urls = linkify().match(message.payload)
                if (urls) {
                    const {url} = urls[0]
                    const origin = window.location.origin
                    if (url.startsWith(origin)) {
                        window.location.href = url
                        return
                    }
                }
                history.push(`/communication?room=${message.roomId}`)
            }
        }
    }

    return {newMessageNotification, newMessageNotificationV2}
}
