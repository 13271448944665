import React from "react"
import ReactFlow, {
    addEdge,
    Background,
    Controls,
    DefaultEdgeOptions,
    Edge,
    Handle,
    MarkerType,
    Node,
    NodeProps,
    OnEdgesChange,
    OnNodesChange,
    Position,
    ReactFlowInstance,
    useStore
} from "reactflow"
import classNames from "classnames"
import {NodeType} from "./FlowChart.constants"
import {Icon} from "../Icon"
import {useTranslation} from "react-i18next"
import {useModel} from "hooks"
import {Col, Popover, Row} from "antd"
import {BaseButton} from "components/buttons"
import "./FlowChart.css"
import styles from "./FlowChart.module.css"
import {
    assertClassFromType,
    getWorkflowNodeTypeIcon,
    ProcessWorkflowActionNodeData,
    ProcessWorkflowNodeType,
    ProcessWorkflowStepNodeData
} from "types/workflow"
import RichTextEditor from "components/RichTextEditor/RichTextEditor"
import {StudentActivity} from "types/activity"
import {getFullName} from "helpers"
import moment from "moment"

const connectionNodeIdSelector = (state) => state.connectionNodeId
const deleteKeyCodes = ["Backspace", "Delete"]

type StartNodeProps = {
    node: NodeProps<void>
    onAddTarget?: (sourceId: string, nodeType: NodeType) => void
    readOnly: boolean
}

const StartNode: React.FC<StartNodeProps> = ({node, onAddTarget, readOnly}) => {
    const {t} = useTranslation(["workflow"])
    const {id} = node
    const [newNodeVisible, setNewNodeVisible] = React.useState(false)

    const handleAddTarget = React.useCallback(
        (nodeType: NodeType) => {
            onAddTarget?.(id, nodeType)
            setNewNodeVisible(false)
        },
        [id, onAddTarget]
    )

    const newNodeContent = (
        <Row gutter={24} wrap={false} className="w-full">
            <Col span={12}>
                <BaseButton title={t("workflow.flow.addStep")} onClick={() => handleAddTarget(NodeType.Step)} />
            </Col>
        </Row>
    )

    return (
        <>
            <div className={classNames(styles.workflowStartNode)}></div>

            <Popover
                content={newNodeContent}
                trigger="click"
                visible={!readOnly && newNodeVisible}
                onVisibleChange={setNewNodeVisible}>
                <Handle type="source" position={Position.Bottom} className={styles.sourceHandle}>
                    <Icon icon="ADD_CIRCLE_BLUE" />
                </Handle>
            </Popover>
        </>
    )
}

type CustomNodeProps<T> = {
    node: NodeProps<T>
    reactFlowRef: React.MutableRefObject<ReactFlowInstance>
    onAddTarget?: (sourceId: string, nodeType: NodeType, type?: ProcessWorkflowNodeType) => void
    onEdit?: (node: NodeProps<T>) => void
    onDelete?: (node: NodeProps<T>) => void
    readOnly: boolean
    activity?: StudentActivity
}

const CustomNode = <T,>({
    node,
    reactFlowRef,
    onAddTarget,
    onEdit,
    onDelete,
    readOnly,
    activity
}: CustomNodeProps<T>) => {
    const {t} = useTranslation(["workflow"])
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const userTimeFormat = model.getUserTimeFormat()
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const connectionNodeId = useStore(connectionNodeIdSelector)
    const isTarget = connectionNodeId && connectionNodeId !== node.id
    const targetHandleStyle = {zIndex: isTarget ? 3 : -1}

    const [newNodeVisible, setNewNodeVisible] = React.useState(false)

    const handleAddTarget = React.useCallback(
        (nodeType: NodeType, type?: ProcessWorkflowNodeType) => {
            onAddTarget?.(node.id, nodeType, type)
            setNewNodeVisible(false)
        },
        [node.id, onAddTarget]
    )

    const handleEdit = React.useCallback(() => {
        onEdit?.(node)
    }, [node, onEdit])

    const handleDelete = React.useCallback(() => {
        onDelete?.(node)
    }, [node, onDelete])

    const newNodeContent = (
        <Row gutter={[24, 12]} className={styles.workflowActionButtonsContainer}>
            {assertClassFromType<ProcessWorkflowActionNodeData>(node, NodeType.Action) &&
                node.data.type === ProcessWorkflowNodeType.ActionCreateActivity && (
                    <Col span={24}>
                        <BaseButton
                            className="w-full"
                            title={t("workflow.flow.addStep")}
                            onClick={() => handleAddTarget(NodeType.Step)}
                        />
                    </Col>
                )}
            {assertClassFromType<ProcessWorkflowStepNodeData>(node, NodeType.Step) && (
                <>
                    <Col span={24}>
                        <BaseButton
                            className="w-full"
                            title={t("workflow.flow.createActivity")}
                            onClick={() =>
                                handleAddTarget(NodeType.Action, ProcessWorkflowNodeType.ActionCreateActivity)
                            }
                        />
                    </Col>
                    <Col span={24}>
                        <BaseButton
                            className="w-full"
                            title={t("workflow.flow.sendEmail")}
                            onClick={() => handleAddTarget(NodeType.Action, ProcessWorkflowNodeType.ActionSendEmail)}
                        />
                    </Col>
                    <Col span={24}>
                        <BaseButton
                            className="w-full"
                            title={t("workflow.flow.sendMessage")}
                            onClick={() => handleAddTarget(NodeType.Action, ProcessWorkflowNodeType.ActionSendMessage)}
                        />
                    </Col>
                    <Col span={24}>
                        <BaseButton
                            className="w-full"
                            title={t("workflow.flow.createCalendarEvent")}
                            onClick={() =>
                                handleAddTarget(NodeType.Action, ProcessWorkflowNodeType.ActionCreateCalendarEvent)
                            }
                        />
                    </Col>
                </>
            )}
        </Row>
    )

    const renderContent = () => {
        if (assertClassFromType<ProcessWorkflowStepNodeData>(node, NodeType.Step)) {
            return node.data.name
        }
        if (assertClassFromType<ProcessWorkflowActionNodeData>(node, NodeType.Action)) {
            const {type, data = {}} = node.data
            switch (type) {
                case ProcessWorkflowNodeType.ActionCreateActivity:
                    return (
                        <Row gutter={[8, 8]}>
                            <Col span={24}>
                                <span className={styles.nodeContentBadge}>{data.activity?.name}</span>
                            </Col>
                            <Col span={24}>
                                <span className={styles.nodeContentText}>{data.description}</span>
                            </Col>
                        </Row>
                    )
                case ProcessWorkflowNodeType.ActionSendEmail:
                case ProcessWorkflowNodeType.ActionSendMessage:
                    return (
                        <RichTextEditor
                            className={styles.rteWrapper}
                            value={data.descendants}
                            fieldReadOnly={true}
                            readOnly={true}
                            userDateFormat={userDateFormat}
                            userTimeFormat={userTimeFormat}
                        />
                    )
                case ProcessWorkflowNodeType.ActionCreateCalendarEvent:
                    return (
                        <Row gutter={[8, 8]}>
                            <Col span={24}>
                                <span className={styles.nodeContentBadge}>{data.eventType?.name}</span>
                            </Col>
                            <Col span={24}>
                                <span className={styles.nodeContentText}>{data.name}</span>
                            </Col>
                        </Row>
                    )
            }
        }
    }

    let stepPending = false
    let stepInProgress = false
    let stepDone = false
    let nodePending = false
    let nodeInProgress = false
    let nodeDone = false
    let inProgressHint
    let doneHint
    let description
    if (readOnly && assertClassFromType<ProcessWorkflowStepNodeData>(node, NodeType.Step)) {
        const nextNodeIds =
            reactFlowRef.current
                ?.getEdges()
                .filter((edge) => edge.source === node.id)
                .map((edge) => edge.target) ?? []
        const nextActivityNodes =
            reactFlowRef.current
                ?.getNodes()
                .filter(
                    (node) =>
                        nextNodeIds.includes(node.id) &&
                        node.type === NodeType.Action &&
                        node.data.type === ProcessWorkflowNodeType.ActionCreateActivity
                ) ?? []
        stepInProgress = nextActivityNodes.some((node) => {
            const workflowActivity = activity?.workflowActivities?.find(
                (activity) => activity.workflowNodeId === node.id
            )
            return workflowActivity && !workflowActivity.completed
        })
        stepDone = nextActivityNodes.every((node) => {
            const workflowActivity = activity?.workflowActivities?.find(
                (activity) => activity.workflowNodeId === node.id
            )
            return workflowActivity && !!workflowActivity.completed
        })
        stepPending = !stepInProgress && !stepDone
    }
    if (readOnly && assertClassFromType<ProcessWorkflowActionNodeData>(node, NodeType.Action)) {
        const workflowActivity = activity?.workflowActivities?.find((activity) => activity.workflowNodeId === node.id)
        if (workflowActivity) {
            nodeDone = !!workflowActivity.completed
            nodeInProgress = !workflowActivity.completed
            description = workflowActivity.assignedProfiles?.map(getFullName).join(", ") || "-"
            if (nodeDone) {
                doneHint =
                    getFullName(workflowActivity.completedByProfile) +
                    " - " +
                    moment(workflowActivity.completedDate).format(userDateTimeFormat)
            }
            if (workflowActivity.dueDate) {
                inProgressHint = `DUE ${moment(workflowActivity.dueDate).format(userDateTimeFormat)}`
            }
        }
        nodePending = !nodeInProgress && !nodeDone
    }

    return (
        <>
            <div
                className={classNames(styles.node, {
                    [styles.conditionNode]: node.type === NodeType.Step,
                    [styles.isTarget]: isTarget,
                    [styles.stepDone]: stepDone || nodeDone,
                    [styles.stepInProgress]: stepInProgress || nodeInProgress,
                    [styles.stepPending]: stepPending || nodePending
                })}>
                <Handle
                    type="target"
                    position={Position.Top}
                    className={styles.targetHandle}
                    style={targetHandleStyle}
                />

                <Row className={styles.nodeType} align="middle" wrap={false}>
                    <Col className={styles.nodeTypeIcon}>
                        <Icon
                            icon={
                                assertClassFromType<ProcessWorkflowActionNodeData>(node, NodeType.Action)
                                    ? getWorkflowNodeTypeIcon(node.data.type)
                                    : "TYPE_LINE"
                            }
                            color="var(--primary-400-base)"
                        />
                    </Col>
                    <Col className={styles.nodeTypeText} flex={1}>
                        {t(
                            assertClassFromType<ProcessWorkflowActionNodeData>(node, NodeType.Action)
                                ? `workflow.actionType.${node.data.type}.title`
                                : "workflow.flow.step"
                        )}
                    </Col>
                    {!readOnly && (
                        <Col>
                            <Row gutter={8} wrap={false}>
                                <Col className={styles.nodeTypeIcon} onClick={handleEdit} title="Edit">
                                    <Icon icon="EDIT_LINE" />
                                </Col>
                                <Col className={styles.nodeTypeIcon} onClick={handleDelete} title="Delete">
                                    <Icon icon="DELETE" color="#DF1642" />
                                </Col>
                            </Row>
                        </Col>
                    )}
                    {(stepDone || nodeDone) && (
                        <Col title={doneHint}>
                            <Icon icon="CHECKMARK_CIRCLE" color="#18a957" />
                        </Col>
                    )}
                    {(stepInProgress || nodeInProgress) && (
                        <Col title={inProgressHint}>
                            <div className={styles.statusBadge}>IN PROGRESS</div>
                        </Col>
                    )}
                </Row>
                <div className={styles.nodeContent}>
                    {renderContent()}
                    {!!description && <div className={styles.nodeContentDescription}>{description}</div>}
                </div>
            </div>

            {!(
                assertClassFromType<ProcessWorkflowActionNodeData>(node, NodeType.Action) &&
                node.data.type !== ProcessWorkflowNodeType.ActionCreateActivity
            ) && (
                <Popover
                    content={newNodeContent}
                    trigger="click"
                    visible={!readOnly && newNodeVisible}
                    onVisibleChange={setNewNodeVisible}>
                    <Handle type="source" position={Position.Bottom} className={styles.sourceHandle}>
                        <Icon icon={"ADD_CIRCLE_BLUE"} />
                    </Handle>
                </Popover>
            )}
        </>
    )
}

const defaultEdgeOptions: DefaultEdgeOptions = {
    type: "default",
    markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 40,
        height: 40,
        markerUnits: "userSpaceOnUse",
        color: "#1e90ff"
    },
    labelBgPadding: [8, 4],
    labelBgBorderRadius: 4,
    labelBgStyle: {fill: "#FFCC00", color: "#fff", fillOpacity: 0.7}
}

type FlowChartProps = {
    nodes: Node[]
    setNodes: React.Dispatch<React.SetStateAction<Node[]>>
    onNodesChange: OnNodesChange
    edges: Edge[]
    setEdges: React.Dispatch<React.SetStateAction<Edge[]>>
    onEdgesChange: OnEdgesChange
    onAddTarget?: (sourceId: string, nodeType: NodeType, type?: ProcessWorkflowNodeType) => void
    onEdit?: (node: NodeProps<ProcessWorkflowStepNodeData | ProcessWorkflowActionNodeData>) => void
    onDelete?: (node: NodeProps<ProcessWorkflowStepNodeData | ProcessWorkflowActionNodeData>) => void
    readOnly?: boolean
    activity?: StudentActivity
}

const ProcessWorkflowFlowChart: React.FC<FlowChartProps> = ({
    nodes,
    setNodes,
    onNodesChange,
    edges,
    setEdges,
    onEdgesChange,
    onAddTarget,
    onEdit,
    onDelete,
    readOnly = false,
    activity
}) => {
    const reactFlowRef = React.useRef<ReactFlowInstance>()

    const [loadingKey, setLoadingKey] = React.useState(Math.random())

    const onConnect = React.useCallback((connection) => setEdges((eds) => addEdge(connection, eds)), [setEdges])

    const nodeTypes = React.useMemo(() => {
        const renderStartNode = (props) => <StartNode key={loadingKey} {...{node: props, onAddTarget, readOnly}} />

        const renderCustomNode = (props) => (
            <CustomNode<any>
                key={loadingKey}
                {...{
                    node: props,
                    reactFlowRef,
                    onAddTarget,
                    onEdit,
                    onDelete,
                    readOnly,
                    activity
                }}
            />
        )

        return {
            [NodeType.Start]: renderStartNode,
            [NodeType.Step]: renderCustomNode,
            [NodeType.Action]: renderCustomNode
        }
    }, [onAddTarget, onEdit, onDelete, readOnly, activity, loadingKey])

    const edgeTypes = React.useMemo(() => ({}), [])

    const handleInit = React.useCallback((reactFlowInstance: ReactFlowInstance) => {
        reactFlowRef.current = reactFlowInstance
        setLoadingKey(Math.random())
    }, [])

    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            defaultEdgeOptions={defaultEdgeOptions}
            onInit={handleInit}
            fitView={readOnly}
            preventScrolling={false}
            zoomOnScroll={false}
            nodesDraggable={!readOnly}
            nodesConnectable={!readOnly}
            edgesFocusable={!readOnly}
            deleteKeyCode={deleteKeyCodes}
            attributionPosition="top-right">
            <Controls />
            <Background />
        </ReactFlow>
    )
}

export default ProcessWorkflowFlowChart
