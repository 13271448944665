/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react"
import {store} from "helpers"
import {Auth} from "types/auth"

export const useCurrentUserDateFormat = () => {
    const [userCurrentUserDateFormat, getUserCurrentUserDateFormat] = useState(null)
    const user = store.get("user") as Auth.UserProfile

    useEffect(() => {
        getUserCurrentUserDateFormat(user?.dateFormat)
    }, [])

    return userCurrentUserDateFormat
}
