import {useCallback, useEffect, useRef} from "react"
import {useHistory, useLocation} from "react-router-dom"
import qs, {ParsedQuery} from "query-string"
import {useDeepEqualMemo} from "./useDeepEqualMemo"

type UseSearchParamOptions = {
    defaultValue?: string | number | null
    parse?: (value: string) => any
    replace?: boolean
    initiallyUpdateDefaultValue?: boolean
    resetParamsOnChange?: string[]
}

const DEFAULT_OPTIONS: UseSearchParamOptions = {
    defaultValue: null,
    parse: (v: string) => v,
    replace: false,
    initiallyUpdateDefaultValue: false,
    resetParamsOnChange: []
}

type QueryParamValue = string | number | null

const getNextQuery = (currentQuery: ParsedQuery, paramName: string, nextQuery: QueryParamValue | ParsedQuery) => {
    if (nextQuery === null || ["string", "number"].includes(typeof nextQuery)) {
        return {
            ...currentQuery,
            [paramName]: nextQuery !== null ? String(nextQuery) : (nextQuery as null)
        }
    }

    return {...currentQuery, ...(nextQuery as object)}
}

export const useSearchParam = (name: string, opts: UseSearchParamOptions = DEFAULT_OPTIONS) => {
    const options = {
        ...DEFAULT_OPTIONS,
        ...opts
    }
    const location = useLocation()
    const history = useHistory()
    const initialRender = useRef(true)

    const query = qs.parse(location.search)
    const value = query[name]
    const normalizedValue = typeof value === "string" ? options.parse(value) : value

    const resetParamsOnChange = useDeepEqualMemo(() => options.resetParamsOnChange, [options.resetParamsOnChange])

    const handleChange = useCallback(
        (nextValue: QueryParamValue | ParsedQuery) => {
            let nextQuery = getNextQuery(query, name, nextValue)

            if (query[name] !== nextQuery[name]) {
                nextQuery = resetParamsOnChange.reduce((acc, paramName) => ({...acc, [paramName]: null}), nextQuery)
            }

            const nextUrl = qs.stringifyUrl(
                {
                    url: location.pathname,
                    query: nextQuery
                },
                {skipNull: true, skipEmptyString: true}
            )
            const navigate = options.replace || initialRender.current ? history.replace : history.push
            navigate(nextUrl)
        },
        [history.push, history.replace, location.pathname, name, options.replace, query, resetParamsOnChange]
    )

    useEffect(() => {
        if (initialRender.current) {
            if (options.initiallyUpdateDefaultValue && !value && options.defaultValue) {
                handleChange(options.defaultValue)
            }

            initialRender.current = false
        }
    }, [value, options.defaultValue, options.initiallyUpdateDefaultValue, handleChange])

    useEffect(() => {
        return () => {}
    }, [])

    return {value: normalizedValue ?? options.defaultValue, onChange: handleChange}
}
