/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react"
import {debounce, isEmpty} from "lodash"
import {KlassappTableProps} from "types/common"
import {isirService, userServiceV3, profileCampusService} from "services"
import {KlassappTableHeader, KlassappTable} from "uiKit"
import {KlassappTableHOC} from "HOC"
import styles from "./ISIR.module.css"
import {handleError, getFullName} from "helpers"
import {Model} from "Model"
import {Filter} from "./parts"
import {useTranslation} from "react-i18next"
import cx from "classnames"
import {FinancialAidStudentTabs} from "../../FinancialAidStudent/FinancialAidStudent"
import {FinancialAidStaffTabs} from "../../FinancialAidStaff"
import {routing} from "helpers/routing"
import {useModel} from "hooks"
import {useHistory} from "react-router-dom"

const verificationStatus = {
    y: "Verification",
    n: "New",
    "*": "Verification"
}

function ISIR(props: KlassappTableProps) {
    const {t} = useTranslation(["common", "financialAid"])

    const model = useModel()
    const history = useHistory()
    const [filterObject, setFilterObject] = useState<any>({})
    const [filterFormSearch, setFilterFormSearch] = useState("")
    const [schoolCodeData, setSchoolCodeData] = useState<any>(null)

    useEffect(() => {
        props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
        props.dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getListISIR},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickRowItem", func: onClickShowDetail}
        ])
        debounce(throttleOnSearchChange, 300)
    }, [])

    useEffect(() => {
        if (!schoolCodeData) {
            getProfileCampusData()
        } else {
            getListISIR()
        }
    }, [schoolCodeData])

    const getPageTitle = () => {
        return t("financialAidStaff.isir")
    }

    const getFields = () => {
        return [
            t("financialAid:isir.type"),
            t("financialAid:isir.id"),
            t("financialAid:isir.studentId"),
            t("financialAid:isir.name"),
            t("financialAid:isir.dateReceived"),
            t("financialAid:isir.status"),
            t("financialAid:isir.default")
        ]
    }

    const getColumns = () => {
        const format = "DD/MM/YYYY, hh:mm A"

        return [
            {
                title: t("financialAid:isir.type"),
                field: "type",
                sortable: true
            },
            {
                title: t("financialAid:isir.id"),
                field: "isirCode",
                classNameHeader: "",
                classNameBody: "",
                style: {minWidth: "120px"},
                sortable: true
            },
            {
                title: t("financialAid:isir.studentId"),
                field: "studentId",
                classNameHeader: "",
                classNameBody: "",
                style: {minWidth: "120px"},
                sortable: true
            },
            {
                title: t("financialAid:isir.name"),
                field: "name",
                classNameHeader: "",
                classNameBody: "",
                style: {minWidth: "180px"},
                sortable: true
            },
            {
                title: t("financialAid:isir.dateReceived"),
                field: "dateReceived",
                fieldType: "date",
                format,
                classNameHeader: "",
                classNameBody: "",
                style: {minWidth: "180px"},
                sortable: true
            },
            {
                title: t("financialAid:isir.status"),
                field: "statusHtml",
                classNameHeader: "",
                classNameBody: "",
                style: {minWidth: "180px", paddingTop: "1em"}
            },
            {
                title: t("financialAid:isir.default"),
                field: "defaultHtml",
                classNameHeader: "",
                classNameBody: "",
                style: {minWidth: "180px", paddingTop: "1em"}
            }
        ]
    }

    const getProfileCampusData = async () => {
        try {
            const {data} = await profileCampusService.getAll({
                filter: {
                    profileId: [model.profileId]
                },
                linkedObjects: true
            })
            if (data.length > 0) {
                let schoolCodes: string[] = []
                for (let i = 0; i < data.length; i++) {
                    if (data[i].schoolCodes) {
                        for (let j = 0; j < data[i].schoolCodes.length; j++) {
                            schoolCodes.push(data[i].schoolCodes[j].code)
                        }
                    }
                }

                setSchoolCodeData(schoolCodes)
            }
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
        }
    }

    const getParams = () => {
        const {page, pageSize, orderField} = props
        const params: any = {
            range: {
                page,
                pageSize
            },
            filter: {
                ...filterObject
            }
        }
        if (!isEmpty(orderField)) {
            params.orderBy = orderField.field
            params.orderDir = orderField.order
        }
        return params
    }

    const getListISIR = async (filterObjectInput?) => {
        if (!schoolCodeData) {
            return false
        }
        let params = getParams()
        if (filterObjectInput && Object.keys(filterObjectInput).length > 0) {
            params = {
                ...params,
                filter: {
                    ...params.filter,
                    ...filterObjectInput
                }
            }
        }
        if (schoolCodeData) {
            params.filter = {
                ...params.filter,
                schoolCode: schoolCodeData
            }
        }

        props.dispatch({isLoading: true})
        try {
            const {data: isirs, total} = await isirService.getList(params)
            const newISIR = isirs.map((isir) => {
                let isirJSON = JSON.parse(isir.isirJson)

                let newData: any = {
                    type: t("financialAid:financialAidStaff.isir"),
                    id: isir.id,
                    isirCode: isir.isirCode,
                    studentId: isirJSON.studentId,
                    name: getFullName({
                        firstName: isirJSON.studentFirstName,
                        middleName: isirJSON.studentMiddleNameInitial,
                        lastName: isirJSON.studentLastName
                    }),
                    email: isir.studentEmail,
                    firstName: isirJSON.studentFirstName,
                    middleName: isirJSON.studentMiddleNameInitial,
                    lastName: isirJSON.studentLastName,
                    dateReceived: isir.createdAt,
                    statusHtml: renderStatus(isirJSON.verificationFlag),
                    isDefault: isir.isDefault,
                    defaultHtml: renderDefault(isir.isDefault)
                }

                return newData
            })

            props.dispatch({data: newISIR, total})
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
        }
    }

    const renderStatus = (status: string) => {
        const statusString: string = verificationStatus[status.toLowerCase()]
        return statusString ? (
            <p className={cx(styles.status, styles[`status-${statusString.toLowerCase()}`])}>{statusString}</p>
        ) : (
            ""
        )
    }

    const renderDefault = (isDefault: boolean) => {
        return isDefault ? <p className={cx(styles.reportBox, styles.reportBoxSmall, styles.bgP4B)}>Default</p> : ""
    }

    const throttleOnSearchChange = () => {}

    const onClickShowDetail = async (inputData) => {
        const {email} = inputData

        let profileId: number | null = null

        const {data} = await userServiceV3.getAll({
            filter: {
                emails: [email]
            },
            range: {
                limit: 1,
                offset: 0
            },
            linkedObjects: true
        })

        if (data.length > 0) {
            if (data[0].profiles !== null) {
                if (data[0].profiles.length === 1) {
                    profileId = data[0].profiles[0].id
                } else {
                    for (let i = 0; i < data[0].profiles.length; i++) {
                        if (data[0].profiles[i].active === 1) {
                            profileId = data[0].profiles[i].id
                        }
                    }
                }
            }
        }

        history.push({
            pathname: routing.finAid.student.isirDetail(profileId),
            search: `?tab=${FinancialAidStudentTabs.ISIR}&isirCode=${inputData.isirCode}&profileId=${profileId}`,
            state: {
                backUrl: `/financial-aid/staff?tab=${FinancialAidStaffTabs.ISIR}`
            }
        })
    }

    useEffect(() => {
        onChangeFilter({search: filterFormSearch})
    }, [filterFormSearch])

    const updateFilter = (filterData) => {
        setFilterObject({
            ...filterObject,
            ...filterData
        })
    }

    const onChangeFilter = async (filterDataObject?) => {
        let filterObjectUpdated = filterObject
        if (filterDataObject && Object.keys(filterDataObject).length > 0) {
            filterObjectUpdated = {
                ...filterObjectUpdated,
                ...filterDataObject
            }
        }
        if ((filterFormSearch ?? "").trim() !== "") {
            filterObjectUpdated = {
                ...filterObjectUpdated,
                search: filterFormSearch
            }
        }
        getListISIR(filterObjectUpdated)
    }

    const onClearFilter = () => {
        setFilterObject({})
    }

    const {
        page,
        total,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        tableHeaderActions,
        isShowTableHeaderAction,
        isLoading,
        orderField
    } = props

    return (
        <div>
            <Filter
                onChangeFilter={onChangeFilter}
                updateFilter={updateFilter}
                onClearFilter={onClearFilter}
                filterObject={filterObject}
                setFilterFormSearch={setFilterFormSearch}
                onUploaded={getListISIR}
            />
            <div>
                <KlassappTableHeader
                    isShowAction={isShowTableHeaderAction}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={props.onChangePage}
                    onChangeRowPerPage={props.onChangeRowPerPage}
                />
                <KlassappTable
                    columns={columns}
                    menuActions={[]}
                    data={data}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    orderField={orderField}
                    isShowCheckedColumn={false}
                    onClickRowItem={onClickShowDetail}
                    onChangeFields={props.onChangeFields}
                    onUpdateRowData={props.onUpdateRowData}
                    onUpdateTableData={props.onUpdateTableData}
                    onClickSortColumn={props.onClickSortColumn}
                    onDraggableColumn={props.onDraggableColumn}
                    onChangeAllFields={props.onChangeAllFields}
                />
            </div>
        </div>
    )
}

export default KlassappTableHOC(ISIR)
