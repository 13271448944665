import BaseService from "./Base"
import {get} from "lodash"

class COAFormService extends BaseService {
    getOne = async (coaFormId) => {
        const params = {
            filter: {coaFormIds: [coaFormId]},
            linkedEntities: ["campus", "program", "rates", "periodDates"]
        }
        const {data: response} = await this.post("/v1/coa-forms/list", params)
        return response.data[0] ?? null
    }
    getAll = async (params = {}) => {
        const response = await this.post("/v1/coa-forms/list", params)
        return get(response, "data", [])
    }

    delete = async (params = {}) => {
        const response = await this.post("/v1/coa-forms/delete", params)
        return get(response, "data", {})
    }

    create = async (params = {}) => {
        const response = await this.post("/v1/coa-forms/create", params)
        return get(response, "data.data", {})
    }

    update = async (id, data, params = {}) => {
        data.coaFormId = id
        const response = await this.post("/v1/coa-forms/update", data, params)
        return get(response, "data", {})
    }

    deleteBulk = async (ids: number[]) => {
        const response = await this.post("/v1/coa-forms/delete", {coaFormId: ids})
        return get(response, "data", {})
    }
}
export default COAFormService
