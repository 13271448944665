import {get} from "lodash"
import BaseService from "./Base"

class StudentExternshipService extends BaseService {
    async updateStudentExternship(params = {}) {
        const response = await this.post("/v1/student-externship/update", params)
        return get(response, "data", {})
    }
}

export default StudentExternshipService
