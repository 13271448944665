import {BaseButton, BaseInput, BaseLoading, BasePopup} from "components"
import {useCallback, useEffect, useState} from "react"
import styles from "./EditGpaPopup.module.css"
import {FormLabel} from "components/Form"
import {handleError} from "helpers"
import {useTranslation} from "react-i18next"
import {academicCourseGradesService, academicPlansService} from "services"

type Props = {
    isShow: boolean
    onClose: () => void
    courseId: number
    percentage?: number
    termId: number
    academicStudentStatsId?: number
    academicTermStatsId?: number
    studentProfileId: number
    onRefresh: () => void
    scheduleId: number
}

export function EditAttendancePopup(props: Props) {
    const {
        isShow,
        onClose,
        onRefresh,
        termId,
        courseId,
        percentage,
        scheduleId,
        studentProfileId,
        academicStudentStatsId,
        academicTermStatsId
    } = props
    const [isLoading, setIsLoading] = useState(false)
    const [editedPercentage, setEditedPercentage] = useState<number>(0)
    const {t} = useTranslation(["common"])

    useEffect(() => {
        setEditedPercentage(percentage)
    }, [percentage])

    const onSubmit = useCallback(async () => {
        try {
            setIsLoading(true)
            if (!courseId) {
                const payload = [
                    {
                        academicTermStatsId: academicTermStatsId,
                        attendancePercentage: editedPercentage,
                        studentProfileId: studentProfileId,
                        termId
                    }
                ]
                await academicPlansService.updateAttendances({
                    attendances: payload
                })
            } else {
                const payload = [
                    {
                        academicStudentStatsId: academicStudentStatsId,
                        attendancePercentage: editedPercentage,
                        studentProfileId: studentProfileId,
                        termId,
                        courseId,
                        scheduleId
                    }
                ]
                await academicPlansService.updateAttendances({
                    attendances: payload
                })
            }
            await onRefresh()
            setIsLoading(false)
            onClose()
        } catch (err) {
            handleError(err)
            setIsLoading(false)
        }
    }, [
        courseId,
        onClose,
        academicStudentStatsId,
        onRefresh,
        editedPercentage,
        studentProfileId,
        termId,
        scheduleId,
        academicTermStatsId
    ])

    const onChangePercentage = useCallback((text) => {
        const newValue: number = parseFloat(text)
        if (!isNaN(newValue)) {
            setEditedPercentage(newValue)
        } else {
            setEditedPercentage(undefined)
        }
    }, [])

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="FILE_LINE_ALT" width="50vw">
            <div className={styles.wrap}>
                <p className={styles.title}>Edit Attendance</p>
                <FormLabel label="Edit Attendance Percentage" />
                <BaseInput
                    onChange={onChangePercentage}
                    placeholder={"Percentage"}
                    disabledArrow
                    min={0}
                    type="number"
                    max={100}
                    value={editedPercentage}
                />
                <div className={styles.submitContainer}>
                    <BaseButton
                        title={isLoading ? t("common:action.saving") : t("common:action.save")}
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={isLoading || isNaN(editedPercentage)}
                    />
                </div>
                <BaseLoading isShow={isLoading} />
            </div>
        </BasePopup>
    )
}
