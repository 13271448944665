/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect} from "react"
import {useTranslation} from "react-i18next"
import {get, isEmpty} from "lodash"
import {KlassappTableHeader, KlassappTable} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {academicCourseGradesService} from "services"

function GradeTable(props) {
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        onClickEdit,
        onClickDelete,
        pageSize,
        courseInfo,
        columns,
        termId,
        gradeTableData,
        data,
        lessonDetail,
        allFields,
        fields,
        updateUpdatedData,
        updateInitialData,
        updatedDataGradingDetail = {},
        tableHeaderActions,
        orderField,
        isLoading,
        searchDebounce,
        isShowTableHeaderAction,
        isLoadedTableFuncs,
        getCurrentPage,
        getCurrentPageSize
    } = props
    const {academicCourseGradeId} = gradeTableData || {}

    const {t} = useTranslation(["campuses"])

    const getFields = () => {
        return ["ID", "Name", "Attendance", "Grade", "Notes"]
    }

    const getPageTitle = () => {
        return t("campuses.campus")
    }

    useEffect(() => {
        if (academicCourseGradeId && courseInfo?.courseId && termId && isLoadedTableFuncs) {
            getGrades(searchDebounce && searchDebounce !== "" ? searchDebounce : undefined)
        }
    }, [academicCourseGradeId, isLoadedTableFuncs, page, pageSize, searchDebounce, lessonDetail, courseInfo, termId])

    const getColumns = () => {
        return [
            {
                title: "ID",
                field: "studentProfileId"
            },
            {
                title: "Name",
                field: "studentName"
            },
            {
                title: "Attendance",
                field: "attendanceHtml",
                headerStyle: {textAlign: "center"}
            },
            {
                title: "Grade",
                field: "grade"
            },
            {
                title: "Notes",
                field: "notes"
            }
        ]
    }

    const getMenuActions = useCallback(() => {
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: (payload) => onClickEdit(academicCourseGradeId, payload)
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: (payload) => onClickDelete(academicCourseGradeId, payload)
            }
        ]
    }, [onClickDelete, t, onClickEdit, academicCourseGradeId])

    const getParams = () => {
        const page = getCurrentPage()
        const pageSize = getCurrentPageSize()
        const params: any = {
            range: {
                pageSize,
                page
            },
            filter: {academicCourseGradeId, courseId: courseInfo?.courseId, termIds: [termId]}
        }
        if (lessonDetail) {
            params.filter.lessonIds = [lessonDetail.id]
        }
        if (!isEmpty(orderField)) {
            params.sort = {
                orderBy: orderField.field,
                orderDir: orderField.order
            }
        }
        return params
    }

    const appendDataGradingDetail = (academicCourseGradeId, newDataGradingDetail) => {
        updateUpdatedData(academicCourseGradeId, newDataGradingDetail)
        updateInitialData(academicCourseGradeId, newDataGradingDetail)
    }

    const getGrades = async (search = "") => {
        try {
            dispatch({isLoading: true})
            const params = getParams()
            if (search) {
                params.filter.search = search
            }
            const {data = [], total = 0} = await academicCourseGradesService.getLessonCourseGradeStudentsDetail(params)
            dispatch({total: total})
            appendDataGradingDetail(academicCourseGradeId, data)
        } catch (e) {
            console.log(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    useEffect(() => {
        dispatch({data: get(updatedDataGradingDetail, [academicCourseGradeId], [])})
    }, [updatedDataGradingDetail, academicCourseGradeId])

    const onClickRowItem = (row) => {
        //
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getGrades},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickRowItem", func: onClickRowItem},
            {key: "getMenuActions", func: getMenuActions}
        ])
    }, [])

    return (
        <>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                menuActions={[]}
                isLoading={isLoading}
                allFields={allFields}
                isShowCheckedColumn={false}
                orderField={orderField}
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onClickSortColumn={props.onClickSortColumn}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </>
    )
}

export default KlassappTableHOC(GradeTable)
