import {useCallback} from "react"
import {useQuery, useQueryClient} from "@tanstack/react-query"
import {shiftsService} from "services"
import {UNLIMITED_PAGE_SIZE} from "data/constants"

const queryCacheKey = "all-shifts"
const INITIAL_DATA = []

export const useAllShifts = () => {
    const queryClient = useQueryClient()

    const query = useQuery(
        [queryCacheKey],
        async () => {
            const {data: shifts} = await shiftsService.getAll({
                filter: {isActive: true},
                sort: {orderBy: "name", orderDir: "asc"},
                range: {
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE
                }
            })
            return shifts.map((item) => ({...item, id: item.shiftId}))
        },
        {
            refetchOnMount: false
        }
    )

    return {
        shifts: query.data ?? INITIAL_DATA,
        invalidateShiftsCache: useCallback(() => {
            queryClient.invalidateQueries({queryKey: [queryCacheKey]})
        }, [queryClient])
    }
}
