/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {Row, Col, Divider} from "antd"
import {Screen} from "components/ui"
import {useLocation} from "react-router-dom"
import {BaseInput} from "components/inputs"
import {KlassDropdown} from "components/Select"
import {PDFViewer} from "components/FileViewer"
import {FinancialAidStudentTabs} from "../../FinancialAidStudent"
import styles from "./FinAidNoticeDetail.module.css"
import {FinAidNoticeDetailTable} from "./parts"
import {handleError, handleErrorMessage} from "helpers"
import {useTranslation} from "react-i18next"
import moment from "moment"
import {noticeService} from "services"
import {routing} from "helpers/routing"
import {useModel} from "hooks"

export enum NoticeTypes {
    ISIR = "isir",
    OTHERS = "others"
}

export const typesMaster = [
    {
        id: "isir",
        name: "ISIR"
    }
]

function FinAidNoticeStudentDetail(props) {
    const {t} = useTranslation(["common"])
    const model = useModel()
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const studentId: number = parseInt(query.get("studentId"))
    const noticeId: number = parseInt(query.get("id"))

    const [noticeDetails, setNoticeDetails] = useState(null)

    useEffect(() => {
        getData()
    }, [])

    const getParams = () => {
        const params: any = {
            filter: {
                noticeId,
                profileId: studentId
            },
            linkedObjects: true
        }
        return params
    }

    const getData = async () => {
        const params = getParams()
        try {
            // fetch notice details
            const result = await noticeService.getList(params)
            if (!result.data || result.data.length === 0) {
                handleErrorMessage("Notice details not found")
                return false
            }

            let noticeDetails = result.data[0]

            if (noticeDetails.type === NoticeTypes.ISIR && (process.env.REACT_APP_ISIR_WEB_BASE_URL as string)) {
                // isir
                const url = new URL(`/`, process.env.REACT_APP_ISIR_WEB_BASE_URL as string)
                url.searchParams.append("isirCode", noticeDetails.code)
                url.searchParams.append("xProfileId", props.model.profileId.toString())
                url.searchParams.append("token", props.model.authToken)
                url.searchParams.append("pdf", "")

                noticeDetails.documentUrl = url.href
            }

            setNoticeDetails(noticeDetails)
        } catch (e) {
            handleError(e)
        }
    }

    return (
        <Screen
            htmlTitle="Notice"
            header={{
                title: "Notice",
                backUrl: routing.finAid.student.index(studentId, FinancialAidStudentTabs.Notices)
            }}>
            <Row>
                <Col span={8}>
                    <div className={`${styles.itemBodyWrap} ${styles.itemBodyMargin}`}>
                        <p className={styles.bodyTitle}>Type</p>
                        <KlassDropdown
                            options={typesMaster}
                            value={
                                noticeDetails ? typesMaster.filter((item) => item.id === noticeDetails.type)[0] : null
                            }
                            onChange={() => {}}
                            placeholder={t("isir.type")}
                            readOnly={true}
                        />
                    </div>
                </Col>
                <Col span={16}>
                    <div className={`${styles.itemBodyWrap}`}>
                        <p className={styles.bodyTitle}>Description</p>
                        <BaseInput
                            onChange={() => {}}
                            placeholder="Description"
                            value={noticeDetails ? noticeDetails.description : null}
                            disabled={true}
                        />
                    </div>
                </Col>
            </Row>
            <FinAidNoticeDetailTable noticeDetails={noticeDetails} {...props} />
            {noticeDetails && (
                <>
                    <Divider className={styles.divider} />
                    {noticeDetails.documentUrl && <PDFViewer fileSrc={noticeDetails.documentUrl} />}
                    <p className={styles.footerInfo}>
                        Created on{" "}
                        <span className={styles.footerInfo__bold}>
                            {moment(noticeDetails.createdAt).format("DD/MM/YYYY")}
                        </span>
                        .{" "}
                        {noticeDetails.updatedByProfile && (
                            <span>
                                Last modified on{" "}
                                <span className={styles.footerInfo__bold}>
                                    {moment(noticeDetails.updatedAt).format(userDateTimeFormat)}
                                </span>{" "}
                                by
                                <span className={styles.footerInfo__bold}>
                                    {" "}
                                    {noticeDetails.updatedByProfile.fullName}
                                </span>
                            </span>
                        )}
                    </p>
                </>
            )}
        </Screen>
    )
}

export default FinAidNoticeStudentDetail
