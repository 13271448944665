import React, {useState} from "react"
import {Popover, Tooltip, Button} from "antd"
import moment from "moment"
import {get} from "lodash"
import {Icon} from "components/Icon"
import {getColorContrast} from "helpers"
import styles from "./EventContent.module.css"
import {useModel} from "hooks"
import {getScheduleEventMethod, getScheduleEventType, getRecurringInfo} from "helpers/calendar"

export function EventContent(props) {
    const model = useModel()
    const userTimeFormat = model.getUserTimeFormat()
    const [isShowEventPreview, setIsShowEventPreview] = useState(false)
    const {eventInfo, clickInfo, handleShowEventPreview} = props
    const eventData = eventInfo?.event
    const backgroundColor = get(eventData, "backgroundColor", "#1e90ff")
    const {
        startDateTime,
        endDateTime,
        description,
        isConflict,
        submodule,
        instructors,
        rrule,
        isHolidayBreak,
        course,
        json_data
    } = eventData?.extendedProps || {}

    const closeEventPreview = () => {
        setIsShowEventPreview(false)
        setTimeout(() => {
            handleShowEventPreview(false)
            // To trigger handleDateClick event happen before close
        }, 300)
        clickInfo?.el?.parentElement?.classList?.remove(styles.highlightEventPreviewWrap)
        clickInfo?.el?.classList?.remove(styles.highlightEventPreview)
    }

    const onEventPreviewChange = (visible) => {
        if (!isHolidayBreak) {
            if (!visible) {
                clickInfo?.el?.parentElement?.classList?.remove(styles.highlightEventPreviewWrap)
                clickInfo?.el?.classList?.remove(styles.highlightEventPreview)
            } else {
                clickInfo?.el?.parentElement?.classList?.add(styles.highlightEventPreviewWrap)
                clickInfo?.el?.classList?.add(styles.highlightEventPreview)
            }
            setIsShowEventPreview(visible)
            setTimeout(() => {
                handleShowEventPreview(visible)
                // To trigger handleDateClick event happen before close
            }, 300)
        }
    }

    const renderHeader = () => {
        return (
            <div className={styles.eventPreviewHeader}>
                <Tooltip
                    title="Close"
                    placement="bottom"
                    color="rgba(97,97,97,0.902)"
                    overlayInnerStyle={{minHeight: 27}}
                    overlayClassName={styles.tooltipCard}>
                    <Button
                        className={styles.eventPreviewHeaderBtn}
                        icon={<Icon icon="CLOSE" color="#5f6368" className={styles.eventPreviewHeaderIcon} />}
                        onClick={closeEventPreview}
                    />
                </Tooltip>
            </div>
        )
    }

    const renderTitle = () => {
        return (
            <div className={styles.eventPreviewContentRow}>
                <div className={styles.eventPreviewContentLeft}>
                    <div className={styles.eventPreviewType} style={{background: backgroundColor}}></div>
                </div>
                <div className={styles.eventPreviewInfo}>
                    <p className={styles.eventPreviewTitle}>{eventData.title || ""}</p>
                    <p className={styles.submoduleNamePreview}>
                        {course.name} ({course.code})
                    </p>
                    <p className={styles.submoduleNamePreview}>
                        {(instructors || []).map((instructor) => instructor.fullName).join(", ")}
                    </p>
                    {submodule?.title && <p className={styles.submoduleNamePreview}>{submodule.title}</p>}
                    <div className={styles.eventPreviewDate}>
                        <span>{moment(startDateTime).format("dddd, MMMM DD")}</span>
                        <span className={styles.eventPreviewDate__dot}>⋅</span>
                        <span>
                            {moment(startDateTime).format(userTimeFormat)} -{" "}
                            {moment(endDateTime).format(userTimeFormat)}
                        </span>
                    </div>
                    <div className={styles.eventPreviewInfo}>{getRecurringInfo(rrule)}</div>
                </div>
            </div>
        )
    }

    const renderEventInfo = () => {
        const {eventMethod, eventType} = json_data || {}
        return (
            <div className={styles.eventPreviewContentRow}>
                <div className={styles.eventPreviewContentLeft}>
                    <Icon className={styles.infoIcon} icon="EVENT" />
                </div>
                <div className={styles.eventPreviewInfo}>
                    <div className={styles.eventPreviewTitle}>
                        {getScheduleEventMethod(eventMethod)} - {getScheduleEventType(eventType)}
                    </div>
                </div>
            </div>
        )
    }

    const renderDescription = (description) => {
        if (!description) {
            return null
        }
        return (
            <div className={styles.eventPreviewContentRow}>
                <div className={styles.eventPreviewContentLeft}>
                    <Icon className={styles.infoIcon} icon="NOTES" />
                </div>
                <div className={styles.eventPreviewInfo}>
                    <div
                        className={styles.eventPreviewTitle}
                        dangerouslySetInnerHTML={{
                            __html: description
                        }}
                    />
                </div>
            </div>
        )
    }

    const renderEventPreviewContent = () => {
        if (!eventData) return null
        return (
            <div className={styles.eventPreviewWrap}>
                {renderHeader()}
                <div className={styles.eventPreviewContent}>
                    {renderTitle()}
                    {renderEventInfo()}
                    {renderDescription(description)}
                </div>
            </div>
        )
    }

    if (isConflict) {
        return (
            <div className={styles.warningIconWrap}>
                <Icon icon="WARNING_TRIANGLE_FILL" className={styles.warningIcon} />
            </div>
        )
    }

    return (
        <Popover
            open={isShowEventPreview}
            content={() => renderEventPreviewContent()}
            trigger="click"
            onOpenChange={onEventPreviewChange}
            placement="left">
            <div className={styles.eventContent}>
                <p className={styles.eventTitle} style={{color: getColorContrast(backgroundColor).color}}>
                    {eventData.title || ""}
                </p>
                {!isHolidayBreak && (
                    <>
                        <p className={styles.eventTime} style={{color: getColorContrast(backgroundColor).color}}>
                            {course.name}
                        </p>
                        {submodule?.title && (
                            <p className={styles.eventTime} style={{color: getColorContrast(backgroundColor).color}}>
                                {submodule.title}
                            </p>
                        )}
                    </>
                )}
            </div>
        </Popover>
    )
}
