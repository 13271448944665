import {Campus} from "./campus"
import {DegreeLevel} from "./degreeLevel"
import {Major} from "./major"
import {StudentStatus} from "./students"
import {api_response} from "lib/gate/interfaces"

export namespace Retention {
    export type RetentionCampus = {
        campusId: number
        campusName: string
        campusCode: string
    }

    export type RetentionDegreeLevel = {
        degreeLevelId: number
        degreeLevelName: string
        degreeLevelCode: string
    }

    export type RetentionProgram = {
        programId: number
        programName: string
        programCode: string
    }

    export type MeasurementSetting = {
        riskLevel: RiskLevel
        rangeFrom: number | string
        rangeTo: number | string
    }

    export type RetentionMeasurementSetting = {
        [measurementId: number]: MeasurementSetting[]
    }

    export type RetentionEntity = {
        id: number
        code: string
        name: string
        campusIds: number[]
        degreeLevelIds: number[]
        programIds: number[]
        measurements?: RetentionMeasurementSetting
        elementTypes?: string[]
    }

    export type Retention = RetentionEntity & {
        campuses: RetentionCampus[]
        degreeLevels: RetentionDegreeLevel[]
        programs: RetentionProgram[]
    }

    export type RetentionDetail = Partial<RetentionEntity> & {
        campuses: Campus.Campus
        degreeLevels: DegreeLevel.DegreeLevel
        programs: Major.Major[]
    }

    export enum MeasurementElementType {
        Grade = "grade",
        Attendance = "attendance"
    }

    export type Measurement = {
        id: number
        elementType: MeasurementElementType
        name: string
    }

    export enum RiskLevel {
        Good = "good",
        Low = "low",
        Medium = "medium",
        High = "high",
        Failed = "failed"
    }

    export type RetentionMeasurement = {
        id?: number
        retentionId?: number
        measurementId: number
        riskLevel: RiskLevel
        rangeFrom: number
        rangeTo: number
    }

    export type RetentionStatItem = {
        total: number
        gap: number
        percentage: number
        gapPercentage: number
    }

    export type CategoryStatItem = RetentionStatItem & {
        name: string
    }

    export type RiskItem = {
        total: number
        prev30Days: number
    }

    export type RiskGroupItem = {
        [measurementName: string]: RiskItem
    }

    export type RiskStatItem = RiskItem & {
        grade: RiskGroupItem
        attendance: RiskGroupItem
    }

    export type RiskStats = {
        totalActive: number
        data: {
            [riskLevel: string]: RiskStatItem
        }
    }

    export enum RetentionChartType {
        Gender = "gender",
        Campus = "campus",
        Ethnic = "ethnic",
        Program = "program"
    }

    export type AcademicStage = {
        [stageName: string]: StudentStatus[]
    }

    export type RetentionAcademicStats = {
        [stageName: string]: number
    }
    export type RiskStatsByEntity = {
        entity: Campus.Campus | api_response.Program
        stats: RiskStats
    }
}

export enum AcademicStageType {
    Active = "stage1",
    Probation = "stage2",
    LOA = "stage3",
    Graduate = "stage4"
}
