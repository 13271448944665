/* eslint-disable react-hooks/exhaustive-deps */
import {Communication} from "types/communication"
import cx from "classnames"
import styles from "./ReplyMessage.module.css"
import linkifyHtml from "linkify-html"
import * as DOMPurify from "dompurify"
import linkifyStr from "linkify-string"
import {useMemo} from "react"

type Props = {
    message: Communication.ReplyMessage
    containerStyle?: string
    onClick?: (e: any) => void
}

export function ReplyMessage(props: Props) {
    const {message, containerStyle, onClick} = props

    /**
     * NOTE
     * New lib "linkify-string" was used to fix showing special characters, issue reported at
     * https://github.com/Hypercontext/linkifyjs/issues/338
     */

    const linkifyText = useMemo(
        () =>
            message.withEmail || message.withSms
                ? linkifyHtml(message.content || "")
                : linkifyStr(message.content || ""),
        [message]
    )

    return (
        <div className={cx(styles.container, containerStyle)} onClick={onClick}>
            <div className={styles.heading}>
                <div className={styles.info}>
                    {message.authorFullName && <span className={styles.fullName}>{message.authorFullName}</span>}
                </div>
            </div>
            <div className={styles.main}>
                <div className={styles.content} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(linkifyText)}} />
                {message.content === "" && <span className={styles.attached}>Attached file</span>}
            </div>
        </div>
    )
}
