import {get} from "lodash"
import BaseService from "./Base"

class ResourceServiceV3 extends BaseService {
    async list(params = {}) {
        const {data: response} = await this.post("/v3/resources/list", params)
        if (!response.success) {
            throw response
        }

        return {
            data: response.data,
            total: response.total
        }
    }

    async getOne(resourceId: number) {
        const {data: response} = await this.post("/v3/resources/list", {
            filter: {
                id: [resourceId]
            },
            linkedEntities: true
        })

        if (!response.success) {
            throw response
        }

        return response.data[0] ?? null
    }

    async create(data: any) {
        const response = await this.post("/v3/resources/create", data)
        return get(response, "data", [])
    }

    updateResource = async (payload: any) => {
        const {data: response} = await this.post("/v3/resources/edit", payload)

        if (!response.success) {
            throw response
        }
    }

    async deleteBulk(id: number[]) {
        const {data: response} = await this.post(`/v3/resources/delete`, {id})
        if (!response.success) {
            throw response
        }
    }

    async duplicate(id: number[]) {
        const {data: response} = await this.post(`/v3/resources/duplicate`, {id})
        if (!response.success) {
            throw response
        }
    }

    async getMaxId() {
        const {data: response} = await this.post("/v3/resources/get-max-id", {})
        if (!response.success) {
            throw response
        }
        return response.data
    }

    async uploadFile(data = {}) {
        const response = await this.post("/v3/resources/upload", data)
        return get(response, "data.data", {})
    }

    async getResourceHistories(resourceId: number, params = {}) {
        const response = await this.get(`/v1/resource-history/${resourceId}`, params)
        return get(response, "data.data", {})
    }
}

export default ResourceServiceV3
