import React from "react"
import classNames from "classnames"
import styles from "./item.module.css"
import {RemarkIndicator} from "./RemarkIndicator"

type Props = {
    field: any
    onChangeRemarkField: (fieldId: string, comment: string) => void
}

export function Text(props: Props) {
    const {field, onChangeRemarkField} = props
    const {width, height} = field.placeInformation

    return (
        <RemarkIndicator field={field} onChangeRemarkField={onChangeRemarkField}>
            <div
                className={styles.actionItem}
                style={{
                    width,
                    height,
                    backgroundColor: `var(--primary-100)`,
                    borderColor: `var(--primary-400-base)`
                }}>
                <div
                    className={classNames(styles.inputWrap, {
                        [styles.smallInput]: height <= 30,
                        [styles.superSmallInput]: height <= 14
                    })}>
                    {field.value}
                </div>
            </div>
        </RemarkIndicator>
    )
}
