import React from "react"
import styles from "./StudentStatusLabel.module.css"
import {StudentStatus} from "types/students"
import {useTranslation} from "react-i18next"

type Props = {
    status: StudentStatus
}

const DEFAULT_STATUS_COLOR = "#939393"

export function StudentStatusLabel({status: {hexColor = DEFAULT_STATUS_COLOR, name, state}}: Props) {
    const {t} = useTranslation(["user"])

    return (
        <div className={styles.statusContainer}>
            <span className={styles.status} style={{background: hexColor}}>
                {`${name} ${state ? `[${t(`user.studentStateType.${state}`)}]` : ""}`}
            </span>
        </div>
    )
}
