import {get} from "lodash"
import BaseService from "./Base"

class SettingActivityGroupService extends BaseService {
    async list(params = {}) {
        const response = await this.post("/v1/settings/activity-groups/list", params)
        return get(response, "data", [])
    }

    async create(params: any = {}) {
        const response = await this.post("/v1/settings/activity-groups/create", params)
        return get(response, "data.data", {})
    }

    async update(params = {}) {
        const response = await this.post(`/v1/settings/activity-groups/edit`, params)
        return get(response, "data.data", {})
    }

    async delete(params = {}) {
        const response = await this.post(`/v1/settings/activity-groups/delete`, params)
        return get(response, "data", {})
    }
}

export default SettingActivityGroupService
