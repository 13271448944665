export enum ApiErrorType {
    GenericError = "GenericError",
    ValidationError = "ValidationError"
}

export type ApiSuccessResponse<T> = {
    success: true
    data: T
}

export type ApiListSuccessResponse<T> = ApiSuccessResponse<T> & {
    total: number
}

export type ApiValidationError = {
    errorType: ApiErrorType.ValidationError
    message: string
    error: unknown[]
}

export type ApiGenericError = {
    errorType: ApiErrorType.GenericError
    message: string
}

export type ApiErrorDetails = ApiValidationError | ApiGenericError

export type ApiErrorResponse = {
    success: false
} & ApiErrorDetails

export type ApiListResponse<T = any> = ApiListSuccessResponse<T> | ApiErrorResponse
export type ApiResponse<T = any> = ApiSuccessResponse<T> | ApiErrorResponse

export type ApiListResponseResult<T> = {
    total: number
    data: T
}
