import React from "react"
import {useTranslation} from "react-i18next"
import {Tabs} from "antd"
import {useActiveTab} from "hooks"
import Disbursements from "sections/FinancialAid/FinancialAidStudent/parts/FinAidAward/Disbursement/Disbursements"
import DisbursementResponsesTable from "sections/FinancialAid/FinancialAidStudent/parts/FinAidAward/Disbursement/DisbursementResponsesTable"
import {TabHeader} from "components/Tab"

const {TabPane} = Tabs

enum Tab {
    Transactions = "transactions",
    Responses = "responses"
}

type Props = {}

const DisbursementTab: React.FC<Props> = () => {
    const {t} = useTranslation(["financialAid", "common"])
    const [tab, setTab] = useActiveTab(Tab.Transactions, "disbtab")

    return (
        <div>
            <Tabs className="fullwidth klassSubTab" activeKey={tab} onChange={setTab}>
                <TabPane tab={<TabHeader title={t(`disbursement.tab.${Tab.Transactions}`)} />} key={Tab.Transactions}>
                    <Disbursements />
                </TabPane>
                <TabPane tab={<TabHeader title={t(`disbursement.tab.${Tab.Responses}`)} />} key={Tab.Responses}>
                    <DisbursementResponsesTable />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default DisbursementTab
