import {useQuery} from "@tanstack/react-query"
import {settingLeadTypesService} from "services"

const INITIAL_DATA = []

export const useAllLeadTypes = () => {
    const leadTypesQuery = useQuery(
        ["lead-types"],
        async () => {
            const {data} = await settingLeadTypesService.getAllActive()
            return data.map((item) => ({
                ...item,
                id: item.leadTypeId
            }))
        },
        {}
    )

    return {
        leadTypes: leadTypesQuery.data ?? INITIAL_DATA,
        isLoading: leadTypesQuery.isFetching,
        fetched: leadTypesQuery.isFetched
    }
}
