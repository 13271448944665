import React, {useState} from "react"
import moment, {Moment} from "moment-timezone"
import {head, isEmpty} from "lodash"
import {Tooltip} from "antd"
import {Major} from "types/major"
import {Icon} from "components/Icon"
import {useVisible} from "hooks"
import {AddMultiCoursesPopup} from "../AddCoursePopup"
import {ConfirmRemoveCoursePopup} from "../ConfirmRemoveCoursePopup"
import {AddMultiEventPopup} from "../AddEventPopup"
import {TimeContent} from "./TimeContent"
import {DotContent} from "./DotContent"
import {DaysInfo} from "./DaysInfo"
import styles from "./CourseItem.module.css"
import {getColorContrast} from "helpers"

type Props = {
    group: Major.GroupCourseSequence
    times: string[]
    eventData: Record<string, Major.EventSequence[]>
    prevCourseDay: number
    extraData: any
    termDetail: any
    workdays: Moment[]
    versionData?: Major.MajorVersion
    programTermTemplateId?: number
    termCalendarBaseId?: number
    isInMajorVersion: boolean
    isInTermSettingPreview: boolean
    isInAcademicPlan: boolean
    courseDisabled: boolean
    studentProfileId?: number
    majorVersionId: number
    campusId: number
    reloadView: () => void
}

export function MultiCourseItems(props: Props) {
    const {
        group,
        times,
        eventData,
        prevCourseDay,
        extraData,
        termDetail,
        workdays,
        versionData,
        isInMajorVersion,
        isInTermSettingPreview,
        isInAcademicPlan,
        courseDisabled,
        studentProfileId,
        majorVersionId,
        programTermTemplateId,
        termCalendarBaseId,
        campusId,
        reloadView
    } = props
    const {courses} = group
    const [eventPopupData, setEventPopupData] = useState(null)
    const {provided} = extraData
    const addMultiCoursePopup = useVisible()
    const addEventPopup = useVisible()
    const confirmRemoveCoursePopup = useVisible()
    const CELL_WIDTH = 78

    const onClickDeleteCourse = () => {
        confirmRemoveCoursePopup.open()
    }

    const onClickEditCourse = () => {
        addMultiCoursePopup.open()
    }

    const onClickAddEvent = (eventInfo, {time: startTime, day}) => {
        if (isInAcademicPlan && !eventInfo) {
            // dont allow add event in academic
            return
        }
        if (eventInfo) {
            setEventPopupData(eventInfo)
        } else if (!isInAcademicPlan) {
            setEventPopupData({
                startTime: startTime,
                endTime: moment(startTime).add(1, "hour"),
                daysOfCourses: [{id: day, name: prevCourseDay + day}]
            })
        }
        addEventPopup.open()
    }

    const getCourseTitle = (course) => {
        if (isInMajorVersion) {
            return `[${course.code}] ${course.name}`
        }
        return `${course.name} ${course.schedule?.schedule_suffix ? `(${course.schedule.schedule_suffix})` : ""} `
    }

    const renderCourseHeader = () => {
        if (isEmpty(courses)) {
            return null
        }
        const {color} = getColorContrast(group?.color)
        const title = courses.map((course) => getCourseTitle(course)).join(", ")
        return (
            <div
                className={styles.courseItem}
                style={{width: `${group.numberOfDays * CELL_WIDTH}px`, background: group?.color}}
                {...provided.dragHandleProps}>
                <div className={styles.courseNameWrap}>
                    <Tooltip placement="top" title={title}>
                        <span className={styles.courseName} style={{color}}>
                            {title}
                        </span>
                    </Tooltip>
                    {courseDisabled && (
                        <Tooltip title="Course is not available">
                            <div>
                                <Icon
                                    icon="WARNING_TRIANGLE_FILL"
                                    color="#ff8a38"
                                    className={styles.courseDisabledIcon}
                                />
                            </div>
                        </Tooltip>
                    )}
                </div>
                {isInMajorVersion && (
                    <div className={styles.courseAction}>
                        <div className={styles.editCourseBtn} onClick={onClickEditCourse}>
                            <Icon icon="EDIT_LINE" className={styles.actionIcon} />
                        </div>
                        <div className={styles.deleteCourseBtn} onClick={onClickDeleteCourse}>
                            <Icon icon="DELETE" className={styles.actionIcon} />
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const renderDotInfo = () => {
        const dates = (workdays || []).slice(prevCourseDay, group.numberOfDays + prevCourseDay)

        return (
            <>
                <DaysInfo dates={dates} />
                <DotContent prevCourseDay={prevCourseDay} dateRange={group.numberOfDays + 1} />
            </>
        )
    }

    return (
        <div>
            {renderCourseHeader()}
            {!isEmpty(termDetail) ? (
                renderDotInfo()
            ) : (
                <DotContent prevCourseDay={prevCourseDay} dateRange={group.numberOfDays + 1} />
            )}
            <TimeContent
                times={times}
                dateRange={group.numberOfDays + 1}
                eventData={eventData}
                prevCourseDay={prevCourseDay}
                isMultiCourse
                onClickAddEvent={onClickAddEvent}
            />
            {addMultiCoursePopup.isVisible && (
                <AddMultiCoursesPopup
                    isShow={addMultiCoursePopup.isVisible}
                    onClose={addMultiCoursePopup.close}
                    group={group}
                    versionData={versionData}
                    isInMajorVersion={isInMajorVersion}
                    isInTermSettingPreview={isInTermSettingPreview}
                    isInAcademicPlan={isInAcademicPlan}
                    studentProfileId={studentProfileId}
                    majorVersionId={majorVersionId}
                    programTermTemplateId={programTermTemplateId}
                    termCalendarBaseId={termCalendarBaseId}
                    reloadView={reloadView}
                />
            )}
            {addEventPopup.isVisible && (
                <AddMultiEventPopup
                    isShow={addEventPopup.isVisible}
                    onClose={addEventPopup.close}
                    group={group}
                    eventData={eventPopupData}
                    prevCourseDay={prevCourseDay}
                    isInMajorVersion={isInMajorVersion}
                    isInTermSettingPreview={isInTermSettingPreview}
                    isInAcademicPlan={isInAcademicPlan}
                    workdays={workdays}
                    campusId={campusId}
                    reloadView={reloadView}
                />
            )}
            <ConfirmRemoveCoursePopup
                isShow={confirmRemoveCoursePopup.isVisible}
                onClose={confirmRemoveCoursePopup.close}
                groupId={group.groupId}
                isInMajorVersion={isInMajorVersion}
                isInAcademicPlan={isInAcademicPlan}
                isInTermSettingPreview={isInTermSettingPreview}
                reloadView={reloadView}
            />
        </div>
    )
}
