/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useState} from "react"
import {useStripe, useElements} from "@stripe/react-stripe-js"
import {getBaseUrl} from "helpers/common"
import {CreditCardBox, AddCreditBox} from "../"
import {paymentsService} from "services"

export const Payment = ({intentId}) => {
    const [amountYouWantToPay, setAmountYouWantToPay] = useState<number>(0)
    const [amountYouHaveToPay, setAmountYouHaveToPay] = useState<number>(0)
    const stripe = useStripe()
    const elements = useElements()
    const returnUrl = getBaseUrl()

    const updateIntent = useCallback(async () => {
        await paymentsService.updateIntent({
            intentId,
            amountInCents: Math.floor(amountYouHaveToPay * 100)
        })
    }, [paymentsService, amountYouHaveToPay, intentId])

    const handlePayment = async (event) => {
        event.preventDefault()

        if (!stripe || !elements) {
            return
        }

        await updateIntent()

        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${returnUrl}/settings/sms`
            }
        })

        if (result.error) {
            console.log(result.error.message)
        } else {
            console.log(result)
        }
    }

    return (
        <>
            <CreditCardBox />
            <AddCreditBox
                amountYouWantToPay={amountYouWantToPay}
                amountYouHaveToPay={amountYouHaveToPay}
                onAmountYouWantToPayChange={setAmountYouWantToPay}
                onAmountYouHaveToPayChange={setAmountYouHaveToPay}
                onStart={handlePayment}
            />
        </>
    )
}
