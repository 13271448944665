import {AxiosResponse} from "axios"
import {get} from "lodash"
import {ApiListResponse, ApiListResponseResult, ApiResponse} from "types/api"
import {
    Department,
    DepartmentEditPayload,
    DepartmentsCreateOnePayload,
    DepartmentsDeletePayload,
    DepartmentWithLinkedEntities,
    UpdateApplicationAcceptanceAutoStatusPayload
} from "types/departments"
import {Auth} from "types/auth"
import BaseService from "./Base"
import {StudentStatus} from "types/students"

class DepartmentsService extends BaseService {
    getMaxId = async (): Promise<number> => {
        const {data: response}: AxiosResponse<ApiResponse<number>> = await this.post("/v1/departments/get-max-id", {})

        if (!response.success) {
            throw response
        }

        return response.data
    }

    list = async (payload = {}): Promise<ApiListResponseResult<Department[]>> => {
        const {data: response}: AxiosResponse<ApiListResponse<Department[]>> = await this.post("/v1/departments/list", {
            ...payload,
            linkedEntities: false
        })

        if (!response.success) {
            throw response
        }

        return response
    }

    getAll = async (payload = {}): Promise<ApiListResponseResult<DepartmentWithLinkedEntities[]>> => {
        const {data: response}: AxiosResponse<ApiListResponse<DepartmentWithLinkedEntities[]>> = await this.post(
            "/v1/departments/list",
            {
                ...payload,
                linkedEntities: true
            }
        )

        if (!response.success) {
            throw response
        }

        return response
    }

    getOne = async (departmentId: number): Promise<DepartmentWithLinkedEntities | null> => {
        const {data: response}: AxiosResponse<ApiListResponse<DepartmentWithLinkedEntities[]>> = await this.post(
            "/v1/departments/list",
            {
                filter: {
                    departmentIds: [departmentId]
                },
                linkedEntities: true
            }
        )

        if (!response.success) {
            throw response
        }

        const [department = null] = response.data
        return department
    }

    createBulk = async (items: DepartmentsCreateOnePayload[]): Promise<Department[]> => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/departments/create", items)

        if (!response.success) {
            throw response
        }

        return response.data
    }

    edit = async (payload: DepartmentEditPayload): Promise<Department | null> => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/departments/edit", payload)

        if (!response.success) {
            throw response
        }

        return response.data
    }

    updateApplicationAcceptanceAutoStatus = async (
        payload: UpdateApplicationAcceptanceAutoStatusPayload
    ): Promise<Department | null> => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post(
            "/v1/departments/upload-application-acceptance-auto-status-id",
            payload
        )

        if (!response.success) {
            throw response
        }

        return response.data
    }

    deleteBulk = async (departmentIds: number[]): Promise<void> => {
        const payload: DepartmentsDeletePayload = {departmentIds}
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/departments/delete", payload)

        if (!response.success) {
            throw response
        }
    }

    searchDirectors = async (
        search: string,
        params = {}
    ): Promise<ApiListResponseResult<Auth.UserProfileShortDetails[]>> => {
        const response = await this.post("/v1/departments/directors/list", {
            filter: {
                search
            },
            range: {
                page: 1,
                pageSize: 20
            },
            ...params
        })
        return get(response, "data", {})
    }

    getAllForChat = async (payload = {}): Promise<ApiListResponseResult<Array<any>>> => {
        const {data: response}: AxiosResponse<ApiListResponse<Array<any>>> = await this.post(
            "/v1/departments/list-for-chat",
            {
                ...payload
            }
        )

        if (!response.success) {
            throw response
        }

        return response
    }
}

export default DepartmentsService
