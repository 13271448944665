import React, {useEffect, useState} from "react"
import classNames from "classnames"
import {Popover, Button} from "antd"
import {Icon} from "components/Icon"
import styles from "./TermStep.module.css"
import {AcademicPlans} from "types/academicPlans"
import moment from "moment-timezone"
import {formatDecimalWithoutRound} from "helpers"

type Props = {
    index: number
    term: AcademicPlans.TermCourseStats
    isLastCompletedItem: boolean
    onChangeActiveTerm?: (term: AcademicPlans.TermCourseStats) => void
}

export function TermStepItem(props: Props) {
    const {term, isLastCompletedItem, onChangeActiveTerm} = props
    const gpa = typeof term.gpa === "number" ? formatDecimalWithoutRound(term.gpa) : term.gpa
    const {attendancePercentage = 0, totalProgress = 0} = term
    const progress = Math.min(totalProgress, 100)
    const totalAttendancePercentage = Math.min(attendancePercentage, 100)
    const isCompleted = totalProgress === 100
    const onClickGoTermDetail = () => {
        onChangeActiveTerm && onChangeActiveTerm(term)
    }

    const content = (
        <div className={styles.termPopupWrap}>
            <p className={styles.termPopupTitle}>Term {term.title}</p>
            {isCompleted ? (
                <div className={styles.termPopupItem}>
                    <Icon className={styles.termPopupItem__icon} icon="COMPLETE" />
                    <p className={styles.termPopupItem__text}>Completed</p>
                </div>
            ) : (
                <div className={styles.termPopupProgressItem}>
                    <div className={styles.termPopupProgressWrap}>
                        <p className={styles.termPopupProgress__text}>Progress</p>
                        <p className={styles.termPopupProgress__progressValue}>{Math.round(progress)}%</p>
                    </div>
                    <div className={styles.progressBarWrap}>
                        <div className={styles.progressBarValue} style={{width: `${Math.round(progress)}%`}}></div>
                    </div>
                </div>
            )}
            <div className={styles.termPopupItem} style={{marginBottom: 15}}>
                <Icon className={styles.termPopupItem__icon} icon="PERSON_FILL" />
                <p className={styles.termPopupItem__text}>Attendance</p>
                <p className={styles.termPopupItem__score}>{Math.round(totalAttendancePercentage)}%</p>
            </div>
            <div className={styles.termPopupItem}>
                <Icon className={styles.termPopupItem__icon} icon="COURSE" />
                <p className={styles.termPopupItem__text}>GPA</p>
                <p className={styles.termPopupItem__score}>{gpa}</p>
            </div>
            <div className={styles.detailBtnWrap}>
                <Button className={styles.detailBtn} onClick={onClickGoTermDetail}>
                    Term Detail
                </Button>
            </div>
        </div>
    )

    return (
        <Popover content={content}>
            <div className={styles.termStepItem} onClick={onClickGoTermDetail}>
                <p className={styles.termItemTitle}>{term.title}</p>
                <div className={styles.termItemStatusWrap}>
                    <div className={classNames(styles.termItemLine, isCompleted ? styles.termItemCompletedLine : "")} />
                    {isCompleted ? (
                        <div className={styles.termItemStatusCompleted}>
                            <Icon icon="TICK" className={styles.termItemStatusCompletedIcon} color="#18a957" />
                        </div>
                    ) : (
                        <div className={styles.termItemStatus}>
                            <div className={styles.termItemStatusInCompleted}></div>
                        </div>
                    )}
                    {isLastCompletedItem && (
                        <div className={styles.currentTermItemWrap}>
                            <div className={styles.currentTermItemStatus}>
                                <div className={styles.currentTermItemStatusWhite}></div>
                            </div>
                            <p className={styles.currentTermItemDate}>{moment().format("MMMM")}</p>
                        </div>
                    )}
                </div>
                {gpa ? <p className={styles.termItemGpa}>({gpa})</p> : <p className={styles.tempGpa}></p>}
            </div>
        </Popover>
    )
}
