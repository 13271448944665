/* eslint-disable react-hooks/exhaustive-deps */
import debounce from "debounce-promise"
import React from "react"
import {useTranslation} from "react-i18next"
import {studentActivitiesService} from "services"
import {KlassDropdownAsync, KlassDropdownAsyncProps} from "../Select"
import {StudentActivity} from "types/activity"
import groupBy from "lodash/groupBy"

type Props = Partial<KlassDropdownAsyncProps> & {
    profileId: number
    selectedStudentActivityIds?: number[]
}

const StudentActivitySelect: React.FC<Props> = ({profileId, selectedStudentActivityIds = [], ...props}) => {
    const {t} = useTranslation(["common"])

    const search = React.useCallback(
        debounce(async (search: string = "") => {
            const {data} = await studentActivitiesService.autocompleteActivities({
                search,
                studentProfileId: profileId
            })
            const dataFiltered = data.filter((item) => !selectedStudentActivityIds.includes(item.studentActivityId))

            return Object.entries(groupBy(dataFiltered, "departmentId")).map(([departmentId, items]) => ({
                label: t(`common:moduleName.${departmentId}`),
                options: items
            }))
        }, 500),
        [profileId]
    )

    return (
        <KlassDropdownAsync
            {...props}
            placeholder={props.placeholder || t("selectField.placeholder")}
            loadOptions={search}
            defaultOptions={true}
            valueKey="studentActivityId"
            labelKey="name"
            getOptionLabel={(option: StudentActivity) => option.name}
        />
    )
}

export default StudentActivitySelect
