import React from "react"
import classNames from "classnames"
import {AsyncPaginate} from "react-select-async-paginate"
import {KlassDropdownAsyncProps} from "./KlassDropdownAsync"
import {components} from "react-select"
import styles from "./KlassDropAsyncPaginate.module.css"

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: "6px",
        borderColor: "transparent",
        boxShadow: state?.selectProps?.error ? "none" : "1px 2px 3px 1px var(--black-100)",
        border: "none",
        minHeight: "44px",
        fontSize: 16
    }),

    option: (provided) => ({
        ...provided,
        padding: "10px",
        cursor: "pointer"
    }),

    container: (provided, state) => ({
        ...provided,
        border: state?.selectProps?.error ? "1px solid var(--error-400-base) !important" : "none",
        borderRadius: 6
    }),

    indicatorSeparator: () => ({
        display: "none"
    }),

    dropdownIndicator: (provided, state) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        width: "40px",
        height: "100%",
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
        justifyContent: "center",
        color: "#666666",
        cursor: "pointer"
    }),

    placeholder: (provided) => ({
        ...provided,
        fontSize: "16px",
        color: "var(--black-200)"
    }),

    multiValue: (styles) => {
        return {
            ...styles,
            backgroundColor: "var(--primary-400-base)"
        }
    },

    multiValueLabel: (styles) => ({
        ...styles,
        color: "var(--whitebase)"
    }),

    multiValueRemove: (styles) => ({
        ...styles,
        color: "var(--whitebase)"
    }),

    menuList: () => ({
        maxHeight: "200px",
        overflow: "auto"
    }),

    menu: (provided) => ({
        ...provided,
        zIndex: 10
    }),

    groupHeading: (provided) => ({
        ...provided,
        backgroundColor: "var(--primary-100)",
        fontSize: 18,
        fontWeight: 700,
        lineHeight: 1.11,
        color: "var(--black-200)",
        paddingTop: 4,
        paddingBottom: 4
    })
}

const customMultiValueRemove = (components) => (props) => {
    if (props.data.isFixed) {
        return null
    }

    return <components.MultiValueRemove {...props} />
}

export function KlassDropAsyncPaginate(props: KlassDropdownAsyncProps) {
    const {
        stylesCustom,
        valueKey,
        labelKey,
        isMulti,
        checkRemoveButton,
        readOnly = false,
        isClearable,
        className,
        refData,
        ...rest
    } = props
    let valueKeySelect = valueKey || "id"
    let labelKeySelect = labelKey || "name"
    let styles = customStyles
    if (stylesCustom) styles = {...styles, ...stylesCustom}
    const customProps = {...rest, styles}

    return (
        <AsyncPaginate
            cacheOptions
            defaultOptions
            getOptionValue={(option: any) => option[valueKeySelect]}
            getOptionLabel={(option: any) => option[labelKeySelect]}
            closeMenuOnSelect
            isMulti={isMulti}
            menuIsOpen={readOnly ? false : undefined}
            isSearchable={!readOnly}
            isClearable={!!isClearable && !readOnly}
            classNamePrefix="klassapp-dropdown"
            className={classNames("klassapp-dropdown", className, {readOnly})}
            ref={refData}
            loadOptions={rest.loadOptions}
            components={checkRemoveButton ? {MultiValueRemove: customMultiValueRemove(components)} : undefined}
            {...customProps}
        />
    )
}

const DropdownIndicator = (props) => {
    return (
        <div className={styles.orButtonContainer}>
            <components.DropdownIndicator {...props}>
                <div onClick={props.onClickOr} className={styles.orButton}>
                    <span className={styles.orText}>OR</span>
                </div>
            </components.DropdownIndicator>
        </div>
    )
}

const MultiValueContainer = (props) => {
    const isOr = !!props.data.or
    const courseId = props.data.courseId
    const currentIndex = props.selectProps.value.findIndex((item) => item.courseId === courseId) ?? 0

    if (isOr) {
        return (
            <div className={styles.orLabelContainerLeft}>
                <components.MultiValueContainer {...props} />
                <span className={styles.orLabel}>OR</span>
            </div>
        )
    } else if (currentIndex - 1 >= 0 && props.selectProps.value[currentIndex - 1].or) {
        return (
            <div className={styles.orLabelContainer}>
                <components.MultiValueContainer {...props} />
            </div>
        )
    }
    return <components.MultiValueContainer {...props} />
}

export function KlassDropConditionAsyncPaginate(props: KlassDropdownAsyncProps) {
    const {
        stylesCustom,
        valueKey,
        labelKey,
        isMulti,
        readOnly = false,
        isClearable,
        onClickOr,
        className,
        refData,
        ...rest
    } = props
    let valueKeySelect = valueKey || "id"
    let labelKeySelect = labelKey || "name"
    let styles = customStyles
    if (stylesCustom) styles = {...styles, ...stylesCustom}
    const customProps = {...rest, styles}

    return (
        <AsyncPaginate
            cacheOptions
            defaultOptions
            getOptionValue={(option: any) => option[valueKeySelect]}
            getOptionLabel={(option: any) => option[labelKeySelect]}
            closeMenuOnSelect
            isMulti={isMulti}
            components={{
                DropdownIndicator: (props) => <DropdownIndicator onClickOr={onClickOr} {...props} />,
                MultiValueContainer
            }}
            menuIsOpen={readOnly ? false : undefined}
            isSearchable={!readOnly}
            isClearable={isClearable && !readOnly}
            classNamePrefix="klassapp-dropdown"
            className={classNames("klassapp-dropdown", className, {readOnly})}
            ref={refData}
            loadOptions={rest.loadOptions}
            {...customProps}
        />
    )
}
