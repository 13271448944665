import BaseService from "./Base"
import {get} from "lodash"

class AdmissionsLegacyService extends BaseService {
    constructor() {
        super(process.env.REACT_APP_ADMISSIONS_LEGACY_API)
    }
    async createCampusCode(params = {}) {
        // campusId, slug
        const response = await this.post("/public/clients/code/create", params)
        return get(response, "data", [])
    }

    async deleteCampusCode(params = {}) {
        // campusId, slug, code
        const response = await this.post("/public/clients/code/delete", params)
        return get(response, "data", [])
    }
}

export default AdmissionsLegacyService
