export enum SocketEvent {
    Ping = "ping",
    Pong = "pong",
    UserLogin = "User.Login",
    UserLogout = "User.Logout",
    SocketJoin = "Socket.Join",
    SocketLeave = "Socket.Leave",

    StudentPaymentUpdated = "StudentPayment.Updated",
    LessonUpdated = "Lesson.Updated",

    ProfileUpdated = "Profile.Updated",
    ProfileStatusUpdated = "Profile.Status.Updated",
    ProfileStudentStatusUpdated = "Profile.StudentStatus.Updated",
    ProfileFinancialAidStatusUpdated = "Profile.FinancialAidStatus.Updated",
    ProfileDueSet = "Profile.Due.Set",
    ProfileDueCleared = "Profile.Due.Cleared",
    StudentActivityCreated = "StudentActivity.Created",
    StudentActivityUpdated = "StudentActivity.Updated",

    DialerUpdated = "Dialer.Updated"
}

export const socketLessonIdRoom = (lessonId: number) => `lesson_${lessonId}`
export const SOCKET_ROOM_PRIORITY_TABLE = "priority_table"
