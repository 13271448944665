import {useModel} from "hooks"
import styles from "./FooterReport.module.css"

type FooterReportProps = {
    logo: string
}

const FooterReport = (props: FooterReportProps) => {
    const {logo} = props
    return (
        <div className={styles.container}>
            <img className={styles.logo} alt="" src={logo} />
            <p className={styles.header}>Healing Hands School of Holistic Health</p>
            <p className={styles.subHeader}>344 E. Valley Pkwy, Escondido, CA 92025</p>
            <p className={styles.subHeader}>23022 La Cadena Ave, Suite 205, Laguna Hills, CA 92653</p>
            <p className={styles.subHeader}>Phone: (800) 355-6463 FAX: (760) 839-0504</p>
            <p
                className={styles.subHeader}
                style={{
                    color: "rgb(51, 51, 154)"
                }}>
                www.hhs.edu
            </p>

            <div className={styles.detail}>
                <p className={styles.title}>Transcript Information:</p>
                <ol className={styles.list}>
                    <li className={styles.listTitle}> Grading Policies</li>
                    <p className={styles.content}>
                        In order to establish proficiency in each course or module, students will be required to
                        participate in, and complete multiple graded activities throughout the program. The Healing
                        Hands School instructor has the final authority for determining the student's grade for a
                        course. Grades may not be changed by anyone other than the instructor. The scholastic progress
                        of the student is reported at the end of each course and grades are recorded on the student's
                        permanent record (transcript).
                    </p>
                    <li className={styles.listTitle}> Grading Scale</li>
                    <p className={styles.content}>
                        <p>Tests and other assignments will be graded Pass/Fail.</p>
                        <p>
                            <span className={styles.content}>P</span>ass = pass all course work and attendance
                            requirement
                        </p>
                        <p>
                            <span className={styles.content}>F</span>ail = Student misses more than 40% of course and/or
                            has not met course requirements such as exam/homework/proficiency
                        </p>
                        <p>
                            <span className={styles.content}>I</span>ncomplete = Student may submit required course work
                            and/or make up course time in instructor supervised session. Student has 3 months [90 days]
                            from the last date of incomplete course to complete course requirements.
                        </p>
                        <p>
                            <span className={styles.content}>W</span> = student withdrew from program before class
                            started; student did not attend course
                        </p>

                        <p>The Healing Hands School's grading scale is as follows:</p>
                        <p>100 - 80% .... Pass</p>
                        <p>79 - 0% .... Fail</p>
                        <p>W ............ Withdrawal (a course from which a student withdrew)</p>
                        <p>
                            I ............. Incomplete (a course in which a student received an 'Incomplete'- refer to
                            Incomplete Policy)
                        </p>
                        <p>TC............ Transfer Credit</p>
                    </p>
                    <li className={styles.listTitle}> Please Note</li>
                    <p className={styles.content}>
                        Healing Hands School of Holistic Health is approved by the Bureau of Private Postsecondary
                        Education State of California, School code #3707991. NCBTMB # 043152-00. Certified for
                        continuing education units by the Board of Registered Nursing #CEP11104. U.S. Department of
                        Education OPE ID# 0414700. CAMTC # SCH 131. All hours are supervised by faculty.
                    </p>
                    <li className={styles.listTitle}> Program Graduation</li>
                    <p className={styles.content}>
                        Program date of graduation is the date all course work is completed and required courses are
                        Passed
                    </p>
                    <li className={styles.listTitle}>
                        {" "}
                        Please note <span>Healing Hands School of Holistic Health is:</span>
                    </li>
                    <p className={styles.content}>
                        <ul>
                            <li>
                                Certified by the Bureau of Private Post-Secondary Education of the State of California
                                School code #3707991 (M) 3014001(S).
                            </li>
                            <li>
                                National Accreditation: Institutionally Accredited by Accrediting Bureau of Health
                                Education Schools [ABHES] &#8226; Phone: (703) 917-9503 Address: 7777 Leesburg Pike,
                                Suite 314 N. Falls Church, VA 22043
                            </li>
                            <li>
                                Federal Recognition: Recognized by U.S. Department of Education to process Title IV
                                Benefits (Federal Student Aid) OPE ID # 04141700
                            </li>
                            <li>
                                Member in good standing of the American Bodyworkers' & Massage Practitioners'
                                Association & the American Massage Therapy Association
                            </li>
                            <li>Certified by NCBTMB; School Code # 043152-00, Provider # 450453-07</li>
                            <li>Approved for Vocational Rehabilitation by the state of California.</li>
                            <li>Approved by the California Board of Registered Nursing, Provider #CEP 11194</li>
                            <li>
                                Approved by California State Approving Authority to use the Department of Veteran
                                Affairs for VA Education Benefits and training including title IX and Post 9/11
                            </li>
                            <li>
                                Listed on the EDD's Eligibility Training Providers' List to provide training while
                                receiving Unemployment Benefits as well as participating in retraining through the
                                Workforce Innovation and Opportunity Act. [WIOA] Please see www.CalJobs.ca.gov
                            </li>
                            <li>
                                Authorized by The Department of Homeland Security to issue I-20 forms for M-1 Student
                                Visas. Please see the www.hhs.edu website for more information.
                            </li>
                        </ul>
                    </p>
                </ol>
            </div>
        </div>
    )
}

export default FooterReport
