/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {Row, Col, Spin} from "antd"
import {cloneDeep, pick} from "lodash"
import moment from "moment"
import {handleError, toastError, toastSuccess} from "helpers"
import {BaseButton} from "components/buttons"
import {careerService} from "services"
import styles from "./CareerServiceForm.module.css"
import {CareerServices} from "types/careerServices"
import {DisplayMode} from "types/common"
import {FormDetail, NotAvailableForEmployment} from "./parts"
import {BoardLicense} from "./parts/BoardLicense"

type Props = {
    profileId: number
    displayMode?: DisplayMode
    onSaveSuccess?: () => void
}

export function CareerServiceForm(props: Props) {
    const {displayMode = "normal", profileId, onSaveSuccess} = props
    const [newStudentData, setNewStudentData] = useState<CareerServices.StudentDetail>(null)
    const [oldStudentData, setOldStudentData] = useState<CareerServices.StudentDetail>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        getCareerServiceStudent()
    }, [])

    const getCareerServiceStudent = async () => {
        try {
            setIsLoading(true)
            let data = await careerService.getStudent({profileId})
            data.salaryUnit = data.salaryUnit || CareerServices.SalaryUnit.Monthly
            data.verifiedSalaryUnit = data.verifiedSalaryUnit || CareerServices.SalaryUnit.Monthly
            setNewStudentData(data)
            setOldStudentData(cloneDeep(data))
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const validateBeforeSubmit = () => {
        let _errors = []
        return _errors.length > 0
    }

    const onClickSave = async () => {
        try {
            setIsSubmitting(true)

            if (validateBeforeSubmit()) {
                toastError("Check field errors.")
                return
            }

            const _boardLicenses = newStudentData.boardLicenses.map((x) => {
                return {
                    ...x,
                    boardDate: x.boardDate ? moment(x.boardDate).format("YYYY-MM-DD") : null
                }
            })

            const params = {
                ...pick(newStudentData, [
                    "profileId",
                    // placed
                    "isPlaced",
                    "contactPerson",
                    "phone",
                    "employer",
                    "email",
                    "salaryUnit",
                    "website",
                    "city",
                    "state",
                    "address",
                    "jobDescription",
                    // verified
                    "isVerified",
                    "isTaskVerified",
                    "isEmailVerified",
                    "isPhoneVerified",
                    "verifiedAt",
                    "verifiedContactPerson",
                    "verifiedPhone",
                    "verifiedEmployer",
                    "verifiedSalaryUnit",
                    "verifiedEmail",
                    "verifiedWebsite",
                    "verifiedCity",
                    "verifiedState",
                    "verifiedEmploymentStartDate",
                    "verifiedBy",
                    "verifiedAddress",
                    "verifiedJobDescription",
                    // not available for employment
                    "unemploymentType",
                    "selfEmployedBusinessName",
                    "selfEmployedBusinessDescription",
                    "selfEmployedPhone",
                    "selfEmployedEmail",
                    "selfEmployedWebsite",
                    "selfEmployedStartDate",
                    "contEduInstitutionName",
                    "contEduProgramDegreeName",
                    "contEduEnrolledDate",
                    "contEduExpectedGraduationDate",
                    "medicalCondFromDate",
                    "medicalCondToDate",
                    "medicalCondDeathDate",
                    "medicalCondSituation",
                    "militaryMemberName",
                    "militaryDutyStation",
                    "militaryReportDate",
                    "incarceratedInstitutionName",
                    "incarceratedLengthOfSentencing",
                    "othersComment"
                ]),
                employmentDate: newStudentData.employmentDate
                    ? moment(newStudentData.employmentDate).format("YYYY-MM-DD")
                    : null,
                employmentStartDate: newStudentData.employmentStartDate
                    ? moment(newStudentData.employmentStartDate).format("YYYY-MM-DD")
                    : null,
                verifiedAt: newStudentData.verifiedAt ? moment(newStudentData.verifiedAt).format("YYYY-MM-DD") : null,
                verifiedEmploymentStartDate: newStudentData.verifiedEmploymentStartDate
                    ? moment(newStudentData.verifiedEmploymentStartDate).format("YYYY-MM-DD")
                    : null,
                salary: newStudentData.salary ? +newStudentData.salary : null,
                verifiedSalary: newStudentData.verifiedSalary ? +newStudentData.verifiedSalary : null,
                positionId: newStudentData.position
                    ? newStudentData.position.id || newStudentData.position.value
                    : null,
                verifiedPositionId: newStudentData.verifiedPosition
                    ? newStudentData.verifiedPosition.id || newStudentData.verifiedPosition.value
                    : null,
                boardLicenses: _boardLicenses
            }
            await careerService.saveStudent(params)
            toastSuccess("Save Successful")
            setOldStudentData(cloneDeep(newStudentData))
            onSaveSuccess?.()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitting(false)
        }
    }

    if (isLoading || !oldStudentData) {
        return <Spin />
    }

    const renderDaysToGraduation = (daysToGraduation) => {
        let _str = "--"
        if (daysToGraduation) {
            _str = `${daysToGraduation} ${daysToGraduation > 1 ? "days" : "day"}`
        }
        return _str
    }

    return (
        <div className={styles.formBody}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <div className={styles.graduatingInfo}>
                        <span className={styles.graduatingInfo__title}>Graduating In</span>
                        <span className={styles.graduatingInfo__value}>
                            {renderDaysToGraduation(oldStudentData.daysToGraduation)}
                        </span>
                    </div>
                </Col>
            </Row>
            <FormDetail
                student={newStudentData}
                isLoading={isLoading}
                isSubmitting={isSubmitting}
                updateStudent={(_student) => setNewStudentData(_student)}
                displayMode={displayMode}
            />
            <BoardLicense
                student={newStudentData}
                isLoading={isLoading}
                isSubmitting={isSubmitting}
                displayMode={displayMode}
                updateStudent={(_student) => setNewStudentData(_student)}
            />
            <div className={styles.line} />
            <FormDetail
                formType="verification"
                student={newStudentData}
                isLoading={isLoading}
                isSubmitting={isSubmitting}
                updateStudent={(_student) => setNewStudentData(_student)}
                displayMode={displayMode}
            />
            <div className={styles.line} />
            <NotAvailableForEmployment
                student={newStudentData}
                updateStudent={(_student) => setNewStudentData(_student)}
                displayMode={displayMode}
            />
            <div className={styles.formAction}>
                <BaseButton title="Save" onClick={onClickSave} loading={isSubmitting} />
            </div>
        </div>
    )
}
