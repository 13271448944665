/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react"
import {useTranslation} from "react-i18next"
import moment from "moment"
import {useHistory} from "react-router-dom"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import styles from "./CollegeFinancingPlan.module.css"
import {finAidService} from "services"
import {useModel} from "hooks"
import {Col, Row} from "antd"
import {routing} from "helpers/routing"
import {getFullName} from "helpers"
import {KlassappTableProps} from "types/common"
import {Auth} from "types/auth"
import {PermissionsRequired} from "components/PermissionsRequired"
import {Permissions} from "types/permission"
import {BaseButton, Icon} from "components"
import {CollegeFinancingPlanStatus} from "types/fin-aid/fin-aid"

interface CollegeFinancingPlansProps extends KlassappTableProps {
    studentId: number
    departmentId: number
    awardYear: number
    student?: Auth.DepartmentStudent
}

function CollegeFinancingPlans(props: CollegeFinancingPlansProps) {
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        tableHeaderActions,
        isLoading,
        menuActions,
        isHideMenuActions,
        isShowTableHeaderAction,
        onClickShowConfirmModal,
        getCurrentData,
        getCurrentPage,
        getCurrentPageSize,
        studentId,
        awardYear
    } = props
    const history = useHistory()
    const model = useModel()
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const {t} = useTranslation(["financialAid", "common"])

    const getFields = () => {
        return [t("cfp.column.code"), t("cfp.column.awardYear"), t("cfp.column.createdBy"), t("cfp.column.lastUpdated")]
    }

    const getPageTitle = () => {
        return t("cfp.tableTitle")
    }

    const getColumns = () => {
        return [
            {
                title: t("cfp.column.code"),
                field: "code",
                style: {minWidth: "100px"}
            },
            {
                title: t("cfp.column.awardYear"),
                field: "awardYear",
                style: {minWidth: "80px"},
                render: (value) => `${value} - ${value + 1}`
            },
            {
                title: t("cfp.column.createdBy"),
                field: "createdAt",
                style: {minWidth: "200px"},
                render: (value, record) => (
                    <div className={styles.statusInfo}>
                        <div className={styles.statusInfo__text}>{getFullName(record.createdBy)}</div>
                        <div className={styles.statusInfo__text}>{moment(value).format(userDateTimeFormat)}</div>
                    </div>
                )
            },
            {
                title: t("cfp.column.lastUpdated"),
                field: "updatedAt",
                style: {minWidth: "200px"},
                render: (value, record) => (
                    <div className={styles.statusInfo}>
                        <div className={styles.statusInfo__text}>{getFullName(record.updatedBy)}</div>
                        <div className={styles.statusInfo__text}>{moment(value).format(userDateTimeFormat)}</div>
                    </div>
                )
            }
        ]
    }

    const getTableHeaderActions = React.useCallback(
        (isShowDuplicateBtn = true, checkedData = []) => {
            return [
                {
                    title: t("common:action.delete"),
                    icon: "DELETE",
                    action: () => onClickShowConfirmModal("DELETE")
                }
            ]
        },
        [t, onClickShowConfirmModal]
    )

    const getMenuActions = React.useCallback(
        () => [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: onClickDelete
            }
        ],
        [t]
    )

    const getData = async () => {
        dispatch({isLoading: true})
        const page = getCurrentPage()
        const pageSize = getCurrentPageSize()
        const {total, data: packages} = await finAidService.getCFPs({
            filter: {studentProfileId: [+studentId], awardYear, status: CollegeFinancingPlanStatus.Active},
            range: {
                page,
                pageSize
            },
            linkedObjects: true
        })
        dispatch({total, data: packages.map((item) => ({...item, id: item.cfpId})), isLoading: false})
    }

    const onClickRowItem = ({studentProfileId, cfpId}) => {
        history.push(routing.finAid.student.cfpDetail(studentProfileId, cfpId))
    }

    const onClickAdd = () => {
        history.push(routing.finAid.student.cfpDetail(studentId, "new") + `?awardYear=${awardYear}`)
    }

    const onClickDeleteMulti = React.useCallback(async () => {
        const items = getCurrentData()
        const selectedIds = items.filter((item) => item.isChecked).map((item) => item.id)
        await Promise.all(selectedIds.map((cfpId) => finAidService.deleteCFP(cfpId)))
        dispatch({isShowTableHeaderAction: false, isHideMenuActions: false})
        await getData()
    }, [getCurrentData])

    const onClickEdit = ({studentProfileId, cfpId}) => {
        history.push(routing.finAid.student.cfpDetail(studentProfileId, cfpId))
    }

    const onClickDelete = async ({cfpId}) => {
        await finAidService.deleteCFP(cfpId)
        await getData()
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "getTableHeaderActions", func: getTableHeaderActions},
            {key: "getMenuActions", func: getMenuActions},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDelete},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
        getData()
    }, [])

    useEffect(() => {
        getData()
    }, [page, pageSize])

    const [, setSearch] = React.useState("")
    const [filter, setFilter] = React.useState<Record<string, any>>({})

    const onApplyFilter = () => {}

    const onClearFilter = () => {
        setFilter({})
    }

    const onSearchInput = (search) => {
        setSearch(search)
    }

    return (
        <div>
            <BaseNewFilter
                filter={filter}
                onClick={onApplyFilter}
                onClickClear={onClearFilter}
                onSearchInput={onSearchInput}
                renderRightFilter={() => (
                    <PermissionsRequired
                        permissions={{staff: [Permissions.Staff.FinancialAid.Students.FinancialAidStudentDetail.Add]}}>
                        <div className={styles.actionWrap}>
                            <BaseButton
                                title="CREATE"
                                icon={<Icon className={styles.plusIcon} icon="PLUS" color="#FFF" />}
                                onClick={onClickAdd}
                            />
                        </div>
                    </PermissionsRequired>
                )}>
                <Row gutter={[24, 24]}>
                    <Col span={12}></Col>
                    <Col span={12}></Col>
                </Row>
            </BaseNewFilter>

            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                data={data}
                isLoading={isLoading}
                menuActions={isHideMenuActions ? [] : menuActions}
                fields={fields}
                allFields={allFields}
                isShowCheckedColumn
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </div>
    )
}

export default KlassappTableHOC(CollegeFinancingPlans)
