/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {Row, Col, Tabs} from "antd"
import {studentService} from "services"
import {handleError} from "helpers"
import {useActiveTab, useCurrentProfilePermissions} from "hooks"
import {BaseDepartmentId} from "types/departments"
import {Permissions, PermissionUserType} from "types/permission"
import {ChecklistTable} from "../ChecklistTable"
import styles from "./Checklist.module.css"
import {TabHeader} from "components/Tab"

type Module = {
    id: number
    name: string
}

type Props = {
    studentId: number
    departmentId: number
}

const {TabPane} = Tabs
const modules: Module[] = [
    {
        id: BaseDepartmentId.Admissions,
        name: "Admissions"
    },
    {
        id: BaseDepartmentId.FinancialAid,
        name: "Financial Aid"
    },
    {
        id: BaseDepartmentId.Academics,
        name: "Academics"
    },
    {
        id: BaseDepartmentId.StudentServices,
        name: "Student Services"
    },
    {
        id: BaseDepartmentId.CareerServices,
        name: "Career Services"
    }
]

export function Checklist(props: Props) {
    const {studentId, departmentId} = props
    const [progress, setProgress] = useState({total: 0, completed: 0})
    const {t} = useTranslation(["common"])
    const [activeTabKey, onChangeTab] = useActiveTab(`${departmentId}`, "department")
    const currentProfilePermissions = useCurrentProfilePermissions()

    useEffect(() => {
        getStudentChecklistProgress()
    }, [departmentId])

    useEffect(() => {
        onChangeTab(`${departmentId}`)
    }, [])

    useEffect(() => {
        getStudentChecklistProgress()
    }, [activeTabKey])

    async function getStudentChecklistProgress() {
        try {
            const data = {
                profileId: +studentId,
                departmentId: +activeTabKey
            }
            const progress = await studentService.getStudentChecklistProgress(data)
            setProgress(progress)
        } catch (error) {
            handleError(error)
        }
    }

    const onCreateSuccess = () => {
        getStudentChecklistProgress()
    }

    const getPermissionsByDepartment = (departmentId: number): PermissionUserType => {
        switch (departmentId) {
            case BaseDepartmentId.Admissions:
                return {
                    staff: [Permissions.Staff.Admissions.Students.AdmissionsStudentDetails.View],
                    student: [Permissions.Student.Admissions.View]
                }
            case BaseDepartmentId.FinancialAid:
                return {
                    staff: [Permissions.Staff.FinancialAid.Students.FinancialAidStudentDetail.View],
                    student: [Permissions.Student.FinancialAid.View]
                }
            case BaseDepartmentId.Academics:
                return {
                    staff: [Permissions.Staff.Academics.Registrar.Students.AcademicPlanning.View],
                    student: [Permissions.Student.Academics.Workspace.View]
                }
            case BaseDepartmentId.StudentServices:
                return {
                    staff: [
                        Permissions.Staff.StudentServicesAndAcademicAffairs.StudentServices.Students.AcademicPlanning
                            .View
                    ],
                    student: [Permissions.Student.StudentServicesAndAffairs.StudentServices.View]
                }
            case BaseDepartmentId.CareerServices:
                return {
                    staff: [Permissions.Staff.CareerServices.View],
                    student: [Permissions.Student.CareerServices.View]
                }
            default:
                return {}
        }
    }

    const renderBody = (module: Module): JSX.Element => {
        const isActive = module.id === +activeTabKey
        return (
            <TabPane tab={<TabHeader title={module.name} />} key={module.id}>
                {isActive && (
                    <>
                        <Row gutter={[24, 24]}>
                            <Col span={12}>
                                <div className={styles.cardWrap}>
                                    <p className={styles.cardTitle}>{t("checklist.completion")}</p>
                                    <hr className={styles.line} />
                                    <p className={`${styles.number} ${styles.completion}`}>
                                        {progress.completed}/{progress.total}
                                    </p>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className={styles.cardWrap}>
                                    <p className={styles.cardTitle}>{t("checklist.itemsPending")}</p>
                                    <hr className={styles.line} />
                                    <p className={`${styles.number} ${styles.pending}`}>
                                        {progress.total - progress.completed}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        <ChecklistTable
                            {...props}
                            getStudentChecklistProgress={getStudentChecklistProgress}
                            onCreateSuccess={onCreateSuccess}
                            departmentId={module.id}
                            departmentName={module.name}
                        />
                    </>
                )}
            </TabPane>
        )
    }

    return (
        <div>
            <p className={styles.title}>Checklist</p>
            <Tabs className="fullwidth klassSubTab" activeKey={activeTabKey} onChange={onChangeTab}>
                {modules.map((module) => {
                    const permissions = getPermissionsByDepartment(module.id)
                    const hasPermission = currentProfilePermissions.hasPermissions(permissions)
                    return hasPermission && renderBody(module)
                })}
            </Tabs>
        </div>
    )
}
