import {get} from "lodash"
import BaseService from "./Base"

type ADMStudents = {
    admissionCategory: number
    students: number
}

type Staffs = {
    contactProfileId: number
    firstName: string
    middleName: string
    lastName: string
    students: number
}

export default class AdmissionsReportsService extends BaseService {
    async getADMStudents(params): Promise<ADMStudents[]> {
        const response = await this.post(`/v1/admissions/report/list`, params)
        return get(response, "data.data", [])
    }

    async getStaffs(params): Promise<{data: Staffs[]; total}> {
        const response = await this.post(`/v1/admissions/report/staff/list`, params)
        return get(response, "data", [])
    }

    async getStudents(params): Promise<{data: any[]; total: number}> {
        const response = await this.post(`/v1/admissions/report/students/list`, params)
        return get(response, "data", {})
    }

    async getLeadStats(params = {}): Promise<any> {
        const response = await this.post("/v1/admissions/lead-stats", params)
        return get(response, "data.data", {})
    }

    async getLeadYearStats(params = {}): Promise<any> {
        const response = await this.post("/v1/admissions/lead-year-stats", params)
        return get(response, "data.data", {})
    }

    async getLeadSummary(params = {}): Promise<{data: any; total: number}> {
        const response = await this.post("/v1/admissions/lead-summary", params)
        return get(response, "data", null)
    }
}
