/* eslint-disable react-hooks/exhaustive-deps */
import {FilterList} from "types/filter"
import {Card} from "../Card"
import {useCallback} from "react"
import {studentServicesService} from "services"
import {Line} from "react-chartjs-2"
import {v4 as uuidV4} from "uuid"
import {useTranslation} from "react-i18next"
import {handleError} from "helpers"
import {useQuery} from "@tanstack/react-query"

const MONTH_LABELS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

type Props = {
    loadKey: string
    filters?: FilterList
    chartOptions?: any
}

export function Attendance(props: Props) {
    const {loadKey, filters, chartOptions} = props
    const {t} = useTranslation(["studentServices"])

    const {data, isFetching} = useQuery({
        queryKey: ["student-attendance", loadKey],
        enabled: !!loadKey,
        queryFn: async () => {
            const data = await studentServicesService.getAttendance({filters})
            return {
                labels: data.map((x) => {
                    const timeLabels = x.yearMonth.split("-")
                    return `${MONTH_LABELS[parseInt(timeLabels[1]) - 1]} ${timeLabels[0]}`
                }),
                datasets: [
                    {
                        data: data.map((x) => x.noStudents),
                        label: "",
                        fill: false,
                        backgroundColor: "#62b1ff",
                        borderColor: "#62b1ff"
                    }
                ]
            }
        },
        onError: (err) => {
            handleError(err)
        }
    })

    const datasetKeyProvider = useCallback(() => uuidV4(), [filters])

    const renderChart = () => {
        if (!data) return null
        return <Line data={data} options={chartOptions} datasetKeyProvider={datasetKeyProvider} />
    }

    return (
        <Card
            key={loadKey}
            iconName="PERSON_FILL"
            title={t("attendanceLabel")}
            renderChart={renderChart}
            isLoading={isFetching}
        />
    )
}
