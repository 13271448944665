import {get} from "lodash"
import BaseService from "./Base"

const basePath: string = `/v1/news-feed-posts`

class NewsFeedPostsService extends BaseService {
    async listMyFeed(params) {
        const response = await this.post(`${basePath}/list/my`, params)
        return get(response, "data", [])
    }
}

export default NewsFeedPostsService
