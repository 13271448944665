import React from "react"
import {withTranslation} from "react-i18next"
import {isEmpty, isEqual, keyBy, get} from "lodash"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableProps} from "types/common"
import {BaseButton} from "components/buttons"
import {Icon} from "components/Icon"
import {PermissionsRequired} from "components/PermissionsRequired"
import {Permissions, PermissionType} from "types/permission"
import {getEnableSubunitsByDepartmentPermission, handleError} from "helpers"
import {KlassappTableHOC} from "HOC"
import {departmentSubunitService, taskService} from "services"
import {Model} from "Model"
import {TaskSubtype, TaskType} from "types/tasks"
import styles from "./LibraryTab.module.css"
import {FilterKey} from "types/filter"

interface PageProps {
    t: Function
    filter: any
    isStaff: boolean
    history?: any
    model: Model
    departmentId?: number
    search: string
    onClickAdd: () => void
}

type Props = KlassappTableProps & PageProps

class LibraryTab extends React.Component<Props, {}> {
    state = {
        departmentSubunits: []
    }

    async componentDidMount() {
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getFilterMemoryKey", func: this.getFilterKey},
            {key: "getListData", func: this.getData},
            {key: "getFields", func: this.getFields},
            {key: "getColumns", func: this.getColumns},
            {key: "getMenuActions", func: this.getMenuActions},
            {key: "getTableHeaderActions", func: this.getTableHeaderActions},
            {key: "onClickDeleteMulti", func: this.onClickDeleteMulti},
            {key: "onClickDelete", func: this.onClickDelete},
            {key: "onClickRowItem", func: this.onClickRowItem}
        ])
        this.props.dispatch({isLoading: true})
        const departmentSubunits = await this.getDepartmentSubunits()
        this.getData(departmentSubunits)
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.filter, this.props.filter) || prevProps.search !== this.props.search) {
            this.props.dispatch({page: 1}, () => {
                this.getData()
            })
        }
    }

    getDepartmentSubunits = async () => {
        try {
            const params: any = {
                range: {pageSize: 50, page: 1}
            }
            const {departmentId} = this.props
            if (departmentId) {
                params.filter = {
                    departmentIds: [departmentId]
                }
            }
            const {data: departmentSubunits} = await departmentSubunitService.getSubunits(params)
            this.setState({departmentSubunits})
            return departmentSubunits
        } catch (error) {
            handleError(error)
            return []
        }
    }

    getPageTitle = () => {
        return this.props.t("tasks.task")
    }

    getFilterKey = () => {
        return FilterKey.TasksLibraryPage
    }

    getFields = () => {
        const {t} = this.props
        return [
            t("tasks.code"),
            t("tasks.name"),
            t("tasks.type"),
            t("tasks.department"),
            t("tasks.tasksAssigned"),
            t("tasks.added")
        ]
    }

    getTableHeaderActions = () => {
        const {t, onClickShowConfirmModal} = this.props
        return [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            }
        ]
    }

    getData = async (departmentSubunits = null) => {
        const {dispatch} = this.props
        const {departmentSubunits: departmentSubunitsState} = this.state
        dispatch({isLoading: true})
        try {
            const subunits = departmentSubunits || departmentSubunitsState
            const subunitsKeyById = keyBy(subunits, "subunitId")
            const params = this.getParams(subunits)
            const {data, total} = await taskService.getAll(params)
            const newData = data.map((task: any) => {
                task.typeHtml = this.renderTypeHtml(task.type)
                task.children = `${task.children}`
                task.departmentSubunitName = get(subunitsKeyById, [task.departmentId, "name"])
                return task
            })
            this.props.dispatch({data: newData, total})
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    renderTypeHtml = (type) => {
        switch (type) {
            case TaskType.DIGITAL_DOCUMENT:
                return "Digital Document"
            case TaskType.SCAN_UPLOAD:
                return "Scan Upload"
            case TaskType.FROM_LIBRARY:
                return "Library"
            case TaskType.FORM:
                return "Web Form"
            case TaskType.CUSTOM:
                return "Custom"
            default:
                return ""
        }
    }

    getParams = (departmentSubunits) => {
        const {page, pageSize, filter, orderField, model, departmentId, search} = this.props
        const {staffs, departments, type} = filter
        const filterData: any = {search, subtype: TaskSubtype.LIBRARY}
        const accessibleSubunitIds = getEnableSubunitsByDepartmentPermission(PermissionType.View, model, "Tasks")
        if (isEmpty(filterData.departmentIds) && accessibleSubunitIds.length) {
            filterData.departmentIds = accessibleSubunitIds
        }
        if (!isEmpty(staffs)) {
            filterData.staff = staffs.map((item) => item.id)
        }
        if (!isEmpty(departments)) {
            filterData.departmentIds = (departments || []).map((department) => department.subunitId)
        } else if (departmentId) {
            const subunitIds = departmentSubunits.map((subunit) => subunit.subunitId)
            filterData.departmentIds = subunitIds
        } else if (!isEmpty(accessibleSubunitIds)) {
            filterData.departmentIds = accessibleSubunitIds
        }
        if (!isEmpty(type)) {
            filterData.type = type.id
        }
        const params = {
            filter: filterData,
            range: {
                page,
                pageSize
            },
            sort: {
                orderBy: orderField.field ?? "createdAt",
                orderDir: orderField.order ?? "DESC"
            }
        }
        return params
    }

    getColumns = () => {
        const {t} = this.props
        return [
            {
                title: t("tasks.code"),
                field: "code",
                style: {minWidth: "120px"},
                sortable: true
            },
            {
                title: t("tasks.name"),
                field: "name",
                style: {minWidth: "150px"},
                sortable: true
            },
            {
                title: t("tasks.type"),
                field: "typeHtml",
                style: {minWidth: "150px"},
                orderField: "type",
                sortable: true
            },
            {
                title: t("tasks.department"),
                field: "departmentSubunitName",
                style: {minWidth: "120px"}
            },
            {
                title: t("tasks.tasksAssigned"),
                style: {width: "240px"},
                field: "children",
                sortable: true
            },
            {
                title: t("tasks.added"),
                field: "createdAt",
                fieldType: "date",
                style: {width: "140px"},
                sortable: true
            }
        ]
    }

    getMenuActions = () => {
        const {t} = this.props
        return [
            {
                title: t("common:action.configure"),
                icon: "EDIT",
                action: this.onClickConfigure
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: this.onClickDelete
            }
        ]
    }

    onClickDeleteMulti = async () => {
        const {data, dispatch} = this.props
        const checkedItems = data.filter((item) => item.isChecked)
        const payload = checkedItems.map((item) => item.id)
        try {
            this.props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await taskService.deleteTask(payload)
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    onClickRowItem = (data) => {
        const {history} = this.props
        history.push({
            pathname: "/tasks/detail",
            search: `type=${data.type}&subtype=library&id=${data.id}`
        })
    }

    onClickConfigure = (editItem) => {
        const {history} = this.props
        history.push(`/tasks/detail?type=${editItem.type}&subtype=library&id=${editItem.id}&configure=1`)
    }

    onClickDelete = async (deletedItem) => {
        const {dispatch} = this.props
        try {
            dispatch({isLoading: true})
            await taskService.deleteTask([deletedItem.id])
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    render() {
        const {
            page,
            total,
            pageSize,
            columns,
            data,
            menuActions,
            allFields,
            fields,
            tableHeaderActions,
            isLoading,
            isHideMenuActions,
            isShowTableHeaderAction,
            t,
            orderField
        } = this.props

        return (
            <div>
                <PermissionsRequired permissions={{staff: [Permissions.Staff.Tasks.Library.Add]}}>
                    <div className={styles.createBtnWrap}>
                        <BaseButton
                            title={t("tasks.addTask").toUpperCase()}
                            onClick={this.props.onClickAdd}
                            icon={<Icon className={styles.plusIcon} icon="PLUS" color="#FFF" />}
                            disabled={false}
                        />
                    </div>
                </PermissionsRequired>
                <KlassappTableHeader
                    isShowAction={isShowTableHeaderAction}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.props.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    data={data}
                    menuActions={isHideMenuActions ? [] : menuActions}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    isShowCheckedColumn
                    orderField={orderField}
                    onClickRowItem={this.onClickRowItem}
                    onChangeFields={this.props.onChangeFields}
                    onUpdateRowData={this.props.onUpdateRowData}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onClickSortColumn={this.props.onClickSortColumn}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onChangeAllFields={this.props.onChangeAllFields}
                />
            </div>
        )
    }
}

export default KlassappTableHOC(withTranslation(["tasks", "common"])(LibraryTab))
