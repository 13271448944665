import React from "react"
import {Row, Col, Radio} from "antd"
import {isEmpty, omit} from "lodash"
import moment from "moment"
import {BaseFilter, BaseFilterContext} from "uiKit"
import {Search} from "components/inputs/Search"
import {BaseDatePicker} from "components/DateTimePicker"
import DepartmentSubunitSelect from "components/DepartmentSubunitSelect"
import {DepartmentSubunit} from "types/departments"
import styles from "./TableFilter.module.css"

export function TableFilter(props) {
    const [filterData, setFilterData] = React.useState({departmentSubunit: null, date: null, status: [], search: ""})
    const {onClickApply, enabledSubunitIds} = props

    const onClickFilter = () => {
        let filterDataWithoutEmptyValue: any = {}
        Object.keys(filterData).forEach((key) => {
            const value = filterData[key]
            if (!isEmpty(value)) {
                filterDataWithoutEmptyValue[key] = value
            }
            if (filterDataWithoutEmptyValue.date) {
                filterDataWithoutEmptyValue.date = moment(filterDataWithoutEmptyValue.date).format()
            }
            if (filterDataWithoutEmptyValue.departmentSubunit) {
                filterDataWithoutEmptyValue.departmentSubunitId =
                    filterDataWithoutEmptyValue.departmentSubunit.subunitId
            }
        })
        filterDataWithoutEmptyValue = omit(filterDataWithoutEmptyValue, ["departmentSubunit"])
        onClickApply(filterDataWithoutEmptyValue)
    }

    const onChangeFilterData = (key, value) => {
        const newFilterData = {...filterData}
        newFilterData[key] = value
        setFilterData(newFilterData)
    }

    const onChangeStatus = (status) => {
        let newStatus = [...filterData.status]
        if (filterData.status.includes(status)) {
            newStatus = filterData.status.filter((item) => item !== status)
        } else {
            newStatus.push(status)
        }
        onChangeFilterData("status", newStatus)
    }

    const onClickClearFilter = () => {
        setFilterData({departmentSubunit: null, date: null, status: [], search: ""}) // todo: fix UI, departments.
    }

    return (
        <BaseFilter onClick={onClickFilter} filter={filterData} onClickClear={onClickClearFilter} isShowFilterClear>
            <BaseFilterContext.Consumer>
                {({setIsShowFilter}) => (
                    <Row gutter={[40, 32]}>
                        <Col span={12}>
                            <DepartmentSubunitSelect
                                subunitIds={enabledSubunitIds}
                                isMulti={false}
                                isClearable={true}
                                placeholder="Department"
                                value={filterData.departmentSubunit}
                                onChange={(subunit?: DepartmentSubunit) => {
                                    onChangeFilterData("departmentSubunit", subunit)
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <BaseDatePicker
                                placeholder="Date"
                                value={filterData.date}
                                onChange={(value) => onChangeFilterData("date", value)}
                            />
                        </Col>
                        <Col span={12}>
                            <div className={styles.choiceChecklistWrap}>
                                <Radio
                                    className={styles.choiceChecklist__item}
                                    checked={filterData.status.includes("approved")}
                                    onClick={() => onChangeStatus("approved")}>
                                    Approved
                                </Radio>
                                <Radio
                                    value={2}
                                    className={styles.choiceChecklist__item}
                                    checked={filterData.status.includes("rejected")}
                                    onClick={() => onChangeStatus("rejected")}>
                                    Rejected
                                </Radio>
                            </div>
                        </Col>
                        <Col span={24}>
                            <Search
                                className={styles.search}
                                value={filterData.search}
                                onChange={(value) => onChangeFilterData("search", value)}
                                placeholder="Search"
                                onPressEnter={() => {
                                    onClickFilter()
                                    setIsShowFilter(false)
                                }}
                            />
                        </Col>
                    </Row>
                )}
            </BaseFilterContext.Consumer>
        </BaseFilter>
    )
}
