import {useSearchParam} from "hooks"

export const useActiveTab = (defaultValue: string, key = "tab") => {
    const {value: activeTabKey, onChange: onChangeTab} = useSearchParam(key, {
        defaultValue,
        initiallyUpdateDefaultValue: true,
        replace: true
    })

    return [activeTabKey, onChangeTab]
}
