import React from "react"
import {TFunction, withTranslation} from "react-i18next"
import {KlassappTableHeader, KlassappTable} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {KlassappTableProps} from "types/common"
import {handleError} from "helpers"
import {Course} from "types/courses"
import {AcademicPlans} from "types/academicPlans"
import {Checkbox, Tooltip} from "antd"
import styles from "./CoursesTable.module.css"
import {Icon} from "components"

interface PageProps {
    t: TFunction
    selectedCourses: AcademicPlans.TermCourse[] | Course.Course[]
}

type Props = KlassappTableProps & PageProps

type State = {}

class SelectedCoursesTable extends React.Component<Props, State> {
    async componentDidMount() {
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getData},
            {key: "getFields", func: this.getFields},
            {key: "getColumns", func: this.getColumns},
            {key: "onClickRowItem", func: this.onClickRowItem},
            {key: "getTableHeaderActions", func: this.getTableHeaderActions}
        ])
        this.getData()
    }

    getFields = () => {
        const {t} = this.props
        return [t("course.code"), t("course.name"), t("course.instructor"), "Class Assistance"]
    }

    getPageTitle = () => {
        return "Course"
    }

    getColumns = () => {
        const {t} = this.props

        return [
            {
                title: t("course.code"),
                field: "code",
                style: {minWidth: "150px"}
            },
            {
                title: t("course.name"),
                field: "name",
                style: {minWidth: "250px"}
            },
            {
                title: t("course.instructor"),
                field: "instructor",
                style: {minWidth: "200px"}
            },
            {
                title: "Class Assistance",
                field: "isCA",
                style: {minWidth: "20px"},
                titleHtml: (
                    <div className={styles.classAssistance}>
                        <span>Class Assistance</span>
                        <Tooltip title={"Clock hour will not be calculated"} key={"Class Assistance"}>
                            <div className={styles.classAssistanceInfo}>
                                <Icon icon={"INFO"} />
                            </div>
                        </Tooltip>
                    </div>
                )
            }
        ]
    }

    getTableHeaderActions = () => {
        return []
    }

    renderIsCA = (isCA: boolean) => {
        const {getCurrentData, dispatch} = this.props
        return (
            <Checkbox
                disabled
                checked={isCA}
                onChange={(event) => {
                    const data = getCurrentData()
                    dispatch({
                        data: data.map((item) => ({
                            ...item,
                            isCA: this.renderIsCA(event.target.checked),
                            role: event.target.checked ? Course.AcademicPlanCourseRole.ClassAssistance : undefined
                        }))
                    })
                }}
            />
        )
    }

    getData = async () => {
        const {selectedCourses, dispatch} = this.props
        try {
            dispatch({isLoading: true})
            const data = (selectedCourses || []).map((course, index) => {
                course.id = course.id || `${course.courseId}_${index}`
                course.code = course.code || course.courseCode
                course.name = course.name || course.courseName
                course.isCA = this.renderIsCA(course.role === Course.AcademicPlanCourseRole.ClassAssistance)
                return course
            })
            dispatch({data, total: data.length})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    onClickRowItem = () => {}

    render() {
        const {
            page,
            total,
            pageSize,
            columns,
            data,
            allFields,
            fields,
            orderField,
            isLoading,
            isShowTableHeaderAction,
            tableHeaderActions
        } = this.props

        return (
            <>
                <KlassappTableHeader
                    isShowAction={isShowTableHeaderAction}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.props.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    data={data}
                    menuActions={[]}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    orderField={orderField}
                    onClickRowItem={this.onClickRowItem}
                    onChangeFields={this.props.onChangeFields}
                    onUpdateRowData={this.props.onUpdateRowData}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onClickSortColumn={this.props.onClickSortColumn}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onChangeAllFields={this.props.onChangeAllFields}
                />
            </>
        )
    }
}

export default KlassappTableHOC(withTranslation(["course"])(SelectedCoursesTable))
