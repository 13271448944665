import React from "react"
import {KlassappTableContext} from "uiKit/table"
import {BaseColorPicker} from "./BaseColorPicker"
import styles from "./BaseColorPicker.module.css"

type Props = {
    value: string
    onChange?: (color: string) => void
}

export const TableColorPicker = (props: Props) => {
    return (
        <KlassappTableContext.Consumer>
            {({setIsShowScrollTable}) => (
                <div
                    onClick={() => {
                        setIsShowScrollTable(false)
                    }}>
                    <BaseColorPicker {...props} popoverClass={styles.tableColorPickerPopover} />
                </div>
            )}
        </KlassappTableContext.Consumer>
    )
}
