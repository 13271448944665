/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react"
import {BaseInput} from "components/inputs"
import {BaseButton} from "components/buttons"
import {Icon} from "components/Icon"
import styles from "./AddCreditBox.module.css"

export type AddCreditBoxMode = "start" | "stop"

export type AddCreditBoxProps = {
    amountYouWantToPay: number
    amountYouHaveToPay: number
    onAmountYouWantToPayChange: Function
    onAmountYouHaveToPayChange: Function
    percentage?: number
    mode?: AddCreditBoxMode
    onStop?
    onStart?
}

export const AddCreditBox = ({
    amountYouWantToPay,
    amountYouHaveToPay,
    onAmountYouWantToPayChange,
    onAmountYouHaveToPayChange,
    percentage = 3,
    onStart,
    onStop,
    mode = "start"
}: AddCreditBoxProps) => {
    useEffect(() => {
        _handleAmountYouWantToPayChange(amountYouWantToPay)
    }, [amountYouWantToPay])

    const _handleAmountYouWantToPayChange = (value) => {
        onAmountYouWantToPayChange(+value)
        onAmountYouHaveToPayChange(+value + value * (percentage / 100))
    }
    return (
        <div className={styles.box}>
            <div className={styles.title}>Add Sms credit</div>
            <div className={styles.notice}>
                <Icon icon="WHITE_INFO" color="#FF349B"></Icon> {percentage}% credit card processing fee will be assesed
            </div>
            <div className={styles.inputRow}>
                <div className={styles.inputColumn}>
                    <label htmlFor="lname">Enter Amount that will automatically recharged</label>
                    <BaseInput
                        type="number"
                        min={0}
                        value={amountYouWantToPay}
                        onChange={_handleAmountYouWantToPayChange}
                        className={styles.input}
                        icon={<Icon className={styles.dollarSignIcon} icon="DOLLAR_SIGN" color="#FFF" />}
                    />
                </div>
            </div>
            <div className={styles.inputRow}>
                <div className={styles.inputColumn}>
                    <label htmlFor="lname">Amount that will be charged</label>
                    <BaseInput
                        type="number"
                        min={0}
                        value={amountYouHaveToPay}
                        className={styles.input}
                        icon={<Icon className={styles.dollarSignIcon} icon="DOLLAR_SIGN" color="#FFF" />}
                    />
                </div>
            </div>
            The system will auto recharged when available credit is below 10% of the amount
            {mode === "start" && (
                <BaseButton
                    className={styles.payButton}
                    title={"start auto payment"}
                    onClick={onStart}
                    loading={false}
                />
            )}
            {mode === "stop" && (
                <BaseButton
                    variant="secondary"
                    className={styles.payButton}
                    title={"stop auto payment"}
                    onClick={onStop}
                    loading={false}
                />
            )}
        </div>
    )
}
