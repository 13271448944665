import i18n from "i18next"
import {
    chatEN,
    commonEN,
    sidebarEN,
    majorEN,
    termEN,
    workflowEN,
    courseEN,
    userEN,
    admissionEN,
    financialAidEN,
    degreeLevelEN,
    departmentsEN,
    resourcesEN,
    resourcesTypesEN,
    campusesEN,
    calendarEN,
    settingsEN,
    tasksEN,
    externshipEN,
    academicsEN,
    coaEN,
    studentEN,
    superadminEN,
    homeEN,
    documentsEN,
    activitiesEN,
    studentServicesEN,
    attendanceReportEN,
    concernEN,
    reportsEN,
    templatesEN,
    studentAccountEN,
    notificationEN
} from "../locale/en"
import {
    chatDE,
    commonDE,
    sidebarDE,
    majorDE,
    termDE,
    workflowDE,
    courseDE,
    userDE,
    admissionDE,
    financialAidDE,
    degreeLevelDE,
    departmentsDE,
    resourcesDE,
    resourcesTypesDE,
    campusesDE,
    calendarDE,
    settingsDE,
    tasksDE,
    externshipDE,
    academicsDE,
    coaDE,
    studentDE,
    superadminDE,
    homeDE,
    documentsDE,
    activitiesDE,
    studentServicesDE,
    attendanceReportDE,
    concernDE,
    reportsDE,
    templatesDE,
    studentAccountDE,
    notificationDE
} from "../locale/de"

const locale: string = typeof window !== "undefined" ? window.localStorage.getItem("locale") : "en"

i18n.init({
    interpolation: {escapeValue: false},
    lng: locale,
    fallbackLng: "en",
    resources: {
        en: {
            chat: chatEN,
            common: commonEN,
            sidebar: sidebarEN,
            major: majorEN,
            term: termEN,
            workflow: workflowEN,
            course: courseEN,
            user: userEN,
            admission: admissionEN,
            financialAid: financialAidEN,
            degreeLevel: degreeLevelEN,
            departments: departmentsEN,
            resources: resourcesEN,
            resourcesTypes: resourcesTypesEN,
            campuses: campusesEN,
            calendar: calendarEN,
            settings: settingsEN,
            tasks: tasksEN,
            externship: externshipEN,
            academics: academicsEN,
            coa: coaEN,
            student: studentEN,
            superadmin: superadminEN,
            home: homeEN,
            documents: documentsEN,
            activities: activitiesEN,
            studentServices: studentServicesEN,
            attendanceReport: attendanceReportEN,
            concern: concernEN,
            reports: reportsEN,
            templates: templatesEN,
            studentAccount: studentAccountEN,
            notification: notificationEN
        },
        de: {
            chat: chatDE,
            common: commonDE,
            sidebar: sidebarDE,
            major: majorDE,
            term: termDE,
            workflow: workflowDE,
            course: courseDE,
            user: userDE,
            admission: admissionDE,
            financialAid: financialAidDE,
            degreeLevel: degreeLevelDE,
            departments: departmentsDE,
            resources: resourcesDE,
            resourcesTypes: resourcesTypesDE,
            campuses: campusesDE,
            calendar: calendarDE,
            settings: settingsDE,
            tasks: tasksDE,
            externship: externshipDE,
            academics: academicsDE,
            coa: coaDE,
            student: studentDE,
            superadmin: superadminDE,
            home: homeDE,
            documents: documentsDE,
            activities: activitiesDE,
            studentServices: studentServicesDE,
            attendanceReport: attendanceReportDE,
            concern: concernDE,
            reports: reportsDE,
            templates: templatesDE,
            studentAccount: studentAccountDE,
            notification: notificationEN
        }
    }
})

export default i18n
