import {FIELD_OPERATOR_LABELS, FieldOperator, FieldPayload, FieldType, FieldTypeAvailableOperators} from "types/filter"
import styles from "./FilterItem.module.css"
import {Checkbox, Col, Row} from "antd"
import {KlassDropdown} from "components"
import {useMemo} from "react"

export type FilterItemProps = {
    field: string
    label: string
    type: FieldType
    data?: FieldPayload
    children: JSX.Element
    errors?: string[]
    onChangeOperator?: (operator: FieldOperator) => void
    onChangeValue?: (value: any) => void
}

export function FilterItem(props: FilterItemProps) {
    const {label, type, data, errors = [], children, onChangeOperator, onChangeValue} = props

    const operatorOptions = useMemo(() => {
        if (type === FieldType.Flag) return []
        const operators = FieldTypeAvailableOperators[type]
        return FIELD_OPERATOR_LABELS.filter((x) => operators.includes(x.id))
    }, [type])

    return (
        <Row gutter={[8, 8]}>
            <Col span={5}>
                <div className={styles.col}>
                    <span className={styles.title}>{label}</span>
                </div>
            </Col>
            <Col span={4}>
                <div className={styles.col}>
                    {type === FieldType.Flag ? (
                        <Checkbox
                            checked={!!data?.value}
                            onChange={(e) => {
                                onChangeOperator(FieldOperator.Equal)
                                onChangeValue(e.target.checked)
                            }}
                        />
                    ) : (
                        <KlassDropdown
                            options={operatorOptions}
                            value={operatorOptions.find((x) => x.id === data?.operator) ?? null}
                            onChange={(operator) => onChangeOperator(operator?.["id"] ?? null)}
                            error={errors.includes("operator")}
                            isClearable
                        />
                    )}
                </div>
            </Col>
            <Col span={15}>{children}</Col>
        </Row>
    )
}
