/* eslint-disable react-hooks/exhaustive-deps */
import {Checkbox, Col, Row} from "antd"
import cx from "classnames"
import {KlassDropdown} from "components/Select"
import {BaseButton, ProfileButton} from "components/buttons"
import {BasePopup} from "components/popup"
import {handleError, toastError, toastSuccess} from "helpers"
import {cloneDeep, keyBy} from "lodash"
import {FC, useEffect, useReducer} from "react"
import {useTranslation} from "react-i18next"
import {studentStaffContactRolesServiceV3, studentStaffContactsServiceV3} from "services"
import {Auth} from "types/auth"
import {ProfileInfo} from "types/contacts"
import {StaffInfo, StaffInfoFieldsVisibilityConfig} from "uiKit"
import styles from "./AddContactForm.module.css"
import {BaseDepartmentId} from "types/departments"
import {useModel} from "hooks"
import {AdvisorFilter} from "sections/shared/students"

type Props = {
    isShow: boolean
    onClose: Function
    data?: any
    user: any
    staffFieldVisibility?: StaffInfoFieldsVisibilityConfig
}

type SelectOption = {
    id: number
    name: string
}

type State = {
    errors: any
    newData: any
    isSubmitting: boolean
    profiles: ProfileInfo[]
    oldStaffContactRoles: SelectOption[]
    staffContactRoles: SelectOption[]
    showDefault: boolean
}

export enum StudentStaffContactRole {
    FinAidAdvisor = 1,
    AdmissionAdvisor = 2,
    AcademicAdvisor = 3,
    StaffContact = 4,
    CampusDirector = 5,
    StudentServicesAdvisor = 6,
    StudentAccountsAdvisor = 7,
    Instructor = 8,
    CareerServicesAdvisor = 9
}

export const MAP_DEPARTMENT_ADVISOR_ROLE = {
    [BaseDepartmentId.Academics]: StudentStaffContactRole.AcademicAdvisor,
    [BaseDepartmentId.Admissions]: StudentStaffContactRole.AdmissionAdvisor,
    [BaseDepartmentId.FinancialAid]: StudentStaffContactRole.FinAidAdvisor,
    [BaseDepartmentId.StudentServices]: StudentStaffContactRole.StudentServicesAdvisor,
    [BaseDepartmentId.StudentAccount]: StudentStaffContactRole.StudentAccountsAdvisor,
    [BaseDepartmentId.CareerServices]: StudentStaffContactRole.CareerServicesAdvisor
}

export const MAP_ADVISOR_ROLE_DEPARTMENT = {
    [StudentStaffContactRole.AcademicAdvisor]: BaseDepartmentId.Academics,
    [StudentStaffContactRole.AdmissionAdvisor]: BaseDepartmentId.Admissions,
    [StudentStaffContactRole.FinAidAdvisor]: BaseDepartmentId.FinancialAid,
    [StudentStaffContactRole.StudentServicesAdvisor]: BaseDepartmentId.StudentServices,
    [StudentStaffContactRole.StudentAccountsAdvisor]: BaseDepartmentId.StudentAccount,
    [StudentStaffContactRole.CareerServicesAdvisor]: BaseDepartmentId.CareerServices
}

export const MAP_ADVISOR_ROLE_MODULE_FILTER = {
    [StudentStaffContactRole.AcademicAdvisor]: AdvisorFilter.isAcademicAdvisor,
    [StudentStaffContactRole.AdmissionAdvisor]: AdvisorFilter.isAdmissionsAdvisor,
    [StudentStaffContactRole.FinAidAdvisor]: AdvisorFilter.isFinancialAidAdvisor,
    [StudentStaffContactRole.StudentServicesAdvisor]: AdvisorFilter.isStudentServicesAdvisor,
    [StudentStaffContactRole.StudentAccountsAdvisor]: AdvisorFilter.isStudentAccountAdvisor,
    [StudentStaffContactRole.CareerServicesAdvisor]: AdvisorFilter.isCareerServicesAdvisor
}

const disableDefaultByRole = ["Staff Contact", "Instructor"]

const reducer = (state, action) => {
    return {...state, ...action}
}

export const AddContactForm: FC<Props> = ({isShow, onClose, data, user, staffFieldVisibility}: Props) => {
    const initialState: State = {
        errors: {},
        newData: {studentStaffContactRoleId: ""},
        isSubmitting: false,
        profiles: [],
        oldStaffContactRoles: [],
        staffContactRoles: [],
        showDefault: true
    }

    const [{errors, newData, isSubmitting, profiles, staffContactRoles, showDefault}, dispatch] = useReducer(
        reducer,
        initialState
    )
    const model = useModel()
    const {t} = useTranslation(["user", "common"])

    useEffect(() => {
        initDataProfile(data)
        getRoles()
    }, [user])

    const getRoles = async () => {
        const results = await studentStaffContactRolesServiceV3.getAll()
        const allStaffContactRoles = results.data.map((role) => ({id: role.studentStaffContactRoleId, name: role.name}))
        const oldStaffContactRoles = cloneDeep(allStaffContactRoles)

        const staffContactRoles = getAdvisorRelationShipByRoleAndDepartment(allStaffContactRoles)
        dispatch({
            staffContactRoles,
            oldStaffContactRoles
        })
    }

    const getAdvisorRelationShipByRoleAndDepartment = (allStaffContactRoles) => {
        const userDepartmentIds = user.profiles
            .map((profile) => profile.departmentSubunits.map((subunit) => subunit.departmentId))
            .flat()
        const advisorRolesIdsByDepartments = userDepartmentIds.map(
            (deparmentId) => MAP_DEPARTMENT_ADVISOR_ROLE[deparmentId]
        )
        const advisorRolesByDepartments = allStaffContactRoles.filter((role) =>
            advisorRolesIdsByDepartments.includes(role.id)
        )
        const staffRoles = user.profiles
            .filter((profile) => profile.type === Auth.UserProfileType.Staff)
            .map((profile) => profile.roles)
            .flat()

        const advisorRoles = staffRoles.reduce(
            (result, item) => {
                result.isAcademicAdvisor = result.isAcademicAdvisor || item.isAcademicAdvisor
                result.isAdmissionsAdvisor = result.isAdmissionsAdvisor || item.isAdmissionsAdvisor
                result.isCareerServicesAdvisor = result.isCareerServicesAdvisor || item.isCareerServicesAdvisor
                result.isFinancialAidAdvisor = result.isFinancialAidAdvisor || item.isFinancialAidAdvisor
                result.isStudentAccountsAdvisor = result.isStudentAccountsAdvisor || item.isStudentAccountsAdvisor
                result.isStudentServicesAdvisor = result.isStudentServicesAdvisor || item.isStudentServicesAdvisor
                return result
            },
            {
                isAcademicAdvisor: false,
                isAdmissionsAdvisor: false,
                isCareerServicesAdvisor: false,
                isFinancialAidAdvisor: false,
                isStudentAccountsAdvisor: false,
                isStudentServicesAdvisor: false
            }
        )

        const advisorRelationShips = advisorRolesByDepartments.filter((role) => {
            const roleByDepartment = MAP_ADVISOR_ROLE_DEPARTMENT[role.id]

            switch (roleByDepartment) {
                case BaseDepartmentId.Academics:
                    return advisorRoles.isAcademicAdvisor
                case BaseDepartmentId.Admissions:
                    return advisorRoles.isAdmissionsAdvisor
                case BaseDepartmentId.CareerServices:
                    return advisorRoles.isCareerServicesAdvisor
                case BaseDepartmentId.FinancialAid:
                    return advisorRoles.isFinancialAidAdvisor
                case BaseDepartmentId.StudentAccount:
                    return advisorRoles.isStudentAccountsAdvisor
                case BaseDepartmentId.StudentServices:
                    return advisorRoles.isStudentServicesAdvisor
                default:
                    return false
            }
        })
        return advisorRelationShips
    }

    const initDataProfile = (dataProps) => {
        const initProfiles = dataProps.profiles.map((profile) => ({
            id: profile.id,
            type: profile.type,
            customProfileId: profile.customProfileId,
            isActive: false
        }))
        dispatch({profiles: initProfiles})
    }

    const onChangeData = (key, value) => {
        const newErrors = {...errors}
        let showDefaultByRole = true
        delete newErrors[key]
        if ("studentStaffContactRoleId" === key) {
            const roleName = staffContactRoles.find((role) => role.id === value)
            showDefaultByRole = !disableDefaultByRole.includes(roleName.name)
        }
        newData[key] = value
        dispatch({newData, errors: newErrors, showDefault: showDefaultByRole})
    }

    const validateForm = () => {
        const newErrors = {...errors}
        if (!newData.studentStaffContactRoleId) {
            newErrors["studentStaffContactRoleId"] = t("common:validation.fieldRequired")
        }
        const profileActive = profiles.find((profile) => profile.isActive === true)
        if (!profileActive) {
            newErrors["profileActive"] = t("common:validation.fieldRequired")
        }
        if (Object.keys(newErrors).length) {
            toastError(t("common:message.completeForm"))
            dispatch({errors: newErrors})
            return true
        }
        return false
    }

    const onSubmit = async () => {
        const isInvalid = validateForm()
        if (isInvalid) {
            return
        }
        const contactProfileId = user.profiles.find((profile) => profile.type === Auth.UserProfileType.Staff)
        dispatch({isSubmitting: true})
        try {
            const sendData = profiles
                .filter((userProfile) => userProfile.isActive === true)
                .map((userProfile) => ({
                    ...newData,
                    isDefault: newData.isDefault ? 1 : 0,
                    profileId: userProfile.id,
                    contactProfileId: contactProfileId.id
                }))
            await studentStaffContactsServiceV3.invite(sendData)
            onClose()
            toastSuccess(t("user:contact.addContactSuccess"))
        } catch (e) {
            handleError(e, true)
        } finally {
            dispatch({isSubmitting: false})
        }
    }

    const onChangeProfile = (e) => {
        const newErrors = {...errors}
        delete newErrors["profileActive"]
        const newStateProfiles = profiles.map((profile) =>
            profile.id === +e.currentTarget.id ? Object.assign(profile, {isActive: !profile.isActive}) : profile
        )
        dispatch({profiles: newStateProfiles, errors: newErrors})
    }

    const roles = staffContactRoles
    const rolesKeyById = keyBy(roles, "id")
    const roleValue = rolesKeyById[newData.studentStaffContactRoleId]

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="PERSON_FILL" leftIconColor="#fff" width="880px">
            <div className={styles.paddingContent}>
                <p className={styles.title}>{t("common:action.addContact")}</p>
                <StaffInfo user={user} fieldsVisibility={staffFieldVisibility} />
                <div className={styles.userNameLabel}>
                    <p>
                        {t("user:contact.youAdding")} <span className={styles.nameLabel}>{user.fullName}</span>{" "}
                        {t("user:contact.toYourContacts")}
                    </p>
                </div>
                <div className={styles.roleLabel}>
                    <p>{t("common:message.assignRole")}</p>
                </div>
                <Row className={styles.containerField}>
                    <Col span={24}>
                        <div>
                            <p className={styles.bodyItemTitle}>{t("user:contact.relationship")}</p>
                            <Checkbox
                                checked={newData.isDefault}
                                onChange={({target: {checked}}) => onChangeData("isDefault", checked)}
                                className={styles.checkboxItemRight}
                                disabled={!showDefault}>
                                {t("user:contact.makeDefault")}
                            </Checkbox>
                            <div>
                                <KlassDropdown
                                    onChange={(newValue) => onChangeData("studentStaffContactRoleId", newValue.id)}
                                    options={staffContactRoles}
                                    placeholder="Select"
                                    value={roleValue}
                                    className={!!errors["studentStaffContactRoleId"] ? styles.errorSelect : ""}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className={styles.containerField}>
                    <Col span={24}>
                        <div>
                            <p className={styles.bodyItemTitle}>{t("user.userProfile")}</p>
                            <div className={styles.profileButtonContainer}>
                                {profiles.map((profile) => (
                                    <ProfileButton
                                        className={cx(styles.checkboxItem, {
                                            [styles.error]: !!errors["profileActive"]
                                        })}
                                        key={profile.id}
                                        id={profile.id}
                                        isActive={profile.isActive}
                                        onClick={(event) => onChangeProfile(event)}>
                                        <span className={styles.profileLabel}> {profile.type} </span>
                                        {profile.customProfileId}
                                    </ProfileButton>
                                ))}
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className={styles.actionWrap}>
                    <div className={styles.addBtnWrap}>
                        <BaseButton
                            title={t("common:action.add")}
                            onClick={onSubmit}
                            className={styles.addBtn}
                            loading={isSubmitting}
                        />
                    </div>
                </div>
            </div>
        </BasePopup>
    )
}
