/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {settingStageService} from "services"
import styles from "./ApplicationProgress.module.css"
import {ProgressItem} from "./ProgressItem"

type ApplicationProgressProps = {
    departmentId: number
    studentId: number
    stageId: number
    currentStageId: number
    dispatch: any
}

function getColor(stage) {
    if (stage.name === "Lead") {
        return "var(--black-200)"
    }

    if (stage.name === "Start") {
        return "var(--success-400-base)"
    }

    return "var(--primary-400-base)"
}

export function ApplicationProgress(props: ApplicationProgressProps) {
    const [stages, setStages] = useState([{}])
    const [position, setPosition] = useState(0)

    async function getStages() {
        const body = {
            departmentId: props.departmentId,
            profileId: props.studentId
        }
        const {data: newStages} = await settingStageService.getStudentStages(body)
        const newStage: any = newStages.find((stage: any) => stage.stageId === props.stageId)
        setPosition(newStage?.position)
        setStages(newStages)
    }

    useEffect(() => {
        getStages()
    }, [props.stageId])

    function onChangeStage(stageId, stagePosition) {
        if (stagePosition <= position) {
            props.dispatch({currentStageId: stageId})
        }
    }

    return (
        <div className={styles.wrap}>
            <div className={styles.progressWrap}>
                {stages.map((stage: any, index) => (
                    <ProgressItem
                        key={stage.stageId || -index}
                        {...{
                            title: stage.name,
                            color: getColor(stage),
                            checked: position > stage.position,
                            completedAt: stage.completedAt,
                            isCurrent: props.stageId === stage.stageId,
                            onClick: () => onChangeStage(stage.stageId, stage.position)
                        }}
                    />
                ))}
            </div>
        </div>
    )
}
