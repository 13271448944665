import React, {useState, useEffect} from "react"
import {CardElement, useStripe, useElements} from "@stripe/react-stripe-js"
import {paymentsService} from "services"
import {CreditCardBox, AddCreditBox} from "../"

export const EditPayment = ({customer, onSubmit}) => {
    const [isEditing, setIsEditing] = useState(false)
    const [amountYouWantToPay, setAmountYouWantToPay] = useState<number>(0)
    const [amountYouHaveToPay, setAmountYouHaveToPay] = useState<number>(0)
    const stripe = useStripe()
    const elements = useElements()

    const handleRequestChange = () => {
        setIsEditing(true)
    }
    const handleOnCancel = () => {
        setIsEditing(false)
    }

    useEffect(() => {
        setAmountYouWantToPay(customer.amountInCentsToReCharge / 100)
    }, [customer])

    const handleSubmit = async () => {
        stripe
            .createPaymentMethod({
                type: "card",
                card: elements.getElement(CardElement)
            })
            .then(async function (result) {
                const {id, card} = result.paymentMethod
                await paymentsService.updateCustomer({
                    customerId: 1,
                    stripePaymentMethodId: id,
                    last4Digits: card.last4,
                    brand: card.brand
                })
                onSubmit()
                setIsEditing(false)
            })
            .catch(console.error)
    }

    const handleStart = async () => {
        await paymentsService.updateCustomer({
            customerId: 1,
            isActive: true
        })
        onSubmit()
    }

    const handleStop = async () => {
        await paymentsService.updateCustomer({
            customerId: 1,
            isActive: false
        })
        onSubmit()
    }

    return (
        <>
            <CreditCardBox
                mode={isEditing ? "update" : "details"}
                card={customer}
                onSubmit={handleSubmit}
                onRequestChange={handleRequestChange}
                onCancel={handleOnCancel}
            />
            <AddCreditBox
                mode={customer.isActive ? "stop" : "start"}
                amountYouWantToPay={amountYouWantToPay}
                amountYouHaveToPay={amountYouHaveToPay}
                onAmountYouWantToPayChange={setAmountYouWantToPay}
                onAmountYouHaveToPayChange={setAmountYouHaveToPay}
                onStart={handleStart}
                onStop={handleStop}
            />
        </>
    )
}
