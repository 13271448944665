/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useReducer, useCallback} from "react"
import {Tabs, Spin} from "antd"
import {useTranslation} from "react-i18next"
import {cloneDeep} from "lodash"
import {BasePopup} from "components/popup"
import debounce from "debounce-promise"
import {Search} from "components/inputs/Search"
import {handleError} from "helpers"
import {getUserPhotoUrl} from "helpers/user"
import {studentStaffContactsServiceV3} from "services"
import {AdvisorBody} from "./AdvisorBody"
import styles from "./AdvisorsPopup.module.css"
import {BaseDepartmentId} from "types/departments"
import {TabHeader} from "components/Tab"

type Staff = {
    staffProfileId: number
    firstName: string
    middleName: string
    lastName: string
    photo: string
    studentStaffContactId: number
    studentStaffContactRoleId: number
    studentProfileId: number
    isDefault: number
    add?: boolean
    remove?: boolean
    edit?: boolean
}

type State = {
    oldStaffs: Staff[]
    staffs: Staff[]
    isLoading: boolean
    activeTabKey: string
    search: string
}

type Props = {
    profileIds: number[]
    departmentId?: number
    campusIds?: any
    isVisible: boolean
    close: (reload?: boolean) => void
    advisorTitle: AdvisorTitle
    addMultiple?: boolean
    type?: string
}

type Module = {
    id: number
    name: string
}

const modules: Module[] = [
    {
        id: BaseDepartmentId.Admissions,
        name: "Admissions"
    },
    {
        id: BaseDepartmentId.FinancialAid,
        name: "Financial Aid"
    },
    {
        id: BaseDepartmentId.Academics,
        name: "Academics"
    },
    {
        id: BaseDepartmentId.StudentServices,
        name: "Student Services"
    }
]

export enum AdvisorTitle {
    finAidAdvisors = "finAidAdvisors",
    admissionAdvisors = "admissionAdvisors",
    advisors = "advisors"
}

export function AdvisorsPopup(props: Props) {
    function reducer(state, action) {
        return {...state, ...action}
    }
    const initialState: State = {
        oldStaffs: [],
        staffs: [],
        isLoading: false,
        activeTabKey: `${BaseDepartmentId.Admissions}`,
        search: ""
    }
    const [state, dispatch] = useReducer(reducer, initialState)
    const {oldStaffs, staffs, isLoading, activeTabKey, search} = state
    const {isVisible, advisorTitle, campusIds, profileIds, type} = props
    const {t} = useTranslation(["common"])

    useEffect(() => {
        if (profileIds?.length) {
            getStaffs()
        }
    }, [profileIds, activeTabKey])

    const getStaffs = async (search = "") => {
        dispatch({isLoading: true})
        try {
            const filter: any = {
                ...(profileIds?.length && {profileIds}),
                departmentId: props.departmentId || +activeTabKey,
                search,
                ...(type && type !== "" && {byMultipleActions: true})
            }
            if (campusIds) {
                filter.campusIds = campusIds
            }
            const {data} = await studentStaffContactsServiceV3.getStaffs({
                range: {pageSize: 20, page: 1},
                filter
            })
            const staffs = data.map((staff) => ({
                ...staff,
                photo: staff.photo ? getUserPhotoUrl(staff.photo, 32) : "/image/DefaultAvatar.png"
            }))
            dispatch({staffs, oldStaffs: cloneDeep(staffs)})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClose = (reload?: boolean) => {
        if (!reload) dispatch({staffs: cloneDeep(oldStaffs)})
        props.close(reload)
    }

    const onSearchStaff = (search) => {
        dispatch({search})
        debounceGetStaff(search)
    }

    const changeStaffs = (staffs) => {
        dispatch({staffs})
    }

    const debounceGetStaff = useCallback(debounce(getStaffs, 500), [activeTabKey, profileIds])

    const onChangeTab = (activeTabKey) => {
        dispatch({activeTabKey, search: ""})
    }

    const renderBody = (departmentId = null) => {
        if (isLoading) {
            return (
                <div className={styles.spinContainer}>
                    <Spin />
                </div>
            )
        }
        return (
            <div>
                <AdvisorBody
                    {...props}
                    departmentId={departmentId || props.departmentId}
                    staffs={staffs}
                    changeStaffs={changeStaffs}
                    getStaffs={getStaffs}
                    onClose={onClose}
                />
            </div>
        )
    }

    const renderDepartmentTabs = () => {
        return (
            <Tabs className="fullwidth" activeKey={activeTabKey} onChange={onChangeTab}>
                {modules.map((module) => {
                    const isActive = module.id === +activeTabKey
                    return (
                        <Tabs.TabPane tab={<TabHeader title={module.name} />} key={module.id}>
                            <Search value={search} onChange={onSearchStaff} placeholder={t("action.search")} />
                            {isActive && renderBody(module.id)}
                        </Tabs.TabPane>
                    )
                })}
            </Tabs>
        )
    }
    const departmentName = modules.find(({id}) => id === props.departmentId)?.name || ""
    return (
        <BasePopup isShow={isVisible} onClose={onClose} leftIcon="EDIT_LINE" leftIconColor="#fff" width="70vw">
            <Tabs className="fullwidth" activeKey="1">
                <Tabs.TabPane tab={<TabHeader title={t(`common:advisorsPopup.advisors`, {departmentName})} />} key="1">
                    {props.departmentId ? (
                        <>
                            <Search value={search} onChange={onSearchStaff} placeholder={t("action.search")} />
                            {renderBody()}
                        </>
                    ) : (
                        renderDepartmentTabs()
                    )}
                </Tabs.TabPane>
            </Tabs>
        </BasePopup>
    )
}
