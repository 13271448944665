/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useMemo, useState} from "react"
import {Tabs} from "antd"
import {get, head} from "lodash"
import {useHistory} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {Screen} from "components/ui"
import {useActiveTab, useCurrentProfilePermissions} from "hooks"
import {instructionalService, studentService} from "services"
import {getFullName, handleError} from "helpers"
import {Documents} from "sections/Documents"
import {Tasks} from "sections/shared/student"
import {Permissions} from "types/permission"
import {BaseDepartmentId} from "types/departments"
import {Academic} from "sections/academics/student/Academic"
import {AcademicsStudentTerms} from "sections/academics/student/Terms"
import {Attendance} from "sections/Attendance"
import {TabHeader} from "components/Tab"
import {AdvisorTitle, ModuleStudentHeader} from "uiKit"
import {StudentTab, AcademicPlan} from "./parts"
import {Auth} from "types/auth"
import {StudentServicesStaffTab} from "sections/StudentServices/StudentServicesStaff/StudentServicesStaff"
import {StudentServicesPanelInfo} from "sections/StudentServices/StudentServicesPanelInfo"
import {AcademicsStudentDegreeAudit} from "sections/academics/student/DegreeAudit"
import {routing} from "helpers/routing"
import {BaseLoading, Icon} from "components"
import {AcademicPlans} from "types/academicPlans"
import styles from "./StudentServicesStudent.module.css"
import {SAP} from "sections/academics/student/SAP"

const {TabPane} = Tabs

export enum StudentServicesStudentTab {
    Home = "home",
    Academic = "academic",
    Attendance = "attendance",
    TermCourses = "term-courses",
    DegreeAudit = "degree-audit",
    AcademicPlan = "academic-plan",
    Documents = "documents",
    Tasks = "tasks",
    Sap = "sap"
}

enum AcademicMode {
    Instructional = "instructional",
    AcademicAffairs = "academic-affairs"
}

export default function StudentServicesStudent(props) {
    const {departmentId, academicMode = AcademicMode.AcademicAffairs} = props
    const [activeTabKey, onChangeTab] = useActiveTab(
        academicMode !== AcademicMode.Instructional
            ? StudentServicesStudentTab.Home
            : StudentServicesStudentTab.Academic
    )
    const [courseInfo, setCourseInfo] = useState(null)
    const [activeTerm, setActiveTerm] = useState<AcademicPlans.TermCourseStats>(null)
    const [activeCourseId, setActiveCourseId] = useState(null)
    const [activeScheduleId, setActiveScheduleId] = useState(null)
    const {t} = useTranslation(["studentServices", "academics", "common"])
    const currentProfilePermissions = useCurrentProfilePermissions()
    const [student, setStudent] = useState<Auth.DepartmentStudent | undefined>()
    const [isLoading, setIsLoading] = useState(false)
    const {
        match: {
            params: {profileId}
        },
        model
    } = props
    const history = useHistory()

    async function getStudentDetail() {
        try {
            setIsLoading(true)
            const {
                data: [student]
            } = await studentService.getDepartmentStudents({
                filter: {
                    profileIds: [+profileId],
                    departmentId
                }
            })
            setStudent(student)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const onClickCourseId = (courseId: number, scheduleId: number) => {
        setActiveCourseId(courseId)
        setActiveScheduleId(scheduleId)
    }

    useEffect(() => {
        getStudentDetail()
    }, [profileId])

    const getCourseInfo = useCallback(async () => {
        if (activeCourseId && activeTerm) {
            try {
                setIsLoading(true)
                const params: any = {
                    range: {page: 1, pageSize: 1},
                    filter: {isPracticalOrClinicalServices: false, courseIds: [activeCourseId]}
                }
                if (profileId) {
                    params.filter.studentProfileIds = [+profileId]
                }
                if (activeTerm?.termId) {
                    params.filter.termIds = [activeTerm.termId]
                }
                if (activeScheduleId) {
                    params.filter.scheduleIds = [activeScheduleId]
                }
                const {data} = await instructionalService.getInstructionalMyCourse(params)
                setCourseInfo(head(data))
            } catch (error) {
                handleError(error)
            } finally {
                setIsLoading(false)
            }
        }
    }, [activeCourseId, profileId, activeTerm, activeScheduleId])

    useEffect(() => {
        getCourseInfo()
    }, [activeCourseId, profileId, activeTerm])

    const title = departmentId === BaseDepartmentId.Academics ? t("academicAffairs") : t("studentServices")

    const backUrl = useMemo(() => {
        const isStudent = model.isStudent()
        if (isStudent) {
            return null
        }
        if (departmentId === BaseDepartmentId.Academics) {
            if (academicMode === "academic-affairs") {
                return `/academic-affairs/staff?tab=${StudentServicesStaffTab.Students}`
            } else {
                const termId = Number(get(props, "match.params.termId"))
                const courseId = Number(get(props, "match.params.courseId"))
                const {fromRoute} = props
                if (fromRoute === "registrar") {
                    return routing.registrar.course(courseId, termId)
                } else {
                    return routing.academics.instructional.course(courseId, termId)
                }
            }
        } else {
            return `/student-services/staff?tab=${StudentServicesStaffTab.Students}`
        }
    }, [departmentId, academicMode])

    const onClickCustomBackButton = () => {
        setActiveCourseId(null)
    }

    const renderCustomBackButton = () => {
        return (
            <div className={styles.backBtnWrap} onClick={onClickCustomBackButton}>
                <Icon icon="BACK" color="#FF349B" className={styles.backIcon} />
            </div>
        )
    }

    const detailUrl = departmentId === BaseDepartmentId.Academics ? "academic-affairs" : "student-services"

    if (!student) {
        return (
            <Screen
                htmlTitle={title}
                header={{
                    title: getFullName(student) || "-",
                    code: get(student, "customUserId", ""),
                    backUrl
                }}>
                {isLoading ? <BaseLoading isShow /> : <p>Information not found</p>}
            </Screen>
        )
    }

    return (
        <Screen
            htmlTitle={title}
            header={{
                titleClassName: styles.screenTitle,
                title: getFullName(student) || "-",
                code:
                    activeTabKey === StudentServicesStudentTab.TermCourses && activeCourseId
                        ? courseInfo?.scheduleSuffix
                        : get(student, "customProfileId", ""),
                backUrl,
                customBackButton: activeCourseId && renderCustomBackButton
            }}
            rightPanel={{
                type: "student",
                data: {
                    student,
                    departmentId,
                    advisorTitle: AdvisorTitle.advisors,
                    renderDepartmentInfo: (props) => (
                        <StudentServicesPanelInfo
                            tab={activeTabKey}
                            student={student}
                            reloadStudent={(newProfileId) =>
                                history.replace(`/student-services/student/${newProfileId}?tab=${activeTabKey}`)
                            }
                            {...props}
                        />
                    )
                }
            }}>
            <ModuleStudentHeader
                activeModule={
                    departmentId === BaseDepartmentId.Academics
                        ? Auth.ModuleNavigation.AcademicAffairs
                        : Auth.ModuleNavigation.StudentServices
                }
                userId={student?.userId}
                customUserId={student?.customUserId}
                profileId={+profileId}
                isBackButtonActive={!!backUrl}
            />
            <Tabs
                className="fullwidth klassTab"
                activeKey={activeTabKey}
                onChange={onChangeTab}
                destroyInactiveTabPane={true}>
                {currentProfilePermissions.hasPermissions({
                    staff: [
                        Permissions.Staff.StudentServicesAndAcademicAffairs.StudentServices.Students.StudentDetail.View
                    ],
                    student: [Permissions.Student.StudentServicesAndAffairs.StudentServices.View],
                    others: [Permissions.Others.StudentServicesAndAffairs.StudentServices.View]
                }) &&
                    academicMode !== AcademicMode.Instructional && (
                        <TabPane tab={<TabHeader icon="CONTACT" title={title} />} key={StudentServicesStudentTab.Home}>
                            <StudentTab studentId={+profileId} departmentId={departmentId} detailUrl={detailUrl} />
                        </TabPane>
                    )}
                <TabPane
                    tab={<TabHeader icon="ACADEMIC" title={t("academics:registrar.academic")} />}
                    key={StudentServicesStudentTab.Academic}>
                    <Academic userId={student?.userId} studentId={+profileId} departmentId={departmentId} />
                </TabPane>
                <TabPane
                    tab={<TabHeader icon="FILE_TEXT" title={t("common:tasks.title")} />}
                    key={StudentServicesStudentTab.Tasks}>
                    <Tasks
                        studentId={+profileId}
                        departmentId={BaseDepartmentId.StudentServices}
                        history={history}
                        model={model}
                        fromPage={{
                            backUrl: `/${detailUrl}/student/${profileId}?tab=${StudentServicesStudentTab.Home}`
                        }}
                    />
                </TabPane>
                <TabPane
                    tab={<TabHeader icon="PERSON_DONE" title={t("academics:registrar.attendance")} />}
                    key={StudentServicesStudentTab.Attendance}>
                    <Attendance studentProfileId={+profileId} isShowAllMissedAttendance departmentId={departmentId} />
                </TabPane>
                <TabPane
                    tab={<TabHeader icon="COURSE" title={t("academics:registrar.termCourse")} />}
                    key={StudentServicesStudentTab.TermCourses}>
                    <AcademicsStudentTerms
                        courseId={activeCourseId}
                        scheduleId={activeScheduleId}
                        onChangeCourseId={onClickCourseId}
                        studentId={+profileId}
                        activeTerm={activeTerm}
                        setActiveTerm={setActiveTerm}
                    />
                </TabPane>
                <TabPane
                    tab={<TabHeader icon="GRADE" title={t("degreeAudit")} />}
                    key={StudentServicesStudentTab.DegreeAudit}>
                    <AcademicsStudentDegreeAudit studentId={+profileId} departmentId={departmentId} />
                </TabPane>
                <TabPane
                    tab={<TabHeader icon="FILE_ADD" title={t("academicPlan")} />}
                    key={StudentServicesStudentTab.AcademicPlan}>
                    <AcademicPlan studentId={+profileId} departmentId={departmentId} />
                </TabPane>
                <TabPane
                    tab={<TabHeader icon="FILE_TEXT" title={t("documents")} />}
                    key={StudentServicesStudentTab.Documents}>
                    <Documents
                        backUrl={`/student-services/student/${profileId}?tab=${StudentServicesStudentTab.Documents}`}
                        studentId={+profileId}
                        departmentId={departmentId}
                        model={model}
                        userId={student.userId}
                        profileId={+profileId}
                        {...props}
                    />
                </TabPane>
                {model.isStaffOrAdmin() &&
                    departmentId === BaseDepartmentId.Academics &&
                    currentProfilePermissions.hasPermissions({
                        staff: [Permissions.Staff.Settings.General.Sap.View]
                    }) && (
                        <TabPane tab={<TabHeader icon="FILE_TEXT" title="SAP" />} key={StudentServicesStudentTab.Sap}>
                            <SAP studentId={+profileId} departmentId={departmentId} />
                        </TabPane>
                    )}
            </Tabs>
        </Screen>
    )
}
