import React, {createContext, FC, ReactNode, useContext} from "react"
import styles from "./Table.module.css"
import {Preloader} from "components/preloader"
import cx from "classnames"
import {Select} from "antd"
import classNames from "classnames"
import {preventMinusCharacter} from "helpers/inputHelper"
import NumberFormat from "react-number-format"

const TableHeadContext = createContext(false)

export type TableProps = {
    className?: string
    isLoading?: boolean
    children: any
    dense?: boolean
    bordered?: boolean
}

const Table: FC<TableProps> = ({className, isLoading = false, children, dense = false, bordered = false}) => {
    return (
        <div
            className={cx(
                styles.root,
                {
                    [styles.rootDense]: dense,
                    [styles.rootBordered]: bordered
                },
                className
            )}>
            {isLoading && <Preloader />}
            <table className={styles.table}>{children}</table>
        </div>
    )
}

export type TableHeadProps = {
    className?: string
    children: any
}

const TableHead: FC<TableHeadProps> = ({className, children}) => {
    return (
        <thead className={cx(styles.head, className)}>
            <TableHeadContext.Provider value={true}>{children}</TableHeadContext.Provider>
        </thead>
    )
}

export type TableBodyProps = {
    className?: string
    children: any
}

const TableBody: FC<TableBodyProps> = ({className, children}) => {
    return <tbody className={cx(styles.body, className)}>{children}</tbody>
}

export type TableRowProps = {
    className?: string
    children: any
    hoverable?: boolean
    onClick?: () => void
}

const TableRow: FC<TableRowProps> = ({className, children, hoverable = true, onClick}) => {
    return (
        <tr
            className={cx(styles.row, className, {
                [styles.rowClickable]: !!onClick,
                [styles.rowHoverable]: hoverable
            })}
            onClick={onClick}>
            {children}
        </tr>
    )
}

export type TableCellProps = {
    className?: string
    children?: any
    align?: "left" | "center" | "right" | "stretch"
    startIcon?: ReactNode
    endIcon?: ReactNode
} & React.HTMLProps<HTMLTableCellElement>

const TableCell: FC<TableCellProps> = ({className, children, align = "left", startIcon, endIcon, ...rest}) => {
    const isTableHead = useContext(TableHeadContext)
    const Root = isTableHead ? "th" : "td"

    return (
        <Root className={cx(styles.cell, className)} {...rest}>
            <span
                className={cx(styles.cellContent, {
                    [styles.cellContentRight]: align === "right",
                    [styles.cellContentCenter]: align === "center",
                    [styles.cellContentStretch]: align === "stretch"
                })}>
                {startIcon && <span className={styles.cellContentIconStart}>{startIcon}</span>}
                {children}
                {endIcon && <span className={styles.cellContentIconEnd}>{endIcon}</span>}
            </span>
        </Root>
    )
}

export type TableCellInputProps = {
    className?: string
    inputClassName?: string
    type?: "default" | "number" | "price"
    decimals?: boolean
    value?: string | number
    onChange?: (nextValue: string) => void
} & Omit<React.HTMLProps<HTMLTableCellElement>, "onChange">

const TableCellInput: FC<TableCellInputProps> = ({
    className,
    inputClassName,
    type,
    decimals = false,
    value,
    onChange,
    ...rest
}) => {
    const isTableHead = useContext(TableHeadContext)
    const Root = isTableHead ? "th" : "td"
    const isTypePrice = type === "price"

    const inputType = ["number", "price"].includes(type) ? "number" : "text"

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event.target.value)
        }
    }

    return (
        <Root
            className={cx(
                styles.cell,
                styles.cellInput,
                {
                    [styles.cellInputPrice]: isTypePrice
                },
                className
            )}
            {...rest}>
            <span className={styles.cellInputContent}>
                {(type === "number" || type === "price") && decimals ? (
                    <NumberFormat
                        prefix="$"
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        className={classNames(styles.cellInputField, inputClassName)}
                        onKeyDown={preventMinusCharacter}
                        min={0}
                        value={value}
                        onValueChange={({value, floatValue}) => onChange?.(value)}
                    />
                ) : (
                    <>
                        {isTypePrice && <span className={styles.cellInputCurrency}>$</span>}
                        <input
                            type={inputType}
                            min={0}
                            className={classNames(styles.cellInputField, inputClassName)}
                            value={value}
                            onChange={handleChange}
                        />
                    </>
                )}
            </span>
        </Root>
    )
}

type TableCellSelectOptionValue = string | number

type TableCellSelectOption = {
    value: string | number
    label: string
}

export type TableCellSelectProps = {
    className?: string
    placeholder?: string
    options: TableCellSelectOption[]
    value: TableCellSelectOptionValue | null
    onChange: (value: TableCellSelectOptionValue) => void
}

const TableCellSelect = ({className, placeholder, options, value = null, onChange}: TableCellSelectProps) => {
    const isTableHead = useContext(TableHeadContext)
    const Root = isTableHead ? "th" : "td"

    return (
        <Root className={cx(styles.cell, styles.cellSelect, className)}>
            <Select
                size="large"
                className={styles.cellSelectField}
                bordered={false}
                placeholder={placeholder}
                value={value}
                onChange={onChange}>
                {options.map((opt) => (
                    <Select.Option key={opt.value} value={opt.value}>
                        {opt.label}
                    </Select.Option>
                ))}
            </Select>
        </Root>
    )
}

export {Table, TableHead, TableBody, TableRow, TableCell, TableCellInput, TableCellSelect}
