import {get} from "lodash"
import BaseService from "./Base"
import {StudentStatus} from "types/students"

class SettingStudentStatusService extends BaseService {
    async getAllStatuses(params = {}): Promise<{total: number; data: StudentStatus[]}> {
        const response = await this.post("/v1/settings/student-statuses/list", params)
        return get(response, "data", {})
    }

    async createStatus(params = {}) {
        const response = await this.post("/v1/settings/student-statuses/create", params)
        return get(response, "data.data", {})
    }

    async updateStatus(id, data, params = {}) {
        data.statusId = id
        const response = await this.post(`/v1/settings/student-statuses/edit`, data, params)
        return get(response, "data", {})
    }

    async deleteStatus(statusIds) {
        const response = await this.post(`/v1/settings/student-statuses/delete`, {statusIds})
        return get(response, [], {})
    }

    async getStudentStatusAutoAssignedWhenFirstAttendanceLogged(): Promise<StudentStatus | null> {
        const response = await this.post(
            `/v1/settings/student-statuses/auto-assigned-when-first-attendance-logged/get`,
            {}
        )
        return get(response, "data.data", {})
    }

    async saveStudentStatusAutoAssignedWhenFirstAttendanceLogged(statusId: number | null): Promise<void> {
        await this.post(`/v1/settings/student-statuses/auto-assigned-when-first-attendance-logged/save`, {statusId})
    }

    async saveStudentStatusesAutoAssignedToCancelledOrDroppedProfiles(
        payload: Record<"prospect" | "applicant" | "student", number | null>
    ): Promise<void> {
        await this.post(`/v1/settings/student-statuses/auto-assigned-to-cancelled-or-dropped-profiles/save`, payload)
    }
}

export default SettingStudentStatusService
