import {Node, XYPosition} from "reactflow"
import {v4 as uuidv4} from "uuid"
import {CONDITION_TYPES, ContactStrategyNodeData, ContactStrategyNodeType} from "types/contact-strategy"
import {ProcessWorkflowActionNodeData, ProcessWorkflowStepNodeData} from "types/workflow"

export enum NodeType {
    Start = "start",
    Condition = "condition",
    Action = "action",
    Step = "step"
}

export enum EdgeType {
    Default = "default",
    Custom = "custom"
}

export const INITIAL_FLOWCHART_NODES: Node[] = [
    {
        id: NodeType.Start,
        position: {x: 200, y: 50},
        data: {},
        type: NodeType.Start,
        deletable: false,
        selectable: false
    }
]

export const createContactStrategyNode = (
    type: ContactStrategyNodeType,
    position: XYPosition,
    data: ContactStrategyNodeData
): Node => ({
    id: uuidv4(),
    type: CONDITION_TYPES.includes(type) ? NodeType.Condition : NodeType.Action,
    position,
    data
})

export const getPositionForNewContactStrategyNode = (nodes: Node[], sourceId?: string): XYPosition => {
    if (!nodes.length) {
        return {x: 50, y: 50}
    }
    const x = 380 + (nodes.find((node) => node.id === sourceId)?.position.x || nodes.slice(-1)[0].position.x)
    let y = nodes.find((node) => node.id === sourceId)?.position.y || nodes.slice(-1)[0].position.y
    // eslint-disable-next-line no-loop-func
    while (nodes.find((node) => Math.abs(node.position.x - x) < 20 && Math.abs(node.position.y - y) < 20)) {
        y += 250
    }

    return {x, y}
}

export const createWorkflowNode = (
    nodeType: NodeType,
    position: XYPosition,
    data: ProcessWorkflowStepNodeData | ProcessWorkflowActionNodeData
): Node => ({
    id: uuidv4(),
    type: nodeType,
    position,
    data
})

export const getPositionForNewWorkflowNode = (nodes: Node[], sourceId?: string): XYPosition => {
    if (!nodes.length) {
        return {x: 50, y: 50}
    }

    const sourceNode = nodes.find((node) => node.id === sourceId) || nodes.slice(-1)[0]
    const x = sourceNode.position.x
    let y = sourceNode.position.y + 220

    // eslint-disable-next-line no-loop-func
    while (nodes.find((node) => Math.abs(node.position.x - x) < 20 && Math.abs(node.position.y - y) < 20)) {
        y += 220
    }

    return {x, y}
}
