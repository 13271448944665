import {get} from "lodash"
import BaseService from "./Base"

class CourseScheduleService extends BaseService {
    async getAll(params = {}) {
        const response = await this.get("/v1/course-schedules", params)
        return get(response, "data", [])
    }

    async getCourseSchedule(id: number, params = {}) {
        const response = await this.get(`/v1/course-schedules/${id}`, params)
        return get(response, "data.data", [])
    }

    async createCourseSchedule(params = {}) {
        const response = await this.post("/v1/course-schedules", params)
        return get(response, "data", [])
    }

    async createCourseScheduleBulk(params = {}) {
        const response = await this.post("/v1/course-schedules-bulk", params)
        return get(response, "data", [])
    }

    async courseScheduleGet(params = {}) {
        const response = await this.post("/v2/course-schedules/get", params)
        return get(response, "data", [])
    }

    async updateCourseSchedule(id, data) {
        data.scheduleId = id
        const response = await this.post("/v1/course-schedules/edit", data)
        return get(response, "data", [])
    }

    async deleteCourse(id) {
        const response = await this.delete(`/v1/course-schedules/${id}`, {})
        return get(response, "data", [])
    }

    async addStudents(data) {
        const response = await this.post("/v1/course-schedules/add-students", data)
        return get(response, "data", [])
    }

    async createNewCourseSchedule(data) {
        const response = await this.post("/v1/course-schedules/create-new", data)
        return get(response, "data.data", [])
    }

    async list(body) {
        const {data: response} = await this.post("/v3/course-schedule/list", {
            ...body
        })
        return {
            data: response.data,
            total: response.total
        }
    }
    async deleteCourseSchedules(ids: number[]) {
        const {data: response} = await this.post("/v3/course-schedule/delete", {id: ids})
        if (!response.success) {
            throw response
        }
    }
    async approve(courseSchedulesIds: number[]) {
        const {data: response} = await this.post("/v3/course-schedule/approve", {
            id: courseSchedulesIds
        })

        if (!response.success) {
            throw response
        }
    }

    async getAllStudents(params = {}) {
        const response = await this.post("/v1/course-schedules/students", params)
        return get(response, "data", [])
    }

    async getTermCourseSchedules(params = {}) {
        const response = await this.post("/v3/course-schedule/term-course-schedules", params)
        return get(response, "data", [])
    }
}

export default CourseScheduleService
