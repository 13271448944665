export namespace CareerServices {
    export type GraduatingInfo = {
        total: number
        placed: number
        notMet: number
    }

    export type GraduatedInfo = {
        total: number
        verified: number
        placed: number
    }

    export type GraduationTracking = {
        label: string
        count: number
    }

    export type GraduationStatistics = {
        graduating: {
            withinNext30Days: GraduatingInfo
            withinNext60Days: GraduatingInfo
            inMoreThan60Days: GraduatingInfo
        }
        graduated: {
            inLast30Days: GraduatedInfo
            inLast60Days: GraduatedInfo
            moreThan60DaysAgo: GraduatedInfo
        }
    }

    export type SalaryStatistics = {
        averageSalary: number
        highestSalary: number
        lowestSalary: number
    }

    export type StatusStatisticsItem = {
        statusId: number
        name: string
        count: number
    }

    export type PlacementStatisticsItem = {
        date: string
        count: number
    }

    export type StudentTable = {
        profileId: number
        enrollmentId: string
        fullName: string
        employmentDate: string
        position: string
        salary: string
        employer: string
        website: string
        contactPerson: string
        email: string
        phone: string
        isPlaced: boolean
        isVerified: boolean
        unemploymentType: string
        degreeConferredStatus: string
        profileState: string
    }

    export type StudentDetail = {
        profileId: number
        isConsulted: boolean
        updatedByProfileId: string
        daysToGraduation: number | null
        // placement
        isPlaced: boolean
        employmentDate: string
        positionId: number | string
        position: CareerServicePositionDetail & ObjectCreation
        contactPerson: string
        phone: string
        employer: string
        salary: string
        salaryUnit: SalaryUnit
        email: string
        website: string
        city: string
        state: string
        employmentStartDate: string
        offerDocumentUrl: string
        boardLicenses: StudentBoardLicense[]
        // verification
        isVerified: boolean
        isTaskVerified: boolean
        isEmailVerified: boolean
        isPhoneVerified: boolean
        verifiedAt: string
        verifiedPositionId: number | string
        verifiedPosition: CareerServicePositionDetail & ObjectCreation
        verifiedContactPerson: string
        verifiedPhone: string
        verifiedEmployer: string
        verifiedSalary: string
        verifiedSalaryUnit: SalaryUnit
        verifiedEmail: string
        verifiedWebsite: string
        verifiedCity: string
        verifiedState: string
        verifiedEmploymentStartDate: string
        verifiedBy?: string
        verifiedDocumentUrl?: string
        // other fields
        completedReadinessAt?: string
        readinessPercentage?: number
        // not available for employment
        unemploymentType?: UnemploymentType
        selfEmployedBusinessName: string
        selfEmployedBusinessDescription: string
        selfEmployedPhone: string
        selfEmployedEmail: string
        selfEmployedWebsite: string
        selfEmployedStartDate: string
        contEduInstitutionName: string
        contEduProgramDegreeName: string
        contEduEnrolledDate: string
        contEduExpectedGraduationDate: string
        medicalCondFromDate: string
        medicalCondToDate: string
        medicalCondDeathDate: string
        medicalCondSituation: string
        militaryMemberName: string
        militaryDutyStation: string
        militaryReportDate: string
        incarceratedInstitutionName: string
        incarceratedLengthOfSentencing: string
        othersComment: string
    }

    export enum UnemploymentType {
        SelfEmployed = "self-employed",
        ContinuingEducation = "continuing-education",
        MedicalConditions = "medical-conditions",
        MilitaryAssignment = "military-assignment",
        Incarcerated = "incarcerated",
        Others = "others"
    }

    export type EmployerAddress = {
        id?: number
        country: string
        address1: string
        city?: string
        state?: string
        postalCode?: string
    }

    export type EmployerContact = {
        id?: number
        email: string
        phone?: string
        contactType?: EmployerContactType
    }

    export type EmployerContactType = "primary" | "secondary"

    export type Employer = {
        id?: number
        code: string
        companyName: string
        description: string
        status?: string
        address: EmployerAddress
        contacts: EmployerContact[]
        logo?: string
    }

    export enum JobWorkingType {
        Remote = "remote",
        Hybrid = "hybrid",
        Office = "office"
    }

    export enum JobEmploymentType {
        FullTime = "full-time",
        PartTime = "part-time",
        Contract = "contract"
    }

    export enum JobSourceType {
        System = "system",
        Employer = "employer",
        External = "external"
    }

    export enum JobExperienceLevel {
        PostGrad = "post-grad"
    }

    export enum BoardStatus {
        None = "none",
        Failed = "failed",
        Passed = "passed"
    }

    export enum SalaryUnit {
        Hourly = "hourly",
        Weekly = "weekly",
        Monthly = "monthly",
        Annually = "annually"
    }

    export type ReactionStat = {
        totalLikes?: number
        totalDislikes?: number
        isLiked?: boolean
        isDisliked?: boolean
    }

    export type JobCategory = {
        id: number
        name: string
    }

    export type JobNoteEntity = {
        id: number
        jobId: number
        content: string
        /**
         * @format date
         */
        createdAt: string
    }

    export type JobReactionEntity = {
        id: number
        jobId: number
        reactionType: ReactionType
        createdBy: number
    }

    export type JobEntity = {
        id?: number
        employerId: number
        categories?: JobCategory[]
        code: string
        title: string
        workingType: JobWorkingType
        employmentType?: JobEmploymentType | null
        expLevel?: JobExperienceLevel | null
        salary?: string
        description: string
        openedAt?: string
        closedAt?: string
        isSystemApplied?: boolean
        isEmailApplied?: boolean
        isWebsiteApplied?: boolean
        emailAppliedAddress?: string
        websiteAppliedAddress?: string
        sourceType?: JobSourceType
        /**
         * @format date
         */
        createdAt: string
    }

    export type Job = JobEntity & {
        employer?: Employer
        studentNotes?: JobNoteEntity[]
        location?: string
        userApplication?: ApplicationEntity
    } & ReactionStat

    export enum JobApplicationStatus {
        Applied = "applied",
        InReview = "in-review",
        Interview = "interview",
        Rejected = "rejected",
        JobOffer = "job-offer",
        JobClosed = "job-closed",
        Hired = "hired"
    }

    export type ApplicationStudent = {
        id: number
        userId: number
        firstName?: string
        lastName?: string
        avatar?: string
        gender?: string
        genderPronoun?: string
    }

    export enum ApplicationNoteType {
        Employer = "employer",
        ToStudent = "to-student"
    }

    export enum ReactionType {
        Like = "like",
        Dislike = "dislike"
    }

    export enum JobApplicationSource {
        System = "system",
        Email = "email",
        Website = "website"
    }

    export enum ApplicationAttachmentType {
        Application = "application",
        JobOffer = "job-offer"
    }

    export enum ReadinessChecklistType {
        Video = "video",
        Document = "document",
        Advisor = "advisor"
    }

    export enum ReadinessChecklistStatus {
        Pending = "pending",
        InReview = "in-review",
        Rejected = "rejected",
        Completed = "completed"
    }

    export enum ApplicationNoteAuthorType {
        Employer = "employer",
        Student = "student"
    }

    export enum ApplicationNoteRespondType {
        Accepted = "accepted",
        Rejected = "rejected"
    }

    export type ApplicationNoteEntity = {
        id: number
        applicationId: number
        noteType: ApplicationNoteType
        content: string
        attachmentId?: number
        applicationStatus?: JobApplicationStatus
        authorType?: ApplicationNoteAuthorType
        respondType?: ApplicationNoteRespondType
        isResponded?: boolean
        /**
         * @format date
         */
        createdAt: string
    }

    export type ApplicationNote = ApplicationNoteEntity & {
        attachment?: ApplicationAttachmentEntity
    }

    export type ApplicationReactionEntity = {
        id: number
        applicationId: number
        reactionType: ReactionType
        createdBy: number
    }

    export type ApplicationAttachmentEntity = {
        id: number
        applicationId: number
        attachmentFileName: string
        attachmentUrl: string
        attachmentType: ApplicationAttachmentType
    }

    export type ApplicationEntity = {
        id: number
        jobId: number
        studentId: number
        /**
         * @format date
         */
        appliedAt: string | null
        status: JobApplicationStatus
        applyVia: JobApplicationSource
        emailSubject?: string
        emailContent?: string
    }

    export type Application = ApplicationEntity & {
        job: Job
        student: ApplicationStudent
        employer: Employer
        employerNotes?: ApplicationNote[]
        studentNotes?: ApplicationNote[]
        attachments?: ApplicationAttachmentEntity[]
    } & ReactionStat

    export type EditApplicationPayload = {
        id: number
        status: JobApplicationStatus
        content?: string
        attachment?: {attachmentFileName: string; attachmentUrl: string}
    }

    export type StatItem = {
        label: string
        value: number
    }

    export type JobAnalytic = {
        openJobs?: number
        newJobs?: number
        noApplications?: number
        studentWoActivity?: number
        jobSources?: StatItem[]
    }

    export type StudentReadinessChecklistEntity = {
        id?: number
        profileId: number
        checklistId: number
        status: ReadinessChecklistStatus
        studentSignedAt?: string
        studentDocumentUrl?: string
        advisorId?: number
        advisorSignatureUrl?: string
        advisorSignedAt?: string
        advisorRejectedAt?: string
    }

    export type StudentReadinessChecklist = StudentReadinessChecklistEntity & {
        checklist: ReadinessChecklistEntity
    }

    export type StudentJobSeekingStats = {
        totalApplications?: number
        /**
         * @format date
         */
        lastApplicationDate?: string | null
        pastInterviews?: number
        upcomingInterviews?: number
        /**
         * @format date
         */
        lastInterviewDate?: string | null
        /**
         * @format date
         */
        nextInterviewDate?: string | null
        totalOffers?: number
        acceptedOffers?: number
        totalRejections?: number
        /**
         * @format date
         */
        lastRejectionDate?: string | null
    }

    export type StudentCategoryFavouriteEntity = {
        id: number
        profileId: number
        categoryId: number
    }

    export type StudentCategoryFavourite = StudentCategoryFavouriteEntity & {
        categoryName: number
    }

    export type StudentKeywordFavouriteEntity = {
        id: number
        profileId: number
        keyword: string
    }

    export type ReadinessChecklistEntity = {
        id: number
        order: number
        type: ReadinessChecklistType
        name: string
        videoUrl?: string | null
        isRequired: boolean
    }

    export type CareerServicePositionDetail = {
        id: number
        name: string
    }

    export type ObjectCreation = {
        label: string
        value: string
    }

    export type StudentBoardLicense = {
        id?: number
        boardLicense: BoardStatus
        licenseName?: string
        boardDate?: string
    }

    export type StudentBaseFilters = {
        termIds: number[]
        programIds: number[]
        campusIds: number[]
        advisorProfileIds: number[]
        search: string
    }
}
