import styles from "./ProgressReportPdf.module.css"
type FooterProps = {}

const FooterPdf = (props: FooterProps) => {
    return (
        <>
            <hr className={styles.line} />
            <div className={styles.signatureBottom}>
                <div className={styles.bottomInfo}>
                    <span className={styles.signatureText}>* Indicates Retaken Course</span>
                    <span className={styles.signatureText}>R* Indicates Retaken Overide</span>
                </div>
                <span className={styles.signatureText}>Not official unless signed by Registrar</span>
                <div className={styles.bottomInfo}>
                    <span className={styles.signatureText}># Indicates Pass/Fail Course</span>
                    <span className={styles.signatureText}>+Indicates Associated Course</span>
                </div>
            </div>
        </>
    )
}

export default FooterPdf
