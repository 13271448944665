import {useQuery} from "@tanstack/react-query"
import {useMemo} from "react"
import {finAidService} from "services"
import {orderBy} from "lodash"
import {FinancialAidStatus} from "types/fin-aid/financial-aid-status"
import {Order} from "types/common"
import {UNLIMITED_PAGE_SIZE} from "data/constants"

type Options = {
    order?: {
        field: Array<keyof FinancialAidStatus>
        dir: Array<"asc" | "desc">
    }
}

const DEFAULT_ORDER: Options["order"] = {
    field: ["priority"],
    dir: ["asc"]
}

export const useAllFinancialAidStatuses = ({order = DEFAULT_ORDER}: Options = {}) => {
    const statusesQuery = useQuery<FinancialAidStatus[]>(
        ["financial-aid-statuses"],
        async () => {
            const {data, total} = await finAidService.getAllFinancialAidStatuses({
                range: {
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE
                },
                sort: {
                    orderBy: "priority",
                    orderDir: Order.Asc
                },
                withItemCount: true
            })
            return data
        },
        {
            initialData: []
        }
    )

    const statuses = useMemo(
        () => orderBy(statusesQuery.data, DEFAULT_ORDER.field, DEFAULT_ORDER.dir),
        [statusesQuery.data]
    )

    const sortedStatuses = useMemo(
        () => orderBy(statuses, order.field, order.dir),
        [statuses, order?.field, order?.dir]
    )

    return {statuses, sortedStatuses, isLoading: statusesQuery.isFetching}
}
