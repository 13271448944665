import React from "react"
import {useTranslation} from "react-i18next"
import {Every} from "sections/calendar/parts/common"

export function DailyRecurringBody(props) {
    const {t} = useTranslation("calendar")
    const {eventData, onChangeEventData, errors} = props

    return (
        <Every
            title={t("calendar.day")}
            // @ts-ignore
            error={!!errors["every"]}
            value={eventData.every}
            onChange={(newValue) => onChangeEventData("every", newValue)}
        />
    )
}
