import React from "react"
import cx from "classnames"
import styles from "./TextArea.module.css"

export function BaseTextArea(props) {
    const {
        placeholder,
        disabled,
        value,
        rows = 5,
        className,
        onChange,
        maxLength,
        classNameWrap,
        error,
        ...rest
    } = props

    const onChangeValue = (event) => {
        onChange(event.target.value)
    }

    const textAreaClassName = cx(styles.input, className, {[styles.error]: error})
    const classNameWrapContainer = cx(styles.container, classNameWrap)

    return (
        <div className={classNameWrapContainer}>
            <textarea
                rows={rows}
                className={textAreaClassName}
                onChange={onChangeValue}
                placeholder={placeholder}
                disabled={disabled}
                value={value}
                maxLength={maxLength}
                {...rest}></textarea>
            {!!maxLength && (
                <div className={styles.characterCount}>
                    {value.length}/{maxLength}
                </div>
            )}
        </div>
    )
}
