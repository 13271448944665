import React, {useState} from "react"
import {BasePopup} from "components/popup"
import {isEmpty} from "lodash"
import {BaseButton, SecondaryButton} from "components/buttons"
import {BaseTextArea} from "components/inputs"
import {useHistory} from "react-router-dom"
import {taskService} from "services"
import qs from "query-string"
import {handleError, toastError} from "helpers"
import styles from "./RejectTaskPopup.module.css"

export function RejectTaskPopup(props) {
    const {isShow, onClose, taskUserIds, taskData} = props
    const [reason, setReason] = useState("")
    const [isSaving, setIsSaving] = useState(false)
    const history = useHistory()

    const onClickSave = async () => {
        if (isEmpty(taskUserIds)) {
            toastError("taskUserIds are required")
            return
        }
        if (isEmpty(reason)) {
            toastError("Please enter the reason")
            return
        }
        try {
            setIsSaving(true)
            await taskService.rejectTaskUser({
                taskUserIds,
                reason
            })
            const url = qs.stringifyUrl(
                {
                    url: `/tasks/detail`,
                    query: {
                        id: taskData.id,
                        type: taskData.type
                    }
                },
                {skipNull: true}
            )
            history.push(url)
            onClose()
        } catch (error) {
            setIsSaving(false)
            handleError(error)
        }
    }

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="FILE_TEXT" leftIconColor="#fff" width="70vw">
            <div className={styles.wrap}>
                <p className={styles.title}>Reject task</p>
                <p className={styles.description}>Please tell us the reason why are you rejecting the task</p>
                <BaseTextArea value={reason} onChange={(value) => setReason(value)} placeholder="Reason" />
                <div className={styles.actionBtnWrap}>
                    <SecondaryButton title="Cancel" className={styles.cancelBtn} onClick={onClose} />
                    <BaseButton title="Save" onClick={onClickSave} loading={isSaving} />
                </div>
            </div>
        </BasePopup>
    )
}
