import React from "react"
import {range} from "lodash"
import styles from "./CourseItem.module.css"

type Props = {
    prevCourseDay: number
    dateRange: number
}

export function DotContent(props: Props) {
    const {prevCourseDay, dateRange} = props

    return (
        <div className={styles.dotDayWrap}>
            {range(1, dateRange).map((day) => (
                <div key={day} className={styles.dotDayItem}>
                    {prevCourseDay + day}
                </div>
            ))}
        </div>
    )
}
