import {get} from "lodash"
import BaseService from "./Base"

class ResourceService extends BaseService {
    async getAll(params = {}) {
        const response = await this.get("/v1/resources", params)
        return get(response, "data", [])
    }

    async getResource(id: number, params = {}) {
        const response = await this.get(`/v1/resources/${id}`, params)
        return get(response, "data.data", {})
    }

    async createResource(data: any, params = {}) {
        let formData = new FormData()
        Object.keys(data).forEach((key) => {
            if (Array.isArray(data[key])) {
                formData.append(key, JSON.stringify(data[key]))
            } else {
                formData.append(key, data[key])
            }
        })
        const response = await this.post("/v1/resources", formData, params)
        return get(response, "data", [])
    }

    async updateResource(id: number, data: any, params = {}) {
        let formData = new FormData()
        Object.keys(data).forEach((key) => {
            if (Array.isArray(data[key])) {
                formData.append(key, JSON.stringify(data[key]))
            } else {
                formData.append(key, data[key])
            }
        })
        const response = await this.put(`/v1/resources/${id}`, formData, params)
        return get(response, "data", [])
    }

    async deleteResource(id: number, params = {}) {
        const response = await this.delete(`/v1/resources/${id}`, params)
        return get(response, "data", [])
    }

    async getResourceHistories(resourceId: number, params = {}) {
        const response = await this.get(`/v1/resource-history/${resourceId}`, params)
        return get(response, "data.data", {})
    }
}

export default ResourceService
