import React, {useMemo} from "react"
import {KlassDropdown, KlassDropdownAsyncProps} from "../Select"
import {useAllFinancialAidStatuses} from "hooks/student-statuses/useAllFinancialAidStatuses"
import {pick} from "lodash"

type Props = Partial<KlassDropdownAsyncProps> & {}

export const FinancialAidStatusSelect: React.FC<Props> = ({...props}) => {
    const {statuses} = useAllFinancialAidStatuses()

    const statusOptions = useMemo(() => statuses.map((status) => pick(status, ["statusId", "name"])), [statuses])

    return <KlassDropdown {...props} options={statusOptions} valueKey="statusId" labelKey="name" />
}
