import axios, {AxiosInstance} from "axios"
import {store} from "helpers"
import {get} from "lodash"
import {coreSocket} from "services/index"

export enum Header {
    Authorization = "authorization",
    ProfileId = "x-profile-id"
}

class BaseService {
    protected axios: AxiosInstance
    public static authToken: string
    public static profileId: number

    constructor(private baseURL = process.env.REACT_APP_EDULAR_CORE_API_BASE_URL) {
        const http = axios.create({
            timeout: 180 * 1000,
            baseURL
        })
        http.interceptors.response.use(
            (response) => response,
            (error) => {
                const errorCode = get(error, "response.data.code")
                const user = store.get("user")
                if (errorCode === "access_disabled" && user) {
                    store.clearAll()
                    window.location.reload()
                }

                if (errorCode === "profile_access_disabled" && user) {
                    this.changeProfileId()
                    window.location.reload()
                }
                return Promise.reject(error)
            }
        )
        this.axios = http
    }

    public static getBaseUrl = () => {
        return process.env.REACT_APP_EDULAR_CORE_API_BASE_URL
    }

    public getInstance = (): AxiosInstance => {
        return axios.create({
            timeout: 180 * 1000,
            baseURL: this.baseURL,
            headers: {
                [Header.Authorization]: BaseService.authToken,
                [Header.ProfileId]: BaseService.profileId
            }
        })
    }
    public changeProfileId = () => {
        const user = store.get("user")
        if (user) {
            const profile = user.profiles.find((profile) => !profile.isAccessDisabled)
            if (!profile) {
                store.clearAll()
                window.location.href = "/"
            }
            BaseService.setProfileId(profile.id)
        }
    }

    protected setHeaderFromParams({headers, signal, responseType, baseURL, ...params}: any) {
        return {
            headers: {
                "Content-Type": "application/json",
                [Header.Authorization]: `Bearer ${BaseService.authToken}`,
                [Header.ProfileId]: BaseService.profileId,
                ...headers
            },
            signal,
            responseType,
            baseURL,
            params
        }
    }

    public static setAuthToken(token) {
        BaseService.authToken = token
    }

    public static setProfileId(profileId: number) {
        BaseService.profileId = profileId
        coreSocket.setProfileId(profileId)
        coreSocket.login()
    }

    async get(url, params = {}) {
        const config = this.setHeaderFromParams(params)
        const response = await this.axios.get(url, config)
        return response
    }

    async post(url, data, params = {}) {
        const config = this.setHeaderFromParams(params)
        const response = await this.axios.post(url, data, config)
        return response
    }

    async put(url, data, params = {}) {
        const config = this.setHeaderFromParams(params)
        const response = await this.axios.put(url, data, config)
        return response
    }

    async delete(url, params = {}) {
        const config = this.setHeaderFromParams(params)
        const response = await this.axios.delete(url, config)
        return response
    }
}

export default BaseService
