export {Permissions} from "@edular/permissions"

export enum DepartmentPermission {
    BasePermission = "0"
}

export enum BaseDepartmentPermission {
    Director = "Director",
    Chat = "Chat"
}

export enum PermissionType {
    ListView = "ListView",
    View = "View",
    Add = "Add",
    Edit = "Edit",
    Delete = "Delete"
}

export enum SpecificType {
    Director = "Director",
    Activities = "Activities",
    Documents = "Documents",
    Tasks = "Tasks"
}

export enum PermissionItem {
    View = "view",
    Add = "add",
    Edit = "edit",
    Delete = "delete"
}

export type PermissionUserType = {
    staff?: number[]
    student?: number[]
    others?: number[]
}
