import {get} from "lodash"
import BaseService from "./Base"

const basePath: string = `/v1/concerns`

class ConcernsService extends BaseService {
    async list(params) {
        const response = await this.post(`${basePath}/list`, params)
        return get(response, "data", [])
    }

    async create(data = {}) {
        const response = await this.post(`${basePath}/create`, data)
        return get(response, "data.data", {})
    }

    async update(id, data, params = {}) {
        data.concernId = id
        const response = await this.post(`${basePath}/edit`, data, params)
        return get(response, "data", {})
    }

    async getMaxId(body = {}) {
        const response = await this.post(`${basePath}/get-max-id`, body)
        return get(response, "data", [])
    }

    async exportAsCsv(data) {
        const response = await this.post(`${basePath}/export-as-csv`, data, {
            responseType: "blob"
        })
        return get(response, "data", null)
    }
}

export default ConcernsService
