import {BaseButton, BaseInput, BasePopup, BaseTextArea, Icon, SecondaryButton} from "components"
import styles from "./PopupAddMakeupAttendance.module.css"
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"
import moment from "moment"
import {useModel, useVisible} from "hooks"
import {getFullName, handleError} from "helpers"
import {academicPlansService, profileService} from "services"
import cx from "classnames"
import {Search} from "components/inputs/Search"
import {Auth} from "types/auth"
import {SignaturePopup} from "uiKit"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {AttendanceEvent, AttendanceEventStatus, AttendanceEventType} from "types/attendance"
import classNames from "classnames"
import LessonTable from "../LessonTable"
import MissedAttendanceTable from "../MissedAttendanceTable"
import {useDebounce} from "react-use"
import {FormLabel} from "components/Form"

type PopupAddMakeupAttendanceProps = {
    isShow: boolean
    onClose: () => void
    onlyCheckIn: boolean
    onRefreshData?: () => void
}

enum TableType {
    ApplyToLesson = "ApplyToLesson",
    ApplyToMissedAttendance = "ApplyToMissedAttendance"
}

const PopupAddMakeupAttendance = (props: PopupAddMakeupAttendanceProps) => {
    const {isShow, onClose, onlyCheckIn, onRefreshData} = props
    const signaturePopup = useVisible()
    const [tableType, setTableType] = useState<TableType>()
    const [listLessons, setListLessons] = useState<
        Array<{
            scheduleId: number
            courseId: number
            termId: number
            id: number
            isChecked: boolean
            calendarEventId: number
        }>
    >([])
    const [notes, setNotes] = useState<string>("")
    const model = useModel()
    const [loading, setLoading] = useState(false)
    const currentEvent = useRef<AttendanceEventType>(AttendanceEventType.Checkin)
    const [studentSignature, setStudentSignature] = useState<{signatureId: number; imageUrl: string; userId: number}>()
    const [selectedStudent, setSelectedStudent] = useState<Auth.UserProfile>()
    const [search, setSearch] = useState("")
    const [searchDebounce, setSearchDebounce] = useState("")
    const [listStudents, setListStudents] = useState<Auth.UserProfile[]>([])
    const [events, setEvents] = useState<AttendanceEvent[]>([])
    useDebounce(() => setSearchDebounce(search), 400, [search])

    const onSaveSignature = useCallback(
        (signature) => {
            signaturePopup.close()
            if (!events.length && currentEvent.current === AttendanceEventType.Checkin) {
                events.push({
                    createdAt: moment().toISOString(),
                    status: AttendanceEventStatus.Success,
                    eventType: AttendanceEventType.Checkin,
                    location: ""
                })
            } else if (currentEvent.current === AttendanceEventType.Checkout) {
                events.push({
                    createdAt: moment().toISOString(),
                    status: AttendanceEventStatus.Success,
                    eventType: AttendanceEventType.Checkout,
                    location: ""
                })
            } else if (currentEvent.current === AttendanceEventType.BreakStart) {
                const latestEvent = events[events.length - 1]
                if (latestEvent?.eventType === AttendanceEventType.BreakStart) {
                    events.push({
                        createdAt: moment().toISOString(),
                        status: AttendanceEventStatus.Success,
                        eventType: AttendanceEventType.BreakEnd,
                        location: ""
                    })
                } else if (
                    [AttendanceEventType.BreakEnd, AttendanceEventType.Checkin].includes(latestEvent?.eventType)
                ) {
                    events.push({
                        createdAt: moment().toISOString(),
                        status: AttendanceEventStatus.Success,
                        eventType: AttendanceEventType.BreakStart,
                        location: ""
                    })
                }
            }
            setStudentSignature(signature)
            setEvents([...events])
        },
        [signaturePopup, events]
    )

    const onCheckin = useCallback(() => {
        currentEvent.current = AttendanceEventType.Checkin
        signaturePopup.open()
    }, [signaturePopup])

    const onCheckout = useCallback(() => {
        currentEvent.current = AttendanceEventType.Checkout
        signaturePopup.open()
    }, [signaturePopup])

    const onBreak = useCallback(() => {
        currentEvent.current = AttendanceEventType.BreakStart
        signaturePopup.open()
    }, [signaturePopup])

    const onSave = useCallback(async () => {
        const lesson = listLessons.find((lesson) => lesson.isChecked)
        if (studentSignature) {
            try {
                setLoading(true)
                const courseId = lesson?.courseId ?? null
                const termId = lesson?.termId ?? null
                const scheduleId = lesson?.scheduleId ?? null
                const lessonId = lesson?.id ?? null
                const calendarEventId = lesson?.calendarEventId ?? null
                const createdAt = events[0].createdAt
                await academicPlansService.createClassroomMakeupAttendance({
                    academicClassroomMakeupStudents: [
                        {
                            courseId,
                            termId,
                            scheduleId,
                            lessonId,
                            createdAt,
                            studentProfileId: selectedStudent.profileId,
                            calendarEventId,
                            studentCheckInSignatureId: studentSignature.signatureId,
                            reason: notes ?? ""
                        }
                    ]
                })
                onRefreshData && onRefreshData()
                resetState()
                onClose()
            } catch (err) {
                handleError(err)
            } finally {
                setLoading(false)
            }
        }
    }, [listLessons, selectedStudent, onRefreshData, onClose, studentSignature, events, notes])

    const renderEvents = () => {
        return (
            <div className={styles.eventsContainer}>
                {events.map((event, index) => {
                    return (
                        <>
                            <div className={styles.event}>
                                {event.eventType === AttendanceEventType.Checkin ? (
                                    <Icon icon="CHECKIN_FILL" className={styles.eventIcon} color={"#18a957"} />
                                ) : null}
                                {event.eventType === AttendanceEventType.Checkout ? (
                                    <Icon icon="CHECKOUT_FILL" className={styles.eventIcon} color={"#DF1642"} />
                                ) : null}
                                {event.eventType === AttendanceEventType.BreakEnd ? (
                                    <Icon icon="ATTENDANCE_IN" className={styles.eventIcon} color={"#18a957"} />
                                ) : null}
                                {event.eventType === AttendanceEventType.BreakStart ? (
                                    <Icon icon="ATTENDANCE_OUT" className={styles.eventIcon} color={"#DF1642"} />
                                ) : null}
                                <span className={styles.eventTime}>
                                    {moment(event.createdAt).format(model.getUserTimeFormat())}
                                </span>
                            </div>
                            {index !== events?.length - 1 ? <div className={styles.eventSeparator} /> : null}
                        </>
                    )
                })}
            </div>
        )
    }

    const resetState = () => {
        setEvents([])
        setNotes("")
        setListLessons([])
        setStudentSignature(undefined)
        setSelectedStudent(undefined)
        setListStudents([])
        setSearch("")
    }

    const onCancel = useCallback(() => {
        if (selectedStudent) {
            resetState()
        } else {
            resetState()
            onClose()
        }
    }, [selectedStudent, onClose])

    const getListStudent = useCallback(async () => {
        if (searchDebounce === "") {
            setListStudents([])
            return
        }
        try {
            let {data} = await profileService.getAll({
                linkedObjects: true,
                filter: {
                    type: ["student"],
                    search: searchDebounce,
                    state: [Auth.UserProfileState.Student, Auth.UserProfileState.Alumni],
                    campuses: [],
                    excludeProfileIds: selectedStudent ? [selectedStudent.profileId] : []
                },
                range: {
                    limit: UNLIMITED_PAGE_SIZE,
                    offset: 0
                }
            })
            data = data.map((student) => ({
                ...student,
                profileId: student.id
            }))

            setListStudents(data)
        } catch (e) {
            handleError(e)
        }
    }, [searchDebounce, selectedStudent])

    useEffect(() => {
        getListStudent()
    }, [getListStudent])

    const renderUserInfo = useCallback(
        (user: Auth.UserProfile & {customProfileId?: number}, isStudent: boolean = false) => {
            let photo = "/image/DefaultAvatar.png"
            if (user?.photo) {
                photo = user.photo["256"]
            }
            const checkInEvent = events?.find((event) => event.eventType === AttendanceEventType.Checkin)

            return (
                <>
                    <p className={styles.label} style={isStudent ? {marginTop: 40} : {}}>
                        {isStudent ? "STUDENT" : "INSTRUCTOR"}
                    </p>
                    <div className={styles.instructorBox}>
                        <div className={styles.avatar}>
                            <img src={photo} alt="instructorAvt" />
                        </div>
                        <div className={styles.instructorDetail}>
                            <div className={styles.nameBox} style={{marginTop: 0}}>
                                <span className={styles.instructorName}>{getFullName(user)}</span>
                                <div className={styles.checkInBox}>
                                    {!!checkInEvent && isStudent && (
                                        <>
                                            <Icon icon="CHECKIN_FILL" className={styles.eventIcon} color={"#18a957"} />
                                            <span className={styles.eventTime}>Checkin: </span>
                                            <span className={styles.eventTime}>
                                                {moment(checkInEvent.createdAt).format(model.getUserTimeFormat())}
                                            </span>
                                            <div className={styles.statusWrap}>
                                                <div
                                                    className={classNames(
                                                        styles.signatureWrap,
                                                        styles.signature__approval
                                                    )}>
                                                    <img
                                                        src={studentSignature?.imageUrl}
                                                        alt="signature"
                                                        className={styles.signature__img}
                                                    />
                                                </div>
                                                <div className={styles.dateInfo}>
                                                    <span className={styles.dateInfo__text}>
                                                        {moment().local(true).format(model.getUserDateTimeFormat())}
                                                    </span>
                                                    <span className={styles.dateInfo__text}>{getFullName(user)}</span>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div
                                className={cx(styles.instructorInfo, {
                                    [styles.line]: isStudent
                                })}>
                                <div className={styles.nameBox} style={!isStudent ? {flex: "none"} : {}}>
                                    <span className={styles.labelUserId}>User ID</span>
                                    <span className={styles.valueUserId}>{user?.customUserId}</span>
                                </div>
                                {isStudent ? (
                                    <div className={styles.nameBox}>
                                        <span className={styles.labelUserId}>Enrollment ID:</span>
                                        <span className={styles.valueUserId}>{user?.customProfileId}</span>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </>
            )
        },
        [events, model, studentSignature]
    )

    const renderStudentAvatar = (student) => {
        let studentPhoto = "/image/DefaultAvatar.png"
        if (student?.photo) {
            studentPhoto = student.photo["64"]
        }
        return <img src={studentPhoto} className={styles.studentAvatar} alt="" />
    }

    const onSelectStudent = useCallback((student) => {
        setSelectedStudent(student)
    }, [])

    const renderStudent = useCallback(
        (student) => {
            return (
                <div onClick={() => onSelectStudent(student)} className={styles.studentItem}>
                    <div className={styles.studentDetail}>
                        {renderStudentAvatar(student)}
                        <span className={styles.studentName}>{student.customProfileId}</span>
                        <span className={styles.studentName}>{getFullName(student)}</span>
                    </div>
                    <Icon icon="ARROW_CIRCLE_RIGHT" className={styles.selectStudentIcon} />
                </div>
            )
        },
        [onSelectStudent]
    )

    const renderTitleSignature = useMemo(() => {
        return (
            <div className={styles.titleSignContainer}>
                <Icon icon={"SIGN_MAKEUP_ATTENDANCE"} />
                <span className={styles.titleSign}>STUDENT SIGNATURE</span>
                <span className={styles.subTitleSign}>CHECK IN</span>
            </div>
        )
    }, [])

    const checkin = events.find((event) => event.eventType === AttendanceEventType.Checkin)
    const checkout = events.find((event) => event.eventType === AttendanceEventType.Checkout)
    const enableButtonSave = !!checkin

    return (
        <>
            <BasePopup
                isShow={isShow}
                onClose={() => {
                    resetState()
                    onClose()
                }}
                width="90vw"
                leftIcon="PERSON_ADD">
                <div className={styles.wrap}>
                    <p className={styles.title}>Student make up</p>
                    <div className={styles.header}>
                        {selectedStudent ? (
                            <div className={styles.date}>
                                <Icon icon="CALENDAR_ATTENDANCE" color="#62B1FF" className={styles.calendarIcon} />
                                <p className={styles.textDate}>
                                    {moment().tz(selectedStudent.timezone).format(model.getUserDateFormat())}
                                </p>
                            </div>
                        ) : null}
                    </div>
                    {renderUserInfo(model.user)}

                    {!selectedStudent ? (
                        <>
                            <div className={styles.student}>
                                <span className={styles.label}>STUDENT</span>
                                <span className={styles.subLabel}>Search for a student</span>
                            </div>
                            <div className={styles.searchBar}>
                                <Search value={search} placeholder={"Search"} onChange={setSearch} />
                            </div>

                            <div className={styles.searchBar} style={{marginTop: 40, marginBottom: 8}}>
                                <span className={styles.totalStudent} style={{color: "#1e90ff", marginRight: "10px"}}>
                                    {listStudents.length}
                                </span>
                                <span className={styles.totalStudent}>STUDENTS</span>
                            </div>

                            <div className={styles.listStudent}>{listStudents.map(renderStudent)}</div>
                        </>
                    ) : (
                        <>
                            {renderUserInfo(selectedStudent, true)}
                            <div className={styles.checkContainer}>
                                <span className={styles.label}>CHECKS</span>
                            </div>
                            <div className={styles.eventContainer}>
                                {!events.length ? (
                                    <span className={styles.labelCheckLesson}>Check into a lesson</span>
                                ) : (
                                    renderEvents()
                                )}
                                <div className={styles.eventAction}>
                                    {!checkin && <BaseButton title={"CHECKIN"} onClick={onCheckin} />}
                                    {checkin && !checkout && !onlyCheckIn && (
                                        <BaseButton title={"BREAK"} onClick={onBreak} />
                                    )}
                                    {checkin && !checkout && !onlyCheckIn && (
                                        <BaseButton title={"CHECKOUT"} onClick={onCheckout} />
                                    )}
                                </div>
                            </div>
                            {/* TODO: Implement Apply to Lesson and Missed Attendance */}
                            {/* <div className={styles.titleTable}>
                                <div className={styles.titleApply}>
                                    <Checkbox
                                        checked={tableType === TableType.ApplyToLesson}
                                        onChange={(event) => {
                                            if (tableType === TableType.ApplyToLesson) {
                                                setTableType(undefined)
                                            } else {
                                                setTableType(TableType.ApplyToLesson)
                                            }
                                        }}>
                                        <span className={styles.applyToLessons}>
                                            APPLY TO LESSONS <span>(optional)</span>
                                        </span>
                                    </Checkbox>
                                </div>
                                <div className={styles.titleApply}>
                                    <Checkbox
                                        checked={tableType === TableType.ApplyToMissedAttendance}
                                        onChange={(event) => {
                                            if (tableType === TableType.ApplyToMissedAttendance) {
                                                setTableType(undefined)
                                            } else {
                                                setTableType(TableType.ApplyToMissedAttendance)
                                            }
                                        }}>
                                        <span className={styles.applyToLessons}>
                                            APPLY TO MISSED ATTENDANCE <span>(optional)</span>
                                        </span>
                                    </Checkbox>
                                </div>
                            </div> */}
                            {tableType === TableType.ApplyToLesson ? (
                                <LessonTable selectedStudent={selectedStudent} setListLessons={setListLessons} />
                            ) : tableType === TableType.ApplyToMissedAttendance ? (
                                <MissedAttendanceTable
                                    selectedStudent={selectedStudent}
                                    setListLessons={setListLessons}
                                />
                            ) : null}
                            <FormLabel label="Notes" className={styles.notesTitle} />
                            <BaseTextArea
                                value={notes}
                                onChange={(text) => {
                                    setNotes(text)
                                }}
                                placeholder={"Notes"}
                                style={{
                                    width: "100%"
                                }}
                            />
                        </>
                    )}
                    <div className={styles.buttonSubmit}>
                        <SecondaryButton title={"CANCEL"} onClick={onCancel} />
                        {selectedStudent ? (
                            <BaseButton
                                title={"SAVE"}
                                disabled={!enableButtonSave}
                                loading={loading}
                                onClick={onSave}
                            />
                        ) : (
                            <div />
                        )}
                    </div>
                </div>
                {selectedStudent && signaturePopup.isVisible && (
                    <SignaturePopup
                        isShow={signaturePopup.isVisible}
                        onClose={signaturePopup.close}
                        useOneTimeSignature
                        targetUser={{
                            type: selectedStudent?.type,
                            id: selectedStudent?.userId,
                            profileId: selectedStudent?.profileId
                        }}
                        title=""
                        autoClose
                        onSetNewSignature={onSaveSignature}
                        customTitle={renderTitleSignature}
                    />
                )}
            </BasePopup>
        </>
    )
}

export default PopupAddMakeupAttendance
