import {get} from "lodash"
import BaseService from "./Base"

export default class PhasesService extends BaseService {
    getAll = async (params = {}): Promise<{total: number; data: any[]}> => {
        const response = await this.post("/v1/settings/phases/list", params)
        return get(response, "data", {})
    }

    create = async (data: any): Promise<any> => {
        const response = await this.post("/v1/settings/phases/create", data)
        return get(response, "data.data", {})
    }

    update = async (data: any): Promise<any> => {
        const response = await this.post("/v1/settings/phases/edit", data)
        return get(response, "data.data", {})
    }

    delete = async (data: any): Promise<any> => {
        const response = await this.post("/v1/settings/phases/delete", data)
        return get(response, "data.data", {})
    }
}
