/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import {useTranslation} from "react-i18next"
import {settingActivityService} from "services"
import {KlassDropdownAsyncProps} from "../Select"
import {BaseDepartmentId} from "types/departments"
import orderBy from "lodash/orderBy"
import {KlassDropdown} from "../inputs"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {toastError} from "helpers"

type Props = Partial<KlassDropdownAsyncProps> & {
    departmentId?: number | number[]
    filterGroup?: boolean
    excludeActivityGroupId?: number
}

const ActivitiesSelect: React.FC<Props> = ({departmentId, filterGroup = false, excludeActivityGroupId, ...props}) => {
    const {t} = useTranslation(["workflow", "common"])
    const [activities, setActivities] = React.useState([])

    const activitiesGroups = React.useMemo(
        () =>
            (Object.values(BaseDepartmentId).filter((v) => typeof v === "number") as number[])
                .map((departmentId) => {
                    let options = orderBy(activities.filter((activity) => activity.departmentId === departmentId))

                    options = options.map(({activityId, name, departmentId}) => ({
                        activityId,
                        name,
                        departmentId
                    }))

                    return {
                        label: t(`workflow.statusDepartment.${departmentId}`),
                        options
                    }
                })
                .filter((group) => group.options.length > 0),
        [t, activities]
    )

    React.useEffect(() => {
        ;(async function loadActivities() {
            settingActivityService
                .getAllActivities({
                    filter: {
                        departmentIds:
                            departmentId != null
                                ? Array.isArray(departmentId)
                                    ? departmentId
                                    : [departmentId]
                                : (Object.values(BaseDepartmentId).filter((v) => typeof v === "number") as number[]),
                        isActive: true,
                        filterGroup,
                        excludeActivityGroupId
                    },
                    sort: {
                        orderBy: "name",
                        orderDir: "ASC"
                    },
                    range: {
                        page: 1,
                        pageSize: UNLIMITED_PAGE_SIZE
                    }
                })
                .then(({data: statuses}) => {
                    setActivities(statuses)
                })
                .catch((e) => {
                    toastError("Error getting activities list")
                })
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <KlassDropdown
            {...props}
            options={activitiesGroups}
            valueKey="activityId"
            labelKey="name"
            getOptionLabel={(option) =>
                `[${t(`common:moduleAbbrev.${option.departmentId || option.stage?.departmentId || option.id}`)}] ${
                    option.name
                }`
            }
        />
    )
}

export default ActivitiesSelect
