/* eslint-disable react-hooks/exhaustive-deps */
import React, {memo, useEffect, useState} from "react"
import {BaseButton} from "components/buttons"
import {Icon} from "components/Icon"
import {getUserPhotoUrl} from "helpers"
import {useModel} from "hooks"
import {userServiceV3} from "services"
import styles from "./ViewOtherUserChatCalendarButton.module.css"
import cx from "classnames"
import {DisplayMode} from "types/common"

type ViewOtherUserChatCalendarButtonProps = {
    type: "chat" | "calendar"
    displayMode?: DisplayMode
    onGoBack: () => void
    onOpen: () => void
    viewOtherUserIdChat?: number | undefined
    viewOtherUserIdCalendar?: number | undefined
}

const ViewOtherUserChatCalendarButton = memo(
    (props: ViewOtherUserChatCalendarButtonProps) => {
        const model = useModel()
        const [existsUsers, setExistsUsers] = useState(false)

        useEffect(() => {
            checkUsers()
        }, [])

        const checkUsers = async () => {
            const {total} = await userServiceV3.getUsersViewOthersChatCalendar({
                filter: {
                    sharedType: props.type
                },
                range: {
                    pageSize: 1,
                    page: 1
                }
            })
            setExistsUsers(total > 0)
        }
        const openTitle = props.type === "chat" ? "View another person's Chat" : "View another person's Calendar"
        const goBackTitle = props.type === "chat" ? "Go back to your inbox" : "Go back to your calendar"

        const modelProperty = props.type === "chat" ? "viewOtherUserChat" : "viewOtherUserCalendar"

        if (!model?.[modelProperty]?.id && existsUsers) {
            return (
                <BaseButton
                    className={styles.headerButton}
                    onClick={props.onOpen}
                    icon={<Icon icon="EYE_VIEW_PERSON" />}
                    title={openTitle}
                    variant="secondary"
                    uppercase={false}
                />
            )
        }

        if (model?.[modelProperty]?.id && existsUsers) {
            return (
                <div
                    className={cx(styles.selectedStaffButton, {
                        [styles.selectedStaffButtonInModule]: props.displayMode === "panel"
                    })}>
                    You are viewing
                    <img
                        src={
                            model?.[modelProperty]?.photo
                                ? getUserPhotoUrl(model?.[modelProperty].photo, 32)
                                : "/image/DefaultAvatar.png"
                        }
                        alt=""
                        className={styles.avatar}
                    />
                    <span className={styles.selectedStaffFullName}>
                        {model?.[modelProperty]?.customUserId} {model?.[modelProperty]?.fullName}
                    </span>
                    {props.type}
                    <Icon icon="EYE_VIEW_PERSON" />
                    <BaseButton onClick={props.onGoBack} title={goBackTitle} uppercase variant="secondary" />
                </div>
            )
        }
        return null
    },
    (prevProps, nextProps) => {
        return (
            prevProps.type === nextProps.type &&
            prevProps.viewOtherUserIdCalendar === nextProps.viewOtherUserIdCalendar &&
            prevProps.viewOtherUserIdChat === nextProps.viewOtherUserIdChat
        )
    }
)
export {ViewOtherUserChatCalendarButton}
