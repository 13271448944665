const MILESTONES_POSITION = 1000000

export function randomPosition(min, max) {
    return (max + min) / 2 + (Math.ceil(Math.random() * 1000) * (max - min)) / 100000
}

export function randomPositionFirstItem() {
    return Math.ceil((MILESTONES_POSITION / 2) * (Math.random() + 1))
}

export function getPositionMoveInObject(sortedArray, sortKey, oldIndex, newIndex, sortByAsc = false) {
    let position = 0
    if (!newIndex) {
        position = sortedArray[0][sortKey] + randomPositionFirstItem() * (sortByAsc ? -1 : 1)
    } else if (newIndex === sortedArray.length - 1) {
        position = sortedArray[sortedArray.length - 1][sortKey] + randomPositionFirstItem() * (sortByAsc ? 1 : -1)
    } else if (newIndex < oldIndex) {
        const start = sortedArray[newIndex - 1][sortKey]
        const end = sortedArray[newIndex][sortKey]
        position = randomPosition(start, end)
    } else if (newIndex > oldIndex) {
        const start = sortedArray[newIndex + 1][sortKey]
        const end = sortedArray[newIndex][sortKey]
        position = randomPosition(start, end)
    }
    if (isNaN(position)) return randomPositionFirstItem()
    return position
}

export function getPositionMoveToObject(sortedArray, sortKey, newIndex) {
    if (!sortedArray.length) return randomPositionFirstItem()
    let position = 0
    if (!newIndex) {
        position = sortedArray[0][sortKey] + randomPositionFirstItem()
    } else if (newIndex === sortedArray.length) {
        position = sortedArray[sortedArray.length - 1][sortKey] - randomPositionFirstItem()
    } else {
        const start = sortedArray[newIndex - 1][sortKey]
        const end = sortedArray[newIndex][sortKey]
        position = randomPosition(start, end)
    }

    if (isNaN(position)) return randomPositionFirstItem()

    return position
}
