import React from "react"
import {DigitalDocumentFieldSetUp} from "types/tasks"
import {Signature, Initials, DateField, Text, Checkbox} from "./parts"

export function FieldItem(props) {
    const {
        field,
        currentSignature,
        currentInitials,
        canSign,
        errorFieldIds,
        fieldRefs,
        onChangeField,
        onChangeSingleCheckboxField,
        onChangeSingleInitialsField,
        openSignaturePopup,
        openInitialPopup
    } = props

    switch (field.type) {
        case DigitalDocumentFieldSetUp.SIGNATURE:
            return (
                <Signature
                    field={field}
                    canSign={canSign}
                    openSignaturePopup={openSignaturePopup}
                    currentSignature={currentSignature}
                    errorFieldIds={errorFieldIds}
                    fieldRefs={fieldRefs}
                    onChangeField={onChangeField}
                />
            )
        case DigitalDocumentFieldSetUp.DATE_FIELD:
            return (
                <DateField
                    field={field}
                    canSign={canSign}
                    onChangeField={onChangeField}
                    errorFieldIds={errorFieldIds}
                />
            )
        case DigitalDocumentFieldSetUp.INITIAL:
            return (
                <Initials
                    field={field}
                    canSign={canSign}
                    openInitialPopup={openInitialPopup}
                    currentInitials={currentInitials}
                    errorFieldIds={errorFieldIds}
                    fieldRefs={fieldRefs}
                    onChangeField={onChangeField}
                    onChangeSingleInitialsField={onChangeSingleInitialsField}
                />
            )
        case DigitalDocumentFieldSetUp.TEXT:
            return (
                <Text
                    field={field}
                    canSign={canSign}
                    onChangeField={onChangeField}
                    errorFieldIds={errorFieldIds}
                    fieldRefs={fieldRefs}
                />
            )
        case DigitalDocumentFieldSetUp.CHECKBOX:
            return (
                <Checkbox
                    field={field}
                    canSign={canSign}
                    errorFieldIds={errorFieldIds}
                    fieldRefs={fieldRefs}
                    onChangeField={onChangeField}
                    onChangeSingleCheckboxField={onChangeSingleCheckboxField}
                />
            )
        default:
            return null
    }
}
