import {get} from "lodash"
import BaseService from "./Base"
import {CredentialLevel} from "types/degreeLevel"
import {DeepPartial} from "types/common"

class CredentialLevelService extends BaseService {
    getCredentialLevels = async (params = {}): Promise<{total: number; data: CredentialLevel[]}> => {
        const response = await this.post("/v1/credential-levels/list", params)
        return get(response, "data")
    }

    getCredentialLevel = async (credentialLevelId: number, params = {}): Promise<CredentialLevel> => {
        const response = await this.get(`/v1/credential-levels/${credentialLevelId}`, params)
        return get(response, "data.data")
    }

    createCredentialLevel = async (data: DeepPartial<CredentialLevel>): Promise<CredentialLevel> => {
        const response = await this.post(`/v1/credential-levels/create`, data)
        return get(response, "data.data")
    }

    updateCredentialLevel = async (data: DeepPartial<CredentialLevel>): Promise<CredentialLevel> => {
        const response = await this.post(`/v1/credential-levels/edit`, data)
        return get(response, "data.data")
    }

    deleteCredentialLevels = async (ids: number[]): Promise<void> => {
        const response = await this.post(`/v1/credential-levels/delete`, {ids})
        return get(response, "data")
    }
}

export default CredentialLevelService
