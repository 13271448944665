import React from "react"
import {QueryClient, QueryClientProvider} from "@tanstack/react-query"

type ReactQueryClientProviderProps = {
    children: React.ReactNode
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false
        }
    }
})

export const ReactQueryClientProvider = ({children}: ReactQueryClientProviderProps) => {
    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
