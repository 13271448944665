import {get} from "lodash"
import BaseService from "./Base"

class SettingsService extends BaseService {
    async getClientSettings(body, params = {}) {
        const response = await this.post("/v1/client-settings/public/list", body, params)
        return get(response, "data.data", [])
    }

    async getAllowedDocumentFormats() {
        const response = await this.get("/v1/public/users/documents/allowed-formats", {})
        return get(response, "data.data", [])
    }
}

export default SettingsService
