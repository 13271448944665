import React from "react"
import PaymentsTable from "./PaymentsTable"
import {useParams} from "react-router-dom"

const PaymentsTab = () => {
    const params = useParams<{profileId: string}>()
    const profileId = +params.profileId

    return <PaymentsTable profileId={profileId} />
}

export default PaymentsTab
