import {get} from "lodash"
import BaseService from "./Base"

class SettingEinService extends BaseService {
    async getAll(params) {
        const response = await this.post("/v1/settings/ein/list", params)
        return get(response, "data", [])
    }
}

export default SettingEinService
