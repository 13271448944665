import {BaseButton, BaseLoading, BasePopup, Icon, SecondaryButton} from "components"
import styles from "./AddManualDailyAttendance.module.css"
import React, {useCallback, useEffect, useState} from "react"
import moment from "moment"
import {useModel} from "hooks"
import {convertTZ, getFullName, handleError} from "helpers"
import {attendanceService, profileService} from "services"
import {Search} from "components/inputs/Search"
import {Auth} from "types/auth"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {AttendanceEvent, AttendanceEventType} from "types/attendance"
import cx from "classnames"
import {useDebounce} from "react-use"
import Clock from "./Clock"
import {get} from "lodash"

type AddManualDailyAttendanceProps = {
    isShow: boolean
    onClose: () => void
    onRefreshData?: () => void
}

const AddManualDailyAttendance = (props: AddManualDailyAttendanceProps) => {
    const {isShow, onClose} = props
    const [loading, setLoading] = useState(false)
    const model = useModel()
    const [studentSignature, setStudentSignature] = useState<{signatureId: number; imageUrl: string; userId: number}>()
    const [selectedStudent, setSelectedStudent] = useState<Auth.UserProfile>()
    const [search, setSearch] = useState("")
    const [searchDebounce, setSearchDebounce] = useState("")
    const [listStudents, setListStudents] = useState<Auth.UserProfile[]>([])
    const [events, setEvents] = useState<AttendanceEvent[]>([])
    useDebounce(() => setSearchDebounce(search), 400, [search])

    const renderButtonInOut = (eventType: AttendanceEventType) => {
        return (
            <div onClick={(e) => onPressInOut(e, eventType)} className={styles.buttonOut}>
                {[AttendanceEventType.Checkin, AttendanceEventType.BreakEnd].includes(typeButton) ? (
                    <div className={styles.actionButton} style={{backgroundColor: "#18A957"}}>
                        <Icon icon="IN_CLASS" color="white" />
                    </div>
                ) : (
                    <div className={styles.actionButton} style={{backgroundColor: "#DF1642"}}>
                        <Icon icon="OUT_CLASS" color="white" />
                    </div>
                )}
            </div>
        )
    }

    const onPressInOut = (e: React.MouseEvent, eventType: AttendanceEventType) => {
        e.stopPropagation()

        createDailyAttendance(eventType)
    }

    const renderEvents = () => {
        return (
            <div className={styles.eventsContainer}>
                {events?.map((event, index) => {
                    return (
                        <>
                            <div className={styles.event}>
                                {event.eventType === AttendanceEventType.Checkin ? (
                                    <Icon icon="CHECKIN_FILL" className={styles.eventIcon} color={"#18a957"} />
                                ) : null}
                                {event.eventType === AttendanceEventType.Checkout ? (
                                    <Icon icon="CHECKOUT_FILL" className={styles.eventIcon} color={"#DF1642"} />
                                ) : null}
                                {event.eventType === AttendanceEventType.BreakEnd ? (
                                    <Icon icon="ATTENDANCE_IN" className={styles.eventIcon} color={"#18a957"} />
                                ) : null}
                                {event.eventType === AttendanceEventType.BreakStart ? (
                                    <Icon icon="ATTENDANCE_OUT" className={styles.eventIcon} color={"#DF1642"} />
                                ) : null}
                                <span className={styles.eventTime}>
                                    {moment(event.createdAt).format(model.getUserTimeFormat())}
                                </span>
                            </div>
                            {index !== events?.length - 1 ? <div className={styles.eventSeparator} /> : null}
                        </>
                    )
                })}
            </div>
        )
    }

    const resetState = () => {
        setEvents([])
        setStudentSignature(undefined)
        setSelectedStudent(undefined)
        setListStudents([])
        setSearch("")
    }

    const onCancel = useCallback(() => {
        if (selectedStudent) {
            resetState()
        } else {
            resetState()
            onClose()
        }
    }, [selectedStudent, onClose])

    const getListStudent = useCallback(async () => {
        if (searchDebounce === "") {
            setListStudents([])
            return
        }
        try {
            let {data} = await profileService.getAll({
                linkedObjects: true,
                filter: {
                    type: ["student"],
                    search: searchDebounce,
                    state: [Auth.UserProfileState.Student, Auth.UserProfileState.Alumni],
                    campuses: []
                },
                range: {
                    limit: UNLIMITED_PAGE_SIZE,
                    offset: 0
                }
            })
            data = data.map((student) => ({
                ...student,
                profileId: student.id
            }))

            setListStudents(data)
        } catch (e) {
            handleError(e)
        }
    }, [searchDebounce])

    useEffect(() => {
        getListStudent()
    }, [getListStudent])

    const getAttendanceDetail = useCallback(async () => {
        setLoading(true)
        try {
            const {data, total} = await attendanceService.getDailyAttendanceList({
                range: {
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE
                },
                studentProfileIds: [selectedStudent.profileId],
                fromDate: moment(convertTZ(new Date(), selectedStudent.timezone)).toISOString()
            })
            const events = data
                .reduce((events, attendance) => {
                    return [...events, ...(attendance.events ?? [])]
                }, [])
                .sort(function (left, right) {
                    return moment.utc(left.createdAt).diff(moment.utc(right.createdAt))
                })

            setEvents(events)
        } catch (err) {
            handleError(err)
        } finally {
            setLoading(false)
        }
    }, [selectedStudent])

    useEffect(() => {
        if (selectedStudent) {
            getAttendanceDetail()
        }
    }, [selectedStudent, getAttendanceDetail])

    const createDailyAttendance = useCallback(
        async (eventType: AttendanceEventType) => {
            if (selectedStudent.currentMajorVersionId) {
                setLoading(true)
                try {
                    const params = {
                        majorVersionId: selectedStudent.currentMajorVersionId,
                        eventType: eventType,
                        profileId: selectedStudent.profileId,
                        campusId: selectedStudent?.campuses[0].id,
                        message: "Manual create daily attendance",
                        createdAt: moment(convertTZ(new Date(), selectedStudent.timezone)).toISOString()
                    }
                    await attendanceService.createDailyAttendance(params)
                    await getAttendanceDetail()
                } catch (err) {
                    handleError(err)
                } finally {
                    setLoading(false)
                }
            }
        },
        [getAttendanceDetail, selectedStudent]
    )

    const renderUserInfo = useCallback(
        (user: Auth.UserProfile & {customProfileId?: number}, isStudent: boolean = false) => {
            let photo = "/image/DefaultAvatar.png"
            if (user?.photo) {
                photo = user.photo["256"]
            }
            const checkInEvent = events?.find((event) => event.eventType === AttendanceEventType.Checkin)

            return (
                <>
                    <p className={styles.label} style={{marginTop: 40}}>
                        {isStudent ? "STUDENT" : "INSTRUCTOR"}
                    </p>
                    <div className={styles.instructorBox}>
                        <div className={styles.avatar}>
                            <img src={photo} alt="instructorAvt" />
                        </div>
                        <div className={styles.instructorDetail}>
                            <div className={styles.nameBox} style={{marginTop: 0}}>
                                <span className={styles.instructorName}>{getFullName(user)}</span>
                                <div className={styles.checkInBox}>
                                    {!!checkInEvent && isStudent && (
                                        <>
                                            <Icon icon="CHECKIN_FILL" className={styles.eventIcon} color={"#18a957"} />
                                            <span className={styles.eventTime}>Checkin: </span>
                                            <span className={styles.eventTime}>
                                                {moment(checkInEvent.createdAt).format(model.getUserTimeFormat())}
                                            </span>
                                            <div className={styles.statusWrap}>
                                                <div className={cx(styles.signatureWrap, styles.signature__approval)}>
                                                    <img
                                                        src={studentSignature?.imageUrl}
                                                        alt="signature"
                                                        className={styles.signature__img}
                                                    />
                                                </div>
                                                <div className={styles.dateInfo}>
                                                    <span className={styles.dateInfo__text}>
                                                        {moment().local(true).format(model.getUserDateTimeFormat())}
                                                    </span>
                                                    <span className={styles.dateInfo__text}>{getFullName(user)}</span>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div
                                className={cx(styles.instructorInfo, {
                                    [styles.line]: isStudent
                                })}>
                                <div className={styles.nameBox} style={!isStudent ? {flex: "none"} : {}}>
                                    <span className={styles.labelUserId}>User ID</span>
                                    <span className={styles.valueUserId}>{user?.customUserId}</span>
                                </div>
                                {isStudent ? (
                                    <div className={styles.nameBox}>
                                        <span className={styles.labelUserId}>Enrollment ID:</span>
                                        <span className={styles.valueUserId}>{user?.customProfileId}</span>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </>
            )
        },
        [events, model, studentSignature]
    )

    const renderStudentAvatar = (student) => {
        let studentPhoto = "/image/DefaultAvatar.png"
        if (student?.photo) {
            studentPhoto = student.photo["64"]
        }
        return <img src={studentPhoto} className={styles.studentAvatar} alt="" />
    }

    const onSelectStudent = useCallback((student) => {
        setSelectedStudent({...student, currentMajorVersionId: student.currentMajorVersionId?.id})
    }, [])

    const renderStudent = useCallback(
        (student) => {
            return (
                <div onClick={() => onSelectStudent(student)} className={styles.studentItem}>
                    <div className={styles.studentDetail}>
                        {renderStudentAvatar(student)}
                        <span className={styles.studentName}>{student.customProfileId}</span>
                        <span className={styles.studentName}>{getFullName(student)}</span>
                    </div>
                    <Icon icon="ARROW_CIRCLE_RIGHT" className={styles.selectStudentIcon} />
                </div>
            )
        },
        [onSelectStudent]
    )

    let typeButton: AttendanceEventType = AttendanceEventType.Checkin
    const latestEvent = events.length ? get(events, [events.length - 1]) : undefined
    if (latestEvent && latestEvent.eventType === AttendanceEventType.BreakStart) {
        typeButton = AttendanceEventType.BreakEnd
    } else if (latestEvent && latestEvent.eventType === AttendanceEventType.Checkin) {
        typeButton = AttendanceEventType.BreakStart
    }

    return (
        <>
            <BasePopup
                isShow={isShow}
                onClose={() => {
                    resetState()
                    onClose()
                }}
                width="90vw"
                leftIcon="PERSON_ADD">
                <div className={styles.wrap}>
                    <p className={styles.title}>Add daily attendance</p>
                    <div className={styles.header}>
                        <div className={styles.date}>
                            <Icon icon="CALENDAR_ATTENDANCE" color="#62B1FF" className={styles.calendarIcon} />
                            <p className={styles.textDate}>
                                {moment().format(model.getUserDateFormat())}
                                {selectedStudent ? " - " : ""}
                            </p>
                            {selectedStudent ? <Clock timezone={selectedStudent.timezone} /> : null}
                        </div>
                    </div>

                    {!selectedStudent ? (
                        <>
                            <div className={styles.student}>
                                <span className={styles.label}>STUDENT</span>
                                <span className={styles.subLabel}>Search for a student</span>
                            </div>
                            <div className={styles.searchBar}>
                                <Search value={search} placeholder={"Search"} onChange={setSearch} />
                            </div>

                            <div className={styles.searchBar} style={{marginTop: 40, marginBottom: 8}}>
                                <span className={styles.totalStudent} style={{color: "#1e90ff", marginRight: "10px"}}>
                                    {listStudents.length}
                                </span>
                                <span className={styles.totalStudent}>STUDENTS</span>
                            </div>

                            <div className={styles.listStudent}>{listStudents.map(renderStudent)}</div>
                        </>
                    ) : (
                        <>
                            {renderUserInfo(selectedStudent, true)}
                            <div className={styles.checkContainer}>
                                <span className={styles.label}>CHECKS</span>
                            </div>
                            <div className={styles.eventContainer}>
                                {!events.length ? (
                                    <span className={styles.labelCheckLesson}>Check daily attendance</span>
                                ) : (
                                    renderEvents()
                                )}
                                <div className={styles.eventAction}>
                                    {renderButtonInOut(typeButton)}
                                    {[AttendanceEventType.Checkin, AttendanceEventType.BreakEnd].includes(
                                        latestEvent?.eventType
                                    ) ? (
                                        <BaseButton
                                            title="Check out"
                                            variant="secondary"
                                            loading={loading}
                                            className={styles.cancelButton}
                                            onClick={() => {
                                                createDailyAttendance(AttendanceEventType.Checkout)
                                            }}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </>
                    )}
                    <div className={styles.buttonSubmit}>
                        <SecondaryButton title={"CANCEL"} onClick={onCancel} />
                        <div />
                    </div>
                </div>
                <BaseLoading isShow={loading} />
            </BasePopup>
        </>
    )
}

export default AddManualDailyAttendance
