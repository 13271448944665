import React, {Component} from "react"
import Select, {Props} from "react-select"

interface DropdownProps extends Props {
    onChange: any
    options: any
    value: any
    placeholder?: any
    valueKey?: string
    labelKey?: string
    stylesCustom?: any
}

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: "6px",
        borderColor: "transparent",
        border: "none",
        boxShadow: state.isFocused
            ? "0 0 0 2px var(--primary-400-base), 0 16px 24px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05)"
            : "0 16px 24px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05)",
        paddingLeft: "8px"
    }),

    container: (provided) => ({
        ...provided,
        height: "40px"
    }),

    indicatorSeparator: () => ({
        display: "none"
    }),

    dropdownIndicator: (provided, state) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        width: "40px",
        height: "39px",
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
        backgroundColor: "var(--primary-400-base)",
        borderColor: "var(--primary-400-base)",
        justifyContent: "center",
        color: "var(--whitebase)",
        "&:hover": {color: "var(--whitebase)"},
        cursor: "pointer"
    }),

    placeholder: (provided) => ({
        ...provided,
        fontSize: "16px",
        color: "var(--black-200)"
    })
}

export class Dropdown extends Component<DropdownProps, {}> {
    public render() {
        const {stylesCustom, valueKey, labelKey, ...rest} = this.props
        let valueKeySelect = valueKey || "id"
        let labelKeySelect = valueKey || "name"
        let styles = customStyles
        if (stylesCustom) styles = {...styles, ...stylesCustom}
        const props = {...rest, styles}

        return (
            <Select
                getOptionValue={(option: any) => option[valueKeySelect]}
                getOptionLabel={(option: any) => option[labelKeySelect]}
                {...props}
            />
        )
    }
}
