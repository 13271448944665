import React from "react"
import {Tabs} from "antd"
import {useActiveTab} from "hooks"
import {ClassroomAttendance, DailyAttendance, MissedAttendance} from "sections/Attendance/parts"
import {Course} from "types/courses"
import {TabHeader} from "components/Tab"

const {TabPane} = Tabs

const ATTENDANCE_TABS = {
    CLASSROOM_ATTENDANCE: "1",
    DAILY_ATTENDANCE: "2",
    MISSED_ATTENDANCE: "3"
}

type Props = {
    courseId: number
    courseInfo: Course.CourseTableItem
    termIds: number[]
    scheduleId?: number
}

export function AttendanceOverviewTab(props: Props) {
    const {courseId, courseInfo, termIds, scheduleId} = props
    const [activeTab, onChangeTab] = useActiveTab(ATTENDANCE_TABS.CLASSROOM_ATTENDANCE, "type")

    return (
        <Tabs className="fullwidth" activeKey={activeTab} onChange={onChangeTab}>
            <TabPane key={ATTENDANCE_TABS.CLASSROOM_ATTENDANCE} tab={<TabHeader title="Lesson Attendance" />}>
                {activeTab === ATTENDANCE_TABS.CLASSROOM_ATTENDANCE && (
                    <ClassroomAttendance scheduleId={scheduleId} termIds={termIds} courseId={courseId} />
                )}
            </TabPane>
            <TabPane key={ATTENDANCE_TABS.MISSED_ATTENDANCE} tab={<TabHeader title="Missed" />}>
                {activeTab === ATTENDANCE_TABS.MISSED_ATTENDANCE && (
                    <MissedAttendance
                        scheduleId={scheduleId}
                        termIds={termIds}
                        attendanceTrackingType={Course.ProgramAttendanceTrackingType.Course}
                        courseId={courseId}
                    />
                )}
            </TabPane>
        </Tabs>
    )
}
