import {get} from "lodash"
import BaseService from "./Base"

class SettingStatusService extends BaseService {
    async getAllStatuses(params = {}) {
        const response = await this.post("/v1/settings/statuses/list", params)
        return get(response, "data", [])
    }

    async createStatus(params = {}) {
        const response = await this.post("/v1/settings/statuses/create", params)
        return get(response, "data.data", {})
    }

    async duplicateStatus(statuses) {
        const response = await this.post(`/v1/settings/statuses/create`, statuses)
        return get(response, "data", {})
    }

    async updateStatus(id, data, params = {}) {
        data.statusId = id
        const response = await this.post(`/v1/settings/statuses/edit`, data, params)
        return get(response, "data", {})
    }

    async deleteStatus(statusIds) {
        const response = await this.post(`/v1/settings/statuses/delete`, {statusIds})
        return get(response, [], {})
    }
}

export default SettingStatusService
