/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react"
import {SketchPicker} from "react-color"
import cx from "classnames"
import styles from "./BaseColorPicker.module.css"

type Props = {
    value: string
    onChange?: (color: string) => void
    popoverClass?: string
}

export function BaseColorPicker(props: Props) {
    const [isShow, setIsShow] = useState(false)
    const [color, setColor] = useState("#fff")
    const {value, onChange, popoverClass} = props

    useEffect(() => {
        setColor(value)
    }, [value])

    const toggleShowColorPicker = () => {
        setIsShow(!isShow)
    }

    const onClose = () => {
        setIsShow(false)
    }

    const onChangeColor = (color) => {
        const newColor = color.hex
        setColor(newColor)
        if (onChange) {
            onChange(newColor)
        }
    }

    return (
        <div>
            <div className={styles.swatch} onClick={toggleShowColorPicker}>
                <div className={styles.color} style={{background: color}} />
            </div>
            {isShow && (
                <div className={cx(styles.popover, popoverClass)}>
                    <div className={styles.cover} onClick={onClose} />
                    <SketchPicker color={color} onChange={onChangeColor} disableAlpha />
                </div>
            )}
        </div>
    )
}
