import React from "react"
import {Icon} from "components/Icon"
import {NotificationItem} from "./NotificationItem"
import styles from "./Notifications.module.css"
import {Communication} from "types/communication"

type Props = {
    notification: Communication.NotificationSummaryItem
    title: string
    onClickNotificationGroup?: (notification: Communication.NotificationSummaryItem) => void
}

export function NotificationGroupItem(props: Props) {
    const {notification, title, onClickNotificationGroup} = props

    return (
        <div className={styles.notificationItemWrap}>
            <div className={styles.groupHeaderWrap}>
                <div className={styles.groupHeaderInfo}>
                    <span className={styles.groupHeaderInfo__title}>{title}</span>
                    <span className={styles.groupHeaderInfo__count}>{notification.unread}</span>
                </div>
                <div className={styles.groupHeaderAction} onClick={() => onClickNotificationGroup(notification)}>
                    <span className={styles.groupHeaderAction__text}>Open</span>
                    <div className={styles.groupHeaderAction__iconWrap}>
                        <Icon icon="ARROW_LEFT_LINE" size={16} />
                    </div>
                </div>
            </div>
            <NotificationItem notificationMessage={notification.notification} type={notification.source} />
        </div>
    )
}
