import React, {useState, useEffect} from "react"
import {PaymentElement, CardElement} from "@stripe/react-stripe-js"
import {Icon} from "components/Icon"
import styles from "./CreditCardBox.module.css"
import {BaseButton, BaseInput} from "components"

export type CreditCardComponentType = "new" | "details" | "update"

type CreditCardType = {
    brand: string
    last4Digits: number
}

export type CreditCardBoxProps = {
    mode?: CreditCardComponentType
    onSubmit?: () => void
    onRequestChange?: () => void
    card?: CreditCardType
    onCancel?: () => void
}

export const CreditCardBox = ({mode = "new", card, onSubmit, onRequestChange, onCancel}: CreditCardBoxProps) => {
    const [isCreditCardValid, setIsCreditCardValid] = useState(false)

    useEffect(() => {
        const googlePayButton = document.getElementById("google_pay-tab")
        const cardTabButton = document.getElementById("card-tab")

        if (googlePayButton) {
            googlePayButton.remove()
        }
        if (cardTabButton) {
            cardTabButton.remove()
        }
    }, [])

    const cardStyle = {
        style: {
            base: {
                width: "100%",
                background: "#fffz",
                color: "#939393",
                fontFamily: "Arial, sans-serif",
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "939393"
                }
            },
            invalid: {
                fontFamily: "Arial, sans-serif",
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    }
    const _handleReplace = () => {
        if (isCreditCardValid) {
            onSubmit()
        }
    }
    const _handleCardElementChange = (ev) => {
        setIsCreditCardValid(ev.complete)
    }
    return (
        <div>
            <div>
                <div className={styles.title}>Credit Card</div>
            </div>
            <div className={styles.box}>
                {mode === "new" ? (
                    <PaymentElement />
                ) : mode === "update" ? (
                    <div className={styles.cardElement}>
                        <CardElement id="card-element" options={cardStyle} onChange={_handleCardElementChange} />
                        <div className={styles.creditCardButtonWrapper}>
                            <BaseButton
                                variant="secondary"
                                title="Cancel"
                                className={styles.cancelButton}
                                onClick={onCancel}
                            />
                            <BaseButton
                                variant="primary"
                                title="Replace Credit Card"
                                disabled={!isCreditCardValid}
                                onClick={_handleReplace}
                            />
                        </div>
                    </div>
                ) : (
                    <div>
                        <BaseInput
                            type="text"
                            style={{
                                color: "black",
                                marginTop: "48px",
                                marginRight: "-12px"
                            }}
                            value={`${card?.brand} **** **** **** ${card?.last4Digits}`}
                            disabled={true}
                        />
                        <div className={styles.creditCardButtonWrapper}>
                            <BaseButton variant="primary" title="Replace Credit Card" onClick={onRequestChange} />
                        </div>
                    </div>
                )}

                <div className={styles.dataEncrypted}>
                    <Icon icon="LOCK_ACCESS_FILL" color="#939393" />
                    <label className={styles.dataEncryptedLabel}>DATA IS ENCRYPTED</label>
                </div>
            </div>
        </div>
    )
}
