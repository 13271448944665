import BaseService from "./Base"
import {ApiListResponse, ApiResponse} from "types/api"
import {AxiosResponse} from "axios"

export class EventTypeService extends BaseService {
    getAll = async (body = {}) => {
        const {data: response}: AxiosResponse<ApiListResponse<any[]>> = await this.post("/v1/event-type/list", {
            ...body,
            linkedEntities: ["lastUpdatedBy"]
        })
        if (!response.success) {
            throw response
        }

        return {
            data: response.data,
            total: response.total
        }
    }

    getOne = async (eventTypeId: number): Promise<any | null> => {
        const {data: response}: AxiosResponse<ApiResponse<any[]>> = await this.post("/v1/event-type/list", {
            filter: {
                id: [eventTypeId]
            },
            linkedEntities: ["lastUpdatedBy"]
        })

        if (!response.success) {
            throw response
        }

        return response.data[0] ?? null
    }

    create = async (payload: any) => {
        try {
            const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/event-type/create", payload)

            if (!response.success) {
                throw response
            }
        } catch (e: any) {
            throw e
        }
    }
    duplicate = async (eventTypeIds: number[]) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/event-type/duplicate", {
            id: eventTypeIds
        })

        if (!response.success) {
            throw response
        }
    }

    update = async (payload: any) => {
        try {
            const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/event-type/edit", payload)

            if (!response.success) {
                throw response
            }
        } catch (e: any) {
            throw e
        }
    }

    deleteBulk = async (eventTypeIds: number[]) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/event-type/delete", {
            id: eventTypeIds
        })

        if (!response.success) {
            throw response
        }
    }
}
