/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {authService} from "services"
import {BaseLoading} from "components"
import styles from "./AutoLogin.module.css"

type UrlParams = {
    email: string
    code: string
}

const AutoLogin = ({model}) => {
    const [loading, setLoading] = useState(true)
    const params = useParams<UrlParams>()

    useEffect(() => {
        ;(async () => {
            try {
                const payload = {
                    slug: model.slug,
                    email: params.email,
                    otp: params.code,
                    device: "web"
                }
                const {user, token} = await authService.otpSignIn(payload)
                await model.setStorage(token, user.id, user.profiles[0].id)
                model.history.replace(`/`)
            } catch (e) {
                model.history.replace(`/`)
            }
            setLoading(false)
        })()
    }, [params])

    return (
        <div className={styles.root}>
            <BaseLoading isShow={loading} />
        </div>
    )
}

export default AutoLogin
