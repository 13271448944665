import {AcademicPlans} from "types/academicPlans"
import {Major} from "types/major"

export const termsTest: AcademicPlans.TermCourseStats[] = [
    {
        attendancePercentage: 10,
        code: "TermCode",
        endDate: "2023-01-19T00:00:00.000Z",
        gpa: 1,
        name: "Term Title 1",
        id: 1,
        makeUpHours: 1,
        maxTimeframe: 1,
        type: Major.Unit.Clock,
        missedAttendanceHours: 1,
        startDate: "2022-12-19T00:00:00.000Z",
        termId: 1,
        title: "Term Title 1",
        totalHours: 1,
        totalHoursAttempted: 1,
        totalHoursCompleted: 1,
        totalHoursRemaining: 1,
        totalProgress: 100,
        totalTransferHours: 1
    },
    {
        attendancePercentage: 10,
        code: "TermCode",
        endDate: "2023-02-19T00:00:00.000Z",
        gpa: 1,
        id: 2,
        name: "Term Title 2",
        makeUpHours: 1,
        maxTimeframe: 1,
        type: Major.Unit.Clock,
        missedAttendanceHours: 1,
        startDate: "2023-01-19T00:00:00.000Z",
        termId: 1,
        title: "Term Title 2",
        totalHours: 1,
        totalHoursAttempted: 1,
        totalHoursCompleted: 1,
        totalHoursRemaining: 1,
        totalProgress: 100,
        totalTransferHours: 1
    },
    {
        attendancePercentage: 10,
        code: "TermCode",
        name: "Term Title 2",
        endDate: "2023-03-19T00:00:00.000Z",
        gpa: 1,
        id: 3,
        makeUpHours: 1,
        maxTimeframe: 1,
        type: Major.Unit.Clock,
        missedAttendanceHours: 1,
        startDate: "2023-02-19T00:00:00.000Z",
        termId: 1,
        title: "Term Title 3",
        totalHours: 1,
        totalHoursAttempted: 1,
        totalHoursCompleted: 1,
        totalHoursRemaining: 1,
        totalProgress: 30,
        totalTransferHours: 1
    },
    {
        attendancePercentage: 10,
        code: "TermCode",
        name: "Term Title 2",
        endDate: "2023-04-19T00:00:00.000Z",
        gpa: 1,
        id: 4,
        makeUpHours: 1,
        maxTimeframe: 1,
        type: Major.Unit.Clock,
        missedAttendanceHours: 1,
        startDate: "2023-03-19T00:00:00.000Z",
        termId: 1,
        title: "Term Title 4",
        totalHours: 1,
        totalHoursAttempted: 1,
        totalHoursCompleted: 1,
        totalHoursRemaining: 1,
        totalProgress: 0,
        totalTransferHours: 1
    },
    {
        attendancePercentage: 10,
        code: "TermCode",
        name: "Term Title 2",
        endDate: "2023-05-19T00:00:00.000Z",
        gpa: 1,
        id: 5,
        makeUpHours: 1,
        maxTimeframe: 1,
        type: Major.Unit.Clock,
        missedAttendanceHours: 1,
        startDate: "2023-04-19T00:00:00.000Z",
        termId: 1,
        title: "Term Title 5",
        totalHours: 1,
        totalHoursAttempted: 1,
        totalHoursCompleted: 1,
        totalHoursRemaining: 1,
        totalProgress: 0,
        totalTransferHours: 1
    }
]
