import React, {ReactNode} from "react"
import classes from "./IconButton.module.css"
import cx from "classnames"

export type IconButtonProps = {
    className?: string
    children: ReactNode
    disabled?: boolean
    onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

export const IconButton = ({className, children, disabled, onClick}: IconButtonProps) => {
    const handleClick = onClick
        ? (event) => {
              event.stopPropagation()
              if (!disabled) onClick(event)
          }
        : undefined

    return (
        <span
            className={cx(
                classes.root,
                {
                    [classes.rootHoverable]: !!onClick
                },
                className
            )}
            onClick={handleClick}>
            {children}
        </span>
    )
}
