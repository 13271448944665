import React from "react"
import {Moment} from "moment-timezone"
import cx from "classnames"
import styles from "./CourseItem.module.css"

type Props = {
    dates: Moment[]
}

export function DaysInfo(props: Props) {
    const {dates} = props

    return (
        <div className={cx(styles.dotDayWrap, styles.daysInfo)}>
            {dates.map((date, index) => {
                return (
                    <div key={index} className={styles.dayOfTerm}>
                        <span>{date.format("ddd")}</span>
                        <span>{date.format("DD MMM")}</span>
                    </div>
                )
            })}
        </div>
    )
}
