import React from "react"
import classNames from "classnames"
import styles from "../StudentsTable.module.css"

export function ResizeHandler({
    refEl,
    colName,
    onMousedown,
    onMouseup,
    resizeCursor,
    resizingColumn,
    virtualResizeWidth
}) {
    const resizeHandleStyle = React.useMemo(() => ({cursor: resizeCursor}), [resizeCursor])
    const resizeLineStyle = React.useMemo(() => ({right: virtualResizeWidth}), [virtualResizeWidth])

    return (
        <>
            <div
                ref={refEl}
                className={styles.resizeDragHandle}
                onMouseDown={(event) => onMousedown(event, colName)}
                onMouseUp={onMouseup}
                style={resizeHandleStyle}
            />
            {resizingColumn === colName && (
                <div
                    className={classNames(styles.resizeDragHandle, styles.virtualResizeLine)}
                    style={resizeLineStyle}
                />
            )}
        </>
    )
}
