import React, {useEffect, useRef} from "react"
import "mapbox-gl/dist/mapbox-gl.css"
import mapboxgl from "mapbox-gl"

import styles from "./StudentsHeatMap.module.css"

/**
 * ℹ️ NOTE
 * See https://github.com/mapbox/mapbox-gl-js/issues/10173#issuecomment-750489778
 */
//  @ts-ignore
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default //eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

export type StudentsHeatMapProps = {}

export const StudentsHeatMap = (props: StudentsHeatMapProps) => {
    const mapContainerRef = useRef(null)
    const mapRef = useRef<mapboxgl.Map | null>(null)

    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: "mapbox://styles/mapbox/streets-v11",
            center: [-79.999732, 40.4374],
            zoom: 11
        })

        map.on("load", () => {
            map.addSource("trees", {
                type: "geojson",
                data: require("./trees.geojson")
            })

            map.addLayer(
                {
                    id: "trees-heat",
                    type: "heatmap",
                    source: "trees",
                    maxzoom: 15,
                    paint: {
                        "heatmap-weight": {
                            property: "dbh",
                            type: "exponential",
                            stops: [
                                [1, 0],
                                [62, 1]
                            ]
                        },
                        "heatmap-intensity": {
                            stops: [
                                [11, 1],
                                [15, 3]
                            ]
                        },
                        "heatmap-color": [
                            "interpolate",
                            ["linear"],
                            ["heatmap-density"],
                            0,
                            "rgba(0, 0, 0, 0)",
                            0.15,
                            "rgb(127, 0, 255)",
                            0.3,
                            "rgb(0, 0, 255)",
                            0.45,
                            "rgb(0, 255, 255)",
                            0.6,
                            "rgb(0, 255, 0)",
                            0.75,
                            "rgb(255, 255, 0)",
                            0.9,
                            "rgb(255, 165, 0)",
                            1,
                            "rgb(255, 0, 0)"
                        ],
                        "heatmap-radius": {
                            stops: [
                                [11, 15],
                                [15, 20]
                            ]
                        },
                        "heatmap-opacity": {
                            default: 0.6,
                            stops: [
                                [14, 0.6],
                                [15, 0]
                            ]
                        }
                    }
                },
                "waterway-label"
            )

            map.addLayer(
                {
                    id: "trees-point",
                    type: "circle",
                    source: "trees",
                    minzoom: 14,
                    paint: {
                        "circle-radius": {
                            property: "dbh",
                            type: "exponential",
                            stops: [
                                [{zoom: 15, value: 1}, 5],
                                [{zoom: 15, value: 62}, 10],
                                [{zoom: 22, value: 1}, 20],
                                [{zoom: 22, value: 62}, 50]
                            ]
                        },
                        "circle-color": {
                            property: "dbh",
                            type: "exponential",
                            stops: [
                                [0, "rgba(236,222,239,0)"],
                                [10, "rgb(236,222,239)"],
                                [20, "rgb(208,209,230)"],
                                [30, "rgb(166,189,219)"],
                                [40, "rgb(103,169,207)"],
                                [50, "rgb(28,144,153)"],
                                [60, "rgb(1,108,89)"]
                            ]
                        },
                        "circle-stroke-color": "white",
                        "circle-stroke-width": 1,
                        "circle-opacity": {
                            stops: [
                                [14, 0],
                                [15, 1]
                            ]
                        }
                    }
                },
                "waterway-label"
            )
        })

        map.on("click", "trees-point", (event) => {
            new mapboxgl.Popup()
                .setLngLat((event.features[0].geometry as any).coordinates)
                .setHTML(`<strong>DBH:</strong> ${event.features[0].properties.dbh}`)
                .addTo(map)
        })

        mapRef.current = map
    }, [])

    return (
        <div className={styles.root}>
            <div className={styles.map} ref={mapContainerRef} />
        </div>
    )
}
