import {get} from "lodash"
import BaseService from "./Base"
import {getBlobFromBase64String} from "helpers"
import {UserAutocomplete, UserSignatureDetails} from "types/user"

class UserService extends BaseService {
    async generateToken(data, params = {}) {
        const response = await this.post("/users/1.2/login", data, params)
        return get(response, "data", {})
    }

    async getUserById(params = {}) {
        const response = await this.post(`/v3/users/list`, params)
        return get(response, "data.data[0]", {})
    }

    async getAllUsers(params = {}, search = "", role = "") {
        const searchUrl = search ? `&search=${search}` : ""
        const roleUrl = role ? `&role=${role}` : ""
        const url = `/v3/users?limit=50&offset=0${searchUrl}${roleUrl}`
        const response = await this.get(url, params)
        return get(response, "data.data", {})
    }

    async updateUserById(id, data: any = {}) {
        data.id = id
        const response = await this.post(`/v3/users/edit`, data)
        return get(response, "data.data")
    }

    async updateProfileUserById(id, data: any = {}) {
        data.id = id
        const response = await this.post(`/v3/users/profiles`, data)
        return get(response, "data.data")
    }

    async uploadUserImage(id, params = {}, load?) {
        const response = await this.post(`/v3/users/photo/${id}`, params, load)
        return get(response, "data.data")
    }

    async updateCourse(id, data, params = {}) {
        const response = await this.put(`/v1/courses/${id}`, data, params)
        return get(response, "data", [])
    }

    async getCoursesByStudent(id, data, params = {}) {
        const response = await this.put(`/v1/student/${id}/student-courses`, data, params)
        return get(response, "data", [])
    }

    async updateCourses(id, data, params = {}) {
        const response = await this.put(`/v1/student/${id}/student-courses`, data, params)
        return get(response, "data", [])
    }

    async updateTerms(id, data, params = {}) {
        const response = await this.put(`/v1/student/${id}/student-terms`, data, params)
        return get(response, "data", [])
    }

    async getCurrentSignature(params = {}) {
        const response = await this.post(`/v1/users/signatures/my/current`, params)
        return get(response, "data", [])
    }

    async getAllSignatures(params = {}) {
        const response = await this.post(`/v1/users/signatures/my/list`, params)
        return get(response, "data", [])
    }

    async getSignaturesList(params = {}) {
        const response = await this.post(`/v1/users/signatures/list`, params)
        return get(response, "data", [])
    }

    async deleteUserSignature(params = {}) {
        const response = await this.post(`/v1/users/signatures/delete`, params)
        return get(response, "data", [])
    }

    async updateCurrentSignature(body = {}) {
        const response = await this.post(`/v1/users/signatures/my/update`, body)
        return get(response, "data", {})
    }

    async getCurrentInitials(params = {}) {
        const response = await this.post(`/v1/users/initials/my/current`, params)
        return get(response, "data", [])
    }

    async getAllInitials(params = {}) {
        const response = await this.post(`/v1/users/initials/my/list`, params)
        return get(response, "data", [])
    }

    async getInitialsList(params = {}) {
        const response = await this.post(`/v1/users/initials/list`, params)
        return get(response, "data", [])
    }

    async deleteUserInitials(params = {}) {
        const response = await this.post(`/v1/users/initials/delete`, params)
        return get(response, "data", [])
    }

    async updateCurrentInitials(body = {}) {
        const response = await this.post(`/v1/users/initials/my/update`, body)
        return get(response, "data", {})
    }

    async deleteMyAccount(body = {}) {
        const response = await this.post(`/v3/users/delete`, body)
        return get(response, "data", {})
    }

    async getStudentData(body = {}) {
        const response = await this.post(`/v1/task/student/list`, body)
        return get(response, "data.data", {})
    }

    updateCurrentSignatureUsingBase64Image = async (base64: string) => {
        const file = await getBlobFromBase64String(base64)
        const formData = new FormData()
        formData.append("file", file)
        return await this.updateCurrentSignature(formData)
    }

    createSignature = async (body: FormData): Promise<UserSignatureDetails> => {
        const response = await this.post(`/v1/users/signatures/create`, body)
        return get(response, "data", {})
    }

    updateCurrentInitialsUsingBase64Image = async (base64: string) => {
        const file = await getBlobFromBase64String(base64)
        const formData = new FormData()
        formData.append("file", file)
        return await this.updateCurrentInitials(formData)
    }

    async getBase64Img(imageUrls) {
        const response = await this.post("/v1/images/get-base64", {imageUrls})
        return get(response, "data.data", [])
    }

    async downloadSignatureCertificate(params = {}) {
        const response = await this.post("/v1/users/signatures/certificate", params, {
            responseType: "blob"
        })
        return get(response, "data", {})
    }

    async downloadInitialsCertificate(params = {}) {
        const response = await this.post("/v1/users/initials/certificate", params, {
            responseType: "blob"
        })
        return get(response, "data", {})
    }

    async getModuleOption() {
        const response = await this.post("/v1/module-options/list", {})
        return get(response, "data.data", {})
    }

    async getReportModuleOptions() {
        const response = await this.post("/v1/module-options/list-reports", {})
        return get(response, "data.data", {})
    }

    async searchUsers(params): Promise<{total: number; data: UserAutocomplete[]}> {
        const response = await this.post("/v1/users/autocomplete", params)
        return get(response, "data", {})
    }

    async getLabels(params = {}) {
        const response = await this.post("/v1/labels/list", params)
        return get(response, "data.data", {})
    }
}

export default UserService
