import {get} from "lodash"
import {ApiListResponseResult} from "types/api"
import BaseService from "./Base"

class IntegrationsService extends BaseService {
    constructor() {
        super(process.env.REACT_APP_INTEGRATIONS)
    }
    async list(params = {}): Promise<ApiListResponseResult<any[]>> {
        const response = await this.post("/v1/errors/get", params)
        return get(response, "data", [])
    }
}

export default IntegrationsService
