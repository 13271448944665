import React from "react"
import {Model} from "Model"
import {Modal} from "antd"
import {Icon} from "components/Icon"
import styles from "./EventPopup.module.css"
import {CalendarEventForm} from "./CalendarEventForm"

type EventPopupProps = {
    model: Model
    calendarId: number
    isShow: boolean
    initialEventData: any
    disabledTypeEvents?: string[]
    onCreateSuccess: Function
    onClose: () => void
}

export const CalendarEventPopup = (props: EventPopupProps) => {
    const {isShow, onClose} = props

    return (
        <Modal
            keyboard={false}
            closable={false}
            visible={isShow}
            onCancel={onClose}
            closeIcon={<Icon icon="CLOSE" color="#000" className={styles.closeIcon} />}
            maskClosable={false}
            footer={null}
            className="eventPopup">
            <CalendarEventForm {...props} />
        </Modal>
    )
}
