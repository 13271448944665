import React, {useEffect} from "react"
import styles from "./buttonCheckbox.module.css"
import cx from "classnames"

type PropsType = {
    id: string
    name: string
    onClick: () => void
    allChecked?: boolean
    classNameCheckBox?: string
    label?: string
}

const ButtonCheckbox = (props: PropsType) => {
    const [checked, setChecked] = React.useState(false)

    const {id, name, onClick, allChecked, label} = props

    useEffect(() => {
        setChecked(allChecked)
    }, [allChecked])

    return (
        <span className={styles.checkbox_container}>
            <input
                type="checkbox"
                onClick={onClick}
                className={cx(styles.checkbox, props.classNameCheckBox)}
                id={id}
                name={name}
                checked={checked || allChecked}
                onChange={() => setChecked(!checked)}
            />
            <label htmlFor={id}>{label ?? ""}</label>
        </span>
    )
}

export default ButtonCheckbox
