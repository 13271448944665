import React from "react"
import {cloneDeep} from "lodash"
import classNames from "classnames"
import {SecondaryButton} from "components/buttons"
import {KlassappTableHeader, KlassappTable} from "uiKit"
import {Icon} from "components/Icon"
import {KlassappTableHOC} from "HOC"
import {KlassappTableProps} from "types/common"
import {ReorderFundsPopup} from "./parts"
import styles from "./FinAidTab.module.css"
import {tableData} from "./rawData"

interface PageProps {
    history?: any
    t: Function
}

type Props = KlassappTableProps & PageProps

class FinAidTab extends React.Component<Props, {}> {
    state = {
        isOpenReorderFundsPopup: false
    }

    componentDidMount() {
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getData},
            {key: "getFields", func: this.getFields},
            {key: "getColumns", func: this.getColumns},
            {key: "onClickDeleteMulti", func: this.onClickDeleteMulti},
            {key: "onClickDuplicateMulti", func: this.onClickDuplicateMulti},
            {key: "onClickEdit", func: this.onClickEdit},
            {key: "onClickDelete", func: this.onClickDelete},
            {key: "onClickRowItem", func: this.onClickRowItem}
        ])
        this.getData()
    }

    getFields = () => {
        return ["Item", "Amount", "Due Date", "Status", "Order"]
    }

    getPageTitle = () => {
        return "Fin Aid"
    }

    getColumns = () => {
        return [
            {
                title: "Item",
                field: "item",
                style: {minWidth: "300px"}
            },
            {
                title: "Amount",
                field: "amount",
                style: {minWidth: "150px"}
            },
            {
                title: "Due Date",
                field: "dueDate",
                style: {minWidth: "150px"}
            },
            {
                title: "Status",
                field: "statusHtml",
                style: {minWidth: "150px"}
            },
            {
                title: "Order",
                field: "orderHtml",
                style: {minWidth: "150px"}
            }
        ]
    }

    renderHeaderChildTitle = (title) => {
        return <span className={styles.headerChildTitle}>{title}</span>
    }

    onToggleRow = (event, rowItem) => {
        event.stopPropagation()
        const {data} = this.props
        if (rowItem.isOpenChild) {
            const newData = data
                .filter((item) => item.parentId !== rowItem.id)
                .map((item) => {
                    if (item.id === rowItem.id) {
                        item.isOpenChild = false
                    }
                    item.item = this.renderItemHtml(item)
                    item.statusHtml = this.renderStatusHtml(item)
                    item.orderHtml = this.renderOrderHtml(item)
                    return item
                })
            this.props.dispatch({data: newData})
        } else {
            let findIndex = -1
            let childs = data.find((item, index) => {
                if (item.id === rowItem.id) {
                    findIndex = index
                    return true
                }
                return false
            }).childs
            if (childs && childs.length) {
                let newData = cloneDeep(data)
                childs = childs.map((childItem) => {
                    childItem.cellTableStyle = styles.cellChildTableStyle
                    return childItem
                })
                newData.splice(findIndex + 1, 0, ...childs)
                newData = newData.map((item: any) => {
                    if (item.id === rowItem.id) {
                        item.isOpenChild = true
                    }
                    item.item = this.renderItemHtml(item)
                    item.statusHtml = this.renderStatusHtml(item)
                    item.orderHtml = this.renderOrderHtml(item)
                    return item
                })
                this.props.dispatch({data: newData})
            }
        }
    }

    renderItemHtml = (item) => {
        return (
            <div className={styles.chargeWrap}>
                {!item.parentId ? (
                    <div className={styles.arrowWrap} onClick={(e) => this.onToggleRow(e, item)}>
                        <Icon icon={item.isOpenChild ? "ARROW_DOWN" : "ARROW_RIGHT"} className={styles.arrowIcon} />
                    </div>
                ) : (
                    <div className={styles.arrowWrap}></div>
                )}
                <p className={styles.charge__code}>{item.code}</p>
                <p className={styles.charge__name}>{item.title}</p>
            </div>
        )
    }

    renderStatusHtml = (item) => {
        return (
            <div
                className={classNames(styles.status, {
                    [styles.status__pastDue]: item.status === "pastDue",
                    [styles.status__ordered]: item.status === "ordered",
                    [styles.status__rejected]: item.status === "rejected",
                    [styles.status__upcoming]: item.status === "upcoming"
                })}>
                {item.status === "pastDue" ? "Past Due" : item.status}
            </div>
        )
    }

    renderOrderHtml = (item) => {
        if (!item.orderStatus) {
            return <></>
        }
        return (
            <div className={classNames(styles.orderStatus)} onClick={() => this.onClickPopupByStatus(item.orderStatus)}>
                {item.orderStatus}
            </div>
        )
    }

    onClickPopupByStatus = (status) => {
        switch (status) {
            default:
                this.setState({isOpenReorderFundsPopup: true})
        }
    }

    getData = async () => {
        const {dispatch} = this.props
        dispatch({isLoading: true})
        try {
            const newData = tableData.map((item: any) => {
                item.isOpenChild = false
                item.item = this.renderItemHtml(item)
                item.statusHtml = this.renderStatusHtml(item)
                item.orderHtml = this.renderOrderHtml(item)
                return item
            })
            dispatch({data: newData, total: 1})
        } catch (e) {
            console.log("e")
        } finally {
            dispatch({isLoading: false})
        }
    }

    onClickRowItem = (row) => {}

    onClickDeleteMulti = async () => {}

    onClickDuplicateMulti = async () => {}

    onClickEdit = (editedItem) => {}

    onClickDelete = async (deletedItem) => {}

    onCloseReorderFundsPopup = () => {
        this.setState({isOpenReorderFundsPopup: false})
    }

    render() {
        const {isOpenReorderFundsPopup} = this.state
        const {
            page,
            total,
            pageSize,
            columns,
            data,
            allFields,
            fields,
            tableHeaderActions,
            orderField,
            isLoading,
            isShowTableHeaderAction
        } = this.props

        return (
            <div className={styles.wrap}>
                <div className={styles.headerWrap}>
                    <div className={styles.headerActionWrap}>
                        <SecondaryButton title="REFUND" className={styles.headerActionItem} />
                        <SecondaryButton title="FINANACIAL AID PLAN" />
                    </div>
                </div>
                <KlassappTableHeader
                    isShowAction={isShowTableHeaderAction}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.props.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    fields={fields}
                    data={data}
                    menuActions={[]}
                    isLoading={isLoading}
                    allFields={allFields}
                    orderField={orderField}
                    onClickRowItem={this.onClickRowItem}
                    onChangeFields={this.props.onChangeFields}
                    onUpdateRowData={this.props.onUpdateRowData}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onChangeAllFields={this.props.onChangeAllFields}
                />
                <ReorderFundsPopup isShow={isOpenReorderFundsPopup} onClose={this.onCloseReorderFundsPopup} />
            </div>
        )
    }
}

export default KlassappTableHOC(FinAidTab)
