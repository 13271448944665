import {Tabs} from "antd"
import React, {useReducer} from "react"
import {useTranslation} from "react-i18next"
import CurrentProgress from "../CurrentProgress/CurrentProgress"
import PreviouslyCompletedCredits from "../PreviouslyCompletedCredits/PreviouslyCompletedCredits"
import TransferCredits from "../TransferCredits/TransferCredits"
import styles from "./TableDegreeAudit.module.css"

const {TabPane} = Tabs

export const ACADEMIC_TABS = {
    CURRENT_PROGRESS: "CURRENT_PROGRESS",
    TRANSFER_CREDIT: "TRANSFER_CREDIT",
    PREVIOUSLY_COMPLETED_CREDITS: "PREVIOUSLY_COMPLETED_CREDITS"
}
function reducer(state, action) {
    return {...state, ...action}
}

const TableDegreeAudit = (props) => {
    const {studentId, refreshProgressReport} = props
    const {t} = useTranslation("academics")
    const initialState = {
        activeTabKey: ACADEMIC_TABS.CURRENT_PROGRESS,
        directors: [],
        activeDirectors: [],
        oldActiveDirectors: "",
        isSubmitting: false
    }
    const [{activeTabKey}, dispatch] = useReducer(reducer, initialState)

    function onChangeTab(activeKey) {
        dispatch({activeTabKey: activeKey})
    }

    return (
        <div style={{paddingBottom: 140}}>
            <Tabs className="fullwidth" activeKey={activeTabKey} onChange={onChangeTab}>
                <TabPane
                    tab={
                        <div className={styles.tabHeader}>
                            <span className={styles.tabTitle}>{t("academics.degreeAudit.currentProgress")}</span>
                        </div>
                    }
                    key={ACADEMIC_TABS.CURRENT_PROGRESS}>
                    {activeTabKey === ACADEMIC_TABS.CURRENT_PROGRESS && <CurrentProgress studentId={studentId} />}
                </TabPane>
                <TabPane
                    tab={
                        <div className={styles.tabHeader}>
                            <span className={styles.tabTitle}>{t("academics.degreeAudit.transferCredits")}</span>
                        </div>
                    }
                    key={ACADEMIC_TABS.TRANSFER_CREDIT}>
                    {activeTabKey === ACADEMIC_TABS.TRANSFER_CREDIT ? (
                        <TransferCredits refreshProgressReport={refreshProgressReport} studentId={studentId} />
                    ) : null}
                </TabPane>
                <TabPane
                    tab={
                        <div className={styles.tabHeader}>
                            <span className={styles.tabTitle}>
                                {t("academics.degreeAudit.previouslyCompletedCredits")}
                            </span>
                        </div>
                    }
                    key={ACADEMIC_TABS.PREVIOUSLY_COMPLETED_CREDITS}>
                    {activeTabKey === ACADEMIC_TABS.PREVIOUSLY_COMPLETED_CREDITS ? (
                        <PreviouslyCompletedCredits
                            refreshProgressReport={refreshProgressReport}
                            studentId={studentId}
                        />
                    ) : null}
                </TabPane>
            </Tabs>
        </div>
    )
}

export default TableDegreeAudit
