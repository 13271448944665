/* eslint-disable react-hooks/exhaustive-deps */
import React, {useReducer, useEffect, useState} from "react"
import {Row, Col, Popover} from "antd"
import {useTranslation} from "react-i18next"
import {get, head, isEmpty} from "lodash"
import debounce from "debounce-promise"
import moment from "moment"
import {KlassappTable, KlassappTableHeader, BasePopup, BaseNewFilter} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {Icon} from "components/Icon"
import {KlassDropAsyncPaginate} from "components/Select"
import {BaseDatePicker} from "components/DateTimePicker"
import {attendanceService, campusesService, courseService, majorService, reportsService, userServiceV3} from "services"
import {useModel} from "hooks"
import {convertTZ, formatCodeName, handleError, roundTwoDigits, toastInfo} from "helpers"
import {AttendanceStatus, LessonStatus} from "types/attendance"
import styles from "../common.module.css"
import classroomAttendanceStyles from "./ClassroomAttendance.module.css"
import {AttendanceDetailPopup} from "sections/academics/student/Terms/parts/CourseTab/parts/Attendance/parts"
import classNames from "classnames"
import {BaseButton, SecondaryButton} from "components"
import {Course} from "types/courses"
import {LessonAttendancePopup} from "../LessonAttendancePopup"
import {AcademicPlans} from "types/academicPlans"
import {FilterKey} from "types/filter"
import {useFilterStorage} from "hooks/useFilterStorage"
import {useDebounce} from "react-use"
import {ExportButton, ExportFileType} from "components/ui"
import {exportCsv, exportExcel} from "helpers/export-table"
import {YesNo} from "types/common"
import TermSelect from "components/TermSelect"

function reducer(state, action) {
    return {...state, ...action}
}

const initFilter = {
    search: "",
    campuses: [],
    programs: [],
    instructors: [],
    terms: [],
    date: null
}

const ATTENDANCE_TRACKING_TYPE_LABEL = {
    [Course.AttendanceTrackingType.ClassAttendance]: "Classroom",
    [Course.AttendanceTrackingType.ClockHour]: "Clock Hour"
}

function ClassroomAttendanceTab(props) {
    const model = useModel()
    const dateFormat = model.getUserDateFormat()
    const {searchValue, currentFilter, setCurrentFilter, changeSearch, clearFilter, applyFilter} = useFilterStorage(
        FilterKey.ClassroomAttendance,
        initFilter
    )
    const {t} = useTranslation(["studentServices"])
    const [courseInfo, setCourseInfo] = useState<Course.CourseTableItem>()
    const {
        data,
        page,
        studentProfileId,
        total,
        pageSize,
        columns,
        scheduleId,
        fields,
        allFields,
        isLoading,
        dispatch,
        courseId,
        termIds
    } = props
    const initialState = {
        count: {
            scheduledHours: 0,
            attendedHours: 0,
            missedHours: 0,
            makeUpHours: 0
        }
    }
    const [isShowConfirmVerify, setIsShowConfirmVerify] = useState(false)
    const [selectedStudent, setSelectedStudent] = useState<any>()
    const [isShowPopup, setIsShowPopup] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showLessonAttendancePopup, setShowLessonAttendancePopup] = useState(false)
    const [selectedAttendance, setSelectedAttendance] = useState<{
        classroomAttendanceType: Course.AttendanceTrackingType
        lessonId: number
        termId: number
        actualStartedAt: string
        attendedHours: number
        attendanceDate: string
        attendanceMessage: string
        actualEndedAt: string
        courseId: number
        status: LessonStatus
    }>()
    const [isExporting, setIsExporting] = useState(false)

    const [{count}, dispatchState] = useReducer(reducer, initialState)
    const [searchDebounce, setSearchDebounce] = useState(searchValue)
    useDebounce(() => setSearchDebounce(searchValue), 500, [searchValue])

    useEffect(() => {
        props.dispatch({isClassComponent: false})
        props.dispatchFunc([
            {key: "getListData", func: getClassroom},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
    }, [])

    useEffect(() => {
        getClassroomStats()
    }, [scheduleId, searchDebounce])

    useEffect(() => {
        getClassroom()
    }, [page, pageSize, scheduleId, searchDebounce])

    const onClickExportBtn = async (type: ExportFileType) => {
        if (total > 1000) {
            try {
                setIsExporting(true)
                const reportFilter = getFilterParams()
                const reportColumns = getColumns()
                    .filter((col) => fields.includes(col.title))
                    .map((column) => ({
                        field: column.field,
                        title: column.title
                        //  fieldType: column.fieldType
                    }))
                await reportsService.generateReport({
                    reportKey: "classroomAttendances",
                    payload: {filter: reportFilter, fields: reportColumns},
                    format: type
                })
                toastInfo(
                    "The report contains more than 1000 records; it will take some time to generate it. We will send you a notification with the download link when the report is ready"
                )
            } catch (error) {
                handleError(error)
            } finally {
                setIsExporting(false)
            }
        } else {
            try {
                setIsExporting(true)
                let params = getFilterParams()
                const {data = []} = await attendanceService.getClassroomAttendanceList({
                    page: 1,
                    pageSize: total,
                    ...params
                })
                const classroomAttendances = data.map((classroom) => {
                    const isActivity = classroom.classroomAttendanceType === Course.ClassroomAttendanceType.Activity

                    return {
                        ...classroom,
                        date: isActivity
                            ? classroom.attendanceDate
                            : moment(convertTZ(new Date(classroom.date), classroom.timezone)).format(
                                  model.getUserDateFormat()
                              ),
                        type: isActivity ? "Activity" : "Attendance",
                        attendanceTracking: ATTENDANCE_TRACKING_TYPE_LABEL[classroom.attendanceTracking] ?? "",
                        attendanceStatus: classroom.status,
                        isMakeup: !!classroom.makeupInfo,
                        studentNameHtml: classroom.studentName,
                        id: classroom.attendanceId,
                        scheduledMinutes: isActivity ? "" : `${roundTwoDigits((classroom.scheduledHours ?? 0) * 60)}`,
                        missedMinutes: isActivity ? "" : `${roundTwoDigits((classroom.missedHours ?? 0) * 60)}`,
                        makeupMinutes: isActivity ? "" : `${roundTwoDigits((classroom.makeUpHours ?? 0) * 60)}`,
                        attendedMinutes: `${roundTwoDigits((classroom.attendedHours ?? 0) * 60)}`,
                        instructorNames: classroom.instructors,
                        attendHtml:
                            classroom.status === AttendanceStatus.Approved
                                ? `${YesNo.Yes} (Verified: ${classroom.isVerified ? YesNo.Yes : YesNo.No})`
                                : `${YesNo.No} (Verified: ${classroom.isVerified ? YesNo.Yes : YesNo.No})`
                    }
                })
                const filename = `Classroom-Attendance-Report-${moment().format("MM_DD_YYYY")}`
                const reportColumns = getColumns().filter((col) => fields.includes(col.title))
                const payload = {
                    filename,
                    columns: reportColumns.map((col) => col.title.toUpperCase()),
                    rows: classroomAttendances.map((item) =>
                        reportColumns.map((col) => {
                            return item[col.field]?.toString()?.replace(/\r\n|\n|\r/g, " ") ?? ""
                        })
                    )
                }
                if (type === "csv") {
                    exportCsv(payload)
                } else if (type === "excel") {
                    exportExcel(payload)
                }
            } catch (error) {
                handleError(error)
            } finally {
                setIsExporting(false)
            }
        }
    }

    const renderMakeupInfo = (makeupInfo: {
        type: AcademicPlans.MakeupStudentType
        reason: string
        targetMakeupLesson?: {
            lessonName: string
            startsAt: string
            endAts: string
            termCode: string
            courseCode: string
            scheduleSuffix: string
        }
    }) => (
        <div onClick={(e) => e.preventDefault()}>
            <p className={classroomAttendanceStyles.titleMakeupPopup}>This lesson is a Makeup lesson for:</p>
            <div className={classroomAttendanceStyles.detailMakeupPopup}>
                {makeupInfo.type === AcademicPlans.MakeupStudentType.MakeupAttendance ? (
                    <>
                        <span className={classroomAttendanceStyles.contentMakeupPopup}>
                            {makeupInfo.targetMakeupLesson?.lessonName} ({makeupInfo.targetMakeupLesson?.termCode}-
                            {makeupInfo.targetMakeupLesson?.scheduleSuffix})
                        </span>
                        <span className={classroomAttendanceStyles.contentMakeupPopup}>
                            {moment(makeupInfo.targetMakeupLesson?.startsAt).format(model.getUserDateFormat())},{" "}
                            {moment(makeupInfo.targetMakeupLesson?.startsAt).format(model.getUserTimeFormat())} -{" "}
                            {moment(makeupInfo.targetMakeupLesson?.endAts).format(model.getUserTimeFormat())}
                        </span>
                    </>
                ) : (
                    <div className={classroomAttendanceStyles.rowContentMakeup}>
                        <span className={classroomAttendanceStyles.contentMakeupPopup}>Reason:</span>
                        <span className={classroomAttendanceStyles.contentMakeupPopup}>{makeupInfo.reason ?? ""}</span>
                    </div>
                )}
            </div>
        </div>
    )

    const renderName = (item) => {
        return (
            <div className={classroomAttendanceStyles.name}>
                <div className={classroomAttendanceStyles.nameStudent}>
                    <span>{item.studentName}</span>
                </div>
                {!!item.isMakeup && (
                    <Popover placement="right" showArrow={false} content={renderMakeupInfo(item.makeupInfo)}>
                        <div onClick={(e) => e.stopPropagation()} className={classroomAttendanceStyles.icon}>
                            <Icon icon="PLUS_PERSON" color="#666" />
                        </div>
                    </Popover>
                )}
            </div>
        )
    }

    const getFilterParams = () => {
        const params: any = {
            courseIds: [],
            termIds: !isEmpty(currentFilter.terms) ? currentFilter.terms.map((term) => term.id) : undefined,
            search: searchDebounce || undefined,
            campusIds: !isEmpty(currentFilter.campuses) ? currentFilter.campuses.map((campus) => campus.id) : undefined,
            programIds: !isEmpty(currentFilter.programs)
                ? currentFilter.programs.map((program) => program.id)
                : undefined,
            instructorProfileIds: !isEmpty(currentFilter.instructors)
                ? currentFilter.instructors.map((instructor) => instructor.profileId)
                : undefined,
            date: !!currentFilter.date ? moment(currentFilter.date).format("YYYY-MM-DD") : undefined
            // isActionNeeded: true,
        }
        const profileId = get(model, "user.profiles[0].id")
        if (scheduleId) {
            params.scheduleId = scheduleId
        }
        if (studentProfileId) {
            params.studentProfileIds = [parseInt(studentProfileId)]
        } else if (!model.isStaffOrAdmin() && profileId) {
            params.studentProfileIds = [profileId]
        }
        if (courseId) {
            params.courseIds = [courseId]
        } else if (!isEmpty(currentFilter.courses)) {
            params.courseIds = currentFilter.courses.map((course) => course.courseId)
        }
        if (termIds?.length) {
            params.termIds = termIds
        }
        return params
    }

    const getClassroomStats = async () => {
        try {
            const params = getFilterParams()
            const count = await attendanceService.getClassroomAttendanceStats(params)
            if (count) {
                dispatchState({count})
            }
        } catch (error) {
            handleError(error)
        }
    }

    async function getClassroom() {
        try {
            dispatch({isLoading: true})
            const params = getFilterParams()
            const {data = [], total} = await attendanceService.getClassroomAttendanceList({
                page,
                pageSize,
                ...params
            })
            const classrooms = data.map((classroom) => {
                const isActivity = classroom.classroomAttendanceType === Course.ClassroomAttendanceType.Activity
                if (isActivity) {
                    classroom.date = classroom.attendanceDate
                } else {
                    classroom.date = moment(convertTZ(new Date(classroom.date), classroom.timezone)).format(
                        model.getUserDateFormat()
                    )
                }

                classroom.isMakeup = !!classroom.makeupInfo
                classroom.studentNameHtml = renderName(classroom)
                classroom.attendanceStatus = classroom.status
                classroom.attendanceTracking = ATTENDANCE_TRACKING_TYPE_LABEL[classroom.attendanceTracking] ?? ""
                classroom.type = isActivity ? "Activity" : "Attendance"
                if (!model.isStaffOrAdmin()) {
                    classroom.isDisableCheckBox = true
                }
                if (!classroom?.events?.length && !isActivity) {
                    classroom.isDisableCheckBox = true
                }
                if (classroom.isVerified === "yes") {
                    classroom.isDisableCheckBox = true
                }

                return {
                    ...classroom,
                    id: classroom.attendanceId,
                    scheduledMinutes: isActivity ? "" : `${roundTwoDigits((classroom.scheduledHours ?? 0) * 60)}`,
                    missedMinutes: isActivity ? "" : `${roundTwoDigits((classroom.missedHours ?? 0) * 60)}`,
                    makeupMinutes: isActivity ? "" : `${roundTwoDigits((classroom.makeUpHours ?? 0) * 60)}`,
                    attendedMinutes: `${roundTwoDigits((classroom.attendedHours ?? 0) * 60)}`,
                    instructorNames: classroom.instructors,
                    attendHtml: renderAttendHtml(classroom.status, classroom.isVerified)
                }
            })
            props.dispatch({data: classrooms, total})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }
    function renderAttendHtml(status, isVerified) {
        if (isVerified === "yes" && status === AttendanceStatus.Rejected) {
            return (
                <div className={styles.attClose}>
                    <Icon icon="CROSS_CIRCLE" />
                </div>
            )
        }
        if (isVerified === "yes") {
            return (
                <div className={classroomAttendanceStyles.attCheckVerified}>
                    <Icon icon="CHECKED" />
                </div>
            )
        }
        switch (status) {
            case AttendanceStatus.Approved:
                return (
                    <div className={styles.attCheck}>
                        <Icon icon="CHECKED" />
                    </div>
                )
            case AttendanceStatus.Irregular:
                return (
                    <div className={styles.actionWarning}>
                        <Icon icon="WARNING_TRIANGLE_FILL" />
                    </div>
                )
            case AttendanceStatus.Rejected:
                return (
                    <div className={styles.attClose}>
                        <Icon icon="CROSS_CIRCLE" />
                    </div>
                )
            case AttendanceStatus.InClass:
            default:
                return null
        }
    }
    function getColumns() {
        return [
            {
                title: t("attendance.table.date"),
                field: "date",
                fieldType: "date"
            },
            {
                title: t("attendance.table.dot"),
                field: "dayOfTerm",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: t("attendance.table.studentId"),
                field: "profileId",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: t("attendance.table.name"),
                field: "studentNameHtml",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: t("attendance.table.id"),
                field: "termCourseCode",
                headerStyle: {minWidth: "120px"}
            },
            {
                title: "Schedule Id",
                field: "scheduleSuffix",
                headerStyle: {minWidth: "120px"}
            },
            {
                title: "Term",
                field: "termName",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: t("attendance.table.course"),
                field: "courseName",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Lesson Type",
                field: "lessonType",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: t("attendance.table.scheduledMinutes"),
                field: "scheduledMinutes",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: t("attendance.table.attendedMinutes"),
                field: "attendedMinutes",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: t("attendance.table.missedMinutes"),
                field: "missedMinutes",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: t("attendance.table.makeUpMinutes"),
                field: "makeupMinutes",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: t("attendance.table.attend"),
                field: "attendHtml",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: t("attendance.table.instructor"),
                field: "instructorNames",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: t("attendance.table.attendanceTracking"),
                field: "attendanceTracking",
                headerStyle: {minWidth: "200px"}
            },
            {
                title: "Type",
                field: "type",
                headerStyle: {minWidth: "150px"}
            }
        ]
    }

    function getFields() {
        return [
            t("attendance.table.date"),
            t("attendance.table.dot"),
            t("attendance.table.studentId"),
            t("attendance.table.name"),
            t("attendance.table.id"),
            "Schedule Id",
            "Term",
            t("attendance.table.course"),
            "Lesson Type",
            t("attendance.table.scheduledMinutes"),
            t("attendance.table.attendedMinutes"),
            t("attendance.table.missedMinutes"),
            t("attendance.table.makeUpMinutes"),
            t("attendance.table.attend"),
            t("attendance.table.instructor"),
            t("attendance.table.attendanceTracking"),
            "Type"
        ]
    }

    const onClickRowItem = async (data) => {
        if (model.isStaffOrAdmin()) {
            setLoading(true)
            setIsShowPopup(true)
            try {
                const paramsGetCourse: any = {
                    filter: {
                        courseIds: data.courseId ? [data.courseId] : []
                    },
                    range: {page: 1, pageSize: 2}
                }
                const {data: courseData} = await courseService.getAll(paramsGetCourse)
                const course = head(courseData)
                setCourseInfo(course)
                setSelectedAttendance({
                    attendanceDate: data.attendanceDate,
                    classroomAttendanceType: data.classroomAttendanceType,
                    attendanceMessage: data.attendanceMessage,
                    attendedHours: data.attendedHours,
                    termId: data.termId,
                    courseId: data.courseId,
                    actualEndedAt: data.actualEndedAt,
                    actualStartedAt: data.actualStartedAt,
                    lessonId: data.lessonId,
                    status: data.lessonStatus
                })
                setSelectedStudent(data)
            } catch (err) {
                handleError(err)
            } finally {
                setLoading(false)
            }
        } else {
            setSelectedAttendance({
                termId: data.termId,
                attendanceDate: data.attendanceDate,
                attendanceMessage: data.attendanceMessage,
                classroomAttendanceType: data.classroomAttendanceType,
                attendedHours: data.attendedHours,
                courseId: data.courseId,
                actualEndedAt: data.actualEndedAt,
                actualStartedAt: data.actualStartedAt,
                lessonId: data.lessonId,
                status: data.lessonStatus
            })
            setShowLessonAttendancePopup(true)
        }
    }

    const onClosePopup = () => {
        setIsShowPopup(false)
        setSelectedStudent(undefined)
    }

    const onPressVerify = async () => {
        setLoading(true)
        try {
            const selectedAttendances = props.data.filter((item) => item.isChecked)
            const params = selectedAttendances.map((item) => ({
                attendanceId: item.attendanceId,
                status: item.attendanceStatus,
                profileId: item.profileId,
                isVerified: true
            }))
            await attendanceService.updateAttendance(params)
            getClassroom()
            getClassroomStats()
            onCancelConfirm()
        } catch (err) {
            handleError(err)
        } finally {
            setLoading(false)
        }
    }
    const onCancelConfirm = () => setIsShowConfirmVerify(false)
    const onShowConfirm = () => setIsShowConfirmVerify(true)

    const onCampusSearchChange = async (search = "", loadedOptions) => {
        try {
            const params = {
                filter: {
                    search
                },
                range: {
                    limit: 20,
                    offset: loadedOptions.length
                },
                sort: {
                    orderBy: "name",
                    orderDir: "asc"
                }
            }
            const {data: campuses, total} = await campusesService.getAll(params)
            return {
                options: campuses,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onProgramSearch = async (search = "", loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const {data: programs, total} = await majorService.getAll({
                filter: {
                    search
                },
                range: {
                    page,
                    pageSize
                }
            })
            return {
                options: programs,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            handleError(error)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onInstructorSearchChange = async (search, loadedOptions) => {
        try {
            const {data, total} = await userServiceV3.getAll({
                filter: {
                    type: ["staff"],
                    search
                },
                range: {
                    limit: 20,
                    offset: loadedOptions.length
                }
            })
            const instructors = data.map((user) => ({
                userId: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                fullName: user.fullName,
                profileId: user.profiles[0]
            }))
            return {
                options: instructors,
                hasMore: loadedOptions.length < total
            }
        } catch (e) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onSearchCourses = async (search, loadedOptions) => {
        try {
            const pageSize = 20
            const params = {
                filter: {
                    search,
                    campusIds: !isEmpty(currentFilter.campuses)
                        ? currentFilter.campuses.map((campus) => campus.id)
                        : undefined
                },
                range: {
                    limit: pageSize,
                    offset: loadedOptions.length
                },
                sort: {
                    orderBy: "name",
                    orderDir: "asc"
                }
            }
            const {data: courses, total} = await courseService.list(params)
            return {
                options: courses,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const tableHeaderActions = () => {
        if (!model.isStaffOrAdmin()) {
            return []
        }
        return [
            {
                title: (
                    <div className={styles.actionWrap}>
                        <BaseButton
                            title="Verify"
                            variant="secondary"
                            className={styles.blackBtn}
                            onClick={onShowConfirm}
                            loading={false}
                        />
                    </div>
                ) as any,
                icon: null,
                action: () => {}
            }
        ]
    }

    const onClickClearFilter = () => {
        clearFilter()
    }

    const onChangeFilter = (key, value) => {
        const newFilter = {...currentFilter}
        newFilter[key] = value
        setCurrentFilter(newFilter)
    }

    const onSearchInput = (search) => {
        changeSearch(search)
    }

    const onApplyFilter = () => {
        applyFilter()
        getClassroomStats()
        getClassroom()
    }

    const debounceCampuses = debounce(onCampusSearchChange, 300)
    const debouncePrograms = debounce(onProgramSearch, 300)
    const debounceInstructors = debounce(onInstructorSearchChange, 300)
    const debounceCourses = debounce(onSearchCourses, 300)

    return (
        <div>
            <BaseNewFilter
                filter={currentFilter}
                onClick={onApplyFilter}
                searchValue={searchValue}
                onClickClear={onClickClearFilter}
                renderRightFilter={() => (
                    <div className={classroomAttendanceStyles.actionWrap}>
                        <ExportButton
                            onSelect={onClickExportBtn}
                            isLoading={isExporting}
                            availableFileTypes={["csv", "excel"]}
                        />
                    </div>
                )}
                onSearchInput={onSearchInput}>
                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        <KlassDropAsyncPaginate
                            value={currentFilter.instructors}
                            onChange={(newValue) => onChangeFilter("instructors", newValue)}
                            loadOptions={debounceInstructors}
                            valueKey="profileId"
                            labelKey="fullName"
                            isMulti
                            placeholder={t("filter.instructor")}
                        />
                    </Col>
                    <Col span={12}>
                        <BaseDatePicker
                            value={currentFilter.date ? moment(currentFilter.date) : undefined}
                            onChange={(newValue) => onChangeFilter("date", newValue)}
                            format="MM/DD/YYYY"
                            placeholder={t(`filter.lessonDate`)}
                        />
                    </Col>
                    <Col span={12}>
                        <KlassDropAsyncPaginate
                            value={currentFilter.campuses}
                            onChange={(newValue) => onChangeFilter("campuses", newValue)}
                            loadOptions={debounceCampuses}
                            getOptionLabel={formatCodeName}
                            isMulti
                            placeholder={t("filter.campus")}
                        />
                    </Col>
                    <Col span={12}>
                        <KlassDropAsyncPaginate
                            value={currentFilter.programs}
                            onChange={(newValue) => onChangeFilter("programs", newValue)}
                            loadOptions={debouncePrograms}
                            getOptionLabel={formatCodeName}
                            isMulti
                            placeholder={t("filter.program")}
                        />
                    </Col>
                    {!courseId ? (
                        <Col span={12}>
                            <KlassDropAsyncPaginate
                                key={currentFilter.campuses?.map((campus) => campus.id).join(",")}
                                value={currentFilter.courses}
                                onChange={(newValue) => onChangeFilter("courses", newValue)}
                                loadOptions={debounceCourses}
                                getOptionLabel={formatCodeName}
                                isMulti
                                valueKey="courseId"
                                placeholder={t("filter.course")}
                            />
                        </Col>
                    ) : null}
                    <Col span={12}>
                        <TermSelect
                            value={currentFilter.terms}
                            placeholder="Select Term"
                            onChange={(option: any) => onChangeFilter("terms", option ?? [])}
                            isMulti
                        />
                    </Col>
                </Row>
            </BaseNewFilter>

            <Row gutter={16}>
                <Col span={6}>
                    <div className={styles.cardWrap}>
                        <div className={styles.titleWrap}>
                            <div className={`${styles.iconWrapper} ${styles.lessonColor}`}>
                                <Icon icon="TIMER" />
                            </div>
                            <div>
                                <p className={styles.cardTitle}>{t("attendance.classroomAttendance.scheduledHours")}</p>
                                <span className={styles.cardTitleStandFor}>(SCHD)</span>
                            </div>
                        </div>
                        <p className={styles.cardValue}>{roundTwoDigits(count.scheduledHours)}</p>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.cardWrap}>
                        <div className={styles.titleWrap}>
                            <div className={`${styles.iconWrapper} ${styles.attendedColor}`}>
                                <Icon icon="PERSON_DONE" color="var(--success-400-base)" />
                            </div>
                            <div>
                                <p className={styles.cardTitle}>{t("attendance.classroomAttendance.attendedHours")}</p>
                                <span className={styles.cardTitleStandFor}>(ATTD)</span>
                            </div>
                        </div>
                        <p className={styles.cardValue}>{roundTwoDigits(count.attendedHours)}</p>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.cardWrap}>
                        <div className={styles.titleWrap}>
                            <div className={`${styles.iconWrapper} ${styles.missedColor}`}>
                                <Icon icon="PERSON_REMOVE" color="var(--error-300)" />
                            </div>
                            <div>
                                <p className={styles.cardTitle}>{t("attendance.classroomAttendance.missedHours")}</p>
                                <span className={styles.cardTitleStandFor}>(MISS)</span>
                            </div>
                        </div>
                        <p className={styles.cardValue}>{roundTwoDigits(count.missedHours)}</p>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.cardWrap}>
                        <div className={styles.titleWrap}>
                            <div className={`${styles.iconWrapper} ${styles.actionColor}`}>
                                <Icon icon="WARNING_TRIANGLE_FILL" color="var(--warning-400-base)" />
                            </div>
                            <div>
                                <p className={styles.cardTitle}>{t("attendance.classroomAttendance.makeUpHours")}</p>
                                <span className={styles.cardTitleStandFor}>(MKUP)</span>
                            </div>
                        </div>
                        <p className={styles.cardValue}>{roundTwoDigits(count.makeUpHours)}</p>
                    </div>
                </Col>
            </Row>
            <Row className={styles.marginTop}>
                <Col span={24}>
                    <KlassappTableHeader
                        page={page}
                        total={total}
                        isShowAction={!!data.find((item) => item.isChecked)}
                        actions={tableHeaderActions()}
                        defaultPageSize={pageSize}
                        onChangePage={props.onChangePage}
                        onChangeRowPerPage={props.onChangeRowPerPage}
                        fields={fields}
                        allFields={allFields}
                        onChangeFields={props.onChangeFields}
                        onChangeAllFields={props.onChangeAllFields}
                        onDraggableColumn={props.onDraggableColumn}
                    />
                    <KlassappTable
                        isShowCheckedColumn={model.isStaffOrAdmin()}
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        fields={fields}
                        allFields={allFields}
                        isShowCheckedColumnHeader={false}
                        onClickRowItem={onClickRowItem}
                        onChangeFields={props.onChangeFields}
                        onUpdateRowData={props.onUpdateRowData}
                        onUpdateTableData={props.onUpdateTableData}
                        onDraggableColumn={props.onDraggableColumn}
                        onChangeAllFields={props.onChangeAllFields}
                    />
                </Col>
            </Row>
            <BasePopup
                isShow={isShowConfirmVerify}
                onClose={onCancelConfirm}
                width="70vw"
                leftIcon="WARNING_SOLID"
                leftIconColor="#fff">
                <div className={classroomAttendanceStyles.wrap}>
                    <div className={classroomAttendanceStyles.popupTitle}>
                        {t("common:confirmationPopup.confirmVerifyAttendance")}
                    </div>
                    <span className={styles.smallTitle}>{t("common:confirmationPopup.smallTextVerifyAttendance")}</span>
                    <div className={classroomAttendanceStyles.footer}>
                        <div className={classNames(classroomAttendanceStyles.actionDetailWrapPopup)}>
                            <div className={classroomAttendanceStyles.button}>
                                <SecondaryButton onClick={onCancelConfirm} title={t("common:action.cancelModal")} />
                            </div>
                            <div
                                className={classNames(
                                    classroomAttendanceStyles.button,
                                    classroomAttendanceStyles.buttonPopupConfirm
                                )}>
                                <BaseButton
                                    onClick={onPressVerify}
                                    title={t("common:action.confirm")}
                                    loading={loading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </BasePopup>
            {!model.isStaffOrAdmin() ? (
                <LessonAttendancePopup
                    studentProfileIds={[model.profileId]}
                    isShow={showLessonAttendancePopup}
                    lessonId={selectedAttendance?.lessonId}
                    courseId={selectedAttendance?.courseId}
                    status={selectedAttendance?.status}
                    termId={selectedAttendance?.termId}
                    attendanceMessage={selectedAttendance?.attendanceMessage}
                    attendedHours={selectedAttendance?.attendedHours}
                    attendanceDate={selectedAttendance?.attendanceDate}
                    classroomAttendanceType={selectedAttendance?.classroomAttendanceType}
                    onClose={() => setShowLessonAttendancePopup(false)}
                />
            ) : (
                <AttendanceDetailPopup
                    actualStartedAt={selectedAttendance?.actualStartedAt}
                    actualEndedAt={selectedAttendance?.actualEndedAt}
                    studentProfileIds={selectedStudent?.profileId ? [selectedStudent?.profileId] : []}
                    courseIds={selectedStudent?.courseId ? [selectedStudent?.courseId] : []}
                    getListAttendances={() => {
                        getClassroom()
                        getClassroomStats()
                    }}
                    loading={loading}
                    termIds={[]}
                    courseInfo={courseInfo}
                    selectedAttendance={selectedStudent}
                    isShow={isShowPopup}
                    onClose={onClosePopup}
                />
            )}
        </div>
    )
}
export const ClassroomAttendance = KlassappTableHOC(ClassroomAttendanceTab)
