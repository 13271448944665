import {useQuery} from "@tanstack/react-query"
import {admissionService} from "services"

const INITIAL_DATA = []

export const useAllLeadSources = () => {
    const leadSourcesQuery = useQuery(
        ["lead-sources"],
        async () => {
            const {data} = await admissionService.getAllLeadSources({
                sort: {orderBy: "name", orderDir: "ASC"}
            })
            return data.map((v: any) => ({
                id: v.admissionsLeadSourceId,
                name: v.name,
                code: v.code
            }))
        },
        {}
    )

    return {
        leadSources: leadSourcesQuery.data ?? INITIAL_DATA,
        isLoading: leadSourcesQuery.isFetching,
        fetched: leadSourcesQuery.isFetched
    }
}
