import React, {useContext, useEffect} from "react"
import {Tabs} from "antd"
import {useActiveTab} from "hooks"
import {Screen} from "components/ui"
import {PaymentPlanTab, StudentLedgerTab} from "./parts"
import {TabHeader} from "components/Tab"
import {useTranslation} from "react-i18next"
import {StudentAccountStaffTab as StaffTab} from "../Staff/StudentAccountStaff"
import Disbursements from "../../FinancialAid/FinancialAidStudent/parts/FinAidAward/Disbursement/Disbursements"
import {useParams} from "react-router-dom"
import {BaseDepartmentId} from "types/departments"
import {Auth} from "types/auth"
import {studentService} from "services"
import {getFullName, toastError} from "helpers"
import {AdvisorTitle} from "uiKit"
import {StudentPaymentContext} from "context/StudentPaymentContext"
import StudentAccountPanelInfo from "sections/shared/student/StudentAccountPanelInfo/StudentAccountPanelInfo"

const {TabPane} = Tabs

export enum StudentAccountStudentTab {
    StudentLedger = "student-ledger",
    PaymentPlan = "payment-plan",
    Disbursement = "disbursement",
    Refund = "refund",
    Scholarship = "scholarship"
}

export default function StudentAccountStudent(props) {
    const {t} = useTranslation(["studentAccount", "common"])
    const params = useParams<{profileId: string}>()
    const studentId = +params.profileId
    const [activeTabKey, onChangeTab] = useActiveTab(StudentAccountStudentTab.StudentLedger, "tab")
    const [student, setStudent] = React.useState<Auth.DepartmentStudent | undefined>()
    const {setStudentId} = useContext(StudentPaymentContext)

    useEffect(() => {
        ;(async function loadStudent() {
            try {
                const {
                    data: [student]
                } = await studentService.getDepartmentStudents({
                    filter: {profileIds: [studentId], departmentId: BaseDepartmentId.StudentAccount}
                })
                setStudent(student)
                setStudentId(studentId)
            } catch {
                toastError("Student not found")
                setStudent(undefined)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentId])

    const pageTitle = getFullName(student) || t("student.pageTitle")

    return (
        <Screen
            htmlTitle={pageTitle}
            header={{
                title: pageTitle,
                backUrl: `/student-account/staff?tab=${StaffTab.Students}`
            }}
            rightPanel={{
                type: "student",
                data: {
                    student,
                    departmentId: BaseDepartmentId.StudentAccount,
                    advisorTitle: AdvisorTitle.advisors,
                    isDetailButtonVisible: true,
                    detailUrl: "student-account",
                    fromPage: "student-account",
                    renderDepartmentInfo: (props) => <StudentAccountPanelInfo student={student} {...props} />
                }
            }}>
            <Tabs className="fullwidth klassTab" activeKey={activeTabKey} onChange={onChangeTab}>
                <TabPane
                    tab={<TabHeader title={t("student.tab.studentledger")} />}
                    key={StudentAccountStudentTab.StudentLedger}>
                    {activeTabKey === StudentAccountStudentTab.StudentLedger && <StudentLedgerTab {...props} />}
                </TabPane>
                <TabPane
                    tab={<TabHeader title={t("student.tab.paymentplan")} />}
                    key={StudentAccountStudentTab.PaymentPlan}>
                    {activeTabKey === StudentAccountStudentTab.PaymentPlan && <PaymentPlanTab {...props} />}
                </TabPane>
                <TabPane
                    tab={<TabHeader title={t("student.tab.disbursement")} />}
                    key={StudentAccountStudentTab.Disbursement}>
                    {activeTabKey === StudentAccountStudentTab.Disbursement && <Disbursements studentId={studentId} />}
                </TabPane>
                {/*
                <TabPane tab={<TabHeader title={t("student.tab.refund")} />} key={StudentAccountStudentTab.Refund}>
                    {activeTabKey === StudentAccountStudentTab.Refund && <RefundTab />}
                </TabPane>
                <TabPane
                    tab={<TabHeader title={t("student.tab.scholarship")} />}
                    key={StudentAccountStudentTab.Scholarship}>
                    {activeTabKey === StudentAccountStudentTab.Scholarship && <ScholarshipTab {...props} />}
                </TabPane>
                */}
            </Tabs>
        </Screen>
    )
}
