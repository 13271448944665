import React from "react"
import {UserType} from "types/tasks"
import {StaffSignerSelecting, StudentSignerSelecting, OthersSignerSelecting} from "./parts"

export function TaskSignerSelecting(props) {
    const {signer} = props

    switch (signer.userType) {
        case UserType.STAFF:
            return <StaffSignerSelecting {...props} />
        case UserType.STUDENT:
            return <StudentSignerSelecting {...props} />
        case UserType.OTHERS:
            return <OthersSignerSelecting {...props} />
        default:
            return <></>
    }
}
