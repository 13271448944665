export function differenceTwoArrays(array1, array2) {
    return array1.filter((x) => !array2.includes(x)).concat(array2.filter((x) => !array1.includes(x)))
}

export const toChunks = (arr: any[], chunkSize: number) => {
    const result: any = []
    let i = 0
    while (i < arr.length) {
        result.push(arr.slice(i, i + chunkSize))
        i += chunkSize
    }
    return result
}
