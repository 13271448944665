/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react"
import {v4 as uuidv4} from "uuid"
import {BasePopup} from "components/popup"
import {FormLabel} from "components/Form"
import {BaseInput} from "components/inputs"
import {KlassDropdown} from "components/Select"
import {BaseButton} from "components/buttons"
import {BaseColorPicker} from "uiKit"
import {formatCodeName, handleError, toastError} from "helpers"
import {Major} from "types/major"
import styles from "./AddCoursesPopup.module.css"
import {academicPlansService, majorService, termsService} from "services"
import {AcademicPlans} from "types/academicPlans"
import {head, isEmpty} from "lodash"

type Props = {
    isShow: boolean
    onClose: () => void
    versionData?: Major.MajorVersion
    group?: Major.GroupCourseSequence
    programTermTemplateId?: number
    termCalendarBaseId?: number
    position?: number
    isInMajorVersion: boolean
    isInTermSettingPreview: boolean
    isInAcademicPlan: boolean
    majorVersionId?: number
    studentProfileId?: number
    reloadView: () => void
}

export function AddMultiCoursesPopup(props: Props) {
    const {
        isShow,
        group,
        versionData,
        programTermTemplateId,
        position,
        isInMajorVersion,
        isInTermSettingPreview,
        isInAcademicPlan,
        majorVersionId,
        termCalendarBaseId,
        studentProfileId,
        onClose,
        reloadView
    } = props
    const [courses, setCourses] = useState([])
    const [courseInfo, setCourseInfo] = useState({
        numberOfDays: null,
        courses: [],
        color: "#ccc"
    })
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        if (isInMajorVersion) {
            getCourses(versionData.list)
        } else if (isInTermSettingPreview) {
            getMajorVersionDetail()
        } else {
            getListCourseInListView()
        }
        if (group) {
            setCourseInfo({
                numberOfDays: group.numberOfDays,
                color: group.color,
                courses: group.courses.map((item) => ({
                    id: item.courseId,
                    name: item.name,
                    code: item.code
                }))
            })
        }
    }, [])

    const getMajorVersionDetail = async () => {
        const {list} = await majorService.getMajorVersionDetail(majorVersionId, {type: "list"})
        getCourses(list)
    }

    const getListCourseInListView = async () => {
        try {
            const sections = await academicPlansService.getListCourseBySection({
                studentProfileId,
                viewType: AcademicPlans.TabKey.List
            })
            getCourses(sections)
        } catch (error) {
            handleError(error)
        }
    }

    const getCourses = (sections) => {
        const courses = sections.map((section) => {
            const options = section.courses.map((course) => ({
                id: course.courseId,
                name: course.courseName,
                code: course.courseCode
            }))
            return {label: section.section_title || section.sectionTitle, options}
        })
        setCourses(courses)
    }

    const onChangeCourse = (key, value) => {
        const newCourse = {...courseInfo}
        newCourse[key] = value
        setCourseInfo(newCourse)
    }

    const createCourse = async () => {
        if (isInMajorVersion) {
            const data = {
                programTermTemplateId,
                courseIds: courseInfo.courses.map((course) => course.id),
                numberOfDays: +courseInfo.numberOfDays,
                position,
                groupId: uuidv4(),
                color: courseInfo.color
            }
            await majorService.addCourseCalendarBased(data)
        } else if (isInTermSettingPreview) {
            const data = {
                termCalendarBaseId,
                courseIds: courseInfo.courses.map((course) => course.id),
                numberOfDays: +courseInfo.numberOfDays,
                position,
                groupId: uuidv4(),
                color: courseInfo.color
            }
            await termsService.addCourseCalendarBased(data)
        }
    }

    const editCourses = async () => {
        const data = {
            groupId: group.groupId,
            courseIds: courseInfo.courses.map((course) => course.id),
            numberOfDays: +courseInfo.numberOfDays,
            color: courseInfo.color
        }
        if (isInMajorVersion) {
            await majorService.updateCourseCalendarBased(data)
        } else if (isInTermSettingPreview) {
            await termsService.updateCourseCalendarBased(data)
        } else if (isInAcademicPlan) {
            await academicPlansService.updateCourseCalendarBased(data)
        }
    }

    const onClickSave = async () => {
        if (!courseInfo.numberOfDays || isEmpty(courseInfo.courses) || !courseInfo.color) {
            toastError("Please enter all the fields")
            return
        }
        try {
            setIsSubmitting(true)
            if (group) {
                await editCourses()
            } else {
                await createCourse()
            }
            onClose()
            reloadView()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <BasePopup isShow={isShow} onClose={onClose} isShowLeftSide={false} width="400px">
            <div>
                <p className={styles.title}>Add course</p>
                <div>
                    <div className={styles.formItem}>
                        <FormLabel label="DOT" isRequired />
                        <BaseInput
                            type="number"
                            min={0}
                            value={courseInfo.numberOfDays}
                            onChange={(newValue) => onChangeCourse("numberOfDays", newValue)}
                            placeholder="Enter"
                        />
                    </div>
                    <div className={styles.formItem}>
                        <FormLabel label="Course" isRequired />
                        <KlassDropdown
                            options={courses}
                            value={courseInfo.courses}
                            onChange={(newValue) => onChangeCourse("courses", newValue)}
                            placeholder="Courses"
                            getOptionLabel={formatCodeName}
                            isMulti
                        />
                    </div>
                    <div className={styles.formItem}>
                        <FormLabel label="Color Label" isRequired />
                        <BaseColorPicker
                            value={courseInfo.color}
                            onChange={(newValue) => onChangeCourse("color", newValue)}
                        />
                    </div>
                </div>
                <div className={styles.actionWrap}>
                    <BaseButton title="Cancel" variant="secondary" onClick={onClose} />
                    <BaseButton title="Save" onClick={onClickSave} loading={isSubmitting} />
                </div>
            </div>
        </BasePopup>
    )
}
