import {useState} from "react"
import {isEmpty} from "lodash"
import {FilterKey} from "types/filter"
import {useModel} from "./useModel"

export const useFilterStorage = (filterKey: FilterKey, defaultFilter: any = {}) => {
    const model = useModel()
    const storageData = model.getStorageFilter(filterKey)
    const [searchValue, setSearchValue] = useState(!isEmpty(storageData) ? storageData.search : "")
    const [currentFilter, setCurrentFilter] = useState(
        !isEmpty(storageData?.filter) ? storageData.filter : defaultFilter
    )

    const changeSearch = (searchValue) => {
        model.updateStorageFilter(filterKey, {search: searchValue, page: 1})
        setSearchValue(searchValue)
    }

    const clearFilter = () => {
        setCurrentFilter({...defaultFilter})
    }

    const applyFilter = () => {
        model.updateStorageFilter(filterKey, {filter: currentFilter, page: 1})
    }

    return {
        searchValue,
        currentFilter,
        setCurrentFilter,
        changeSearch,
        clearFilter,
        applyFilter
    }
}
