import React, {useState} from "react"
import {Row, Col} from "antd"
import {BasePopup} from "components/popup"
import {Icon} from "components/Icon"
import {BaseFileInput} from "components/inputs"
import {BaseButton} from "components/buttons"
import {taskService} from "services"
import {handleError, toastError, toastSuccess} from "helpers"
import styles from "./ScanUploadTaskPopup.module.css"
import {useModel} from "hooks"

export function ScanUploadTaskPopup(props) {
    const model = useModel()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [file, setFile] = useState(null)
    const {isShow, onClose, taskUserId, taskUserEncodedSecurityLevelWeb} = props
    const allowedDocumentFormats = props.model.getAllowedDocumentFormats() || "application/pdf"

    const onChangeData = (value) => {
        setFile(value)
    }

    const onClickSubmit = async () => {
        if (!file) {
            toastError("Please select a file")
            return
        }
        try {
            setIsSubmitting(true)
            if (!taskUserId) {
                toastError("TaskUserId is required")
                setIsSubmitting(false)
                return
            }
            const formData = new FormData()
            formData.append("taskUserId", taskUserId)
            formData.append("files", file)
            let params = `sl=${taskUserEncodedSecurityLevelWeb}`
            if (model.isSubmittingTaskFromEmail) {
                params = `sl=${model.securityLevelEncoded}`
            }
            await taskService.answerScanTask(formData, params)
            toastSuccess("Submit successful", "", {duration: 1})
            setTimeout(() => {
                onClose(true)
            }, 1800)
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <BasePopup isShow={isShow} onClose={() => onClose(false)} leftIcon="UPLOAD_THIN" width="70vw">
            <div className={styles.wrap}>
                <p className={styles.title}>Scan/upload</p>
                <Row gutter={[40, 40]}>
                    {/* <Col span={12}>
                        <div className={styles.item}>
                            <div className={styles.iconWrap}>
                                <Icon icon="CAMERA" />
                            </div>
                            <span className={styles.text}>Open camera</span>
                        </div>
                    </Col> */}
                    <Col span={24}>
                        <div className={styles.item}>
                            <BaseFileInput
                                onChange={onChangeData}
                                className={styles.fileNameWrap}
                                accept={allowedDocumentFormats}>
                                <span className={styles.iconWrap}>
                                    <Icon icon="UPLOAD_THIN" />
                                </span>
                            </BaseFileInput>
                            <span className={styles.text}>Upload</span>
                        </div>
                    </Col>
                </Row>
                <div className={styles.actionWrap}>
                    <BaseButton title="Submit" onClick={onClickSubmit} loading={isSubmitting} />
                </div>
            </div>
        </BasePopup>
    )
}
