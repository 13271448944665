import React, {useState} from "react"
import {Radio} from "antd"
import {BasePopup} from "components/popup"
import {BaseButton} from "components/buttons"
import {RecurringPopupType, TargetRecurringEvent} from "types/calendar"
import styles from "./ChooseRecurringPopup.module.css"

type Props = {
    isShow: boolean
    type: RecurringPopupType
    loading?: boolean
    isHideThisEvent?: boolean
    isHideAllEvent?: boolean
    onClose: () => void
    onClickSave: (value: TargetRecurringEvent) => void
}

export function ChooseRecurringPopup(props: Props) {
    const [value, setValue] = useState<TargetRecurringEvent>(TargetRecurringEvent.OnlyThis)
    const {isShow, loading, type, isHideThisEvent, isHideAllEvent, onClose, onClickSave} = props

    const onChangeData = (newValue) => {
        setValue(newValue)
    }

    const renderTitle = () => {
        if (type === RecurringPopupType.Update) {
            return <p className={styles.title}>Update recurring event</p>
        }
        return <p className={styles.title}>Delete recurring event</p>
    }

    return (
        <BasePopup isShow={isShow} onClose={onClose} isShowLeftSide={false} width="450px" className={styles.wrapper}>
            <div className={styles.wrap}>
                {renderTitle()}
                <Radio.Group
                    onChange={(event) => onChangeData(event.target.value)}
                    value={value}
                    className={styles.radioBtnWrap}>
                    {!isHideThisEvent && (
                        <Radio value={TargetRecurringEvent.OnlyThis} className={styles.radioBtn__item}>
                            This event
                        </Radio>
                    )}
                    <Radio value={TargetRecurringEvent.ThisAndFurther} className={styles.radioBtn__item}>
                        This and following events
                    </Radio>
                    {!isHideAllEvent && (
                        <Radio value={TargetRecurringEvent.All} className={styles.radioBtn__item}>
                            All events
                        </Radio>
                    )}
                </Radio.Group>
                <div className={styles.action}>
                    <BaseButton title="Cancel" variant="secondary" className={styles.cancelBtn} onClick={onClose} />
                    <BaseButton title="Save" loading={loading} onClick={() => onClickSave(value)} />
                </div>
            </div>
        </BasePopup>
    )
}
