import React from "react"
import {FinAidNoticeFilter, FinAidNoticeTable} from "./parts"

type State = {
    filterObject: any
    isSubmitFilter: boolean
    filterFormSearch: string
}

class FinancialAidStaffTabNotesTab extends React.Component<{}, State> {
    constructor(props) {
        super(props)

        this.state = {
            filterObject: {},
            filterFormSearch: "",
            isSubmitFilter: false
        }
    }

    public onChangeFilter = async () => {
        this.setState({isSubmitFilter: true})
    }

    public onChangeFilterComplete = async () => {
        this.setState({isSubmitFilter: false})
    }

    public updateFilter = (filterData) => {
        this.setState({
            filterObject: {
                ...this.state.filterObject,
                ...filterData
            }
        })
    }

    public onClearFilter = () => {
        this.setState({filterObject: {}})
    }

    render() {
        const {filterObject, isSubmitFilter, filterFormSearch} = this.state

        return (
            <div>
                <FinAidNoticeFilter
                    onChangeFilter={this.onChangeFilter}
                    updateFilter={this.updateFilter}
                    onClearFilter={this.onClearFilter}
                    filterObject={filterObject}
                    setFilterFormSearch={(value) => this.setState({filterFormSearch: value})}
                />
                <FinAidNoticeTable
                    filterObject={filterObject}
                    filterFormSearch={filterFormSearch}
                    isSubmitFilter={isSubmitFilter}
                    onChangeFilterComplete={this.onChangeFilterComplete}
                />
            </div>
        )
    }
}

export default FinancialAidStaffTabNotesTab
