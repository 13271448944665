import React, {ReactNode} from "react"
import classes from "./InputLabel.module.css"
import cx from "classnames"

export type InputLabelProps = {
    className?: string
    required?: boolean
    error?: boolean
    children: ReactNode
}

export const InputLabel = ({children, required = false, error = false, className}: InputLabelProps) => {
    return (
        <span
            className={cx(
                classes.root,
                {
                    [classes.rootError]: error
                },
                className
            )}>
            {children}
            {required && <span className={classes.asterisk}> *</span>}
        </span>
    )
}
