import React from "react"
import moment from "moment"
import classNames from "classnames"
import styles from "./Tasks.module.css"

export function TaskDueDate(props) {
    const {date} = props
    if (!date) return <></>
    const isLate = moment(date).isSameOrBefore(moment())

    return (
        <div className={styles.itemDesc}>
            <p className={classNames(styles.dueText, isLate ? styles.redDueText : "")}>Due</p>
            <p className={classNames(styles.datetime, isLate ? styles.redDateTime : "")}>
                {moment(date).format("MMMM DD, YYYY")}
            </p>
        </div>
    )
}
