import React from "react"
import {get} from "lodash"
import {Auth} from "types/auth"
import {AdvisorTitle, StudentInfo, StaffInfo, OtherInfo, TotalProgress, ProgressItem, StudentScore} from "uiKit"
import {getTotalHourStatistics, getStudentTotalProgress, checkPermission, isFieldActive, getFieldLabel} from "helpers"
import {useModel} from "hooks"
import styles from "./UsersTab.module.css"
import cx from "classnames"
import {Permissions} from "@edular/permissions"
import {Settings} from "types/settings"

type Props = {
    user: Auth.UserProfile
    onLoadStudentStats?: (stats: Auth.StudentStatistics) => void
}

export function ActiveUser(props: Props) {
    const {user} = props
    const model = useModel()

    const showTotalHours = isFieldActive(model, Settings.AcademicLabel.TotalHours)
    const showTotalMajorHours = isFieldActive(model, Settings.AcademicLabel.TotalMajorHours)
    const totalHoursLabel = getFieldLabel(model, Settings.AcademicLabel.TotalHours)
    const totalMajorHoursLabel = getFieldLabel(model, Settings.AcademicLabel.TotalMajorHours)

    const hasPermissionsToSeeAcademicProgress = checkPermission(
        {
            staff: [Permissions.Staff.Users.Students.AcademicProgress.View]
        },
        model
    )

    const renderLeftInfo = (studentStats: Auth.StudentStatistics, activeProfileId: number) => {
        const activeProfile = user.profiles.find((profile) => profile.id === activeProfileId)
        const programVersionTotalClockHours = get(activeProfile, "currentMajorVersionId.totalClockHours", 0)
        const totalHours = getTotalHourStatistics(model, studentStats, programVersionTotalClockHours)

        return (
            <div className={styles.studentLeftInfo}>
                <TotalProgress percent={getStudentTotalProgress(studentStats)} />
                {hasPermissionsToSeeAcademicProgress && (
                    <ProgressItem
                        title="Total hours"
                        totalTitle={totalHoursLabel}
                        totalHours={totalHours}
                        showTotalHours={showTotalHours}
                        totalContractedHours={studentStats.totalHours}
                    />
                )}
            </div>
        )
    }

    const renderRightInfo = (studentStats: Auth.StudentStatistics) => {
        return (
            <div className={styles.studentRightInfo}>
                <div className={cx(styles.studentLeftInfo, styles.studentRightInfo__margin)}>
                    <StudentScore studentStats={studentStats} />
                </div>
            </div>
        )
    }

    if (user.type === Auth.UserProfileType.Student) {
        return (
            <StudentInfo
                student={user}
                isDetailButtonVisible={false}
                advisorTitle={AdvisorTitle.advisors}
                renderExtraPhoneEmail={({studentStats, activeProfileId}) =>
                    renderLeftInfo(studentStats, activeProfileId)
                }
                renderRightInfo={
                    hasPermissionsToSeeAcademicProgress ? ({studentStats}) => renderRightInfo(studentStats) : null
                }
                onLoadStudentStats={props.onLoadStudentStats}
            />
        )
    }

    if (user.type === Auth.UserProfileType.Staff) {
        return <StaffInfo user={user} />
    }

    if (user.type === Auth.UserProfileType.Others) {
        return <OtherInfo user={user} />
    }

    return null
}
