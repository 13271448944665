import React from "react"
import {Button} from "antd"
import classNames from "classnames"
import {Icon} from "components/Icon"
import styles from "./BaseButton.module.css"

export function AddCircleButton(props) {
    const {title, className, disabled, ...rest} = props

    return (
        <Button
            className={classNames(styles.baseAddCircleBtn, {[styles.disabled]: !!disabled}, className || "")}
            shape="circle"
            icon={<Icon className={styles.iconPlus} icon="PLUS" color="#FFF" />}
            disabled={disabled}
            {...rest}
        />
    )
}
