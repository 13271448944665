import React from "react"
import {Spin} from "antd"
import styles from "./BaseLoading.module.css"

type Props = {
    isShow?: boolean
}

export function BaseLoading(props: Props) {
    const {isShow} = props
    if (!isShow) return null

    return (
        <div className={styles.overlay}>
            <Spin />
        </div>
    )
}
