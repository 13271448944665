import React from "react"
import {useTranslation} from "react-i18next"
import classNames from "classnames"
import moment from "moment"
import {getFullName} from "helpers"
import styles from "./CreatedInfo.module.css"
import {useModel} from "hooks/useModel"
import {Tooltip} from "antd"

export function CreatedInfo(props) {
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const {data, userInfoKey = "updatedByProfile", className, userCreatedInfoKey} = props
    const {t} = useTranslation("common")

    return (
        <p className={classNames(styles.createdInfo, className)}>
            <span className={styles.createdInfo__text}>
                {t("action.created")}{" "}
                {data.createdAt ? (
                    <Tooltip title={moment(data.createdAt).format("dddd, MMMM D YYYY, h:mm A")}>
                        <b>{moment(data.createdAt).format(userDateFormat)}</b>
                    </Tooltip>
                ) : (
                    <b>N/A</b>
                )}{" "}
                {userCreatedInfoKey && data[userCreatedInfoKey] && (
                    <>
                        {t("action.by")} <b>{getFullName(data[userCreatedInfoKey])}</b>
                    </>
                )}
                . {t("action.lastModified")}{" "}
                {data.updatedAt ? (
                    <Tooltip title={moment(data.updatedAt).format("dddd, MMMM D YYYY, h:mm A")}>
                        <b>{moment(data.updatedAt).format(userDateTimeFormat)}</b>
                    </Tooltip>
                ) : (
                    <b>N/A</b>
                )}{" "}
                {data[userInfoKey] && (
                    <>
                        {t("action.by")} <b>{getFullName(data[userInfoKey])}</b>
                    </>
                )}
            </span>
        </p>
    )
}
