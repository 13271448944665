/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import {CareerServices} from "types/careerServices"
import {displaySince} from "helpers"
import styles from "./JobItemPanel.module.css"
import {ReactComponent as DetailNavigationIcon} from "./assets/detail.svg"
import {Link} from "react-router-dom"

const EMPLOYMENT_TYPE = {
    "full-time": "Full time",
    "part-time": "Part time",
    contract: "Contract"
}

const EXPERIENCE_LEVEL = {
    "post-grad": "Post-Graduation"
}

const WORKING_TYPE = {
    remote: "Yes",
    hybrid: "Hybrid",
    office: "No"
}

type Props = {
    job: CareerServices.Job
}

export function JobItemPanel(props: Props) {
    const {job} = props

    const renderJobInfo = () => {
        return (
            <div className={styles.jobInfo}>
                <div className={styles.contentWrap}>
                    <div className={styles.contentTitle}>Posted</div>
                    <div className={styles.contentValue}>{displaySince(job.createdAt, "MM/DD/YYYY hh:mm A")}</div>
                </div>
                <div className={styles.contentWrap}>
                    <div className={styles.contentTitle}>Location</div>
                    <div className={styles.contentValue}>{job.location}</div>
                </div>
                <div className={styles.contentWrap}>
                    <div className={styles.contentTitle}>Remote</div>
                    <div className={styles.contentValue}>{WORKING_TYPE[job.workingType]}</div>
                </div>
                <div className={styles.contentWrap}>
                    <div className={styles.contentTitle}>Employment type</div>
                    <div className={styles.contentValue}>
                        {job.employmentType ? EMPLOYMENT_TYPE[job.employmentType] : "-"}
                    </div>
                </div>
                <div className={styles.contentWrap}>
                    <div className={styles.contentTitle}>Experience Level</div>
                    <div className={styles.contentValue}>{job.expLevel ? EXPERIENCE_LEVEL[job.expLevel] : "-"}</div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className={styles.headingTitle}>
                <div className={styles.titleWrap}>
                    <div className={styles.jobLogo}>
                        {job.employer.logo && (
                            <img src={JSON.parse(job.employer.logo)["64"]} width={48} height={48} alt="Company logo" />
                        )}
                    </div>
                    <div className={styles.jobTitle}>{job.title}</div>
                </div>
                <Link to={`/career-services/job-details/${job.id}`}>
                    <div className={styles.profileLinkIconWrap}>
                        <DetailNavigationIcon className={styles.profileLinkIcon} />
                    </div>
                </Link>
            </div>
            <div className={styles.categoriesWrap}>
                {job.categories?.map((item) => {
                    return (
                        <div key={item.id} className={styles.categoryItem}>
                            {item.name}
                        </div>
                    )
                })}
            </div>
            {renderJobInfo()}
        </div>
    )
}
