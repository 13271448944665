import {useCallback, useEffect, useMemo, useState} from "react"
import {Checkbox, Tabs} from "antd"
import {Icon} from "components"
import styles from "./MakeupAttendance.module.css"
import {TableMakeupAttendance} from "./MakeupAttendance"
import {TabHeader} from "components/Tab"
import {academicPlansService} from "services"
import {roundTwoDigits} from "helpers"
import {BaseRangePicker} from "components/DateTimePicker"
import classNames from "classnames"
import moment from "moment"

const {TabPane} = Tabs

const ATTENDANCE_TABS = {
    ACTIVE: "1",
    INCOMPLETE: "2",
    COMPLETED: "3"
}
type Props = {
    studentProfileId?: number
}
export enum MakeupStudentType {
    Active = "active",
    Incomplete = "incomplete",
    Completed = "completed"
}
const MakeupAttendance = (props: Props) => {
    const {studentProfileId} = props
    const [activeTab, setActiveTab] = useState(ATTENDANCE_TABS.ACTIVE)
    const [stats, setStats] = useState<{
        numberOfCompletedStudent: number
        numberOfMakeupStudent: number
        numberOfUnconfirmedMakeupAttendance: number
        numberOfNotVerifiedMakeupAttendance: number
        numberOfIncompleteMakeupAttendance: number
    }>({
        numberOfCompletedStudent: 0,
        numberOfMakeupStudent: 0,
        numberOfUnconfirmedMakeupAttendance: 0,
        numberOfNotVerifiedMakeupAttendance: 0,
        numberOfIncompleteMakeupAttendance: 0
    })
    const [filters, setFilters] = useState<{
        isToday: boolean
        isUnconfirmed: boolean
        isNotVerified: boolean
        dateRange?: [moment.Moment, moment.Moment]
    }>({
        isToday: true,
        isUnconfirmed: false,
        isNotVerified: false
    })

    const getClassroomMakeupAttendanceStats = useCallback(async () => {
        if (!filters.isToday && !filters.dateRange?.length) {
            return
        }
        const params = {
            dateRange: {
                startDate: moment().startOf("day").format("YYYY-MM-DD"),
                endDate: moment().endOf("day").format("YYYY-MM-DD")
            },
            date: moment().startOf("day").format("YYYY-MM-DD")
        }
        if (!filters.isToday && filters.dateRange) {
            params.dateRange = {
                startDate: filters.dateRange[0].startOf("day").format("YYYY-MM-DD"),
                endDate: filters.dateRange[1].endOf("day").format("YYYY-MM-DD")
            }
        }
        const response = await academicPlansService.getClassroomMakeupAttendanceStats(params)
        setStats(response)
    }, [filters])

    useEffect(() => {
        getClassroomMakeupAttendanceStats()
    }, [getClassroomMakeupAttendanceStats])

    const renderMakeupAttendanceStats = useMemo(() => {
        return (
            <div className={styles.stats}>
                <div className={styles.statsBox}>
                    <Icon className={styles.statsIcon} color="grey" icon="CLOCK" />
                    <span className={styles.statsTitle}>Number Of Makeup Students (Daily)</span>
                    <span className={styles.statsValue}>{stats.numberOfMakeupStudent}</span>
                </div>
                <div className={styles.statsBox}>
                    <Icon className={styles.statsIcon} color="grey" icon="CLOCK" />
                    <span className={styles.statsTitle}>Number Of Completed Students (Daily)</span>
                    <span className={styles.statsValue}>{stats.numberOfCompletedStudent}</span>
                </div>

                <div
                    onClick={() => {
                        setFilters({
                            ...filters,
                            isUnconfirmed: !filters.isUnconfirmed
                        })
                    }}
                    className={classNames(styles.filterStatsBox, {
                        [styles.statsBoxSelected]: filters.isUnconfirmed
                    })}>
                    <Icon className={styles.statsIcon} color="grey" icon="CLOCK" />
                    <span className={styles.statsTitle}>Unconfirmed (Instructor)</span>
                    <span className={styles.statsValue}>{stats.numberOfUnconfirmedMakeupAttendance}</span>
                </div>
                <div
                    onClick={() => {
                        setFilters({
                            ...filters,
                            isNotVerified: !filters.isNotVerified
                        })
                    }}
                    className={classNames(styles.filterStatsBox, {
                        [styles.statsBoxSelected]: filters.isNotVerified
                    })}>
                    <Icon className={styles.statsIcon} color="grey" icon="CLOCK" />
                    <span className={styles.statsTitle}>Not Verified (Academic Affairs)</span>
                    <span className={styles.statsValue}>{stats.numberOfNotVerifiedMakeupAttendance}</span>
                </div>
                <div className={classNames(styles.filterStatsBox)}>
                    <Icon className={styles.statsIcon} color="grey" icon="CLOCK" />
                    <span className={styles.statsTitle}>Incomplete</span>
                    <span className={styles.statsValue}>{stats.numberOfIncompleteMakeupAttendance}</span>
                </div>
            </div>
        )
    }, [filters, stats])

    const renderActiveMakeupAttendance = useMemo(() => {
        return (
            <TableMakeupAttendance
                getClassroomMakeupAttendanceStats={getClassroomMakeupAttendanceStats}
                filters={filters}
                studentProfileId={+studentProfileId}
                tableType={MakeupStudentType.Active}
                key={MakeupStudentType.Active}
            />
        )
    }, [filters, getClassroomMakeupAttendanceStats, studentProfileId])

    return (
        <div className={styles.container}>
            {!studentProfileId ? (
                <>
                    <Checkbox
                        checked={filters.isToday}
                        onChange={(event) =>
                            setFilters({
                                ...filters,
                                isToday: event.target.checked
                            })
                        }>
                        <span className={styles.mediaCheckbox}>Today</span>
                    </Checkbox>
                    {!filters.isToday ? (
                        <BaseRangePicker
                            suffixIcon={
                                <div className={styles.calendarIconWrap}>
                                    <Icon icon="CALENDAR_INPUT" color="#fff" />
                                </div>
                            }
                            className={styles.datePicker}
                            value={
                                filters.dateRange ? [moment(filters.dateRange[0]), moment(filters.dateRange[1])] : null
                            }
                            onChange={(value) => {
                                if (value) {
                                    setFilters({
                                        ...filters,
                                        dateRange: value
                                    })
                                } else {
                                    setFilters({
                                        ...filters,
                                        dateRange: undefined
                                    })
                                }
                            }}
                        />
                    ) : null}
                </>
            ) : null}
            {!studentProfileId ? renderMakeupAttendanceStats : null}

            <Tabs className="fullwidth klassSubTab" activeKey={activeTab} onChange={setActiveTab}>
                <TabPane key={ATTENDANCE_TABS.ACTIVE} tab={<TabHeader title="Active" />}>
                    {activeTab === ATTENDANCE_TABS.ACTIVE && renderActiveMakeupAttendance}
                </TabPane>
                <TabPane key={ATTENDANCE_TABS.INCOMPLETE} tab={<TabHeader title="Incomplete" />}>
                    {activeTab === ATTENDANCE_TABS.INCOMPLETE && (
                        <TableMakeupAttendance
                            getClassroomMakeupAttendanceStats={getClassroomMakeupAttendanceStats}
                            studentProfileId={+studentProfileId}
                            filters={filters}
                            tableType={MakeupStudentType.Incomplete}
                            key={MakeupStudentType.Incomplete}
                        />
                    )}
                </TabPane>
                <TabPane key={ATTENDANCE_TABS.COMPLETED} tab={<TabHeader title="Completed" />}>
                    {activeTab === ATTENDANCE_TABS.COMPLETED && (
                        <TableMakeupAttendance
                            filters={filters}
                            studentProfileId={+studentProfileId}
                            getClassroomMakeupAttendanceStats={getClassroomMakeupAttendanceStats}
                            tableType={MakeupStudentType.Completed}
                            key={MakeupStudentType.Completed}
                        />
                    )}
                </TabPane>
            </Tabs>
        </div>
    )
}

export default MakeupAttendance
