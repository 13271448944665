import {get} from "lodash"
import BaseService from "./Base"
import {AutoDialer} from "types/auto-dialer"

class AutoDialerService extends BaseService {
    async addToDialer(params): Promise<void> {
        const response = await this.post("/v1/auto-dialer/students/add", params)
        return get(response, "data", [])
    }

    async getListStudents(params): Promise<{total: number; data: AutoDialer.Student[]}> {
        const response = await this.post("/v1/auto-dialer/students/list", params)
        return get(response, "data", [])
    }

    async getCallLogs(params): Promise<{total: number; data: AutoDialer.CallLog[]}> {
        const response = await this.post("/v1/auto-dialer/calls/list", params)
        return get(response, "data", [])
    }

    async dial(params): Promise<AutoDialer.ZoomAuthorization | AutoDialer.Student> {
        const response = await this.post("/v1/auto-dialer/dial", params)
        return get(response, "data.data", {})
    }

    async removeFromDialer(params): Promise<void> {
        const response = await this.post("/v1/auto-dialer/students/remove", params)
        return get(response, "data", [])
    }

    async getAutoDialerState(): Promise<AutoDialer.AutoDialerState> {
        const response = await this.post("/v1/auto-dialer/status", {})
        return get(response, "data.data", {})
    }

    async stopAutoDialer(): Promise<void> {
        const response = await this.post("/v1/auto-dialer/stop-auto", {})
        return get(response, "data.data", {})
    }

    async uploadStudents(params): Promise<{total: number; existed: number; created: number}> {
        const response = await this.post("/v1/auto-dialer/students/upload", params)
        return get(response, "data.data", {})
    }
}

export default AutoDialerService
