import BaseService from "./Base"
import {ApiListResponse, ApiResponse} from "types/api"
import {AxiosResponse} from "axios"

class StudentCoursesV2 extends BaseService {
    getAll = async (params = {}) => {
        const defaultParams = {
            range: {
                pageSize: 20,
                page: 1
            },
            sort: {
                orderBy: "profileId",
                orderDir: "ASC"
            }
        }
        const {data: response}: AxiosResponse<ApiListResponse<any[]>> = await this.post("/v2/student-courses/list", {
            ...defaultParams,
            ...params
        })
        if (!response.success) {
            throw response
        }
        return response
    }

    getOne = async (id: number, params = {}): Promise<any | null> => {
        const {data: response}: AxiosResponse<ApiResponse<any[]>> = await this.post("/v2/student-courses/list", {
            ...params,
            filter: {
                id: [id]
            },
            linkedObjects: true
        })

        if (!response.success) {
            throw response
        }

        const [user = null] = response.data
        return user
    }

    delete = async (params) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v2/student-courses/delete", params)
        if (!response.success) {
            throw response
        }
        return response.data
    }

    create = async (params) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v2/student-courses/create", params)
        if (!response.success) {
            throw response
        }
        return response.data
    }
}
export default StudentCoursesV2
