/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {Row, Col} from "antd"
import {Icon} from "components/Icon"
import {TotalProgress} from "uiKit"
import {useModel, useVisible} from "hooks"
import {AcademicAdvisorPopup} from "./AcademicAdvisorPopup"
import styles from "./TermInfo.module.css"
import {AcademicPlans} from "types/academicPlans"
import moment from "moment"
import {checkPermission, roundTwoDigits} from "helpers"
import {Permissions} from "@edular/permissions"
import {TermAndCourseProgress} from "uiKit/StudentProgress/TermAndCourseProgress"
import {Major} from "types/major"

type Props = {
    activeTerm: AcademicPlans.TermCourseStats
    termIds: number[]
    attendanceRating: number
    gpa: number
    setGpa: (value: number) => void
    studentId: number
    progressData: {
        completed: number
        attempted: number
        distEdHours: number
        appliedToDegree: number
    }
}

export function TermInfo(props: Props) {
    const advisorPopup = useVisible()
    const {activeTerm, gpa, attendanceRating, progressData} = props
    const [totalHours, setTotalHours] = useState([
        {title: "Total hours scheduled", hour: 0, color: "#e95c7b"},
        {title: "Total hours completed", hour: 0, color: "#18a957"},
        {title: "Total hours attempted", hour: 0, color: "#ff8a38"},
        {title: "Total hours remaining", hour: 0, color: "#939393"},
        {title: "Total DistEd Hours", hour: 0, color: "#11763d"}
    ])
    const [percent, setPercent] = useState(0)
    const isClockHourSchool = activeTerm?.type === Major.Unit.Clock

    useEffect(() => {
        let remainingHour = (progressData?.appliedToDegree ?? 0) - (progressData?.completed ?? 0)
        remainingHour = Math.max(remainingHour, 0)

        setTotalHours([
            {
                title: "Total hours scheduled",
                hour: progressData?.attempted ? Math.round(progressData?.attempted) : 0,
                color: "#e95c7b"
            },
            {
                title: "Total hours completed",
                hour: progressData?.completed ? roundTwoDigits(progressData.completed) : 0,
                color: "#18a957"
            },
            {
                title: "Total hours attempted",
                hour: isClockHourSchool ? 0 : progressData.attempted ? Math.round(progressData.attempted) : 0,
                color: "#ff8a38"
            },
            {title: "Total hours remaining", hour: remainingHour ? roundTwoDigits(remainingHour) : 0, color: "#939393"},
            {
                title: "Total DistEd Hours",
                hour: progressData?.distEdHours ? Math.round(progressData?.distEdHours) : 0,
                color: "#11763d"
            },
            {
                title: "Total Hours",
                hour: progressData?.appliedToDegree ? Math.round(progressData?.appliedToDegree) : 0,
                color: "#11763d"
            }
        ])
        setPercent(
            Math.min(
                Math.round(
                    progressData?.appliedToDegree > 0
                        ? (progressData?.completed * 100) / progressData?.appliedToDegree
                        : 0
                ),
                100
            )
        )
    }, [isClockHourSchool, progressData])

    const model = useModel()

    const hasPermissionsToSeeAcademicProgress = checkPermission(
        {
            student: [Permissions.Student.Users.AcademicProgress.OverallProgress.View],
            staff: [Permissions.Staff.Academics.Registrar.Students.TermsAndCourses.View]
        },
        model
    )

    return (
        <div className={styles.wrap}>
            <Row gutter={[40, 12]}>
                <Col span={12}>
                    <div className={styles.leftHeader}>
                        <span className={styles.termName}>{activeTerm?.title}</span>
                        <div className={styles.termIdInfo}>
                            <span className={styles.termIdInfo__title}>Term ID:</span>
                            <span className={styles.termIdInfo__value}>{activeTerm?.code}</span>
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className={styles.rightHeader}>
                        <div className={styles.rightHeaderIconWrap} onClick={advisorPopup.open}>
                            <Icon icon="PROFILE_FILL" className={styles.rightHeaderIcon} color="#fff" />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row gutter={[40, 8]}>
                {/* <Col span={12}>
                    <div className={styles.item}>
                        <span className={styles.item__title}>Start Date</span>
                        <span className={styles.item__value}>{moment(activeTerm.startDate).format("MM/DD/YYYY")}</span>
                    </div>
                </Col> */}
                {/* <Col span={12}>
                    <div className={styles.item}>
                        <span className={styles.item__title}>GPA</span>
                        <span className={styles.item__value}>{gpa}</span>
                    </div>
                </Col> */}
                {/* <Col span={12}>
                    <div className={styles.item}>
                        <span className={styles.item__title}>Projected End Date</span>
                        <span className={styles.item__value}>{moment(activeTerm.endDate).format("MM/DD/YYYY")}</span>
                    </div>
                </Col> */}
                {/* <Col span={12}>
                    <div className={styles.item}>
                        <span className={styles.item__title}>Attendance</span>
                        <span className={styles.item__value}>{Math.round(attendanceRating)}%</span>
                    </div>
                </Col> */}
                <Col span={12}>
                    <div className={styles.progressWrap}>
                        <TotalProgress termInfo={activeTerm} percent={percent} />
                    </div>
                </Col>
                {hasPermissionsToSeeAcademicProgress && (
                    <Col span={12}>
                        <div className={styles.progressWrap}>
                            <TermAndCourseProgress
                                title="Total hours"
                                totalTitle="Total hours"
                                totalHours={totalHours}
                                programVersionTotalClockHours={0}
                            />
                        </div>
                    </Col>
                )}
            </Row>
            <AcademicAdvisorPopup isShow={advisorPopup.isVisible} onClose={advisorPopup.close} />
        </div>
    )
}
