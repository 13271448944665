import {action, computed, observable} from "mobx"
import {attendanceService, calendarService, userServiceV3, courseService, academicPlansService} from "services"
import {
    CourseStudentAttendanceStats,
    LastDayOfAttendancePayload,
    LessonAttendance,
    LessonsScheduleItem,
    StudentLessonActivity,
    StudentsAttendanceByCourse,
    UpdateOrCreateAttendancePayload
} from "types/attendance"
import {CalendarDetails, CalendarEvent} from "types/calendar"
import camelize from "camelcase-keys"
import moment from "moment"
import {Course} from "types/courses"

type ApiResponseSuccess<T> = {
    success: true
    data: T
}

type ApiResponseError = {
    success: false
    error: any
}

type ApiResponse<T> = ApiResponseSuccess<T> | ApiResponseError

type ValueCallOptions = {
    camelize: boolean
}

const VALUE_CALL_DEFAULT_OPTIONS: ValueCallOptions = {
    camelize: false
}

const createCalendarEventsPayload = (invited_user_id: number, date: Date) => ({
    sort: {
        orderBy: "start_at_utc",
        order: "ASC"
    },
    filter: {
        from: moment(date).startOf("day").toISOString(),
        to: moment(date).endOf("day").toISOString(),
        include_invites: true,
        invited_user_id: invited_user_id
    },
    pagination: {
        page: 1,
        pageSize: 100000
    }
})

class Value<T> {
    @observable
    public value: T

    @observable
    public isLoading = false

    @observable
    public error: Error | null = null

    @action
    setLoading = (isLoading: boolean) => {
        if (isLoading) {
            this.error = null
        }

        this.isLoading = isLoading
    }

    @action
    setError = (error: Error) => {
        this.error = error
    }

    @action
    setValue = (value: T) => {
        this.value = value
    }

    constructor(value: T) {
        this.value = value
    }

    @action
    call = async <Fn extends (...args: any[]) => Promise<ApiResponse<T>>>(
        fn: Fn,
        args: Parameters<Fn>,
        options: ValueCallOptions | undefined = VALUE_CALL_DEFAULT_OPTIONS
    ) => {
        try {
            this.setLoading(true)
            const res = await fn(...args)
            if (res.success) {
                const nextValue = options.camelize ? camelize(res.data, {deep: true}) : res.data

                this.setValue(nextValue)
            } else {
                const error = new Error((res as ApiResponseError).error?.message || "Something went wrong")
                this.setError(error)
            }
        } catch (error: any) {
            this.setError(error)
        } finally {
            this.setLoading(false)
        }
    }
}

type AttendanceRating = {
    totalHours: number
    totalHoursCompleted: number
    percentage: number
}
type ProgressAttendance = {
    completedHours: number
    totalHours: number
    id: number
}
type TotalProgressAttendance = {
    progress: ProgressAttendance[]
    distedHours: number
}
class AcademicsInstructionalStore {
    @observable
    attendanceRating = new Value<AttendanceRating | null>(null)

    @observable
    progressAttendance = new Value<TotalProgressAttendance>({} as any)

    @observable
    termProgressAttendance = new Value<TotalProgressAttendance>({} as any)

    @observable
    attendanceRatingByCourse = new Value<AttendanceRating | null>(null)

    @observable
    allActionNeededLessonsAttendance = new Value<LessonAttendance[]>([])

    @observable
    allLessonsAttendanceByCourse = new Value<LessonAttendance[]>([])

    @observable
    allActionNeededLessonsAttendanceByCourse = new Value<LessonAttendance[]>([])

    @observable
    primaryCalendar = new Value<CalendarDetails | null>(null)

    @observable
    calendarEvents = new Value<CalendarEvent[]>([])

    @observable
    todayLessons = new Value<LessonsScheduleItem[]>([])

    @observable
    currentCourse = new Value<Course.Course | null>(null)

    @observable
    courseStudentsAttendanceStats = new Value<CourseStudentAttendanceStats[]>([])

    @observable
    studentsAttendanceByCourse = new Value<StudentsAttendanceByCourse | null>(null)

    @observable
    lessonActivity = new Value<StudentLessonActivity[]>([])

    @observable
    missedAttendanceStats = new Value<{
        makeUpHours?: number
        missedHours?: number
        remainingHours?: number
    }>({})

    @observable
    lastDayAttendance: Array<{
        termId: number
        courseId: number
        scheduleId: number
        profileId: number
        lastDayOfAttendance: string
    }> = []

    @computed
    get lastLesson() {
        const [lastLessonAttendance] = this.allActionNeededLessonsAttendance.value.slice(-1)
        return lastLessonAttendance ?? null
    }

    @computed
    get lastLessonByCourse() {
        const [lastLessonAttendance] = this.allActionNeededLessonsAttendanceByCourse.value.slice(-1)
        return lastLessonAttendance ?? null
    }

    @action getAttendanceRating = async (studentProfileIds?: number[], termIds?: number[]) => {
        const response = await academicPlansService.getAttendancePercentage({
            studentProfileIds,
            termIds
        })
        this.attendanceRating.setValue(response)
    }

    @action getAttendanceRatingByCourse = async (
        courseId: number,
        studentProfileIds?: number[],
        termIds?: number[],
        scheduleId?: number
    ) => {
        const response = await academicPlansService.getAttendancePercentage({
            courseId,
            studentProfileIds,
            termIds,
            scheduleId
        })
        this.attendanceRatingByCourse.setValue(response)
    }

    @action
    getAllActionNeededLessonsAttendance = async (studentProfileIds: number[], termIds?: number[]) => {
        await this.allActionNeededLessonsAttendance.call(attendanceService.getLessonsAttendance, [
            {onlyActionNeeded: true, studentProfileIds, termIds}
        ])
    }

    @action
    getAllLessonsAttendanceByCourse = async (
        courseId: number,
        studentProfileIds?: number[],
        termIds?: number[],
        scheduleId?: number
    ) => {
        this.allLessonsAttendanceByCourse.call(attendanceService.getLessonsAttendance, [
            {courses: [courseId], studentProfileIds, termIds, scheduleId}
        ])
    }

    @action
    getAllActionNeededLessonsAttendanceByCourse = async (
        courseId: number,
        studentProfileIds?: number[],
        termIds?: number[],
        scheduleId?: number
    ) => {
        await this.allActionNeededLessonsAttendanceByCourse.call(attendanceService.getLessonsAttendance, [
            {courses: [courseId], onlyActionNeeded: true, studentProfileIds, termIds, scheduleId}
        ])
    }

    @action
    getMissedAttendanceStats = async (payload: {
        courseIds?: number[]
        studentProfileIds?: number[]
        termIds?: number[]
    }) => {
        const response = await attendanceService.getMissedAttendanceStats(payload)
        this.missedAttendanceStats.setValue(response)
    }

    @action
    getProgressAttendance = async (
        courseId: number,
        termIds: number[],
        studentProfileIds: number[],
        scheduleId?: number
    ) => {
        await this.progressAttendance.call(attendanceService.getCourseStatistics, [
            {termIds, courseId, studentProfileIds, scheduleId}
        ])
    }

    @action
    getTermProgressAttendance = async (courseId: number, termIds: number[], studentProfileIds: number[]) => {
        await this.termProgressAttendance.call(attendanceService.getCourseStatistics, [
            {termIds, courseId, studentProfileIds}
        ])
    }

    @action
    getPrimaryCalendar = async () => {
        if (!this.primaryCalendar.value) {
            await this.primaryCalendar.call(calendarService.getMyPrimaryCalendar, [{}, {}], {camelize: true})
        }
    }

    @action
    getCalendarEvents = async (userId: number, date: Date) => {
        if (!this.primaryCalendar.value) {
            await this.getPrimaryCalendar()
        }
        const payload = createCalendarEventsPayload(userId, date)
        await this.calendarEvents.call(calendarService.getAll, [payload], {
            camelize: true
        })
    }

    @action
    getTodayLessons = async (params: {nextLessons?: boolean; termIds?: number[]; scheduleId?: number} = {}) => {
        await this.todayLessons.call(userServiceV3.getTodayLessons as any, [params])
    }

    @action
    getCurrentCourse = async (courseId: number) => {
        await this.currentCourse.call(courseService.getOne, [courseId], {camelize: true})
    }

    @action
    getCourseStudentsAttendanceStats = async (courseId: number, termIds: number[], scheduleId?: number) => {
        await this.courseStudentsAttendanceStats.call(attendanceService.getCourseStudentsStats, [
            courseId,
            termIds,
            [],
            [],
            scheduleId
        ])
    }

    @action
    getStudentsAttendanceByCourse = async (courseId: number) => {
        await this.studentsAttendanceByCourse.call(attendanceService.getStudentsAttendanceByCourse, [courseId])
    }

    @action
    getLessonActivity = async ({
        lessonId,
        courseId,
        termIds,
        scheduleId
    }: {
        lessonId: number
        courseId: number
        termIds: number[]
        scheduleId?: number
    }) => {
        await this.lessonActivity.call(attendanceService.getLessonActivity, [{lessonId, courseId, termIds, scheduleId}])
    }

    @action
    updateOrCreateAttendance = async (payload: UpdateOrCreateAttendancePayload[]) => {
        const response = await attendanceService.updateOrCreateAttendance(payload)

        return response.success
    }
}

export {AcademicsInstructionalStore}
