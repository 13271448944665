import React from "react"
import AsyncSelect from "react-select/async"
import classNames from "classnames"

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: "6px",
        borderColor: "transparent",
        boxShadow: state?.selectProps?.error ? "none" : "1px 2px 3px 1px var(--black-100)",
        border: "none",
        minHeight: "44px",
        fontSize: 16
    }),

    option: (provided) => ({
        ...provided,
        padding: "10px",
        cursor: "pointer"
    }),

    container: (provided, state) => ({
        ...provided,
        border: state?.selectProps?.error ? "1px solid var(--error-400-base) !important" : "none",
        borderRadius: 6
    }),

    indicatorSeparator: () => ({
        display: "none"
    }),

    dropdownIndicator: (provided, state) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        width: "40px",
        height: "100%",
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
        justifyContent: "center",
        color: "#666666",
        cursor: "pointer"
    }),

    placeholder: (provided) => ({
        ...provided,
        fontSize: "16px",
        color: "var(--black-200)"
    }),

    multiValue: (styles) => {
        return {
            ...styles,
            backgroundColor: "var(--primary-400-base)"
        }
    },

    multiValueLabel: (styles) => ({
        ...styles,
        color: "var(--whitebase)"
    }),

    multiValueRemove: (styles) => ({
        ...styles,
        color: "var(--whitebase)"
    }),

    menuList: () => ({
        maxHeight: "200px",
        overflow: "auto"
    }),

    menu: (provided) => ({
        ...provided,
        zIndex: 10
    }),

    groupHeading: (provided) => ({
        ...provided,
        backgroundColor: "var(--primary-100)",
        fontSize: 18,
        fontWeight: 700,
        lineHeight: 1.11,
        color: "var(--black-200)",
        paddingTop: 4,
        paddingBottom: 4
    })
}

type SelectComponentsProps = {[key in string]: any}

export type KlassDropdownAsyncProps = SelectComponentsProps & {
    options?: any[]
    value?: any
    onChange?: any
    placeholder?: string
    valueKey?: string
    labelKey?: string
    hasEmptyOption?: boolean
    readOnly?: boolean
    isMulti?: boolean
    stylesCustom?: any
    checkRemoveButton?: boolean
    closeMenuOnSelect?: boolean
    refData?: any
    cacheOptions?: boolean
    loadOptions?: (search: string, loadedOptions: any) => any
}

export function KlassDropdownAsync(props: KlassDropdownAsyncProps) {
    const {
        stylesCustom,
        valueKey,
        labelKey,
        isMulti,
        isDisabled = false,
        readOnly = false,
        isClearable = false,
        className,
        refData,
        cacheOptions = true,
        ...rest
    } = props
    let valueKeySelect = valueKey || "id"
    let labelKeySelect = labelKey || "name"
    let styles = customStyles
    if (stylesCustom) styles = {...styles, ...stylesCustom}
    const customProps = {...rest, styles}

    return (
        <AsyncSelect
            cacheOptions={cacheOptions}
            defaultOptions
            noOptionsMessage={({inputValue}) => (!inputValue ? "Enter text to search" : "No data")}
            getOptionValue={(option: any) => option[valueKeySelect]}
            getOptionLabel={(option: any) => option[labelKeySelect]}
            closeMenuOnSelect
            isMulti={isMulti}
            menuIsOpen={readOnly ? false : undefined}
            isDisabled={isDisabled}
            isSearchable={!readOnly}
            isClearable={isClearable && !readOnly}
            classNamePrefix="klassapp-dropdown"
            className={classNames("klassapp-dropdown", className, {readOnly})}
            ref={refData}
            {...customProps}
        />
    )
}
