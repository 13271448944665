import {useQuery} from "@tanstack/react-query"
import {finAidService} from "services"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {Order} from "types/common"
import {VerificationStatus} from "types/fin-aid/verification-status"

const INITIAL_DATA: VerificationStatus[] = []

export const useAllVerificationStatuses = () => {
    const query = useQuery(
        ["all-verification-statuses"],
        async () => {
            const {data} = await finAidService.getAllVerificationStatuses({
                range: {
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE
                },
                sort: {
                    orderBy: "priority",
                    orderDir: Order.Asc
                },
                withItemCount: true
            })
            return data as VerificationStatus[]
        },
        {}
    )
    return {verificationStatuses: query.data ?? INITIAL_DATA}
}
