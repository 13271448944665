import {AcademicsInstructionalStore} from "./academics/instructional/AcademicsInstructionalStore"

export const rootStore = {
    academics: {
        instructional: new AcademicsInstructionalStore()
    }
}

export type RootStore = typeof rootStore

export {StoreProvider} from "./StoreProvider"
