/* eslint-disable react-hooks/exhaustive-deps */
import {useModel, useVisible} from "hooks"
import {useContext, useEffect, useState} from "react"
import {intersection} from "lodash"
import {getFullName, handleError, toastError, toastSuccess} from "helpers"
import {chatServiceV2} from "services"
import {useTranslation} from "react-i18next"
import {CommunicationContext, CommunicationProvider} from "context/CommunicationContext"
import {ChatRoomProvider} from "context/ChatRoomContext"
import styles from "./ChatModalV2.module.css"
import {ConfirmPopup} from "uiKit"
import {BaseLoading, BasePopup} from "components"
import {Compose} from "../Compose"
import {convertRoom} from "helpers/chat"
import {Communication} from "types/communication"

type Props = {
    user: any
    onShow: boolean
    onClose: () => void
    userIdField: string
    departmentId: number
    initialValue?: any
}

const ChatModal = (props: Props) => {
    const {departmentId, user, userIdField, onClose, onShow} = props
    const model = useModel()
    const {t} = useTranslation(["chat", "user"])
    const {changeRoom} = useContext(CommunicationContext)
    const confirmPopup = useVisible(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (user && user?.[userIdField]) {
            if (departmentId) {
                findDepartmentRoom()
            } else {
                findDirectChatRoom()
            }
        }
    }, [departmentId, user])

    const findDepartmentRoom = async () => {
        if (!user) return
        try {
            setIsLoading(true)
            const studentCampusIds: number[] = user?.campusIds || []
            const currentUser = await model.getOneCompleteUser(model.user.id)
            const userCampuses = currentUser.profiles.map(({campuses}) => campuses.map(({id}) => id))
            const userCampusIds = userCampuses.flatMap((id) => id)
            const sameCampusIds = intersection(userCampusIds, studentCampusIds)
            if (!sameCampusIds.length) {
                toastError(
                    `Please assign campus to this student to start ${t(`common:moduleName.${departmentId}`)} chat`
                )
                onClose()
                return
            }
            const toUserId = user?.[userIdField]
            const departmentRoom = await chatServiceV2.addDepartmentCampusRoom(departmentId, sameCampusIds[0], toUserId)
            changeRoom(departmentRoom, undefined, false)
        } catch (err) {
            handleError(err)
            onClose()
        } finally {
            setIsLoading(false)
        }
    }

    const findDirectChatRoom = async () => {
        if (!user) return
        try {
            setIsLoading(true)
            const data = await chatServiceV2.addRoom({
                type: "private",
                userIds: [user[userIdField]]
            })
            const directChatRoom = convertRoom(Communication.RoomTypes.DirectChat, data)
            changeRoom(directChatRoom, undefined, false)
        } catch (err) {
            handleError(err)
            onClose()
        } finally {
            setIsLoading(false)
        }
    }

    const onCloseConfirmPopup = () => {
        confirmPopup.close()
        onClose()
    }

    const onCancelConfirmPopup = () => {
        confirmPopup.close()
    }

    if (!user) return null

    return (
        <>
            <ConfirmPopup
                isVisible={confirmPopup.isVisible}
                title={t("chat.confirmCancelSenMessage")}
                onClose={onCancelConfirmPopup}
                onConfirm={onCloseConfirmPopup}
            />
            <BasePopup isShow={onShow} onClose={onClose} leftIcon="MESSAGE" leftIconColor="#fff" width="700px">
                <p className={styles.titlePopup}>{t(departmentId ? "chat.departmentalMessage" : "chat.message")}</p>
                <div className={styles.wrapperName}>
                    <h1 className={styles.title}>
                        <span className={styles.name}>{getFullName(user)}</span>
                        <span className={styles.spanId}>ID: {user?.customUserId}</span>
                    </h1>
                </div>
                <Compose
                    userList={[
                        {
                            userId: user?.[userIdField],
                            isMe: false,
                            isDepartmentChatUser: true,
                            fullName: getFullName(user)
                        }
                    ]}
                    onSuccess={() => {
                        toastSuccess("Message sent")
                        onClose()
                    }}
                    emojiPickerClassName={styles.emojiPicker}
                />
                <BaseLoading isShow={isLoading} />
            </BasePopup>
        </>
    )
}

export function ChatModalV2(props: Props) {
    return (
        <CommunicationProvider>
            <ChatRoomProvider>
                <ChatModal {...props} />
            </ChatRoomProvider>
        </CommunicationProvider>
    )
}
