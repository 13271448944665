import React, {useEffect, useState} from "react"
import moment from "moment"
import {convertTZ} from "helpers"
import styles from "./AddManualDailyAttendance.module.css"

const Clock = (props: {timezone?: string}) => {
    const {timezone} = props
    const [currentTime, setCurrentTime] = useState(moment(convertTZ(new Date(), timezone)).format("HH:mm:ss"))

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(moment(convertTZ(new Date(), timezone)).format("HH:mm:ss"))
        }, 1000)

        return () => clearInterval(intervalId)
    }, [timezone])

    return <p className={styles.textDate}>{currentTime}</p>
}

export default Clock
