import React from "react"
import {Icon} from "components/Icon"
import styles from "./Toast.module.css"

type Props = {
    id: string
    title: string
    description?: string
    onClose: (id: string) => void
}

export function ToastInfo(props: Props) {
    const {title, description, onClose, id} = props

    return (
        <div className={styles.infoWrap}>
            <div className={styles.closeWrap} onClick={() => onClose(id)}>
                <Icon icon="CLOSE" color="#212121" className={styles.closeIcon} />
            </div>
            <span className={styles.title}>{title}</span>
            <span className={styles.description}>{description}</span>
        </div>
    )
}
