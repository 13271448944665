import React, {useState} from "react"
import {get} from "lodash"
import {Popover} from "antd"
import {getColorContrast} from "helpers"
import {EventPreviewContent} from "./EventPreviewContent"
import styles from "./EventContent.module.css"
import moment from "moment-timezone"
import {useModel} from "hooks"
import {CalendarEventType} from "types/calendar"

const VIEWS = {
    DAY: "timeGridDay",
    MONTH: "dayGridMonth",
    WEEK: "timeGridWeek",
    YEAR: "YEAR"
}

export function EventContent(props) {
    const [isShowEventPreview, setIsShowEventPreview] = useState(false)
    const model = useModel()
    const {updateViewTo, eventInfo, clickInfo, handleShowEventPreview, isShowGoing, eventOptions, isWorkScheduleView} =
        props
    const backgroundColor =
        updateViewTo === VIEWS.MONTH ? "#fff" : get(eventInfo, "event._def.ui.backgroundColor", "#1e90ff")

    const closeEventPreview = () => {
        setIsShowEventPreview(false)
        setTimeout(() => {
            handleShowEventPreview(false)
            // To trigger handleDateClick event happen before close
        }, 300)
        clickInfo?.el?.parentElement?.classList?.remove(styles.highlightEventPreviewWrap)
        clickInfo?.el?.classList?.remove(styles.highlightEventPreview)
    }

    const onEventPreviewChange = (visible) => {
        if (!visible) {
            clickInfo?.el?.parentElement?.classList?.remove(styles.highlightEventPreviewWrap)
            clickInfo?.el?.classList?.remove(styles.highlightEventPreview)
        } else {
            clickInfo?.el?.parentElement?.classList?.add(styles.highlightEventPreviewWrap)
            clickInfo?.el?.classList?.add(styles.highlightEventPreview)
        }
        setIsShowEventPreview(visible)
        setTimeout(() => {
            handleShowEventPreview(visible)
            // To trigger handleDateClick event happen before close
        }, 300)
    }

    const renderEventPreviewContent = (eventInfo) => {
        return (
            <EventPreviewContent
                eventInfo={eventInfo}
                isShowGoing={isShowGoing}
                eventOptions={eventOptions}
                closeEventPreview={closeEventPreview}
                isWorkScheduleView={isWorkScheduleView}
            />
        )
    }

    const renderMonthView = () => {
        return (
            <div className={styles.eventMonthView}>
                <div className={styles.dot} style={{background: eventInfo.event.backgroundColor}}></div>
                <div className={styles.eventTitleMonthView} style={{color: getColorContrast(backgroundColor).color}}>
                    <span>{eventInfo.event.title}</span>
                </div>
            </div>
        )
    }

    const renderOtherView = () => {
        const format = model.getUserTimeFormat()
        const {schedule_suffix, moduleTitle, subModuleTitle, type} = eventInfo.event.extendedProps
        const courseScheduleInfo = [schedule_suffix, moduleTitle, subModuleTitle].filter((item) => item).join(", ")
        const time = moment(eventInfo.event.end).diff(eventInfo.event.start, "hour")
        const color = getColorContrast(backgroundColor).color
        const timePreview =
            type === CalendarEventType.EndTermDate
                ? moment(eventInfo.event.start).endOf("date")
                : moment(eventInfo.event.start)

        if (time >= 1) {
            return (
                <div className={styles.eventContent}>
                    <p className={styles.eventTitle} style={{color}}>
                        {eventInfo.event.title || ""}
                    </p>
                    <p className={styles.eventTime} style={{color}}>
                        {courseScheduleInfo}
                    </p>
                    <p className={styles.eventTime} style={{color}}>
                        {timePreview.format(format)}
                    </p>
                </div>
            )
        }
        return (
            <div className={styles.eventContent}>
                <p className={styles.eventTitle} style={{color}}>
                    {eventInfo.event.title || ""}
                    {courseScheduleInfo && <span className={styles.eventTime}>, {courseScheduleInfo}</span>}
                    <span className={styles.eventTime}>, {timePreview.format(format)}</span>
                </p>
            </div>
        )
    }

    return (
        <Popover
            overlayClassName="event-content-preview"
            visible={isShowEventPreview}
            content={() => renderEventPreviewContent(eventInfo.event)}
            trigger="click"
            onVisibleChange={onEventPreviewChange}
            placement="left">
            {updateViewTo === VIEWS.MONTH ? renderMonthView() : renderOtherView()}
        </Popover>
    )
}
