/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useContext} from "react"
import {Tabs} from "antd"
import {useTranslation} from "react-i18next"
import {Screen} from "components/ui"
import {useActiveTab} from "hooks"
import {Students, Workspace} from "sections/StudentServices/StudentServicesStaff/parts"
import {Attendance} from "sections/Attendance"
import {TabHeader} from "components/Tab"
import {BaseDepartmentId} from "types/departments"
import {Modules, useEdularModulesContext} from "@edular/modules"
import {StudentServicesStaffTab} from "sections/StudentServices/StudentServicesStaff/StudentServicesStaff"
import {ActivitiesStaffTab} from "sections/academics/registrar/Activities"
import styles from "./RegistrarStaff.module.css"
import {ActiveStudentContext, ActiveStudentProvider} from "context/ActiveStudentContext"
import cx from "classnames"
import {AdvisorTitle, StudentInfoSidebar} from "uiKit"
import {StudentServicesPanelInfo} from "sections/StudentServices/StudentServicesPanelInfo"
import TermAndCourses from "../TermAndCourses"
import {SapAlert} from "sections/academics/sap/SapAlert"

const {TabPane} = Tabs

export enum RegistrarStaffTab {
    Workspace = "workspace",
    Activities = "activities",
    Students = "students",
    Attendance = "attendance",
    SapAlerts = "sap",
    TermAndCourses = "termAndCourses"
}

const RegistrarStaffView = (props) => {
    const {t} = useTranslation(["academics"])
    const {isModuleEnable} = useEdularModulesContext()
    const [activeTabKey, onChangeTab] = useActiveTab(RegistrarStaffTab.Workspace)
    const departmentId = BaseDepartmentId.Academics
    const [statsKey, setStatsKey] = React.useState(Math.random())

    const {
        student,
        setStudent,
        setStatusChange,
        setStudentStatusChange,
        setFinancialAidStatusChange,
        studentDetailVisible,
        setEnrollmentProfileIdChange
    } = useContext(ActiveStudentContext)

    React.useEffect(() => {
        setStudent(undefined)
        studentDetailVisible.close()
    }, [activeTabKey])

    const goToStudentTab = useCallback(() => {
        onChangeTab(StudentServicesStaffTab.Students)
    }, [])

    return (
        <div className={styles.screenWrap}>
            <Screen
                htmlTitle={t("registrar.title")}
                header={{
                    title: t("registrar.title")
                }}
                isReducingPaddingRight={studentDetailVisible.isVisible}>
                <Tabs
                    className="fullwidth klassTab"
                    activeKey={activeTabKey}
                    onChange={onChangeTab}
                    destroyInactiveTabPane={true}>
                    <TabPane
                        tab={<TabHeader icon="PIE_CHART" title={t("registrar.workspace")} />}
                        key={RegistrarStaffTab.Workspace}>
                        <Workspace departmentId={departmentId} />
                    </TabPane>
                    {isModuleEnable(Modules.ActivitiesAndFollowups) && (
                        <TabPane
                            tab={<TabHeader icon="DOOR_OPEN" title={t("activities")} />}
                            key={StudentServicesStaffTab.Activities}>
                            <ActivitiesStaffTab departmentId={departmentId} />
                        </TabPane>
                    )}
                    <TabPane
                        tab={<TabHeader icon="PERSON" title={t("registrar.attendance")} />}
                        key={RegistrarStaffTab.Attendance}>
                        <Attendance isShowAllMissedAttendance departmentId={BaseDepartmentId.Academics} />
                    </TabPane>
                    <TabPane
                        tab={<TabHeader icon="PEOPLE" title={t("registrar.students")} />}
                        key={RegistrarStaffTab.Students}>
                        <Students departmentId={departmentId} statsKey={statsKey} />
                    </TabPane>
                    <TabPane tab={<TabHeader icon="FILE_TEXT" title="SAP Alerts" />} key={RegistrarStaffTab.SapAlerts}>
                        {activeTabKey === RegistrarStaffTab.SapAlerts ? (
                            <SapAlert departmentId={BaseDepartmentId.Academics} />
                        ) : null}
                    </TabPane>
                    <TabPane
                        tab={<TabHeader icon="COURSE" title={t("registrar.termCourse")} />}
                        key={RegistrarStaffTab.TermAndCourses}>
                        {activeTabKey === RegistrarStaffTab.TermAndCourses ? <TermAndCourses /> : null}
                    </TabPane>
                </Tabs>
            </Screen>
            <div className={cx(styles.expandedSidebar, {[styles.hidden]: !studentDetailVisible.isVisible})}>
                {!!student?.profileId && (
                    <StudentInfoSidebar
                        onClose={studentDetailVisible.close}
                        data={{
                            departmentId,
                            student,
                            isDetailButtonVisible: true,
                            detailUrl: "academics/registrar",
                            fromPage: "academics",
                            renderDepartmentInfo: (props) => (
                                <StudentServicesPanelInfo
                                    student={student}
                                    reloadStudent={() => {
                                        setStudent(undefined)
                                        studentDetailVisible.close()
                                    }}
                                    reloadStudentTable={(profileId, newProfileId) => {
                                        setEnrollmentProfileIdChange({
                                            profileId,
                                            newProfileId,
                                            departmentId
                                        })
                                        setStatsKey(Math.random())
                                    }}
                                    {...props}
                                />
                            ),
                            onChangeStatus: setStatusChange,
                            onChangeStudentStatus: setStudentStatusChange,
                            onChangeFinancialAidStatus: setFinancialAidStatusChange,
                            advisorTitle: AdvisorTitle.advisors
                        }}
                    />
                )}
            </div>
        </div>
    )
}

export default function AdmissionStaff() {
    return (
        <ActiveStudentProvider departmentId={BaseDepartmentId.Academics}>
            <RegistrarStaffView />
        </ActiveStudentProvider>
    )
}
