import {get} from "lodash"
import {Auth} from "types/auth"
import BaseService from "./Base"
import {AssignStudentStatus} from "types/students"

export enum AssignStatusToStudentsAction {
    AcceptApplication = "accept-application",
    CancelApplication = "cancel-application",
    Enroll = "enroll",
    CancelEnrollment = "cancel-enrollment",
    MarkAsAttending = "mark-as-attending",
    MarkAsNotAttending = "mark-as-not-attending"
}

export type AssignStudentStatusesInBulkPayload = {
    studentProfileIds: number[]
    studentStatusIdToAssign: number
    action: AssignStatusToStudentsAction
}

export type StudentStatusAssignmentResult = {
    success: false
    studentProfileId: number
    message: string
}

export default class StudentService extends BaseService {
    public async getAll(params = {}) {
        const {data: response} = await this.post("/v1/student/list", params)
        return response
    }

    async getDepartmentStudents(params = {}): Promise<{
        total: number
        data: Auth.DepartmentStudent[]
        profilesCountByStatus?: Record<number, number>
    }> {
        const response = await this.post("/v1/departments-students/list", params)
        return get(response, "data", null)
    }

    async getStudentAdvisors(params = {}) {
        const response = await this.post("/v1/departments-students/list-student-advisors", params)
        return get(response, "data", null)
    }

    async getAllStudentAdvisors(params = {}) {
        const response = await this.post("/v1/departments-students/list-all-advisors", params)
        return get(response, "data", null)
    }

    async getAdvisors(params = {}) {
        const response = await this.post("/v1/departments-students/list-advisors", params)
        return get(response, "data", null)
    }

    async getCurrentStudentStatus(profileId, params = {}, filter = {}) {
        const response = await this.post(`/v1/students/department-statuses/list`, {
            filter: {profileId, ...filter},
            linkedEntities: true,
            ...params
        })
        return get(response, "data.data[0].status", null)
    }

    async getStudentStatuses(profileId, params = {}) {
        const response = await this.post(`/v1/students/department-statuses/list`, {
            filter: {profileId},
            linkedEntities: true,
            ...params
        })
        return get(response, "data", null)
    }

    async updateStudentStatus(profileId, data) {
        data.profileId = profileId
        const response = await this.post(`/v1/students/department-statuses/edit`, data)
        return get(response, "data.data", null)
    }

    async getAllChecklist(data) {
        const response = await this.post(`/v1/students/checklist/list`, data)
        return get(response, "data", null)
    }

    async autocompleteChecklist(filter: {search: string; studentProfileId: number | number[]}) {
        const response = await this.post("/v1/students/checklist/autocomplete", {
            filter
        })
        return get(response, "data", [])
    }

    async updateStudentApprovalStatus(data) {
        const response = await this.post(`/v1/students/checklist/edit-approval-status`, data)
        return get(response, "data", null)
    }

    async updateStudentAuditStatus(data) {
        const response = await this.post(`/v1/students/checklist/edit-audit-status`, data)
        return get(response, "data", null)
    }

    async updateStudentChecklistDocumentUpload(data) {
        const response = await this.post(`/v1/students/checklist/edit-not-required`, data)
        return get(response, "data", null)
    }

    async getStudentChecklistProgress(data) {
        const response = await this.post(`/v1/students/checklist/progress`, data)
        return get(response, "data.data", null)
    }

    async getStudentChecklistListOverallSummary(data) {
        const response = await this.post(`/v1/students/checklist/list-overall-summary`, data)
        return get(response, "data.data", null)
    }

    async updateStudentChecklistDocument(data) {
        const response = await this.post(`/v1/students/checklist/edit-document`, data)
        return get(response, "data", null)
    }

    async createStudentChecklistCustom(data) {
        const response = await this.post(`/v1/students/checklist/create`, data)
        return get(response, "data.data", null)
    }

    async deleteStudentChecklistCustom(data) {
        const response = await this.post(`/v1/students/checklist/delete`, data)
        return get(response, "data", null)
    }

    async updateStudentChecklistPosition(id, data, params = {}) {
        data.studentChecklistItemId = id
        const response = await this.post(`/v1/students/checklist/update-item-position`, data)
        return get(response, "data", null)
    }

    async updateStudentChecklistCustom(data) {
        const response = await this.post(`/v1/students/checklist/refresh-manually`, data)
        return get(response, "data", null)
    }

    async exportAsCsv(data) {
        const response = await this.post(`/v1/students/checklist/export-as-csv`, data, {
            responseType: "blob"
        })
        return get(response, "data", null)
    }

    async updateStudentChecklistDueDate(data) {
        const response = await this.post("/v1/students/checklist/update-item-due-date", data)
        return get(response, "data", null)
    }

    async assignStudentChecklistManually(data) {
        const response = await this.post("/v1/students/checklist/assign-checklist-manually", data)
        return get(response, "data", null)
    }

    async archiveChecklistItemsForStudent(data) {
        const response = await this.post("/v1/students/checklist/archive/edit", data)
        return get(response, "data", null)
    }

    getStudentPracticalCourses = async (body) => {
        const response = await this.post("v1/students/practical-courses/list", body)
        return get(response, "data", {})
    }

    createStudentPracticalCourses = async (data) => {
        const response = await this.post("v1/students/practical-courses/create", data)
        return get(response, "data", null)
    }

    updateStudentPracticalCourses = async (data) => {
        const response = await this.post("v1/students/practical-courses/update", data)
        return get(response, "data", null)
    }

    getSummaryStudentPracticalCourses = async (data) => {
        const response = await this.post("v1/students/practical-courses/practical-summary", data)
        return get(response, "data", null)
    }

    async updateNewStudentStatus(data: AssignStudentStatus) {
        const response = await this.post(`/v1/students/statuses/assign`, data)
        return get(response, "data", null)
    }

    async getLeaderboard(params = {}) {
        const response = await this.post("/v1/student/leaderboard", params)
        return get(response, "data.data", null)
    }

    async assignStudentStatusesInBulk(
        data: AssignStudentStatusesInBulkPayload
    ): Promise<{data: StudentStatusAssignmentResult[]}> {
        const response = await this.post(`/v1/students/statuses/assign/bulk`, data)
        return get(response, "data", null)
    }

    async getOverallStats(params = {}) {
        const response = await this.post("/v1/student/overall-stats", params)
        return get(response, "data.data", {})
    }
}
