import React from "react"
import {Modal, ModalProps} from "antd"
import classNames from "classnames"
import {Icon, IconType} from "components/Icon"
import styles from "./popup.module.css"

export type BasePopupProps = ModalProps & {
    isShow: boolean
    onClose?: any
    className?: string
    dialogContentClassName?: string
    width?: string
    leftIcon?: IconType
    leftIconColor?: string
    isShowLeftSide?: boolean
    children?: JSX.Element | any
    closeIcon?: JSX.Element
}

export function BasePopup(props: BasePopupProps) {
    const {
        isShow,
        onClose,
        children,
        className,
        dialogContentClassName,
        leftIcon,
        leftIconColor,
        isShowLeftSide = true,
        ...rest
    } = props

    return (
        <Modal
            visible={isShow}
            onCancel={onClose}
            width="90vw"
            closeIcon={<Icon icon="CLOSE" color="#000" className={styles.closeIcon} />}
            maskClosable={false}
            footer={null}
            centered={true}
            className={classNames("base-popup", className || "")}
            {...rest}>
            <div className={styles.dialogWrap}>
                {isShowLeftSide && (
                    <div className={styles.leftSide}>
                        <Icon
                            icon={leftIcon ? leftIcon : "UNORDER_LIST"}
                            className={styles.leftSideIcon}
                            color={leftIconColor || "#fff"}
                        />
                    </div>
                )}
                <div
                    className={classNames(
                        styles.dialogContent,
                        {
                            [styles.dialogContentWithoutLeftSide]: !isShowLeftSide
                        },
                        dialogContentClassName
                    )}>
                    {children}
                </div>
            </div>
        </Modal>
    )
}

BasePopup.defaultProps = {
    isShowLeftSide: true
}
