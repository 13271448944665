import {useState} from "react"
import cx from "classnames"
import styles from "./ApplicationStatusItem.module.css"

type StatusItemProps = {
    value: string
    text: string
    isStudent: boolean
    isCurrentStatus: boolean
    onClick: (key: string) => void
}

export const ApplicationStatusItem = (props: StatusItemProps) => {
    const {value, text, isStudent, isCurrentStatus, onClick} = props
    const [isHovering, setIsHovering] = useState(false)

    const handleMouseOver = () => {
        if (isStudent) return
        setIsHovering(true)
    }

    const handleMouseOut = () => {
        setIsHovering(false)
    }

    return (
        <div
            className={cx(styles.status, {
                [styles[`status-${value}`]]: isCurrentStatus || isHovering,
                [styles.cursor]: !isStudent
            })}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={() => onClick(value)}>
            {text}
        </div>
    )
}
