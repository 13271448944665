/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import styles from "./PanelExpandedSection.module.css"
import {ReactComponent as CloseIcon} from "./close.svg"

type Props = {
    title: string
    children: React.ReactNode
    onClose?: () => void
}

export function PanelExpandedSection(props: Props) {
    const {title, children, onClose} = props

    return (
        <div className={styles.windowsWrap}>
            <div className={styles.titleWrap}>
                <div className={styles.windowsTitle}>{title}</div>
                <div className={styles.windowsCloseWrap} onClick={() => onClose()}>
                    <CloseIcon className={styles.closeIcon} width={24} height={24} />
                </div>
            </div>
            <div className={styles.contentWrap}>{children}</div>
        </div>
    )
}
