import {message as antMessage} from "antd"
import {extractErrorMessageFromApiError} from "helpers/api"
import {
    MutationFunction,
    useMutation as useMutationRq,
    UseMutationOptions,
    UseMutationResult
} from "@tanstack/react-query"
import {ApiErrorDetails} from "types/api"

export function useMutation<TData = unknown, TVariables = void, TContext = unknown>(
    mutationFn: MutationFunction<TData, TVariables>,
    options?: UseMutationOptions<TData, ApiErrorDetails | Error, TVariables, TContext>
): UseMutationResult<TData, ApiErrorDetails | Error, TVariables, TContext> {
    return useMutationRq(mutationFn, {
        onError: (error) => {
            const errorMessage = extractErrorMessageFromApiError(error)
            antMessage.error(errorMessage)
        },
        ...options
    })
}
