import React from "react"
import {useTranslation} from "react-i18next"
import classNames from "classnames"
import {head} from "lodash"
import {TaskStatus} from "types/tasks"
import styles from "./CardItem.module.css"

export function CardItem(props) {
    const {item, activeStatus, onChangeFilter} = props
    const {t} = useTranslation(["tasks", "common"])
    const isActive = activeStatus && activeStatus.id === item.id
    const {status} = item

    return (
        <div className={styles.wrap} onClick={() => onChangeFilter(status)}>
            <div className={styles.headerWrap}>
                {status !== "all" && (
                    <div
                        className={classNames(styles.status, {
                            [styles.status__todo]: status === TaskStatus.TODO,
                            [styles.status__started]: status === TaskStatus.STARTED,
                            [styles.status__done]: status === TaskStatus.DONE,
                            [styles.status__awaiting]: status === TaskStatus.AWAITING_APPROVAL,
                            [styles.status__rejected]: status === TaskStatus.REJECTED,
                            [styles.status__overdue]: status === TaskStatus.OVERDUE,
                            [styles.status__draft]: status === TaskStatus.DRAFT
                        })}></div>
                )}
                <div className={styles.titleWrap}>
                    <p className={styles.title}>{t(`tasks.${item.title}`)}</p>
                    <p className={styles.title}>{isActive ? `(${t("common:columnPicker.active")})` : ""}</p>
                </div>
            </div>
            <p className={styles.count}>{item.count || 0}</p>
        </div>
    )
}
