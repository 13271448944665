import BaseService from "./Base"
import {get} from "lodash"

class AddressService extends BaseService {
    constructor() {
        super(process.env.REACT_APP_ADDRESS_API)
    }
    async getCountries(params = {}) {
        const response = await this.post("/v3/country/list", params)
        return get(response, "data", [])
    }
    async getStates(params = {}) {
        const response = await this.post("/v3/state/list", params)
        return get(response, "data", [])
    }
    async getPostalCode(params = {}) {
        const response = await this.post("/v3/postal-code/list", params)
        return get(response, "data", [])
    }
    async getCities(params = {}) {
        const response = await this.post("/v3/city/list", params)
        return get(response, "data", [])
    }
}

export default AddressService
