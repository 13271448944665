import React from "react"
import {useTranslation} from "react-i18next"
import {DisplayMode} from "types/common"
import ActivitiesTable from "../ActivitiesTable"
import styles from "./ActivityPanel.module.css"

type Props = {
    studentId?: number
    departmentId: number
    title?: string
    wrapContent?: boolean
    showTitle?: boolean
    displayMode?: DisplayMode
}

export function ActivityPanel({
    studentId,
    departmentId,
    title = null,
    wrapContent = true,
    showTitle = true,
    displayMode = "normal"
}: Props) {
    const {t} = useTranslation(["common"])

    return (
        <div className={wrapContent ? styles.wrap : ""}>
            <div className={wrapContent ? styles.tableWrap : ""}>
                {showTitle && (
                    <div className={styles.headerActivities}>
                        <p className={styles.activity__title}>{title || t("applicationProgress.activities")}</p>
                    </div>
                )}
                <ActivitiesTable studentId={studentId} departmentId={departmentId} displayMode={displayMode} />
            </div>
        </div>
    )
}
