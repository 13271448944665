/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react"
import {useTranslation} from "react-i18next"
import {Spin, Tabs} from "antd"
import {Auth} from "types/auth"
import {academicPlansService, studentService} from "services"
import {BaseDepartmentId} from "types/departments"
import {toastError} from "helpers"
import {useActiveTab} from "hooks"
import styles from "./FinAidAward.module.css"
import AwardSummaries from "./AwardSummary/AwardSummaries"
import Disbursements from "./Disbursement/Disbursements"
import {AwardSummary} from "types/fin-aid/fin-aid"
import {TabHeader} from "components/Tab"

const {TabPane} = Tabs

interface FinAidAwardProps {
    studentId: number
    departmentId: number
}

export enum AwardTab {
    AwardSummary = "summary",
    Disbursements = "disbursements"
}

function FinAidAward({studentId, departmentId}: FinAidAwardProps) {
    const {t} = useTranslation(["financialAid", "common"])
    const [isLoading, setLoading] = React.useState(true)
    const [student, setStudent] = React.useState<Auth.DepartmentStudent | undefined>()
    const [academicStats, setAcademicStats] = React.useState<Auth.StudentStatistics | undefined>()
    const [activeTabKey, onChangeTab] = useActiveTab(AwardTab.AwardSummary, "awardtab")
    const [selectedAwardSummary, setSelectedAwardSummary] = React.useState<AwardSummary | undefined>()

    useEffect(() => {
        ;(async function loadStudent() {
            try {
                const {
                    data: [student]
                } = await studentService.getDepartmentStudents({
                    filter: {profileIds: [studentId], departmentId: BaseDepartmentId.FinancialAid}
                })
                setStudent(student)

                academicPlansService.getStudentStatistics({profileId: studentId}).then(setAcademicStats)

                setLoading(false)
            } catch (error) {
                console.error(error)
                toastError("Student not found")
                setStudent(undefined)
            }
        })()
    }, [studentId])

    React.useEffect(() => {
        if (selectedAwardSummary) {
            onChangeTab(AwardTab.Disbursements)
        }
    }, [selectedAwardSummary])

    React.useEffect(() => {
        if (activeTabKey !== AwardTab.Disbursements) {
            setSelectedAwardSummary(undefined)
        }
    }, [activeTabKey])

    if (isLoading) {
        return <Spin />
    }

    return (
        <div className={styles.container}>
            <Tabs className="fullwidth mt-16" activeKey={activeTabKey} onChange={onChangeTab}>
                <TabPane
                    tab={<TabHeader title={t(`finAidAward.tab.${AwardTab.AwardSummary}`)} />}
                    key={AwardTab.AwardSummary}>
                    <AwardSummaries
                        studentId={studentId}
                        student={student}
                        onAwardSummarySelect={setSelectedAwardSummary}
                    />
                </TabPane>

                <TabPane
                    tab={<TabHeader title={t(`finAidAward.tab.${AwardTab.Disbursements}`)} />}
                    key={AwardTab.Disbursements}>
                    <Disbursements
                        studentId={studentId}
                        awardSummary={selectedAwardSummary}
                        academicStats={academicStats}
                    />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default FinAidAward
