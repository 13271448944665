import React, {useReducer} from "react"
import {Row, Col} from "antd"
import {BasePopup} from "components/popup"
import {FormLabel} from "components/Form"
import {BaseInput} from "components/inputs"
import {BaseButton} from "components/buttons"
import {Course} from "types/courses"
import {handleError, toastError} from "helpers"
import {academicPlansService} from "services"
import {CoursesTable, SelectedCoursesTable} from "../CourseTable"
import styles from "./AddSectionPopup.module.css"
import {KlassDropdown} from "components/Select"
import {Major} from "types/major"
import {preventMinusCharacter} from "helpers/inputHelper"

type Props = {
    isShow: boolean
    studentId: number
    majorVersionType: string
    onClose: () => void
    onUpdateSections: () => void
}

export function AddSectionPopup(props: Props) {
    function reducer(state, action) {
        return {...state, ...action}
    }
    const initialState = {
        section: {
            sectionTitle: "",
            minimum: "",
            courseUnit: null
        },
        isSubmitting: false,
        selectedCourses: [],
        forceKey: 1
    }
    const [{section, isSubmitting, selectedCourses, forceKey}, dispatch] = useReducer(reducer, initialState)
    const {isShow, studentId, majorVersionType, onClose, onUpdateSections} = props
    const courseUnits = [
        {id: Course.Unit.Hour, name: majorVersionType === Major.Unit.Clock ? "Clock hour" : "Credit hour"},
        {id: Course.Unit.Each, name: "Each"}
    ]

    const onChangeData = (key, value) => {
        section[key] = value
        if (key === "courseUnit") {
            dispatch({section, forceKey: forceKey + 1})
        } else {
            dispatch({section})
        }
    }

    const onAddCourses = (addedCourses: Course.CourseTableItem[]) => {
        const newCourses = addedCourses.map((course) => ({
            id: course.courseId,
            courseCode: course.code,
            courseName: course.name
        }))
        dispatch({selectedCourses: [...selectedCourses, ...newCourses], forceKey: forceKey + 1})
    }

    const saveSection = async () => {
        if (validateBeforeSubmit(section)) {
            return
        }
        try {
            await createSection(section)
            onUpdateSections()
        } catch (error) {
            handleError(error)
        }
    }

    const validateBeforeSubmit = (data) => {
        if (data && !data.sectionTitle) {
            toastError("Please enter section title value")
            return true
        }
        if (data && !data.minimum) {
            toastError("Please enter minimum value")
            return true
        }
        return false
    }

    const createSection = async (data) => {
        try {
            dispatch({isSubmitting: true})
            data.studentProfileId = studentId
            data.courseIds = selectedCourses.map((course) => course.id)
            data.minimum = parseInt(data.minimum, 10)
            data.courseUnit = data.courseUnit?.id
            await academicPlansService.addSection(data)
            onClose()
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isSubmitting: false})
        }
    }

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="PLUS_CIRCLE" width="80vw">
            <div className={styles.wrap}>
                <p className={styles.title}>Add Section</p>
                <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <div>
                            <FormLabel label="Section title" />
                            <BaseInput
                                placeholder="Title"
                                value={section.sectionTitle}
                                onChange={(newValue) => onChangeData("sectionTitle", newValue)}
                            />
                        </div>
                    </Col>
                    <Col span={6}>
                        <div>
                            <FormLabel label="Minimum" />
                            <BaseInput
                                placeholder="Number"
                                type="number"
                                min={0}
                                onKeyDown={preventMinusCharacter}
                                value={section.minimum}
                                onChange={(newValue) => onChangeData("minimum", newValue)}
                            />
                        </div>
                    </Col>
                    <Col span={6}>
                        <div>
                            <FormLabel label="Unit" />
                            <KlassDropdown
                                options={courseUnits}
                                placeholder="Unit"
                                value={section.courseUnit}
                                onChange={(newValue) => onChangeData("courseUnit", newValue)}
                                isClearable
                            />
                        </div>
                    </Col>
                </Row>
                <div className={styles.tableWrap}>
                    <SelectedCoursesTable key={`selected_${forceKey}`} selectedCourses={selectedCourses} />
                    <CoursesTable key={forceKey} unit={section.courseUnit?.id} onAddCourses={onAddCourses} />
                </div>
                <div className={styles.action}>
                    <BaseButton title="Save" onClick={saveSection} loading={isSubmitting} />
                </div>
            </div>
        </BasePopup>
    )
}
