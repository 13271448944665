import React, {useContext, useEffect, useState} from "react"
import {Row, Col} from "antd"
import moment from "moment"
import {FormLabel} from "components/Form"
import {BaseButton} from "components/buttons"
import {BaseInput} from "components/inputs"
import {BaseDatePicker} from "components/DateTimePicker"
import styles from "./form.module.css"
import {Auth} from "types/auth"
import {DisplayMode} from "types/common"
import cx from "classnames"
import {ActiveStudentContext} from "context/ActiveStudentContext"
import TermSelect from "components/TermSelect"
import {get, isEmpty, pick} from "lodash"
import {academicPlansService, majorService, userService} from "services"
import {getFieldLabel, getTotalHourStatistics, handleError} from "helpers"
import {useModel} from "hooks"
import {useTranslation} from "react-i18next"
import produce from "immer"
import {Settings} from "types/settings"

type Props = {
    onClickCancel: () => void
    onSaveSuccess: () => void
    displayMode?: DisplayMode
}

export function AcademicsForm(props: Props) {
    const {t} = useTranslation(["common", "chat", "user"])
    const model = useModel()
    const {displayMode = "normal", onClickCancel, onSaveSuccess} = props
    const isFullDisplay = displayMode === "normal"
    const {student, setStudent} = useContext(ActiveStudentContext)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [programVersionTotalClockHours, setProgramVersionTotalClockHours] = useState(0)
    const [studentStats, setStudentStats] = useState<Auth.StudentStatistics>({
        profileId: null,
        cgpa: 0,
        averageAttendancePercentage: 0,
        missedAttendanceHours: 0,
        maxTimeframe: 0,
        createdAt: "",
        updatedAt: "",
        deletedAt: null,
        makeUpHours: 0,
        totalHours: 0,
        totalHoursAttempted: 0,
        totalHoursCompleted: 0,
        totalHoursRemaining: 0,
        totalDistEdHours: 0,
        totalTransferHours: 0,
        terms: []
    })

    useEffect(() => {
        if (!student?.profileId) return
        ;(async function getStudentStatistics() {
            try {
                const currentMajorId = student.currentMajor?.id
                const currentMajorVersionId = student.currentMajorVersionId
                const [data, major] = await Promise.all([
                    academicPlansService.getStudentStatistics({profileId: student.profileId}),
                    currentMajorId && majorService.getMajor(currentMajorId)
                ])
                if (!isEmpty(major) && !isEmpty(major.versions)) {
                    const currentVersion = major.versions.find((version) => version.id === currentMajorVersionId)
                    const totalClockHours = get(currentVersion, "totalClockHours", 0)
                    setProgramVersionTotalClockHours(totalClockHours)
                }
                setStudentStats(data)
            } catch (error) {
                console.error(error)
            }
        })()
    }, [student?.profileId, student?.currentMajor, student?.currentMajorVersionId])

    useEffect(() => {
        if (!student?.startingTerm) return
        ;(async function updateStartingTermDates() {
            const {data: programsOfTerm} = await majorService.getMajorByTerm(student.startingTerm.id)
            setStudent((prev) =>
                produce(prev, (draft) => {
                    const isCurrentMajorExist =
                        !!draft.currentMajor?.id && programsOfTerm.some((major) => major.id === draft.currentMajor.id)
                    const programByTerm = programsOfTerm.find((major) => major.id === draft.currentMajor?.id)
                    if (!!programByTerm?.startDate) {
                        draft.startDate = moment.utc(programByTerm.startDate).toISOString()
                    }
                    if (!!programByTerm?.projectedEndDate) {
                        draft.contractEndDate = moment.utc(programByTerm.projectedEndDate).toISOString()
                        draft.projectedEndDate = moment.utc(programByTerm.projectedEndDate).toISOString()
                    }
                    if (!isCurrentMajorExist) {
                        draft.currentMajor = null
                        draft.currentMajorVersionId = null
                    }
                })
            )
        })()
    }, [setStudent, student?.startingTerm])

    const onChangeData = React.useCallback(
        (key: string, value: any) => {
            setStudent((prev) =>
                produce(prev, (draft) => {
                    if (key === "currentMajor") {
                        draft.startingTermId = null
                        draft.startingTerm = null
                        draft.currentMajorVersionId = null
                        draft.degreeLevel = get(value, "degreeLevel.name", "")
                    }
                    draft[key] = value
                })
            )
        },
        [setStudent]
    )

    const clickSave = async () => {
        const dataSubmit: any = pick(student, [
            "startDate",
            "contractEndDate",
            "firstDateOfClass",
            "midpointDate",
            "projectedEndDate",
            "actualEndDate"
        ])
        dataSubmit.type = Auth.UserProfileType.Student
        dataSubmit.startingTermId = student.startingTerm ? get(student.startingTerm, "id") : null
        dataSubmit.currentTerm = student.currentTerm ? get(student.currentTerm, "id") : null
        try {
            setIsSubmitting(true)
            await userService.updateProfileUserById(student.profileId, dataSubmit)
            onSaveSuccess()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitting(false)
        }
    }

    const totalHours = getTotalHourStatistics(model, studentStats, programVersionTotalClockHours)
    const [totalTransferHours, completedHour, attemptedHour, remainingHour, distEdHour, _, totalMajorHours] = totalHours

    return (
        <div className={cx({[styles.wrap]: isFullDisplay})}>
            {isFullDisplay && <p className={styles.title}>Student academics Details</p>}

            <div className={styles.body}>
                <p className={styles.subTitle}>Total Progress</p>

                <Row gutter={[24, 24]}>
                    {totalTransferHours.isShow && (
                        <Col span={isFullDisplay ? 8 : 24}>
                            <div>
                                <FormLabel label={totalTransferHours.title} />
                                <BaseInput readOnly value={totalTransferHours.hour} />
                            </div>
                        </Col>
                    )}
                    {attemptedHour.isShow && (
                        <Col span={isFullDisplay ? 8 : 24}>
                            <div>
                                <FormLabel label={attemptedHour.title} />
                                <BaseInput readOnly value={attemptedHour.hour} />
                            </div>
                        </Col>
                    )}
                    {completedHour.isShow && (
                        <Col span={isFullDisplay ? 8 : 24}>
                            <div>
                                <FormLabel label={completedHour.title} />
                                <BaseInput readOnly value={completedHour.hour} />
                            </div>
                        </Col>
                    )}
                    {remainingHour.isShow && (
                        <Col span={isFullDisplay ? 8 : 24}>
                            <div>
                                <FormLabel label={remainingHour.title} />
                                <BaseInput readOnly value={remainingHour.hour} />
                            </div>
                        </Col>
                    )}
                    {distEdHour.isShow && (
                        <Col span={isFullDisplay ? 8 : 24}>
                            <div>
                                <FormLabel label={distEdHour.title} />
                                <BaseInput readOnly value={distEdHour.hour} />
                            </div>
                        </Col>
                    )}
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label="Total hours" />
                            <BaseInput readOnly value={programVersionTotalClockHours} />
                        </div>
                    </Col>
                    {totalMajorHours.isShow && (
                        <Col span={isFullDisplay ? 8 : 24}>
                            <div>
                                <FormLabel label={totalMajorHours.title} />
                                <BaseInput readOnly value={totalMajorHours.hour} />
                            </div>
                        </Col>
                    )}
                </Row>

                <p className={styles.subTitle}>General</p>

                <Row gutter={[24, 24]}>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label="Attendance" />
                            <BaseInput value={""} readOnly />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label="GPA" />
                            <BaseInput value={""} readOnly />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label="CGPA" />
                            <BaseInput value={""} readOnly />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label="Missed Attendance" />
                            <BaseInput value={""} readOnly />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label="Max timeframe" />
                            <BaseInput value={""} readOnly />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label="MPN" />
                            <BaseInput value={""} readOnly />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label="Entrance Counceling" />
                            <BaseInput value={""} readOnly />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label="Make up attendance" />
                            <BaseInput value={""} readOnly />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label="Hours to make up" />
                            <BaseInput value={""} readOnly />
                        </div>
                    </Col>
                </Row>

                <div className={styles.divider} />

                <Row gutter={[24, 24]}>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label={t("user:user.currentTerm")} />
                            <TermSelect
                                value={student.currentTerm}
                                onChange={(newValue) => onChangeData("currentTerm", newValue)}
                            />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label={t("user:user.startTerm")} />
                            <TermSelect
                                isClearable
                                value={student.startingTerm}
                                onChange={(newValue) => onChangeData("startingTerm", newValue)}
                            />
                        </div>
                    </Col>

                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel
                                label={getFieldLabel(model, Settings.GeneralLabel.StartDate, t("user:user.startDate"))}
                            />
                            <BaseDatePicker value={student.startDate ? moment.utc(student.startDate) : null} readOnly />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel
                                label={getFieldLabel(
                                    model,
                                    Settings.GeneralLabel.ContractEndDate,
                                    t("user:user.contractEndDate")
                                )}
                            />
                            <BaseDatePicker
                                value={student.contractEndDate ? moment.utc(student.contractEndDate) : null}
                                readOnly
                            />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel
                                label={getFieldLabel(
                                    model,
                                    Settings.GeneralLabel.FirstDateOfClass,
                                    t("user:user.firstDateOfClass")
                                )}
                            />
                            <BaseDatePicker
                                value={student.firstDateOfClass ? moment.utc(student.firstDateOfClass) : null}
                                onChange={(date) => onChangeData("firstDateOfClass", date.toISOString())}
                            />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel
                                label={getFieldLabel(
                                    model,
                                    Settings.GeneralLabel.MidpointDate,
                                    t("user:user.midpointDate")
                                )}
                            />
                            <BaseDatePicker
                                value={student.midpointDate ? moment.utc(student.midpointDate) : null}
                                onChange={(date) => onChangeData("midpointDate", date.toISOString())}
                            />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel
                                label={getFieldLabel(
                                    model,
                                    Settings.GeneralLabel.ProjectedEndDate,
                                    t("user:user.projectedEndDate")
                                )}
                            />
                            <BaseDatePicker
                                value={student.projectedEndDate ? moment.utc(student.projectedEndDate) : null}
                                onChange={(date) => onChangeData("projectedEndDate", date.toISOString())}
                            />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel
                                label={
                                    getFieldLabel(
                                        model,
                                        Settings.GeneralLabel.ActualEndDate,
                                        t("user:user.actualEndDate")
                                    ) + " (System generated)"
                                }
                            />
                            <BaseDatePicker
                                value={student.actualEndDate ? moment.utc(student.actualEndDate) : null}
                                readOnly
                            />
                        </div>
                    </Col>

                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label="LDA" />
                            <BaseDatePicker
                                value={student.lastDayOfAttendance ? moment.utc(student.lastDayOfAttendance) : null}
                                readOnly
                            />
                        </div>
                    </Col>
                </Row>
            </div>

            <div className={styles.action}>
                <BaseButton title="Cancel" variant="secondary" onClick={onClickCancel} />
                <BaseButton title="Save" onClick={clickSave} loading={isSubmitting} />
            </div>
        </div>
    )
}
