import React, {useEffect, useState} from "react"
import {BasePopup} from "components/popup"
import {BaseButton} from "components/buttons"
import {BaseTimePicker} from "components/DateTimePicker"
import styles from "./StartBreakPopup.module.css"
import moment from "moment-timezone"
import {useModel} from "hooks"

export function StartBreakPopup(props) {
    const {isShow, onClose, onStartBreak, timezone} = props
    const model = useModel()
    const timeFormat = model.getUserTimeFormat()
    const [startBreakTime, setStartBreakTime] = useState(moment())
    useEffect(() => {
        if (isShow) {
            setStartBreakTime(moment())
        }
    }, [isShow])

    const getDisabledHours = () => {
        let hours = []
        for (let i = moment().hours() + 1; i < 24; i++) {
            hours.push(i)
        }
        return hours
    }

    const getDisabledMinutes = (selectedHour) => {
        let minutes = []
        if (selectedHour === moment().hour()) {
            for (let i = moment().minute(); i < 60; i++) {
                minutes.push(i)
            }
        }
        return minutes
    }

    return (
        <BasePopup isShow={isShow} onClose={onClose} width="70vw" leftIcon="PERSONS">
            <div className={styles.wrap}>
                <div className={styles.headerWrap}>
                    <p className={styles.title}>Start Break</p>
                    <BaseTimePicker
                        value={startBreakTime}
                        format={timeFormat}
                        onChange={setStartBreakTime}
                        placeholder="Time"
                        disabledHours={getDisabledHours}
                        disabledMinutes={getDisabledMinutes}
                    />
                </div>
                <div className={styles.action}>
                    <BaseButton
                        title="Start Break"
                        variant="primary"
                        onClick={() => {
                            onStartBreak(moment(startBreakTime).tz(timezone, true).utc())
                        }}
                    />
                </div>
            </div>
        </BasePopup>
    )
}
