import {get} from "lodash"
import BaseService from "./Base"

class SettingSiteSupervisorService extends BaseService {
    async getAllSiteSupervisors(params = {}) {
        const response = await this.post("/v1/settings/site-supervisors/list", params)
        return get(response, "data", [])
    }

    async createSiteSupervisor(params: any = {}) {
        const response = await this.post("/v1/settings/site-supervisors/create", params)
        return get(response, "data.data", {})
    }

    async duplicateSiteSupervisor(externshipSites) {
        const response = await this.post(`/v1/settings/site-supervisors/create`, externshipSites)
        return get(response, "data", {})
    }

    async updateSiteSupervisor(id, data, params = {}) {
        data.id = id
        const response = await this.post(`/v1/settings/site-supervisors/edit`, data, params)
        return get(response, "data", {})
    }

    async deleteSiteSupervisor(ids) {
        const response = await this.post(`/v1/settings/site-supervisors/delete`, {ids})
        return get(response, "data", {})
    }
}

export default SettingSiteSupervisorService
