import React, {Component} from "react"
import styles from "./DateTimePicker.module.css"
import {ReactComponent as Icon} from "./icon/calendarIcon.svg"
import classNames from "classnames"
import {CalendarWidget} from "components/CalendarWidget"
import {CloseButton} from "./CloseButton"

interface DayModifiers {
    today: boolean | undefined
    outside: boolean | undefined
    [other: string]: boolean | undefined
}

interface Props {
    placeholder?: string
    onChange?: (value: string) => void
    onClickDay: (dayPicked: Date, modifiers?: DayModifiers, e?: React.MouseEvent<HTMLDivElement>) => void
    disabled?: boolean
    value: string
    stylesNames?: string
}

interface State {
    showCalendar: boolean
}

export class DateTimePicker extends Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            showCalendar: false
        }
    }

    private onChange(event) {
        this.props.onChange(event.target.value)
    }

    private handleShowCalendar() {
        this.setState({
            showCalendar: !this.state.showCalendar
        })
    }

    public render() {
        const {placeholder, disabled, value, onClickDay, onChange} = this.props
        return (
            <div className={styles.root}>
                <input
                    className={styles.input}
                    type="text"
                    value={value}
                    onChange={this.onChange.bind(this)}
                    placeholder={placeholder}
                    disabled={disabled}
                />
                <div className={styles.iconWrapper} onClick={this.handleShowCalendar.bind(this)}>
                    <Icon className={styles.icon} width={18} height={18} />
                </div>
                {value.length > 0 && (
                    <div className={styles.clearButton}>
                        <CloseButton
                            onClick={() => {
                                onChange("")
                            }}
                        />
                    </div>
                )}
                <div className={classNames(!this.state.showCalendar && styles.hideCalendar, styles.calendarWidget)}>
                    <CalendarWidget
                        input={value ? new Date(value).toString() : new Date().toString()}
                        onClickDay={onClickDay}
                    />
                </div>
            </div>
        )
    }
}
