import React from "react"
import {Button, Menu, Dropdown} from "antd"
import {Icon} from "components/Icon"
import styles from "../KlassappTable.module.css"

type Props = {
    data: any
    menuActions: any[]
}

export default function ActionRowItem(props: Props) {
    const {menuActions, data} = props

    const menu = (
        <Menu className={styles.menuActionWrap}>
            {menuActions.map((menuAction, index) => (
                <Menu.Item key={index} className={styles.menuActionItem} onClick={() => menuAction.action(data)}>
                    <Icon className={styles.menuActionIcon} icon={menuAction.icon} />
                    <span className={styles.menuActionTitle}>{menuAction.title}</span>
                </Menu.Item>
            ))}
        </Menu>
    )

    return (
        <Dropdown overlay={menu} trigger={["click"]}>
            <Button className={styles.iconBtn} icon={<Icon icon="MORE_HORZ" />} />
        </Dropdown>
    )
}
