/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import {Tabs} from "antd"
import {useActiveTab, useModel} from "hooks"
import {AcademicPlans} from "types/academicPlans"
import {Auth} from "types/auth"
import {TermCourseSequence} from "sections/TermCourseSequence"
import {ListViewTab} from "./ListView"
import {TermViewTab} from "./TermView"
import {TabHeader} from "components/Tab"

const {TabPane} = Tabs

type Props = {
    studentId: number
    student: Auth.DepartmentStudent
    departmentId: number
}

export function TabView(props: Props) {
    const [activeTabKey, onChangeTab] = useActiveTab(AcademicPlans.TabKey.List, "view")
    const {studentId, student} = props
    const model = useModel()
    const isStaff = model.isStaffOrAdmin()

    const renderTermTab = () => {
        if (!student) {
            return null
        }
        if (student.isCalendarBased || student.isCalendarBasedMultiCourse) {
            return (
                <TabPane
                    tab={<TabHeader title="Term view with Course Sequence" icon="DUPLICATE" />}
                    key={AcademicPlans.TabKey.Term}>
                    {activeTabKey === AcademicPlans.TabKey.Term && student.profileId && (
                        <TermCourseSequence studentProfileId={student.profileId} student={student} />
                    )}
                </TabPane>
            )
        }
        return (
            <TabPane tab={<TabHeader title="Term view" icon="DUPLICATE" />} key={AcademicPlans.TabKey.Term}>
                {activeTabKey === AcademicPlans.TabKey.Term && (
                    <TermViewTab
                        studentId={studentId}
                        isStaff={isStaff}
                        student={student}
                        currentProfileId={model.profileId}
                    />
                )}
            </TabPane>
        )
    }

    return (
        <Tabs className="fullwidth" activeKey={activeTabKey} onChange={onChangeTab}>
            <TabPane tab={<TabHeader title="List View" icon="UNORDER_LIST" />} key={AcademicPlans.TabKey.List}>
                {activeTabKey === AcademicPlans.TabKey.List && (
                    <ListViewTab
                        studentId={studentId}
                        isStaff={isStaff}
                        student={student}
                        majorVersionType={student.programVersionType}
                    />
                )}
            </TabPane>
            {renderTermTab()}
        </Tabs>
    )
}
