/* eslint-disable react-hooks/exhaustive-deps */
import {KlassappTableHOC} from "HOC"
import {routing} from "helpers/routing"
import {head} from "lodash"
import moment from "moment-timezone"
import React, {useCallback, useEffect} from "react"
import {useTranslation} from "react-i18next"
import {useHistory} from "react-router-dom"
import {KlassappTable, KlassappTableHeader} from "uiKit"

const ListCourseGrade = (props) => {
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        listCourseGrade,
        scheduleId,
        courseId,
        termIds,
        data,
        allFields,
        fields,
        tableHeaderActions,
        orderField,
        isLoading,
        isShowTableHeaderAction
    } = props

    const {t} = useTranslation(["campuses"])
    const history = useHistory()

    const getFields = () => {
        return ["Title", "Type", "Avg. points", "Max points", "Date", "Lesson Or Manual Grade", "Instructor Name"]
    }

    const getPageTitle = () => {
        return t("campuses.campus")
    }

    useEffect(() => {
        const listData = []
        for (let i = 0; i < listCourseGrade.length; i++) {
            if (listCourseGrade[i].date) {
                listCourseGrade[i].dateFormat = moment(listCourseGrade[i].date).format("MMM DD, YYYY")
                listCourseGrade[i].gradeType = listCourseGrade[i].lessonId ? "LESSON GRADE" : "MANUAL GRADE"
                listData.push(listCourseGrade[i])
            }
        }
        dispatch({data: listData, total: listData.length})
    }, [listCourseGrade])

    const getColumns = () => {
        return [
            {
                title: "Title",
                field: "title"
            },
            {
                title: "Type",
                field: "gradingItem"
            },
            {
                title: "Avg. points",
                field: "avgPoints"
            },
            {
                title: "Max points",
                field: "maxPoints"
            },
            {
                title: "Date",
                field: "dateFormat"
            },
            {
                title: "Lesson Or Manual Grade",
                field: "gradeType"
            },
            {
                title: "Instructor Name",
                field: "instructors"
            }
        ]
    }

    const onClickRowItem = useCallback(
        (courseGrade) => {
            const termId: number = head(termIds)
            history.push(routing.academics.instructional.manualGrade(courseId, termId, scheduleId, courseGrade.id))
        },
        [courseId, termIds, scheduleId]
    )

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
    }, [])

    return (
        <div>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                onClickRowItem={onClickRowItem}
                menuActions={[]}
                isLoading={isLoading}
                allFields={allFields}
                isShowCheckedColumn={false}
                orderField={orderField}
                onChangeFields={props.onChangeFields}
                onUpdateTableData={props.onUpdateTableData}
                onClickSortColumn={props.onClickSortColumn}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </div>
    )
}

export default KlassappTableHOC(ListCourseGrade)
