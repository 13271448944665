/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {Model} from "Model"
import {useParams} from "react-router-dom"
import {useLocation} from "react-router-dom"
import {Branding} from "types/branding"
import {get} from "lodash"
import {userServiceV3} from "services"
import DefaultLogo from "assets/images/png/EdularLogoRectangle.png"
import styles from "./Unsubscribe.module.css"
import {store} from "helpers"

type UrlParams = {
    token: string
}

type Props = {
    model: Model
}

let intervalId = null
export const Unsubscribe = (props: Props) => {
    const {model} = props
    const location: any = useLocation()
    const params = useParams<UrlParams>()

    const closeSessionStorage = location.state?.closeSessionStorage
    const closeLocalStorage = location.state?.closeLocalStorage

    const [count, setCount] = useState(5) // 5 seconds

    useEffect(() => {
        model.menu.infoBar = false
        model.menu.sidebar = false
    }, [])

    useEffect(() => {
        intervalId = setInterval(() => {
            setCount((c) => c - 1)
        }, 1000)
        return () => {
            clearInterval(intervalId)
        }
    }, [])
    useEffect(() => {
        if (count <= 0) {
            close()
        }
    }, [count])
    useEffect(() => {
        unsubscribe(params.token)
    }, [userServiceV3, params.token])

    const unsubscribe = async (token) => {
        await userServiceV3.unsubscribe({token})
    }

    const close = () => {
        clearInterval(intervalId)
        if (closeSessionStorage) {
            store.clearAll()
        }
        if (closeLocalStorage) {
            model.logout()
        }
    }

    const getBrandingImg = () => {
        const rectangularImg = get(
            model,
            ["clientSetting", "branding", Branding.BrandingType.OfficialLogoRectangular],
            null
        )
        const squareImg = get(model, ["clientSetting", "branding", Branding.BrandingType.OfficialLogoSquare], null)
        return squareImg || rectangularImg || DefaultLogo
    }

    const logoUrl = getBrandingImg()

    return (
        <div className={styles.root}>
            <img src={logoUrl} alt="logo" />
            <br />
            <br />
            <p>You have been successfully unsubscribed</p>
        </div>
    )
}
