/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react"
import {KlassappTableHeader, KlassappTable, ConfirmPopup} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {Icon} from "components/Icon"
import {attendanceService} from "services"
import moment from "moment"
import {useHistory} from "react-router-dom"
import {routing} from "helpers/routing"
import {useModel, useVisible} from "hooks"
import {LessonAttendanceStatus, LessonStatus} from "types/attendance"
import {checkPermission, convertTZ, handleError, roundTwoDigits, toastError} from "helpers"
import {KlassappTableProps} from "types/common"
import {Course} from "types/courses"
import {Permissions} from "types/permission"
import {LessonPopup} from "../LessonPopup"
import {useTranslation} from "react-i18next"

const LESSON_STATUS_ALL = "all"

type Props = KlassappTableProps & {
    filter: any
    scheduleId: number
    courseId: number
    courseInfo: Course.CourseTableItem
    termId: number | "current"
    fromRoute: "registrar" | "instructional"
}

function LessonsTable(props: Props) {
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        filter,
        pageSize,
        columns,
        scheduleId,
        data,
        courseInfo,
        allFields,
        fields,
        tableHeaderActions,
        orderField,
        isLoading,
        isShowTableHeaderAction,
        isLoadedTableFuncs,
        courseId,
        termId,
        menuActions,
        fromRoute
    } = props
    const history = useHistory()
    const model = useModel()
    const editLessonPopup = useVisible()
    const {t} = useTranslation(["common"])
    const deleteLessonPopup = useVisible()
    const deleteMultipleLessonPopup = useVisible()
    const [lesson, setLesson] = useState(null)

    const getFields = () => {
        return ["Lesson Date", "Time", "Name", "Type", "Location", "Attendance", "Lesson Status"]
    }

    const getPageTitle = () => {
        return "Lesson"
    }

    const getColumns = () => {
        return [
            {
                title: "Lesson Date",
                field: "lessonDate"
            },
            {
                title: "Time",
                field: "timeRange"
            },
            {
                title: "Name",
                field: "lessonName"
            },
            {
                title: "Type",
                field: "lessonType"
            },
            {
                title: "Location",
                field: "location"
            },
            {
                title: "Attendance",
                field: "attendanceHtml"
            },
            {
                title: "Lesson Status",
                field: "actionHtml"
            }
        ]
    }
    const getMenuActions = () => {
        const canEdit = checkPermission(
            {staff: [Permissions.Staff.Academics.Instructional.Lessons.LessonDetails.Edit]},
            model
        )
        const canDelete = checkPermission(
            {staff: [Permissions.Staff.Academics.Instructional.Lessons.LessonDetails.Delete]},
            model
        )
        const menus = []
        if (canEdit) {
            menus.push({title: "Edit", icon: "EDIT", action: onClickEditLesson})
        }
        if (canDelete) {
            menus.push({title: "Delete", icon: "DELETE", action: onClickDeleteLesson})
        }
        return menus
    }

    const renderAttendanceHtml = (item) => {
        const attendedHours = item.attendance?.attended ?? 0
        const totalHours = item.attendance?.total ?? 0
        const percentage = item.attendance?.percentage ?? 0

        return (
            <span>
                {roundTwoDigits(attendedHours)}/{totalHours} ({percentage}%)
            </span>
        )
    }

    const renderActionHtml = (item) => {
        if (item.lesson.status === LessonStatus.Active || item.lesson.status === LessonStatus.Paused) {
            return <Icon icon="LESSON_IN_PROGRESS" />
        }
        if (item.lesson.status === LessonStatus.Inactive) {
            return <Icon icon="LESSON_NOT_STARTED" />
        }
        if (item.lesson?.lessonAttendanceStatus === LessonAttendanceStatus.Verified) {
            return <Icon icon="CHECKED" />
        }

        if (item.lesson.lessonAttendanceStatus === LessonAttendanceStatus.NeedAction) {
            return <Icon icon="ALERT" />
        }
        return <Icon icon="LESSON_ENDED_NOT_VERIFY" color="#1e90ff" />
    }

    const getData = async () => {
        dispatch({isLoading: true})
        try {
            const timezone = courseInfo?.timezone ?? moment.tz.guess()
            const paramsLessonAttendance: any = {
                courses: [courseId],
                termIds: [termId],
                checkInvite: false,
                instructorProfileIds: model.isAdmin() || fromRoute === "registrar" ? [] : [model.profileId]
            }
            const profileId = model.profileId
            if (!model.isStaffOrAdmin() && profileId) {
                paramsLessonAttendance.studentProfileIds = [profileId]
            }
            if (filter && filter.lessonStatus?.value !== LESSON_STATUS_ALL) {
                paramsLessonAttendance.lessonStatus = filter.lessonStatus?.value
            }
            if (scheduleId) {
                paramsLessonAttendance.scheduleId = scheduleId
            }
            if (filter.search && filter.search !== "") {
                paramsLessonAttendance.search = filter.search
            }

            const {data: lessons} = await attendanceService.getLessonsAttendance(paramsLessonAttendance)
            const data = lessons.map((item: any) => {
                const {startsAt, endsAt, status} = item.lesson
                item.id = item.lesson.id
                item.lessonDate = moment(convertTZ(new Date(startsAt), timezone)).format(model.getUserDateFormat())
                item.timeRange = `${moment(convertTZ(new Date(startsAt), timezone)).format(
                    model.getUserTimeFormat()
                )} - ${moment(convertTZ(new Date(endsAt), timezone)).format(model.getUserTimeFormat())}`
                item.lessonName = item.lesson.name
                item.attendance = {
                    ...item.attendance,
                    percentage: roundTwoDigits(item.attendance?.percentage ?? 0)
                }
                item.attendanceHtml = renderAttendanceHtml(item)
                item.lessonType = item.lesson.type
                item.actionHtml = renderActionHtml(item)
                item.status = status
                return item
            })
            dispatch({data, total: data.length})
        } catch (e) {
            console.log("e", e)
        } finally {
            dispatch({isLoading: false, isShowTableHeaderAction: false})
        }
    }

    const onClickRowItem = (data) => {
        if (fromRoute === "registrar") {
            history.push(routing.registrar.lessonDetail(courseId, termId, data.id, 1))
        } else {
            history.push(routing.academics.instructional.lessonDetail(courseId, termId, data.id, 1, scheduleId))
        }
    }

    const onClickEditLesson = (data) => {
        if (data.lesson.status === LessonStatus.Inactive) {
            setLesson(data.lesson)
            editLessonPopup.open()
        } else {
            toastError("This lesson already started. We can't edit this lesson")
        }
    }

    const onClickDeleteLesson = useCallback((data) => {
        setLesson(data.lesson)
        deleteLessonPopup.open()
    }, [])

    const onClickCancelConfirmDelete = useCallback(() => {
        setLesson(null)
        deleteLessonPopup.close()
    }, [])

    const onClickConfirmDeleteMultipleLesson = useCallback(async () => {
        try {
            const lessonIds = props
                .getCurrentData()
                .filter((item) => item.isChecked)
                .map((item) => item.id)
            dispatch({isLoading: true})
            await attendanceService.deleteLessonByIds({lessonIds})
            getData()
            deleteMultipleLessonPopup.close()
        } catch (err) {
            handleError(err)
        }
    }, [fromRoute])

    const onClickCancelDeleteMultipleLesson = useCallback(() => {
        setLesson(null)
        deleteMultipleLessonPopup.close()
    }, [])

    const onClickConfirmDelete = useCallback(async () => {
        if (lesson) {
            try {
                dispatch({isLoading: true})
                await attendanceService.deleteLessonByIds({lessonIds: [lesson.id]})
                getData()
                deleteLessonPopup.close()
            } catch (err) {
                handleError(err)
            }
        }
    }, [lesson, fromRoute])

    const getTableHeaderActions = () => {
        return [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: deleteMultipleLessonPopup.open
            }
        ]
    }

    const editLessonSuccess = () => {
        editLessonPopup.close()
        setLesson(null)
        getData()
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "getMenuActions", func: getMenuActions},
            {key: "onClickRowItem", func: onClickRowItem},
            {key: "getTableHeaderActions", func: getTableHeaderActions}
        ])
    }, [])

    useEffect(() => {
        if (isLoadedTableFuncs && courseInfo && fromRoute) {
            getData()
        }
    }, [isLoadedTableFuncs, page, pageSize, filter, scheduleId, courseInfo, fromRoute])

    useEffect(() => {
        props.updateTableHeaderActions()
    }, [data])

    const canDelete = checkPermission(
        {staff: [Permissions.Staff.Academics.Instructional.Lessons.LessonDetails.Delete]},
        model
    )

    return (
        <div>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                menuActions={menuActions}
                isLoading={isLoading}
                allFields={allFields}
                isShowCheckedColumn={canDelete}
                orderField={orderField}
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
            />
            {editLessonPopup.isVisible && (
                <LessonPopup
                    campusIds={courseInfo?.campusIds}
                    isShow={editLessonPopup.isVisible}
                    lessonInfo={lesson}
                    onClose={editLessonPopup.close}
                    onSuccess={editLessonSuccess}
                />
            )}

            {deleteLessonPopup.isVisible && (
                <ConfirmPopup
                    isVisible={deleteLessonPopup.isVisible}
                    title={t("common:message.deleteItem")}
                    onClose={onClickCancelConfirmDelete}
                    loading={isLoading}
                    onConfirm={onClickConfirmDelete}
                />
            )}

            {deleteMultipleLessonPopup.isVisible && (
                <ConfirmPopup
                    isVisible={deleteMultipleLessonPopup.isVisible}
                    title={t("common:message.deleteItems")}
                    loading={isLoading}
                    onClose={onClickCancelDeleteMultipleLesson}
                    onConfirm={onClickConfirmDeleteMultipleLesson}
                />
            )}
        </div>
    )
}

export default KlassappTableHOC(LessonsTable)
