import React from "react"
import {Icon} from "components/Icon"
import styles from "./SAPCheckPoints.module.css"

export class SAPCheckPoints extends React.Component {
    renderCompletedIcon = () => {
        return (
            <div className={styles.tickCircleIcon}>
                <Icon icon="TICK" className={styles.tickIcon} color="#18a957" />
            </div>
        )
    }

    render() {
        return (
            <div className={styles.wrap}>
                <div className={styles.header}>
                    <Icon icon="FILE_LINE_ALT" color="#62B1FF" />
                    <p className={styles.headerTitle}>SAP Checkpoints</p>
                </div>
                <div className={styles.body}>
                    <div className={styles.item}>
                        <p className={styles.itemTitle}>Good Standing (Overall)</p>
                    </div>
                    <div className={styles.item}>
                        <p className={styles.itemTitle}>Academic Standing</p>
                    </div>
                    <div className={styles.item}>
                        <p className={styles.itemTitle}>Program Completion</p>
                    </div>
                    <div className={styles.item}>
                        <p className={styles.itemTitle}>Completion Rate of Attempted Units</p>
                    </div>
                    <div className={styles.item}>
                        <p className={styles.itemTitle}>Cumulative GPA</p>
                    </div>
                    <div className={styles.item}>
                        <p className={styles.itemTitle}>Graduation Requirements</p>
                    </div>
                    <p className={styles.note}>
                        If you see one or more{" "}
                        <Icon
                            className={styles.warningIcon}
                            color="var(--warning-400-base)"
                            icon={"WARNING_TRIANGLE_FILL"}
                        />{" "}
                        or <Icon className={styles.warningIcon} icon={"CROSS_CIRCLE"} color="#df1642" />, please
                        schedule an appointment with your Advisor immediately.
                    </p>
                </div>
            </div>
        )
    }
}
