import React from "react"
import {useTranslation} from "react-i18next"
import {BaseDatePicker, BaseTimePicker} from "components/DateTimePicker"
import styles from "./CalendarSelection.module.css"

export function CalendarSelection(props) {
    const {t} = useTranslation(["calendar"])

    return (
        <div className={styles.wrap}>
            <p className={styles.title}>{t("calendar.cancelled.selectDateTime")}</p>
            <div className={styles.item}>
                <BaseDatePicker />
            </div>
            <div className={styles.item}>
                <BaseTimePicker />
            </div>
        </div>
    )
}
