import {get} from "lodash"
import BaseService from "./Base"

type DepartmentStagesStats = {
    count: number
    name: string
    stageId: number
}
export default class StatsService extends BaseService {
    async getDepartmentStatsStages(data): Promise<DepartmentStagesStats[]> {
        const response = await this.post(`/v1/department-stats/list/stages`, data)
        return get(response, "data.data", null)
    }

    async getDepartmentStatsEnrolledStudents(data) {
        const response = await this.post(`/v1/department-stats/list/enrolled-students`, data)
        return get(response, "data.data", null)
    }

    async getDepartmentStatsAdmissions(data) {
        const response = await this.post(`/v1/department-stats/list/admissions`, data)
        return get(response, "data.data", null)
    }
}
