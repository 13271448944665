import React from "react"
import classNames from "classnames"
import {RemarkIndicator} from "./RemarkIndicator"
import styles from "./item.module.css"

type Props = {
    field: any
    onChangeRemarkField: (fieldId: string, comment: string) => void
}

export function Signature(props: Props) {
    const {field, onChangeRemarkField} = props
    const {width, height} = field.placeInformation

    return (
        <RemarkIndicator field={field} onChangeRemarkField={onChangeRemarkField}>
            <div className={classNames(styles.actionItem, styles.flexCenter)} style={{width, height}}>
                {field.value && <img className={styles.signedItem} src={field.value} alt="" />}
            </div>
        </RemarkIndicator>
    )
}
