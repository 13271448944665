import {RRule} from "rrule"
import {AcademicPlans} from "types/academicPlans"
import {RecurringEnd} from "types/calendar"

export const TYPES = {
    lectures: "Lecture",
    labs: "Labs",
    practicals: "Practicals",
    general: "General Event",
    appointment_slot: "Appointment"
}

export const RRULE_OPTIONS_FREQUENCY = {
    [RRule.YEARLY]: 1,
    [RRule.MONTHLY]: 2,
    [RRule.WEEKLY]: 3,
    [RRule.DAILY]: 4
}
export const RRULE_DAYS = {
    0: 1,
    1: 2,
    2: 3,
    3: 4,
    4: 5,
    5: 6,
    6: 0
}
export const OPTIONS_FREQUENCY = [
    {value: 0, originalValue: "NEVER", label: "NEVER"},
    {value: 4, originalValue: "DAILY", label: "DAILY"},
    {value: 3, originalValue: "WEEKLY", label: "WEEKLY"},
    {value: 2, originalValue: "MONTHLY", label: "MONTHLY"},
    {value: 1, originalValue: "YEARLY", label: "YEARLY"}
]

export const OPTIONS_TIME_SLOTS = [
    {value: 15, label: "15 min"},
    {value: 30, label: "30 min"},
    {value: 60, label: "1 hour"},
    {value: 120, label: "2 hours"}
]

export const OPTIONS_WEEK_DAYS = [
    {value: 0, label: "Sun", short: "SU"},
    {value: 1, label: "Mon", short: "MO"},
    {value: 2, label: "Tue", short: "TU"},
    {value: 3, label: "Wed", short: "WE"},
    {value: 4, label: "Thu", short: "TH"},
    {value: 5, label: "Fri", short: "FR"},
    {value: 6, label: "Sat", short: "SA"}
]

export const OPTIONS_DAYS_LONG = [
    {value: 0, label: "Sunday", short: "SU"},
    {value: 1, label: "Monday", short: "MO"},
    {value: 2, label: "Tuesday", short: "TU"},
    {value: 3, label: "Wednesday", short: "WE"},
    {value: 4, label: "Thursday", short: "TH"},
    {value: 5, label: "Friday", short: "FR"},
    {value: 6, label: "Saturday", short: "SA"}
]

export const OPTIONS_MONTHLY_FREQUENCY = [
    {value: 1, label: "First"},
    {value: 2, label: "Second"},
    {value: 3, label: "Third"},
    {value: 4, label: "Fourth"},
    {value: -1, label: "Last"}
]

export const OPTIONS_SAP_STATUS = [
    {value: AcademicPlans.SapStatus.New, label: "New"},
    {value: AcademicPlans.SapStatus.Satisfactory, label: "Satisfactory"},
    {value: AcademicPlans.SapStatus.Warning, label: "Warning"},
    {value: AcademicPlans.SapStatus.Probation, label: "Probation"},
    {value: AcademicPlans.SapStatus.Suspension, label: "Suspension"}
]

export const OPTIONS_SAP_ACTION_TYPE = [
    {value: AcademicPlans.SapActionType.Meeting, label: "Meeting"},
    {value: AcademicPlans.SapActionType.ActionPlan, label: "Action Plan"}
]

export const OPTIONS_FREQUENCY_TYPE = [
    {value: RecurringEnd.Never, label: "Never"},
    {value: RecurringEnd.AfterExecution, label: "After"},
    {value: RecurringEnd.OnDate, label: "On date"}
]

export const OPTIONS_MONTH_DAYS = Array.from(Array(31).keys()).map((nothing, index) => {
    return {value: index + 1, label: `${index + 1}`}
})

export const OPTIONS_TIMES = [
    {value: 0, label: "00:00 AM"},
    {value: 1, label: "00:15 AM"},
    {value: 2, label: "00:30 AM"},
    {value: 3, label: "00:45 AM"},
    {value: 4, label: "01:00 AM"},
    {value: 5, label: "01:15 AM"},
    {value: 6, label: "01:30 AM"},
    {value: 7, label: "01:45 AM"},
    {value: 8, label: "02:00 AM"},
    {value: 9, label: "02:15 AM"},
    {value: 10, label: "02:30 AM"},
    {value: 11, label: "02:45 AM"},
    {value: 12, label: "03:00 AM"},
    {value: 13, label: "03:15 AM"},
    {value: 14, label: "03:30 AM"},
    {value: 15, label: "03:45 AM"},
    {value: 16, label: "04:00 AM"},
    {value: 17, label: "04:15 AM"},
    {value: 18, label: "04:30 AM"},
    {value: 19, label: "04:45 AM"},
    {value: 20, label: "05:00 AM"},
    {value: 21, label: "05:15 AM"},
    {value: 22, label: "05:30 AM"},
    {value: 23, label: "05:45 AM"},
    {value: 24, label: "06:00 AM"},
    {value: 25, label: "06:15 AM"},
    {value: 26, label: "06:30 AM"},
    {value: 27, label: "06:45 AM"},
    {value: 28, label: "07:00 AM"},
    {value: 29, label: "07:15 AM"},
    {value: 30, label: "07:30 AM"},
    {value: 31, label: "07:45 AM"},
    {value: 32, label: "08:00 AM"},
    {value: 33, label: "08:15 AM"},
    {value: 34, label: "08:30 AM"},
    {value: 35, label: "08:45 AM"},
    {value: 36, label: "09:00 AM"},
    {value: 37, label: "09:15 AM"},
    {value: 38, label: "09:30 AM"},
    {value: 39, label: "09:45 AM"},
    {value: 40, label: "10:00 AM"},
    {value: 41, label: "10:15 AM"},
    {value: 42, label: "10:30 AM"},
    {value: 43, label: "10:45 AM"},
    {value: 44, label: "11:00 AM"},
    {value: 45, label: "11:15 AM"},
    {value: 46, label: "11:30 AM"},
    {value: 47, label: "11:45 AM"},
    {value: 48, label: "12:00 PM"},
    {value: 49, label: "12:15 PM"},
    {value: 50, label: "12:30 PM"},
    {value: 51, label: "12:45 PM"},
    {value: 52, label: "01:00 PM"},
    {value: 53, label: "01:15 PM"},
    {value: 54, label: "01:30 PM"},
    {value: 55, label: "01:45 PM"},
    {value: 56, label: "02:00 PM"},
    {value: 57, label: "02:15 PM"},
    {value: 58, label: "02:30 PM"},
    {value: 59, label: "02:45 PM"},
    {value: 60, label: "03:00 PM"},
    {value: 61, label: "03:15 PM"},
    {value: 62, label: "03:30 PM"},
    {value: 63, label: "03:45 PM"},
    {value: 64, label: "04:00 PM"},
    {value: 65, label: "04:15 PM"},
    {value: 66, label: "04:30 PM"},
    {value: 67, label: "04:45 PM"},
    {value: 68, label: "05:00 PM"},
    {value: 69, label: "05:15 PM"},
    {value: 70, label: "05:30 PM"},
    {value: 71, label: "05:45 PM"},
    {value: 72, label: "06:00 PM"},
    {value: 73, label: "06:15 PM"},
    {value: 74, label: "06:30 PM"},
    {value: 75, label: "06:45 PM"},
    {value: 76, label: "07:00 PM"},
    {value: 77, label: "07:15 PM"},
    {value: 78, label: "07:30 PM"},
    {value: 79, label: "07:45 PM"},
    {value: 80, label: "08:00 PM"},
    {value: 81, label: "08:15 PM"},
    {value: 82, label: "08:30 PM"},
    {value: 83, label: "08:45 PM"},
    {value: 84, label: "09:00 PM"},
    {value: 85, label: "09:15 PM"},
    {value: 86, label: "09:30 PM"},
    {value: 87, label: "09:45 PM"},
    {value: 88, label: "10:00 PM"},
    {value: 89, label: "10:15 PM"},
    {value: 90, label: "10:30 PM"},
    {value: 91, label: "10:45 PM"},
    {value: 92, label: "11:00 PM"},
    {value: 93, label: "11:15 PM"},
    {value: 94, label: "11:30 PM"},
    {value: 95, label: "11:45 PM"}
]

export const REMINDERS = [
    {id: 10 * 60, name: "10 minutes before"},
    {id: 30 * 60, name: "30 minutes before"},
    {id: 1 * 60 * 60, name: "1 hour before"},
    {id: 1 * 24 * 60 * 60, name: "1 day before"}
    // {id: "custom", name: "Custom"}
]
