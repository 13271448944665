/* eslint-disable react-hooks/exhaustive-deps */
import React, {useReducer, useEffect} from "react"
import {isEmpty} from "lodash"
import {BasePopup} from "components/popup"
import {BaseButton} from "components/buttons"
import {Course} from "types/courses"
import {KlassDropdown} from "components/Select"
import {FormLabel} from "components/Form"
import {handleError, toastError} from "helpers"
import {academicPlansService} from "services"
import {AcademicPlans} from "types/academicPlans"
import {CoursesTable, SelectedCoursesTable} from "../CourseTable"
import styles from "./TermChooseCoursesPopup.module.css"

type Props = {
    isShow: boolean
    onClose: () => void
    section?: AcademicPlans.TermSection
    academicPlanTermId: number
    studentId: number
    onSaveSuccess: () => void
}

export function TermChooseCoursesPopup(props: Props) {
    function reducer(state, action) {
        return {...state, ...action}
    }
    const initialState = {
        sections: [],
        selectedSection: null,
        selectedCourses: [],
        isSubmitting: false,
        forceKey: 1
    }
    const [{sections, selectedSection, selectedCourses, isSubmitting, forceKey}, dispatch] = useReducer(
        reducer,
        initialState
    )
    const {isShow, onClose, section, studentId, academicPlanTermId, onSaveSuccess} = props
    const hasChanged = selectedCourses.filter((course) => !course.isSelected).length > 0

    useEffect(() => {
        if (section) {
            getListSectionCourses(section.id)
        } else {
            getListSections()
        }
    }, [])

    const getListSections = async () => {
        try {
            const sections = await academicPlansService.getListSections({studentProfileId: studentId})
            dispatch({sections})
        } catch (error) {
            handleError(error)
        }
    }

    const getListSectionCourses = async (sectionId) => {
        try {
            const courses = await academicPlansService.getAllSectionCourses({sectionId})
            const selectedCourses = courses
                .filter((course) => course.academicPlanTermId === academicPlanTermId)
                .map((course) => {
                    course.isSelected = true
                    return course
                })
            const restCourses = courses.filter((course) => !course.academicPlanTermId)
            dispatch({courses: restCourses, selectedCourses, forceKey: forceKey + 1})
        } catch (error) {
            handleError(error)
        }
    }

    const onAddCourses = (addedCourses: Course.CourseTableItem[]) => {
        const newCourses = addedCourses.map((course) => ({
            id: course.id,
            courseId: course.courseId,
            courseCode: course.code,
            courseName: course.name,
            role: course.role
        }))
        dispatch({selectedCourses: [...selectedCourses, ...newCourses], forceKey: forceKey + 1})
    }

    const onSelectSection = (section) => {
        dispatch({selectedSection: section})
        getListSectionCourses(section.id)
    }

    const onClickSave = async () => {
        if (isEmpty(selectedCourses)) {
            toastError("Please add a course in order to continue.")
            return
        }
        try {
            dispatch({isSubmitting: true})
            const courseIds = selectedCourses.filter((course) => !course.isSelected).map((course) => course.courseId)
            const roles = selectedCourses
                .filter((course) => !course.isSelected)
                .map((course) => {
                    return {
                        courseId: course.courseId,
                        role: course.role
                    }
                })
            const academicPlanCourseIds = selectedCourses
                .filter((course) => !course.isSelected)
                .map((course) => course.id)
            await academicPlansService.addCourse({
                studentProfileId: studentId,
                courseIds,
                roles,
                academicPlanCourseIds,
                academicPlanSectionId: section ? section.id : selectedSection.id,
                academicPlanTermId
            })
            dispatch({isSubmitting: false})
            onSaveSuccess()
            onClose()
        } catch (error) {
            dispatch({isSubmitting: false})
            handleError(error)
        }
    }

    const renderAddCoursesToSection = () => {
        return (
            <>
                <div className={styles.sectionInfo}>
                    <span className={styles.sectionTitle}>{section.sectionTitle}</span>
                </div>
                <div className={styles.tableWrap}>
                    <SelectedCoursesTable key={`selected_${forceKey}`} selectedCourses={selectedCourses} />
                    <CoursesTable
                        key={forceKey}
                        onAddCourses={onAddCourses}
                        sectionId={section.id}
                        selectedCourses={selectedCourses}
                        uniqCourse
                    />
                </div>
                <div className={styles.action}>
                    <BaseButton title="Save" loading={isSubmitting} onClick={onClickSave} isActive={hasChanged} />
                </div>
            </>
        )
    }

    const renderAddCoursesToSelectionSection = () => {
        return (
            <>
                <div>
                    <FormLabel label="Section" />
                    <KlassDropdown
                        options={sections}
                        value={selectedSection}
                        labelKey="sectionTitle"
                        onChange={onSelectSection}
                    />
                </div>
                {!!selectedSection && (
                    <>
                        <div className={styles.tableWrap}>
                            <SelectedCoursesTable key={`selected_${forceKey}`} selectedCourses={selectedCourses} />

                            <CoursesTable
                                key={forceKey}
                                onAddCourses={onAddCourses}
                                sectionId={selectedSection.id}
                                selectedCourses={selectedCourses}
                                uniqCourse
                            />
                        </div>
                        <div className={styles.action}>
                            <BaseButton
                                title="Save"
                                loading={isSubmitting}
                                onClick={onClickSave}
                                isActive={hasChanged}
                            />
                        </div>
                    </>
                )}
            </>
        )
    }

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="PLUS_CIRCLE" width="80vw">
            <div className={styles.wrap}>
                <p className={styles.title}>Add Courses</p>
                {section ? renderAddCoursesToSection() : renderAddCoursesToSelectionSection()}
            </div>
        </BasePopup>
    )
}
