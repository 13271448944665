import BaseService from "./Base"
import {ApiListResponse, ApiResponse} from "types/api"
import {AxiosResponse} from "axios"

export class WorkScheduleService extends BaseService {
    getAll = async (body = {}) => {
        const {data: response}: AxiosResponse<ApiListResponse<any[]>> = await this.post("/v1/work-schedule/list", {
            ...body
        })
        if (!response.success) {
            throw response
        }

        return {
            data: response.data,
            total: response.total
        }
    }

    getOne = async (workScheduleId: number): Promise<any | null> => {
        const {data: response}: AxiosResponse<ApiResponse<any[]>> = await this.post("/v1/work-schedule/list", {
            filter: {
                id: [workScheduleId]
            }
        })

        if (!response.success) {
            throw response
        }

        return response.data[0] ?? null
    }

    create = async (payload: any) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/work-schedule/create", payload)

        if (!response.success) {
            throw response
        }
    }

    duplicate = async (workSchedulesIds: number[]) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/work-schedule/duplicate", {
            id: workSchedulesIds
        })

        if (!response.success) {
            throw response
        }
    }

    update = async (payload: any) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/work-schedule/edit", payload)

        if (!response.success) {
            throw response
        }
    }

    addEvent = async (payload: any) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/work-schedule/add-event", payload)

        if (!response.success) {
            throw response
        }
    }

    deleteBulk = async (workSchedulesIds: number[]) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/work-schedule/delete", {
            id: workSchedulesIds
        })

        if (!response.success) {
            throw response
        }
    }
}
