import React from "react"
import {withTranslation} from "react-i18next"
import {omit, get, isEmpty} from "lodash"
import {stringifyUrl} from "query-string"
import moment from "moment"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {BaseButton} from "components/buttons"
import {Icon} from "components/Icon"
import {documentService} from "services"
import {handleError, checkDocumentDepartmentPermission, getEnableSubunitsByDepartmentPermission} from "helpers"
import {Model} from "Model"
import {KlassappTableHOC} from "HOC"
import {KlassappTableProps} from "types/common"
import {PermissionsRequired} from "components/PermissionsRequired"
import {PermissionType, Permissions} from "types/permission"
import {TableFilter} from "./parts"
import styles from "./Documents.module.css"

interface PageProps {
    t: Function
    model: Model
    backUrl?: string
    history?: any
    user?: any
    userId?: any
    studentId?: number
    isUserDocumentTab?: boolean
    profileId?: number
    departmentId?: number
    backUrlFromPage?: string
}

type Props = KlassappTableProps & PageProps

interface State {
    filterData: any
    enabledSubunitIds?: number[]
    defaultPageSize: number
}

class Documents extends React.Component<Props, State> {
    state: State = {
        filterData: {},
        defaultPageSize: 100
    }

    componentDidMount() {
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getData},
            {key: "getFields", func: this.getFields},
            {key: "getColumns", func: this.getColumns},
            {key: "getTableHeaderActions", func: this.getTableHeaderActions},
            {key: "onClickEdit", func: this.onClickEdit},
            {key: "onClickDelete", func: this.onClickDelete},
            {key: "getMenuActions", func: this.getMenuActions}
        ])
        this.props.dispatch({isLoading: true})
        this.getData()
        this.getEnabledSubunitIds()
    }

    getEnabledSubunitIds = async () => {
        const enabledSubunitIds = getEnableSubunitsByDepartmentPermission(
            PermissionType.View,
            this.props.model,
            "Documents"
        )
        this.setState({enabledSubunitIds})
    }

    getPageTitle = () => {
        return "Document"
    }

    getFields = () => {
        return ["Code", "Name", "Description", "Subunit", "Profile Type", "Date", "Approved"]
    }

    getColumns = () => {
        return [
            {
                title: "Code",
                field: "code",
                style: {
                    minWidth: "70px",
                    maxWidth: "80px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                },
                sortable: true
            },
            {
                title: "Name",
                field: "name",
                style: {
                    minWidth: "90px",
                    maxWidth: "100px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                },
                sortable: true
            },
            {
                title: "Description",
                field: "description",
                style: {
                    minWidth: "120px",
                    maxWidth: "130px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                },
                sortable: true
            },
            {
                title: "Subunit",
                field: "departmentSubunitName",
                style: {
                    minWidth: "100px",
                    maxWidth: "100px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                },
                sortable: true
            },
            {
                title: "Profile Type",
                field: "profileType",
                style: {minWidth: "120px", maxWidth: "120px"},
                sortable: true
            },
            {
                title: "Date",
                field: "createdAt",
                fieldType: "date",
                format: "MM/DD/YYYY",
                style: {minWidth: "100px", maxWidth: "110px"},
                sortable: true
            },
            {
                title: "Approved",
                field: "statusHtml",
                style: {
                    minWidth: "100px",
                    maxWidth: "110px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                }
            }
        ]
    }

    canShowDeleteIcon = (data) => {
        return data.status !== "approved"
    }

    getMenuActions = () => {
        const {t} = this.props
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: this.onClickEdit
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: this.onClickDelete,
                canShow: this.canShowDeleteIcon
            }
        ]
    }

    getTableHeaderActions = () => {
        const {t, model, departmentId, data} = this.props
        const canApprove = data.filter((item) => item.isChecked).every((item) => item.ownerUserId !== model.user.id)
        const canDelete = data.filter((item) => item.isChecked).every((item) => item.status !== "approved")
        const actions: any[] = canApprove
            ? [
                  {
                      title: t("common:action.approve"),
                      icon: "CHECKMARK_CIRCLE",
                      iconClassName: styles.approveIcon,
                      action: this.onClickApprove
                  },
                  {
                      title: t("common:action.reject"),
                      icon: "CROSS_CIRCLE_FILL",
                      iconClassName: styles.rejectIcon,
                      action: this.onClickReject
                  },
                  {
                      title: t("common:action.print"),
                      icon: "DOCUMENT",
                      iconClassName: styles.printIcon,
                      action: this.onClickPrint
                  }
              ]
            : []
        const canDeleteByPermission = checkDocumentDepartmentPermission(PermissionType.Delete, model, departmentId)
        if (canDeleteByPermission && canDelete) {
            actions.unshift({
                title: t("common:action.delete"),
                icon: "DELETE",
                action: this.onClickDeleteMulti
            })
        }
        return actions
    }

    onClickDeleteMulti = async () => {
        const checkedItems = this.props.data.filter((item) => item.isChecked)
        const payload = checkedItems.map((item) => item.id)
        try {
            this.props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await documentService.deleteDocument(payload)
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onClickDelete = async (deletedItem) => {
        try {
            this.props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await documentService.deleteDocument([deletedItem.userDocumentId])
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onClickApprove = async () => {
        const checkedItems = this.props.data.filter((item) => item.isChecked)
        const payload = checkedItems.map((item) => ({userDocumentId: item.id, status: "approved"}))
        try {
            this.props.dispatch({isLoading: true})
            await documentService.updateDocumentStatus(payload)
            await this.getData()
            this.props.dispatch({isShowTableHeaderAction: false})
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onClickReject = async () => {
        const checkedItems = this.props.data.filter((item) => item.isChecked)
        const payload = checkedItems.map((item) => ({userDocumentId: item.id, status: "rejected"}))
        try {
            this.props.dispatch({isLoading: true})
            await documentService.updateDocumentStatus(payload)
            await this.getData()
            this.props.dispatch({isShowTableHeaderAction: false})
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onClickPrint = async () => {
        const checkedItems = this.props.data.filter((item) => item.isChecked)
        const userDocumentIds = checkedItems.map((item) => item.id)
        try {
            this.props.dispatch({isLoading: true})
            const dataUrls = await documentService.mergeDocuments({
                userDocumentIds
            })
            if (dataUrls?.length) {
                dataUrls.forEach((dataUrl) => {
                    const url = new URL(dataUrl)
                    const link = document.createElement("a")
                    link.setAttribute("href", url.href)
                    link.setAttribute("target", "_blank")
                    link.setAttribute("rel", "noopener")
                    link.style.visibility = "hidden"

                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                })
            }
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    getParams = () => {
        const {page, orderField, userId} = this.props
        let filterData: any = this.state.filterData
        let ownerUserId = this.props.user?.id || userId
        let ownerUserProfileId = this.props.profileId || get(this.props, "match.params.profileId")
        if (ownerUserId) {
            ownerUserProfileId = undefined
        }
        let departmentSubunitIds = undefined
        if (filterData?.departmentSubunitId) {
            departmentSubunitIds = [filterData.departmentSubunitId]
            filterData = omit(filterData, ["departmentSubunitId"])
        }
        const params: any = {
            range: {
                page,
                pageSize: this.state.defaultPageSize
            },
            filter: {
                ...filterData,
                ownerUserProfileId: +ownerUserProfileId,
                ownerUserId,
                departmentSubunitIds
            }
        }

        if (!isEmpty(orderField)) {
            params.sort = {
                orderBy: orderField.field,
                orderDir: orderField.order
            }
        }

        return params
    }

    getData = async () => {
        try {
            this.props.dispatch({isLoading: true})
            const params = this.getParams()
            const {data, total} = await documentService.getAllUserDocuments(params)
            const newData = data.map((item: any) => {
                item.id = item.userDocumentId
                item.statusHtml = this.renderStatusHtml(item)
                return item
            })
            this.props.dispatch({data: newData, total})
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    renderStatusHtml = ({status, statusUpdatedBy, statusUpdatedAt}) => {
        return (
            <div className={styles.statusWrap}>
                <div className={styles.status}>
                    {status === "approved" ? (
                        <Icon icon="CHECKMARK_CIRCLE" color="#18A957" />
                    ) : status === "rejected" ? (
                        <Icon icon="CROSS_CIRCLE_FILL" color="#DF1642" />
                    ) : (
                        <Icon icon="CHECKMARK_CIRCLE_1" color="#E6E6E6" />
                    )}
                </div>
                {status !== "pending" && (
                    <div className={styles.statusInfo}>
                        <p className={styles.statusInfo__text}>{statusUpdatedBy}</p>
                        <p className={styles.statusInfo__text}>
                            {moment(statusUpdatedAt).format("MM/DD/YYYY hh:mm A")}
                        </p>
                    </div>
                )}
            </div>
        )
    }

    onClickApply = (filterData) => {
        this.setState({filterData}, () => {
            this.getData()
        })
    }

    onClickRowItem = (data) => {
        const {history, backUrl, user, studentId, backUrlFromPage} = this.props
        if (studentId) {
            const documentDetailsUrl = stringifyUrl(
                {
                    url: `/documents/detail`,
                    query: {
                        id: data.id,
                        backUrl
                    }
                },
                {skipNull: true}
            )
            history.push(documentDetailsUrl)
            return
        }
        if (user) {
            const documentDetailsUrl = stringifyUrl(
                {
                    url: `/documents/detail`,
                    query: {
                        id: data.id,
                        backUrl: backUrl,
                        userProfileId: user.profileId,
                        ...(backUrlFromPage && {backUrlFromPage})
                    }
                },
                {skipNull: true}
            )
            history.push(documentDetailsUrl)
        } else {
            history.push(`/documents/detail?id=${data.id}&backUrl=${backUrl}`)
        }
    }

    onClickEdit = (editedItem) => {
        this.onClickRowItem(editedItem)
    }

    onChangeRowPerPage = (rowPerPage) => {
        this.setState({defaultPageSize: rowPerPage}, () => {})
        this.props.onChangeRowPerPage(rowPerPage)
    }

    onClickAddBtn = () => {
        const {history, backUrl, user, studentId, profileId, backUrlFromPage} = this.props
        const documentDetailObject = {
            url: `/documents/detail`,
            query: {
                backUrl: backUrl,
                userProfileId: null,
                backUrlFromPage
            }
        }
        if (studentId) {
            documentDetailObject.query.userProfileId = profileId
        }
        if (user) {
            documentDetailObject.query.userProfileId = user.profileId
        }
        history.push(stringifyUrl(documentDetailObject, {skipNull: true}))
    }

    renderAddButton = () => {
        const {t} = this.props
        return (
            <div className={styles.createBtnWrap}>
                <BaseButton
                    title={t("common:action.add").toUpperCase()}
                    icon={<Icon className={styles.plusIcon} icon="PLUS" color="#FFF" />}
                    disabled={false}
                    onClick={this.onClickAddBtn}
                />
            </div>
        )
    }

    renderAddButtonWithPermission = () => {
        const {isUserDocumentTab, departmentId, model} = this.props
        if (isUserDocumentTab) {
            if (model.isStaffOrAdmin()) {
                return this.renderAddButton()
            }
            return (
                <PermissionsRequired
                    permissions={{
                        student: [Permissions.Student.Documents.Add],
                        others: [Permissions.Others.Documents.Add]
                    }}>
                    {this.renderAddButton()}
                </PermissionsRequired>
            )
        }
        return (
            <PermissionsRequired permissions={null} permissionType={PermissionType.Add} departmentId={departmentId}>
                {this.renderAddButton()}
            </PermissionsRequired>
        )
    }

    render() {
        const {
            isLoading,
            data,
            tableHeaderActions,
            orderField,
            page,
            total,
            pageSize,
            columns,
            fields,
            allFields,
            menuActions,
            isHideMenuActions,
            isShowTableHeaderAction
        } = this.props

        return (
            <div className={styles.wrap}>
                <TableFilter onClickApply={this.onClickApply} enabledSubunitIds={this.state.enabledSubunitIds} />
                {this.renderAddButtonWithPermission()}
                <KlassappTableHeader
                    isShowAction={isShowTableHeaderAction}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={this.state.defaultPageSize}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    data={data}
                    isLoading={isLoading}
                    menuActions={isHideMenuActions ? [] : menuActions}
                    fields={fields}
                    allFields={allFields}
                    orderField={orderField}
                    isShowCheckedColumn
                    onClickRowItem={this.onClickRowItem}
                    onChangeFields={this.props.onChangeFields}
                    onUpdateRowData={this.props.onUpdateRowData}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onClickSortColumn={this.props.onClickSortColumn}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onChangeAllFields={this.props.onChangeAllFields}
                />
            </div>
        )
    }
}

export default KlassappTableHOC(withTranslation(["admission", "common"])(Documents))
