import {get} from "lodash"
import BaseService from "./Base"
import {EditableWorkflow, Workflow, WorkflowTriggerElement} from "types/workflow"
import {TriggeredWorkflow} from "types/triggered-workflow"
import {DeepPartial} from "react-hook-form"

class WorkflowService extends BaseService {
    getAllWorkflows = async (params = {}): Promise<{total: number; data: Workflow[]}> => {
        const response = await this.post("/v1/workflows/list", params)
        return get(response, "data")
    }

    getCompletedTaskWorkflows = async (params = {}): Promise<{total: number; data: Workflow[]}> => {
        const response = await this.get("/v1/workflows/completed-task", params)
        return get(response, "data")
    }

    getAutomatedWorkflowsByTrigger = async (
        element: WorkflowTriggerElement,
        params = {}
    ): Promise<{total: number; data: Workflow[]}> => {
        const response = await this.get(`/v1/workflows/trigger/${element}`, params)
        return get(response, "data")
    }

    getWorkflow = async (id: number, params = {}): Promise<Workflow> => {
        const response = await this.get(`/v1/workflows/${id}`, params)
        return get(response, "data.data")
    }

    createWorkflow = async (data: EditableWorkflow): Promise<Workflow> => {
        const response = await this.post(`/v1/workflows/create`, data)
        return get(response, "data.data")
    }

    updateWorkflow = async (data: DeepPartial<EditableWorkflow>): Promise<Workflow> => {
        const response = await this.post(`/v1/workflows/edit`, data)
        return get(response, "data.data")
    }

    deleteWorkflow = async (id: number): Promise<void> => {
        const response = await this.post(`/v1/workflows/delete`, {ids: [id]})
        return get(response, "data")
    }

    deleteMulti = async (ids: number[]): Promise<void> => {
        const response = await this.post(`/v1/workflows/delete`, {ids})
        return get(response, "data")
    }

    duplicateMulti = async (ids: Workflow["id"][]): Promise<void> => {
        const response = await this.post(`/v1/workflows/duplicate-multi`, {ids})
        return get(response, "data")
    }

    manuallyTriggerAutomatedWorkflow = async (id: number, triggerElement: WorkflowTriggerElement): Promise<void> => {
        const response = await this.post(`/v1/workflows/trigger`, {automatedWorkflowId: id, triggerElement})
        return get(response, "data")
    }

    getTriggeredWorkflows = async (params = {}): Promise<{total: number; data: TriggeredWorkflow[]}> => {
        const response = await this.post("/v1/triggered-workflows/list", params)
        return get(response, "data")
    }

    toggleTriggeredWorkflow = async (triggeredWorkflowId: number): Promise<void> => {
        const response = await this.post("/v1/triggered-workflows/toggle", {triggeredWorkflowId})
        return get(response, "data")
    }

    stopTriggeredWorkflow = async (triggeredWorkflowId: number): Promise<void> => {
        const response = await this.post("/v1/triggered-workflows/stop", {triggeredWorkflowId})
        return get(response, "data")
    }
}

export default WorkflowService
