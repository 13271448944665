/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useMemo, useState} from "react"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import styles from "./ListApplications.module.css"
import {useHistory} from "react-router-dom"
import {careerService} from "services"
import {formatDate, handleError} from "helpers"
import {KlassappTableHOC} from "HOC"
import {Col, Popover, Row} from "antd"
import cx from "classnames"
import {ReactComponent as LikeIcon} from "./like.svg"
import {ReactComponent as DislikeIcon} from "./dislike.svg"
import {ReactComponent as CommentIcon} from "./comment.svg"
import debounce from "debounce-promise"
import {FormLabel} from "components/Form"
import {KlassDropAsyncPaginate, KlassDropdown} from "components/Select"
import {useModel} from "hooks"
import {ActiveApplicationContext} from "context/ActiveApplicationContext"
import {CareerServices} from "types/careerServices"

const JOB_APPLICATION_STATUSES = {
    applied: "Applied",
    "in-review": "In Review",
    rejected: "Rejected",
    "job-closed": "Job Closed",
    hired: "Hired",
    "job-offer": "Job Offer",
    interview: "Interview"
}

export function Applications(props) {
    const history = useHistory()
    const {jobId, profileId, employerProfileId, context} = props
    const {data, page, total, pageSize, columns, fields, allFields, isLoading, dispatch, getCurrentData} = props

    const [filter, setFilter] = useState({companies: [], statuses: []})
    const [search, setSearch] = useState("")
    const model = useModel()
    const isStudent = model.isStudent()
    const {changeActiveApplication, statusChange} = useContext(ActiveApplicationContext)

    useEffect(() => {
        props.dispatch({isClassComponent: false})
        props.dispatchFunc([
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
    }, [])

    useEffect(() => {
        getData()
    }, [search, page, pageSize])

    useEffect(() => {
        if (!statusChange) return
        props.dispatch({
            data: getCurrentData().map((item) =>
                item.id !== statusChange.applicationId
                    ? item
                    : {
                          ...item,
                          status: statusChange.status,
                          statusHtml: <ApplicationStatus item={{...item, status: statusChange.status}} />
                      }
            )
        })
    }, [statusChange])

    async function getData() {
        try {
            dispatch({isLoading: true})
            let filterParams: any = {}
            if (jobId) {
                filterParams.jobId = jobId
            }
            if (profileId) {
                filterParams.profileId = profileId
            }
            if (search) {
                filterParams.search = search
            }
            if (filter.companies && filter.companies.length > 0) {
                const companyIds = filter.companies.map((x) => x.id)
                filterParams.companyIds = companyIds
            }
            if (filter.statuses && filter.statuses.length > 0) {
                const statuses = filter.statuses.map((x) => x.value)
                filterParams.statuses = statuses
            }
            if (employerProfileId) {
                filterParams.employerProfileId = employerProfileId
            }
            const {data, total} = await careerService.getListApplications({
                range: {page, pageSize},
                filter: filterParams
            })
            const applications = data.map((item) => {
                return {
                    ...item,
                    studentId: item.student.id,
                    studentName: `${item.student.firstName} ${item.student.lastName}`,
                    jobTitle: item.job.title,
                    companyName: item.employer.companyName,
                    appliedDate: formatDate(item.appliedAt, model.getUserDateFormat()),
                    statusHtml: <ApplicationStatus item={item} />,
                    reactionHtml: (
                        <div className={styles.flexRow}>
                            <div className={styles.flexRowMinGap}>
                                <LikeIcon className={styles.icon} width={18} height={18} />
                                <div className={styles.count}>({item.totalLikes})</div>
                            </div>
                            <div className={styles.flexRowMinGap}>
                                <DislikeIcon className={styles.icon} width={18} height={18} />
                                <div className={styles.count}>({item.totalDislikes})</div>
                            </div>
                        </div>
                    )
                }
            })

            dispatch({data: applications, total})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const getEmployers = async (search = "", loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const params: any = {
                range: {
                    pageSize,
                    page
                }
            }
            if (search) {
                params.filter.search = search
            }
            const {data, total} = await careerService.getListEmployers(params)
            const employers = data.map((item) => {
                const _item: any = {...item}
                _item.name = item.companyName
                return _item
            })
            return {
                options: employers,
                hasMore: loadedOptions.length < total
            }
        } catch (e) {
            return {options: [], hasMore: false}
        }
    }

    function getColumns() {
        return [
            {
                title: "Student ID",
                field: "studentId",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: "Student Name",
                field: "studentName",
                headerStyle: {minWidth: "100px"}
            },
            {
                title: "Job",
                field: "jobTitle",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Employer",
                field: "companyName",
                headerStyle: {minWidth: "100px"}
            },
            {
                title: "Date",
                field: "appliedDate",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: "Status",
                field: "statusHtml",
                headerStyle: {minWidth: "150px"}
            },
            isStudent && {
                title: "Reaction",
                field: "reactionHtml",
                headerStyle: {minWidth: "80px"}
            }
        ]
    }

    function getFields() {
        const arr = ["Student ID", "Student Name", "Job", "Employer", "Date", "Status"]
        if (!isStudent) arr.push("Reaction")
        return arr
    }

    const onClickRowItem = (row) => {
        if (context === "Job-Board") {
            changeActiveApplication(row)
        } else {
            navigate_to(row.id)
        }
    }

    let statuses: any = []
    Object.entries(JOB_APPLICATION_STATUSES).forEach(([key, value]) => {
        statuses.push({
            value: key,
            label: value
        })
    })

    const navigate_to = (_id: string) => {
        history.push(`/career-services/applications/${_id}`)
    }

    const onClickClearFilter = () => {
        setFilter({companies: [], statuses: []})
    }

    const onChangeFilter = (key, value) => {
        const newFilter = {...filter}
        newFilter[key] = value
        setFilter(newFilter)
    }

    const onSearchInput = (search) => {
        setSearch(search)
    }

    const onApplyFilter = () => {
        getData()
    }

    const debounceEmployer = debounce(getEmployers, 300)

    const isEmployer = useMemo(() => {
        return !!employerProfileId
    }, [employerProfileId])

    return (
        <div>
            <BaseNewFilter
                filter={filter}
                onClick={onApplyFilter}
                onClickClear={onClickClearFilter}
                onSearchInput={onSearchInput}>
                <Row gutter={[12, 12]}>
                    {!jobId && !isEmployer && (
                        <Col span={12}>
                            <div>
                                <FormLabel label="Company" />
                                <KlassDropAsyncPaginate
                                    value={filter.companies}
                                    loadOptions={debounceEmployer}
                                    onChange={(newValue) => onChangeFilter("companies", newValue)}
                                    placeholder="Company"
                                    isMulti
                                />
                            </div>
                        </Col>
                    )}
                    <Col span={12}>
                        <div>
                            <FormLabel label="Status" />
                            <KlassDropdown
                                value={filter.statuses}
                                placeholder="Select"
                                onChange={(option: any) => onChangeFilter("statuses", option)}
                                valueKey="value"
                                labelKey="label"
                                options={statuses}
                                hasEmptyOption
                                isMulti
                            />
                        </div>
                    </Col>
                </Row>
            </BaseNewFilter>
            <Row className={styles.marginTop}>
                <Col span={24}>
                    <KlassappTableHeader
                        page={page}
                        total={total}
                        defaultPageSize={pageSize}
                        onChangePage={props.onChangePage}
                        onChangeRowPerPage={props.onChangeRowPerPage}
                    />
                    <KlassappTable
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        fields={fields}
                        allFields={allFields}
                        onChangeFields={props.onChangeFields}
                        onUpdateRowData={props.onUpdateRowData}
                        onUpdateTableData={props.onUpdateTableData}
                        onClickRowItem={onClickRowItem}
                    />
                </Col>
            </Row>
        </div>
    )
}

type StatusProps = {
    item: CareerServices.Application
}

function ApplicationStatus(props: StatusProps) {
    const {item} = props

    const commentContent = (
        <div className={styles.commentContainer}>
            <div className={styles.commentTitle}>Company Comment</div>
            <div className={styles.comment}>
                {item.employerNotes.length > 0 ? item.employerNotes[item.employerNotes.length - 1].content : ""}
            </div>
        </div>
    )

    return (
        <div className={styles.flexRow}>
            <div className={cx(styles.status, styles[`status-${item.status}`])}>
                {JOB_APPLICATION_STATUSES[item.status]}
            </div>
            {item.employerNotes.length > 0 && (
                <Popover placement="bottomLeft" content={commentContent}>
                    <CommentIcon className={styles.icon} width={18} height={18} />
                </Popover>
            )}
        </div>
    )
}

export const ListApplications = KlassappTableHOC(Applications)
