export namespace AutoDialer {
    export type Student = {
        profileId: number
        fullName: string
        email: string
        phone: string
    }

    export enum DialerStatus {
        NotStart = "not-start",
        Initial = "initial",
        Ringing = "ringing",
        Connected = "connected",
        Hold = "hold",
        Unhold = "unhold",
        Ended = "ended"
    }

    export type DialerStudent = {
        studentProfileId?: number
        status?: DialerStatus
        ringingAt?: string
        connectedAt?: string
        holdAt?: string
        unholdeAt?: string
        endedAt?: string
    }

    export type DialerScreen = {
        isAuto: boolean
        status: DialerStatus
        isPaused: boolean
        dialerStudent?: DialerStudent
    }

    export type ZoomAuthorization = {
        redirect: boolean
        redirectUri?: string
    }

    export type ZoomSocketPayload = {
        profileId: number
        studentProfileId: number
        status: string
        ringingAt?: string
        connectedAt?: string
        holdAt?: string
        unholdeAt?: string
        endedAt?: string
    }

    export type AutoDialerState = {
        profileId: number
        isAutoDialerRunning: boolean
    }

    export type CallLog = {
        profileId: number
        enrollmentId: string
        fullName: string
        phone: string
        connectedAt?: string
        endedAt?: string
    }
}
