import {get} from "lodash"
import BaseService from "./Base"

export default class MissedAttendanceSettingService extends BaseService {
    async getAll(params = {}) {
        const response = await this.post("/v1/settings/missed-attendance/list", params)
        return get(response, "data", [])
    }

    async create(setting) {
        const response = await this.post("/v1/settings/missed-attendance/create", [setting])
        return get(response, "data.data", {})
    }

    async update(roleId: number, data, params = {}) {
        data.roleId = roleId
        const response = await this.post(`/v1/settings/missed-attendance/edit`, data, params)
        return get(response, "data", {})
    }

    async delete(roleIds: number[]) {
        const response = await this.post(`/v1/settings/missed-attendance/delete`, {roleIds})
        return get(response, "data", {})
    }
}
