import {ArrowDownOutlined} from "@ant-design/icons"
import DefaultLogo from "assets/images/png/EdularLogoRectangle.png"
import cx from "classnames"
import {BasePopup} from "components"
import {convertImgToBase64, handleError} from "helpers"
import {generatePdfFromHtml} from "helpers/pdf"
import {useModel} from "hooks"
import moment from "moment"
import {QRCodeSVG} from "qrcode.react"
import {useEffect, useRef, useState} from "react"
import {useTranslation} from "react-i18next"
import {Branding} from "types/branding"
import styles from "./SiteSupervisorQrCodeDetail.module.css"
import qs from "query-string"

const createNonCacheableImageUrl = (url: string) =>
    qs.stringifyUrl({
        url,
        query: {t: Date.now()}
    })

type Props = {
    isVisible: boolean
    qrData: any
    externshipCoordinatorsList: string[]
    onClose: () => void
}

const SiteSupervisorQrCodeDetail = ({isVisible, onClose, qrData, externshipCoordinatorsList}: Props) => {
    const {t} = useTranslation(["settings"])

    const [logoBase64, setLogoBase64] = useState("")
    const pdfRef = useRef<HTMLDivElement>()
    const model = useModel()

    const collegeName = model.clientSetting?.name
    const logoUrl = model.clientSetting?.branding?.[Branding.BrandingType.OfficialLogoSquare]

    useEffect(() => {
        if (logoUrl) {
            const nonCacheableLogoUrl = createNonCacheableImageUrl(logoUrl)

            convertImgToBase64(nonCacheableLogoUrl).then((base64Img) => {
                setLogoBase64(base64Img)
            })
        }
    }, [logoUrl])

    const handleDownloadAsPdf = async () => {
        try {
            const pdfElement = pdfRef.current
            const pdf = await generatePdfFromHtml(pdfElement)
            pdf.save(`Document-${moment().format("YYYY-MM-DD")}.pdf`)
        } catch (error) {
            handleError(error)
        }
    }
    return (
        <BasePopup isShow={isVisible} onClose={onClose} width="600px" isShowLeftSide leftIcon="VIEW_DETAILS">
            <div className={styles.modalRoot}>
                <div className={styles.disflex}>
                    <p className={styles.modalTitle}>QR Code Details</p>
                    <div className={styles.baseAddCircleBtn}>
                        <ArrowDownOutlined
                            onClick={handleDownloadAsPdf}
                            className={styles.downloadIcon}
                            style={{
                                color: "#FFFFFF"
                            }}
                        />
                    </div>
                </div>
                <div
                    ref={pdfRef}
                    style={{
                        width: "100%"
                    }}>
                    <img alt="logo" src={logoBase64} className={styles.logo} />
                    <p className={styles.modalHeading}>Externship QR for {collegeName}</p>
                    <div>
                        <div>
                            <div className={cx(styles.disflex, styles.mt3)}>
                                <span className={styles.qrModalLabel}>
                                    {t("settings.siteSupervisors.schoolName")} :
                                </span>
                                <span className={styles.qrModalText}>{collegeName}</span>
                            </div>
                            <div className={cx(styles.disflex, styles.mt2)}>
                                <span className={styles.qrModalLabel}>{t("settings.siteSupervisors.siteName")} :</span>
                                <span className={styles.qrModalText}>{qrData?.externshipSiteName}</span>
                            </div>
                            <div className={cx(styles.disflex, styles.mt2)}>
                                <span className={styles.qrModalLabel}>
                                    {t("settings.siteSupervisors.siteSupervisor")} :
                                </span>
                                <span className={styles.qrModalText}>{qrData?.userName}</span>
                            </div>
                            <div className={cx(styles.disflex, styles.mt2)}>
                                <span className={styles.qrModalLabel}>
                                    {t("settings.siteSupervisors.siteSupervisorCoordinator")} :
                                </span>
                                <span className={styles.qrModalText}>{externshipCoordinatorsList.join(", ")}</span>
                            </div>
                        </div>
                        {qrData?.qrCode && (
                            <div className={cx(styles.centerQR, styles.mt3)}>
                                <QRCodeSVG className={styles.qrModalQRCode} value={qrData.qrCode} />
                                <p className={styles.footer}>
                                    <span className={styles.mt3}>Powered By Edular</span>
                                    <img src={DefaultLogo} className={styles.defaultLogo} alt="default logo" />
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </BasePopup>
    )
}

export default SiteSupervisorQrCodeDetail
