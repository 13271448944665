/* eslint-disable react-hooks/exhaustive-deps */
import React, {useReducer, useEffect} from "react"
import {Row, Col} from "antd"
import {taskService} from "services"
import {handleError} from "helpers"
import {useIsMounted} from "hooks"
import {CardItem} from "../../Tasks"
import {ResponsesTable, RejectedTasks} from "./parts"
import styles from "./Responses.module.css"
import {RejectedTaskUsers} from "./parts/RejectedTaskUsers"
import {keyBy} from "lodash"

function reducer(state, action) {
    return {...state, ...action}
}

export function Responses(props) {
    const isMounted = useIsMounted()
    const initialState = {
        statusInfos: [
            {id: "all", title: "all", status: "all", count: 0},
            {id: "todo", title: "todo", status: "todo", count: 0},
            {id: "started", title: "started", status: "started", count: 0},
            {id: "overdue", title: "overdue", status: "overdue", count: 0},
            {id: "awaitingApproval", title: "awaiting_approval", status: "awaiting_approval", count: 0},
            {id: "done", title: "done", status: "done", count: 0}
        ],
        filter: {status: null}
    }
    const [{statusInfos, filter}, dispatch] = useReducer(reducer, initialState)
    const {
        taskId,
        taskData,
        type,
        userId,
        isReget,
        openedSubTaskId,
        onChangeSignerUsers,
        onChangeOpenedSubTaskId,
        canAccessTaskOther
    } = props

    useEffect(() => {
        getSubTasksDashBoard()
    }, [])

    const getSubTasksDashBoard = async () => {
        try {
            const data = await taskService.getSubTasksDashboard({taskId: +taskId})
            const newStatusInfos = statusInfos.map((info) => {
                info.count = data[info.id]
                return info
            })
            if (isMounted) {
                dispatch({statusInfos: newStatusInfos})
            }
        } catch (error) {
            handleError(error)
        }
    }

    const onChangeStatusFilter = (status) => {
        const statusInfosKeyByValue = keyBy(statusInfos, "status")
        const newFilter: any = {...filter}
        newFilter.status = statusInfosKeyByValue[status]
        dispatch({filter: newFilter})
    }

    return (
        <div className={styles.statusInfoWrap}>
            <Row gutter={[12, 12]}>
                {statusInfos.map((statusInfo) => (
                    <Col key={statusInfo.id} span={4}>
                        <CardItem
                            item={statusInfo}
                            activeStatus={filter.status}
                            onChangeFilter={onChangeStatusFilter}
                        />
                    </Col>
                ))}
            </Row>
            <RejectedTasks taskData={taskData} />
            <RejectedTaskUsers taskData={taskData} />
            <ResponsesTable
                taskData={taskData}
                filter={filter}
                userId={userId}
                type={type}
                isReget={isReget}
                openedSubTaskId={openedSubTaskId}
                onChangeSignerUsers={onChangeSignerUsers}
                onChangeOpenedSubTaskId={onChangeOpenedSubTaskId}
                canAccessTaskOther={canAccessTaskOther}
            />
        </div>
    )
}
