/* eslint-disable react-hooks/exhaustive-deps */
import debounce from "debounce-promise"
import React from "react"
import {useTranslation} from "react-i18next"
import {termsService} from "services"
import {KlassDropAsyncPaginate, KlassDropdownAsyncProps} from "../Select"
import {TermDetails} from "types/terms"
import moment from "moment/moment"
import styles from "./TermSelect.module.css"
import {useModel} from "hooks"
import {toDateOnly} from "sections/academics/instructional/common/utils"

type Props = Partial<KlassDropdownAsyncProps> & {
    majorId?: number
    majorVersionId?: number
    campusIds?: number[]
}

const TermSelect: React.FC<Props> = ({majorId, majorVersionId, campusIds, ...props}) => {
    const {t} = useTranslation(["common"])
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()

    const search = React.useCallback(
        debounce(async (search: string = "", loadedOptions) => {
            if (majorId) {
                const {data} = await termsService.getTermByMajor(majorId, {
                    search,
                    isActive: true,
                    majorVersionId,
                    campusIds: campusIds?.length ? campusIds.join(",") : undefined
                })
                return {data, hasMore: false}
            }
            const {data, total} = await termsService.getAllTerms({
                fields: ["id", "name", "code", "start_date", "end_date"],
                text: search,
                campusId: campusIds?.length ? campusIds.join(",") : undefined,
                orderBy: "start_date",
                orderDir: "DESC",
                limit: 20,
                offset: loadedOptions.length
            })
            return {
                options: data,
                hasMore: loadedOptions.length < total
            }
        }, 500),
        [majorId, majorVersionId, campusIds]
    )

    return (
        <KlassDropAsyncPaginate
            {...props}
            key={`${majorId}-${majorVersionId}-${JSON.stringify(campusIds)}`}
            placeholder={props.placeholder || t("selectField.placeholder")}
            loadOptions={search}
            valueKey="id"
            labelKey="name"
            getOptionLabel={(option: TermDetails) => (
                <span>
                    {option.name}
                    {!!option.code && <span className={styles.termCode}>({option.code})</span>}
                    {!!option.start_date && (
                        <span className={styles.termDate}>
                            ({moment(toDateOnly(option.start_date)).format(userDateFormat)})
                        </span>
                    )}
                </span>
            )}
        />
    )
}

export default TermSelect
