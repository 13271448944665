import {get} from "lodash"
import BaseService from "./Base"

class TermsCourses extends BaseService {
    async getAll(params = {}) {
        const response = await this.post("/v2/terms/courses/list", params)
        return get(response, "data", [])
    }

    async exportAsCsv(data) {
        const response = await this.post(`/v2/terms/courses/export-as-csv`, data, {
            responseType: "blob"
        })
        return get(response, "data", null)
    }
}

export default TermsCourses
