import React from "react"
import {Row, Col} from "antd"
import cx from "classnames"
import {AcademicInstructional} from "types/academicInstructional"
import styles from "./LessonInfo.module.css"

type Props = {
    lessonDetail: AcademicInstructional.LessonDetail
}

export function LessonInfo(props: Props) {
    const {lessonDetail} = props

    return (
        <div>
            <div className={styles.lessonInfoWrap}>
                <div className={styles.lessonHeader}>
                    <span className={styles.lessonName}>{lessonDetail.name}</span>
                    <span className={styles.lessonCode}>{lessonDetail.code}</span>
                </div>
                <div className={styles.lessonInfoBody}>
                    <Row>
                        <Col span={5}>
                            <span className={styles.lessonInfo__title}>Type</span>
                        </Col>
                        <Col span={10}>
                            <span className={cx(styles.lessonInfo__value, styles.capitalize)}>{lessonDetail.type}</span>
                        </Col>
                        <Col span={4}>
                            <span className={styles.lessonInfo__title}>DOT</span>
                        </Col>
                        <Col span={3}>
                            <span className={styles.lessonInfo__value}>12</span>
                        </Col>
                        <Col span={5}>
                            <span className={styles.lessonInfo__title}>Instructor</span>
                        </Col>
                        <Col span={12}>
                            <span className={styles.lessonInfo__value}>
                                {lessonDetail.instructors.map((item, index) => (
                                    <div key={index}>{item.name}</div>
                                ))}
                            </span>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}
