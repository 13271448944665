import React, {useState} from "react"
import {Radio} from "antd"
import cx from "classnames"
import {useTranslation} from "react-i18next"
import {BasePopup} from "components/popup"
import {RescheduleSelection} from "types/calendar"
import {BaseButton} from "components/buttons"
import {ClassSelection, CalendarSelection} from "./parts"
import styles from "./ReschedulePopup.module.css"

export function ReschedulePopup(props) {
    const [selection, setSelection] = useState(RescheduleSelection.Class)
    const {isShow, onClose} = props
    const {t} = useTranslation(["calendar", "common"])

    const renderCourseInfo = () => {
        return (
            <div className={styles.courseWrap}>
                <div className={styles.courseInfo}>
                    <span className={styles.courseName}>{t("calendar.cancelled.courseName")}</span>
                    <span className={styles.courseCode}>CD-12315</span>
                </div>
                <div className={styles.otherInfo}>
                    <span className={styles.otherInfoTitle}>{t("calendar.cancelled.campus")}:</span>
                    <span className={styles.otherInfoValue}>New York</span>
                </div>
                <div className={styles.otherInfo}>
                    <span className={styles.otherInfoTitle}>{t("calendar.cancelled.instructor")}:</span>
                    <span className={styles.otherInfoValue}>J Reynolds</span>
                </div>
            </div>
        )
    }

    // const renderStudentInfo = () => {
    //     return (
    //         <div className={styles.studentInfoWrap}>
    //             <div className={styles.studentInfoImageWrap}>
    //                 <img
    //                     src="/image/DefaultAvatar.png"
    //                     className={styles.studentInfoImage}
    //                     alt=""
    //                 />
    //             </div>
    //             <div className={styles.studentInfoBody}>
    //                 <p className={styles.studentName}>Linda Stacey</p>
    //                 <div className={styles.studentOtherInfo}>
    //                     <Row gutter={[32, 24]}>
    //                         <Col span={12}>
    //                             <div className={styles.studentOtherInfoItem}>
    //                                 <span className={styles.studentOtherInfoTitle}>User ID:</span>
    //                                 <span className={cx(styles.studentOtherInfoValue, styles.studentId)}>ID12521</span>
    //                             </div>
    //                             <div className={styles.studentOtherInfoItem}>
    //                                 <span className={styles.studentOtherInfoTitle}>Enrollment ID:</span>
    //                                 <span className={cx(styles.studentOtherInfoValue, styles.studentId)}>ID12521</span>
    //                             </div>
    //                         </Col>
    //                         <Col span={12}>
    //                             <div className={styles.studentOtherInfoItem}>
    //                                 <span className={styles.studentOtherInfoTitle}>Course:</span>
    //                                 <span className={styles.studentOtherInfoValue}>Psy101</span>
    //                             </div>
    //                             <div className={styles.studentOtherInfoItem}>
    //                                 <span className={styles.studentOtherInfoTitle}>Instructor</span>
    //                                 <span className={styles.studentOtherInfoValue}>Psy101</span>
    //                             </div>
    //                         </Col>
    //                     </Row>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="RESCHEDULE" leftIconColor="#fff" width="70vw">
            <div className={styles.wrap}>
                <p className={styles.title}>{t("calendar.cancelled.reschedule")}</p>
                {renderCourseInfo()}
                <div className={styles.timeInfo}>02/12 /2020, 8:00 - 9:30 AM</div>
                <div className={styles.selectionWrap}>
                    <Radio.Group
                        value={selection}
                        onChange={(event) => setSelection(event.target.value)}
                        className={styles.radioBtnWrap}>
                        <Radio
                            value={RescheduleSelection.Class}
                            className={cx(styles.radioBtnItem, {
                                [styles.radioBtn__active]: selection === RescheduleSelection.Class
                            })}>
                            {t("calendar.cancelled.selectClass")}
                        </Radio>
                        <Radio
                            value={RescheduleSelection.Calendar}
                            className={cx(styles.radioBtnItem, {
                                [styles.radioBtn__active]: selection === RescheduleSelection.Calendar
                            })}>
                            {t("calendar.cancelled.selectFromCalendar")}
                        </Radio>
                    </Radio.Group>
                </div>
                {selection === RescheduleSelection.Class ? <ClassSelection /> : <CalendarSelection />}
                <div className={styles.actionWrap}>
                    <BaseButton title={t("common:action.save")} />
                </div>
            </div>
        </BasePopup>
    )
}
