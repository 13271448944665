import {useModel} from "hooks"
import styles from "./ProjectedEndDateCard.module.css"
import {ReactComponent as GradIcon} from "./grad.svg"
import moment from "moment"

type Props = {
    projectedEndDate?: string
    contractEndDate?: string
}

export function ProjectedEndDateCard(props: Props) {
    const {projectedEndDate, contractEndDate} = props
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()

    return (
        <div className={styles.container}>
            <GradIcon className={styles.cardIcon} />
            <div className={styles.subContainer}>
                <span className={styles.title}>Projected grad date</span>
                <span className={styles.value}>
                    {projectedEndDate ? moment(projectedEndDate).format(userDateFormat) : "--"}
                </span>
            </div>
            {projectedEndDate && (
                <div className={styles.subContainer}>
                    <span className={styles.distant}>{moment(projectedEndDate).diff(moment(), "days")} days</span>
                    <span className={styles.description}>till graduation</span>
                </div>
            )}
            <div className={styles.subContainer}>
                <span className={styles.contractTitle}>Contract grad date</span>
                <span className={styles.value}>
                    {contractEndDate ? moment(contractEndDate).format(userDateFormat) : "--"}
                </span>
            </div>
        </div>
    )
}
