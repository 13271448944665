import React, {FC, createContext, ReactNode} from "react"
import {RootStore, rootStore} from "."

export const StoreContext = createContext<RootStore>({} as RootStore)

export type StoreProviderProps = {
    children: ReactNode
}

export const StoreProvider: FC<StoreProviderProps> = ({children}) => {
    return <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>
}
