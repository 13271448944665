import React from "react"
import {message} from "antd"
import {v4 as uuidV4} from "uuid"
import {ToastIcon, ToastInfo} from "components/Toast"

type ToastConfig = {
    duration?: number
    top?: number
}

export function toastError(title = "", description = "", config: ToastConfig = {}) {
    const key = uuidV4()
    message.error({
        key,
        className: "custom-message-wrap error",
        content: <ToastInfo id={key} title={title} description={description} onClose={() => message.destroy(key)} />,
        icon: <ToastIcon type="error" />,
        ...config
    })
}

export function toastSuccess(title = "", description = "", config: ToastConfig = {}) {
    const key = uuidV4()
    message.success({
        key,
        className: "custom-message-wrap success",
        content: <ToastInfo id={key} title={title} description={description} onClose={() => message.destroy(key)} />,
        icon: <ToastIcon type="success" />,
        ...config
    })
}

export function toastWarning(title = "", description = "", config: ToastConfig = {}) {
    const key = uuidV4()
    message.warning({
        key,
        className: "custom-message-wrap warning",
        content: <ToastInfo id={key} title={title} description={description} onClose={() => message.destroy(key)} />,
        icon: <ToastIcon type="warning" />,
        ...config
    })
}

export function toastInfo(title = "", description = "", config: ToastConfig = {}) {
    const key = uuidV4()
    message.info({
        key,
        className: "custom-message-wrap info",
        content: <ToastInfo id={key} title={title} description={description} onClose={() => message.destroy(key)} />,
        icon: <ToastIcon type="info" />,
        ...config
    })
}
