import moment from "moment"
import {RRule, rrulestr} from "rrule"
import {keyBy, keys, range, isEmpty, isArray, get, size} from "lodash"
import {
    OPTIONS_WEEK_DAYS,
    OPTIONS_MONTH_DAYS,
    OPTIONS_MONTHLY_FREQUENCY,
    OPTIONS_DAYS_LONG,
    OPTIONS_FREQUENCY_TYPE
} from "sections/calendar/parts/common/constants"
import {CalendarEventType, CalendarRecurringType, RecurringEnd} from "types/calendar"
import {CourseEvent} from "types/courseEvent"
import {Course} from "types/courses"
import {toDateOnly} from "./dateHelper"

const weekDaysKeyByShort = keyBy(OPTIONS_WEEK_DAYS, "short")
const daysLongKeyByShort = keyBy(OPTIONS_DAYS_LONG, "short")
const monthDaysKeyByValue = keyBy(OPTIONS_MONTH_DAYS, "value")
const monthlyFreqKeyByValue = keyBy(OPTIONS_MONTHLY_FREQUENCY, "value")
const weekDayOptions = [
    {value: RRule.MO.weekday, label: "Monday", short: "MO"},
    {value: RRule.TU.weekday, label: "Tuesday", short: "TU"},
    {value: RRule.WE.weekday, label: "Wednesday", short: "WE"},
    {value: RRule.TH.weekday, label: "Thursday", short: "TH"},
    {value: RRule.FR.weekday, label: "Friday", short: "FR"},
    {value: RRule.SA.weekday, label: "Saturday", short: "SA"},
    {value: RRule.SU.weekday, label: "Sunday", short: "SU"}
]

export function flattedDays(days) {
    if (typeof days === "string") {
        return days
    }
    return Object.keys(days)
        .filter((k) => !!days[k])
        .map((k) => days[k].short)
        .join(",")
}

export function getExtraEventData(eventData) {
    return {
        ...eventData,
        days: flattedDays(eventData.days),
        end: eventData.end.value,
        recurring: eventData.recurring?.originalValue,
        monthDay: eventData.monthDay?.label,
        monthWeek: eventData.monthWeek?.value,
        monthWeekDay: eventData.monthWeekDay?.short
    }
}

export function createRruleFromEvent(eventData) {
    const event = getExtraEventData(eventData)
    const {
        startDate,
        endDate,
        startTime,
        endTime,
        days,
        recurring,
        executions,
        monthDay,
        monthWeek,
        monthWeekDay,
        every,
        end
    } = event
    if (!recurring || recurring === "NEVER") {
        return ""
    }
    const startTimeStr = moment(startTime).format("HH:mm")
    const endTimeStr = moment(endTime).format("HH:mm")
    const rrule = []
    rrule.push(`DTSTART:${moment(startDate).format("YYYYMMDDT")}${startTimeStr.replace(":", "")}00Z\n`)
    if (recurring) {
        rrule.push(`RRULE:FREQ=${recurring};`)
    }
    if (endDate && end === RecurringEnd.OnDate) {
        const until = `${moment(endDate).format("YYYYMMDDT")}${endTimeStr.replace(":", "")}00Z`
        rrule.push(`UNTIL=${until};`)
    }
    if (recurring === CalendarRecurringType.Monthly) {
        if (monthWeek) {
            rrule.push(`BYSETPOS=${monthWeek};`)
        }
        if (monthDay) {
            rrule.push(`BYMONTHDAY=${monthDay};`)
        }
        if (!isEmpty(monthWeekDay)) {
            rrule.push(`BYDAY=${monthWeekDay};`)
        }
    }
    if (recurring === CalendarRecurringType.Weekly && !isEmpty(days)) {
        rrule.push(`BYDAY=${days};`)
    }
    if (executions && end === RecurringEnd.AfterExecution) {
        rrule.push(`COUNT=${executions};`)
    }
    rrule.push(`INTERVAL=${every || 1}`)
    return rrule.join("")
}

export function extractEventFromRrule(rrule: string) {
    if (!rrule) {
        return {
            recurring: "NEVER"
        }
    }
    const rules = rrulestr(rrule)
    const {count, bymonthday, until} = rules.origOptions
    const [, rule] = rrule.split("\n")
    const listRules = rule.split(";")
    const result: any = {}
    listRules.forEach((rule) => {
        if (rule.includes("RRULE:FREQ=")) {
            result.recurring = rule.replace("RRULE:FREQ=", "")
        } else if (rule.includes("BYSETPOS=")) {
            const monthWeek = +rule.replace("BYSETPOS=", "")
            result.monthWeek = monthlyFreqKeyByValue[monthWeek]
            result.monthlyRecurring = 1
        } else if (rule.includes("BYMONTHDAY=")) {
            const monthDay = +rule.replace("BYMONTHDAY=", "")
            result.monthDay = monthDaysKeyByValue[monthDay]
            result.monthlyRecurring = 0
        } else if (rule.includes("BYDAY=")) {
            if (result.recurring === CalendarRecurringType.Weekly) {
                const days = rule
                    .replace("BYDAY=", "")
                    .split(",")
                    .map((day) => weekDaysKeyByShort[day])
                result.days = keyBy(days, "value")
            } else {
                const days = rule.replace("BYDAY=", "")
                result.monthWeekDay = daysLongKeyByShort[days]
            }
        } else if (rule.includes("COUNT=")) {
            const executions = +rule.replace("COUNT=", "")
            result.executions = executions
        } else if (rule.includes("INTERVAL=")) {
            result.every = +rule.replace("INTERVAL=", "")
        }
    })
    if (bymonthday) {
        result.monthDay = OPTIONS_MONTH_DAYS.find((option) => option.value === bymonthday)
    }
    if (count) {
        result.end = OPTIONS_FREQUENCY_TYPE[1]
        result.executions = count
    } else if (until) {
        result.end = OPTIONS_FREQUENCY_TYPE[2]
        result.endDate = moment.utc(until)
    }
    return result
}

export function getDisabledStartDate(currentDate, endDate) {
    if (!endDate) {
        return false
    }
    return moment(currentDate).isAfter(moment(endDate))
}

export function getDisabledEndDate(currentDate, startDate) {
    if (!startDate) {
        return false
    }
    return moment(currentDate).isBefore(moment(startDate))
}

export function getDisabledStartHours(endTime) {
    if (!endTime) {
        return []
    }
    const endHour = moment(endTime).hour()
    return range(endHour, 24)
}

export function getDisabledStartMinutes(startTime, endTime) {
    if (!endTime || !startTime) {
        return []
    }
    const startHour = moment(startTime).hour()
    const endHour = moment(endTime).hour()
    if (startHour !== endHour) {
        return []
    }
    const endMinute = moment(endTime).minute()
    return range(endMinute, 60)
}

export function getDisabledEndHours(startTime) {
    if (!startTime) {
        return []
    }
    const startHour = moment(startTime).hour()
    return range(0, startHour)
}

export function getDisabledEndMinutes(startTime, endTime) {
    if (!startTime || !endTime) {
        return []
    }
    const startHour = moment(startTime).hour()
    const endHour = moment(endTime).hour()
    if (startHour !== endHour) {
        return []
    }
    const startMinute = moment(startTime).minute()
    return range(0, startMinute)
}

export function ordinalSuffixOf(data: number) {
    const by10 = data % 10
    const by100 = data % 100
    if (by10 === 1 && by100 !== 11) {
        return data + "st"
    }
    if (by10 === 2 && by100 !== 12) {
        return data + "nd"
    }
    if (by10 === 3 && by100 !== 13) {
        return data + "rd"
    }
    return data + "th"
}

function getEndInfo(rules: RRule): string {
    const {count, until} = rules.origOptions
    let endInfo = ""
    if (count) {
        endInfo = `, ${count} times`
    } else if (until) {
        endInfo = `, until ${moment(until).format("MMM DD, YYYY")}`
    }
    return endInfo
}

function getDailyRecurring(rules: RRule): string {
    const {interval} = rules.origOptions
    const dailyInfo = interval === 1 ? "Daily" : `Every ${interval} days`
    const endInfo = getEndInfo(rules)
    return `${dailyInfo}${endInfo}`
}

function getWeeklyRecurring(rules: RRule): string {
    const {count, byweekday, until} = rules.origOptions
    const daysByValue = keyBy(weekDayOptions, "value")
    let weeklyInfo = ""
    let endInfo = ""
    if (isArray(byweekday)) {
        weeklyInfo = byweekday
            .map((day) => {
                const weekday = get(day, "weekday", 0)
                return get(daysByValue[weekday], "label")
            })
            .join(", ")
    } else {
        const weekday = get(byweekday, "weekday", 0)
        weeklyInfo = get(daysByValue[weekday], "label")
    }
    if (count) {
        endInfo = `, ${count} times`
    } else if (until) {
        endInfo = `, until ${moment(until).format("MMM DD, YYYY")}`
    }
    return `Weekly on ${weeklyInfo}${endInfo}`
}

function getMonthlyRecurring(rules: RRule): string {
    const {bymonthday, byweekday, bysetpos} = rules.origOptions
    let monthlyInfo = `day ${bymonthday}`
    const endInfo = getEndInfo(rules)
    if (byweekday) {
        const daysByValue = keyBy(weekDayOptions, "value")
        monthlyInfo = bysetpos === -1 ? "last " : `${ordinalSuffixOf(bysetpos as number)} `
        if (isArray(byweekday)) {
            monthlyInfo += byweekday
                .map((day) => {
                    const weekday = get(day, "weekday", 0)

                    return get(daysByValue[weekday], "label")
                })
                .join(", ")
        } else {
            const weekday = get(byweekday, "weekday", 0)
            monthlyInfo += get(daysByValue[weekday], "label")
        }
    }
    return `Monthly on ${monthlyInfo}${endInfo}`
}

function getYearlyRecurring(rules: RRule): string {
    const {interval, dtstart} = rules.origOptions
    const dailyInfo = interval === 1 ? "Annually " : `Every ${interval} years`
    const endInfo = getEndInfo(rules)
    return `${dailyInfo} on ${moment(dtstart).format("MMM DD")}${endInfo}`
}

export function getRecurringInfo(rrule: string): string {
    if (!rrule) {
        return null
    }
    const {recurring} = extractEventFromRrule(rrule)
    const rules = rrulestr(rrule)
    switch (recurring) {
        case CalendarRecurringType.Daily:
            return getDailyRecurring(rules)
        case CalendarRecurringType.Weekly:
            return getWeeklyRecurring(rules)
        case CalendarRecurringType.Monthly:
            return getMonthlyRecurring(rules)
        case CalendarRecurringType.Yearly:
            return getYearlyRecurring(rules)
        default:
            return null
    }
}

export function getScheduleEventMethod(method: string) {
    switch (method) {
        case CourseEvent.Method.Online:
            return "Online"
        case CourseEvent.Method.InClass:
            return "In Class"
        case CourseEvent.Method.Hybrid:
            return "Hybrid"
        default:
            return null
    }
}

export function getScheduleEventType(type: string) {
    switch (type) {
        case CourseEvent.CourseEventType.Classroom:
            return "Classroom"
        case CourseEvent.CourseEventType.Lab:
            return "Lab"
        case CourseEvent.CourseEventType.Others:
            return "Others"
        case CourseEvent.CourseEventType.Test:
            return "Test"
        default:
            return null
    }
}

export function getScheduleEventColor(type: string) {
    switch (type) {
        case CourseEvent.CourseEventType.Classroom:
        case Course.CourseModuleType.TheoryOrClassroom:
            return "#1e90ff"
        case CourseEvent.CourseEventType.Lab:
            return "#ff349b"
        case CourseEvent.CourseEventType.Others:
            return "#125699"
        case CourseEvent.CourseEventType.Test:
            return "#939393"
        default:
            return "#1e90ff"
    }
}

export function getStartDateTime(event) {
    switch (event.type) {
        case CalendarEventType.StartTermDate:
            return moment(toDateOnly(event.start_at_utc)).startOf("date").local()
        case CalendarEventType.EndTermDate:
            return moment(toDateOnly(event.start_at_utc)).startOf("date").local()
        default:
            return moment(event.start_at_utc).local()
    }
}

export function getEndDateTime(event) {
    switch (event.type) {
        case CalendarEventType.StartTermDate:
            return moment(toDateOnly(event.end_at_utc)).endOf("date").local()
        case CalendarEventType.EndTermDate:
            return moment(toDateOnly(event.end_at_utc)).endOf("date").local()
        default:
            return moment(event.end_at_utc).local()
    }
}
