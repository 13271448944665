import React from "react"
import {Icon} from "components/Icon"
import {BarChart} from "components/Chart"
import styles from "./Withdrawn.module.css"
import {useTranslation} from "react-i18next"

export function Withdrawn(props) {
    const {t} = useTranslation(["student"])
    const chartData = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
            {
                data: [25, 10, 5, 10, 20, 18, 12, 9, 25, 12, 8, 10],
                backgroundColor: "#e95c7b"
            }
        ]
    }

    const options = {
        tooltips: {
            enabled: true
        },
        plugins: {
            datalabels: {
                display: false
            }
        }
    }

    return (
        <div className={styles.wrap}>
            <div className={styles.headerWrap}>
                <Icon icon="PERSON_REMOVE" color="#E95C7B" />
                <p className={styles.title}>{t("student.workspace.withDrawn")}</p>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.chartWrap}>
                <BarChart data={chartData} options={options} />
            </div>
        </div>
    )
}
