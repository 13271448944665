import classNames from "classnames"
import React, {useCallback} from "react"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"
import {get, isEqual} from "lodash"
import {useTranslation} from "react-i18next"
import {dropdownStyle} from "./dropdownStyle"

type SelectComponentsProps = {[key in string]: any}

export type KlassDropdownProps = SelectComponentsProps & {
    options?: any[]
    value?: any
    onChange?: any
    placeholder?: string
    valueKey?: string
    labelKey?: string
    hasEmptyOption?: boolean
    isDisabled?: boolean
    readOnly?: boolean
    isMulti?: boolean
    isGrouped?: boolean
    isClearable?: boolean
    stylesCustom?: any
    closeMenuOnSelect?: boolean
    error?: boolean
    showTop?: boolean
    creatable?: boolean
}

export function KlassDropdown(props: KlassDropdownProps) {
    const {
        stylesCustom,
        valueKey,
        labelKey,
        hasEmptyOption,
        options = [],
        isDisabled = false,
        readOnly = false,
        isClearable = false,
        isMulti,
        className,
        isGrouped,
        showTop = false,
        creatable = false,
        ...rest
    } = props

    const getMenuStyle = (provided) => ({
        ...provided,
        zIndex: 10,
        top: showTop ? "unset" : "100%",
        bottom: showTop ? "100%" : "unset"
    })

    const {t} = useTranslation("common")
    let valueKeySelect = valueKey || "id"
    let labelKeySelect = labelKey || "name"
    let styles = dropdownStyle
    styles.menu = getMenuStyle
    if (stylesCustom) {
        styles = {...styles, ...stylesCustom}
    }
    const customProps = {...rest, styles}
    const emptyValue = {[valueKeySelect]: "", [labelKeySelect]: t("selectField.emptyValue")}
    const extendedOptions = [...options]
    if (hasEmptyOption && options && options.length && !isEqual(options[0], emptyValue)) {
        extendedOptions.splice(0, 0, emptyValue)
    }

    const formatGroupLabel = (data) => (
        <div>
            <span>{data.label}</span>
        </div>
    )

    const getOptionValue = useCallback((option: any) => get(option, valueKeySelect), [valueKeySelect])
    const getOptionLabel = useCallback((option: any) => get(option, labelKeySelect), [labelKeySelect])
    const filterOption = useCallback(
        (candidate: {label: string; value: string; data: any}, input: string) => {
            const {data: option} = candidate
            if (input) {
                return `${get(option, valueKeySelect)} ${get(option, labelKeySelect)}`
                    .toLowerCase()
                    .includes(input.toLowerCase())
            }
            return true
        },
        [valueKeySelect, labelKeySelect]
    )

    const selectProps = {
        getOptionValue,
        getOptionLabel,
        filterOption,
        options: extendedOptions,
        menuIsOpen: readOnly ? false : undefined,
        isDisabled: isDisabled,
        isSearchable: !readOnly,
        isClearable: !!isClearable && !readOnly,
        closeMenuOnSelect: true,
        isMulti: isMulti,
        formatGroupLabel: isGrouped && formatGroupLabel,
        classNamePrefix: "klassapp-dropdown",
        className: classNames("klassapp-dropdown", className, {readOnly})
    }

    const creatableSelectRef = React.useRef<CreatableSelect<any, boolean>>()

    const onOpen = React.useCallback(() => {
        if (!creatableSelectRef.current) return
        const select = creatableSelectRef.current
        // @ts-ignore
        select.state.inputValue = select.state.value?.[labelKeySelect] || ""
    }, [labelKeySelect])

    if (creatable) {
        return <CreatableSelect {...selectProps} {...customProps} ref={creatableSelectRef} onMenuOpen={onOpen} />
    }
    return <Select {...selectProps} {...customProps} />
}
