/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react"
import classNames from "classnames"
import moment, {Moment} from "moment-timezone"
import debounce from "debounce-promise"
import {get, head, isEmpty, keyBy, range} from "lodash"
import {Radio, Row, Col} from "antd"
import {BasePopup} from "components/popup"
import {KlassDropdown, KlassDropAsyncPaginate} from "components/Select"
import {Icon} from "components/Icon"
import {BaseTimePicker} from "components/DateTimePicker"
import {BaseInput} from "components/inputs"
import {BaseLoading} from "components/Loading"
import {BaseButton} from "components/buttons"
import {Course} from "types/courses"
import {academicPlansService, courseService, majorService, termsService, userServiceV3, calendarService} from "services"
import {Major} from "types/major"
import {CourseEvent} from "types/courseEvent"
import {formatCodeName, getFullName, getValuesChangeTwoObj, handleError, toastError} from "helpers"
import styles from "./AddEventPopup.module.css"
import {FormLabel} from "components/Form"
import {Auth} from "types/auth"
import {TargetRecurringEvent} from "types/calendar"

type Props = {
    isShow: boolean
    group: Major.GroupCourseSequence
    eventData: any
    prevCourseDay: number
    isInMajorVersion: boolean
    isInTermSettingPreview: boolean
    isInAcademicPlan: boolean
    workdays: Moment[]
    campusId: number
    onClose: () => void
    reloadView: () => void
}

export function AddMultiEventPopup(props: Props) {
    const {
        isShow,
        group,
        eventData,
        prevCourseDay,
        isInMajorVersion,
        isInTermSettingPreview,
        isInAcademicPlan,
        workdays,
        campusId,
        onClose,
        reloadView
    } = props
    const {courses} = group
    const [event, setEvent] = useState({
        type: CourseEvent.CourseEventType.Classroom,
        submodule: null,
        method: CourseEvent.Method.Online,
        notes: "",
        daysOfCourses: [],
        startTime: null,
        endTime: null,
        capacity: null,
        instructors: []
    })
    const [course, setCourse] = useState<Course.Course>(null)
    const [submodulesData, setSubmodulesData] = useState<Course.Submodule[]>([])
    const [dotOptions, setDotOptions] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [activeCourse, setActiveCourse] = useState<Major.CourseSequence>(null)

    useEffect(() => {
        initEvent()
    }, [])

    const initEvent = async () => {
        const newEvent = {...event, ...eventData}
        const dotOptions = getDotOptions()
        if (eventData.id) {
            const activeCourse = courses.find((item) => item.courseId === eventData.courseId)
            const dotOptionsById = keyBy(dotOptions, "id")
            newEvent.startTime = moment(newEvent.startTime)
            newEvent.endTime = moment(newEvent.endTime)
            const daysOfCourse = (eventData.daysOfCourse || "").split(",").map((item) => +item)
            newEvent.daysOfCourses = daysOfCourse.map((day) => dotOptionsById[day])
            const moduleMethodsByType = {
                [CourseEvent.CourseEventType.Classroom]: Course.CourseModuleType.TheoryOrClassroom,
                [CourseEvent.CourseEventType.Lab]: Course.CourseModuleType.Lab,
                [CourseEvent.CourseEventType.Others]: Course.CourseModuleType.Others,
                [CourseEvent.CourseEventType.Test]: Course.CourseModuleType.Test
            }
            const courseDetail = await getCourseInfo(moduleMethodsByType[newEvent.type], activeCourse.courseId)
            newEvent.submodule = newEvent.submoduleId
                ? {
                      submoduleId: newEvent.submoduleId,
                      title: courseDetail.submodules.find((item) => item.submoduleId === newEvent.submoduleId)?.title
                  }
                : null
            setActiveCourse(activeCourse)
        } else {
            const firstCourse = head(courses)
            setActiveCourse(firstCourse)
            newEvent.instructors = get(firstCourse.schedule, "instructors", [])
            newEvent.capacity = +get(firstCourse.schedule, "capacity")
            getCourseInfo(Course.CourseModuleType.TheoryOrClassroom, firstCourse.courseId)
        }
        setEvent(newEvent)
    }

    const getDotOptions = () => {
        const dotOptions = range(1, group.numberOfDays + 1).map((day) => ({id: day, name: prevCourseDay + day}))
        setDotOptions(dotOptions)
        return dotOptions
    }

    const getCourseInfo = async (moduleType: Course.CourseModuleType, courseId: number) => {
        try {
            const courseDetail = await courseService.getOne(courseId)
            const submodulesData = courseDetail.submodules.filter((item) => item.moduleType === moduleType)
            setCourse(courseDetail)
            setSubmodulesData(submodulesData)
            return courseDetail
        } catch (error) {
            handleError(error)
        }
    }

    const onChangeEventData = (key, value) => {
        if (isInAcademicPlan) {
            return
        }
        const newEventData = {...event}
        newEventData[key] = value
        if (key === "type") {
            let submodulesData = []
            const {submodules} = course
            switch (value) {
                case CourseEvent.CourseEventType.Classroom:
                    submodulesData = submodules.filter(
                        (item) => item.moduleType === Course.CourseModuleType.TheoryOrClassroom
                    )
                    break
                case CourseEvent.CourseEventType.Lab:
                    submodulesData = submodules.filter((item) => item.moduleType === Course.CourseModuleType.Lab)
                    break
                case CourseEvent.CourseEventType.Test:
                    submodulesData = submodules.filter((item) => item.moduleType === Course.CourseModuleType.Test)
                    break
                case CourseEvent.CourseEventType.Others:
                    submodulesData = submodules.filter((item) => item.moduleType === Course.CourseModuleType.Others)
                    break
                default:
                    break
            }
            setSubmodulesData(submodulesData)
            newEventData.submodule = null
        }
        setEvent(newEventData)
    }

    const createEvent = async () => {
        const localOffset = moment().utcOffset()
        const currentOffset = moment().utcOffset()
        const diffInMinutes = localOffset - currentOffset

        const data: any = {
            programTermTemplateCourseId: undefined,
            termCalendarBaseCourseId: undefined,
            type: event.type,
            method: event.method,
            submoduleId: event.submodule?.submoduleId,
            daysOfCourse: event.daysOfCourses.map((item) => item.id).join(","),
            startTime: moment(event.startTime).add(diffInMinutes, "minute").format("HH:mm:ss"),
            endTime: moment(event.endTime).add(diffInMinutes, "minute").format("HH:mm:ss"),
            instructorProfileIds: (event.instructors || []).map((instructor) => instructor.profileId),
            capacity: undefined,
            location: "",
            notes: event.notes
        }
        if (isInMajorVersion) {
            data.programTermTemplateCourseId = activeCourse.id
            await majorService.addEventCalendarBased(data)
        } else if (isInTermSettingPreview) {
            data.scheduleId = activeCourse.scheduleId
            data.capacity = +event.capacity
            data.dayDiffWithStartTerm = workdays[prevCourseDay].diff(workdays[0], "day")
            await termsService.addEventCalendarBased(data)
        }
    }

    const editEvent = async () => {
        const localOffset = moment().utcOffset()
        const currentOffset = moment().utcOffset()
        const diffInMinutes = localOffset - currentOffset
        const oldData = {
            type: eventData.type,
            method: eventData.method,
            submoduleId: eventData.submoduleId,
            daysOfCourse: eventData.daysOfCourse,
            startTime: moment(eventData.startTime).add(diffInMinutes, "minute").format("HH:mm:ss"),
            endTime: moment(eventData.endTime).add(diffInMinutes, "minute").format("HH:mm:ss"),
            instructorProfileIds: (eventData.instructors || []).map((instructor) => instructor.profileId),
            notes: eventData.notes
        }
        const data: any = {
            type: event.type,
            method: event.method,
            submoduleId: event.submodule?.submoduleId || null,
            daysOfCourse: event.daysOfCourses.map((item) => item.id).join(","),
            startTime: moment(event.startTime).add(diffInMinutes, "minute").format("HH:mm:ss"),
            endTime: moment(event.endTime).add(diffInMinutes, "minute").format("HH:mm:ss"),
            instructorProfileIds: (event.instructors || []).map((instructor) => instructor.profileId),
            notes: event.notes
        }
        const payload: any = getValuesChangeTwoObj(oldData, data)
        if (!isEmpty(payload)) {
            if (isInMajorVersion) {
                payload.id = eventData.id
                payload.programTermTemplateCourseId = activeCourse.id
                await majorService.updateEventCalendarBased(payload)
            } else if (isInTermSettingPreview) {
                payload.calendarId = get(activeCourse.schedule, "calendar_id")
                payload.calendarEventId = eventData.id
                payload.capacity = +event.capacity
                payload.scheduleId = eventData.scheduleId
                await termsService.updateEventCalendarBased(payload)
            }
        }
    }

    const onClickSave = async () => {
        if (isEmpty(event.daysOfCourses) || !event.startTime || !event.endTime) {
            toastError("Please enter all the required field")
            return
        }
        try {
            setIsSubmitting(true)
            if (eventData.id) {
                await editEvent()
            } else {
                await createEvent()
            }
            onClose()
            reloadView()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitting(false)
        }
    }

    const deleteEvent = async () => {
        try {
            setIsDeleting(true)
            if (isInMajorVersion) {
                await majorService.removeEventCalendarBased({ids: [eventData.id]})
            } else if (isInTermSettingPreview) {
                await calendarService.deleteEvent(eventData.id, {target_filter: TargetRecurringEvent.OnlyThis})
            } else if (isInAcademicPlan) {
                await academicPlansService.removeEventCalendarBased({ids: [eventData.id]})
            }
            onClose()
            reloadView()
        } catch (error) {
            handleError(error)
        } finally {
            setIsDeleting(false)
        }
    }

    const onInstructorSearchChange = async (search = "", loadedOptions) => {
        try {
            const {data, total} = await userServiceV3.getAll({
                filter: {
                    active: true,
                    isAccessDisabled: false,
                    notState: [Auth.UserProfileState.ExEmployee],
                    type: [Auth.UserProfileType.Staff],
                    search,
                    campus: isInTermSettingPreview ? [+campusId] : undefined
                },
                range: {
                    limit: 20,
                    offset: loadedOptions.length
                }
            })
            const instructors = data.map((user) => ({
                userId: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                fullName: user.fullName,
                profileId: user.profiles[0]
            }))
            return {
                options: instructors,
                hasMore: loadedOptions.length < total
            }
        } catch (e) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const renderBody = () => {
        if (!course) {
            return (
                <div className={styles.loading}>
                    <BaseLoading isShow />
                </div>
            )
        }
        const debounceInstructors = debounce(onInstructorSearchChange, 300)

        return (
            <div>
                <div className={classNames(styles.eventTypeWrap)}>
                    <Radio.Group
                        className={styles.radioGroup}
                        onChange={(event) => onChangeEventData("type", event.target.value)}
                        value={event.type}>
                        <Radio className={styles.radioItem} value={CourseEvent.CourseEventType.Classroom}>
                            {course.theoryOrClassroomModuleTitle || "Classroom"}
                        </Radio>
                        <Radio className={styles.radioItem} value={CourseEvent.CourseEventType.Lab}>
                            {course.labModuleTitle || "Lab"}
                        </Radio>
                        <Radio className={styles.radioItem} value={CourseEvent.CourseEventType.Test}>
                            {course.testModuleTitle || "Test"}
                        </Radio>
                        <Radio className={styles.radioItem} value={CourseEvent.CourseEventType.Others}>
                            {course.othersModuleTitle || "Others"}
                        </Radio>
                    </Radio.Group>
                    <Radio.Group
                        className={styles.radioGroup}
                        onChange={(event) => onChangeEventData("method", event.target.value)}
                        value={event.method}>
                        <Radio className={styles.radioItem} value={CourseEvent.Method.Online}>
                            Online
                        </Radio>
                        <Radio className={styles.radioItem} value={CourseEvent.Method.InClass}>
                            In Class
                        </Radio>
                        <Radio className={styles.radioItem} value={CourseEvent.Method.Hybrid}>
                            Hybrid
                        </Radio>
                    </Radio.Group>
                    <div className={styles.submoduleDropdown}>
                        <KlassDropdown
                            options={submodulesData}
                            onChange={(newValue) => onChangeEventData("submodule", newValue)}
                            labelKey="title"
                            valueKey="submoduleId"
                            value={event.submodule}
                            placeholder="Select submodule"
                            readOnly={isInAcademicPlan}
                        />
                    </div>
                </div>
                <div className={styles.formBody}>
                    <Row gutter={[16, 16]} align="middle">
                        <Col span={2}>
                            <Icon icon="CALENDAR_FILL" className={styles.icon} color="#62B1FF" />
                        </Col>
                        <Col span={6}>
                            <FormLabel label="DOT" isRequired />
                        </Col>
                        <Col span={16}>
                            <KlassDropdown
                                value={event.daysOfCourses}
                                options={dotOptions}
                                onChange={(newValue) => onChangeEventData("daysOfCourses", newValue)}
                                placeholder="Days"
                                isMulti
                                readOnly={isInAcademicPlan}
                            />
                        </Col>
                        <Col span={2}>
                            <Icon icon="TIMER" className={styles.icon} color="#62B1FF" />
                        </Col>
                        <Col span={6}>
                            <FormLabel label="Time" isRequired />
                        </Col>
                        <Col span={16}>
                            <div className={styles.timeWrap}>
                                <BaseTimePicker
                                    value={event.startTime}
                                    onChange={(newValue) => onChangeEventData("startTime", newValue)}
                                    placeholder="From"
                                    format="HH:mm"
                                    readOnly={isInAcademicPlan}
                                />
                                <BaseTimePicker
                                    value={event.endTime}
                                    onChange={(newValue) => onChangeEventData("endTime", newValue)}
                                    placeholder="To"
                                    format="HH:mm"
                                    readOnly={isInAcademicPlan}
                                />
                            </div>
                        </Col>
                        <Col span={2}>
                            <Icon icon="PERSON" className={styles.icon} color="#62B1FF" />
                        </Col>
                        <Col span={6}>
                            <label>Instructor</label>
                        </Col>
                        <Col span={16}>
                            <KlassDropAsyncPaginate
                                value={event.instructors}
                                onChange={(newValue) => onChangeEventData("instructors", newValue)}
                                loadOptions={debounceInstructors}
                                isMulti
                                valueKey="profileId"
                                getOptionLabel={(option: any) => getFullName(option)}
                                placeholder="Instructors"
                                readOnly={isInAcademicPlan}
                            />
                        </Col>
                        {(isInTermSettingPreview || isInAcademicPlan) && (
                            <>
                                <Col span={2}>
                                    <Icon icon="PEOPLE" className={styles.icon} color="#62B1FF" />
                                </Col>
                                <Col span={6}>
                                    <label>Capacity</label>
                                </Col>
                                <Col span={16}>
                                    <BaseInput
                                        type="number"
                                        min={0}
                                        value={event.capacity}
                                        onChange={(newValue) => onChangeEventData("capacity", newValue)}
                                        placeholder="Capacity"
                                        readOnly={isInAcademicPlan}
                                    />
                                </Col>
                            </>
                        )}
                        <Col span={2}>
                            <Icon icon="NOTES" className={styles.icon} color="#62B1FF" />
                        </Col>
                        <Col span={6}>
                            <label>Notes</label>
                        </Col>
                        <Col span={16}>
                            <BaseInput
                                value={event.notes}
                                onChange={(newValue) => onChangeEventData("notes", newValue)}
                                placeholder="Notes"
                                readOnly={isInAcademicPlan}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    return (
        <BasePopup isShow={isShow} onClose={onClose} isShowLeftSide={false} width="600px">
            <div className={styles.wrap}>
                <p className={styles.title}>{eventData.id ? "Event Detail" : "Create Event"}</p>
                <div className={styles.coursesDropdown}>
                    <KlassDropdown
                        options={group.courses}
                        value={activeCourse}
                        onChange={setActiveCourse}
                        getOptionLabel={formatCodeName}
                        valueKey="courseId"
                        isDisabled={!!eventData.id}
                    />
                </div>
                {renderBody()}
                {!isInAcademicPlan && (
                    <div className={styles.action}>
                        {eventData.id && (
                            <BaseButton
                                title="Delete Event"
                                variant="secondary"
                                onClick={deleteEvent}
                                loading={isDeleting}
                            />
                        )}
                        <BaseButton title="Cancel" variant="secondary" onClick={onClose} />
                        <BaseButton title="OK" onClick={onClickSave} loading={isSubmitting} />
                    </div>
                )}
            </div>
        </BasePopup>
    )
}
