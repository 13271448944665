/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {Row, Col} from "antd"
import {KlassappTableHeader, KlassappTable} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {handleError} from "helpers"
import {Icon} from "components/Icon"
import {Search} from "components/inputs/Search"
import {KlassDropdown} from "components/Select"
import {BaseDatePicker} from "components/DateTimePicker"
import {BaseFilter, BaseFilterContext} from "uiKit"
import {useVisible} from "hooks"
import {ReschedulePopup} from "./parts"
import styles from "./Cancelled.module.css"
import {cancelledData} from "./rawData"

function Cancelled(props) {
    const [search, setSearch] = useState("")
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        tableHeaderActions,
        orderField,
        isLoading,
        menuActions,
        isHideMenuActions,
        isShowTableHeaderAction,
        getCurrentData
    } = props
    const {t} = useTranslation(["calendar"])
    const reschedulePopup = useVisible(false)

    const getFields = () => {
        return [
            t("calendar.cancelled.major"),
            t("calendar.cancelled.courseId"),
            t("calendar.cancelled.courseName"),
            t("calendar.cancelled.lessonDate"),
            t("calendar.cancelled.time"),
            t("calendar.cancelled.campus"),
            t("calendar.cancelled.description"),
            t("calendar.cancelled.reschedule")
        ]
    }

    const getPageTitle = () => {
        return t("calendar.cancelled.cancelled")
    }

    const getColumns = () => {
        return [
            {
                title: t("calendar.cancelled.major"),
                field: "major"
            },
            {
                title: t("calendar.cancelled.courseId"),
                field: "courseId"
            },
            {
                title: t("calendar.cancelled.courseName"),
                field: "courseName"
            },
            {
                title: t("calendar.cancelled.lessonDate"),
                field: "lessonDate",
                // fieldType: "date",
                // format: "MM/DD/YYYY",
                style: {minWidth: 150}
            },
            {
                title: t("calendar.cancelled.time"),
                field: "time"
            },
            {
                title: t("calendar.cancelled.campus"),
                field: "campus"
            },
            {
                title: t("calendar.cancelled.description"),
                field: "description"
            },
            {
                title: t("calendar.cancelled.reschedule"),
                field: "rescheduleHtml"
            }
        ]
    }

    const removeSchoolClosure = () => {}

    const getTableHeaderActions = () => {
        return [
            {
                title: t("calendar.cancelled.removeFromList"),
                icon: "DELETE",
                action: removeSchoolClosure
            }
        ]
    }

    // const getParams = () => {
    //     const params = {
    //         range: {page, pageSize},
    //         filter: {},
    //         linkedEntities: true
    //     }
    //     return params
    // }

    const onClickReschedule = (event, item) => {
        event.stopPropagation()
        reschedulePopup.open()
    }

    const renderReschedule = (item) => {
        return (
            <div className={styles.rescheduleIconWrap} onClick={(event) => onClickReschedule(event, item)}>
                <Icon icon="RESCHEDULE" />
            </div>
        )
    }

    const getData = async () => {
        dispatch({isLoading: true})
        try {
            // const params = getParams()
            const data = cancelledData.map((item: any) => {
                item.rescheduleHtml = renderReschedule(item)
                return item
            })
            dispatch({data, total})
        } catch (e) {
            console.log("e")
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onUpdateRowData = (updatedData) => {
        const data = getCurrentData()
        const newData = data.map((item) => {
            if (item.id === updatedData.id) {
                updatedData.isActiveTableCol = updatedData.isChecked
                return updatedData
            }
            return item
        })
        dispatch({data: newData})
    }

    const onClickRowItem = (row) => {}

    const onClickDeleteMulti = async () => {
        // const data = getCurrentData()
        // const checkedIds = data.filter((item) => item.isChecked).map((item) => item.id)
        try {
            dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await getData()
        } catch (error) {
            console.log(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClickDuplicateMulti = async () => {
        // const data = getCurrentData()
        // const payload = data
        //     .filter((item) => item.isChecked)
        //     .map(({name, description}) => ({name, description: `${description} (Copy)`}))
        try {
            dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await getData()
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClickEdit = (editedItem) => {
        onClickRowItem(editedItem)
    }

    const onClickDelete = async (deletedItem) => {
        const {dispatch} = props
        try {
            dispatch({isLoading: true})
            await getData()
        } catch (error) {
            console.log(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "getTableHeaderActions", func: getTableHeaderActions},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickDuplicateMulti", func: onClickDuplicateMulti},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDelete},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [])

    useEffect(() => {
        getData()
    }, [page, pageSize])

    useEffect(() => {
        props.updateTableHeaderActions()
    }, [data])

    const onChangeSearch = (newValue) => {
        setSearch(newValue)
    }

    const onClickFilter = () => {}

    const onCloseReschedulePopup = () => {
        reschedulePopup.close()
    }

    return (
        <>
            <BaseFilter filter={{}} onClick={onClickFilter}>
                <BaseFilterContext.Consumer>
                    {({setIsShowFilter}) => (
                        <Row gutter={[32, 24]}>
                            <Col span={12}>
                                <KlassDropdown options={[]} placeholder={t("calendar.cancelled.program")} />
                            </Col>
                            <Col span={12}>
                                <BaseDatePicker placeholder={t("calendar.cancelled.date")} />
                            </Col>
                            <Col span={12}>
                                <KlassDropdown options={[]} placeholder={t("calendar.cancelled.campus")} />
                            </Col>
                            <Col span={12}>
                                <KlassDropdown options={[]} placeholder={t("calendar.cancelled.course")} />
                            </Col>
                            <Col span={24}>
                                <Search
                                    value={search}
                                    onChange={onChangeSearch}
                                    onPressEnter={() => {
                                        setIsShowFilter(false)
                                        onClickFilter()
                                    }}
                                    placeholder={t("common:action.search")}
                                />
                            </Col>
                        </Row>
                    )}
                </BaseFilterContext.Consumer>
            </BaseFilter>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                menuActions={isHideMenuActions ? [] : menuActions}
                isLoading={isLoading}
                allFields={allFields}
                isShowCheckedColumn={true}
                orderField={orderField}
                onClickRowItem={onClickRowItem}
                onUpdateRowData={onUpdateRowData}
                onChangeFields={props.onChangeFields}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
            <ReschedulePopup isShow={reschedulePopup.isVisible} onClose={onCloseReschedulePopup} />
        </>
    )
}

export default KlassappTableHOC(Cancelled)
