/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useModel} from "hooks"
import {useTranslation} from "react-i18next"
import {useHistory} from "react-router-dom"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {routing} from "helpers/routing"
import {KlassappTableProps} from "types/common"
import {TermDetails} from "types/terms"
import {KlassappTableHOC} from "HOC"
import {FilterKey} from "types/filter"
import {isEmpty} from "lodash"
import TermAndCoursesFilter from "../TermAndCoursesFilter"
import {TermAndCoursesTabs} from ".."
import {handleError} from "helpers"
import {instructionalService} from "services"
import moment from "moment"
import {toDateOnly} from "sections/academics/instructional/common/utils"

type Props = KlassappTableProps & {
    terms: TermDetails[]
}

const MajorsTab = (props: Props) => {
    const {
        dispatch,
        dispatchFunc,
        page,
        terms,
        total,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        tableHeaderActions,
        orderField,
        isLoading,
        isLoadedTableFuncs,
        isShowTableHeaderAction
    } = props
    const {t} = useTranslation(["course"])
    const model = useModel()
    const history = useHistory()
    const storageData = model.getStorageFilter(FilterKey.RegistrarTermAndMajors)

    const [textSearch, setTextSearch] = useState<string>(!isEmpty(storageData) ? storageData.search : "")
    const [tempFilter, setTempFilter] = useState(!isEmpty(storageData?.filter) ? storageData.filter : {campus: null})
    const [filter, setFilter] = useState(!isEmpty(storageData?.filter) ? storageData.filter : {campus: null})

    const getFields = () => {
        return [
            "ID",
            t("academics:registrar.majorName"),
            t("academics:registrar.startDate"),
            t("academics:registrar.endDate"),
            "Instructor",
            "No. Student"
        ]
    }

    const getPageTitle = () => {
        return "Course"
    }

    const getColumns = () => {
        return [
            {
                title: "ID",
                field: "code",
                sortable: true
            },
            {
                title: t("academics:registrar.majorName"),
                field: "name",
                style: {width: "250px"},
                sortable: true
            },
            {
                title: "Start Date",
                field: "startDate",
                sortable: true
            },
            {
                title: "End Date",
                field: "endDate",
                sortable: true
            },
            {
                title: "Instructor",
                field: "instructors",
                sortable: true
            },
            {
                title: "No. Student",
                field: "numberOfStudent",
                sortable: true
            }
        ]
    }

    const getData = async () => {
        dispatch({isLoading: true})
        try {
            const params: any = {
                range: {page, pageSize},
                filter: {}
            }
            params.sort = orderField?.field
                ? {
                      orderBy: orderField.field,
                      orderDir: orderField.order
                  }
                : undefined
            params.filter.termIds = terms.map((item) => item.id)
            if (filter.campus && filter.campus.length) {
                params.filter.campusIds = filter.campus.map((item) => item.id)
            }
            if (filter.instructor && filter.instructor.length) {
                params.filter.instructorProfileIds = filter.instructor.map((item) => item.profileId)
            }
            if (textSearch && textSearch !== "") {
                params.filter.search = textSearch
            }
            const {data, total} = await instructionalService.getMajors(params)

            const majors = data.map((item) => {
                item.startDate = item.startDate
                    ? moment(toDateOnly(item.startDate)).format(model.getUserDateFormat())
                    : ""
                item.endDate = item.endDate ? moment(toDateOnly(item.endDate)).format(model.getUserDateFormat()) : ""
                return item
            })
            dispatch({data: majors, total})
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClickRowItem = (row) => {
        //
    }

    useEffect(() => {
        dispatch({isClassComponent: false})

        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [])

    useEffect(() => {
        if (terms?.length && isLoadedTableFuncs) {
            getData()
        }
    }, [isLoadedTableFuncs, page, pageSize, textSearch, terms, filter, orderField])

    useEffect(() => {
        props.updateTableHeaderActions()
    }, [data])

    return (
        <div>
            <TermAndCoursesFilter
                type={TermAndCoursesTabs.TermCoursesMajors}
                tempFilter={tempFilter}
                setTempFilter={setTempFilter}
                textSearch={textSearch}
                setFilter={setFilter}
                filter={filter}
                setTextSearch={setTextSearch}
            />
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                menuActions={[]}
                isLoading={isLoading}
                allFields={allFields}
                isShowCheckedColumn={false}
                orderField={orderField}
                keyRender="code"
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onUpdateRowData={props.onUpdateRowData}
                onClickSortColumn={props.onClickSortColumn}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
            />
        </div>
    )
}

export default KlassappTableHOC(MajorsTab, {
    page: 1,
    pageSize: 100
})
