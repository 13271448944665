import {Auth} from "./auth"

export enum ImportType {
    User = "user",
    AwardSummary = "award-summary"
    // more import here, e.g FinAid
}

export enum ImportItemStatus {
    New = "new",
    Updated = "updated",
    Skipped = "skipped"
}

export enum ImportStep {
    FileSelect = "file-select",
    Mapping = "mapping",
    PreviewData = "preview-data",
    Result = "result"
}

export namespace Import {
    export type EdularEntity = "user" | "profile"

    export type EdularFieldValueType = "string" | "number" | "date" | "enum" | "boolean"

    export type EdularField = {
        label: string
        field: string
        type?: EdularFieldValueType // default is "string"
        entity?: EdularEntity // default is "user"
    }

    export const EdularUserGeneralDetails: EdularField[] = [
        {
            label: "User ID",
            field: "customUserId"
        },
        {
            label: "Gender Pronoun",
            field: "genderPronoun"
        },
        {
            label: "First Name",
            field: "firstName"
        },
        {
            label: "Last Name",
            field: "lastName"
        },
        {
            label: "Middle Name",
            field: "middleName"
        },
        {
            label: "Name Suffix",
            field: "nameSuffix"
        },
        {
            label: "Maiden Name",
            field: "maidenName"
        },
        {
            label: "Nickname or Preferred Name",
            field: "nickNamePreferedName"
        },
        {
            label: "SSN",
            field: "ssn"
        },
        {
            label: "Gender",
            field: "gender"
        },
        {
            label: "Date Of Birth",
            field: "birthDate"
        },
        {
            label: "Place Of Birth",
            field: "placeOfBirth"
        },
        {
            label: "Hispanic",
            field: "isHispanic",
            type: "boolean"
        },
        {
            label: "Ethnicity",
            field: "race"
        },
        {
            label: "Marital Status",
            field: "marialStatus"
        },
        {
            label: "Citizenship Status",
            field: "citizenshipStatus"
        },
        {
            label: "Citizen Ship",
            field: "citizenShip"
        },
        {
            label: "Permanent Resident ID (Green Cards) or Visa Number",
            field: "permanentResidentId"
        },
        {
            label: "Driver License Number",
            field: "driverLicenseNumber"
        },
        {
            label: "Driver License Issuing State",
            field: "driverLicenseState",
            type: "date"
        },
        {
            label: "Highest level of formal education achieved",
            field: "highestLevelOfFormalEducationAchieved"
        }
    ]

    export const EdularUserMilitaryDetails: EdularField[] = [
        {
            label: "Prior Military Service",
            field: "priorMilitaryService"
        },
        {
            label: "Your Military Branch",
            field: "yourMilitaryBranch"
        },
        {
            label: "Military / Veteran Status",
            field: "veteranStatus"
        },
        {
            label: "Military Benefits",
            field: "militaryBenefits"
        }
    ]

    export const EdularUserHomeAddress: EdularField[] = [
        {
            label: "Country",
            field: "country"
        },
        {
            label: "City",
            field: "city"
        },
        {
            label: "Street Address",
            field: "address"
        },
        {
            label: "Postal Code",
            field: "postalCode"
        },
        {
            label: "State/Province/Territory",
            field: "state"
        }
    ]

    export const EdularUserMailingAddress: EdularField[] = [
        {
            label: "Mailing Country",
            field: "mailingCountry"
        },
        {
            label: "Mailing City",
            field: "mailingCity"
        },
        {
            label: "Mailing Street Address",
            field: "mailingStreetAddress"
        },
        {
            label: "Mailing Postal Code",
            field: "mailingPostalCode"
        },
        {
            label: "Mailing State/Province/Territory",
            field: "mailingState"
        }
    ]

    export const EdularUserContactDetails: EdularField[] = [
        {
            label: "Phone",
            field: "phone"
        },
        {
            label: "Email",
            field: "email"
        }
    ]

    export const EdularProfileOverview: EdularField[] = [
        {
            entity: "profile",
            label: "Profile Status",
            field: "active",
            type: "boolean"
        },
        {
            entity: "profile",
            label: "Access",
            field: "isAccessDisabled",
            type: "boolean"
        }
    ]

    export const EdularProfileGeneralDetails: EdularField[] = [
        {
            entity: "profile",
            label: "Enrollment ID",
            field: "customProfileId"
        },
        {
            entity: "profile",
            label: "User Type",
            field: "type"
        },
        {
            entity: "profile",
            label: "Role",
            field: "role"
        },
        {
            entity: "profile",
            label: "Campus",
            field: "campus"
        },
        {
            entity: "profile",
            label: "State",
            field: "state"
        },
        {
            entity: "profile",
            label: "Lead Source",
            field: "admissionsLeadSourceId"
        },
        {
            entity: "profile",
            label: "Lead Type",
            field: "leadTypeId"
        }
    ]

    export const EdularProfileAdmissions: EdularField[] = [
        {
            entity: "profile",
            label: "Initial Inquiry Date",
            field: "initialInquiryDate",
            type: "date"
        },
        {
            entity: "profile",
            label: "Acceptance Date",
            field: "acceptanceDate",
            type: "date"
        },
        {
            entity: "profile",
            label: "Enrollment Type",
            field: "enrollmentType"
        },
        {
            entity: "profile",
            label: "Enrollment Citizenship Status",
            field: "enrollmentCitizenshipStatus"
        },
        {
            entity: "profile",
            label: "Enrollment Status",
            field: "enrollmentStatus"
        },
        {
            entity: "profile",
            label: "Inquiry Message",
            field: "inquiryMessage"
        },
        {
            entity: "profile",
            label: "Student Status",
            field: "statusId"
        },
        {
            entity: "profile",
            label: "Fully Enrolled Date",
            field: "fullyEnrolledDate",
            type: "date"
        },
        {
            entity: "profile",
            label: "Dependency Status",
            field: "dependencyStatus"
        },
        {
            entity: "profile",
            label: "Number Of Dependents",
            field: "numberOfDependents"
        }
    ]

    export const EdularProfileAcademics: EdularField[] = [
        {
            entity: "profile",
            label: "Current Major",
            field: "currentMajor"
        },
        {
            entity: "profile",
            label: "Current Major Version",
            field: "currentMajorVersionId"
        },
        {
            entity: "profile",
            label: "Starting Term",
            field: "startingTermId"
        },
        {
            entity: "profile",
            label: "Current Term",
            field: "currentTerm"
        },
        {
            entity: "profile",
            label: "Specialization",
            field: "specializationId"
        },
        {
            entity: "profile",
            label: "Academic Track",
            field: "academicTrackId"
        },
        {
            entity: "profile",
            label: "Start Date",
            field: "startDate",
            type: "date"
        },
        {
            entity: "profile",
            label: "First Date Of Class",
            field: "firstDateOfClass",
            type: "date"
        },
        {
            entity: "profile",
            label: "Midpoint Date",
            field: "midpointDate",
            type: "date"
        },
        {
            entity: "profile",
            label: "contractEndDate",
            field: "Contract End Date",
            type: "date"
        },
        {
            entity: "profile",
            label: "Projected End Date",
            field: "projectedEndDate",
            type: "date"
        },
        {
            entity: "profile",
            label: "Degree Conferred",
            field: "degreeConferredStatus"
        }
    ]

    export const AvailableEdularFields: EdularField[] = [
        ...EdularUserGeneralDetails,
        ...EdularUserMilitaryDetails,
        ...EdularUserHomeAddress,
        ...EdularUserMailingAddress,
        ...EdularUserContactDetails,
        ...EdularProfileOverview,
        ...EdularProfileGeneralDetails,
        ...EdularProfileAdmissions,
        ...EdularProfileAcademics
    ]

    export const AwardSummaryEdularFields: EdularField[] = [
        {field: "awardYear", label: "Award Year"},
        {field: "fundSourceId", label: "Fund Source ID"},
        {field: "revision", label: "Revision"},
        {field: "status", label: "Award Status"},
        {field: "rejectionCode", label: "Rejection Code"},
        {field: "grossAmount", label: "Gross Amount"},
        {field: "netAmount", label: "Net Amount"},
        {field: "interestRebase", label: "Interest Rebase"},
        {field: "originationFee", label: "Origination Fee"},
        {field: "grossDisbursedAmount", label: "Gross Disbursed Amount"},
        {field: "netDisbursedAmount", label: "Net Disbursed Amount"},
        {field: "acceptanceDate", label: "Acceptance Date"},

        {field: "paymentPeriod", label: "Payment Period"},
        {field: "paymentPeriodStartDate", label: "Payment Period Start Date"},
        {field: "paymentPeriodEndDate", label: "Payment Period End Date"},
        {field: "fundSource", label: "Fund Source"},
        {field: "fundSourceExternalId", label: "Fund Source External ID"},

        {field: "currentCPSTransactionNo", label: "Current CPS Transaction #"},
        {field: "adjustedCPSTransactionNo", label: "Adjusted CPS Transaction #"},
        {field: "isirCPSTrans", label: "ISIR CPS Trans"},
        {field: "comments", label: "Comments"},
        {field: "codStatusCode", label: "COD Status Code"},
        {field: "codInformedBorrowerId", label: "COD Informed Borrower Id"},
        {field: "codInformedBorrowerComplete", label: "COD Informed Borrower Complete"},
        {field: "codInformedBorrowerCompletedDate", label: "COD Informed Borrower Completed Date"},
        {field: "codAwardId", label: "COD Award ID"},
        {field: "additionalPellEligible", label: "Additional Pell Eligible"},

        {field: "primaryEFC", label: "Primary EFC"},
        {field: "dependencyStatus", label: "Dependency Status"},
        {field: "selectedForVerification", label: "Selected for Verification"},
        {field: "verificationStatus", label: "Verification Status"},
        {field: "enrollmentLevel", label: "Enrollment Level"},
        {field: "manualAward", label: "Manual Award"},
        {field: "managedByR8", label: "Managed By R8"},
        {field: "overRideEligibilityRules", label: "Override Eligibility Rules"},

        {field: "studentExternalId", label: "Student External ID"},
        {field: "firstName", label: "Student First Name"},
        {field: "lastName", label: "Student Last Name"},
        {field: "isirEmail", label: "ISIR Email"},
        {field: "sisEmail", label: "SIS Email"},
        {field: "isirPhone", label: "ISIR Phone"},
        {field: "sisPhone", label: "SIS Phone"},
        {field: "campus", label: "Campus"},
        {field: "program", label: "Program of Study"},
        {field: "gradeLevel", label: "Grade Level"}
    ]

    export enum ImportStatus {
        Pending = "pending",
        Processing = "processing",
        Completed = "completed"
    }

    export type Metadata = Partial<{
        id: number
        name: string
        description: string
        status: ImportStatus
        type: ImportType
        userType: Auth.UserProfileType
        fromUserId: string
        enableLeadDistribution: boolean
        updateExistingUser: boolean
        createNewProfile: boolean
        shouldExtractValue: boolean
        importedRows?: number
        failedRows?: number
        createdAt?: string
        updatedAt?: string
    }>

    export type Schema = Partial<{
        id: number
        name: string
        isNew: boolean
        type: ImportType
        columns: string[]
    }>
}
