/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react"
import {EditorState, ContentState, convertToRaw} from "draft-js"
import {Editor} from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import {useTranslation} from "react-i18next"
import styles from "./BaseEditor.module.css"
import cx from "classnames"

export function BaseEditor(props) {
    const {value, onChange, defaultValue = "", error = false} = props
    const {t} = useTranslation(["common"])
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(value)))
    )

    useEffect(() => {
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(defaultValue))))
    }, [])

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        const newValue = editorState.getCurrentContent().getPlainText().length
            ? draftToHtml(convertToRaw(editorState.getCurrentContent()))
            : ""
        if (onChange) {
            onChange(newValue)
        }
    }

    return (
        <Editor
            placeholder={t("common:message.beginTyping")}
            editorState={editorState}
            toolbarClassName={styles.toolbar}
            wrapperClassName={cx(styles.wrap, {[styles.error]: error})}
            editorClassName={styles.editorWrap}
            onEditorStateChange={onEditorStateChange}
            toolbar={{
                options: ["inline", "blockType", "fontSize", "emoji", "list", "textAlign", "link", "history"],
                inline: {
                    inDropdown: false,
                    options: ["bold", "italic", "underline", "strikethrough"]
                },
                blockType: {
                    inDropdown: true,
                    options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote", "Code"],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined
                },
                fontSize: {
                    // icon: fontSize,
                    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined
                },
                list: {inDropdown: true},
                textAlign: {inDropdown: true},
                link: {inDropdown: false}
            }}
        />
    )
}
