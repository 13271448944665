import {useEffect, useMemo, useState} from "react"
import {LessonsScheduleItem} from "types/attendance"
import moment from "moment"
import {useInterval} from "react-use"

export const useCalendarNavigator = (getCalendarEvents, userId: number, initialDate: Date = new Date()) => {
    const [date, setDate] = useState(initialDate)

    useEffect(() => {
        getCalendarEvents(userId, date)
    }, [date, userId, getCalendarEvents])

    const handleSelectPrevDate = () => {
        setDate(moment(date).subtract(1, "day").toDate())
    }

    const handleSelectNextDate = () => {
        setDate(moment(date).add(1, "day").toDate())
    }

    return {date, next: handleSelectNextDate, prev: handleSelectPrevDate}
}

export const toDateOnly = (datestr: string | Date) => {
    const dt = new Date(datestr)
    return new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)
}

export const useNextLesson = (lessons: LessonsScheduleItem[], courseId?: number | null) => {
    const [currentDate, setCurrentDate] = useState(new Date())

    useInterval(() => {
        setCurrentDate(new Date())
    }, 60 * 1000)

    const nextLesson = useMemo(
        () =>
            lessons.find((lesson) => {
                const isAfterNow = moment(lesson.startAt).isAfter(moment(currentDate))

                return typeof courseId !== "undefined" ? lesson.courseId === courseId && isAfterNow : isAfterNow
            }) ?? null,
        [courseId, currentDate, lessons]
    )

    return {
        lesson: nextLesson
    }
}

export const getDateOnly = (payload: {date?: string; placeholder: boolean; dateFormat: string}) => {
    const {date, placeholder, dateFormat} = payload
    if (!date && placeholder) {
        return "xx/xx/xxxx"
    } else {
        if (!date) {
            return ""
        }
        return moment(toDateOnly(date)).format(dateFormat)
    }
}
