import React from "react"
import {Route, Redirect} from "react-router-dom"

export function PrivateRoutes({isLoggedIn, children, ...rest}) {
    if (!isLoggedIn) {
        return (
            <Route
                render={({location}) => {
                    const to = location.pathname + location.search
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                search: to.length > 2 ? `to=${encodeURIComponent(to)}` : undefined,
                                state: {from: location}
                            }}
                        />
                    )
                }}
            />
        )
    }
    return <Route {...rest} render={() => children} />
}
