import React, {useCallback, useEffect, useState} from "react"
import {BaseDrawer} from "components/BaseDrawer"
import {StudentInfoPanel, StudentInfoPanelProps} from "uiKit/StudentInfoPanel"

type Props = StudentInfoPanelProps & {
    // component props
    displayDrawer: boolean
    onCloseDrawer?: () => void
}

const INIT_DRAWER_WIDTH = 320
const EXPANDED_DRAWER_WIDTH = 400

export default function StudentInfoDrawer({displayDrawer, onCloseDrawer, activeSection, ...rest}: Props) {
    const [drawerWidth, setDrawerWidth] = useState<number>(INIT_DRAWER_WIDTH)

    useEffect(() => {
        if (activeSection && activeSection !== "") maximizeDrawerWidth()
        else minimizeDrawerWidth()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayDrawer])

    const minimizeDrawerWidth = useCallback(() => {
        setDrawerWidth(INIT_DRAWER_WIDTH)
    }, [])

    const maximizeDrawerWidth = useCallback(() => {
        setDrawerWidth(INIT_DRAWER_WIDTH + EXPANDED_DRAWER_WIDTH)
    }, [])

    return (
        <BaseDrawer isShow={displayDrawer} onClose={onCloseDrawer} width={drawerWidth}>
            <StudentInfoPanel
                {...rest}
                onClose={onCloseDrawer}
                displayType="drawer"
                activeSection={activeSection}
                minimizeDrawerWidth={minimizeDrawerWidth}
                maximizeDrawerWidth={maximizeDrawerWidth}
            />
        </BaseDrawer>
    )
}
