import BaseService from "./Base"
import {get} from "lodash"

class UserCertificationService extends BaseService {
    getAll = async (params = {}) => {
        const response = await this.post("/v1/user-certification/list", params)
        return get(response, "data", [])
    }

    delete = async (params = {}) => {
        const response = await this.post("/v1/user-certification/delete", params)
        return get(response, "data", {})
    }

    create = async (params = {}) => {
        const response = await this.post("/v1/user-certification/create", params)
        return get(response, "data.data", {})
    }

    update = async (id, data, params = {}) => {
        data.userCertificationId = id
        const response = await this.post("/v1/user-certification/edit", data, params)
        return get(response, "data", {})
    }
}
export default UserCertificationService
