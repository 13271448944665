/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useReducer, useRef, useState} from "react"
import {get, keyBy, groupBy, isEqual, uniqBy, head, flatten, cloneDeep, isEmpty} from "lodash"
import {useHistory, useLocation} from "react-router-dom"
import {v4 as uuidV4} from "uuid"
import cx from "classnames"
import randomColor from "randomcolor"
import {CreatedInfo} from "components/ui"
import {handleError, loadImage, toastError, toastSuccess} from "helpers"
import {
    DigitalDocumentFieldSetUp,
    TaskSubtype,
    UserType,
    DefaultDataType,
    ParticipantType,
    TaskType,
    SignatureMethod,
    TaskStatus
} from "types/tasks"
import {taskService} from "services"
import {dataTypes, defaultDataTypes, checkboxTypes, initialTypes, studentDataValues} from "sections/Tasks/data"
import {DefinedUser, HeaderBody, FieldAction} from "./parts"
import styles from "./DigitalDocSetUp.module.css"
import {BaseLoading} from "components"
import * as queryString from "query-string"

function reducer(state, action) {
    return {...state, ...action}
}

export const SIGNATURE_METHOD_OPTIONS = [
    {id: SignatureMethod.HAND, name: "Sign by hand"},
    {id: SignatureMethod.KEYBOARD, name: "Sign by keyboard"},
    {id: SignatureMethod.BOTH, name: "Allow both signing methods"}
]

export function DigitalDocSetUp(props) {
    const {taskData, isCreation, onCloseDigitalDocSetup} = props
    const bodyRef = useRef(null)
    const wrapRef = useRef(null)
    const pageRef = useRef(null)
    const initialState = {
        fields: [],
        activeField: null,
        fieldBodyContent: null,
        isSaving: false,
        isImgsLoaded: false,
        definedUserTypes: [
            {id: UserType.STUDENT, name: "Student"},
            {id: UserType.STAFF, name: "Staff"},
            {id: UserType.OTHERS, name: "Others"}
        ],
        participants: [],
        definedUsers: [],
        combinedColor: {},
        scaleInfo: {},
        previousFieldInfo: {
            width: 32,
            height: 32,
            signatureTypes: []
        }
    }
    const [
        {
            fields,
            activeField,
            fieldBodyContent,
            isSaving,
            isImgsLoaded,
            definedUserTypes,
            definedUsers,
            participants,
            combinedColor,
            scaleInfo,
            previousFieldInfo
        },
        dispatch
    ] = useReducer(reducer, initialState)
    const history = useHistory()
    const location = useLocation()
    const queryParams = queryString.parse(location.search)
    const [errorFieldIds, setErrorFieldIds] = useState(null)
    const margin = 10

    useEffect(() => {
        initData()
    }, [])

    const initSignature = (field, {placeInformation, newFieldValues, scaleHeight, scaleWidth, pageHeight}) => {
        if (placeInformation && placeInformation["dateField"]) {
            field.isAddDateTime = true
            const dateFieldPlaceInfo = scalePlaceInformation(
                placeInformation["dateField"],
                {scaleHeight, scaleWidth},
                false
            )
            dateFieldPlaceInfo.top = dateFieldPlaceInfo.top + (field.page - 1) * (pageHeight + margin)
            newFieldValues.push({
                id: uuidV4(),
                type: DigitalDocumentFieldSetUp.DATE_FIELD,
                parentId: field.id,
                parentSignatureTypeId: get(field, "signatureTypes[0].id", null),
                placeInformation: dateFieldPlaceInfo
            })
        }
        if (field.signatureMethod) {
            const methodById = keyBy(SIGNATURE_METHOD_OPTIONS, "id")
            field.signatureMethod = methodById[field.signatureMethod]
        }
    }

    const initData = async () => {
        let task = taskData
        try {
            task = await taskService.getOne(taskData.id)
            await Promise.all(task.digitalDocument.pages.map((image) => loadImage(image)))
        } catch (error) {
            console.log("Failed to load images", error)
        } finally {
            dispatch({isImgsLoaded: true})
        }
        const newFieldValues = []
        const signers = task.digitalDocument.signers
        const {scaleHeight, scaleWidth, pageHeight} = getScaleInfo()
        const definedUserTypesKeyById = keyBy(definedUserTypes, "id")
        const dataTypesKeyById = keyBy(dataTypes, "id")
        const defaultDataTypesKeyById = keyBy(defaultDataTypes, "id")
        const studentData = flatten(studentDataValues.map((item) => item.options))
        const studentDataValuesKeyById = keyBy(studentData, "id")
        const checkboxTypesKeyById = keyBy(checkboxTypes, "id")
        const initialTypesKeyById = keyBy(initialTypes, "id")
        const signersKeyById = keyBy(signers, "id")
        const newFields = task.digitalDocument.fields.map((field) => {
            const placeInformation = field.placeInformation[0]
            const fieldValues = field.fieldValues || []
            field.signatureTypes = field.signerIds.map((signerId) => {
                const signerData = signersKeyById[signerId]
                return {id: signerData.id, type: signerData.type, label: signerData.label}
            })
            field.required = !!field.required
            if (placeInformation) {
                const {height, left, top, width} = placeInformation
                field.placeInformation = scalePlaceInformation(
                    {height, left, top, width},
                    {scaleHeight, scaleWidth},
                    false
                )
                field.placeInformation.top = field.placeInformation.top + (field.page - 1) * (pageHeight + margin)
            }
            switch (field.type) {
                case DigitalDocumentFieldSetUp.SIGNATURE: {
                    initSignature(field, {placeInformation, newFieldValues, scaleHeight, scaleWidth, pageHeight})
                    break
                }
                case DigitalDocumentFieldSetUp.INITIAL: {
                    const fieldValuesWithoutParent = fieldValues.filter((fieldValue) => {
                        const {height, left, top, width} = placeInformation
                        const {
                            height: heightFieldValue,
                            left: leftFieldValue,
                            top: topFieldValue,
                            width: widthFieldValue
                        } = fieldValue.position
                        const parentField = {height, left, top, width}
                        const childField = {
                            height: heightFieldValue,
                            left: leftFieldValue,
                            top: topFieldValue,
                            width: widthFieldValue
                        }
                        return !isEqual(childField, parentField)
                    })
                    if (fieldValuesWithoutParent.length) {
                        const initialFieldValues = fieldValuesWithoutParent.map((fieldValue) => {
                            const {height, left, top, width} = fieldValue.position
                            const fieldValuePlaceInformation = scalePlaceInformation(
                                {height, left, top, width},
                                {scaleHeight, scaleWidth},
                                false
                            )
                            const childFieldPage = fieldValue.page || field.page
                            fieldValuePlaceInformation.top =
                                fieldValuePlaceInformation.top + (childFieldPage - 1) * (pageHeight + margin)
                            return {
                                id: fieldValue.id,
                                type: DigitalDocumentFieldSetUp.INITIAL,
                                parentId: field.id,
                                parentSignatureTypeId: get(field, "signatureTypes[0].id", null),
                                label: fieldValue.label,
                                placeInformation: fieldValuePlaceInformation,
                                signatureTypes: field.signerIds.map((signerId) => {
                                    const signerData = signersKeyById[signerId]
                                    return {id: signerData.id, type: signerData.type, label: signerData.label}
                                })
                            }
                        })
                        newFieldValues.splice(newFieldValues.length, 0, ...initialFieldValues)
                    }
                    field.initialType = initialTypesKeyById[field.initialType]
                    break
                }
                case DigitalDocumentFieldSetUp.TEXT: {
                    field.dataType = dataTypesKeyById[field.dataType]
                    if (field.defaultDataType === DefaultDataType.STUDENT_DATA) {
                        field.defaultData = studentDataValuesKeyById[field.defaultData]
                    }
                    field.defaultDataType = defaultDataTypesKeyById[field.defaultDataType]
                    field.defaultParticipant = signersKeyById[+field.defaultParticipant]
                    break
                }
                case DigitalDocumentFieldSetUp.CHECKBOX: {
                    const fieldValuesWithoutParent = fieldValues.filter((fieldValue) => {
                        const {height, left, top, width} = placeInformation
                        const {
                            height: heightFieldValue,
                            left: leftFieldValue,
                            top: topFieldValue,
                            width: widthFieldValue
                        } = fieldValue.position
                        const parentField = {height, left, top, width}
                        const childField = {
                            height: heightFieldValue,
                            left: leftFieldValue,
                            top: topFieldValue,
                            width: widthFieldValue
                        }
                        return !isEqual(childField, parentField)
                    })
                    if (fieldValuesWithoutParent.length) {
                        const checkboxFieldValues = fieldValuesWithoutParent.map((fieldValue) => {
                            const {height, left, top, width} = fieldValue.position
                            const fieldValuePlaceInformation = scalePlaceInformation(
                                {height, left, top, width},
                                {scaleHeight, scaleWidth},
                                false
                            )
                            const childFieldPage = fieldValue.page || field.page
                            fieldValuePlaceInformation.top =
                                fieldValuePlaceInformation.top + (childFieldPage - 1) * (pageHeight + margin)
                            return {
                                id: fieldValue.id,
                                type: DigitalDocumentFieldSetUp.CHECKBOX,
                                parentId: field.id,
                                parentSignatureTypeId: get(field, "signatureTypes[0].id", null),
                                label: fieldValue.label,
                                placeInformation: fieldValuePlaceInformation,
                                signatureTypes: field.signerIds.map((signerId) => {
                                    const signerData = signersKeyById[signerId]
                                    return {id: signerData.id, type: signerData.type, label: signerData.label}
                                })
                            }
                        })
                        newFieldValues.splice(newFieldValues.length, 0, ...checkboxFieldValues)
                    }
                    field.checkboxType = checkboxTypesKeyById[field.checkboxType]
                    break
                }
                default:
                    break
            }
            return field
        })
        const allFields = [...newFields, ...newFieldValues]
        const newParticipants = task.digitalDocument.signers.map(({id, type, label, participantType}) => ({
            id,
            type,
            label,
            participantType,
            color: randomColor({luminosity: "bright", format: "rgba", alpha: 0.5})
        }))
        const newDefinedUsers = newParticipants.filter(
            (participant) => participant.participantType !== ParticipantType.UserDataOnly
        )
        let newDefinedUserTypes = task.digitalDocument.signers
            .map((signer) => signer.type)
            .map((signerType) => definedUserTypesKeyById[signerType])
        newDefinedUserTypes = uniqBy(newDefinedUserTypes, "id")
        const scaleInfo = getScaleInfo()
        dispatch({
            fields: allFields,
            definedUsers: newDefinedUsers,
            participants: newParticipants,
            definedUserTypes: newDefinedUserTypes,
            scaleInfo
        })
    }

    const onDragStart = (e, isFields = true) => {}

    const onDragStop = (_, data, newField) => {
        const {x: left, y: top} = data
        const newFields = fields.map((field) => {
            if (field.id === newField.id) {
                field.placeInformation = {...field.placeInformation, top, left}
            }
            return field
        })
        dispatch({fields: newFields})
    }

    const onResize = (_, {size}, newField) => {
        const newFields = fields.map((field) => {
            if (field.id === newField.id) {
                field.placeInformation = {...field.placeInformation, ...size}
            }
            return field
        })
        dispatch({fields: newFields, previousFieldInfo: {...previousFieldInfo, ...size}})
    }

    const setActiveField = (field) => {
        dispatch({activeField: field})
    }

    const onChangeField = (newField) => {
        const firstSignatureTypeId = get(newField, "signatureTypes[0].id", null)
        const newFields = fields.map((field) => {
            if (field.id === newField.id) {
                return {...field, ...newField}
            } else if (field.parentId === newField.id) {
                return {...field, parentSignatureTypeId: firstSignatureTypeId, signatureTypes: newField.signatureTypes}
            }
            return field
        })
        if (newField.signatureTypes && newField.signatureTypes.length > 0) {
            previousFieldInfo.signatureTypes = newField.signatureTypes
        }
        dispatch({fields: newFields, previousFieldInfo})
        return newFields
    }

    const onChangeFieldValue = (parentId, id, value) => {
        const newFields = fields.map((field) => {
            if (field.id === parentId) {
                field.fieldValues = field.fieldValues.map((fieldValue) => {
                    if (fieldValue.id === id) {
                        return {...fieldValue, ...value}
                    }
                    return fieldValue
                })
                return {...field}
            }
            return field
        })
        const fieldBodyContent = newFields.find((item) => item.id === parentId)
        dispatch({fields: newFields, fieldBodyContent})
    }

    const onAddField = (type) => {
        const signatureTypes =
            previousFieldInfo.signatureTypes && previousFieldInfo.signatureTypes.length > 0
                ? previousFieldInfo.signatureTypes
                : head(definedUsers)
                ? [head(definedUsers)]
                : []
        const newField: any = {
            id: uuidV4(),
            type,
            label: "",
            required: [DigitalDocumentFieldSetUp.SIGNATURE, DigitalDocumentFieldSetUp.INITIAL].includes(type),
            signatureTypes,
            defaultParticipant: head(participants),
            placeInformation: {
                width: previousFieldInfo.width,
                height: previousFieldInfo.height,
                top: wrapRef.current.scrollTop,
                left: 0
            }
        }
        switch (type) {
            case DigitalDocumentFieldSetUp.INITIAL:
                newField.initialType = head(initialTypes)
                break
            case DigitalDocumentFieldSetUp.TEXT:
                newField.dataType = head(dataTypes)
                newField.defaultDataType = head(defaultDataTypes)
                break
            case DigitalDocumentFieldSetUp.CHECKBOX:
                newField.checkboxType = head(checkboxTypes)
                break
            default:
                break
        }
        if (type === DigitalDocumentFieldSetUp.INITIAL || type === DigitalDocumentFieldSetUp.CHECKBOX) {
            newField.fieldValues = [
                {
                    id: uuidV4(),
                    type,
                    parentId: newField.id,
                    parentSignatureTypeId: get(newField, "signatureTypes[0].id", null),
                    label: "",
                    placeInformation: {...newField.placeInformation}
                }
            ]
        }
        fields.push(newField)
        dispatch({fields, activeField: newField, fieldBodyContent: newField})
    }

    const onAddNewFieldValue = (parentId, fieldValue, newFieldsData = fields) => {
        if (fieldValue.type === DigitalDocumentFieldSetUp.DATE_FIELD) {
            newFieldsData.push(fieldValue)
            const fieldBodyContent = newFieldsData.find((item) => item.id === parentId)
            dispatch({fields: newFieldsData, fieldBodyContent})
        } else {
            const newFields = newFieldsData.map((field) => {
                if (field.id === parentId) {
                    field.fieldValues.push(fieldValue)
                    return {...field}
                }
                return field
            })
            newFields.push(fieldValue)
            const fieldBodyContent = newFields.find((item) => item.id === parentId)
            dispatch({fields: newFields, fieldBodyContent})
        }
    }

    const onRemoveFieldValue = (parentId, id, newFieldsData = fields) => {
        let newFields = []
        if (id === DigitalDocumentFieldSetUp.DATE_FIELD) {
            newFields = newFieldsData.filter((field) => {
                return !(field.parentId === parentId && field.type === DigitalDocumentFieldSetUp.DATE_FIELD)
            })
        } else {
            newFields = newFieldsData
                .map((field) => {
                    if (field.id === parentId) {
                        field.fieldValues = field.fieldValues.filter((fieldValue) => {
                            return fieldValue.id !== id
                        })
                        return {...field}
                    }
                    return field
                })
                .filter((field) => {
                    if (field.parentId === parentId && field.id === id) {
                        return false
                    }
                    return true
                })
        }
        const fieldBodyContent = newFields.find((item) => item.id === parentId)
        dispatch({fields: newFields, fieldBodyContent})
    }

    const onClickDelete = (deletedField) => {
        const newFields = fields.filter((field) => {
            if (!field.parentId) {
                return field.id !== deletedField.id
            }
            return field.parentId !== deletedField.id
        })
        dispatch({fields: newFields, activeField: null})
    }

    const onClickField = (field) => {
        const newPreviousFieldInfo = {
            ...previousFieldInfo,
            width: field.placeInformation.width,
            height: field.placeInformation.height
        }
        if (field.parentId) {
            const fieldBodyContent = fields.find((item) => item.id === field.parentId)
            dispatch({fieldBodyContent, previousFieldInfo: newPreviousFieldInfo})
        } else {
            dispatch({fieldBodyContent: field, previousFieldInfo: newPreviousFieldInfo})
        }
    }

    const getIconByType = (type) => {
        switch (type) {
            case DigitalDocumentFieldSetUp.SIGNATURE:
                return "SIGN_HAND_DRAWN"
            case DigitalDocumentFieldSetUp.INITIAL:
                return "INITIALS"
            case DigitalDocumentFieldSetUp.TEXT:
                return "TEXT"
            case DigitalDocumentFieldSetUp.CHECKBOX:
                return "CHECKBOX_CHECKED"
            case DigitalDocumentFieldSetUp.DATE_FIELD:
                return "CALENDAR_FILL_INPUT"
            default:
                return "SIGN_HAND_DRAWN"
        }
    }

    const scalePlaceInformation = (placeInformation, {scaleHeight, scaleWidth}, isSubmit = true) => {
        const {top, left, width, height} = placeInformation
        if (isSubmit) {
            return {
                width: width * scaleWidth,
                height: height * scaleHeight,
                top: top * scaleHeight,
                left: left * scaleWidth
            }
        }
        return {
            width: width / scaleWidth,
            height: height / scaleHeight,
            top: top / scaleHeight,
            left: left / scaleWidth
        }
    }

    const getScaleInfo = () => {
        const pageHeight = pageRef?.current?.offsetHeight
        const pageWidth = pageRef?.current?.offsetWidth
        const {width = pageWidth, height = pageHeight} = taskData.digitalDocument.pages?.[0] || {}
        const scaleHeight = height / pageHeight
        const scaleWidth = width / pageWidth
        return {scaleHeight, scaleWidth, pageHeight, pageWidth}
    }

    const getDataSubmit = () => {
        const {scaleHeight, scaleWidth, pageHeight} = getScaleInfo()
        let tempCount = 1
        const timestamp = Date.now()
        const fieldValuesGroupByParentId = groupBy(fields, "parentId")
        const tempFields = cloneDeep(fields)
        const fieldsData = tempFields
            .filter((field) => !field.parentId)
            .map((field, index) => {
                const {top} = field.placeInformation
                const page = Math.ceil((top + margin) / (pageHeight + margin))
                const newTop = top - (page - 1) * (pageHeight + margin)
                field.placeInformation.top = newTop
                field.page = page
                const item: any = {
                    dDocId: taskData.digitalDocument.id,
                    fieldOrder: index + 1,
                    label: field.label || "",
                    page,
                    required: field.required ? 1 : 0,
                    // TODO remove
                    signatureType: "",
                    signerIds: field.signatureTypes
                        ? (field.signatureTypes || []).map((signatureType) => signatureType.id)
                        : field.signerIds,
                    type: field.type,
                    placeInformation: [scalePlaceInformation(field.placeInformation, {scaleHeight, scaleWidth})]
                }
                switch (field.type) {
                    case DigitalDocumentFieldSetUp.SIGNATURE: {
                        item.signatureMethod = field.signatureMethod?.id
                        const dateField = fields.find(
                            (item) => item.parentId === field.id && item.type === DigitalDocumentFieldSetUp.DATE_FIELD
                        )
                        if (dateField) {
                            const {top} = dateField.placeInformation
                            const newTop = top - (page - 1) * (pageHeight + margin)
                            dateField.placeInformation.top = newTop
                            item.placeInformation = JSON.stringify([
                                {
                                    ...item.placeInformation[0],
                                    dateField: scalePlaceInformation(dateField.placeInformation, {
                                        scaleHeight,
                                        scaleWidth
                                    })
                                }
                            ])
                        } else {
                            item.placeInformation = JSON.stringify(item.placeInformation)
                        }
                        break
                    }
                    case DigitalDocumentFieldSetUp.INITIAL: {
                        const fieldValues = (fieldValuesGroupByParentId[field.id] || []).map((childField) => {
                            const {top} = childField.placeInformation
                            const childFieldPage = Math.ceil((top + margin) / (pageHeight + margin))
                            const newTop = top - (childFieldPage - 1) * (pageHeight + margin)
                            childField.page = childFieldPage
                            childField.placeInformation.top = newTop
                            return childField
                        })
                        fieldValues.unshift(field)
                        item.fieldValues = fieldValues.map((fieldValue, index) => {
                            tempCount += 1
                            return {
                                id: timestamp + tempCount,
                                index: index + 1,
                                label: get(field.fieldValues, [index, "label"], ""),
                                page: fieldValue.page,
                                position: scalePlaceInformation(fieldValue.placeInformation, {
                                    scaleHeight,
                                    scaleWidth
                                })
                            }
                        })
                        item.fieldValues = JSON.stringify(item.fieldValues)
                        item.initialType = get(field.initialType, "id", "")
                        item.placeInformation = JSON.stringify(item.placeInformation)
                        break
                    }
                    case DigitalDocumentFieldSetUp.TEXT:
                        item.dataType = get(field, "dataType.id", "")
                        item.defaultDataType = get(field, "defaultDataType.id", "")
                        item.defaultParticipant = get(field, "defaultParticipant.id", "") || null
                        if (item.defaultDataType === DefaultDataType.MANUAL_TEXT) {
                            item.defaultData = field.defaultData || ""
                        } else if (item.defaultDataType === DefaultDataType.STUDENT_DATA) {
                            item.defaultData = get(field.defaultData, "id", "")
                        }
                        item.placeInformation = JSON.stringify(item.placeInformation)
                        break
                    case DigitalDocumentFieldSetUp.CHECKBOX: {
                        const fieldValues = (fieldValuesGroupByParentId[field.id] || []).map((childField) => {
                            const {top} = childField.placeInformation
                            const childFieldPage = Math.ceil((top + margin) / (pageHeight + margin))
                            const newTop = top - (childFieldPage - 1) * (pageHeight + margin)
                            childField.page = childFieldPage
                            childField.placeInformation.top = newTop
                            return childField
                        })
                        fieldValues.unshift(field)
                        item.fieldValues = fieldValues.map((fieldValue, index) => {
                            tempCount += 1
                            return {
                                id: timestamp + tempCount,
                                index: index + 1,
                                page: fieldValue.page,
                                label: get(field.fieldValues, [index, "label"], ""),
                                position: scalePlaceInformation(fieldValue.placeInformation, {
                                    scaleHeight,
                                    scaleWidth
                                })
                            }
                        })
                        item.fieldValues = JSON.stringify(item.fieldValues)
                        item.checkboxType = get(field.checkboxType, "id", "")
                        item.placeInformation = JSON.stringify(item.placeInformation)
                        break
                    }
                    default:
                        break
                }

                return item
            })
        return {
            taskId: taskData.id,
            fields: fieldsData
        }
    }

    const validateData = () => {
        const fieldsValidator = fields
            .filter((field) => !field.parentId)
            .map((field) => ({
                id: field.id,
                signerIds: (field.signatureTypes || []).map((signatureType) => signatureType.id)
            }))
        const invalidSignerFieldIds = fieldsValidator
            .filter((field) => isEmpty(field.signerIds))
            .map((field) => field.id)
        if (!isEmpty(invalidSignerFieldIds)) {
            setErrorFieldIds(invalidSignerFieldIds)
            toastError("Please select at least 1 signer")
            return true
        }
        const invalidTextFieldIds = fields
            .filter(
                (field) =>
                    field.type === DigitalDocumentFieldSetUp.TEXT &&
                    field.defaultDataType?.id === DefaultDataType.STUDENT_DATA &&
                    (!field.defaultParticipant || !field.defaultData)
            )
            .map((field) => field.id)
        if (!isEmpty(invalidTextFieldIds)) {
            setErrorFieldIds(invalidTextFieldIds)
            toastError("Please select at participant info in text fields")
            return true
        }
        setErrorFieldIds(null)
        return false
    }

    const onClickSave = async () => {
        const isInvalid = validateData()
        if (isInvalid) {
            return
        }
        try {
            dispatch({isSaving: true})
            const dataSubmit = getDataSubmit()
            await taskService.editDigitalDocumentField(dataSubmit)
            let tab = taskData.subtype === TaskSubtype.LIBRARY ? 2 : 1
            if (taskData.subtype === TaskSubtype.LIBRARY && queryParams.id) {
                toastSuccess("Task updated successfully")
                onCloseDigitalDocSetup()
            } else {
                toastSuccess("Task submitted successfully")
                history.push(`/tasks?tab=${tab}`)
            }
        } catch (error) {
            dispatch({isSaving: false})
            handleError(error)
        } finally {
            dispatch({isSaving: false})
        }
    }

    const onChangeCombinedColor = (sortedSignerIds: string, color: string) => {
        const newCombinedColor = {...combinedColor}
        newCombinedColor[sortedSignerIds] = color
        dispatch({combinedColor: newCombinedColor})
    }

    const boundaryWidth = get(bodyRef.current, "offsetWidth", 300) - 32
    const boundaryHeight = get(bodyRef.current, "offsetHeight", 1000) - 32
    const isDraftTask = taskData.isDraft || taskData.status === TaskStatus.DRAFT
    const canEditable =
        isDraftTask ||
        isCreation ||
        (taskData.type === TaskType.DIGITAL_DOCUMENT && taskData.subtype === TaskSubtype.LIBRARY)

    return (
        <div ref={wrapRef} className={styles.wrap}>
            {!isImgsLoaded && <BaseLoading isShow />}
            <div className={styles.headerContainer}>
                <div style={{height: 12}}></div>
                <div className={styles.headerContentWrap}>
                    <div className={styles.headerWrap}>
                        <DefinedUser definedUserTypes={definedUserTypes} />
                        <HeaderBody
                            type={taskData.type}
                            taskId={taskData.id}
                            activeField={activeField}
                            isSaving={isSaving}
                            subtype={taskData.subtype}
                            canEditable={canEditable}
                            onAddField={onAddField}
                            getIconByType={getIconByType}
                            onClickSave={onClickSave}
                        />
                    </div>
                    <CreatedInfo data={taskData} userInfoKey="createdByUser" />
                </div>
            </div>
            <div ref={bodyRef} className={styles.body} style={{width: pageRef?.current?.offsetWidth}}>
                {fields.map((field) => (
                    <FieldAction
                        key={field.id}
                        field={field}
                        fieldBodyContent={fieldBodyContent}
                        activeField={activeField}
                        boundaryWidth={boundaryWidth}
                        boundaryHeight={boundaryHeight}
                        definedUsers={definedUsers}
                        participants={participants}
                        combinedColor={combinedColor}
                        scaleInfo={scaleInfo}
                        totalPage={taskData.digitalDocument.pages.length}
                        previousFieldInfo={previousFieldInfo}
                        canEditable={canEditable}
                        errorFieldIds={errorFieldIds}
                        onDragStart={onDragStart}
                        onDragStop={onDragStop}
                        onResize={onResize}
                        getIconByType={getIconByType}
                        onClickDelete={onClickDelete}
                        setActiveField={setActiveField}
                        onChangeField={onChangeField}
                        onAddNewFieldValue={onAddNewFieldValue}
                        onRemoveFieldValue={onRemoveFieldValue}
                        onClickField={onClickField}
                        onChangeFieldValue={onChangeFieldValue}
                        onChangeCombinedColor={onChangeCombinedColor}
                    />
                ))}
                <div className={styles.pageWrap}>
                    {(taskData.digitalDocument.pages || []).map((page, index) => (
                        <img
                            ref={pageRef}
                            key={index}
                            className={cx(styles.pageImg, {
                                [styles.lastPage]: index === taskData.digitalDocument.pages.length - 1
                            })}
                            src={page.url}
                            alt=""
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}
