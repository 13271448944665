import React, {useEffect, useState} from "react"
import {BasePopup} from "components/popup"
import styles from "./StatusForm.module.css"
import {BaseButton, BaseInput} from "components"
import {handleError} from "helpers"
import {FormLabel} from "components/Form"
import {KlassDropdown} from "components/Select"
import {admissionService} from "services"
import {useAllAvailableStudentStatuses} from "hooks/student-statuses/useAllAvailableStudentStatuses"
import {AdmissionsFunnelStatus} from "types/admissions"

type Props = {
    item?: AdmissionsFunnelStatus
    maxRank: number
    open: boolean
    onClose: () => void
    onSuccess?: () => void
}

export function StatusForm(props: Props) {
    const {item, maxRank, open, onClose, onSuccess} = props
    const [status, setStatus] = useState<Partial<AdmissionsFunnelStatus>>(item)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const isEdit = !!item

    const {statuses: availableStatuses} = useAllAvailableStudentStatuses()

    useEffect(() => {
        if (item) {
            setStatus(item)
        } else {
            setStatus({})
        }
    }, [item])

    const onSubmit = async () => {
        try {
            setIsSubmitting(true)
            const {id, statusId} = status
            let payload: Partial<AdmissionsFunnelStatus> = {
                statusId
            }
            if (isEdit) {
                await admissionService.updateFunnelStatus({id, ...payload})
            } else {
                await admissionService.createFunnelStatus(payload)
            }
            onSuccess?.()
        } catch (e) {
            handleError(e)
        } finally {
            setIsSubmitting(false)
        }
    }

    const onChangeData = (key: string, value: any) => {
        status[key] = value
        setStatus({...status})
    }

    return (
        <BasePopup
            isShow={open}
            onClose={onClose}
            leftIcon={isEdit ? "PENCIL" : "PLUS_CIRCLE"}
            leftIconColor="#fff"
            width="40vw"
            // height="50vh"
            closable={true}
            destroyOnClose={true}>
            <div className={styles.headingTitle}>{isEdit ? "Edit Funnel Status" : "Add Funnel Status"}</div>
            <div className={styles.field}>
                <FormLabel label="Priority" />
                <BaseInput type="text" value={isEdit ? item.priorityRank : maxRank} disabled={true} />
            </div>
            <div className={styles.field}>
                <FormLabel label="Status" />
                <KlassDropdown
                    value={item?.statusId && availableStatuses.filter((x) => x.statusId === item.statusId)[0]}
                    placeholder="Select"
                    onChange={(option: any) => onChangeData("statusId", option.statusId)}
                    valueKey="statusId"
                    labelKey="name"
                    options={availableStatuses}
                />
            </div>
            <div className={styles.doneBtn}>
                <BaseButton title="Submit" onClick={onSubmit} className={styles.saveBtn} loading={isSubmitting} />
            </div>
        </BasePopup>
    )
}
