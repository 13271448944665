import DepartmentSubunitService from "./DepartmentSubunitService"
import TermsService from "./TermsService"
import WorkflowService from "./WorkflowService"
import UserService from "./UserService"
import ResourceService from "./ResourceService"
import ResourceServiceV3 from "./ResourceServiceV3"
import AuthService from "./AuthService"
import BaseService from "./Base"
import CalendarService from "./CalendarService"
import MajorService from "./MajorService"
import MajorVersionsService from "./MajorVersionsService"
import SAPService from "./SAPService"
import CourseService from "./CourseService"
import IntegrationsService from "./IntegrationsService"
import AttendanceService from "./AttendanceService"
import CourseScheduleService from "./CourseScheduleService"
import ChatService from "./ChatService"
import SettingsService from "./SettingsService"
import {RateSheetsService} from "./RateSheetsService"
import {CoaTemplatesService} from "./CoaTemplatesService"
import TermProgramCoaService from "./TermProgramCoaService"
import ClientsService from "./ClientsService"
import CampusesService from "./CampusesService"
import ExternshipsService from "./ExternshipsService"
import DegreeLevelService from "./DegreeLevelService"
import {WorkScheduleService} from "./WorkScheduleService"
import {CalendarSettingsCustomService} from "./CalendarSettingsCustomService"
import {EventTypeService} from "./EventTypeService"
import FinAidService from "./FinAidService"
import FundSourcesService from "./FundSourcesService"
import DepartmentsService from "./DepartmentsService"
import ResourceTypeService from "./ResourceTypeService"
import ReportsService from "./ReportsService"
import AdmissionService from "./AdmissionService"
import UserServiceV3 from "./UserServiceV3"
import StudentService from "./StudentService"
import ProfileContactsServiceV3 from "./ProfileContactsServiceV3"
import UserContactsServiceV3 from "./UserContactsServiceV3"
import StudentCoursesV2 from "./StudentCoursesV2"
import StudentTermsV2 from "./StudentTermsV2"
import SettingActivityService from "./SettingActivityService"
import SettingStageService from "./SettingStageService"
import SettingStatusService from "./SettingStatusService"
import SettingChecklistService from "./SettingChecklistService"
import StudentActivitiesService from "./StudentActivitiesService"
import DocumentService from "./DocumentService"
import SettingExternshipSiteService from "./SettingExternshipSiteService"
import SettingSiteSupervisorService from "./SettingSiteSupervisorService"
import StudentStaffContactsServiceV3 from "./StudentStaffContactsServiceV3"
import UserPersonalContactsServiceV3 from "./UserPersonalContactsServiceV3"
import StudentStaffContactRolesServiceV3 from "./StudentStaffContactRolesServiceV3"
import UserPersonalContactRolesServiceV3 from "./UserPersonalContactRolesServiceV3"
import StatsService from "./StatsService"
import UserProfileServiceV3 from "./UserProfileServiceV3"
import ProfileService from "./ProfileService"
import FerpaService from "./FerpaService"
import TaskService from "./TaskService"
import TagService from "./TagService"
import RolePermissionService from "./RolePermissionService"
import SettingAcademicTrackService from "./SettingAcademicTrackService"
import AddressService from "./AddressService"
import AdmissionsReportsService from "./AdmissionsReportsService"
import MediaPrivacyService from "./MediaPrivacyService"
import NotificationService from "./NotificationService"
import BrandingsService from "./BrandingsService"
import AptitudeTestService from "./AptitudeTestService"
import MediaLibraryService from "./MediaLibraryService"
import StudentServicesService from "./StudentServicesService"
import StudentAccountService from "./StudentAccountService"
import StudentBillingsService from "services/StudentBillingsService"
import StudentLedgerService from "services/StudentLedgerService"
import ISIRService from "./ISIRService"
import NoticeService from "./NoticeService"
import ProfileCampusService from "./ProfileCampusService"
import AcademicPlansService from "./AcademicPlansService"
import CredentialLevelService from "./CredentialLevelService"
import CurriculumEnrollmentStatusService from "./CurriculumEnrollmentStatusService"
import DisbursementService from "./DisbursementService"
import DisbursementSettingsService from "./DisbursementSettingsService"
import TermsServiceV2 from "./TermsServiceV2"
import TermsMajors from "./TermsMajors"
import TermsCourses from "./TermsCourses"
import LoanEnrollmentLevelsService from "./LoanEnrollmentLevelsService"
import ConcernsService from "./ConcernsService"
import AutoDisableAccessService from "./AutoDisableAccessService"
import ConcernSettingsService from "./ConcernSettingsService"
import MissedAttendanceSettingService from "./MissedAttendanceSettingService"
import NewsFeedPostsService from "./NewsFeedPostsService"
import AcademicGradingSystem from "./AcademicGradingSystem"
import ScrubReportsService from "./ScrubReportsService"
import SettingEinService from "./SettingEinService"
import StudentActivityLogService from "./StudentActivityLogService"
import DegreeAuditService from "./DegreeAuditService"
import UniversityService from "./UniversityService"
import TransferCreditsService from "./TransferCreditsV3"
import PreviousCompletedCreditsService from "./PreviousCompletedCreditsService"
import GroupsService from "./GroupsService"
import FieldsService from "./FieldsService"
import AiSchedulingParametersService from "./AiSchedulingParametersService"
import WebFormService from "./WebFormService"
import LinksService from "./LinksService"
import LeadsService from "./LeadsService"
import LegacyTestService from "./LegacyTestService"
import SmsService from "./SmsService"
import PaymentsService from "./PaymentsService"
import StudentPaymentService from "./StudentPaymentService"
import AcademicCourseGradesService from "./AcademicCourseGradesService"
import InstructionalService from "./InstructionalService"
import CareerService from "./CareerService"
import ContactStrategyService from "./ContactStrategyService"
import SettingEmailTemplatesService from "./SettingEmailTemplatesService"
import AcademicIntegrationMapping from "./AcademicIntegrationMapping"
import SocketClient, {SOCKET_PATH_EDULAR_CORE} from "./SocketClient"
import CollegesService from "./CollegesService"
import RetentionService from "./RetentionService"
import ProfileIDCardsService from "./ProfileIDCardsService"
import AutoDialerService from "./AutoDialerService"
import FinAidReportsService from "./FinAidReportsService"
import SettingStudentStatusService from "./SettingStudentStatusService"
import UserEducationalBackgroundService from "./UserEducationalBackgroundService"
import UserCertificationService from "./UserCertificationService"
import UserProfessionalExperienceService from "./UserProfessionalExperienceService"
import UserProfessionalEducationalDevelopmentService from "./UserProfessionalEducationalDevelopmentService"
import UserServiceSessionsAttendedService from "./UserServiceSessionsAttendedService"
import SMSChatService from "./SMSChatService"
import NewStudentStatusService from "./NewStudentStatusService"
import CourseLevelService from "./CourseLevelService"
import ZoomService from "./ZoomService"
import WithdrawalCancellationService from "./WithdrawalCancellationService"
import SettingLeadTypesService from "./SettingLeadTypesService"
import ChatServiceV2 from "./ChatServiceV2"
import StudentMarketingFieldsService from "./StudentMarketingFieldsService"
import EnrollmentRosterService from "./EnrollmentRosterService"
import StudentSubmissionsService from "./StudentSubmissionsService"
import FileService from "./FileService"
import GeneralSettingsService from "./GeneralSettingsService"
import COAFormService from "./COAFormService"
import AdmissionsLegacyService from "./AdmissionsLegacyService"
import NpsasReportsService from "./NpsasReportsService"
import ImportService from "./ImportService"
import SavedFilterService from "./SavedFilterService"
import StudentServiceV2 from "./StudentServiceV2"
import StudentServiceV3 from "./StudentServiceV3"
import SettingActivityGroupService from "./SettingActivityGroupService"
import PhasesService from "./PhasesService"
import ShiftsService from "./ShiftsService"
import StudentExternshipService from "./StudentExternshipService"
import DocumentTypesService from "./DocumentTypesService"

const coreSocket = new SocketClient(process.env.REACT_APP_EDULAR_CORE_API_BASE_URL, SOCKET_PATH_EDULAR_CORE)
const termsService = new TermsService()
const termsMajorService = new TermsMajors()
const termsCoursesService = new TermsCourses()
const workflowService = new WorkflowService()
const userService = new UserService()
const sapService = new SAPService()
const resourceService = new ResourceService()
const resourceServiceV3 = new ResourceServiceV3()
const authService = new AuthService()
const calendarService = new CalendarService()
const majorService = new MajorService()
const majorVersionsService = new MajorVersionsService()
const courseService = new CourseService()
const integrationsService = new IntegrationsService()
const attendanceService = new AttendanceService()
const instructionalService = new InstructionalService()
const courseScheduleService = new CourseScheduleService()
const rateSheetsService = new RateSheetsService()
const chatService = new ChatService()
const settingsService = new SettingsService()
const generalSettingsService = new GeneralSettingsService()
const coaTemplatesService = new CoaTemplatesService()
const termProgramCoaService = new TermProgramCoaService()
const clientsService = new ClientsService()
const campusesService = new CampusesService()
const externshipsService = new ExternshipsService()
const degreeLevelService = new DegreeLevelService()
const credentialLevelService = new CredentialLevelService()
const curriculumEnrollmentStatusService = new CurriculumEnrollmentStatusService()
const workScheduleService = new WorkScheduleService()
const calendarSettingsCustomService = new CalendarSettingsCustomService()
const eventTypeService = new EventTypeService()
const finAidService = new FinAidService()
const fundSourcesService = new FundSourcesService()
const departmentsService = new DepartmentsService()
const departmentSubunitService = new DepartmentSubunitService()
const resourceTypeService = new ResourceTypeService()
const reportsService = new ReportsService()
const admissionService = new AdmissionService()
const userServiceV3 = new UserServiceV3()
const studentService = new StudentService()
const profileContactsServiceV3 = new ProfileContactsServiceV3()
const userContactsServiceV3 = new UserContactsServiceV3()
const studentCoursesV2 = new StudentCoursesV2()
const studentTermsV2 = new StudentTermsV2()
const settingActivityService = new SettingActivityService()
const settingExternshipSiteService = new SettingExternshipSiteService()
const settingSiteSupervisorService = new SettingSiteSupervisorService()
const settingStageService = new SettingStageService()
const settingStatusService = new SettingStatusService()
const settingChecklistService = new SettingChecklistService()
const studentActivitiesService = new StudentActivitiesService()
const documentService = new DocumentService()
const studentStaffContactsServiceV3 = new StudentStaffContactsServiceV3()
const userPersonalContactsServiceV3 = new UserPersonalContactsServiceV3()
const studentStaffContactRolesServiceV3 = new StudentStaffContactRolesServiceV3()
const userPersonalContactRolesServiceV3 = new UserPersonalContactRolesServiceV3()
const statsService = new StatsService()
const userProfileServiceV3 = new UserProfileServiceV3()
const profileService = new ProfileService()
const ferpaService = new FerpaService()
const taskService = new TaskService()
const tagService = new TagService()
const rolePermissionService = new RolePermissionService()
const settingAcademicTrackService = new SettingAcademicTrackService()
const addressService = new AddressService()
const admissionsReportsService = new AdmissionsReportsService()
const mediaPrivacyService = new MediaPrivacyService()
const notificationService = new NotificationService()
const brandingsService = new BrandingsService()
const aptitudeTestService = new AptitudeTestService()
const mediaLibraryService = new MediaLibraryService()
const studentServicesService = new StudentServicesService()
const studentAccountService = new StudentAccountService()
const studentBillingsService = new StudentBillingsService()
const studentLedgerService = new StudentLedgerService()
const isirService = new ISIRService()
const noticeService = new NoticeService()
const profileCampusService = new ProfileCampusService()
const academicPlansService = new AcademicPlansService()
const academicGradingSystem = new AcademicGradingSystem()
const academicCourseGradesService = new AcademicCourseGradesService()
const degreeAuditService = new DegreeAuditService()
const disbursementService = new DisbursementService()
const disbursementSettingsService = new DisbursementSettingsService()
const termsServiceV2 = new TermsServiceV2()
const loanEnrollmentLevelsService = new LoanEnrollmentLevelsService()
const concernsService = new ConcernsService()
const autoDisableAccessService = new AutoDisableAccessService()
const concernSettingsService = new ConcernSettingsService()
const missedAttendanceSettingService = new MissedAttendanceSettingService()
const newsFeedPostsService = new NewsFeedPostsService()
const scrubReportsService = new ScrubReportsService()
const settingEinService = new SettingEinService()
const studentActivityLogService = new StudentActivityLogService()
const universityService = new UniversityService()
const transferCreditsService = new TransferCreditsService()
const previousCompletedCreditsService = new PreviousCompletedCreditsService()
const groupsService = new GroupsService()
const fieldsService = new FieldsService()
const aiSchedulingParametersService = new AiSchedulingParametersService()
const webFormService = new WebFormService()
const linksService = new LinksService()
const leadsService = new LeadsService()
const legacyTestService = new LegacyTestService()
const smsService = new SmsService()
const paymentsService = new PaymentsService()
const studentPaymentService = new StudentPaymentService()
const careerService = new CareerService()
const contactStrategyService = new ContactStrategyService()
const settingEmailTemplatesService = new SettingEmailTemplatesService()
const academicIntegrationMapping = new AcademicIntegrationMapping()
const retentionService = new RetentionService()
const collegesService = new CollegesService()
const profileIDCardsService = new ProfileIDCardsService()
const autoDialerService = new AutoDialerService()
const finAidReportsService = new FinAidReportsService()
const settingStudentStatusService = new SettingStudentStatusService()
const userEducationalBackgroundService = new UserEducationalBackgroundService()
const userCertificationService = new UserCertificationService()
const userProfessionalExperienceService = new UserProfessionalExperienceService()
const userProfessionalEducationalDevelopmentService = new UserProfessionalEducationalDevelopmentService()
const userServiceSessionsAttendedService = new UserServiceSessionsAttendedService()
const smsChatService = new SMSChatService()
const newStudentStatusService = new NewStudentStatusService()
const courseLevelService = new CourseLevelService()
const zoomService = new ZoomService()
const withdrawalCancellationService = new WithdrawalCancellationService()
const settingLeadTypesService = new SettingLeadTypesService()
const chatServiceV2 = new ChatServiceV2()
const studentMarketingFieldsService = new StudentMarketingFieldsService()
const enrollmentRosterService = new EnrollmentRosterService()
const studentSubmissionsService = new StudentSubmissionsService()
const fileService = new FileService()
const coaFormService = new COAFormService()
const admissionsLegacyService = new AdmissionsLegacyService()
const npsasReportsService = new NpsasReportsService()
const importService = new ImportService()
const savedFilterService = new SavedFilterService()
const studentServiceV2 = new StudentServiceV2()
const studentServiceV3 = new StudentServiceV3()
const settingActivityGroupService = new SettingActivityGroupService()
const phasesService = new PhasesService()
const shiftsService = new ShiftsService()
const studentExternshipService = new StudentExternshipService()
const documentTypesService = new DocumentTypesService()

export {
    coreSocket,
    termsService,
    termsMajorService,
    termsCoursesService,
    workflowService,
    userService,
    academicIntegrationMapping,
    sapService,
    resourceService,
    resourceServiceV3,
    authService,
    BaseService,
    calendarService,
    majorService,
    majorVersionsService,
    courseService,
    courseLevelService,
    integrationsService,
    attendanceService,
    instructionalService,
    courseScheduleService,
    chatService,
    settingsService,
    generalSettingsService,
    rateSheetsService,
    coaTemplatesService,
    termProgramCoaService,
    clientsService,
    campusesService,
    externshipsService,
    degreeLevelService,
    credentialLevelService,
    curriculumEnrollmentStatusService,
    finAidService,
    fundSourcesService,
    departmentsService,
    departmentSubunitService,
    workScheduleService,
    calendarSettingsCustomService,
    eventTypeService,
    reportsService,
    resourceTypeService,
    admissionService,
    userServiceV3,
    studentService,
    profileContactsServiceV3,
    userContactsServiceV3,
    studentCoursesV2,
    studentTermsV2,
    settingActivityService,
    settingExternshipSiteService,
    settingSiteSupervisorService,
    settingStageService,
    settingStatusService,
    settingChecklistService,
    studentActivitiesService,
    documentService,
    studentStaffContactsServiceV3,
    userPersonalContactsServiceV3,
    studentStaffContactRolesServiceV3,
    userPersonalContactRolesServiceV3,
    statsService,
    userProfileServiceV3,
    profileService,
    taskService,
    tagService,
    rolePermissionService,
    settingAcademicTrackService,
    addressService,
    admissionsReportsService,
    mediaPrivacyService,
    brandingsService,
    notificationService,
    aptitudeTestService,
    mediaLibraryService,
    studentServicesService,
    studentAccountService,
    studentBillingsService,
    studentLedgerService,
    isirService,
    noticeService,
    profileCampusService,
    academicPlansService,
    academicGradingSystem,
    disbursementService,
    disbursementSettingsService,
    termsServiceV2,
    loanEnrollmentLevelsService,
    concernsService,
    autoDisableAccessService,
    concernSettingsService,
    missedAttendanceSettingService,
    newsFeedPostsService,
    scrubReportsService,
    studentActivityLogService,
    universityService,
    degreeAuditService,
    transferCreditsService,
    previousCompletedCreditsService,
    settingEinService,
    groupsService,
    fieldsService,
    aiSchedulingParametersService,
    webFormService,
    smsService,
    paymentsService,
    studentPaymentService,
    linksService,
    leadsService,
    legacyTestService,
    academicCourseGradesService,
    ferpaService,
    careerService,
    contactStrategyService,
    settingEmailTemplatesService,
    collegesService,
    retentionService,
    profileIDCardsService,
    autoDialerService,
    finAidReportsService,
    settingStudentStatusService,
    userEducationalBackgroundService,
    userCertificationService,
    userProfessionalExperienceService,
    userProfessionalEducationalDevelopmentService,
    userServiceSessionsAttendedService,
    smsChatService,
    newStudentStatusService,
    zoomService,
    withdrawalCancellationService,
    settingLeadTypesService,
    chatServiceV2,
    enrollmentRosterService,
    studentSubmissionsService,
    fileService,
    studentMarketingFieldsService,
    coaFormService,
    admissionsLegacyService,
    npsasReportsService,
    importService,
    savedFilterService,
    studentServiceV2,
    studentServiceV3,
    settingActivityGroupService,
    phasesService,
    shiftsService,
    studentExternshipService,
    documentTypesService
}
