import {get} from "lodash"
import BaseService from "./Base"
import {CollegesCreatePayload, DeleteCollegesPayload, EditCollegesPayload, ListCollegesPayload} from "types/colleges"

class CollegesService extends BaseService {
    constructor() {
        super(process.env.REACT_APP_COLLEGE_API_BASE_URL)
    }

    getAllColleges = async (params: ListCollegesPayload) => {
        const response = await this.post("/v3/colleges/list", params)
        return get(response, "data", [])
    }

    createCollege = async (params: CollegesCreatePayload) => {
        const response = await this.post("/v3/colleges/create", params)
        return get(response, "data.data", {})
    }

    editCollege = async (id: number, params: EditCollegesPayload) => {
        const response = await this.post("/v3/colleges/edit", {...params, id})
        return get(response, "data.data", {})
    }

    deleteCollege = async (params: DeleteCollegesPayload) => {
        const response = await this.post("/v3/colleges/delete", params)
        return get(response, "data.data", {})
    }
}

export default CollegesService
