import React from "react"
import {Drawer, DrawerProps} from "antd"

export type BaseDrawerProps = DrawerProps & {
    isShow: boolean
    width: number
    onClose?: any
    children?: JSX.Element | any
}

export function BaseDrawer(props: BaseDrawerProps) {
    const {isShow, width, onClose, children, ...rest} = props

    return (
        <Drawer
            visible={isShow}
            width={width}
            onClose={onClose}
            maskClosable={false}
            closable={false}
            footer={null}
            bodyStyle={{padding: 0}}
            placement="right"
            destroyOnClose
            {...rest}>
            {children}
        </Drawer>
    )
}
