/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import {Modules, useEdularModulesContext} from "@edular/modules"
import {ActivityPanel, Checklist} from "sections/shared/student"
import {BaseDepartmentId} from "types/departments"

export function StudentTab(props) {
    const {studentId} = props
    const {isModuleEnable} = useEdularModulesContext()

    return (
        <div>
            {isModuleEnable(Modules.ActivitiesAndFollowups) && (
                <ActivityPanel studentId={props.studentId} departmentId={props.departmentId} />
            )}
            <Checklist studentId={studentId} departmentId={BaseDepartmentId.StudentServices} />
        </div>
    )
}
