import React from "react"
import {Icon} from "components/Icon"
import styles from "./StatusCards.module.css"

export function StatusCardItem(props) {
    const {title, total, isUp} = props.item

    return (
        <div className={styles.cardItem}>
            <p className={styles.cardItem__title}>{title}</p>
            <div className={styles.cardItemBody}>
                <div className={styles.cardItemStatusWrap}>
                    <p className={styles.cardItem__number}>{total}</p>
                </div>
            </div>
        </div>
    )
}
