import React from "react"
import moment from "moment"
import classNames from "classnames"
import {Icon} from "components/Icon"
import styles from "./item.module.css"

export function DateField(props) {
    const {field, canSign, onChangeField} = props
    const {top, left, width, height} = field.placeInformation
    const {isYourField} = field

    const onFillDate = () => {
        if (!canSign || !isYourField) {
            return
        }
        onChangeField(field.id, {isSigned: true, date: moment().format("MM/DD/YYYY")})
    }

    const renderBody = () => {
        if (!canSign || !isYourField) {
            return (
                <div
                    className={classNames(styles.actionItem, styles.flexCenter)}
                    style={{
                        width,
                        height,
                        backgroundColor: "transparent",
                        borderColor: "transparent"
                    }}>
                    <span className={styles.date}>{field.date}</span>
                </div>
            )
        }
        if (!field.isSigned) {
            return (
                <div
                    className={classNames(styles.actionItem, styles.flexCenter, {
                        [styles.actionItemRequired]: isYourField && field.required
                    })}
                    style={{width, height}}
                    onClick={onFillDate}>
                    <Icon icon="CALENDAR_FILL_INPUT" />
                </div>
            )
        }
        return (
            <div
                className={classNames(styles.actionItem, styles.flexCenter, {
                    [styles.actionItemRequired]: isYourField && field.required
                })}
                style={{width, height}}>
                <span className={styles.date}>{field.date}</span>
            </div>
        )
    }

    return (
        <div className={styles.wrap} style={{left, top}}>
            {renderBody()}
        </div>
    )
}
