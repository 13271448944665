/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {Row, Col} from "antd"
import {Icon} from "components/Icon"
import {useModel, useVisible} from "hooks"
import {TotalProgress} from "uiKit"
import {AcademicAdvisorPopup} from "../TermInfo/AcademicAdvisorPopup"
import styles from "./CourseInfo.module.css"
import {get} from "lodash"
import moment from "moment"
import {checkPermission, roundTwoDigits} from "helpers"
import {Permissions} from "@edular/permissions"
import {StudentProgressTermAndCourse} from "uiKit/StudentProgress/StudentProgressTermAndCourse"
import {Major} from "types/major"

export function CourseInfo(props) {
    const advisorPopup = useVisible()
    const {
        isShowAcademicAdvisorPopup = true,
        attendanceRating,
        progressData,
        codeCourse,
        courseName,
        termInfo,
        type,
        gpa,
        firstDayOfCourse,
        lastDayOfCourse,
        instructor
    } = props
    const model = useModel()
    const isClockHourSchool = type === Major.Unit.Clock
    const [percent, setPercent] = useState(0)
    const [totalHours, setTotalHours] = useState([
        {title: "Total hours scheduled", hour: 0, color: "#e95c7b"},
        {title: "Total hours completed", hour: 0, color: "#18a957"},
        {title: "Total hours attempted", hour: 0, color: "#ff8a38"},
        {title: "Total hours remaining", hour: 0, color: "#939393"},
        {title: "Total DistEd Hours", hour: 0, color: "#11763d"}
    ])

    useEffect(() => {
        if (progressData) {
            let remainingHour = (progressData?.appliedToDegree ?? 0) - (progressData?.completed ?? 0)
            remainingHour = Math.max(remainingHour, 0)

            setTotalHours([
                {
                    title: "Total hours scheduled",
                    hour: progressData?.attempted ? Math.round(progressData?.attempted) : 0,
                    color: "#e95c7b"
                },
                {
                    title: "Total hours completed",
                    hour: progressData?.completed ? roundTwoDigits(progressData.completed) : 0,
                    color: "#18a957"
                },
                {
                    title: "Total hours attempted",
                    hour: isClockHourSchool ? 0 : progressData.attempted ? Math.round(progressData.attempted) : 0,
                    color: "#ff8a38"
                },
                {
                    title: "Total hours remaining",
                    hour: remainingHour ? roundTwoDigits(remainingHour) : 0,
                    color: "#939393"
                },
                {
                    title: "Total DistEd Hours",
                    hour: progressData?.distEdHours ? Math.round(progressData?.distEdHours) : 0,
                    color: "#11763d"
                },
                {
                    title: "Total Hours",
                    hour: progressData?.appliedToDegree ? Math.round(progressData?.appliedToDegree) : 0,
                    color: "#11763d"
                }
            ])
            setPercent(
                Math.min(
                    Math.round(
                        progressData?.appliedToDegree > 0
                            ? (progressData?.completed * 100) / progressData?.appliedToDegree
                            : 0
                    ),
                    100
                )
            )
        }
    }, [isClockHourSchool, progressData])

    const hasPermissionsToSeeAcademicProgress = checkPermission(
        {
            student: [Permissions.Student.Users.AcademicProgress.OverallProgress.View],
            staff: [Permissions.Staff.Academics.Registrar.Students.TermsAndCourses.View]
        },
        model
    )

    return (
        <div className={styles.wrap}>
            <Row gutter={[40, 12]}>
                <Col span={12}>
                    <div className={styles.leftHeader}>
                        <span className={styles.termName}>{courseName ?? ""}</span>
                        <div className={styles.termIdInfo}>
                            <span className={styles.termIdInfo__title}>COURSE ID:</span>
                            <span className={styles.termIdInfo__value}>{codeCourse ?? ""}</span>
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    {isShowAcademicAdvisorPopup && (
                        <div className={styles.rightHeader}>
                            <div className={styles.rightHeaderIconWrap} onClick={advisorPopup.open}>
                                <Icon icon="PROFILE_FILL" className={styles.rightHeaderIcon} color="#fff" />
                            </div>
                        </div>
                    )}
                </Col>
            </Row>
            <Row gutter={[40, 8]}>
                {/* <Col span={12}>
                    <div className={styles.item}>
                        <span className={styles.item__title}>START DATE</span>
                        <span className={styles.item__value}>
                            {startDate ? moment(startDate).format("MM/DD/YYYY") : ""}
                        </span>
                    </div>
                </Col>
                <Col span={12}>
                    <div className={styles.item}>
                        <span className={styles.item__title}>GPA</span>
                        <span className={styles.item__value}>{gpa ?? 0}</span>
                    </div>
                </Col>
                <Col span={12}>
                    <div className={styles.item}>
                        <span className={styles.item__title}>PROJECTED END DATE</span>
                        <span className={styles.item__value}>
                            {startDate ? moment(projectedEndDate).format("MM/DD/YYYY") : ""}
                        </span>
                    </div>
                </Col>
                <Col span={12}>
                    <div className={styles.item}>
                        <span className={styles.item__title}>ATTENDANCE</span>
                        <span className={styles.item__value}>{Math.round(attendanceRating)}%</span>
                    </div>
                </Col> */}
                <Col span={12}>
                    {/* <div className={styles.item} style={{marginBottom: 8}}>
                        <span className={styles.item__title}>INSTRUCTOR</span>
                        <span className={styles.item__value}>{instructor}</span>
                    </div> */}
                    <div className={styles.progressWrap}>
                        <TotalProgress
                            termInfo={{
                                startDate: firstDayOfCourse,
                                name: termInfo?.name,
                                endDate: lastDayOfCourse
                            }}
                            activeSchedule={{
                                instructor
                            }}
                            percent={percent}
                        />
                    </div>
                </Col>
                {hasPermissionsToSeeAcademicProgress && (
                    <Col span={12}>
                        <div className={styles.progressWrap}>
                            <StudentProgressTermAndCourse
                                title="Total hours"
                                totalTitle="Total hours"
                                totalHours={totalHours}
                                programVersionTotalClockHours={0}
                            />
                        </div>
                    </Col>
                )}
            </Row>
            {isShowAcademicAdvisorPopup && (
                <AcademicAdvisorPopup isShow={advisorPopup.isVisible} onClose={advisorPopup.close} />
            )}
        </div>
    )
}
