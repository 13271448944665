import {get} from "lodash"
import BaseService from "./Base"

export default class AutoDisableAccessService extends BaseService {
    async getAll(params = {}) {
        const response = await this.post("/v1/settings/auto-disabled-access/list", params)
        return get(response, "data", [])
    }

    async edit(params) {
        const response = await this.post("/v1/settings/auto-disabled-access/edit", params)
        return get(response, "data", [])
    }
}
