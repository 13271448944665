import {get} from "lodash"
import {CareerServices} from "types/careerServices"
import BaseService from "./Base"

class CareerService extends BaseService {
    async getListGraduationStatistics(params): Promise<CareerServices.GraduationStatistics> {
        const response = await this.post("/v1/career-service/graduation-stats/list", params)
        return get(response, "data.data", [])
    }

    async getListPlacementStatistics(params): Promise<CareerServices.PlacementStatisticsItem[]> {
        const response = await this.post("/v1/career-service/placement-stats/list", params)
        return get(response, "data.data", [])
    }

    async getListStatusesStatistics(params): Promise<CareerServices.StatusStatisticsItem[]> {
        const response = await this.post("/v1/career-service/statuses-stats/list", params)
        return get(response, "data.data", [])
    }

    async getListSalaryStatistics(params): Promise<CareerServices.SalaryStatistics> {
        const response = await this.post("/v1/career-service/salary-stats/list", params)
        return get(response, "data.data", [])
    }

    async getListGraduationTrackingStatistics(params): Promise<CareerServices.GraduationTracking[]> {
        const response = await this.post("/v1/career-service/graduation-tracking-stats/list", params)
        return get(response, "data.data", [])
    }

    async getListStudents(params): Promise<{total: number; data: CareerServices.StudentTable[]}> {
        const response = await this.post("/v1/career-service/students/list", params)
        return get(response, "data", [])
    }

    async getStudent(params): Promise<CareerServices.StudentDetail> {
        const response = await this.post("/v1/career-service/student-detail/get", params)
        return get(response, "data.data", {})
    }

    async saveStudent(params): Promise<void> {
        const response = await this.post("/v1/career-service/student-detail/save", params)
        return get(response, "data", [])
    }

    // Setting
    async getListReadinessChecklists(
        params
    ): Promise<{total: number; data: CareerServices.ReadinessChecklistEntity[]}> {
        const response = await this.post("/v1/career-service/readiness-checklists/list", params)
        return get(response, "data", [])
    }

    async getReadinessChecklist(params): Promise<CareerServices.ReadinessChecklistEntity> {
        const response = await this.post("/v1/career-service/readiness-checklists/get", params)
        return get(response, "data.data", {})
    }

    async createReadinessChecklist(params): Promise<CareerServices.ReadinessChecklistEntity> {
        const response = await this.post("/v1/career-service/readiness-checklists/create", params)
        return get(response, "data.data", {})
    }

    async updateReadinessChecklist(params): Promise<void> {
        const response = await this.post("/v1/career-service/readiness-checklists/update", params)
        return get(response, "data", {})
    }

    // Job Board
    async getListEmployers(params): Promise<{total: number; data: CareerServices.Employer[]}> {
        const response = await this.post("/v1/career-service/employers/list", params)
        return get(response, "data", [])
    }

    async getEmployer(params): Promise<CareerServices.Employer> {
        const response = await this.post("/v1/career-service/employers/get", params)
        return get(response, "data.data", {})
    }

    async createEmployer(params): Promise<CareerServices.Employer> {
        const response = await this.post("/v1/career-service/employers/create", params)
        return get(response, "data.data", {})
    }

    async updateEmployer(params): Promise<void> {
        const response = await this.post("/v1/career-service/employers/update", params)
        return get(response, "data", {})
    }

    async uploadEmployerLogo(params): Promise<any> {
        const response = await this.post(`/v1/career-service/employers/logo-upload`, params)
        return get(response, "data.data")
    }

    async getListJobs(params): Promise<{total: number; data: CareerServices.Job[]}> {
        const response = await this.post("/v1/career-service/jobs/list", params)
        return get(response, "data", [])
    }

    async getJob(params): Promise<CareerServices.Job> {
        const response = await this.post("/v1/career-service/jobs/get", params)
        return get(response, "data.data", {})
    }

    async createJob(params): Promise<void> {
        const response = await this.post("/v1/career-service/jobs/create", params)
        return get(response, "data", {})
    }

    async updateJob(params): Promise<void> {
        const response = await this.post("/v1/career-service/jobs/update", params)
        return get(response, "data", {})
    }

    async getListApplications(params): Promise<{total: number; data: CareerServices.Application[]}> {
        const response = await this.post("/v1/career-service/applications/list", params)
        return get(response, "data", [])
    }

    async getApplication(params): Promise<CareerServices.Application> {
        const response = await this.post("/v1/career-service/applications/get", params)
        return get(response, "data.data", {})
    }

    async createApplicationNote(params): Promise<CareerServices.ApplicationNoteEntity> {
        const response = await this.post("/v1/career-service/applications/notes/create", params)
        return get(response, "data.data", {})
    }

    async reactApplication(params): Promise<CareerServices.ReactionStat> {
        const response = await this.post("/v1/career-service/applications/react", params)
        return get(response, "data.data", {})
    }

    async getJobStats(params): Promise<CareerServices.JobAnalytic> {
        const response = await this.post("/v1/career-service/job-stats/get", params)
        return get(response, "data.data", {})
    }

    async updateStudentDocument(params): Promise<any> {
        const response = await this.post(`/v1/career-service/student-detail/doc-save`, params)
        return get(response, "data.data")
    }

    async getStudentReadinessChecklist(
        params
    ): Promise<{total: number; data: CareerServices.StudentReadinessChecklist[]}> {
        const response = await this.post("/v1/career-service/student-detail/readiness-checklist/get", params)
        return get(response, "data", [])
    }

    async getStudentJobSeekingStats(params): Promise<CareerServices.StudentJobSeekingStats> {
        const response = await this.post("/v1/career-service/student-detail/job-stats", params)
        return get(response, "data.data", {})
    }

    async getListCategories(params): Promise<{total: number; data: CareerServices.JobCategory[]}> {
        const response = await this.post("/v1/career-service/job-categories/list", params)
        return get(response, "data", [])
    }

    async createJobCategory(params): Promise<CareerServices.JobCategory> {
        const response = await this.post("/v1/career-service/job-categories/create", params)
        return get(response, "data.data", {})
    }

    async createJobNote(params): Promise<CareerServices.JobNoteEntity> {
        const response = await this.post("/v1/career-service/jobs/notes/create", params)
        return get(response, "data.data", {})
    }

    async reactJob(params): Promise<CareerServices.ReactionStat> {
        const response = await this.post("/v1/career-service/jobs/react", params)
        return get(response, "data.data", {})
    }

    async updateStudentReadinessChecklist(params): Promise<CareerServices.StudentReadinessChecklist> {
        const response = await this.post("/v1/career-service/student-detail/readiness-checklist/update", params)
        return get(response, "data.data", {})
    }

    async uploadStudentDocument(params): Promise<any> {
        const response = await this.post(`/v1/career-service/student-detail/readiness-checklist/upload-file`, params)
        return get(response, "data.data")
    }

    async attachApplicationDocuments(params): Promise<{attachmentUrl: string; attachmentFileName: string}[]> {
        const response = await this.post(`/v1/career-service/applications/attach-file`, params)
        return get(response, "data.data", [])
    }

    async applyJob(params): Promise<CareerServices.Application> {
        const response = await this.post("/v1/career-service/applications/create", params)
        return get(response, "data.data", {})
    }

    async updateApplication(params): Promise<CareerServices.ApplicationNote> {
        const response = await this.post("/v1/career-service/applications/update", params)
        return get(response, "data.data", {})
    }

    async getStudentCategoryFavourites(): Promise<CareerServices.StudentCategoryFavourite[]> {
        const response = await this.post("/v1/career-service/student-detail/categories/list", {})
        return get(response, "data.data", [])
    }

    async createStudentCategoryFavourite(params): Promise<CareerServices.StudentCategoryFavourite> {
        const response = await this.post("/v1/career-service/student-detail/categories/create", params)
        return get(response, "data.data", {})
    }

    async removeStudentCategoryFavourite(params): Promise<void> {
        const response = await this.post("/v1/career-service/student-detail/categories/remove", params)
        return get(response, "data.data", {})
    }

    async getStudentKeywordFavourites(): Promise<CareerServices.StudentKeywordFavouriteEntity[]> {
        const response = await this.post("/v1/career-service/student-detail/keywords/list", {})
        return get(response, "data.data", [])
    }

    async createStudentKeywordFavourite(params): Promise<CareerServices.StudentKeywordFavouriteEntity> {
        const response = await this.post("/v1/career-service/student-detail/keywords/create", params)
        return get(response, "data.data", {})
    }

    async removeStudentKeywordFavourite(params): Promise<void> {
        const response = await this.post("/v1/career-service/student-detail/keywords/remove", params)
        return get(response, "data.data", {})
    }

    async respondOfferApplication(params): Promise<CareerServices.ApplicationNote> {
        const response = await this.post("/v1/career-service/applications/respond-offer", params)
        return get(response, "data.data", {})
    }

    async getPositions(params) {
        const response = await this.post(`/v1/career-service/positions/autocomplete`, params)
        return get(response, "data", {})
    }
}

export default CareerService
