/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useCallback} from "react"
import {useTranslation} from "react-i18next"
import cx from "classnames"
import {isEmpty} from "lodash"
import debounce from "debounce-promise"
import {Icon} from "components/Icon"
import {Search} from "components/inputs/Search"
import {BaseButton} from "components/buttons"
import {BaseFilterContext} from "./BaseFilterContext"
import styles from "./BaseNewFilter.module.css"

type BaseNewFilterProps = {
    className?: string
    filter?: any
    children?: JSX.Element
    searchValue?: string
    showRightFilter?: boolean
    onClickClear?: () => any
    onClick?: () => any
    onSearchInput: (input: string) => any
    renderRightFilter?: () => JSX.Element
    showSearch?: boolean
}

export function BaseNewFilter(props: BaseNewFilterProps) {
    const {
        children,
        className,
        filter = {},
        searchValue,
        showRightFilter = true,
        onClickClear,
        onClick,
        onSearchInput,
        renderRightFilter,
        showSearch = true
    } = props
    const {t} = useTranslation(["common"])
    const [isShowFilter, setIsShowFilter] = React.useState(false)
    const [hasFilterBody, setHasFilterBody] = React.useState(false)
    const [search, setSearch] = React.useState("")

    useEffect(() => {
        if (searchValue) {
            setSearch(searchValue)
        }
    }, [searchValue])

    useEffect(() => {
        checkHasFilterBody()
    }, [filter])

    const checkHasFilterBody = () => {
        const hasFilter = Object.values(filter).some((value) => {
            if ((typeof value === "boolean" && value) || !isEmpty(value)) {
                return true
            }
            return false
        })
        setHasFilterBody(hasFilter)
    }

    const toggleShowBodyFilter = () => {
        setIsShowFilter(!isShowFilter)
    }

    const debounceSearch = useCallback(
        debounce((searchInput) => {
            onSearchInput(searchInput)
        }, 500),
        []
    )

    const onSearch = (search) => {
        setSearch(search)
        debounceSearch(search)
    }

    const onClickApply = () => {
        setIsShowFilter(false)
        onClick()
    }

    return (
        <BaseFilterContext.Provider value={{isShowFilter, setIsShowFilter}}>
            <div className={cx(styles.wrap, className)}>
                {!isEmpty(children) && isShowFilter && (
                    <div className={styles.body}>
                        <div className={styles.headerBody}>
                            <div className={styles.headerFilterIconWrap} onClick={toggleShowBodyFilter}>
                                <Icon icon="FILTER" color="#fff" />
                            </div>
                            <div className={styles.closeIconWrap} onClick={toggleShowBodyFilter}>
                                <Icon icon="CLOSE" className={styles.closeIcon} />
                            </div>
                        </div>
                        <div className={styles.bodyContent}>{children}</div>
                        <div className={styles.footerBody}>
                            <div className={styles.footerBodyAction}>
                                <BaseButton
                                    title={t("common:action.clear")}
                                    variant="secondary"
                                    onClick={onClickClear}
                                />
                                <BaseButton title={t("common:action.apply")} onClick={onClickApply} />
                            </div>
                        </div>
                    </div>
                )}
                <div className={styles.filterWrap}>
                    <div className={styles.leftFilterWrap}>
                        {!isEmpty(children) && !isShowFilter && (
                            <div
                                className={cx(styles.filterIconWrap, {
                                    [styles.hasFilterBody]: hasFilterBody
                                })}
                                onClick={toggleShowBodyFilter}>
                                <Icon icon="FILTER" color={hasFilterBody ? "#fff" : "#1e90ff"} />
                            </div>
                        )}
                        {showSearch && (
                            <Search
                                className={styles.search}
                                onChange={onSearch}
                                value={search}
                                placeholder={t("common:action.search")}
                            />
                        )}
                    </div>
                    {showRightFilter && (
                        <div className={styles.rightFilterWrap}>{renderRightFilter && renderRightFilter()}</div>
                    )}
                </div>
            </div>
        </BaseFilterContext.Provider>
    )
}
