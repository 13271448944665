import {get} from "lodash"
import BaseService from "./Base"
import {SavedFilter} from "types/filter"

class SavedFilterService extends BaseService {
    async list(params): Promise<{total: number; data: SavedFilter[]}> {
        const response = await this.post("/v1/students/saved-filters/list", params)
        return get(response, "data", [])
    }

    async create(params): Promise<SavedFilter> {
        const response = await this.post("/v1/students/saved-filters/create", params)
        return get(response, "data.data", {})
    }
}

export default SavedFilterService
