/* eslint-disable react-hooks/exhaustive-deps */
import {Col, Row} from "antd"
import {checkPermission, formatDecimalWithoutRound, getFullName, handleError} from "helpers"
import jsPDF from "jspdf"
import React, {useEffect, useMemo, useRef, useState} from "react"
import {
    fontBold,
    fontRegular
} from "sections/FinancialAid/FinancialAidStudent/parts/CollegeFinancingPlan/CFPStepDetail/font"
import {
    academicCourseGradesService,
    attendanceService,
    clientsService,
    degreeAuditService,
    instructionalService,
    previousCompletedCreditsService,
    studentService,
    transferCreditsService
} from "services"
import styles from "./ProgressReportPdf.module.css"
import {Branding} from "types/branding"
import {useModel} from "hooks"
import StudentInfoCredit from "./StudentInfoCredit"
import {Screen} from "components/ui"
import {Auth} from "types/auth"
import {get} from "lodash"
import {BaseDepartmentId} from "types/departments"
import moment from "moment"
import {DegreeAuditProgressData} from "types/degreeAudit"
import TermCourseClock from "./TermCourseClock"
import {BaseButton} from "components"
import PracticalTable from "./PracticalTable"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import StudentInfoClock from "./StudentInfoClock"
import TermCourseCredit from "./TermCourseCredit"
import {Course} from "types/courses"
import {TransferCredits} from "types/transferCredits"
import {GeneralSettingField} from "types/settings/general-setting"
import {Permissions} from "@edular/permissions"
import OfficialTranscriptSignature from "./OfficialTranscriptSignature"
import {PreviousCompletedCredits} from "types/previousCompletedCredits"
import FooterPdf from "./Footer"

type ProgressReportPdfProps = {
    match: {
        params: {
            profileId: number
            departmentId: BaseDepartmentId
            type: Course.CourseType
            transcriptType: GeneralSettingField
        }
    }
}

const ProgressReportPdf = (props: ProgressReportPdfProps) => {
    const {
        match: {
            params: {profileId, departmentId, type, transcriptType}
        }
    } = props
    const [listOfSubmodules, setListOfSubmodules] = useState<string[]>([])
    const model = useModel()
    const pdfRef = useRef<HTMLDivElement>()
    const footerTranscriptRef = useRef<HTMLDivElement>()
    const [listTableDegreeAudit, setListTableDegreeAudit] = useState([])
    const [downloading, setDownloading] = useState(false)
    const [practicalCourses, setPracticalCourses] = useState([])
    const [transferCredits, setTransferCredits] = useState<TransferCredits[]>([])
    const [client, setClient] = React.useState<any | undefined>()
    const [studentInfo, setStudentInfo] = useState<Auth.DepartmentStudent | undefined>()
    const [cgpa, setCgpa] = useState(0)
    const [previouslyCompletedCredits, setPreviouslyCompletedCredits] = useState<PreviousCompletedCredits[]>([])
    const [logoBase64, setLogoBase64] = useState("")
    const [missedStats, setMissedStats] = useState({
        makeUpHours: 0,
        missedHours: 0
    })
    const [progressData, setProgressData] = useState<DegreeAuditProgressData>({
        completed: 0,
        distEdHours: 0,
        attempted: 0,
        degreeRequirement: 0,
        appliedToDegree: 0,
        totalTransfer: 0,
        totalTransferApplied: 0,
        totalPreviousCompletedApplied: 0
    })

    const logo = client?.college_logo || model.clientSetting?.branding?.[Branding.BrandingType.OfficialLogoSquare]

    function convertImgToBase64(url, callback) {
        var canvas: any = document.createElement("CANVAS")
        var ctx = canvas.getContext("2d")
        var img = new Image()
        img.crossOrigin = "Anonymous"
        img.onload = function () {
            canvas.height = img.height
            canvas.width = img.width
            ctx.drawImage(img, 0, 0)
            var dataURL = canvas.toDataURL("image/png")
            callback.call(this, dataURL)
            canvas = null
        }
        img.src = url
    }

    useEffect(() => {
        if (logo) {
            convertImgToBase64(logo + `?t=${Date.now()}`, function (base64Img) {
                setLogoBase64(base64Img)
            })
        }
    }, [logo])

    async function loadClientDetails() {
        setClient(await clientsService.getClientDetail())
    }

    const getStatusProfile = async (student) => {
        try {
            const {data: statuses} = await studentService.getStudentStatuses(+profileId)

            statuses.forEach((status) => {
                if (status.departmentId === BaseDepartmentId.StudentAccount) {
                    student.studentAccountStatus = status.status
                } else if (status.departmentId === BaseDepartmentId.Academics) {
                    student.studentStatus = status.status
                }
            })
            setStudentInfo({...student})
        } catch (err) {
            handleError(err)
        }
    }

    async function getStudentDetail() {
        try {
            const {
                data: [student]
            } = await studentService.getDepartmentStudents({
                filter: {
                    profileIds: [+profileId],
                    departmentId: +departmentId
                }
            })
            if (model.clientSetting.isNewStudentStatusesVisible) {
                student.studentStatus = student.status
                setStudentInfo({...student})
            } else {
                getStatusProfile(student)
            }
        } catch (error) {
            handleError(error)
        }
    }

    async function getProgress() {
        try {
            const response = await degreeAuditService.getProgress({
                studentProfileId: +profileId
            })
            const data = response.data
            setProgressData(data)
        } catch (err) {
            handleError(err)
        }
    }

    async function getTransferCredits() {
        try {
            const params: any = {
                studentProfileId: +profileId,
                range: {
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE
                },
                filter: {
                    approved: true
                }
            }
            let {data, total} = await transferCreditsService.listTransferCredits(params)
            setTransferCredits(data)
        } catch (err) {
            handleError(err)
        }
    }

    async function getPreviouslyCompletedCredits() {
        try {
            const params: any = {
                studentProfileId: +profileId,
                range: {
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE
                },
                filter: {
                    approved: true
                }
            }
            let {data} = await previousCompletedCreditsService.listPreviousCompletedCredits(params)
            setPreviouslyCompletedCredits(data)
        } catch (err) {
            handleError(err)
        }
    }

    async function getDegreeAudit() {
        try {
            const response = await degreeAuditService.getTableCurrentProgress({studentProfileId: +profileId})
            const data = response.data || []
            setListTableDegreeAudit(data)
        } catch (err) {
            handleError(err)
        }
    }

    async function getPracticalCourse() {
        try {
            const {data} = await instructionalService.getStudentCourses({
                filter: {
                    studentProfileIds: [+profileId],
                    isPracticalOrClinicalServices: true
                },
                range: {
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE
                },
                linkedEntities: ["submodule"]
            })
            setPracticalCourses(data)
        } catch (error) {
            handleError(error)
        }
    }

    const getGpa = async () => {
        try {
            const params: {studentProfileId: number} = {
                studentProfileId: +profileId
            }
            let {cgpa} = await academicCourseGradesService.getCourseGradeStudentStats(params)
            setCgpa(formatDecimalWithoutRound(cgpa ?? 0))
        } catch (err) {
            handleError(err)
        }
    }

    const getMissedStats = async () => {
        try {
            const result = await attendanceService.getMissedAttendanceStats({
                studentProfileIds: [profileId]
            })
            setMissedStats(result)
        } catch (err) {
            handleError(err)
        }
    }

    useEffect(() => {
        loadClientDetails()
        getStudentDetail()
        getProgress()
        getDegreeAudit()
        getTransferCredits()
        getPreviouslyCompletedCredits()
        getPracticalCourse()
        getGpa()
        getMissedStats()
    }, [])

    const renderFooter = useMemo(() => {
        return (
            <div
                ref={footerTranscriptRef}
                style={
                    downloading
                        ? {
                              paddingLeft: 33,
                              paddingRight: 33
                          }
                        : {}
                }>
                <FooterPdf />
            </div>
        )
    }, [downloading])

    const handleDownload = React.useCallback(async () => {
        setDownloading(true)
        try {
            const element = pdfRef.current
            const pdfWidth = 210
            const elementWidth = element.clientWidth
            const widthScaleFactor = pdfWidth / elementWidth

            const pdf = new jsPDF("p", "mm", "a4")

            pdf.addFileToVFS("Poppins-regular.ttf", fontRegular)
            pdf.addFont("Poppins-regular.ttf", "Poppins", "normal", "normal")
            pdf.addFileToVFS("Poppins-bold.ttf", fontBold)
            pdf.addFont("Poppins-bold.ttf", "Poppins", "normal", "bold")

            pdf.setFont("Poppins")

            await pdf.html(element, {
                image: {
                    type: "jpeg",
                    quality: 100
                },
                html2canvas: {
                    scale: widthScaleFactor,
                    allowTaint: true,
                    letterRendering: true,
                    svgRendering: true
                },
                autoPaging: "text",
                margin: [10, 0, 25, 0],
                x: 0,
                y: 0,
                width: pdfWidth,
                windowWidth: elementWidth
            })

            const pageCount = pdf.getNumberOfPages()
            for (let i = 1; i <= pageCount; i++) {
                pdf.setPage(i)
                const pageSize = pdf.internal.pageSize
                const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()

                const footerTranscript = footerTranscriptRef.current
                await pdf.html(footerTranscript, {
                    x: 0,
                    y: pageHeight * i - 15,
                    image: {
                        type: "jpeg",
                        quality: 100
                    },
                    html2canvas: {
                        scale: widthScaleFactor,
                        allowTaint: true,
                        letterRendering: true,
                        svgRendering: true
                    },
                    width: pdfWidth,
                    windowWidth: elementWidth
                })
            }

            pdf.save(`Degree Audit - ${getFullName(studentInfo)}.pdf`)
        } catch (error) {
            handleError(error)
        } finally {
            setDownloading(false)
        }
    }, [studentInfo])

    const renderSignature = useMemo(() => {
        return <OfficialTranscriptSignature />
    }, [])

    const hasPermissionToCreateProgressReport = useMemo(() => {
        return checkPermission({staff: [Permissions.Staff.Academics.Registrar.Students.ProgressReport.Add]}, model)
    }, [model])

    const totalTransferCreditGpa = transferCredits.reduce((total, item) => {
        if (typeof item.grades == "number" && item?.grades >= 0) {
            total += item.grades
        }
        return total
    }, 0)

    const totalTransferCredits = transferCredits.reduce((total, item) => {
        if (typeof item.appliedCredits === "number" && item.appliedCredits) {
            total += item.appliedCredits
        }
        return total
    }, 0)

    const totalTransferCreditGpaValue = formatDecimalWithoutRound(
        totalTransferCreditGpa >= 0 ? totalTransferCreditGpa / transferCredits.length : 0
    )
    const totalTransferCreditsValue = formatDecimalWithoutRound(totalTransferCredits ?? 0)

    const totalCompletedGpa = previouslyCompletedCredits.reduce((total, item) => {
        if (typeof item.grades == "number" && item?.grades >= 0) {
            total += item.grades
        }
        return total
    }, 0)

    const totalCompletedCredits = previouslyCompletedCredits.reduce((total, item) => {
        if (typeof item.creditsApplied === "number" && item.creditsApplied) {
            total += item.creditsApplied
        }
        return total
    }, 0)

    const totalCompletedGpaValue = formatDecimalWithoutRound(
        totalCompletedGpa >= 0 ? totalCompletedGpa / previouslyCompletedCredits.length : 0
    )
    const totalCompletedCreditsValue = formatDecimalWithoutRound(totalCompletedCredits ?? 0)

    return (
        <Screen
            className={styles.screen}
            header={{
                isShowNotification: false,
                className: styles.header,
                backUrl: `/academics/registrar/student/${profileId}?tab=degree-audit`,
                variant: "column"
            }}>
            <div className={styles.container}>
                <div
                    ref={pdfRef}
                    className={styles.pdfPage}
                    style={
                        !downloading
                            ? {
                                  paddingTop: 40
                              }
                            : {paddingTop: 0}
                    }>
                    <div className={styles.rowTitle}>
                        <div>
                            <img className={styles.logo} alt="" src={logoBase64} />
                        </div>
                        <div className={styles.titleView}>
                            <span className={styles.title}>Progress Report</span>
                            <span className={styles.title}>(Unofficial Transcript)</span>
                        </div>
                    </div>

                    <div className={styles.studentNameForm}>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <span className={styles.studentCode}>{get(studentInfo, "customProfileId", "")}</span>
                            <span className={styles.studentName}>{getFullName(studentInfo) || "-"}</span>
                        </div>
                        <span className={styles.issuedDate}>Issued {moment().format(model.getUserDateFormat())}</span>
                    </div>

                    {type === Course.CourseType.Credit ? (
                        <StudentInfoCredit cgpa={cgpa} progressData={progressData} studentInfo={studentInfo} />
                    ) : (
                        <StudentInfoClock
                            cgpa={cgpa}
                            missedStats={missedStats}
                            progressData={progressData}
                            studentInfo={studentInfo}
                        />
                    )}

                    {transferCredits?.length ? (
                        <div className={styles.tableContainer}>
                            <span className={styles.tableTitle}>Transfered credits</span>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}>Transfer</span>
                                                <span>Id</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Course Name</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Term Name</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>University</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}>Equivalent</span>
                                                <span>Id</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Course Name</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText} style={{width: 115}}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Units earned</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText} style={{width: 60}}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Quality Points</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText} style={{width: 60}}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Grade</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText} style={{width: 114}}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Grade Points</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transferCredits.map((item, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td className={styles.tableColumn}>{item.idTransfer}</td>
                                                <td className={styles.tableColumn}>{item.courseNameTransfer}</td>
                                                <td className={styles.tableColumn}>{item.termNameTransfer}</td>
                                                <td className={styles.tableColumn}>{item.universityName}</td>
                                                <td className={styles.tableColumn}>{item.codeEquivalent}</td>
                                                <td className={styles.tableColumn}>{item.courseName}</td>
                                                <td className={styles.tableColumn}>{item.appliedCredits}</td>
                                                <td className={styles.tableColumn}>{item.qualityPoints}</td>
                                                <td className={styles.tableColumn}>{item.alphabetical}</td>
                                                <td className={styles.tableColumn}>{item.grades}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td className={styles.termAvg}>TOTAL</td>
                                        <td className={styles.termAvg}></td>
                                        <td className={styles.termAvg} />
                                        <td className={styles.termAvg} />
                                        <td className={styles.termAvg} />
                                        <td className={styles.termAvg} />
                                        <td className={styles.termAvg}>{totalTransferCreditsValue}</td>
                                        <td className={styles.termAvg} />
                                        <td className={styles.termAvg} />
                                        <td className={styles.termAvg}>{totalTransferCreditGpaValue}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ) : null}

                    {previouslyCompletedCredits?.length ? (
                        <div className={styles.tableContainer}>
                            <span className={styles.tableTitle}>Previously completed credits</span>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span>Id</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span>Course Name</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Term Name</span>
                                            </div>
                                        </th>

                                        <th className={styles.tableHeaderText} style={{width: 115}}>
                                            <div className={styles.headerTransfer}>
                                                <span>Units earned</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText} style={{width: 60}}>
                                            <div className={styles.headerTransfer}>
                                                <span>Quality Points</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText} style={{width: 60}}>
                                            <div className={styles.headerTransfer}>
                                                <span>Grade</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText} style={{width: 114}}>
                                            <div className={styles.headerTransfer}>
                                                <span>Grade Points</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {previouslyCompletedCredits.map((item, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td className={styles.tableColumn}>{item.codeApplied}</td>
                                                <td className={styles.tableColumn}>{item.courseNameApplied}</td>
                                                <td className={styles.tableColumn}>{item.termName}</td>
                                                <td className={styles.tableColumn}>{item.creditsApplied}</td>
                                                <td className={styles.tableColumn}>{item.qualityPoints}</td>
                                                <td className={styles.tableColumn}>{item.alphabetical}</td>
                                                <td className={styles.tableColumn}>{item.grades}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td className={styles.termAvg}>TOTAL</td>
                                        <td className={styles.termAvg}></td>
                                        <td className={styles.termAvg} />
                                        <td className={styles.termAvg}>{totalCompletedCreditsValue}</td>
                                        <td className={styles.termAvg} />
                                        <td className={styles.termAvg} />
                                        <td className={styles.termAvg}>{totalCompletedGpaValue}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ) : null}

                    {type === Course.CourseType.Credit ? (
                        <TermCourseCredit listTableDegreeAudit={listTableDegreeAudit} profileId={profileId} />
                    ) : (
                        <TermCourseClock
                            setListOfSubmodules={setListOfSubmodules}
                            listOfSubmodules={listOfSubmodules}
                            transcriptType={transcriptType}
                            listTableDegreeAudit={listTableDegreeAudit}
                            profileId={profileId}
                        />
                    )}
                    {practicalCourses?.length ? <PracticalTable data={practicalCourses} /> : null}
                    {renderSignature}
                    {!downloading ? renderFooter : null}
                </div>
            </div>
            {downloading ? renderFooter : null}
            {hasPermissionToCreateProgressReport ? (
                <BaseButton
                    className={styles.buttonDownload}
                    loading={downloading}
                    onClick={handleDownload}
                    title={"Download"}
                />
            ) : null}
        </Screen>
    )
}

export default ProgressReportPdf
