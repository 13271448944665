import {get} from "lodash"
import BaseService from "./Base"

class MajorVersionsService extends BaseService {
    async getAll(params = {}) {
        const response = await this.post("/v3/program-versions/list", params)
        return get(response, "data", [])
    }
    async exportAsCsv(data) {
        const response = await this.post(`/v3/program-versions/export-as-csv`, data, {
            responseType: "blob"
        })
        return get(response, "data", null)
    }
}

export default MajorVersionsService
