import {Auth} from "types/auth"
import {ActivityStatus, Timestamps} from "./common"
import {CFPType, HousingPlan} from "./fin-aid/fin-aid"
import {LedgerAccount} from "types/student-account/ledger-accounts"

export enum RateType {
    Program = "program",
    Fee = "fee",
    RoomAndBoard = "room_and_board"
}

// Rate Sheet Rates

export type RateSheetRateBaseDetails = {
    rateSheetRateId: number
    rateSheetId: number
    type: RateType
    name: string
    rate: number
    isInstitutionalCharge: boolean
    housingPlan?: HousingPlan | null
    cfpType?: CFPType | null
    isDiscount: boolean
    discounts?: RateSheetRateDiscount[]
    ledgerAccountId?: number | null
}

export type RateSheetDiscountRange = Timestamps & {
    rateSheetRangeId: number
    rateSheetId: number
    creditsFrom: number
    creditsTo: number
}

export type RateSheetRateDiscount = {
    rateSheetRateId: number
    rateSheetRangeId: number
    amount: number
}

export type RateSheetRateDetails = RateSheetRateBaseDetails & Timestamps

export type NewRateSheetRateDetails = Omit<
    RateSheetRateBaseDetails,
    "rateSheetRateId" | "rateSheetId" | "discounts"
> & {
    discounts: Partial<RateSheetRateDiscount>[]
}

export type EditableRateSheetRateDetails = Omit<
    RateSheetRateBaseDetails,
    "rateSheetRateId" | "rateSheetId" | "discounts"
> & {
    action?: "create" | "edit" | "delete"
    rateSheetRateId: number | string
    discounts: Partial<RateSheetRateDiscount>[]
}

export type EditRateSheetRatePayload =
    | ({action: "edit"} & RateSheetRateBaseDetails)
    | ({action: "create"} & NewRateSheetRateDetails)
    | {action: "delete"; rateSheetRateId: number}

// RateSheets

export type RateSheetBaseDetails<RateT = RateSheetRateBaseDetails, LastUpdatedByT = number> = {
    rateSheetId: number
    code: string
    name: string
    status: ActivityStatus
    lastUpdatedBy: LastUpdatedByT | null
    rates: RateT[]
    discountRanges?: RateSheetDiscountRange[]
}

export type RateSheetListItemDetails = RateSheetBaseDetails<number, Auth.UserShortDetails> & Timestamps

export type RateSheetDetails = RateSheetBaseDetails<RateSheetRateDetails, number> & Timestamps

export type CreateRateSheetPayload = Omit<
    RateSheetBaseDetails<NewRateSheetRateDetails>,
    "rateSheetId" | "lastUpdatedBy" | "discountRanges"
> & {
    discountRanges: Partial<RateSheetDiscountRange>[]
}

export type EditRateSheetPayload = Omit<
    RateSheetBaseDetails<EditRateSheetRatePayload>,
    "lastUpdatedBy" | "discountRanges"
> & {
    discountRanges: Partial<RateSheetDiscountRange>[]
}

export type EditableRateSheet = Omit<
    RateSheetBaseDetails<EditableRateSheetRateDetails>,
    "rateSheetId" | "lastUpdatedBy" | "discountRanges"
> & {
    discountRanges: Partial<RateSheetDiscountRange>[]
}

export const DEFAULT_RATE_SHEET_RANGES: Partial<RateSheetDiscountRange>[] = [
    {creditsFrom: 136, creditsTo: -1},
    {creditsFrom: 91, creditsTo: 135},
    {creditsFrom: 46, creditsTo: 90},
    {creditsFrom: 0, creditsTo: 45}
]

export const DEFAULT_RATE_DISCOUNTS: Partial<RateSheetRateDiscount>[] = DEFAULT_RATE_SHEET_RANGES.map((range) => ({
    amount: 0
}))
