import React from "react"
import styles from "./HTMLPreviewEditor.module.css"

const HTML_NODE_TYPE = {
    ELEMENT_NODE: 1
}

const isHtmlValid = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === HTML_NODE_TYPE.ELEMENT_NODE)
}

const decodeHtml = (html: string) => {
    const txt = document.createElement("textarea")
    txt.innerHTML = html
    return txt.value
}

const renderHTML = (htmlContent: string) => {
    let decodedHtml = decodeHtml(htmlContent.replace(/\\n/g, "\n"))

    if (isHtmlValid(decodedHtml)) {
        return decodedHtml
    }
    if (isHtmlValid(htmlContent)) {
        return htmlContent
    }

    return decodedHtml
}

const PreviewPane: React.FC<{markdown: string; isFullScreen?: boolean}> = ({markdown, isFullScreen = false}) => {
    return (
        <iframe
            title="Preview"
            srcDoc={renderHTML(markdown)}
            width="100%"
            height="100%"
            className={isFullScreen ? styles.IFrameFullscreenStyles : styles.IFrameDefaultStyles}
        />
    )
}

export default PreviewPane
