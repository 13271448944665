import React from "react"
import cx from "classnames"
import {Auth} from "types/auth"
import styles from "./ModuleStudentHeader.module.css"

type Props = {
    activeModule: Auth.ModuleNavigation
    userId?: number
    customUserId?: string | null
    profileId?: number
    className?: string
    isBackButtonActive?: boolean
}

export function ModuleStudentHeader(props: Props) {
    const {userId, customUserId, className, isBackButtonActive} = props

    return (
        <div className={cx(styles.wrap, {[styles.without_back_button]: !isBackButtonActive}, className)}>
            <div className={styles.item}>
                <span className={styles.item__title}>User ID</span>
                <span className={styles.item__value}>{customUserId ?? userId}</span>
            </div>
        </div>
    )
}
