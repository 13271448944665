import React from "react"
import {LoadingOutlined} from "@ant-design/icons"
import {Spin, SpinProps} from "antd"

type Props = SpinProps & {}

const CircularSpin: React.FC<Props> = (props) => {
    const antIcon = <LoadingOutlined style={{fontSize: props.size === "small" ? 18 : 24}} spin />

    return <Spin indicator={antIcon} {...props} />
}

export default CircularSpin
