/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import {useTranslation} from "react-i18next"
import {Row, Col, Tooltip} from "antd"
import moment from "moment"
import classNames from "classnames"
import {Spin} from "antd"
import {LoadingOutlined} from "@ant-design/icons"
import {FormLabel} from "components/Form"
import {BaseInput} from "components/inputs"
import {KlassDropdown, KlassDropAsyncPaginate} from "components/Select"
import {BaseDatePicker} from "components/DateTimePicker"
import {TaskType, TaskSubtype} from "types/tasks"
import {getFullName, getEnableSubunitsByDepartmentPermission, handleError, formatCodeName} from "helpers"
import {useModel} from "hooks"
import DepartmentSubunitSelect from "components/DepartmentSubunitSelect"
import {PermissionType} from "types/permission"
import styles from "./TaskForm.module.css"
import {Workflow, WorkflowType} from "types/workflow"
import {workflowService} from "services"
import {get} from "lodash"
import {Icon} from "components"
import {preventMinusCharacter} from "helpers/inputHelper"

const antIcon = <LoadingOutlined style={{fontSize: 24}} spin />

function disabledDate(current) {
    return current && current < moment().startOf("day")
}

export function TaskForm(props) {
    const {t} = useTranslation(["tasks"])
    const {
        type,
        subtype,
        taskData,
        reminders,
        onChangeData,
        onSearchChange,
        onCheckTaskCode,
        isStudentView,
        isLoadingTaskCode,
        errors,
        refData,
        isWorkflowAction = false,
        signerUsers,
        taskId,
        getNewLibraryNextCode
    } = props
    const model = useModel()
    const subunitIds = getEnableSubunitsByDepartmentPermission(
        !taskData.id ? PermissionType.Add : PermissionType.Edit,
        model,
        "Tasks"
    )

    const [automatedWorkflows, setAutomatedWorkflows] = React.useState<Workflow[]>([])
    const [processWorkflows, setProcessWorkflows] = React.useState<Workflow[]>([])
    const [showHiddenFields, setShowHiddenFields] = React.useState<any>(false)

    React.useEffect(() => {
        ;(async function loadAutomatedWorkflows() {
            const {data} = await workflowService.getCompletedTaskWorkflows({subunitId: taskData.department?.subunitId})
            setAutomatedWorkflows(data)
        })()
        ;(async function loadProcessWorkflows() {
            const {data} = await workflowService.getAllWorkflows({
                filter: {
                    departmentSubunitIds: taskData.department ? [taskData.department.subunitId] : undefined,
                    isActive: true,
                    type: WorkflowType.Process
                }
            })
            setProcessWorkflows(data)
        })()
    }, [taskData.department])

    React.useEffect(() => {
        getTaskSigner()
    }, [signerUsers, taskId])

    const getTaskTypeAbbr = () => {
        if (subtype === TaskSubtype.LIBRARY) {
            return ""
        }
        switch (type) {
            case TaskType.SCAN_UPLOAD:
                return "SUD"
            case TaskType.DIGITAL_DOCUMENT:
                return "IDD"
            case TaskType.FROM_LIBRARY:
                return taskData.parentTaskCode
            default:
                return ""
        }
    }

    const getTaskSigner = async () => {
        try {
            if (!taskId) {
                setShowHiddenFields(true)
            }
            if (taskId && signerUsers.length) {
                const userId = get(model, "user.id", "")
                const signerUser = signerUsers.find((signerUser) => signerUser.userId === userId)
                setShowHiddenFields(!signerUser)
            }
        } catch (error) {
            handleError(error)
        }
    }
    return (
        <div>
            <Row gutter={[40, 32]}>
                <Col span={8} xs={24} sm={8}>
                    <div className={styles.formItemWrap} ref={refData?.departmentRef}>
                        <FormLabel label={t("taskDetail.form.department")} isRequired />
                        <DepartmentSubunitSelect
                            isMulti={false}
                            isClearable={false}
                            value={taskData.department}
                            onChange={(value) => onChangeData("department", {...value, departmentId: value.subunitId})}
                            readOnly={isStudentView || isWorkflowAction || type === TaskType.FROM_LIBRARY}
                            placeholder={t("taskDetail.form.department")}
                            error={!!errors?.department}
                            subunitIds={subunitIds}
                        />
                    </div>
                </Col>
                <Col span={8} xs={24} sm={8}>
                    {showHiddenFields && (
                        <div className={styles.formItemWrap} ref={refData?.taskCodeRef}>
                            <FormLabel label={t("taskDetail.form.taskId")} isRequired />
                            <BaseInput
                                value={isWorkflowAction ? "" : taskData.taskCode}
                                onChange={(value) => onChangeData("taskCode", value)}
                                onBlur={(e) => onCheckTaskCode(e.target.value)}
                                placeholder={t("taskDetail.form.taskId")}
                                readOnly={isStudentView || isWorkflowAction || type === TaskType.FROM_LIBRARY}
                                disabled={!taskData.department || isLoadingTaskCode}
                                error={!!errors?.taskCode}
                                prefix={
                                    <div
                                        className={classNames(styles.taskIdBadgeContainer, {
                                            [styles.taskIdBadgeContainerVisible]: !!taskData?.department
                                        })}>
                                        <span className={styles.taskIdBadge}>{taskData?.department?.code}</span>
                                        {getTaskTypeAbbr() && (
                                            <span className={styles.taskIdBadge}>{getTaskTypeAbbr()}</span>
                                        )}
                                    </div>
                                }
                                suffix={
                                    <div>
                                        {isLoadingTaskCode && <Spin indicator={antIcon} />}
                                        {type === TaskType.FROM_LIBRARY && !!errors?.taskCode && !isLoadingTaskCode && (
                                            <Tooltip title="Generate new Task ID" placement="top">
                                                <button
                                                    className={styles.taskReloadTaskCodeButton}
                                                    onClick={() => getNewLibraryNextCode()}>
                                                    <Icon icon="REFRESH" />
                                                </button>
                                            </Tooltip>
                                        )}
                                    </div>
                                }
                            />
                        </div>
                    )}
                </Col>
                <Col span={8} xs={24} sm={8}></Col>
                <Col span={24}>
                    <div className={styles.formItemWrap} ref={refData?.nameRef}>
                        <FormLabel label={t("taskDetail.form.taskName")} isRequired />
                        <BaseInput
                            value={taskData.name}
                            onChange={(value) => onChangeData("name", value)}
                            placeholder={t("taskDetail.form.taskName")}
                            readOnly={isStudentView}
                            error={!!errors?.name}
                        />
                    </div>
                </Col>
                {(showHiddenFields || type === TaskType.SCAN_UPLOAD) && (
                    <Col span={24}>
                        <div className={styles.formItemWrap}>
                            <FormLabel label={t("taskDetail.form.nameForSavedDocument")} />
                            <BaseInput
                                value={taskData.filename}
                                onChange={(value) => onChangeData("filename", value)}
                                placeholder={t("taskDetail.form.nameForSavedDocument")}
                                readOnly={isStudentView || !!taskData.id}
                            />
                        </div>
                    </Col>
                )}
                {showHiddenFields && (
                    <Col span={8} xs={24} sm={8}>
                        <div className={styles.formItemWrap}>
                            <FormLabel label={t("taskDetail.form.reminder")} />
                            <KlassDropdown
                                options={reminders}
                                value={taskData.reminders}
                                placeholder={t("taskDetail.form.reminder")}
                                onChange={isStudentView ? () => {} : (value) => onChangeData("reminders", value)}
                                isMulti
                                readOnly={isStudentView}
                            />
                        </div>
                    </Col>
                )}
                {subtype !== TaskSubtype.LIBRARY && (
                    <Col xs={24} sm={isWorkflowAction ? 12 : 8}>
                        <div className={styles.formItemWrap} ref={refData?.dueDateRef}>
                            <FormLabel
                                label={t("taskDetail.form.dueDate")}
                                isRequired
                                subtitle={isWorkflowAction && <small>(Number of days after trigger)</small>}
                            />
                            {isWorkflowAction ? (
                                <BaseInput
                                    value={taskData.dueDateDays}
                                    onChange={(value) => onChangeData("dueDateDays", value ? +value : undefined)}
                                    placeholder={t("taskDetail.form.dueDateDays")}
                                    readOnly={isStudentView}
                                    error={!!errors?.dueDateDays}
                                    type="number"
                                    min={0}
                                    onKeyDown={preventMinusCharacter}
                                />
                            ) : (
                                <BaseDatePicker
                                    value={taskData.dueDate}
                                    onChange={isStudentView ? () => {} : (value) => onChangeData("dueDate", value)}
                                    placeholder={t("taskDetail.form.dueDate")}
                                    disabledDate={disabledDate}
                                    readOnly={isStudentView}
                                    error={!!errors?.dueDate}
                                />
                            )}
                        </div>
                    </Col>
                )}
                {subtype === TaskSubtype.LIBRARY ? <Col span={12} /> : <Col span={isWorkflowAction ? 4 : 8} />}
                {showHiddenFields && (
                    <Col span={8} xs={24} sm={8}>
                        <div className={styles.formItemWrap}>
                            <FormLabel label={t("taskDetail.form.additionalApprovalAccess")} />
                            <KlassDropAsyncPaginate
                                value={taskData.departmentDeanProfiles}
                                onChange={
                                    isStudentView
                                        ? () => {}
                                        : (newValue) => onChangeData("departmentDeanProfiles", newValue)
                                }
                                getOptionLabel={(option: any) => getFullName(option)}
                                loadOptions={(input, loadedOptions) =>
                                    onSearchChange("staffUsers", input, loadedOptions)
                                }
                                isMulti
                                readOnly={isStudentView}
                            />
                        </div>
                    </Col>
                )}
                <Col span={24}>
                    <div className={styles.formItemWrap} ref={refData?.descriptionRef}>
                        <FormLabel label={t("taskDetail.form.description")} isRequired />
                        <BaseInput
                            value={taskData.description}
                            onChange={(value) => onChangeData("description", value)}
                            placeholder={t("taskDetail.form.description")}
                            readOnly={isStudentView}
                            error={!!errors?.description}
                        />
                    </div>
                </Col>

                <Col span={8} xs={24} sm={8}>
                    <div className={styles.formItemWrap}>
                        <FormLabel label={t("taskDetail.form.automatedWorkflowOnCompletion")} />
                        <KlassDropdown
                            options={automatedWorkflows}
                            valueKey="id"
                            labelKey="name"
                            getOptionLabel={formatCodeName}
                            value={automatedWorkflows.find(
                                (workflow) => workflow.id === taskData.completedAutomatedWorkflowId
                            )}
                            onChange={
                                isStudentView
                                    ? () => {}
                                    : (value: Workflow) => onChangeData("completedAutomatedWorkflowId", value?.id)
                            }
                            isMulti={false}
                            readOnly={isStudentView}
                            isClearable
                        />
                    </div>
                </Col>

                <Col span={8} xs={24} sm={8}>
                    <div className={styles.formItemWrap}>
                        <FormLabel label={t("taskDetail.form.processWorkflowOnCompletion")} />
                        <KlassDropdown
                            options={processWorkflows}
                            valueKey="id"
                            labelKey="name"
                            getOptionLabel={formatCodeName}
                            value={processWorkflows.find(
                                (workflow) => workflow.id === taskData.completedProcessWorkflowId
                            )}
                            onChange={
                                isStudentView
                                    ? () => {}
                                    : (value: Workflow) => onChangeData("completedProcessWorkflowId", value?.id)
                            }
                            isMulti={false}
                            readOnly={isStudentView}
                            isClearable
                        />
                    </div>
                </Col>
            </Row>
        </div>
    )
}
