import {useTranslation} from "react-i18next"
import styles from "./PopupMessageSenderV2.module.css"
import {useVisible} from "hooks"
import {BaseButton} from "components"
import {ChatModalV2} from "../ChatModalV2"

type Props = {
    user?: any
    userIdField: string
    renderButton?: (props) => JSX.Element
    departmentId?: number
}

export function PopupMessageSenderV2(props: Props) {
    const {user, userIdField, renderButton, departmentId} = props
    const {t} = useTranslation(["user"])
    const senderPopup = useVisible()

    return (
        <>
            {renderButton ? (
                renderButton({onClick: senderPopup.open})
            ) : (
                <BaseButton title={t("user.message")} className={styles.messageBtn} onClick={senderPopup.open} />
            )}
            {senderPopup.isVisible && (
                <ChatModalV2
                    user={user}
                    onShow={senderPopup.isVisible}
                    onClose={senderPopup.close}
                    userIdField={userIdField}
                    departmentId={departmentId}
                />
            )}
        </>
    )
}
