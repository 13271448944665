import {get} from "lodash"
import BaseService from "./Base"

class DegreeLevelService extends BaseService {
    async getAll(params = {}) {
        const response = await this.post("/v1/degree-level/list", params)
        return get(response, "data", [])
    }

    async get(degreeLevelId) {
        const response = await this.post("/v1/degree-level/get", {degreeLevelId})
        return get(response, "data.data", {})
    }

    async getTotal() {
        const response = await this.post("/v1/degree-level/get-total", {})
        return get(response, "data.data", {})
    }

    async createDegreeLevel(params = {}) {
        const response = await this.post("/v1/degree-level/create", params)
        return get(response, "data.data", {})
    }

    async duplicateDegreeLevel(degreeLevels) {
        const response = await this.post(`/v1/degree-level/duplicate`, {degreeLevels})
        return get(response, "data", {})
    }

    async updateDegreeLevel(id, data, params = {}) {
        data.degreeLevelId = id
        const response = await this.post(`/v1/degree-level/edit`, data, params)
        return get(response, "data", {})
    }

    async deleteDegreeLevel(degreeLevelIds) {
        const response = await this.post(`/v1/degree-level/delete`, {degreeLevelIds})
        return get(response, "data", {})
    }
}

export default DegreeLevelService
