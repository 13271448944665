/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {Row, Col} from "antd"
import {campusesService} from "services"
import {BaseNewFilter} from "uiKit"
import {KlassDropAsyncPaginate} from "components/Select"
import {KlassappTableHeader, KlassappTable} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {courseService} from "services"
import {formatDecimal, formatDecimalWithoutRound, getFullName} from "helpers"
import {useModel, useVisible} from "hooks"
import {get, isEmpty, uniqBy} from "lodash"
import {useHistory} from "react-router-dom"
import {routing} from "helpers/routing"
import {BaseButton, Icon} from "components"
import {KlassappTableProps} from "types/common"
import styles from "./StudentsTab.module.css"
import {AddStudentPopup} from "./AddStudentPopup"
import {ChangeCourseStatusPopup} from "./parts"
import {Course} from "types/courses"
import moment from "moment"
import {AddDropVariant} from "types/terms"
import {getDateOnly} from "sections/academics/instructional/common/utils"

type Props = KlassappTableProps & {
    scheduleId: number
    activeSchedule: any
    courseId: number
    termId: number
    fromRoute: "registrar" | "instructional"
    courseInfo: Course.CourseTableItem
}

function StudentsTab(props: Props) {
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        tableHeaderActions,
        menuActions,
        scheduleId,
        orderField,
        isLoading,
        isShowTableHeaderAction,
        courseInfo,
        isLoadedTableFuncs,
        courseId,
        termId,
        activeSchedule,
        fromRoute
    } = props
    const history = useHistory()
    const model = useModel()
    const addStudentPopup = useVisible(false)
    const changeCourseStatusPopup = useVisible(false)
    const [filter, setFilter] = useState({campuses: null, schedule: null})
    const [search, setSearch] = useState<string>()
    const [changeCourseStatusInfo, setChangeCourseStatusInfo] = useState(null)
    const studentProfileIds = data.map((item) => item.student.profileId)

    const getFields = () => {
        const fields = [
            "ID",
            "Name",
            "Last Lesson",
            "Grade (GPA)",
            "Attendance",
            "Is Registered",
            "Schedule",
            "Academic Status"
        ]
        if (!model.clientSetting.isDepartmentalStatusesHidden) {
            fields.push("Academic Status")
        }
        if (model.clientSetting.isNewStudentStatusesVisible) {
            fields.push("Student Status")
        }
        return fields
    }

    const getPageTitle = () => {
        return "Student"
    }

    const getColumns = () => {
        const columns = [
            {
                title: "ID",
                field: "customProfileId",
                orderField: "customProfileId",
                sortable: true
            },
            {
                title: "Name",
                field: "fullName",
                sortable: true,
                style: {minWidth: "200px"}
            },
            {
                title: "Last Lesson",
                field: "lda",
                fieldType: "date",
                format: "MM/DD/YYYY"
            },
            {
                title: "Grade (GPA)",
                field: "gpa"
            },
            {
                title: "Attendance",
                field: "percentage"
            },
            {
                title: "Is Registered",
                field: "isRegistered",
                sortable: true
            },
            {
                title: "Schedule",
                field: "scheduleName",
                sortable: true
            }
        ]
        if (!model.clientSetting.isDepartmentalStatusesHidden) {
            columns.push({
                title: "Academic Status",
                field: "academicStatus",
                sortable: true
            })
        }
        if (model.clientSetting.isNewStudentStatusesVisible) {
            columns.push({
                title: "Student Status",
                field: "statusName",
                sortable: true
            })
        }
        return columns
    }

    const onClickChangeCourseStatus = (item) => {
        setChangeCourseStatusInfo(item)
        changeCourseStatusPopup.open()
    }

    const getMenuActions = () => {
        return [
            {
                title: "Change Status",
                icon: "EDIT",
                action: onClickChangeCourseStatus
            }
        ]
    }

    const renderName = (item) => {
        const timezone = courseInfo?.timezone
        const isDropBeforeAddDrop =
            item.addDropVariant === AddDropVariant.Date
                ? moment(item.droppedDate).isSameOrBefore(moment(item.addDropDate))
                : moment(item.droppedDate).isSameOrBefore(moment(item.addDropHours))
        return (
            <div className={styles.name}>
                <div className={styles.nameStudent}>
                    <span>{getFullName(item)}</span>
                </div>
                {!!item.isMakeup && (
                    <div className={styles.icon}>
                        <Icon icon="PLUS_PERSON" color="#666" />
                    </div>
                )}
                {!!item.isDropped && (
                    <p className={styles.droppedText} style={{color: isDropBeforeAddDrop ? "#1890ff" : "red"}}>
                        DD (
                        {getDateOnly({
                            date: item.droppedDate,
                            placeholder: true,
                            dateFormat: model.getUserDateFormat()
                        })}
                        )
                    </p>
                )}
                {!!item.isLoa && (
                    <p className={styles.droppedText} style={{color: "#1890ff"}}>
                        LOA (
                        {getDateOnly({
                            date: item.statusUpdatedAt,
                            placeholder: true,
                            dateFormat: model.getUserDateFormat()
                        })}
                        )
                    </p>
                )}
            </div>
        )
    }

    const getData = async () => {
        dispatch({isLoading: true})
        try {
            const params: any = {
                range: {page, pageSize},
                filter: {courseIds: [courseId]}
            }
            if (!isEmpty(orderField)) {
                params.sort = {
                    orderBy: orderField.field,
                    orderDir: orderField.order
                }
            }
            if (termId) {
                params.filter.termIds = [termId]
            }
            if (!isEmpty(filter.campuses)) {
                params.filter.campusIds = filter.campuses.map((item) => item.id)
            }
            if (search && search !== "") {
                params.filter.search = search
            }
            const profileId = model.profileId
            if (!model.isStaffOrAdmin() && profileId) {
                params.filter.studentProfileIds = [profileId]
            }
            if (scheduleId) {
                params.filter.scheduleId = scheduleId
            }
            const {data: studentStats, total} = await courseService.listInstructionalStudents(params)
            const result = studentStats.map((item: any) => {
                item.id = item.student.profileId
                item.profileId = item.student.profileId
                item.fullName = renderName(item.student)
                item.customProfileId = item.student.customProfileId
                item.scheduleName = item.student.scheduleName
                item.statusName = item.student?.statusName ?? "Not Defined"
                item.gpa = formatDecimalWithoutRound(item.student?.gpa)
                item.academicStatus = item.student?.academicStatus ?? "Not Defined"
                item.percentage = `${formatDecimal(parseFloat(get(item, ["student", "percentage"]) ?? 0))}%`
                item.isRegistered = !!item.student?.isRegistered ? <Icon icon="CHECKED" /> : null
                return item
            })
            dispatch({data: result, total: total})
        } catch (e) {
            console.log("e")
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClickRowItem = (row) => {
        if (fromRoute === "registrar") {
            history.push(routing.registrar.courseStudentDetail(courseId, termId, row.profileId))
        } else {
            history.push(routing.academics.instructional.courseStudentDetail(courseId, termId, row.profileId))
        }
    }

    const onClickDeleteMulti = async () => {}

    const onClickDuplicateMulti = async () => {}

    const onClickEdit = () => {}

    const onClickDelete = async () => {}

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "getMenuActions", func: getMenuActions},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickDuplicateMulti", func: onClickDuplicateMulti},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDelete},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [])

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getData()
        }
    }, [isLoadedTableFuncs, page, pageSize, search, scheduleId, orderField])

    useEffect(() => {
        props.updateTableHeaderActions()
    }, [data])

    const onChangeFilter = (key, value) => {
        const newFilter = {...filter}
        newFilter[key] = value
        setFilter(newFilter)
    }

    const onApplyFilter = () => {
        getData()
    }

    const onClickClearFilter = () => {
        setFilter({campuses: null, schedule: null})
    }

    const onSearchInput = (text) => {
        setSearch(text)
    }

    const onCampusSearchChange = async (search = "", loadedOptions) => {
        try {
            const params = {
                filter: {search},
                range: {limit: 20, offset: loadedOptions.length},
                sort: {orderBy: "name", orderDir: "asc"}
            }
            const {data: campuses, total} = await campusesService.getAll(params)
            return {
                options: campuses,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onCloseChangeCourseStatus = () => {
        changeCourseStatusPopup.close()
        setChangeCourseStatusInfo(null)
        getData()
    }

    const renderAddButton = () => {
        if (isEmpty(activeSchedule)) {
            return null
        }
        return (
            <div className={styles.addStudentBox}>
                <div className={styles.actionButton}>
                    <BaseButton title="Add Student" onClick={addStudentPopup.open} />
                </div>
            </div>
        )
    }

    return (
        <div>
            <BaseNewFilter
                filter={filter}
                onClick={onApplyFilter}
                onClickClear={onClickClearFilter}
                renderRightFilter={() => renderAddButton()}
                onSearchInput={onSearchInput}>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <KlassDropAsyncPaginate
                            value={filter.campuses}
                            onChange={(newValue) => onChangeFilter("campuses", newValue)}
                            loadOptions={onCampusSearchChange}
                            isMulti
                            placeholder="Campus"
                        />
                    </Col>
                </Row>
            </BaseNewFilter>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                menuActions={menuActions}
                isLoading={isLoading}
                allFields={allFields}
                orderField={orderField}
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onUpdateRowData={props.onUpdateRowData}
                onClickSortColumn={props.onClickSortColumn}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
            />
            {addStudentPopup.isVisible && (
                <AddStudentPopup
                    studentProfileIds={studentProfileIds}
                    isShow={addStudentPopup.isVisible}
                    onClose={() => {
                        addStudentPopup.close()
                        getData()
                    }}
                    campusIds={courseInfo?.campusIds ?? []}
                    courseInfo={courseInfo}
                    termId={termId}
                    courseId={courseId}
                    activeSchedule={activeSchedule}
                />
            )}
            {changeCourseStatusPopup.isVisible && changeCourseStatusInfo && (
                <ChangeCourseStatusPopup
                    isShow={changeCourseStatusPopup.isVisible}
                    onClose={onCloseChangeCourseStatus}
                    studentProfileId={changeCourseStatusInfo.student.studentProfileId}
                    termId={changeCourseStatusInfo.student.termId}
                    courseId={changeCourseStatusInfo.student.courseId}
                    scheduleId={changeCourseStatusInfo.student.scheduleId}
                    academicPlanCourseId={changeCourseStatusInfo.student.academicPlanCourseId}
                />
            )}
        </div>
    )
}

export default KlassappTableHOC(StudentsTab)
