import React, {MutableRefObject} from "react"
import classNames from "classnames"
import {Icon} from "components/Icon"
import {CheckboxType} from "types/tasks"
import styles from "./item.module.css"
import {RemarkIndicator} from "./RemarkIndicator"

type Props = {
    field: any
    canSign: boolean
    errorFieldIds: string[]
    fieldRefs: MutableRefObject<Record<string, HTMLDivElement>>
    onChangeField: (fieldId: string, data: any) => void
    onChangeSingleCheckboxField: (fieldData: any, data: any) => void
}

export function Checkbox(props: Props) {
    const {field, canSign, errorFieldIds, fieldRefs, onChangeField, onChangeSingleCheckboxField} = props
    const {top, left, width, height} = field.placeInformation
    const {isYourField} = field
    const style: any = {width, height}

    if (!canSign || !isYourField) {
        style.backgroundColor = "transparent"
        style.borderColor = "transparent"
    }

    const onToggleCheck = () => {
        if (!canSign || !isYourField) {
            return
        }
        if (field.checkboxType === CheckboxType.MULTIPLE) {
            onChangeField(field.id, {isSigned: true, value: !field.value})
        } else {
            onChangeSingleCheckboxField(
                {fieldId: field.id, parentId: field.parentId},
                {isSigned: true, value: !field.value}
            )
        }
    }

    const renderField = () => {
        if (!field.isSigned) {
            return <p className={styles.label}>{field.label}</p>
        }
        if (field.value) {
            return <Icon icon="CHECKBOX_CHECKED" className={styles.iconChecked} />
        }
        return null
    }

    const renderBody = () => {
        if (!canSign || !isYourField) {
            return field.value ? (
                <Icon
                    icon="CHECKBOX_CHECKED"
                    className={styles.iconChecked}
                    color={`var(--${field.backgroundColor}-400-base)`}
                />
            ) : null
        }
        return renderField()
    }

    return (
        <>
            <div className={styles.wrap} style={{left, top}}>
                <div
                    ref={(ref) => (fieldRefs.current[field.id] = ref)}
                    className={classNames(styles.actionItem, {
                        [styles.actionItemRequired]: isYourField && field.required,
                        [styles.flexCenter]: field.isSigned && field.value,
                        [styles.hasCommentBorder]: !!field.remarkComment,
                        [styles.actionItem_error]: errorFieldIds.includes(field.id)
                    })}
                    style={style}
                    onClick={onToggleCheck}>
                    {renderBody()}
                </div>
            </div>
            {field.remarkComment && (
                <div className={styles.wrap} style={{left, top, zIndex: 9}}>
                    <RemarkIndicator field={field} />
                </div>
            )}
        </>
    )
}
