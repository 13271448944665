/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react"
import cx from "classnames"
import {useModel, useVisible} from "hooks"
import {BaseButton, Icon} from "components"
import styles from "./StudentServicesPanelInfo.module.css"
import {Auth} from "types/auth"
import {useTranslation} from "react-i18next"
import {academicPlansService, studentService, studentServicesService} from "services"
import {checkPermission, getFieldLabel, getStudentTotalProgress, getTotalHourStatistics, handleError} from "helpers"
import {ConfirmPopup, ProgressItem, StudentScore, TotalProgress} from "uiKit"
import {AcademicPlans} from "types/academicPlans"
import {BaseDepartmentId} from "types/departments"
import {StudentServicesStudentTab} from "sections/StudentServices/StudentServicesStudent/StudentServicesStudent"
import {RegistrarStudentTab} from "sections/academics/student/AcademicsStudent"
import {PermissionsRequired} from "components/PermissionsRequired"
import {Permissions} from "types/permission"
import {NewStudentStatusSelectFilter} from "components/NewStudentStatusSelect"
import {Settings} from "types/settings"

interface PanelInfoProps {
    tab?: string
    student?: Auth.DepartmentStudent
    reloadStudent?: (newProfileId: number) => void
    reloadStudentTable?: (profileId: number, newProfileId: number) => void
}

export function StudentServicesPanelInfo({tab, student, reloadStudent, reloadStudentTable}: PanelInfoProps) {
    const {t} = useTranslation(["studentServices"])
    const model = useModel()
    const detailVisible = useVisible(true)
    const cancelEnrollConfirmPopup = useVisible(false)
    const cancelActiveConfirmPopup = useVisible(false)
    const enrollConfirmPopup = useVisible(false)
    const activeStudentPopup = useVisible(false)
    const [newStudentStatus, setNewStudentStatus] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [profileId, setProfileId] = useState(student?.profileId)
    const [profileState, setProfileState] = useState(student?.profileState)
    const [admissionsChecklistProgress, setAdmissionsChecklistProgress] = useState({total: 0, completed: 0})
    const [finAidChecklistProgress, setFinAidChecklistProgress] = useState({total: 0, completed: 0})
    const [enrollStats, setEnrollStats] = useState({
        status: AcademicPlans.EnrollmentStatus.NotStarted,
        termTracks: "",
        numberOfRegisteredCourses: 0,
        numberOfCredits: 0
    })
    const [studentStats, setStudentStats] = useState<Auth.StudentStatistics>()

    const hasPermissionsToSeeAcademicProgress = checkPermission(
        {
            student: [Permissions.Student.Users.AcademicProgress.OverallProgress.View]
        },
        model
    )

    useEffect(() => {
        if (student) {
            setProfileId(student.profileId)
            setProfileState(student.profileState)
        }
    }, [student])

    useEffect(() => {
        if (!tab || ([StudentServicesStudentTab.Home, RegistrarStudentTab.Workspace] as string[]).includes(tab)) {
            ;(async function getStudentChecklistProgress() {
                try {
                    const [admissions, finAid] = await Promise.all([
                        studentService.getStudentChecklistProgress({
                            profileId,
                            departmentId: BaseDepartmentId.Admissions
                        }),
                        studentService.getStudentChecklistProgress({
                            profileId,
                            departmentId: BaseDepartmentId.FinancialAid
                        })
                    ])
                    setAdmissionsChecklistProgress(admissions)
                    setFinAidChecklistProgress(finAid)
                } catch (error) {
                    handleError(error)
                }
            })()
        }
        if (([StudentServicesStudentTab.AcademicPlan, RegistrarStudentTab.AcademicPlan] as string[]).includes(tab)) {
            ;(async function getEnrollStatsStudent() {
                try {
                    const data = await academicPlansService.getEnrollmentStats({studentProfileId: profileId})
                    setEnrollStats(data)
                } catch (error) {
                    handleError(error)
                }
            })()
        }
        if (
            (
                [
                    StudentServicesStudentTab.Academic,
                    StudentServicesStudentTab.DegreeAudit,
                    RegistrarStudentTab.Academic,
                    RegistrarStudentTab.DegreeAudit
                ] as string[]
            ).includes(tab)
        ) {
            ;(async function getStudentStatistics() {
                try {
                    const data = await studentService.getOverallStats({studentProfileId: profileId})
                    setStudentStats(data)
                } catch (error) {
                    handleError(error)
                }
            })()
        }
    }, [profileId, tab])

    const handleEnrollConfirm = useCallback(async () => {
        try {
            setIsLoading(true)
            await studentServicesService.enrollStudent({
                studentProfileId: profileId,
                studentStatusIdToAssign: newStudentStatus?.statusId
            })

            setProfileState(Auth.StudentState.Enrollment)
            reloadStudent?.(profileId)
            reloadStudentTable?.(profileId, profileId)
            setNewStudentStatus(null)
            enrollConfirmPopup.close()
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }, [profileId, reloadStudent, reloadStudentTable, newStudentStatus])

    const handleActiveStudentConfirm = useCallback(async () => {
        try {
            setIsLoading(true)
            await studentServicesService.markStudentAsAttending({
                studentProfileId: profileId,
                studentStatusIdToAssign: newStudentStatus?.statusId
            })

            setProfileState(Auth.StudentState.Student)
            reloadStudent?.(profileId)
            reloadStudentTable?.(profileId, profileId)
            setNewStudentStatus(null)
            activeStudentPopup.close()
        } catch (error) {
            handleError(error, true)
        } finally {
            setIsLoading(false)
        }
    }, [profileId, reloadStudent, reloadStudentTable, newStudentStatus])

    const handleMarkStudentAsNotAttending = useCallback(async () => {
        try {
            setIsLoading(true)
            await studentServicesService.markStudentAsNotAttending({
                studentProfileId: profileId,
                studentStatusIdToAssign: newStudentStatus?.statusId
            })
            setProfileState(Auth.StudentState.Enrollment)
            reloadStudent?.(profileId)
            reloadStudentTable?.(profileId, profileId)
            setNewStudentStatus(null)
            cancelActiveConfirmPopup.close()
        } catch (error) {
            handleError(error, true)
        } finally {
            setIsLoading(false)
        }
    }, [profileId, reloadStudent, reloadStudentTable, newStudentStatus])

    const handleCancelEnrollment = useCallback(async () => {
        try {
            setIsLoading(true)
            await studentServicesService.cancelStudentEnrollment({
                studentProfileId: profileId,
                studentStatusIdToAssign: newStudentStatus?.statusId
            })
            setProfileState(Auth.StudentState.Applicant)
            reloadStudent?.(profileId)
            reloadStudentTable?.(profileId, profileId)
            setNewStudentStatus(null)
            cancelEnrollConfirmPopup.close()
        } catch (error) {
            handleError(error, true)
        } finally {
            setIsLoading(false)
        }
    }, [profileId, reloadStudent, reloadStudentTable, newStudentStatus])

    const renderOutStatus = () => {
        if (profileState === Auth.StudentState.TemporaryOut) {
            return (
                <div className={styles.fullyEnrollWrap}>
                    <button className={`${styles.enrolledBtn} ${styles.permanentOutColor}`}>
                        <Icon icon="CHECKED" color="white" />
                        {t("studentInfo.temporaryOut")}
                    </button>
                </div>
            )
        }
        if ([Auth.StudentState.PermanentOut, Auth.StudentState.NeverAttended].includes(profileState)) {
            return (
                <div className={styles.fullyEnrollWrap}>
                    <button className={`${styles.enrolledBtn} ${styles.permanentOutColor}`}>
                        <Icon icon="CHECKED" color="white" />
                        {t("studentInfo.permanentOut")}
                    </button>
                </div>
            )
        }
    }

    const renderActiveStudentAction = () => {
        if (model.isStudent()) {
            if (profileState === Auth.StudentState.Student) {
                return (
                    <div className={styles.fullyEnrollWrap}>
                        <button
                            className={`${styles.enrolledBtn} ${styles.enrolledColor}`}
                            style={{
                                fontSize: 14
                            }}>
                            <Icon icon="CHECKED" />
                            {t("studentInfo.activeStudent")}
                        </button>
                    </div>
                )
            }
        }
        if (model.isStaffOrAdmin()) {
            if (profileState === Auth.StudentState.Enrollment) {
                return (
                    <PermissionsRequired
                        permissions={{
                            staff: [
                                Permissions.Staff.StudentServicesAndAcademicAffairs.StudentServices.Students
                                    .StudentDetail.Edit,
                                Permissions.Staff.AcademicAffairs.StudentServices.Students.StudentDetail.Edit
                            ]
                        }}>
                        <PermissionsRequired
                            permissions={{
                                staff: [Permissions.Staff.Users.Students.StatusesOf.Student.Edit]
                            }}>
                            <BaseButton
                                title={t("studentInfo.activeStudent")}
                                className={styles.enrolledBtn}
                                onClick={activeStudentPopup.open}
                            />
                        </PermissionsRequired>
                    </PermissionsRequired>
                )
            }
            if (profileState === Auth.StudentState.Student) {
                return (
                    <div className={styles.fullyEnrollWrap}>
                        <button
                            className={`${styles.enrolledBtn} ${styles.enrolledColor}`}
                            style={{
                                fontSize: 14
                            }}>
                            <Icon icon="CHECKED" />
                            {t("studentInfo.activeStudent")}
                        </button>
                        <PermissionsRequired
                            permissions={{
                                staff: [
                                    Permissions.Staff.StudentServicesAndAcademicAffairs.StudentServices.Students
                                        .StudentDetail.Edit,
                                    Permissions.Staff.AcademicAffairs.StudentServices.Students.StudentDetail.Edit
                                ]
                            }}>
                            <PermissionsRequired
                                permissions={{
                                    staff: [Permissions.Staff.Users.Students.StatusesOf.Enrollment.Edit]
                                }}>
                                <BaseButton
                                    title={t("common:action.cancel")}
                                    variant="secondary"
                                    onClick={cancelActiveConfirmPopup.open}
                                />
                            </PermissionsRequired>
                        </PermissionsRequired>
                    </div>
                )
            }
        }

        return null
    }

    const renderAction = () => {
        if (model.isStudent()) {
            if (profileState === Auth.StudentState.Enrollment || profileState === Auth.StudentState.Student) {
                return (
                    <div className={styles.fullyEnrollWrap}>
                        <button className={`${styles.enrolledBtn} ${styles.enrolledColor}`}>
                            <Icon icon="CHECKED" />
                            {t("studentInfo.fullyEnrolled")}
                        </button>
                    </div>
                )
            }
            return null
        }

        if (profileState === Auth.StudentState.Enrollment || profileState === Auth.StudentState.Student) {
            return (
                <div className={styles.fullyEnrollWrap}>
                    <button className={`${styles.enrolledBtn} ${styles.enrolledColor}`}>
                        <Icon icon="CHECKED" />
                        {t("studentInfo.fullyEnrolled")}
                    </button>
                    {profileState !== Auth.StudentState.Student ? (
                        <PermissionsRequired
                            permissions={{
                                staff: [
                                    Permissions.Staff.StudentServicesAndAcademicAffairs.StudentServices.Students
                                        .StudentDetail.Edit,
                                    Permissions.Staff.AcademicAffairs.StudentServices.Students.StudentDetail.Edit
                                ]
                            }}>
                            <PermissionsRequired
                                permissions={{
                                    staff: [Permissions.Staff.Users.Students.StatusesOf.Applicant.Edit]
                                }}>
                                <BaseButton
                                    title={t("common:action.cancel")}
                                    variant="secondary"
                                    onClick={cancelEnrollConfirmPopup.open}
                                />
                            </PermissionsRequired>
                        </PermissionsRequired>
                    ) : null}
                </div>
            )
        }
        if (
            profileState === Auth.StudentState.TemporaryOut ||
            profileState === Auth.StudentState.PermanentOut ||
            profileState === Auth.StudentState.NeverAttended
        ) {
            return null
        }

        return (
            <PermissionsRequired
                permissions={{
                    staff: [
                        Permissions.Staff.StudentServicesAndAcademicAffairs.StudentServices.Students.StudentDetail.Edit,
                        Permissions.Staff.AcademicAffairs.StudentServices.Students.StudentDetail.Edit
                    ]
                }}>
                <PermissionsRequired
                    permissions={{
                        staff: [Permissions.Staff.Users.Students.StatusesOf.Enrollment.Edit]
                    }}>
                    <BaseButton
                        title={t("studentInfo.enrollStudent")}
                        className={styles.enrolledBtn}
                        onClick={enrollConfirmPopup.open}
                    />
                </PermissionsRequired>
            </PermissionsRequired>
        )
    }

    const admissionsChecklistCompletion = !admissionsChecklistProgress.total
        ? 0
        : Math.round((admissionsChecklistProgress.completed / admissionsChecklistProgress.total) * 100)

    const finAidChecklistCompletion = !finAidChecklistProgress.total
        ? 0
        : Math.round((finAidChecklistProgress.completed / finAidChecklistProgress.total) * 100)

    const renderStudentProgress = () => {
        if (!studentStats) return null
        const totalHours = getTotalHourStatistics(model, studentStats, student.programVersionTotalClockHour)
        const totalHoursLabel = getFieldLabel(model, Settings.AcademicLabel.TotalHours)

        return (
            <div className={styles.studentLeftInfo}>
                <TotalProgress percent={getStudentTotalProgress(studentStats)} />
                {hasPermissionsToSeeAcademicProgress && (
                    <ProgressItem
                        title="Total hours"
                        totalTitle={totalHoursLabel}
                        totalHours={totalHours}
                        totalContractedHours={studentStats.totalHours}
                        isRightPanel
                    />
                )}
            </div>
        )
    }

    const renderStudentStats = () => {
        if (!hasPermissionsToSeeAcademicProgress) return null
        if (!studentStats) return null
        return (
            <div className={styles.studentRightInfo}>
                <div className={styles.studentLeftInfo}>
                    <StudentScore studentStats={studentStats} isRightPanel />
                </div>
            </div>
        )
    }

    const renderNewStudentStatusSelect = (state: Auth.StudentState) => {
        if (!model.clientSetting.isNewStudentStatusesVisible) return null
        return (
            <NewStudentStatusSelectFilter
                value={newStudentStatus}
                onChange={setNewStudentStatus}
                isMulti={false}
                forState={state}
            />
        )
    }

    return (
        <div className={styles.panelWrap}>
            <div className={styles.headingWrap}>
                <div className={styles.headingTitle}>Student Services Details</div>
                <button
                    className={cx(styles.collapseButton, {[styles.collapsed]: detailVisible.isVisible})}
                    onClick={() => detailVisible.toggle()}>
                    <Icon icon="ARROW_DOWN" color="#666666" className={styles.headingIcon} />
                </button>
            </div>

            {detailVisible.isVisible && (
                <>
                    {(!tab ||
                        ([StudentServicesStudentTab.Home, RegistrarStudentTab.Workspace] as string[]).includes(
                            tab
                        )) && (
                        <>
                            <div className={styles.contentWrap}>
                                <div className={styles.contentTitle}>Admission Checklist</div>
                                <div className={styles.contentValue}>{admissionsChecklistCompletion}%</div>
                            </div>
                            <div className={styles.contentWrap}>
                                <div className={styles.contentTitle}>Financial Aid Checklist</div>
                                <div className={styles.contentValue}>{finAidChecklistCompletion}%</div>
                            </div>
                        </>
                    )}

                    {([StudentServicesStudentTab.AcademicPlan, RegistrarStudentTab.AcademicPlan] as string[]).includes(
                        tab
                    ) && (
                        <>
                            <div className={styles.contentWrap}>
                                <div className={styles.contentTitle}>{t("studentInfo.courseRegistration")}</div>
                                <div className={styles.contentValue}>
                                    {enrollStats.status === AcademicPlans.EnrollmentStatus.InProgress
                                        ? "In Progress"
                                        : "Not Started"}
                                </div>
                            </div>
                            <div className={styles.contentWrap}>
                                <div className={styles.contentTitle}>{t("studentInfo.termTrack")}</div>
                                <div className={styles.contentValue}>{enrollStats.termTracks}</div>
                            </div>
                            <div className={styles.contentWrap}>
                                <div className={styles.contentTitle}>{t("studentInfo.registeredCourses")}</div>
                                <div className={styles.contentValue}>
                                    {enrollStats.numberOfRegisteredCourses || "-"}
                                </div>
                            </div>
                            <div className={styles.contentWrap}>
                                <div className={styles.contentTitle}>{t("studentInfo.credits")}</div>
                                <div className={styles.contentValue}>{enrollStats.numberOfCredits || "-"}</div>
                            </div>
                        </>
                    )}

                    {(
                        [
                            StudentServicesStudentTab.Academic,
                            StudentServicesStudentTab.DegreeAudit,
                            RegistrarStudentTab.Academic,
                            RegistrarStudentTab.DegreeAudit
                        ] as string[]
                    ).includes(tab) && (
                        <>
                            {renderStudentProgress()}
                            {renderStudentStats()}
                        </>
                    )}

                    {!!profileId && <div className={styles.contentWrap}>{renderAction()}</div>}
                    {!!profileId && <div className={styles.contentWrap}>{renderActiveStudentAction()}</div>}
                    {!!profileId && <div className={styles.contentWrap}>{renderOutStatus()}</div>}
                </>
            )}

            <ConfirmPopup
                isVisible={cancelEnrollConfirmPopup.isVisible}
                title={t("cancelEnrollTitle")}
                description={t("cancelEnrollDesc", {
                    userName: `${student.firstName} ${student.lastName}`
                })}
                onClose={cancelEnrollConfirmPopup.close}
                loading={isLoading}
                onConfirm={handleCancelEnrollment}>
                {renderNewStudentStatusSelect(Auth.StudentState.Applicant)}
            </ConfirmPopup>

            <ConfirmPopup
                isVisible={enrollConfirmPopup.isVisible}
                title={t("enrollConfirmTitle")}
                description={t("enrollConfirmDescription", {
                    userName: `${student.firstName} ${student.lastName}`
                })}
                onClose={enrollConfirmPopup.close}
                loading={isLoading}
                onConfirm={handleEnrollConfirm}>
                {renderNewStudentStatusSelect(Auth.StudentState.Enrollment)}
            </ConfirmPopup>

            <ConfirmPopup
                isVisible={activeStudentPopup.isVisible}
                title={t("activeConfirmTitle")}
                description={t("activeConfirmDescription", {
                    userName: `${student.firstName} ${student.lastName}`
                })}
                onClose={activeStudentPopup.close}
                loading={isLoading}
                onConfirm={handleActiveStudentConfirm}>
                {renderNewStudentStatusSelect(Auth.StudentState.Student)}
            </ConfirmPopup>

            <ConfirmPopup
                isVisible={cancelActiveConfirmPopup.isVisible}
                title={t("cancelActiveTitle")}
                description={t("cancelActiveDesc", {
                    userName: `${student.firstName} ${student.lastName}`
                })}
                onClose={cancelActiveConfirmPopup.close}
                loading={isLoading}
                onConfirm={handleMarkStudentAsNotAttending}>
                {renderNewStudentStatusSelect(Auth.StudentState.Enrollment)}
            </ConfirmPopup>
        </div>
    )
}
