import {get} from "lodash"
import {Branding} from "types/branding"
import BaseService from "./Base"

class BrandingsService extends BaseService {
    async getBrandings(data: Branding.GetBrandingParams = {}): Promise<Branding.Branding[]> {
        const response = await this.post("/v1/settings/brandings/list", data)
        return get(response, "data.data", [])
    }

    async updateBrandings(data: FormData): Promise<Branding.Branding[]> {
        const response = await this.post("/v1/settings/brandings/update", data)
        return get(response, "data.data", [])
    }

    async deleteBrandings(data = {}): Promise<boolean> {
        const response = await this.post("/v1/settings/brandings/delete", data)
        return get(response, "data.data", false)
    }
}

export default BrandingsService
