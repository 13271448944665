import React from "react"
import {Col, Row} from "antd"
import {StatusCardItem} from "./StatusCardItem"
import styles from "./FAStatusCards.module.css"
import {useTranslation} from "react-i18next"

export function FAStatusCards(props) {
    const {t} = useTranslation(["student"])
    return (
        <div className={styles.wrap}>
            <Row gutter={[24, 12]}>
                <Col span={6}>
                    <StatusCardItem item={{title: "Label", total: "0", cost: "0.00", isUp: true}} />
                </Col>
                <Col span={6}>
                    <StatusCardItem item={{title: "Label", total: "0", cost: "0.00", isUp: false}} />
                </Col>
                <Col span={6}>
                    <StatusCardItem item={{title: "Label", total: "0", cost: "0.00", isUp: false}} />
                </Col>
                <Col span={6}>
                    <StatusCardItem item={{title: "Label", total: "0", cost: "0.00", isUp: true}} />
                </Col>
            </Row>
        </div>
    )
}
