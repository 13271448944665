import React from "react"
import {useTranslation} from "react-i18next"
import {BaseButton, SecondaryButton} from "components/buttons"
import {ConfirmPopup} from "uiKit"
import {useVisible} from "hooks"
import styles from "./Stages.module.css"

export function ActionButton(props) {
    const {t} = useTranslation("common")
    const {isSubmitting, onClickCancel, onClickSave} = props
    const confirmPopup = useVisible(false)

    const onClickCancelBtn = () => {
        confirmPopup.open()
    }

    const onCloseConfirmPopup = () => {
        confirmPopup.close()
    }

    return (
        <div className={styles.buttonWrap}>
            <SecondaryButton title={t("action.cancel")} onClick={onClickCancelBtn} className={styles.cancelBtn} />
            <BaseButton title={t("action.save")} uppercase onClick={onClickSave} loading={isSubmitting} />
            <ConfirmPopup
                isVisible={confirmPopup.isVisible}
                title={t("message.changesWontSave")}
                onClose={onCloseConfirmPopup}
                onConfirm={onClickCancel}
            />
        </div>
    )
}
