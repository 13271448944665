import {io, Socket} from "socket.io-client"
import {store} from "helpers"
import {SocketEvent} from "helpers/socket"

interface SocketResponse<T> {
    success: boolean
    message?: string
    data?: T
}

const DEFAULT_EMIT_TIMEOUT_MS = 30 * 1000
const TIMEOUT_CODE = 504
export const SOCKET_PATH_EDULAR_CORE = "/edular-core-socketio/"

export default class SocketClient {
    private socket: Socket
    private userId?: number
    private profileId?: number
    private accessToken?: string

    private showLog = process.env.REACT_APP_SOCKET_LOG === "true"
    private isConnecting = false
    private isLoggingIn = false
    private reconnectCount = 0
    private lastReceivingTime = 0
    private lastSendingTime = 0
    private boundEvents: {[event: string]: Array<(data?: any) => void>} = {}
    private joinedRooms = new Set<string>()

    constructor(private url: string, private path: string) {
        // We on-hold init socket until user logged in
        // this.initSocket(url, path)
    }

    private _isLoggedIn = false

    public get isLoggedIn(): boolean {
        return this._isLoggedIn
    }

    public set isLoggedIn(value: boolean) {
        this._isLoggedIn = value
    }

    public get isConnected(): boolean {
        return this.socket.connected
    }

    public setUserId = (userId?: number): void => {
        this.userId = userId
    }

    public setProfileId = (profileId?: number): void => {
        this.profileId = profileId
    }

    public setAccessToken = (accessToken?: string): void => {
        this.accessToken = accessToken
    }

    public connect(): void {
        this.socket.connect()
    }

    public disconnect(): void {
        this.socket.disconnect()
    }

    public reconnect(): void {
        if (!this.isConnecting) {
            this.isConnecting = true
            this.disconnect()
            this.connect()
        }
    }

    public on = (event: string, listener: (data?: any) => void): void => {
        this.boundEvents[event] ||= []
        this.boundEvents[event].push(listener)
        this.socket.on(event, listener)
    }

    public off = (event: string, listener?: (data?: any) => void): void => {
        this.socket.off(event, listener)
        if (!listener) {
            delete this.boundEvents[event]
        } else if (this.boundEvents[event]) {
            const index = this.boundEvents[event].indexOf(listener)
            if (index > -1) {
                this.boundEvents[event].splice(index, 1)
            }
        }
    }

    private sleep = async (ms: number): Promise<unknown> => new Promise((resolve) => setTimeout(resolve, ms))

    public emit = async <T>(event: string, data: any, timeoutMs = DEFAULT_EMIT_TIMEOUT_MS): Promise<T> => {
        if (!this.isConnected) {
            this.connect()
        }
        const timestamp = Date.now()

        while (!this.isLoggedIn && ![SocketEvent.Ping, SocketEvent.UserLogin].includes(event as SocketEvent)) {
            await this.sleep(1000)
            if (Date.now() - timestamp > timeoutMs) {
                this.log("emit error", {
                    event,
                    data,
                    response: {resultCode: TIMEOUT_CODE, errMessage: "timeout log in"}
                })
                throw new Error("[SOCKET] login timed-out")
            }
        }

        this.lastSendingTime = Date.now()
        this.log("emit send", {timestamp, event, data})

        return new Promise((resolve, reject) => {
            let timedOut = false
            const timeoutId = setTimeout(() => {
                timedOut = true
                this.log("emit error", {event, data, response: {resultCode: TIMEOUT_CODE, errMessage: "timeout"}})
                reject(new Error("[SOCKET] login timed-out"))
            }, timeoutMs)

            this.socket.emit(
                event,
                data,
                async (
                    response: SocketResponse<T> = {
                        success: false,
                        message: "Unknown response"
                    }
                ) => {
                    this.lastReceivingTime = Date.now()

                    if (timedOut) {
                        return // should ignore ack when setTimeout has been triggered
                    }

                    clearTimeout(timeoutId)

                    if (response.success) {
                        this.log("emit ack ", {timestamp, event, response})
                        resolve(response.data)
                        return
                    }

                    this.log("emit error", {event, data, response})

                    if (!response.success && response.message === "Unauthorized error") {
                        this.isLoggedIn = false
                        try {
                            this.accessToken = store.get("authToken")
                            this.reconnect()
                        } catch (err: any) {
                            console.warn(err.message)
                        }
                    }

                    reject(new Error(response.message))
                }
            )
        })
    }

    public joinRoom = async (room: string) => {
        this.joinedRooms.add(room)
        return this.emit(SocketEvent.SocketJoin, {room})
    }

    public leaveRoom = async (room: string) => {
        this.joinedRooms.delete(room)
        return this.emit(SocketEvent.SocketLeave, {room})
    }

    public initSocket(url: string, path: string) {
        const oldSocket = this.socket
        this.url = url
        this.path = path

        this.socket = io(this.url, {
            path: this.path,
            reconnection: true,
            forceNew: true,
            upgrade: true,
            rememberUpgrade: true,
            transports: ["websocket"]
        })

        this.socket.on("connecting", this.onConnecting)
        this.socket.on("connect", this.onConnect)
        this.socket.on("reconnecting", this.onReconnecting)
        this.socket.on("reconnect", this.onReconnect)
        this.socket.on("disconnect", this.onDisconnect)
        this.socket.on("connect_timeout", this.onConnectTimeout)
        this.socket.on("connect_error", this.onConnectError)
        this.socket.on("reconnect_error", this.onReconnectError)
        this.socket.on("reconnect_failed", this.onReconnectFailed)
        this.socket.on("error", this.onError)
        this.socket.on("ping", this.onPing)
        this.socket.on("pong", this.onPong)
        this.socket.onAny((event, data) => {
            this.log("on", {event, data})
            this.lastReceivingTime = Date.now()
        })

        if (oldSocket) {
            for (const [event, listeners] of Object.entries(this.boundEvents)) {
                for (const listener of listeners) {
                    this.socket.on(event, listener)
                }
            }
            oldSocket.disconnect()
        }
    }

    private onConnecting = async () => {
        this.log("on connecting")
        this.isConnecting = true
        this.increaseReconnectingCount()
    }

    private onReconnecting = async () => {
        this.log("on reconnecting")
        this.isConnecting = true
        this.increaseReconnectingCount()
    }

    private onConnect = async () => {
        this.log("on connected")
        await this.onConnected()
        this.isConnecting = false
        this.reconnectCount = 0
    }

    private onReconnect = async () => {
        this.log("on reconnected")
        await this.onConnected()
        this.isConnecting = false
        this.reconnectCount = 0
    }

    private onDisconnect = async (reason: string) => {
        this.log("on disconnected", {reason})
        this.isLoggedIn = false
        this.reconnectCount = 0
        // TODO
    }

    private onConnectTimeout = async () => {
        this.log("on connect_timeout")
    }

    private onConnectError = async (err: Error) => {
        this.log("on connect_error", err)
    }

    private onReconnectError = async (err: {name: string; message: string; description: any}) => {
        this.log("on reconnect_error", err)
    }

    private onReconnectFailed = async () => {
        this.log("on reconnect_failed")
    }

    private onError = async (err: {name: string; message: string; description: any}) => {
        this.log("on error", err)
    }

    private onPing = () => {
        this.lastSendingTime = Date.now()
        // console.log('on ping', Date.now());
    }

    private onPong = (ms: number) => {
        // ms: number of ms elapsed since ping packet (i.e.: latency).
        this.lastReceivingTime = Date.now()
        // console.log('on pong', Date.now(), ms, this.socket.io.engine.transport.name);
    }

    private increaseReconnectingCount = () => {
        this.reconnectCount += 1
        if (this.reconnectCount > 3) {
            this.reconnectCount = 0
            // TODO
        }
    }

    private onConnected = async () => {
        if (!this.isLoggingIn) {
            this.login().catch(console.log)
        }
    }

    public login = async (): Promise<void> => {
        this.isLoggingIn = true
        try {
            if (!this.userId || !this.profileId || !this.accessToken) {
                throw new Error("[SOCKET] User not logged in")
            }
            await this.emit<{}>(SocketEvent.UserLogin, {
                userId: this.userId,
                profileId: this.profileId,
                accessToken: this.accessToken
            })
            this.isLoggedIn = true
            this.joinedRooms.forEach((room) => this.joinRoom(room))
        } catch (err: any) {
            this.isLoggedIn = false
            this.log("login error", {error: err.message})
            // throw err;
        } finally {
            this.isLoggingIn = false
        }
    }

    /**
     * check alive, return true if alive, otherwise false
     */
    public ping = async (): Promise<boolean> => {
        try {
            await this.emit<void>(SocketEvent.Ping, {}, 7000)
            return true
        } catch {}
        return false
    }

    private log = (event = "general", data?: any) => {
        if (this.showLog) {
            const time = new Date()
            console.log("[SOCKET]", time.toLocaleTimeString(), event, data)
        }
    }
}
