import {get} from "lodash"
import BaseService from "./Base"
import {GeneralSetting} from "types/settings/general-setting"

class GeneralSettingsService extends BaseService {
    async getSettings(fields?: string[]): Promise<GeneralSetting[]> {
        const response = await this.post(`/v1/general-settings/list`, {fields})
        return get(response, "data.data", [])
    }

    async updateSettings(data: Partial<GeneralSetting>[]) {
        await this.post(`/v1/general-settings/edit`, data)
    }
}

export default GeneralSettingsService
