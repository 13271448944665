import {BaseInput} from "components"
import styles from "./OtherReasons.module.css"
import {useState} from "react"

type OtherReasonsProps = {
    reason: string
    setReason: (value: string) => void
}

const OtherReasons = (props: OtherReasonsProps) => {
    return (
        <div style={{height: "146px"}}>
            <span className={styles.selectTerm}>Write down a Reason (optional)</span>
            <BaseInput
                value={props.reason}
                onChange={(value) => props.setReason(value)}
                style={{marginTop: 10}}
                placeholder={"Type"}
            />
        </div>
    )
}

export default OtherReasons
