import React, {useState} from "react"
import {BaseButton, BasePopup, SecondaryButton} from "components"
import styles from "./PopupVerifyAttendance.module.css"
import {withTranslation} from "react-i18next"
import classNames from "classnames"
import {attendanceService} from "services"
import {handleError} from "helpers"
import {AttendanceStatus} from "types/attendance"
import {Course} from "types/courses"
import {Major} from "types/major"

type PropsPopupVerifyAttendance = {
    isShowConfirmVerify: boolean
    onCancelConfirm: () => void
    refreshData: () => void
    courseInfo?: Course.CourseTableItem
    lessonId?: number
    t: Function
    data: Array<{isChecked: boolean; attendanceStatus: AttendanceStatus; attendanceId: number; profileId: number}>
    type?: Course.ProgramAttendanceTrackingType
}

const PopupVerifyAttendance = (props: PropsPopupVerifyAttendance) => {
    const {
        isShowConfirmVerify,
        onCancelConfirm,
        lessonId,
        data = [],
        t,
        courseInfo,
        refreshData,
        type = Course.ProgramAttendanceTrackingType.Course
    } = props
    const [loading, setLoading] = useState(false)

    const onPressVerify = async () => {
        setLoading(true)
        try {
            const selectedAttendances = data.filter((item) => item.isChecked)
            const params = selectedAttendances.map((item) => {
                if (courseInfo && courseInfo?.type === Major.Unit.Clock) {
                    item.attendanceStatus = AttendanceStatus.Approved
                }
                return {
                    attendanceId: item.attendanceId,
                    status: item.attendanceStatus,
                    isVerified: true,
                    lessonId,
                    profileId: item.profileId
                }
            })
            if (type === Course.ProgramAttendanceTrackingType.Course) {
                await attendanceService.updateAttendance(params)
            } else if (type === Course.ProgramAttendanceTrackingType.Daily) {
                await attendanceService.updateDailyAttendance(params)
            }
            await refreshData()
            onCancelConfirm()
        } catch (err) {
            handleError(err)
        } finally {
            setLoading(false)
        }
    }

    return (
        <BasePopup
            isShow={isShowConfirmVerify}
            onClose={onCancelConfirm}
            width="70vw"
            leftIcon="WARNING_SOLID"
            leftIconColor="#fff">
            <div>
                <div className={styles.popupTitle}>{t("common:confirmationPopup.confirmVerifyAttendance")}</div>
                <span className={styles.smallTitle}>{t("common:confirmationPopup.smallTextVerifyAttendance")}</span>
                <div>
                    <div className={classNames(styles.actionDetailWrapPopup)}>
                        <div>
                            <SecondaryButton onClick={onCancelConfirm} title={t("common:action.cancelModal")} />
                        </div>
                        <div className={classNames(styles.buttonPopupConfirm)}>
                            <BaseButton onClick={onPressVerify} title={t("common:action.confirm")} loading={loading} />
                        </div>
                    </div>
                </div>
            </div>
        </BasePopup>
    )
}

export default withTranslation(["settings", "common"])(PopupVerifyAttendance)
