import {useCallback} from "react"
import {useQuery, useQueryClient} from "@tanstack/react-query"
import {studentAccountService} from "services"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {LedgerAccount} from "types/student-account/ledger-accounts"

const queryCacheKey = "all-ledger-accounts"
const INITIAL_DATA: LedgerAccount[] = []

export const useAllLedgerAccounts = () => {
    const queryClient = useQueryClient()

    const query = useQuery(
        [queryCacheKey],
        async () => {
            const {data} = await studentAccountService.getLedgerAccounts({
                filter: {},
                range: {
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE
                }
            })
            return data.map((item) => ({...item, id: item.accountId}))
        },
        {
            refetchOnMount: false
        }
    )
    return {
        ledgerAccounts: query.data ?? INITIAL_DATA,
        invalidateLedgerAccountsCache: useCallback(() => {
            queryClient.invalidateQueries({queryKey: [queryCacheKey]})
        }, [queryClient])
    }
}
