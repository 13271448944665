import React, {useCallback, useMemo, useRef, useState} from "react"
import {Button, Col, Row} from "antd"
import {Icon} from "components/Icon"
import styles from "../StudentsTab.module.css"
import {BaseNewFilter} from "uiKit"
import {KlassDropdown} from "components/Select"
import {ColumnPicker} from "./ColumnPicker"
import {useTranslation} from "react-i18next"
import {ENROLLMENT_STATUS_OPTIONS, ENROLLMENT_TYPE_OPTIONS} from "types/students"
import {Column} from "./StudentsTable/types"
import {useClickAway} from "react-use"
import {getFieldLabel} from "helpers"
import StudentStatusSelect from "components/StudentStatusSelect"
import {Settings} from "types/settings"
import {useModel} from "hooks"
import {NewStudentStatusSelect} from "components/NewStudentStatusSelect"
import CampusSelect from "components/CampusSelect"
import ProgramSelect from "components/ProgramSelect"
import {BaseRangePicker} from "components/DateTimePicker"
import {Auth} from "types/auth"
import moment from "moment/moment"

interface Props {
    columns: Column[]
    visibleColumns: string[]
    onChangeVisibleColumns: (fields: string[]) => void
    search?: string
    setSearch?: React.Dispatch<React.SetStateAction<string>>
    initialFilter?: Record<string, any>
    applyFilter?: (filter: any) => void
}

const INITIAL_FILTER = {
    campuses: [],
    programs: [],
    studentStatuses: [],
    statuses: [],
    states: [],
    enrollmentType: null,
    enrollmentStatus: null,
    tranxDates: undefined
}

const StudentFilter = (props: Props) => {
    const model = useModel()
    const {t} = useTranslation(["user", "common"])
    const colsPickerRef = useRef(null)
    const [isShowChooseCols, setIsShowChooseCols] = useState(false)
    const {columns, visibleColumns, onChangeVisibleColumns, search = "", setSearch, applyFilter, initialFilter} = props
    const [filter, setFilter] = useState(Object.assign({}, INITIAL_FILTER, initialFilter))

    useClickAway(colsPickerRef, () => {
        setIsShowChooseCols(false)
    })

    const onOpenColumnPicker = () => {
        setIsShowChooseCols(true)
    }

    const onChangeFilter = (key: keyof typeof filter, value: any) => {
        setFilter((prev) => ({...prev, [key]: value}))
    }

    const onClearFilter = () => {
        setFilter(INITIAL_FILTER)
    }

    const onApplyFilter = useCallback(() => {
        applyFilter?.(filter)
    }, [applyFilter, filter])

    const onSearchInput = (search = "") => {
        setSearch(search)
    }

    // endregion

    const profileStateOptions = useMemo(
        () =>
            Object.values(Auth.UserProfileState).map((state) => ({
                id: state,
                name: t(`user.studentStateType.${state}`)
            })),
        [t]
    )

    return (
        <BaseNewFilter
            searchValue={search}
            filter={filter}
            onClick={onApplyFilter}
            onClickClear={onClearFilter}
            onSearchInput={onSearchInput}
            renderRightFilter={() => (
                <div className={styles.rightBtnWrap}>
                    <Button icon={<Icon icon="COLUMNS" />} className={styles.columnBtn} onClick={onOpenColumnPicker} />
                    {/*<Button icon={<Icon icon="EXPAND" />} className={styles.expandBtn} onClick={onShowFullScreen} />*/}
                    {isShowChooseCols && (
                        <div ref={colsPickerRef} className={styles.colPickerWrap}>
                            <ColumnPicker
                                columns={columns}
                                visibleColumns={visibleColumns}
                                onChange={onChangeVisibleColumns}
                            />
                        </div>
                    )}
                </div>
            )}>
            <Row gutter={[24, 24]}>
                <Col span={12}>
                    <ProgramSelect
                        isClearable={true}
                        isMulti={true}
                        isActive={true}
                        placeholder={t("user.programs")}
                        value={filter.programs}
                        onChange={(newValue) => onChangeFilter("programs", newValue || [])}
                    />
                </Col>
                <Col span={12}>
                    <CampusSelect
                        isClearable={true}
                        isMulti={true}
                        placeholder={t("user.campuses")}
                        value={filter.campuses}
                        onChange={(newValue) => onChangeFilter("campuses", newValue || [])}
                    />
                </Col>
                <Col span={12}>
                    <KlassDropdown
                        options={ENROLLMENT_TYPE_OPTIONS}
                        value={filter.enrollmentType}
                        placeholder={t("user.enrollmentType")}
                        onChange={(newValue) => onChangeFilter("enrollmentType", newValue)}
                        isClearable
                    />
                </Col>
                <Col span={12}>
                    <KlassDropdown
                        options={ENROLLMENT_STATUS_OPTIONS}
                        value={filter.enrollmentStatus}
                        onChange={(newValue) => onChangeFilter("enrollmentStatus", newValue)}
                        placeholder={getFieldLabel(
                            model,
                            Settings.GeneralLabel.EnrollmentStatus,
                            t("financialAid:financialAidPackage.enrollmentStatus")
                        )}
                        isClearable
                    />
                </Col>
                {model.clientSetting.isNewStudentStatusesVisible ? (
                    <Col span={12}>
                        <NewStudentStatusSelect
                            isMulti
                            placeholder={t(`common:studentInfo.newStudentStatus`)}
                            onChange={(newValue) => onChangeFilter("studentStatuses", newValue || [])}
                            value={filter?.studentStatuses}
                        />
                    </Col>
                ) : (
                    <Col span={12}>
                        <StudentStatusSelect
                            isMulti
                            placeholder={t(`common:statsFilter.studentStatus`)}
                            valueKey="statusId"
                            labelKey="name"
                            value={filter.statuses}
                            onChange={(newValue) => onChangeFilter("statuses", newValue || [])}
                        />
                    </Col>
                )}
                <Col span={12}>
                    <KlassDropdown
                        options={profileStateOptions}
                        value={profileStateOptions.filter((option) => filter.states?.includes(option.id))}
                        onChange={(options) =>
                            onChangeFilter(
                                "states",
                                (options ?? []).map((option) => option.id)
                            )
                        }
                        placeholder={t(`common:statsFilter.profileState`)}
                        isClearable
                        isMulti
                    />
                </Col>
                <Col span={12}>
                    <BaseRangePicker
                        placeholder={["Transaction Start Date", "End Date"]}
                        value={filter.tranxDates ? [moment(filter.tranxDates[0]), moment(filter.tranxDates[1])] : null}
                        onChange={(value) => onChangeFilter("tranxDates", value)}
                    />
                </Col>
            </Row>
        </BaseNewFilter>
    )
}

export default StudentFilter
