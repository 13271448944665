/* eslint-disable react-hooks/exhaustive-deps */
import debounce from "debounce-promise"
import groupBy from "lodash/groupBy"
import React from "react"
import {useTranslation} from "react-i18next"
import {departmentSubunitService} from "services"
import {DepartmentSubunit, getSubunitName} from "types/departments"
import {KlassDropdownAsync, KlassDropdownAsyncProps} from "../Select"

type Props = Partial<KlassDropdownAsyncProps> & {
    departmentIds?: number[]
    subunitIds?: number[]
    setFirstAsPreSelected?: boolean
    shortly?: boolean
}

const DepartmentSubunitSelect: React.FC<Props> = ({
    departmentIds,
    subunitIds,
    setFirstAsPreSelected = false,
    shortly,
    ...props
}) => {
    const {t} = useTranslation(["common"])

    const searchSubunits = React.useCallback(
        debounce(async (search: string = "") => {
            const {data: subunits} = await departmentSubunitService.getSubunits({
                range: {pageSize: 50, page: 1},
                filter: {search, departmentIds, subunitIds},
                linkedEntities: true
            })
            const subunitsFiltered = subunits.filter((subunit) => subunit.department)

            if (setFirstAsPreSelected && !props.value && subunits?.length) {
                props.onChange(subunits[0])
            }

            return Object.entries(groupBy(subunitsFiltered, "departmentId")).map(([departmentId, subunits]) => ({
                label: subunits[0]?.department?.name,
                options: shortly ? subunits.map(({subunitId, name, code}) => ({subunitId, name, code})) : subunits
            }))
        }, 500),
        [departmentIds, subunitIds]
    )

    return (
        <KlassDropdownAsync
            {...props}
            placeholder={props.placeholder || t("selectField.placeholder")}
            loadOptions={searchSubunits}
            defaultOptions={true}
            valueKey="subunitId"
            getOptionLabel={(option: DepartmentSubunit) => getSubunitName(option)}
        />
    )
}

export default DepartmentSubunitSelect
