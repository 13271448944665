import React from "react"
import {withTranslation} from "react-i18next"
import {Model} from "Model"
import {Icon} from "components/Icon"
import {BaseButton} from "components/buttons"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableProps} from "types/common"
import {settingChecklistService, degreeLevelService, campusesService, majorService} from "services"
import {KlassappTableHOC} from "HOC"
import {BaseDepartmentId} from "types/departments"
import {PermissionsRequired} from "components/PermissionsRequired"
import {getPermissionsByDepartment} from "sections/Settings/helper"
import {handleError, checkPermission} from "helpers"
import {PermissionUserType} from "types/permission"
import styles from "./CheckList.module.css"

interface PageProps {
    t: Function
    model: Model
    departmentId: BaseDepartmentId
    history?: any
}

type Props = KlassappTableProps & PageProps

interface State {
    degreeLevels: any[]
    campuses: any[]
    programs: any[]
    permissions: PermissionUserType
}

class CheckList extends React.Component<Props, State> {
    state = {
        degreeLevels: [],
        campuses: [],
        programs: [],
        permissions: {staff: []}
    }

    async componentDidMount() {
        this.getPermissions()
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getListChecklist},
            {key: "getFields", func: this.getFields},
            {key: "getColumns", func: this.getColumns},
            {key: "getTableHeaderActions", func: this.getTableHeaderActions},
            {key: "getMenuActions", func: this.getMenuActions},
            {key: "onClickDeleteMulti", func: this.onClickDeleteMulti},
            {key: "onClickDuplicateMulti", func: this.onClickDuplicateMulti},
            {key: "onClickEdit", func: this.onClickEdit},
            {key: "onClickDelete", func: this.onClickDelete}
        ])
        this.props.dispatch({isLoading: true})
        this.getListChecklist()
    }

    getPermissions = () => {
        const permissions = getPermissionsByDepartment(this.props.departmentId)
        this.setState({permissions})
    }

    getPageTitle = () => {
        return this.props.t("settings.checklist.checklist")
    }

    getFields = () => {
        const {t} = this.props
        return [
            t("settings.checklist.code"),
            t("settings.checklist.title"),
            // t("settings.checklist.degreeLevel"),
            t("settings.checklist.campus")
            // t("settings.checklist.program")
        ]
    }

    getTableHeaderActions = (isShowDuplicateBtn = true, checkedData = []) => {
        const canWrite = checkPermission(this.state.permissions, this.props.model)
        if (!canWrite) {
            return []
        }
        const {t, onClickShowConfirmModal} = this.props
        const actions = [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            }
        ]
        if (isShowDuplicateBtn) {
            actions.push({
                title: t("common:action.duplicate"),
                icon: "DUPLICATE",
                action: () => this.onClickDuplicateMulti()
            })
        }
        return actions
    }

    canShowDeleteIcon = () => {
        return checkPermission(this.state.permissions, this.props.model)
    }

    canShowEditIcon = () => {
        return checkPermission(this.state.permissions, this.props.model)
    }

    getMenuActions = () => {
        const {t} = this.props
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: this.onClickEdit,
                canShow: this.canShowEditIcon
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: this.onClickDelete,
                canShow: this.canShowDeleteIcon
            }
        ]
    }

    getListChecklist = async () => {
        this.props.dispatch({isLoading: true})
        const params = this.getParams()
        try {
            const {data, total} = await settingChecklistService.getAllChecklists(params)
            const newData = data.map((item) => {
                item.id = item.checklistId
                item.degreeLevelsHtml = item.degreeLevels.map((degreeLevel) => degreeLevel.name).join(", ")
                item.campusesHtml = item.campuses.map((campus) => campus.name).join(", ")
                item.programsHtml = item.programs.map((program) => program.name).join(", ")
                return item
            })
            this.props.dispatch({data: newData, total})
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    getParams = () => {
        const {page, pageSize, departmentId, orderField} = this.props
        const {order} = orderField
        const params: any = {
            sort: {
                orderBy: orderField.field ?? "createdAt",
                orderDir: (order && order.toUpperCase()) || "DESC"
            },
            range: {
                page,
                pageSize
            },
            filter: {
                departmentIds: [departmentId]
            },
            linkedEntities: ["degreeLevels", "campuses", "programs"]
        }
        return params
    }

    getDegreeLevels = async (params = {}) => {
        try {
            const {data: degreeLevels} = await degreeLevelService.getAll(params)
            this.setState({degreeLevels})
            return degreeLevels
        } catch (e) {
            handleError(e)
            return []
        }
    }

    getCampuses = async (params = {}) => {
        try {
            const {data: campuses} = await campusesService.getAll(params)
            this.setState({campuses})
        } catch (e) {
            handleError(e)
        }
    }

    getPrograms = async (params = {}) => {
        try {
            const {data: programs} = await majorService.getAll(params)
            this.setState({programs})
        } catch (e) {
            handleError(e)
        }
    }

    getColumns = () => {
        const {t} = this.props
        return [
            {
                title: t("settings.checklist.code"),
                field: "code",
                style: {minWidth: "150px"}
            },
            {
                title: t("settings.checklist.title"),
                field: "title",
                style: {minWidth: "150px"},
                sortable: true
            },
            /* {
                title: t("settings.checklist.degreeLevel"),
                field: "degreeLevelsHtml",
                style: {minWidth: "200px"}
            }, */
            {
                title: t("settings.checklist.campus"),
                field: "campusesHtml",
                style: {minWidth: "200px"}
            } /* ,
            {
                title: t("settings.checklist.program"),
                field: "programsHtml",
                style: {minWidth: "200px"}
            } */
        ]
    }

    onClickDeleteMulti = async () => {
        const checkedItems = this.props.data.filter((item) => item.isChecked)
        const payload = checkedItems.map((item) => item.id)
        try {
            this.props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await settingChecklistService.deleteChecklist(payload)
            await this.getListChecklist()
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onClickDuplicateMulti = async () => {
        const {departmentId} = this.props
        this.props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
        const checklistSelected = this.props.data.filter((item) => item.isChecked)
        const checklistSelectedIds = checklistSelected.map((checklist) => checklist.id)
        const {data: checklistItemsSelected} = await settingChecklistService.getAllChecklistItems({
            filter: {checklistIds: checklistSelectedIds}
        })
        const payload = checklistSelected.map(
            ({
                checklistId,
                title,
                code,
                description,
                campuses,
                degreeLevels,
                programs,
                enrollmentCitizenshipStatus,
                enrollmentType,
                payingMethods,
                dependencyStatus
            }) => {
                const checklistItems = checklistItemsSelected
                    .filter((checklistItem) => checklistItem.checklistId === checklistId)
                    .map(
                        ({
                            checklistId,
                            workflowId,
                            name,
                            checklistItemNameId,
                            isVisibleForStudent,
                            isDocumentUploadRequired,
                            position
                        }) => {
                            return {
                                checklistId,
                                workflowId: !!workflowId ? workflowId : null,
                                name,
                                checklistItemNameId,
                                isVisibleForStudent: !!isVisibleForStudent,
                                isDocumentUploadRequired: !!isDocumentUploadRequired,
                                position
                            }
                        }
                    )
                return {
                    title,
                    description,
                    campuses: campuses.map((campus) => campus.campusId),
                    degreeLevels: degreeLevels.map((degreeLevel) => degreeLevel.degreeLevelId),
                    programs: programs.map((program) => program.programId),
                    code: `${code} (Copy)`,
                    enrollmentCitizenshipStatus,
                    enrollmentType,
                    payingMethods,
                    dependencyStatus,
                    departmentId,
                    checklistItems
                }
            }
        )
        try {
            await settingChecklistService.duplicateChecklist(payload)
            await this.getListChecklist()
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onClickRowItem = (data) => {
        const {history} = this.props
        const routeName = this.getRouteNameByDepartmentId()
        history.push(`/settings/${routeName}/checklist/detail?id=${data.id}`)
    }

    getRouteNameByDepartmentId = () => {
        const {departmentId} = this.props
        let route = ""
        switch (departmentId) {
            case BaseDepartmentId.Admissions:
                route = "admissions"
                break
            case BaseDepartmentId.FinancialAid:
                route = "financial-aid"
                break
            case BaseDepartmentId.Academics:
                route = "academics"
                break
            case BaseDepartmentId.StudentServices:
                route = "student-services"
                break
            case BaseDepartmentId.StudentAccount:
                route = "student-account"
                break
        }
        return route
    }

    onClickEdit = (editItem) => {
        const {history} = this.props
        const routeName = this.getRouteNameByDepartmentId()
        history.push(`/settings/${routeName}/checklist/detail?id=${editItem.id}`)
    }

    onClickDelete = async (deletedItem) => {
        try {
            this.props.dispatch({isLoading: true})
            await settingChecklistService.deleteChecklist([deletedItem.id])
            await this.getListChecklist()
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onClickAdd = () => {
        const {history} = this.props
        const routeName = this.getRouteNameByDepartmentId()
        history.push(`/settings/${routeName}/checklist/detail`)
    }

    render() {
        const {
            page,
            total,
            pageSize,
            columns,
            data,
            menuActions,
            allFields,
            fields,
            tableHeaderActions,
            isLoading,
            isHideMenuActions,
            isShowTableHeaderAction,
            t,
            orderField
        } = this.props

        return (
            <div className={styles.wrapper}>
                <PermissionsRequired permissions={this.state.permissions}>
                    <div className={styles.createBtnWrap}>
                        <BaseButton
                            title={t("common:action.add").toUpperCase()}
                            onClick={this.onClickAdd}
                            icon={<Icon className={styles.plusIcon} icon="PLUS" color="#FFF" />}
                            disabled={false}
                        />
                    </div>
                </PermissionsRequired>
                <KlassappTableHeader
                    isShowAction={isShowTableHeaderAction}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.props.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    data={data}
                    menuActions={isHideMenuActions ? [] : menuActions}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    isShowCheckedColumn
                    orderField={orderField}
                    onClickRowItem={this.onClickRowItem}
                    onChangeFields={this.props.onChangeFields}
                    onUpdateRowData={this.props.onUpdateRowData}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onClickSortColumn={this.props.onClickSortColumn}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onChangeAllFields={this.props.onChangeAllFields}
                />
            </div>
        )
    }
}

export default KlassappTableHOC(withTranslation(["settings", "common"])(CheckList))
