import React from "react"
import {Col, Divider, Row, Spin} from "antd"
import {useTranslation} from "react-i18next"
import {sumBy} from "lodash"
import moment, {Moment} from "moment"
import {BasePopup} from "components/popup"
import {BaseButton} from "components/buttons"
import {FormLabel} from "components/Form"
import {BaseDatePicker} from "components/DateTimePicker"
import {useModel} from "hooks"
import {formatCurrency} from "helpers"
import {ConfirmPopup} from "uiKit"
import {Disbursement} from "types/fin-aid/disbursement"
import styles from "./DrawAndPost.module.css"
import Disbursements from "../../Disbursements"

type DrawAndPostProps = {
    visible: boolean
    onClose: () => void
}

const DrawAndPost: React.FC<DrawAndPostProps> = ({visible, onClose}) => {
    const {t} = useTranslation(["financialAid", "common"])
    const model = useModel()
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const userDateFormat = model.getUserDateFormat()

    const [loading] = React.useState(false)
    const [confirmVisible, setConfirmVisible] = React.useState(false)
    const [disbursementDate, setDisbursementDate] = React.useState<Moment>()
    const [selectedRequests, setSelectedRequests] = React.useState<Disbursement[]>([])

    const handleDrawAndPost = React.useCallback(() => setConfirmVisible(true), [])

    const handleCloseConfirm = React.useCallback(() => setConfirmVisible(false), [])

    const handleConfirm = React.useCallback(() => {
        setConfirmVisible(false)
    }, [])

    return (
        <BasePopup isShow={visible} onClose={onClose} leftIcon="SEND" leftIconColor="#fff" width="70vw">
            <div className={styles.wrap}>
                <p className={styles.title}>{t("finAidAward.drawAndPost.title")}</p>

                <Divider className={styles.divider} />

                <Row gutter={80}>
                    <Col span={8} className={styles.filterBlock}>
                        <FormLabel label={t("finAidAward.drawAndPost.disbursementDate")} />
                        <BaseDatePicker
                            format={userDateTimeFormat}
                            value={disbursementDate}
                            onChange={(value: Moment, text: string) => setDisbursementDate(value)}
                        />
                    </Col>

                    <Col span={8} className={styles.totalBlock}>
                        <FormLabel label={t("finAidAward.drawAndPost.total")} />
                        <div className={styles.totalValue}>
                            {formatCurrency(sumBy(selectedRequests, "grossAmount"))}
                        </div>
                    </Col>
                </Row>

                <div className={styles.body}>
                    <Disbursements drawAndPost={true} onItemsChecked={setSelectedRequests} />
                </div>

                <div className={styles.actionBtnWrap}>
                    {loading ? (
                        <Spin />
                    ) : (
                        <>
                            <BaseButton
                                title="Cancel"
                                variant="secondary"
                                className={styles.cancelBtn}
                                onClick={onClose}
                            />
                            <BaseButton
                                title={t("finAidAward.drawAndPost.title")}
                                onClick={handleDrawAndPost}
                                disabled={!selectedRequests.length}
                            />
                        </>
                    )}
                </div>
            </div>

            <ConfirmPopup
                isVisible={confirmVisible}
                title={t("finAidAward.disbursement.drawAndPost")}
                onClose={handleCloseConfirm}
                onConfirm={handleConfirm}
                description={t("finAidAward.disbursement.drawAndPostConfirm", {
                    total: selectedRequests.length,
                    amount: formatCurrency(sumBy(selectedRequests, "grossAmount")),
                    date: moment(disbursementDate).format(userDateFormat)
                })}
                icon="SEND"
            />
        </BasePopup>
    )
}

export default DrawAndPost
