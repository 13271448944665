import {get} from "lodash"
import BaseService from "./Base"
import {Settings} from "types/settings"

class SettingAcademicTrackService extends BaseService {
    async getAllAcademicTracks(params = {}): Promise<{data: Settings.AcademicTrack[]; total: number}> {
        const response = await this.post("/v1/settings/academic-tracks/list", params)
        return get(response, "data", [])
    }

    async createAcademicTrack(academicTrack) {
        const response = await this.post("/v1/settings/academic-tracks/create", [academicTrack])
        return get(response, "data.data", {})
    }

    async duplicateAcademicTrack(academicTracks) {
        const response = await this.post(`/v1/settings/academic-tracks/create`, academicTracks)
        return get(response, "data", {})
    }

    async updateAcademicTrack(id, data, params = {}) {
        data.trackId = id
        const response = await this.post(`/v1/settings/academic-tracks/edit`, data, params)
        return get(response, "data", {})
    }

    async deleteAcademicTrack(trackIds) {
        const response = await this.post(`/v1/settings/academic-tracks/delete`, {trackIds})
        return get(response, "data", {})
    }
}

export default SettingAcademicTrackService
