import {Tabs} from "antd"
import {useActiveTab} from "hooks"
import {TabHeader} from "components/Tab"
import {SapAlert} from "sections/academics/sap/SapAlert"
import {SapAction} from "sections/academics/sap/SapAction"

const {TabPane} = Tabs

const SAP_TABS = {
    ALERTS: "alerts",
    ACTIONS: "actions"
}

type Props = {
    studentId: number
    departmentId: number
}

export function SAP(props: Props) {
    const {studentId, departmentId} = props
    const [activeTab, onChangeTab] = useActiveTab(SAP_TABS.ALERTS, "saptype")

    const onSwitchTabPane = (activeKey?: string) => {
        onChangeTab(activeKey)
    }

    return (
        <Tabs className="fullwidth klassSubTab" activeKey={activeTab} onChange={onSwitchTabPane}>
            <TabPane key={SAP_TABS.ALERTS} tab={<TabHeader title="Alerts" />}>
                {activeTab === SAP_TABS.ALERTS && <SapAlert studentId={studentId} departmentId={departmentId} />}
            </TabPane>
            <TabPane key={SAP_TABS.ACTIONS} tab={<TabHeader title="Actions" />}>
                {activeTab === SAP_TABS.ACTIONS && <SapAction studentId={studentId} departmentId={departmentId} />}
            </TabPane>
        </Tabs>
    )
}
