export function getColorContrast(colorCode) {
    if (colorCode === "transparent") {
        return {
            background: "transparent",
            color: "#666666"
        }
    }
    if (colorCode.indexOf("#") === 0) {
        colorCode = colorCode.slice(1)
        if (colorCode.toUpperCase() === "FFF") colorCode = "ffffff"
        const red = parseInt(colorCode.substr(0, 2), 16)
        const green = parseInt(colorCode.substr(2, 2), 16)
        const blue = parseInt(colorCode.substr(4, 2), 16)
        const contrast = (red * 299 + green * 587 + blue * 114) / 1000
        const color = contrast >= 128 ? "#212121" : "white"
        return {
            background: `#${colorCode}`,
            color
        }
    }
    return {
        background: colorCode,
        color: parseInt(colorCode.replace("#", ""), 16) > 0xffffff / 2 ? "#212121" : "white"
    }
}

export function addAlpha(color: string, opacity: number): string {
    const alpha = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
    return color + alpha.toString(16).toUpperCase()
}
