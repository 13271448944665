import {get} from "lodash"
import BaseService from "./Base"

class TermsMajors extends BaseService {
    async getAll(params = {}) {
        const response = await this.post("/v2/terms/majors/list", params)
        return get(response, "data", [])
    }

    async updateTermMajor(params = {}) {
        const response = await this.post("/v2/terms/majors/update", params)
        return get(response, "data", [])
    }

    async exportAsCsv(data) {
        const response = await this.post(`/v2/terms/majors/export-as-csv`, data, {
            responseType: "blob"
        })
        return get(response, "data", null)
    }
}

export default TermsMajors
