import {get} from "lodash"
import {SlugHelper} from "SlugHelper"
import {Auth} from "types/auth"
import BaseService from "./Base"

class AuthService extends BaseService {
    constructor() {
        super(process.env.REACT_APP_EDULAR_AUTH_API_BASE_URL)
    }

    async getOtp(data, params = {}) {
        const response = await this.post("/auth/v2/get-one-time-password", data, params)
        return get(response, "data.data", {})
    }

    async otpSignIn(data, params = {}) {
        const response = await this.post("/auth/v2/sign-in", data, params)
        return get(response, "data.data", {})
    }

    async signIn(data: Auth.SignInParams): Promise<Auth.SignInResponse> {
        try {
            const response = await this.post("/auth/v2/normal-sign-in", data)
            return get(response, "data.data", {})
        } catch (e: any) {
            throw e.response.data
        }
    }

    async ssoSignIn(data: Auth.SsoParams): Promise<Auth.SignInResponse> {
        try {
            const response = await this.post("/auth/v2/sso-sign-in", data)
            return get(response, "data.data", {})
        } catch (e: any) {
            throw e.response.data
        }
    }

    async getTokenFromRedirect(token): Promise<any> {
        try {
            const response = await this.post("/auth/v2/get-token-from-redirect", token)
            return get(response, "data.data", {})
        } catch (e: any) {
            throw e.response.data
        }
    }

    async getMe(): Promise<any> {
        try {
            const response = await this.get("/auth/v2/get-me")
            return get(response, "data.data", {})
        } catch (e: any) {
            throw e.response.data
        }
    }

    async getAuthClients(): Promise<any> {
        try {
            const response = await this.get(`/auth/v2/get-auth-clients?slug=${SlugHelper.generate()}`)
            return get(response, "data.data", {})
        } catch (e: any) {
            throw e.response.data
        }
    }

    async send2FACode(data: {challengeToken: string; provider: string}): Promise<void> {
        try {
            await this.post("/auth/send-code-for-challenge", data)
        } catch (e: any) {
            throw e.response.data
        }
    }

    async complete2FAChallenge(data: {
        challengeToken: string
        code: string
        deviceId: string
    }): Promise<Auth.SignInNormal> {
        try {
            const response = await this.post("/auth/complete-challenge", data)
            return get(response, "data.data", {})
        } catch (e: any) {
            throw e.response.data
        }
    }
}

export default AuthService
