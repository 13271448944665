import {get} from "lodash"
import BaseService from "./Base"

export default class FundSourcesService extends BaseService {
    async getFundSources(params = {}) {
        const response = await this.post("/v1/settings/fund-sources/list", params)
        return get(response, "data", [])
    }

    async createFundSources(data) {
        const response = await this.post("/v1/settings/fund-sources/create", data)
        return get(response, "data.data", [])
    }

    async updateFundSource(id, data) {
        data.fundSourceId = id
        const response = await this.post("/v1/settings/fund-sources/edit", data)
        return get(response, "data.data", [])
    }

    async deleteFundSources(body = {}, params = {}) {
        const response = await this.post("/v1/settings/fund-sources/delete", body, params)
        return get(response, "data.data", [])
    }
}
