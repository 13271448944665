import {get} from "lodash"
import BaseService from "./Base"
import {StudentBilling} from "types/student-account/student-billing"
import {DeepPartial} from "types/common"

export default class StudentBillingsService extends BaseService {
    getBillings = async (params = {}): Promise<{total: number; data: StudentBilling[]}> => {
        const response = await this.post("/v1/student-account/student-billings/list", params)
        return get(response, "data", {})
    }

    getBilling = async (id: number): Promise<StudentBilling> => {
        const response = await this.get(`/v1/student-account/student-billings/${id}`)
        return get(response, "data.data", {})
    }

    createBilling = async (
        data: DeepPartial<StudentBilling> & {
            isSendInvoice?: boolean
        }
    ): Promise<StudentBilling> => {
        const response = await this.post("/v1/student-account/student-billings/create", data)
        return get(response, "data.data", {})
    }

    updateBilling = async (
        data: DeepPartial<StudentBilling> & {
            isSendInvoice?: boolean
        }
    ): Promise<StudentBilling> => {
        const response = await this.post("/v1/student-account/student-billings/edit", data)
        return get(response, "data.data", {})
    }

    deleteBillings = async (ids: number[]): Promise<void> => {
        await this.post("/v1/student-account/student-billings/delete", {ids})
    }
}
