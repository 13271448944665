import BaseService from "./Base"
import {get} from "lodash"

class UserPersonalContactsServiceV3 extends BaseService {
    getAll = async (params = {}) => {
        const response = await this.post("/v1/user-personal-contacts/list", params)
        return get(response, "data", [])
    }

    create = async (params = {}) => {
        const response = await this.post("/v1/user-personal-contacts/create", params)
        return get(response, "data.data", {})
    }

    update = async (id, data, params = {}) => {
        data.id = id
        const response = await this.post("/v1/user-personal-contacts/edit", data, params)
        return get(response, "data", {})
    }

    delete = async (ids) => {
        const response = await this.post("/v1/user-personal-contacts/delete", {ids})
        return get(response, "data", {})
    }

    getByUser = async (userId) => {
        const response = await this.post("/v1/user-personal-contacts/list", {
            filter: {
                userIds: [userId]
            },
            linkedEntities: true
        })
        return get(response, "data", {})
    }

    getRelatedToUser = async (params = {}) => {
        const response = await this.post("/v1/user-personal-contacts/related-to", params)
        return get(response, "data", [])
    }

    invite = async (params) => {
        const response = await this.post("/v1/user-personal-contacts/invite", params)
        return get(response, "data", {})
    }
}
export default UserPersonalContactsServiceV3
