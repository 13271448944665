import {get} from "lodash"
import {BaseService} from "services"

const basePath: string = "v1/notices"

export default class NoticeService extends BaseService {
    async getList(body = {}) {
        const response = await this.post(`${basePath}/list`, body)
        return get(response, "data", [])
    }

    async getStudentsList(body = {}) {
        const response = await this.post(`${basePath}/students/list`, body)
        return get(response, "data", [])
    }

    async edit(data) {
        const response = await this.post(`${basePath}/edit`, data)
        return get(response, "data", {})
    }
}
