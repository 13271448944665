import React from "react"
import {Link} from "react-router-dom"
import cx from "classnames"
import {isEmpty} from "lodash"
import {useHistory} from "react-router-dom"
import {useModel, useSearchParam} from "hooks"
import {Icon} from "components/Icon"
import styles from "./BackButton.module.css"

interface Props {
    url: string
    className?: string
    backData?: any
}

export function BackButton(props: Props) {
    const {url, className, backData} = props
    const history = useHistory()
    const model = useModel()
    const {value: backUrl} = useSearchParam("backUrl")

    const goBack = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const resultUrl = backUrl || url
        if (!isEmpty(backData) && resultUrl) {
            const searchIndex = resultUrl.indexOf("?")
            const newUrl = searchIndex > 0 ? resultUrl.slice(0, searchIndex) : resultUrl
            const search = searchIndex > 0 ? resultUrl.slice(searchIndex) : null
            history.push({pathname: newUrl, search, state: {backData}})
        } else if (backUrl || url) {
            history.push(backUrl || url)
        } else if (model.prevLocation && model.prevLocation.pathname !== history.location.pathname) {
            history.push(typeof model.prevLocation === "object" ? model.prevLocation.pathname : model.prevLocation)
        }
    }

    return (
        <Link
            className={cx(styles.arrowLink, className)}
            to={url}
            title="GO BACK"
            onClick={model.prevLocation ? goBack : undefined}>
            <Icon icon="BACK" color="#FF349B" className={styles.backIcon} />
        </Link>
    )
}
