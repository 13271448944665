import {get} from "lodash"
import BaseService from "./Base"

const basePath: string = `/v1/scrub-reports`

class ScrubReportsService extends BaseService {
    async list(params) {
        const response = await this.post(`${basePath}/list`, params)
        return get(response, "data", [])
    }

    async exportAsCsv(data) {
        const response = await this.post(`${basePath}/export-as-csv`, data, {
            responseType: "blob"
        })
        return get(response, "data", null)
    }
}

export default ScrubReportsService
