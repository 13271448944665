export const cCodes = [
    {
        id: 1,
        cCode: "CODE01",
        description: "Authorization to Research the NSLDS (if applicable)",
        task: "",
        approval: "",
        upload: "",
        approvalStatus: "pending",
        visibleStudent: false
    },
    {
        id: 2,
        cCode: "CODE02",
        description: "Authorization to Research the NSLDS (if applicable)",
        task: "",
        approval: "",
        upload: "",
        approvalStatus: "pending",
        visibleStudent: false
    },
    {
        id: 3,
        cCode: "CODE03",
        description: "Authorization to Research the NSLDS (if applicable)",
        task: "",
        approval: "",
        upload: "",
        approvalStatus: "pending",
        visibleStudent: false
    }
]
