import {get} from "lodash"
import BaseService from "./Base"
import {ChecklistItemLink, DocumentLink, StudentActivityLink, TaskLink} from "types/links"

class LinksService extends BaseService {
    async getDocumentLinks(userDocumentIds: number[], withDetails = true): Promise<DocumentLink[]> {
        const response = await this.post("/v1/links/list", {userDocumentIds, withDetails})
        return get(response, "data.data", [])
    }

    async getTaskLinks(taskIds: number[], withDetails = true): Promise<TaskLink[]> {
        const response = await this.post("/v1/links/list", {taskIds, withDetails})
        return get(response, "data.data", [])
    }

    async getStudentActivityLinks(studentActivityIds: number[], withDetails = true): Promise<StudentActivityLink[]> {
        const response = await this.post("/v1/links/list", {studentActivityIds, withDetails})
        return get(response, "data.data", [])
    }

    async getChecklistItemLinks(studentChecklistItemIds: number[], withDetails = true): Promise<ChecklistItemLink[]> {
        const response = await this.post("/v1/links/list", {studentChecklistItemIds, withDetails})
        return get(response, "data.data", [])
    }

    async saveDocumentLink(
        userDocumentId: number,
        links: {taskIds?: number[]; studentChecklistItemIds?: number[]; studentActivityIds?: number[]}
    ): Promise<DocumentLink[]> {
        const response = await this.post("/v1/links/save", {userDocumentId, ...links})
        return get(response, "data.data", [])
    }

    async saveTaskLink(
        taskId: number,
        links: {userDocumentIds?: number[]; studentChecklistItemIds?: number[]; studentActivityIds?: number[]}
    ): Promise<TaskLink[]> {
        const response = await this.post("/v1/links/save", {taskId, ...links})
        return get(response, "data.data", [])
    }

    async saveStudentActivityLink(
        studentActivityId: number,
        links: {userDocumentIds?: number[]; studentChecklistItemIds?: number[]; taskIds?: number[]}
    ): Promise<StudentActivityLink[]> {
        const response = await this.post("/v1/links/save", {studentActivityId, ...links})
        return get(response, "data.data", [])
    }

    async saveChecklistItemLink(
        studentChecklistItemId: number,
        links: {userDocumentIds?: number[]; studentActivityIds?: number[]; taskIds?: number[]}
    ): Promise<ChecklistItemLink[]> {
        const response = await this.post("/v1/links/save", {studentChecklistItemId, ...links})
        return get(response, "data.data", [])
    }
}

export default LinksService
