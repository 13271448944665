import BaseService from "./Base"
import {get} from "lodash"

class UserPersonalContactRolesServiceV3 extends BaseService {
    getAll = async (params = {}) => {
        const response = await this.post("/v1/user-personal-contact-roles/list", {
            ...params,
            sort: {
                orderBy: "name",
                orderDir: "asc"
            }
        })
        return get(response, "data", [])
    }
}
export default UserPersonalContactRolesServiceV3
