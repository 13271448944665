import {get} from "lodash"
import BaseService from "./Base"

const basePath: string = `/v1/npsas-report`

class NpsasReportsService extends BaseService {
    async list(params) {
        const response = await this.post(`${basePath}/list`, params)
        return get(response, "data", [])
    }
}

export default NpsasReportsService
