import {Timestamps} from "types/common"
import {Auth} from "types/auth"
import {TermDetails} from "types/terms"
import {RateSheetDetails} from "types/rate-sheets"

export enum StudentBillingStatus {
    Active = "active",
    Completed = "completed"
}

export const BILLING_STATUS_OPTIONS = [
    {value: StudentBillingStatus.Active, label: "Active"},
    {value: StudentBillingStatus.Completed, label: "Completed"}
]

export type StudentBilling = Timestamps & {
    billingId: number
    status: StudentBillingStatus
    profileId: number
    profile?: Auth.LinkedProfileDetails // populate
    awardYear: number
    termId: number
    term?: TermDetails // populate
    description?: string | null
    packaged: number
    registered: number
    registeredCourses: number
    rateSheetId?: number | null
    rateSheetName?: string | null
    rateSheet?: RateSheetDetails // populate
    completedAt?: string | null
    isLock: boolean | 0 | 1
    finAidOfferId?: number | null
    items: StudentBillingItem[]

    updatedByProfileId?: number
    updatedBy?: Auth.LinkedProfileDetails // populate
}

export enum StudentBillingItemType {
    RateSheet = "rate_sheet",
    GeneralPayment = "general_payment"
}

export type StudentBillingItem = {
    billingItemId: number
    billingId: number

    type: StudentBillingItemType
    itemId: number
    itemType?: string | null
    itemName: string
    description?: string | null
    rate: number
    qty: number
    isInstitutionalCharge: boolean | 0 | 1
}
