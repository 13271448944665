import {useMemo} from "react"
import {Doughnut} from "react-chartjs-2"

type Props = {
    completed: number
    totalHours: number
}

export function DoughnutChart(props: Props) {
    const {completed, totalHours} = props

    const left = useMemo(() => {
        return Math.max(0, Number((totalHours - completed).toFixed(2)))
    }, [totalHours, completed])

    const data = useMemo(() => {
        return {
            labels: ["completed", "left"],
            datasets: [
                {
                    label: "Graduation",
                    data: [completed, left],
                    backgroundColor: ["rgb(153, 45, 97)", "rgb(226, 226, 226)"]
                }
            ]
        }
    }, [left, completed])

    const plugins = useMemo(() => {
        return [
            {
                beforeDraw: function (chart) {
                    var width = chart.width,
                        height = chart.height,
                        ctx = chart.ctx
                    ctx.restore()

                    ctx.font = "bold 1.2em Poppins"
                    ctx.textBaseline = "top"
                    let text = `${left}h`,
                        textX = Math.round((width - ctx.measureText(text).width) / 2),
                        textY = height / 3
                    ctx.fillText(text, textX, textY)

                    ctx.font = "0.8em Poppins"
                    text = "left"
                    textX = Math.round((width - ctx.measureText(text).width) / 2)
                    textY = height / 3 + 20
                    ctx.fillText(text, textX, textY)

                    text = `${completed}h`
                    textX = Math.round((width - ctx.measureText(text).width) / 2)
                    textY = height / 2 + 10
                    ctx.fillText(text, textX, textY)

                    text = "completed"
                    textX = Math.round((width - ctx.measureText(text).width) / 2)
                    textY = height / 2 + 24
                    ctx.fillText(text, textX, textY)

                    ctx.save()
                }
            }
        ]
    }, [left, completed])

    const options = {
        tooltips: {enabled: true},
        legend: {display: false},
        plugins: {datalabels: {display: false}},
        cutoutPercentage: 90,
        maintainAspectRatio: false,
        width: "220px",
        height: "220px"
    }

    return (
        <div style={{width: 220, height: 220}}>
            <Doughnut data={data} options={options} plugins={plugins} />
        </div>
    )
}
