/* eslint-disable react-hooks/exhaustive-deps */
import {FilterList, FieldOperator, FilterKey} from "types/filter"
import {useCallback} from "react"
import {studentServicesService} from "services"
import {useTranslation} from "react-i18next"
import styles from "./SevActions.module.css"
import {BaseLoading, Icon} from "components"
import {useModel} from "hooks"
import {handleError} from "helpers"
import {useQuery} from "@tanstack/react-query"

type Props = {
    loadKey: string
    filters?: FilterList
    goToStudentTab: () => void
}

export function SevActions(props: Props) {
    const {loadKey, filters, goToStudentTab} = props
    const {t} = useTranslation(["studentServices"])
    const model = useModel()
    const filterKey = FilterKey.AcademicsPage_Analytics_Students

    const {data, isFetching} = useQuery({
        queryKey: ["student-sev", loadKey],
        enabled: !!loadKey,
        initialData: 0,
        queryFn: async () => {
            const data = await studentServicesService.getActionsNeededSEV({filters})
            return data.actionsNeededSEV
        },
        onError: (err) => {
            handleError(err)
        }
    })

    const handleSEVClick = useCallback(() => {
        let filters: any = {}
        filters.onlySEV = {
            operator: FieldOperator.Equal,
            value: true
        }

        model.updateStorageFilter(filterKey, {
            filters
        })
        goToStudentTab()
    }, [goToStudentTab])

    return (
        <div className={styles.cardWrap}>
            <div className={styles.titleWrap}>
                <Icon icon="NOTIFY" className={styles.iconSize} />
                <p className={styles.cardTitle}>{t("actionsNeededSEV")}</p>
            </div>
            <p className={styles.cardValue}>{data}</p>
            <div className={styles.buttonWrapper} onClick={handleSEVClick}>
                <span className={styles.buttonLabel}>{t("viewAll")}</span>
                <Icon icon="ARROW_RIGHT_CIRCLE" />
            </div>
            <BaseLoading isShow={isFetching} />
        </div>
    )
}
