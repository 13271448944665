/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {useModel} from "hooks"
import {getFullName, handleError} from "helpers"
import {KlassappTableHOC} from "HOC"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableProps} from "types/common"
import styles from "./DisbursementLogTable.module.css"
import {disbursementService} from "services"
import moment from "moment"
import {BaseButton} from "components"
import {Row, Upload} from "antd"
import {RcFile} from "antd/lib/upload/interface"

interface Props extends KlassappTableProps {}

const DisbursementResponsesTable: React.FC<Props> = ({
    dispatch,
    dispatchFunc,
    page,
    total,
    pageSize,
    columns,
    data,
    allFields,
    fields,
    tableHeaderActions,
    isLoading,
    menuActions,
    isHideMenuActions,
    isShowTableHeaderAction,
    onClickShowConfirmModal,
    getCurrentData,
    getCurrentPage,
    getCurrentPageSize,
    onChangePage,
    onChangeRowPerPage,
    onChangeFields,
    onDraggableColumn,
    onChangeAllFields,
    onUpdateRowData,
    onUpdateTableData
}) => {
    const {t} = useTranslation(["financialAid", "common"])
    const model = useModel()
    const userDateTimeFormat = model.getUserDateTimeFormat()

    // region filter

    const initialFilter = {}
    const [search, setSearch] = useState("")
    const [filter, setFilter] = useState(initialFilter)
    const [appliedFilter, setAppliedFilter] = useState(initialFilter)

    const onClearFilter = () => {
        setFilter(initialFilter)
        setAppliedFilter(initialFilter)
    }

    const onApplyFilter = () => {
        setAppliedFilter(filter)
    }

    const onSearchInput = (search = "") => {
        setSearch(search)
    }

    // endregion filter

    const getData = React.useCallback(async () => {
        dispatch({isLoading: true})
        try {
            const {data, total} = await disbursementService.responsesList({
                filter: {},
                range: {
                    page,
                    pageSize
                },
                sort: {
                    orderBy: "createdAt",
                    orderDir: "DESC"
                }
            })
            dispatch({data: data.map((item) => ({...item, id: item.userDocumentId})), total})
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }, [page, pageSize, search, appliedFilter])

    const handleRowClick = React.useCallback(() => {}, [])

    const getFields = () => {
        return [
            t("disbursement.responses.column.createdAt"),
            t("disbursement.responses.column.fileName"),
            t("disbursement.responses.column.uploader")
        ]
    }

    const getColumns = React.useCallback(
        () => [
            {
                title: t("disbursement.responses.column.createdAt"),
                field: "createdAt",
                render: (value) => (value ? moment(value).format(userDateTimeFormat) : undefined)
            },
            {
                title: t("disbursement.responses.column.fileName"),
                field: "name",
                render: (value, record) => (
                    <a href={record.documentUrl} target="_blank" rel="noopener noreferrer">
                        {value}
                    </a>
                )
            },
            {
                title: t("disbursement.responses.column.uploader"),
                field: "lastUpdatedByUser",
                render: (value) => getFullName(value)
            }
        ],
        [t, userDateTimeFormat]
    )

    const tableOptions = React.useMemo(
        () => [
            {key: "getPageTitle", func: () => ""},
            {key: "getListData", func: getData},
            {
                key: "getFields",
                func: getFields
            },
            {
                key: "getColumns",
                func: getColumns
            },
            {key: "onClickRowItem", func: handleRowClick}
        ],
        [t, getColumns, handleRowClick]
    )

    React.useEffect(() => {
        dispatch({isLoading: true})
        dispatchFunc(tableOptions)
    }, [tableOptions])

    React.useEffect(() => {
        getData()
    }, [search, appliedFilter, page, pageSize])

    const [isUploading, setUploading] = React.useState(false)

    const handleFilesChange = React.useCallback(
        async (file: RcFile, files: RcFile[]) => {
            if (!files?.length) return
            setUploading(true)
            try {
                await disbursementService.responsesUpload(files)
                getData()
            } catch (error) {
                handleError(error)
            } finally {
                setUploading(false)
            }
        },
        [getData]
    )

    return (
        <div>
            <BaseNewFilter
                filter={filter}
                onClick={onApplyFilter}
                onClickClear={onClearFilter}
                onSearchInput={onSearchInput}
                renderRightFilter={() => (
                    <div className="flex justify-end items-center">
                        <Upload multiple={true} beforeUpload={handleFilesChange} accept="application/xml,text/xml">
                            <BaseButton
                                title={t("disbursement.responses.upload")}
                                className={styles.addButton}
                                loading={isUploading}
                            />
                        </Upload>
                    </div>
                )}>
                <Row gutter={[24, 24]}></Row>
            </BaseNewFilter>

            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={onChangePage}
                onChangeRowPerPage={onChangeRowPerPage}
            />

            <KlassappTable
                columns={columns}
                data={data}
                isLoading={isLoading}
                menuActions={[]}
                fields={fields}
                allFields={allFields}
                isShowCheckedColumn={false}
                onClickRowItem={handleRowClick}
                onChangeFields={onChangeFields}
                onDraggableColumn={onDraggableColumn}
                onChangeAllFields={onChangeAllFields}
                onUpdateRowData={onUpdateRowData}
                onUpdateTableData={onUpdateTableData}
            />
        </div>
    )
}

export default KlassappTableHOC(DisbursementResponsesTable)
