import React from "react"
import Autocomplete from "react-google-autocomplete"
import styles from "./GooglePlacesAutocomplete.module.css"

type Props = {
    onSelectGoogleAddress: (places: google.maps.places.PlaceResult) => void
}

const GooglePlacesAutocomplete = (props: Props) => {
    const {onSelectGoogleAddress} = props

    return (
        <Autocomplete
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            onPlaceSelected={onSelectGoogleAddress}
            options={{
                types: ["address"]
            }}
            style={{width: "100%"}}
            placeholder="Enter an address"
            className={styles.autocomplete}
        />
    )
}

export default GooglePlacesAutocomplete
