/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react"
import styles from "./PinnedIcon.module.css"
import {useQuery} from "@tanstack/react-query"
import {chatServiceV2} from "services"
import {handleErrorChat} from "helpers"
import cx from "classnames"
import {Icon} from "components"
import ChatWsV2 from "sections/NewCommunication/chatWsV2"

type Props = {
    roomId: number | string
    onClick: () => void
}

export function PinnedIcon(props: Props) {
    const {roomId} = props
    const [pinnedTotal, setPinnedTotal] = useState<number>(0)

    useQuery(
        ["room-pinned-total", roomId],
        () =>
            chatServiceV2.getPinned({
                roomId: roomId
            }),
        {
            onSuccess: (resp) => {
                setPinnedTotal(resp.total || 0)
            },
            onError: (err) => {
                handleErrorChat(err)
            },
            enabled: !!roomId
        }
    )

    useEffect(() => {
        const unSubscribeRoomPinMessage = ChatWsV2.registerOnRoomPinMessage(roomId, (data) => {
            handlerRoomPinMessage(data)
        })

        return () => {
            unSubscribeRoomPinMessage()
        }
    }, [roomId])

    const handlerRoomPinMessage = (data: any) => {
        if (data.roomId === roomId) {
            setPinnedTotal((prev) => {
                const _pinnedTotal = prev + (data.isPinned ? 1 : -1)
                return _pinnedTotal > 0 ? _pinnedTotal : 0
            })
        }
    }

    return (
        <div
            className={cx(styles.iconWrap, {[styles.unClickable]: pinnedTotal <= 0})}
            onClick={() => {
                if (pinnedTotal > 0) {
                    props.onClick()
                }
            }}>
            <Icon
                icon="PUSH_PIN"
                className={styles.icon}
                color={pinnedTotal > 0 ? "var(--primary-400-base)" : "var(--black-100)"}
            />
        </div>
    )
}
