/* eslint-disable react-hooks/exhaustive-deps */
import React, {ChangeEvent, useEffect, useState} from "react"
import {RadioButton} from "components/inputs"
import styles from "./PollV2.module.css"
import {SecondaryButton} from "components/buttons"
import {handleErrorChat, toastInfo} from "helpers"
import {Icon} from "components/Icon"
import {Tooltip} from "antd"
import {formatDistanceToNow} from "date-fns"
import {useModel} from "hooks"
import {Communication} from "types/communication"
import ChatWsV2 from "sections/NewCommunication/chatWsV2"
import {LinkifyContent} from "components/ui"

type PollProps = {
    poll: Communication.Poll
    messageId: number
}

export function PollV2(props: PollProps) {
    const {poll, messageId} = props
    const [selectedOptions, setSelectedOptions] = useState<Array<number>>([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [leftTimePollText, setLeftTimePollText] = useState("")
    const [disabledPoll, setDisabledPoll] = useState(false)
    const model = useModel()

    const hasVoted = poll.hasVoted || poll.isClosed
    const isMultiple = poll.type === Communication.PollTypes.Multiple

    useEffect(() => {
        let intervalId = null
        if (!poll.isClosed && poll.closeAt && !disabledPoll) {
            handlerPollLeftTime()
            intervalId = setInterval(() => {
                handlerPollLeftTime()
            }, 1000 * 30)
        }
        return () => {
            clearInterval(intervalId)
        }
    }, [poll, model, disabledPoll])

    const handlerPollLeftTime = () => {
        let date = formatDistanceToNow(new Date(poll.closeAt), {
            locale: model.getDateFnsLocale()
        })
        if (Date.now() < +new Date(poll.closeAt)) {
            setLeftTimePollText(date)
        } else {
            setLeftTimePollText("")
            setDisabledPoll(true)
        }
    }

    const handleCheck = (e: ChangeEvent<HTMLInputElement>, value: number) => {
        if (isSubmitting || hasVoted) {
            e.preventDefault()
            e.stopPropagation()
            return
        }
        let newSelectedOptions = [...selectedOptions]
        if (selectedOptions.includes(value)) {
            newSelectedOptions = selectedOptions.filter((option) => option !== value)
        } else {
            newSelectedOptions.push(value)
        }
        setSelectedOptions(newSelectedOptions)
        if (!isMultiple && newSelectedOptions.length > 0) {
            sendVote(newSelectedOptions)
        }
    }

    const handleSend = () => {
        sendVote(selectedOptions)
    }

    const sendVote = async (options) => {
        if (poll.closeAt && Date.now() > +new Date(poll.closeAt)) {
            return
        }
        try {
            setIsSubmitting(true)
            ChatWsV2.sendPacket({
                command: Communication.SocketCommand.AddPollVote,
                payload: {
                    pollId: poll.pollId,
                    pollOptionIds: options,
                    messageId: messageId
                }
            })
            setSelectedOptions([])
        } catch (e) {
            handleErrorChat(e)
        } finally {
            setIsSubmitting(false)
        }
    }

    const getVotersCountTitle = () => {
        let text = "vote"
        if (poll.type === "quiz") {
            text = "answer"
        }
        if (poll.totalVoters === 0) {
            return `No ${text}s yet`
        }
        text = poll.totalVoters === 1 ? text : `${text}s`
        return `${poll.totalVoters} ${text}`
    }

    const getIcon = (option: Communication.PollOption) => {
        if (Communication.PollTypes.Quiz === poll.type && option.chosen && !option.correct) {
            return <Icon icon="CROSS_CIRCLE_FILL" color="var(--error-400-base)" />
        }
        if (Communication.PollTypes.Quiz === poll.type && option.chosen && option.correct) {
            return <Icon icon="CHECKMARK_CIRCLE" color="var(--success-400-base)" />
        }
        if (option.chosen || option.correct) {
            return <Icon icon="CHECKMARK_CIRCLE" />
        }
        return null
    }
    const getBackground = (option: Communication.PollOption) => {
        if (Communication.PollTypes.Quiz === poll.type && option.chosen && !option.correct) {
            return "var(--error-400-base)"
        }
        if (Communication.PollTypes.Quiz === poll.type && option.chosen && option.correct) {
            return "var(--success-400-base)"
        }
        return "var(--primary-400-base)"
    }

    const getTitle = () => {
        let text = "Poll"
        if (Communication.PollTypes.Quiz === poll?.type) {
            text = "Quiz"
        }
        let title = `Anonymous ${text}`
        if (poll.isClosed) {
            title = `Final results`
        }
        return title
    }

    const showExplanation = () => {
        if (poll.explanation && poll.type === Communication.PollTypes.Quiz) {
            toastInfo(poll.explanation)
        }
    }

    const getPercentage = (option) => {
        return poll.totalVoters > 0 ? Math.round((option.voters / poll.totalVoters) * 100) : 0
    }

    if (Object.keys(poll || {}).length === 0) {
        return <div> Poll not Found </div>
    }

    return (
        <div className={styles.root}>
            <div className={styles.titlePoll}>
                {getTitle()}
                {poll?.explanation && poll?.type === Communication.PollTypes.Quiz && (
                    <button className={styles.explanationIcon} onClick={showExplanation}>
                        <Icon icon="BULB" />
                    </button>
                )}
            </div>
            {!hasVoted && (
                <>
                    {poll.options.map((option) => (
                        <div key={`poll-option-${option.pollOptionId}`} className={styles.option}>
                            <div className={styles.radioContainer}>
                                <RadioButton
                                    name="poll"
                                    onClick={(e) => handleCheck(e, option.pollOptionId)}
                                    disabled={isSubmitting || disabledPoll}
                                />
                            </div>
                            <div className={styles.textOption}>
                                <LinkifyContent content={option.option} />
                            </div>
                        </div>
                    ))}
                    {isMultiple && !hasVoted && (
                        <div className={styles.submit}>
                            <SecondaryButton
                                title="VOTE"
                                disabled={isSubmitting || selectedOptions.length === 0 || disabledPoll}
                                onClick={handleSend}
                                loading={isSubmitting}
                                className={styles.voteButton}
                            />
                        </div>
                    )}
                </>
            )}
            {hasVoted && (
                <>
                    {poll.options.map((option) => {
                        return (
                            <Tooltip
                                title={option.voters}
                                key={`poll-voted-option-${option.pollOptionId}`}
                                className={styles.resultOption}>
                                <div className={styles.votersCount}>{`${getPercentage(option)}%`}</div>
                                <div className={styles.textOption}>
                                    <LinkifyContent content={option.option} />
                                </div>
                                <div className={styles.iconWrapper}>
                                    <span className={styles.icon}>{getIcon(option)}</span>
                                </div>
                                <div className={styles.progressContainer}>
                                    <div
                                        className={styles.progress}
                                        style={{
                                            width: `${getPercentage(option)}%`,
                                            background: getBackground(option)
                                        }}></div>
                                </div>
                            </Tooltip>
                        )
                    })}
                </>
            )}
            {poll.closeAt && !poll.isClosed && leftTimePollText && !disabledPoll && (
                <span className={styles.votersCount}>{leftTimePollText} to finish the poll</span>
            )}
            {disabledPoll && !poll.isClosed && <span className={styles.votersCount}>Poll finish wait a moment</span>}
            <span className={styles.votersCount}>{getVotersCountTitle()}</span>
        </div>
    )
}
