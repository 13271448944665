/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useReducer} from "react"
import cx from "classnames"
import {get, head} from "lodash"
import {Col, Row, Tabs} from "antd"
import {settingStageService, studentService} from "services"
import {handleError} from "helpers"
import {useActiveTab} from "hooks"
import {BaseButton} from "components/buttons"
import {CurrentProgress, PreviouslyCompletedCredits, TransferCredits} from "./parts"
import styles from "./DegreeAudit.module.css"
import {TabHeader} from "components/Tab"

const {TabPane} = Tabs

const DEGREE_AUDIT_TABS = {
    CURRENT_PROGRESS: "1",
    TRANSFER_CREDITS: "2",
    PREVIOUS: "3"
}

function reducer(state, action) {
    return {...state, ...action}
}

export function DegreeAudit(props) {
    const {studentId, departmentId} = props
    const initialState = {
        student: {},
        stageId: 0
    }
    const [, dispatch] = useReducer(reducer, initialState)
    const [activeTabKey, onChangeTab] = useActiveTab(DEGREE_AUDIT_TABS.CURRENT_PROGRESS, "progress")

    useEffect(() => {
        loadStudent(studentId)
    }, [])

    async function loadStudent(studentProfileId: number) {
        try {
            const params = {
                filter: {
                    departmentIds: [props.departmentId]
                }
            }
            const [
                {
                    data: [student]
                },
                {data: stages}
            ] = await Promise.all([
                studentService.getDepartmentStudents({filter: {profileIds: [studentProfileId], departmentId}}),
                settingStageService.getAllStages(params)
            ])
            const stageId = get(head(stages), "stageId")

            dispatch({student, stageId})
        } catch (error) {
            handleError(error)
        }
    }

    return (
        <div>
            <div className={styles.progressReportBtn}>
                <BaseButton title="Progress Report" variant="secondary" />
            </div>
            <Row gutter={[24, 24]} className={styles.rowInfo}>
                <Col span={6}>
                    <div className={styles.infoWrap}>
                        <p className={styles.infoTitle}>Applied to degree</p>
                        <p className={styles.infoValue}>56</p>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.infoWrap}>
                        <div className={styles.infoLeftWrap}>
                            <p className={cx(styles.infoTitle, styles.center)}>Attempted</p>
                            <p className={cx(styles.infoTitle, styles.center)}>Completed</p>
                        </div>
                        <div className={styles.infoRightWrap}>
                            <p className={cx(styles.infoValue, styles.center)}>43</p>
                            <p className={cx(styles.infoValue, styles.center)}>24</p>
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.infoWrap}>
                        <div className={styles.infoLeftWrap}>
                            <p className={cx(styles.infoTitle, styles.center)}>Transfer</p>
                            <p className={cx(styles.infoTitle, styles.center)}>Applied</p>
                        </div>
                        <div className={styles.infoRightWrap}>
                            <p className={cx(styles.infoValue, styles.center)}>43</p>
                            <p className={cx(styles.infoValue, styles.center)}>24</p>
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.infoWrap}>
                        <div className={styles.infoLeftWrap}>
                            <p className={cx(styles.infoTitle, styles.center)}>Previously completed</p>
                            <p className={cx(styles.infoTitle, styles.center)}>APPLIED</p>
                        </div>
                        <div className={styles.infoRightWrap}>
                            <p className={cx(styles.infoValue, styles.center)}>12</p>
                            <p className={cx(styles.infoValue, styles.center)}>3</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Tabs className="fullwidth" activeKey={activeTabKey} onChange={onChangeTab}>
                <TabPane tab={<TabHeader title="Current Progress" />} key={DEGREE_AUDIT_TABS.CURRENT_PROGRESS}>
                    {activeTabKey === DEGREE_AUDIT_TABS.CURRENT_PROGRESS && <CurrentProgress />}
                </TabPane>
                <TabPane tab={<TabHeader title="Transfer Credits" />} key={DEGREE_AUDIT_TABS.TRANSFER_CREDITS}>
                    {activeTabKey === DEGREE_AUDIT_TABS.TRANSFER_CREDITS && <TransferCredits />}
                </TabPane>
                <TabPane tab={<TabHeader title="Previously Completed Credits" />} key={DEGREE_AUDIT_TABS.PREVIOUS}>
                    {activeTabKey === DEGREE_AUDIT_TABS.PREVIOUS && <PreviouslyCompletedCredits />}
                </TabPane>
            </Tabs>
        </div>
    )
}
