/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {get} from "lodash"
import {authService} from "services"
import {BaseLoading} from "components"
import DefaultLogo from "assets/images/png/EdularLogoRectangle.png"
import {Branding} from "types/branding"
import styles from "./MagicLink.module.css"

type UrlParams = {
    email: string
    code: string
}

const MagicLink = ({model}) => {
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(true)
    const params = useParams<UrlParams>()

    useEffect(() => {
        ;(async () => {
            try {
                const payload = {
                    slug: model.slug,
                    email: params.email,
                    otp: params.code,
                    device: "web"
                }
                await authService.otpSignIn(payload)
                setSuccess(true)
            } catch (e) {
                setSuccess(false)
            }
            setLoading(false)
        })()
    }, [params])

    const getBrandingImg = () => {
        const rectangularImg = get(
            model,
            ["clientSetting", "branding", Branding.BrandingType.OfficialLogoRectangular],
            null
        )
        const squareImg = get(model, ["clientSetting", "branding", Branding.BrandingType.OfficialLogoSquare], null)
        return squareImg || rectangularImg || DefaultLogo
    }

    const logoUrl = getBrandingImg()

    return (
        <div className={styles.root}>
            <BaseLoading isShow={loading} />
            {!loading && (
                <div className={styles.verticalCenter}>
                    <img src={logoUrl} alt="logo" />
                    <br />
                    <br />
                    <label>{success ? "Logged in successfully" : "One Time Password not found or is expired"}</label>
                </div>
            )}
        </div>
    )
}

export default MagicLink
