import BaseService from "./Base"
import {get} from "lodash"
import {Auth} from "types/auth"

type Staff = {
    staffProfileId: number
    firstName: string
    middleName: string
    lastName: string
    photo: any
    studentStaffContactId: number
    studentStaffContactRoleId: number
    studentProfileId: number
    isDefault: number
}

class StudentStaffContactsServiceV3 extends BaseService {
    getAll = async (params = {}) => {
        const response = await this.post("/v1/student-staff-contacts/list", params)
        return get(response, "data", [])
    }

    create = async (params = {}) => {
        const response = await this.post("/v1/student-staff-contacts/create", params)
        return get(response, "data.data", {})
    }

    reAssignAdvisor = async (params = {}) => {
        const response = await this.post("/v1/student-staff-contacts/re-assign-advisor", params)
        return get(response, "data.data", {})
    }

    update = async (id, data, params = {}) => {
        data.studentStaffContactId = id
        const response = await this.post("/v1/student-staff-contacts/edit", data, params)
        return get(response, "data", {})
    }

    updateBulk = async (data, params = {}) => {
        const response = await this.post("/v1/student-staff-contacts/edit", data, params)
        return get(response, "data", {})
    }

    delete = async (studentStaffContactIds) => {
        const response = await this.post("/v1/student-staff-contacts/delete", {studentStaffContactIds})
        return get(response, "data", {})
    }

    getByProfile = async (profileId) => {
        const response = await this.post("/v1/student-staff-contacts/list", {
            filter: {
                profileIds: profileId,
                withDirectorContact: true
            },
            linkedEntities: true
        })
        return get(response, "data", {})
    }

    getByRole = async (profileIds: number[], roleId: number) => {
        const response = await this.post("/v1/student-staff-contacts/by-role", {
            profileIds,
            roleId
        })
        return get(response, "data.data", [])
    }

    getStaffs = async (params = {}): Promise<{data: Staff[]; total: number}> => {
        const response = await this.post("/v1/staffs/list", params)
        return get(response, "data", [])
    }

    invite = async (params = {}) => {
        const response = await this.post("/v1/student-staff-contacts/invite", params)
        return get(response, "data.data", {})
    }

    getAvailableAdvisorsForDepartment = async (
        params = {}
    ): Promise<{
        total: number
        data: Auth.AdvisorsListItemDetail[]
    }> => {
        const response = await this.post("/v1/department-advisors/list", params)
        return get(response, "data", {})
    }
}

export default StudentStaffContactsServiceV3
