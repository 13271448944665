import {getSecret, getTOTP} from "helpers/topt"
import {useCallback, useMemo} from "react"
import {useModel} from "./useModel"

export default function useTOPT(campusId?: number) {
    const model = useModel()

    const secret = useMemo(
        () => getSecret(`${model.clientSetting.slug}-${model.clientSetting.name}${campusId ? `-${campusId}` : ""}`, 32),
        [model.clientSetting, campusId]
    )

    return useCallback(() => getTOTP(secret), [secret])
}
