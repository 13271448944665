import React from "react"
import classNames from "classnames"
import styles from "../StudentsTable.module.css"

interface RowItemProps {
    leftColumns: Array<{field: string; title: string}>
    rightColumns: Array<{field: string; title: string}>
    visibleColumns: string[]
    columnData: {[key: string]: {width: number}}
    leftWidth: number
    title: React.ReactNode
    render: (field: string) => React.ReactNode
}

export function TotalRowItem({
    leftColumns,
    rightColumns,
    visibleColumns,
    columnData,
    leftWidth,
    title,
    render
}: RowItemProps) {
    return (
        <div className={classNames(styles.rowGroup, styles.rowHeaderGroup)}>
            <div className={styles.rowItem}>
                <div className={styles.stickyCell} style={{width: leftWidth}}>
                    {leftColumns.map(
                        (column, index) =>
                            visibleColumns.includes(column.field) && (
                                <div
                                    key={column.field}
                                    className={classNames(styles.statusGroupWrap, styles.cell, styles.cell__total)}
                                    style={{width: columnData[column.field]?.width}}>
                                    {index === leftColumns.length - 1 && (
                                        <span className={styles.total__text}>{title}</span>
                                    )}
                                </div>
                            )
                    )}
                </div>

                <div style={{width: leftWidth, minWidth: leftWidth}} />

                {rightColumns.map(
                    (column) =>
                        visibleColumns.includes(column.field) && (
                            <div key={column.field}>
                                <div
                                    className={classNames(styles.cell, styles.cell__total)}
                                    style={{width: columnData[column.field]?.width}}>
                                    <div className={styles.itemWrap}>{render(column.field)}</div>
                                </div>
                            </div>
                        )
                )}
            </div>
        </div>
    )
}
