import React, {ReactNode, useCallback, useEffect, useState} from "react"
import {BasePopup} from "../BasePopup"
import {BaseButton, SecondaryButton} from "components/buttons/Button"
import styles from "./SelectSchedulePopup.module.css"
import {useTranslation} from "react-i18next"
import {AcademicPlans} from "types/academicPlans"
import {isEmpty} from "lodash"
import {BaseLoading} from "components"
import {Checkbox} from "antd"
import cx from "classnames"
import {academicPlansService} from "services"
import {handleError} from "helpers"

type Props = {
    title: string
    isVisible: boolean
    children?: ReactNode
    registeredScheduleId?: number
    onClose: () => void
    studentProfileId: number
    termCourse: AcademicPlans.TermCourse
    onRefresh: () => void
    schedules: AcademicPlans.CourseSchedule[]
}

export const SelectSchedulePopup = (props: Props) => {
    const {
        title,
        isVisible = true,
        onClose,
        children,
        schedules,
        registeredScheduleId,
        termCourse,
        onRefresh,
        studentProfileId
    } = props
    const [loading, setLoading] = useState(false)
    const [selectedScheduleId, setSelectedScheduleId] = useState<number>()
    const {t} = useTranslation(["common"])

    useEffect(() => {
        if (registeredScheduleId) {
            setSelectedScheduleId(registeredScheduleId)
        }
    }, [registeredScheduleId])

    const onConfirm = useCallback(async () => {
        setLoading(true)
        try {
            await academicPlansService.changeSchedule({
                academicPlanCourseId: termCourse.academicPlanCourseId,
                scheduleId: selectedScheduleId,
                studentProfileId
            })
        } catch (err) {
            handleError(err)
        } finally {
            onClose()
            onRefresh()
        }
    }, [onClose, selectedScheduleId, termCourse, onRefresh, studentProfileId])

    return (
        <BasePopup isShow={isVisible} onClose={onClose} leftIconColor="#fff" width="400px">
            <div className={styles.root}>
                <div className={styles.title}>{title}</div>
                <div className={styles.description}>Select schedule to change</div>
                <div className={styles.listSchedule}>
                    {isEmpty(schedules) ? (
                        <span>No Schedule</span>
                    ) : (
                        schedules.map((courseSchedule) => {
                            const isSelected = courseSchedule.id === selectedScheduleId
                            const isCurrentSchedule = courseSchedule.id === registeredScheduleId
                            return (
                                <div
                                    className={cx(styles.scheduleRow, {
                                        [styles.disabled]: isCurrentSchedule
                                    })}>
                                    <Checkbox
                                        checked={isSelected}
                                        disabled={isCurrentSchedule}
                                        onChange={(event) => {
                                            if (!isCurrentSchedule) {
                                                setSelectedScheduleId(courseSchedule.id)
                                            }
                                        }}
                                    />
                                    <span
                                        className={styles.scheduleName}
                                        key={courseSchedule.id}
                                        onClick={() => {
                                            if (!isCurrentSchedule) {
                                                setSelectedScheduleId(courseSchedule.id)
                                            }
                                        }}>
                                        {courseSchedule.schedule_suffix}
                                    </span>
                                    {isCurrentSchedule ? (
                                        <span className={styles.currentSchedule}>(Current schedule)</span>
                                    ) : null}
                                </div>
                            )
                        })
                    )}
                </div>
                <div className={styles.footer}>
                    <div className={styles.buttons}>
                        <div className={styles.button}>
                            <SecondaryButton onClick={onClose} title={t("common:action.cancelModal")} />
                        </div>
                        <div className={styles.button}>
                            <BaseButton
                                disabled={!selectedScheduleId || registeredScheduleId === selectedScheduleId}
                                onClick={onConfirm}
                                title={t("common:action.confirm")}
                                loading={loading}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {loading ? <BaseLoading isShow /> : null}
        </BasePopup>
    )
}
