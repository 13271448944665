import React, {useState} from "react"
import {BasePopup} from "components/popup"
import styles from "./RespondOfferApplication.module.css"
import {BaseButton, BaseTextArea} from "components"
import {handleError, toastError} from "helpers"
import {careerService} from "services"
import {CareerServices} from "types/careerServices"

type Props = {
    applicationId: number
    respondType: "accepted" | "rejected"
    respondNoteId: number
    open: boolean
    onClose: () => void
    onSuccess?: (newNote: CareerServices.ApplicationNote, respondNoteId: number) => void
}

export function RespondOfferApplication(props: Props) {
    const {applicationId, respondType, respondNoteId, open, onClose, onSuccess} = props
    const [comment, setComment] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false)

    const onSubmit = async () => {
        try {
            if (comment && comment.trim() === "") {
                toastError("Should give some thoughts to respond an offer")
                return
            }

            setIsSubmitting(true)
            const newNote = await careerService.respondOfferApplication({
                id: applicationId,
                respondType,
                respondNoteId,
                content: comment
            })
            if (onSuccess) onSuccess(newNote, respondNoteId)
        } catch (e) {
            handleError(e)
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <BasePopup
            isShow={open}
            onClose={onClose}
            leftIcon="SEND"
            leftIconColor="#fff"
            width="40vw"
            // height="50vh"
            closable={true}
            destroyOnClose={true}>
            <div className={styles.headingTitle}>{`${respondType === "accepted" ? "Accept" : "Reject"} offer`}</div>
            <BaseTextArea
                className={styles.noteInput}
                rows={5}
                placeholder="type"
                onChange={setComment}
                value={comment}
            />
            <div className={styles.doneBtn}>
                <BaseButton title="Submit" onClick={onSubmit} className={styles.saveBtn} loading={isSubmitting} />
            </div>
        </BasePopup>
    )
}
