export enum SAPCheckpoint {
    GoodStanding = "overall",
    AcademicStanding = "academic-standing",
    ProgramCompletion = "program-completion",
    CompletionRateOfAttemptedUnits = "completion-rate",
    CumulativeGPA = "cumulative-gpa",
    GraduationRequirements = "graduation-requirements"
}

export const sapCheckpointOptions = [
    {id: SAPCheckpoint.GoodStanding, name: "Good Standing (Overall)"},
    {id: SAPCheckpoint.AcademicStanding, name: "Academic Standing"},
    {id: SAPCheckpoint.ProgramCompletion, name: "Program Completion"},
    {id: SAPCheckpoint.CompletionRateOfAttemptedUnits, name: "Completion Rate of Attempted Units"},
    {id: SAPCheckpoint.CumulativeGPA, name: "Cumulative GPA"},
    {id: SAPCheckpoint.GraduationRequirements, name: "Graduation Requirements"}
]

export enum RetentionLevel {
    GoodStanding = "good",
    LowRisk = "low",
    MediumRisk = "medium",
    HighRisk = "high",
    FailedSAP = "failed"
}

export const retentionLevelOptions = [
    {id: RetentionLevel.GoodStanding, name: "Good Standing"},
    {id: RetentionLevel.LowRisk, name: "Low Risk"},
    {id: RetentionLevel.MediumRisk, name: "Medium Risk"},
    {id: RetentionLevel.HighRisk, name: "High Risk"},
    {id: RetentionLevel.FailedSAP, name: "Failed SAP"}
]

export enum RetentionCategory {
    Overall = "overall",
    GradesGPA = "gradeGpa",
    GradesCGPA = "gradeCgpa",
    GradesCourses = "gradeCourse",
    AttendancePercentage = "attendancePercentage",
    AttendanceMissedHours = "attendanceMissedHours",
    AttendanceLda = "attendanceLda"
}

export const RetentionCategoryLabels = {
    [RetentionCategory.Overall]: "Overall Risk Level",
    [RetentionCategory.GradesCGPA]: "Grade CGPA Risk Level",
    [RetentionCategory.GradesGPA]: "Grade GPA Risk Level",
    [RetentionCategory.GradesCourses]: "Course Grade Risk Level",
    [RetentionCategory.AttendanceLda]: "Attendance LDA Risk Level",
    [RetentionCategory.AttendanceMissedHours]: "Attendance Missed Hours Risk Level",
    [RetentionCategory.AttendancePercentage]: "Attendance Percentage Risk Level"
}
