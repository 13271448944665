/* eslint-disable react-hooks/exhaustive-deps */
import React, {useReducer, useEffect} from "react"
import {BasePopup} from "components/popup"
import {BaseButton} from "components/buttons"
import {Course} from "types/courses"
import {AcademicPlans} from "types/academicPlans"
import {academicPlansService} from "services"
import {handleError} from "helpers"
import {CoursesTable, SelectedCoursesTable} from "../CourseTable"
import styles from "./AddCoursesPopup.module.css"

type Props = {
    selectedCourses: AcademicPlans.SectionCourse[]
    isShow: boolean
    studentId: number
    academicPlanSectionId: number
    courseUnit: Course.Unit
    onClose: () => void
    saveCoursesSuccess: (courses: AcademicPlans.SectionCourse[]) => void
}

export function AddCoursesPopup(props: Props) {
    function reducer(state, action) {
        return {...state, ...action}
    }
    const initialState = {
        isSaving: false,
        selectedCourses: [],
        forceKey: 1
    }
    const [{selectedCourses, forceKey, isSaving}, dispatch] = useReducer(reducer, initialState)
    const {isShow, onClose, studentId, academicPlanSectionId, courseUnit, saveCoursesSuccess} = props
    const hasChanged = selectedCourses.filter((course) => !course.isSelected).length > 0

    useEffect(() => {
        const selectedCourses = props.selectedCourses.map((course: any) => {
            course.isSelected = true
            return course
        })
        dispatch({selectedCourses: selectedCourses, forceKey: forceKey + 1})
    }, [])

    const onAddCourses = (addedCourses: Course.CourseTableItem[]) => {
        const newCourses = addedCourses.map((course) => ({
            courseId: course.courseId,
            courseCode: course.code,
            courseName: course.name
        }))
        dispatch({selectedCourses: [...selectedCourses, ...newCourses], forceKey: forceKey + 1})
    }

    const saveCourses = async () => {
        try {
            dispatch({isSaving: true})
            const courseIds = selectedCourses.filter((course) => !course.isSelected).map((course) => course.courseId)
            await academicPlansService.addCourse({
                studentProfileId: studentId,
                courseIds,
                academicPlanSectionId: academicPlanSectionId
            })
            saveCoursesSuccess(selectedCourses)
            onClose()
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isSaving: false})
        }
    }

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="PLUS_CIRCLE" width="80vw">
            <div className={styles.wrap}>
                <p className={styles.title}>Add Course</p>
                <div className={styles.tableWrap}>
                    <SelectedCoursesTable key={`selected_${forceKey}`} selectedCourses={selectedCourses} />
                    <CoursesTable key={forceKey} unit={courseUnit} onAddCourses={onAddCourses} />
                </div>
                <div className={styles.action}>
                    <BaseButton title="Save" onClick={saveCourses} loading={isSaving} isActive={hasChanged} />
                </div>
            </div>
        </BasePopup>
    )
}
