/* eslint-disable react-hooks/exhaustive-deps */
import {FilterField, KlassappFilters} from "uiKit"
import styles from "./AcademicAffairsFilter.module.css"
import {AppliedFilter, FieldType, FilterKey} from "types/filter"
import {useMemo} from "react"
import {useTranslation} from "react-i18next"
import CampusSelect from "components/CampusSelect"
import ProgramSelect from "components/ProgramSelect"
import {useModel} from "hooks"
import {NewStudentStatusSelectFilter} from "components/NewStudentStatusSelect"
import DegreeLevelSelect from "components/DegreeLevelSelect"
import TermSelect from "components/TermSelect"
import {KlassDropdown} from "components"
import {STATES_OPTIONS} from "types/students"
import {ExportButton, ExportFileType} from "components/ui"

type Props = {
    onApplyFilter: (payload: AppliedFilter) => void
    filterKey: FilterKey
    isStudentList?: boolean
    showExport?: boolean
    handleExport?: (type: ExportFileType) => void
    isExporting?: boolean
}

export function AcademicAffairsFilter(props: Props) {
    const {onApplyFilter, filterKey, isStudentList, showExport = false, handleExport, isExporting} = props
    const {t} = useTranslation(["common"])
    const model = useModel()

    const availableFilters = useMemo(() => {
        let filters: FilterField[] = []

        if (isStudentList) {
            filters.push({
                field: "state",
                label: t("statsFilter.state"),
                type: FieldType.List,
                renderForm: (props: any) => {
                    return (
                        <KlassDropdown
                            options={STATES_OPTIONS}
                            isMulti
                            placeholder={t("statsFilter.state")}
                            {...props}
                        />
                    )
                }
            })
        }

        filters.push(
            ...[
                {
                    field: "startDateRange",
                    label: "Student Start Date",
                    type: FieldType.Date
                },
                {
                    field: "degreeLevel",
                    label: "Degree Level",
                    type: FieldType.List,
                    renderForm: (props: any) => {
                        return <DegreeLevelSelect isMulti placeholder="Degree Level" {...props} />
                    }
                },
                {
                    field: "program",
                    label: t("statsFilter.program"),
                    type: FieldType.List,
                    renderForm: (props: any) => {
                        return <ProgramSelect isMulti placeholder={t("statsFilter.program")} {...props} />
                    }
                },
                {
                    field: "campus",
                    label: t("statsFilter.campus"),
                    type: FieldType.List,
                    renderForm: (props: any) => {
                        return <CampusSelect isMulti placeholder={t("statsFilter.campus")} {...props} />
                    }
                },
                {
                    field: "term",
                    label: t("statsFilter.term"),
                    type: FieldType.List,
                    renderForm: (props: any) => {
                        return <TermSelect isMulti placeholder={t("statsFilter.term")} {...props} />
                    }
                }
            ]
        )

        if (isStudentList) {
            filters.push(
                ...[
                    {
                        field: "temporaryOutDateRange",
                        label: "Temporary Out Effective Date",
                        type: FieldType.Date
                    },
                    {
                        field: "withdrawalDateRange",
                        label: "Withdrawal Date",
                        type: FieldType.Date
                    }
                ]
            )
        }

        if (model.clientSetting.isNewStudentStatusesVisible) {
            filters.push({
                field: "studentStatusIds",
                label: "Student Status",
                type: FieldType.List,
                renderForm: (props: any) => {
                    return <NewStudentStatusSelectFilter isMulti placeholder="Student Status" {...props} />
                }
            })
        }

        filters.push({
            field: "onlySEV",
            label: t("statsFilter.onlySEV"),
            type: FieldType.Flag
        })

        return filters
    }, [model, isStudentList])

    const onClearFilter = () => {
        onApplyFilter({filters: {}})
    }

    const renderAdditionalFilter = () => {
        if (!showExport) return null
        return (
            <div className={styles.actionWrap}>
                <ExportButton isLoading={isExporting} onSelect={handleExport} availableFileTypes={["csv", "excel"]} />
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <KlassappFilters
                filterKey={filterKey}
                showSearch={false}
                availableFilters={availableFilters}
                renderAdditionalFilter={renderAdditionalFilter}
                onApply={onApplyFilter}
                onClear={onClearFilter}
            />
        </div>
    )
}
