/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback} from "react"
import {useTranslation} from "react-i18next"
import {KlassDropdown, KlassDropdownAsyncProps} from "../Select"
import {Col, Dropdown, Menu} from "antd"
import {Icon} from "components/Icon"
import styles from "./NewStudentStatusSelect.module.css"
import cx from "classnames"
import {useModel} from "hooks"
import {StatusStateShortName, checkIfProfileHasPermissionsToEditStudentStatus} from "helpers"
import {StudentStatus} from "types/students"
import {Auth} from "types/auth"
import {useGroupedStudentStatusesByStateAsDropdownOptions} from "./useGroupedStudentStatusesByStateAsDropdownOptions"

export type Variant = "form" | "card" | "sidebar"

type NewStudentStatusSelectProps = Partial<KlassDropdownAsyncProps> & {
    studentState?: Auth.StudentState
    variant?: Variant
    readOnly?: boolean
}

export const NewStudentStatusSelect: React.FC<NewStudentStatusSelectProps> = ({
    onChange,
    value,
    readOnly,
    studentState,
    variant = "form",
    isMulti = false,
    ...props
}) => {
    const {t} = useTranslation(["user"])
    const model = useModel()
    const title = t("user.newStudentStatus")
    const className = value?.hexColor

    const {statuses, groupedStatuses} = useGroupedStudentStatusesByStateAsDropdownOptions({forState: studentState})

    const renderListStudentStatus = () => {
        return (
            <Menu className={styles.menuActionWrap} selectedKeys={[`${value?.statusId}`]}>
                {statuses.map((status) => (
                    <Menu.Item key={status.statusId} className={styles.menuActionItem} onClick={() => onChange(status)}>
                        <span className={styles.menuActionItem__status}>
                            {`[${StatusStateShortName[status.state]}] ${status.name}`}
                        </span>
                    </Menu.Item>
                ))}
            </Menu>
        )
    }

    const canEdit: boolean = checkIfProfileHasPermissionsToEditStudentStatus(model, studentState)

    const getOptionLabel = useCallback(
        (status: StudentStatus) => `[${StatusStateShortName[status.state]}] ${status.name}`,
        []
    )

    if (variant === "form") {
        return (
            <KlassDropdown
                {...props}
                options={groupedStatuses}
                valueKey="statusId"
                labelKey="name"
                getOptionLabel={getOptionLabel}
                readOnly={readOnly}
                isClearable
                value={value}
                onChange={onChange}
                isMulti={isMulti}
            />
        )
    }
    if (variant === "card") {
        return (
            <Col span={4}>
                <div className={styles.statusItem}>
                    <p className={styles.title}>{title}</p>
                    {canEdit ? (
                        <Dropdown overlay={() => renderListStudentStatus()} trigger={["click"]}>
                            <div
                                className={cx(
                                    styles.statusBar,
                                    {[value?.hexColor || "#939393"]: value},
                                    styles.statusItem__cursor
                                )}>
                                <span className={styles.statusText}>{value?.name || "Not Defined"}</span>
                                <Icon icon="ARROW_DOWN" className={styles.statusArrowDownIcon} color="#fff" />
                            </div>
                        </Dropdown>
                    ) : (
                        <span className={cx(styles.statusBar, className)}>
                            <span className={styles.statusText}>{value?.name || "Not Defined"}</span>
                        </span>
                    )}
                </div>
            </Col>
        )
    }

    if (variant === "sidebar") {
        return (
            <div className={styles.statusItemSidebar}>
                <div className={styles.titleSidebar}>{title}</div>
                {canEdit ? (
                    <Dropdown overlay={() => renderListStudentStatus()} trigger={["click"]} placement="bottomRight">
                        <div
                            className={cx(
                                styles.statusBarSidebar,
                                {[value?.hexColor || "#939393"]: value},
                                styles.statusItem__cursor
                            )}>
                            <span className={styles.statusText}>{value?.name || "Not Defined"}</span>
                            <Icon icon="ARROW_DOWN" className={styles.statusArrowDownIconSidebar} color="#fff" />
                        </div>
                    </Dropdown>
                ) : (
                    <div className={cx(styles.statusBarSidebar, className)}>
                        <span className={styles.statusText}>{value?.name || "Not Defined"}</span>
                    </div>
                )}
            </div>
        )
    }
}
