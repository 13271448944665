import {useQuery} from "@tanstack/react-query"
import {studentStaffContactsServiceV3} from "services"
import {BaseDepartmentId} from "types/departments"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {sortBy} from "lodash"
import {getFullName} from "helpers"

export const useAdvisors = () => {
    return useQuery(
        ["advisors"],
        async () => {
            const [{data: admissionAdvisors}, {data: finAidAdvisors}, {data: academicAdvisors}] = await Promise.all([
                studentStaffContactsServiceV3.getAvailableAdvisorsForDepartment({
                    filter: {
                        departmentId: BaseDepartmentId.Admissions
                    },
                    range: {
                        pageSize: UNLIMITED_PAGE_SIZE,
                        page: 1
                    }
                }),
                studentStaffContactsServiceV3.getAvailableAdvisorsForDepartment({
                    filter: {
                        departmentId: BaseDepartmentId.FinancialAid
                    },
                    range: {
                        pageSize: UNLIMITED_PAGE_SIZE,
                        page: 1
                    }
                }),
                studentStaffContactsServiceV3.getAvailableAdvisorsForDepartment({
                    filter: {
                        departmentId: BaseDepartmentId.Academics
                    },
                    range: {
                        pageSize: UNLIMITED_PAGE_SIZE,
                        page: 1
                    }
                })
            ])
            return {
                admissionAdvisors: sortBy(
                    admissionAdvisors.map((advisor) => ({
                        ...advisor,
                        fullName: getFullName(advisor),
                        name: getFullName(advisor)
                    })),
                    "firstName"
                ),
                finAidAdvisors: sortBy(
                    finAidAdvisors.map((advisor) => ({
                        ...advisor,
                        fullName: getFullName(advisor),
                        name: getFullName(advisor)
                    })),
                    "firstName"
                ),
                academicAdvisors: sortBy(
                    academicAdvisors.map((advisor) => ({
                        ...advisor,
                        fullName: getFullName(advisor),
                        name: getFullName(advisor)
                    })),
                    "firstName"
                )
            }
        },
        {
            initialData: {
                admissionAdvisors: [],
                finAidAdvisors: [],
                academicAdvisors: []
            }
        }
    )
}
