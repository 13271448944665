import React, {useCallback, useEffect, useState} from "react"
import styles from "./TermAndCourseStats.module.css"
import {Icon, IconType} from "components"
import moment from "moment"
import {useModel} from "hooks"
import {TermDetails} from "types/terms"
import {termsService} from "services"

type Props = {
    term: TermDetails
}

const TermAndCourseStats = (props: Props) => {
    const {term} = props
    const model = useModel()

    const renderItem = (title: string, info: string | number, icon: IconType) => {
        return (
            <div className={styles.block}>
                <Icon icon={icon} />
                <p className={styles.title}>{title}</p>
                <p className={styles.info}>{info}</p>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            {renderItem("Courses", term.numberOfCourses ?? 0, "TERM_COURSES_COURSES")}
            {renderItem("Hours", term.totalCourseHours ?? 0, "TERM_COURSES_HOURS")}
            {renderItem(
                "Starting day",
                moment(term?.start_date).utc().format(model.getUserDateFormat()),
                "TERM_COURSES_STARTING_DAY"
            )}
            {renderItem("Students", term.numberOfStudents ?? 0, "TERM_COURSES_STUDENTS")}
        </div>
    )
}

export default TermAndCourseStats
