import React, {Component} from "react"
import styles from "./Page404.module.css"
import HTMLTitle from "components/misc/HTMLTitle"

interface Props {}

export class Page404 extends Component<Props, {}> {
    public render() {
        return (
            <div className={styles.wrapper}>
                <HTMLTitle title={"Error 404"} />
                <h1 className={styles.title}>Error 404</h1>
                <p className={styles.text}>Page Not Found</p>
            </div>
        )
    }
}
