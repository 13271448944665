export class SlugHelper {
    public static generate(): string {
        const {host} = window.location
        const slug = process.env.NODE_ENV === "development" ? process.env.REACT_APP_SLUG_DEV : ""

        if (process.env.REACT_APP_SLUG_OVERRIDE) {
            return process.env.REACT_APP_SLUG_OVERRIDE
        }

        if (slug) return slug

        if (host.includes("10.10.10.10")) {
            return slug
        }
        let parts = host.split(".")
        if (parts.length >= 3) {
            return parts[0]
        } else {
            return slug
        }
    }
}
