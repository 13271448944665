import {color} from "d3-color"
import {toHoursMinutes} from "helpers"
import {useMemo} from "react"
// @ts-ignore
import LiquidFillGauge from "react-liquid-gauge"

type Props = {
    totalAttendedHours?: number
    totalAvailableHours?: number
}

export function LiquidChart(props: Props) {
    const {totalAttendedHours = 0, totalAvailableHours = 0} = props

    const radius = 110
    const fillColor = "#46a5ff"
    const gradientStops = [
        {
            key: "0%",
            stopColor: color(fillColor).darker(0.5).toString(),
            stopOpacity: 1,
            offset: "0%"
        },
        {
            key: "50%",
            stopColor: fillColor,
            stopOpacity: 0.75,
            offset: "50%"
        },
        {
            key: "100%",
            stopColor: color(fillColor).brighter(0.5).toString(),
            stopOpacity: 0.5,
            offset: "100%"
        }
    ]

    const progress = useMemo(() => {
        if (totalAvailableHours === 0) {
            return 0
        }
        return (totalAttendedHours / totalAvailableHours) * 100
    }, [totalAvailableHours, totalAttendedHours])

    return (
        <LiquidFillGauge
            style={{margin: "0 auto"}}
            width={radius * 2}
            height={radius * 2}
            value={progress}
            percent="%"
            totalAvailableHours={totalAvailableHours}
            totalAttendedHours={totalAttendedHours}
            textSize={0.5}
            textOffsetX={0}
            textOffsetY={0}
            textRenderer={(props) => {
                const totalAvailableHours = props.totalAvailableHours
                const totalAttendedHours = props.totalAttendedHours
                const radius = Math.min(props.height / 2, props.width / 2)
                const textPixels = (props.textSize * radius) / 2
                const valueStyle = {
                    fontSize: textPixels
                }
                const percentStyle = {
                    fontSize: textPixels * 0.5
                }

                return (
                    <>
                        <tspan style={valueStyle} x="0" dy="0">
                            {toHoursMinutes((totalAvailableHours - totalAttendedHours) * 60)}
                        </tspan>
                        <tspan style={percentStyle} x="0" dy="1em">
                            left
                        </tspan>
                        <tspan style={percentStyle} x="0" dy="2em">
                            {toHoursMinutes(totalAttendedHours * 60)}
                        </tspan>
                        <tspan style={percentStyle} x="0" dy="1em">
                            completed
                        </tspan>
                        {/* <tspan style={percentStyle}>{props.percent}</tspan> */}
                    </>
                )
            }}
            riseAnimation
            waveAnimation
            waveFrequency={2}
            waveAmplitude={2}
            gradient
            gradientStops={gradientStops}
            circleStyle={{
                fill: fillColor
            }}
            waveStyle={{
                fill: fillColor
            }}
            textStyle={{
                fill: color("#444").toString(),
                fontFamily: "Poppins"
            }}
            waveTextStyle={{
                fill: color("#fff").toString(),
                fontFamily: "Poppins"
            }}
            innerRadius={0.99}
            outerRadius={1}
        />
    )
}
