/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react"
import {useTranslation} from "react-i18next"
import moment from "moment"
import {useHistory} from "react-router-dom"
import {KlassappTable} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {useModel} from "hooks"
import {formatCurrency} from "helpers"
import {KlassappTableProps} from "types/common"
import {PAYMENT_METHOD_OPTIONS, StudentPayment, StudentPaymentCharge} from "types/student-account/student-payment"
import {Popover} from "antd"
import styles from "./PaymentsTable.module.css"
import {Icon} from "components"
import {routing} from "helpers/routing"

interface Props extends KlassappTableProps {
    payments: StudentPayment[]
    paymentCharges: StudentPaymentCharge[]
}

const ChargePaymentsTable: React.FC<Props> = ({
    dispatch,
    dispatchFunc,
    columns,
    data,
    allFields,
    fields,
    isLoading,
    onUpdateRowData,
    onUpdateTableData,
    onChangeFields,
    onDraggableColumn,
    onChangeAllFields,
    payments,
    paymentCharges
}) => {
    const history = useHistory()
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const {t} = useTranslation(["studentAccount", "common"])

    const getFields = React.useCallback(() => {
        return [
            t("payments.column.id"),
            t("payments.column.payment"),
            t("payments.column.amount"),
            t("payments.column.date"),
            t("payments.column.methodOfPayment"),
            t("payments.column.notes")
        ]
    }, [])

    const getColumns = React.useCallback(
        () => [
            {
                title: t("payments.column.id"),
                field: "paymentId"
            },
            {
                title: t("payments.column.payment"),
                field: "account",
                render: (_, record: StudentPayment) => record.account?.name || record.extraDescription
            },
            {
                title: t("payments.column.amount"),
                field: "paymentChargeAmount",
                render: (amount: number) => formatCurrency(amount),
                renderText: (value: number) => (value ?? 0).toFixed(2)
            },
            {
                title: t("payments.column.date"),
                field: "tranxDate",
                render: (date: string) => moment(date).format(userDateFormat)
            },
            {
                title: t("payments.column.methodOfPayment"),
                field: "methodOfPayment",
                render: (methodOfPayment: string) =>
                    PAYMENT_METHOD_OPTIONS.find((option) => option.id === methodOfPayment)?.name || methodOfPayment
            },
            {
                title: t("payments.column.notes"),
                titleHtml: <span />,
                isHideTitle: true,
                field: "notes",
                render: (notes?: string) =>
                    !!notes &&
                    (notes.length < 50 ? (
                        notes
                    ) : (
                        <Popover trigger="click" title="Notes" content={notes} overlayClassName={styles.notes}>
                            <span onClick={(e) => e.stopPropagation()}>
                                <Icon icon="MESSAGE_CIRCLE_LINE" />
                            </span>
                        </Popover>
                    ))
            }
        ],
        [t, userDateFormat]
    )

    const onClickRowItem = React.useCallback(({studentProfileId, paymentId}: StudentPayment) => {
        history.push(routing.studentAccount.student.ledger.payment.detail(studentProfileId, paymentId))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {
                key: "getListData",
                func: () => {}
            },
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "getTableHeaderActions", func: () => []},
            {key: "onClickRowItem", func: onClickRowItem},
            {key: "getColumns", func: getColumns}
        ])
    }, [])

    useEffect(() => {
        dispatch({
            total: payments.length,
            data: payments.map((payment) => ({
                ...payment,
                id: payment.paymentId,
                paymentChargeAmount: paymentCharges.find((item) => item.paymentId === payment.paymentId)?.amount
            }))
        })
    }, [paymentCharges, payments])

    return (
        <KlassappTable
            columns={columns}
            data={data}
            isLoading={isLoading}
            menuActions={[]}
            fields={fields}
            allFields={allFields}
            isShowHeader={true}
            isShowCheckedColumn={false}
            onClickRowItem={onClickRowItem}
            onChangeFields={onChangeFields}
            onDraggableColumn={onDraggableColumn}
            onChangeAllFields={onChangeAllFields}
            onUpdateRowData={onUpdateRowData}
            onUpdateTableData={onUpdateTableData}
        />
    )
}

export default KlassappTableHOC(ChargePaymentsTable)
