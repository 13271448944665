import React, {Component} from "react"
import {TFunction, withTranslation} from "react-i18next"
import {Checkbox, Col, Row, Tooltip} from "antd"
import debounce from "debounce-promise"
import moment from "moment"
import {get, head, isEmpty} from "lodash"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {KlassappTableProps, PaginationPerPage, SelectOption} from "types/common"
import {Auth} from "types/auth"
import {
    campusesService,
    degreeLevelService,
    departmentsService,
    majorService,
    profileService,
    reportsService,
    termsService,
    userServiceV3
} from "services"
import {
    checkPermission,
    formatCodeName,
    getFieldLabel,
    getFullName,
    getUserPhotoUrl,
    handleError,
    toastInfo
} from "helpers"
import {BaseButton} from "components/buttons"
import {KlassDropAsyncPaginate, KlassDropdown} from "components/Select"
import {ActiveUser} from "./ActiveUser"
import styles from "./UsersTab.module.css"
import {BaseDepartmentId, Department} from "types/departments"
import {ENROLLMENT_STATUS_OPTIONS, ENROLLMENT_TYPE_OPTIONS} from "types/students"
import DepartmentSubunitSelect from "components/DepartmentSubunitSelect"
import StudentStatusSelect from "components/StudentStatusSelect"
import {Model} from "Model"
import {FilterKey} from "types/filter"
import {BaseRangePicker} from "components/DateTimePicker"
import {getDefaultOrFirstProfile} from "helpers/task"
import {Settings} from "types/settings"
import {Permissions} from "types/permission"
import {NewStudentStatusSelectFilter} from "components/NewStudentStatusSelect"
import {ExportButton, ExportFileType} from "components/ui"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {exportCsv, exportExcel} from "helpers/export-table"

const enrollmentStatusOptions = ENROLLMENT_STATUS_OPTIONS

const enrollmentTypeOptions = ENROLLMENT_TYPE_OPTIONS

type Props = KlassappTableProps & {
    history?: any
    role: Auth.UserProfileType
    t: TFunction
    model: Model
    fromMenu: boolean
}

type State = {
    filter: any
    search: string
    activeUser: any
    departments: Department[]
    departmentIds: any
    degreeLevelOptions: any
    roleOptions: Array<SelectOption>
    page: number
    pageSize: number
    isExporting: boolean
}

class UsersTab extends Component<Props, State> {
    constructor(props) {
        super(props)
        const {search, filter} = this.getOldFilterData()
        this.state = {
            filter,
            search,
            activeUser: null,
            departments: [],
            degreeLevelOptions: [],
            roleOptions: [],
            departmentIds: [
                BaseDepartmentId.Admissions,
                BaseDepartmentId.FinancialAid,
                BaseDepartmentId.Academics,
                BaseDepartmentId.StudentAccount
            ],
            page: 1,
            pageSize: PaginationPerPage.item20,
            isExporting: false
        }
        this.onCampusSearchChange = debounce(this.onCampusSearchChange.bind(this), 300)
        this.onTermSearchChange = debounce(this.onTermSearchChange.bind(this), 300)
        this.onMajorSearchChange = debounce(this.onMajorSearchChange.bind(this), 300)
    }

    componentDidMount() {
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getFilterMemoryKey", func: this.getFilterKey},
            {key: "getListData", func: this.getData},
            {key: "getFields", func: this.getFields},
            {key: "getTableHeaderActions", func: this.getTableHeaderActions},
            {key: "getColumns", func: this.getColumns},
            {key: "onClickRowItem", func: this.onClickRowItem}
        ])
        this.getDepartments()
        this.getData()
        this.getRolesOptions()
    }

    getDefaultFilterByRole = () => {
        switch (this.props.role) {
            case Auth.UserProfileType.Student:
                return {
                    degreeLevel: [],
                    major: [],
                    campus: [],
                    startingTermIds: [],
                    profileStatus: null,
                    studentDepartmentStatusesIds: [],
                    enrollmentType: [],
                    enrollmentStatus: [],
                    startDateRange: [],
                    lastDayOfAttendanceRange: [],
                    actualEndDateRange: [],
                    studentStatusIds: []
                }
            case Auth.UserProfileType.Staff:
                return {
                    campus: [],
                    departmentSubunits: [],
                    roleIds: []
                }
            default:
                return {
                    campus: []
                }
        }
    }

    getDepartments = async () => {
        try {
            const {data: departments} = await departmentsService.getAll()
            this.setState({departments})
        } catch (error) {
            handleError(error)
        }
    }

    getRolesOptions = async () => {
        try {
            const data = await profileService.getRoles({
                sort: {
                    orderBy: "name",
                    orderDir: "asc"
                },
                filter: {
                    userType: Auth.UserProfileType.Staff
                }
            })
            const roles: Array<SelectOption> = data.map((role) => ({id: role.roleId, name: role.name}))
            this.setState({roleOptions: roles})
        } catch (error) {
            handleError(error)
        }
    }

    getOldFilterData = () => {
        const filterKey = this.getFilterKey()
        const storageData = this.props.model.getStorageFilter(filterKey)
        if (!isEmpty(storageData)) {
            return {search: storageData.search, filter: storageData.filter || this.getDefaultFilterByRole()}
        }
        return {
            search: "",
            filter: this.getDefaultFilterByRole()
        }
    }

    getPageTitle = () => {
        return this.props.t("user.users")
    }

    getFields = () => {
        const {t, role} = this.props
        switch (role) {
            case Auth.UserProfileType.Student:
                return [
                    t("user.firstName"),
                    t("user.lastName"),
                    t("user.nickNamePreferedName"),
                    t("user.userId"),
                    t("user.startDate"),
                    t("user.lastDateAttended"),
                    t("user.lastUpdatedDateTime"),
                    t("user.emails"),
                    t("user.phones"),
                    t("user.degree"),
                    t("user.program"),
                    t("user.campus"),
                    t("user.systemStatus")
                ]
            case Auth.UserProfileType.Staff:
                return [
                    t("user.firstName"),
                    t("user.lastName"),
                    t("user.nickNamePreferedName"),
                    t("user.userId"),
                    t("user.title"),
                    t("user.role"),
                    t("user.department"),
                    t("user.emails"),
                    t("user.phones")
                ]
            case Auth.UserProfileType.Others:
                return [
                    t("user.firstName"),
                    t("user.lastName"),
                    t("user.nickNamePreferedName"),
                    t("user.role"),
                    t("user.emails"),
                    t("user.phones")
                ]
            default:
                return []
        }
    }

    getTableHeaderActions = () => {
        const {t, onClickShowConfirmModal} = this.props
        return [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            }
        ]
    }

    getParams = () => {
        const {page, pageSize, orderField, role, model} = this.props
        const filterData: any = this.getFilterData()
        const filterKey = this.getFilterKey()
        const storageData = model.getStorageFilter(filterKey)
        const pageData = storageData?.page || page
        const pageSizeData = storageData?.pageSize || pageSize
        let orderFieldData = orderField
        if (storageData?.colOrd) {
            const columns: any[] = this.getColumns()
            const columnOrder = columns.find((column: any) => column.columnIndex === storageData.colOrd.idx)
            orderFieldData = {field: columnOrder?.orderField || columnOrder?.field, order: storageData.colOrd.order}
        }

        const params = {
            filter: {
                type: role,
                ...filterData
            },
            range: {
                limit: pageSizeData,
                offset: (pageData - 1) * pageSizeData
            },
            sort: {
                orderBy: orderFieldData.field ?? "firstName",
                orderDir: orderFieldData.order ?? "ASC"
            },
            linkedObjects: true
        }
        return params
    }

    getFilterData = () => {
        const {filter, search} = this.state
        const filterData: any = {}
        const filterMultiSelectName = [
            "major",
            "degreeLevel",
            "startingTermIds",
            "campus",
            "enrollmentType",
            "enrollmentStatus"
        ]
        filterData.search = search
        if (filter.department) {
            filterData.department = [filter.department.departmentId]
        }
        if (filter.departmentSubunits && filter.departmentSubunits.length) {
            filterData.departmentSubunits = filter.departmentSubunits.map((subunit) => subunit.subunitId)
        }
        if (filter.roleIds && filter.roleIds.length) {
            filterData.roleIds = filter.roleIds.map((role) => role.id)
        }
        if (filter && filter.profileStatus) {
            filterData.profileStatus = filter.profileStatus.id === "active" ? 1 : 0
        }
        filterMultiSelectName.forEach((name) => {
            if (filter && filter[name] && filter[name].length) {
                filterData[name] = filter[name].map((item) => item.id)
            }
        })

        if (filter.studentDepartmentStatusesIds?.length) {
            filterData.studentDepartmentStatusesIds = filter.studentDepartmentStatusesIds.map((d) => d.statusId)
        }

        if (!filterData.search) {
            delete filterData.search
        }

        if (filter.startDateRange?.length) {
            filterData.startDateRange = [
                moment(filter.startDateRange[0]).format("YYYY-MM-DD"),
                moment(filter.startDateRange[1]).format("YYYY-MM-DD")
            ]
        }

        if (filter.lastDayOfAttendanceRange?.length) {
            filterData.lastDayOfAttendanceRange = [
                moment(filter.lastDayOfAttendanceRange[0]).format("YYYY-MM-DD"),
                moment(filter.lastDayOfAttendanceRange[1]).format("YYYY-MM-DD")
            ]
        }

        if (filter.actualEndDateRange?.length) {
            filterData.actualEndDateRange = [
                moment(filter.actualEndDateRange[0]).format("YYYY-MM-DD"),
                moment(filter.actualEndDateRange[1]).format("YYYY-MM-DD")
            ]
        }

        if (filter.studentStatusIds?.length) {
            filterData.studentStatusIds = filter.studentStatusIds.map((s) => s.statusId)
        }
        return filterData
    }

    getData = async () => {
        const {dispatch, t, role} = this.props
        try {
            const params = this.getParams()
            dispatch({isLoading: true})
            const {data: users, total} = await userServiceV3.getAll(params)
            const newUsers = users.map((user: any, index) => {
                return this.convertUserInfo(user, index, role, t)
            })
            dispatch({data: newUsers, total: total})
            this.setState({activeUser: head(newUsers)})
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    convertUserInfo = (user: any, index, role, t) => {
        user.isActiveTableCol = index === 0
        user.fullName = getFullName(user)
        user.photoTab = user.photo ? getUserPhotoUrl(user.photo, 512) : "/image/DefaultAvatar.png"
        if (user.profiles.length > 0) {
            const profile = getDefaultOrFirstProfile(user, role)
            if (profile) {
                const ldaProfile = user.profiles.find((profile) => profile.lastDayOfAttendance)
                user.startDate = profile.startDate ? profile.startDate : ""
                user.degree = profile.degreeLevel ? profile.degreeLevel.name : ""
                user.programHtml = profile.currentMajor ? (profile.currentMajor as Auth.CurrentMajor).name : ""
                user.campusHtml = profile.campuses.map((campus) => campus.name).join(" ")
                user.roles = profile.roles.map((rol) => rol.name).join(" ")
                user.title = profile.title
                user.type = profile.type
                user.profileId = profile.id
                user.enrollmentType = profile.enrollmentType
                user.enrollmentId = profile.enrollmentId
                user.customProfileId = profile.customProfileId
                user.currentTerm = profile.currentTerm?.name
                user.programVersion = get(profile.currentMajorVersionId, "versionName")
                user.programVersionTotalClockHours = get(profile.currentMajorVersionId, "totalClockHours", 0)
                user.departmentSubunits = profile.departmentSubunits.map((subunit) => subunit.name).join(", ")
                user.lastDateAttended = ldaProfile?.lastDayOfAttendance
                    ? moment.utc(ldaProfile.lastDayOfAttendance).format("MM/DD/YYYY")
                    : "N/A"
            }
        }
        user.status = user.active === 1 ? t("common:message.active") : t("common:message.inactive")
        user.department = user.department.join(" ")
        user.emailHtml = (user.emails || []).map(({email}) => email).join(", ")
        user.phoneHtml = (user.phones || []).map(({phone}) => phone).join(", ")
        return user
    }

    getTableStudentColumns = () => {
        const {t} = this.props
        const columns: any[] = [
            {
                title: t("user.firstName"),
                field: "firstName",
                headerStyle: {minWidth: "120px"},
                columnIndex: 1,
                sortable: true
            },
            {
                title: t("user.lastName"),
                field: "lastName",
                headerStyle: {minWidth: "120px"},
                columnIndex: 2,
                sortable: true
            },
            {
                title: t("user.nickNamePreferedName"),
                field: "nickNamePreferedName",
                headerStyle: {minWidth: "120px"},
                columnIndex: 3,
                sortable: true
            },
            {
                title: t("user.userId"),
                field: "customUserId",
                headerStyle: {minWidth: "120px"},
                columnIndex: 4,
                sortable: true
            },
            {
                title: t("user.startDate"),
                field: "startDate",
                fieldType: "dateUtc",
                headerStyle: {minWidth: "180px"},
                columnIndex: 5,
                sortable: true
            },
            {
                title: t("user.lastDateAttended"),
                orderField: "lastDayOfAttendance",
                field: "lastDateAttended",
                headerStyle: {minWidth: "180px"},
                columnIndex: 6,
                sortable: true
            },
            {
                title: t("user.lastUpdatedDateTime"),
                field: "updatedAt",
                orderField: "updatedAt",
                fieldType: "date",
                headerStyle: {minWidth: "180px"},
                columnIndex: 7,
                sortable: true
            },
            {
                title: t("user.emails"),
                field: "emailHtml",
                orderField: "emails",
                headerStyle: {minWidth: "250px"},
                columnIndex: 8,
                sortable: true
            },
            {
                title: t("user.phones"),
                field: "phoneHtml",
                orderField: "phones",
                headerStyle: {minWidth: "150px"},
                columnIndex: 9,
                sortable: true
            },
            {
                title: t("user.degree"),
                field: "degree",
                orderField: "degreeLevel",
                headerStyle: {minWidth: "150px"},
                columnIndex: 10,
                sortable: true
            },
            {
                title: t("user.program"),
                field: "programHtml",
                orderField: "program",
                headerStyle: {minWidth: "150px", maxWidth: "220px"},
                columnIndex: 11,
                sortable: true
            },
            {
                title: t("user.campus"),
                field: "campusHtml",
                orderField: "campus",
                headerStyle: {minWidth: "150px", maxWidth: "220px"},
                columnIndex: 12,
                sortable: true
            },
            {
                title: t("user.systemStatus"),
                orderField: "active",
                field: "status",
                headerStyle: {minWidth: "160px"},
                columnIndex: 13,
                sortable: true
            },
            {
                title: t("user.city"),
                field: "city",
                columnIndex: 14,
                headerStyle: {minWidth: "150px"}
            },
            {
                title: t("user.homeAddress"),
                field: "address",
                columnIndex: 15,
                headerStyle: {minWidth: "150px"}
            }
        ]

        const canShowDeleteMyAccount = checkPermission(
            {
                staff: [Permissions.Staff.Users.DeleteAccount.Delete]
            },
            this.props.model
        )
        if (canShowDeleteMyAccount) {
            columns.push({
                title: t("user.mobileDeleted"),
                field: "mobileDeletedAt",
                columnIndex: 16,
                render: (value: string | null, record) => (
                    <Checkbox
                        checked={!!value}
                        onChange={(event) => {
                            event.stopPropagation()
                            const mobileDeleted = event.target.checked
                            this.props.dispatch({
                                data: this.props.getCurrentData().map((user) =>
                                    record.id === user.id
                                        ? {
                                              ...record,
                                              mobileDeletedAt: mobileDeleted ? new Date().toISOString() : null
                                          }
                                        : user
                                )
                            })
                            this.toggleMobileDeleted(record.id, mobileDeleted)
                        }}
                    />
                )
            })
        }

        return columns
    }

    getTableStaffColumns = () => {
        const {t} = this.props
        return [
            {
                title: t("user.firstName"),
                field: "firstName",
                headerStyle: {minWidth: "120px"},
                columnIndex: 1,
                sortable: true
            },
            {
                title: t("user.lastName"),
                field: "lastName",
                headerStyle: {minWidth: "120px"},
                columnIndex: 2,
                sortable: true
            },
            {
                title: t("user.nickNamePreferedName"),
                field: "nickNamePreferedName",
                headerStyle: {minWidth: "120px"},
                columnIndex: 3,
                sortable: true
            },
            {
                title: t("user.userId"),
                field: "customUserId",
                headerStyle: {minWidth: "150px"},
                columnIndex: 4,
                sortable: true
            },
            {
                title: t("user.title"),
                field: "title",
                headerStyle: {minWidth: "250px"},
                columnIndex: 5,
                sortable: true
            },
            {
                title: t("user.role"),
                field: "roles",
                headerStyle: {minWidth: "250px"},
                columnIndex: 6,
                style: {maxWidth: "250px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden"},
                sortable: true
            },
            {
                title: t("user.department"),
                field: "departmentSubunits",
                headerStyle: {
                    minWidth: "200px",
                    maxWidth: "350px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                },
                columnIndex: 7,
                sortable: true
            },
            {
                title: t("user.emails"),
                field: "emailHtml",
                headerStyle: {minWidth: "250px"},
                columnIndex: 8,
                sortable: false
            },
            {
                title: t("user.phones"),
                field: "phoneHtml",
                headerStyle: {minWidth: "150px"},
                columnIndex: 9,
                sortable: false
            }
        ]
    }

    getTableOtherColumns = () => {
        const {t} = this.props
        return [
            {
                title: t("user.firstName"),
                field: "firstName",
                headerStyle: {minWidth: "120px"},
                columnIndex: 1,
                sortable: true
            },
            {
                title: t("user.lastName"),
                field: "lastName",
                headerStyle: {minWidth: "120px"},
                columnIndex: 2,
                sortable: true
            },
            {
                title: t("user.nickNamePreferedName"),
                field: "nickNamePreferedName",
                headerStyle: {minWidth: "120px"},
                columnIndex: 3,
                sortable: true
            },
            {
                title: t("user.role"),
                field: "roles",
                style: {minWidth: "150px"},
                columnIndex: 4,
                sortable: true
            },
            {
                title: t("user.emails"),
                field: "emailHtml",
                columnIndex: 5,
                style: {minWidth: "150px"}
            },
            {
                title: t("user.phones"),
                field: "phoneHtml",
                columnIndex: 6,
                style: {minWidth: "150px"}
            }
        ]
    }

    getColumns = () => {
        const {role} = this.props
        switch (role) {
            case Auth.UserProfileType.Student:
                return this.getTableStudentColumns()
            case Auth.UserProfileType.Staff:
                return this.getTableStaffColumns()
            case Auth.UserProfileType.Others:
                return this.getTableOtherColumns()
            default:
                return []
        }
    }

    onClickRowItem = async (user) => {
        const newUser = this.props.data.map((item) => {
            item.isActiveTableCol = item.id === user.id
            return item
        })
        this.setState({activeUser: user})
        this.props.dispatch({data: newUser})
    }

    onDoubleClickRowItem = (user) => {
        const {role} = this.props
        this.props.history.push({
            pathname: `/users/${user.id}`,
            state: {edit: true, defaultRole: role}
        })
    }

    onClickAddUser = () => {
        const {history} = this.props
        switch (this.props.role) {
            case Auth.UserProfileType.Student:
                history.push("/users/student-detail")
                break
            case Auth.UserProfileType.Staff:
                history.push("/users/staff-detail")
                break
            case Auth.UserProfileType.Others:
                history.push("/users/others-detail")
                break
            default:
                break
        }
    }

    onClickFilter = () => {
        const filterKey = this.getFilterKey()
        this.props.model.updateStorageFilter(filterKey, {filter: this.state.filter, page: 1})
        this.getData()
    }

    onClickClearFilter = () => {
        const defaultFilter = this.getDefaultFilterByRole()
        this.setState({filter: {...defaultFilter}})
    }

    onChangeFilter = (key, value) => {
        const {filter} = this.state
        filter[key] = value
        this.setState({filter: {...filter}})
    }

    onCampusSearchChange = async (search = "", loadedOptions) => {
        try {
            const params = {
                filter: {
                    search
                },
                range: {
                    limit: 20,
                    offset: loadedOptions.length
                },
                sort: {
                    orderBy: "name",
                    orderDir: "asc"
                }
            }
            const {data: campuses, total} = await campusesService.getAll(params)
            return {
                options: campuses.map(({id, name, code}) => ({id, name, code})),
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    onTermSearchChange = async (search = "", loadedOptions) => {
        try {
            const params = {
                limit: 20,
                offset: loadedOptions.length,
                text: search
            }
            const {data: terms, total} = await termsService.getAllTerms(params)
            return {
                options: terms.map(({id, name, code}) => ({id, name, code})),
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    onMajorSearchChange = async (search = "", loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const params = {
                range: {
                    page,
                    pageSize
                },
                filter: {
                    search
                }
            }
            const {data: majors, total} = await majorService.getAll(params)
            return {
                options: majors.map(({id, name, code}) => ({id, name, code})),
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    onDegreeLevelsChange = async (search = "", loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const params = {
                range: {
                    page,
                    pageSize
                },
                filter: {
                    search
                },
                fields: ["degreeLevelId", "name", "code"]
            }

            const {data: degreeLevels, total} = await degreeLevelService.getAll(params)
            const degreeLevelOptions = degreeLevels.map(({degreeLevelId: id, name, code}) => ({id, name, code}))
            return {
                options: degreeLevelOptions,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    getFilterKey = () => {
        switch (this.props.role) {
            case Auth.UserProfileType.Student:
                return FilterKey.UsersPage_Student
            case Auth.UserProfileType.Staff:
                return FilterKey.UsersPage_Staff
            case Auth.UserProfileType.Others:
                return FilterKey.UsersPage_Other
            default:
                return null
        }
    }

    onSearchInput = (search) => {
        const filterKey = this.getFilterKey()
        this.props.model.updateStorageFilter(filterKey, {search, page: 1})
        this.props.dispatch({page: 1}, () => {
            this.setState({search}, () => {
                this.getData()
            })
        })
    }

    renderStudentFilterBody = () => {
        const {filter} = this.state
        const {t} = this.props
        return (
            <Row gutter={[24, 24]}>
                <Col span={12}>
                    <KlassDropAsyncPaginate
                        value={filter.degreeLevel}
                        onChange={(newValue) => this.onChangeFilter("degreeLevel", newValue)}
                        loadOptions={this.onDegreeLevelsChange}
                        placeholder={t("user.degreeLevel")}
                        isMulti
                        getOptionLabel={formatCodeName}
                    />
                </Col>
                <Col span={12}>
                    <KlassDropAsyncPaginate
                        value={filter.major}
                        onChange={(newValue) => this.onChangeFilter("major", newValue)}
                        loadOptions={this.onMajorSearchChange}
                        placeholder={t("user.program")}
                        isMulti
                        getOptionLabel={formatCodeName}
                    />
                </Col>
                <Col span={12}>
                    <KlassDropAsyncPaginate
                        value={filter.campus}
                        onChange={(newValue) => this.onChangeFilter("campus", newValue)}
                        loadOptions={this.onCampusSearchChange}
                        isMulti
                        placeholder={t("user.campuses")}
                        getOptionLabel={formatCodeName}
                    />
                </Col>
                <Col span={12}>
                    <KlassDropAsyncPaginate
                        value={filter.startingTermIds}
                        loadOptions={this.onTermSearchChange}
                        onChange={(newValue) => this.onChangeFilter("startingTermIds", newValue)}
                        isMulti
                        placeholder={"Starting Term"}
                        getOptionLabel={formatCodeName}
                    />
                </Col>
                <Col span={12}>
                    <KlassDropdown
                        options={[
                            {id: "active", name: "Active"},
                            {id: "inactive", name: "Inactive"}
                        ]}
                        value={filter.profileStatus}
                        placeholder={t("user.profileStatus")}
                        onChange={(newValue) => this.onChangeFilter("profileStatus", newValue)}
                        isClearable
                    />
                </Col>
                {!this.props.model.clientSetting.isNewStudentStatusesVisible &&
                    !this.props.model.clientSetting.isDepartmentalStatusesHidden && (
                        <Col span={12}>
                            <StudentStatusSelect
                                isMulti
                                placeholder={t("common:statsFilter.studentStatus")}
                                valueKey="statusId"
                                labelKey="name"
                                isClearable={false}
                                value={filter.studentDepartmentStatusesIds}
                                onChange={(newValue) => this.onChangeFilter("studentDepartmentStatusesIds", newValue)}
                                shortly
                            />
                        </Col>
                    )}
                <Col span={12}>
                    <KlassDropdown
                        options={enrollmentTypeOptions}
                        value={filter.enrollmentType}
                        placeholder={t("user.enrollmentType")}
                        onChange={(newValue) => this.onChangeFilter("enrollmentType", newValue)}
                        isClearable
                        isMulti
                    />
                </Col>
                <Col span={12}>
                    <KlassDropdown
                        options={enrollmentStatusOptions}
                        value={filter.enrollmentStatus}
                        onChange={(newValue) => this.onChangeFilter("enrollmentStatus", newValue)}
                        placeholder={getFieldLabel(
                            this.props.model,
                            Settings.GeneralLabel.EnrollmentStatus,
                            t("financialAid:financialAidPackage.enrollmentStatus")
                        )}
                        isClearable
                        isMulti
                    />
                </Col>
                <Col span={12}>
                    <NewStudentStatusSelectFilter
                        isMulti
                        onChange={(newValue) => this.onChangeFilter("studentStatusIds", newValue)}
                        value={filter.studentStatusIds}
                    />
                </Col>
                <Col span={12}>
                    <Tooltip title={"Start Date"}>
                        <BaseRangePicker
                            placeholder={["Start Date From", "Start Date To"]}
                            value={
                                filter.startDateRange?.length
                                    ? [moment(filter.startDateRange[0]), moment(filter.startDateRange[1])]
                                    : null
                            }
                            onChange={(newValue) => this.onChangeFilter("startDateRange", newValue)}
                        />
                    </Tooltip>
                </Col>
                <Col span={12}>
                    <Tooltip title={"Last Day of Attendance"}>
                        <BaseRangePicker
                            placeholder={["Last Day of Attendance From", "Last Day of Attendance To"]}
                            value={
                                filter.lastDayOfAttendanceRange?.length
                                    ? [
                                          moment(filter.lastDayOfAttendanceRange[0]),
                                          moment(filter.lastDayOfAttendanceRange[1])
                                      ]
                                    : null
                            }
                            onChange={(newValue) => this.onChangeFilter("lastDayOfAttendanceRange", newValue)}
                        />
                    </Tooltip>
                </Col>
                <Col span={12}>
                    <Tooltip title={"Actual End Date"}>
                        <BaseRangePicker
                            placeholder={["Actual End Date From", "Actual End Date To"]}
                            value={filter.actualEndDateRange}
                            onChange={(newValue) => this.onChangeFilter("actualEndDateRange", newValue)}
                        />
                    </Tooltip>
                </Col>
            </Row>
        )
    }

    renderStaffFilterBody = () => {
        const {filter, roleOptions} = this.state
        const {t} = this.props

        return (
            <Row gutter={[24, 24]}>
                <Col span={12}>
                    <KlassDropAsyncPaginate
                        value={filter.campus}
                        onChange={(newValue) => this.onChangeFilter("campus", newValue)}
                        loadOptions={this.onCampusSearchChange}
                        isMulti
                        placeholder={t("user.campuses")}
                        getOptionLabel={formatCodeName}
                    />
                </Col>
                <Col span={12}>
                    <DepartmentSubunitSelect
                        onChange={(newValue) => this.onChangeFilter("departmentSubunits", newValue)}
                        value={filter.departmentSubunits}
                        isMulti
                        placeholder={t("user.department")}
                        shortly
                    />
                </Col>
                <Col span={12}>
                    <KlassDropdown
                        onChange={(newValue) => this.onChangeFilter("roleIds", newValue)}
                        options={roleOptions}
                        value={filter.roleIds}
                        isMulti
                        placeholder={t("user.role")}
                    />
                </Col>
            </Row>
        )
    }

    renderOthersFilterBody = () => {
        const {filter} = this.state
        const {t} = this.props

        return (
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <KlassDropAsyncPaginate
                        value={filter.campus}
                        onChange={(newValue) => this.onChangeFilter("campus", newValue)}
                        loadOptions={this.onCampusSearchChange}
                        isMulti
                        placeholder={t("user.campuses")}
                        getOptionLabel={formatCodeName}
                    />
                </Col>
            </Row>
        )
    }

    renderFilterBody = () => {
        switch (this.props.role) {
            case Auth.UserProfileType.Student:
                return this.renderStudentFilterBody()
            case Auth.UserProfileType.Staff:
                return this.renderStaffFilterBody()
            case Auth.UserProfileType.Others:
                return this.renderOthersFilterBody()
            default:
                return null
        }
    }

    toggleMobileDeleted = async (userId: number, mobileDeleted: boolean) => {
        await userServiceV3.toggleMobileDeleted({userId, mobileDeleted})
    }

    handleExportFile = async (type: ExportFileType) => {
        const reportInfo = {
            reportKey: "users/students",
            filename: ["students-report", moment().format("DD_MM_YYYY-hhmm")].join("-")
        }
        const {role, total, fields, t} = this.props
        if (total > 1000) {
            try {
                this.setState({isExporting: true})
                const params = this.getParams()
                const reportColumns = this.getColumns()
                    .filter((col) => fields.includes(col.title))
                    .map((column) => ({field: column.field, title: column.title, fieldType: column.fieldType}))
                await reportsService.generateReport({
                    reportKey: reportInfo.reportKey,
                    payload: {
                        filter: {
                            ...params,
                            range: undefined,
                            linkedObjects: undefined
                        },
                        fields: reportColumns
                    },
                    format: type
                })
                toastInfo(
                    "The report contains more than 1000 records; it will take some time to generate it. We will send you a notification with the download link when the report is ready"
                )
            } catch (error) {
                handleError(error)
            } finally {
                this.setState({isExporting: false})
            }
            return
        }
        try {
            this.setState({isExporting: true})
            const params = this.getParams()
            const {data: users} = await userServiceV3.getAll({
                ...params,
                range: {
                    limit: UNLIMITED_PAGE_SIZE,
                    offset: 0
                }
            })
            const reportColumns = this.getColumns().filter((col) => fields.includes(col.title))
            const filename = reportInfo.filename
            const data = users.map((user: any, index) => {
                return this.convertUserInfo(user, index, role, t)
            })

            const payload = {
                filename,
                columns: reportColumns.map((col) => col.title),
                rows: data.map((item) =>
                    reportColumns.map((col) => {
                        const value = col.renderText
                            ? col.renderText(get(item, col.field), item)
                            : col.render
                            ? col.render(get(item, col.field), item)?.toString()
                            : get(item, col.field)?.toString()
                        return (type === "csv" ? `"${value?.replaceAll(/[|,;"]/g, "")}"` : value) || ""
                    })
                )
            }
            if (type === "csv") {
                exportCsv(payload)
            } else if (type === "excel") {
                exportExcel(payload)
            }
        } catch (error) {
            handleError(error)
        } finally {
            this.setState({isExporting: false})
        }
    }

    render() {
        const {activeUser, filter, search, isExporting} = this.state
        const {page, total, pageSize, columns, data, allFields, fields, isLoading, orderField, t} = this.props

        return (
            <>
                {activeUser && <ActiveUser user={activeUser} />}
                <BaseNewFilter
                    searchValue={search}
                    filter={filter}
                    onClick={this.onClickFilter}
                    onClickClear={this.onClickClearFilter}
                    onSearchInput={this.onSearchInput}
                    renderRightFilter={() => (
                        <div className={styles.actionWrap}>
                            <BaseButton title={t("common:action.add")} onClick={this.onClickAddUser} />
                            {this.props.role === Auth.UserProfileType.Student && (
                                <ExportButton
                                    isLoading={isExporting}
                                    onSelect={this.handleExportFile}
                                    availableFileTypes={["csv", "excel"]}
                                />
                            )}
                        </div>
                    )}>
                    {this.renderFilterBody()}
                </BaseNewFilter>
                <KlassappTableHeader
                    isShowAction={false}
                    actions={[]}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.props.onChangeRowPerPage}
                />
                <KlassappTable
                    columns={columns}
                    data={data}
                    menuActions={[]}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    isShowCheckedColumn={false}
                    orderField={orderField}
                    onClickRowItem={this.onClickRowItem}
                    onDoubleClickRowItem={this.onDoubleClickRowItem}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onUpdateRowData={this.props.onUpdateRowData}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onClickSortColumn={this.props.onClickSortColumn}
                />
            </>
        )
    }
}

export default KlassappTableHOC(withTranslation(["user"])(UsersTab))
