export const API_URL = `http://${process.env.REACT_APP_EDULAR_CORE_API_BASE_URL}/v1`
export const BTN_GO_BACK = "back"

export const UNLIMITED_PAGE_SIZE = 99999999

export const MODULES = {
    SMS: 14
}

export const KEY_PRIORITY_COLLAPSIBLE = "PRIORITY_COLLAPSIBLE"
