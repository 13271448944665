import useChatV2 from "hooks/useChatV2"
import React, {createContext} from "react"

export const ChatContextV2 = createContext<any>({})

type ChatProviderProps = {
    children: React.ReactNode
}

export const ChatProviderV2: any = ({children}: ChatProviderProps) => {
    useChatV2()

    return <ChatContextV2.Provider value={{}}>{children}</ChatContextV2.Provider>
}
