import {get} from "lodash"
import BaseService from "./Base"
import {Auth} from "types/auth"

export default class StudentServiceV2 extends BaseService {
    public async getAll(params = {}): Promise<{
        total: number
        data: Auth.DepartmentStudent[]
        profilesCountByStatus?: Record<number, number>
    }> {
        const response = await this.post("/v2/students/list", params)
        return get(response, "data", null)
    }
}
