/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useReducer} from "react"
import {academicPlansService, studentService} from "services"
import {handleError} from "helpers"
import {BaseLoading} from "components"
import {TabView} from "./parts/TabView"
import styles from "./AcademicPlan.module.css"
import {Col, Row} from "antd"
import {FormLabel} from "components/Form"
import {useTranslation} from "react-i18next"

function reducer(state, action) {
    return {...state, ...action}
}

export function AcademicPlan(props) {
    const {studentId, departmentId} = props
    const initialState = {
        student: {},
        isLoading: false
    }
    const [{student, isLoading}, dispatch] = useReducer(reducer, initialState)
    const {t} = useTranslation(["major", "common"])

    useEffect(() => {
        loadStudent(studentId)
    }, [studentId])

    async function loadStudent(studentProfileId: number) {
        try {
            dispatch({isLoading: true})
            const {
                data: [student]
            } = await studentService.getDepartmentStudents({filter: {profileIds: [studentProfileId], departmentId}})
            dispatch({student})
            if (student && student.programVersionName && !student.isAcademicPlanLoaded) {
                await setupAcademicPlan()
            }
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const setupAcademicPlan = async () => {
        try {
            await academicPlansService.setupAcademicPlan({profileId: studentId})
        } catch (error) {
            handleError(error)
        }
    }

    return (
        <div>
            <div className={styles.requirementWrap}>
                <div className={styles.requirementTitle}>Requirements</div>
                <Row gutter={[12, 12]} className={styles.majorVersionInfoWrap}>
                    <Col span={8}>
                        <FormLabel
                            label={
                                student.programVersionType === "clock"
                                    ? t("version.totalClockHours")
                                    : t("version.totalCreditHours")
                            }
                        />
                        <span className={styles.majorVersionData}>{student.programVersionTotalClockHour || "-"}</span>
                    </Col>
                    <Col span={8}>
                        <FormLabel label={t("version.numberOfWeeks")} />
                        <span className={styles.majorVersionData}>{student.programVersionNumberOfWeeks || "-"}</span>
                    </Col>
                    <Col span={8}>
                        <FormLabel label={t("version.numberHourPerWeek")} />
                        <span className={styles.majorVersionData}>
                            {student.programVersionNumberOfHoursPerWeek || "-"}
                        </span>
                    </Col>
                </Row>
                {isLoading ? <BaseLoading isShow={isLoading} /> : <TabView {...props} student={student} />}
            </div>
        </div>
    )
}
