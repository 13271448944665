/* eslint-disable react-hooks/exhaustive-deps */
import {FilterList} from "types/filter"
import {Card} from "../Card"
import styles from "./SapStatus.module.css"
import {useCallback} from "react"
import {studentServicesService} from "services"
import {Bar} from "react-chartjs-2"
import {v4 as uuidV4} from "uuid"
import {uniq} from "lodash"
import {useQuery} from "@tanstack/react-query"
import {handleError} from "helpers"

const MONTH_LABELS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

type Props = {
    loadKey: string
    filters?: FilterList
    chartOptions?: any
}

export function SapStatus(props: Props) {
    const {loadKey, filters, chartOptions} = props

    const {data, isFetching} = useQuery({
        queryKey: ["student-sap-status", loadKey],
        enabled: !!loadKey,
        queryFn: async () => {
            const _data = await studentServicesService.getSAP({
                filters
            })
            const labels = uniq(_data.map((x) => x.yearMonth)).map((x: any) => {
                const timeLabels = x.split("-")
                return {
                    label: `${MONTH_LABELS[parseInt(timeLabels[1]) - 1]} ${timeLabels[0]}`,
                    yearMonth: x
                }
            })
            const failedDataset: any = [],
                riskDataset: any = []
            labels.forEach((x) => {
                const failed = _data.find((y) => y.sapStatusCategory === "failed" && y.yearMonth === x.yearMonth)
                const risk = _data.find((y) => y.sapStatusCategory === "at-risk" && y.yearMonth === x.yearMonth)

                failedDataset.push(failed?.noStudents || 0)
                riskDataset.push(risk?.noStudents || 0)
            })
            return {
                labels: labels.map((x) => x.label),
                datasets: [
                    {
                        data: failedDataset,
                        label: "Failed",
                        backgroundColor: "#ff8a38"
                    },
                    {
                        data: riskDataset,
                        label: "At risk",
                        backgroundColor: "#ffcf74"
                    }
                ]
            }
        },
        onError: (err) => {
            handleError(err)
        }
    })

    const datasetKeyProvider = useCallback(() => uuidV4(), [filters])

    const renderChart = () => {
        if (!data) return null
        return <Bar data={data} options={chartOptions} datasetKeyProvider={datasetKeyProvider} />
    }

    return (
        <Card
            key={loadKey}
            iconName="PLUS"
            iconColor="var(--warning-400-base)"
            iconStyle={styles.iconSAP}
            title="SAP"
            renderChart={renderChart}
            isLoading={isFetching}
        />
    )
}
