import React from "react"
import classes from "./ActivityStatus.module.css"
import cx from "classnames"
import {useTranslation} from "react-i18next"

export type ActivityStatusProps = {
    className?: string
    status: "active" | "inactive"
}

export const ActivityStatus = ({className, status}: ActivityStatusProps) => {
    const {t} = useTranslation(["common"])
    const label = status === "active" ? t("common:message.active") : t("common:message.inactive")
    return (
        <div
            className={cx(
                classes.root,
                {
                    [classes.active]: status === "active",
                    [classes.inactive]: status === "inactive"
                },
                className
            )}>
            {label}
        </div>
    )
}
