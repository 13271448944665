/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useContext, useEffect, useMemo, useState} from "react"
import {BaseButton, BaseLoading} from "components"
import {BaseNewFilter, KlassappTableHeader} from "uiKit"
import styles from "./ListJobs.module.css"
import {Col, Row} from "antd"
import {KlassappTableHOC} from "HOC"
import {handleError} from "helpers"
import {careerService} from "services"
import {useHistory} from "react-router-dom"
import {JobItem} from "./parts/JobItem"
import {KlassDropAsyncPaginate, KlassDropdown} from "components/Select"
import debounce from "debounce-promise"
import {FormLabel} from "components/Form"
import {CareerServices} from "types/careerServices"
import {ActiveJobContext} from "context/ActiveJobContext"
import {useModel} from "hooks"

const EMPLOYMENT_TYPE = {
    "full-time": "Full time",
    "part-time": "Part time",
    contract: "Contract"
}

const EXPERIENCE_LEVEL = {
    "post-grad": "Post-Graduation"
}

const WORKING_TYPE = {
    remote: "Yes",
    hybrid: "Hybrid",
    office: "No"
}

export function Jobs(props) {
    const history = useHistory()
    const {studentId, employerProfileId} = props
    const {data, page, total, pageSize, isLoading, dispatch} = props
    const [filter, setFilter] = useState({companies: [], workingTypes: [], employmentTypes: [], expLevels: []})
    const [search, setSearch] = useState("")
    const {changeActiveJob} = useContext(ActiveJobContext)
    const model = useModel()
    const isStudent = model.isStudent()

    useEffect(() => {
        props.dispatch({isClassComponent: false})
        props.dispatchFunc([{key: "getListData", func: getData}])
    }, [])

    useEffect(() => {
        getData()
    }, [search, page, pageSize, studentId, employerProfileId])

    async function getData() {
        try {
            dispatch({isLoading: true})
            let filterParams: any = {}
            if (search) {
                filterParams.search = search
            }
            if (filter.companies && filter.companies.length > 0) {
                const companyIds = filter.companies.map((x) => x.id)
                filterParams.companyIds = companyIds
            }
            if (filter.employmentTypes && filter.employmentTypes.length > 0) {
                const employmentTypes = filter.employmentTypes.map((x) => x.value)
                filterParams.employmentTypes = employmentTypes
            }
            if (filter.expLevels && filter.expLevels.length > 0) {
                const expLevels = filter.expLevels.map((x) => x.value)
                filterParams.expLevels = expLevels
            }
            if (filter.workingTypes && filter.workingTypes.length > 0) {
                const workingTypes = filter.workingTypes.map((x) => x.value)
                filterParams.workingTypes = workingTypes
            }
            if (studentId) {
                filterParams.studentId = studentId
            }
            if (employerProfileId) {
                filterParams.employerProfileId = employerProfileId
            }
            const {data, total} = await careerService.getListJobs({
                range: {page, pageSize},
                filter: filterParams
            })
            dispatch({data: data, total})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const getEmployers = async (search = "", loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const params: any = {
                range: {
                    pageSize,
                    page
                }
            }
            if (search) {
                params.filter.search = search
            }
            const {data, total} = await careerService.getListEmployers(params)
            const employers = data.map((item) => {
                const _item: any = {...item}
                _item.name = item.companyName
                return _item
            })
            return {
                options: employers,
                hasMore: loadedOptions.length < total
            }
        } catch (e) {
            return {options: [], hasMore: false}
        }
    }

    let employmentTypes: any = []
    Object.entries(EMPLOYMENT_TYPE).forEach(([key, value]) => {
        employmentTypes.push({
            value: key,
            label: value
        })
    })

    let workingTypes: any = []
    Object.entries(WORKING_TYPE).forEach(([key, value]) => {
        workingTypes.push({
            value: key,
            label: value
        })
    })

    let expLevels: any = []
    Object.entries(EXPERIENCE_LEVEL).forEach(([key, value]) => {
        expLevels.push({
            value: key,
            label: value
        })
    })

    const navigate_to = (_id?: string) => {
        let id = _id
        if (_id === undefined) {
            history.push(`/career-services/jobs/create`)
        } else {
            history.push(`/career-services/job-details/${id}`)
        }
    }

    const onClickClearFilter = () => {
        setFilter({companies: [], workingTypes: [], employmentTypes: [], expLevels: []})
    }

    const onChangeFilter = (key, value) => {
        const newFilter = {...filter}
        newFilter[key] = value
        setFilter(newFilter)
    }

    const onSearchInput = (search) => {
        setSearch(search)
    }

    const onApplyFilter = () => {
        getData()
    }

    const renderJobItem = (item: CareerServices.Job) => {
        return <JobItem job={item} onClick={() => changeActiveJob(item)} key={item.id} allowNavigate />
    }

    const renderJobs = (data) => {
        if (data.length <= 0) {
            return <div className={styles.emptyData}>Empty Data</div>
        }
        return data.map((item) => renderJobItem(item))
    }

    const debounceEmployer = useCallback(debounce(getEmployers, 300), [])

    const isEmployer = useMemo(() => {
        return !!employerProfileId
    }, [employerProfileId])

    return (
        <div>
            <BaseNewFilter
                filter={filter}
                onClick={onApplyFilter}
                onClickClear={onClickClearFilter}
                onSearchInput={onSearchInput}
                renderRightFilter={() => (
                    <>
                        {isStudent ? null : (
                            <div className={styles.actionWrap}>
                                <BaseButton title="Add" onClick={() => navigate_to()} className={styles.addButton} />
                            </div>
                        )}
                    </>
                )}>
                <Row gutter={[12, 12]}>
                    {!isEmployer && (
                        <Col span={12}>
                            <div>
                                <FormLabel label="Company" />
                                <KlassDropAsyncPaginate
                                    value={filter.companies}
                                    loadOptions={debounceEmployer}
                                    onChange={(newValue) => onChangeFilter("companies", newValue)}
                                    placeholder="Company"
                                    isMulti
                                />
                            </div>
                        </Col>
                    )}
                    <Col span={12}>
                        <div>
                            <FormLabel label="Remote" />
                            <KlassDropdown
                                value={filter.workingTypes}
                                placeholder="Select"
                                onChange={(option: any) => onChangeFilter("workingTypes", option)}
                                valueKey="value"
                                labelKey="label"
                                options={workingTypes}
                                hasEmptyOption
                                isMulti
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div>
                            <FormLabel label="Employment Type" />
                            <KlassDropdown
                                value={filter.employmentTypes}
                                placeholder="Select"
                                onChange={(option: any) => onChangeFilter("employmentTypes", option)}
                                valueKey="value"
                                labelKey="label"
                                options={employmentTypes}
                                hasEmptyOption
                                isMulti
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div>
                            <FormLabel label="Experience Level" />
                            <KlassDropdown
                                value={filter.expLevels}
                                placeholder="Select"
                                onChange={(option: any) => onChangeFilter("expLevels", option)}
                                valueKey="value"
                                labelKey="label"
                                options={expLevels}
                                hasEmptyOption
                                isMulti
                            />
                        </div>
                    </Col>
                </Row>
            </BaseNewFilter>
            <Row className={styles.marginTop}>
                <Col span={24}>
                    <KlassappTableHeader
                        page={page}
                        total={total}
                        defaultPageSize={pageSize}
                        onChangePage={props.onChangePage}
                        onChangeRowPerPage={props.onChangeRowPerPage}
                    />
                    {isLoading ? <BaseLoading isShow /> : renderJobs(data)}
                </Col>
            </Row>
        </div>
    )
}

export const ListJobs = KlassappTableHOC(Jobs)
