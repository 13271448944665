import React from "react"
import {Route, Redirect} from "react-router-dom"
import {checkPermission} from "helpers/common"
import {useEdularModulesContext} from "@edular/modules"

export const RouteWithPermissionsRequired = (props) => {
    const {component: Component, permissions, module, model, children, ...rest} = props
    const {isModuleEnable} = useEdularModulesContext()
    let havePermission = true
    let isEnabled = true

    if (permissions && model?.myPermissions) {
        havePermission = checkPermission(permissions, model)
    }
    if (module) {
        isEnabled = isModuleEnable(module)
    }

    if (!havePermission || !isEnabled) {
        return <Route {...rest} render={() => <Redirect to={{pathname: "/"}} />} />
    }

    return <Route {...rest} render={(props) => <Component {...props} model={model} {...rest} />} />
}
