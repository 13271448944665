import React, {useState} from "react"
import {BaseButton, BasePopup, SecondaryButton} from "components"
import styles from "./PopupVerifyClassActivities.module.css"
import {withTranslation} from "react-i18next"
import classNames from "classnames"
import {attendanceService} from "services"
import {handleError} from "helpers"
import {AttendanceStatus} from "types/attendance"
import {Course} from "types/courses"
import {ClassActivityType} from "./ClassActivitiesTab"

type PropsPopupVerifyClassActivities = {
    isShowConfirmVerify: boolean
    onCancelConfirm: () => void
    refreshData: () => void
    scheduleId?: number
    t: Function
    data: Array<{id: number; activityType: ClassActivityType; isChecked: boolean}>
}

const PopupVerifyClassActivities = (props: PropsPopupVerifyClassActivities) => {
    const {isShowConfirmVerify, onCancelConfirm, scheduleId, data = [], t, refreshData} = props
    const [loading, setLoading] = useState(false)

    const onPressVerify = async () => {
        setLoading(true)
        try {
            const selectedActivities = data.filter((item) => item.isChecked)
            const activities = selectedActivities.map((item) => ({
                id: item.id,
                activityType: item.activityType
            }))
            await attendanceService.verifyActivities({activities: activities, scheduleId})
            await refreshData()
            onCancelConfirm()
        } catch (err) {
            handleError(err)
        } finally {
            setLoading(false)
        }
    }

    return (
        <BasePopup
            isShow={isShowConfirmVerify}
            onClose={onCancelConfirm}
            width="70vw"
            leftIcon="WARNING_SOLID"
            leftIconColor="#fff">
            <div>
                <div className={styles.popupTitle}>{t("common:confirmationPopup.confirmVerifyAttendance")}</div>
                <span className={styles.smallTitle}>{t("common:confirmationPopup.smallTextVerifyAttendance")}</span>
                <div>
                    <div className={classNames(styles.actionDetailWrapPopup)}>
                        <div>
                            <SecondaryButton onClick={onCancelConfirm} title={t("common:action.cancelModal")} />
                        </div>
                        <div className={classNames(styles.buttonPopupConfirm)}>
                            <BaseButton onClick={onPressVerify} title={t("common:action.confirm")} loading={loading} />
                        </div>
                    </div>
                </div>
            </div>
        </BasePopup>
    )
}

export default withTranslation(["settings", "common"])(PopupVerifyClassActivities)
