import {get} from "lodash"
import {
    ParamCreateTransferCredits,
    ParamsGetListTransferCredits,
    ParamUpdateTransferCredits,
    ResponseListTransferCredits,
    TransferCredits
} from "types/transferCredits"
import BaseService from "./Base"

class TransferCreditsService extends BaseService {
    async listTransferCredits(params: ParamsGetListTransferCredits): Promise<ResponseListTransferCredits> {
        const url = "/v3/academics/transferCredits/list"
        const response = await this.post(url, params)
        return get(response, "data", {})
    }
    async deleteTransferCredits(ids: number[]): Promise<TransferCredits> {
        const url = "/v3/academics/transferCredits/delete"
        const response = await this.post(url, {transferCreditsIds: ids})
        return get(response, "data", {})
    }
    async detailTransferCredits(id: number): Promise<TransferCredits> {
        const url = `/v3/academics/transferCredits/get`
        const response = await this.post(url, {transferCreditsId: id})
        return get(response, "data", {})
    }
    async updateTransferCredits(id: number, params: ParamUpdateTransferCredits): Promise<TransferCredits> {
        const url = `/v3/academics/transferCredits/edit`
        const response = await this.post(url, {...params, transferCreditsId: id})
        return get(response, "data", {})
    }
    async createTransferCredits(params: ParamCreateTransferCredits): Promise<TransferCredits> {
        const url = "/v3/academics/transferCredits/create"
        const response = await this.post(url, params)
        return get(response, "data", {})
    }
}

export default TransferCreditsService
