import React from "react"
import styles from "./ScreenRightPanel.module.css"
import {ReactComponent as SideBarIcon} from "./rightPanel.svg"
import cx from "classnames"
import {StudentInfoSidebar} from "uiKit/StudentInfoSidebar"
import {StudentInfoPanelProps} from "uiKit/StudentInfoPanel"

export type ScreenRightPanelProps = {
    type: "student" | "application"
    data?: StudentInfoPanelProps
}

type Props = ScreenRightPanelProps & {
    isRightPanelOpen: boolean
    setIsRightPanelOpen: (isOpen: boolean) => void
}

export const ScreenRightPanel = ({type, data, isRightPanelOpen, setIsRightPanelOpen}: Props) => {
    const renderByType = () => {
        switch (type) {
            case "student":
                return (
                    <StudentInfoSidebar
                        data={data}
                        onClose={() => {
                            setIsRightPanelOpen(false)
                        }}
                    />
                )
            case "application":
                return <div>Application</div>
        }
    }

    return (
        <>
            <div
                className={cx(styles.rightPanelIconWrap, {[styles.hidden]: isRightPanelOpen})}
                onClick={() => setIsRightPanelOpen(true)}>
                <SideBarIcon className={styles.rightPanelIcon} width={17} height={17.9} />
            </div>
            <div className={cx(styles.expandedRightPanel, {[styles.hidden]: !isRightPanelOpen})}>{renderByType()}</div>
        </>
    )
}
