export const tableData = [
    {
        id: 1,
        title: "Payment Plan",
        invoice: "CD1242",
        amount: "$1200",
        paid: "$1200",
        balance: "$1200",
        dueDate: "22/12/2020",
        paidDate: "22/12/2020",
        status: "complete"
    },
    {
        id: 2,
        title: "Payment Plan",
        invoice: "CD1242",
        amount: "$1200",
        paid: "$1200",
        balance: "$1200",
        dueDate: "22/12/2020",
        paidDate: "22/12/2020",
        status: "complete"
    },
    {
        id: 3,
        title: "Payment Plan",
        invoice: "CD1242",
        amount: "$1200",
        paid: "$1200",
        balance: "$1200",
        dueDate: "22/12/2020",
        paidDate: "22/12/2020",
        status: "complete"
    },
    {
        id: 4,
        title: "Payment Plan",
        invoice: "CD1242",
        amount: "$1200",
        paid: "$1200",
        balance: "$1200",
        dueDate: "22/12/2020",
        paidDate: "22/12/2020",
        status: "complete"
    },
    {
        id: 1,
        title: "Payment Plan",
        invoice: "CD1242",
        amount: "$1200",
        paid: "$1200",
        balance: "$1200",
        dueDate: "22/12/2020",
        paidDate: "22/12/2020",
        status: "overdue"
    }
]
