import {useEffect, useRef, useState} from "react"

export default function useThrottle<T>(value: T, delay: number) {
    const [throttledValue, setThrottledValue] = useState<T>(value)
    const lastUpdate = useRef<number>(0)
    const lastValue = useRef(value)

    useEffect(() => {
        lastValue.current = value
        if (Date.now() - lastUpdate.current < delay) {
            return () => {}
        }
        lastUpdate.current = Date.now()
        setThrottledValue(value)
        const to = setTimeout(() => {
            setThrottledValue(lastValue.current)
        }, delay)
        return () => {
            clearTimeout(to)
        }
    }, [value, delay])

    return throttledValue
}
