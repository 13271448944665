/* eslint-disable react-hooks/exhaustive-deps */
import {useModel, useVisible} from "hooks"
import {UseVisibleArgs} from "hooks/useVisible"
import React, {createContext, useCallback, useState} from "react"
import {Auth} from "types/auth"
import {Communication} from "types/communication"
import {chatServiceV2} from "services"
import {handleErrorChat, toastInfo} from "helpers"
import {uniqBy} from "lodash"
import {convertRoom} from "helpers/chat"

export type RoomProps = {
    pageId: string
    pageProps: any
}

type ContextProps = {
    activeRoom: Communication.Room
    changeRoom: (room: Communication.Room, _roomProps?: RoomProps, overwrite?: boolean) => void
    extendedInfoVisible: UseVisibleArgs
    viewAs: Auth.Profile
    changeViewAs: (_viewAs?: Auth.Profile) => void
    activeGroup?: Communication.ItemGroup
    changeActiveGroup: (group?: Communication.ItemGroup, overwrite?: boolean) => void
    departmentalChats: Communication.DepartmentalChat[]
    departmentCampuses: Record<number, Communication.DepartmentCampus[]>
    loadDepartmentCampuses: (filter: {departmentId: number; search?: string}) => Promise<void>
    loadDepartmentCampusRooms: (
        filter: {
            departmentId: number
            campusId: number
            search?: string
            unread?: boolean
        },
        roomsPage: number
    ) => Promise<void>
    loadDepartmentalChats: (unread?: boolean) => void
    markDepartmentCampusAsRead: (departmentId: number, campusId: number, roomId: number) => void
    roomGroups: Communication.RoomGroup[]
    updateRoomGroup: (_roomGroup: Communication.RoomGroup, overwrite?: boolean) => void
    moveRoomToFirst: (roomGroupId: Communication.RoomGroupId, roomId: string) => void
    loadRoomList: (
        type: Communication.RoomTypes,
        options?: {unread?: boolean; page?: number; searchText?: string; overwrite?: boolean}
    ) => Promise<void>

    roomProps?: RoomProps
    updateLastState: (appendState: Communication.StorageLastState, overwrite?: boolean) => void
    updateRoomInfo: (roomId: string, key: string, value: any) => void
    removeRoom: (roomId: string) => void
    newRoom: (room: any) => void
    moveGroupOfRoom: (room: Communication.Room) => void

    fetchingSections: Record<string, boolean>
    unreadSections: Record<string, boolean>
    loadUnreadState: (_unreadSections: Record<string, boolean>) => void
    toggleUnreadSection: (sectionId: string, value: boolean) => void

    foundMessageId: number
    setFoundMessageId: (messageId: number) => void
}

export const CommunicationContext = createContext<ContextProps>({} as ContextProps)

type CommunicationProviderProps = {
    children: React.ReactNode
}

export const DEFAULT_ROOM: Communication.Room = {
    roomId: "default",
    name: "Default",
    type: Communication.RoomTypes.Default,
    unread: 0
}

export const SETTING_ROOM: Communication.Room = {
    roomId: "settings",
    name: "Settings",
    type: Communication.RoomTypes.Settings,
    unread: 0
}

export const CHAT_ROOM_PAGESIZE = 20

export const CommunicationProvider: any = ({children}: CommunicationProviderProps) => {
    const [activeRoom, setActiveRoom] = useState<Communication.Room>(DEFAULT_ROOM)
    const [activeGroup, setActiveGroup] = useState<Communication.ItemGroup | undefined>()
    const [viewAs, setViewAs] = useState<Auth.Profile | undefined>()
    const extendedInfoVisible = useVisible(false)
    const [roomGroups, setRoomGroups] = useState<Communication.RoomGroup[]>([])
    const [roomProps, setRoomProps] = useState<RoomProps | undefined>()
    const [departmentCampuses, setDepartmentCampuses] = useState<
        Record<number, Communication.DepartmentCampus[] | undefined>
    >({})
    const [departmentalChats, setDepartmentalChats] = useState<Communication.DepartmentalChat[]>([])
    const [fetchingSections, setFetchingSections] = useState<Record<string, boolean>>({})
    const [unreadSections, setUnreadSections] = useState<Record<string, boolean> | undefined>({})
    const [foundMessageId, setFoundMessageId] = useState<number | undefined>()
    const model = useModel()

    const updateLastState = useCallback(
        (appendState: Communication.StorageLastState, overwrite: boolean = true) => {
            if (overwrite) {
                model.updateStorageByKey(Communication.CHAT_STORAGE_KEY, appendState)
            }
        },
        [model]
    )

    const changeRoom = useCallback((room: Communication.Room, _roomProps?: RoomProps, overwrite?: boolean) => {
        extendedInfoVisible.close()
        const {roomIcon, ...rest} = room
        setActiveRoom(room)
        setRoomProps(_roomProps)
        if (
            ![
                Communication.RoomTypes.OpenedSupportHub,
                Communication.RoomTypes.ClosedSupportHub,
                Communication.RoomTypes.NewSupportHub
            ].includes(room.type)
        ) {
            updateLastState({activeRoom: rest, roomProps: _roomProps}, overwrite)
        }
    }, [])

    const toggleUnreadSection = useCallback((sectionId: string, value: boolean) => {
        setUnreadSections((prev) => {
            const updated = {...prev, [sectionId]: value}
            updateLastState({unreadSections: updated}, true)

            return updated
        })
    }, [])

    const changeViewAs = (_viewAs: Auth.Profile) => {
        setViewAs(_viewAs)
        changeRoom(DEFAULT_ROOM)
    }

    const loadDepartmentCampuses = useCallback(
        async (filter: {departmentId: number; search?: string}) => {
            setDepartmentCampuses((prev) => ({...prev, [filter.departmentId]: undefined}))
            const campuses = await chatServiceV2.getDepartmentCampuses({
                ...filter,
                roomsPageSize: CHAT_ROOM_PAGESIZE
            })
            campuses.forEach((campus) => {
                campus.totalRooms ||= campus.rooms.length
                campus.roomsPage = 1
                campus.roomsPageSize = CHAT_ROOM_PAGESIZE
            })
            for (var i = 0; i < campuses.length; i++) {
                const campus = campuses[i]
                const unread = unreadSections[`dept_${filter.departmentId}_campus_${campus.campusId}`] || false
                if (unread) {
                    try {
                        const {total: totalRooms, data: rooms} = await chatServiceV2.getDepartmentCampusRooms(
                            {
                                departmentId: filter.departmentId,
                                campusId: campus.campusId,
                                search: filter.search,
                                unread
                            },
                            {
                                page: 1,
                                pageSize: CHAT_ROOM_PAGESIZE
                            }
                        )
                        campuses[i].totalRooms = totalRooms
                        campuses[i].rooms = rooms
                    } catch (err) {
                        handleErrorChat(err)
                    }
                }
            }
            setDepartmentCampuses((prev) => ({...prev, [filter.departmentId]: campuses || []}))
        },
        [unreadSections]
    )

    const loadDepartmentCampusRooms = useCallback(
        async (
            filter: {departmentId: number; campusId: number; search?: string; unread?: boolean},
            roomsPage: number
        ) => {
            const campuses = departmentCampuses[filter.departmentId]
            if (!campuses) return
            const campus = campuses.find((campus) => campus.campusId === filter.campusId)
            if (!campus) return
            try {
                setFetchingSections((prev) => ({
                    ...prev,
                    [`dept_${filter.departmentId}_campus_${campus.campusId}`]: true
                }))
                const {total: totalRooms, data: rooms} = await chatServiceV2.getDepartmentCampusRooms(filter, {
                    page: roomsPage,
                    pageSize: campus.roomsPageSize
                })
                setDepartmentCampuses((prev) => ({
                    ...prev,
                    [filter.departmentId]: campuses.map((campus) =>
                        campus.campusId !== filter.campusId
                            ? campus
                            : {
                                  ...campus,
                                  totalRooms,
                                  roomsPage,
                                  rooms: roomsPage === 1 ? rooms : uniqBy([...campus.rooms, ...rooms], "roomId")
                              }
                    )
                }))
            } catch (err) {
                handleErrorChat(err)
            } finally {
                setFetchingSections((prev) => ({
                    ...prev,
                    [`dept_${filter.departmentId}_campus_${campus.campusId}`]: false
                }))
            }
        },
        [departmentCampuses]
    )

    const markDepartmentCampusAsRead = useCallback((departmentId: number, campusId: number, roomId: number) => {
        setDepartmentCampuses((prev) => ({
            ...prev,
            [departmentId]: prev[departmentId]?.map((campus) =>
                campus.campusId !== campusId
                    ? campus
                    : {
                          ...campus,
                          rooms: campus.rooms.map((room) => (room.roomId !== roomId ? room : {...room, unread: 0}))
                      }
            )
        }))
    }, [])

    const loadDepartmentalChats = useCallback(async (unread?: boolean) => {
        try {
            setFetchingSections((prev) => ({...prev, [Communication.RoomGroupId.Department]: true}))
            const {data} = await chatServiceV2.getSidebar(Communication.RoomTypes.Department, {filter: {unread}})
            const _departmentalChats = data.map((department) => ({
                ...department,
                campus: Array.isArray(department.campus) ? department.campus : []
            }))
            setDepartmentalChats(_departmentalChats)
        } catch (err) {
            handleErrorChat(err)
        } finally {
            setFetchingSections((prev) => ({...prev, [Communication.RoomGroupId.Department]: false}))
        }
    }, [])

    const loadRoomList = useCallback(
        async (
            roomType: Communication.RoomTypes,
            options?: {unread?: boolean; page?: number; searchText?: string; overwrite?: boolean}
        ) => {
            try {
                setFetchingSections((prev) => ({...prev, [roomType]: true}))
                const _page: number = options?.page || 1
                const filter: any = {}
                if (options?.unread) {
                    filter.unread = true
                }
                if (options?.searchText) {
                    filter.search = options.searchText
                }
                if (
                    [Communication.RoomTypes.OpenedSupportHub, Communication.RoomTypes.ClosedSupportHub].includes(
                        roomType
                    )
                ) {
                    filter.status =
                        roomType === Communication.RoomTypes.OpenedSupportHub
                            ? [Communication.SupportRoomStatus.Open]
                            : [Communication.SupportRoomStatus.Cancelled, Communication.SupportRoomStatus.Resolved]
                }
                let payload: any = {
                    filter,
                    range: {
                        pageSize: CHAT_ROOM_PAGESIZE,
                        page: _page
                    }
                }
                const {data, total} = await chatServiceV2.getSidebar(roomType, payload)
                let _roomGroup: Partial<Communication.RoomGroup> = {
                    roomType,
                    items: data.map((x) => {
                        return convertRoom(roomType, x)
                    }),
                    totalRooms: total,
                    roomsPage: _page
                }
                switch (roomType) {
                    case Communication.RoomTypes.DirectChat:
                        _roomGroup.id = Communication.RoomGroupId.Direct
                        _roomGroup.title = "Direct Chats"
                        break
                    case Communication.RoomTypes.Channel:
                        _roomGroup.id = Communication.RoomGroupId.Channel
                        _roomGroup.title = "Channels"
                        break
                    case Communication.RoomTypes.DirectMessage:
                        _roomGroup.id = Communication.RoomGroupId.DirectMessage
                        _roomGroup.title = "Sent Direct Messages"
                        break
                    case Communication.RoomTypes.OpenedSupportHub:
                        _roomGroup.id = Communication.RoomGroupId.OpenedSupportHub
                        _roomGroup.title = "Open support requests"
                        break
                    case Communication.RoomTypes.ClosedSupportHub:
                        _roomGroup.id = Communication.RoomGroupId.ClosedSupportHub
                        _roomGroup.title = "Closed support requests"
                        break
                    default:
                        break
                }

                updateRoomGroup(_roomGroup, options?.overwrite || false)
            } catch (err) {
                handleErrorChat(err)
            } finally {
                setFetchingSections((prev) => ({...prev, [roomType]: false}))
            }
        },
        []
    )

    const updateRoomGroup = (_roomGroup: Communication.RoomGroup, overwrite?: boolean) => {
        setRoomGroups((prev) => {
            const _roomGroups = [...prev]
            const idx = _roomGroups.findIndex((x) => x.id === _roomGroup.id)
            if (idx >= 0) {
                if (overwrite) {
                    _roomGroups[idx] = _roomGroup
                } else {
                    const items = uniqBy(_roomGroups[idx].items.concat(_roomGroup.items), "roomId")
                    _roomGroups[idx] = {
                        ..._roomGroups[idx],
                        ..._roomGroup,
                        items: items
                    }
                }
            } else {
                _roomGroups.push(_roomGroup)
            }
            return _roomGroups
        })
    }

    const moveRoomToFirst = (roomGroupId: Communication.RoomGroupId, roomId: string) => {
        setRoomGroups((prev) => {
            const _roomGroups = [...prev]
            const idx = _roomGroups.findIndex((x) => x.id === roomGroupId)
            if (idx >= 0) {
                let _roomGroup = _roomGroups[idx]
                const idxRoom = (_roomGroup.items || []).findIndex((x) => x.roomId === roomId)
                if (idxRoom >= 0) {
                    const updatedRoom = _roomGroup.items[idxRoom]
                    _roomGroup.items.splice(idxRoom, 1)
                    _roomGroup = {
                        ..._roomGroup,
                        items: [updatedRoom, ..._roomGroup.items]
                    }
                    _roomGroups[idx] = _roomGroup
                }
            }
            return _roomGroups
        })
    }

    const moveGroupOfRoom = (room: Communication.Room) => {
        setRoomGroups((prev) => {
            let _roomGroups = [...prev]

            const allRooms = _roomGroups.reduce((acc, x) => [...acc, ...x.items], [])
            const oldRoom: Communication.GeneralRoom = allRooms.find((x) => x.roomId === room.roomId)
            if (oldRoom) {
                let oldRoomGroupIdx = _roomGroups.findIndex((x) => x.roomType === oldRoom.type)
                if (oldRoomGroupIdx >= 0) {
                    let oldRoomGroup = _roomGroups[oldRoomGroupIdx]
                    const idxRoom = (oldRoomGroup.items || []).findIndex((x) => x.roomId === room.roomId)
                    if (idxRoom >= 0) {
                        oldRoomGroup.items.splice(idxRoom, 1)
                        oldRoomGroup.totalRooms = (oldRoomGroup.totalRooms || 1) - 1
                        _roomGroups[oldRoomGroupIdx] = oldRoomGroup
                    }
                }

                const changeToGroupIdx = _roomGroups.findIndex((x) => x.roomType === room.type)
                if (changeToGroupIdx >= 0) {
                    let _roomGroup = _roomGroups[changeToGroupIdx]
                    _roomGroup.items = [room, ..._roomGroup.items]
                    _roomGroup.totalRooms = (_roomGroup.totalRooms || 0) + 1
                    _roomGroups[changeToGroupIdx] = _roomGroup
                }
            }

            return _roomGroups
        })
    }

    const changeActiveGroup = useCallback((group?: Communication.ItemGroup, overwrite?: boolean) => {
        setActiveGroup(group)
        if (group) {
            const {icon, ...rest} = group
            updateLastState({activeGroup: rest, activeRoom: undefined, roomProps: undefined}, overwrite)
        } else {
            updateLastState({activeGroup: undefined}, overwrite)
        }
    }, [])

    const updateRoomInfo = useCallback(
        async (roomId: string, key: string, value: any) => {
            const _rooms = roomGroups.reduce((acc, x) => [...acc, ...x.items], [])
            const _room: Communication.GeneralRoom = _rooms.find((x) => x.roomId === roomId)
            if (_room) {
                // this is direct/chanel room
                let _roomGroup = roomGroups.find((x) => x.roomType === _room.type)
                if (_roomGroup) {
                    const idx = _roomGroup.items.findIndex((x) => x.roomId === _room.roomId)
                    if (idx >= 0) {
                        const roomUpdated = {
                            ..._room,
                            [key]: value
                        }
                        _roomGroup.items[idx] = roomUpdated
                        updateRoomGroup(_roomGroup, true)
                        if (activeRoom.roomId === roomId && key !== "unread") {
                            setActiveRoom(roomUpdated)
                        }
                    }
                }
            } else {
                const foundRoom = await chatServiceV2.getRoom({
                    roomId: parseInt(roomId)
                })
                if (foundRoom.type !== Communication.RoomTypes.Department) return
                // this is departmental room
                const departmentalRoom = foundRoom as Communication.DepartmentRoom
                if (activeGroup?.id) {
                    // in rendering department rooms
                    let dCampuses = {...departmentCampuses}
                    const foundDepartment = departmentCampuses[`${departmentalRoom.departmentId}`]
                    if (foundDepartment) {
                        const foundCampusIdx = foundDepartment.findIndex(
                            (x) => x.campusId === departmentalRoom.campusId
                        )
                        if (foundCampusIdx >= 0) {
                            const foundRoomIdx = foundDepartment[foundCampusIdx].rooms?.findIndex(
                                (x) => x.roomId === departmentalRoom.roomId
                            )
                            if (foundRoomIdx >= 0) {
                                dCampuses[`${departmentalRoom.departmentId}`][foundCampusIdx].rooms[foundRoomIdx] = {
                                    ...departmentalRoom,
                                    [key]: value
                                }
                                setDepartmentCampuses(dCampuses)
                                if (activeRoom.roomId === roomId && key !== "unread") {
                                    setActiveRoom(departmentalRoom)
                                }
                            }
                        }
                    }
                } else {
                    // in rendering main sidebar or student rooms
                    let dChats = [...departmentalChats]
                    const foundDepartmentIdx = departmentalChats.findIndex(
                        (x) => x.departmentId === departmentalRoom.departmentId
                    )
                    if (foundDepartmentIdx >= 0) {
                        const foundCampusIdx = Array.isArray(departmentalChats[foundDepartmentIdx].campus)
                            ? departmentalChats[foundDepartmentIdx].campus.findIndex(
                                  (x) => x.campusId === departmentalRoom.campusId
                              )
                            : undefined
                        if (foundCampusIdx >= 0) {
                            dChats[foundDepartmentIdx].campus[foundCampusIdx][key] = value
                            setDepartmentalChats(dChats)
                        }
                    }
                }
            }
        },
        [roomGroups, activeRoom, activeGroup, departmentalChats, departmentCampuses]
    )

    const removeRoom = useCallback(
        (roomId: string) => {
            const _rooms = roomGroups.reduce((acc, x) => [...acc, ...x.items], [])
            const _room: Communication.GeneralRoom = _rooms.find((x) => x.roomId === roomId)
            if (_room) {
                let _roomGroup = roomGroups.find((x) => x.roomType === _room.type)
                if (_roomGroup) {
                    const idx = _roomGroup.items.findIndex((x) => x.roomId === _room.roomId)
                    if (idx >= 0) {
                        _roomGroup.items.splice(idx, 1)
                        updateRoomGroup(_roomGroup, true)
                        if (activeRoom?.roomId === roomId) {
                            toastInfo("You have been removed from a chat")
                            setActiveRoom(DEFAULT_ROOM)
                        }
                    }
                }
            }
        },
        [roomGroups, activeRoom]
    )

    const newRoom = useCallback(
        (room: any) => {
            const roomType = ["group", "private"].includes(room.type) ? Communication.RoomTypes.DirectChat : room.type
            let _roomGroup = roomGroups.find((x) => x.roomType === roomType)
            if (_roomGroup) {
                const existed = _roomGroup.items.find((x) => x.roomId === room.roomId)
                if (!existed) {
                    _roomGroup.items = [convertRoom(roomType, room), ..._roomGroup.items]
                    updateRoomGroup(_roomGroup, true)
                }
            }
        },
        [roomGroups]
    )

    return (
        <CommunicationContext.Provider
            value={{
                activeRoom,
                changeRoom,
                extendedInfoVisible,
                viewAs,
                changeViewAs,
                activeGroup,
                changeActiveGroup,
                departmentalChats,
                departmentCampuses,
                loadDepartmentCampuses,
                loadDepartmentCampusRooms,
                loadDepartmentalChats,
                markDepartmentCampusAsRead,
                roomGroups,
                updateRoomGroup,
                moveRoomToFirst,
                fetchingSections,
                loadRoomList,
                roomProps,
                updateLastState,
                updateRoomInfo,
                removeRoom,
                newRoom,
                moveGroupOfRoom,
                unreadSections,
                loadUnreadState: setUnreadSections,
                toggleUnreadSection,
                foundMessageId,
                setFoundMessageId
            }}>
            {children}
        </CommunicationContext.Provider>
    )
}
