/* eslint-disable react-hooks/exhaustive-deps */
import debounce from "debounce-promise"
import React from "react"
import {useTranslation} from "react-i18next"
import {departmentsService} from "services"
import {Department} from "types/departments"
import {KlassDropdownAsync, KlassDropdownAsyncProps} from "../Select"

type Props = Partial<KlassDropdownAsyncProps> & {}

const DepartmentForChatSelect: React.FC<Props> = ({...props}) => {
    const {t} = useTranslation(["common"])

    const searchDepartments = React.useCallback(
        debounce(async () => {
            const {data} = await departmentsService.getAllForChat()

            return data
        }, 500),
        []
    )

    return (
        <KlassDropdownAsync
            {...props}
            placeholder={props.placeholder || t("selectField.placeholder")}
            loadOptions={searchDepartments}
            defaultOptions={true}
            valueKey="departmentId"
            labelKey="name"
            getOptionLabel={(option: Department) => option.name}
        />
    )
}

export default DepartmentForChatSelect
