import React, {useState} from "react"
import {Row, Col} from "antd"
import {KlassDropdown} from "components/Select"
import {BaseDatePicker} from "components/DateTimePicker"
import {BaseNewFilter} from "uiKit"
import {useTranslation} from "react-i18next"
import {typesMaster} from "../FinAidNoticeDetail"
import styles from "./FinAidNoticeFilter.module.css"

type Filter = {
    type?: string
    dateReceived?: string
}

type Props = {
    updateFilter
    onChangeFilter
    onClearFilter
    filterObject?: Filter
    setFilterFormSearch
}

const initState: Filter = {
    type: "",
    dateReceived: ""
}

export function FinAidNoticeFilter(props: Props) {
    const {updateFilter, onChangeFilter, onClearFilter, filterObject, setFilterFormSearch} = props
    const {t} = useTranslation(["common"])

    const [inputFields, setInputFields] = useState(initState)
    const [formSearch, setFormSearch] = useState("")

    const onClickClear = async () => {
        setInputFields({...initState})
        onClearFilter()
    }

    const onSearchInput = (search) => {
        setFormSearch(search)
        setFilterFormSearch(search)
    }

    return (
        <BaseNewFilter
            className={styles.filterContainer}
            searchValue={formSearch}
            onSearchInput={onSearchInput}
            onClick={onChangeFilter}
            onClickClear={onClickClear}
            filter={filterObject}>
            <Row gutter={[40, 32]}>
                <Col span={12}>
                    <KlassDropdown
                        options={typesMaster}
                        value={inputFields.type}
                        onChange={(selectedValues) => {
                            setInputFields((prevState) => ({...prevState, type: selectedValues}))
                            updateFilter({type: selectedValues.id})
                        }}
                        placeholder={t("isir.type")}
                    />
                </Col>
                <Col span={12}>
                    <BaseDatePicker
                        placeholder={t("isir.dateReceived")}
                        format="YYYY-MM-DD"
                        value={inputFields.dateReceived}
                        onChange={(date, dateString) => {
                            setInputFields((prevState) => ({...prevState, dateReceived: date}))
                            updateFilter({createdAt: dateString})
                        }}
                    />
                </Col>
            </Row>
        </BaseNewFilter>
    )
}
