import React from "react"
import {MissedAttendanceSection} from "./../MissedAttendanceSection"
import {Course} from "types/courses"

type Props = {
    courseId?: number
    termIds?: number[]
    scheduleId?: number
    studentProfileId?: number
    attendanceTrackingType?: Course.ProgramAttendanceTrackingType
}

export function MissedAttendance(props: Props) {
    const {studentProfileId, attendanceTrackingType, scheduleId} = props
    return (
        <MissedAttendanceSection
            scheduleId={scheduleId}
            studentProfileId={studentProfileId}
            title="classroom"
            attendanceTrackingType={attendanceTrackingType}
            termIds={props.termIds}
            courseId={props.courseId}
        />
    )
}
