import React, {useCallback, useEffect, useState} from "react"
import moment from "moment-timezone"
import {BasePopup} from "components/popup"
import {BaseButton} from "components/buttons"
import {BaseTimePicker} from "components/DateTimePicker"
import {FormLabel} from "components/Form"
import {Icon} from "components/Icon"
import {convertTZ, handleError, toastWarning} from "helpers"
import {attendanceService} from "services"
import {useModel} from "hooks"
import {AcademicInstructional} from "types/academicInstructional"
import styles from "./EndLessonPopup.module.css"
import {StudentInfo} from "../Common"
import {cloneDeep, get} from "lodash"

type Props = {
    isShow: boolean
    students: AcademicInstructional.LessonStudent[]
    lessonId: number
    timezone: string
    lessonDetail: AcademicInstructional.LessonDetail
    getListStudents: () => void
    onClose: () => void
    onEndLessonSuccess: () => void
}

export function EndLessonPopup(props: Props) {
    const {isShow, lessonId, students, timezone, onEndLessonSuccess, onClose, getListStudents, lessonDetail} = props
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [listStudent, setListStudent] = useState(cloneDeep(students))
    const [checkedStudentIds, setCheckedStudentIds] = useState([])
    const [loading, setLoading] = useState(false)
    const [endTime, setEndTime] = useState(moment(convertTZ(new Date(lessonDetail.endsAt), timezone)))
    const model = useModel()
    const timezoneData = moment.tz(timezone).utcOffset()
    const offset = timezoneData / 60
    const timeFormat = model.getUserTimeFormat()

    useEffect(() => {
        if (lessonDetail) {
            setEndTime(moment(convertTZ(new Date(lessonDetail.endsAt), timezone)))
        }
    }, [lessonDetail, timezone])

    const onlineAttendanceCheck = useCallback(async () => {
        try {
            setLoading(true)
            const response = await attendanceService.checkOnlineAttendanceStatus({
                lessonId,
                forceEventType: "check-out"
            })
            const errorMessage = get(response, ["error", "details", "message"])
            if (errorMessage) {
                toastWarning(errorMessage)
            }
        } catch (err) {
            handleError(err)
        } finally {
            getListStudents()
            setLoading(false)
        }
    }, [lessonId, getListStudents])

    const onSelectAll = useCallback(() => {
        if (students.length === checkedStudentIds.length) {
            setCheckedStudentIds([])
        } else {
            const newCheckedStudentIds = students.map((item) => {
                return item.student.userId
            })
            setCheckedStudentIds(newCheckedStudentIds)
        }
    }, [checkedStudentIds, students])

    const onToggleCheck = useCallback(
        (userId) => {
            if (checkedStudentIds.includes(userId)) {
                const newCheckedStudentIds = checkedStudentIds.filter((item) => item !== userId)
                setCheckedStudentIds(newCheckedStudentIds)
            } else {
                const newCheckedStudentIds = [...checkedStudentIds]
                newCheckedStudentIds.push(userId)
                setCheckedStudentIds(newCheckedStudentIds)
            }
        },
        [checkedStudentIds]
    )

    const onEndLesson = async () => {
        try {
            setIsSubmitting(true)
            const params: {lessonId: number; students?: {profileId: number; status: string; createdAt: string}[]} = {
                lessonId
            }
            const createdAt = moment(endTime).tz(timezone, true).utc().format()
            params.students = []
            for (let i = 0; i < listStudent.length; i++) {
                const student = listStudent[i]
                if (checkedStudentIds.includes(student.student.userId)) {
                    params.students.push({profileId: student.student.profileId, status: "success", createdAt})
                } else {
                    params.students.push({profileId: student.student.profileId, status: "failure", createdAt})
                }
            }
            await attendanceService.endLesson(params)
            await attendanceService.updateLessonStatus({
                lessonId: lessonId,
                actualEndedAt: createdAt,
                status: AcademicInstructional.LessonStatus.Ended
            })
            onEndLessonSuccess()
        } catch (error) {
            handleError(error)
        } finally {
            getListStudents()
            setIsSubmitting(false)
        }
    }

    useEffect(() => {
        setListStudent(cloneDeep(students))
        if (students?.length) {
            const preselectStudentIds = []
            for (let i = 0; i < students.length; i++) {
                const events = students[i].events
                const isExistCheckout = events.find((item) => item.eventType === "check-out")
                if (isExistCheckout) {
                    preselectStudentIds.push(students[i].student.userId)
                }
            }
            setCheckedStudentIds(preselectStudentIds)
        }
    }, [students])

    return (
        <BasePopup isShow={isShow} onClose={onClose} width="50vw" isShowLeftSide={false}>
            <div className={styles.wrap}>
                <div className={styles.iconWrap}>
                    <Icon icon="ALERT" className={styles.icon} />
                </div>
                <p className={styles.content}>Before ending the lesson let's do a final attendance check</p>
                <BaseButton
                    loading={loading}
                    title="Final Online Attendance Check"
                    variant="secondary"
                    onClick={onlineAttendanceCheck}
                />
                <div className={styles.formItem}>
                    <FormLabel label="End time" />
                    <div className={styles.selectTime}>
                        <BaseTimePicker
                            value={endTime}
                            onChange={setEndTime}
                            placeholder="Select time"
                            className={styles.selectTime}
                            format={timeFormat}
                        />
                        <div className={styles.zone}>
                            {moment.tz(timezone).format("z")}{" "}
                            {`UTC${offset > 0 ? `+${Math.abs(offset)}` : `-${Math.abs(offset)}`}`}
                        </div>
                    </div>
                </div>
                <div className={styles.listStudents}>
                    <div className={styles.listStudentHeader}>
                        <div className={styles.totalStudent}>
                            <span className={styles.totalStudent__count}>{students.length}</span>
                            <span className={styles.totalStudent__title}>Students</span>
                        </div>
                        <div onClick={onSelectAll} className={styles.rightHeader}>
                            <span className={styles.rightHeader__title}>{checkedStudentIds.length} selected</span>
                            <Icon icon="SELECT_ALL" />
                        </div>
                    </div>
                    <div className={styles.studentsBody}>
                        {listStudent.map((student) => (
                            <StudentInfo
                                student={student}
                                disabled={!!student.disabled}
                                key={student.student.userId}
                                checkedStudentIds={checkedStudentIds}
                                onToggleCheck={onToggleCheck}
                            />
                        ))}
                    </div>
                </div>
                <div className={styles.action}>
                    <BaseButton title="Cancel" variant="secondary" onClick={onClose} />
                    <BaseButton title="End" onClick={onEndLesson} loading={isSubmitting} />
                </div>
            </div>
        </BasePopup>
    )
}
