import {Timestamps} from "../common"
import {Auth} from "../auth"

export enum TemplateType {
    Email = "email",
    SMS = "sms"
}

export enum EditorType {
    Text = "text",
    RichText = "rich_text",
    Html = "html"
}

export type EmailTemplate = {
    emailTemplateId: number
    code: string
    title: string
    description: string
    template: string
    templateType: TemplateType
    editorType?: EditorType
    subject: string | null
    createdByProfileId: number
    updatedByProfileId: number
} & Timestamps &
    Partial<{
        createdByProfile: Auth.UserProfileShortDetails
        updatedByProfile: Auth.UserProfileShortDetails
    }>
