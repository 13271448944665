import BaseService from "./Base"
import {ApiResponse} from "types/api"
import {AxiosResponse} from "axios"

export default class NotificationService extends BaseService {
    send = async (payload: any) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/notification/send", payload)
        if (!response.success) {
            throw response
        }
    }
}
