import {get} from "lodash"
import BaseService from "./Base"

class SettingExternshipSiteService extends BaseService {
    async getAllExternshipSites(params = {}) {
        const response = await this.post("/v1/settings/externship-sites/list", params)
        return get(response, "data", [])
    }

    async createExternshipSite(params: any = {}) {
        const response = await this.post("/v1/settings/externship-sites/create", params)
        return get(response, "data.data", {})
    }

    async duplicateExternshipSite(externshipSites) {
        const response = await this.post(`/v1/settings/externship-sites/create`, externshipSites)
        return get(response, "data", {})
    }

    async updateExternshipSite(id, data, params = {}) {
        data.id = id
        const response = await this.post(`/v1/settings/externship-sites/edit`, data, params)
        return get(response, "data", {})
    }

    async deleteExternshipSite(ids) {
        const response = await this.post(`/v1/settings/externship-sites/delete`, {ids})
        return get(response, "data", {})
    }

    async getAllExternshipCoordinators(params = {}) {
        const response = await this.post("/v1/settings/externship-sites/coordinators/list", params)
        return get(response, "data", [])
    }
}

export default SettingExternshipSiteService
