import {Model} from "Model"
import React, {createContext} from "react"

export const ModelContext = createContext<any>({})

type ModelProviderProps = {
    children: React.ReactNode
    model: Model
}

export const ModelProvider: any = ({children, model}: ModelProviderProps) => {
    return <ModelContext.Provider value={model}>{children}</ModelContext.Provider>
}
