import {get} from "lodash"
import BaseService from "./Base"

class SettingChecklistService extends BaseService {
    async getAllChecklists(params = {}) {
        const response = await this.post("/v1/settings/checklists/list", params)
        return get(response, "data", [])
    }

    async getChecklist(checklistId) {
        const params = {filter: {checklistIds: [checklistId]}, linkedEntities: true}
        const response = await this.post("/v1/settings/checklists/list", params)
        return get(response, "data.data[0]", null)
    }

    async getChecklistMaxId(params = {}) {
        const response = await this.post("/v1/settings/checklists/get-max-id", params)
        return get(response, "data.data", null)
    }

    async createChecklist(params = {}) {
        const response = await this.post("/v1/settings/checklists/create", params)
        return get(response, "data.data", {})
    }

    async duplicateChecklist(data) {
        const response = await this.post(`/v1/settings/checklists/create`, data)
        return get(response, "data", {})
    }

    async updateChecklist(id, data, params = {}) {
        data.checklistId = id
        const response = await this.post(`/v1/settings/checklists/edit`, data, params)
        return get(response, "data", {})
    }

    async deleteChecklist(checklistIds) {
        const response = await this.post(`/v1/settings/checklists/delete`, {checklistIds})
        return get(response, "data", {})
    }

    async getAllChecklistItemNames(params) {
        const response = await this.post("/v1/settings/checklist-item-names/list", params)
        return get(response, "data", [])
    }

    async getAllChecklistItems(params) {
        const response = await this.post("/v1/settings/checklist-items/list", params)
        return get(response, "data", [])
    }

    async getMaxChecklistItemPosition(params) {
        const response = await this.post("/v1/settings/checklist-items/max-position", params)
        return get(response, "data", [])
    }

    async createChecklistItem(data = {}) {
        const response = await this.post("/v1/settings/checklist-items/create", data)
        return get(response, "data.data", {})
    }

    async duplicateChecklistItem(checklists) {
        const response = await this.post(`/v1/settings/checklist-items/create`, checklists)
        return get(response, "data", {})
    }

    async updateChecklistItem(id, data, params = {}) {
        data.checklistItemId = id
        const response = await this.post(`/v1/settings/checklist-items/edit`, data, params)
        return get(response, "data", {})
    }

    async deleteChecklistItem(checklistItemIds) {
        const response = await this.post(`/v1/settings/checklist-items/delete`, {checklistItemIds})
        return get(response, "data", {})
    }
}

export default SettingChecklistService
