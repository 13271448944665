import React, {useCallback} from "react"
import {Menu, Dropdown} from "antd"
import {useTranslation} from "react-i18next"
import {store} from "helpers"
import {CountryFlag} from "./CountryFlag"
import styles from "./Language.module.css"

export function Language(props) {
    const locale = store.get("locale") || "en"
    const [language, setLanguage] = React.useState(locale)

    /* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
    const [t, i18n] = useTranslation("common")

    const loadLocale = useCallback(() => {
        setLanguage(locale)
        i18n.changeLanguage(locale)
    }, [i18n, locale])

    React.useEffect(() => {
        loadLocale()
    }, [loadLocale])

    const onChangeLanguage = (lang) => {
        setLanguage(lang)
        i18n.changeLanguage(lang)
        store.set("locale", lang)
    }

    const menu = (
        <Menu className={styles.menuActionWrap}>
            <Menu.Item key="en" className={styles.menuActionItem} onClick={() => onChangeLanguage("en")}>
                <span className={styles.menuActionTitle}>
                    <CountryFlag country="USA" className={styles.flag} />
                </span>
            </Menu.Item>
            <Menu.Item key="de" className={styles.menuActionItem} onClick={() => onChangeLanguage("de")}>
                <span className={styles.menuActionTitle}>
                    <CountryFlag country="SPAIN" className={styles.flag} />
                </span>
            </Menu.Item>
        </Menu>
    )

    return (
        <Dropdown overlay={menu} trigger={["click"]}>
            <div>
                {language === "en" ? (
                    <CountryFlag country="USA" className={styles.flag} />
                ) : (
                    <CountryFlag country="SPAIN" className={styles.flag} />
                )}
            </div>
        </Dropdown>
    )
}
