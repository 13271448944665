import React from "react"
import {useTranslation} from "react-i18next"
import {useActiveTab} from "hooks"
import {Tabs} from "antd"
import {TabHeader} from "components/Tab"
import StudentBillings from "./parts/StudentBillings"
import {StudentBillingStatus} from "types/student-account/student-billing"

const {TabPane} = Tabs

export enum BillingTab {
    Billings = "billings",
    Completed = "completed"
}

const activeStatuses = [StudentBillingStatus.Active]
const completedStatuses = [StudentBillingStatus.Completed]

function BillingsTab() {
    const {t} = useTranslation(["studentAccount", "common"])
    const [activeSubTab, setActiveSubTab] = useActiveTab(BillingTab.Billings, "subtab")

    return (
        <div>
            <Tabs className="fullwidth" activeKey={activeSubTab} onChange={setActiveSubTab}>
                <TabPane tab={<TabHeader title={t("billings.billings")} />} key={BillingTab.Billings}>
                    {activeSubTab === BillingTab.Billings && <StudentBillings status={activeStatuses} />}
                </TabPane>
                <TabPane tab={<TabHeader title={t("billings.completed")} />} key={BillingTab.Completed}>
                    {activeSubTab === BillingTab.Completed && <StudentBillings status={completedStatuses} />}
                </TabPane>
            </Tabs>
        </div>
    )
}

export default BillingsTab
