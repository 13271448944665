import {get} from "lodash"
import {Auth} from "types/auth"
import BaseService from "./Base"

export default class FinAidReportsService extends BaseService {
    public async getDemographicsReport(params = {}) {
        const {data: response} = await this.post("/v1/fin-aid-reports/demographics-report/list", params)
        return response
    }

    public async getEnrollmentReport(params = {}) {
        const {data: response} = await this.post("/v1/fin-aid-reports/enrollment-report/list", params)
        return response
    }

    public async getDropAddSBLReport(params = {}) {
        const {data: response} = await this.post("/v1/fin-aid-reports/drop-add-sbl-report/list", params)
        return response
    }

    public async getCensusReport(params = {}) {
        const {data: response} = await this.post("/v1/fin-aid-reports/census-report/list", params)
        return response
    }

    public async getTermCloseoutReport(params = {}) {
        const {data: response} = await this.post("/v1/fin-aid-reports/term-closeout-report/list", params)
        return response
    }
}
