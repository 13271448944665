import React, {Component} from "react"
import styles from "./Search.module.css"
import {ReactComponent as Icon} from "./parts/icons/search.svg"
import {CloseButton} from "./parts/CloseButton"
import cx from "classnames"

export type SearchProps = {
    className?: string
    placeholder?: string
    disabled?: boolean
    value: string
    onChange: (value: string) => void
    onPressEnter?: () => void
}

export class Search extends Component<SearchProps, {}> {
    private onChange(event) {
        this.props.onChange(event.target.value)
    }

    onKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey && this.props.onPressEnter) {
            event.preventDefault()
            this.props.onPressEnter()
        }
    }

    public render() {
        const {placeholder, disabled, value, className, onChange} = this.props
        return (
            <div className={cx(styles.root, className)}>
                <input
                    className={styles.input}
                    type="text"
                    value={value}
                    onChange={this.onChange.bind(this)}
                    placeholder={placeholder}
                    disabled={disabled}
                    onKeyDown={this.onKeyDown}
                />
                <div className={styles.iconWrapper}>
                    <Icon className={styles.icon} width={18} height={18} />
                </div>

                {value.length > 0 && (
                    <div className={styles.clearButton}>
                        <CloseButton
                            onClick={() => {
                                onChange("")
                            }}
                        />
                    </div>
                )}
            </div>
        )
    }
}
