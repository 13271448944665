/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useContext, useMemo, useRef} from "react"
import {displaySince, handleErrorChat} from "helpers"
import {chatServiceV2} from "services"
import styles from "./PopupPinnedMessages.module.css"
import {BaseButton, BasePopup} from "components"
import {Communication} from "types/communication"
import {CommunicationContext} from "context/CommunicationContext"
import {useInfiniteQuery} from "@tanstack/react-query"
import {Spin} from "antd"
import {UserAvatar} from "../UserAvatar"
import {useModel} from "hooks"

type Props = {
    roomId: number | string
    visible: boolean
    onClose: () => void
    onClickPinnedMesage: (message: Communication.Message) => void
}

export const PopupPinnedMessages = (props: Props) => {
    const {roomId, onClose, visible, onClickPinnedMesage} = props
    const model = useModel()
    const userDateTimeFormat = model.getUserDateTimeFormat()

    const fetchData = async ({pageParam = 1}) => {
        if (roomId) {
            const {data, total} = await chatServiceV2.getPinned({
                roomId: roomId,
                range: {pageSize: 10, page: pageParam}
            })
            return {results: data, nextPage: pageParam + 1, totalPages: Math.ceil(total / 10)}
        }
    }

    const {data, hasNextPage, fetchNextPage, isFetchingNextPage, isFetching} = useInfiniteQuery(
        ["room-pinned-messages", roomId, visible],
        fetchData,
        {
            getNextPageParam: (lastPage, pages) => {
                if (lastPage.nextPage <= lastPage.totalPages) return lastPage.nextPage
                return undefined
            },
            onError: (err) => {
                handleErrorChat(err)
            },
            enabled: !!roomId
        }
    )

    const isEmpty = useMemo(() => {
        if (data && data.pages && data.pages.length > 0 && data.pages[0].totalPages > 0) {
            return false
        }
        return true
    }, [data])

    const intObserver = useRef<any>()
    const lastPostRef = useCallback(
        (item) => {
            if (isFetchingNextPage) return
            if (intObserver.current) intObserver.current.disconnect()

            intObserver.current = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting && hasNextPage) {
                        fetchNextPage()
                    }
                },
                {
                    threshold: 0.25,
                    root: document.querySelector(`main`),
                    rootMargin: "150px"
                }
            )

            if (item) intObserver.current.observe(item)
        },
        [isFetchingNextPage, fetchNextPage, hasNextPage]
    )

    const renderItems = () => {
        return (
            <div className={styles.main} id={`main`}>
                {data?.pages.map((page) =>
                    page.results.map((x, idx) => {
                        return (
                            <div
                                className={styles.pinned}
                                key={x.messageId}
                                ref={page.results.length === idx + 1 ? lastPostRef : null}
                                onClick={() => onClickPinnedMesage(x)}>
                                <div className={styles.messageContainer}>
                                    <div className={styles.infoContainer}>
                                        <UserAvatar user={x.author} className={styles.avatar} />
                                        <span className={styles.fullName}>{x.author.fullName}</span>
                                        <div className={styles.dot} />
                                        <span className={styles.timestamp}>
                                            {displaySince(new Date(x.createdAt), userDateTimeFormat)}
                                        </span>
                                    </div>
                                    <div
                                        className={styles.pinnedContent}
                                        dangerouslySetInnerHTML={{__html: x.content}}
                                    />
                                    {x.content === "" && <span className={styles.attachContent}>Attachment</span>}
                                </div>
                            </div>
                        )
                    })
                )}
                {isFetching && <Spin className={styles.loadingData} />}
                {!isFetching && isEmpty && <div className={styles.emptyList}>No record found</div>}
            </div>
        )
    }

    return (
        <BasePopup isShow={visible} onClose={onClose} leftIcon="PUSH_PIN" leftIconColor="#fff" width="700px">
            <div className={styles.title}>Pinned messages</div>
            {renderItems()}
            <div className={styles.actionWrap}>
                <BaseButton title="Cancel" variant="secondary" onClick={onClose} />
            </div>
        </BasePopup>
    )
}
