/* eslint-disable react-hooks/exhaustive-deps */
import React, {useReducer, useEffect} from "react"
import {useEdularModulesContext, Modules} from "@edular/modules"
import {ApplicationProgress, Checklist} from "sections/shared/student"
import {handleError} from "helpers"
import {studentService} from "services"
import {useActiveTab} from "hooks"
import {Tabs} from "antd"
import {useTranslation} from "react-i18next"
import ActivitiesTable from "sections/shared/student/ActivitiesTable/ActivitiesTable"
import WorkflowTriggersTable from "sections/Workflow/WorkflowTriggersTable"
import {TabHeader} from "components/Tab"

const {TabPane} = Tabs

function reducer(state, action) {
    return {...state, ...action}
}

export enum FinAidStudentFinAidSubtab {
    Activities = "activities",
    WorkflowTriggers = "workflow-triggers",
    Checklist = "checklist"
}

const FinancialAidStudentTab = (props) => {
    const {studentId, departmentId} = props
    const {t} = useTranslation(["user", "common"])
    const initialState = {
        activeTabKey: "1",
        stageId: 0,
        currentStageId: 0,
        student: {profiles: [{}]}
    }
    const [{stageId, currentStageId}, dispatch] = useReducer(reducer, initialState)
    const [activeTabKey, onChangeTab] = useActiveTab(FinAidStudentFinAidSubtab.Activities, "subtab")
    const {isModuleEnable} = useEdularModulesContext()

    async function loadStudent(studentProfileId: number) {
        try {
            const {
                data: [student]
            } = await studentService.getDepartmentStudents({filter: {profileIds: [studentProfileId], departmentId}})
            dispatch({student})
        } catch (error) {
            handleError(error)
        }
    }

    useEffect(() => {
        loadStudent(studentId)
    }, [])

    return (
        <div>
            <ApplicationProgress
                departmentId={departmentId}
                studentId={studentId}
                stageId={stageId}
                currentStageId={currentStageId}
                dispatch={dispatch}
            />
            <Tabs className="fullwidth klassSubTab" activeKey={activeTabKey} onChange={onChangeTab}>
                {isModuleEnable(Modules.ActivitiesAndFollowups) && (
                    <TabPane
                        tab={<TabHeader title={t("user.activities")} />}
                        key={FinAidStudentFinAidSubtab.Activities}>
                        <ActivitiesTable
                            studentId={studentId}
                            departmentId={departmentId}
                            // includesHoldActivities={true}
                        />
                    </TabPane>
                )}
                <TabPane
                    tab={<TabHeader title={t("user.workflowTriggers")} />}
                    key={FinAidStudentFinAidSubtab.WorkflowTriggers}>
                    <WorkflowTriggersTable profileId={studentId} departmentId={departmentId} />
                </TabPane>
                <TabPane tab={<TabHeader title={t("user.checklist")} />} key={FinAidStudentFinAidSubtab.Checklist}>
                    <Checklist studentId={studentId} departmentId={departmentId} />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default FinancialAidStudentTab
