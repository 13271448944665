import {get} from "lodash"
import BaseService from "./Base"

class CourseLevelService extends BaseService {
    async getAllCourseLevels() {
        const response = await this.post("/v1/settings/course-levels/list", {})
        return get(response, "data", [])
    }

    async createCourseLevel(params = {}) {
        const response = await this.post("/v1/settings/course-levels/create", params)
        return get(response, "data.data", {})
    }

    async updateCourseLevel(id, data, params = {}) {
        data.courseLevelId = id
        const response = await this.post(`/v1/settings/course-levels/edit`, data, params)
        return get(response, "data", {})
    }

    async deleteCourseLevel(courseLevelIds) {
        const response = await this.post(`/v1/settings/course-levels/delete`, {courseLevelIds})
        return get(response, [], {})
    }
}

export default CourseLevelService
