import {get} from "lodash"
import {BaseService} from "services"

export default class CampusesService extends BaseService {
    async getMaxId(body = {}) {
        const response = await this.post("/v2/campuses/get-max-id", body)
        return get(response, "data", [])
    }
    async getAll(body = {}) {
        const response = await this.post("/v2/campuses/list", body)
        return get(response, "data", [])
    }

    async saveCampus(body = {}) {
        const response = await this.post("/v2/campuses/create", body)
        return get(response, "data", [])
    }

    async editCampus(body = {}) {
        const response = await this.post("/v2/campuses/edit", body)
        return get(response, "data", [])
    }

    async deleteCampus(body = {}) {
        const response = await this.post("/v2/campuses/delete", body)
        return get(response, "data", [])
    }

    async getSchoolCodes(body = {}) {
        const response = await this.post("/v2/campuses/get-school-codes", body)
        return get(response, "data", [])
    }

    async createSchoolCodes(body = {}) {
        const response = await this.post("/v2/campuses/create-school-code", body)
        return get(response, "data.data", [])
    }

    async getCampusFinAids() {
        const response = await this.post("/v1/campus-fin-aids/list", {})
        return get(response, "data.data", [])
    }

    async getCampusFinAid(campusId: number) {
        const response = await this.post("/v1/campus-fin-aids/get", {campusId})
        return get(response, "data.data", [])
    }

    async createCampusFinAid(body = {}) {
        const response = await this.post("/v1/campus-fin-aids/create", body)
        return get(response, "data.data", [])
    }

    async editCampusFinAid(body = {}) {
        const response = await this.post("/v1/campus-fin-aids/edit", body)
        return get(response, "data.data", [])
    }
}
