import {getUserPhotoUrl, handleError, toastWarning} from "helpers"
import {KlassappTableHOC} from "HOC"
import {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {userServiceV3} from "services"
import {KlassappTableProps} from "types/common"
import {BaseNewFilter, ConfirmPopupChildren, KlassappTable, KlassappTableHeader} from "uiKit"
import styles from "./ViewOtherUserChatCalendarPopup.module.css"

type ViewOtherUserChatCalendarPopupProps = {
    isShow: boolean
    onClose: () => void
    onConfirm: (staffUser) => void
    type: "chat" | "calendar"
} & KlassappTableProps

export const ViewOtherUserChatCalendarPopup = KlassappTableHOC((props: ViewOtherUserChatCalendarPopupProps) => {
    const {isShow, onClose, onConfirm, type, dispatch, total, isLoading, data, page, pageSize} = props
    const [selectedStaffUserId, setSelectedStaffUserId] = useState(null)
    const [search, setSearch] = useState("")
    const {t} = useTranslation(["user", "common"])

    const getParams = (searchValue: string) => {
        const params = {
            filter: {
                search: searchValue,
                sharedType: type
            },
            range: {
                pageSize,
                page
            }
        }
        return params
    }

    const renderStudentColumn = (data) => {
        const photo = data.photo ? getUserPhotoUrl(data.photo, 32) : "/image/DefaultAvatar.png"
        return (
            <div className={styles.fullnameWrap}>
                <img src={photo} alt="" className={styles.avatar} />
                <p className={styles.fullname}>{data.fullName}</p>
            </div>
        )
    }

    useEffect(() => {
        getUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, pageSize])

    const getUsers = async (searchValue = "") => {
        try {
            const params = getParams(searchValue)
            dispatch({isLoading: true})
            const {data: users, total} = await userServiceV3.getUsersViewOthersChatCalendar(params)
            const newUsers = users.map((user: any) => {
                const isChecked = selectedStaffUserId === user.userId
                user.id = user.userId
                user.isChecked = isChecked
                user.isActiveTableCol = isChecked
                user.fullNameHtml = renderStudentColumn(user)
                user.titles = user.titles?.length ? user.titles?.join("\n") : ""
                user.roles = user.roles?.length ? user.roles?.join("\n") : ""
                user.departments = user.departmentSubunits?.length ? user.departmentSubunits?.join("\n") : ""
                return user
            })
            dispatch({data: newUsers, total: total})
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }
    const onUpdateRowData = (updatedData) => {
        let selectedRowUserId = null
        if (updatedData.isChecked) {
            selectedRowUserId = updatedData.id
        }
        const data = props.getCurrentData()
        const newData = data.map((item) => {
            if (selectedRowUserId === item.id) {
                item.isChecked = true
                item.isActiveTableCol = true
                return item
            }
            item.isChecked = false
            item.isActiveTableCol = false
            return item
        })
        setSelectedStaffUserId(selectedRowUserId)
        props.dispatch({data: newData})
    }
    const columns = [
        {
            title: t("user.user"),
            field: "fullNameHtml",
            style: {minWidth: "250px"}
        },
        {
            title: t("user.id"),
            field: "customUserId",
            style: {minWidth: "150px"}
        },
        {
            title: t("user.title"),
            field: "titles",
            style: {minWidth: "150px", whiteSpace: "pre"}
        },
        {
            title: t("user.role"),
            field: "roles",
            style: {minWidth: "150px", whiteSpace: "pre"}
        },
        {
            title: t("user.department"),
            field: "departments",
            style: {minWidth: "150px", whiteSpace: "pre"}
        }
    ]
    const allFields = [t("user.user"), t("user.id"), t("user.title"), t("user.role"), t("user.department")]
    const fields = [t("user.user"), t("user.id"), t("user.title"), t("user.role"), t("user.department")]

    const onSearchInput = (searchInput) => {
        setSearch(searchInput)
        dispatch({page: 1}, () => {
            getUsers(searchInput)
        })
    }

    const onConfirmSelectStaff = () => {
        const data = props.getCurrentData()
        const checkedUser = data.find((item) => item.isChecked)
        if (!checkedUser) {
            toastWarning("You need to select one user")
            return
        }
        setSearch("")
        setSelectedStaffUserId(null)
        const newData = data.map((item) => {
            item.isChecked = false
            item.isActiveTableCol = false
            return item
        })
        dispatch({data: newData})
        onConfirm(checkedUser)
    }

    const title = type === "chat" ? "Select user to view their Chat" : "Select user to view their Calendar"

    const onClickRowItem = (updatedData) => {
        const selectedRowUserId = updatedData.id
        const data = props.getCurrentData()
        const newData = data.map((item) => {
            if (selectedRowUserId === item.id) {
                item.isChecked = true
                item.isActiveTableCol = true
                return item
            }
            item.isChecked = false
            item.isActiveTableCol = false
            return item
        })
        setSelectedStaffUserId(selectedRowUserId)
        props.dispatch({data: newData})
    }

    return (
        <ConfirmPopupChildren
            onClose={onClose}
            title={title}
            isVisible={isShow}
            alignFooterButtons="right"
            onConfirm={onConfirmSelectStaff}
            icon="EYE_VIEW_PERSON"
            width="70vw">
            <BaseNewFilter searchValue={search} onSearchInput={onSearchInput} />
            <KlassappTableHeader
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
            />
            <KlassappTable
                data={data}
                columns={columns}
                fields={fields}
                allFields={allFields}
                isShowCheckedColumn={true}
                onClickRowItem={onClickRowItem}
                onToggleCheckHeader={() => {}}
                isLoading={isLoading}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onClickSortColumn={props.onClickSortColumn}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </ConfirmPopupChildren>
    )
})
