import React from "react"
import {useTranslation} from "react-i18next"
import {Every, Days} from "sections/calendar/parts/common"

export function WeeklyRecurringBody(props) {
    const {t} = useTranslation("calendar")
    const {eventData, onChangeEventData, errors} = props

    return (
        <>
            <Every
                title={t("calendar.options.Week")}
                value={eventData.every}
                onChange={(newValue) => onChangeEventData("every", newValue)}
                // @ts-ignore
                error={!!errors["every"]}
            />
            <Days
                value={eventData.days}
                onChange={(newValue) => onChangeEventData("days", newValue)}
                error={!!errors["days"]}
            />
        </>
    )
}
