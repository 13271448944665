/* eslint-disable react-hooks/exhaustive-deps */
import {KlassappTableHOC} from "HOC"
import React, {useCallback, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {useHistory} from "react-router-dom"
import {degreeAuditService} from "services"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {Course} from "types/courses"
import {handleError, roundTwoDigits} from "helpers"
import {AcademicPlans} from "types/academicPlans"
import {Tooltip} from "antd"
import {Icon} from "components"
import styles from "./CurrentProgress.module.css"

const TableCurrentProgress = (props) => {
    const {
        data,
        dispatch,
        typeMajor,
        dispatchFunc,
        page,
        pageSize,
        termId,
        statusTerm,
        studentId,
        allFields,
        fields,
        tableHeaderActions,
        orderField,
        isLoading,
        isShowTableHeaderAction,
        isLoadedTableFuncs,
        total
    } = props

    const {t} = useTranslation(["course"])
    const history = useHistory()

    const getFields = () => {
        return [
            "CODE",
            "COURSE NAME",
            "TERM",
            typeMajor === Course.CourseType.Clock ? "COMPLETED HOURS" : "REQ CREDITS",
            "EARNED CREDITS",
            "ALPHABETICAL",
            "GRADE (GPA)"
        ]
    }

    const getPageTitle = () => {
        return "Course"
    }
    const [columns, setColumns] = useState([
        {
            title: "CODE",
            field: "code",
            style: {
                minWidth: "60px",
                maxWidth: "120px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
            }
        },
        {
            title: "COURSE NAME",
            field: "nameCourseHtml",
            style: {
                minWidth: "300px",
                maxWidth: "300px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
            }
        },
        {
            title: "TERM",
            field: "nameTerm",
            style: {
                minWidth: "300px",
                maxWidth: "300px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
            }
        },
        {
            title: "REQ CREDITS",
            field: "credit",
            style: {
                minWidth: "60px",
                maxWidth: "60px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
            }
        },
        {
            title: typeMajor === Course.CourseType.Clock ? "COMPLETED HOURS" : "EARNED CREDITS",
            field: "earnedCredits",
            style: {
                minWidth: "60px",
                maxWidth: "60px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
            }
        },
        {
            title: "ALPHABETICAL",
            field: "alpha"
        },
        {
            title: "GRADE (GPA)",
            field: "totalGpa"
        }
    ])

    const getColumns = () => {
        return columns
    }

    const getParams = () => {
        const params: any = {
            studentProfileId: studentId,
            range: {
                page,
                pageSize
            }
        }
        if (termId) {
            params.termIds = [termId]
        }
        return params
    }

    const getData = async () => {
        try {
            dispatch({isLoading: true})
            const params = getParams()
            const {data, total} = await degreeAuditService.getDetailCurrentProgress(params)
            const checkEarnedCredits = (data) => {
                if (data.status === AcademicPlans.AcademicPlanCourseReviewStatus.Dropped) {
                    return "Withdrawn"
                } else if (data.courseStatus === AcademicPlans.AcademicPlanCourseStatus.Suspended) {
                    return "Suspended"
                } else if (data.courseStatus === AcademicPlans.AcademicPlanCourseStatus.Completed) {
                    return roundTwoDigits(
                        typeMajor === Course.CourseType.Credit ? data.earnedCredits : data.earnedHours
                    )
                } else if (data.courseStatus === AcademicPlans.AcademicPlanCourseStatus.Failed) {
                    return "Failed"
                } else if (
                    data.courseStatus === AcademicPlans.AcademicPlanCourseStatus.Inprogress &&
                    typeMajor === Course.CourseType.Credit
                ) {
                    return "In-progress"
                } else if (
                    typeMajor === Course.CourseType.Credit &&
                    data.courseStatus === AcademicPlans.AcademicPlanCourseStatus.Incomplete
                ) {
                    return "Incomplete"
                } else if (
                    typeMajor === Course.CourseType.Credit &&
                    data.courseStatus === AcademicPlans.AcademicPlanCourseStatus.LoaIncomplete
                ) {
                    return "Loa-Incomplete"
                } else if (typeMajor === Course.CourseType.Clock) {
                    return roundTwoDigits(data.earnedHours)
                } else {
                    return ""
                }
            }
            const listData = data.map((item) => ({
                ...item,
                alpha: typeof item?.alpha === "string" ? item?.alpha : "",
                earnedCredits: checkEarnedCredits(item),
                nameCourseHtml: (
                    <div className={styles.classAssistance}>
                        <span>
                            {item?.nameCourse}
                            {item?.role === Course.AcademicPlanCourseRole.ClassAssistance ? " (CA)" : ""}
                        </span>
                        {item?.role === Course.AcademicPlanCourseRole.ClassAssistance ? (
                            <Tooltip title={"Clock hour will not be calculated"} key={"Class Assistance"}>
                                <div className={styles.classAssistanceInfo}>
                                    <Icon icon={"INFO"} />
                                </div>
                            </Tooltip>
                        ) : null}
                    </div>
                )
            }))
            dispatch({data: listData, total: total})
        } catch (err) {
            handleError(err)
        } finally {
            dispatch({isLoading: false})
        }
    }

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getData()
        }
    }, [isLoadedTableFuncs, page, pageSize, studentId, statusTerm])

    const onClickDeleteMulti = useCallback(async () => {}, [data])

    const onClickDuplicateMulti = useCallback(async () => {}, [data])

    const onClickEdit = (editItem) => {
        history.push(`/settings/add-grading?id=${editItem.id}`)
    }

    const onClickDelete = useCallback(async (deletedItem) => {}, [])

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "getMenuActions", func: getMenuActions},
            {key: "onClickDuplicateMulti", func: onClickDuplicateMulti},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDelete}
        ])
    }, [data])

    useEffect(() => {
        props.updateTableHeaderActions()
    }, [data])

    const getMenuActions = () => {
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: onClickEdit
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: onClickDelete
            }
        ]
    }

    return (
        <div>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                menuActions={[]}
                isLoading={isLoading}
                allFields={allFields}
                isShowCheckedColumn={false}
                orderField={orderField}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </div>
    )
}

export default KlassappTableHOC(TableCurrentProgress)
