/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {Icon} from "components/Icon"
import {BaseButton} from "components/buttons"
import {KlassappTable, KlassappTableHeader, BaseNewFilter} from "uiKit"
import {groupsService} from "services"
import styles from "./Groups.module.css"
import {Screen} from "components/ui"
import {KlassappTableHOC} from "HOC"
import {useHistory} from "react-router-dom"
import {getFullName, handleError} from "helpers"
import {PermissionsRequired} from "components/PermissionsRequired"
import {Permissions} from "@edular/permissions"
import {useCurrentProfilePermissions} from "hooks"

const permissionToAddGroup = {staff: [Permissions.Staff.Settings.General.Groups.Add]}
const permissionToDeleteGroup = {staff: [Permissions.Staff.Settings.General.Groups.Delete]}
const permissionToEditGroup = {staff: [Permissions.Staff.Settings.General.Groups.Edit]}

const Groups = (props) => {
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        tableHeaderActions,
        orderField,
        isLoading,
        menuActions,
        isHideMenuActions,
        isShowTableHeaderAction,
        isLoadedTableFuncs,
        getCurrentPage,
        getCurrentPageSize
    } = props
    const {t} = useTranslation(["chat", "common"])
    const [search, setSearch] = useState("")
    const history = useHistory()
    const currentProfilePermissions = useCurrentProfilePermissions()

    const getFields = () => {
        return [t("chat:groupId"), t("chat:name"), t("chat:createdBy"), t("chat:members"), t("chat:createdAt")]
    }

    const getPageTitle = () => {
        return t("chat:group")
    }

    const getColumns = () => {
        return [
            {
                title: t("chat:groupId"),
                field: "code",
                sortable: true,
                style: {minWidth: "120px"}
            },
            {
                title: t("chat:name"),
                field: "name",
                sortable: true,
                style: {minWidth: "200px"}
            },
            {
                title: t("chat:createdBy"),
                field: "createdByUserFullName",
                style: {minWidth: "200px"}
            },
            {
                title: t("chat:members"),
                field: "members",
                fieldType: "numberWithZero",
                sortable: true,
                style: {minWidth: "120px"}
            },
            {
                title: t("chat:createdAt"),
                sortable: true,
                field: "createdAt",
                fieldType: "dateTime",
                style: {minWidth: "160px"}
            }
        ]
    }

    const canShowDeleteIcon = () => {
        return currentProfilePermissions.hasPermissions(permissionToDeleteGroup)
    }

    const canShowEditIcon = () => {
        return currentProfilePermissions.hasPermissions(permissionToEditGroup)
    }

    const getMenuActions = () => {
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: onClickEdit,
                canShow: canShowEditIcon
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: onClickDelete,
                canShow: canShowDeleteIcon
            }
        ]
    }

    const getParams = () => {
        const page = getCurrentPage()
        const pageSize = getCurrentPageSize()
        const {order} = orderField
        const params = {
            range: {page, pageSize},
            sort: {
                orderBy: orderField.field ?? "createdAt",
                orderDir: (order && order.toUpperCase()) || "DESC"
            },
            filter: {
                search: search || ""
            },
            linkedEntities: true
        }
        return params
    }

    const getChatGroups = async () => {
        dispatch({isLoading: true})
        try {
            const params = getParams()
            const {data, total} = await groupsService.getAll(params)
            const newData = data.map((group) => {
                group.id = group.groupId
                group.createdByUserFullName = getFullName(group.createdByUser)
                return group
            })
            dispatch({
                data: newData,
                total
            })
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClickAdd = () => {
        history.push("/settings/groups/details")
    }

    const onClickRowItem = (row) => {
        history.push(`/settings/groups/details/${row.id}`)
    }

    const onClickDeleteMulti = async () => {
        const data = props.getCurrentData()
        const checkedItems = data.filter((item) => item.isChecked)
        const groupIds = checkedItems.map((group) => group.id)
        try {
            dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await groupsService.deleteBulk(groupIds)
            await getChatGroups()
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClickShowConfirmModal = (modalAction) => {
        dispatch({isModalVisible: true, modalAction})
    }
    const getTableHeaderActions = () => {
        const actions = []
        if (currentProfilePermissions.hasPermissions(permissionToDeleteGroup)) {
            actions.push({
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            })
        }
        return actions
    }

    const onClickEdit = (editedItem) => {
        onClickRowItem(editedItem)
    }

    const onClickDelete = async (deletedItem) => {
        const {dispatch} = props
        try {
            dispatch({isLoading: true})
            await groupsService.deleteBulk([deletedItem.id])
            await getChatGroups()
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getChatGroups},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "getMenuActions", func: getMenuActions},
            {key: "onClickRowItem", func: onClickRowItem},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDelete},
            {key: "getTableHeaderActions", func: getTableHeaderActions}
        ])
    }, [])

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getChatGroups()
        }
    }, [isLoadedTableFuncs, page, pageSize, orderField?.field, orderField?.order, search])

    useEffect(() => {
        dispatchFunc([{key: "onClickDeleteMulti", func: onClickDeleteMulti}])
        props.updateTableHeaderActions()
    }, [data])

    const onSearchInput = (searchValue) => {
        setSearch(searchValue)
        dispatch({page: 1})
    }

    return (
        <Screen
            htmlTitle={t("chat:titleSettings")}
            header={{
                title: t("chat:titleSettings")
            }}>
            <BaseNewFilter className={styles.filterWrap} searchValue={search} onSearchInput={onSearchInput} />

            <PermissionsRequired permissions={permissionToAddGroup}>
                <div className={styles.createBtnWrap}>
                    <BaseButton
                        title={t("common:add").toUpperCase()}
                        onClick={onClickAdd}
                        icon={<Icon className={styles.plusIcon} icon="PLUS" color="var(--whitebase)" />}
                    />
                </div>
            </PermissionsRequired>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                menuActions={isHideMenuActions ? [] : menuActions}
                isLoading={isLoading}
                allFields={allFields}
                isShowCheckedColumn={true}
                orderField={orderField}
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onClickSortColumn={props.onClickSortColumn}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </Screen>
    )
}

export default KlassappTableHOC(Groups)
