import {DependencyList, useEffect, useRef} from "react"
import {isEqual} from "lodash"

type MemoFn<T> = () => T

export function useDeepEqualMemo<T>(fn: MemoFn<T>, deps: DependencyList) {
    const ref = useRef<T | null>(fn())
    const initialRender = useRef(true)

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false
            return
        }

        const nextValue = fn()

        if (!isEqual(ref.current, nextValue)) {
            ref.current = nextValue
        }
        // eslint-disable-next-line
    }, [deps])

    return ref.current
}
