import {Campus} from "types/campus"
import {Department, DepartmentSubunit} from "types/departments"
import {Auth} from "types/auth"
import {DegreeLevel} from "types/degreeLevel"
import {Course} from "types/courses"

export namespace Major {
    export enum Unit {
        Clock = "clock",
        Credit = "credit"
    }

    export enum TabKey {
        List = "list",
        Term = "term",
        CourseSequence = "courseSequence"
    }

    export enum VersionType {
        FixedTerm = "fixedTerm",
        RollingStart = "rollingStart",
        CalendarBased = "calendarBased",
        CalendarBasedMultiCourse = "calendarBasedMultiCourse"
    }

    export enum TermDraggable {
        TermSectionEmpty = "termSectionEmpty"
    }

    export enum ProgramLengthType {
        Weeks = "weeks",
        Months = "months",
        Lessons = "lessons",
        Terms = "terms",
        ClockHours = "clock_hours"
    }

    export type Major = {
        id: number
        name: string
        description: string
        departmentId: number
        departmentSubunitId: number
        degreeLevelId: number
        createdByProfileId: number
        updatedByProfileId: number
        code: string
        createdAt: string
        updatedAt: string
        deletedAt: string
        sisId: number
        vDeleted: string
        cipCode: string
        programId: number
        isActive: boolean
        campusIds: number[]
        departmentDeanProfileIds: number[]
        versionIds: number[]
        campuses: Campus.Campus[]
        departmentDeans: DepartmentDeans[]
        versions: VersionDetail[]
        department: Department
        departmentSubunit?: DepartmentSubunit
        createdByProfile: Auth.UpdatedByProfile
        updatedByProfile: Auth.UpdatedByProfile
        degreeLevel: DegreeLevel.DegreeLevel
    }

    export type DepartmentDeans = {
        userId: number
        firstName: string
        lastName: string
        fullName: string
        title: string
        email: string
        profileId: number
    }

    export type VersionDetail = {
        id: number
        versionName: string
        versionCode: string
        effectiveDate: string
        type: string
        isFixedTermAndCourseSequence: boolean
        isRollingStartsProgram: boolean
        isCalendarBased: boolean
        isCalendarBasedMultiCourse: boolean
        studentCount: number
        programId: number
        numberOfWeeks: number
        totalClockHours: number
        createdBy: number
        updatedBy: number
        createdAt: string
        updatedAt: string
        deletedAt: string
        sisId: number
        vDeleted: string
    }

    export type MajorVersion = {
        id: number
        version_name: string
        version_code: string
        effective_date: string
        type: string
        isFixedTermAndCourseSequence: number
        isRollingStartsProgram: number
        isCalendarBased: number
        isCalendarBasedMultiCourse: number
        student_count: number
        program_id: number
        number_of_weeks: number
        total_clock_hours: number
        created_by: number
        updated_by: number
        created_at: string
        updated_at: string
        deleted_at: string
        sisId: number
        vDeleted: string
        program_term_template_name?: string
        list?: Section[]
        terms?: Term[]
        academicTerms?: AcademicTerm[]
        campuses: any[]
        calendarBasedTimezone: string
    }

    export type Section = {
        id: number
        section_title: string
        courseUnit: Course.Unit
        minimum: number
        position: number
        program_term_template_id: number
        courses: SectionCourse[]
    }

    export type SectionCourse = {
        id: number
        position: number
        program_term_template_id: number
        term_index?: number
        courseName: string
        courseId: number
        courseCode: string
        courseType: string
        totalQuantity: number
        totalUnits: string
        theoryOrClassroomModuleQuantity: number
        theoryOrClassroomModuleUnits: string
        labModuleQuantity: number
        labModuleUnits: string
        testModuleQuantity: number
        testModuleUnits: string
        othersModuleQuantity: number
        othersModuleUnits: string
        credits: number
        each: number
        prerequisiteCourseIds: number[]
    }

    export type AcademicTerm = {
        id: string
        academicYear: string
        order: number
        terms: Term[]
    }

    export type Term = {
        id: number
        name: string
        termId?: number
        termTitle?: string
        sections: Section[]
    }

    export type UpdateSection = {
        section_title?: string
        program_term_template_id?: number
        minimum?: number
        unit?: number
        coursesData?: {
            addedItems: number[]
            deletedItems: number[]
        }
    }

    export type TermSequence = {
        id: number
        name?: string
        dayOfTerm?: number
        isRegistrationHold?: boolean
        scheduledTermId?: number
        termId?: number
        programTermTemplateId?: number
        registration_end_date?: string
        registration_start_date?: string
        registeredDate?: string
        start_date?: string
        end_date?: string
        courses: CourseSequence[]
        groupCourses?: GroupCourseSequence[]
    }

    export type GroupCourseSequence = {
        groupId: string
        numberOfDays: number
        color: string
        position: number
        courses: CourseSequence[]
    }

    export type CourseSequence = {
        id: number
        courseId: number
        groupId: string
        calendarBasedGroupId: string
        code: string
        name: string
        color: string
        position: number
        templateCourseId?: number
        numberOfDays?: number
        scheduleId?: number
        scheduleSuffix?: string
        instructors: any[]
        events: EventSequence[]
        schedule?: any
    }

    export type EventSequence = {
        id: string
        startTime: string
        endTime: string
        dayCount?: number
        daysOfCourse: string
        notes: string
        type: string
        method?: string
        submoduleId?: number
        submoduleTitle?: string
        startAts?: any
        endAts?: any
    }

    export type SelectableTerm = {
        termId: number
        name: string
        start_date: string
        end_date: string
        registration_start_date: string
        registration_end_date: string
    }
}
