import React from "react"
import {useTranslation} from "react-i18next"
import styles from "./CardItem.module.css"
import {VerificationStatus} from "types/fin-aid/verification-status"

type Props = {
    label: string
    count: number
}

export function CardItem({label, count}: Props) {
    const {t} = useTranslation(["financialAid", "common"])

    return (
        <div className={styles.wrap}>
            <div className={styles.headerWrap}>
                <div className={styles.titleWrap}>
                    <p className={styles.title}>{label}</p>
                </div>
            </div>
            <p className={styles.count}>{count}</p>
        </div>
    )
}
