/* eslint-disable react-hooks/exhaustive-deps */
import {formatPhoneNumber, handleError} from "helpers"
import {useModel} from "hooks"
import {intersection, uniqBy} from "lodash"
import {useEffect, useMemo, useState} from "react"

export const useSmsSenders = () => {
    const [isLoading, setIsLoading] = useState(false)
    const model = useModel()
    const [userCampusIds, setUserCampusIds] = useState<number[]>([])

    useEffect(() => {
        getCurrentUserCampuses()
    }, [])

    const getCurrentUserCampuses = async () => {
        try {
            setIsLoading(true)
            const currentUser = model.user
            const userCampuses = currentUser.profiles.map(({campuses}) => campuses.map(({id}) => id))
            const userCampusIds = userCampuses.flatMap((id) => id)
            setUserCampusIds(userCampusIds)
        } catch (err) {
            handleError(err)
        } finally {
            setIsLoading(false)
        }
    }

    const defaultSmsSenderId = useMemo(() => {
        const globalPhoneCampuses = model.smsAllPhoneNumbers
            .filter((x) => x.isGlobal === 1)
            .map(({campuses}) => (campuses || []).map(({id}) => id))
            .flatMap((id) => id)
        const sameCampusIds = intersection(userCampusIds, globalPhoneCampuses)
        const selectedCampusId = sameCampusIds?.[0]
        if (selectedCampusId) {
            const selectedPhone = model.smsAllPhoneNumbers.find(
                (x) => x.isGlobal === 1 && (x.campuses || []).filter((y) => y.id === selectedCampusId).length > 0
            )
            return selectedPhone?.smsPhoneNumbersId
        }
        return null
    }, [model.smsAllPhoneNumbers, userCampusIds])

    const senderOptions = useMemo(() => {
        return model.smsAllPhoneNumbers
            .filter((x) => x.isGlobal === 1)
            .map((x) => ({
                id: x.smsPhoneNumbersId,
                name: `${formatPhoneNumber(x.phone)}${
                    x.campuses && x.campuses.length > 0 ? ` - ${x.campuses.map((y) => y.name).join(", ")}` : ""
                }`
            }))
    }, model.smsAllPhoneNumbers)

    const campusList = useMemo(() => {
        return uniqBy(
            model.smsAllPhoneNumbers
                .filter((x) => x.isGlobal === 1 && !!x.campuses)
                .map((x) => x.campuses)
                .flatMap((x) => x),
            "id"
        )
    }, model.smsAllPhoneNumbers)

    return {isLoading, defaultSmsSenderId, senderOptions, campusList}
}
