import React, {useEffect, useState} from "react"
import styles from "./ProgressReportPdf.module.css"

type PracticalTableProps = {
    data: {
        id: number
        courseCode: string
        courseName: string
        description: string
        termName: string
    }[]
}

const PracticalTable = (props: PracticalTableProps) => {
    const {data} = props

    return (
        <div className={styles.tableContainer}>
            <div className={styles.tableTerm}>
                <div className={styles.tableTermTitle}>
                    <span className={styles.tableTitle}>Practical</span>
                </div>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th className={styles.tableHeaderText}>ID</th>
                            <th className={styles.tableHeaderText}>Description</th>
                            <th className={styles.tableHeaderText}>Course Id</th>
                            <th className={styles.tableHeaderText}>Name</th>
                            <th className={styles.tableHeaderText}>Term</th>
                        </tr>
                    </thead>
                    {data.map((practical) => (
                        <tbody>
                            <tr>
                                <td className={styles.tableColumn}>{practical.id}</td>
                                <td className={styles.tableColumn}>{practical.description}</td>
                                <td className={styles.tableColumn}>{practical.courseCode}</td>
                                <td className={styles.tableColumn}>{practical.courseName}</td>
                                <td className={styles.tableColumn}>{practical.termName}</td>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
        </div>
    )
}

export default PracticalTable
