import React, {useState} from "react"
import moment from "moment"
import Modal from "antd/lib/modal/Modal"
import {useTranslation} from "react-i18next"
import {Col, Row} from "antd"
import {BaseInput, Icon} from "components"
import {BaseButton} from "components/buttons"
import styles from "./BookingModal.module.css"

export const BookingModal = (props) => {
    const {onCancel = undefined, onSubmit, isLoading = false, event} = props
    const [reason, setReason] = useState("")
    const {t} = useTranslation(["calendar", "common"])

    const handleOnSubmit = () => {
        onSubmit(reason)
    }

    const date = moment(event.start).format("MM/DD/YYYY")
    const startAt = moment(event.start).format("hh:mm A")
    const endAt = moment(event.end).format("hh:mm A")
    return (
        <Modal
            keyboard={false}
            closable={false}
            visible={true}
            onCancel={onCancel}
            closeIcon={<Icon icon="CLOSE" color="#000" className={styles.closeIcon} />}
            maskClosable={false}
            footer={null}
            className="eventPopup">
            <div className={styles.root}>
                <div className={styles.eventPopupHeader}>
                    <h1 className={styles.eventPopupTitle}>{t("calendar.bookAnAppointment")}</h1>
                </div>

                <div>
                    <Row gutter={[16, 16]} className={styles.rowItem}>
                        <Col span={2} className={styles.eventPopupLabel}>
                            <Icon icon="WHITE_CALENDAR" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                        <Col span={6} className={styles.eventPopupLabel}>
                            <label>{t("calendar.date")}</label>
                        </Col>
                        <Col span={16}>
                            <BaseInput value={date} />
                        </Col>
                        <Col span={2}>
                            <Icon icon="TIME_CLOCK_OUTLINE" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                        <Col span={6} className={styles.eventPopupLabel}>
                            <label>{t("calendar.time")}</label>
                        </Col>
                        <Col span={8}>
                            <BaseInput value={startAt} />
                        </Col>
                        <Col span={8}>
                            <BaseInput value={endAt} />
                        </Col>
                        <Col span={2} className={styles.eventPopupLabel}>
                            <Icon icon="NOTES" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                        <Col span={6} className={styles.eventPopupLabel}>
                            <label>{t("calendar.bookReason")}</label>
                        </Col>
                        <Col span={16}>
                            <BaseInput onChange={setReason} placeholder={t("calendar.bookReason")} />
                        </Col>
                    </Row>
                    <div className={styles.actionBtn}>
                        <BaseButton
                            disabled={isLoading}
                            onClick={onCancel}
                            variant="secondary"
                            title={t("common:action.cancel")}
                        />
                        <BaseButton disabled={isLoading} onClick={handleOnSubmit} title={t("common:action.save")} />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
