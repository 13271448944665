/* eslint-disable react-hooks/exhaustive-deps */
import {Dropdown, Input, Menu, Popover, Tooltip, Upload} from "antd"
import classNames from "classnames"
import isHotkey from "is-hotkey"
import isUrl from "is-url"
import React, {ReactPropTypes} from "react"
import {v4 as uuidv4} from "uuid"
import {
    MdCode,
    MdFormatAlignCenter,
    MdFormatAlignJustify,
    MdFormatAlignLeft,
    MdFormatAlignRight,
    MdFormatBold,
    MdFormatItalic,
    MdFormatListBulleted,
    MdFormatListNumbered,
    MdFormatUnderlined,
    MdImage,
    MdInsertLink,
    MdLooksOne,
    MdLooksTwo,
    MdPostAdd,
    MdRedo,
    MdUndo
} from "react-icons/md"
import {createEditor, Descendant, Editor, Element as SlateElement, Range, Text as SlateText, Transforms} from "slate"
import {withHistory} from "slate-history"
import {jsx} from "slate-hyperscript"
import {Editable, ReactEditor, Slate, useFocused, useSelected, useSlate, useSlateStatic, withReact} from "slate-react"
import {embedVideoLink, handleError, isImageLink} from "../../helpers"
import styles from "./RichTextEditor.module.css"

import {
    CustomElement,
    ELEMENT_TAGS,
    EmbedVideoElement,
    FieldInputElement,
    FieldLabelElement,
    FinAidTableElement,
    ImageElement,
    INITIAL_RTE_CONTENT,
    isEmptyContent,
    LinkElement,
    LIST_TYPES,
    ParagraphElement,
    TEXT_ALIGN_TYPES,
    TEXT_TAGS
} from "./slate-custom-types"
import memoize from "lodash/memoize"
import {ReactComponent as IconFieldLabel} from "./icons/AddField.svg"
import {ReactComponent as IconFieldInput} from "./icons/Add_InputField.svg"
import {BaseInput} from "../inputs"
import {BaseDatePicker, BaseTimePicker} from "../DateTimePicker"
import moment, {Moment} from "moment"
import {studentDataValues} from "sections/Tasks/data"
import tableStyles from "sections/FinancialAid/FinancialAidStudent/parts/FinAidPackageDetail/parts/Table.module.css"
import {TextAlign} from "chartjs-plugin-datalabels/types/options"
import {SecondaryButton} from "components/buttons"
import {RcFile} from "antd/lib/upload/interface"
import {fileService} from "services"
import {useMutation} from "@tanstack/react-query"
import axios from "axios"
import {EditorType} from "types/settings/email-template"

const HOTKEYS = {
    "mod+b": "bold",
    "mod+i": "italic",
    "mod+u": "underline",
    "mod+`": "code"
}

/// region Link

const isLinkActive = (editor: Editor): boolean => {
    const [link] = Editor.nodes(editor, {
        match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link"
    })
    return !!link
}

const unwrapLink = (editor: Editor) => {
    Transforms.unwrapNodes(editor, {
        match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link"
    })
}

const wrapLink = (editor: Editor, url: string) => {
    if (isLinkActive(editor)) {
        unwrapLink(editor)
    }

    const {selection} = editor
    const isCollapsed = selection && Range.isCollapsed(selection)
    const link: LinkElement = {
        type: "link",
        url,
        children: isCollapsed ? [{text: url}] : []
    }

    if (isCollapsed) {
        Transforms.insertNodes(editor, link)
    } else {
        Transforms.wrapNodes(editor, link, {split: true})
        Transforms.collapse(editor, {edge: "end"})
    }
}

const insertLink = (editor: Editor, url: string) => {
    if (editor.selection) {
        wrapLink(editor, url)
    }
}

const withLinks = (editor: Editor) => {
    const {insertData, insertText} = editor

    editor.insertText = (text) => {
        if (text && isUrl(text)) {
            wrapLink(editor, text)
        } else {
            insertText(text)
        }
    }

    editor.insertData = (data) => {
        const text = data.getData("text/plain")

        if (text && isUrl(text)) {
            wrapLink(editor, text)
        } else {
            insertData(data)
        }
    }

    return editor
}

/// endregion Link

/// region Image

const insertImage = (editor: Editor, url: string) => {
    const text = {text: ""}
    const image: ImageElement = {
        type: "image",
        url,
        children: [text]
    }
    const newLine: ParagraphElement = {type: "paragraph", children: [text]}
    Transforms.insertNodes(editor, [image, newLine])
}

const insertEmbedVideo = (editor: Editor, videoId: string) => {
    const text = {text: ""}
    const embedVideo: EmbedVideoElement = {
        type: "embed-video",
        videoId: embedVideoLink(videoId),
        children: [text]
    }
    const newLine: ParagraphElement = {type: "paragraph", children: [text]}
    Transforms.insertNodes(editor, [embedVideo, newLine])
}

const withImages = (editor: Editor) => {
    const {insertData} = editor

    editor.insertData = async (data) => {
        const text = data.getData("text/plain")
        const {files} = data

        if (files && files.length > 0) {
            for (const file of files) {
                const reader = new FileReader()
                const [mime] = file.type.split("/")

                if (mime === "image") {
                    const fileExtension = (file.name.match(/\.[0-9a-z]+$/i)?.[0] || ".jpg").slice(1)
                    const {url, urlForUploading} = await fileService.createSignedUrlForUploadingFile({
                        fileExtension,
                        isPublic: true
                    })
                    await axios.put(urlForUploading, file, {headers: {"Content-Type": file.type}})
                    insertImage(editor, url)
                }
            }
        } else if (isUrl(text)) {
            if (await isImageLink(text)) {
                insertImage(editor, text)
            } else if (embedVideoLink(text)) {
                insertEmbedVideo(editor, text)
            } else {
                insertLink(editor, text)
            }
        } else {
            insertData(data)
        }
    }

    return editor
}

/// endregion Image

/// region Data Field

const insertFieldLabel = (editor: Editor, field: string, label: string) => {
    const fieldNode: FieldLabelElement = {
        type: "field-label",
        field,
        id: uuidv4(),
        value: label,
        children: [{text: ""}]
    }
    Transforms.insertNodes(editor, fieldNode)
}

const insertFieldInput = (editor: Editor, input: "text" | "date" | "time") => {
    const fieldNode: FieldInputElement = {
        type: "field-input",
        input,
        id: uuidv4(),
        children: [{text: ""}]
    }
    Transforms.insertNodes(editor, fieldNode)
}

const insertFinAidTable = (editor: Editor, table: "costs" | "grants" | "loans" | "disbursements") => {
    const fieldNode: FinAidTableElement = {
        type: "fin-aid-table",
        table,
        id: uuidv4(),
        children: [{text: ""}]
    }
    Transforms.insertNodes(editor, fieldNode)
}

// endregion Data Field

/// region Format

const isBlockActive = (editor: Editor, format: SlateElement["type"], blockType = "type"): boolean => {
    const {selection} = editor
    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format
        })
    )

    return !!match
}

const isMarkActive = (editor: Editor, format: keyof SlateText): boolean => {
    try {
        const marks = Editor.marks(editor)
        return marks ? marks[format] === true : false
    } catch {
        return false
    }
}

const toggleBlock = (editor: Editor, format: SlateElement["type"]): void => {
    const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "align" : "type")
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: (n) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true
    })
    let newProperties: Partial<SlateElement>
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format
        }
    } else {
        newProperties = {
            type: isActive ? "paragraph" : isList ? "list-item" : format
        }
    }
    Transforms.setNodes<SlateElement>(editor, newProperties)

    if (!isActive && isList) {
        const block = {type: format, children: []} as SlateElement
        Transforms.wrapNodes(editor, block)
    }
}

const toggleMark = (editor: Editor, format: keyof SlateText): void => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

/// endregion Format

/// region Element

const ImageComponent: React.FC<ElementProps> = ({attributes, children, element}) => {
    const selected = useSelected()
    const focused = useFocused()
    return (
        <div {...attributes}>
            <div contentEditable={false} className={styles.imageContent}>
                <img
                    alt=""
                    src={(element as ImageElement).url}
                    className={classNames(styles.image, {
                        [styles.imageHighlight]: selected && focused
                    })}
                />
            </div>

            {children}
        </div>
    )
}

const FieldLabel: React.FC<ElementProps> = ({
    attributes,
    children,
    element,
    updateNodes,
    readOnly,
    fieldReadOnly,
    dataFieldInsertable
}) => {
    const selected = useSelected()
    const {id, value} = element as FieldLabelElement

    return (
        <span {...attributes}>
            {dataFieldInsertable || (readOnly && fieldReadOnly) ? (
                <span
                    contentEditable={false}
                    className={classNames(styles.fieldLabelContent, {
                        [styles.fieldLabelContentHighlighted]: selected,
                        [styles.fieldReadOnly]: readOnly
                    })}>
                    {readOnly ? `${value}` : `[${value}]`}
                </span>
            ) : (
                <span
                    contentEditable={false}
                    className={classNames(styles.fieldInputContent, {
                        [styles.fieldInputContentHighlighted]: selected,
                        [styles.fieldReadOnly]: readOnly
                    })}>
                    <BaseInput
                        className={styles.fieldInputText}
                        readOnly={fieldReadOnly}
                        defaultValue={value}
                        onBlur={(e) => {
                            if (dataFieldInsertable) return
                            updateNodes("field-label", id, e.target.value)
                        }}
                    />
                </span>
            )}

            {children}
        </span>
    )
}

const FieldInput: React.FC<ElementProps> = ({
    attributes,
    children,
    element,
    updateNodes,
    userDateFormat,
    userTimeFormat,
    fieldReadOnly,
    inputFieldInsertable
}) => {
    const selected = useSelected()

    const renderInput = () => {
        const {input, value, id} = element as FieldInputElement
        switch (input) {
            case "text":
                return (
                    <BaseInput
                        readOnly={inputFieldInsertable || fieldReadOnly}
                        placeholder="Input"
                        className={styles.fieldInputText}
                        defaultValue={value}
                        onBlur={(e) => {
                            if (inputFieldInsertable) return
                            updateNodes("field-input", id, e.target.value)
                        }}
                    />
                )
            case "date":
                return (
                    <BaseDatePicker
                        readOnly={inputFieldInsertable || fieldReadOnly}
                        placeholder="Date"
                        className={styles.fieldInputDate}
                        format={userDateFormat}
                        value={value ? moment(value) : undefined}
                        onChange={(value: Moment, text) => {
                            updateNodes("field-input", id, value.toISOString())
                        }}
                    />
                )
            case "time":
                return (
                    <BaseTimePicker
                        disabled={inputFieldInsertable || fieldReadOnly}
                        placeholder="Time"
                        format={userTimeFormat || "HH:mm"}
                        className={styles.fieldInputDate}
                        value={value ? moment(value) : undefined}
                        onChange={(value: Moment, text) => {
                            updateNodes("field-input", id, value.toISOString())
                        }}
                    />
                )
            default:
                return null
        }
    }

    return (
        <span {...attributes}>
            <span
                contentEditable={false}
                className={classNames(styles.fieldInputContent, {
                    [styles.fieldInputContentHighlighted]: selected
                })}>
                {renderInput()}
            </span>

            {children}
        </span>
    )
}

const FinAidTable: React.FC<ElementProps> = ({attributes, children, element, finAidInsertable, finAidTables}) => {
    const selected = useSelected()
    const {id, table} = element as FinAidTableElement

    const columns = React.useMemo(() => {
        switch (table) {
            case "costs":
                return [{title: "ITEM"}, {title: "Term 1"}, {title: "Term 2"}, {title: "Term 3"}]
            case "grants":
                return [
                    {title: "FINANCIAL RESOURCES"},
                    {title: "Term 1"},
                    {title: "Term 2"},
                    {title: "Term 3"},
                    {title: "SUBTOTAL"}
                ]
            case "loans":
                return [
                    {title: "LOAN PROGRAM"},
                    {title: "Term 1"},
                    {title: "Term 2"},
                    {title: "Term 3"},
                    {title: "GROSS TOTAL"},
                    {title: "NET TOTAL"}
                ]
            case "disbursements":
                return [
                    {title: "Award Year"},
                    {title: "Fund Source"},
                    {title: "Disb #"},
                    {title: "Disb Date"},
                    {title: "Gross Amount"},
                    {title: "Net Amount"}
                ]
            default:
                return []
        }
    }, [table])

    return (
        <div
            key={id}
            {...attributes}
            contentEditable={false}
            className={classNames(styles.finAidTableContent, {
                [styles.highlighted]: selected
            })}>
            {finAidInsertable ? (
                <table className={classNames(tableStyles.table, styles.finAidTable)}>
                    <thead className={tableStyles.tableHead}>
                        <tr className={tableStyles.tableRowHeader}>
                            {columns.map((col) => (
                                <th
                                    key={col.title}
                                    className={classNames(tableStyles.tableCell, tableStyles.tableCellHeader)}>
                                    {col.title}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className={tableStyles.bodyTable}>
                        <tr className={classNames(tableStyles.tableRow)}>
                            {columns.map((col) => (
                                <td
                                    key={col.title}
                                    className={classNames(tableStyles.tableCell, tableStyles.tableCellBody)}>
                                    ###
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            ) : (
                finAidTables?.[table]
            )}

            <div className="d-none">{children}</div>
        </div>
    )
}

interface ElementProps {
    attributes: ReactPropTypes
    element: SlateElement
    updateNodes: (type: CustomElement["type"], id: string, value: any) => void
    userDateFormat?: string
    userTimeFormat?: string
    readOnly?: boolean
    fieldReadOnly?: boolean
    dataFieldInsertable: boolean
    inputFieldInsertable: boolean
    finAidInsertable: boolean
    finAidTables?: Record<"costs" | "grants" | "loans" | "disbursements", React.ReactNode>
}

const Element: React.FC<ElementProps> = React.memo((props) => {
    const {attributes, children, element} = props
    const style = {textAlign: element.align as TextAlign}

    switch (element.type) {
        case "block-quote":
            return (
                <blockquote style={style} {...attributes}>
                    {children}
                </blockquote>
            )
        case "bulleted-list":
            return <ul {...attributes}>{children}</ul>
        case "heading-one":
            return (
                <h1 style={style} {...attributes}>
                    {children}
                </h1>
            )
        case "heading-two":
            return (
                <h2 style={style} {...attributes}>
                    {children}
                </h2>
            )
        case "list-item":
            return <li {...attributes}>{children}</li>
        case "numbered-list":
            return <ol {...attributes}>{children}</ol>
        case "link":
            return (
                <a {...attributes} href={element.url}>
                    {children}
                </a>
            )
        case "embed-video":
            if (element.videoId != null) {
                return (
                    <div {...attributes} contentEditable={false}>
                        <iframe
                            src={embedVideoLink(element.videoId)}
                            aria-label="video"
                            frameBorder="0"
                            title="Video Link"></iframe>
                        {children}
                    </div>
                )
            }
            return (
                <div style={style} {...attributes}>
                    {children}
                </div>
            )
        case "image":
            return <ImageComponent {...props} />
        case "field-label":
            return <FieldLabel {...props} />
        case "field-input":
            return <FieldInput {...props} />
        case "fin-aid-table":
            return <FinAidTable {...props} />
        default:
            return (
                <div style={style} {...attributes}>
                    {children}
                </div>
            )
    }
})

interface LeafProps {
    attributes: ReactPropTypes
    leaf: SlateText
}

const Leaf: React.FC<LeafProps> = React.memo(({attributes, children, leaf}) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
})

/// endregion Element

/// region Toolbar

interface BlockButtonProps {
    format: SlateElement["type"]
    icon: React.ReactNode
    title: string
}

const BlockButton: React.FC<BlockButtonProps> = React.memo(({format, icon, title}) => {
    const editor = useSlate()
    return (
        <Tooltip title={title}>
            <span
                className={classNames(styles.toolbarButton, {
                    [styles.toolbarButtonHighlight]: isBlockActive(
                        editor,
                        format,
                        TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
                    )
                })}
                onMouseDown={(event) => {
                    event.preventDefault()
                    toggleBlock(editor, format)
                }}>
                {icon}
            </span>
        </Tooltip>
    )
})

interface MarkButtonProps {
    format: keyof SlateText
    icon: React.ReactNode
    title: string
}

const MarkButton: React.FC<MarkButtonProps> = React.memo(({format, icon, title}) => {
    const editor = useSlate()
    return (
        <Tooltip title={title}>
            <span
                className={classNames(styles.toolbarButton, {
                    [styles.toolbarButtonHighlight]: isMarkActive(editor, format)
                })}
                onMouseDown={(event) => {
                    event.preventDefault()
                    toggleMark(editor, format)
                }}>
                {icon}
            </span>
        </Tooltip>
    )
})

interface LinkButtonProps {
    icon: React.ReactNode
}

const LinkButton: React.FC<LinkButtonProps> = ({icon}) => {
    const editor = useSlate()
    const [visible, setVisible] = React.useState(false)
    const [url, setUrl] = React.useState("")

    const handleUrlChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => setUrl(e.target.value), [])

    const handleDone = React.useCallback(() => {
        try {
            if (url) {
                ReactEditor.focus(editor)
                setTimeout(() => {
                    Transforms.select(editor, editor["blurSelection"])
                    insertLink(editor, url)
                    ReactEditor.focus(editor)
                })
            }
        } catch (err) {
            console.error("insert link error", err)
        }
        setVisible(false)
    }, [editor, url])

    const handleButtonClick = React.useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault()
            if (isLinkActive(editor)) {
                unwrapLink(editor)
            } else {
                // setVisible(visible => !visible)
            }
        },
        [editor]
    )

    React.useEffect(() => {
        if (!visible) setUrl("")
    }, [visible])

    return (
        <Popover
            visible={visible}
            trigger="click"
            title={null}
            onVisibleChange={setVisible}
            overlayClassName={classNames("rte__link-input", styles.linkInput)}
            content={
                <div className={styles.linkInputContent}>
                    <Input
                        placeholder="Enter an URL..."
                        size="large"
                        value={url}
                        onChange={handleUrlChange}
                        className={styles.linkInputInput}
                    />
                    <button className={styles.linkInputButton} onClick={handleDone}>
                        Done
                    </button>
                </div>
            }>
            <Tooltip title="Insert link">
                <span
                    className={classNames(styles.linkInputIcon, {
                        [styles.linkInputIconHighlight]: isLinkActive(editor)
                    })}
                    onMouseDown={handleButtonClick}>
                    {icon}
                </span>
            </Tooltip>
        </Popover>
    )
}

interface ImageButtonProps {
    icon: React.ReactNode
}

const ImageButton: React.FC<ImageButtonProps> = ({icon}) => {
    const editor = useSlateStatic()
    const [visible, setVisible] = React.useState(false)
    const [url, setUrl] = React.useState("")

    const handleUrlChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => setUrl(e.target.value), [])

    const handleDone = React.useCallback(() => {
        try {
            if (url && isUrl(url)) {
                ReactEditor.focus(editor)
                setTimeout(() => {
                    Transforms.select(editor, editor["blurSelection"])
                    insertImage(editor, url)
                    ReactEditor.focus(editor)
                })
            }
        } catch (err) {
            console.error("insert link error", err)
        }
        setVisible(false)
    }, [editor, url])

    const handleButtonClick = React.useCallback(() => {
        // setVisible(visible => !visible)
    }, [])

    React.useEffect(() => {
        if (!visible) setUrl("")
    }, [visible])

    return (
        <Popover
            visible={visible}
            trigger="click"
            title={null}
            onVisibleChange={setVisible}
            overlayClassName={classNames("rte__link-input", styles.linkInput)}
            content={
                <div className={styles.linkInputContent}>
                    <Input
                        placeholder="Enter an URL..."
                        size="large"
                        value={url}
                        onChange={handleUrlChange}
                        className={styles.linkInputInput}
                    />
                    <button className={styles.linkInputButton} onClick={handleDone}>
                        Done
                    </button>
                </div>
            }>
            <Tooltip title="Insert image">
                <span
                    className={classNames(styles.linkInputIcon, {
                        [styles.linkInputIconHighlight]: isLinkActive(editor)
                    })}
                    onMouseDown={handleButtonClick}>
                    {icon}
                </span>
            </Tooltip>
        </Popover>
    )
}

const ImageUploadButton: React.FC<ImageButtonProps> = ({icon}) => {
    const editor = useSlateStatic()
    const [visible, setVisible] = React.useState(false)

    const uploadFileMutation = useMutation(
        async (file: RcFile) => {
            const fileExtension = (file.name.match(/\.[0-9a-z]+$/i)?.[0] || ".jpg").slice(1)
            const {url, urlForUploading} = await fileService.createSignedUrlForUploadingFile({
                fileExtension,
                isPublic: true
            })
            await axios.put(urlForUploading, file, {headers: {"Content-Type": file.type}})
            ReactEditor.focus(editor)
            setTimeout(() => {
                Transforms.select(editor, editor["blurSelection"])
                insertImage(editor, url)
                ReactEditor.focus(editor)
            })
        },
        {
            onError: (error) => handleError(error),
            onSettled: () => setVisible(false)
        }
    )

    return (
        <Popover
            visible={visible}
            trigger="click"
            title={null}
            onVisibleChange={setVisible}
            overlayClassName={classNames("rte__link-input", styles.linkInput)}
            content={
                <div>
                    <Upload
                        multiple={false}
                        beforeUpload={(file) => {
                            uploadFileMutation.mutate(file)
                            return false
                        }}>
                        <SecondaryButton title="Browse Image" loading={uploadFileMutation.isLoading} />
                    </Upload>
                </div>
            }>
            <Tooltip title="Upload image">
                <span
                    className={classNames(styles.linkInputIcon, {
                        [styles.linkInputIconHighlight]: isLinkActive(editor)
                    })}>
                    {icon}
                </span>
            </Tooltip>
        </Popover>
    )
}

interface CommandButtonProps {
    title: string
    icon: React.ReactNode
    onClick: () => void
}

const CommandButton: React.FC<CommandButtonProps> = ({title, icon, onClick}) => {
    return (
        <Tooltip title={title}>
            <span
                className={classNames(styles.toolbarButton)}
                onMouseDown={(event) => {
                    event.preventDefault()
                    onClick()
                }}>
                {icon}
            </span>
        </Tooltip>
    )
}

interface FieldLabelButtonProps {
    icon: React.ReactNode
}

const FieldLabelButton: React.FC<FieldLabelButtonProps> = ({icon}) => {
    const editor = useSlateStatic()

    const handleInsertField = React.useMemo(
        () =>
            memoize((field: string, label: string) => () => {
                try {
                    ReactEditor.focus(editor)
                    setTimeout(() => {
                        Transforms.select(editor, editor["blurSelection"])
                        insertFieldLabel(editor, field, label)
                        ReactEditor.focus(editor)
                    })
                } catch (err) {
                    console.error("insert field error", err)
                }
            }),
        [editor]
    )

    const menu = (
        <Menu className={styles.dropdownMenu}>
            <div className={styles.dropdownTitle}>Insert Data Field</div>
            {studentDataValues.map((group) => (
                <Menu.ItemGroup title={group.label} key={group.label}>
                    {group.options.map(({id, name}) => (
                        <Menu.Item key={id} onClick={handleInsertField(id, name)}>
                            {name}
                        </Menu.Item>
                    ))}
                </Menu.ItemGroup>
            ))}
        </Menu>
    )

    return (
        <Dropdown overlay={menu} trigger={["click"]}>
            <Tooltip title="Insert data field">
                <span className={styles.linkInputIcon}>{icon}</span>
            </Tooltip>
        </Dropdown>
    )
}

interface FieldInputButtonProps {
    icon: React.ReactNode
}

const FieldInputButton: React.FC<FieldInputButtonProps> = ({icon}) => {
    const editor = useSlateStatic()

    const handleInsertField = React.useMemo(
        () =>
            memoize((input: "text" | "date" | "time") => () => {
                try {
                    ReactEditor.focus(editor)
                    setTimeout(() => {
                        Transforms.select(editor, editor["blurSelection"])
                        insertFieldInput(editor, input)
                        ReactEditor.focus(editor)
                    })
                } catch (err) {
                    console.error("insert field error", err)
                }
            }),
        [editor]
    )

    const menu = (
        <Menu>
            <div className={styles.dropdownTitle}>Insert Input Field</div>
            <Menu.Item onClick={handleInsertField("text")}>Text field</Menu.Item>
            <Menu.Item onClick={handleInsertField("date")}>Date</Menu.Item>
            <Menu.Item onClick={handleInsertField("time")}>Time</Menu.Item>
        </Menu>
    )

    return (
        <Dropdown overlay={menu} trigger={["click"]}>
            <Tooltip title="Insert input field">
                <span className={styles.linkInputIcon}>{icon}</span>
            </Tooltip>
        </Dropdown>
    )
}

interface FinAidFieldButtonProps {
    icon: React.ReactNode
}

const FinAidFieldButton: React.FC<FinAidFieldButtonProps> = ({icon}) => {
    const editor = useSlateStatic()

    const handleInsertFinAidField = React.useMemo(
        () =>
            memoize(
                (input: "today_date" | "bbay_start" | "bbay_end" | "costs" | "grants" | "loans" | "disbursements") =>
                    () => {
                        try {
                            ReactEditor.focus(editor)
                            setTimeout(() => {
                                Transforms.select(editor, editor["blurSelection"])
                                if (input === "today_date") {
                                    insertFieldLabel(editor, input, "Today's Date")
                                } else if (input === "bbay_start") {
                                    insertFieldLabel(editor, input, "BBAY Start Date")
                                } else if (input === "bbay_end") {
                                    insertFieldLabel(editor, input, "BBAY End Date")
                                } else {
                                    insertFinAidTable(editor, input)
                                }
                                ReactEditor.focus(editor)
                            })
                        } catch (err) {
                            console.error("insert field error", err)
                        }
                    }
            ),
        [editor]
    )

    const menu = (
        <Menu>
            <div className={styles.dropdownTitle}>Insert Financial Aid Table</div>
            <Menu.Item onClick={handleInsertFinAidField("today_date")}>Today's date</Menu.Item>
            <Menu.Item onClick={handleInsertFinAidField("bbay_start")}>BBAY start date</Menu.Item>
            <Menu.Item onClick={handleInsertFinAidField("bbay_end")}>BBAY end date</Menu.Item>
            <Menu.Item onClick={handleInsertFinAidField("costs")}>Direct Costs</Menu.Item>
            <Menu.Item onClick={handleInsertFinAidField("grants")}>Grants & Scholarships</Menu.Item>
            <Menu.Item onClick={handleInsertFinAidField("loans")}>Loans</Menu.Item>
            <Menu.Item onClick={handleInsertFinAidField("disbursements")}>Disbursements</Menu.Item>
        </Menu>
    )

    return (
        <Dropdown overlay={menu} trigger={["click"]}>
            <Tooltip title="Insert financial aid table">
                <span className={styles.linkInputIcon}>{icon}</span>
            </Tooltip>
        </Dropdown>
    )
}

/// endregion Toolbar

/// region Handle paster html

export const deserialize = (el) => {
    if (el.nodeType === 3) {
        return el.textContent
    } else if (el.nodeType !== 1) {
        return null
    } else if (el.nodeName === "BR") {
        return "\n"
    }

    const {nodeName} = el
    let parent = el
    let children = []

    if (nodeName === "PRE") {
        if (el.childNodes[0] && el.childNodes[0].nodeName === "CODE") {
            parent = el.childNodes[0]
        }
        children = Array.from(parent.childNodes).map((e: any) => {
            return {
                text: e.textContent,
                code: true
            }
        })
    } else {
        children = Array.from(parent.childNodes).map(deserialize).flat()
    }

    if (el.nodeName === "BODY") {
        return jsx("fragment", {}, children)
    }

    if (ELEMENT_TAGS[nodeName]) {
        const attrs = ELEMENT_TAGS[nodeName](el)
        return jsx("element", attrs, children)
    }

    if (TEXT_TAGS[nodeName]) {
        const attrs = TEXT_TAGS[nodeName](el)
        return children.map((child) => jsx("text", attrs, child))
    }

    return children
}

const withHtml = (editor) => {
    const {insertData, isInline, isVoid} = editor

    editor.isInline = (element) => {
        return element.type === "link" ? true : isInline(element)
    }

    editor.isVoid = (element) => {
        return element.type === "image" ? true : isVoid(element)
    }

    editor.insertData = (data) => {
        const html = data.getData("text/html")

        if (html) {
            const parsed = new DOMParser().parseFromString(html, "text/html")
            const fragment = deserialize(parsed.body)
            Transforms.insertFragment(editor, fragment)
            return
        }

        insertData(data)
    }

    return editor
}

/// endregion Handle paster html

interface RichTextEditorProps {
    className?: string
    wrapperClassName?: string
    value?: Descendant[]
    onChange?: (value: Descendant[]) => void
    placeholder?: string
    userDateFormat?: string
    userTimeFormat?: string
    readOnly?: boolean
    editorType?: EditorType
    fieldReadOnly?: boolean
    dataFieldInsertable?: boolean
    inputFieldInsertable?: boolean
    finAidInsertable?: boolean
    finAidTables?: Record<"costs" | "grants" | "loans" | "disbursements", React.ReactNode>
}

export interface RichTextEditorRef {
    focus: () => void
}

const RichTextEditor: React.ForwardRefRenderFunction<RichTextEditorRef, RichTextEditorProps> = (
    {
        className,
        wrapperClassName,
        value = INITIAL_RTE_CONTENT,
        onChange,
        userDateFormat,
        userTimeFormat,
        placeholder = "Write something…",
        readOnly,
        editorType,
        fieldReadOnly,
        dataFieldInsertable = false,
        inputFieldInsertable = false,
        finAidInsertable = false,
        finAidTables
    },
    ref
) => {
    // Create a Slate editor object that won't change across renders.
    const editor = React.useMemo(() => {
        let editor = withHistory(withReact(createEditor()))
        editor = withHtml(editor)
        editor = withLinks(editor)
        editor = withImages(editor)

        editor.isInline = (element) => {
            return ["link", "field-label", "field-input"].includes(element.type)
        }

        editor.isVoid = (element) => {
            return ["image", "embed-video", "field-label", "field-input", "fin-aid-table"].includes(element.type)
        }

        return editor
    }, [])

    const handleValueChange = React.useCallback(
        (value: Descendant[]) => {
            onChange?.(value)
        },
        [onChange]
    )

    const handleUpdateNodes = React.useCallback(
        (type: "field-label" | "field-input", id: string, value: any) => {
            const updateValue = (node: Descendant) => {
                if (SlateElement.isElement(node)) {
                    if (node.type === type && node.id === id) {
                        return {...node, value}
                    }
                    if (node.children?.length) {
                        return {
                            ...node,
                            children: node.children.map(updateValue)
                        }
                    }
                }
                return node
            }
            const newValue = value.map(updateValue)
            handleValueChange(newValue)
        },
        [value, handleValueChange]
    )

    const renderElement = React.useCallback(
        (props) => (
            <Element
                {...props}
                {...{
                    updateNodes: handleUpdateNodes,
                    userDateFormat,
                    userTimeFormat,
                    readOnly,
                    fieldReadOnly,
                    dataFieldInsertable,
                    inputFieldInsertable,
                    finAidInsertable,
                    finAidTables
                }}
            />
        ),
        [
            handleUpdateNodes,
            readOnly,
            fieldReadOnly,
            dataFieldInsertable,
            inputFieldInsertable,
            finAidInsertable,
            finAidTables
        ]
    )

    const renderLeaf = React.useCallback((props) => <Leaf {...props} />, [])

    React.useImperativeHandle(
        ref,
        () => ({
            focus: () => {
                // Moving cursor to the end of the document
                ReactEditor.focus(editor)
                Transforms.select(editor, Editor.end(editor, []))
            }
        }),
        [editor]
    )

    const undo = React.useCallback(() => editor.undo(), [editor])
    const redo = React.useCallback(() => editor.redo(), [editor])
    const focus = React.useCallback(() => {
        // Moving cursor to the end of the document
        ReactEditor.focus(editor)
        Transforms.select(editor, Editor.end(editor, []))
    }, [editor])

    // https://github.com/ianstormtaylor/slate/issues/4689
    // https://github.com/ianstormtaylor/slate/issues/4710
    editor.children = value

    return (
        <div className={classNames(styles.editorWrapper, wrapperClassName)}>
            <Slate editor={editor} value={value} onChange={handleValueChange}>
                {!readOnly && (
                    <div className={classNames("rte__toolbar", styles.toolbar)}>
                        {dataFieldInsertable && <FieldLabelButton icon={<IconFieldLabel width={24} height={24} />} />}
                        {inputFieldInsertable && <FieldInputButton icon={<IconFieldInput width={24} height={24} />} />}
                        {finAidInsertable && <FinAidFieldButton icon={<MdPostAdd size={20} />} />}
                        <div className={styles.toolbarDivider} />
                        <CommandButton icon={<MdUndo size={20} />} onClick={undo} title="Undo" />
                        <CommandButton icon={<MdRedo size={20} />} onClick={redo} title="Redo" />
                        <div className={styles.toolbarDivider} />
                        {editorType !== EditorType.Html && (
                            <>
                                <MarkButton format="bold" icon={<MdFormatBold size={20} />} title="Bold" />
                                <MarkButton format="italic" icon={<MdFormatItalic size={20} />} title="Italic" />
                                <MarkButton
                                    format="underline"
                                    icon={<MdFormatUnderlined size={20} />}
                                    title="Underline"
                                />
                                <div className={styles.toolbarDivider} />
                                <BlockButton format="left" icon={<MdFormatAlignLeft size={20} />} title="Align Left" />
                                <BlockButton
                                    format="center"
                                    icon={<MdFormatAlignCenter size={20} />}
                                    title="Align Center"
                                />
                                <BlockButton
                                    format="right"
                                    icon={<MdFormatAlignRight size={20} />}
                                    title="Align Right"
                                />
                                <BlockButton
                                    format="justify"
                                    icon={<MdFormatAlignJustify size={20} />}
                                    title="Align Justify"
                                />
                                <div className={styles.toolbarDivider} />
                                <MarkButton format="code" icon={<MdCode size={20} />} title="Insert code" />
                                <BlockButton format="heading-one" icon={<MdLooksOne size={20} />} title="Heading 1" />
                                <BlockButton format="heading-two" icon={<MdLooksTwo size={20} />} title="Heading 2" />
                                {/* <BlockButton format="block-quote" icon={<MdFormatQuote size={20} />} title="Insert quote" /> */}
                                <div className={styles.toolbarDivider} />
                                <BlockButton
                                    format="numbered-list"
                                    icon={<MdFormatListNumbered size={20} />}
                                    title="Insert number list"
                                />
                                <BlockButton
                                    format="bulleted-list"
                                    icon={<MdFormatListBulleted size={20} />}
                                    title="Insert bulleted list"
                                />
                                <div className={styles.toolbarDivider} />
                                <LinkButton icon={<MdInsertLink size={20} />} />
                                <ImageButton icon={<MdImage size={20} />} />
                                <ImageUploadButton icon={<MdImage size={20} />} />
                            </>
                        )}
                    </div>
                )}

                {!readOnly && isEmptyContent(value) && <div className={styles.placeholder}>{placeholder}</div>}

                <Editable
                    readOnly={!!readOnly}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    className={classNames("rte__editor", styles.editor, editorType, className)}
                    spellCheck={false}
                    autoFocus={false}
                    onBlur={() => (editor["blurSelection"] = editor.selection)}
                    onKeyDown={(event) => {
                        for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event as any)) {
                                event.preventDefault()
                                const mark = HOTKEYS[hotkey]
                                toggleMark(editor, mark)
                            }
                        }
                    }}
                />
            </Slate>

            <div className={styles.spacePadding} onClick={focus} />
        </div>
    )
}

export default React.forwardRef(RichTextEditor)
