import {get} from "lodash"
import {BaseService} from "services"
import {ProfileIDCard} from "types/user"

export default class ProfileIDCardsService extends BaseService {
    async createToken(profileId: number): Promise<{token: string}> {
        const response = await this.post("/v1/profile-id-cards/create-token", {profileId})
        return get(response, "data.data", {})
    }

    async getProfiles(userId: number): Promise<ProfileIDCard[]> {
        const response = await this.post("/v1/profile-id-cards/list", {userId})
        return get(response, "data.data", [])
    }
}
