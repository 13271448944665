import React, {useReducer} from "react"
import {Checkbox} from "antd"
import {useTranslation} from "react-i18next"
import {cloneDeep} from "lodash"
import {BaseButton} from "components/buttons"
import {Icon} from "components/Icon"
import {checkHasPermissionToUpdateAdvisor, getFullNameWithEnrollmentId, handleError} from "helpers"
import {studentStaffContactsServiceV3} from "services"
import styles from "./AdvisorsPopup.module.css"
import {useModel} from "hooks"

type Staff = {
    staffProfileId: number
    firstName: string
    middleName: string
    lastName: string
    photo: string
    studentStaffContactId: number
    studentStaffContactRoleId: number
    studentProfileId: number
    isDefault: number
    add?: boolean
    remove?: boolean
    edit?: boolean
}

type State = {
    isSubmitting: boolean
}

type Props = {
    profileIds: number[]
    departmentId: number
    staffs: any[]
    changeStaffs: (staffs: any[]) => void
    getStaffs: () => Promise<void>
    onClose: (reload?: boolean) => void
    addMultiple?: boolean
    type?: string
}

export function AdvisorBody(props: Props) {
    function reducer(state, action) {
        return {...state, ...action}
    }
    const initialState: State = {
        isSubmitting: false
    }
    const [state, dispatch] = useReducer(reducer, initialState)
    const model = useModel()
    const {isSubmitting} = state
    const {type, addMultiple, profileIds, departmentId, staffs, changeStaffs, getStaffs, onClose} = props
    const {t} = useTranslation(["common"])

    function getIsDefault(staff: Staff, index: number) {
        if (staff.isDefault) {
            return (
                <div className={styles.primaryLabelWrap}>
                    <label className={styles.defaultLabel}>Primary</label>
                </div>
            )
        }
        if (staff.studentStaffContactId && !staff.remove && !staff.isDefault && !addMultiple) {
            return (
                <Checkbox
                    checked={false}
                    onChange={() => {
                        makeDefault(index)
                    }}>
                    Make Primary
                </Checkbox>
            )
        }
        if (staff.add) {
            return (
                <Checkbox
                    checked={false}
                    onChange={() => {
                        makeDefault(index)
                    }}>
                    Make Primary
                </Checkbox>
            )
        }
    }

    function addAdvisor(index: number) {
        const copyStaffs = cloneDeep(staffs)
        copyStaffs[index].add = true
        copyStaffs[index].remove = false
        changeStaffs(copyStaffs)
    }

    async function addAdvisorQuery(staffsToAdd: Staff[]) {
        const payload: any[] = []
        let response: any
        try {
            const newStaffs: any[] = staffsToAdd.map((staff) => ({
                departmentId,
                contactProfileId: staff.staffProfileId,
                isDefault: staff.isDefault ? 1 : 0
            }))
            profileIds.forEach((profileId) => {
                newStaffs.forEach((staff) => payload.push({...staff, profileId}))
            })
            if (type === "reassign") {
                response = await studentStaffContactsServiceV3.reAssignAdvisor(payload)
            } else {
                response = await studentStaffContactsServiceV3.create(payload)
            }

            return response
        } catch (error) {
            handleError(error)
        }
    }

    function removeAdvisor(index: number) {
        const copyStaffs = cloneDeep(staffs)
        copyStaffs[index].add = false
        copyStaffs[index].remove = true
        copyStaffs[index].isDefault = false
        changeStaffs(copyStaffs)
    }

    async function removeAdvisorQuery(staffsToRemove: Staff[]) {
        try {
            const studentStaffContactIds = staffsToRemove.map((staff) => staff.studentStaffContactId)
            const response = await studentStaffContactsServiceV3.delete(studentStaffContactIds)
            return response
        } catch (error) {
            handleError(error)
        }
    }

    async function editAdvisorQuery(staffsToEdit: Staff[]) {
        try {
            const editStaffContacts = staffsToEdit.map(({studentStaffContactId, isDefault}) => ({
                studentStaffContactId,
                isDefault
            }))
            const response = await studentStaffContactsServiceV3.updateBulk(editStaffContacts)
            return response
        } catch (error) {
            handleError(error)
        }
    }

    async function updateStaffs() {
        const addAdvisors = staffs.filter((staff) => staff.add && !staff.studentStaffContactId)
        const removeAdvisors = staffs.filter((staff) => staff.remove && staff.studentStaffContactId)
        const editAdvisors = staffs.filter((staff) => staff.edit && staff.studentStaffContactId)
        const updatedStaffs = []
        if (addAdvisors.length) {
            updatedStaffs.push(addAdvisorQuery(addAdvisors))
        }
        if (removeAdvisors.length) {
            updatedStaffs.push(removeAdvisorQuery(removeAdvisors))
        }
        if (editAdvisors.length) {
            updatedStaffs.push(editAdvisorQuery(editAdvisors))
        }
        if (!updatedStaffs.length) {
            onClose()
            return
        }
        try {
            dispatch({isSubmitting: true})
            await Promise.all(updatedStaffs)
            await getStaffs()
            onClose(true)
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isSubmitting: false})
        }
    }

    function makeDefault(index: number) {
        const copyStaffs: Staff[] = cloneDeep(staffs)
        copyStaffs.forEach((staff) => {
            if (staff.isDefault) {
                staff.isDefault = 0
                staff.edit = true
            }
        })
        copyStaffs[index].isDefault = 1
        copyStaffs[index].edit = true
        changeStaffs(copyStaffs)
    }

    const renderAdvisorInfos = () => {
        return (
            <div className={styles.tableWrapper}>
                <table className={styles.usersTable}>
                    <thead className={styles.titleColor}>
                        <tr>
                            <th>{t("advisorsPopup.staff")}</th>
                            <th className={styles.columnSize}>{t("advisorsPopup.title")}</th>
                            <th className={styles.columnSize}>{t("advisorsPopup.default")}</th>
                            <th>{t("advisorsPopup.role")}</th>
                            <th>{t("advisorsPopup.select")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {staffs.map((staff, index) => (
                            <tr
                                key={index}
                                className={styles.tableRow}
                                onClick={
                                    (staff.studentStaffContactId && !staff.remove && !addMultiple) || staff.add
                                        ? () => removeAdvisor(index)
                                        : () => addAdvisor(index)
                                }>
                                <td>
                                    <div className={styles.fullnameWrap}>
                                        <img src={staff.photo} alt="" className={styles.avatar} />
                                        <p className={styles.fullname}>{getFullNameWithEnrollmentId(staff)}</p>
                                    </div>
                                </td>
                                <td className={styles.textAlignLeft}>{staff.title}</td>
                                <td>
                                    <div className={styles.defaultWrap}>{getIsDefault(staff, index)}</div>
                                </td>
                                <td className={styles.textAlignLeft}>{staff.roleName}</td>
                                <td>
                                    {(staff.studentStaffContactId && !staff.remove && !addMultiple) || staff.add ? (
                                        <div className={styles.checkIconWrap} onClick={() => removeAdvisor(index)}>
                                            <Icon icon="CHECKMARK_CIRCLE" />
                                        </div>
                                    ) : (
                                        <div className={styles.checkIconWrap} onClick={() => addAdvisor(index)}>
                                            <Icon icon="CHECK_CIRCLE" color="#E6E6E6" />
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                        {!staffs.length && (
                            <tr className={styles.tableRow}>
                                <td colSpan={5}>
                                    <p className={styles.emptyRowData}>{t("common:message.notResultFound")}</p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        )
    }

    const hasPermissionToUpdateAdvisor = checkHasPermissionToUpdateAdvisor(departmentId, model) || model.isAdmin()

    return (
        <>
            {renderAdvisorInfos()}
            {hasPermissionToUpdateAdvisor && (
                <div className={styles.saveBtnWrapper}>
                    <BaseButton
                        className={styles.saveButton}
                        title="SAVE"
                        onClick={updateStaffs}
                        loading={isSubmitting}
                    />
                </div>
            )}
        </>
    )
}
