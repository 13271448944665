/* eslint-disable react-hooks/exhaustive-deps */
import debounce from "debounce-promise"
import React from "react"
import {useTranslation} from "react-i18next"
import {taskService} from "services"
import {KlassDropdownAsync, KlassDropdownAsyncProps} from "../Select"
import {Task} from "types/tasks"

type Props = Partial<KlassDropdownAsyncProps> & {
    profileId: number
    seletedTaskIds?: number[]
}

const TaskSelect: React.FC<Props> = ({profileId, seletedTaskIds, ...props}) => {
    const {t} = useTranslation(["common"])

    const search = React.useCallback(
        debounce(async (search: string = "") => {
            const {data} = await taskService.autocomplete({search, signerProfileIds: [profileId]})
            const dataFiltered = data.filter((item) => !seletedTaskIds.includes(item.taskId))
            return dataFiltered
        }, 500),
        [profileId]
    )

    return (
        <KlassDropdownAsync
            {...props}
            placeholder={props.placeholder || t("selectField.placeholder")}
            loadOptions={search}
            defaultOptions={true}
            valueKey="taskId"
            labelKey="name"
            getOptionLabel={(option: Task) => option.name}
        />
    )
}

export default TaskSelect
