import BaseService from "./Base"
import {get} from "lodash"

class FerpaService extends BaseService {
    getAllFerpaAcademicFields = async (params = {}) => {
        const response = await this.post("/v1/ferpa-academic-fields/list", params)
        return get(response, "data", {})
    }

    createFerpaAcademicFields = async (params = {}) => {
        const response = await this.post("/v1/ferpa-academic-fields/create", params)
        return get(response, "data", {})
    }

    createStudentRelationshipAddress = async (params = {}) => {
        const response = await this.post("/v1/student-relationship-addresses/create", params)
        return get(response, "data.data", {})
    }

    createStudentRelationshipRequest = async (params = {}) => {
        const response = await this.post("/v1/student-relationship-request/create", params)
        return get(response, "data.data", {})
    }

    updateStudentRelationshipAddress = async (params = {}) => {
        const response = await this.post("/v1/student-relationship-addresses/edit", params)
        return get(response, "data.data", {})
    }

    deleteStudentRelationshipAddress = async (params = {}) => {
        const response = await this.post("/v1/student-relationship-addresses/delete", params)
        return get(response, "data.data", {})
    }

    listStudentRelationshipAddress = async (params = {}) => {
        const response = await this.post("/v1/student-relationship-addresses/list", params)
        return get(response, "data", {})
    }

    listFerpaStudents = async (params = {}) => {
        const response = await this.post("/v1/ferpa-students/list", params)
        return get(response, "data.data", {})
    }

    createFerpaStudents = async (params = {}) => {
        const response = await this.post("/v1/ferpa-students/create", params)
        return get(response, "data.data", {})
    }

    updateFerpaStudents = async (params = {}) => {
        const response = await this.post("/v1/ferpa-students/edit", params)
        return get(response, "data.data", {})
    }

    createFerpaStudentsAcademicFields = async (params = {}) => {
        const response = await this.post("/v1/ferpa-students-academic-fields/create", params)
        return get(response, "data.data", {})
    }

    createFerpaStudentsRequestAcademicFields = async (params = {}) => {
        const response = await this.post("/v1/ferpa-students-request-academic-fields/create", params)
        return get(response, "data.data", {})
    }

    updateAcademicFields = async (params = {}) => {
        const response = await this.post("/v1/ferpa-students/update-academic-fields", params)
        return get(response, "data.data", {})
    }

    listStudentRelationshipRequest = async (params = {}) => {
        const response = await this.post("/v1/student-relationship-request/list", params)
        return get(response, "data", {})
    }

    updateStudentRelationshipRequest = async (params = {}) => {
        const response = await this.post("/v1/student-relationship-request/edit", params)
        return get(response, "data.data", {})
    }

    deleteStudentRelationshipRequest = async (params = {}) => {
        const response = await this.post("/v1/student-relationship-request/delete", params)
        return get(response, "data.data", {})
    }

    updateRequestAcademicFields = async (params = {}) => {
        const response = await this.post("/v1/student-relationship-request/update-academic-fields", params)
        return get(response, "data.data", {})
    }
}
export default FerpaService
