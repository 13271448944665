/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useCallback} from "react"
import {useTranslation} from "react-i18next"
import debounce from "debounce-promise"
import {Search} from "components/inputs/Search"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {departmentSubunitService, taskService} from "services"
import {handleError} from "helpers"
import {TaskType, TaskSubtype} from "types/tasks"
import {Icon} from "components/Icon"
import styles from "./LibraryTaskHeader.module.css"
import {get, keyBy} from "lodash"
import {DepartmentSubunit} from "types/departments"
import {UNLIMITED_PAGE_SIZE} from "data/constants"

function LibraryTaskHeader(props) {
    const [search, setSearch] = useState("")
    const {t} = useTranslation(["tasks"])
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        isLoading,
        librarySelectedTask,
        onChangeLibrarySelectedTask,
        orderField,
        isLoadedTableFuncs,
        getCurrentPage,
        getCurrentPageSize
    } = props

    const [departmentSubunits, setDepartmentSubunits] = useState<DepartmentSubunit[]>([])

    useEffect(() => {
        ;(async function loadDepartmentSubunits() {
            try {
                const {data} = await departmentSubunitService.getSubunits({
                    range: {pageSize: UNLIMITED_PAGE_SIZE, page: 1}
                })
                setDepartmentSubunits(data)
            } catch (error) {
                handleError(error)
                return []
            }
        })()
    }, [])

    const getFields = () => {
        return [
            t("taskDetail.libraryHeader.name"),
            t("taskDetail.libraryHeader.type"),
            t("tasks.department"),
            t("taskDetail.libraryHeader.assignedTo")
        ]
    }

    const getPageTitle = () => {
        return t("tasks.task")
    }

    const getColumns = useCallback(() => {
        return [
            {
                title: t("taskDetail.libraryHeader.name"),
                field: "name",
                sortable: true
            },
            {
                title: t("taskDetail.libraryHeader.type"),
                field: "type",
                orderField: "type",
                sortable: true,
                render: (type: TaskType) => {
                    switch (type) {
                        case TaskType.DIGITAL_DOCUMENT:
                            return "Digital Document"
                        case TaskType.SCAN_UPLOAD:
                            return "Scan Upload"
                        case TaskType.FROM_LIBRARY:
                            return "Library"
                        case TaskType.FORM:
                            return "Web Form"
                        case TaskType.CUSTOM:
                            return "Custom"
                        default:
                            return ""
                    }
                }
            },
            {
                title: t("tasks.department"),
                field: "departmentId",
                style: {minWidth: "120px"},
                render: (departmentId: number) =>
                    departmentSubunits.find((subunit) => subunit.subunitId === departmentId)?.name
            },
            {
                title: t("taskDetail.libraryHeader.assignedTo"),
                field: "children"
            }
        ]
    }, [departmentSubunits])

    const getParams = (filter = {}) => {
        const page = getCurrentPage()
        const pageSize = getCurrentPageSize()
        const {orderField} = props
        const params: any = {
            filter: {search, ...filter, subtype: TaskSubtype.LIBRARY},
            range: {
                page,
                pageSize
            }
        }
        params.sort = {
            orderBy: orderField.field || "name",
            orderDir: orderField.order || "asc"
        }

        return params
    }

    const getData = async (filter = {}) => {
        const {dispatch} = props
        dispatch({isLoading: true})
        if (librarySelectedTask) {
            dispatch({data: [librarySelectedTask], total: 1, isLoading: false})
        } else {
            try {
                const params = getParams(filter)
                const {data, total} = await taskService.getAll(params)
                const newData = data.map((task: any) => ({...task, cellTableStyle: styles.tabelCellStyle}))
                dispatch({data: newData, total})
            } catch (e) {
                handleError(e)
            } finally {
                dispatch({isLoading: false})
            }
        }
    }

    const onClickEdit = () => {}

    const onClickRowItem = (row) => {
        onChangeLibrarySelectedTask(row)
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [getColumns])

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getData()
        }
    }, [isLoadedTableFuncs, page, pageSize, librarySelectedTask, orderField])

    const onChangeSearch = (search) => {
        setSearch(search)
        debounceGetLibraryTask({search})
    }

    const onCancelSelectedTask = () => {
        onChangeLibrarySelectedTask(null)
    }

    const debounceGetLibraryTask = useCallback(debounce(getData, 500), [])

    return (
        <div className={styles.wrap}>
            {librarySelectedTask ? (
                <div className={styles.seletedTaskWrap}>
                    <div className={styles.backBtn}>
                        <div className={styles.backBtnWrap} onClick={onCancelSelectedTask}>
                            <Icon icon="BACK" className={styles.backIcon} color="#ff349b" />
                        </div>
                    </div>
                    <p className={styles.selectedTaskTitle}>Selected Task</p>
                </div>
            ) : (
                <>
                    <p className={styles.title}>Choose a task from library</p>
                    <Search className={styles.search} value={search} onChange={onChangeSearch} placeholder="Search" />
                </>
            )}
            <KlassappTableHeader
                isShowAction={false}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                data={data}
                isLoading={isLoading}
                fields={fields}
                allFields={allFields}
                orderField={orderField}
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onClickSortColumn={props.onClickSortColumn}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </div>
    )
}

export default KlassappTableHOC(LibraryTaskHeader)
