import React from "react"
import cx from "classnames"
import {Icon} from "components/Icon"
import styles from "./Toast.module.css"

type Props = {
    type: "error" | "success" | "warning" | "info"
}

export function ToastIcon(props: Props) {
    const {type} = props

    const getIconByType = () => {
        switch (type) {
            case "error":
                return <Icon icon="ALERT_CIRCLE" color="#DF1642" className={styles.icon} />
            case "success":
                return <Icon icon="CHECKMARK_CIRCLE" color="#18a957" className={styles.icon} />
            case "warning":
                return <Icon icon="WARNING_TRIANGLE_FILL" color="#ff8a38" className={styles.icon} />
            case "info":
                return <Icon icon="INFO" color="#1e90ff" className={styles.icon} />
            default:
                return null
        }
    }

    return (
        <div
            className={cx(styles.iconWrap, {
                [styles.error]: type === "error",
                [styles.success]: type === "success",
                [styles.warning]: type === "warning",
                [styles.info]: type === "info"
            })}>
            {getIconByType()}
        </div>
    )
}
