import React, {useMemo} from "react"
import {Row, Col, Checkbox} from "antd"
import {KlassDropdown} from "components/Select"
import {FormLabel} from "components/Form"
import styles from "./UserDocumentVisible.module.css"
import {UserType} from "types/tasks"

export function UserDocumentVisible(props) {
    const {taskData, errors, onChangeData, canEdit} = props
    const {signers} = taskData

    const options = useMemo(
        () =>
            signers.map((signer) => {
                if (signer.userType === UserType.OTHERS) {
                    return {...signer, label: `${signer.label} (Document will be saved to related user)`}
                }
                return signer
            }),
        [signers]
    )

    return (
        <Row>
            <Col span={16}>
                <div>
                    <FormLabel
                        label="Save completed PDF to Participant's User Document Table"
                        isRequired
                        className={styles.title}
                    />
                    <KlassDropdown
                        options={options}
                        value={taskData.userDocumentInfo}
                        labelKey="label"
                        onChange={(newValue) => onChangeData("userDocumentInfo", newValue)}
                        error={!!errors?.userDocumentInfo}
                        readOnly={!canEdit}
                    />
                    <Checkbox
                        className={styles.checkbox}
                        checked={taskData.visibleForUser}
                        onChange={({target: {checked}}) => onChangeData("visibleForUser", checked)}
                        disabled={!canEdit}>
                        <span className={styles.checkboxTitle}>
                            Make saved document visible to selected participant in User Document Table
                        </span>
                    </Checkbox>
                </div>
            </Col>
        </Row>
    )
}
