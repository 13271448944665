import React, {useState, useEffect} from "react"
import {Alert} from "antd"

export const InternetConnectionStatus = () => {
    const [isOnline, setIsOnline] = useState(window.navigator.onLine)

    useEffect(() => {
        const updateOnlineStatus = () => {
            setIsOnline(window.navigator.onLine)
        }

        window.addEventListener("online", updateOnlineStatus)
        window.addEventListener("offline", updateOnlineStatus)

        updateOnlineStatus()
    }, [])

    return !isOnline ? (
        <Alert
            message="Sorry, it seems like there's a problem with your internet connection. Please check your connection and try again later."
            banner
        />
    ) : (
        <></>
    )
}
