/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useRef, useEffect} from "react"
import cx from "classnames"
import {v4 as uuidV4} from "uuid"
import {useHistory} from "react-router-dom"
import {Model} from "Model"
import {Icon} from "components/Icon"
import styles from "./TaskObservation.module.css"
import {BaseButton, BaseLoading, BaseTextArea} from "components"
import {DefaultDataType, DigitalDocumentFieldSetUp} from "types/tasks"
import {get, isEmpty, keys} from "lodash"
import {handleError, loadImage, toastError} from "helpers"
import {taskService} from "services"
import {ObservationField} from "./ObservationField"
import {Iframe} from "components/Iframe"
import {stringifyUrl} from "query-string"

type Props = {
    model: Model
    isWebForm?: boolean
    taskData: any
    taskUser: any
    taskUserId?: any
}

const sendIframeObject = {
    type: "",
    data: {}
}

export function TaskObservation(props: Props) {
    const {model, isWebForm, taskData, taskUser, taskUserId} = props
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [reason, setReason] = useState("")
    const [fields, setFields] = useState([])
    const [remarks, setRemarks] = useState({})
    const [webFormUrl, setWebFormUrl] = useState("")
    const pageRef = useRef(null)
    const pagesRef = useRef(null)
    const history = useHistory()
    const iframeRef = useRef<HTMLIFrameElement>()
    const margin = 10
    const subTaskAnswers = taskUser?.answers
    const signerId = taskUser?.signerId

    useEffect(() => {
        if (!isWebForm) {
            initFieldData()
        }
    }, [taskUser])

    useEffect(() => {
        if (isWebForm) {
            const handler = (e) => {
                const data = JSON.parse(e.data)
                if (!data?.error && data.message === "submit") {
                    const url = stringifyUrl(
                        {
                            url: `/tasks/detail`,
                            query: {
                                id: taskData.id,
                                type: taskData.type
                            }
                        },
                        {skipNull: true}
                    )
                    history.push(url)
                }
            }
            window.addEventListener("message", handler)
            return () => window.removeEventListener("message", handler)
        }
    }, [])

    useEffect(() => {
        if (isWebForm) {
            setWebFormUrl(
                `${process.env.REACT_APP_WEBFORMS_BASE_URL}/sign/${taskUserId}/?token=${model.authToken}&profileId=${model.profileId}&isReopen=1`
            )
            setIsLoading(true)
        }
    }, [taskUserId])

    const initFieldData = async () => {
        try {
            setIsLoading(true)
            await Promise.all(taskData.digitalDocument.pages.map((image) => loadImage(image)))
            const {scaleHeight, scaleWidth, pageHeight} = getScaleInfo()
            const newFieldValues = []
            const newFields = taskData.digitalDocument.fields
                .filter((field) => field.signerIds.includes(signerId))
                .map((field) => {
                    const placeInformation = field.placeInformation[0]
                    const fieldValues = field.fieldValues
                    field.required = !!field.required
                    const {signatureType} = field
                    if (placeInformation) {
                        const {height, left, top, width} = placeInformation
                        field.placeInformation = scalePlaceInformation(
                            {height, left, top, width},
                            {scaleHeight, scaleWidth},
                            false
                        )
                        field.placeInformation.top =
                            field.placeInformation.top + (field.page - 1) * (pageHeight + margin)
                    }
                    switch (field.type) {
                        case DigitalDocumentFieldSetUp.SIGNATURE: {
                            const answer = get(subTaskAnswers, [field.id], "")
                            const hasSigned = answer === "signed"
                            field.isSigned = !!answer
                            field.value = hasSigned ? taskUser.signature : null
                            const extraInfo = {
                                isSigned: true,
                                date: hasSigned ? taskUser.submittedAt : null
                            }
                            initSignature(field, {
                                placeInformation,
                                signatureType,
                                newFieldValues,
                                scaleHeight,
                                scaleWidth,
                                parentId: field.id,
                                extraInfo,
                                pageHeight
                            })
                            break
                        }
                        case DigitalDocumentFieldSetUp.INITIAL: {
                            if (fieldValues) {
                                let answer = get(subTaskAnswers, [field.id], "")
                                answer = (answer || "").split(",")
                                const initialFieldValues = fieldValues.map((fieldValue, index) => {
                                    const {height, left, top, width} = fieldValue.position
                                    const fieldValuePlaceInformation = scalePlaceInformation(
                                        {height, left, top, width},
                                        {scaleHeight, scaleWidth},
                                        false
                                    )
                                    const childFieldPage = fieldValue.page || field.page
                                    fieldValuePlaceInformation.top =
                                        fieldValuePlaceInformation.top + (childFieldPage - 1) * (pageHeight + margin)
                                    const hasSigned = answer[index] === "signed"
                                    return {
                                        id: fieldValue.id,
                                        type: DigitalDocumentFieldSetUp.INITIAL,
                                        parentId: field.id,
                                        signatureType,
                                        isSigned: true,
                                        value: hasSigned ? taskUser.initials : null,
                                        placeInformation: fieldValuePlaceInformation
                                    }
                                })
                                newFieldValues.splice(newFieldValues.length, 0, ...initialFieldValues)
                            }
                            break
                        }
                        case DigitalDocumentFieldSetUp.CHECKBOX: {
                            if (fieldValues) {
                                let answer = get(subTaskAnswers, [field.id], "")
                                answer = (answer || "").split(",")
                                const checkboxFieldValues = fieldValues.map((fieldValue, index) => {
                                    const {height, left, top, width} = fieldValue.position
                                    const fieldValuePlaceInformation = scalePlaceInformation(
                                        {height, left, top, width},
                                        {scaleHeight, scaleWidth},
                                        false
                                    )
                                    const childFieldPage = fieldValue.page || field.page
                                    fieldValuePlaceInformation.top =
                                        fieldValuePlaceInformation.top + (childFieldPage - 1) * (pageHeight + margin)
                                    const isChecked = answer[index] === "Yes"
                                    return {
                                        id: fieldValue.id,
                                        type: DigitalDocumentFieldSetUp.CHECKBOX,
                                        parentId: field.id,
                                        signatureType,
                                        checkboxType: field.checkboxType,
                                        isSigned: true,
                                        value: isChecked,
                                        placeInformation: fieldValuePlaceInformation
                                    }
                                })
                                newFieldValues.splice(newFieldValues.length, 0, ...checkboxFieldValues)
                            }
                            break
                        }
                        case DigitalDocumentFieldSetUp.TEXT: {
                            const isAutocompletedField =
                                field.defaultDataType === DefaultDataType.STUDENT_DATA && field.defaultData
                            field.isAutocompletedField = isAutocompletedField
                            field.readOnly = true
                            field.isSigned = true
                            field.value = get(subTaskAnswers, [field.id], "")
                            break
                        }
                        default:
                            break
                    }
                    return field
                })
            const allFields = [...newFields, ...newFieldValues]
            setFields(allFields)
        } catch (error) {
            console.log("error", error)
        } finally {
            setIsLoading(false)
        }
    }

    const getScaleInfo = () => {
        const pageHeight = pageRef.current?.offsetHeight
        const pageWidth = pageRef.current?.offsetWidth
        const {width = pageWidth, height = pageHeight} = taskData.digitalDocument.pages?.[0] || {}
        const scaleHeight = height / pageHeight
        const scaleWidth = width / pageWidth
        return {scaleHeight, scaleWidth, pageHeight}
    }

    const scalePlaceInformation = (placeInformation, {scaleHeight, scaleWidth}, isSubmit = true) => {
        const {top, left, width, height} = placeInformation
        if (isSubmit) {
            return {
                width: width * scaleWidth,
                height: height * scaleHeight,
                top: top * scaleHeight,
                left: left * scaleWidth
            }
        }
        return {
            width: width / scaleWidth,
            height: height / scaleHeight,
            top: top / scaleHeight,
            left: left / scaleWidth
        }
    }

    const initSignature = (
        field,
        {placeInformation, signatureType, newFieldValues, scaleHeight, scaleWidth, parentId, extraInfo, pageHeight}
    ) => {
        if (placeInformation && placeInformation["dateField"]) {
            const dateFieldPlaceInfo = scalePlaceInformation(
                placeInformation["dateField"],
                {scaleHeight, scaleWidth},
                false
            )
            dateFieldPlaceInfo.top = dateFieldPlaceInfo.top + (field.page - 1) * (pageHeight + margin)
            newFieldValues.push({
                id: uuidV4(),
                parentId,
                type: DigitalDocumentFieldSetUp.DATE_FIELD,
                signatureType,
                placeInformation: dateFieldPlaceInfo,
                ...extraInfo
            })
        }
    }

    const onClickSubmit = async () => {
        if (!reason) {
            toastError("Please enter the reason")
            return
        }
        try {
            setIsSubmitting(true)
            const remarksData = {}
            keys(remarks).forEach((fieldId) => {
                if (!isEmpty(remarks[fieldId])) {
                    remarksData[fieldId] = remarks[fieldId]
                }
            })
            await taskService.remarkTaskUser({taskUserId: +taskUser.id, reason, remarks: remarksData})
            onClickCancel()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitting(false)
        }
    }

    const onClickCancel = () => {
        history.push(`/tasks/detail?type=${taskData.type}&id=${taskData.id}`)
    }

    const onChangeRemarkField = (fieldId, comment) => {
        const newRemarks = {...remarks}
        newRemarks[fieldId] = comment
        setRemarks(newRemarks)
    }

    const renderTaskHeader = () => {
        return (
            <div
                className={cx(styles.headerWrap, {
                    [styles.headerWrapFromEmail]: model.isSubmittingTaskFromEmail,
                    [styles.webFormHeader]: isWebForm
                })}>
                {!model.isSubmittingTaskFromEmail && (
                    <div className={styles.backBtn}>
                        <div className={styles.backBtnWrap} onClick={() => onClickCancel()}>
                            <Icon icon="BACK" className={styles.backIcon} color="#ff349b" />
                        </div>
                    </div>
                )}
                <div className={styles.headerTitleWrap}>
                    <p className={styles.headerTitle}>{taskData.name}</p>
                    <p className={styles.headerTaskType}>{isWebForm ? "Web Form" : "Digital document task"}</p>
                </div>
                <p className={styles.taskCode}>{taskData.code}</p>
            </div>
        )
    }

    const renderReason = () => {
        const sendIframe = (e) => {
            sendIframeObject.type = "submitObservations"
            sendIframeObject.data = reason
            if (isWebForm) {
                iframeRef.current.contentWindow.postMessage(sendIframeObject, "*")
            }
        }

        return (
            <div className={styles.reasonWrap}>
                <div className={styles.reasonInput}>
                    <BaseTextArea placeholder="Reason" value={reason} onChange={setReason} />
                </div>
                <div className={styles.headerAction}>
                    <BaseButton
                        title="Submit"
                        loading={isSubmitting}
                        onClick={isWebForm ? sendIframe : onClickSubmit}
                    />
                    <BaseButton title="Cancel" variant="secondary" onClick={onClickCancel} />
                </div>
            </div>
        )
    }

    const renderContent = () => {
        return (
            <div className={styles.pageWrap} ref={pagesRef}>
                {(taskData.digitalDocument.pages || []).map((page, index) => (
                    <img
                        ref={pageRef}
                        key={index}
                        className={cx(styles.pageImg, {
                            [styles.lastPage]: index === taskData.digitalDocument.pages.length - 1
                        })}
                        src={page.url}
                        style={{maxWidth: window.innerWidth}}
                        alt=""
                    />
                ))}
                {fields.map((field, index) => (
                    <ObservationField key={field.id || index} field={field} onChangeRemarkField={onChangeRemarkField} />
                ))}
            </div>
        )
    }
    const onLoad = () => {
        setIsLoading(false)
    }

    return (
        <div className={styles.wrap}>
            <div className={styles.headerContainer}>
                <BaseLoading isShow={isLoading} />
                {renderTaskHeader()}
                {renderReason()}
            </div>
            {isWebForm ? (
                <>
                    <Iframe
                        id="web_forms"
                        className={styles.iframeWebForm}
                        iframe_url={webFormUrl}
                        title={"Web Form"}
                        iframeRef={iframeRef}
                        onLoad={onLoad}
                    />
                </>
            ) : (
                <div className={styles.body}>{renderContent()}</div>
            )}
        </div>
    )
}
