import React from "react"
import {withTranslation} from "react-i18next"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableProps} from "types/common"
import {settingChecklistService, studentService} from "services"
import {KlassappTableHOC} from "HOC"
import {BaseDepartmentId} from "types/departments"
import {handleError, toastError} from "helpers"
import {BaseButton} from "components/buttons"
import styles from "./AddStudentChecklistPopup.module.css"

interface PageProps {
    t: Function
    departmentId: BaseDepartmentId
    profileId: number
    onSaveSuccess: () => void
    onClose: () => void
    isArchiveAll?: boolean
    campusIds?: number[]
    programIds?: number[]
    degreeLevelIds?: number[]
    payingMethodIds?: number[]
    dependencyStatuses?: string[]
    enrollmentTypes?: string[]
    enrollmentCitizenshipStatuses?: string[]
}

type Props = KlassappTableProps & PageProps

interface State {
    isSubmitting: boolean
}

class CheckListTable extends React.Component<Props, State> {
    state = {
        isSubmitting: false
    }

    async componentDidMount() {
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getListChecklist},
            {key: "getFields", func: this.getFields},
            {key: "getColumns", func: this.getColumns},
            {key: "onClickEdit", func: this.onClickEdit}
        ])
        this.props.dispatch({isLoading: true})
        this.getListChecklist()
    }

    getPageTitle = () => {
        return this.props.t("settings.checklist.checklist")
    }

    getFields = () => {
        const {t} = this.props
        return [
            t("settings.checklist.code"),
            t("settings.checklist.title"),
            t("settings.checklist.degreeLevel"),
            t("settings.checklist.campus"),
            t("settings.checklist.program")
        ]
    }

    getListChecklist = async () => {
        this.props.dispatch({isLoading: true})
        const params = this.getParams()
        try {
            const {data, total} = await settingChecklistService.getAllChecklists(params)
            const newData = data.map((item) => {
                item.id = item.checklistId
                item.degreeLevelsHtml = item.degreeLevels.map((degreeLevel) => degreeLevel.name).join(", ")
                item.campusesHtml = item.campuses.map((campus) => campus.name).join(", ")
                item.programsHtml = item.programs.map((program) => program.name).join(", ")
                return item
            })
            this.props.dispatch({data: newData, total})
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    getParams = () => {
        const {page, pageSize, departmentId, orderField, campusIds} = this.props
        const {order} = orderField
        const params: any = {
            sort: {
                orderBy: orderField.field ?? "createdAt",
                orderDir: (order && order.toUpperCase()) || "DESC"
            },
            range: {
                page,
                pageSize
            },
            filter: {
                departmentIds: [departmentId],
                ...(campusIds.length && {campusIds})
            },
            linkedEntities: ["degreeLevels", "campuses", "programs"]
        }
        return params
    }

    getColumns = () => {
        const {t} = this.props
        return [
            {
                title: t("settings.checklist.code"),
                field: "code",
                style: {minWidth: "150px"}
            },
            {
                title: t("settings.checklist.title"),
                field: "title",
                style: {minWidth: "150px"},
                sortable: true
            },
            {
                title: t("settings.checklist.degreeLevel"),
                field: "degreeLevelsHtml",
                style: {minWidth: "200px"}
            },
            {
                title: t("settings.checklist.campus"),
                field: "campusesHtml",
                style: {minWidth: "200px"}
            },
            {
                title: t("settings.checklist.program"),
                field: "programsHtml",
                style: {minWidth: "200px"}
            }
        ]
    }

    onClickRowItem = () => {}

    onClickEdit = () => {}

    onUpdateRowData = (newItem) => {
        const newTableData = this.props.data.map((item) => {
            if (newItem.id === item.id) {
                item.isChecked = !item.isChecked
            } else {
                item.isChecked = false
            }
            return item
        })
        this.props.dispatch({data: newTableData})
    }

    onSave = async () => {
        const checkedItem = this.props.data.find((item) => item.isChecked)
        if (!checkedItem) {
            toastError("Please select one checklist")
            return
        }
        const {profileId, onSaveSuccess} = this.props
        try {
            this.setState({isSubmitting: true})

            // update archival status for old checklist items if isArchiveAll === true
            if (this.props.isArchiveAll) {
                await studentService.archiveChecklistItemsForStudent({
                    profileId: profileId,
                    isUpdateAll: this.props.isArchiveAll,
                    departmentId: this.props.departmentId,
                    isArchived: true
                })
            }

            await studentService.assignStudentChecklistManually({
                profileId: profileId,
                checklistId: checkedItem.id
            })
            onSaveSuccess()
        } catch (error) {
            handleError(error)
        } finally {
            this.setState({isSubmitting: false})
        }
    }

    render() {
        const {page, total, pageSize, columns, data, allFields, fields, isLoading, t} = this.props
        const {isSubmitting} = this.state

        return (
            <>
                <div className={styles.tableWrapper}>
                    <KlassappTableHeader
                        isShowAction={false}
                        actions={[]}
                        page={page}
                        total={total}
                        defaultPageSize={pageSize}
                        onChangePage={this.props.onChangePage}
                        onChangeRowPerPage={this.props.onChangeRowPerPage}
                        fields={fields}
                        allFields={allFields}
                        onChangeFields={this.props.onChangeFields}
                        onChangeAllFields={this.props.onChangeAllFields}
                        onDraggableColumn={this.props.onDraggableColumn}
                    />
                    <KlassappTable
                        columns={columns}
                        data={data}
                        menuActions={[]}
                        isLoading={isLoading}
                        fields={fields}
                        allFields={allFields}
                        isShowCheckedColumn
                        onClickRowItem={this.onClickRowItem}
                        onUpdateRowData={this.onUpdateRowData}
                        onToggleCheckHeader={() => {}}
                        onChangeFields={this.props.onChangeFields}
                        onUpdateTableData={this.props.onUpdateTableData}
                        onDraggableColumn={this.props.onDraggableColumn}
                        onChangeAllFields={this.props.onChangeAllFields}
                    />
                </div>
                <div className={styles.action}>
                    <BaseButton
                        title={t("common:action.cancel")}
                        onClick={this.props.onClose}
                        disabled={isSubmitting}
                        variant="secondary"
                    />
                    <BaseButton title={t("common:action.save")} onClick={this.onSave} loading={isSubmitting} />
                </div>
            </>
        )
    }
}

export default KlassappTableHOC(withTranslation(["settings", "common"])(CheckListTable))
