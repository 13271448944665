export const tableData = [
    {
        id: 1,
        code: "CD1242",
        title: "Charge title",
        amount: "1,234.00",
        dueDate: "22/12/2020",
        status: "pastDue",
        orderStatus: "",
        childs: [
            {
                id: "11",
                code: "CD1242",
                title: "Payment 1",
                amount: "1,234.00",
                dueDate: "22/12/2020",
                status: "ordered",
                orderStatus: "reorder",
                parentId: 1
            },
            {
                id: "12",
                code: "CD1242",
                title: "Payment 2",
                amount: "1,234.00",
                dueDate: "22/12/2020",
                status: "pastDue",
                orderStatus: "order",
                parentId: 1
            }
        ]
    },
    {
        id: 2,
        code: "CD1242",
        title: "Charge title",
        amount: "1,234.00",
        dueDate: "",
        orderStatus: "rejected",
        status: "reorder"
    },
    {
        id: 3,
        code: "CD1242",
        title: "Charge title",
        amount: "1,234.00",
        dueDate: "",
        orderStatus: "origination",
        status: "upcoming"
    },
    {
        id: 4,
        code: "CD1242",
        title: "Charge title",
        amount: "1,234.00",
        dueDate: "22/12/2020",
        status: "received"
    }
]
