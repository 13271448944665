import React from "react"
import {Row, Col, Divider} from "antd"
import {BaseInput} from "components/inputs/BaseInput"
import {KlassDropdown} from "components/Select"
import {BaseDatePicker} from "components/DateTimePicker"
import {BaseButton, SecondaryButton} from "components/buttons"
import styles from "./ScholarshipItemForm.module.css"
import moment from "moment"

export function ScholarshipItemForm(props) {
    const {data, isEdit, isSubmitting, onChangeData, onClickEdit, onClickSave, onClickCancel} = props

    return (
        <div className={styles.wrap}>
            <div className={styles.bodyWrap}>
                <Row gutter={[12, 12]}>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>Code</p>
                            <BaseInput
                                onChange={(newValue) => onChangeData("code", newValue)}
                                placeholder={"Code"}
                                value={data.code}
                            />
                        </div>
                    </Col>
                    <Col span={16}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>Title</p>
                            <BaseInput
                                onChange={(newValue) => onChangeData("title", newValue)}
                                placeholder="Title"
                                value={data.title}
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>Description</p>
                            <BaseInput
                                onChange={(newValue) => onChangeData("description", newValue)}
                                placeholder="Description"
                                value={data.description}
                                className={styles.textarea}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <Divider className={styles.divider} />
            <div className={styles.bodyWrap}>
                <Row gutter={[20, 12]}>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>Amount</p>
                            <BaseInput
                                onChange={(newValue) => onChangeData("amount", newValue)}
                                placeholder={"Amount"}
                                value={data.amount}
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>Funding Period</p>
                            <KlassDropdown
                                options={[]}
                                onChange={(newValue) => onChangeData("fundingPeriod", newValue)}
                                placeholder="Select"
                                value={data.fundingPeriod}
                            />
                        </div>
                    </Col>
                    <Col span={8}></Col>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>Awarded Date</p>
                            <BaseDatePicker
                                onChange={(newValue) => onChangeData("awarededDate", newValue)}
                                value={data.awarededDate ? moment(data.awarededDate) : ""}
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={styles.itemBodyWrap}>
                            <p className={styles.bodyTitle}>Disbursed Date</p>
                            <BaseDatePicker
                                onChange={(newValue) => onChangeData("disbursedDate", newValue)}
                                value={data.disbursedDate ? moment(data.disbursedDate) : ""}
                            />
                        </div>
                    </Col>
                    <Col span={8}></Col>
                </Row>
            </div>
            <Divider className={styles.divider} />
            <p className={styles.footerDesc}>
                Student has been awarded with a <span className={styles.footerDesc__bold}>Scholarship Name</span> with
                the amount of <span className={styles.footerDesc__bold}>$12000</span> for{" "}
                <span className={styles.footerDesc__bold}>2021-2022 academic year</span>
            </p>
            <div className={styles.footerAction}>
                {!isEdit && (
                    <p className={styles.footerAction__desc}>
                        Created on <span className={styles.footerAction__bold_600}>22/12/2020</span> by{" "}
                        <span className={styles.footerAction__bold_600}>A. Anderson</span>
                    </p>
                )}
                <div className={styles.footerAction__button}>
                    {isEdit ? (
                        <>
                            <SecondaryButton title="Cancel" onClick={onClickCancel} className={styles.cancelBtn} />
                            <BaseButton title="Save" onClick={onClickSave} loading={isSubmitting} />
                        </>
                    ) : (
                        <BaseButton title="Edit" onClick={onClickEdit} />
                    )}
                </div>
            </div>
            <br />
        </div>
    )
}
