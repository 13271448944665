import hmacSHA1 from "crypto-js/hmac-sha1"
import base32 from "hi-base32"

function dec2hex(s: number) {
    return (s < 15.5 ? "0" : "") + Math.round(s).toString(16)
}

function hex2dec(s: string) {
    return parseInt(s, 16)
}

function base32tohex(base32: string) {
    const base32chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567"
    let bits = ""
    let hex = ""

    for (let i = 0; i < base32.length; i++) {
        const val = base32chars.indexOf(base32.charAt(i).toUpperCase())
        bits += val.toString(2).padStart(5, "0")
    }

    for (let i = 0; i + 4 <= bits.length; i += 4) {
        const chunk = bits.substring(i, i + 4)
        hex += parseInt(chunk, 2).toString(16)
    }
    return hex
}

export const getSecret = (text: string, len = 16) => base32.encode(text).substring(0, len)

export function getTOTP(secret: string, count = 6) {
    const key = base32tohex(secret)
    const epoch = Math.round(new Date().getTime() / 1000.0)
    const time = dec2hex(Math.floor(epoch / 30)).padStart(16, "0")
    const hmac = hmacSHA1(time, key).toString()
    const offset = hex2dec(hmac.substring(hmac.length - 1))
    const otp = `${hex2dec(hmac.substring(offset * 2, offset * 2 + 8)) & hex2dec("7fffffff")}`

    const start = otp.length - count
    return {
        otp: otp.substring(start, start + count),
        timeLeft: 30 - (epoch % 30)
    }
}
