import React from "react"
import classNames from "classnames"
import {useHistory} from "react-router-dom"
import {Icon} from "components/Icon"
import {SecondaryButton, BaseButton} from "components/buttons"
import {DigitalDocumentFieldSetUp} from "types/tasks"
import styles from "./HeaderBody.module.css"

export function HeaderBody(props) {
    const {type, taskId, onAddField, activeField, isSaving, getIconByType, canEditable, onClickSave} = props
    const history = useHistory()

    const onClickCancel = () => {
        history.push(`/tasks/detail?type=${type}&id=${taskId}`)
        window.location.reload()
    }

    const renderActionItem = (type) => {
        const icon = getIconByType(type)
        const isActive = activeField && activeField.type === type

        return (
            <div
                className={classNames(styles.headerActionItem, {[styles.headerActionItemActive]: isActive})}
                onClick={() => {
                    if (canEditable) {
                        onAddField(type)
                    }
                }}>
                <Icon icon={icon} className={styles.headerActionItem__icon} color={isActive ? "#fff" : "#1E90FF"} />
            </div>
        )
    }

    return (
        <div className={styles.headerBody}>
            <div className={styles.headerBodyContent}>
                <p className={styles.headerBodyContent__title}>Create Document</p>
                <div className={styles.headerActionWrap}>
                    {renderActionItem(DigitalDocumentFieldSetUp.SIGNATURE)}
                    {renderActionItem(DigitalDocumentFieldSetUp.INITIAL)}
                    {renderActionItem(DigitalDocumentFieldSetUp.TEXT)}
                    {renderActionItem(DigitalDocumentFieldSetUp.CHECKBOX)}
                </div>
            </div>
            <div className={styles.headerRightAction}>
                <SecondaryButton title="Cancel" onClick={onClickCancel} />
                <BaseButton
                    title="Save"
                    className={styles.saveBtn}
                    loading={isSaving}
                    disabled={!canEditable}
                    onClick={onClickSave}
                />
            </div>
        </div>
    )
}
