import {get} from "lodash"
import BaseService from "./Base"

const basePath: string = `/v1/settings/concern-settings`

class ConcernSettingsService extends BaseService {
    async list(params) {
        const response = await this.post(`${basePath}/list`, params)
        return get(response, "data", [])
    }

    async create(data = {}) {
        const response = await this.post(`${basePath}/create`, data)
        return get(response, "data.data", {})
    }

    async update(id, data, params = {}) {
        data.concernSettingId = id
        const response = await this.post(`${basePath}/edit`, data, params)
        return get(response, "data", {})
    }

    async delete(concernSettingIds: number[]) {
        const response = await this.post(`${basePath}/delete`, {concernSettingIds})
        return get(response, "data", {})
    }

    async listComplianceDirector() {
        const response = await this.post(`${basePath}/compliance-director/list`, {})
        return get(response, "data", [])
    }

    async updateComplianceDirector(data = {}) {
        const response = await this.post(`${basePath}/compliance-director/update`, data)
        return get(response, "data", {})
    }

    async listDepartmentSubunits(params) {
        const response = await this.post(`${basePath}/department-subunits/list`, params)
        return get(response, "data", [])
    }

    async listPersons(params) {
        const response = await this.post(`${basePath}/persons/list`, params)
        return get(response, "data", [])
    }

    async listReviewers(params) {
        const response = await this.post(`${basePath}/reviewers/list`, params)
        return get(response, "data", [])
    }
}

export default ConcernSettingsService
