import {get} from "lodash"
import BaseService from "./Base"

export enum ExternalSystem {
    Frame = "frame",
    Genesis = "genesis"
}

export type IntegrationMappingItem = {
    integrationMappingId: number
    courseId: number
    courseName: string
    courseCode?: string
    mappingCode: string
    externalSystem: ExternalSystem
}

export type ListIntegrationMapping = {
    filter?: Partial<{
        text?: string
        courseId?: number
    }>
    range?: {
        page: number
        pageSize: number
    }
    sort?: {
        orderBy: keyof IntegrationMappingItem
        orderDir: "asc" | "desc" | "ASC" | "DESC"
    }
}

export type EditIntegrationMapping = {
    integrationMappingId?: number
    courseId: number
    mappingCode: string
    externalSystem: ExternalSystem
}

class AcademicIntegrationMapping extends BaseService {
    listIntegrationMapping = async (
        data: ListIntegrationMapping
    ): Promise<{integrationMappings: IntegrationMappingItem[]; total: number}> => {
        const response = await this.post("/v3/academic/integration-mapping/list", data)
        return get(response, "data.data", [])
    }
    editIntegrationMappings = async (data: EditIntegrationMapping[]) => {
        const response = await this.post("/v3/academic/integration-mapping/edit", {
            integrationMappings: data
        })
        return get(response, "data.data", [])
    }
    deleteIntegrationMappings = async (ids: number[]) => {
        const response = await this.post("/v3/academic/integration-mapping/delete", {
            integrationMappingIds: ids
        })
        return get(response, "data.data", [])
    }
}

export default AcademicIntegrationMapping
