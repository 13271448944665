import React from "react"
import {Icon} from "components/Icon"
import styles from "./StatusCards.module.css"

export function StatusCardItem(props) {
    const {title, total, cost, isUp} = props.item

    return (
        <div className={styles.cardItem}>
            <p className={styles.cardItem__title}>{title}</p>
            <div className={styles.cardItemBody}>
                <div className={styles.cardItemStatusWrap}>
                    {isUp ? (
                        <Icon icon="ARROW_UP_FILL" color="#18A957" />
                    ) : (
                        <Icon icon="ARROW_DOWN_FILL" color="#DF1642" />
                    )}
                    <p className={styles.cardItem__number}>{total}</p>
                </div>
                <p className={styles.cardItem__cost}>(${cost})</p>
            </div>
        </div>
    )
}
