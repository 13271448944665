import React from "react"
import styles from "./AutocompleteInput.module.css"
import Autocomplete from "react-autocomplete"

interface Props {
    items: any
    value: string
    error?: boolean
    keyName?: string
    valueName: string
    placeHolder?: string
    getItemValue?: (item) => string
    onChange: (e, newValue) => void
    onSelect: (selectedValue, obj) => void
}
export class AutocompleteInput extends React.Component<Props, {}> {
    renderUser(item: any, value: string) {
        const {valueName} = this.props
        return item[valueName].toLowerCase().indexOf(value.toLowerCase()) !== -1
    }
    render() {
        const {items, value, error, onChange, onSelect, valueName, placeHolder, keyName, getItemValue} = this.props
        return (
            <div className={`${styles.autocompleteWrapper} ${error && styles.error}`}>
                <Autocomplete
                    value={value}
                    items={items}
                    getItemValue={getItemValue ? getItemValue : (item) => item[valueName]}
                    shouldItemRender={() => true}
                    renderMenu={(item) => (
                        <div key={item.value || item[keyName]} className={styles.dropdown}>
                            {item}
                        </div>
                    )}
                    renderItem={(item, isHighlighted) => (
                        <div
                            key={item.value || item[keyName]}
                            className={`${styles.item} ${isHighlighted ? styles.selectedItem : ""}`}>
                            {item[valueName]}
                        </div>
                    )}
                    onChange={onChange}
                    onSelect={onSelect}
                    inputProps={{placeholder: placeHolder}}
                />
            </div>
        )
    }
}
