import React from "react"
import {Checkbox} from "antd"
import {useTranslation} from "react-i18next"
import styles from "./ColumnPicker.module.css"
import {Column} from "../StudentsTable/types"
import classNames from "classnames"

const CheckboxGroup = Checkbox.Group

interface Props {
    columns: Column[]
    visibleColumns: string[]
    onChange: (fields: string[]) => void
}

export function ColumnPicker({columns, visibleColumns, onChange}: Props) {
    const {t} = useTranslation("common")

    const columnOptions = React.useMemo(
        () =>
            columns.map((column) => ({
                value: column.field,
                label: column.title
            })),
        [columns]
    )

    const handleChange = React.useCallback((values) => onChange(values as string[]), [onChange])

    return (
        <div className={`col-picker ${styles.colsPicker}`}>
            <p className={styles.colsPickerTitle}>{t("columnPicker.column")}</p>
            <div className={classNames(styles.colsPickerBody, "custom-scroll")}>
                <CheckboxGroup
                    options={columnOptions}
                    value={visibleColumns}
                    onChange={handleChange}
                    className={styles.checkboxes}
                />
            </div>
        </div>
    )
}
