import {get} from "lodash"
import {
    ParamCreatePreviousCompletedCredits,
    ParamsGetPreviousCompletedCredits,
    ParamUpdatePreviousCompletedCredits,
    PreviousCompletedCredits,
    ResponseListPreviousCompletedCredits
} from "types/previousCompletedCredits"
import BaseService from "./Base"

class PreviousCompletedCreditsService extends BaseService {
    async listPreviousCompletedCredits(
        params: ParamsGetPreviousCompletedCredits
    ): Promise<ResponseListPreviousCompletedCredits> {
        const url = "/v3/academics/previousCompletedCredits/list"
        const response = await this.post(url, params)
        return get(response, "data", {})
    }
    async deletePreviousCompletedCredits(ids: number[]): Promise<PreviousCompletedCredits> {
        const url = "/v3/academics/previousCompletedCredits/delete"
        const response = await this.post(url, {previousCompletedCreditsIds: ids})
        return get(response, "data", {})
    }
    async detailPreviousCompletedCredits(id: number): Promise<PreviousCompletedCredits> {
        const url = "/v3/academics/previousCompletedCredits/get"
        const response = await this.post(url, {previousCompletedCreditsId: id})
        return get(response, "data", {})
    }
    async updatePreviousCompletedCredits(
        id: number,
        params: ParamUpdatePreviousCompletedCredits
    ): Promise<PreviousCompletedCredits> {
        const url = "/v3/academics/previousCompletedCredits/edit"
        const response = await this.post(url, {...params, previousCompletedCreditsId: id})
        return get(response, "data", {})
    }
    async createPreviousCompletedCredits(
        params: ParamCreatePreviousCompletedCredits
    ): Promise<PreviousCompletedCredits> {
        const url = "/v3/academics/previousCompletedCredits/create"
        const response = await this.post(url, params)
        return get(response, "data", {})
    }
}

export default PreviousCompletedCreditsService
