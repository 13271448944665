enum GradingType {
    GRADES = "grades",
    PASSFAIL = "passFail"
}
type GradingSystemDetail = {
    id: number
    type: GradingType
    code: string
    title: string
    minimumPassingGrade?: number
    isWeightingRequired?: boolean
    gradingPassFail?: GradingPassFailDetail[]
    gradingScale?: GradingScaleDetail[]
    gradingElements?: GradingElementDetail[]
}
type GradingSystemItem = {
    id: number
    type: GradingType
    code: string
    title: string
    gradingPassFail?: GradingPassFail[]
    gradingScale?: GradingScale[]
    gradingElements?: GradingElement[]
}

type ResponseGradingSystem = {
    data: GradingSystemItem[]
    total: number
}
type ResponseDetailGradingSystem = {
    data: GradingSystemDetail
    success: boolean
}
type ParamsGradingSystem = {
    range?: {
        page: number
        pageSize: number
    }
    filter?: {
        text?: string
    }
}
type GradingScale = {
    id?: number
    gradingId?: number
    numericLow: number
    numericHigh: number
    alphabetical: string
    fourPointScale: number
}
type GradingElement = {
    id?: number
    gradingId?: number
    gradingItem: string
    colorLabel: string
    affectGpa: number
    weight: number
    percentGpa: number
}
type GradingPassFail = {
    id?: number
    gradingId?: number
    numericLow: number
    numericHigh: number
    passFail: boolean
}
type GradingScaleDetail = {
    id?: number
    gradingId?: number
    numericLow: number
    numericHigh: number
    alphabetical: string
    fourPointScale: number
}
type GradingElementDetail = {
    id?: number
    gradingId?: number
    gradingItem: string
    colorLabel: string
    affectGpa: number
    weight: number
    percentPga: number
}
type GradingPassFailDetail = {
    id?: number
    gradingId?: number
    numericLow: number
    numericHigh: number
    passFail: boolean
}
type ParamsCreateGradingSystem = {
    type: GradingType
    code: string
    title: string
    minimumPassingGrade?: number
    isWeightingRequired?: boolean
    gradingPassFail?: GradingPassFail[]
    gradingScale?: GradingScale[]
    gradingElements?: GradingElement[]
}
export type {
    ResponseGradingSystem,
    GradingSystemItem,
    ParamsGradingSystem,
    GradingSystemDetail,
    ParamsCreateGradingSystem,
    GradingPassFail,
    ResponseDetailGradingSystem,
    GradingScale,
    GradingElement
}
export {GradingType}
