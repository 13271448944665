import React from "react"
import {StaffSigner, StudentSigner, OthersSigner} from "./parts"
import {SelectStaffFrom, TaskSubtype, UserType} from "types/tasks"

export function TaskSignerDetail(props) {
    const {signer, subtype} = props

    switch (signer.userType) {
        case UserType.STAFF: {
            if (
                subtype === TaskSubtype.LIBRARY &&
                (signer.selectedType === SelectStaffFrom.ROLE ||
                    signer.selectedType === SelectStaffFrom.NO_DEFAULT ||
                    signer.jsonData?.selectedOption === SelectStaffFrom.ROLE)
            ) {
                return null
            }
            return <StaffSigner {...props} />
        }
        case UserType.STUDENT:
            return <StudentSigner {...props} />
        case UserType.OTHERS:
            return <OthersSigner {...props} />
        default:
            return <></>
    }
}
