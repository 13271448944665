import {useCurrentProfilePermissions} from "hooks"
import {PermissionUserType, PermissionType, BaseDepartmentPermission} from "types/permission"

type Props = {
    permissions: PermissionUserType
    permissionType?: PermissionType
    departmentFor?: "Documents" | "Activities" | "Tasks" | "Director" | "Chat"
    departmentId?: number
    subunitId?: number
    children: JSX.Element
}

export function PermissionsRequired(props: Props): JSX.Element {
    const {permissions, permissionType, departmentFor = "Documents", departmentId, subunitId, children} = props
    const currentProfilePermissions = useCurrentProfilePermissions()

    const checkDepartmentDocumentsPermission = () => {
        switch (permissionType) {
            case PermissionType.ListView:
                return currentProfilePermissions.departmentsDocuments.hasPermissionsToView(departmentId, subunitId)
            case PermissionType.View:
                return currentProfilePermissions.departmentsDocuments.hasPermissionsToView(departmentId, subunitId)
            case PermissionType.Add:
                return currentProfilePermissions.departmentsDocuments.hasPermissionsToAdd(departmentId, subunitId)
            case PermissionType.Edit:
                return currentProfilePermissions.departmentsDocuments.hasPermissionsToEdit(departmentId, subunitId)
            case PermissionType.Delete:
                return currentProfilePermissions.departmentsDocuments.hasPermissionsToDelete(departmentId, subunitId)
            default:
                return false
        }
    }

    const checkDepartmentActivitiesPermission = () => {
        switch (permissionType) {
            case PermissionType.View:
                return currentProfilePermissions.departmentActivities.hasPermissionsToView(departmentId, subunitId)
            case PermissionType.Add:
                return currentProfilePermissions.departmentActivities.hasPermissionsToAdd(departmentId, subunitId)
            case PermissionType.Edit:
                return currentProfilePermissions.departmentActivities.hasPermissionsToEdit(departmentId, subunitId)
            case PermissionType.Delete:
                return currentProfilePermissions.departmentActivities.hasPermissionsToDelete(departmentId, subunitId)
            default:
                return false
        }
    }

    const checkDepartmentTasksPermission = () => {
        switch (permissionType) {
            case PermissionType.View:
                return currentProfilePermissions.departmentTasks.hasPermissionsToView(departmentId, subunitId)
            case PermissionType.Add:
                return currentProfilePermissions.departmentTasks.hasPermissionsToAdd(departmentId, subunitId)
            case PermissionType.Edit:
                return currentProfilePermissions.departmentTasks.hasPermissionsToEdit(departmentId, subunitId)
            case PermissionType.Delete:
                return currentProfilePermissions.departmentTasks.hasPermissionsToDelete(departmentId, subunitId)
            default:
                return false
        }
    }

    const checkBaseDepartmentPermission = (value: BaseDepartmentPermission) => {
        switch (permissionType) {
            case PermissionType.View:
                return currentProfilePermissions.baseDepartment.hasPermissionsToView(departmentId, value)
            case PermissionType.Add:
                return currentProfilePermissions.baseDepartment.hasPermissionsToAdd(departmentId, value)
            case PermissionType.Edit:
                return currentProfilePermissions.baseDepartment.hasPermissionsToEdit(departmentId, value)
            case PermissionType.Delete:
                return currentProfilePermissions.baseDepartment.hasPermissionsToDelete(departmentId, value)
            default:
                return false
        }
    }

    const checkDepartmentPermissions = () => {
        switch (departmentFor) {
            case "Documents":
                return checkDepartmentDocumentsPermission()
            case "Activities":
                return checkDepartmentActivitiesPermission()
            case "Tasks":
                return checkDepartmentTasksPermission()
            case "Director":
                return checkBaseDepartmentPermission(BaseDepartmentPermission.Director)
            case "Chat":
                return checkBaseDepartmentPermission(BaseDepartmentPermission.Chat)
            default:
                break
        }
    }

    let hasPermission = false
    if (permissions) {
        hasPermission = currentProfilePermissions.hasPermissions(permissions)
    } else if (departmentId) {
        hasPermission = checkDepartmentPermissions()
    }
    if (!hasPermission) {
        return null
    }
    return children
}
