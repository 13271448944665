import {get} from "lodash"
import BaseService from "./Base"

class PaymentsService extends BaseService {
    constructor() {
        super(process.env.REACT_APP_PAYMENTS_URL)
    }

    async createIntent(data, params = {}) {
        const response = await this.post("/v1/payment-intents/create", data, params)
        return get(response, "data.data", {})
    }
    async updateIntent(data, params = {}) {
        const response = await this.post("/v1/payment-intents/update", data, params)
        return get(response, "data.data", {})
    }
    async getBalance() {
        const response = await this.post("/v1/balance/get", {}, {})
        return get(response, "data.data", {})
    }

    async getCustomer() {
        const response = await this.post("/v1/customers/get", {}, {})
        return get(response, "data.data", null)
    }
    async updateCustomer(data, params = {}) {
        const response = await this.post("/v1/customers/update", data, params)
        return get(response, "data.data", {})
    }
}

export default PaymentsService
