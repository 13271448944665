import React, {useState, useEffect, ReactNode} from "react"
import {useTranslation} from "react-i18next"
import {head, isEmpty, get} from "lodash"
import classNames from "classnames"
import {BasePopup} from "components/popup"
import {Icon, IconType} from "components/Icon"
import {BaseLoading} from "components/Loading"
import {userService} from "services"
import {
    checkIfProfileHasPermissionsForSignaturesAndInitialsOfAnotherProfileOfType,
    checkIfProfileHasPermissionsForTheirSignatureAndInitials,
    handleError,
    toastSuccess
} from "helpers"
import {SignInitialsEditForm, TextInitialsEditForm} from "./parts"
import styles from "./InitialsPopup.module.css"
import {useModel} from "hooks"
import {PermissionType} from "types/permission"
import {BaseButton} from "components/buttons"

const SIGNATURE_TYPE = {
    SIGNATURE: "SIGNATURE",
    TEXT: "TEXT"
}

type Props = {
    isShow: boolean
    onClose: () => void
    title: string
    onSetNewInitials?: (newInitials) => void
    targetUser?: any
    currentInitialLoaded?: any
    isAnsweringATask?: boolean
    children?: ReactNode
    actions?: {
        signLabel?: string
        onCancel?: () => void
        onSign?: (signature) => void
    }
}

export function InitialsPopup(props: Props) {
    const {isShow, onClose, title, onSetNewInitials, targetUser, currentInitialLoaded, isAnsweringATask, actions} =
        props
    const [activeType, setActiveType] = useState(SIGNATURE_TYPE.SIGNATURE)
    const [isShowEditForm, setIsShowEditForm] = useState(false)
    const [currentInitials, setCurrentInitials] = useState(null)
    const [isLoadingInitials, setIsLoadingInitials] = useState(null)
    const {t} = useTranslation(["user", "common"])
    const model = useModel()

    useEffect(() => {
        if (isShow && !isAnsweringATask) {
            getCurrentInitials()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow])

    useEffect(() => {
        if (isAnsweringATask) {
            if (currentInitialLoaded) {
                setCurrentInitials(currentInitialLoaded)
            } else {
                setIsShowEditForm(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentInitialLoaded])

    const checkCanEditSignature = () => {
        if (targetUser) {
            return targetUser.id === model.user.id
                ? checkIfProfileHasPermissionsForTheirSignatureAndInitials(model, PermissionType.Edit)
                : checkIfProfileHasPermissionsForSignaturesAndInitialsOfAnotherProfileOfType(
                      model,
                      targetUser.type,
                      PermissionType.Edit
                  )
        }
        return checkIfProfileHasPermissionsForTheirSignatureAndInitials(model, PermissionType.Edit)
    }

    const checkCanDeleteSignature = () => {
        if (targetUser) {
            return targetUser.id === model.user.id
                ? checkIfProfileHasPermissionsForTheirSignatureAndInitials(model, PermissionType.Delete)
                : checkIfProfileHasPermissionsForSignaturesAndInitialsOfAnotherProfileOfType(
                      model,
                      targetUser.type,
                      PermissionType.Delete
                  )
        }
        return checkIfProfileHasPermissionsForTheirSignatureAndInitials(model, PermissionType.Delete)
    }

    const getCurrentInitials = async () => {
        try {
            setIsLoadingInitials(true)
            const userId = targetUser?.id || model.user.id
            const {data} = await userService.getInitialsList({userId, onlyActive: true, range: {page: 1, pageSize: 1}})
            if (isEmpty(data) || !get(head(data), "imageUrl")) {
                setIsShowEditForm(true)
            } else {
                setCurrentInitials(head(data))
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoadingInitials(false)
        }
    }

    const onClickInitialsType = (type) => {
        setActiveType(type)
    }

    const renderInitialsItemIcon = (type) => {
        let icon: IconType = "SIGN_HAND_DRAWN"
        switch (type) {
            case SIGNATURE_TYPE.SIGNATURE:
                icon = "SIGN_HAND_DRAWN"
                break
            case SIGNATURE_TYPE.TEXT:
                icon = "TEXT"
                break
        }
        return (
            <div
                className={classNames(styles.iconItemWrap, {
                    [styles.iconItemWrap__active]: activeType === type
                })}
                onClick={() => onClickInitialsType(type)}>
                <Icon icon={icon} color={activeType === type ? "#FFF" : "#1E90FF"} className={styles.icon} />
            </div>
        )
    }

    const onClickEditInitials = () => {
        setIsShowEditForm(true)
    }

    const onSaveSuccessful = (newInitials) => {
        setIsShowEditForm(false)
        toastSuccess(t("personal.signature.saveInitials"))
        setCurrentInitials(newInitials)
        onClose()
        if (onSetNewInitials) {
            onSetNewInitials(newInitials)
        }
    }

    const onCancelEditForm = () => {
        setIsShowEditForm(false)
    }

    const onDeleteSuccessful = () => {
        setIsShowEditForm(false)
        setCurrentInitials(null)
        toastSuccess(t("personal.initials.deleteInitials"))
    }

    const renderAction = () => {
        if (isShowEditForm) {
            const userId = targetUser?.id || model.user.id
            if (activeType === SIGNATURE_TYPE.SIGNATURE) {
                const canDeleteSignatureInitials = checkCanDeleteSignature()
                return (
                    <SignInitialsEditForm
                        userId={userId}
                        onSaveSuccessful={onSaveSuccessful}
                        onDelete={onDeleteSuccessful}
                        canDeleteSignatureInitials={canDeleteSignatureInitials}
                        onCancel={onCancelEditForm}
                    />
                )
            }
            return (
                <TextInitialsEditForm userId={userId} onSaveSuccessful={onSaveSuccessful} onCancel={onCancelEditForm} />
            )
        }
        const canEditSignatureInitials = checkCanEditSignature()
        return (
            <>
                <div className={styles.signatureImgWrap}>
                    <img src={currentInitials ? currentInitials.imageUrl : ""} className={styles.signatureImg} alt="" />
                    <BaseLoading isShow={isLoadingInitials} />
                </div>
                {canEditSignatureInitials && (
                    <div className={styles.editBtnWrap} onClick={onClickEditInitials}>
                        <p className={styles.editBtn}>{t("personal.initials.editInitials")}</p>
                    </div>
                )}
            </>
        )
    }

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="PEOPLE" leftIconColor="#fff" width="70vw">
            <div className={styles.wrap}>
                <p className={styles.title}>{title}</p>
                <div className={styles.iconWrap}>
                    {renderInitialsItemIcon(SIGNATURE_TYPE.SIGNATURE)}
                    {model.clientSetting?.isTypedSignaturesEnabled && renderInitialsItemIcon(SIGNATURE_TYPE.TEXT)}
                </div>
                <p className={styles.desc}>{t("personal.initials.typeYourInitialsBelow")}</p>
                {renderAction()}
                {!isShowEditForm && !isLoadingInitials && actions && (
                    <div className={styles.actions}>
                        <BaseButton title={t("common:action.cancel")} variant="secondary" onClick={actions.onCancel} />
                        <BaseButton
                            title={actions.signLabel || "Sign"}
                            onClick={() => actions.onSign(currentInitials)}
                        />
                    </div>
                )}
                {props.children}
            </div>
        </BasePopup>
    )
}
