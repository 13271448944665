import {useQuery} from "@tanstack/react-query"
import {useEffect, useMemo} from "react"
import {newStudentStatusService} from "services"
import {Auth} from "types/auth"
import {StudentStatus} from "types/students"
import {orderBy} from "lodash"

type Options = {
    forState?: Auth.StudentState | Auth.StudentState[]
    order?: {
        field: Array<keyof StudentStatus>
        dir: Array<"asc" | "desc">
    }
}

const DEFAULT_ORDER: Options["order"] = {
    field: ["priority"],
    dir: ["asc"]
}

const INITIAL_DATA: StudentStatus[] = []

export const useAllAvailableStudentStatuses = ({forState, order = DEFAULT_ORDER}: Options = {}) => {
    const allAvailableStatusesQ = useQuery(
        ["all-active-student-statuses-new"],
        () => newStudentStatusService.getAvailableStatuses(true),
        {
            refetchOnMount: false
        }
    )

    const statuses = useMemo(() => {
        let resultStatuses = allAvailableStatusesQ.data ?? INITIAL_DATA

        if (forState) {
            const statesToInclude = typeof forState === "object" ? forState : [forState]
            if (typeof forState !== "object") {
                if ([Auth.StudentState.Prospect, Auth.StudentState.Applicant].includes(forState as Auth.StudentState)) {
                    statesToInclude.push(Auth.StudentState.NeverAttended)
                } else if (forState === Auth.StudentState.NeverAttended) {
                    statesToInclude.push(Auth.StudentState.Prospect, Auth.StudentState.Applicant)
                }
            }

            resultStatuses = resultStatuses.filter((status) => statesToInclude.includes(status.state))
        }

        return orderBy(resultStatuses, DEFAULT_ORDER.field, DEFAULT_ORDER.dir)
    }, [allAvailableStatusesQ.data, forState])

    const sortedStatuses = useMemo(() => orderBy(statuses, order.field, order.dir), [statuses, order])

    return {statuses, sortedStatuses, isLoading: allAvailableStatusesQ.isFetching}
}
