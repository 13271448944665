import React from "react"
import {Checkbox} from "antd"
import {v4 as uuidv4} from "uuid"
import {keyBy, get, isEmpty} from "lodash"
import {Icon} from "components/Icon"
import debounce from "debounce-promise"
import {withTranslation} from "react-i18next"
import {BaseInput} from "components/inputs"
import {BaseButton, AddItemCircleButton, SecondaryButton} from "components/buttons"
import {KlassappTable, KlassappTableHeader, KlassappTableDropdown, TableColorPicker} from "uiKit"
import {KlassappTableProps, Order} from "types/common"
import {settingStageService, settingStatusService} from "services"
import {handleError, checkPermission, toastError, toastWarning, getPositionMoveInObject} from "helpers"
import {KlassappTableHOC} from "HOC"
import {BaseDepartmentId} from "types/departments"
import {PermissionsRequired} from "components/PermissionsRequired"
import {getPermissionsByDepartment} from "sections/Settings/helper"
import {Model} from "Model"
import {PermissionUserType} from "types/permission"
import styles from "./Statuses.module.css"

const defaultColor = "#828282"

interface PageProps {
    t: Function
    departmentId: BaseDepartmentId
    model: Model
    includeStage?: boolean
}

type Props = KlassappTableProps & PageProps

interface State {
    newItemData: any
    oldItemData: any
    isSubmitting: boolean
    stages: any[]
    permissions: PermissionUserType
    departmentId: number
}

const admCategories = [
    {id: "1", name: "A"},
    {id: "2", name: "B"},
    {id: "3", name: "C"},
    {id: "4", name: "D"},
    {id: "5", name: "Others"},
    {id: "6", name: "Moved"}
]

class Statuses extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        const {departmentId} = props
        this.state = {
            newItemData: null,
            oldItemData: null,
            isSubmitting: false,
            stages: [],
            permissions: {staff: []},
            departmentId: departmentId === BaseDepartmentId.StudentServices ? BaseDepartmentId.Academics : departmentId
        }
        this.onSearchChange = debounce(this.onSearchChange.bind(this), 300)
    }

    async componentDidMount() {
        this.getPermissions()
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getData},
            {key: "getFields", func: this.getFields},
            {key: "getColumns", func: this.getColumns},
            {key: "getTableHeaderActions", func: this.getTableHeaderActions},
            {key: "getMenuActions", func: this.getMenuActions},
            {key: "onClickDeleteMulti", func: this.onClickDeleteMulti},
            {key: "onClickDuplicateMulti", func: this.onClickDuplicateMulti},
            {key: "onClickEdit", func: this.onClickEdit},
            {key: "onClickDelete", func: this.onClickDelete}
        ])
        this.props.dispatch({isLoading: true, orderField: {field: "priority", order: Order.Asc}})
        await this.getAllStages()
        this.getData()
    }

    getPermissions = () => {
        const permissions = getPermissionsByDepartment(this.state.departmentId)
        this.setState({permissions})
    }

    getPageTitle = () => {
        return this.props.t("admission:admission.statuses.status")
    }

    getFields = () => {
        const {t, includeStage = true} = this.props
        return [
            t("admission:admission.statuses.priority"),
            t("admission:admission.statuses.name"),
            includeStage && t("admission:admission.statuses.stage"),
            includeStage && t("admission:admission.statuses.sev"),
            t("admission:admission.statuses.admCategory"),
            t("admission:admission.statuses.color")
        ].filter(Boolean)
    }

    getTableHeaderActions = (isShowDuplicateBtn = true, checkedData = []) => {
        const canWrite = checkPermission(this.state.permissions, this.props.model)
        if (!canWrite) {
            return []
        }
        const hasLockedItem = (checkedData || []).some((item) => item.isLock)
        const {t, onClickShowConfirmModal} = this.props
        const actions = []
        if (!hasLockedItem) {
            actions.push({
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            })
        }
        if (isShowDuplicateBtn) {
            actions.push({
                title: t("common:action.duplicate"),
                icon: "DUPLICATE",
                action: () => this.onClickDuplicateMulti()
            })
        }
        return actions
    }

    canShowDeleteIcon = (data) => {
        return checkPermission(this.state.permissions, this.props.model) && !data.isLock
    }

    canShowEditIcon = () => {
        return checkPermission(this.state.permissions, this.props.model)
    }

    getMenuActions = () => {
        const {t} = this.props
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: this.onClickEdit,
                canShow: this.canShowEditIcon
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: this.onClickDelete,
                canShow: this.canShowDeleteIcon
            }
        ]
    }

    getAllStages = async (input = "") => {
        try {
            const {departmentId} = this.state
            const params = {
                range: {
                    page: 1,
                    pageSize: 50
                },
                filter: {
                    departmentIds: [departmentId]
                }
            }
            const {data} = await settingStageService.getAllStages(params)
            const newData = data.map((item) => {
                item.id = item.stageId
                return item
            })
            this.setState({stages: newData})
        } catch (e) {
            handleError(e)
        }
    }

    renderSEVHtml = (stage = null) => {
        if (stage) {
            return (
                <div key={`show-${stage.statusId}`} className={styles.visibleCheckboxWrap}>
                    <Checkbox checked={stage.isStudentEnrollmentVerificationEnabled} />
                </div>
            )
        }
        const newItemData = this.state.newItemData || {}
        return (
            <div key={`edit-${newItemData.statusId}`} className={styles.visibleCheckboxWrap}>
                <Checkbox
                    checked={newItemData.isSEVData}
                    onChange={(event) => this.onChangeNewItemData("isSEVData", event.target.checked)}
                />
            </div>
        )
    }

    renderAdmCategoryHtml = (admCategoryData) => {
        if (!admCategoryData) {
            return null
        }
        if (!admCategoryData.id) {
            return admCategoryData.name
        }
        return (
            <span className={styles.admCategory}>
                {admCategoryData.name} <span className={styles.admCategoryId}>({admCategoryData.id})</span>
            </span>
        )
    }

    renderNameHtml = (item) => {
        return (
            <div className={styles.titleWrap}>
                {item.isLock ? (
                    <div className={styles.lockIcon}>
                        <Icon icon="LOCK_CHECKMARK" />
                    </div>
                ) : (
                    ""
                )}
                <span className={styles.title}>{item.name}</span>
            </div>
        )
    }

    renderColorHtml = (item = null) => {
        if (item) {
            return <div className={styles.colorInfo} style={{background: item.hexColor}}></div>
        }
        const {hexColorData} = this.state.newItemData || {}
        return (
            <TableColorPicker
                value={hexColorData || defaultColor}
                onChange={(newValue) => this.onChangeNewItemData("hexColorData", newValue)}
            />
        )
    }

    getData = async () => {
        const {page, pageSize, orderField} = this.props
        this.props.dispatch({isLoading: true})
        const params = this.getParams()
        try {
            const {data, total} = await settingStatusService.getAllStatuses(params)
            let totalPage = Math.ceil(total / pageSize)
            totalPage = totalPage <= 0 ? 1 : totalPage
            const {stages} = this.state
            const stagesKeyById = keyBy(stages, "id")
            const admCategoriesKeyById = keyBy(admCategories, "id")
            const newData = data.map((item, index) => {
                const stageData = stagesKeyById[item.stageId] || {}
                const admCategoryData = admCategoriesKeyById[item.admissionCategory]
                item.id = item.statusId
                item.stageValue = get(stageData, "name", "")
                item.nameHtml = this.renderNameHtml(item)
                item.order = index + 1
                item.sevHtml = this.renderSEVHtml(item)
                item.admCategoryValue = this.renderAdmCategoryHtml(admCategoryData)
                item.colorHtml = this.renderColorHtml(item)
                if (orderField.order === Order.Asc) {
                    item.priorityCount = (page - 1) * pageSize + index + 1
                } else if (totalPage !== page) {
                    item.priorityCount = (totalPage - page) * pageSize + data.length - index - 1
                } else {
                    item.priorityCount = data.length - index
                }
                return item
            })
            this.props.dispatch({data: newData, total})
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    getParams = () => {
        const {page, pageSize, orderField} = this.props
        const params: any = {
            range: {
                page,
                pageSize
            },
            sort: {
                orderBy: "priority",
                orderDir: Order.Desc
            },
            filter: {
                departmentIds: [this.state.departmentId]
            }
        }
        if (!isEmpty(orderField)) {
            params.sort = {
                orderBy: orderField.field,
                orderDir: orderField.order
            }
        }
        return params
    }

    getColumns = () => {
        const {t, includeStage = true} = this.props
        return [
            {
                title: t("admission:admission.statuses.priority"),
                field: "priorityCount",
                style: {minWidth: "100px"},
                sortable: true,
                orderField: "priority"
            },
            {
                title: t("admission:admission.statuses.name"),
                field: "nameHtml",
                style: {minWidth: "200px"},
                sortable: true,
                orderField: "name"
            },
            includeStage && {
                title: t("admission:admission.statuses.stage"),
                field: "stageValue",
                style: {minWidth: "150px"},
                sortable: true,
                orderField: "stage"
            },
            includeStage && {
                title: t("admission:admission.statuses.sev"),
                field: "sevHtml",
                style: {minWidth: "100px"},
                sortable: true,
                orderField: "isStudentEnrollmentVerificationEnabled"
            },
            {
                title: t("admission:admission.statuses.admCategory"),
                field: "admCategoryValue",
                style: {minWidth: "200px"},
                sortable: true,
                orderField: "admissionCategory"
            },
            {
                title: t("admission:admission.statuses.color"),
                style: {minWidth: "100px"},
                field: "colorHtml",
                sortable: true,
                orderField: "hexColor"
            }
        ].filter(Boolean)
    }

    onClickDeleteMulti = async () => {
        const checkedItems = this.props.data.filter((item) => item.isChecked)
        const payload = checkedItems.map((item) => item.id)
        const lockedStages = checkedItems.filter((stage) => stage.isLock)

        if (lockedStages.length) {
            toastError(this.props.t("common:message.cantDeleteLockedItem"))
            return
        }

        try {
            this.props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await settingStatusService.deleteStatus(payload)
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onClickDuplicateMulti = async () => {
        const {data} = this.props
        const payload = data
            .filter((item) => item.isChecked)
            .map(({name, stageId, isStudentEnrollmentVerificationEnabled, admissionCategory}) => ({
                name: `${name} (Copy)`,
                stageId,
                isStudentEnrollmentVerificationEnabled,
                admissionCategory: +admissionCategory
            }))
        try {
            this.props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await settingStatusService.duplicateStatus(payload)
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onClickEdit = (editItem) => {
        const {newItemData, stages} = this.state
        if (newItemData) {
            toastWarning(this.props.t("common:validation.saveBeforeEdit"))
            return
        }
        const stagesKeyById = keyBy(stages, "id")
        const admCategoriesKeyById = keyBy(admCategories, "id")

        this.setState(
            {
                newItemData: {
                    ...editItem,
                    nameData: editItem.name,
                    stageData: stagesKeyById[editItem.stageId],
                    hexColorData: editItem.hexColor || defaultColor,
                    admCategoryData: admCategoriesKeyById[editItem.admissionCategory],
                    isSEVData: editItem.isStudentEnrollmentVerificationEnabled
                },
                oldItemData: {...editItem}
            },
            () => {
                const newData = this.props.data.map((item) => {
                    if (item.id === editItem.id) {
                        item.nameHtml = this.renderNameInput()
                        item.stageValue = this.renderStageDropdown()
                        item.sevHtml = this.renderSEVHtml()
                        item.admCategoryValue = this.renderAdmCategoryDropdown()
                        item.colorHtml = this.renderColorHtml()
                        return item
                    }
                    return item
                })
                this.props.dispatch({data: newData})
            }
        )
    }

    onClickDelete = async (deletedItem) => {
        if (deletedItem.isLock) {
            toastError(this.props.t("common:message.cantDeleteLockedItem"))
            return
        }
        if (deletedItem.isForm) {
            this.setState({newItemData: null, oldItemData: null})
            await this.getData()
            return
        }
        try {
            this.props.dispatch({isLoading: true})
            this.setState({newItemData: null, oldItemData: null})
            await settingStatusService.deleteStatus([deletedItem.id])
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onChangeNewItemData = (key, value) => {
        const {newItemData} = this.state
        const {data} = this.props
        newItemData[key] = value
        const newData = data.map((item) => {
            if (item.id === newItemData.id) {
                item.nameHtml = this.renderNameInput()
                item.stageValue = this.renderStageDropdown()
                item.sevHtml = this.renderSEVHtml()
                item.admCategoryValue = this.renderAdmCategoryDropdown()
                item.colorHtml = this.renderColorHtml()
                return item
            }
            return item
        })
        this.setState({newItemData})
        this.props.dispatch({data: newData})
    }

    renderNameInput = () => {
        const newItemData = this.state.newItemData || {}
        return (
            <BaseInput
                value={newItemData.nameData}
                placeholder="Name"
                onChange={(value) => this.onChangeNewItemData("nameData", value)}
                onBlur={(e) => this.onSearchStatus(e.target.value)}
            />
        )
    }

    onSearchChange = async (input, callback) => {
        await this.getAllStages(input)
        callback(this.state.stages)
    }

    onSearchStatus = async (input) => {
        const params = this.getParams()
        const {newItemData} = this.state
        try {
            const {data} = await settingStatusService.getAllStatuses({
                ...params,
                ...(input && {filter: {search: input}})
            })
            if (data.length) {
                newItemData.nameExist = true
            } else {
                delete newItemData.nameExist
            }
            this.setState({newItemData})
        } catch (e) {
            handleError(e)
        } finally {
        }
    }

    renderStageDropdown = () => {
        const {stages} = this.state
        const {stageData} = this.state.newItemData || {}

        return (
            <KlassappTableDropdown
                options={stages}
                value={stageData}
                onChange={(value) => this.onChangeNewItemData("stageData", value)}
                placeholder="Stage"
            />
        )
    }

    renderAdmCategoryDropdown = () => {
        const {admCategoryData} = this.state.newItemData || {}

        return (
            <KlassappTableDropdown
                options={admCategories}
                value={admCategoryData}
                getOptionLabel={(option) => this.renderAdmCategoryHtml(option)}
                onChange={(value) => this.onChangeNewItemData("admCategoryData", value)}
                placeholder="ADM Category"
                hasEmptyOption
            />
        )
    }

    getMaxOrderInList = () => {
        const orders = this.props.data.length ? this.props.data.map((item) => item.priorityCount) : [0]
        return Math.max(...orders)
    }

    onClickAddItem = () => {
        const priorityCount = this.getMaxOrderInList() + 1
        const {includeStage = true} = this.props
        const newItem = {
            id: uuidv4(),
            nameHtml: this.renderNameInput(),
            ...(includeStage && {stageValue: this.renderStageDropdown()}),
            admCategoryValue: this.renderAdmCategoryDropdown(),
            ...(includeStage && {sevHtml: this.renderSEVHtml()}),
            isForm: true,
            priorityCount,
            hexColorData: defaultColor,
            colorHtml: this.renderColorHtml(),
            ...(!includeStage && {stateData: this.state.stages[0]?.state}),
            ...(!includeStage && {stageData: {stageId: this.state.stages[0]?.stageId}})
        }
        const {data} = this.props
        const newData = [...data]
        newData.push(newItem)
        this.setState({newItemData: newItem, oldItemData: null})
        this.props.dispatch({data: newData})
    }

    validateDataBeforeSubmit = () => {
        const {nameData, stageData, nameExist} = this.state.newItemData
        if (!nameData) {
            toastError(this.props.t("common:validation.cantEmpty", {field: "The Stage Name"}))
            return false
        }
        if (!stageData) {
            toastError(this.props.t("common:validation.hasToBeSelect", {field: "The Stage"}))
            return false
        }
        if (nameExist) {
            toastError(this.props.t("common:validation.fieldAlreadyExist", {field: "The Status Name"}))
            return false
        }
        return true
    }

    onClickSave = async () => {
        if (!this.validateDataBeforeSubmit()) {
            return
        }
        const {nameData, stageData, isSEVData, hexColorData, admCategoryData, isForm, id} = this.state.newItemData
        if (isForm) {
            const submitData = {
                name: nameData,
                stageId: stageData.stageId,
                isStudentEnrollmentVerificationEnabled: !!isSEVData,
                hexColor: hexColorData || defaultColor,
                admissionCategory: admCategoryData?.id ? +admCategoryData.id : null
            }
            await this.createStatus(submitData)
        } else {
            const submitData = {
                name: nameData,
                stageId: stageData.stageId,
                isStudentEnrollmentVerificationEnabled: !!isSEVData,
                hexColor: hexColorData || defaultColor,
                admissionCategory: admCategoryData?.id ? +admCategoryData.id : null
            }
            await this.updateStatus(id, submitData)
        }
        this.setState({newItemData: null, oldItemData: null}, () => {
            this.getData()
        })
        this.props.dispatch({isShowTableHeaderAction: false})
    }

    createStatus = async (data) => {
        try {
            this.props.dispatch({isLoading: true})
            this.setState({isSubmitting: true})
            await settingStatusService.createStatus(data)
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
            this.setState({isSubmitting: false})
        }
    }

    updateStatus = async (id, data) => {
        try {
            this.props.dispatch({isLoading: true})
            this.setState({isSubmitting: true})
            await settingStatusService.updateStatus(id, data)
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
            this.setState({isSubmitting: false})
        }
    }

    onDraggableEnd = (result) => {
        const {destination, source} = result || {}
        if (!destination || destination.index === source.index) {
            return
        }
        if (destination.index === source.index) {
            return
        }
        const {id} = this.props.data[source.index]
        const newPriority = getPositionMoveInObject(
            this.props.data,
            "priority",
            source.index,
            destination.index,
            this.props.orderField.order === Order.Asc
        )
        this.updateStatus(id, {priority: newPriority})
    }

    onClickCancel = () => {
        const {oldItemData, stages} = this.state
        const {data} = this.props
        const stagesKeyById = keyBy(stages, "id")
        const admCategoriesKeyById = keyBy(admCategories, "id")
        const newData = data
            .filter((item) => !item.isForm)
            .map((item) => {
                if (oldItemData && oldItemData.id === item.id) {
                    const oldStageData = stagesKeyById[oldItemData.stageId]
                    const oldAdmCategoryData = admCategoriesKeyById[oldItemData.admissionCategory]
                    item.nameHtml = oldItemData.name
                    item.stageValue = get(oldStageData, "name", "")
                    item.admCategoryValue = this.renderAdmCategoryHtml(oldAdmCategoryData)
                    item.sevHtml = this.renderSEVHtml(oldItemData)
                    item.colorHtml = this.renderColorHtml(oldItemData)
                    return item
                }
                return item
            })
        this.setState({newItemData: null, oldItemData: null})
        this.props.dispatch({data: newData})
    }

    render() {
        const {newItemData, isSubmitting, permissions} = this.state
        const {
            t,
            isLoading,
            page,
            total,
            pageSize,
            columns,
            data,
            menuActions,
            tableHeaderActions,
            allFields,
            fields,
            orderField,
            isHideMenuActions,
            isShowTableHeaderAction
        } = this.props

        return (
            <div className={styles.wrapper}>
                <KlassappTableHeader
                    isShowAction={isShowTableHeaderAction}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.props.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    data={data}
                    menuActions={isHideMenuActions ? [] : menuActions}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    orderField={orderField}
                    isDraggable={!newItemData && orderField.field === "priority"}
                    isShowCheckedColumn
                    onClickRowItem={() => {}}
                    onChangeFields={this.props.onChangeFields}
                    onUpdateRowData={this.props.onUpdateRowData}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onClickSortColumn={this.props.onClickSortColumn}
                    onDraggableEnd={this.onDraggableEnd}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onChangeAllFields={this.props.onChangeAllFields}
                />
                <PermissionsRequired permissions={permissions}>
                    {!newItemData ? (
                        !isLoading && <AddItemCircleButton onClick={this.onClickAddItem} />
                    ) : (
                        <div className={styles.buttonWrap}>
                            <SecondaryButton
                                title={t("common:action.cancel")}
                                className={styles.cancelBtn}
                                onClick={this.onClickCancel}
                            />
                            <BaseButton
                                title={t("common:action.save").toUpperCase()}
                                onClick={this.onClickSave}
                                loading={isSubmitting}
                            />
                        </div>
                    )}
                </PermissionsRequired>
            </div>
        )
    }
}

export default KlassappTableHOC(withTranslation(["financialAid", "admission", "common"])(Statuses))
