import React from "react"
import {TimePicker} from "antd"
import {Icon} from "components/Icon"
import classNames from "classnames"
import styles from "./BaseDatePicker.module.css"

type TimePickerComponentsProps = {[key in string]: any}

export type BaseTimePickerProps = TimePickerComponentsProps & {
    value?: any
    onChange?: any
    className?: string
    placeholder?: string
    format?: string
    allowClear?: boolean
    disabled?: boolean
    readOnly?: boolean
    error?: boolean
    suffixIcon?: JSX.Element
    disabledDate?: (currentDate: any) => boolean
}

export function BaseTimePicker(props: BaseTimePickerProps) {
    const {onChange, className, error, ...rest} = props

    return (
        <TimePicker
            onChange={onChange}
            onSelect={onChange}
            className={classNames(styles.datePicker, className, {[styles.error]: error})}
            suffixIcon={
                <div className={styles.calendarIconWrap}>
                    <Icon icon="TIME_CLOCK_OUTLINE" color="#fff" />
                </div>
            }
            {...rest}
        />
    )
}

BaseTimePicker.defaultProps = {
    suffixIcon: (
        <div className={styles.calendarIconWrap}>
            <Icon icon="TIME_CLOCK_OUTLINE" color="#fff" />
        </div>
    )
}
