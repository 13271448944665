import {get} from "lodash"
import BaseService from "./Base"
import {StudentActivityLog} from "types/student-activity-log"
import {StudentAdvisorLog} from "types/student-advisor-log"

class StudentActivityLogService extends BaseService {
    async getStatusLogs(params) {
        const response = await this.post("/v1/student-status-logs/list", params)
        return get(response, "data", [])
    }

    async getNewStatusLogs(params) {
        const response = await this.post("/v1/new-student-status-logs/list", params)
        return get(response, "data", [])
    }

    async getActivityLogs(params): Promise<{total: number; data: StudentActivityLog[]}> {
        const response = await this.post("/v1/student-activity-logs/list", params)
        return get(response, "data", {})
    }

    async getAdvisorLogs(params): Promise<{total: number; data: StudentAdvisorLog[]}> {
        const response = await this.post("/v1/student-advisor-logs/list", params)
        return get(response, "data", {})
    }

    async createActivityLog(logs: Partial<StudentActivityLog>[]): Promise<StudentActivityLog[]> {
        const response = await this.post("/v1/student-activity-logs/create", {logs})
        return get(response, "data.data", null)
    }
}

export default StudentActivityLogService
