/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import cx from "classnames"
import styles from "./PanelNavigation.module.css"
import {ReactComponent as CloseIcon} from "./assets/collapse.svg"
import {ReactComponent as PrevIcon} from "./assets/prev.svg"
import {ReactComponent as NextIcon} from "./assets/next.svg"

type NavigationItem = {
    enabled: boolean
    onClick?: () => void
}

type PanelNavigationProps = {
    nextItem?: NavigationItem
    prevItem?: NavigationItem
    onClose?: () => void
}

export const PanelNavigation = ({nextItem, prevItem, onClose}: PanelNavigationProps) => {
    return (
        <div className={styles.navigationWrap}>
            <div className={styles.navigationIconWrap} onClick={onClose}>
                <CloseIcon className={styles.navigationIcon} width={24} height={24} />
            </div>
            <div className={styles.navigationSeparator} />
            <div className={cx(styles.navigationIconWrap, {[styles.navigationIconWrap__disabled]: !nextItem?.enabled})}>
                <NextIcon
                    className={cx(styles.navigationIcon, {[styles.navigationIcon__active]: nextItem?.enabled})}
                    width={24}
                    height={24}
                />
            </div>
            <div className={cx(styles.navigationIconWrap, {[styles.navigationIconWrap__disabled]: !prevItem?.enabled})}>
                <PrevIcon
                    className={cx(styles.navigationIcon, {[styles.navigationIcon__active]: prevItem?.enabled})}
                    width={24}
                    height={24}
                />
            </div>
            <div className={styles.navigationSeparator} />
        </div>
    )
}
