import {useVisible} from "hooks"
import {useState} from "react"
import {ApplicationInfoPanel, ApplicationInfoPanelProps} from "sections/shared/careerService"
import {ApplicationInfoDrawer} from "uiKit/ApplicationInfoDrawer"

type Props = {
    data: ApplicationInfoPanelProps
    onClose: () => void
}

export const ApplicationInfoSidebar = (props: Props) => {
    const {data, onClose} = props
    const drawerVisible = useVisible(false)
    const [activeSection, setActiveSection] = useState<string>()

    const onOpenDrawer = (_activeSection: string) => {
        setActiveSection(_activeSection)
        drawerVisible.open()
    }

    return (
        <>
            <ApplicationInfoPanel {...data} onClose={onClose} onOpenDrawer={onOpenDrawer} />
            <ApplicationInfoDrawer
                {...data}
                displayDrawer={drawerVisible.isVisible}
                onCloseDrawer={() => drawerVisible.close()}
                activeSection={activeSection}
            />
        </>
    )
}
