import React, {useState} from "react"
import styles from "./ListActivities.module.css"
import {BaseButton} from "components"
import {ActivityPanel} from "sections/shared/student"
import {PanelExpandedSection} from "uiKit/PanelExpandedSection"
import {Tabs} from "antd"
import {useTranslation} from "react-i18next"
import WorkflowTriggersTable from "sections/Workflow/WorkflowTriggersTable/WorkflowTriggersTable"
import {TabHeader} from "components/Tab"

type DisplayPart = "list" | "create"

const {TabPane} = Tabs

enum Tab {
    Activities = "activities",
    WorkflowTriggers = "workflow-triggers"
}

type Props = {
    studentId: number
    departmentId?: number
    onNavigate?: (displayMode: DisplayPart) => void
    onClose?: () => void
}

export function ListActivities(props: Props) {
    const {studentId, departmentId, onClose, onNavigate} = props
    const {t} = useTranslation(["activities", "common"])
    const [activeTabKey, onChangeTab] = useState<string>(Tab.Activities)

    return (
        <PanelExpandedSection title="Activities" onClose={onClose}>
            <Tabs className="fullwidth" activeKey={activeTabKey} onChange={onChangeTab}>
                <TabPane tab={<TabHeader title={t("activities.title")} />} key={Tab.Activities}>
                    {activeTabKey === Tab.Activities && (
                        <>
                            <div className={styles.actionWrap}>
                                <BaseButton
                                    onClick={() => onNavigate("create")}
                                    title="Add activity"
                                    className={styles.addAction}
                                />
                            </div>
                            <ActivityPanel
                                departmentId={departmentId}
                                studentId={studentId}
                                title=""
                                wrapContent={false}
                                showTitle={false}
                                displayMode="panel"
                            />
                        </>
                    )}
                </TabPane>
                <TabPane tab={<TabHeader title={t("activities.workflowTriggers")} />} key={Tab.WorkflowTriggers}>
                    {activeTabKey === Tab.WorkflowTriggers && (
                        <WorkflowTriggersTable profileId={studentId} departmentId={departmentId} displayMode="panel" />
                    )}
                </TabPane>
            </Tabs>
        </PanelExpandedSection>
    )
}
