import React from "react"
import {
    KlassDropdown,
    KlassDropdownAsync,
    KlassDropdownProps,
    KlassDropdownAsyncProps,
    KlassDropAsyncPaginate
} from "components/Select"
import {KlassappTableContext} from "./KlassappTableContext"
import DepartmentSubunitSelect from "components/DepartmentSubunitSelect"

export function KlassappTableDropdown(props: Partial<KlassDropdownProps>) {
    return (
        <KlassappTableContext.Consumer>
            {({setIsShowScrollTable}) => (
                <KlassDropdown
                    onMenuOpen={() => setIsShowScrollTable(false)}
                    onMenuClose={() => setIsShowScrollTable(true)}
                    {...props}
                />
            )}
        </KlassappTableContext.Consumer>
    )
}

export function KlassappTableDropdownAsyncPaginate(props: Partial<KlassDropdownAsyncProps>) {
    return (
        <KlassappTableContext.Consumer>
            {({setIsShowScrollTable}) => (
                <KlassDropAsyncPaginate
                    onMenuOpen={() => setIsShowScrollTable(false)}
                    onMenuClose={() => setIsShowScrollTable(true)}
                    {...props}
                />
            )}
        </KlassappTableContext.Consumer>
    )
}

export function KlassappTableDropdownAsync(props: Partial<KlassDropdownAsyncProps>) {
    return (
        <KlassappTableContext.Consumer>
            {({setIsShowScrollTable}) => (
                <KlassDropdownAsync
                    onMenuOpen={() => setIsShowScrollTable(false)}
                    onMenuClose={() => setIsShowScrollTable(true)}
                    {...props}
                />
            )}
        </KlassappTableContext.Consumer>
    )
}

export function KlassappTableDepartmentSubunitSelect(props: Partial<KlassDropdownAsyncProps>) {
    return (
        <KlassappTableContext.Consumer>
            {({setIsShowScrollTable}) => (
                <DepartmentSubunitSelect
                    onMenuOpen={() => setIsShowScrollTable(false)}
                    onMenuClose={() => setIsShowScrollTable(true)}
                    {...props}
                />
            )}
        </KlassappTableContext.Consumer>
    )
}
