import React, {useState, useRef} from "react"
import {Checkbox} from "antd"
import {useTranslation} from "react-i18next"
import {useClickAway} from "react-use"
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd"
import {isFunction} from "lodash"
import styles from "../KlassappTable.module.css"

type Props = {
    fields: string[]
    allFields: string[]
    unselectableFields?: string[]
    onChangeFields: (fields: string[]) => void
    setIsShowChooseCols?: (value: boolean) => void
    onDraggableColumn?: (sourceIndex: number, destIndex: number) => void
    onChangeAllFields?: (fields: string[]) => void
}

export function ColumnPicker(props: Props) {
    const {
        fields,
        allFields,
        unselectableFields,
        onChangeFields,
        setIsShowChooseCols,
        onDraggableColumn,
        onChangeAllFields
    } = props
    const {t} = useTranslation("common")
    const [allDataFields, setAllDataFields] = useState(allFields)
    const [activeFields, setActiveFields] = useState(fields)
    const colsPickerRef = useRef(null)

    useClickAway(colsPickerRef, () => {
        setIsShowChooseCols?.(false)
    })

    const toggleField = (field: string) => {
        if (activeFields.includes(field)) {
            const newFields = activeFields.filter((item) => item !== field)
            setActiveFields(newFields)
            onChangeFields(newFields)
        } else {
            activeFields.push(field)
            setActiveFields(activeFields)
            onChangeFields(activeFields)
        }
    }

    const reorder = (list: string[], startIndex: number, endIndex: number): string[] => {
        const result = Array.from(list)
        const removedItems: any = result.splice(startIndex, 1)
        const [removed] = removedItems
        result.splice(endIndex, 0, removed)
        return result
    }

    const onDragEnd = (result) => {
        const {destination, source} = result || {}
        if (!destination || destination.index === source.index) {
            return
        }
        const newAllFields = reorder(allDataFields, source.index, destination.index)
        setAllDataFields(newAllFields)
        onChangeAllFields?.(newAllFields)
        onDraggableColumn?.(source.index, destination.index)
    }

    const renderDraggableCols = () => {
        return (
            <DragDropContext onDragEnd={onDragEnd} className={styles.termItemWrap}>
                <Droppable droppableId="taskParticipants">
                    {(droppableProvided) => (
                        <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                            {allDataFields.map((field, index) =>
                                unselectableFields?.includes(field) ? null : (
                                    <Draggable draggableId={field || `${index}`} index={index} key={field}>
                                        {(provided) => (
                                            <div
                                                key={index}
                                                className={styles.colWrap}
                                                ref={provided.innerRef}
                                                {...{...provided.draggableProps, ...provided.dragHandleProps}}>
                                                <div
                                                    key={index}
                                                    className={styles.colPickerItem}
                                                    onClick={() => toggleField(field)}>
                                                    <Checkbox checked={activeFields.includes(field)} />
                                                    <div className={styles.colDraggable}></div>
                                                    <p className={styles.colTitle}>{field}</p>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            )}
                            {droppableProvided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }

    const renderCols = () => {
        return (
            <div className={styles.colsNormalWrap}>
                {allDataFields.map((field, index) => (
                    <div key={index} className={styles.colPickerItem} onClick={() => toggleField(field)}>
                        <Checkbox checked={activeFields.includes(field)} />
                        <div className={styles.colDraggable}></div>
                        <p className={styles.colTitle}>{field}</p>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div ref={colsPickerRef} className={`col-picker ${styles.colsPicker}`}>
            <p className={styles.colsPickerTitle}>{t("columnPicker.column")}</p>
            <div className={`${styles.colsPickerBody} custom-scroll`}>
                {isFunction(onDraggableColumn) ? renderDraggableCols() : renderCols()}
            </div>
        </div>
    )
}
