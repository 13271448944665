import BaseService from "./Base"
import {ApiListResponse, ApiListResponseResult, ApiResponse} from "types/api"
import {
    RateSheetListItemDetails,
    RateSheetDetails,
    CreateRateSheetPayload,
    EditRateSheetPayload
} from "types/rate-sheets"
import {AxiosResponse} from "axios"

export class RateSheetsService extends BaseService {
    getAll = async (params): Promise<ApiListResponseResult<RateSheetListItemDetails[]>> => {
        const {data: response}: AxiosResponse<ApiListResponse<RateSheetListItemDetails[]>> = await this.post(
            "/v1/rate-sheets/list",
            params
        )

        if (!response.success) {
            throw response
        }

        return {
            data: response.data,
            total: response.total
        }
    }

    search = async ({search = ""}: {search: string}): Promise<RateSheetListItemDetails[]> => {
        const {data: response}: AxiosResponse<ApiListResponse<RateSheetListItemDetails[]>> = await this.post(
            "/v1/rate-sheets/list",
            {
                filter: {search, status: "active"},
                range: {page: 1, pageSize: 100}
            }
        )

        if (!response.success) {
            throw response
        }

        return response.data
    }

    getOne = async (rateSheetId: number): Promise<RateSheetDetails | null> => {
        const {data: response}: AxiosResponse<ApiListResponse<RateSheetDetails[]>> = await this.post(
            "/v1/rate-sheets/list",
            {
                filter: {
                    id: [rateSheetId]
                },
                linkedEntities: ["rates", "ranges"]
            }
        )

        if (!response.success) {
            throw response
        }

        return response.data[0] ?? null
    }

    create = async (payload: CreateRateSheetPayload) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/rate-sheets/create", payload)

        if (!response.success) {
            throw response
        }
    }

    update = async (payload: EditRateSheetPayload) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/rate-sheets/edit", payload)

        if (!response.success) {
            throw response
        }
    }

    deleteBulk = async (id: number[]) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/rate-sheets/delete", {id})

        if (!response.success) {
            throw response
        }
    }
}
