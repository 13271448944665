import React, {useCallback, useEffect, useState} from "react"
import {BaseButton} from "components/buttons"
import {PaymentPlanDetail} from "./parts"
import styles from "./PaymentPlanTab.module.css"
import {PaymentPlan} from "types/student-account/payment-plan"
import {Spin} from "antd"
import {studentAccountService} from "services"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {useParams} from "react-router-dom"
import {handleError} from "helpers"

export function PaymentPlanTab(props) {
    const params = useParams<{profileId: string}>()
    const profileId = +params.profileId
    const [paymentPlans, setPaymentPlans] = useState<PaymentPlan[]>([])
    const [newPaymentPlan, setNewPaymentPlan] = useState(false)
    const [isLoading, setLoading] = useState(true)

    const loadPaymentPlans = useCallback(async () => {
        try {
            setLoading(true)
            const {data} = await studentAccountService.getPaymentPlans({
                filter: {
                    profileIds: [profileId]
                },
                range: {
                    pageSize: UNLIMITED_PAGE_SIZE,
                    page: 1
                },
                linkedEntities: true
            })
            setPaymentPlans(data)
        } catch (error) {
            handleError(error)
            setPaymentPlans([])
        } finally {
            setLoading(false)
        }
    }, [profileId])

    useEffect(() => {
        loadPaymentPlans()
    }, [loadPaymentPlans])

    const onClickCreate = useCallback(() => {
        setNewPaymentPlan(true)
    }, [])

    const onSave = useCallback((paymentPlan: PaymentPlan, isNew = false) => {
        setPaymentPlans((oldPlans) =>
            isNew
                ? [paymentPlan, ...oldPlans]
                : oldPlans.map((plan) => (plan.paymentPlanId === paymentPlan.paymentPlanId ? paymentPlan : plan))
        )
    }, [])

    const onSaveNew = useCallback(
        (createdPaymentPlan: PaymentPlan) => {
            onSave(createdPaymentPlan, true)
            setNewPaymentPlan(false)
        },
        [onSave]
    )

    const onCancelNew = useCallback(() => {
        setNewPaymentPlan(false)
    }, [])

    const onUpdate = useCallback(() => {
        loadPaymentPlans()
    }, [loadPaymentPlans])

    return (
        <div>
            {!newPaymentPlan ? (
                <div className={styles.buttonWrap}>
                    <BaseButton title="CREATE" onClick={onClickCreate} />
                </div>
            ) : (
                <>
                    <p className={styles.newItemText}>New Plan</p>
                    <PaymentPlanDetail key="new" profileId={profileId} onSave={onSaveNew} onCancel={onCancelNew} />
                </>
            )}

            <div>
                {isLoading ? (
                    <Spin />
                ) : (
                    paymentPlans.map((paymentPlan) => (
                        <PaymentPlanDetail
                            key={paymentPlan.paymentPlanId}
                            profileId={profileId}
                            oldPaymentPlan={paymentPlan}
                            onSave={onSave}
                            onUpdate={onUpdate}
                        />
                    ))
                )}
            </div>
        </div>
    )
}
