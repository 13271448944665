/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useMemo, useState} from "react"
import {TFunction, useTranslation} from "react-i18next"
import moment from "moment"
import classNames from "classnames"
import {AdvisorsPopup, AdvisorTitle, KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {
    autoDialerService,
    coreSocket,
    reportsService,
    settingStatusService,
    studentService,
    studentServiceV2,
    userProfileServiceV3
} from "services"
import {
    checkHasPermissionToUpdateAdvisor,
    getFullName,
    handleError,
    renderList,
    renderStatus,
    toastInfo,
    toastSuccess
} from "helpers"
import {Auth} from "types/auth"
import {BaseDepartmentId, DepartmentDialerModuleMapping, DepartmentKeyNameByIdForPriority} from "types/departments"
import styles from "./StudentListTable.module.css"
import {useModel} from "hooks/useModel"
import {Icon} from "components/Icon"
import {useVisible} from "hooks"
import {ActivityPopup} from "sections/shared/student"
import {get, head, isEmpty, uniq, uniqBy} from "lodash"
import {StatusDetails} from "types/settings/statuses"
import {DUE_COLOR_OPTIONS} from "types/contact-strategy"
import {KlassDropdown} from "components"
import {KlassappTableProps} from "types/common"
import {FieldOperator, FilterKey, FilterList} from "types/filter"
import {useEdularModulesContext} from "@edular/modules"
import {SOCKET_ROOM_PRIORITY_TABLE, SocketEvent} from "helpers/socket"
import {emitEvent, offEvent, onEvent} from "helpers/eventHelper"
import {
    EVENT_STUDENT_ACTIVITY_COMPLETION_TOGGLED,
    EVENT_STUDENT_ACTIVITY_CREATED,
    StudentActivity
} from "types/activity"
import {StudentStatusLabel} from "components/StudentStatusLabel"
import {StudentStatus} from "types/students"
import {exportCsv, exportExcel} from "helpers/export-table"
import {ExportFileType} from "components/ui"
import {ChatModalV2} from "sections/NewCommunication/shared"
import {useAllAvailableStudentStatuses} from "hooks/student-statuses/useAllAvailableStudentStatuses"
import {useAllLeadTypes} from "hooks/useAllLeadTypes"
import {VerificationStatus} from "types/fin-aid/verification-status"
import {FinAidOfferStatus} from "types/fin-aid/fin-aid"
import {ChangeStatusPopup} from "./parts"
import {RetentionCategory} from "types/student-services/sap"
import {useAllLeadSources} from "hooks/useAllLeadSources"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {useAllVerificationStatuses} from "hooks/useAllVerificationStatuses"

const MODULE_REPORT_INFO = {
    [BaseDepartmentId.Admissions]: {
        reportKey: "admissions/students",
        filename: ["admissions-student-report", moment().format("DD_MM_YYYY-hhmm")].join("-")
    },
    [BaseDepartmentId.FinancialAid]: {
        reportKey: "financialAid/students",
        filename: ["financial-aid-student-report", moment().format("DD_MM_YYYY-hhmm")].join("-")
    }
}

const MODULE_REPORT_INFO_NEW_FILTERS = {
    [BaseDepartmentId.Admissions]: {
        reportKey: "newFilter_admissions/students",
        filename: ["admissions-student-report", moment().format("DD_MM_YYYY-hhmm")].join("-")
    },
    [BaseDepartmentId.FinancialAid]: {
        reportKey: "newFilter_financialAid/students",
        filename: ["financial-aid-student-report", moment().format("DD_MM_YYYY-hhmm")].join("-")
    },
    [BaseDepartmentId.Academics]: {
        reportKey: "newFilter_academics/students",
        filename: ["academics-student-report", moment().format("DD_MM_YYYY-hhmm")].join("-")
    }
}

type Props = KlassappTableProps & {
    studentForm: any
    studentFormSearch: string
    canShowAllStudents: boolean
    canHaveAccessOthers: boolean
    departmentId: number
    studentId: number
    statusChange?: any
    studentStatusChange?: any
    enrollmentProfileIdChange: any
    advisorTitle: AdvisorTitle
    filterMemoryKey: FilterKey
    reloadStudentAdvisors: () => void
    updateState?: (data: any) => void
    t: TFunction
    exportFileType?: ExportFileType
    isFinancialVerificationTab?: boolean
    isFinancialPackagingTab?: boolean
    useNewFilter?: boolean
}

function StudentListTable(props: Props) {
    const model = useModel()
    const {
        data,
        page,
        total,
        pageSize,
        columns,
        fields,
        allFields,
        isLoading,
        orderField,
        getCurrentPage,
        getCurrentPageSize,
        getCurrentData,
        studentForm,
        studentFormSearch,
        canShowAllStudents,
        canHaveAccessOthers,
        departmentId,
        studentId,
        isShowTableHeaderAction,
        tableHeaderActions,
        statusChange,
        studentStatusChange,
        enrollmentProfileIdChange,
        isLoadedTableFuncs,
        filterMemoryKey,
        reloadStudentAdvisors,
        exportFileType,
        isFinancialPackagingTab = false,
        isFinancialVerificationTab = false,
        useNewFilter = false
    } = props
    const {t} = useTranslation(["common", "financialAid"])
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const userDateFormat = model.getUserDateFormat()
    const [statuses, setStatuses] = useState<StatusDetails[]>([])
    const {statuses: studentStatuses} = useAllAvailableStudentStatuses()
    const [studentProfileIds, setStudentProfileIds] = useState<number[]>([])
    const [studentCampusIds, setStudentCampusIds] = useState<number[]>([])
    const [type, setType] = useState<string>("")
    const advisorsPopup = useVisible(false)
    const hasPermissionToUpdateAdvisor = checkHasPermissionToUpdateAdvisor(departmentId, model) || model.isAdmin()
    const chatPopup = useVisible(false)
    const activityPopup = useVisible(false)
    const changeStatusPopup = useVisible(false)
    const [selectedStudent, setSelectedStudent] = React.useState<Auth.DepartmentStudent | undefined>()
    const loadingSeqRef = React.useRef(Math.random())
    const activeStudentRef = React.useRef<number | undefined>()
    const {isModuleEnable} = useEdularModulesContext()
    const {leadSources} = useAllLeadSources()
    const {leadTypes} = useAllLeadTypes()
    const {verificationStatuses} = useAllVerificationStatuses()
    const [isFirstLoaded, setFirstLoaded] = useState(false)
    const [statusStudents, setStatusStudents] = useState([])
    const faTab = isFinancialVerificationTab ? "verification" : isFinancialPackagingTab ? "packaging" : undefined

    useEffect(() => {
        if (studentId) {
            onChangeActiveStudent(studentId)
        }
    }, [studentId])

    useEffect(() => {
        ;(async function getStatuses() {
            try {
                if (model.clientSetting?.isNewStudentStatusesVisible) {
                    return
                }
                const {data: statuses = []} = await settingStatusService.getAllStatuses({
                    filter: {
                        departmentIds: [departmentId]
                    },
                    sort: {
                        orderBy: "priority",
                        orderDir: "asc"
                    },
                    linkedEntities: true
                })
                setStatuses(statuses)
            } catch (error) {
                handleError(error)
            }
        })()
    }, [departmentId, model.clientSetting?.isNewStudentStatusesVisible])

    useEffect(() => {
        if (model.clientSetting?.isNewStudentStatusesVisible) return
        if (!statusChange) return
        const newStatus = statuses.find((status) => status.statusId === statusChange.statusId)
        if (!newStatus) return
        props.dispatch({
            data: getCurrentData().map((student) =>
                student.profileId !== statusChange.profileId
                    ? student
                    : {...student, statusHtml: renderStatusHtml(newStatus.statusId, newStatus.name)}
            )
        })
    }, [statusChange, statuses, model.clientSetting?.isNewStudentStatusesVisible])

    useEffect(() => {
        if (!model.clientSetting?.isNewStudentStatusesVisible) return
        if (!studentStatusChange) return
        const newStatus = studentStatuses.find((status) => status?.statusId === studentStatusChange?.statusId)
        if (!newStatus) return
        props.dispatch({
            data: getCurrentData().map((student) =>
                student.profileId !== studentStatusChange.profileId
                    ? student
                    : {
                          ...student,
                          profileState: newStatus.state,
                          studentStatusHtml: renderStudentStudentStatus(newStatus)
                      }
            )
        })
    }, [studentStatusChange, studentStatuses, model.clientSetting?.isNewStudentStatusesVisible])

    useEffect(() => {
        if (!enrollmentProfileIdChange) return
        ;(async function loadNewStudentEnrollment() {
            try {
                const {
                    data: [newStudent]
                } = await studentService.getDepartmentStudents({
                    skipTotal: true,
                    filter: {
                        departmentId,
                        profileIds: [enrollmentProfileIdChange.newProfileId],
                        faTab
                    }
                })
                if (!newStudent) return
                props.dispatch({
                    data: getCurrentData().map((student, index) =>
                        student.profileId !== enrollmentProfileIdChange.profileId
                            ? student
                            : getStudentRecord(newStudent, index)
                    )
                })
            } catch (error) {
                handleError(error)
            }
        })()
    }, [enrollmentProfileIdChange, faTab])

    useEffect(() => {
        if (exportFileType) {
            handleExportFileTypeSelect(exportFileType)
        }
    }, [exportFileType])

    const getFilter = () => {
        const filters = {...studentForm, ...model.filterUserList}
        const {
            campus,
            program,
            advisor,
            acadAdvisors,
            faAdvisors,
            admAdvisors,
            term,
            startingTerm,
            checklist,
            status,
            state,
            profileStatus,
            applicationCompletedDate,
            startDateRange,
            createdAtRange,
            inquiryDateRange,
            lastActivityDateRange,
            includeArchive = false,
            showDueOnly = false,
            fullyEnrolled,
            onlySEV,
            sapCheckpoints,
            retentionLevels,
            studentStatusIds,
            admissionsLeadSourceIds,
            leadTypeIds,
            activityIds,
            activityDescription,
            faAwardYear,
            faTerm
        } = filters
        let filter: any = {
            departmentId: props.departmentId,
            includeArchive,
            showDueOnly,
            onlySEV,
            activityDescription,
            faTab,
            faAwardYear,
            faTermId: faTerm?.id
        }
        if (fullyEnrolled) {
            filter.fullyEnrolled = true
        }
        if (campus) {
            filter.campusIds = campus.map(({id}) => id)
        }
        if (program) {
            filter.programIds = program.map(({id}) => id)
        }
        if (studentStatusIds) {
            filter.studentStatusIds = studentStatusIds.map(({statusId}) => statusId)
        }
        if (admissionsLeadSourceIds) {
            filter.admissionsLeadSourceIds = admissionsLeadSourceIds.map(({id}) => id)
        }
        if (leadTypeIds) {
            filter.leadTypeIds = leadTypeIds
        }

        if (canShowAllStudents || canHaveAccessOthers) {
            if (isEmpty(advisor)) {
                filter.advisorProfileIds = []
            } else {
                filter.advisorProfileIds = (advisor || []).map((item) => item?.profileId).filter((item) => item)
            }
        } else {
            const profile = model.user.profiles.find((profile) => profile.type === Auth.UserProfileType.Staff)
            filter.advisorProfileIds = [profile.id]
        }

        if (admAdvisors?.length) {
            filter.admAdvisorProfileIds = admAdvisors.map((profile) => profile.profileId)
        }
        if (faAdvisors?.length) {
            filter.faAdvisorProfileIds = faAdvisors.map((profile) => profile.profileId)
        }
        if (acadAdvisors?.length) {
            filter.acadAdvisorProfileIds = acadAdvisors.map((profile) => profile.profileId)
        }

        filter.checklistCompleted = checklist === "completed"
        if (status?.length) {
            filter.statusIds = status.map(({statusId}) => statusId)
        }
        if (state) {
            filter.states = state.map(({id}) => id)
        }
        if (profileStatus) {
            filter.profileStatus = profileStatus.map(({id}) => id === "active")
        }
        if (term) {
            filter.termsIds = term.map(({id}) => id)
        }
        if (startingTerm) {
            filter.startingTermIds = startingTerm.map(({id}) => id)
        }
        if (activityIds) {
            filter.activityIds = activityIds.map(({activityId}) => activityId)
        }
        if (applicationCompletedDate) {
            filter.applicationCompletedDate = moment(applicationCompletedDate).format("YYYY-MM-DD")
        }
        if (startDateRange && startDateRange.length) {
            filter.startDateRange = [
                moment(startDateRange[0]).format("YYYY-MM-DD"),
                moment(startDateRange[1]).format("YYYY-MM-DD")
            ]
        }
        if (createdAtRange && createdAtRange.length) {
            filter.createdAtRange = [
                moment(createdAtRange[0]).startOf("date").toISOString(),
                moment(createdAtRange[1]).endOf("date").toISOString()
            ]
        }
        if (inquiryDateRange && inquiryDateRange.length) {
            filter.inquiryDateRange = [
                moment(inquiryDateRange[0]).format("YYYY-MM-DD"),
                moment(inquiryDateRange[1]).format("YYYY-MM-DD")
            ]
        }
        if (lastActivityDateRange && lastActivityDateRange.length) {
            filter.lastActivityDateRange = [
                moment(lastActivityDateRange[0]).startOf("date").toISOString(),
                moment(lastActivityDateRange[1]).endOf("date").toISOString()
            ]
        }
        if (studentFormSearch) {
            filter.search = studentFormSearch
        }
        if (sapCheckpoints) {
            filter.sapCheckpoints = sapCheckpoints.map(({id}) => id)
        }
        if (retentionLevels) {
            filter.retentionLevels = retentionLevels.map(({id}) => id)
        }

        return filter
    }

    const getNewFilter = useCallback(() => {
        const filters = {...studentForm}
        const {
            profiles,
            campus,
            program,
            acadAdvisors,
            faAdvisors,
            admAdvisors,
            term,
            startingTerm,
            checklist,
            status,
            state,
            profileStatus,
            applicationCompletedDate,
            startDateRange,
            createdAtRange,
            inquiryDateRange,
            lastActivityDateRange,
            includeArchive,
            showDueOnly,
            fullyEnrolled,
            onlySEV,
            sapCheckpoints,
            retentionLevels,
            studentStatusIds,
            admissionsLeadSourceIds,
            leadTypeIds,
            phases,
            shifts,
            activityIds,
            activityDescription,
            faAwardYear,
            faTerm,
            temporaryOutDateRange,
            withdrawalDateRange
        } = filters

        let filter: FilterList = {}
        if (fullyEnrolled) filter.fullyEnrolled = fullyEnrolled
        if (includeArchive) filter.includeArchive = includeArchive
        if (showDueOnly) filter.showDueOnly = showDueOnly
        if (onlySEV) filter.onlySEV = onlySEV
        if (checklist) filter.checklistCompleted = checklist
        if (faTab) {
            filter.faTab = {
                operator: FieldOperator.Equal,
                value: faTab
            }
        }
        if (faAwardYear) {
            filter.faAwardYear = {
                operator: FieldOperator.Equal,
                value: faAwardYear
            }
        }
        if (faTerm) {
            filter.faTermId = {
                operator: FieldOperator.Equal,
                value: faTerm.id
            }
        }

        if (profiles) {
            filter.profileIds = {
                operator: profiles.operator,
                value: profiles.value.map(({profileId}) => profileId)
            }
        }
        if (campus) {
            filter.campusIds = {
                operator: campus.operator,
                value: campus.value.map(({id}) => id)
            }
        }
        if (program) {
            filter.programIds = {
                operator: program.operator,
                value: program.value.map(({id}) => id)
            }
        }
        if (studentStatusIds) {
            filter.studentStatusIds = {
                operator: studentStatusIds.operator,
                value: studentStatusIds.value.map(({statusId}) => statusId)
            }
        }
        if (admissionsLeadSourceIds) {
            filter.admissionsLeadSourceIds = {
                operator: admissionsLeadSourceIds.operator,
                value: admissionsLeadSourceIds.value.map(({id}) => id)
            }
        }
        if (leadTypeIds) {
            filter.leadTypeIds = leadTypeIds
        }
        if (phases) {
            filter.phases = {
                ...phases,
                value: phases.value.map(({phaseId}) => phaseId)
            }
        }
        if (shifts) {
            filter.shifts = {
                ...shifts,
                value: shifts.value.map(({shiftId}) => shiftId)
            }
        }

        // if (!canShowAllStudents && !canHaveAccessOthers) {
        //     const profile = model.user.profiles.find((profile) => profile.type === Auth.UserProfileType.Staff)
        //     filter.advisorProfileIds = {
        //         operator: FieldOperator.Equal,
        //         value: [profile.id]
        //     }
        // }

        if (admAdvisors) {
            filter.admAdvisorProfileIds = {
                operator: admAdvisors.operator,
                value: admAdvisors.value.map((profile) => profile.profileId)
            }
        }
        if (faAdvisors) {
            filter.faAdvisorProfileIds = {
                operator: faAdvisors.operator,
                value: faAdvisors.value.map((profile) => profile.profileId)
            }
        }
        if (acadAdvisors) {
            filter.acadAdvisorProfileIds = {
                operator: acadAdvisors.operator,
                value: acadAdvisors.value.map((profile) => profile.profileId)
            }
        }

        if (status) {
            filter.statusIds = {
                operator: status.operator,
                value: status.value.map(({statusId}) => statusId)
            }
        }
        if (state) {
            filter.states = {
                operator: state.operator,
                value: state.value.map(({id}) => id)
            }
        }
        if (profileStatus) {
            filter.profileStatuses = {
                operator: profileStatus.operator,
                value: profileStatus.value.map(({id}) => id === "active")
            }
        }
        if (term) {
            filter.termIds = {
                operator: term.operator,
                value: term.value.map(({id}) => id)
            }
        }
        if (startingTerm) {
            filter.startingTermIds = {
                operator: startingTerm.operator,
                value: startingTerm.value.map(({id}) => id)
            }
        }
        if (activityIds) {
            filter.activityIds = {
                operator: activityIds.operator,
                value: activityIds.value.map(({activityId}) => activityId)
            }
        }
        if (activityDescription) {
            filter.activityDescription = activityDescription
        }
        if (applicationCompletedDate) {
            filter.applicationCompletedDate = {
                operator: applicationCompletedDate.operator,
                value: Array.isArray(applicationCompletedDate.value)
                    ? applicationCompletedDate.value.map((v) => moment(v).format("YYYY-MM-DD"))
                    : moment(applicationCompletedDate.value).format("YYYY-MM-DD")
            }
        }
        if (startDateRange) {
            filter.startDateRange = {
                operator: startDateRange.operator,
                value: Array.isArray(startDateRange.value)
                    ? startDateRange.value.map((v) => moment(v).format("YYYY-MM-DD"))
                    : moment(startDateRange.value).format("YYYY-MM-DD")
            }
        }
        if (createdAtRange) {
            filter.createdAtRange = {
                operator: createdAtRange.operator,
                value: Array.isArray(createdAtRange.value)
                    ? createdAtRange.value.map((v) => moment(v).format("YYYY-MM-DD"))
                    : moment(createdAtRange.value).format("YYYY-MM-DD")
            }
        }
        if (inquiryDateRange) {
            filter.inquiryDateRange = {
                operator: inquiryDateRange.operator,
                value: Array.isArray(inquiryDateRange.value)
                    ? inquiryDateRange.value.map((v) => moment(v).format("YYYY-MM-DD"))
                    : moment(inquiryDateRange.value).format("YYYY-MM-DD")
            }
        }
        if (lastActivityDateRange) {
            filter.lastActivityDateRange = {
                operator: lastActivityDateRange.operator,
                value: Array.isArray(lastActivityDateRange.value)
                    ? lastActivityDateRange.value.map((v) => moment(v).format("YYYY-MM-DD"))
                    : moment(lastActivityDateRange.value).format("YYYY-MM-DD")
            }
        }
        if (sapCheckpoints) {
            filter.sapCheckpoints = {
                operator: sapCheckpoints.operator,
                value: sapCheckpoints.value.map(({id}) => id)
            }
        }
        if (retentionLevels) {
            filter.retentionLevels = {
                operator: retentionLevels.operator,
                value: retentionLevels.value.map(({id}) => id)
            }
        }

        Object.values(RetentionCategory).forEach((x) => {
            const keyName = `riskLevel${x}`
            if (filters[keyName]) {
                filter[keyName] = {
                    operator: filters[keyName].operator,
                    value: filters[keyName].value?.id
                }
            }
        })

        if (temporaryOutDateRange) {
            filter.temporaryOutDateRange = {
                operator: temporaryOutDateRange.operator,
                value: Array.isArray(temporaryOutDateRange.value)
                    ? temporaryOutDateRange.value.map((v) => moment(v).format("YYYY-MM-DD"))
                    : moment(temporaryOutDateRange.value).format("YYYY-MM-DD")
            }
        }
        if (withdrawalDateRange) {
            filter.withdrawalDateRange = {
                operator: withdrawalDateRange.operator,
                value: Array.isArray(withdrawalDateRange.value)
                    ? withdrawalDateRange.value.map((v) => moment(v).format("YYYY-MM-DD"))
                    : moment(withdrawalDateRange.value).format("YYYY-MM-DD")
            }
        }

        return {
            filters: filter,
            departmentId: props.departmentId,
            search: studentFormSearch
        }
    }, [props.departmentId, studentFormSearch, studentForm, canShowAllStudents, canHaveAccessOthers])

    const renderListHtml = React.useCallback((list: any[], field?: string) => {
        return <span>{list?.map((item) => (field ? item[field] : item))?.join(", ")}</span>
    }, [])

    const renderStageHtml = React.useCallback((stage: string) => {
        return (
            <div className={styles.statusContainer}>
                {(stage ?? "").trim() !== "" && (
                    <span className={classNames(styles.status, styles[`status-inprogress`])}>{stage}</span>
                )}
            </div>
        )
    }, [])

    const renderStudentStudentStatus = (status: StudentStatus) => {
        return <StudentStatusLabel status={status} />
    }

    const renderStatusHtml = React.useCallback(
        (statusId: number, statusName?: string) => {
            let studentStatus: StatusDetails | undefined
            statuses.forEach((status) => {
                if (status.statusId === statusId) {
                    studentStatus = status
                }
            })
            return (
                <div className={styles.statusContainer}>
                    {!!(studentStatus || statusName) && (
                        <span className={classNames(styles.status, styles[`status-empty`])}>
                            {studentStatus?.name || statusName || ""}
                        </span>
                    )}
                </div>
            )
        },
        [statuses]
    )

    const renderChecklistStatusHtml = React.useCallback((status: Auth.CheckListStatus) => {
        return (
            <div className={styles.statusContainer}>
                <span className={classNames(styles.status, styles[`status-${status}`])}>{status}</span>
            </div>
        )
    }, [])

    const updateProfileAdmissionLeadSource = React.useCallback(
        async (profileId: number, admissionsLeadSourceId: number) => {
            try {
                await userProfileServiceV3.update({id: profileId, admissionsLeadSourceId})
            } catch (error) {
                handleError(error)
            }
        },
        []
    )

    const updateProfileLeadType = React.useCallback(async (profileId: number, leadTypeId: number) => {
        try {
            await userProfileServiceV3.update({id: profileId, leadTypeId})
        } catch (error) {
            handleError(error)
        }
    }, [])

    const renderApplicationFeeHtml = React.useCallback((applicationFee: string) => {
        switch (applicationFee) {
            case Auth.ApplicationFee.Pay:
                return "Pay"
            case Auth.ApplicationFee.NotPay:
                return "Not Pay"
            case Auth.ApplicationFee.Wait:
                return "Wait"
            default:
                return ""
        }
    }, [])

    const renderTickHtml = React.useCallback((record) => {
        return (
            <div
                onClick={() => {
                    setSelectedStudent(record)
                    activityPopup.open()
                }}>
                <Icon icon="CHECKMARK_CIRCLE" className={styles.tableIcon} />
            </div>
        )
    }, [])

    const renderChatHtml = React.useCallback((record) => {
        return (
            <div
                onClick={() => {
                    setSelectedStudent(record)
                    chatPopup.open()
                }}>
                <Icon icon="MESSAGE" className={styles.tableIcon} />
            </div>
        )
    }, [])

    const getStudentRecord = React.useCallback(
        (student: Auth.DepartmentStudent, index: number): Auth.DepartmentStudent & Record<string, any> => {
            const departmentDue = student.departmentDues?.find((due) => due.departmentId === departmentId)
            return {
                ...student,
                id: `${student.profileId}_${index}`,
                firstName: student.firstName || "",
                name: getFullName(student),
                statusHtml: renderStatusHtml(
                    student[`${DepartmentKeyNameByIdForPriority[departmentId]}StatusId`],
                    student[`${DepartmentKeyNameByIdForPriority[departmentId]}Status`]
                ),
                createdAt: moment(student.createdAt).format(userDateTimeFormat),
                lastActivityDate: student.latestActivity
                    ? moment(student.latestActivity.updatedAt).format(userDateTimeFormat)
                    : "",
                lastActivity: student.latestActivity?.name,
                admissionAdvisorsHtml: renderListHtml(student.admissionAdvisors, "fullName"),
                finAidAdvisorsHtml: renderListHtml(student.finAidAdvisors, "fullName"),
                campusHtml: renderListHtml(student.campuses),
                admissionStageHtml: renderStageHtml(student.admissionStage),
                finAidStageHtml: renderStageHtml(student.finAidStage),
                admissionStatusHtml: renderStatusHtml(student.admissionStatusId, student.admissionStatus),
                finAidStatusHtml: renderStatusHtml(student.finAidStatusId, student.finAidStatus),
                admChecklistStatusHtml: renderChecklistStatusHtml(student.admChecklistStatus),
                faChecklistStatusHtml: renderChecklistStatusHtml(student.faChecklistStatus),
                applicationFeeHtml: renderApplicationFeeHtml(student.applicationFee),
                preferredTimeOfContact: student.preferredTimeOfContact,
                rowStyle: departmentDue ? {background: departmentDue.dueColor || DUE_COLOR_OPTIONS[0].id} : undefined,
                isActiveTableCol: student.profileId === activeStudentRef.current,
                studentStatusHtml: student.status ? renderStudentStudentStatus(student.status) : null
            }
        },
        [
            departmentId,
            renderListHtml,
            renderStageHtml,
            renderStatusHtml,
            renderChecklistStatusHtml,
            renderApplicationFeeHtml
        ]
    )

    const getStudentRecordByReport = (student: Auth.DepartmentStudent) => {
        return {
            ...student,
            id: student.profileId,
            firstName: student.firstName || "",
            name: getFullName(student),
            statusHtml: renderStatus(
                student[`${DepartmentKeyNameByIdForPriority[departmentId]}StatusId`],
                student[`${DepartmentKeyNameByIdForPriority[departmentId]}Status`],
                statuses
            ),
            createdAt: moment(student.createdAt).format(userDateTimeFormat),
            lastActivityDate: student.latestActivity
                ? moment(student.latestActivity.updatedAt).format(userDateTimeFormat)
                : "",
            lastActivity: student.latestActivity?.name,
            admissionAdvisorsHtml: renderList(student.admissionAdvisors, "fullName"),
            finAidAdvisorsHtml: renderList(student.finAidAdvisors, "fullName"),
            campusHtml: renderList(student.campuses),
            admissionStageHtml: student.admissionStage,
            finAidStageHtml: student.finAidStage,
            admissionStatusHtml: renderStatus(student.admissionStatusId, student.admissionStatus, statuses),
            finAidStatusHtml: renderStatus(student.finAidStatusId, student.finAidStatus, statuses),
            admChecklistStatusHtml: student.admChecklistStatus,
            faChecklistStatusHtml: student.faChecklistStatus,
            applicationFeeHtml: renderApplicationFeeHtml(student.applicationFee),
            preferredTimeOfContact: student.preferredTimeOfContact,
            studentStatusHtml: student.status ? `${student.status.name} [${student.status.state}]` : null
        }
    }

    const getColumns = useCallback(() => {
        const isFinancialTab = isFinancialPackagingTab || isFinancialVerificationTab

        const columns = [
            isFinancialTab
                ? null
                : {
                      title: "Activity",
                      field: "tickHtml",
                      isHideTitle: true,
                      style: {width: 50, padding: "3px 4px", textAlign: "right", lineHeight: "1.2"},
                      headerStyle: {width: 50, padding: "3px 4px", lineHeight: "1.2"},
                      columnIndex: 1,
                      render: (_, record) => renderTickHtml(record)
                  },
            isFinancialTab
                ? null
                : {
                      title: "Message",
                      field: "chatHtml",
                      isHideTitle: true,
                      style: {width: 50, padding: "3px 4px", textAlign: "right", lineHeight: "1.2"},
                      headerStyle: {width: 50, padding: "3px 4px", lineHeight: "1.2"},
                      columnIndex: 2,
                      render: (_, record) => renderChatHtml(record)
                  },
            {
                title: "Date Added",
                field: "createdAt",
                render: (value) => (value ? moment(value).format(userDateTimeFormat) : ""),
                headerStyle: {minWidth: "90px", padding: "0px 5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "0px 5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 3,
                sortable: true
            },
            {
                title: t("studentInfo.enrollmentID"),
                field: "customProfileId",
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 14,
                sortable: true
            },
            {
                title: t("studentInfo.userState"),
                field: "profileState",
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 15,
                sortable: true
            },
            {
                title: t("studentInfo.userID"),
                field: "customUserId",
                headerStyle: {minWidth: "80px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 4,
                sortable: true
            },
            {
                title: t("studentInfo.firstName"),
                field: "firstName",
                orderField: "name",
                headerStyle: {minWidth: "100px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 5,
                sortable: true
            },
            {
                title: t("studentInfo.lastName"),
                field: "lastName",
                headerStyle: {minWidth: "100px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 6,
                sortable: true
            },
            !isFinancialTab
                ? null
                : {
                      title: t("studentInfo.isirCode"),
                      field: "isirCode",
                      columnIndex: 61
                  },
            !isFinancialTab
                ? null
                : {
                      title: t("studentInfo.isirDate"),
                      field: "isirDate",
                      columnIndex: 62,
                      render: (date?: string) => (date ? moment(date).format(userDateFormat) : "")
                  },
            !isFinancialTab
                ? null
                : {
                      title: t("studentInfo.isirSAI"),
                      field: "isirSAI",
                      columnIndex: 63
                  },
            !isFinancialVerificationTab
                ? null
                : {
                      title: t("studentInfo.verificationStatus"),
                      field: "verificationStatusId",
                      columnIndex: 64,
                      render: (verificationStatusId?: number) => {
                          return (
                              verificationStatuses.find((status) => status.statusId === verificationStatusId)?.name ??
                              ""
                          )
                      }
                  },
            !isFinancialPackagingTab
                ? null
                : {
                      title: t("studentInfo.packagingStatus"),
                      field: "packagingStatus",
                      columnIndex: 65,
                      render: (status?: FinAidOfferStatus) =>
                          status ? t(`financialAid:finAidOffer.status.${status}`) : ""
                  },
            !isFinancialTab
                ? null
                : {
                      title: t("studentInfo.faStatusUpdatedAt"),
                      field: "faStatusUpdatedAt",
                      columnIndex: 66,
                      render: (date?: string) => (date ? moment(date).format(userDateFormat) : "")
                  },
            !isFinancialTab
                ? null
                : {
                      title: t("studentInfo.faStatusUpdatedBy"),
                      field: "faStatusUpdatedByProfile",
                      columnIndex: 67,
                      render: (profile) => getFullName(profile)
                  },
            {
                title: t("studentInfo.programInterest"),
                field: "programName",
                headerStyle: {minWidth: "100px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {
                    maxWidth: "180px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    padding: "0px 5px",
                    margin: "0px",
                    lineHeight: "1.2"
                },
                columnIndex: 7,
                sortable: true
            },
            {
                title: t("studentInfo.phone"),
                field: "phone",
                headerStyle: {minWidth: "100px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 8,
                sortable: true
            },
            model.clientSetting.isNewStudentStatusesVisible && {
                title: t("studentInfo.newStudentStatus"),
                field: "studentStatusHtml",
                orderField: "status",
                headerStyle: {minWidth: "100px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 55,
                sortable: true
            },
            !model.clientSetting.isDepartmentalStatusesHidden && {
                title: t("studentInfo.studentStatus"),
                field: "statusHtml",
                headerStyle: {minWidth: "120px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 9,
                sortable: true
            },
            {
                title: "Last Activity Date",
                field: "lastActivityDate",
                orderField: "latestActivityDate",
                render: (value) => (value ? moment(value).format(userDateTimeFormat) : ""),
                headerStyle: {minWidth: "90px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 10,
                sortable: true
            },
            {
                title: "Last Activity",
                field: "lastActivity",
                orderField: "latestActivityDate",
                headerStyle: {minWidth: "100px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 11,
                sortable: true
            },
            {
                title: t("studentInfo.email"),
                field: "email",
                headerStyle: {minWidth: "140px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 12,
                sortable: true
            },
            isFinancialTab
                ? null
                : {
                      title: t("studentInfo.leadSource"),
                      field: "admissionsLeadSourceId",
                      headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                      columnIndex: 21,
                      style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                      orderField: "leadSource",
                      sortable: true,
                      render: (admissionsLeadSourceId: number | null, student: Auth.DepartmentStudent) => {
                          const selectedLeadSource = (leadSources || []).find((v) => v.id === admissionsLeadSourceId)
                          return (
                              <div onClick={(e) => e.stopPropagation()}>
                                  <KlassDropdown
                                      options={leadSources}
                                      value={selectedLeadSource}
                                      getOptionLabel={(option: any) => [option.code, option.name].join(" - ")}
                                      onChange={(value) =>
                                          updateProfileAdmissionLeadSource(
                                              student.profileId,
                                              value.id ? value.id : value
                                          )
                                      }
                                      placeholder="Select"
                                      menuPortalTarget={document.body}
                                  />
                              </div>
                          )
                      },
                      renderText: (admissionsLeadSourceId: number | null, student: Auth.DepartmentStudent) => {
                          const selectedLeadSource = (leadSources || []).find((v) => v.id === admissionsLeadSourceId)
                          if (!selectedLeadSource) return ""
                          return [selectedLeadSource.code, selectedLeadSource.name].join(" - ")
                      }
                  },
            isFinancialTab
                ? null
                : {
                      title: t("studentInfo.leadType"),
                      field: "leadTypeId",
                      headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                      columnIndex: 61,
                      style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                      orderField: "leadTypeId",
                      sortable: true,
                      render: (leadTypeId: number | null, student: Auth.DepartmentStudent) => {
                          return (
                              <div onClick={(e) => e.stopPropagation()}>
                                  <KlassDropdown
                                      options={leadTypes}
                                      value={leadTypes.find((type) => type.leadTypeId === leadTypeId)}
                                      getOptionLabel={(option: any) => [option.code, option.name].join(" - ")}
                                      onChange={(value) =>
                                          updateProfileLeadType(student.profileId, value.id ? value.id : value)
                                      }
                                      placeholder="Select"
                                      menuPortalTarget={document.body}
                                  />
                              </div>
                          )
                      },
                      renderText: (leadTypeId: number | null, student: Auth.DepartmentStudent) => {
                          const leadType = leadTypes.find((type) => type.leadTypeId === leadTypeId)
                          if (!leadType) return ""
                          return [leadType.code, leadType.name].join(" - ")
                      }
                  },
            {
                title: t("studentInfo.campusInterest"),
                field: "campusHtml",
                orderField: "campus",
                headerStyle: {minWidth: "100px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {
                    maxWidth: "180px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    padding: "0px 5px",
                    margin: "0px",
                    lineHeight: "1.2"
                },
                columnIndex: 13,
                sortable: true
            },
            {
                title: t("studentInfo.initialInquiryDate"),
                field: "initialInquiryDate",
                fieldType: "dateUtc",
                format: userDateFormat,
                render: (value) => (value ? moment(value).format(userDateFormat) : ""),
                headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 16,
                sortable: true
            },
            {
                title: t("studentInfo.applicationCompletedDate"),
                field: "applicationCompletedDate",
                fieldType: "dateUtc",
                format: userDateFormat,
                render: (value) => (value ? moment(value).format(userDateFormat) : ""),
                headerStyle: {minWidth: "180px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 17,
                sortable: true
            },
            {
                title: t("studentInfo.acceptanceDate"),
                field: "acceptanceDate",
                fieldType: "dateUtc",
                format: userDateFormat,
                render: (value) => (value ? moment(value).format(userDateFormat) : ""),
                headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 18,
                sortable: true
            },
            {
                title: t("studentInfo.gender"),
                field: "gender",
                headerStyle: {minWidth: "100px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 19,
                sortable: true
            },
            {
                title: t("studentInfo.applicationFee"),
                field: "applicationFeeHtml",
                headerStyle: {minWidth: "180px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                orderField: "applicationFee",
                columnIndex: 20,
                sortable: true
            },
            {
                title: t("studentInfo.birthDate"),
                field: "birthDate",
                fieldType: "date",
                format: userDateFormat,
                render: (value) => (value ? moment(value).format(userDateFormat) : ""),
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 22,
                sortable: true
            },
            {
                title: t("studentInfo.race"),
                field: "race",
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 23,
                sortable: true
            },
            {
                title: t("studentInfo.maritalStatus"),
                field: "maritalStatus",
                headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 24,
                sortable: true
            },
            {
                title: t("studentInfo.country"),
                field: "country",
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 25,
                sortable: true
            },
            {
                title: t("studentInfo.address"),
                field: "address",
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 26,
                sortable: true
            },
            {
                title: t("studentInfo.city"),
                field: "city",
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 27,
                sortable: true
            },
            {
                title: t("studentInfo.state"),
                field: "state",
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 28,
                sortable: true
            },
            {
                title: t("studentInfo.postalCode"),
                field: "postalCode",
                headerStyle: {minWidth: "180px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 29,
                sortable: true
            },
            {
                title: t("studentInfo.highSchoolOrGED"),
                field: "highSchoolType",
                headerStyle: {minWidth: "250px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 30,
                sortable: true
            },
            {
                title: t("studentInfo.preferredTimeOfContact"),
                field: "preferredTimeOfContact",
                headerStyle: {minWidth: "260px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 31,
                render: (value) => value?.join(", ")
            },
            {
                title: t("studentInfo.howDidYouHearAboutUs"),
                field: "howDidYouKnowAboutUs",
                headerStyle: {minWidth: "320px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 32,
                sortable: true
            },
            {
                title: t("studentInfo.enrollmentState"),
                field: "profileState",
                headerStyle: {minWidth: "230px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 33,
                sortable: true
            },
            !model.clientSetting.isDepartmentalStatusesHidden &&
                !isFinancialTab && {
                    title: t("studentInfo.admissionStatus"),
                    field: "admissionStatusHtml",
                    headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                    style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                    columnIndex: 34,
                    sortable: true
                },
            !model.clientSetting.isDepartmentalStatusesHidden &&
                !isFinancialTab && {
                    title: t("studentInfo.finAidStatus"),
                    field: "finAidStatusHtml",
                    headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                    style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                    columnIndex: 35,
                    sortable: true
                },
            !model.clientSetting.isDepartmentalStatusesHidden &&
                !isFinancialTab && {
                    title: t("studentInfo.admissionStage"),
                    field: "admissionStageHtml",
                    headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                    style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                    columnIndex: 36,
                    sortable: true
                },
            !model.clientSetting.isDepartmentalStatusesHidden &&
                !isFinancialTab && {
                    title: t("studentInfo.finAidStage"),
                    field: "finAidStageHtml",
                    headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                    style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                    columnIndex: 37,
                    sortable: true
                },
            isFinancialTab
                ? null
                : {
                      title: t("studentInfo.admissionAdvisor"),
                      field: "admissionAdvisorsHtml",
                      orderField: useNewFilter ? "admissionAdvisor" : "admisionAdvisor",
                      headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                      style: {
                          maxWidth: "180px",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          padding: "0px 5px",
                          margin: "0px",
                          lineHeight: "1.2"
                      },
                      columnIndex: 38,
                      sortable: true
                  },
            isFinancialTab
                ? null
                : {
                      title: t("studentInfo.finAidAdvisor"),
                      field: "finAidAdvisorsHtml",
                      orderField: "finAidAdvisor",
                      headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                      style: {
                          maxWidth: "180px",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          padding: "0px 5px",
                          margin: "0px",
                          lineHeight: "1.2"
                      },
                      columnIndex: 39,
                      sortable: true
                  },
            {
                title: t("studentInfo.programVersion"),
                field: "programVersionName",
                headerStyle: {minWidth: "230px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 40,
                sortable: true
            },
            {
                title: t("studentInfo.degreeLevel"),
                field: "degreeLevel",
                headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 41,
                sortable: true
            },
            {
                title: t("studentInfo.term"),
                field: "termName",
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 42,
                sortable: true
            },
            {
                title: t("studentInfo.startingTerm"),
                field: "startingTermName",
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 43,
                sortable: true
            },
            {
                title: t("studentInfo.startDate"),
                field: "startDate",
                fieldType: "dateUtc",
                format: userDateFormat,
                render: (value) => (value ? moment(value).format(userDateFormat) : ""),
                headerStyle: {minWidth: "160px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 44,
                sortable: true
            },
            {
                title: t("studentInfo.contractEndDate"),
                field: "contractEndDate",
                fieldType: "dateUtc",
                format: userDateFormat,
                render: (value) => (value ? moment(value).format(userDateFormat) : ""),
                headerStyle: {minWidth: "300px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 45,
                sortable: true
            },
            {
                title: t("studentInfo.actualContractEndDate"),
                field: "actualContractEndDate",
                fieldType: "dateUtc",
                format: userDateFormat,
                render: (value) => (value ? moment(value).format(userDateFormat) : ""),
                headerStyle: {minWidth: "350px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 46,
                sortable: true
            },
            isFinancialTab
                ? null
                : {
                      title: t("studentInfo.academicTrack"),
                      field: "academicTrack",
                      headerStyle: {minWidth: "220px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                      style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                      columnIndex: 47,
                      sortable: true
                  },
            isFinancialTab
                ? null
                : {
                      title: t("studentInfo.admissionChecklist"),
                      field: "admChecklistStatusHtml",
                      headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                      columnIndex: 48,
                      style: {padding: "5px", margin: "0px", lineHeight: "1.2"}
                  },
            isFinancialTab
                ? null
                : {
                      title: t("studentInfo.finAidChecklist"),
                      field: "faChecklistStatusHtml",
                      headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                      columnIndex: 49,
                      style: {padding: "5px", margin: "0px", lineHeight: "1.2"}
                  }
        ]
        const finAidColumns: any[] = [
            {
                title: "ISIR",
                field: "ISIR",
                fieldType: "date",
                format: userDateFormat,
                render: (value) => (value ? moment(value).format(userDateFormat) : ""),
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 50,
                sortable: false
            },
            {
                title: "EFC",
                field: "efc",
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 51,
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"}
            },
            {
                title: "Direct Loan MPN",
                field: "directLoanMPN",
                fieldType: "date",
                format: userDateFormat,
                render: (value) => (value ? moment(value).format(userDateFormat) : ""),
                headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 52,
                sortable: false
            },
            {
                title: "Entrance Counseling",
                field: "entranceCounseling",
                fieldType: "date",
                format: userDateFormat,
                render: (value) => (value ? moment(value).format(userDateFormat) : ""),
                headerStyle: {minWidth: "250px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 53,
                sortable: false
            },
            {
                title: t("studentInfo.FAPlanStatus"),
                field: "packageStatus",
                headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                columnIndex: 54,
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"}
            }
        ]
        return (props.departmentId === BaseDepartmentId.FinancialAid ? columns.concat(finAidColumns) : columns).filter(
            Boolean
        )
    }, [
        userDateFormat,
        renderTickHtml,
        renderChatHtml,
        model.clientSetting.isDepartmentalStatusesHidden,
        props.departmentId,
        verificationStatuses,
        leadSources,
        leadTypes,
        updateProfileAdmissionLeadSource,
        updateProfileLeadType,
        isFinancialVerificationTab,
        isFinancialPackagingTab
    ])

    const getFields = useCallback(() => {
        const isFinancialTab = isFinancialPackagingTab || isFinancialVerificationTab
        const columns = [
            isFinancialTab ? null : "Activity",
            isFinancialTab ? null : "Message",
            isFinancialTab ? null : "Date Added",
            t("studentInfo.enrollmentID"),
            isFinancialTab ? null : t("studentInfo.userState"),
            t("studentInfo.userID"),
            t("studentInfo.firstName"),
            t("studentInfo.lastName"),
            !isFinancialTab ? null : t("studentInfo.isirCode"),
            !isFinancialTab ? null : t("studentInfo.isirDate"),
            !isFinancialTab ? null : t("studentInfo.isirSAI"),
            !isFinancialVerificationTab ? null : t("studentInfo.verificationStatus"),
            !isFinancialPackagingTab ? null : t("studentInfo.packagingStatus"),
            !isFinancialTab ? null : t("studentInfo.term"),
            !isFinancialTab ? null : t("studentInfo.faStatusUpdatedAt"),
            !isFinancialTab ? null : t("studentInfo.faStatusUpdatedBy"),
            model.clientSetting.isNewStudentStatusesVisible && t("studentInfo.newStudentStatus"),
            !model.clientSetting.isDepartmentalStatusesHidden && t("studentInfo.studentStatus"),
            isFinancialTab ? null : "Last Activity Date",
            isFinancialTab ? null : "Last Activity",
            isFinancialTab ? null : t("studentInfo.phone"),
            isFinancialTab ? null : t("studentInfo.email"),
            isFinancialTab ? null : t("studentInfo.leadSource"),
            isFinancialTab ? null : t("studentInfo.leadType"),
            isFinancialTab ? null : t("studentInfo.programInterest"),
            isFinancialTab ? null : t("studentInfo.campusInterest")
        ]
        return columns.filter(Boolean)
    }, [
        model.clientSetting.isNewStudentStatusesVisible,
        model.clientSetting.isDepartmentalStatusesHidden,
        isFinancialVerificationTab,
        isFinancialPackagingTab
    ])

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getStudents()
        }
    }, [
        isLoadedTableFuncs,
        page,
        pageSize,
        orderField?.field,
        orderField?.order,
        studentFormSearch,
        studentForm,
        useNewFilter
    ])

    useEffect(() => {
        if (isFirstLoaded) {
            coreSocket.joinRoom(SOCKET_ROOM_PRIORITY_TABLE)
        }
        return () => {
            coreSocket.leaveRoom(SOCKET_ROOM_PRIORITY_TABLE)
        }
    }, [isFirstLoaded])

    useEffect(() => {
        const handleStudentDueChange = ({
            departmentId: dueDepartmentId,
            profileId,
            dueTime,
            dueColor
        }: {
            departmentId: number
            profileId: number
            dueTime?: string
            dueColor?: string
        }) => {
            if (departmentId !== dueDepartmentId) return
            props.dispatch({
                data: getCurrentData().map((student: Auth.DepartmentStudent) =>
                    student.profileId !== profileId
                        ? student
                        : {
                              ...student,
                              dueTime,
                              rowStyle: dueTime ? {background: dueColor || DUE_COLOR_OPTIONS[0].id} : undefined
                          }
                )
            })
        }

        const handleStatusChange = async ({
            profileId,
            studentDepartmentStatuses
        }: {
            profileId: number
            studentDepartmentStatuses: Auth.StudentDepartmentStatusDetails[]
        }) => {
            if (!profileId) return
            const deptStatus = studentDepartmentStatuses?.find((deptStatus) => deptStatus.departmentId === departmentId)
            if (!deptStatus) return

            const data = getCurrentData()
            const student = data.find((student: Auth.DepartmentStudent) => student.profileId === profileId)
            if (!student) return

            const newStatus = statuses.find((status) => status.statusId === statusChange?.statusId)
            if (!newStatus) return
            props.dispatch({
                data: getCurrentData().map((student) =>
                    student.profileId !== profileId
                        ? student
                        : {...student, statusHtml: renderStatusHtml(newStatus.statusId, newStatus.name)}
                )
            })
        }

        const handleStudentStatusChange = async ({profileId, statusId}: {profileId: number; statusId: number}) => {
            if (!profileId) return

            const data = getCurrentData()
            const student = data.find((student: Auth.DepartmentStudent) => student.profileId === profileId)
            if (!student) return

            const newStatus = studentStatuses.find((status) => status.statusId === statusId)
            if (!newStatus) return

            props.dispatch({
                data: getCurrentData().map((student) =>
                    student.profileId !== profileId
                        ? student
                        : {
                              ...student,
                              profileState: newStatus.state,
                              studentStatusHtml: renderStudentStudentStatus(newStatus)
                          }
                )
            })
        }

        const handleStudentActivityCompletionToggled = async ({profileId}) => {
            try {
                const {
                    data: [newStudent]
                } = await studentService.getDepartmentStudents({
                    skipTotal: true,
                    filter: {departmentId, profileIds: [profileId], faTab}
                })
                props.dispatch({
                    data: getCurrentData().map((student, index) =>
                        student.profileId !== profileId ? student : getStudentRecord(newStudent, index)
                    )
                })
            } catch (error) {
                handleError(error)
            }
        }

        coreSocket.on(SocketEvent.ProfileDueSet, handleStudentDueChange)
        coreSocket.on(SocketEvent.ProfileDueCleared, handleStudentDueChange)
        coreSocket.on(SocketEvent.ProfileStatusUpdated, handleStatusChange)
        coreSocket.on(SocketEvent.ProfileStudentStatusUpdated, handleStudentStatusChange)
        onEvent(EVENT_STUDENT_ACTIVITY_COMPLETION_TOGGLED, handleStudentActivityCompletionToggled)

        return () => {
            coreSocket.off(SocketEvent.ProfileDueSet, handleStudentDueChange)
            coreSocket.off(SocketEvent.ProfileDueCleared, handleStudentDueChange)
            coreSocket.off(SocketEvent.ProfileStatusUpdated, handleStatusChange)
            coreSocket.off(SocketEvent.ProfileStudentStatusUpdated, handleStudentStatusChange)
            offEvent(EVENT_STUDENT_ACTIVITY_COMPLETION_TOGGLED, handleStudentActivityCompletionToggled)
        }
    }, [departmentId, statuses, renderStatusHtml, getStudentRecord, faTab])

    useEffect(() => {
        function updateStudentDueColorAfterActivityCreated(activity: StudentActivity) {
            if (!activity.completed && Math.abs(moment().diff(activity.createdAt, "seconds")) > 30) {
                return
            }
            props.dispatch({
                data: getCurrentData().map((student: Auth.DepartmentStudent) =>
                    student.profileId !== activity.profileId
                        ? student
                        : {...student, dueTime: undefined, rowStyle: undefined}
                )
            })
        }

        onEvent(EVENT_STUDENT_ACTIVITY_CREATED, updateStudentDueColorAfterActivityCreated)
        return () => {
            offEvent(EVENT_STUDENT_ACTIVITY_CREATED, updateStudentDueColorAfterActivityCreated)
        }
    }, [])

    useEffect(() => {
        props.dispatch({isClassComponent: false, isLoading: true})
        props.dispatchFunc([
            {key: "getListData", func: getStudents},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "getFilterMemoryKey", func: getFilterMemoryKey},
            {key: "getTableHeaderActions", func: getTableHeaderActions}
        ])
    }, [])

    useEffect(() => {
        props.dispatchFunc([{key: "getColumns", func: getColumns}])
    }, [getColumns])

    const getStudents = async () => {
        const loadingSeq = Math.random()
        loadingSeqRef.current = loadingSeq
        const storageData = model.getStorageFilter(filterMemoryKey)
        const page = storageData?.page || getCurrentPage()
        const pageSize = storageData?.pageSize || getCurrentPageSize()
        let orderFieldData = orderField
        if (storageData?.colOrd) {
            const columns: any[] = getColumns()
            const columnOrder = columns.find((column: any) => column.columnIndex === storageData.colOrd.idx)
            orderFieldData = {field: columnOrder?.orderField || columnOrder?.field, order: storageData.colOrd.order}
        }
        try {
            props.dispatch({isLoading: true})
            // activeStudentRef.current = undefined
            let _students: Auth.DepartmentStudent[] = [],
                _total: number = 0
            if (useNewFilter) {
                const {filters, departmentId, search} = getNewFilter()
                if (
                    !search &&
                    !Object.entries(filters).filter(
                        ([field, filter]) => !(field === "faTab" || (field === "includeArchive" && !filter?.value))
                    ).length
                ) {
                    _students = []
                    _total = 0
                } else {
                    const {data: students, total} = await studentServiceV2.getAll({
                        range: {pageSize, page},
                        sorts: [
                            {
                                orderBy: orderFieldData.field ?? "createdAt",
                                orderDir: orderFieldData.order ?? "desc"
                            }
                        ],
                        fields: [...fields, "Dues"],
                        search,
                        departmentId,
                        filters
                    })
                    _students = students
                    _total = total
                }
            } else {
                const {data: students, total} = await studentService.getDepartmentStudents({
                    range: {pageSize, page},
                    sort: {
                        orderBy: orderFieldData.field ?? "createdAt",
                        orderDir: orderFieldData.order ?? "desc"
                    },
                    filter: getFilter()
                })
                _students = students
                _total = total
            }
            const studentsList = uniqBy(_students, "profileId").map(getStudentRecord)
            if (loadingSeq === loadingSeqRef.current) {
                props.dispatch({data: studentsList, total: _total})
                if (_total < (page - 1) * pageSize) {
                    props.onChangePage(1)
                }
            }
            setFirstLoaded(true)
            // DEFAULT: Hide student sidebar
            // if (!activeStudentRef.current && studentsList.length) {
            //     setTimeout(() => onChangeActiveStudent(studentsList[0].profileId), 200)
            // }
        } catch (error) {
            handleError(error)
        } finally {
            props.dispatch({isLoading: false})
        }
    }

    async function loadStudent(profileId: number) {
        try {
            const {
                data: [activeStudent]
            } = await studentService.getDepartmentStudents({
                skipTotal: true,
                filter: {departmentId: props.departmentId, profileIds: [profileId]}
            })
            if (activeStudentRef.current !== activeStudent?.profileId) return
            props.updateState?.({activeStudent})
            props.dispatch({
                data: getCurrentData().map((student, index) =>
                    student.profileId !== profileId ? student : getStudentRecord(activeStudent, index)
                )
            })
        } catch (error) {
            handleError(error)
        }
    }

    function onChangeActiveStudent(profileId: number) {
        let activeStudent
        const newData = getCurrentData().map((student: Auth.DepartmentStudent) => {
            const departmentDue = student.departmentDues?.find((due) => due.departmentId === departmentId)
            if (student.profileId === profileId) {
                activeStudent = student
            }
            return {
                ...student,
                isActiveTableCol: student.profileId === profileId,
                rowStyle:
                    student.profileId === profileId
                        ? undefined
                        : departmentDue
                        ? {background: departmentDue.dueColor || DUE_COLOR_OPTIONS[0].id}
                        : undefined
            }
        })
        activeStudentRef.current = profileId
        props.dispatch({data: newData})
        props.updateState?.({activeStudent})
    }

    const handleRowClick = React.useCallback(
        (item: Auth.DepartmentStudent) => onChangeActiveStudent(item.profileId),
        [onChangeActiveStudent]
    )

    const getFilterMemoryKey = () => {
        return filterMemoryKey
    }

    const getTableHeaderActions = () => {
        const {t} = props
        const checkedData = getCurrentData().filter((item) => item.isChecked)
        const checkedProfileStates = checkedData.map((item) => item.profileState)
        const actions = hasPermissionToUpdateAdvisor
            ? [
                  {
                      title: t("studentInfo.addAdvisor"),
                      icon: null,
                      action: () => onClickShowAdvisorModal("asign")
                  },
                  {
                      title: t("studentInfo.reAssignAdvisor"),
                      icon: null,
                      action: () => onClickShowAdvisorModal("reassign")
                  }
              ]
            : []
        if (
            DepartmentDialerModuleMapping[departmentId] &&
            isModuleEnable(DepartmentDialerModuleMapping[departmentId])
        ) {
            actions.push({
                title: "Add to Dialer",
                icon: null,
                action: addToDialer
            })
        }
        if (
            !isEmpty(checkedProfileStates) &&
            uniq(checkedProfileStates).length === 1 &&
            [
                Auth.StudentState.Prospect,
                Auth.StudentState.Applicant,
                Auth.StudentState.Enrollment,
                Auth.StudentState.Student
            ].includes(head(checkedProfileStates))
        ) {
            actions.push({
                title: "Change Status",
                icon: null,
                action: () => {
                    setStatusStudents(checkedData)
                    changeStatusPopup.open()
                }
            })
        }
        return actions
    }

    const addToDialer = async () => {
        const data = getCurrentData()
        const checkedData: any[] = data.filter((item) => item.isChecked)
        const profileIds = checkedData.map(({profileId}) => profileId)
        try {
            await autoDialerService.addToDialer({
                departmentId,
                studentProfileIds: profileIds
            })
            toastSuccess("Added to dialer")
            props.dispatch({
                data: data.map((student) => ({...student, isChecked: false}))
            })
            props.updateTableHeaderActions()
        } catch (error) {
            handleError(error)
        }
    }

    const onClickShowAdvisorModal = (action) => {
        const data = getCurrentData()
        const checkedData: any[] = data.filter((item) => item.isChecked)
        setStudentProfileIds(checkedData.map(({profileId}) => profileId))
        const campusIds = checkedData.map(({campusIds}) => campusIds)
        setStudentCampusIds(campusIds.flat())
        setType(action)
        advisorsPopup.open()
    }

    const onClosePopup = () => {
        setType("")
        reloadStudentAdvisors()
        advisorsPopup.close()
    }

    const handleExportFileTypeSelect = async (type: ExportFileType) => {
        const reportInfo = useNewFilter
            ? MODULE_REPORT_INFO_NEW_FILTERS[departmentId]
            : MODULE_REPORT_INFO[departmentId]
        if (total > 1000) {
            try {
                props.updateState?.({isExporting: true})
                const reportFilter = useNewFilter ? getNewFilter() : getFilter()
                const reportColumns = getColumns()
                    .filter((col) => fields.includes(col.title) && !["Activity", "Message"].includes(col.title))
                    .map((column) => ({field: column.field, title: column.title, fieldType: column.fieldType}))
                await reportsService.generateReport({
                    reportKey: reportInfo.reportKey,
                    payload: {filter: reportFilter, fields: reportColumns},
                    format: type
                })
                toastInfo(
                    "The report contains more than 1000 records; it will take some time to generate it. We will send you a notification with the download link when the report is ready"
                )
            } catch (error) {
                handleError(error)
            } finally {
                props.updateState?.({isExporting: false, exportFileType: null})
            }
            return
        }
        try {
            props.updateState?.({isExporting: true})
            const storageData = model.getStorageFilter(filterMemoryKey)
            const page = 1
            const pageSize = UNLIMITED_PAGE_SIZE
            let orderFieldData = orderField
            if (storageData?.colOrd) {
                const columns: any[] = getColumns()
                const columnOrder = columns.find((column: any) => column.columnIndex === storageData.colOrd.idx)
                orderFieldData = {field: columnOrder?.orderField || columnOrder?.field, order: storageData.colOrd.order}
            }
            let allStudents: Auth.DepartmentStudent[] = []
            if (useNewFilter) {
                const {filters, departmentId, search} = getNewFilter()
                const {data: _allStudents} = await studentServiceV2.getAll({
                    range: {pageSize, page},
                    sorts: [
                        {
                            orderBy: orderFieldData.field ?? "createdAt",
                            orderDir: orderFieldData.order ?? "desc"
                        }
                    ],
                    fields: [...fields, "Dues"],
                    search,
                    departmentId,
                    filters
                })
                allStudents = _allStudents
            } else {
                const filterParams = getFilter()
                const {data: _allStudents} = await studentService.getDepartmentStudents({
                    range: {pageSize, page},
                    sort: {
                        orderBy: orderFieldData.field ?? "createdAt",
                        orderDir: orderFieldData.order ?? "desc"
                    },
                    filter: filterParams
                })
                allStudents = _allStudents
            }
            const reportData = uniqBy(allStudents, "profileId").map(getStudentRecordByReport)

            const reportColumns = getColumns().filter(
                (col) => fields.includes(col.title) && !["Activity", "Message"].includes(col.title)
            )
            const filename = reportInfo.filename

            const payload = {
                filename,
                columns: reportColumns.map((col) => col.title),
                rows: reportData.map((item) =>
                    reportColumns.map((col) => {
                        const value = col.renderText
                            ? col.renderText(get(item, col.field), item)
                            : col.render
                            ? col.render(get(item, col.field), item)?.toString()
                            : get(item, col.field)?.toString()
                        return (type === "csv" ? `"${value?.replaceAll(/[|,;"]/g, "")}"` : value) || ""
                    })
                )
            }
            if (type === "csv") {
                exportCsv(payload)
            } else if (type === "excel") {
                exportExcel(payload)
            }
        } catch (error) {
            handleError(error)
        } finally {
            props.updateState?.({isExporting: false, exportFileType: null})
        }
    }

    const isEmptyFilter = useMemo(() => {
        if (!useNewFilter) return false
        const {filters, departmentId, search} = getNewFilter()
        return (
            !search &&
            !Object.entries(filters).filter(
                ([field, filter]) => !(field === "faTab" || (field === "includeArchive" && !filter?.value))
            ).length
        )
    }, [useNewFilter, getNewFilter])

    const handleActivityUpserted = useCallback(
        (activity: StudentActivity) => {
            if (selectedStudent?.profileId) {
                emitEvent(EVENT_STUDENT_ACTIVITY_CREATED, {
                    profileId: selectedStudent.profileId,
                    activity
                })
                if (activeStudentRef.current === selectedStudent.profileId) {
                    onChangeActiveStudent(selectedStudent?.profileId)
                }
            }
        },
        [selectedStudent?.profileId, onChangeActiveStudent]
    )

    return (
        <div className={styles.root}>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                isShowCheckedColumn
                columns={columns}
                data={data}
                isLoading={isLoading}
                fields={fields}
                allFields={allFields}
                orderField={orderField}
                onClickRowItem={handleRowClick}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onClickSortColumn={props.onClickSortColumn}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
                tableClassName={styles.studentTable}
                emptyText={isEmptyFilter ? t("common:table.emptyFilter") : t("common:table.emptyData")}
            />

            {!!activityPopup.isVisible && (
                <ActivityPopup
                    isShow={activityPopup.isVisible}
                    onClose={activityPopup.close}
                    studentId={selectedStudent?.profileId}
                    departmentId={departmentId}
                    isCompactForm={false}
                    setReload={handleActivityUpserted}
                />
            )}

            {!!chatPopup.isVisible && (
                <ChatModalV2
                    user={selectedStudent}
                    onShow={chatPopup.isVisible}
                    onClose={chatPopup.close}
                    userIdField="userId"
                    departmentId={departmentId}
                />
            )}
            {!!advisorsPopup.isVisible && (
                <AdvisorsPopup
                    isVisible={advisorsPopup.isVisible}
                    close={onClosePopup}
                    profileIds={studentProfileIds}
                    campusIds={studentCampusIds}
                    departmentId={departmentId}
                    advisorTitle={props.advisorTitle}
                    addMultiple
                    type={type}
                />
            )}
            <ChangeStatusPopup
                isShow={changeStatusPopup.isVisible}
                students={statusStudents}
                onClose={changeStatusPopup.close}
                onReload={() => {
                    props.dispatch({isShowTableHeaderAction: false, tableHeaderActions: []})
                    getStudents()
                }}
            />
        </div>
    )
}

export default KlassappTableHOC(StudentListTable)
