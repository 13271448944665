import React, {useEffect, useState} from "react"
import {head} from "lodash"
import {Row, Col} from "antd"
import {Auth} from "types/auth"
import {AcademicPlans} from "types/academicPlans"
import {userService} from "services"
import {store, handleError, formatDecimal} from "helpers"
import styles from "./StudentProgress.module.css"
import {BaseLoading} from "components"
import {useAllPhases} from "hooks/useAllPhases"
import {useAllShifts} from "hooks/useAllShifts"

type Props = {
    studentStats: Auth.StudentStatistics
    isRightPanel?: boolean
}

export function StudentScore(props: Props) {
    const locale = store.get("locale") || "en"
    const labelKey = locale === "en" ? "english" : "spanish"
    const {studentStats, isRightPanel = false} = props
    const [isLoading, setIsLoading] = useState(false)
    const [studentLabelInfo, setStudentLabelInfo] = useState<Auth.StudentLabelInfo>(null)
    const {phases} = useAllPhases()
    const {shifts} = useAllShifts()

    useEffect(() => {
        getLabels()
    }, [])

    const getLabels = async () => {
        try {
            setIsLoading(true)
            const labels = await userService.getLabels()
            setStudentLabelInfo(labels)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const formatNumber = (value: number | string): string => {
        if (value === "NA") {
            return value
        }
        if (!value || value === 0 || parseFloat(value as string) === 0) {
            return "0"
        }
        return formatDecimal(value as number)
    }

    if (isLoading || !studentLabelInfo) {
        return (
            <div className={styles.loadingWrapper}>
                <BaseLoading isShow />
            </div>
        )
    }

    const missedAttendanceHours = formatNumber(studentStats?.missedAttendanceHours)
    const makeUpHours = formatNumber(studentStats?.makeUpHours)
    const hoursToMakeup = formatNumber(parseFloat(missedAttendanceHours) - parseFloat(makeUpHours))

    return (
        <Row gutter={[12, 8]}>
            <Col span={isRightPanel ? 24 : 12}>
                <div className={styles.scoreInfoWrap}>
                    {studentLabelInfo["academics.gpa"]?.isActive && (
                        <div className={styles.scoreInfoItem}>
                            <span className={styles.scoreTitle}>{studentLabelInfo["academics.gpa"][labelKey]}</span>
                            <span className={styles.score}>{formatNumber(head(studentStats?.terms)?.gpa)}</span>
                        </div>
                    )}
                    {studentLabelInfo["academics.cgpa"]?.isActive && (
                        <div className={styles.scoreInfoItem}>
                            <span className={styles.scoreTitle}>{studentLabelInfo["academics.cgpa"][labelKey]}</span>
                            <span className={styles.score}>{formatNumber(studentStats?.cgpa)}</span>
                        </div>
                    )}
                </div>
            </Col>
            {studentLabelInfo["academics.attendance_percentage"]?.isActive && (
                <Col span={isRightPanel ? 24 : 12}>
                    <div className={styles.scoreInfo}>
                        <span className={styles.scoreTitle}>
                            {studentLabelInfo["academics.attendance_percentage"][labelKey]}
                        </span>
                        <span className={styles.score}>{formatNumber(studentStats?.averageAttendancePercentage)}%</span>
                    </div>
                </Col>
            )}
            {studentLabelInfo["academics.max_timeframe_percentage"]?.isActive && (
                <Col span={isRightPanel ? 24 : 12}>
                    <div className={styles.scoreInfo}>
                        <span className={styles.scoreTitle}>
                            {studentLabelInfo["academics.max_timeframe_percentage"][labelKey]}
                        </span>
                        <span className={styles.score}>{formatNumber(studentStats?.maxTimeframe)}%</span>
                    </div>
                </Col>
            )}
            {studentLabelInfo["academics.missed_attendance_hours"]?.isActive && (
                <Col span={isRightPanel ? 24 : 12}>
                    <div className={styles.scoreInfo}>
                        <span className={styles.scoreTitle}>
                            {studentLabelInfo["academics.missed_attendance_hours"][labelKey]}
                        </span>
                        <span className={styles.score}>{missedAttendanceHours}</span>
                    </div>
                </Col>
            )}
            {studentLabelInfo["academics.make_up_attendance_hours"]?.isActive && (
                <Col span={isRightPanel ? 24 : 12}>
                    <div className={styles.scoreInfo}>
                        <span className={styles.scoreTitle}>
                            {studentLabelInfo["academics.make_up_attendance_hours"][labelKey]}
                        </span>
                        <span className={styles.score}>{makeUpHours}</span>
                    </div>
                </Col>
            )}
            {studentLabelInfo["academics.hours_to_make_up"]?.isActive && (
                <Col span={isRightPanel ? 24 : 12}>
                    <div className={styles.scoreInfo}>
                        <span className={styles.scoreTitle}>
                            {studentLabelInfo["academics.hours_to_make_up"][labelKey]}
                        </span>
                        <span className={styles.score}>{makeUpHours}</span>
                    </div>
                </Col>
            )}
            {studentLabelInfo["academics.phase"]?.isActive && (
                <Col span={isRightPanel ? 24 : 12}>
                    <div className={styles.scoreInfo}>
                        <span className={styles.scoreTitle}>{studentLabelInfo["academics.phase"][labelKey]}</span>
                        <span className={styles.score}>
                            {phases.find((item) => item.phaseId === studentStats.phaseId)?.name}
                        </span>
                    </div>
                </Col>
            )}
            {studentLabelInfo["academics.shift"]?.isActive && (
                <Col span={isRightPanel ? 24 : 12}>
                    <div className={styles.scoreInfo}>
                        <span className={styles.scoreTitle}>{studentLabelInfo["academics.shift"][labelKey]}</span>
                        <span className={styles.score}>
                            {shifts.find((item) => item.shiftId === studentStats.shiftId)?.name}
                        </span>
                    </div>
                </Col>
            )}
            {studentLabelInfo["academics.sap_status"]?.isActive && (
                <Col span={isRightPanel ? 24 : 12}>
                    <div className={styles.scoreInfo}>
                        <span className={styles.scoreTitle}>{studentLabelInfo["academics.sap_status"][labelKey]}</span>
                        <span className={styles.score}>
                            {studentStats?.sapStatus ? AcademicPlans.SapStatus[studentStats?.sapStatus] : ""}
                        </span>
                    </div>
                </Col>
            )}
        </Row>
    )
}
