import {get} from "lodash"
import BaseService from "./Base"
import {StudentCharge, StudentStats, StudentTransaction} from "types/student-account/student-ledger"

export default class StudentLedgerService extends BaseService {
    getCharges = async (params = {}): Promise<{total: number; data: StudentCharge[]}> => {
        const response = await this.post("/v1/student-ledger/charges/list", params)
        return get(response, "data", {})
    }

    getCharge = async (id: number): Promise<StudentCharge> => {
        const response = await this.get(`/v1/student-ledger/charges/${id}`)
        return get(response, "data.data", {})
    }

    createCharge = async (
        data: Partial<StudentCharge> & {
            isSendInvoice?: boolean
        }
    ): Promise<StudentCharge> => {
        const response = await this.post("/v1/student-ledger/charges/create", data)
        return get(response, "data.data", {})
    }

    updateCharge = async (
        data: Partial<StudentCharge> & {
            isSendInvoice?: boolean
        }
    ): Promise<StudentCharge> => {
        const response = await this.post("/v1/student-ledger/charges/edit", data)
        return get(response, "data.data", {})
    }

    deleteCharges = async (ids: number[]): Promise<void> => {
        await this.post("/v1/student-ledger/charges/delete", {ids})
    }

    getTransactionAcademicYears = async (profileId: number): Promise<number[]> => {
        const response = await this.post("/v1/student-ledger/transactions/academic-years", {profileId})
        return get(response, "data.data", [])
    }

    getTransactionAwardYears = async (profileId: number): Promise<number[]> => {
        const response = await this.post("/v1/student-ledger/transactions/award-years", {profileId})
        return get(response, "data.data", [])
    }

    getStudentStats = async (profileId: number): Promise<StudentStats> => {
        const response = await this.post("/v1/student-ledger/transactions/stats", {profileId})
        return get(response, "data.data", {})
    }

    getTransactions = async (params = {}): Promise<{total: number; data: StudentTransaction[]}> => {
        const response = await this.post("/v1/student-ledger/transactions/list", params)
        return get(response, "data", {})
    }

    getTransaction = async (id: number): Promise<StudentTransaction> => {
        const response = await this.get(`/v1/student-ledger/transactions/${id}`)
        return get(response, "data.data", {})
    }
}
