import React from "react"
import {useTranslation} from "react-i18next"
import styles from "./ExecutiveSummary.module.css"

export function ExecutiveSummary(props) {
    const {t} = useTranslation(["student"])
    return (
        <div className={styles.wrap}>
            <div className={styles.headerWrap}>
                <p className={styles.title}>{t("student.workspace.executiveSummary")}</p>
                <p className={styles.date}>July 22, 2020 </p>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.body}>
                <div className={styles.item}>
                    <p className={styles.item__title}>SUB, UNS</p>
                    <p className={styles.item__cost}>12,232.00</p>
                </div>
                <div className={styles.item}>
                    <p className={styles.item__title}>PELL</p>
                    <p className={styles.item__cost}></p>
                </div>
                <div className={styles.item}>
                    <p className={styles.item__title}>{t("student.workspace.multiple")}</p>
                    <p className={styles.item__cost}>12,232.00</p>
                </div>
                <div className={styles.item}>
                    <p className={styles.item__title}>{t("student.workspace.stipend")}</p>
                    <p className={styles.item__cost}>12,232.00</p>
                </div>
                <div className={styles.item}>
                    <p className={styles.item__title}>PELL</p>
                    <p className={styles.item__cost}></p>
                </div>
                <div className={styles.item}>
                    <p className={styles.item__title}>{t("student.workspace.multiple")}</p>
                    <p className={styles.item__cost}>12,232.00</p>
                </div>
                <div className={styles.item}>
                    <p className={styles.item__title}>{t("student.workspace.stipend")}</p>
                    <p className={styles.item__cost}>12,232.00</p>
                </div>
                <div className={styles.divider}></div>
                <div className={styles.totalItem}>
                    <p className={styles.item__title}>TOTAL</p>
                    <p className={styles.item__cost}>12,232.00</p>
                </div>
            </div>
        </div>
    )
}
