import {get} from "lodash"
import BaseService from "./Base"

export default class FileService extends BaseService {
    async createSignedUrlForUploadingFile(params = {}) {
        const response = await this.post("/v1/files/create-signed-url-for-uploading", params)
        return get(response, "data.data", [])
    }

    async createMultipleSignedUrlForUploadingFile(params): Promise<{url: string; urlForUploading: string}[]> {
        const response = await this.post("/v1/files/create-multiple-signed-url-for-uploading", params)
        return get(response, "data.data", [])
    }
}
