import React from "react"
import {cloneDeep} from "lodash"
import classNames from "classnames"
import {Icon} from "components/Icon"
import {ScholarshipItemForm} from "./ScholarshipItemForm"
import ScholarshipItemTable from "./ScholarshipItemTable"
import styles from "./ScholarshipItem.module.css"

interface Props {
    history?: any
    data: any
    onClickSaveNewItem: Function
    onClickCancelNewItem: Function
}

interface State {
    scholarshipItemData: any
    oldScholarshipItemData: any
    isSubmitting: boolean
    isShowBody: boolean
    isEdit: boolean
}

export class ScholarshipItem extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        const initData = this.initData(props.data)
        this.state = {
            scholarshipItemData: cloneDeep(initData),
            oldScholarshipItemData: cloneDeep(initData),
            isSubmitting: false,
            isShowBody: !!props.data.isNewItem,
            isEdit: !!props.data.isNewItem
        }
    }

    initData = (dataProps) => {
        const result = {
            code: "",
            title: "",
            description: "",
            type: "",
            term: "",
            originalAmount: "",
            currentBalance: "",
            numberOfPayment: "",
            dueDate: "",
            frequency: ""
        }
        if (!dataProps) return result
        return {...result, ...dataProps}
    }

    onToggleShowBody = () => {
        const {isShowBody} = this.state
        this.setState({isShowBody: !isShowBody})
    }

    onChangeData = (key, value) => {
        const {scholarshipItemData} = this.state
        scholarshipItemData[key] = value
        this.setState({scholarshipItemData})
    }

    onClickEdit = () => {
        this.setState({isEdit: true})
    }

    onClickSave = () => {
        this.setState({isEdit: false})
        this.props.onClickSaveNewItem()
    }

    onClickCancel = () => {
        const {oldScholarshipItemData} = this.state
        this.setState({isEdit: false, scholarshipItemData: cloneDeep(oldScholarshipItemData)})
        this.props.onClickCancelNewItem()
    }

    render() {
        const {isShowBody, isSubmitting, isEdit, scholarshipItemData} = this.state
        const {data} = this.props

        return (
            <div className={styles.itemWrap}>
                {!data.isNewItem && (
                    <div className={styles.itemHeaderWrap}>
                        <p className={styles.item__code}>A2489</p>
                        <p className={styles.item__title}>{data.title}</p>
                        <p className={styles.item__description}>Description Lorem ispm dolor sit samet</p>
                        <div className={styles.rightWrap}>
                            <div className={styles.disbursedDate}>
                                <p className={styles.disbursedDate__title}>Balance:</p>
                                <p className={styles.disbursedDate__value}>$12000</p>
                            </div>
                            <div className={classNames(styles.balanceWrap)}>
                                <p className={styles.balance__title}>Balance:</p>
                                <p className={styles.balance__cost}>$12000</p>
                            </div>
                            <div className={styles.statusWrap}>
                                <p className={styles.status__text}>Awarded</p>
                            </div>
                            <div className={styles.iconWrap} onClick={this.onToggleShowBody}>
                                <Icon icon="ARROW_DOWN" color="#212121" className={styles.icon} />
                            </div>
                        </div>
                    </div>
                )}
                {isShowBody && (
                    <>
                        <ScholarshipItemForm
                            data={scholarshipItemData}
                            isSubmitting={isSubmitting}
                            isEdit={isEdit}
                            onChangeData={this.onChangeData}
                            onClickEdit={this.onClickEdit}
                            onClickSave={this.onClickSave}
                            onClickCancel={this.onClickCancel}
                        />
                        {!data.isNewItem && <ScholarshipItemTable />}
                    </>
                )}
            </div>
        )
    }
}
