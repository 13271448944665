/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useMemo, useState} from "react"
import {CareerServices} from "types/careerServices"
import {formatDate, handleError, toastInfo, toastSuccess} from "helpers"
import {careerService} from "services"
import {BaseButton, BaseLoading} from "components"
import styles from "./ApplicationInfoPanel.module.css"
import {Tooltip} from "antd"
import cx from "classnames"
import {PanelNavigation} from "uiKit/PanelNavigation"
import {MessagePanel} from "sections/NewCommunication/shared"
import {BaseDepartmentId} from "types/departments"
import {ReactComponent as ProfileIcon} from "./assets/profile.svg"
import {ReactComponent as CalendarIcon} from "./assets/calendar.svg"
import {ReactComponent as MessageIcon} from "./assets/message.svg"
import {ReactComponent as LikeIcon} from "./assets/like.svg"
import {ReactComponent as FilledLikeIcon} from "./assets/like-filled.svg"
import {ReactComponent as DislikeIcon} from "./assets/dislike.svg"
import {ReactComponent as FilledDislikeIcon} from "./assets/dislike-filled.svg"
import {ReactComponent as DetailNavigationIcon} from "./assets/detail.svg"
import {ReactComponent as FileIcon} from "./assets/file.svg"
import {useModel, useVisible} from "hooks"
import {Link, useHistory} from "react-router-dom"
import {ApplicationStatusItem} from "../ApplicationStatusItem"
import {JobItemPanel} from "../JobItemPanel"
import {EmployerNote} from "../EmployerNote"
import {StudentAndEmployerComment} from "../StudentAndEmployerComment"
import {RejectApplicationForm} from "../RejectApplicationForm"
import {OfferApplicationForm} from "../OfferApplicationForm"
import {StudentInfoCalendarPanel} from "uiKit/StudentInfoPanel/parts"

type DisplayType = "drawer" | "panel"

const JOB_APPLICATION_STATUSES = {
    applied: "Applied",
    "in-review": "In Review",
    interview: "Interview",
    rejected: "Rejected",
    "job-offer": "Job Offer",
    "job-closed": "Job Closed",
    hired: "Hired"
}

export type ApplicationInfoPanelProps = {
    applicationId: number
    activeSection?: string
    displayType?: DisplayType
    onClose?: () => void
    onOpenDrawer?: (activeSection: string) => void
    minimizeDrawerWidth?: () => void
    maximizeDrawerWidth?: () => void
    onChangeStatus?: (_statusChange: {applicationId: number; status: string}) => void
}

export function ApplicationInfoPanel(props: ApplicationInfoPanelProps) {
    const {
        applicationId,
        displayType = "panel",
        activeSection: initActiveSection,
        onOpenDrawer,
        minimizeDrawerWidth,
        maximizeDrawerWidth,
        onClose,
        onChangeStatus
    } = props

    const [application, setApplication] = useState<CareerServices.Application>()
    const [job, setJob] = useState<CareerServices.Job>()
    const [isLoading, setIsLoading] = useState(false)
    const [activeSection, setActiveSection] = useState<string>(initActiveSection)
    const [reactionStat, setReactionStat] = useState<CareerServices.ReactionStat>()
    const model = useModel()
    const isStudent = model.isStudent()
    const rejectFormVisible = useVisible(false)
    const offerFormVisible = useVisible(false)
    const [applicationStatus, setApplicationStatus] = useState<string>("applied")
    const [studentNotes, setStudentNotes] = useState<CareerServices.ApplicationNote[]>([])
    const history = useHistory()

    useEffect(() => {
        getApplication()
    }, [applicationId])

    useEffect(() => {
        onChangeStatus?.({applicationId: applicationId, status: applicationStatus})
    }, [applicationId, applicationStatus])

    const getApplication = async () => {
        try {
            setIsLoading(true)
            const _application = await careerService.getApplication({
                id: applicationId,
                linkedObjects: true
            })
            setJob(_application.job)
            setReactionStat({
                totalLikes: _application.totalLikes,
                totalDislikes: _application.totalDislikes,
                isLiked: _application.isLiked,
                isDisliked: _application.isDisliked
            })
            setApplicationStatus(_application.status)
            setStudentNotes(_application.studentNotes)
            setApplication(_application)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const studentPhoto = useMemo(() => {
        let studentPhoto = "/image/DefaultAvatar.png"
        if (application && application.student && application.student.avatar) {
            studentPhoto = JSON.parse(application.student.avatar)["128"]
        }
        return studentPhoto
    }, [application])

    const changeApplicationStatus = async (status: string) => {
        if (isStudent) return
        if (status === applicationStatus) {
            toastInfo("The application is already in this status.")
            return
        }
        switch (status) {
            case "rejected": {
                rejectFormVisible.open()
                break
            }
            case "job-offer": {
                offerFormVisible.open()
                break
            }
            default: {
                const payload = {
                    id: application.id,
                    status
                }
                await careerService.updateApplication(payload)
                setApplicationStatus(status)
                toastSuccess("Change status successfully")
                break
            }
        }
    }

    const onRejectedApplication = useCallback(
        async (comment: string) => {
            const payload = {
                id: application.id,
                status: "rejected",
                content: comment
            }
            const newNote = await careerService.updateApplication(payload)
            setStudentNotes((prev) => [...prev, newNote])
            rejectFormVisible.close()
            setApplicationStatus("rejected")
            toastSuccess("Change status successfully")
        },
        [application, studentNotes]
    )

    const onOfferredApplication = async (
        comment: string,
        attachment: {attachmentFileName: string; attachmentUrl: string}
    ) => {
        const payload = {
            id: application.id,
            status: "job-offer",
            content: comment,
            attachment
        }
        const newNote = await careerService.updateApplication(payload)
        setStudentNotes((prev) => [...prev, newNote])
        offerFormVisible.close()
        setApplicationStatus("job-offer")
        toastSuccess("Change status successfully")
    }

    const navigate_to = (path: string) => {
        history.push(path)
    }

    if (isLoading || !application || !job) {
        return <BaseLoading />
    }

    const renderStatuses = () => {
        const statuses = []
        Object.entries(JOB_APPLICATION_STATUSES).forEach(([key, value]) => {
            statuses.push(
                <ApplicationStatusItem
                    value={key}
                    text={value}
                    isStudent={isStudent}
                    isCurrentStatus={applicationStatus === key}
                    onClick={changeApplicationStatus}
                />
            )
        })
        return (
            <div className={styles.statusContainer}>
                <div className={styles.statusTitle}>Application Status</div>
                <div className={styles.statusList}>
                    {statuses.map((item, index) => {
                        return <div key={index}>{item}</div>
                    })}
                </div>
            </div>
        )
    }

    const renderAction = () => {
        if (isStudent) return
        return (
            <div className={styles.profileAction}>
                <div className={styles.profileActionWrap}>
                    <Tooltip title="Student Detail">
                        <div
                            className={styles.profileActionIconWrap}
                            onClick={() => navigate_to(`/users/${application.student.userId}`)}>
                            <ProfileIcon width={20} height={20} />
                        </div>
                    </Tooltip>
                    <Tooltip title="Appointment">
                        <div
                            className={cx(styles.profileActionIconWrap, {
                                [styles.profileActionIconActiveWrap]: activeSection === "appointment"
                            })}
                            onClick={() => handleAction("appointment")}>
                            <CalendarIcon
                                className={cx(styles.profileActionIcon, {
                                    [styles.profileActionIconActive]: activeSection === "appointment"
                                })}
                            />
                        </div>
                    </Tooltip>
                    <Tooltip title="Message">
                        <div
                            className={cx(styles.profileActionIconWrap, {
                                [styles.profileActionIconActiveWrap]: activeSection === "message"
                            })}
                            onClick={() => handleAction("message")}>
                            <MessageIcon
                                className={cx(styles.profileActionIcon, {
                                    [styles.profileActionIconActive]: activeSection === "message"
                                })}
                            />
                        </div>
                    </Tooltip>
                </div>
                <div className={styles.reactionActionWrap}>
                    <div
                        className={styles.reactionContainer}
                        onClick={() => handleReaction(CareerServices.ReactionType.Like)}>
                        {reactionStat.isLiked ? (
                            <FilledLikeIcon width={20} height={20} />
                        ) : (
                            <LikeIcon width={20} height={20} />
                        )}
                        <div className={styles.count}>({reactionStat.totalLikes})</div>
                    </div>
                    <div
                        className={styles.reactionContainer}
                        onClick={() => handleReaction(CareerServices.ReactionType.Dislike)}>
                        {reactionStat.isDisliked ? (
                            <FilledDislikeIcon width={20} height={20} />
                        ) : (
                            <DislikeIcon width={20} height={20} />
                        )}
                        <div className={styles.count}>({reactionStat.totalDislikes})</div>
                    </div>
                </div>
            </div>
        )
    }

    const onCollapsePanel = () => {
        handleCloseActiveSection()
        if (onClose) onClose()
    }

    const handleAction = (_activeSection: string) => {
        if (displayType === "panel") {
            if (onOpenDrawer) onOpenDrawer(_activeSection)
            if (maximizeDrawerWidth) maximizeDrawerWidth()
            onCollapsePanel()
        } else {
            setActiveSection(_activeSection)
            if (maximizeDrawerWidth) maximizeDrawerWidth()
        }
    }

    const handleCloseActiveSection = () => {
        if (displayType === "drawer") {
            setActiveSection("") // clear active action
            if (minimizeDrawerWidth) minimizeDrawerWidth()
        }
    }

    const handleReaction = async (type: CareerServices.ReactionType) => {
        try {
            if (
                (type === CareerServices.ReactionType.Like && reactionStat.isLiked) ||
                (type === CareerServices.ReactionType.Dislike && reactionStat.isDisliked)
            )
                return
            const payload = {
                applicationId: application.id,
                reactionType: type
            }
            const _reactionStat = await careerService.reactApplication(payload)
            if (_reactionStat) {
                setReactionStat(_reactionStat)
            }
        } catch (error) {
            handleError(error)
        }
    }

    const renderAttachments = () => {
        const {attachments} = application
        if (attachments.length <= 0) return

        return (
            <div className={styles.attachmentsWrap}>
                {attachments.map((item) => {
                    return (
                        <div
                            className={styles.documentWrap}
                            key={item.attachmentUrl}
                            onClick={() => {
                                window.open(item.attachmentUrl, "_blank")
                            }}>
                            <FileIcon className={styles.fileIcon} width={24} height={24} />
                            <div className={styles.documentFileName}>{item.attachmentFileName}</div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderApplicationInfo = () => {
        return (
            <div className={styles.applicationWrap}>
                <div className={styles.applicationTitleWrap}>
                    <span className={styles.sectionTitle}>Application</span>
                    <Link to={`/career-services/applications/${application.id}`}>
                        <div className={styles.profileLinkIconWrap}>
                            <DetailNavigationIcon className={styles.profileLinkIcon} />
                        </div>
                    </Link>
                </div>
                {renderAction()}
                <div className={styles.profile}>
                    <img className={styles.avatar} src={studentPhoto} alt="Profile" />
                    <div className={styles.nameContainer}>
                        <div
                            className={
                                styles.name
                            }>{`${application.student.firstName} ${application.student.lastName}`}</div>
                        <div className={styles.gender}>{application.student.genderPronoun || "--"}</div>
                    </div>
                </div>
                {renderStatuses()}
                {!isStudent && !["job-closed", "hired"].includes(applicationStatus) && (
                    <div className={styles.actionContainer}>
                        <BaseButton
                            title="Reject Application"
                            className={styles.addButton}
                            variant="secondary"
                            onClick={() => changeApplicationStatus("rejected")}
                        />
                        <BaseButton
                            title="Send Job Offer"
                            className={styles.addButton}
                            onClick={() => changeApplicationStatus("job-offer")}
                        />
                    </div>
                )}
            </div>
        )
    }

    const renderApplicationContent = () => {
        return (
            <div className={styles.contentWrap}>
                {application.applyVia === CareerServices.JobApplicationSource.Website && (
                    <div className={styles.applicationViaWebsite}>Aplication sent via company website</div>
                )}
                <div className={styles.appliedAt}>{`Application sent on ${formatDate(
                    application.appliedAt,
                    model.getUserDateFormat()
                )}`}</div>
                <div className={styles.subject}>
                    <div className={styles.subjectTitle}>{application.emailSubject}</div>
                    <div
                        className={styles.subjectContent}
                        dangerouslySetInnerHTML={{__html: application.emailContent}}></div>
                </div>
                {renderAttachments()}
            </div>
        )
    }

    const renderExpandSection = () => {
        switch (activeSection) {
            case "message":
                return (
                    <MessagePanel
                        student={undefined} // To implement later
                        departmentId={BaseDepartmentId.CareerServices}
                        studentId={application.student.userId}
                        onClose={handleCloseActiveSection}
                    />
                )
            case "appointment":
                return <StudentInfoCalendarPanel onClose={handleCloseActiveSection} />
            default:
                return <div>{activeSection}</div>
        }
    }

    return (
        <div className={styles.drawerWrap}>
            {/* Application Info */}
            <div className={styles.panelApplicationWrap}>
                <PanelNavigation onClose={onCollapsePanel} />
                <div className={styles.panelApplicationContainer}>
                    <div className={styles.jobWrap}>
                        <div className={styles.sectionTitle}>Job description</div>
                        <JobItemPanel job={job} />
                    </div>
                    {renderApplicationInfo()}
                    {renderApplicationContent()}
                    <div className={styles.noteWrap}>
                        <div className={styles.noteContainer}>
                            {!isStudent && (
                                <EmployerNote notes={application.employerNotes} applicationId={application.id} />
                            )}
                            <StudentAndEmployerComment
                                notes={studentNotes}
                                applicationId={application.id}
                                employerLogo={job.employer.logo}
                                appicationProfileId={application.studentId}
                                studentPhoto={studentPhoto}
                            />
                        </div>
                    </div>
                </div>
                <RejectApplicationForm
                    open={rejectFormVisible.isVisible}
                    onClose={() => rejectFormVisible.close()}
                    onSuccess={onRejectedApplication}
                />
                <OfferApplicationForm
                    open={offerFormVisible.isVisible}
                    onClose={() => offerFormVisible.close()}
                    onSuccess={onOfferredApplication}
                />
            </div>
            {/* Expanded action */}
            <div className={styles.panelWrap}>{renderExpandSection()}</div>
        </div>
    )
}
