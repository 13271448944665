/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./StudentHomepage.module.css"
import {ReactComponent as ProgramIcon} from "./assets/program.svg"
import {ReactComponent as CalendarIcon} from "./assets/calendar.svg"
import {ReactComponent as AttendanceIcon} from "./assets/check.svg"
import {ReactComponent as AttendanceStreakIcon} from "./assets/streak.svg"
import {ReactComponent as SapIcon} from "./assets/sap.svg"
import {ReactComponent as GpaIcon} from "./assets/gpa.svg"
import {ReactComponent as CgpaIcon} from "./assets/cgpa.svg"
import {ReactComponent as LockIcon} from "./assets/lock.svg"
import {ReactComponent as NoticeIcon} from "./assets/notice.svg"
import {useModel} from "hooks"
import {Col, Row} from "antd"
import {Card, DoughnutChart, LiquidChart, ProgressChart, ProjectedEndDateCard, WeeklyPerformance} from "./parts"
import {Auth} from "types/auth"
import moment from "moment"
import {useQuery} from "@tanstack/react-query"
import {attendanceService, studentAccountService, studentLedgerService, studentService} from "services"
import {AttendanceLeaderboard, StudentLeaderboard, TotalHoursData} from "types/students"
import React, {useContext, useEffect, useMemo} from "react"
import {checkPermission, formatCurrency} from "helpers"
import {StudentPaymentContext} from "context/StudentPaymentContext"
import {StudentChargeStatus} from "types/student-account/student-ledger"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {BaseButton, Icon} from "components"
import {useTranslation} from "react-i18next"
import cx from "classnames"
import classNames from "classnames"
import {Permissions} from "@edular/permissions"
import {PersonalFeed} from "../PersonalFeed"
import {AcademicPlans} from "types/academicPlans"
import {HomeCalendar} from "../PersonalCalendar"
import {Tasks} from "../Tasks"
import {Modules, useEdularModulesContext} from "@edular/modules"
import {getDefaultOrFirstProfile} from "helpers/task"

export function StudentHomepage() {
    const {t} = useTranslation(["home"])
    const model = useModel()
    const profile = getDefaultOrFirstProfile(model.user, Auth.UserProfileType.Student)
    const userDateFormat = model.getUserDateFormat()
    const {isModuleEnable} = useEdularModulesContext()

    const isDailyStudent = useMemo(() => {
        if (profile && typeof profile.currentMajorVersionId === "object") {
            return profile.currentMajorVersionId?.attendanceTrackingType === "daily"
        }
        return true
    }, [profile])

    const enabledAcademicProgressDailyProgress = checkPermission(
        {
            student: [Permissions.Student.Users.AcademicProgress.DailyProgress.View]
        },
        model
    )

    const enabledAcademicProgressGraduation = checkPermission(
        {
            student: [Permissions.Student.Users.AcademicProgress.Graduation.View]
        },
        model
    )

    const enabledAcademicProgressWeeklyPerformance = checkPermission(
        {
            student: [Permissions.Student.Users.AcademicProgress.WeeklyPerformance.View]
        },
        model
    )

    const enabledAcademicProgressProjectedGradDate = checkPermission(
        {
            student: [Permissions.Student.Users.AcademicProgress.ProjectedGradDate.View]
        },
        model
    )

    const enabledAcademicProgressRankingAttendance = checkPermission(
        {
            student: [Permissions.Student.Users.AcademicProgress.RankingAttendance.View]
        },
        model
    )

    const enabledAcademicProgressRankingAttendanceStreak = checkPermission(
        {
            student: [Permissions.Student.Users.AcademicProgress.RankingAttendanceStreak.View]
        },
        model
    )

    const enabledAcademicProgressRankingGpa = checkPermission(
        {
            student: [Permissions.Student.Users.AcademicProgress.RankingGpa.View]
        },
        model
    )

    const enabledAcademicProgressRankingCgpa = checkPermission(
        {
            student: [Permissions.Student.Users.AcademicProgress.RankingCgpa.View]
        },
        model
    )

    const enabledAcademicProgressRankingDaysOff = checkPermission(
        {
            student: [Permissions.Student.Users.AcademicProgress.RankingDaysOff.View]
        },
        model
    )

    const enabledAcademicProgressOverallProgress = checkPermission(
        {
            student: [Permissions.Student.Users.AcademicProgress.OverallProgress.View]
        },
        model
    )

    const studentLeaderboardQuery = useQuery(["student-leaderboard"], () => studentService.getLeaderboard(), {
        initialData: {}
    })

    const attendanceLeaderboardQuery = useQuery(
        ["attendance-leaderboard", isDailyStudent],
        () =>
            attendanceService.getLeaderboard({
                timezone: profile.campuses?.[0]?.timezone ?? moment.tz.guess(),
                isDailyStudent
            }),
        {
            enabled: !!profile?.id,
            initialData: {}
        }
    )

    const studentLedgerStatsQuery = useQuery(
        ["student-ledger-stats", profile?.id],
        () => studentLedgerService.getStudentStats(profile?.id),
        {
            enabled: !!profile?.id,
            initialData: null
        }
    )

    const studentOverallStatQuery = useQuery(
        ["student-overall-stats", profile?.id],
        () => studentService.getOverallStats({studentProfileId: profile?.id}),
        {
            enabled: !!profile?.id,
            initialData: {}
        }
    )

    const totalHoursData: TotalHoursData | undefined = useMemo(() => {
        if (!studentOverallStatQuery.isFetched) {
            return undefined
        }

        const stats = studentOverallStatQuery.data as TotalHoursData
        const totalHoursCompleted = stats.totalHoursCompleted || 0
        const totalHours = stats.totalHours || 0
        const totalHoursAttempted = stats.totalHoursAttempted || 0
        const totalDistEdHours = stats.totalDistEdHours || 0
        const totalTransferHours = stats.totalTransferHours || 0
        const totalHoursRemaining = stats.totalHoursRemaining || 0
        const hoursScheduled = stats.hoursScheduled || 0

        return {
            totalHours: Number(totalHours.toFixed(2)),
            totalHoursCompleted: Number(totalHoursCompleted.toFixed(2)),
            totalHoursRemaining: Number(totalHoursRemaining.toFixed(2)),
            totalHoursAttempted: Number(totalHoursAttempted.toFixed(2)),
            totalDistEdHours: Number(totalDistEdHours.toFixed(2)),
            totalTransferHours: Number(totalTransferHours.toFixed(2)),
            hoursScheduled: Number(hoursScheduled.toFixed(2))
        }
    }, [studentOverallStatQuery.isFetched])

    const isStudent = model.isStudent()

    const {paymentPopup, setStudentId} = useContext(StudentPaymentContext)
    const [amountDue, setAmountDue] = React.useState(0)
    const [amountPastDue, setAmountPastDue] = React.useState(0)

    useEffect(() => {
        setAmountDue(0)
        setAmountPastDue(0)
        if (!isStudent) return
        setStudentId(model.profileId)
        ;(async function loadDueCharges() {
            try {
                const {data: incompletedCharges} = await studentLedgerService.getCharges({
                    filter: {
                        profileIds: [model.profileId],
                        statuses: [StudentChargeStatus.Pending, StudentChargeStatus.InProgress]
                    },
                    range: {
                        pageSize: UNLIMITED_PAGE_SIZE,
                        page: 1
                    }
                })
                const {items: planItems, paymentPlans} = await studentAccountService.getIncompletePaymentPlans({
                    profileId: model.profileId
                })

                let amountDue = 0,
                    amountPastDue = 0
                const chargeIdsFromPaymentPlan: number[] = []

                for (const item of planItems) {
                    if (item.paidAmount < item.amount) {
                        if (moment().isAfter(item.dueDate)) {
                            amountPastDue += Math.max(0, item.amount - item.paidAmount)
                        } else if (moment().add(20, "day").isAfter(item.dueDate)) {
                            amountDue += Math.max(0, item.amount - item.paidAmount)
                        }
                        const paymentPlan = paymentPlans.find((plan) => plan.paymentPlanId === item.paymentPlanId)
                        chargeIdsFromPaymentPlan.push(
                            ...(paymentPlan?.charges ?? [])
                                .filter((charge) => charge.status !== StudentChargeStatus.Completed)
                                .map((charge) => charge.chargeId)
                        )
                    }
                }

                for (const charge of incompletedCharges) {
                    if (chargeIdsFromPaymentPlan.includes(charge.chargeId)) continue
                    if (!charge.dueDate) continue
                    if (moment().isAfter(charge.dueDate)) {
                        amountPastDue += Math.max(0, charge.amount - charge.paidAmount)
                    } else if (moment().add(20, "day").isAfter(charge.dueDate)) {
                        amountDue += Math.max(0, charge.amount - charge.paidAmount)
                    }
                }

                setAmountDue(amountDue)
                setAmountPastDue(amountPastDue)
            } catch (error) {
                console.error(error)
                setAmountDue(0)
                setAmountPastDue(0)
            }
        })()
    }, [isStudent, model.profileId])

    const renderGpaCard = () => {
        if (studentLeaderboardQuery.isLoading) return null

        const leaderboard = (studentLeaderboardQuery.data as StudentLeaderboard)?.gpaLeaderboard || []
        const bestRank = leaderboard.length > 0 ? leaderboard.sort((a, b) => (a.rank > b.rank ? 1 : -1))[0] : undefined
        const studentRank = leaderboard.find((x) => x.profileId === profile?.id)

        return (
            <Card
                icon={<GpaIcon className={styles.cardIcon} />}
                title="GPA"
                mainValue={studentRank && studentRank.gpa ? studentRank.gpa.toFixed(2).toString() : "--"}
                bestValue={bestRank && bestRank.gpa ? bestRank.gpa.toFixed(2).toString() : "--"}
                rank={studentRank?.rank || 0}
            />
        )
    }

    const renderDaysOffCard = () => {
        if (studentLeaderboardQuery.isLoading) return null

        const leaderboard = (studentLeaderboardQuery.data as StudentLeaderboard)?.daysOffLeaderboard || []
        const bestRank = leaderboard.length > 0 ? leaderboard.sort((a, b) => (a.rank > b.rank ? 1 : -1))[0] : undefined
        const studentRank = leaderboard.find((x) => x.profileId === profile?.id)

        return (
            <Card
                icon={<span className={styles.cardIcon}>🎢</span>}
                title="Off Track"
                subTitle="(days from contract grad date)"
                mainValue={studentRank && studentRank.daysOff ? studentRank.daysOff.toString() : "--"}
                bestValue={bestRank && bestRank.daysOff ? bestRank.daysOff.toString() : "--"}
                rank={studentRank?.rank || 0}
            />
        )
    }

    const renderCgpaCard = () => {
        if (studentLeaderboardQuery.isLoading) return null

        const leaderboard = (studentLeaderboardQuery.data as StudentLeaderboard)?.cgpaLeaderboard || []
        const bestRank = leaderboard.length > 0 ? leaderboard.sort((a, b) => (a.rank > b.rank ? 1 : -1))[0] : undefined
        const studentRank = leaderboard.find((x) => x.profileId === profile?.id)

        return (
            <Card
                icon={<CgpaIcon className={styles.cardIcon} />}
                title="CGPA"
                mainValue={studentRank && studentRank.cgpa ? studentRank.cgpa.toFixed(2).toString() : "--"}
                bestValue={bestRank && bestRank.cgpa ? bestRank.cgpa.toFixed(2).toString() : "--"}
                rank={studentRank?.rank || 0}
            />
        )
    }

    const renderAttendancePercentageCard = () => {
        if (studentLeaderboardQuery.isLoading) return null

        const leaderboard = (studentLeaderboardQuery.data as StudentLeaderboard)?.attendancePercentageLeaderboard || []
        const bestRank = leaderboard.length > 0 ? leaderboard.sort((a, b) => (a.rank > b.rank ? 1 : -1))[0] : undefined
        const studentRank = leaderboard.find((x) => x.profileId === profile?.id)

        return (
            <Card
                icon={<AttendanceIcon className={styles.cardIcon} />}
                title="Attendance"
                mainValue={
                    studentRank && studentRank.actualAttendancePercentage
                        ? `${studentRank.actualAttendancePercentage.toFixed(2).toString()}%`
                        : "--"
                }
                bestValue={
                    bestRank && bestRank.attendancePercentage
                        ? `${bestRank.attendancePercentage.toFixed(2).toString()}%`
                        : "--"
                }
                rank={studentRank?.rank || 0}
            />
        )
    }

    const renderAttendanceStreakCard = () => {
        if (attendanceLeaderboardQuery.isLoading) return null

        const leaderboard =
            (attendanceLeaderboardQuery.data as AttendanceLeaderboard)?.attendanceStreakLeaderboard || []
        const bestRank = leaderboard.length > 0 ? leaderboard.sort((a, b) => (a.rank > b.rank ? 1 : -1))[0] : undefined
        const studentRank = leaderboard.find((x) => x.profileId === profile?.id)

        return (
            <Card
                icon={<AttendanceStreakIcon className={styles.cardIcon} />}
                title="Attendance Streak"
                mainValue={
                    studentRank && studentRank.attendanceStreak ? `${studentRank.attendanceStreak.toString()}` : "--"
                }
                bestValue={
                    bestRank && bestRank.attendanceStreak ? `${bestRank.attendanceStreak.toString()} days` : "--"
                }
                rank={studentRank?.rank || 0}
                mainUnit="Days"
                comingSoon={true}
            />
        )
    }

    const renderAccountStatus = () => {
        if (isModuleEnable(Modules.StudentAccount) && studentLedgerStatsQuery.isFetched) {
            const stats = studentLedgerStatsQuery.data
            let status: string = "Good"
            if (moment().diff(moment(stats.paymentPastDueDate), "days") > 30) {
                status = "Delinquent"
            }
            if (stats.paymentDueCount || stats.paymentPastDueCount) {
                status = "At Risk"
            }
            return (
                <div className={styles.info}>
                    <Icon icon="DOLLAR_SIGN_FILL" className={styles.attendanceIcon} />
                    <div className={styles.valueWrap}>
                        <span className={styles.valueTitle}>Account Status</span>
                        <span
                            className={cx(styles.sapWrap, {
                                [styles.sapGood]: status === "Good",
                                [styles.sapWarning]: status === "At Risk"
                            })}>
                            {status}
                        </span>
                    </div>
                </div>
            )
        }

        return <></>
    }

    const isBlockedByClock = useMemo(() => {
        if (!profile) {
            return true
        }
        return profile.clockStatus && profile.clockStatus.toLowerCase().includes("off clock")
    }, [profile])

    const isTemporaryNotice = true

    const totalAvailableHours = useMemo(() => {
        if (isDailyStudent) {
            const currentMajorVersion = profile.currentMajorVersionId as Auth.CurrentMajorVersion
            return (
                currentMajorVersion?.fullTimeHoursPerDay ??
                currentMajorVersion?.hybridHoursPerDay ??
                currentMajorVersion?.partTimeHoursPerDay
            )
        }
        return attendanceLeaderboardQuery.isLoading
            ? 0
            : attendanceLeaderboardQuery.data.todayAttendance?.totalAvailableHours
    }, [attendanceLeaderboardQuery.data, isDailyStudent])

    const showsPayment = ["naa", "nau", "hhs", "sandbox", "mdt"].includes(model.slug)

    return (
        <div className={styles.container}>
            {/** Attendance */}
            <div className={styles.section}>
                <Row gutter={[12, 12]}>
                    {showsPayment && (
                        <Col span={12}>
                            <div className={styles.section}>
                                <div className={styles.sectionHeading}>
                                    <Icon icon="DOLLAR_SIGN_FILL" className={styles.attendanceIcon} />
                                    <span className={styles.title}>Payments</span>
                                </div>
                                <div className={classNames(styles.paymentCard, {[styles.due]: !!amountDue})}>
                                    <Row gutter={16} justify="space-between">
                                        <Col className={styles.paymentLabel}>{t("payment.amountDue")}</Col>
                                        <Col className={styles.paymentValue}>{formatCurrency(amountDue)}</Col>
                                    </Row>
                                    {!!amountPastDue && (
                                        <Row gutter={16} justify="space-between" className="mt-4">
                                            <Col className={styles.paymentLabel}>{t("payment.amountPastDue")}</Col>
                                            <Col className={styles.paymentValue}>{formatCurrency(amountPastDue)}</Col>
                                        </Row>
                                    )}
                                    <BaseButton
                                        className="w-full mt-8"
                                        size="small"
                                        title={t("payment.makePayment")}
                                        onClick={paymentPopup.open}
                                    />
                                </div>
                            </div>
                        </Col>
                    )}
                    <Col span={showsPayment ? 12 : 24}>
                        <div className={styles.section}>
                            <div className={styles.sectionHeading}>
                                <Icon icon="CALENDAR_ATTENDANCE" className={styles.attendanceIcon} />
                                <span className={styles.title}>Attendance</span>
                            </div>
                            <div className={styles.infoContainer}>
                                <Row gutter={[12, 12]} style={{flex: 1}}>
                                    <Col span={showsPayment ? 16 : 8}>
                                        <div className={styles.info}>
                                            <ProgramIcon className={styles.attendanceIcon} />
                                            <div className={styles.valueWrap}>
                                                <span className={styles.valueTitle}>Program</span>
                                                <span className={styles.value}>
                                                    {(profile?.currentMajor as Auth.CurrentMajor)?.name || "--"}
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={showsPayment ? 8 : 4}>
                                        <div className={styles.info}>
                                            <CalendarIcon className={styles.attendanceIcon} />
                                            <div className={styles.valueWrap}>
                                                <span className={styles.valueTitle}>Start Date</span>
                                                <span className={styles.value}>
                                                    {profile?.startDate
                                                        ? moment.utc(profile.startDate).format(userDateFormat)
                                                        : "--"}
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={showsPayment ? 8 : 4}>
                                        <div className={styles.info}>
                                            <CalendarIcon className={styles.attendanceIcon} />
                                            <div className={styles.valueWrap}>
                                                <span className={styles.valueTitle}>LDA</span>
                                                <span className={styles.value}>
                                                    {profile?.lastDayOfAttendance
                                                        ? moment(profile.lastDayOfAttendance).format(userDateFormat)
                                                        : "--"}
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={showsPayment ? 8 : 4}>
                                        <div className={styles.info}>
                                            <SapIcon className={styles.attendanceIcon} />
                                            <div className={styles.valueWrap}>
                                                <span className={styles.valueTitle}>SAP status</span>
                                                {profile?.sapStatus ? (
                                                    <span
                                                        className={cx(styles.sapWrap, {
                                                            [styles.sapGood]: [
                                                                AcademicPlans.SapStatus.New,
                                                                AcademicPlans.SapStatus.Satisfactory,
                                                                AcademicPlans.SapStatus["sap-met"]
                                                            ].includes(AcademicPlans.SapStatus[profile.sapStatus]),
                                                            [styles.sapWarning]: [
                                                                AcademicPlans.SapStatus.Warning,
                                                                AcademicPlans.SapStatus.Probation,
                                                                AcademicPlans.SapStatus["sap-not-met"]
                                                            ].includes(AcademicPlans.SapStatus[profile.sapStatus])
                                                        })}>
                                                        {AcademicPlans.SapStatus[profile.sapStatus]}
                                                    </span>
                                                ) : (
                                                    <span className={styles.value}>--</span>
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={showsPayment ? 8 : 4}>{renderAccountStatus()}</Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
                {/* <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <div className={styles.highlightContainer}>
                            <div className={styles.highlightItem}>
                                <FireIcon className={styles.highlightIcon} />
                                <span className={styles.highlightContent}>
                                    2 more hours to go. Complete today and you will be on track to a 24-days streak
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col span={12} />
                </Row> */}
                <Row gutter={[12, 12]}>
                    {enabledAcademicProgressDailyProgress && (
                        <Col span={6}>
                            <div className={styles.cardContainer}>
                                <div className={cx(styles.card)}>
                                    <span className={styles.chartTitle}>Today on-prem</span>
                                    {isTemporaryNotice && (
                                        <div className={styles.noticeWrap}>
                                            <NoticeIcon className={styles.attendanceIcon} />
                                            <div className={styles.noticeTextWrap}>
                                                <span className={styles.noticeHeading}>Temporary Notice</span>
                                                <span className={styles.noticeDescription}>
                                                    Currently these values may not be fully up to date
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    <div className={styles.divider} />
                                    <div className={cx(styles.chartArea, {[styles.cardCensored]: isBlockedByClock})}>
                                        <span className={styles.itemTitle} />
                                        <LiquidChart
                                            totalAttendedHours={
                                                (attendanceLeaderboardQuery.data as AttendanceLeaderboard)
                                                    ?.todayAttendance?.totalAttendedHours
                                            }
                                            totalAvailableHours={totalAvailableHours}
                                        />
                                    </div>
                                </div>
                                {isBlockedByClock && (
                                    <div className={styles.blocked}>
                                        <LockIcon className={styles.cardIcon} />
                                        <span className={styles.blockedLabel}>Blocked From Clock</span>
                                    </div>
                                )}
                            </div>
                        </Col>
                    )}
                    {enabledAcademicProgressGraduation && (
                        <Col span={6}>
                            <div className={styles.chartContainer}>
                                <span className={styles.chartTitle}>Graduation</span>
                                <div className={styles.divider} />
                                <div className={styles.chartArea}>
                                    <span className={styles.itemTitle}>Graduation</span>
                                    {studentOverallStatQuery.isFetched && (
                                        <DoughnutChart
                                            completed={totalHoursData.totalHoursCompleted}
                                            totalHours={totalHoursData.totalHours}
                                        />
                                    )}
                                </div>
                            </div>
                        </Col>
                    )}
                    {enabledAcademicProgressWeeklyPerformance && (
                        <Col span={6}>
                            <div className={styles.cardContainer}>
                                <div className={cx(styles.card)}>
                                    <span className={styles.chartTitle}>Total missed hours</span>
                                    <div className={styles.divider} />
                                    <WeeklyPerformance totalHoursData={totalHoursData} />
                                </div>
                            </div>
                        </Col>
                    )}
                    {enabledAcademicProgressProjectedGradDate && (
                        <Col span={6}>
                            <ProjectedEndDateCard
                                projectedEndDate={profile?.projectedEndDate}
                                contractEndDate={profile?.contractEndDate}
                            />
                        </Col>
                    )}
                </Row>
            </div>
            <div className={styles.section}>
                <Row gutter={[12, 12]}>
                    {enabledAcademicProgressRankingAttendance && <Col span={6}>{renderAttendancePercentageCard()}</Col>}
                    {enabledAcademicProgressRankingAttendanceStreak && (
                        <Col span={6}>{renderAttendanceStreakCard()}</Col>
                    )}
                    {enabledAcademicProgressRankingDaysOff && <Col span={6}>{renderDaysOffCard()}</Col>}
                    {/* {enabledAcademicProgressRankingGpa && <Col span={6}>{renderGpaCard()}</Col>} */}
                    {enabledAcademicProgressRankingCgpa && <Col span={6}>{renderCgpaCard()}</Col>}
                </Row>
            </div>
            <div>
                <Row gutter={[12, 36]}>
                    {enabledAcademicProgressOverallProgress && (
                        <Col span={12}>
                            <div className={styles.section}>
                                <div className={styles.sectionHeading}>
                                    <Icon icon="PIE_CHART" className={styles.attendanceIcon} />
                                    <span className={styles.title}>Progress</span>
                                </div>
                                {studentOverallStatQuery.isFetched && <ProgressChart totalHoursData={totalHoursData} />}
                            </div>
                        </Col>
                    )}
                    <Col span={12}>
                        <div className={styles.section}>
                            <div className={styles.sectionHeading}>
                                <Icon icon="CALENDAR" className={styles.attendanceIcon} />
                                <span className={styles.title}>My Calendar</span>
                            </div>
                            <div
                                className={cx(styles.cardWrap, {
                                    [styles.fixedHeight]: enabledAcademicProgressOverallProgress
                                })}>
                                <HomeCalendar
                                    customCalendarHeight={enabledAcademicProgressOverallProgress ? 240 : undefined}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className={styles.section}>
                            <div className={styles.sectionHeading}>
                                <Icon icon="NEWS" className={styles.attendanceIcon} />
                                <span className={styles.title}>Personal Feed & News</span>
                            </div>
                            <div className={styles.cardWrap}>
                                <PersonalFeed model={model} />
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className={styles.section}>
                            <div className={styles.sectionHeading}>
                                <Icon icon="TASK_FILL" className={styles.attendanceIcon} />
                                <span className={styles.title}>Tasks</span>
                            </div>
                            <div className={styles.cardWrap}>
                                <Tasks />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
