import {get} from "lodash"
import {BaseService} from "services"

const isirBasePath: string = "v1/isir"

export default class ISIRService extends BaseService {
    async getList(body = {}) {
        const response = await this.post(`${isirBasePath}/list`, body)
        return get(response, "data", [])
    }

    async edit(id, data) {
        data.id = id
        const response = await this.post(`${isirBasePath}/edit`, data)
        return get(response, "data", [])
    }

    async createStudentCCodeDocument(data) {
        const response = await this.post(`${isirBasePath}/student-ccode/create-document`, data)
        return get(response, "data", null)
    }

    async getListStudentCCode(body = {}) {
        const response = await this.post(`${isirBasePath}/student-ccode/list`, body)
        return get(response, "data", [])
    }

    async updateStudentCCodeDocument(id, data) {
        data = {
            ...data,
            id
        }
        const response = await this.post(`${isirBasePath}/student-ccode/edit-document`, data)
        return get(response, "data", null)
    }

    async updateStudentApprovalStatus(data) {
        const response = await this.post(`${isirBasePath}/student-ccode/edit-approval-status`, data)
        return get(response, "data", null)
    }

    async getStudentCCodeStatistics() {
        const response = await this.post(`${isirBasePath}/student-ccode/statistics`, {})
        return get(response, "data.data", [])
    }

    async getListCCode(data = {}) {
        const response = await this.post(`${isirBasePath}/ccode/list`, data)
        return get(response, "data", null)
    }

    async updateCcodeItemRequired(id: number, isRequired: boolean | 0 | 1) {
        const response = await this.post(`${isirBasePath}/student-ccode/is-required`, {id, isRequired})
        return get(response, "data", null)
    }

    isirUpload = async (files: File[]): Promise<void> => {
        const formData = new FormData()
        for (const file of files) {
            formData.append(`files`, file)
        }
        const response = await this.post(`${isirBasePath}/upload`, formData)
        return get(response, "data.data", [])
    }
}
