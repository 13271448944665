/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react"
import {useTranslation} from "react-i18next"
import moment from "moment"
import {useHistory} from "react-router-dom"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {useModel} from "hooks"
import {formatCurrency} from "helpers"
import {KlassappTableProps, PaginationPerPage} from "types/common"
import styles from "./PaymentPlanItemsTable.module.css"
import {StudentCharge} from "types/student-account/student-ledger"
import {preventMinusCharacter} from "helpers/inputHelper"
import NumberFormat from "react-number-format"
import {toDateOnly} from "sections/academics/instructional/common/utils"
import {PaymentPlanItem} from "types/student-account/payment-plan"
import LedgerAccountSelect from "components/LedgerAccountSelect"
import {LedgerAccount, LedgerAccountType} from "types/student-account/ledger-accounts"
import {sumBy} from "lodash"

interface Props extends KlassappTableProps {
    studentPaymentAmount?: number
    paymentPlanItems?: PaymentPlanItem[]
    selectedPaymentItems?: PaymentPlanItem[]
    setSelectedPaymentItems?: React.Dispatch<React.SetStateAction<PaymentPlanItem[]>>
}

const PaymentPlanItemsTable: React.FC<Props> = ({
    dispatch,
    dispatchFunc,
    page,
    total,
    pageSize,
    columns,
    data,
    allFields,
    fields,
    tableHeaderActions,
    isLoading,
    menuActions,
    isHideMenuActions,
    isShowTableHeaderAction,
    isLoadedTableFuncs,
    getCurrentData,
    getCurrentPage,
    getCurrentPageSize,
    onChangeFields,
    onDraggableColumn,
    onChangeAllFields,
    onUpdateTableData,
    onChangePage,
    onChangeRowPerPage,
    studentPaymentAmount,
    paymentPlanItems,
    selectedPaymentItems,
    setSelectedPaymentItems
}) => {
    const history = useHistory()
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const {t} = useTranslation(["studentAccount", "common"])

    const getData = React.useCallback(async () => {
        try {
            dispatch({isLoading: true})
            const page = getCurrentPage()
            const pageSize = getCurrentPageSize()
            const filteredPaymentPlanItems = paymentPlanItems.filter((item) => item.paidAmount < item.amount)
            dispatch({
                total: filteredPaymentPlanItems.length,
                data: filteredPaymentPlanItems.slice((page - 1) * pageSize, page * pageSize).map((item) => {
                    const selectedItem = selectedPaymentItems?.find(
                        ({paymentPlanItemId}) => paymentPlanItemId === item.paymentPlanItemId
                    )
                    return {
                        ...item,
                        id: item.paymentPlanItemId,
                        isChecked: !!selectedItem,
                        isActiveTableCol: !!selectedItem,
                        paymentAmount: selectedItem?.paymentAmount ?? item.paymentAmount,
                        paymentAccountId: selectedItem?.paymentAccountId ?? item.paymentAccountId
                    }
                })
            })
        } catch (error) {
            console.error(error)
        } finally {
            dispatch({isLoading: false})
        }
    }, [paymentPlanItems, selectedPaymentItems])

    const onClickRowItem = React.useCallback((item: StudentCharge) => {}, [])

    const getFields = React.useCallback(() => {
        return [
            t("payments.column.id"),
            t("payments.column.paymentPlanId"),
            t("payments.column.originalAmount"),
            t("payments.column.paidAmount"),
            t("payments.column.dueDate"),
            t("payments.column.status"),
            t("payments.column.payment"),
            t("payments.column.transactionType")
        ]
    }, [])

    const getColumns = React.useCallback(
        () => [
            {
                title: t("payments.column.id"),
                field: "paymentPlanItemId"
            },
            {
                title: t("payments.column.paymentPlanId"),
                field: "paymentPlanCode"
            },
            {
                title: t("payments.column.originalAmount"),
                field: "amount",
                render: (value) => formatCurrency(value),
                renderText: (value: number) => (value ?? 0).toFixed(2)
            },
            {
                title: t("payments.column.paidAmount"),
                field: "paidAmount",
                render: (value) => formatCurrency(value),
                renderText: (value: number) => (value ?? 0).toFixed(2)
            },
            {
                title: t("payments.column.dueDate"),
                field: "dueDate",
                render: (_, record: PaymentPlanItem) => {
                    const dueDate = moment(toDateOnly(record.dueDate))
                    if (!record.paidAmount) {
                        const numDays = Math.abs(moment().diff(dueDate, "day"))
                        const isPastDue = dueDate.isBefore(Date.now(), "day")
                        const isDue = moment().add(20, "day").isAfter(dueDate)
                        if (isPastDue)
                            return (
                                <span className={styles.pastDue}>
                                    {dueDate.format(userDateFormat)}
                                    <br />
                                    Past Due{" "}
                                    {t(!numDays ? "paymentPlan.today" : "paymentPlan.numDays", {
                                        count: numDays
                                    })}
                                </span>
                            )
                        if (isDue)
                            return (
                                <span className={styles.due}>
                                    {dueDate.format(userDateFormat)}
                                    <br />
                                    Due{" "}
                                    {t(!numDays ? "paymentPlan.today" : "paymentPlan.numDays", {
                                        count: numDays
                                    })}
                                </span>
                            )
                    }
                    return dueDate.format(userDateFormat)
                }
            },
            {
                title: t("payments.column.payment"),
                field: "paymentAmount",
                render: (_, record: PaymentPlanItem & {isChecked?: boolean}) =>
                    !record.isChecked ? null : (
                        <NumberFormat
                            prefix="$"
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            className={styles.input}
                            onKeyDown={preventMinusCharacter}
                            min={1}
                            placeholder={t(t("payments.column.amount"))}
                            value={record.paymentAmount}
                            onValueChange={({floatValue}) => {
                                dispatch({
                                    data: getCurrentData().map((item: PaymentPlanItem) =>
                                        item.paymentPlanItemId === record.paymentPlanItemId
                                            ? {...item, paymentAmount: floatValue}
                                            : item
                                    )
                                })
                                setSelectedPaymentItems?.((prev) =>
                                    prev.map((item) =>
                                        item.paymentPlanItemId !== record.paymentPlanItemId
                                            ? item
                                            : {...item, paymentAmount: floatValue}
                                    )
                                )
                            }}
                        />
                    )
            },
            {
                title: t("payments.column.transactionType"),
                field: "paymentAccountId",
                render: (_, record: PaymentPlanItem & {isChecked?: boolean}) =>
                    !record.isChecked ? null : (
                        <LedgerAccountSelect
                            placeholder={t("payments.column.transactionType")}
                            active={true}
                            type={LedgerAccountType.Payments}
                            isClearable={true}
                            isMulti={false}
                            value={record.paymentAccountId}
                            onChange={(account?: LedgerAccount) => {
                                dispatch({
                                    data: getCurrentData().map((item: PaymentPlanItem) =>
                                        item.paymentPlanItemId === record.paymentPlanItemId
                                            ? {...item, paymentAccountId: account?.accountId ?? null}
                                            : item
                                    )
                                })
                                setSelectedPaymentItems?.((prev) =>
                                    prev.map((item) =>
                                        item.paymentPlanItemId !== record.paymentPlanItemId
                                            ? item
                                            : {...item, paymentAccountId: account?.accountId ?? null}
                                    )
                                )
                            }}
                            components={{
                                ClearIndicator: () => <div className="w-20" />,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null
                            }}
                            menuPortalTarget={document.body}
                            stylesCustom={{
                                menuPortal: (styles) => ({
                                    ...styles,
                                    zIndex: 9999
                                }),
                                menu: (styles) => ({
                                    ...styles,
                                    width: "max-content",
                                    minWidth: "100%",
                                    maxWidth: "450px",
                                    right: 0
                                })
                            }}
                        />
                    )
            }
        ],
        [t, userDateFormat]
    )

    const getTableHeaderActions = React.useCallback(
        (isShowDuplicateBtn = true, checkedData = []) => {
            return []
        },
        [t]
    )

    const getMenuActions = React.useCallback(() => {
        return []
    }, [t])

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: () => ""},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getTableHeaderActions", func: getTableHeaderActions},
            {key: "getMenuActions", func: getMenuActions},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
        setTimeout(() => {
            onChangeRowPerPage(PaginationPerPage.item10)
        }, 500)
    }, [])

    useEffect(() => {
        dispatchFunc([
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
    }, [getColumns, getFields])

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getData()
        }
    }, [isLoadedTableFuncs, page, pageSize, paymentPlanItems])

    const handleUpdateRowData = React.useCallback(
        (updatedRow: PaymentPlanItem & {isChecked: boolean}) => {
            const otherCheckedItems =
                selectedPaymentItems?.filter((item) => item.paymentPlanItemId !== updatedRow.paymentPlanItemId) ?? []
            const newData = getCurrentData().map((item: PaymentPlanItem) => {
                if (item.paymentPlanItemId !== updatedRow.paymentPlanItemId) {
                    return item
                }
                let paymentAmount: number
                let paymentAccountId: number
                if (!updatedRow.isChecked) {
                    paymentAmount = undefined
                    paymentAccountId = undefined
                } else {
                    const paymentAmountDue = Math.max(
                        0,
                        studentPaymentAmount - sumBy(otherCheckedItems, "paymentAmount")
                    )
                    const itemAmountDue = Math.max(0, updatedRow.amount - updatedRow.paidAmount)
                    paymentAmount = Math.min(itemAmountDue, paymentAmountDue)
                    paymentAccountId = updatedRow.paymentAccountId
                }
                return {
                    ...updatedRow,
                    isActiveTableCol: !!updatedRow.isChecked,
                    paymentAmount,
                    paymentAccountId
                }
            })
            dispatch({data: newData})
            setSelectedPaymentItems?.(
                !updatedRow.isChecked
                    ? otherCheckedItems
                    : [
                          ...otherCheckedItems,
                          newData.find((item) => item.paymentPlanItemId === updatedRow.paymentPlanItemId)
                      ]
            )
        },
        [studentPaymentAmount, selectedPaymentItems]
    )

    return (
        <div className={styles.wrap}>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={onChangePage}
                onChangeRowPerPage={onChangeRowPerPage}
            />
            <KlassappTable
                columns={columns}
                data={data}
                isLoading={isLoading}
                menuActions={isHideMenuActions ? [] : menuActions}
                fields={fields}
                allFields={allFields}
                isShowCheckedColumn={true}
                onClickRowItem={onClickRowItem}
                onChangeFields={onChangeFields}
                onDraggableColumn={onDraggableColumn}
                onChangeAllFields={onChangeAllFields}
                onUpdateRowData={handleUpdateRowData}
                onUpdateTableData={onUpdateTableData}
            />
        </div>
    )
}

export default KlassappTableHOC(PaymentPlanItemsTable)
