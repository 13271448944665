import React from "react"
import {isString} from "lodash"
import classes from "./ScreenHeader.module.css"
import cx from "classnames"
import {BackButton} from "components/buttons/BackButton"
import {Search, SearchProps} from "components/inputs/Search"
import {Notification} from "components/Notification"

export type ScreenHeaderProps = {
    className?: string
    title?: string | React.ReactNode
    htmlSubtitle?: string | React.ReactNode
    code?: string
    backUrl?: string
    search?: SearchProps
    isShowNotification?: boolean
    action?: JSX.Element
    htmlTitle?: any
    variant?: "row" | "column"
    backData?: any
    rightPanelDetected?: boolean
    titleClassName?: any
    customBackButton?: () => JSX.Element
}

export const ScreenHeader = ({
    className,
    title,
    code,
    backUrl,
    search,
    action,
    htmlSubtitle,
    htmlTitle,
    variant = "row",
    isShowNotification = true,
    titleClassName,
    backData,
    rightPanelDetected,
    customBackButton
}: ScreenHeaderProps) => {
    const renderTitle = () => {
        if (isString(title)) {
            return (
                <span className={cx(classes.title, {[classes.titleAlone]: !headerBackButton}, titleClassName)}>
                    {title}
                </span>
            )
        }
        return title
    }

    const renderNotification = () => {
        return (
            <div className={cx(classes.notificationWrap, {[classes.notificationWrap__hasDrawer]: rightPanelDetected})}>
                <Notification />
            </div>
        )
    }

    const headerBackButton = customBackButton
        ? customBackButton()
        : backUrl && <BackButton url={backUrl} className={classes.backButton} backData={backData} />

    return (
        <div className={cx(classes.root, className)}>
            {variant === "row" ? (
                <>
                    {headerBackButton}
                    {renderTitle()}
                    {!!code && <span className={classes.code}>({code})</span>}
                    {search && <Search {...search} className={cx(classes.search, search.className)} />}
                    {action}
                    {isShowNotification ? renderNotification() : null}
                </>
            ) : (
                <div className={classes.headerWrap}>
                    <div className={classes.headerLeftWrap}>
                        <BackButton url={backUrl} className={classes.backButton} backData={backData} />
                    </div>
                    <div className={cx(classes.headerRightWrap, !title && !htmlSubtitle ? classes.flexCenter : "")}>
                        <p className={classes.headerRightTitle}>{htmlTitle}</p>
                        {title && (
                            <div className={classes.headerRightInfo}>
                                <>
                                    <p className={classes.headerRightInfo__title}>{title}</p>
                                    <p className={classes.headerRightInfo__code}>{code}</p>
                                </>
                            </div>
                        )}
                        {htmlSubtitle && (
                            <p style={{marginTop: 5}} className={classes.headerRightInfo__code}>
                                {htmlSubtitle}
                            </p>
                        )}
                    </div>
                    {isShowNotification ? renderNotification() : null}
                </div>
            )}
        </div>
    )
}
