import React, {useState} from "react"
import {Row, Col} from "antd"
import {BasePopup} from "components/popup"
import {BaseButton} from "components/buttons"
import {KlassDropdown} from "components/Select"
import {AcademicPlans} from "types/academicPlans"
import {academicPlansService, academicCourseGradesService} from "services"
import {handleError, isPositiveFloat, toastError, toastSuccess} from "helpers"
import {FormLabel} from "components/Form"
import {BaseInput} from "components/inputs"
import styles from "./ChangeCourseStatusPopup.module.css"
import {BaseDatePicker} from "components/DateTimePicker"
import {AcademicInstructional} from "types/academicInstructional"

type Props = {
    isShow: boolean
    studentProfileId: number
    termId: number
    courseId: number
    academicPlanCourseId: number
    scheduleId: number
    onClose: () => void
}

export function ChangeCourseStatusPopup(props: Props) {
    const statuses = [
        {id: AcademicPlans.AcademicPlanCourseStatus.Completed, name: "Completed"},
        {id: AcademicPlans.AcademicPlanCourseStatus.Failed, name: "Failed"},
        {id: AcademicPlans.AcademicPlanCourseStatus.Dropped, name: "Dropped"}
    ]
    const [isSaving, setIsSaving] = useState(false)
    const [info, setInfo] = useState({
        totalGrade: null,
        maxTotalGrade: null,
        term: null,
        schedule: null,
        weightedAvgValue: null,
        gpaValue: null,
        alphaValue: null,
        totalValue: null,
        maxTotalValue: null,
        status: {id: AcademicPlans.AcademicPlanCourseStatus.Completed, name: "Completed"}
    })
    const [dropDate, setDropDate] = useState(null)
    const {isShow, studentProfileId, termId, courseId, academicPlanCourseId, scheduleId, onClose} = props

    const updateStatus = async () => {
        try {
            setIsSaving(true)
            await academicPlansService.changeCourseStatus({
                studentProfileId,
                status: info.status.id,
                termId,
                academicPlanCourseId,
                scheduleId: scheduleId,
                dropDate: dropDate || undefined
            })
            await academicCourseGradesService.addManualGrade({
                listGrades: [
                    {
                        courseId: courseId,
                        weightedAvg: info.weightedAvgValue ? parseFloat(info.weightedAvgValue) : 0,
                        isPassed: info.status.id === AcademicPlans.AcademicPlanCourseStatus.Completed,
                        passFail:
                            info.status.id === AcademicPlans.AcademicPlanCourseStatus.Completed
                                ? AcademicInstructional.PassFailStatus.Pass
                                : AcademicInstructional.PassFailStatus.Fail,
                        gpa: info.gpaValue ? parseFloat(info.gpaValue) : 0,
                        alphabetical: info.alphaValue || undefined,
                        totalGrade: info.totalValue ? parseFloat(info.totalValue) : 0,
                        maxTotalGrade: info.maxTotalValue ? parseFloat(info.maxTotalValue) : 0,
                        isVerified: true,
                        studentProfileId: studentProfileId,
                        termId,
                        scheduleId
                    }
                ]
            })
            toastSuccess("Update successfully")
            onClose()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSaving(false)
        }
    }

    const updateField = (field, value) => {
        const newInfo = {...info}
        newInfo[field] = value
        setInfo(newInfo)
    }

    const onChangeInfo = (field, value) => {
        switch (field) {
            case "totalValue":
            case "weightedAvgValue":
            case "gpa":
                if (isPositiveFloat(value)) {
                    updateField(field, value)
                } else {
                    toastError(`Invalid value`)
                }
                break
            default:
                updateField(field, value)
                break
        }
    }

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="EDIT_LINE" width="60vw">
            <div className={styles.wrap}>
                <p className={styles.title}>Change Course Status</p>
                <Row gutter={[24, 12]}>
                    <Col span={12}>
                        <div>
                            <FormLabel label="Status" />
                            <KlassDropdown
                                options={statuses}
                                value={info.status}
                                onChange={(newValue) => onChangeInfo("status", newValue)}
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        {info.status.id === AcademicPlans.AcademicPlanCourseStatus.Dropped && (
                            <div>
                                <FormLabel label="Drop Date" />
                                <BaseDatePicker value={dropDate} onChange={(newValue) => setDropDate(newValue)} />
                            </div>
                        )}
                    </Col>
                </Row>
                <div className={styles.courseGradeWrap}>
                    <FormLabel label="Course Grade" />
                    <Row gutter={[24, 12]}>
                        <Col span={12}>
                            <div>
                                <FormLabel label="Total" />
                                <div className={styles.inputWrap}>
                                    <BaseInput
                                        disabledArrow
                                        className={styles.inputGrade}
                                        type="number"
                                        value={info.totalValue}
                                        onChange={(newValue) => onChangeInfo("totalValue", newValue)}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <FormLabel label="Weight AVG" />
                                <div className={styles.inputWrap}>
                                    <BaseInput
                                        disabledArrow
                                        className={styles.inputGrade}
                                        type="number"
                                        value={info.weightedAvgValue}
                                        onChange={(newValue) => onChangeInfo("weightedAvgValue", newValue)}
                                    />
                                    <span className={styles.extraInput}>/ 100</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <FormLabel label="Alpha" />
                                <BaseInput
                                    className={styles.inputGrade}
                                    value={info.alphaValue}
                                    onChange={(newValue) => onChangeInfo("alphaValue", newValue)}
                                />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <FormLabel label="Point" />
                                <BaseInput
                                    disabledArrow
                                    className={styles.inputGrade}
                                    type="number"
                                    value={info.gpaValue}
                                    onChange={(newValue) => onChangeInfo("gpaValue", newValue)}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className={styles.action}>
                    <BaseButton title="Cancel" variant="secondary" loading={isSaving} onClick={onClose} />
                    <BaseButton title="Save" loading={isSaving} onClick={updateStatus} />
                </div>
            </div>
        </BasePopup>
    )
}
