import {get} from "lodash"
import BaseService from "./Base"
import {CurriculumEnrollmentStatus} from "types/degreeLevel"

class CurriculumEnrollmentStatusService extends BaseService {
    getEnrollmentStatuses = async (degreeLevelId: number): Promise<CurriculumEnrollmentStatus[]> => {
        const response = await this.post("/v1/curriculum-enrollment-statuses/list", {degreeLevelId})
        return get(response, "data.data")
    }

    updateEnrollmentStatuses = async (
        degreeLevelId: number,
        enrollmentStatuses: Partial<CurriculumEnrollmentStatus>[]
    ): Promise<void> => {
        await this.post(`/v1/curriculum-enrollment-statuses/update`, {degreeLevelId, enrollmentStatuses})
    }
}

export default CurriculumEnrollmentStatusService
