import {KlassDropAsyncPaginate} from "components/Select"
import {convertTZ, formatCodeName, getFullName, handleError} from "helpers"
import debounce from "debounce-promise"
import {useCallback, useEffect, useState} from "react"
import {attendanceService, courseScheduleService, instructionalService, termsService} from "services"
import styles from "./ListLessons.module.css"
import {TermDetails} from "types/terms"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {KlassappTableProps} from "types/common"
import {cloneDeep, get} from "lodash"
import {useModel} from "hooks"
import {useTranslation} from "react-i18next"
import moment from "moment"
import {Course} from "types/courses"
import {Col, Row} from "antd"
import {KlassDropdown} from "components"

type ListLessonsProps = {
    timezone?: string
    campusIds: number[]
    studentProfileId: number
    setListLessons: (value: {id: number; selected: boolean}[]) => void
} & KlassappTableProps

const ListLessons = (props: ListLessonsProps) => {
    const {
        data,
        page,
        total,
        pageSize,
        columns,
        fields,
        allFields,
        isLoading,
        dispatch,
        timezone,
        campusIds,
        setListLessons,
        studentProfileId
    } = props
    const [selectedTerm, setSelectedTerm] = useState<TermDetails>()
    const [selectedSchedule, setSelectedSchedule] = useState<TermDetails>()
    const [selectedCourse, setSelectedCourse] = useState<Course.Course>()
    const [schedules, setSchedules] = useState([])
    const model = useModel()
    const dateFormat = model.getUserDateFormat()
    const {t} = useTranslation(["studentServices", "common"])

    useEffect(() => {
        props.dispatch({isClassComponent: false})
        props.dispatchFunc([
            {key: "getListData", func: getLessons},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getFilterParams = useCallback(() => {
        const params: any = {
            termIds: selectedTerm?.id ? [selectedTerm.id] : [],
            isMissedAttendances: true,
            checkInvite: false,
            scheduleId: selectedSchedule?.id,
            studentProfileIds: [studentProfileId],
            courses: selectedCourse ? [selectedCourse.courseId] : []
        }
        return params
    }, [selectedCourse, selectedSchedule?.id, selectedTerm?.id, studentProfileId])

    const getLessons = useCallback(async () => {
        try {
            dispatch({isLoading: true})
            const params = getFilterParams()
            const {data = []} = await attendanceService.getLessonsAttendance(params)

            const lessons = data.map((item) => {
                const {startsAt, endsAt, status} = item.lesson
                item.id = item.lesson.id
                item.date = moment(convertTZ(new Date(startsAt), timezone)).format(model.getUserDateFormat())
                item.timeRange = `${moment(convertTZ(new Date(startsAt), timezone)).format(
                    model.getUserTimeFormat()
                )} - ${moment(convertTZ(new Date(endsAt), timezone)).format(model.getUserTimeFormat())}`
                item.name = item.lesson.name
                item.status = status
                item.instructors = item.lesson.instructors?.map((instructor) => getFullName(instructor))?.join(",")
                return item
            })
            dispatch({data: lessons, total: lessons.length ?? 0})
            setListLessons(lessons)
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }, [dispatch, getFilterParams, timezone, model, setListLessons])

    useEffect(() => {
        if (studentProfileId) {
            getLessons()
        }
    }, [page, pageSize, selectedTerm, selectedCourse, getLessons, studentProfileId])

    function getColumns() {
        return [
            {
                title: t("attendance.table.date"),
                field: "date",
                fieldType: "date",
                format: dateFormat
            },
            {
                title: "Time",
                field: "timeRange"
            },
            {
                title: t("attendance.table.name"),
                field: "name"
            },
            {
                title: t("attendance.table.instructor"),
                field: "instructors"
            }
        ]
    }

    function getFields() {
        return [t("attendance.table.date"), "Time", t("attendance.table.name"), t("attendance.table.instructor")]
    }

    const searchTerm = useCallback(async (search: string = "", loadedOptions) => {
        try {
            const {data, total} = await termsService.getAllTerms({
                fields: ["id", "name"],
                text: search,
                limit: 20,
                offset: loadedOptions.length,
                orderBy: "start_date",
                orderDir: "desc"
            })
            return {
                options: data,
                hasMore: loadedOptions.length < total
            }
        } catch (e) {
            return {
                options: [],
                hasMore: false
            }
        }
    }, [])

    const onUpdateRowData = (updatedData) => {
        const data = props.getCurrentData()
        const newData = cloneDeep(data).map((item) => {
            if (item.id === updatedData.id) {
                return {
                    ...item,
                    isChecked: updatedData.isChecked
                }
            }
            return {
                ...item,
                isChecked: false
            }
        })
        setListLessons(newData)
        dispatch({data: newData})
    }

    const searchCourse = useCallback(
        async (search: string = "", loadedOptions) => {
            try {
                const pageSize = 20
                const page = Math.ceil(loadedOptions.length / pageSize) + 1
                const {data, total} = await instructionalService.getMyCourse({
                    range: {page, pageSize},
                    filter: {
                        search,
                        termIds: [selectedTerm.id],
                        isPracticalOrClinicalServices: false
                    }
                })
                return {
                    options: data.map((item) => ({
                        ...item,
                        name: item.courseName,
                        code: item.courseCode
                    })),
                    hasMore: loadedOptions.length < total
                }
            } catch (e) {
                handleError(e)
                return {
                    options: [],
                    hasMore: false
                }
            }
        },
        [selectedTerm]
    )

    const getSchedules = useCallback(async () => {
        try {
            const {data} = await courseScheduleService.courseScheduleGet({
                filter: {
                    term_id: selectedTerm.id,
                    course_id: selectedCourse.courseId
                }
            })
            setSchedules(data)
        } catch (e) {
            handleError(e)
        }
    }, [selectedTerm, selectedCourse])

    useEffect(() => {
        if (selectedCourse) {
            getSchedules()
        }
    }, [getSchedules, selectedCourse])

    const onSearchTerm = debounce(searchTerm, 300)
    const onSearchCourse = debounce(searchCourse, 300)

    return (
        <div className={styles.container}>
            <span className={styles.selectTerm}>Select term</span>
            <KlassDropAsyncPaginate
                stylesCustom={{
                    container: (provided, state) => {
                        return {
                            ...provided,
                            width: "100%",
                            marginTop: 10,
                            marginBottom: 20
                        }
                    }
                }}
                isClearable
                getOptionLabel={formatCodeName}
                value={selectedTerm}
                valueKey="id"
                onChange={(value) => {
                    setSelectedCourse(undefined)
                    setSelectedSchedule(undefined)
                    setSelectedTerm(value)
                }}
                labelKey="name"
                loadOptions={onSearchTerm}
                placeholder="Select"
            />
            <Row style={{marginBottom: 20}} gutter={20}>
                <Col span={12}>
                    <span className={styles.selectTerm}>Select course</span>
                    <KlassDropAsyncPaginate
                        key={selectedTerm?.id}
                        stylesCustom={{
                            container: (provided, state) => {
                                return {
                                    ...provided,
                                    width: "100%",
                                    marginTop: 10
                                }
                            }
                        }}
                        isDisabled={!selectedTerm}
                        isClearable
                        getOptionLabel={formatCodeName}
                        value={selectedCourse}
                        valueKey="courseId"
                        onChange={(value) => {
                            setSelectedSchedule(undefined)
                            setSelectedCourse(value)
                        }}
                        labelKey="courseName"
                        loadOptions={selectedTerm ? onSearchCourse : undefined}
                        placeholder="Select"
                    />
                </Col>
                <Col span={12}>
                    <span className={styles.selectTerm}>Select schedule</span>
                    <KlassDropdown
                        key={`${selectedTerm?.id} ${selectedCourse?.courseId}`}
                        options={schedules}
                        value={selectedSchedule}
                        labelKey="schedule_suffix"
                        stylesCustom={{
                            container: (provided, state) => {
                                return {
                                    ...provided,
                                    width: "100%",
                                    marginTop: 10
                                }
                            }
                        }}
                        isDisabled={!selectedCourse}
                        onChange={setSelectedSchedule}
                        isClearable
                    />
                </Col>
            </Row>
            <KlassappTableHeader
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                isShowAllRecords
                isShowAction={false}
                onChangeRowPerPage={props.onChangeRowPerPage}
            />
            <KlassappTable
                columns={columns}
                data={data}
                className={styles.tableLesson}
                isShowScrollTable
                isShowCheckedColumn
                isShowCheckedColumnHeader={false}
                isLoading={isLoading}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </div>
    )
}

export default KlassappTableHOC(ListLessons)
