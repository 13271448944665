import {get} from "lodash"
import BaseService from "./Base"

const basePath: string = `/v1/settings/email-templates`

class SettingEmailTemplatesService extends BaseService {
    async list(params) {
        const response = await this.post(`${basePath}/list`, params)
        return get(response, "data", [])
    }

    async getMaxId(body = {}) {
        const response = await this.post(`${basePath}/get-max-id`, body)
        return get(response, "data", [])
    }

    async create(data = {}) {
        const response = await this.post(`${basePath}/create`, data)
        return get(response, "data.data", {})
    }

    async edit(id, data, params = {}) {
        data.emailTemplateId = id
        const response = await this.post(`${basePath}/edit`, data, params)
        return get(response, "data", {})
    }

    async delete(emailTemplateIds: number[]) {
        const response = await this.post(`${basePath}/delete`, {emailTemplateIds})
        return get(response, "data", {})
    }
}

export default SettingEmailTemplatesService
