import {Timestamps} from "./common"
import {Workflow} from "./workflow"
import {Auth} from "./auth"
import {ContactStrategy} from "./contact-strategy"

export enum TriggeredWorkflowStatus {
    Pending = "pending",
    InProgress = "in_progress",
    OnHold = "on_hold",
    Canceled = "canceled",
    Done = "done"
}

export enum TriggeredWorkflowType {
    AutomatedWorkflow = "automated-workflow",
    ProcessWorkflow = "process-workflow",
    ContactStrategy = "contact-strategy"
}

export type TriggeredWorkflow = Timestamps & {
    triggeredWorkflowId: number
    type: TriggeredWorkflowType
    status: TriggeredWorkflowStatus
    profileId?: number | null
    profile?: Auth.UpdatedByProfile // populate

    workflowId?: number | null
    workflow?: Workflow // populate
    template?: string | null

    contactStrategyId?: number | null
    contactStrategy?: ContactStrategy // populate
    triggeredTime?: string | null
    completedTime?: string | null

    // internal use
    currentDay?: number
    currentDayChanged?: boolean
}
