/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useContext, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import moment, {Moment} from "moment"
import {useHistory} from "react-router-dom"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {studentAccountService, studentPaymentService} from "services"
import {useModel, useVisible} from "hooks"
import {Col, Popover, Row} from "antd"
import {routing} from "helpers/routing"
import {formatCurrency, getFullName, handleError} from "helpers"
import {KlassappTableProps, Order} from "types/common"
import {BaseButton, Icon} from "components"
import styles from "./PaymentsTable.module.css"
import classNames from "classnames"
import {
    getPaymentAmountDue,
    PAYMENT_METHOD_OPTIONS,
    StudentPayment,
    StudentPaymentLinkedEntity
} from "types/student-account/student-payment"
import {StudentCharge} from "types/student-account/student-ledger"
import {AssignChargesPopup} from "sections/StudentAccount/Student/parts/StudentLedgerTab/parts/PaymentsTab/PaymentsTable/AssignChargesPopup"
import {StudentPaymentContext} from "context/StudentPaymentContext"
import {toDateOnly} from "sections/academics/instructional/common/utils"
import {FilterKey} from "types/filter"
import {get, isEmpty, sumBy, uniqBy} from "lodash"
import {ExportButton, ExportFileType} from "components/ui"
import {useMutation} from "@tanstack/react-query"
import {exportCsv, exportExcel, exportPdf} from "helpers/export-table"
import {LedgerAccount, LedgerAccountFundType, LedgerAccountType} from "types/student-account/ledger-accounts"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {KlassDropdown} from "components/Select"
import {BaseRangePicker} from "components/DateTimePicker"
import PaymentPeriodSelect from "components/PaymentPeriodSelect"
import LedgerAccountSelect from "components/LedgerAccountSelect"
import {useAllLedgerAccounts} from "hooks/useAllLedgerAccounts"

interface Props extends KlassappTableProps {
    profileId?: number
    isNinetyTenReport?: boolean
    fiscalYearDates?: Moment[] | null
}

const PaymentsTable: React.FC<Props> = ({
    dispatch,
    dispatchFunc,
    page,
    total,
    pageSize,
    columns,
    data,
    allFields,
    fields,
    orderField,
    tableHeaderActions,
    isLoading,
    menuActions,
    isHideMenuActions,
    isShowTableHeaderAction,
    onClickShowConfirmModal,
    getCurrentData,
    getCurrentPage,
    getCurrentPageSize,
    onChangePage,
    onChangeRowPerPage,
    onChangeFields,
    onClickSortColumn,
    onDraggableColumn,
    onChangeAllFields,
    onUpdateRowData,
    onUpdateTableData,
    profileId,
    isNinetyTenReport = false,
    fiscalYearDates
}) => {
    const history = useHistory()
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const filterKey = isNinetyTenReport ? FilterKey.StudentAccount9010 : FilterKey.StudentAccountStudentPayments
    const storageData = model.getStorageFilter(filterKey)
    const {currencyExchangeRates} = useContext(StudentPaymentContext)
    const {t} = useTranslation(["studentAccount", "common"])
    const assignChargesPopup = useVisible(false)
    const [selectedPaymentId, setSelectedPaymentId] = useState<number | undefined>()
    const {ledgerAccounts} = useAllLedgerAccounts()

    // region filter

    const initialFilter = {
        methodOfPayments: [],
        fundTypes: [],
        tranxDates: fiscalYearDates,
        paymentPeriod: null,
        accounts: []
    }
    const [search, setSearch] = useState(!isEmpty(storageData?.search) ? storageData.search : "")
    const [filter, setFilter] = useState(!isEmpty(storageData?.filter) ? storageData.filter : initialFilter)
    const [filterSeq, setFilterSeq] = useState(1)

    const onChangeFilter = useCallback((key: keyof typeof filter, value: any) => {
        setFilter((prev) => ({...prev, [key]: value}))
    }, [])

    const onClearFilter = useCallback(() => {
        setFilter(initialFilter)
    }, [])

    const onSearchInput = useCallback(
        (search = "") => {
            setSearch(search)
            model.updateStorageFilter(filterKey, {search, page: 1})
        },
        [filterKey]
    )

    // endregion filter

    const getFilters = React.useCallback(
        (filter) => ({
            methodOfPayments: filter.methodOfPayments?.length
                ? filter.methodOfPayments.map((option) => option.id)
                : undefined,
            fundTypes: filter.fundTypes?.length ? filter.fundTypes.map((option) => option.id) : undefined,
            tranxDates: filter.tranxDates,
            paymentPeriodStartDate: filter.paymentPeriod
                ? moment(filter.paymentPeriod.start).startOf("day").format("YYYY-MM-DD")
                : undefined,
            paymentPeriodEndDate: filter.paymentPeriod
                ? moment(filter.paymentPeriod.end).endOf("day").format("YYYY-MM-DD")
                : undefined,
            accountIds: filter.accounts?.length ? filter.accounts.map((account) => account.accountId) : undefined
        }),
        []
    )

    const getData = React.useCallback(async () => {
        dispatch({isLoading: true})
        const page = getCurrentPage()
        const pageSize = getCurrentPageSize()
        const {total, data} = await studentPaymentService.getPayments({
            filter: {
                ...getFilters(filter),
                search,
                studentProfileId: profileId,
                status: isNinetyTenReport ? "success" : undefined
            },
            range: {
                pageSize,
                page
            },
            sort: {
                orderBy: orderField?.field ?? "tranxDate",
                orderDir: orderField?.order ?? Order.Desc
            },
            linkedEntities: [
                StudentPaymentLinkedEntity.Profile,
                StudentPaymentLinkedEntity.Account,
                StudentPaymentLinkedEntity.GeneralPayment,
                StudentPaymentLinkedEntity.Charges,
                StudentPaymentLinkedEntity.PaymentPlan
                // !isNinetyTenReport ? null : StudentPaymentLinkedEntity.PaymentPlanCharges
            ].filter(Boolean)
        })
        dispatch({total, data: data.map((item) => ({...item, id: item.paymentId})), isLoading: false})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFilters, search, filter, orderField?.field, orderField?.order, profileId, isNinetyTenReport])

    const onApplyFilter = useCallback(() => {
        setFilterSeq((prev) => prev + 1)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        onChangeFilter("tranxDates", fiscalYearDates)
        setFilterSeq((prev) => prev + 1)
        // eslint-disable-next-line
    }, [fiscalYearDates])

    const onClickRowItem = React.useCallback(({studentProfileId, paymentId}: StudentPayment) => {
        history.push(routing.studentAccount.student.ledger.payment.detail(studentProfileId, paymentId))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClickEdit = React.useCallback(({studentProfileId, paymentId}: StudentPayment) => {
        history.push(routing.studentAccount.student.ledger.payment.detail(studentProfileId, paymentId))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClickDelete = React.useCallback(
        async (deletedItem: StudentPayment) => {
            // if (deletedItem.status !== "pending") {
            //     toastError(t("common:message.cantDeleteLockedItem"))
            //     return
            // }
            try {
                dispatch({isLoading: true})
                await studentPaymentService.deletePayments([deletedItem.paymentId])
                await getData()
            } catch (e) {
                handleError(e)
            } finally {
                dispatch({isLoading: false})
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [getData]
    )

    const onClickDeleteMulti = React.useCallback(async () => {
        const checkedItems = getCurrentData().filter((item) => item.isChecked)
        // if (checkedItems.find((item) => item.status !== "pending")) {
        //     toastError(t("common:message.cantDeleteLockedItem"))
        //     return
        // }
        try {
            dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await studentPaymentService.deletePayments(checkedItems.map((item) => item.paymentId))
            await getData()
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getData])

    const getPageTitle = () => {
        return t("payments.title")
    }

    const getFields = React.useCallback(() => {
        return [
            t("payments.column.date"),
            profileId ? null : t("payments.column.studentId"),
            profileId ? null : t("payments.column.studentName"),
            t("payments.column.transactionType"),
            !isNinetyTenReport ? null : t("payments.column.fundSource"),
            t("payments.column.id"),
            t("payments.column.amount"),
            t("payments.column.methodOfPayment"),
            isNinetyTenReport ? null : t("payments.column.charge"),
            isNinetyTenReport ? null : t("payments.column.status"),
            isNinetyTenReport ? null : t("payments.column.notes")
        ].filter(Boolean)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileId, isNinetyTenReport])

    const getColumns = React.useCallback(
        () =>
            [
                {
                    title: t("payments.column.date"),
                    field: "tranxDate",
                    columnIndex: 1,
                    fieldType: "date",
                    style: {width: "100px"},
                    sortable: true
                },
                {
                    title: t("payments.column.studentId"),
                    field: "studentProfile.customProfileId",
                    columnIndex: 2
                },
                {
                    title: t("payments.column.studentName"),
                    field: "studentProfile",
                    columnIndex: 3,
                    render: (studentProfile) => getFullName(studentProfile)
                },
                {
                    title: t("payments.column.firstName"),
                    field: "studentProfile",
                    columnIndex: 31,
                    render: (studentProfile) => studentProfile?.firstName
                },
                {
                    title: t("payments.column.lastName"),
                    field: "studentProfile",
                    columnIndex: 32,
                    render: (studentProfile) => studentProfile?.lastName
                },
                {
                    title: t("payments.column.description"),
                    field: "extraDescription",
                    columnIndex: 5
                },
                {
                    title: t("payments.column.id"),
                    field: "paymentId",
                    columnIndex: 6,
                    sortable: true
                },
                {
                    title: t("payments.column.amount"),
                    field: "amountInCents",
                    columnIndex: 7,
                    sortable: true,
                    style: {textAlign: "right"},
                    render: (amountInCents: number, record: StudentPayment) =>
                        formatCurrency(amountInCents / 100, record.currency, {currencyDisplay: "symbol"}),
                    renderText: (amountInCents: number) => Number(amountInCents / 100).toFixed(2)
                },
                {
                    title: t("payments.column.methodOfPayment"),
                    field: "methodOfPayment",
                    columnIndex: 8,
                    sortable: true,
                    render: (methodOfPayment: string) =>
                        PAYMENT_METHOD_OPTIONS.find((option) => option.id === methodOfPayment)?.name ||
                        methodOfPayment ||
                        ""
                },
                {
                    title: t("payments.column.transactionType"),
                    field: "account",
                    columnIndex: 4,
                    render: (account: LedgerAccount | undefined, payment: StudentPayment) => {
                        if (!isNinetyTenReport) {
                            return account?.name
                        }

                        let totalFedFunds = 0,
                            totalNonFedFunds = 0,
                            totalOtherFunds = 0,
                            totalExcludedFunds = 0
                        const associatedAccounts: LedgerAccount[] = []

                        function countNinetyTenFunds(amount: number, account?: LedgerAccount) {
                            if (account) {
                                if (account.ninetyTenFedFundsPercentage) {
                                    totalFedFunds += (amount * account.ninetyTenFedFundsPercentage) / 100
                                }
                                if (account.ninetyTenNonFedFundsPercentage) {
                                    totalNonFedFunds += (amount * account.ninetyTenNonFedFundsPercentage) / 100
                                }
                                if (account.ninetyTenOtherPercentage) {
                                    totalOtherFunds += (amount * account.ninetyTenOtherPercentage) / 100
                                }
                                if (account.ninetyTenExcludePercentage) {
                                    totalExcludedFunds += (amount * account.ninetyTenExcludePercentage) / 100
                                }
                            } else {
                                totalExcludedFunds += amount
                            }
                        }

                        const paymentAmount = payment.amountInCents / 100
                        if (payment.paymentCharges?.length) {
                            for (const paymentCharge of payment.paymentCharges) {
                                const account = ledgerAccounts.find(
                                    (account) => account.accountId === paymentCharge.accountId
                                )
                                associatedAccounts.push(account)
                                countNinetyTenFunds(paymentCharge.amount, account)
                            }
                            const chargesAmount = sumBy(payment.paymentCharges, "amount")
                            if (paymentAmount > chargesAmount) {
                                countNinetyTenFunds(paymentAmount - chargesAmount, account)
                            }
                        } else if (payment.paymentPlanItems?.length) {
                            for (const paymentPlanItem of payment.paymentPlanItems) {
                                const account = ledgerAccounts.find(
                                    (account) => account.accountId === paymentPlanItem.accountId
                                )
                                associatedAccounts.push(account)
                                countNinetyTenFunds(paymentPlanItem.amount, account)
                            }
                            const paymentPlanItemsAmount = sumBy(payment.paymentPlanItems, "amount")
                            if (paymentAmount > paymentPlanItemsAmount) {
                                countNinetyTenFunds(paymentAmount - paymentPlanItemsAmount, account)
                            }
                        } else {
                            countNinetyTenFunds(paymentAmount, account)
                        }

                        return (
                            <>
                                <div className={styles.accountName}>{account?.name}</div>
                                {uniqBy(associatedAccounts.filter(Boolean), "accountId").map((account, index) => (
                                    <div key={index} className={styles.accountName}>
                                        {account.name}
                                    </div>
                                ))}
                                {!!totalFedFunds && (
                                    <div className={styles.fundType}>
                                        <b>
                                            {formatCurrency(totalFedFunds, payment.currency, {
                                                currencyDisplay: "symbol"
                                            })}
                                        </b>
                                        <br />
                                        <i>
                                            {t(
                                                `settings.ledgerAccounts.chartOfAccounts.fundTypeLabel.${LedgerAccountFundType.FederalFunds}`
                                            )}
                                        </i>
                                    </div>
                                )}
                                {!!totalNonFedFunds && (
                                    <div className={styles.fundType}>
                                        <b>
                                            {formatCurrency(totalNonFedFunds, payment.currency, {
                                                currencyDisplay: "symbol"
                                            })}
                                        </b>
                                        <br />
                                        <i>
                                            {t(
                                                `settings.ledgerAccounts.chartOfAccounts.fundTypeLabel.${LedgerAccountFundType.NonFederalFunds}`
                                            )}
                                        </i>
                                    </div>
                                )}
                                {!!totalOtherFunds && (
                                    <div className={styles.fundType}>
                                        <b>
                                            {formatCurrency(totalOtherFunds, payment.currency, {
                                                currencyDisplay: "symbol"
                                            })}
                                        </b>
                                        <br />
                                        <i>
                                            {t(
                                                `settings.ledgerAccounts.chartOfAccounts.fundTypeLabel.${LedgerAccountFundType.Other}`
                                            )}
                                        </i>
                                    </div>
                                )}
                                {!!totalExcludedFunds && (
                                    <div className={styles.fundType}>
                                        <b>
                                            {formatCurrency(totalExcludedFunds, payment.currency, {
                                                currencyDisplay: "symbol"
                                            })}
                                        </b>
                                        <br />
                                        <i>
                                            {t(
                                                `settings.ledgerAccounts.chartOfAccounts.fundTypeLabel.${LedgerAccountFundType.NotIncluded}`
                                            )}
                                        </i>
                                    </div>
                                )}
                            </>
                        )
                    },
                    renderText: (account?: LedgerAccount) => account?.name
                },
                {
                    title: t("payments.column.fundSource"),
                    field: "account",
                    columnIndex: 21,
                    render: (account?: LedgerAccount) => account?.fundSource?.title ?? ""
                },
                {
                    title: t("payments.column.charge"),
                    field: "charges",
                    columnIndex: 9,
                    style: {minWidth: "150px"},
                    render: (charges: StudentCharge[] | undefined, record: StudentPayment) => {
                        const amountDue = getPaymentAmountDue(record, currencyExchangeRates)
                        return (
                            <>
                                {!!charges?.length && (
                                    <div className={styles.chargeList}>
                                        {charges?.map((charge) => (
                                            <div key={charge.chargeId}>
                                                <span className={styles.chargeId}>#{charge.chargeId}</span>
                                                {charge.description}
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {record.status === "success" && amountDue > 0 && !isNinetyTenReport && (
                                    <div
                                        className={styles.assignChargeBtn}
                                        onClick={(e) => {
                                            setSelectedPaymentId(record.paymentId)
                                            assignChargesPopup.open()
                                            e.stopPropagation()
                                        }}>
                                        {t("payments.assignChargeAmount", {amountDue: formatCurrency(amountDue)})}
                                    </div>
                                )}
                            </>
                        )
                    },
                    renderText: (charges?: StudentCharge[]) =>
                        (charges ?? []).map((charge) => `#${charge.chargeId} ${charge.description}`).join(", ")
                },
                {
                    title: t("payments.column.paymentPeriod"),
                    field: "paymentPeriodStartDate",
                    columnIndex: 10,
                    render: (_, record: StudentPayment) =>
                        record.paymentPeriodStartDate
                            ? `${moment(toDateOnly(record.paymentPeriodStartDate)).format(userDateFormat)} - ${moment(
                                  toDateOnly(record.paymentPeriodEndDate)
                              ).format(userDateFormat)}`
                            : ""
                },
                {
                    title: t("payments.column.receiptId"),
                    field: "receiptId",
                    columnIndex: 11,
                    sortable: true
                },
                {
                    title: t("payments.column.academicYear"),
                    field: "academicYear",
                    columnIndex: 12,
                    render: (year?: number) => (year ? `${year} - ${year + 1}` : "")
                },
                {
                    title: t("payments.column.awardYear"),
                    field: "awardYear",
                    columnIndex: 13,
                    render: (year?: number) => (year ? `${year} - ${year + 1}` : "")
                },
                {
                    title: t("payments.column.status"),
                    field: "status",
                    columnIndex: 14,
                    render: (status) => (
                        <div className={classNames(styles.status, status)}>{t(`payments.status.${status}`)}</div>
                    ),
                    renderText: (status) => t(`payments.status.${status}`)
                },
                {
                    title: t("payments.column.notes"),
                    titleHtml: <span />,
                    columnIndex: 15,
                    isHideTitle: true,
                    field: "notes",
                    render: (notes?: string) =>
                        !!notes &&
                        (notes.length < 50 ? (
                            notes
                        ) : (
                            <Popover trigger="click" title="Notes" content={notes} overlayClassName={styles.notes}>
                                <span onClick={(e) => e.stopPropagation()}>
                                    <Icon icon="MESSAGE_CIRCLE_LINE" />
                                </span>
                            </Popover>
                        )),
                    renderText: (notes) => notes || ""
                }
            ].filter(Boolean),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [t, userDateFormat, isNinetyTenReport, ledgerAccounts]
    )

    const getTableHeaderActions = React.useCallback(
        (isShowDuplicateBtn = true, checkedData: StudentPayment[] = []) => {
            const actions = [
                {
                    title: t("common:action.delete"),
                    icon: "DELETE",
                    action: () => onClickShowConfirmModal("DELETE")
                }
            ]
            if (checkedData?.length === 1) {
                actions.unshift({
                    title: t("common:action.edit"),
                    icon: "EDIT",
                    action: () => onClickEdit(checkedData[0])
                })
            }
            return actions
        },
        [t, onClickShowConfirmModal, onClickEdit]
    )

    const getMenuActions = React.useCallback(() => {
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: onClickEdit,
                canShow: (item: StudentPayment) => true
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: onClickDelete,
                canShow: (item: StudentPayment) => true
            }
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t])

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getFilterMemoryKey", func: () => filterKey},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "getTableHeaderActions", func: getTableHeaderActions},
            {key: "getMenuActions", func: getMenuActions},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatchFunc([{key: "getColumns", func: getColumns}])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getColumns])

    useEffect(() => {
        dispatchFunc([{key: "getFields", func: getFields}])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFields])

    useEffect(() => {
        model.updateStorageFilter(filterKey, {search, filter, page: 1})
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, filterKey, filterSeq, page, pageSize, orderField?.field, orderField?.order])

    const closeAssignCharges = React.useCallback(() => {
        assignChargesPopup.close()
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const exportMutation = useMutation(
        async (type: ExportFileType) => {
            const {data} = await studentPaymentService.getPayments({
                filter: {
                    ...getFilters(filter),
                    search,
                    studentProfileId: profileId,
                    status: isNinetyTenReport ? "success" : undefined
                },
                range: {
                    pageSize: UNLIMITED_PAGE_SIZE,
                    page: 1
                },
                sort: {
                    orderBy: orderField?.field ?? "tranxDate",
                    orderDir: orderField?.order ?? Order.Desc
                },
                linkedEntities: true
            })

            const payload = {
                filename: "student-payments",
                columns: columns.map((col) => col.title),
                rows: data.map((item) =>
                    columns.map((col) => {
                        if (col.fieldType === "date") {
                            const formatDateUtc = model.getUserDateFormat()
                            return get(item, col.field) ? moment.utc(get(item, col.field)).format(formatDateUtc) : ""
                        }
                        if (col.fieldType === "dateUtc") {
                            const formatDateUtc = model.getUserDateFormat() || "MM/DD/YYYY"
                            return item[col.field] ? moment.utc(item[col.field]).format(formatDateUtc) : ""
                        }
                        const value = col.renderText
                            ? col.renderText(get(item, col.field), item)
                            : col.render
                            ? col.render(get(item, col.field), item)?.toString?.()
                            : get(item, col.field)?.toString()
                        return (type === "csv" ? value?.replaceAll(/[^a-zA-Z0-9. -]/g, "") : value) || ""
                    })
                )
            }

            if (type === "csv") {
                exportCsv(payload)
            } else if (type === "excel") {
                exportExcel(payload)
            } else if (type === "pdf") {
                exportPdf(payload)
            }
        },
        {
            onError: (error) => handleError(error)
        }
    )

    const fundTypeOptions = React.useMemo(
        () =>
            Object.values(LedgerAccountFundType).map((type) => ({
                id: type,
                name: t(`settings.ledgerAccounts.chartOfAccounts.fundTypeLabel.${type}`)
            })),
        []
    )

    return (
        <div className={styles.wrap}>
            <BaseNewFilter
                filter={filter}
                onClick={onApplyFilter}
                onClickClear={onClearFilter}
                renderRightFilter={() => (
                    <div className="flex justify-end items-center">
                        <ExportButton
                            onSelect={exportMutation.mutate}
                            availableFileTypes={["csv", "excel"]}
                            isLoading={exportMutation.isLoading}
                        />
                        {!!profileId && (
                            <BaseButton
                                title={t("common:action.add")}
                                className="ml-24"
                                onClick={() => {
                                    history.push(routing.studentAccount.student.ledger.payment.detail(profileId, "new"))
                                }}
                            />
                        )}
                    </div>
                )}
                searchValue={search}
                onSearchInput={onSearchInput}>
                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        <BaseRangePicker
                            placeholder={["Date From", "To"]}
                            value={
                                filter.tranxDates ? [moment(filter.tranxDates[0]), moment(filter.tranxDates[1])] : null
                            }
                            onChange={(value) => onChangeFilter("tranxDates", value)}
                        />
                    </Col>
                    <Col span={12}>
                        <KlassDropdown
                            isMulti={true}
                            isClearable={true}
                            placeholder="Method of Payment"
                            options={PAYMENT_METHOD_OPTIONS}
                            value={filter.methodOfPayments}
                            onChange={(options) => onChangeFilter("methodOfPayments", options || [])}
                        />
                    </Col>
                    <Col span={12}>
                        <PaymentPeriodSelect
                            placeholder="Payment Period"
                            isClearable={true}
                            value={filter.paymentPeriod}
                            onChange={(option) => onChangeFilter("paymentPeriod", option)}
                        />
                    </Col>
                    <Col span={12}>
                        <LedgerAccountSelect
                            placeholder="Transaction Type"
                            active={true}
                            type={LedgerAccountType.Payments}
                            isClearable={true}
                            isMulti={true}
                            value={filter.accounts}
                            onChange={(options?: LedgerAccount[]) => onChangeFilter("accounts", options || [])}
                        />
                    </Col>
                    {isNinetyTenReport && (
                        <Col span={12}>
                            <KlassDropdown
                                isMulti={true}
                                isClearable={true}
                                placeholder="90/10 Fund Type"
                                options={fundTypeOptions}
                                value={filter.fundTypes}
                                onChange={(options) => onChangeFilter("fundTypes", options ?? [])}
                            />
                        </Col>
                    )}
                </Row>
            </BaseNewFilter>

            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={onChangePage}
                onChangeRowPerPage={onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={onChangeFields}
                onChangeAllFields={onChangeAllFields}
                onDraggableColumn={onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                data={data}
                isLoading={isLoading}
                menuActions={isHideMenuActions ? [] : menuActions}
                fields={fields}
                allFields={allFields}
                orderField={orderField}
                isShowCheckedColumn
                onClickRowItem={onClickRowItem}
                onChangeFields={onChangeFields}
                onClickSortColumn={onClickSortColumn}
                onDraggableColumn={onDraggableColumn}
                onChangeAllFields={onChangeAllFields}
                onUpdateRowData={onUpdateRowData}
                onUpdateTableData={onUpdateTableData}
            />

            {!!profileId && (
                <AssignChargesPopup
                    isShow={assignChargesPopup.isVisible}
                    onClose={closeAssignCharges}
                    profileId={profileId}
                    paymentId={selectedPaymentId}
                />
            )}
        </div>
    )
}

export default KlassappTableHOC(PaymentsTable)
