/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useContext, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import moment from "moment"
import {useHistory} from "react-router-dom"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {studentLedgerService} from "services"
import {useModel} from "hooks"
import {Col, Popover, Row} from "antd"
import {routing} from "helpers/routing"
import {formatCurrency, handleError, toastError} from "helpers"
import {DeepPartial, KlassappTableProps, Order, PaginationPerPage} from "types/common"
import {BaseButton, Icon} from "components"
import styles from "./ChargesTable.module.css"
import classNames from "classnames"
import {StudentCharge, StudentChargeStatus} from "types/student-account/student-ledger"
import {TermDetails} from "types/terms"
import {getPaymentAmountDue, StudentPayment} from "types/student-account/student-payment"
import {preventMinusCharacter} from "helpers/inputHelper"
import {StudentPaymentContext} from "context/StudentPaymentContext"
import {get, isEmpty, sumBy} from "lodash"
import NumberFormat from "react-number-format"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import ChargePaymentsTable from "sections/StudentAccount/Student/parts/StudentLedgerTab/parts/PaymentsTab/PaymentsTable/ChargePaymentsTable"
import {toDateOnly} from "sections/academics/instructional/common/utils"
import {FilterKey} from "types/filter"
import PaymentPeriodSelect from "components/PaymentPeriodSelect"
import LedgerAccountSelect from "components/LedgerAccountSelect"
import {LedgerAccount, LedgerAccountType} from "types/student-account/ledger-accounts"
import {BaseRangePicker} from "components/DateTimePicker"
import ProgramSelect from "components/ProgramSelect"
import CampusSelect from "components/CampusSelect"
import {ExportButton, ExportFileType} from "components/ui"
import {useMutation} from "@tanstack/react-query"
import {exportCsv, exportExcel} from "helpers/export-table"

interface Props extends KlassappTableProps {
    profileId?: number
    assignPayment?: DeepPartial<StudentPayment>
    selectedCharges?: StudentCharge[]
    setSelectedCharges?: React.Dispatch<React.SetStateAction<StudentCharge[]>>
    isReport?: boolean
}

const ChargesTable: React.FC<Props> = ({
    dispatch,
    dispatchFunc,
    page,
    total,
    pageSize,
    columns,
    data,
    allFields,
    fields,
    tableHeaderActions,
    isLoading,
    menuActions,
    isHideMenuActions,
    isShowTableHeaderAction,
    isLoadedTableFuncs,
    onClickShowConfirmModal,
    getCurrentData,
    getCurrentPage,
    getCurrentPageSize,
    onChangePage,
    onChangeRowPerPage,
    onChangeFields,
    onDraggableColumn,
    onChangeAllFields,
    onUpdateTableData,
    profileId,
    assignPayment,
    selectedCharges,
    setSelectedCharges,
    isReport = false
}) => {
    const history = useHistory()
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const filterKey = assignPayment ? FilterKey.StudentAccountAssignPayments : FilterKey.StudentAccountStudentCharges
    const storageData = model.getStorageFilter(filterKey)
    const {currencyExchangeRates} = useContext(StudentPaymentContext)
    const {t} = useTranslation(["studentAccount", "common"])

    const [chargesForAssignment, setChargesForAssignment] = useState<StudentCharge[]>([])

    // region filter

    const initialFilter = {
        programs: [],
        campuses: [],
        accounts: [],
        paymentPeriod: null,
        tranxDates: undefined
    }
    const [search, setSearch] = useState(!isEmpty(storageData) ? storageData.search : "")
    const [filter, setFilter] = useState(!isEmpty(storageData?.filter) ? storageData.filter : initialFilter)
    const [appliedFilter, setAppliedFilter] = useState(filter)

    const onChangeFilter = useCallback((key: keyof typeof filter, value: any) => {
        setFilter((prev) => ({...prev, [key]: value}))
    }, [])

    const onClearFilter = useCallback(() => {
        setFilter(initialFilter)
        setAppliedFilter(initialFilter)
    }, [])

    const onApplyFilter = useCallback(() => {
        setAppliedFilter(filter)
        model.updateStorageFilter(filterKey, {filter})
    }, [filter])

    const onSearchInput = useCallback((search = "") => {
        setSearch(search)
        model.updateStorageFilter(filterKey, {search})
    }, [])

    // endregion filter

    const getFilters = useCallback(() => {
        return {
            search,
            profileIds: profileId ? [profileId] : undefined,
            accountIds: appliedFilter.accounts?.length
                ? appliedFilter.accounts.map((account) => account.accountId)
                : undefined,
            campusIds: appliedFilter.campuses?.length ? appliedFilter.campuses.map((campus) => campus.id) : undefined,
            programIds: appliedFilter.programs?.length
                ? appliedFilter.programs.map((program) => program.id)
                : undefined,
            paymentPeriodStartDate: appliedFilter.paymentPeriod
                ? moment(appliedFilter.paymentPeriod.start).startOf("day").format("YYYY-MM-DD")
                : undefined,
            paymentPeriodEndDate: appliedFilter.paymentPeriod
                ? moment(appliedFilter.paymentPeriod.end).endOf("day").format("YYYY-MM-DD")
                : undefined,
            tranxDates: appliedFilter.tranxDates
        }
    }, [profileId, search, appliedFilter])

    const getData = React.useCallback(async () => {
        try {
            dispatch({isLoading: true})
            const page = getCurrentPage()
            const pageSize = getCurrentPageSize()
            if (assignPayment) {
                dispatch({
                    total: chargesForAssignment.length,
                    data: chargesForAssignment.slice((page - 1) * pageSize, page * pageSize).map((charge) => {
                        const selectedCharge = selectedCharges?.find(({chargeId}) => chargeId === charge.chargeId)
                        return {
                            ...charge,
                            id: charge.chargeId,
                            isChecked: !!selectedCharge,
                            isActiveTableCol: !!selectedCharge,
                            paymentAmount: selectedCharge?.paymentAmount ?? charge.paymentAmount,
                            paymentAccountId: selectedCharge?.paymentAccountId ?? charge.paymentAccountId
                        }
                    })
                })
                return
            }

            const {total, data} = await studentLedgerService.getCharges({
                filter: getFilters(),
                range: {pageSize, page},
                sort: {
                    orderBy: "tranxDate",
                    orderDir: Order.Desc
                },
                linkedEntities: true
            })
            dispatch({
                total,
                data: data.map((charge) => {
                    const selectedCharge = selectedCharges?.find(({chargeId}) => chargeId === charge.chargeId)
                    return {
                        ...charge,
                        id: charge.chargeId,
                        isChecked: !!selectedCharge,
                        isActiveTableCol: !!selectedCharge,
                        paymentAmount: selectedCharge?.paymentAmount ?? charge.paymentAmount,
                        paymentAccountId: selectedCharge?.paymentAccountId ?? charge.paymentAccountId
                    }
                })
            })
        } catch (error) {
            console.error(error)
        } finally {
            dispatch({isLoading: false})
        }
    }, [getFilters, !!assignPayment, chargesForAssignment, selectedCharges, isReport])

    const onClickRowItem = React.useCallback(
        ({profileId, chargeId}: StudentCharge) => {
            if (isReport || setSelectedCharges) return
            history.push(routing.studentAccount.student.ledger.charge.detail(profileId, chargeId))
        },
        [isReport, setSelectedCharges]
    )

    const onClickEdit = React.useCallback(({profileId, chargeId}: StudentCharge) => {
        history.push(routing.studentAccount.student.ledger.charge.detail(profileId, chargeId))
    }, [])

    const onClickDelete = React.useCallback(
        async (deletedItem: StudentCharge) => {
            if (deletedItem.isLock) {
                toastError(t("common:message.cantDeleteLockedItem"))
                return
            }
            try {
                dispatch({isLoading: true})
                await studentLedgerService.deleteCharges([deletedItem.chargeId])
                await getData()
            } catch (e) {
                handleError(e)
            } finally {
                dispatch({isLoading: false})
            }
        },
        [getData]
    )

    const onClickDeleteMulti = React.useCallback(async () => {
        const checkedItems = getCurrentData().filter((item) => item.isChecked)
        if (checkedItems.find((item) => item.isLock)) {
            toastError(t("common:message.cantDeleteLockedItem"))
            return
        }
        try {
            dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await studentLedgerService.deleteCharges(checkedItems.map((item) => item.chargeId))
            await getData()
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }, [getData])

    const getFields = React.useCallback(() => {
        const fields = [
            t("charges.column.date"),
            t("charges.column.id"),
            t("charges.column.charge"),
            t("charges.column.description"),
            t("charges.column.originalAmount"),
            t("charges.column.paid"),
            t("charges.column.amountDue"),
            t("charges.column.paymentPeriod")
        ]
        if (assignPayment) {
            fields.push(t("charges.column.payment"))
            fields.push(t("charges.column.transactionType"))
        } else {
            fields.push(t("charges.column.status"))
        }
        fields.push(t("charges.column.notes"))
        return fields
    }, [!!assignPayment])

    const getColumns = React.useCallback(
        () => [
            {
                title: t("charges.column.date"),
                field: "tranxDate",
                columnIndex: 1,
                render: (date: string) => moment(date).format(userDateFormat)
            },
            {
                title: t("charges.column.id"),
                field: "chargeId",
                columnIndex: 2
            },
            {
                title: t("charges.column.charge"),
                field: "accountId",
                columnIndex: 3,
                render: (_, record: StudentCharge) => (
                    <div className={styles.nameWrap}>
                        {!!record.isLock && (
                            <span className={styles.lockIconWrap}>
                                <Icon icon="LOCK_CHECKMARK" />
                            </span>
                        )}
                        <span>{record.account?.name || record.customAccountName}</span>
                    </div>
                ),
                renderText: (_, record: StudentCharge) => record.account?.name || record.customAccountName
            },
            {
                title: t("charges.column.academicYear"),
                field: "academicYear",
                columnIndex: 4,
                render: (year?: number) => (year ? `${year} - ${year + 1}` : "")
            },
            {
                title: t("charges.column.awardYear"),
                field: "awardYear",
                columnIndex: 5,
                render: (year?: number) => (year ? `${year} - ${year + 1}` : "")
            },
            {
                title: t("charges.column.term"),
                field: "term",
                columnIndex: 6,
                render: (term?: TermDetails) => term?.name
            },
            {
                title: t("charges.column.description"),
                field: "description",
                columnIndex: 7,
                render: (description: string, record: StudentCharge) => {
                    if (description) return description
                    if (record.paymentPlan) return `Payment Plan ${record.paymentPlan.code}`
                    return ""
                }
            },
            {
                title: t("charges.column.originalAmount"),
                field: "amount",
                columnIndex: 8,
                render: (value) => formatCurrency(value),
                renderText: (value: number) => (value ?? 0).toFixed(2)
            },
            {
                title: t("charges.column.paid"),
                field: "paidAmount",
                columnIndex: 9,
                render: (value) => formatCurrency(value),
                renderText: (value: number) => (value ?? 0).toFixed(2)
            },
            {
                title: t("charges.column.amountDue"),
                field: "paidAmount",
                columnIndex: 10,
                render: (_, record: StudentCharge) => formatCurrency(Math.max(0, record.amount - record.paidAmount)),
                renderText: (_, record: StudentCharge) => Math.max(0, record.amount - record.paidAmount).toFixed(2)
            },
            {
                title: t("charges.column.payment"),
                field: "paymentAmount",
                columnIndex: 11,
                render: (_, record: StudentCharge & {isChecked?: boolean}) =>
                    !record.isChecked ? null : (
                        <NumberFormat
                            prefix="$"
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            className={styles.input}
                            onKeyDown={preventMinusCharacter}
                            min={1}
                            placeholder={t(t("charges.amount"))}
                            value={record.paymentAmount}
                            onValueChange={({floatValue}) => {
                                dispatch({
                                    data: getCurrentData().map((item: StudentCharge) =>
                                        item.chargeId === record.chargeId ? {...item, paymentAmount: floatValue} : item
                                    )
                                })
                                setSelectedCharges?.((prev) =>
                                    prev.map((charge) =>
                                        charge.chargeId !== record.chargeId
                                            ? charge
                                            : {...charge, paymentAmount: floatValue}
                                    )
                                )
                            }}
                        />
                    )
            },
            {
                title: t("charges.column.transactionType"),
                field: "paymentAccountId",
                columnIndex: 11,
                render: (_, record: StudentCharge & {isChecked?: boolean}) =>
                    !record.isChecked ? null : (
                        <LedgerAccountSelect
                            placeholder={t("charges.column.transactionType")}
                            active={true}
                            type={LedgerAccountType.Payments}
                            isClearable={true}
                            isMulti={false}
                            value={record.paymentAccountId}
                            onChange={(account?: LedgerAccount) => {
                                dispatch({
                                    data: getCurrentData().map((item) =>
                                        item.chargeId === record.chargeId
                                            ? {...item, paymentAccountId: account?.accountId ?? null}
                                            : item
                                    )
                                })
                                setSelectedCharges?.((prev) =>
                                    prev.map((charge) =>
                                        charge.chargeId !== record.chargeId
                                            ? charge
                                            : {...charge, paymentAccountId: account?.accountId ?? null}
                                    )
                                )
                            }}
                            components={{
                                ClearIndicator: () => <div className="w-20" />,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null
                            }}
                            menuPortalTarget={document.body}
                            stylesCustom={{
                                menuPortal: (styles) => ({
                                    ...styles,
                                    zIndex: 9999
                                }),
                                menu: (styles) => ({
                                    ...styles,
                                    width: "max-content",
                                    minWidth: "100%",
                                    maxWidth: "450px",
                                    right: 0
                                })
                            }}
                        />
                    )
            },
            {
                title: t("charges.column.paidDate"),
                field: "paidDate",
                columnIndex: 12,
                render: (date) => (date ? moment(toDateOnly(date)).format(userDateFormat) : "")
            },
            {
                title: t("charges.column.dueDate"),
                field: "dueDate",
                columnIndex: 13,
                render: (date) => (date ? moment(toDateOnly(date)).format(userDateFormat) : "")
            },
            {
                title: t("charges.column.paymentPeriod"),
                field: "paymentPeriodStartDate",
                columnIndex: 14,
                render: (_, record: StudentCharge) =>
                    record.paymentPeriodStartDate
                        ? `${moment(toDateOnly(record.paymentPeriodStartDate)).format(userDateFormat)} - ${moment(
                              toDateOnly(record.paymentPeriodEndDate)
                          ).format(userDateFormat)}`
                        : ""
            },
            {
                title: t("charges.column.status"),
                field: "status",
                columnIndex: 15,
                render: (value) => (
                    <div className={classNames(styles.status, value)}>{t(`charges.status.${value}`)}</div>
                ),
                renderText: (value) => t(`charges.status.${value}`)
            },
            {
                title: t("charges.column.notes"),
                field: "notes",
                columnIndex: 16,
                render: (notes?: string) =>
                    !!notes &&
                    (notes.length < 50 ? (
                        notes
                    ) : (
                        <Popover trigger="click" title="Notes" content={notes} overlayClassName={styles.notes}>
                            <span onClick={(e) => e.stopPropagation()}>
                                <Icon icon="MESSAGE_CIRCLE_LINE" />
                            </span>
                        </Popover>
                    )),
                renderText: (notes?: string) => notes
            }
        ],
        [t, userDateFormat]
    )

    const getTableHeaderActions = React.useCallback(
        (isShowDuplicateBtn = true, checkedData = []) => {
            if (assignPayment) return []
            const hasLockedItem = (checkedData || []).some((item: StudentCharge) => item.isLock)
            const actions = []
            if (checkedData?.length === 1) {
                actions.push({
                    title: t("common:action.edit"),
                    icon: "EDIT",
                    action: () => onClickEdit(checkedData[0])
                })
            }
            if (!hasLockedItem) {
                actions.push({
                    title: t("common:action.delete"),
                    icon: "DELETE",
                    action: () => onClickShowConfirmModal("DELETE")
                })
            }
            return actions
        },
        [t, onClickShowConfirmModal, onClickEdit, !!assignPayment]
    )

    const getMenuActions = React.useCallback(() => {
        if (assignPayment) return []
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: onClickEdit,
                canShow: (item: StudentCharge) => true
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: onClickDelete,
                canShow: (item: StudentCharge) => !item.isLock
            }
        ]
    }, [t, !!assignPayment])

    const handleUpdateRowData = React.useCallback(
        (updatedRow: StudentCharge & {isChecked: boolean}) => {
            const otherCheckedItems = selectedCharges?.filter((charge) => charge.chargeId !== updatedRow.chargeId) ?? []
            const newData = getCurrentData().map((charge: StudentCharge) => {
                if (charge.chargeId !== updatedRow.chargeId) {
                    return charge
                }
                let paymentAmount: number
                let paymentAccountId: number
                if (!updatedRow.isChecked) {
                    paymentAmount = undefined
                    paymentAccountId = undefined
                } else if (assignPayment) {
                    const paymentAmountDue = Math.max(
                        0,
                        getPaymentAmountDue(assignPayment, currencyExchangeRates, false) -
                            sumBy(otherCheckedItems, "paymentAmount")
                    )
                    const chargeAmountDue = Math.max(0, updatedRow.amount - updatedRow.paidAmount)
                    paymentAmount = Math.min(chargeAmountDue, paymentAmountDue)
                    paymentAccountId = updatedRow.paymentAccountId
                }
                return {
                    ...updatedRow,
                    isActiveTableCol: !!updatedRow.isChecked,
                    paymentAmount,
                    paymentAccountId
                }
            })
            dispatch({data: newData})
            setSelectedCharges?.(
                !updatedRow.isChecked
                    ? otherCheckedItems
                    : [...otherCheckedItems, newData.find((item) => item.chargeId === updatedRow.chargeId)]
            )
        },
        [currencyExchangeRates, assignPayment, selectedCharges]
    )

    useEffect(() => {
        if (!assignPayment || isReport) return
        ;(async function getChargesForAssignment() {
            const {data} = await studentLedgerService.getCharges({
                filter: {
                    statuses: [StudentChargeStatus.Pending, StudentChargeStatus.InProgress],
                    includeChargeIds: assignPayment?.paymentId
                        ? assignPayment.paymentCharges?.map((paymentCharge) => paymentCharge.chargeId)
                        : undefined,
                    profileIds: [profileId]
                },
                range: {page: 1, pageSize: UNLIMITED_PAGE_SIZE},
                sort: {
                    orderBy: "tranxDate",
                    orderDir: Order.Asc
                },
                linkedEntities: true
            })
            const processedData = data.map((charge) => {
                const paymentCharge = assignPayment.paymentCharges?.find(
                    (paymentCharge) => paymentCharge.chargeId === charge.chargeId
                )
                return {
                    ...charge,
                    id: charge.chargeId,
                    paymentAmount: paymentCharge?.amount,
                    paymentAccountId: paymentCharge?.accountId
                }
            })
            const selectedCharges = processedData.filter((charge) =>
                assignPayment.paymentCharges?.find((paymentCharge) => paymentCharge.chargeId === charge.chargeId)
            )
            setChargesForAssignment(processedData)
            setSelectedCharges?.(selectedCharges)
            const page = getCurrentPage()
            const pageSize = getCurrentPageSize()
            dispatch({
                total: processedData.length,
                data: processedData.slice((page - 1) * pageSize, page * pageSize).map((charge) => {
                    const selectedCharge = selectedCharges?.find(({chargeId}) => chargeId === charge.chargeId)
                    return {
                        ...charge,
                        id: charge.chargeId,
                        isChecked: !!selectedCharge,
                        isActiveTableCol: !!selectedCharge,
                        paymentAmount: selectedCharge?.paymentAmount ?? charge.paymentAmount,
                        paymentAccountId: selectedCharge?.paymentAccountId ?? charge.paymentAccountId
                    }
                })
            })
        })()
    }, [profileId, assignPayment?.paymentId, isReport])

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: () => t("charges.title")},
            {key: "getFilterMemoryKey", func: () => filterKey},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getTableHeaderActions", func: getTableHeaderActions},
            {key: "getMenuActions", func: getMenuActions},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
        setTimeout(() => {
            if (assignPayment) {
                onChangeRowPerPage(PaginationPerPage.item10)
            }
        }, 500)
    }, [])

    useEffect(() => {
        dispatchFunc([
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
    }, [getColumns, getFields])

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getData()
        }
    }, [isLoadedTableFuncs, page, pageSize, search, appliedFilter, profileId, assignPayment?.paymentId, isReport])

    // TODO when student payment amount is changed, auto re-assign payment amount for top charges
    useEffect(() => {
        if (!assignPayment) return

        const paymentAmountDue = getPaymentAmountDue(assignPayment, currencyExchangeRates, false)
        const newData = getCurrentData()
        newData.forEach((charge: StudentCharge, index: number) => {
            const chargeAmountDue = Math.max(0, charge.amount - charge.paidAmount)
            const paymentAmount = Math.min(
                chargeAmountDue,
                Math.max(0, paymentAmountDue - sumBy(newData.slice(0, index), "paymentAmount"))
            )
            newData[index] = {
                ...charge,
                paymentAmount,
                isChecked: !!paymentAmount,
                isActiveTableCol: !!paymentAmount
            }
        })
        dispatch({data: newData})
        setSelectedCharges?.(newData.filter((row) => row.isChecked))
    }, [assignPayment?.amount])

    const exportMutation = useMutation(
        async (type: ExportFileType) => {
            const {data} = await studentLedgerService.getCharges({
                filter: getFilters(),
                range: {pageSize: UNLIMITED_PAGE_SIZE, page: 1},
                sort: {
                    orderBy: "tranxDate",
                    orderDir: Order.Desc
                },
                linkedEntities: true
            })
            const payload = {
                filename: "Student Charges Report",
                columns: columns.map((col) => col.title),
                rows: data.map((item) =>
                    columns.map((col) => {
                        if (col.fieldType === "date") {
                            const formatDateUtc = model.getUserDateFormat()
                            return get(item, col.field) ? moment.utc(get(item, col.field)).format(formatDateUtc) : ""
                        }
                        if (col.fieldType === "dateUtc") {
                            const formatDateUtc = model.getUserDateFormat() || "MM/DD/YYYY"
                            return item[col.field] ? moment.utc(item[col.field]).format(formatDateUtc) : ""
                        }
                        const value = col.renderText
                            ? col.renderText(get(item, col.field), item)
                            : col.render
                            ? col.render(get(item, col.field), item)?.toString?.()
                            : get(item, col.field)?.toString()
                        return (type === "csv" ? value?.replaceAll(/[^a-zA-Z0-9. -]/g, "") : value) || ""
                    })
                )
            }
            if (type === "csv") {
                exportCsv(payload)
            } else if (type === "excel") {
                exportExcel(payload)
            }
        },
        {
            onError: (error) => handleError(error)
        }
    )

    return (
        <div className={styles.wrap}>
            {!assignPayment && (
                <BaseNewFilter
                    className="mb-24"
                    filter={filter}
                    onClick={onApplyFilter}
                    onClickClear={onClearFilter}
                    renderRightFilter={() =>
                        assignPayment ? null : (
                            <div className="flex justify-end items-center">
                                <ExportButton
                                    onSelect={exportMutation.mutate}
                                    availableFileTypes={["csv", "excel"]}
                                    isLoading={exportMutation.isLoading}
                                />
                                {!isReport && (
                                    <BaseButton
                                        title={t("common:action.add")}
                                        className="ml-24"
                                        onClick={() => {
                                            history.push(
                                                routing.studentAccount.student.ledger.charge.detail(profileId, "new")
                                            )
                                        }}
                                    />
                                )}
                            </div>
                        )
                    }
                    onSearchInput={onSearchInput}>
                    <Row gutter={[24, 24]}>
                        <Col span={12}>
                            <ProgramSelect
                                placeholder="Programs"
                                isMulti={true}
                                isActive={true}
                                value={filter.programs}
                                onChange={(options) => onChangeFilter("programs", options || [])}
                            />
                        </Col>
                        <Col span={12}>
                            <CampusSelect
                                placeholder="Campuses"
                                isMulti={true}
                                value={filter.campuses}
                                onChange={(options) => onChangeFilter("campuses", options || [])}
                            />
                        </Col>
                        <Col span={12}>
                            <BaseRangePicker
                                placeholder={["Date From", "To"]}
                                value={
                                    filter.tranxDates
                                        ? [moment(filter.tranxDates[0]), moment(filter.tranxDates[1])]
                                        : null
                                }
                                onChange={(value) => onChangeFilter("tranxDates", value)}
                            />
                        </Col>
                        <Col span={12}>
                            <PaymentPeriodSelect
                                placeholder="Payment Period"
                                isClearable={true}
                                value={filter.paymentPeriod}
                                onChange={(option) => onChangeFilter("paymentPeriod", option)}
                            />
                        </Col>
                        <Col span={12}>
                            <LedgerAccountSelect
                                placeholder="Transaction Type"
                                active={true}
                                type={LedgerAccountType.Charges}
                                isClearable={true}
                                isMulti={true}
                                value={filter.accounts}
                                onChange={(options?: LedgerAccount[]) => onChangeFilter("accounts", options || [])}
                            />
                        </Col>
                    </Row>
                </BaseNewFilter>
            )}

            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={onChangePage}
                onChangeRowPerPage={onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={onChangeFields}
                onChangeAllFields={onChangeAllFields}
                onDraggableColumn={onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                data={data}
                isLoading={isLoading}
                menuActions={isHideMenuActions ? [] : menuActions}
                fields={fields}
                allFields={allFields}
                isShowCheckedColumn
                onClickRowItem={onClickRowItem}
                onChangeFields={onChangeFields}
                onDraggableColumn={onDraggableColumn}
                onChangeAllFields={onChangeAllFields}
                onUpdateRowData={handleUpdateRowData}
                onUpdateTableData={onUpdateTableData}
                expandable={{
                    rowExpandable: (record: StudentCharge) =>
                        !assignPayment && !!record.payments?.filter((item) => item.status === "success").length,
                    expandedRowRender: (record: StudentCharge, expanded: boolean) =>
                        !expanded ? null : (
                            <div className={styles.paymentsContainer}>
                                <div className={styles.paymentTitle}>PAYMENTS</div>
                                <ChargePaymentsTable
                                    paymentCharges={record.paymentCharges ?? []}
                                    payments={record.payments ?? []}
                                />
                            </div>
                        )
                }}
            />
        </div>
    )
}

export default KlassappTableHOC(ChargesTable)
