import {get} from "lodash"
import BaseService from "./Base"

class AiSchedulingParametersService extends BaseService {
    async getAll(params = {}) {
        const response = await this.post(`/v1/ai-scheduling-parameters/list`, params)
        return get(response, "data", [])
    }

    async delete(params = {}) {
        const response = await this.post(`/v1/ai-scheduling-parameters/delete`, params)
        return get(response, "data", [])
    }

    async update(params = {}) {
        const response = await this.post(`/v1/ai-scheduling-parameters/edit`, params)
        return get(response, "data", [])
    }

    async create(params = {}) {
        const response = await this.post("/v1/ai-scheduling-parameters/create", params)
        return get(response, "data", [])
    }
}

export default AiSchedulingParametersService
