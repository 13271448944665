import React, {useState, useCallback, useEffect} from "react"
import debounce from "debounce-promise"
import {Checkbox} from "antd"
import {BasePopup} from "components/popup"
import {BaseButton} from "components/buttons"
import styles from "./AddStudentPopup.module.css"
import {courseScheduleService} from "services"
import {StudentInfo} from "./StudentInfo"
import {useDebounce} from "react-use"
import {Search} from "components/inputs/Search"
import {handleError, toastError} from "helpers"
import {isEmpty} from "lodash"
import {KlassDropAsyncPaginate} from "components/Select"
import {FormLabel} from "components/Form"
import {BaseLoading, Icon} from "components"
import {Course} from "types/courses"
import moment from "moment"
import {AcademicPlans} from "types/academicPlans"

type Props = {
    isShow: boolean
    onClose: () => void
    studentProfileIds: number[]
    termId: number
    courseId: number
    campusIds: number[]
    activeSchedule: any
    courseInfo: Course.CourseTableItem
}

export function AddStudentPopup(props: Props) {
    const {isShow, onClose, studentProfileIds = [], termId, courseId, campusIds, activeSchedule, courseInfo} = props
    const [isLoading, setIsLoading] = useState(false)
    const [isAllChecked, setIsAllChecked] = useState(false)
    const [isRegisterAllChecked, setIsRegisterAllChecked] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [students, setStudents] = useState([])
    const [search, setSearch] = useState("")
    const [total, setTotal] = useState(0)
    const [schedule, setSchedule] = useState(null)
    const [searchDebounce, setSearchDebounce] = useState("")
    const [checkedStudents, setCheckedStudents] = useState<AcademicPlans.AddStudentInfo[]>([])

    useDebounce(() => setSearchDebounce(search), 400, [search])

    const getListSchedules = async (search: string, loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const {data: schedules, total} = await courseScheduleService.getTermCourseSchedules({
                range: {page, pageSize},
                filter: {
                    search,
                    trackIds: activeSchedule?.academicTrackId ? [activeSchedule?.academicTrackId] : undefined
                }
            })
            return {
                options: schedules,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const getListStudent = async () => {
        try {
            setIsLoading(true)
            const {data} = await courseScheduleService.getAllStudents({
                filter: {
                    scheduleIds: schedule?.scheduleId ? [schedule?.scheduleId] : undefined,
                    trackIds: activeSchedule?.academicTrackId ? [activeSchedule?.academicTrackId] : undefined,
                    search
                }
            })
            const students = data.filter((student) => !studentProfileIds.includes(student.profileId))
            setTotal(students.length)
            setStudents(students)
        } catch (e) {
            handleError(e)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getListStudent()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchDebounce, schedule])

    const updateStudentRow = (profileId, key, value) => {
        switch (key) {
            case "isChecked": {
                if (value) {
                    const newCheckedStudents = [...checkedStudents]
                    newCheckedStudents.push({profileId, isRegisterCheck: false, registeredDate: null})
                    setCheckedStudents(newCheckedStudents)
                } else {
                    const newCheckedStudents = checkedStudents.filter((item) => item.profileId !== profileId)
                    setCheckedStudents(newCheckedStudents)
                }
                break
            }
            case "isRegisterCheck": {
                const newCheckedStudents = checkedStudents.map((item) => {
                    if (item.profileId === profileId) {
                        return {...item, isRegisterCheck: value, registeredDate: value ? moment() : null}
                    }
                    return item
                })
                setCheckedStudents(newCheckedStudents)
                break
            }
            case "registeredDate": {
                const newCheckedStudents = checkedStudents.map((item) => {
                    if (item.profileId === profileId) {
                        return {...item, registeredDate: value}
                    }
                    return item
                })
                setCheckedStudents(newCheckedStudents)
                break
            }
            default:
                break
        }
    }

    const onClickAdd = async () => {
        const data = checkedStudents.map((student) => {
            if (student.isRegisterCheck && student.registeredDate) {
                return {profileId: student.profileId, registeredDate: moment(student.registeredDate).format()}
            }
            return {profileId: student.profileId}
        })
        if (isEmpty(data)) {
            toastError("Please select at least 1 student")
            return
        }
        try {
            setIsSubmitting(true)
            await courseScheduleService.addStudents({
                termId,
                courseId,
                scheduleId: activeSchedule.id,
                students: data
            })
            onClose()
            setIsSubmitting(false)
            setCheckedStudents([])
        } catch (err) {
            setIsSubmitting(false)
            handleError(err)
        }
    }

    const onToggleCheckAll = (checked) => {
        const newStudents = students.map((student) => ({...student, isChecked: checked}))
        setStudents(newStudents)
    }

    const onToggleRegisterCheckAll = (checked) => {
        const newStudents = students.map((student) => ({
            ...student,
            isRegisterCheck: checked,
            isChecked: checked ? checked : student.isChecked,
            registeredDate: checked ? moment() : student.registeredDate
        }))
        if (checked) {
            setIsAllChecked(true)
        }
        setStudents(newStudents)
    }

    const debounceSchedules = debounce(getListSchedules, 300)

    return (
        <BasePopup isShow={isShow} onClose={onClose} width="70vw" leftIcon="PERSON_ADD">
            <div className={styles.wrap}>
                <p className={styles.title}>Add Student</p>
                <div className={styles.courseScheduleInfo}>
                    {courseInfo.courseName}
                    <span className={styles.courseScheduleInfo__schedule}>{activeSchedule.termCourseCode}</span>
                </div>
                <div className={styles.scheduleDropdown}>
                    <FormLabel label="Select Term Course Schedule" />
                    <KlassDropAsyncPaginate
                        loadOptions={debounceSchedules}
                        value={schedule}
                        valueKey="scheduleId"
                        getOptionLabel={(option) =>
                            `${option.termCode}-${option.scheduleSuffix} (${option.courseName})`
                        }
                        onChange={setSchedule}
                        placeholder="Select"
                        isClearable
                    />
                </div>
                <Search value={search} onChange={(newValue) => setSearch(newValue)} placeholder="Search" />
                <div className={styles.listStudents}>
                    <div className={styles.listStudentHeader}>
                        <div className={styles.totalStudent}>
                            <span className={styles.totalStudent__count}>{total}</span>
                            <span className={styles.totalStudent__title}>Students</span>
                        </div>
                    </div>
                    <BaseLoading isShow={isLoading} />
                    <div className={styles.studentHeader}>
                        <div className={styles.checkboxCol}>
                            <Checkbox
                                checked={isAllChecked}
                                onChange={(event) => {
                                    setIsAllChecked(event.target.checked)
                                    onToggleCheckAll(event.target.checked)
                                }}
                            />
                        </div>
                        <div className={styles.studentNameCol}>Student</div>
                        <div
                            className={styles.registerCol}
                            onClick={() => {
                                setIsRegisterAllChecked(!isRegisterAllChecked)
                                onToggleRegisterCheckAll(!isRegisterAllChecked)
                            }}>
                            <Icon
                                icon="CHECKED"
                                className={styles.checkedIcon}
                                color={isRegisterAllChecked ? "#18a957" : "#e5e5e5"}
                            />
                            Register
                        </div>
                    </div>
                    <div className={styles.listStudent}>
                        {students.map((student, index) => (
                            <StudentInfo
                                index={index}
                                key={student.profileId}
                                student={student}
                                checkedStudents={checkedStudents}
                                updateStudentRow={updateStudentRow}
                            />
                        ))}
                    </div>
                </div>
                <div className={styles.action}>
                    <BaseButton title="Add" loading={isSubmitting} onClick={onClickAdd} />
                </div>
            </div>
        </BasePopup>
    )
}
