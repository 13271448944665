import React from "react"
import {CalendarEventForm} from "sections/calendar/parts/EventPopup"
import {useModel} from "hooks"
import {RSVP} from "types/calendar"
import {OPTIONS_FREQUENCY, OPTIONS_FREQUENCY_TYPE} from "sections/calendar/parts/common"
import {PanelExpandedSection} from "uiKit/PanelExpandedSection"

type DisplayPart = "list" | "create"

type Props = {
    calendarId: any
    onNavigate?: (displayMode: DisplayPart) => void
}

const INITIAL_PERSONAL_EVENT_DATA = {
    publicId: "",
    name: "",
    typeEvent: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    rsvp: RSVP.Yes,
    recurring: OPTIONS_FREQUENCY[0],
    end: OPTIONS_FREQUENCY_TYPE[0],
    executions: "",
    every: "",
    days: {},
    monthlyRecurring: 0,
    monthDay: {},
    monthWeek: {},
    monthWeekDay: {},
    notes: "",
    allDay: false,
    rrule: undefined
}

export const CreateEvent = (props: Props) => {
    const {calendarId, onNavigate} = props
    const model = useModel()

    return (
        <PanelExpandedSection title="Calendar - Create Event" onClose={() => onNavigate("list")}>
            <CalendarEventForm
                model={model}
                calendarId={calendarId}
                onClose={() => onNavigate("list")}
                initialEventData={INITIAL_PERSONAL_EVENT_DATA}
                onCreateSuccess={() => {}}
                displayMode="panel"
            />
        </PanelExpandedSection>
    )
}
