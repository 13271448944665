import {get} from "lodash"
import {Notification} from "types/notification"
import BaseService from "./Base"

class ChatService extends BaseService {
    private viewOtherUserId = null
    constructor() {
        super(process.env.REACT_APP_CHAT_API_BASE_URL)
    }
    setViewOtherUserId(viewOtherUserId: number | null) {
        this.viewOtherUserId = viewOtherUserId
    }

    private addViewOtherUserIdQueryParam(path: string) {
        return `${path}${this.viewOtherUserId ? `?viewOtherUserId=${this.viewOtherUserId}` : ""}`
    }
    async getSidebar(params = {}) {
        const {data: response}: any = await this.post(this.addViewOtherUserIdQueryParam(`/get-my-sidebar`), params)
        if (!response.success) {
            throw response
        }
        return response
    }
    async getMessages(params = {}, config = {}) {
        const {data: response}: any = await this.post(
            this.addViewOtherUserIdQueryParam(`/messages/get-all`),
            params,
            config
        )
        if (!response.success) {
            throw response
        }
        return response
    }
    async getFragmentMessages(params = {}) {
        const {data: response}: any = await this.post(
            this.addViewOtherUserIdQueryParam(`/messages/get-fragment`),
            params
        )
        if (!response.success) {
            throw response
        }
        return response
    }

    async addMessage(params = {}) {
        const {data: response}: any = await this.post(`/messages/add`, params)
        if (!response.success) {
            throw response
        }
        return response.data
    }

    async addDirectMessage(params = {}) {
        const {data: response}: any = await this.post(`/messages/add-direct`, params)
        if (!response.success) {
            throw response
        }
        return response
    }
    async uploadFile(params = {}) {
        const {data: response}: any = await this.post(`/uploads/add`, params)
        if (!response.success) {
            throw response
        }
        return response
    }

    async getPreSignedUrl(params) {
        const {data: response}: any = await this.post("/v2/uploads/one-time-upload-link/create", params)
        if (!response.success) {
            throw response
        }
        return response
    }

    async addRoom(params = {}) {
        const {data: response}: any = await this.post(`/rooms/add`, params)
        if (!response.success) {
            throw response
        }
        return response
    }
    async roomFavorite(params = {}) {
        const {data: response}: any = await this.post(`/rooms/favourite`, params)
        if (!response.success) {
            throw response
        }
        return response
    }
    async getOneRoom(roomId: number) {
        const {data: response}: any = await this.post(`/rooms/${roomId}/get`, {})
        if (!response.success) {
            throw response
        }
        return response
    }
    async getAllRooms(params = {}) {
        const {data: response}: any = await this.post(`/rooms/get-all`, params)
        if (!response.success) {
            throw response
        }
        return get(response, "data", [])
    }
    async editRooms(params = {}) {
        const {data: response}: any = await this.post(`/rooms/edit`, params)
        if (!response.success) {
            throw response
        }
        return get(response, "data", [])
    }
    async deleteRooms(params = {}) {
        const {data: response}: any = await this.post(`/rooms/delete`, params)
        if (!response.success) {
            throw response
        }
        return get(response, "data", [])
    }
    async leaveRoom(params = {}) {
        const {data: response}: any = await this.post(`/rooms/leave-room`, params)
        if (!response.success) {
            throw response
        }
        return response
    }
    async hideRoom(params = {}) {
        const {data: response}: any = await this.post(`/rooms/hide-room`, params)
        if (!response.success) {
            throw response
        }
        return response
    }
    async sendTyping(params = {}) {
        const {data: response}: any = await this.post(`/users/i-am-typing`, params)
        if (!response.success) {
            throw response
        }
        return response
    }
    async addReaction(params = {}) {
        const {data: response}: any = await this.post(`/messages/reaction`, params)
        if (!response.success) {
            throw response
        }
        return response
    }
    async forwardMessage(params = {}) {
        const {data: response}: any = await this.post(`/messages/forward`, params)
        if (!response.success) {
            throw response
        }
        return response
    }
    async searchMessages(params = {}) {
        const {data: response}: any = await this.post(this.addViewOtherUserIdQueryParam(`/messages/search`), params)
        if (!response.success) {
            throw response
        }
        return response
    }
    async deleteMessage(params = {}) {
        const {data: response}: any = await this.post(this.addViewOtherUserIdQueryParam(`/messages/delete`), params)
        if (!response.success) {
            throw response
        }
        return response
    }
    async editMessage(params = {}) {
        const {data: response}: any = await this.post(`/messages/edit`, params)
        if (!response.success) {
            throw response
        }
        return response
    }
    async pinMessage(params = {}) {
        const {data: response}: any = await this.post(`/messages/mark-as-pinned`, params)
        if (!response.success) {
            throw response
        }
        return response
    }
    async getLastRead(params = {}, config = {}) {
        const {data: response}: any = await this.post(
            this.addViewOtherUserIdQueryParam(`/messages/get-last-read`),
            params,
            config
        )
        if (!response.success) {
            throw response
        }
        return response
    }
    async markAsReadMultiple(params: Notification.MarkAsReadMultipleParams) {
        const {data: response}: any = await this.post(`/messages/mark-as-read-multiple`, params)
        if (!response.success) {
            throw response
        }
        return response
    }
    async markAsUnread(params = {}) {
        const {data: response}: any = await this.post(`/messages/mark-as-unread`, params)
        if (!response.success) {
            throw response
        }
        return response
    }
    async getRoomNotificationsSettings(params = {}) {
        const response = await this.post(
            this.addViewOtherUserIdQueryParam("/rooms/notifications-settings/list"),
            params
        )
        return get(response, "data", [])
    }
    async editRoomNotificationsSettings(params = {}) {
        const response = await this.post("/rooms/notifications-settings/edit", params)
        return get(response, "data", [])
    }
    async getTotalUnreadMessages(params = {}) {
        const {data: response}: any = await this.post(`/rooms/get-total-unread`, params)
        if (!response.success) {
            throw response
        }
        return response
    }

    async getParticipantsDirectMessages(params = {}) {
        const {data: response}: any = await this.post(
            this.addViewOtherUserIdQueryParam(`/direct-messages/participants`),
            params
        )
        if (!response.success) {
            throw response
        }
        return response
    }

    async getFiles(params = {}) {
        const {data: response}: any = await this.post(this.addViewOtherUserIdQueryParam(`/rooms/files`), params)
        if (!response.success) {
            throw response
        }
        return response
    }

    async createPoll(params = {}) {
        const {data: response}: any = await this.post(`/polls/add`, params)
        if (!response.success) {
            throw response
        }
        return response
    }
    async addVote(params = {}) {
        const {data: response}: any = await this.post(`/polls/add-vote`, params)
        if (!response.success) {
            throw response
        }
        return response
    }

    async retractVote(params = {}) {
        const {data: response}: any = await this.post(`/polls/retract-vote`, params)
        if (!response.success) {
            throw response
        }
        return response
    }
    async stopVote(params = {}) {
        const {data: response}: any = await this.post(`/polls/stop`, params)
        if (!response.success) {
            throw response
        }
        return response
    }

    async getGroups(params = {}) {
        const {data: response}: any = await this.post(`/groups/get-all`, params)
        if (!response.success) {
            throw response
        }
        return response
    }

    async getGroupDetails(params = {}) {
        const {data: response}: any = await this.post(`/groups/get-details`, params)
        if (!response.success) {
            throw response
        }
        return response
    }

    async addGroup(params = {}) {
        const {data: response}: any = await this.post(`/groups/add`, params)
        if (!response.success) {
            throw response
        }
        return response
    }

    async editGroup(params = {}) {
        const {data: response}: any = await this.post(`/groups/edit`, params)
        if (!response.success) {
            throw response
        }
        return response
    }

    async deleteGroups(params = {}) {
        const {data: response}: any = await this.post(`/groups/delete`, params)
        if (!response.success) {
            throw response
        }
        return response
    }

    async userMerge(data) {
        const response = await this.post(`/user-merge`, data)
        return get(response, "data", [])
    }

    async getNotificationTypes(): Promise<{data: Notification.Notification[]}> {
        const response = await this.post(`/messages/notification-types`, {})
        return get(response, "data", [])
    }

    async getNotifications(
        data: Notification.GetNotificationMessageParams
    ): Promise<{data: Notification.NotificationMessage[]; pagination: Notification.Pagination}> {
        const response = await this.post(`/messages/notifications`, data)
        return get(response, "data", [])
    }

    async markReadNotification(data: Notification.MarkAsReadParams) {
        const response = await this.post(`/messages/mark-as-read`, data)
        return get(response, "data", [])
    }
}

export default ChatService
