/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useReducer, useState} from "react"
import styles from "./PreviouslyCompletedCredits.module.css"
import classNames from "classnames"
import {useTranslation} from "react-i18next"
import {getFullName, handleError, preventMinus, toastError} from "helpers"
import {KlassappTable, KlassappTableDropdownAsyncPaginate, KlassappTableHeader, SignaturePopup} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {BaseButton, BaseInput, Icon} from "components"
import {v4 as uuidV4} from "uuid"
import debounce from "debounce-promise"
import {academicPlansService, previousCompletedCreditsService} from "services"
import moment from "moment"
import {BaseDepartmentId} from "types/departments"
import {Permissions} from "@edular/permissions"
import {PermissionUserType} from "types/permission"
import {useCurrentProfilePermissions, useVisible} from "hooks"
import {Button} from "antd"
import {ParamCreatePreviousCompletedCredits, PreviousCompletedCredits} from "types/previousCompletedCredits"
import {cloneDeep, isEqual, pick, uniqBy} from "lodash"
import cx from "classnames"
import {AcademicPlans} from "types/academicPlans"

let dataPreviousCompletedCredits = []
function reducer(state, action) {
    return {...state, ...action}
}
enum ReviewItemType {
    APPROVAL = "approval"
}
enum ApprovalStatus {
    PENDING = "pending",
    APPROVED = "approved",
    REJECTED = "rejected"
}
const PreviouslyCompletedCredits = (props) => {
    const initialState = {
        reviewItem: null,
        reviewType: null,
        isRejecting: false,
        isApproving: false
    }
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        isHideMenuActions,
        menuActions,
        data,
        studentId,
        allFields,
        fields,
        tableHeaderActions,
        refreshProgressReport,
        orderField,
        isLoading,
        isShowTableHeaderAction,
        departmentId
    } = props
    const [newData, setNewData] = useState([])
    const [arrayData, setArrayData] = useState([])
    const currentProfilePermissions = useCurrentProfilePermissions()
    const signaturePopup = useVisible(false)
    const [{reviewItem, isRejecting, isApproving}, dispatchState] = useReducer(reducer, initialState)
    const [errors, setErrors] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)
    const hasPermissionReview = currentProfilePermissions.hasPermissions({
        staff: [Permissions.Staff.Academics.Registrar.Students.DegreeAudit.Edit]
    })
    const [filters] = useState({
        university: null
    })
    const {t} = useTranslation(["course"])

    const getFields = () => {
        return [
            "CODE",
            "COURSE NAME",
            "TERM NAME",
            "CREDITS",
            "GRADES",
            "GRADES POINTS",
            "QUALITY POINTS",
            "ID",
            "COURSE NAME",
            "CREDITS",
            "ACCEPTED"
        ]
    }

    const getPageTitle = () => {
        return "Previously Completed Credits"
    }

    const getColumns = () => {
        return [
            {
                title: "CODE",
                field: "code",
                style: {minWidth: "100px", width: "100px"},
                classNameHeader: styles.titleHeader
            },
            {
                title: "COURSE NAME",
                field: "courseName",
                style: {minWidth: "200px", width: "200px"},
                classNameHeader: styles.titleHeader
            },
            {
                title: "TERM NAME",
                field: "termName",
                style: {minWidth: "200px", width: "200px"},
                classNameHeader: styles.titleHeader
            },
            {
                title: "CREDITS",
                field: "credits",
                style: {minWidth: "90px", width: "90px"},
                classNameHeader: styles.titleHeader
            },
            {
                title: "GRADES",
                field: "alphabetical",
                style: {minWidth: "90px", width: "90px"},
                classNameHeader: styles.titleHeader
            },
            {
                title: "GRADES POINTS",
                field: "grades",
                style: {minWidth: "90px", width: "90px"},
                classNameHeader: styles.titleHeader
            },
            {
                title: "QUALITY POINTS",
                field: "qualityPoints",
                style: {minWidth: "90px", width: "90px"},
                classNameHeader: styles.titleHeader
            },
            {
                title: "ID",
                field: "idApplied",
                style: {minWidth: "150px", width: "150px"},
                classNameHeader: styles.idEquivalentHeader
            },
            {
                title: "COURSE NAME",
                field: "courseNameApplied",
                style: {minWidth: "200px", width: "200px"},
                classNameHeader: styles.titleHeader
            },
            {
                title: "CREDITS",
                field: "creditsApplied",
                style: {minWidth: "90px", width: "90px"},
                classNameHeader: styles.titleHeader
            },
            {
                title: "ACCEPTED",
                field: "accepted",
                style: {minWidth: "100px"},
                classNameHeader: styles.titleHeader
            }
        ]
    }

    const getParams = () => {
        const params: any = {
            studentProfileId: studentId,
            range: {
                page,
                pageSize
            }
        }
        return params
    }

    const onSearchCourse = async (search = "", loadedOptions) => {
        try {
            const sections = await academicPlansService.getListCourseBySection({
                studentProfileId: studentId,
                viewType: AcademicPlans.TabKey.List,
                search
            })
            let courses = []
            for (let section of sections) {
                courses = [...courses, ...section.courses]
            }
            courses = uniqBy(courses, "courseId")
            return {
                options: courses,
                hasMore: false
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }
    const onCodeSearchCourse = async (search = "", loadedOptions) => {
        try {
            const sections = await academicPlansService.getListCourseBySection({
                studentProfileId: studentId,
                viewType: AcademicPlans.TabKey.List,
                search
            })
            let courses = []
            for (let section of sections) {
                courses = [...courses, ...section.courses]
            }
            courses = uniqBy(courses, "courseId")
            return {
                options: courses,
                hasMore: false
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onChangeInput = (id, field, value, type: "number" | "text") => {
        const changeDataForm = dataPreviousCompletedCredits.map((item) => {
            if (item.id !== id) {
                return item
            }
            const newValue = {
                ...item,
                [field]: renderInput(field, field, item.id, value, true, type),
                [`${field}Value`]: value
            }
            return newValue
        })

        dataPreviousCompletedCredits = changeDataForm
        setNewData(changeDataForm)
    }

    const renderInput = (
        field: string,
        nameField: string,
        id: string,
        value: string,
        isEditing: boolean,
        type: "number" | "text"
    ) => {
        if (!isEditing) {
            return (
                <div className={field === "idApplied" ? styles.titleWrapId : styles.titleWrap}>
                    <span className={styles.titleInput}>{value}</span>
                </div>
            )
        }
        return (
            <BaseInput
                type={type}
                placeholder={nameField}
                value={value}
                min={type === "number" ? 0 : undefined}
                onKeyPress={type === "number" ? preventMinus : undefined}
                disabledArrow
                onChange={(text) => onChangeInput(id, field, text, type)}
            />
        )
    }

    useEffect(() => {
        dataPreviousCompletedCredits = [...newData]
        dispatch({data: [...newData], total: newData.length})
    }, [newData])

    const debounceCourse = debounce(onSearchCourse, 500)
    const debounceCodeCourse = debounce(onCodeSearchCourse, 500)

    const onChangeCourseName = (id, value) => {
        const changeDataForm = dataPreviousCompletedCredits.map((item) => {
            if (item.id !== id) {
                return item
            }
            const newValue = {
                ...item,
                courseNameApplied: renderCourseDropDown(id, value),
                courseNameAppliedValue: value,
                idApplied: renderCourseIdDropDown(id, value),
                idAppliedValue: value,
                creditsAppliedValue: value.credit?.toString(),
                creditsApplied: renderInput("credits", "Credits", id, value.credit?.toString(), true, "number")
            }
            return newValue
        })

        dataPreviousCompletedCredits = changeDataForm
        setNewData(changeDataForm)
    }

    const onChangeCourseId = (id, value) => {
        const changeDataForm = dataPreviousCompletedCredits.map((item) => {
            if (item.id !== id) {
                return item
            }
            const newValue = {
                ...item,
                idApplied: renderCourseIdDropDown(id, value),
                idAppliedValue: value,
                courseNameApplied: renderCourseDropDown(id, value),
                courseNameAppliedValue: value,
                creditsAppliedValue: value.credit?.toString(),
                creditsApplied: renderInput("credits", "Credits", id, value.credit?.toString(), true, "number")
            }
            return newValue
        })

        dataPreviousCompletedCredits = changeDataForm
        setNewData(changeDataForm)
    }

    const onAdd = useCallback(() => {
        const id = uuidV4()
        const newData = [
            ...dataPreviousCompletedCredits,
            {
                id: id,
                code: renderInput("code", "Code", id, "", true, "text"),
                courseName: renderInput("courseName", "Course Name", id, "", true, "text"),
                credits: renderInput("credits", "Credits", id, "", true, "number"),
                idApplied: renderCourseIdDropDown(id, null),
                courseNameApplied: renderCourseDropDown(id, null),
                creditsApplied: renderInput("creditsApplied", "Credits", id, "", true, "number"),
                accepted: renderApprovalHtml({id}, !hasPermissionReview)
            }
        ]
        dataPreviousCompletedCredits = newData
        setNewData(newData)
    }, [filters, hasPermissionReview])

    const renderCourseDropDown = (id, currentValue, readOnly = false) => {
        return (
            <KlassappTableDropdownAsyncPaginate
                value={currentValue}
                readOnly={readOnly}
                className={styles.dropDownCourse}
                valueKey="courseId"
                labelKey="courseName"
                onChange={(value) => onChangeCourseName(id, value)}
                placeholder="Select"
                loadOptions={debounceCourse}
            />
        )
    }

    const renderCourseIdDropDown = (id, currentValue, readOnly = false) => {
        return (
            <div className={styles.titleWrapId}>
                <KlassappTableDropdownAsyncPaginate
                    value={currentValue}
                    valueKey="courseId"
                    className={styles.dropDownCourse}
                    readOnly={readOnly}
                    labelKey="courseCode"
                    onChange={(value) => onChangeCourseId(id, value)}
                    placeholder="Id"
                    loadOptions={debounceCodeCourse}
                />
            </div>
        )
    }
    const getPermissionsByDepartment = (): PermissionUserType => {
        switch (departmentId) {
            case BaseDepartmentId.Admissions:
                return {staff: [Permissions.Staff.Admissions.Students.AdmissionsStudentDetails.Edit]}
            case BaseDepartmentId.FinancialAid:
                return {staff: [Permissions.Staff.FinancialAid.Students.FinancialAidStudentDetail.Edit]}
            case BaseDepartmentId.Academics:
                return {staff: [Permissions.Staff.Academics.Registrar.Students.AcademicPlanning.Edit]}
            case BaseDepartmentId.StudentServices:
                return {
                    staff: [
                        Permissions.Staff.StudentServicesAndAcademicAffairs.StudentServices.Students.AcademicPlanning
                            .Edit
                    ]
                }
            default:
                return {}
        }
    }

    function onClickReview(type, item) {
        if (hasPermissionReview) {
            signaturePopup.open()
            dispatchState({reviewItem: item, reviewType: type})
        }
    }

    function renderStatusApproved(item) {
        const approvalDate = item.approvalStatusUpdatedAt
        return (
            <div className={styles.statusWrap}>
                <div className={classNames(styles.signatureWrap, styles.signature__approval)}>
                    <Icon icon="CHECKMARK_CIRCLE" className={styles.signature__statusIcon} color="#18A957" />
                    <img src={item["approvalSignature"].imageUrl} alt="" className={styles.signature__img} />
                </div>
                <div className={styles.dateInfo}>
                    <p className={styles.dateInfo__text}>
                        {moment(approvalDate).local(true).format("MM/DD/YYYY hh:mm A")}
                    </p>
                    <p className={styles.dateInfo__text}>{getFullName(item["approvalSignature"])}</p>
                </div>
            </div>
        )
    }

    function renderStatusRejected(item) {
        const approvalDate = item.approvalStatusUpdatedAt
        return (
            <div className={styles.statusWrap}>
                <div className={classNames(styles.signatureWrap, styles.signature__reject)}>
                    <Icon icon="CROSS_CIRCLE" className={styles.signature__statusIcon} color="#DF1642" />
                    <img src={item["approvalSignature"].imageUrl} alt="" className={styles.signature__img} />
                </div>
                <div className={styles.dateInfo}>
                    <p className={styles.dateInfo__text}>
                        {moment(approvalDate).local(true).format("MM/DD/YYYY hh:mm A")}
                    </p>
                    <p className={styles.dateInfo__text}>{getFullName(item["approvalSignature"])}</p>
                </div>
            </div>
        )
    }
    function renderApprovalHtml(item, isDisabled: boolean = false) {
        if (isDisabled) {
            return (
                <div
                    className={cx(styles.review, {
                        [styles.disabledHtml]: isDisabled
                    })}>
                    REVIEW
                </div>
            )
        }
        switch (item.approvalStatus) {
            case "pending":
                return (
                    <div className={styles.review} onClick={() => onClickReview(ReviewItemType.APPROVAL, item)}>
                        Review
                    </div>
                )
            case "approved":
                return renderStatusApproved(item)
            case "rejected":
                return renderStatusRejected(item)
            default:
                return <></>
        }
    }

    function getKeyFromType() {
        return "approvalStatus"
    }

    async function onClickReject() {
        if (isRejecting || isApproving) {
            return
        }
        const data = {
            studentChecklistItemId: reviewItem.studentChecklistItemId
        }
        const key = getKeyFromType()
        data[key] = "rejected"
        try {
            dispatchState({isRejecting: true})
            await previousCompletedCreditsService.updatePreviousCompletedCredits(reviewItem.id, {
                approvalStatus: ApprovalStatus.REJECTED
            })
            dispatchState({reviewItem: null})
            signaturePopup.close()
            await getData()
        } catch (error) {
            handleError(error)
        } finally {
            dispatchState({isRejecting: false})
            refreshProgressReport()
        }
    }

    async function onClickApprove() {
        if (isRejecting || isApproving) {
            return
        }
        const data = {
            studentChecklistItemId: reviewItem.studentChecklistItemId
        }
        const key = getKeyFromType()
        data[key] = "approved"
        try {
            dispatchState({isApproving: true})
            await previousCompletedCreditsService.updatePreviousCompletedCredits(reviewItem.id, {
                approvalStatus: ApprovalStatus.APPROVED
            })

            dispatchState({reviewItem: null})
            signaturePopup.close()
            await getData()
            // getStudentChecklistProgress()
        } catch (error) {
            handleError(error)
        } finally {
            dispatchState({isApproving: false})
            refreshProgressReport()
        }
    }
    const getData = async () => {
        dispatch({isLoading: true})
        try {
            const params = getParams()
            let {data, total} = await previousCompletedCreditsService.listPreviousCompletedCredits(params)
            const newData = data.map((item) => ({
                ...item,
                codeValue: item.code,
                courseNameValue: item.courseName,
                creditsValue: item.credits?.toString(),
                idAppliedValue: {code: item.codeApplied, name: item.courseNameApplied, courseId: item.idApplied},
                courseNameAppliedValue: {code: item.codeApplied, name: item.courseNameApplied},
                creditsAppliedValue: item.creditsApplied?.toString(),
                idApplied: renderInput("idApplied", "Id", `${item.id}`, item.codeApplied, false, "text"),
                courseNameApplied: item.courseNameApplied,
                approvalStatusValue: item.approvalStatus,
                approvalSignatureIdValue: item.approvalSignatureId,
                approvalStatusUpdatedAtValue: item.approvalStatusUpdatedAt,
                accepted: renderApprovalHtml(item, !hasPermissionReview),
                cellTableStyle: styles.tableRow
            }))
            dispatch({data: newData, total: total})
            setArrayData(newData)
            setNewData(newData)
            dataPreviousCompletedCredits = newData
        } catch (e) {
            console.log("e")
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClickRowItem = (row) => {}

    const onUpdateRowData = (updatedData) => {
        const data = props.getCurrentData()
        const newData = cloneDeep(data).map((item) => {
            if (item.id === updatedData.id) {
                return {
                    ...item,
                    isChecked: updatedData.isChecked
                }
            }
            return item
        })
        dataPreviousCompletedCredits = cloneDeep(newData)
        dispatch({data: newData})
    }

    const onClickDeleteMulti = useCallback(async () => {
        try {
            dispatch({isLoading: true})
            const ids: number[] = dataPreviousCompletedCredits
                .filter((item) => item.isChecked && typeof item.id === "number")
                .map((item) => item.id)
            dispatch({isLoading: true})
            await previousCompletedCreditsService.deletePreviousCompletedCredits(ids)
            await getData()
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
        refreshProgressReport()
    }, [data, hasPermissionReview])

    useCallback(async () => {}, [data])

    const onClickEdit = (editItem) => {
        const newData = dataPreviousCompletedCredits.map((item) => {
            if (editItem.id !== item.id) return item
            const id = item.id
            return {
                ...item,
                code: renderInput("code", "Code", id, item.codeValue, true, "text"),
                courseName: renderInput("courseName", "Course Name", id, item.courseNameValue, true, "text"),
                credits: renderInput("credits", "Credits", id, item.creditsValue, true, "number"),
                idApplied: renderCourseIdDropDown(id, item.idAppliedValue),
                courseNameApplied: renderCourseDropDown(id, item.courseNameAppliedValue),
                creditsApplied: renderInput("creditsApplied", "Credits", id, item.creditsAppliedValue, true, "number"),
                accepted: renderApprovalHtml({id}, !hasPermissionReview)
            }
        })
        dataPreviousCompletedCredits = [...newData]
        setNewData([...newData])
    }

    const onClickDelete = useCallback(
        async (deletedItem) => {
            try {
                if (typeof deletedItem.id == "string") {
                    dataPreviousCompletedCredits = dataPreviousCompletedCredits.filter(
                        (item) => item.id !== deletedItem.id
                    )
                    return setNewData(dataPreviousCompletedCredits)
                }
                dispatch({isLoading: true})
                await previousCompletedCreditsService.deletePreviousCompletedCredits([deletedItem.id])
                await getData()
            } catch (e) {
                handleError(e)
            } finally {
                dispatch({isLoading: false})
                refreshProgressReport()
            }
        },
        [hasPermissionReview]
    )

    const getTableHeaderActions = () => {
        const {t, onClickShowConfirmModal} = props
        return [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            }
        ]
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "getMenuActions", func: getMenuActions},
            {key: "getTableHeaderActions", func: getTableHeaderActions},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDelete},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [data])

    useEffect(() => {
        getData()
    }, [page, pageSize, hasPermissionReview])

    useEffect(() => {
        props.updateTableHeaderActions()
    }, [data])

    const getMenuActions = () => {
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: onClickEdit
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: onClickDelete
            }
        ]
    }

    const compareIsChanged = () => {
        const dataCheck = [
            "codeValue",
            "courseNameValue",
            "creditsValue",
            "idAppliedValue",
            "creditsAppliedValue",
            "approvalStatusValue",
            "approvalSignatureIdValue"
        ]
        if (arrayData.length < newData.length) {
            return true
        }
        for (let i = 0; i < arrayData.length; i++) {
            const oldData = pick(arrayData[i], dataCheck)
            const newDataUpdate = pick(newData[i], dataCheck)

            if (!isEqual(oldData, newDataUpdate)) {
                return true
            }
        }

        return false
    }

    const onClickDoneBtn = useCallback(async () => {
        let totalErrors = {...errors}

        if (Object.keys(totalErrors).length === 0) {
            let params: ParamCreatePreviousCompletedCredits = {
                listPreviousCompletedCredits: []
            }
            params.listPreviousCompletedCredits = dataPreviousCompletedCredits.map((item) => {
                const object: PreviousCompletedCredits = {
                    code: item.codeValue,
                    studentProfileId: studentId,
                    courseName: item.courseNameValue,
                    credits: item.creditsValue?.length ? parseFloat(item.creditsValue) : null,
                    idApplied: item.idAppliedValue?.courseId ? item.idAppliedValue.courseId : null,
                    creditsApplied: item.creditsAppliedValue?.length ? parseFloat(item.creditsAppliedValue) : null,
                    approvalStatus: item.approvalStatus ? item.approvalStatus : "pending"
                    // approvalSignatureId: item.approvalSignatureId
                }
                if (typeof item.id === "number") {
                    object.id = item.id
                }
                return object
            })
            setIsSubmitting(true)
            try {
                await previousCompletedCreditsService.createPreviousCompletedCredits(params)
                setIsSubmitting(false)
                getData()
            } catch (err) {
                setIsSubmitting(false)
                toastError("Update previous completed credits failure")
            }
        } else {
            setErrors(totalErrors)
            toastError(t("common:validation.fieldRequired"))
        }
        refreshProgressReport()
    }, [hasPermissionReview])

    const onClickCancelBtn = useCallback(() => {
        setNewData(cloneDeep(arrayData))
    }, [arrayData])

    let isChanged = compareIsChanged()

    return (
        <div>
            <span className={styles.title}>Previously completed credits</span>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                menuActions={isHideMenuActions ? [] : menuActions}
                isLoading={isLoading}
                allFields={allFields}
                tableRowParent={styles.tableRowParent}
                isShowCheckedColumn={true}
                parentColumns={[
                    {title: "Transfer", classNameHeader: styles.parentTitleHeader},
                    {title: ""},
                    {title: ""},
                    {title: ""},
                    {title: ""},
                    {title: ""},
                    {title: ""},
                    {title: "Applied", classNameHeader: styles.equivalentHeader},
                    {title: ""},
                    {title: ""},
                    {title: ""}
                ]}
                orderField={orderField}
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
            <button onClick={onAdd} className={styles.addButton}>
                <span className={styles.textAdd}>Add</span>
                <Icon icon="ADD_CIRCLE" color="#FF349B" className={styles.plusIcon} />
            </button>
            <div className={styles.actionWrap}>
                {isChanged && (
                    <BaseButton
                        title={t("common:action.cancel")}
                        variant="secondary"
                        className={styles.cancelBtn}
                        onClick={onClickCancelBtn}
                    />
                )}
                <div>
                    <BaseButton
                        title={isSubmitting ? t("common:action.saving") : t("common:action.save")}
                        onClick={onClickDoneBtn}
                        isActive={isChanged}
                        disabled={!!Object.keys(errors).length}
                        loading={isSubmitting}
                    />
                </div>
            </div>
            <SignaturePopup
                isShow={signaturePopup.isVisible}
                onClose={() => signaturePopup.close()}
                title="Please Review Item">
                <div className={styles.divider}></div>
                <div className={styles.action}>
                    <Button
                        className={classNames(styles.actionBtn, styles.rejectBtn)}
                        onClick={onClickReject}
                        loading={isRejecting}>
                        <span>{t("common:action.reject")}</span>
                        <Icon icon="CROSS_CIRCLE" color="#df1642" className={styles.action__icon} />
                    </Button>
                    <Button
                        className={classNames(styles.actionBtn, styles.approveBtn)}
                        onClick={onClickApprove}
                        loading={isApproving}>
                        <span>{t("common:action.approve")}</span>
                        <Icon icon="CHECKMARK_CIRCLE_1" color="#18a957" className={styles.action__icon} />
                    </Button>
                </div>
            </SignaturePopup>
        </div>
    )
}

export default KlassappTableHOC(PreviouslyCompletedCredits)
