/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {Model} from "Model"
import {useLocation} from "react-router-dom"
import {Branding} from "types/branding"
import {get} from "lodash"
import DefaultLogo from "assets/images/png/EdularLogoRectangle.png"
import styles from "./Closing.module.css"
import {store} from "helpers"

type Props = {
    model: Model
}

let intervalId = null
export const Closing = (props: Props) => {
    const {model} = props
    const location: any = useLocation()

    const closeSessionStorage = location.state?.closeSessionStorage
    const closeLocalStorage = location.state?.closeLocalStorage
    const text = location.state?.text || "Task completed"

    const [count, setCount] = useState(5) // 5 seconds

    useEffect(() => {
        model.menu.infoBar = false
        model.menu.sidebar = false
    }, [])

    useEffect(() => {
        intervalId = setInterval(() => {
            setCount((c) => c - 1)
        }, 1000)
        return () => {
            clearInterval(intervalId)
        }
    }, [])
    useEffect(() => {
        if (count <= 0) {
            close()
        }
    }, [count])

    const close = () => {
        clearInterval(intervalId)
        if (closeSessionStorage) {
            store.clearAll()
        }
        if (closeLocalStorage) {
            model.logout()
        }
    }

    const getBrandingImg = () => {
        const rectangularImg = get(
            model,
            ["clientSetting", "branding", Branding.BrandingType.OfficialLogoRectangular],
            null
        )
        const squareImg = get(model, ["clientSetting", "branding", Branding.BrandingType.OfficialLogoSquare], null)
        return squareImg || rectangularImg || DefaultLogo
    }

    const logoUrl = getBrandingImg()

    return (
        <div className={styles.root}>
            <img src={logoUrl} alt="logo" width={250} />
            <br />
            <br />
            {text && <p>{text}</p>}
        </div>
    )
}
