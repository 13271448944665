/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from "react"
import ProgressReport, {ProgressReportFunc} from "./ProgressReport/ProgressReport"
import TableDegreeAudit from "./TableDegreeAudit/TableDegreeAudit"
import {profileService} from "services"
import {get} from "lodash"

type Props = {
    studentId: number
    departmentId: number
}

export function AcademicsStudentDegreeAudit(props: Props) {
    const progressReportRef = useRef<ProgressReportFunc>()
    const [profileInfo, setProfileInfo] = useState({})
    const {studentId, departmentId} = props

    const getProfileInfo = async () => {
        const {data: result, total} = await profileService.getAll({
            linkedObjects: true,
            filter: {
                id: [studentId]
            }
        })
        setProfileInfo(get(result, [0], {}))
    }

    useEffect(() => {
        getProfileInfo()
    }, [studentId])

    const refreshProgressReport = () => {
        progressReportRef && progressReportRef.current && progressReportRef.current.getData()
    }

    return (
        <div>
            <ProgressReport
                profileInfo={profileInfo}
                getProfileInfo={getProfileInfo}
                ref={progressReportRef}
                departmentId={departmentId}
                studentId={studentId}
            />
            <TableDegreeAudit refreshProgressReport={refreshProgressReport} studentId={studentId} />
        </div>
    )
}
