import {get} from "lodash"
import BaseService from "./Base"

class DocumentService extends BaseService {
    async getAllDocuments(params) {
        const response = await this.post("/v1/users/documents/list", params)
        return get(response, "data", [])
    }

    async getAllUserDocuments(params) {
        const response = await this.post("/v1/users/documents/list/table", params)
        return get(response, "data", [])
    }

    async getDocumentDetail(id, params = {}) {
        const response = await this.post("/v1/users/documents/list", {
            ...params,
            userDocumentIds: [id]
        })
        return get(response, "data.data[0]", {})
    }

    async autocompleteDocuments(filter: {search: string; ownerUserProfileId: number}) {
        const response = await this.post("/v1/users/documents/autocomplete", {
            filter
        })
        return get(response, "data.data", [])
    }

    async uploadDocument(data = {}) {
        const response = await this.post("/v1/users/documents/upload", data)
        return get(response, "data.data", {})
    }

    async createDocument(data = {}) {
        const response = await this.post("/v1/users/documents/create", data)
        return get(response, "data.data", {})
    }

    async updateDocument(id, data, params = {}) {
        data.userDocumentId = id
        const response = await this.post(`/v1/users/documents/edit`, data, params)
        return get(response, "data", {})
    }

    async updateDocumentStatus(data, params = {}) {
        const response = await this.post(`/v1/users/documents/update-statuses`, data, params)
        return get(response, "data", {})
    }

    async deleteDocument(userDocumentIds) {
        const response = await this.post(`/v1/users/documents/delete`, {userDocumentIds})
        return get(response, "data", {})
    }

    async mergeDocuments(payload: {userDocumentIds: Array<number>}) {
        const {data: response} = await this.post(`/v1/users/documents/merge`, payload)
        if (!response.success) {
            throw response
        }
        return response.data
    }

    async getNextCode(body: {departmentId: number}) {
        const response = await this.post(`/v1/users/documents/next-code`, body)
        return get(response, "data", {})
    }

    async checkIfCodeIsValid(body = {}) {
        const response = await this.post(`/v1/users/documents/check-code`, body)
        return get(response, "data", {})
    }
}

export default DocumentService
