/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react"
import {useTranslation} from "react-i18next"
import {KlassDropdown} from "components/Select"
import {KlassappTableHOC} from "HOC"
import {KlassappTableHeader, KlassappTable} from "uiKit"
import styles from "./ClassSelection.module.css"
import {classes} from "./rawData"

function ClassSelection(props) {
    const {dispatch, dispatchFunc, page, total, pageSize, columns, data, allFields, fields, orderField, isLoading} =
        props
    const {t} = useTranslation(["calendar"])

    const getFields = () => {
        return [t("calendar.cancelled.date"), t("calendar.cancelled.time"), t("calendar.cancelled.instructor")]
    }

    const getPageTitle = () => {
        return t("calendar.cancelled.reschedule")
    }

    const getColumns = () => {
        return [
            {
                title: t("calendar.cancelled.date"),
                field: "date"
            },
            {
                title: t("calendar.cancelled.time"),
                field: "time"
            },
            {
                title: t("calendar.cancelled.instructor"),
                field: "instructor"
            }
        ]
    }

    // const getParams = () => {
    //     const params = {
    //         range: {page, pageSize},
    //         filter: {},
    //         linkedEntities: true
    //     }
    //     return params
    // }

    const getData = async () => {
        dispatch({isLoading: true})
        try {
            // const params = getParams()
            const data = classes.map((item: any) => {
                return item
            })
            dispatch({data, total})
        } catch (e) {
            console.log("e")
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClickRowItem = (row) => {}

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [])

    useEffect(() => {
        getData()
    }, [page, pageSize])

    useEffect(() => {
        props.updateTableHeaderActions()
    }, [data])

    return (
        <div className={styles.wrap}>
            <div className={styles.dropdownWrap}>
                <KlassDropdown options={[]} />
            </div>
            <KlassappTableHeader
                isShowAction={false}
                actions={[]}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                menuActions={[]}
                isLoading={isLoading}
                allFields={allFields}
                isShowCheckedColumn={true}
                orderField={orderField}
                onClickRowItem={onClickRowItem}
                onUpdateRowData={props.onUpdateRowData}
                onChangeFields={props.onChangeFields}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </div>
    )
}

export default KlassappTableHOC(ClassSelection)
