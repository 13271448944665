import {useState} from "react"

export type UseVisibleArgs = {
    isVisible: boolean
    open: () => void
    close: () => void
    toggle: () => void
}

export const useVisible = (initialValue: boolean = false): UseVisibleArgs => {
    const [isVisible, setIsVisible] = useState(initialValue)

    return {
        isVisible,
        open: () => setIsVisible(true),
        close: () => setIsVisible(false),
        toggle: () => setIsVisible((v) => !v)
    }
}
