import React from "react"
import styles from "./Switch.module.css"

interface Props {
    text?: string
    onClick: (e?) => void
    checked?: boolean
    disabled?: boolean
}

export class Switch extends React.Component<Props, {}> {
    render() {
        const {onClick, checked, text, disabled} = this.props
        return (
            <label className={styles.root}>
                <input
                    onChange={onClick}
                    className={styles.input}
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                />
                {text && <span className={styles.text}>{text}</span>}
                <div className={styles.track}>
                    <div className={styles.thumb} />
                </div>
            </label>
        )
    }
}
