import {BaseButton, BaseLoading, BasePopup} from "components"
import {useCallback, useEffect, useState} from "react"
import styles from "./EditLdaPopup.module.css"
import {FormLabel} from "components/Form"
import {BaseDatePicker} from "components/DateTimePicker"
import {toDateOnly} from "sections/academics/instructional/common/utils"
import moment from "moment"
import {handleError} from "helpers"
import {useTranslation} from "react-i18next"
import {academicPlansService} from "services"

type Props = {
    isShow: boolean
    onClose: () => void
    courseId: number
    lda: string
    termId: number
    studentProfileId: number
    onRefreshLda: () => Promise<void>
    scheduleId: number
}

export function EditLdaPopup(props: Props) {
    const {isShow, onClose, lda, onRefreshLda, termId, courseId, scheduleId, studentProfileId} = props
    const [isLoading, setIsLoading] = useState(false)
    const [editedLda, setEditedLda] = useState(lda ? moment(toDateOnly(lda)) : undefined)
    const {t} = useTranslation(["common"])

    useEffect(() => {
        if (lda) {
            setEditedLda(moment(toDateOnly(lda)))
        }
    }, [lda])

    const onSubmit = useCallback(async () => {
        setIsLoading(true)

        try {
            await academicPlansService.updateLdaTermCourseSchedule([
                {
                    termId,
                    profileId: studentProfileId,
                    courseId,
                    lastDayOfAttendance: editedLda?.format("YYYY-MM-DD"),
                    scheduleId,
                    isForceUpdate: true
                }
            ])
            await onRefreshLda()
            setIsLoading(false)
            onClose()
        } catch (err) {
            handleError(err)
            setIsLoading(false)
        }
    }, [courseId, editedLda, onClose, onRefreshLda, scheduleId, studentProfileId, termId])

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="FILE_LINE_ALT" width="50vw">
            <div className={styles.wrap}>
                <p className={styles.title}>Edit LDA</p>
                <FormLabel label="Lda" />
                <BaseDatePicker value={editedLda} onChange={(option) => setEditedLda(option)} placeholder="Lda" />
                <div className={styles.submitContainer}>
                    <BaseButton
                        title={isLoading ? t("common:action.saving") : t("common:action.save")}
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>
                <BaseLoading isShow={isLoading} />
            </div>
        </BasePopup>
    )
}
