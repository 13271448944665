/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react"
import {BaseDrawer} from "components/BaseDrawer"
import {JobInfoPanel, JobInfoPanelProps} from "sections/shared/careerService"

type Props = JobInfoPanelProps & {
    displayDrawer: boolean
    onCloseDrawer?: () => void
}

const INIT_DRAWER_WIDTH = 400
const EXPANDED_DRAWER_WIDTH = 400

export function JobInfoDrawer(props: Props) {
    const {displayDrawer, onCloseDrawer, activeSection, ...rest} = props

    const [drawerWidth, setDrawerWidth] = useState<number>(INIT_DRAWER_WIDTH)

    useEffect(() => {
        if (displayDrawer) {
            if (activeSection && activeSection !== "") maximizeDrawerWidth()
            else minimizeDrawerWidth()
        }
    }, [displayDrawer])

    const minimizeDrawerWidth = useCallback(() => {
        setDrawerWidth(INIT_DRAWER_WIDTH)
    }, [])

    const maximizeDrawerWidth = useCallback(() => {
        setDrawerWidth(INIT_DRAWER_WIDTH + EXPANDED_DRAWER_WIDTH)
    }, [])

    return (
        <BaseDrawer isShow={displayDrawer} onClose={onCloseDrawer} width={drawerWidth}>
            <JobInfoPanel
                {...rest}
                onClose={onCloseDrawer}
                displayType="drawer"
                activeSection={activeSection}
                minimizeDrawerWidth={minimizeDrawerWidth}
                maximizeDrawerWidth={maximizeDrawerWidth}
            />
        </BaseDrawer>
    )
}
