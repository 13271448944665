import {get} from "lodash"
import BaseService from "./Base"

class ResourceTypeService extends BaseService {
    async getAll(params = {}) {
        const response = await this.post("/v3/resources/types/list", params)
        return get(response, "data", [])
    }

    async get(resourceTypeId) {
        const response = await this.post("/v3/resources/types/get", {resourceTypeId})
        return get(response, "data.data", {})
    }

    async getTotal() {
        const response = await this.post("/v3/resources/types/total", {})
        return get(response, "data.data", {})
    }

    async createResourceType(params = {}) {
        const response = await this.post("/v3/resources/types/create", params)
        return get(response, "data.data", {})
    }

    async duplicateResourceType(resourcesTypes) {
        const response = await this.post(`/v3/resources/types/duplicate`, {resourcesTypes})
        return get(response, "data", {})
    }

    async updateResourceType(id, data, params = {}) {
        data.resourceTypeId = id
        const response = await this.post(`/v3/resources/types/edit`, data, params)
        return get(response, "data", {})
    }

    async deleteResourceType(resourceTypeIds) {
        const response = await this.post(`/v3/resources/types/delete`, {resourceTypeIds})
        return get(response, "data", {})
    }
}

export default ResourceTypeService
