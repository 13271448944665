import {PermissionUserType, PermissionType, BaseDepartmentPermission} from "types/permission"
import {
    checkPermission,
    checkDocumentDepartmentPermission,
    checkDepartmentActivitiesPermission,
    checkTaskDepartmentPermission,
    checkBaseDepartmentPermission
} from "helpers"
import {useModel} from "./useModel"

export const useCurrentProfilePermissions = () => {
    const model = useModel()

    const hasEnabledPermissions = (permissions: PermissionUserType): boolean => {
        return checkPermission(permissions, model)
    }

    const hasDepartmentPermissionToListView = (departmentId: number, subunitId: number = null): boolean => {
        return checkDocumentDepartmentPermission(PermissionType.ListView, model, departmentId, subunitId)
    }

    const hasDepartmentPermissionToView = (departmentId: number, subunitId: number = null): boolean => {
        return checkDocumentDepartmentPermission(PermissionType.View, model, departmentId, subunitId)
    }

    const hasDepartmentPermissionToAdd = (departmentId: number, subunitId: number = null): boolean => {
        return checkDocumentDepartmentPermission(PermissionType.Add, model, departmentId, subunitId)
    }

    const hasDepartmentPermissionToEdit = (departmentId: number, subunitId: number = null): boolean => {
        return checkDocumentDepartmentPermission(PermissionType.Edit, model, departmentId, subunitId)
    }

    const hasDepartmentPermissionToDelete = (departmentId: number, subunitId: number = null): boolean => {
        return checkDocumentDepartmentPermission(PermissionType.Delete, model, departmentId, subunitId)
    }

    const hasDepartmentActivitiesPermissionToView = (departmentId: number, subunitId: number = null): boolean => {
        return checkDepartmentActivitiesPermission(PermissionType.View, model, departmentId, subunitId)
    }

    const hasDepartmentActivitiesPermissionToAdd = (departmentId: number, subunitId: number = null): boolean => {
        return checkDepartmentActivitiesPermission(PermissionType.Add, model, departmentId, subunitId)
    }

    const hasDepartmentActivitiesPermissionToEdit = (departmentId: number, subunitId: number = null): boolean => {
        return checkDepartmentActivitiesPermission(PermissionType.Edit, model, departmentId, subunitId)
    }

    const hasDepartmentActivitiesPermissionToDelete = (departmentId: number, subunitId: number = null): boolean => {
        return checkDepartmentActivitiesPermission(PermissionType.Delete, model, departmentId, subunitId)
    }

    const hasDepartmentTasksPermissionToView = (departmentId: number, subunitId: number = null): boolean => {
        return checkTaskDepartmentPermission(PermissionType.View, model, departmentId, subunitId)
    }

    const hasDepartmentTasksPermissionToAdd = (departmentId: number, subunitId: number = null): boolean => {
        return checkTaskDepartmentPermission(PermissionType.Add, model, departmentId, subunitId)
    }

    const hasDepartmentTasksPermissionToEdit = (departmentId: number, subunitId: number = null): boolean => {
        return checkTaskDepartmentPermission(PermissionType.Edit, model, departmentId, subunitId)
    }

    const hasDepartmentTasksPermissionToDelete = (departmentId: number, subunitId: number = null): boolean => {
        return checkTaskDepartmentPermission(PermissionType.Delete, model, departmentId, subunitId)
    }

    const hasBasePermissionToView = (departmentId: number, value: BaseDepartmentPermission): boolean => {
        return checkBaseDepartmentPermission(PermissionType.View, model, departmentId, value)
    }

    const hasBasePermissionToAdd = (departmentId: number, value: BaseDepartmentPermission): boolean => {
        return checkBaseDepartmentPermission(PermissionType.Add, model, departmentId, value)
    }

    const hasBasePermissionToEdit = (departmentId: number, value: BaseDepartmentPermission): boolean => {
        return checkBaseDepartmentPermission(PermissionType.Edit, model, departmentId, value)
    }

    const hasBasePermissionToDelete = (departmentId: number, value: BaseDepartmentPermission): boolean => {
        return checkBaseDepartmentPermission(PermissionType.Delete, model, departmentId, value)
    }

    return {
        hasPermissions: hasEnabledPermissions,
        baseDepartment: {
            hasPermissionsToView: hasBasePermissionToView,
            hasPermissionsToAdd: hasBasePermissionToAdd,
            hasPermissionsToEdit: hasBasePermissionToEdit,
            hasPermissionsToDelete: hasBasePermissionToDelete
        },
        departmentsDocuments: {
            hasPermissionsToListView: hasDepartmentPermissionToListView,
            hasPermissionsToView: hasDepartmentPermissionToView,
            hasPermissionsToAdd: hasDepartmentPermissionToAdd,
            hasPermissionsToEdit: hasDepartmentPermissionToEdit,
            hasPermissionsToDelete: hasDepartmentPermissionToDelete
        },
        departmentActivities: {
            hasPermissionsToView: hasDepartmentActivitiesPermissionToView,
            hasPermissionsToAdd: hasDepartmentActivitiesPermissionToAdd,
            hasPermissionsToEdit: hasDepartmentActivitiesPermissionToEdit,
            hasPermissionsToDelete: hasDepartmentActivitiesPermissionToDelete
        },
        departmentTasks: {
            hasPermissionsToView: hasDepartmentTasksPermissionToView,
            hasPermissionsToAdd: hasDepartmentTasksPermissionToAdd,
            hasPermissionsToEdit: hasDepartmentTasksPermissionToEdit,
            hasPermissionsToDelete: hasDepartmentTasksPermissionToDelete
        }
    }
}
