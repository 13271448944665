import {get} from "lodash"
import BaseService from "./Base"
import {TermCalendarProgram, TermCourse} from "types/terms"

class TermsService extends BaseService {
    async getAllTerms(params = {}) {
        const response = await this.get("/v1/terms/", params)
        return get(response, "data", [])
    }

    async getTerm(id: number, params = {}) {
        const response = await this.get(`/v1/terms/${id}`, params)
        return get(response, "data.data", [])
    }

    async getCampusesByTermV2(id, params = {}) {
        params = {termId: +id}
        const response = await this.post(`/v1/campuses/by-terms-v2/`, params)
        return get(response, "data.data", [])
    }

    async getCourses(params = {}) {
        const response = await this.get("/v1/courses/", params)
        return get(response, "data.data", [])
    }

    async getCoursesByTerm(id, params = {}) {
        const response = await this.get(`/v1/courses/by-terms/${id}`, params)
        return get(response, "data.data", [])
    }

    async getOneCourse(params = {}) {
        const response = await this.get(`/v1/courses/${params}`, params)
        return get(response, "data", [])
    }

    async postCourses(params = {}) {
        const response = await this.post("/v1/courses/", params)
        return get(response, "data", [])
    }

    async putCourses(params = {}, id) {
        const response = await this.put(`/v1/courses/${id}`, params)
        return get(response, "data", [])
    }

    async deleteCourses(params = {}) {
        const response = await this.delete(`/v1/courses/${params}`, params)
        return get(response, "data", [])
    }

    async postTerms(data, params = {}) {
        const response = await this.post("/v1/terms/create", data, params)
        return get(response, "data", [])
    }

    async updateTerm(id, data, params = {}) {
        const response = await this.put(`/v1/terms/${id}`, data, params)
        return get(response, "data", [])
    }

    async deleteTerm(id) {
        const response = await this.delete(`/v1/terms/delete`, {termsIds: [id]})
        return get(response, "data", [])
    }

    async duplicate(data = {}) {
        const response = await this.post(`/v1/terms/duplicate`, data)
        return get(response, "data.data", {})
    }

    async duplicateMulti(termIds: number[]) {
        const response = await this.post(`/v1/terms/duplicate-multi`, {termIds})
        return get(response, "data.data", {})
    }

    async deleteMulti(termsIds: number[]) {
        const response = await this.post(`/v1/terms/delete`, {termsIds})
        return get(response, "data.data", {})
    }

    async postLocalizationsUser(params = {}) {
        const response = await this.get("/v1/courses/", params)
        return get(response, "data", [])
    }

    async getTermPrograms(params = {}) {
        const response = await this.post(`/v1/term-program-coa/summary/list`, params)
        return get(response, "data", {})
    }

    async approveTermProgramsCoa(termProgramIds) {
        const response = await this.post(`/v1/term-program-coa/summary/approve`, {termProgramIds})
        return get(response, "data", {})
    }

    async getCourseOfTerm(params = {}): Promise<{data: TermCourse[]; total: string}> {
        const response = await this.post(`/v1/terms/courses/list`, params)
        return get(response, "data", {})
    }

    async getTermByMajor(majorId, params = {}) {
        const response = await this.get(`/v1/terms/byMajor/${majorId}`, params)
        return get(response, "data", {})
    }

    async getListTermsCalendarBased(params) {
        const response = await this.post(`/v1/term-calendar-base/list-terms`, params)
        return get(response, "data.data", {})
    }

    async addTermsCalendarBased(params) {
        const response = await this.post(`/v1/term-calendar-base/add-term`, params)
        return get(response, "data", {})
    }

    async updateTermsCalendarBased(params) {
        const response = await this.post(`/v1/term-calendar-base/update-term`, params)
        return get(response, "data", {})
    }

    async removeTermsCalendarBased(params) {
        const response = await this.post(`/v1/term-calendar-base/remove-term`, params)
        return get(response, "data", {})
    }

    async addCourseCalendarBased(params) {
        const response = await this.post(`/v1/term-calendar-base/add-course`, params)
        return get(response, "data", {})
    }

    async updateCourseCalendarBased(params) {
        const response = await this.post(`/v1/term-calendar-base/update-course`, params)
        return get(response, "data", {})
    }

    async removeCourseCalendarBased(params) {
        const response = await this.post(`/v1/term-calendar-base/remove-course`, params)
        return get(response, "data", {})
    }

    async addEventCalendarBased(params) {
        const response = await this.post(`/v1/term-calendar-base/add-event`, params)
        return get(response, "data", {})
    }

    async updateEventCalendarBased(params) {
        const response = await this.post(`/v1/term-calendar-base/update-event`, params)
        return get(response, "data", {})
    }

    async removeEventCalendarBased(params) {
        const response = await this.post(`/v1/term-calendar-base/remove-event`, params)
        return get(response, "data", {})
    }

    async getTermCalendarPrograms(params): Promise<{data: TermCalendarProgram[]; total: number}> {
        const response = await this.post(`/v2/term-calendar-programs/list`, params)
        return get(response, "data", {})
    }

    async editTermCalendarPrograms(params) {
        const response = await this.post(`/v2/term-calendar-programs/edit`, params)
        return get(response, "data", {})
    }

    async getListSelectableTerms(params) {
        const response = await this.post(`/v1/term-calendar-base/list-selectable-terms`, params)
        return get(response, "data.data", {})
    }

    async copyTerm(params) {
        const response = await this.post(`v1/terms/copy`, params)
        return get(response, "data.data", {})
    }
}

export default TermsService
