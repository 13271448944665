import {get} from "lodash"
import BaseService from "./Base"

class InstructionalService extends BaseService {
    getMyCourse = async (body) => {
        const response = await this.post("v3/instructional/courses/list", body)
        return get(response, "data", {})
    }
    getInstructionalMyCourse = async (body) => {
        const response = await this.post("v3/instructional/my-courses", body)
        return get(response, "data", {})
    }
    getMajors = async (body) => {
        const response = await this.post("v3/academic-plans/term-courses/majors", body)
        return get(response, "data", {})
    }
    getTermAndCourseList = async (body) => {
        const response = await this.post("v3/instructional/term-and-course/list", body)
        return get(response, "data", {})
    }
    getCurrentTerm = async (body) => {
        const response = await this.post("v3/instructional/courses/list/current", body)
        return get(response, "data", {})
    }

    getStudentCourses = async (body) => {
        const response = await this.post("v3/instructional/student-courses/list", body)
        return get(response, "data", {})
    }
}

export default InstructionalService
