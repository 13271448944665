import {useCallback} from "react"
import {useQuery, useQueryClient} from "@tanstack/react-query"
import {departmentsService} from "services"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {DepartmentWithLinkedEntities} from "types/departments"

const queryCacheKey = "all-departments"
const INITIAL_DATA: DepartmentWithLinkedEntities[] = []

export const useAllDepartments = () => {
    const queryClient = useQueryClient()

    const query = useQuery(
        [queryCacheKey],
        async () => {
            const {data: departments} = await departmentsService.getAll({
                sort: {orderBy: "name", orderDir: "asc"},
                range: {
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE
                },
                linkedEntities: true
            })
            return departments
        },
        {
            refetchOnMount: false
        }
    )

    return {
        departments: query.data ?? INITIAL_DATA,
        invalidateDepartmentsCache: useCallback(() => {
            queryClient.invalidateQueries({queryKey: [queryCacheKey]})
        }, [queryClient])
    }
}
