import React from "react"
import classNames from "classnames"
import {useHistory, Link} from "react-router-dom"
import {Icon} from "components/Icon"
import styles from "./SideBar.module.css"

export function Menu(props) {
    const history = useHistory()
    const {menu, onOpenChildMenus, isActive} = props
    const {icon, title, url, childs} = menu
    const isEmptyChild = !childs || !childs.length

    const onClickMenu = () => {
        if (!childs || !childs.length) {
            history.push(url)
        } else {
            onOpenChildMenus(menu)
        }
    }

    const renderContent = () => {
        return (
            <>
                {!!icon && (
                    <span className={styles.menuIcon}>
                        <Icon icon={icon} />
                    </span>
                )}
                <p className={styles.menuTitle}>{title}</p>
            </>
        )
    }

    if (isEmptyChild) {
        return (
            <Link to={url}>
                <div
                    className={classNames(styles.menu, {
                        [styles.menuActive]: isActive
                    })}>
                    {renderContent()}
                </div>
            </Link>
        )
    }

    return (
        <div
            onClick={onClickMenu}
            className={classNames(styles.menu, {
                [styles.menuActive]: isActive
            })}>
            {renderContent()}
        </div>
    )
}
