import React, {useState} from "react"
import {Row, Col} from "antd"
import {KlassDropdown} from "components/Select"
import {BaseNewFilter} from "uiKit"
import LessonsTable from "./LessonsTable"
import {LessonStatus} from "types/attendance"
import {cloneDeep} from "lodash"
import {BaseButton, BaseLoading} from "components"
import {PermissionsRequired} from "components/PermissionsRequired"
import {useVisible} from "hooks"
import {LessonPopup} from "../LessonPopup"
import styles from "./LessonsTab.module.css"
import {Course} from "types/courses"
import {Permissions} from "types/permission"

const LESSON_STATUS_ALL = "all"

export const LessonFilterStatus = [
    {value: LESSON_STATUS_ALL, label: "All", short: "All"},
    {value: LessonStatus.Active, label: "Active", short: "Active"},
    {value: LessonStatus.Inactive, label: "Inactive", short: "Inactive"},
    {value: LessonStatus.Paused, label: "Paused", short: "Paused"},
    {value: LessonStatus.Ended, label: "Ended", short: "Ended"}
]

type Props = {
    courseInfo: Course.CourseTableItem
    courseId: number
    scheduleId: number
    termId: number | "current"
    fromRoute: "registrar" | "instructional"
    activeSchedule: any
}

export function LessonsTab(props: Props) {
    const [filter, setFilter] = useState({
        lessonStatus: LessonFilterStatus[0],
        search: ""
    })
    const [appliedFilter, setAppliedFilter] = useState({
        lessonStatus: LessonFilterStatus[0],
        search: ""
    })
    const [renderKey, setRenderKey] = useState(1)
    const addLessonPopup = useVisible()

    const onChangeFilter = (key, value) => {
        const newFilter = {...filter}
        newFilter[key] = value
        setFilter(newFilter)
    }

    const onApplyFilter = () => {
        setAppliedFilter(cloneDeep(filter))
    }

    const onClickClearFilter = () => {
        setFilter({lessonStatus: LessonFilterStatus[0], search: ""})
        setAppliedFilter({lessonStatus: LessonFilterStatus[0], search: ""})
    }

    const onSearchInput = (input: string) => {
        setAppliedFilter({...appliedFilter, search: input})
        setFilter({...filter, search: input})
    }

    const addLesson = () => {
        addLessonPopup.open()
    }

    const addLessonSuccess = () => {
        addLessonPopup.close()
        setRenderKey(renderKey + 1)
    }

    return (
        <div>
            <BaseNewFilter
                filter={filter}
                onClick={onApplyFilter}
                onClickClear={onClickClearFilter}
                onSearchInput={onSearchInput}
                renderRightFilter={() => (
                    <PermissionsRequired
                        permissions={{staff: [Permissions.Staff.Academics.Instructional.Lessons.LessonDetails.Add]}}>
                        <div className={styles.addBtn}>
                            <BaseButton title="Add Lesson" onClick={addLesson} />
                        </div>
                    </PermissionsRequired>
                )}>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <KlassDropdown
                            placeholder="Lesson Status"
                            closeMenuOnSelect
                            value={filter.lessonStatus}
                            onChange={(newValue) => onChangeFilter("lessonStatus", newValue)}
                            options={LessonFilterStatus}
                            labelKey="label"
                            valueKey="value"
                        />
                    </Col>
                </Row>
            </BaseNewFilter>
            {props.courseInfo ? (
                <LessonsTable key={renderKey} {...props} filter={appliedFilter} />
            ) : (
                <BaseLoading isShow={true} />
            )}
            {addLessonPopup.isVisible && (
                <LessonPopup
                    campusIds={props.courseInfo?.campusIds}
                    isShow={addLessonPopup.isVisible}
                    activeSchedule={props.activeSchedule}
                    onClose={addLessonPopup.close}
                    onSuccess={addLessonSuccess}
                />
            )}
        </div>
    )
}
