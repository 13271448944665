import React from "react"
import {withTranslation} from "react-i18next"
import {get, isEmpty, keys} from "lodash"
import debounce from "debounce-promise"
import {KlassappTable, KlassappTableHeader, KlassappTableDropdown} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {BaseInput} from "components/inputs"
import {BaseButton} from "components/buttons"
import {KlassappTableProps} from "types/common"
import {UserType} from "types/tasks"
import {getFullName, validateEmail, handleErrorMessage, handleError, toastError} from "helpers"
import {userPersonalContactRolesServiceV3, userPersonalContactsServiceV3, userServiceV3} from "services"
import styles from "./SelectManualTable.module.css"
import cx from "classnames"

type PageProps = {
    t: Function
    history?: any
    signer: any
    primarySigner: any
    onUpdateSignerItem: Function
    isShowAction: boolean
    isSaving: boolean
    onCancelSelecting: () => void
    onClickSave: () => void
}

type Props = KlassappTableProps & PageProps

type State = {
    otherInfos: any
    roles: any[]
    errors: any
}

class SelectManualTable extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            otherInfos: {},
            roles: [],
            errors: {}
        }
        this.throttleOnChange = debounce(this.throttleOnChange.bind(this), 300)
    }

    async componentDidMount() {
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getData},
            {key: "getFields", func: this.getFields},
            {key: "getColumns", func: this.getColumns},
            {key: "onClickRowItem", func: this.onClickRowItem}
        ])
        this.props.dispatch({isLoading: true})
        await this.getPersonalContactByRole()
        this.getData()
    }

    getPersonalContactByRole = async () => {
        try {
            const {data} = await userPersonalContactRolesServiceV3.getAll()
            this.setState({roles: data})
        } catch (error) {
            handleErrorMessage(error)
        }
    }

    getPageTitle = () => {
        return "Other"
    }

    getFields = () => {
        return ["Primary", "Role", "Contact", "First Name", "Last Name", "Email"]
    }

    getData = async () => {
        const {dispatch, primarySigner} = this.props
        if (!primarySigner) {
            return
        }
        dispatch({isLoading: true})
        try {
            let key = ""
            if (primarySigner.userType === UserType.STUDENT) {
                key = "students"
            } else if (primarySigner.userType === UserType.STAFF) {
                key = "staffs"
            }
            const newData = (primarySigner[key] || []).map((user: any) => {
                user.primaryHtml = this.renderPrimaryHtml(user)
                user.firstNameHtml = this.renderFirstNameHtml(user)
                user.lastNameHtml = this.renderLastNameHtml(user)
                user.roleHtml = this.renderRoleHtml(user)
                user.contactHtml = this.renderContactHtml(user)
                user.emailHtml = this.renderEmailHtml(user)
                return user
            })
            dispatch({data: newData, total: (primarySigner[key] || []).length})
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    renderPrimaryHtml = (user) => {
        const avatarSrc = user.photo?.original
        return (
            <div className={styles.nameWrap}>
                {avatarSrc && <img src={avatarSrc} className={styles.avatar} alt="" />}
                <p className={styles.name}>
                    {getFullName(user)} {user.id}
                </p>
            </div>
        )
    }

    throttleOnChange = async (value) => {
        return await userServiceV3.isUserEmailAlreadyExist({email: value})
    }

    reloadList = () => {
        const newData = this.props.data.map((user) => {
            user.primaryHtml = this.renderPrimaryHtml(user)
            user.firstNameHtml = this.renderFirstNameHtml(user)
            user.lastNameHtml = this.renderLastNameHtml(user)
            user.roleHtml = this.renderRoleHtml(user)
            user.contactHtml = this.renderContactHtml(user)
            user.emailHtml = this.renderEmailHtml(user)
            return user
        })
        this.props.dispatch({data: newData})
    }

    checkEmailValid = async (userId, newValue) => {
        const {errors} = this.state
        const {t} = this.props
        if (!validateEmail(newValue)) {
            errors[userId] = {...errors[userId], email: t("message.emailNotValid")}
            this.setState({errors}, () => {
                this.reloadList()
            })
            return
        }
        const isEmailExist = await this.throttleOnChange(newValue)
        if (isEmailExist) {
            toastError(t("message.emailExists"))
            errors[userId] = {...errors[userId], email: t("message.emailExists")}
            this.reloadList()
            return
        }
    }

    getContactsByRole = async (userId: number, roleId: number) => {
        try {
            const {data} = await userPersonalContactsServiceV3.getAll({
                filter: {
                    userIds: [userId],
                    userPersonalContactRoleIds: [roleId]
                },
                linkedEntities: true,
                range: {
                    page: 1,
                    pageSize: 1000
                }
            })
            return data.map((item) => ({...item, ...item.contactProfile}))
        } catch (error) {
            return []
        }
    }

    onChangeOtherInfo = async (userId, key, newValue) => {
        const {otherInfos, errors} = this.state
        const {signer, onUpdateSignerItem, data, t} = this.props
        otherInfos[userId] = {...otherInfos[userId]} || {}
        otherInfos[userId][key] = newValue
        if (key === "userPersonalContactRole") {
            const contacts = await this.getContactsByRole(userId, newValue.userPersonalContactRoleId)
            otherInfos[userId].contacts = contacts
        } else if (key === "contactData") {
            if (newValue) {
                otherInfos[userId].firstName = newValue.firstName
                otherInfos[userId].lastName = newValue.lastName
                otherInfos[userId].email = newValue.email
            } else {
                otherInfos[userId].firstName = ""
                otherInfos[userId].lastName = ""
                otherInfos[userId].email = ""
            }
        }
        const newSignerOthers = data.map((user) => {
            const otherInfo = otherInfos[user.id] || {}
            const data = {
                primaryUserId: user.id,
                primaryProfileId: user.profileId,
                firstName: otherInfo.firstName,
                lastName: otherInfo.lastName,
                email: otherInfo.email,
                userPersonalContactRole: otherInfo.userPersonalContactRole
            }
            if (otherInfo.contactData) {
                return {...otherInfo.contactData, ...data}
            }
            return data
        })
        onUpdateSignerItem(signer.id, {...signer, others: newSignerOthers})
        errors[userId] = {
            ...errors[userId],
            [key]: !newValue ? t("validation.fieldRequired") : ""
        }
        if (key === "email" && newValue && !otherInfos[userId].contactData) {
            this.checkEmailValid(userId, newValue)
        }
        this.setState({otherInfos, errors}, () => {
            this.reloadList()
        })
    }

    renderFirstNameHtml = (user) => {
        const {otherInfos, errors} = this.state
        const data = otherInfos[user.id] || {}
        if (data.contactData) {
            return <span>{data.firstName}</span>
        }
        return (
            <div className={styles.inputWrap}>
                <BaseInput
                    value={get(otherInfos[user.id], "firstName", "")}
                    onChange={(newValue) => this.onChangeOtherInfo(user.id, "firstName", newValue)}
                    placeholder="First Name"
                />
                {errors[user.id]?.firstName && <sub className={styles.errorText}>{errors[user.id].firstName}</sub>}
            </div>
        )
    }

    renderLastNameHtml = (user) => {
        const {otherInfos, errors} = this.state
        const data = otherInfos[user.id] || {}
        if (data.contactData) {
            return <span>{data.lastName}</span>
        }
        return (
            <div className={styles.inputWrap}>
                <BaseInput
                    value={get(otherInfos[user.id], "lastName", "")}
                    onChange={(newValue) => this.onChangeOtherInfo(user.id, "lastName", newValue)}
                    placeholder="Last Name"
                />
                {errors[user.id]?.lastName && <sub className={styles.errorText}>{errors[user.id].lastName}</sub>}
            </div>
        )
    }

    renderNameHtml = (item) => {
        return (
            <div className={styles.nameWrap}>
                <img src="/image/DefaultAvatar.png" className={styles.avatar} alt="" />
                <p className={styles.name}>{item.name}</p>
            </div>
        )
    }

    renderRoleHtml = (user) => {
        const {roles, otherInfos} = this.state
        const roleValue = get(otherInfos[user.id], "userPersonalContactRole", null)
        return (
            <KlassappTableDropdown
                options={roles}
                value={roleValue}
                valueKey="userPersonalContactRoleId"
                onChange={(value) => this.onChangeOtherInfo(user.id, "userPersonalContactRole", value)}
                placeholder="Role"
            />
        )
    }

    renderContactHtml = (user) => {
        const {otherInfos} = this.state
        const contacts = get(otherInfos[user.id], "contacts", []) || []
        const contactData = get(otherInfos[user.id], "contactData", null)
        return (
            <KlassappTableDropdown
                options={contacts}
                value={contactData}
                getOptionLabel={(option: any) => getFullName(option)}
                onChange={(value) => this.onChangeOtherInfo(user.id, "contactData", value)}
                placeholder="Contact"
                isClearable
            />
        )
    }

    renderEmailHtml = (user) => {
        const {otherInfos, errors} = this.state
        const data = otherInfos[user.id] || {}
        if (data.contactData) {
            return <span>{data.email}</span>
        }
        return (
            <div className={styles.inputWrap}>
                <BaseInput
                    value={get(otherInfos[user.id], "email", "")}
                    onChange={(newValue) => this.onChangeOtherInfo(user.id, "email", newValue)}
                    placeholder="Email"
                    className={cx({
                        [styles.error]: errors[user.id]?.email
                    })}
                />
                {errors[user.id]?.email && <sub className={styles.errorText}>{errors[user.id].email}</sub>}
            </div>
        )
    }

    getColumns = () => {
        return [
            {
                title: "Primary",
                field: "primaryHtml",
                style: {minWidth: 250}
            },
            {
                title: "Role",
                field: "roleHtml",
                style: {minWidth: 200}
            },
            {
                title: "Contact",
                field: "contactHtml",
                style: {minWidth: 200}
            },
            {
                title: "First Name",
                field: "firstNameHtml",
                style: {minWidth: 200}
            },
            {
                title: "Last Name",
                field: "lastNameHtml",
                style: {minWidth: 200}
            },
            {
                title: "Email",
                field: "emailHtml",
                style: {minWidth: 200}
            },
            {
                title: "Add to Contacts",
                field: "addToContact",
                style: {minWidth: 150}
            }
        ]
    }

    onClickRowItem = (data) => {}

    onSave = () => {
        const {errors} = this.state
        let emailError = null
        const hasError = keys(errors).some((userId) => {
            if (isEmpty(errors[userId])) {
                return false
            }
            emailError = !isEmpty(errors[userId]) ? errors[userId]?.email : ""
            return keys(errors[userId]).some((field) => !isEmpty(errors[userId][field]))
        })
        if (hasError) {
            if (emailError) {
                toastError(emailError)
                return
            }
            toastError("Some fields has error")
        } else {
            this.props.onClickSave()
        }
    }

    render() {
        const {
            page,
            total,
            pageSize,
            columns,
            data,
            allFields,
            fields,
            orderField,
            isLoading,
            t,
            isShowAction,
            onCancelSelecting,
            isSaving
        } = this.props

        return (
            <div className={styles.wrap}>
                <KlassappTableHeader
                    isShowAction={false}
                    actions={[]}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.props.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    data={data}
                    menuActions={[]}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    orderField={orderField}
                    isShowCheckedColumn
                    onClickRowItem={this.onClickRowItem}
                    onChangeFields={this.props.onChangeFields}
                    onUpdateRowData={this.props.onUpdateRowData}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onClickSortColumn={this.props.onClickSortColumn}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onChangeAllFields={this.props.onChangeAllFields}
                />
                {isShowAction && (
                    <div className={styles.actionBtnWrap}>
                        <BaseButton
                            variant="secondary"
                            title={t("common:action.cancel")}
                            className={styles.cancelBtn}
                            onClick={onCancelSelecting}
                        />
                        <BaseButton title={t("common:action.save")} onClick={this.onSave} loading={isSaving} />
                    </div>
                )}
            </div>
        )
    }
}

export default KlassappTableHOC(withTranslation(["common"])(SelectManualTable))
