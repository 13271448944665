import React, {useState} from "react"
import {BasePopup} from "components/popup"
import {Icon} from "components/Icon"
import {BaseButton} from "components/buttons"
import {academicPlansService, majorService, termsService} from "services"
import styles from "./ConfirmRemoveCoursePopup.module.css"
import {handleError} from "helpers"

type Props = {
    id?: number
    groupId?: string
    isShow: boolean
    isInMajorVersion: boolean
    isInTermSettingPreview: boolean
    isInAcademicPlan: boolean
    onClose: () => void
    reloadView: () => void
}

export function ConfirmRemoveCoursePopup(props: Props) {
    const {id, groupId, isShow, isInMajorVersion, isInTermSettingPreview, isInAcademicPlan, onClose, reloadView} = props
    const [isDeleting, setIsDeleting] = useState(false)

    const onConfirm = async () => {
        try {
            setIsDeleting(true)
            if (isInMajorVersion) {
                await majorService.removeCourseCalendarBased({id, groupId})
            } else if (isInTermSettingPreview) {
                await termsService.removeCourseCalendarBased({id})
            } else if (isInAcademicPlan) {
                await academicPlansService.removeCourseCalendarBased({id})
            }
            onClose()
            reloadView()
        } catch (error) {
            handleError(error)
        } finally {
            setIsDeleting(false)
        }
    }

    return (
        <BasePopup isShow={isShow} onClose={onClose} isShowLeftSide={false} width="400px">
            <div>
                <div className={styles.deleteIconWrap}>
                    <Icon icon="DELETE" className={styles.deleteIcon} />
                </div>
                <p className={styles.content}>
                    {groupId ? "Are you sure you want to remove courses?" : "Are you sure you want to remove a course?"}
                </p>
                <div className={styles.action}>
                    <BaseButton title="Cancel" variant="secondary" onClick={onClose} />
                    <BaseButton title="Confirm" onClick={onConfirm} loading={isDeleting} />
                </div>
            </div>
        </BasePopup>
    )
}
