/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useMemo, useState} from "react"
import {useTranslation} from "react-i18next"
import moment from "moment"
import classNames from "classnames"
import {KlassappTableSubCategory} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {coreSocket, settingStatusService, studentService, studentServiceV2, userProfileServiceV3} from "services"
import {getFullName, handleError, truncateWithEllipses} from "helpers"
import {Auth} from "types/auth"
import {BaseDepartmentId, DepartmentKeyNameByIdForPriority} from "types/departments"
import styles from "./StudentPriorityTable.module.css"
import {useModel} from "hooks/useModel"
import {Icon} from "components/Icon"
import {useVisible} from "hooks"
import {ActivityPopup} from "../../student"
import {Button, Col, Row} from "antd"
import {KlassappTableProps} from "types/common"
import {isEmpty, random, uniqBy} from "lodash"
import debounce from "lodash/debounce"
import {SOCKET_ROOM_PRIORITY_TABLE, SocketEvent} from "helpers/socket"
import CircularSpin from "components/CircularSpin"
import {DUE_COLOR_OPTIONS} from "types/contact-strategy"
import {emitEvent, offEvent, onEvent} from "helpers/eventHelper"
import {
    EVENT_STUDENT_ACTIVITY_COMPLETION_TOGGLED,
    EVENT_STUDENT_ACTIVITY_CREATED,
    StudentActivity
} from "types/activity"
import {KlassDropdown} from "components"
import {STATES_OPTIONS} from "types/students"
import {ChatModalV2} from "sections/NewCommunication/shared"
import {FieldOperator, FilterKey, FilterList} from "types/filter"
import {useAllAvailableStudentStatuses} from "hooks/student-statuses/useAllAvailableStudentStatuses"
import {useAllLeadTypes} from "hooks/useAllLeadTypes"
import {useAllFinancialAidStatuses} from "hooks/student-statuses/useAllFinancialAidStatuses"
import {FinancialAidStatus} from "types/fin-aid/financial-aid-status"
import {useAllLeadSources} from "hooks/useAllLeadSources"
import {ColumnPicker} from "uiKit/table/parts"

type TableProps = KlassappTableProps & {
    statusChange?: Auth.StudentDepartmentStatusDetails
    studentStatusChange?: {profileId: number; statusId: number}
    financialAidStatusChange?: {profileId: number; statusId: number}
    departmentId: number
    studentId?: number
    studentForm: Record<string, any>
    studentFormSearch: string
    updateState: React.Dispatch<any>
    canShowAllStudents: boolean
    canHaveAccessOthers: boolean
    filterMemoryKey: FilterKey
    enrollmentProfileIdChange?: Auth.StudentDepartmentEnrollmentChangeProfileId
    useNewFilter?: boolean
}

type Status = {
    statusId: number
    name: string
    statusName?: string
    isActive?: boolean | 0 | 1
    userProfilesCount?: number
    hexColor?: string
    stage?: any
    state?: any
}

type LoadMoreStatuses = Record<
    number,
    {
        done: boolean
        isCollapsed: boolean
        loaded: number
    }
>

const StudentPriorityTable: React.FC<TableProps> = ({
    data,
    columns,
    fields,
    allFields,
    isLoading,
    orderField,
    getCurrentData,
    dispatch,
    dispatchFunc,
    onChangeFields,
    onUpdateRowData,
    onUpdateTableData,
    updateState,
    onClickSortColumn,
    onDraggableColumn,
    onChangeAllFields,
    statusChange,
    studentStatusChange,
    financialAidStatusChange,
    departmentId,
    studentId,
    canShowAllStudents,
    canHaveAccessOthers,
    filterMemoryKey,
    studentForm,
    studentFormSearch,
    enrollmentProfileIdChange,
    useNewFilter = false
}) => {
    const model = useModel()
    const {t} = useTranslation(["common"])
    const userDateFormat = model.getUserDateFormat()
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const filterUserList = model.filterUserList
    const chatPopup = useVisible(false)
    const activityPopup = useVisible(false)
    const [selectedStudent, setSelectedStudent] = React.useState<Auth.DepartmentStudent | undefined>()
    const activeStudentRef = React.useRef<number | undefined>()

    const {leadTypes} = useAllLeadTypes()
    const {leadSources} = useAllLeadSources()
    const [statuses, setStatuses] = useState<Status[]>([])
    const visibleStatuses: Status[] = React.useMemo(() => {
        const filters = {...studentForm, ...filterUserList}
        if (!!filters.status?.length) {
            return statuses.filter((status) => filters.status.find(({statusId}) => statusId === status.statusId))
        }
        return statuses
    }, [statuses, studentForm, filterUserList])

    const {statuses: availableStudentStatuses, isLoading: isLoadingStudentStatuses} = useAllAvailableStudentStatuses()
    const studentStatuses = useMemo(
        () => [
            ...availableStudentStatuses.map((status: Status) => ({
                ...status,
                statusName: status.name,
                name:
                    status.name +
                    (status.state ? ` (${STATES_OPTIONS.find((option) => option.id === status.state).name})` : "")
            })),
            {statusId: 0, statusName: "Status not defined", name: "Status not defined", isActive: true}
        ],
        [availableStudentStatuses]
    )
    const visibleStudentStatuses: Status[] = React.useMemo(() => {
        const filters = {...studentForm, ...filterUserList}
        // TODO
        if (useNewFilter) {
            if (!!filters.studentStatusIds?.value) {
                return studentStatuses.filter(
                    (status) =>
                        status.isActive &&
                        filters.studentStatusIds.value.find(({statusId}) => statusId === status.statusId)
                )
            }
        } else {
            if (!!filters.studentStatusIds?.length) {
                return studentStatuses.filter(
                    (status) =>
                        status.isActive && filters.studentStatusIds.find(({statusId}) => statusId === status.statusId)
                )
            }
        }
        return studentStatuses.filter((status) => status.isActive)
    }, [studentStatuses, studentForm, filterUserList, useNewFilter])

    const {statuses: availableFinancialAidStatuses, isLoading: isLoadingFAStatuses} = useAllFinancialAidStatuses()
    const financialAidStatuses = useMemo(
        () => [
            ...availableFinancialAidStatuses.map((status: FinancialAidStatus) => ({
                ...status,
                statusName: status.name,
                name: status.name,
                userProfilesCount: status.itemCount
            })),
            {statusId: 0, statusName: "Status not defined", name: "Status not defined", isActive: true}
        ],
        [availableFinancialAidStatuses]
    )
    const visibleFinancialAidStatuses: Status[] = React.useMemo(() => {
        const filters = {...studentForm, ...filterUserList}
        if (!!filters.financialAidStatuses?.length) {
            return financialAidStatuses.filter((status) =>
                filters.financialAidStatuses.find(({statusId}) => statusId === status.statusId)
            )
        }
        return financialAidStatuses
    }, [financialAidStatuses, studentForm, filterUserList])

    const [isFirstLoaded, setFirstLoaded] = useState(false)
    const [isLoadingMore, setLoadingMore] = React.useState(false)
    const loadingSeqRef = React.useRef(0)
    const loadMoreStatusesRef = React.useRef<LoadMoreStatuses>(
        Object.keys(model.getPriorityViewCollapsible()).reduce((loadMoreStatuses, statusId) => {
            loadMoreStatuses[+statusId] = {
                done: false,
                isCollapsed: !!model.getPriorityViewCollapsible()[+statusId],
                loaded: 0
            }
            return loadMoreStatuses
        }, {})
    )

    const renderStatusGroupTitle = React.useCallback(
        (status: Status, total: number, isCollapsed: boolean) => (
            <Row gutter={8} align="middle">
                <Col>
                    {status.name} - ({total})
                </Col>
                <Col onClick={() => toggleCollapseStatusId(status.statusId)}>
                    <Icon
                        icon={isCollapsed ? "EYE" : "EYE_CROSSED_LINE"}
                        className={classNames(styles.statusCollapseIcon, {
                            [styles.isCollapsed]: !isCollapsed
                        })}
                    />
                </Col>
            </Row>
        ),
        []
    )

    const renderTextHtml = React.useCallback((text: string | number) => {
        return <span className={styles.userId}>{text}</span>
    }, [])

    const renderListHtml = React.useCallback((list?: any[], field?: string) => {
        return <span>{list?.map((item) => (field ? item[field] : item)).join(", ")}</span>
    }, [])

    const renderStatusHtml = React.useCallback(
        (statusId: number, statusName?: string) => {
            let status: Status | undefined
            visibleStatuses.forEach((_status) => {
                if (_status.statusId === statusId) {
                    status = _status
                }
            })
            const hexColor = status?.hexColor || "#939393"

            return (
                <div className={styles.statusContainer}>
                    {!!(status || statusName) && (
                        <span className={styles.status} style={{background: hexColor}}>
                            {truncateWithEllipses(status?.name || statusName || "", 12, 4, 5)}
                        </span>
                    )}
                </div>
            )
        },
        [visibleStatuses]
    )

    const renderStudentStatusHtml = React.useCallback(
        (statusId: number, statusName?: string) => {
            let status: Status | undefined
            visibleStudentStatuses.forEach((_status) => {
                if (_status.statusId === statusId) {
                    status = _status
                }
            })
            const hexColor = status?.hexColor || "#939393"

            return (
                <div className={styles.statusContainer}>
                    {!!(status || statusName) && (
                        <span className={styles.status} style={{background: hexColor}}>
                            {truncateWithEllipses(status?.name || statusName || "", 12, 4, 5)}
                        </span>
                    )}
                </div>
            )
        },
        [visibleStudentStatuses]
    )

    const renderFinancialAidStatusHtml = React.useCallback(
        (statusId: number, statusName?: string) => {
            let status: Status | undefined
            visibleFinancialAidStatuses.forEach((_status) => {
                if (_status.statusId === statusId) {
                    status = _status
                }
            })
            const hexColor = status?.hexColor || "#939393"

            return (
                <div className={styles.statusContainer}>
                    {!!(status || statusName) && (
                        <span className={styles.status} style={{background: hexColor}}>
                            {truncateWithEllipses(status?.name || statusName || "", 12, 4, 5)}
                        </span>
                    )}
                </div>
            )
        },
        [visibleFinancialAidStatuses]
    )

    const renderChecklistStatusHtml = React.useCallback((status: Auth.CheckListStatus) => {
        return (
            <div className={styles.statusContainer}>
                <span className={classNames(styles.status, styles[`status-${status}`])}>{status}</span>
            </div>
        )
    }, [])

    const updateProfileAdmissionLeadSource = React.useCallback(
        async (profileId: number, admissionsLeadSourceId: number) => {
            try {
                await userProfileServiceV3.update({id: profileId, admissionsLeadSourceId})
            } catch (error) {
                handleError(error)
            }
        },
        []
    )

    const updateProfileLeadType = React.useCallback(async (profileId: number, leadTypeId: number) => {
        try {
            await userProfileServiceV3.update({id: profileId, leadTypeId})
        } catch (error) {
            handleError(error)
        }
    }, [])

    const renderApplicationFeeHtml = React.useCallback((applicationFee: string) => {
        switch (applicationFee) {
            case Auth.ApplicationFee.Pay:
                return "Pay"
            case Auth.ApplicationFee.NotPay:
                return "Not Pay"
            case Auth.ApplicationFee.Wait:
                return "Wait"
            default:
                return ""
        }
    }, [])

    const renderTickHtml = React.useCallback((record: Auth.DepartmentStudent) => {
        return (
            <div
                onClick={(e) => {
                    e.stopPropagation()
                    setSelectedStudent(record)
                    activityPopup.open()
                }}>
                <Icon icon="CHECKMARK_CIRCLE" className={styles.tableIcon} />
            </div>
        )
    }, [])

    const renderChatHtml = React.useCallback((record: Auth.DepartmentStudent) => {
        return (
            <div
                onClick={(e) => {
                    e.stopPropagation()
                    setSelectedStudent(record)
                    chatPopup.open()
                }}>
                <Icon icon="MESSAGE" className={styles.tableIcon} />
            </div>
        )
    }, [])

    const getStudentRecord = React.useCallback(
        (student: Auth.DepartmentStudent): Auth.DepartmentStudent & Record<string, any> => {
            const statusId = model.clientSetting?.isNewStudentStatusesVisible
                ? student.statusId
                : student[`${DepartmentKeyNameByIdForPriority[departmentId]}StatusId`]
            const departmentDue = student.departmentDues?.find((due) => due.departmentId === departmentId)
            return {
                ...student,
                id: `${statusId}_${student.profileId}`,
                userIdHtml: renderTextHtml(student.userId),
                firstName: student.firstName || "",
                name: getFullName(student),
                campusHtml: renderListHtml(student.campuses),
                statusHtml: renderStatusHtml(
                    student[`${DepartmentKeyNameByIdForPriority[departmentId]}StatusId`],
                    student[`${DepartmentKeyNameByIdForPriority[departmentId]}Status`]
                ),
                studentStatusHtml: renderStudentStatusHtml(student.statusId),
                financialAidStatusHtml: renderFinancialAidStatusHtml(student.financialAidStatusId),
                createdAt: moment(student.createdAt).format(userDateTimeFormat),
                lastActivityDate: student.latestActivity
                    ? moment(student.latestActivity.updatedAt).format(userDateTimeFormat)
                    : "",
                lastActivity: student.latestActivity?.name,
                rowStyle: departmentDue ? {background: departmentDue.dueColor || DUE_COLOR_OPTIONS[0].id} : undefined,
                admissionAdvisorsHtml: renderListHtml(student.admissionAdvisors, "fullName"),
                finAidAdvisorsHtml: renderListHtml(student.finAidAdvisors, "fullName"),
                admissionStatusHtml: renderStatusHtml(student.admissionStatusId, student.admissionStatus),
                finAidStatusHtml: renderStatusHtml(student.finAidStatusId, student.finAidStatus),
                admChecklistStatusHtml: renderChecklistStatusHtml(student.admChecklistStatus),
                faChecklistStatusHtml: renderChecklistStatusHtml(student.faChecklistStatus),
                applicationFeeHtml: renderApplicationFeeHtml(student.applicationFee),
                preferredTimeOfContact: student.preferredTimeOfContact,
                isActiveTableCol: student.profileId === activeStudentRef.current
            }
        },
        [
            model.clientSetting?.isNewStudentStatusesVisible,
            departmentId,
            renderTextHtml,
            renderListHtml,
            renderStatusHtml,
            renderStudentStatusHtml,
            renderFinancialAidStatusHtml,
            renderChecklistStatusHtml,
            renderApplicationFeeHtml
        ]
    )

    const getData = React.useCallback(
        async (loadMore = false, forceReload = false) => {
            if (!model.clientSetting?.isNewStudentStatusesVisible) {
                if (statuses.length < 2) return
                if (!visibleStatuses.length) {
                    dispatch({data: []})
                    return
                }
            }
            if (model.clientSetting?.isNewStudentStatusesVisible) {
                if (studentStatuses.length < 2) return
                if (!visibleStudentStatuses.length) {
                    dispatch({data: []})
                    return
                }
            }

            if (loadingSeqRef.current) {
                if (loadMore) return
            }

            const getFilter = () => {
                const filters = {...studentForm, ...filterUserList}
                const {
                    campus,
                    program,
                    advisor,
                    acadAdvisors,
                    faAdvisors,
                    admAdvisors,
                    term,
                    startingTerm,
                    checklist,
                    status,
                    state,
                    profileStatus,
                    applicationCompletedDate,
                    startDateRange,
                    createdAtRange,
                    inquiryDateRange,
                    lastActivityDateRange,
                    includeArchive = false,
                    showDueOnly = false,
                    fullyEnrolled,
                    onlySEV,
                    sapCheckpoints,
                    retentionLevels,
                    studentStatusIds,
                    financialAidStatuses,
                    admissionsLeadSourceIds,
                    leadTypeIds,
                    activityIds,
                    activityDescription
                } = filters
                let filter: any = {
                    departmentId,
                    includeArchive,
                    showDueOnly,
                    onlySEV,
                    activityDescription
                }
                if (fullyEnrolled) {
                    filter.fullyEnrolled = true
                }
                if (campus) {
                    filter.campusIds = campus.map(({id}) => id)
                }
                if (program) {
                    filter.programIds = program.map(({id}) => id)
                }

                if (!canShowAllStudents) {
                    const profile = model.user.profiles.find((profile) => profile.type === Auth.UserProfileType.Staff)
                    if (profile?.id) {
                        filter.advisorProfileIds = [profile.id]
                        if (canHaveAccessOthers && !isEmpty(advisor)) {
                            filter.advisorProfileIds = (advisor || [])
                                .map((item) => item?.profileId)
                                .filter((item) => item)
                        }
                    }
                } else {
                    filter.advisorProfileIds = (advisor || []).map((item) => item?.profileId).filter((item) => item)
                }

                if (admAdvisors?.length) {
                    filter.admAdvisorProfileIds = admAdvisors.map((profile) => profile.profileId)
                }
                if (faAdvisors?.length) {
                    filter.faAdvisorProfileIds = faAdvisors.map((profile) => profile.profileId)
                }
                if (acadAdvisors?.length) {
                    filter.acadAdvisorProfileIds = acadAdvisors.map((profile) => profile.profileId)
                }

                if (checklist === "completed") {
                    filter.checklistCompleted = true
                }
                if (checklist === "notCompleted") {
                    filter.checklistCompleted = false
                }
                if (status?.length) {
                    filter.statusIds = status.map(({statusId}) => statusId)
                }
                if (studentStatusIds?.length) {
                    filter.studentStatusIds = studentStatusIds.map(({statusId}) => statusId)
                }
                if (financialAidStatuses?.length) {
                    filter.financialAidStatusIds = financialAidStatuses.map(({statusId}) => statusId)
                }
                if (state) {
                    filter.states = state.map(({id}) => id)
                }
                if (profileStatus) {
                    filter.profileStatus = profileStatus.map(({id}) => id === "active")
                }
                if (term) {
                    filter.termsIds = term.map(({id}) => id)
                }
                if (startingTerm) {
                    filter.startingTermIds = startingTerm.map(({id}) => id)
                }
                if (admissionsLeadSourceIds) {
                    filter.admissionsLeadSourceIds = admissionsLeadSourceIds.map(({id}) => id)
                }
                if (leadTypeIds) {
                    filter.leadTypeIds = leadTypeIds
                }
                if (activityIds) {
                    filter.activityIds = activityIds.map(({activityId}) => activityId)
                }
                if (applicationCompletedDate) {
                    filter.applicationCompletedDate = moment(applicationCompletedDate).format("YYYY-MM-DD")
                }
                if (startDateRange && startDateRange.length) {
                    filter.startDateRange = [
                        moment(startDateRange[0]).format("YYYY-MM-DD"),
                        moment(startDateRange[1]).format("YYYY-MM-DD")
                    ]
                }
                if (createdAtRange && createdAtRange.length) {
                    filter.createdAtRange = [
                        moment(createdAtRange[0]).startOf("date").toISOString(),
                        moment(createdAtRange[1]).endOf("date").toISOString()
                    ]
                }
                if (inquiryDateRange && inquiryDateRange.length) {
                    filter.inquiryDateRange = [
                        moment(inquiryDateRange[0]).format("YYYY-MM-DD"),
                        moment(inquiryDateRange[1]).format("YYYY-MM-DD")
                    ]
                }
                if (lastActivityDateRange && lastActivityDateRange.length) {
                    filter.lastActivityDateRange = [
                        moment(lastActivityDateRange[0]).startOf("date").toISOString(),
                        moment(lastActivityDateRange[1]).endOf("date").toISOString()
                    ]
                }
                if (studentFormSearch) {
                    filter.search = studentFormSearch
                }
                if (sapCheckpoints) {
                    filter.sapCheckpoints = sapCheckpoints.map(({id}) => id)
                }
                if (retentionLevels) {
                    filter.retentionLevels = retentionLevels.map(({id}) => id)
                }
                return filter
            }

            const getNewFilter = (): any => {
                const filters = {...studentForm, ...filterUserList}
                const {
                    profiles,
                    campus,
                    program,
                    acadAdvisors,
                    faAdvisors,
                    admAdvisors,
                    term,
                    startingTerm,
                    checklist,
                    status,
                    state,
                    profileStatus,
                    applicationCompletedDate,
                    startDateRange,
                    createdAtRange,
                    inquiryDateRange,
                    lastActivityDateRange,
                    includeArchive,
                    showDueOnly,
                    fullyEnrolled,
                    onlySEV,
                    sapCheckpoints,
                    retentionLevels,
                    studentStatusIds,
                    admissionsLeadSourceIds,
                    leadTypeIds,
                    activityIds,
                    activityDescription
                } = filters
                let filter: FilterList = {}
                if (fullyEnrolled) filter.fullyEnrolled = fullyEnrolled
                if (includeArchive) filter.includeArchive = includeArchive
                if (showDueOnly) filter.showDueOnly = showDueOnly
                if (onlySEV) filter.onlySEV = onlySEV
                if (checklist) filter.checklistCompleted = checklist

                if (profiles) {
                    filter.profileIds = {
                        operator: profiles.operator,
                        value: profiles.value.map(({profileId}) => profileId)
                    }
                }
                if (campus) {
                    filter.campusIds = {
                        operator: campus.operator,
                        value: campus.value.map(({id}) => id)
                    }
                }
                if (program) {
                    filter.programIds = {
                        operator: program.operator,
                        value: program.value.map(({id}) => id)
                    }
                }
                if (studentStatusIds) {
                    filter.studentStatusIds = {
                        operator: studentStatusIds.operator,
                        value: studentStatusIds.value.map(({statusId}) => statusId)
                    }
                }
                if (admissionsLeadSourceIds) {
                    filter.admissionsLeadSourceIds = {
                        operator: admissionsLeadSourceIds.operator,
                        value: admissionsLeadSourceIds.value.map(({id}) => id)
                    }
                }
                if (leadTypeIds) {
                    filter.leadTypeIds = leadTypeIds
                }

                // if (!canShowAllStudents && !canHaveAccessOthers) {
                //     const profile = model.user.profiles.find((profile) => profile.type === Auth.UserProfileType.Staff)
                //     filter.advisorProfileIds = {
                //         operator: FieldOperator.Equal,
                //         value: [profile.id]
                //     }
                // }

                if (admAdvisors) {
                    filter.admAdvisorProfileIds = {
                        operator: admAdvisors.operator,
                        value: admAdvisors.value.map((profile) => profile.profileId)
                    }
                }
                if (faAdvisors) {
                    filter.faAdvisorProfileIds = {
                        operator: faAdvisors.operator,
                        value: faAdvisors.value.map((profile) => profile.profileId)
                    }
                }
                if (acadAdvisors) {
                    filter.acadAdvisorProfileIds = {
                        operator: acadAdvisors.operator,
                        value: acadAdvisors.value.map((profile) => profile.profileId)
                    }
                }

                if (status) {
                    filter.statusIds = {
                        operator: status.operator,
                        value: status.value.map(({statusId}) => statusId)
                    }
                }
                if (state) {
                    filter.states = {
                        operator: state.operator,
                        value: state.value.map(({id}) => id)
                    }
                }
                if (profileStatus) {
                    filter.profileStatuses = {
                        operator: profileStatus.operator,
                        value: profileStatus.value.map(({id}) => id === "active")
                    }
                }
                if (term) {
                    filter.termIds = {
                        operator: term.operator,
                        value: term.value.map(({id}) => id)
                    }
                }
                if (startingTerm) {
                    filter.startingTermIds = {
                        operator: startingTerm.operator,
                        value: startingTerm.value.map(({id}) => id)
                    }
                }
                if (activityIds) {
                    filter.activityIds = {
                        operator: activityIds.operator,
                        value: activityIds.value.map(({activityId}) => activityId)
                    }
                }
                if (activityDescription) {
                    filter.activityDescription = activityDescription
                }
                if (applicationCompletedDate) {
                    filter.applicationCompletedDate = {
                        operator: applicationCompletedDate.operator,
                        value: Array.isArray(applicationCompletedDate.value)
                            ? applicationCompletedDate.value.map((v) => moment(v).format("YYYY-MM-DD"))
                            : moment(applicationCompletedDate.value).format("YYYY-MM-DD")
                    }
                }
                if (startDateRange) {
                    filter.startDateRange = {
                        operator: startDateRange.operator,
                        value: Array.isArray(startDateRange.value)
                            ? startDateRange.value.map((v) => moment(v).format("YYYY-MM-DD"))
                            : moment(startDateRange.value).format("YYYY-MM-DD")
                    }
                }
                if (createdAtRange) {
                    filter.createdAtRange = {
                        operator: createdAtRange.operator,
                        value: Array.isArray(createdAtRange.value)
                            ? createdAtRange.value.map((v) => moment(v).format("YYYY-MM-DD"))
                            : moment(createdAtRange.value).format("YYYY-MM-DD")
                    }
                }
                if (inquiryDateRange) {
                    filter.inquiryDateRange = {
                        operator: inquiryDateRange.operator,
                        value: Array.isArray(inquiryDateRange.value)
                            ? inquiryDateRange.value.map((v) => moment(v).format("YYYY-MM-DD"))
                            : moment(inquiryDateRange.value).format("YYYY-MM-DD")
                    }
                }
                if (lastActivityDateRange) {
                    filter.lastActivityDateRange = {
                        operator: lastActivityDateRange.operator,
                        value: Array.isArray(lastActivityDateRange.value)
                            ? lastActivityDateRange.value.map((v) => moment(v).format("YYYY-MM-DD"))
                            : moment(lastActivityDateRange.value).format("YYYY-MM-DD")
                    }
                }
                if (sapCheckpoints) {
                    filter.sapCheckpoints = {
                        operator: sapCheckpoints.operator,
                        value: sapCheckpoints.value.map(({id}) => id)
                    }
                }
                if (retentionLevels) {
                    filter.retentionLevels = {
                        operator: retentionLevels.operator,
                        value: retentionLevels.value.map(({id}) => id)
                    }
                }

                return {
                    filters: filter,
                    departmentId,
                    search: studentFormSearch
                }
            }

            const orderBy = orderField.field ?? "createdAt"
            const orderDir = orderField.order ?? "desc"

            if (loadMore) {
                const loadMoreStatuses = Object.values(loadMoreStatusesRef.current)
                if (
                    loadMoreStatuses.length >=
                        (model.clientSetting?.isNewStudentStatusesVisible ? visibleStudentStatuses : visibleStatuses)
                            .length &&
                    !loadMoreStatuses.find((loadMoreStatus) => !loadMoreStatus.done)
                ) {
                    return
                }
                setLoadingMore(true)
            } else {
                if (forceReload) {
                    dispatch({isLoading: true, data: []})
                    // activeStudentRef.current = undefined
                }
                // reset loading
                Object.keys(loadMoreStatusesRef.current).forEach((statusId) => {
                    loadMoreStatusesRef.current[statusId] = {
                        done: false,
                        isCollapsed: loadMoreStatusesRef.current[statusId].isCollapsed,
                        loaded: 0
                    }
                })

                // get first batch for all student statuses
                if (model.clientSetting?.isNewStudentStatusesVisible) {
                    try {
                        const loadingSeq = random(1, 999999)
                        loadingSeqRef.current = loadingSeq

                        let students: Auth.DepartmentStudent[], profilesCountByStatus: Record<number, number>
                        if (useNewFilter) {
                            const {filters, departmentId, search} = getNewFilter()
                            filters.studentStatusIds = {
                                operator: FieldOperator.Equal,
                                value: visibleStudentStatuses.map((status) => status.statusId)
                            }
                            const {data: _students, profilesCountByStatus: _profilesCountByStatus} =
                                await studentServiceV2.getAll({
                                    range: {pageSize: 200, page: 1},
                                    sorts: [{orderBy, orderDir}],
                                    skipTotal: true,
                                    getCountByStatus: true,
                                    search,
                                    departmentId,
                                    fields: [...fields, "Dues"],
                                    filters
                                })
                            students = _students
                            profilesCountByStatus = _profilesCountByStatus
                        } else {
                            const {data: _students, profilesCountByStatus: _profilesCountByStatus} =
                                await studentService.getDepartmentStudents({
                                    filter: {
                                        ...getFilter(),
                                        studentStatusIds: visibleStudentStatuses.map((status) => status.statusId)
                                    },
                                    sort: {orderBy, orderDir},
                                    rangeOffset: {offset: 0, limit: 200},
                                    skipTotal: true,
                                    getProfilesCountByStatus: true
                                })
                            students = _students
                            profilesCountByStatus = _profilesCountByStatus
                        }
                        if (loadingSeq !== loadingSeqRef.current) {
                            console.log(
                                "PriorityView",
                                "first batch loadingSeq not match",
                                loadingSeq,
                                loadingSeqRef.current
                            )
                            setTimeout(() => getData(false, true), random(0.5, 3, true) * 1000)
                            return
                        }

                        const studentsList = uniqBy(students.map(getStudentRecord), "profileId")
                        const newData = visibleStudentStatuses
                            .filter((status) => !!status.statusId)
                            .map((status) => {
                                const isCollapsed = !!model.getPriorityViewCollapsible()[status.statusId]
                                const newRecords = studentsList.filter(
                                    (student) => student.statusId === status.statusId
                                )
                                const total = profilesCountByStatus?.[status.statusId] || 0 // status.userProfilesCount
                                loadMoreStatusesRef.current[status.statusId] ||= {
                                    done: false,
                                    isCollapsed,
                                    loaded: 0
                                }
                                loadMoreStatusesRef.current[status.statusId].loaded = newRecords.length
                                loadMoreStatusesRef.current[status.statusId].done = newRecords.length >= total
                                return {
                                    id: status.statusId,
                                    category: renderStatusGroupTitle(status, total, isCollapsed),
                                    status,
                                    total,
                                    originalRecords: newRecords,
                                    records: isCollapsed ? [] : newRecords
                                }
                            })
                        dispatch({isLoading: false, data: newData})
                        setFirstLoaded(true)
                        // CHECK
                        setTimeout(() => getData(true), 1500)
                    } catch (error) {
                        handleError(error)
                    } finally {
                        loadingSeqRef.current = 0
                        setLoadingMore(false)
                    }
                    return
                }
            }

            try {
                const loadingSeq = random(1, 999999)
                loadingSeqRef.current = loadingSeq
                const pageSize = 100
                const loadingStatus = (
                    model.clientSetting?.isNewStudentStatusesVisible ? visibleStudentStatuses : visibleStatuses
                ).find(
                    (status) =>
                        !loadMoreStatusesRef.current[status.statusId] ||
                        (!loadMoreStatusesRef.current[status.statusId].isCollapsed &&
                            !loadMoreStatusesRef.current[status.statusId].done)
                )
                if (!loadingStatus) return
                loadMoreStatusesRef.current[loadingStatus.statusId] ||= {
                    done: false,
                    isCollapsed: false,
                    loaded: 0
                }
                const loadMoreStatus = loadMoreStatusesRef.current[loadingStatus.statusId]
                let skipTotal = model.clientSetting?.isNewStudentStatusesVisible

                let students: Auth.DepartmentStudent[], studentsTotal: number
                if (useNewFilter) {
                    const {filters, search, departmentId} = getNewFilter()
                    if (model.clientSetting?.isNewStudentStatusesVisible) {
                        filters.studentStatusIds = {
                            operator: FieldOperator.Equal,
                            value: [loadingStatus.statusId]
                        }
                        if (!loadingStatus.statusId) skipTotal = false
                    } else {
                        filters.statusIds = {
                            operator: FieldOperator.Equal,
                            value: [loadingStatus.statusId]
                        }
                    }
                    const {data: _students, total: _studentsTotal} = await studentServiceV2.getAll({
                        filters,
                        search,
                        departmentId,
                        fields: [...fields, "Dues"],
                        sorts: [{orderBy, orderDir}],
                        rangeOffset: {offset: loadMoreStatus.loaded, limit: pageSize},
                        skipTotal
                    })
                    students = _students
                    studentsTotal = _studentsTotal
                } else {
                    const filter = getFilter()
                    if (model.clientSetting?.isNewStudentStatusesVisible) {
                        filter.studentStatusIds = [loadingStatus.statusId]
                        if (!loadingStatus.statusId) skipTotal = false
                    } else {
                        filter.statusIds = [loadingStatus.statusId]
                    }
                    const {data: _students, total: _studentsTotal} = await studentService.getDepartmentStudents({
                        filter,
                        sort: {orderBy, orderDir},
                        rangeOffset: {offset: loadMoreStatus.loaded, limit: pageSize},
                        skipTotal
                    })
                    students = _students
                    studentsTotal = _studentsTotal
                }
                if (loadingSeq !== loadingSeqRef.current) {
                    setTimeout(() => getData(loadMore), 1000)
                    return
                }
                const studentsList = uniqBy(students.map(getStudentRecord), "profileId")
                const currentData = getCurrentData()
                const newStatusData = model.clientSetting?.isNewStudentStatusesVisible
                    ? visibleStudentStatuses
                    : visibleStatuses.filter((status) =>
                          Object.keys(loadMoreStatusesRef.current).find((statusId) => +statusId === status.statusId)
                      )
                const newData = newStatusData.map((status) => {
                    const isCollapsed = !!loadMoreStatusesRef.current[status.statusId]?.isCollapsed
                    let statusGroup = currentData.find((statusGroup) => statusGroup.id === status.statusId)
                    const total =
                        (model.clientSetting?.isNewStudentStatusesVisible
                            ? !loadingStatus.statusId
                                ? studentsTotal
                                : statusGroup?.total ?? status.userProfilesCount
                            : studentsTotal) || 0

                    if (!statusGroup || !loadMore) {
                        statusGroup = {
                            id: status.statusId,
                            category: renderStatusGroupTitle(status, total, isCollapsed),
                            status,
                            total,
                            originalRecords: [],
                            records: []
                        }
                    }

                    if (status.statusId !== loadingStatus.statusId) {
                        return statusGroup
                    }
                    const newRecords = loadMore
                        ? uniqBy([...statusGroup.originalRecords, ...studentsList], "profileId")
                        : studentsList
                    return {
                        ...statusGroup,
                        category: model.clientSetting?.isNewStudentStatusesVisible
                            ? renderStatusGroupTitle(status, total, isCollapsed)
                            : renderStatusGroupTitle(status, total, isCollapsed),
                        status,
                        total,
                        originalRecords: newRecords,
                        records: isCollapsed ? [] : newRecords
                    }
                })
                dispatch({data: newData})
                setFirstLoaded(true)
                loadMoreStatus.loaded += pageSize

                if (studentsList.length < pageSize) {
                    // go to the next status
                    loadMoreStatus.done = true
                    setTimeout(() => getData(true), 100)
                }

                // DEFAULT: Hide student sidebar
                // if (!activeStudentRef.current && studentsList.length) {
                //     setTimeout(() => onChangeActiveStudent(studentsList[0].profileId), 200)
                // }
            } catch (error) {
                handleError(error)
            } finally {
                if (!loadMore) dispatch({isLoading: false})
                loadingSeqRef.current = 0
                setLoadingMore(false)
            }
        },
        [
            departmentId,
            model.clientSetting?.isNewStudentStatusesVisible,
            statuses,
            visibleStatuses,
            studentStatuses,
            visibleStudentStatuses,
            financialAidStatuses,
            visibleFinancialAidStatuses,
            fields,
            orderField,
            studentFormSearch,
            studentForm,
            filterUserList,
            canShowAllStudents,
            canHaveAccessOthers,
            getStudentRecord
        ]
    )

    const tableScrollHeightRef = React.useRef(0)
    const handleScrollDown = React.useCallback(
        debounce((container: HTMLElement) => {
            const {scrollTop, scrollHeight, offsetHeight, clientHeight} = container || {}
            if (scrollHeight - offsetHeight - scrollTop < offsetHeight) {
                // load more
                const newScrollHeight = scrollHeight || 0
                if (tableScrollHeightRef.current !== newScrollHeight) {
                    getData(true)
                }
                tableScrollHeightRef.current = newScrollHeight
            }
        }, 500),
        [getData]
    )

    const toggleCollapseStatusId = React.useCallback(
        (statusId: number) => {
            dispatch({
                data: getCurrentData().map((statusGroup) => {
                    loadMoreStatusesRef.current[statusGroup.id] ||= {
                        done: false,
                        isCollapsed: false,
                        loaded: 0
                    }
                    const loadMoreStatus = loadMoreStatusesRef.current[statusGroup.id]
                    if (statusGroup.id === statusId) {
                        loadMoreStatus.isCollapsed = !loadMoreStatus.isCollapsed
                    }
                    const isCollapsed = loadMoreStatus.isCollapsed
                    return {
                        ...statusGroup,
                        category: renderStatusGroupTitle(statusGroup.status, statusGroup.total, isCollapsed),
                        records: isCollapsed ? [] : statusGroup.originalRecords
                    }
                })
            })

            model.setPriorityViewCollapsible(
                Object.keys(loadMoreStatusesRef.current).reduce((state, statusId) => {
                    state[+statusId] = !!loadMoreStatusesRef.current[+statusId]?.isCollapsed
                    return state
                }, {})
            )

            // reload more data
            setTimeout(() => getData(true), 250)
        },
        [getData, model.clientSetting?.isNewStudentStatusesVisible]
    )

    function getFields() {
        return [
            "Activity",
            "Chat",
            "Date Added",
            t("studentInfo.userID"),
            t("studentInfo.firstName"),
            t("studentInfo.lastName"),
            t("studentInfo.programInterest"),
            t("studentInfo.phone"),
            model.clientSetting.isNewStudentStatusesVisible && t("studentInfo.newStudentStatus"),
            !model.clientSetting.isDepartmentalStatusesHidden && t("studentInfo.status"),
            t("studentInfo.lastActivityDate"),
            t("studentInfo.lastActivity"),
            t("studentInfo.email"),
            t("studentInfo.leadSource"),
            t("studentInfo.leadType"),
            t("studentInfo.campusInterest")
        ].filter(Boolean)
    }

    const getColumns = useCallback(() => {
        return [
            {
                title: "Activity",
                titleHtml: <span />,
                isHideTitle: true,
                field: "tickHtml",
                style: {width: 50, padding: "3px 4px", textAlign: "center", lineHeight: "1.2"},
                headerStyle: {width: 50, padding: "3px 4px", lineHeight: "1.2"},
                render: (_, record) => renderTickHtml(record)
            },
            {
                title: "Chat",
                titleHtml: <span />,
                isHideTitle: true,
                field: "chatHtml",
                style: {width: 50, padding: "3px 4px", textAlign: "center", lineHeight: "1.2"},
                headerStyle: {width: 50, padding: "3px 4px", lineHeight: "1.2"},
                render: (_, record) => renderChatHtml(record)
            },
            {
                title: "Date Added",
                field: "createdAt",
                render: (value) => (value ? moment(value).format(userDateTimeFormat) : ""),
                columnIndex: 1,
                headerStyle: {minWidth: "90px", padding: "0px 5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "0px 5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.userID"),
                field: "customUserId",
                // field: "userIdHtml",
                columnIndex: 2,
                headerStyle: {minWidth: "80px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.firstName"),
                field: "firstName",
                orderField: "name",
                columnIndex: 3,
                headerStyle: {minWidth: "100px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.lastName"),
                field: "lastName",
                columnIndex: 4,
                headerStyle: {minWidth: "100px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.programInterest"),
                field: "programName",
                columnIndex: 5,
                headerStyle: {minWidth: "100px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {
                    maxWidth: "180px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    padding: "0px 5px",
                    margin: "0px",
                    lineHeight: "1.2"
                },
                sortable: true
            },
            {
                title: t("studentInfo.phone"),
                field: "phone",
                columnIndex: 6,
                headerStyle: {minWidth: "100px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            model.clientSetting.isNewStudentStatusesVisible && {
                title: t("studentInfo.newStudentStatus"),
                field: "studentStatusHtml",
                orderField: "newStudentStatus",
                columnIndex: 7,
                headerStyle: {minWidth: "100px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            !model.clientSetting.isDepartmentalStatusesHidden && {
                title: t("studentInfo.status"),
                field: "statusHtml",
                columnIndex: 8,
                headerStyle: {minWidth: "120px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.lastActivityDate"),
                field: "lastActivityDate",
                orderField: "latestActivityDate",
                render: (value) => (value ? moment(value).format(userDateTimeFormat) : ""),
                columnIndex: 9,
                headerStyle: {minWidth: "90px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.lastActivity"),
                field: "lastActivity",
                orderField: "latestActivityDate",
                columnIndex: 10,
                headerStyle: {minWidth: "100px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.email"),
                field: "email",
                columnIndex: 11,
                headerStyle: {minWidth: "140px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.leadSource"),
                field: "admissionsLeadSourceId",
                columnIndex: 12,
                headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                orderField: "leadSource",
                sortable: true,
                render: (admissionsLeadSourceId: number | null, student: Auth.DepartmentStudent) => {
                    const selectedLeadSource = (leadSources || []).find((v) => v.id === admissionsLeadSourceId)
                    return (
                        <div onClick={(e) => e.stopPropagation()}>
                            <KlassDropdown
                                options={leadSources}
                                value={selectedLeadSource}
                                getOptionLabel={(option: any) => [option.code, option.name].join(" - ")}
                                onChange={(value) =>
                                    updateProfileAdmissionLeadSource(student.profileId, value.id ? value.id : value)
                                }
                                placeholder="Select"
                                menuPortalTarget={document.body}
                            />
                        </div>
                    )
                },
                renderText: (admissionsLeadSourceId: number | null, student: Auth.DepartmentStudent) => {
                    const selectedLeadSource = (leadSources || []).find((v) => v.id === admissionsLeadSourceId)
                    if (!selectedLeadSource) return ""
                    return [selectedLeadSource.code, selectedLeadSource.name].join(" - ")
                }
            },
            {
                title: t("studentInfo.leadType"),
                field: "leadTypeId",
                columnIndex: 51,
                headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                orderField: "leadTypeId",
                sortable: true,
                render: (leadTypeId: number | null, student: Auth.DepartmentStudent) => {
                    return (
                        <div onClick={(e) => e.stopPropagation()}>
                            <KlassDropdown
                                options={leadTypes}
                                value={leadTypes.find((type) => type.leadTypeId === leadTypeId)}
                                getOptionLabel={(option: any) => [option.code, option.name].join(" - ")}
                                onChange={(value) =>
                                    updateProfileLeadType(student.profileId, value.id ? value.id : value)
                                }
                                placeholder="Select"
                                menuPortalTarget={document.body}
                            />
                        </div>
                    )
                },
                renderText: (leadTypeId: number | null, student: Auth.DepartmentStudent) => {
                    const leadType = leadTypes.find((type) => type.leadTypeId === leadTypeId)
                    if (!leadType) return ""
                    return [leadType.code, leadType.name].join(" - ")
                }
            },
            {
                title: t("studentInfo.campusInterest"),
                field: "campusHtml",
                orderField: "campus",
                columnIndex: 13,
                headerStyle: {minWidth: "100px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {
                    maxWidth: "180px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    padding: "0px 5px",
                    margin: "0px",
                    lineHeight: "1.2"
                },
                sortable: true
            },
            {
                title: t("studentInfo.enrollmentID"),
                field: "customProfileId",
                columnIndex: 14,
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.userState"),
                field: "profileState",
                columnIndex: 15,
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.initialInquiryDate"),
                field: "initialInquiryDate",
                fieldType: "dateUtc",
                columnIndex: 16,
                format: userDateFormat,
                render: (value) => (value ? moment(value).format(userDateFormat) : ""),
                headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.applicationCompletedDate"),
                field: "applicationCompletedDate",
                fieldType: "dateUtc",
                columnIndex: 17,
                format: userDateFormat,
                render: (value) => (value ? moment(value).format(userDateFormat) : ""),
                headerStyle: {minWidth: "180px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.acceptanceDate"),
                field: "acceptanceDate",
                fieldType: "dateUtc",
                columnIndex: 18,
                format: userDateFormat,
                render: (value) => (value ? moment(value).format(userDateFormat) : ""),
                headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.gender"),
                field: "gender",
                columnIndex: 19,
                headerStyle: {minWidth: "100px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.applicationFee"),
                field: "applicationFeeHtml",
                columnIndex: 20,
                headerStyle: {minWidth: "180px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                orderField: "applicationFee",
                sortable: true
            },
            {
                title: t("studentInfo.birthDate"),
                field: "birthDate",
                fieldType: "date",
                columnIndex: 21,
                format: userDateFormat,
                render: (value) => (value ? moment(value).format(userDateFormat) : ""),
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.race"),
                field: "race",
                columnIndex: 22,
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.maritalStatus"),
                field: "maritalStatus",
                columnIndex: 23,
                headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.country"),
                field: "country",
                columnIndex: 24,
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.address"),
                field: "address",
                columnIndex: 25,
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.city"),
                field: "city",
                columnIndex: 26,
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.state"),
                field: "state",
                columnIndex: 27,
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.postalCode"),
                field: "postalCode",
                columnIndex: 28,
                headerStyle: {minWidth: "180px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.highSchoolOrGED"),
                field: "highSchoolType",
                columnIndex: 29,
                headerStyle: {minWidth: "250px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.preferredTimeOfContact"),
                field: "preferredTimeOfContact",
                columnIndex: 30,
                headerStyle: {minWidth: "260px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true,
                render: (value) => value?.join(", ")
            },
            {
                title: t("studentInfo.howDidYouHearAboutUs"),
                field: "howDidYouKnowAboutUs",
                columnIndex: 31,
                headerStyle: {minWidth: "320px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.enrollmentState"),
                field: "profileState",
                columnIndex: 32,
                headerStyle: {minWidth: "230px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            !model.clientSetting.isDepartmentalStatusesHidden && {
                title: t("studentInfo.admissionStatus"),
                field: "admissionStatusHtml",
                columnIndex: 33,
                headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            !model.clientSetting.isDepartmentalStatusesHidden && {
                title: t("studentInfo.finAidStatus"),
                field: "finAidStatusHtml",
                columnIndex: 34,
                headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.admissionAdvisor"),
                field: "admissionAdvisorsHtml",
                orderField: useNewFilter ? "admissionAdvisor" : "admisionAdvisor",
                columnIndex: 35,
                headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {
                    maxWidth: "180px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    padding: "0px 5px",
                    margin: "0px",
                    lineHeight: "1.2"
                },
                sortable: true
            },
            {
                title: t("studentInfo.finAidAdvisor"),
                field: "finAidAdvisorsHtml",
                orderField: "finAidAdvisor",
                columnIndex: 36,
                headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {
                    maxWidth: "180px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    padding: "0px 5px",
                    margin: "0px",
                    lineHeight: "1.2"
                },
                sortable: true
            },
            {
                title: t("studentInfo.programVersion"),
                field: "programVersionName",
                columnIndex: 37,
                headerStyle: {minWidth: "230px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.degreeLevel"),
                field: "degreeLevel",
                columnIndex: 38,
                headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.term"),
                field: "termName",
                columnIndex: 39,
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.startingTerm"),
                field: "startingTermName",
                columnIndex: 40,
                headerStyle: {minWidth: "150px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.startDate"),
                field: "startDate",
                fieldType: "dateUtc",
                columnIndex: 41,
                format: userDateFormat,
                render: (value) => (value ? moment(value).format(userDateFormat) : ""),
                headerStyle: {minWidth: "160px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.contractEndDate"),
                field: "contractEndDate",
                fieldType: "dateUtc",
                columnIndex: 42,
                format: userDateFormat,
                render: (value) => (value ? moment(value).format(userDateFormat) : ""),
                headerStyle: {minWidth: "300px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.actualContractEndDate"),
                field: "actualContractEndDate",
                fieldType: "dateUtc",
                columnIndex: 43,
                format: userDateFormat,
                render: (value) => (value ? moment(value).format(userDateFormat) : ""),
                headerStyle: {minWidth: "350px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.academicTrack"),
                field: "academicTrack",
                columnIndex: 44,
                headerStyle: {minWidth: "220px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"},
                sortable: true
            },
            {
                title: t("studentInfo.admissionChecklist"),
                field: "admChecklistStatusHtml",
                columnIndex: 45,
                headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"}
            },
            {
                title: t("studentInfo.finAidChecklist"),
                field: "faChecklistStatusHtml",
                columnIndex: 46,
                headerStyle: {minWidth: "200px", padding: "5px", margin: "0px", lineHeight: "1.2"},
                style: {padding: "5px", margin: "0px", lineHeight: "1.2"}
            }
        ].filter(Boolean)
    }, [
        renderTickHtml,
        renderChatHtml,
        useNewFilter,
        model.clientSetting.isNewStudentStatusesVisible,
        model.clientSetting.isDepartmentalStatusesHidden,
        leadSources,
        leadTypes,
        updateProfileAdmissionLeadSource,
        updateProfileLeadType
    ])

    const loadStudent = React.useCallback(
        async (studentProfileId: number) => {
            try {
                const {
                    data: [activeStudent]
                } = await studentService.getDepartmentStudents({
                    skipTotal: true,
                    filter: {departmentId, profileIds: [studentProfileId]}
                })
                if (activeStudentRef.current !== activeStudent?.profileId) return
                updateState({activeStudent})
            } catch (error) {
                handleError(error)
            }
        },
        [departmentId, updateState]
    )

    const onChangeActiveStudent = React.useCallback(
        (profileId: number) => {
            let activeStudent
            const newData = getCurrentData().map((statusGroup) => ({
                ...statusGroup,
                originalRecords: statusGroup.originalRecords.map((student: Auth.DepartmentStudent) => {
                    const departmentDue = student.departmentDues?.find((due) => due.departmentId === departmentId)
                    if (student.profileId === profileId) {
                        activeStudent = student
                    }
                    return {
                        ...student,
                        isActiveTableCol: student.profileId === profileId,
                        rowStyle:
                            student.profileId === profileId
                                ? undefined
                                : departmentDue
                                ? {background: departmentDue.dueColor || DUE_COLOR_OPTIONS[0].id}
                                : undefined
                    }
                }),
                records: statusGroup.records.map((student: Auth.DepartmentStudent) => {
                    const departmentDue = student.departmentDues?.find((due) => due.departmentId === departmentId)
                    return {
                        ...student,
                        isActiveTableCol: student.profileId === profileId,
                        rowStyle:
                            student.profileId === profileId
                                ? undefined
                                : departmentDue
                                ? {background: departmentDue.dueColor || DUE_COLOR_OPTIONS[0].id}
                                : undefined
                    }
                })
            }))

            activeStudentRef.current = profileId
            dispatch({data: newData})
            updateState({activeStudent})
        },
        [departmentId, updateState]
    )

    useEffect(() => {
        if (studentId) {
            onChangeActiveStudent(studentId)
        }
    }, [studentId, onChangeActiveStudent])

    const handleRowClick = React.useCallback(
        (item: Auth.DepartmentStudent) => onChangeActiveStudent(item.profileId),
        [onChangeActiveStudent]
    )

    const moveStatus = React.useCallback(
        async ({
            profileId,
            studentDepartmentStatuses
        }: {
            profileId?: number
            studentDepartmentStatuses?: Auth.StudentDepartmentStatusDetails[]
        }) => {
            if (!!model.clientSetting?.isNewStudentStatusesVisible) return
            if (!profileId) return
            let deptStatus = studentDepartmentStatuses?.find((deptStatus) => deptStatus.departmentId === departmentId)
            let oldStudentStatusId, studentRecord

            if (!deptStatus) {
                try {
                    const {
                        data: [student]
                    } = await studentService.getDepartmentStudents({
                        skipTotal: true,
                        filter: {departmentId, profileIds: [profileId]}
                    })
                    deptStatus = {
                        departmentId,
                        profileId,
                        statusId: student[`${DepartmentKeyNameByIdForPriority[departmentId]}StatusId`]
                    }
                    studentRecord = getStudentRecord(student)
                } catch (error) {
                    console.error(error)
                }
            }

            if (!deptStatus) return

            const currentData = getCurrentData()

            // get current existing student record in the table (if any)
            loop: for (const statusGroup of currentData) {
                for (const record of statusGroup.originalRecords) {
                    if (record.profileId === profileId) {
                        oldStudentStatusId = statusGroup.id
                        studentRecord = studentRecord || record
                        break loop
                    }
                }
            }

            if (!oldStudentStatusId) return

            if (deptStatus && studentRecord) {
                const status = visibleStatuses.find(({statusId}) => statusId === deptStatus.statusId)
                if (status) {
                    studentRecord.statusHtml = renderStatusHtml(status.statusId)
                }
            }

            dispatch({
                data: currentData.map((statusGroup) => {
                    const isCollapsed = !!loadMoreStatusesRef.current[statusGroup.id]?.isCollapsed

                    // remove student from old status group (if any)
                    if (statusGroup.id !== deptStatus.statusId) {
                        if (oldStudentStatusId !== statusGroup.id) {
                            return statusGroup
                        }
                        const newRecords = statusGroup.originalRecords.filter(
                            (record) => record.profileId !== profileId
                        )
                        const newTotal = statusGroup.total - 1
                        return {
                            ...statusGroup,
                            category: renderStatusGroupTitle(statusGroup.status, newTotal, isCollapsed),
                            total: newTotal,
                            originalRecords: newRecords,
                            records: isCollapsed ? [] : newRecords
                        }
                    }

                    // if student is already in new status group, keep it
                    if (oldStudentStatusId === statusGroup.id) {
                        if (!studentRecord) return statusGroup
                        const newRecords = statusGroup.originalRecords.map((record) =>
                            record.profileId === studentRecord.profileId ? studentRecord : record
                        )
                        return {
                            ...statusGroup,
                            originalRecords: newRecords,
                            records: isCollapsed ? [] : newRecords
                        }
                    }

                    // otherwise, add student to new status group
                    let newRecords = statusGroup.originalRecords
                    let newTotal = statusGroup.total
                    if (studentRecord) {
                        newRecords = uniqBy([studentRecord, ...statusGroup.originalRecords], "profileId")
                        newTotal += 1
                    }
                    return {
                        ...statusGroup,
                        category: renderStatusGroupTitle(statusGroup.status, newTotal, isCollapsed),
                        total: newTotal,
                        originalRecords: newRecords,
                        records: isCollapsed ? [] : newRecords
                    }
                })
            })
        },
        [departmentId, visibleStatuses, getStudentRecord, model.clientSetting?.isNewStudentStatusesVisible]
    )

    const moveStudentStatus = React.useCallback(
        async ({profileId, statusId}: {profileId?: number; statusId?: number}) => {
            if (!model.clientSetting?.isNewStudentStatusesVisible) return
            if (!profileId) return
            let oldStudentStatusId, studentRecord

            if (!statusId) {
                try {
                    const {
                        data: [student]
                    } = await studentService.getDepartmentStudents({
                        skipTotal: true,
                        filter: {departmentId, profileIds: [profileId]}
                    })
                    statusId = student.statusId
                    studentRecord = getStudentRecord(student)
                } catch (error) {
                    console.error(error)
                }
            }

            if (!statusId) return

            const currentData = getCurrentData()

            // get current existing student record in the table (if any)
            loop: for (const statusGroup of currentData) {
                for (const record of statusGroup.originalRecords) {
                    if (record.profileId === profileId) {
                        oldStudentStatusId = statusGroup.id
                        studentRecord = studentRecord || record
                        break loop
                    }
                }
            }

            if (!oldStudentStatusId) return

            if (statusId && studentRecord) {
                const status = visibleStudentStatuses.find((status) => statusId === status.statusId)
                if (status) {
                    studentRecord.studentStatusHtml = renderStudentStatusHtml(status.statusId)
                }
            }

            dispatch({
                data: currentData.map((statusGroup) => {
                    const isCollapsed = !!loadMoreStatusesRef.current[statusGroup.id]?.isCollapsed

                    // remove student from old status group (if any)
                    if (statusGroup.id !== statusId) {
                        if (oldStudentStatusId !== statusGroup.id) {
                            return statusGroup
                        }
                        const newRecords = statusGroup.originalRecords.filter(
                            (record) => record.profileId !== profileId
                        )
                        const newTotal = statusGroup.total - 1
                        return {
                            ...statusGroup,
                            category: renderStatusGroupTitle(statusGroup.status, newTotal, isCollapsed),
                            total: newTotal,
                            originalRecords: newRecords,
                            records: isCollapsed ? [] : newRecords
                        }
                    }

                    // if student is already in new status group, keep it
                    if (oldStudentStatusId === statusGroup.id) {
                        if (!studentRecord) return statusGroup
                        const newRecords = statusGroup.originalRecords.map((record) =>
                            record.profileId === studentRecord.profileId ? studentRecord : record
                        )
                        return {
                            ...statusGroup,
                            originalRecords: newRecords,
                            records: isCollapsed ? [] : newRecords
                        }
                    }

                    // otherwise, add student to new status group
                    let newRecords = statusGroup.originalRecords
                    let newTotal = statusGroup.total
                    if (studentRecord) {
                        newRecords = uniqBy([studentRecord, ...statusGroup.originalRecords], "profileId")
                        newTotal += 1
                    }
                    return {
                        ...statusGroup,
                        category: renderStatusGroupTitle(statusGroup.status, newTotal, isCollapsed),
                        total: newTotal,
                        originalRecords: newRecords,
                        records: isCollapsed ? [] : newRecords
                    }
                })
            })
        },
        [visibleStudentStatuses, getStudentRecord, model.clientSetting?.isNewStudentStatusesVisible]
    )

    const moveFinancialAidStatus = React.useCallback(
        async ({profileId, statusId}: {profileId?: number; statusId?: number}) => {
            if (departmentId !== BaseDepartmentId.FinancialAid) return
            if (!profileId) return
            let oldStatusId, studentRecord

            if (!statusId) {
                try {
                    const {
                        data: [student]
                    } = await studentService.getDepartmentStudents({
                        skipTotal: true,
                        filter: {departmentId, profileIds: [profileId]}
                    })
                    statusId = student.financialAidStatusId
                    studentRecord = getStudentRecord(student)
                } catch (error) {
                    console.error(error)
                }
            }

            if (!statusId) return

            const currentData = getCurrentData()

            // get current existing student record in the table (if any)
            loop: for (const statusGroup of currentData) {
                for (const record of statusGroup.originalRecords) {
                    if (record.profileId === profileId) {
                        oldStatusId = statusGroup.id
                        studentRecord = studentRecord || record
                        break loop
                    }
                }
            }

            if (!oldStatusId) return

            if (statusId && studentRecord) {
                const status = visibleFinancialAidStatuses.find((status) => statusId === status.statusId)
                if (status) {
                    studentRecord.financialAidStatusHtml = renderFinancialAidStatusHtml(status.statusId)
                }
            }

            dispatch({
                data: currentData.map((statusGroup) => {
                    const isCollapsed = !!loadMoreStatusesRef.current[statusGroup.id]?.isCollapsed

                    // remove student from old status group (if any)
                    if (statusGroup.id !== statusId) {
                        if (oldStatusId !== statusGroup.id) {
                            return statusGroup
                        }
                        const newRecords = statusGroup.originalRecords.filter(
                            (record) => record.profileId !== profileId
                        )
                        const newTotal = statusGroup.total - 1
                        return {
                            ...statusGroup,
                            category: renderStatusGroupTitle(statusGroup.status, newTotal, isCollapsed),
                            total: newTotal,
                            originalRecords: newRecords,
                            records: isCollapsed ? [] : newRecords
                        }
                    }

                    // if student is already in new status group, keep it
                    if (oldStatusId === statusGroup.id) {
                        if (!studentRecord) return statusGroup
                        const newRecords = statusGroup.originalRecords.map((record) =>
                            record.profileId === studentRecord.profileId ? studentRecord : record
                        )
                        return {
                            ...statusGroup,
                            originalRecords: newRecords,
                            records: isCollapsed ? [] : newRecords
                        }
                    }

                    // otherwise, add student to new status group
                    let newRecords = statusGroup.originalRecords
                    let newTotal = statusGroup.total
                    if (studentRecord) {
                        newRecords = uniqBy([studentRecord, ...statusGroup.originalRecords], "profileId")
                        newTotal += 1
                    }
                    return {
                        ...statusGroup,
                        category: renderStatusGroupTitle(statusGroup.status, newTotal, isCollapsed),
                        total: newTotal,
                        originalRecords: newRecords,
                        records: isCollapsed ? [] : newRecords
                    }
                })
            })
        },
        [departmentId, visibleFinancialAidStatuses, getStudentRecord]
    )

    const changeStudentDue = React.useCallback(
        (dueDepartmentId: number, profileId: number, dueTime?: string, dueColor?: string) => {
            if (departmentId !== dueDepartmentId) return
            dispatch({
                data: getCurrentData().map((statusGroup) => {
                    return {
                        ...statusGroup,
                        originalRecords: statusGroup.originalRecords.map((student) =>
                            student.profileId !== profileId
                                ? student
                                : {
                                      ...student,
                                      dueTime,
                                      rowStyle: dueTime ? {background: dueColor || DUE_COLOR_OPTIONS[0].id} : undefined
                                  }
                        ),
                        records: statusGroup.records.map((student) =>
                            student.profileId !== profileId
                                ? student
                                : {
                                      ...student,
                                      dueTime,
                                      rowStyle: dueTime ? {background: dueColor || DUE_COLOR_OPTIONS[0].id} : undefined
                                  }
                        )
                    }
                })
            })
        },
        [departmentId]
    )

    const reloadStudent = React.useCallback(
        async (profileId: number) => {
            try {
                const {
                    data: [newStudent]
                } = await studentService.getDepartmentStudents({
                    skipTotal: true,
                    filter: {departmentId, profileIds: [profileId]}
                })
                dispatch({
                    data: getCurrentData().map((statusGroup) => {
                        return {
                            ...statusGroup,
                            originalRecords: statusGroup.originalRecords.map((student) =>
                                student.profileId !== newStudent.profileId ? student : getStudentRecord(newStudent)
                            ),
                            records: statusGroup.records.map((student) =>
                                student.profileId !== newStudent.profileId ? student : getStudentRecord(newStudent)
                            )
                        }
                    })
                })
            } catch (error) {
                console.error(error)
            }
        },
        [departmentId, getStudentRecord]
    )

    useEffect(() => {
        dispatch({isClassComponent: false, isLoading: true})
        dispatchFunc([
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "getFilterMemoryKey", func: () => filterMemoryKey}
        ])
    }, [])

    useEffect(() => {
        dispatchFunc([{key: "getColumns", func: getColumns}])
    }, [getColumns])

    useEffect(() => {
        if (isFirstLoaded) {
            coreSocket.joinRoom(SOCKET_ROOM_PRIORITY_TABLE)
        }
        return () => {
            coreSocket.leaveRoom(SOCKET_ROOM_PRIORITY_TABLE)
        }
    }, [isFirstLoaded])

    useEffect(() => {
        ;(async function getStatuses() {
            if (model.clientSetting?.isNewStudentStatusesVisible) {
                return
            }
            try {
                const {data = []} = await settingStatusService.getAllStatuses({
                    filter: {
                        departmentIds: [departmentId]
                    },
                    sort: {
                        orderBy: "priority",
                        orderDir: "asc"
                    },
                    linkedEntities: true
                })
                setStatuses([
                    ...data.map((status: Status) => ({
                        ...status,
                        statusName: status.name,
                        name: `${status.name} (Stage : ${status.stage?.name})`
                    })),
                    {statusId: 0, name: "Status not defined"}
                ])
            } catch (error) {
                handleError(error)
            }
        })()
    }, [departmentId, model.clientSetting?.isNewStudentStatusesVisible])

    useEffect(() => {
        if (!isLoadingStudentStatuses && !isLoadingFAStatuses && fields?.length) {
            getData(false, true)
        }
    }, [
        isLoadingStudentStatuses,
        isLoadingFAStatuses,
        visibleStatuses,
        visibleStudentStatuses,
        visibleFinancialAidStatuses,
        fields,
        orderField,
        studentForm,
        studentFormSearch,
        canShowAllStudents,
        canHaveAccessOthers
    ])

    useEffect(() => {
        const handleStudentDueChange = ({
            departmentId,
            profileId,
            dueTime,
            dueColor
        }: {
            departmentId: number
            profileId: number
            dueTime?: string
            dueColor?: string
        }) => {
            changeStudentDue(departmentId, profileId, dueTime, dueColor)
        }

        const handleStatusChange = ({
            profileId,
            studentDepartmentStatuses
        }: {
            profileId: number
            studentDepartmentStatuses: Auth.StudentDepartmentStatusDetails[]
        }) => {
            moveStatus({profileId, studentDepartmentStatuses})
        }

        const handleStudentStatusChange = ({profileId, statusId}: {profileId: number; statusId: number}) => {
            moveStudentStatus({profileId, statusId})
        }

        const handleFinancialAidStatusChange = ({profileId, statusId}: {profileId: number; statusId: number}) => {
            moveFinancialAidStatus({profileId, statusId})
        }

        const handleStudentActivityCompletionToggled = ({profileId}) => {
            reloadStudent(profileId)
        }

        coreSocket.on(SocketEvent.ProfileDueSet, handleStudentDueChange)
        coreSocket.on(SocketEvent.ProfileDueCleared, handleStudentDueChange)
        coreSocket.on(SocketEvent.ProfileStatusUpdated, handleStatusChange)
        coreSocket.on(SocketEvent.ProfileStudentStatusUpdated, handleStudentStatusChange)
        coreSocket.on(SocketEvent.ProfileFinancialAidStatusUpdated, handleFinancialAidStatusChange)
        onEvent(EVENT_STUDENT_ACTIVITY_COMPLETION_TOGGLED, handleStudentActivityCompletionToggled)

        return () => {
            coreSocket.off(SocketEvent.ProfileDueSet, handleStudentDueChange)
            coreSocket.off(SocketEvent.ProfileDueCleared, handleStudentDueChange)
            coreSocket.off(SocketEvent.ProfileStatusUpdated, handleStatusChange)
            coreSocket.off(SocketEvent.ProfileStudentStatusUpdated, handleStudentStatusChange)
            coreSocket.off(SocketEvent.ProfileFinancialAidStatusUpdated, handleFinancialAidStatusChange)
            offEvent(EVENT_STUDENT_ACTIVITY_COMPLETION_TOGGLED, handleStudentActivityCompletionToggled)
        }
    }, [changeStudentDue, moveStatus, reloadStudent])

    useEffect(() => {
        if (!statusChange) return
        moveStatus({profileId: statusChange.profileId, studentDepartmentStatuses: [statusChange]})
    }, [statusChange, moveStatus])

    useEffect(() => {
        if (!studentStatusChange) return
        moveStudentStatus(studentStatusChange)
    }, [studentStatusChange, moveStudentStatus])

    useEffect(() => {
        if (!financialAidStatusChange) return
        moveFinancialAidStatus(financialAidStatusChange)
    }, [financialAidStatusChange, moveFinancialAidStatus])

    useEffect(() => {
        if (!enrollmentProfileIdChange) return
        ;(async function loadNewStudentEnrollment() {
            try {
                const {
                    data: [newStudent]
                } = await studentService.getDepartmentStudents({
                    skipTotal: true,
                    filter: {departmentId: departmentId, profileIds: [enrollmentProfileIdChange.newProfileId]}
                })
                if (!newStudent) return
                dispatch({
                    data: getCurrentData().map((statusGroup) => {
                        return {
                            ...statusGroup,
                            originalRecords: statusGroup.originalRecords.map((student) =>
                                student.profileId !== enrollmentProfileIdChange.profileId
                                    ? student
                                    : getStudentRecord(newStudent)
                            ),
                            records: statusGroup.records.map((student) =>
                                student.profileId !== enrollmentProfileIdChange.profileId
                                    ? student
                                    : getStudentRecord(newStudent)
                            )
                        }
                    })
                })
            } catch (error) {
                handleError(error)
            }
        })()
    }, [enrollmentProfileIdChange])

    useEffect(() => {
        function updateStudentDueColorAfterActivityCreated(activity: StudentActivity) {
            if (!activity.completed && Math.abs(moment().diff(activity.createdAt, "seconds")) > 30) {
                return
            }
            dispatch({
                data: getCurrentData().map((statusGroup) => {
                    return {
                        ...statusGroup,
                        originalRecords: statusGroup.originalRecords.map((student) =>
                            student.profileId !== activity.profileId
                                ? student
                                : {...student, dueTime: undefined, rowStyle: undefined}
                        ),
                        records: statusGroup.records.map((student) =>
                            student.profileId !== activity.profileId
                                ? student
                                : {...student, dueTime: undefined, rowStyle: undefined}
                        )
                    }
                })
            })
        }

        onEvent(EVENT_STUDENT_ACTIVITY_CREATED, updateStudentDueColorAfterActivityCreated)
        return () => {
            offEvent(EVENT_STUDENT_ACTIVITY_CREATED, updateStudentDueColorAfterActivityCreated)
        }
    }, [])

    const handleActivityUpserted = useCallback(
        (activity: StudentActivity) => {
            if (selectedStudent?.profileId) {
                emitEvent(EVENT_STUDENT_ACTIVITY_CREATED, {
                    profileId: selectedStudent.profileId,
                    activity
                })
                if (model.clientSetting?.isNewStudentStatusesVisible) {
                    moveStudentStatus({profileId: selectedStudent.profileId})
                } else {
                    moveStatus({profileId: selectedStudent.profileId})
                }
                if (activeStudentRef.current === selectedStudent.profileId) {
                    onChangeActiveStudent(selectedStudent.profileId)
                }
            }
        },
        [selectedStudent?.profileId, moveStudentStatus, moveStatus, onChangeActiveStudent]
    )

    const [isShowChooseCols, setIsShowChooseCols] = useState(false)

    return (
        <>
            <div className={styles.columnPickerBtnWrapper}>
                <Button
                    className={styles.columnPickerBtn}
                    icon={<Icon icon="COLUMNS" />}
                    onClick={() => setIsShowChooseCols(true)}
                />
            </div>
            {isShowChooseCols && (
                <div>
                    <ColumnPicker
                        fields={fields}
                        allFields={allFields}
                        onChangeFields={onChangeFields}
                        onChangeAllFields={onChangeAllFields}
                        setIsShowChooseCols={setIsShowChooseCols}
                        onDraggableColumn={onDraggableColumn}
                    />
                </div>
            )}
            <div className={styles.tableContainer}>
                <KlassappTableSubCategory
                    columns={columns}
                    menuActions={[]}
                    data={data}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    orderField={orderField}
                    isShowCheckedColumn={false}
                    onChangeFields={onChangeFields}
                    onUpdateRowData={onUpdateRowData}
                    onUpdateTableData={onUpdateTableData}
                    onClickSortColumn={onClickSortColumn}
                    onDraggableColumn={onDraggableColumn}
                    onChangeAllFields={onChangeAllFields}
                    onClickRowItem={handleRowClick}
                    className={styles.priorityList}
                    tableClassName={styles.priorityListTable}
                    categoryClassNames={[styles.categoryTd]}
                    isHideSortIcon={true}
                    onScrollDown={handleScrollDown}
                />

                {isLoadingMore && <CircularSpin className={styles.loadMoreIndicator} size="small" />}

                {!!activityPopup.isVisible && (
                    <ActivityPopup
                        isShow={activityPopup.isVisible}
                        onClose={activityPopup.close}
                        studentId={selectedStudent?.profileId}
                        departmentId={departmentId}
                        isCompactForm={false}
                        setReload={handleActivityUpserted}
                    />
                )}

                {!!chatPopup.isVisible && (
                    <ChatModalV2
                        user={selectedStudent}
                        onShow={chatPopup.isVisible}
                        onClose={chatPopup.close}
                        userIdField="userId"
                        departmentId={departmentId}
                    />
                )}
            </div>
        </>
    )
}

export const StudentPriorityList = KlassappTableHOC(StudentPriorityTable)
