import React, {useEffect, useRef} from "react"
import cx from "classnames"
import {TaskSubtype, UserType} from "types/tasks"
import {TaskSignerForm, TaskSignerSelecting, TaskSignerDetail, TaskSignerHeader} from "./parts"
import styles from "./TaskSigners.module.css"

export function TaskSignerItem(props) {
    const {signer, taskId, subtype, hasError, isDraggable, snapshot} = props
    const signerRef = useRef(null)

    useEffect(() => {
        if (hasError) {
            signerRef?.current?.scrollIntoView({behavior: "smooth"})
        }
    }, [hasError])

    const renderSignerBody = () => {
        if (signer.userType !== UserType.STAFF && (taskId || subtype === TaskSubtype.LIBRARY)) {
            return null
        }
        if (signer.isSelecting) {
            return <TaskSignerSelecting {...props} />
        }
        return <TaskSignerDetail {...props} />
    }

    if (signer.isForm) {
        return <TaskSignerForm {...props} />
    }
    if (isDraggable) {
        return (
            <div className={cx(styles.itemWrap, {[styles.tableRowDragging]: snapshot.isDragging})}>
                <div ref={signerRef}>
                    <TaskSignerHeader {...props} />
                    {renderSignerBody()}
                </div>
            </div>
        )
    }
    return (
        <div className={cx(styles.itemWrap)} ref={signerRef}>
            <TaskSignerHeader {...props} />
            {renderSignerBody()}
        </div>
    )
}
