import {get} from "lodash"
import BaseService from "./Base"
import {StudentStatus} from "types/students"

class NewStudentStatusService extends BaseService {
    async getAvailableStatuses(includeUserProfilesCount?: boolean): Promise<StudentStatus[]> {
        const response = await this.post("/v1/students/statuses/available/list", {
            includeUserProfilesCount
        })
        return get(response.data, "data", [])
    }

    async getApplicationAcceptanceAutoStudentStatus(): Promise<{status: StudentStatus | null}> {
        const response = await this.post(`/v1/students/statuses/application-acceptance-auto-status/get`, {})

        return get(response.data, "data", [])
    }

    async updateApplicationAcceptanceAutoStudentStatus(params: {studentStatusId: number}) {
        const response = await this.post(`/v1/students/statuses/application-acceptance-auto-status/save`, params)
        return get(response, "data", [])
    }
}

export default NewStudentStatusService
