import {useState} from "react"
import {Radio, Checkbox} from "antd"
import {BasePopup} from "components/popup"
import {studentService} from "services"
import {getFullName, handleError, toastError} from "helpers"
import styles from "./ChangeStatusPopup.module.css"
import {get, head, isEmpty, keyBy} from "lodash"
import {Auth} from "types/auth"
import {BaseButton} from "components"
import {useModel} from "hooks"
import {NewStudentStatusSelectFilter} from "components/NewStudentStatusSelect"
import {FormLabel} from "components/Form"
import {AssignStatusToStudentsAction, StudentStatusAssignmentResult} from "services/StudentService"

type Props = {
    isShow: boolean
    students: Auth.DepartmentStudent[]
    onClose: () => void
    onReload: () => void
}

export function ChangeStatusPopup(props: Props) {
    const {isShow, students, onClose, onReload} = props
    const profileState = get(head(students), "profileState")
    const model = useModel()
    const [newStudentStatus, setNewStudentStatus] = useState(null)
    const [action, setAction] = useState<AssignStatusToStudentsAction>(null)
    const [forStatusState, setForStatusState] = useState<Auth.StudentState>(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [response, setResponse] = useState<StudentStatusAssignmentResult[]>([])

    const onSubmit = async () => {
        if (isEmpty(newStudentStatus)) {
            toastError("Please select the status")
            return
        }
        try {
            setIsSubmitting(true)
            const {data} = await studentService.assignStudentStatusesInBulk({
                studentProfileIds: students.map((student) => student.profileId),
                studentStatusIdToAssign: newStudentStatus.statusId,
                action
            })
            setResponse(data)
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitting(false)
        }
    }

    const getForStateByAction = (action: AssignStatusToStudentsAction): Auth.StudentState => {
        switch (action) {
            case AssignStatusToStudentsAction.AcceptApplication:
            case AssignStatusToStudentsAction.CancelEnrollment:
                return Auth.StudentState.Applicant
            case AssignStatusToStudentsAction.CancelApplication:
                return Auth.StudentState.Prospect
            case AssignStatusToStudentsAction.Enroll:
            case AssignStatusToStudentsAction.MarkAsNotAttending:
                return Auth.StudentState.Enrollment
            case AssignStatusToStudentsAction.MarkAsAttending:
                return Auth.StudentState.Student
            default:
                return null
        }
    }

    const onChangeAction = (action: AssignStatusToStudentsAction) => {
        const forState = getForStateByAction(action)
        setAction(action)
        setForStatusState(forState)
        setNewStudentStatus(null)
    }

    const handleClose = () => {
        setAction(null)
        setForStatusState(null)
        setResponse([])
        setNewStudentStatus(null)

        if (isEmpty(response)) {
            onClose()
        } else {
            onReload()
            onClose()
        }
    }

    const renderActions = () => {
        switch (profileState) {
            case Auth.StudentState.Prospect:
                return (
                    <Checkbox
                        checked={action === AssignStatusToStudentsAction.AcceptApplication}
                        onChange={(event) => {
                            onChangeAction(event.target.checked ? AssignStatusToStudentsAction.AcceptApplication : null)
                        }}>
                        Accept admissions application
                    </Checkbox>
                )
            case Auth.StudentState.Applicant:
                return (
                    <Radio.Group
                        onChange={(event) => onChangeAction(event.target.value)}
                        value={action}
                        className={styles.radioBtnWrap}>
                        <Radio value={null} className={styles.radioBtn__item}>
                            None
                        </Radio>
                        <Radio value={AssignStatusToStudentsAction.CancelApplication} className={styles.radioBtn__item}>
                            Cancel Admission Application
                        </Radio>
                        <Radio value={AssignStatusToStudentsAction.Enroll} className={styles.radioBtn__item}>
                            Enroll
                        </Radio>
                    </Radio.Group>
                )
            case Auth.StudentState.Enrollment:
                return (
                    <Radio.Group
                        onChange={(event) => onChangeAction(event.target.value)}
                        value={action}
                        className={styles.radioBtnWrap}>
                        <Radio value={null} className={styles.radioBtn__item}>
                            None
                        </Radio>
                        <Radio value={AssignStatusToStudentsAction.CancelEnrollment} className={styles.radioBtn__item}>
                            Cancel Enroll
                        </Radio>
                        <Radio value={AssignStatusToStudentsAction.MarkAsAttending} className={styles.radioBtn__item}>
                            Mark student as attending
                        </Radio>
                    </Radio.Group>
                )
            case Auth.StudentState.Student:
                return (
                    <Checkbox
                        checked={action === AssignStatusToStudentsAction.MarkAsNotAttending}
                        onChange={(event) => {
                            onChangeAction(
                                event.target.checked ? AssignStatusToStudentsAction.MarkAsNotAttending : null
                            )
                        }}>
                        Mark student as not attending
                    </Checkbox>
                )
            default:
                return null
        }
    }

    const renderStudentStatusDropdown = () => {
        if (!model.clientSetting.isNewStudentStatusesVisible) return null
        return (
            <NewStudentStatusSelectFilter
                value={newStudentStatus}
                onChange={setNewStudentStatus}
                forState={forStatusState}
            />
        )
    }

    const renderContent = () => {
        if (isEmpty(students)) {
            return null
        }
        if (!isEmpty(response)) {
            const failedProfiles = response.filter((item) => !item.success)
            const studentByProfileId = keyBy(students, "profileId")
            return (
                <div>
                    <p className={styles.description}>
                        Successfully changed statuses of {students.length - failedProfiles.length} profiles out of{" "}
                        {students.length}
                    </p>
                    {!isEmpty(failedProfiles) && (
                        <>
                            <p className={styles.detailTitle}>Detail</p>
                            {failedProfiles.map((item) => (
                                <div key={item.studentProfileId}>
                                    {getFullName(studentByProfileId[item.studentProfileId])} - {item.message}
                                </div>
                            ))}
                        </>
                    )}
                    <div className={styles.actions}>
                        <BaseButton title="Close" variant="secondary" onClick={handleClose} />
                    </div>
                </div>
            )
        }
        return (
            <>
                <FormLabel label="Action" />
                <Radio.Group
                    onChange={(event) => onChangeAction(event.target.value)}
                    value={action}
                    className={styles.radioBtnWrap}>
                    {renderActions()}
                </Radio.Group>
                <p className={styles.description}>Select a status to assign to {students.length} selected profiles</p>
                {renderStudentStatusDropdown()}
                <div className={styles.actions}>
                    <BaseButton title="Cancel" variant="secondary" onClick={handleClose} />
                    <BaseButton title="Confirm" loading={isSubmitting} onClick={onSubmit} />
                </div>
            </>
        )
    }

    return (
        <BasePopup isShow={isShow} onClose={handleClose} leftIcon="EDIT_LINE" leftIconColor="#fff" width="70vw">
            <div className={styles.wrap}>
                <p className={styles.title}>Change Student Statuses</p>
                {renderContent()}
            </div>
        </BasePopup>
    )
}
