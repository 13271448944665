import React from "react"
import {Row, Col} from "antd"
import {useTranslation} from "react-i18next"
import {BasePopup} from "components/popup"
import {Icon} from "components/Icon"
import {TaskType} from "types/tasks"
import styles from "./ChooseCreateTaskPopup.module.css"

export function ChooseCreateTaskPopup(props) {
    const {isShow, onClose, isLibrary, onClickItem} = props
    const {t} = useTranslation(["tasks"])

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="PLUS_CIRCLE" leftIconColor="#fff" width="60vw">
            <div className={styles.wrap}>
                <p className={styles.title}>{isLibrary ? t("tasks.createLibraryTask") : t("tasks.createTask")}</p>
                <div className={styles.body}>
                    <p className={styles.description}>{t("tasks.selectTaskType")}</p>
                    <Row gutter={[40, 12]}>
                        <Col span={8}>
                            <div className={styles.item} onClick={() => onClickItem(TaskType.DIGITAL_DOCUMENT)}>
                                <div className={styles.iconWrap}>
                                    <Icon icon="DIGITAL_DOCUMENT_TASK" className={styles.icon} />
                                </div>
                                <p className={styles.item__title}>
                                    {isLibrary ? t("tasks.digitalDocument") : t("tasks.instantDigitalDocument")}
                                </p>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className={styles.item} onClick={() => onClickItem(TaskType.SCAN_UPLOAD)}>
                                <div className={styles.iconWrap}>
                                    <Icon icon="UPLOAD" className={styles.icon} />
                                </div>
                                <p className={styles.item__title}>{t("tasks.scanUploadTask")}</p>
                            </div>
                        </Col>
                        {!isLibrary && (
                            <Col span={8}>
                                <div className={styles.item} onClick={() => onClickItem(TaskType.FROM_LIBRARY)}>
                                    <div className={styles.iconWrap}>
                                        <Icon icon="GRID_FILL" className={styles.icon} />
                                    </div>
                                    <p className={styles.item__title}>{t("tasks.taskFromLibrary")}</p>
                                </div>
                            </Col>
                        )}
                    </Row>
                </div>
            </div>
        </BasePopup>
    )
}
