import {Timestamps} from "../common"

export enum RefundStatus {
    Pending = "pending",
    Requested = "requested",
    Refunded = "refunded",
    Rejected = "rejected"
}

export enum RefundType {
    R2T4 = "R2T4",
    Other = "OTHER"
}

export enum ProgramType {
    Credit = "CREDIT",
    Clock = "CLOCK"
}

export enum TreatmentPeriod {
    Payment = "PAYMENT",
    Other = "OTHER"
}

export interface Refund extends Timestamps {
    refundId: string
    studentId: string
    firstName: string
    lastName: string
    name: string
    description?: string
    termId: number
    termName: string
    type: "grant" | "loan" | "others"
    refundType: RefundType
    programType: ProgramType
    amount: number
    status: RefundStatus
    treatment?: {
        firstName: string
        lastName: string
        ssn: string
        dateFormCompleted: Date
        dateSchools: Date
        period: TreatmentPeriod
        step1: {}
        step2: {
            startDate: Date
            endDate: Date
            withdrawlDate: Date
            completedDays: number
            totalDays: number
            hoursScheduled: number
            totalHours: number
            percentage: number
        }
        step3: {
            aidDisbursed: number
            period: number
            aidEarned: number
        }
        step4: {
            box1: number
            box2: number
            box3: number
            box4: number
            box5: number
            box6: number
        }
        step5: {
            percentageUnearnedAidBox1: number
            percentageUnearnedAidBox2: number
            percentageUnearnedAidBox3: number
            amountUnearnedChargesBox1: number
            amountUnearnedChargesBox2: number
            amountUnearnedChargesBox3: number
            amountSchoolReturn: number
        }
        step6: {}
        step7: {
            box1: number
            box2: number
            box3: number
        }
        step8: {
            box1: number
            box2: number
            box3: number
        }
        step9: {
            box1: number
            box2: number
            box3: number
            box4: number
            box5: number
            box6: number
            box7: number
            box8: number
            box9: number
        }
        step10: {}
    }
    disbursement?: {
        studentName: string
        ssn: string
        dateOfSchoolWithdrew: Date
        acceptLateResponse: boolean
        responseNotReceived: boolean
        step1: {
            box1: number
        }
        step2: {
            box1: number
        }
        step3: {
            box1: number
            box2: number
            box3: number
        }
        step4: {}
        step5: {
            loanNotification: Date
            deadlineRespond: Date
            responseReceived: boolean
            responseReceivedDate: Date
            acceptLateResponse: boolean
            responseNotReceived: boolean
        }
        step6: {
            grant: Date
            loan: Date
        }
    }
}

export interface EditableRefund extends Partial<Refund> {}
