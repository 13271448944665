import React from "react"
import {Col, Row} from "antd"
import styles from "./FinAidPackageInfo.module.css"
import {Auth} from "types/auth"
import {useTranslation} from "react-i18next"
import {FinAidOffer} from "types/fin-aid/fin-aid"
import {finAidService} from "services"
import cx from "classnames"
import {Icon} from "components"
import {useVisible} from "hooks"

type Props = {
    student?: Auth.DepartmentStudent
    isPanel?: boolean
}

export function FinAidPackageInfo({student, isPanel = false}: Props) {
    const {t} = useTranslation(["financialAid"])
    const detailVisible = useVisible(true)
    const [finAidOffer, setFinAidOffer] = React.useState<FinAidOffer | undefined>()

    React.useEffect(() => {
        ;(async function loadLatestFinAidOffer() {
            if (!student?.profileId) return
            setFinAidOffer(await finAidService.getFinAidLatestOffer({studentProfileId: student.profileId}))
        })()
    }, [student?.profileId])

    const awardYear = finAidOffer?.awardYear ? `${finAidOffer.awardYear} - ${finAidOffer.awardYear + 1}` : "-"

    const dependencyStatus =
        student?.dependencyStatus || finAidOffer?.finAidPackage?.dependencyStatus
            ? t(
                  `financialAidPackage.dependencyStatuses.${
                      student?.dependencyStatus || finAidOffer?.finAidPackage?.dependencyStatus
                  }`
              )
            : "-"

    const payingMethods = student?.payingMethods?.map((method) => method.name).join(", ") || "-"

    const finAidStatus = finAidOffer?.status ? t(`finAidOffer.status.${finAidOffer?.status}`) : "-"

    if (isPanel) {
        return (
            <div className={styles.panelWrap}>
                <div className={styles.headingWrap}>
                    <div className={styles.headingTitle}>Financial Aid Details</div>
                    <button
                        className={cx(styles.collapseButton, {[styles.collapsed]: detailVisible.isVisible})}
                        onClick={detailVisible.toggle}>
                        <Icon icon="ARROW_DOWN" color="#666666" className={styles.headingIcon} />
                    </button>
                </div>

                {detailVisible.isVisible && (
                    <>
                        <div className={styles.contentWrap}>
                            <div className={styles.contentTitle}>Dependency Status</div>
                            <div className={styles.contentValue} title={dependencyStatus}>
                                {dependencyStatus}
                            </div>
                        </div>
                        <div className={styles.contentWrap}>
                            <div className={styles.contentTitle}>Method of paying for College</div>
                            <div className={styles.contentValue} title={payingMethods}>
                                {payingMethods}
                            </div>
                        </div>
                        <div className={styles.contentWrap}>
                            <div className={styles.contentTitle}>Current Award Year</div>
                            <div className={styles.contentValue} title={awardYear}>
                                {awardYear}
                            </div>
                        </div>
                        <div className={styles.contentWrap}>
                            <div className={styles.contentTitle}>Financial Aid Package Status</div>
                            <div className={styles.contentValue} title={finAidStatus}>
                                {finAidStatus}
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }

    return (
        <div className={styles.finAidInfo}>
            <Row gutter={[8, 8]}>
                <Col span={12}>
                    <div className={styles.infoSection}>
                        <div className={styles.infoLabel}>Current Award Year</div>
                        <div className={styles.infoValue} title={awardYear}>
                            {awardYear}
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className={styles.infoSection}>
                        <div className={styles.infoLabel}>Dependency Status</div>
                        <div className={styles.infoValue} title={dependencyStatus}>
                            {dependencyStatus}
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className={styles.infoSection}>
                        <div className={styles.infoLabel}>Method of paying for College</div>
                        <div className={styles.infoValue} title={payingMethods}>
                            {payingMethods}
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className={styles.infoSection}>
                        <div className={styles.infoLabel}>Financial Aid Package Status</div>
                        <div className={styles.infoValue} title={finAidStatus}>
                            {finAidStatus}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
