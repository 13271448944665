/* eslint-disable react-hooks/exhaustive-deps */
import {KlassappTableHOC} from "HOC"
import styles from "./AdmissionsFunnel.module.css"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {useEffect, useMemo, useState} from "react"
import {checkPermission, getPositionMoveInObject, handleError} from "helpers"
import {Order} from "types/common"
import {getPermissionsByDepartment} from "../helper"
import {BaseDepartmentId} from "types/departments"
import {AddItemCircleButton} from "components"
import {isEmpty} from "lodash"
import {admissionService} from "services"
import {useModel, useVisible} from "hooks"
import {ConversionRateSettings, StatusForm} from "./parts"

function AdmissionsFunnelSettings(props) {
    const {
        data,
        page,
        total,
        pageSize,
        columns,
        fields,
        allFields,
        isLoading,
        orderField,
        dispatch,
        isLoadedTableFuncs,
        menuActions
    } = props

    const [currentItem, setCurrentItem] = useState<any>()
    const formVisible = useVisible()
    const [settingLoadKey, setSettingLoadKey] = useState<any>()
    const model = useModel()

    useEffect(() => {
        dispatch({isClassComponent: false})
        props.dispatchFunc([
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getMenuActions", func: getMenuActions},
            {key: "getColumns", func: getColumns}
        ])
        dispatch({orderField: {field: "priority", order: Order.Asc}})
    }, [])

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getData()
        }
    }, [isLoadedTableFuncs, page, pageSize, orderField?.field, orderField?.order])

    const canShowActionIcon = () => {
        return checkPermission(getPermissionsByDepartment(BaseDepartmentId.Admissions), model)
    }

    const onClickEdit = (editItem) => {
        onClickRowItem(editItem)
    }

    const onClickDelete = async (deletedItem) => {
        try {
            await admissionService.deleteFunnelStatus({ids: [deletedItem.id]})
            await getData()
        } catch (err) {
            handleError(err)
        }
    }

    function getMenuActions() {
        return [
            {
                title: "Edit",
                icon: "EDIT",
                action: onClickEdit,
                canShow: canShowActionIcon
            },
            {
                title: "Remove",
                icon: "DELETE",
                action: onClickDelete,
                canShow: canShowActionIcon
            }
        ]
    }

    const onClickRowItem = (row) => {
        setCurrentItem({...row})
        formVisible.open()
    }

    const getParams = () => {
        const params: any = {
            range: {
                page,
                pageSize
            },
            sort: {
                orderBy: "priority",
                orderDir: Order.Asc
            }
        }
        if (!isEmpty(orderField)) {
            params.sort = {
                orderBy: orderField.field,
                orderDir: orderField.order
            }
        }
        return params
    }

    async function getData() {
        try {
            dispatch({isLoading: true})

            const params = getParams()
            const {data, total} = await admissionService.getAllFunnelStatuses(params)

            dispatch({data, total})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    function getColumns() {
        return [
            {
                title: "Priority",
                field: "priorityRank",
                style: {minWidth: "80px"},
                sortable: true,
                orderField: "priority"
            },
            {
                title: "Status",
                field: "statusName",
                style: {minWidth: "100px"},
                sortable: true,
                orderField: "statusId"
            }
        ]
    }

    function getFields() {
        return ["Priority", "Status"]
    }

    const onDraggableEnd = async (result) => {
        const {destination, source} = result || {}
        if (!destination || destination.index === source.index) {
            return
        }
        if (destination.index === source.index) {
            return
        }
        const {id} = props.data[source.index]
        const newPriority = getPositionMoveInObject(
            props.data,
            "priority",
            source.index,
            destination.index,
            props.orderField.order === Order.Asc
        )

        try {
            await admissionService.updateFunnelStatus({id, priority: newPriority})
            await getData()
            setSettingLoadKey(new Date())
        } catch (err) {
            handleError(err)
        }
    }

    const maxRank = useMemo(() => {
        const orders = data.length ? data.map((item) => item.priorityRank) : [0]
        return Math.max(...orders) + 1
    }, [data])

    const handleSubmitSuccessfully = () => {
        setCurrentItem(undefined)
        formVisible.close()
        getData()
        setSettingLoadKey(new Date())
    }

    const handleCreateStatus = () => {
        setCurrentItem(undefined)
        formVisible.open()
    }

    return (
        <div className={styles.wrapper}>
            <ConversionRateSettings key={settingLoadKey} />
            <KlassappTableHeader
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
            />
            <KlassappTable
                columns={columns}
                data={data}
                menuActions={menuActions}
                isLoading={isLoading}
                fields={fields}
                allFields={allFields}
                orderField={orderField}
                isDraggable={orderField.field === "priority"}
                onClickRowItem={props.onClickRowItem}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onClickSortColumn={props.onClickSortColumn}
                onDraggableEnd={onDraggableEnd}
            />
            {canShowActionIcon && !isLoading && <AddItemCircleButton onClick={handleCreateStatus} />}
            <StatusForm
                open={formVisible.isVisible}
                onClose={() => formVisible.close()}
                item={currentItem}
                onSuccess={handleSubmitSuccessfully}
                maxRank={maxRank}
            />
        </div>
    )
}

export const AdmissionsFunnel = KlassappTableHOC(AdmissionsFunnelSettings)
