import React, {useContext} from "react"
import {AcademicsForm, AdmissionsForm, FinancialAidForm, StudentServicesForm} from "uiKit/StudentInfo/parts"
import {BaseLoading} from "components"
import {PanelExpandedSection} from "uiKit/PanelExpandedSection"
import {ActiveStudentContext} from "context/ActiveStudentContext"
import {BaseDepartmentId} from "types/departments"
import {CareerServiceForm} from "sections/shared/careerService"

type Props = {
    onClose?: () => void
    onSuccess?: () => void
}

export function StudentDepartmentInfo(props: Props) {
    const {onClose, onSuccess} = props
    const {departmentId, student} = useContext(ActiveStudentContext)

    let title = ""
    let form = null
    switch (departmentId) {
        case BaseDepartmentId.Admissions:
            title = "Admissions Management"
            form = <AdmissionsForm onClickCancel={onClose} onSaveSuccess={onSuccess} displayMode="panel" />
            break
        case BaseDepartmentId.FinancialAid:
            title = "Financial Aid Management"
            form = <FinancialAidForm onClickCancel={onClose} onSaveSuccess={onSuccess} displayMode="panel" />
            break
        case BaseDepartmentId.StudentAccount:
            title = "Student Account Management"
            form = <FinancialAidForm onClickCancel={onClose} onSaveSuccess={onSuccess} displayMode="panel" />
            break
        case BaseDepartmentId.Academics:
            title = "Academics Management"
            form = <AcademicsForm onClickCancel={onClose} onSaveSuccess={onSuccess} displayMode="panel" />
            break
        case BaseDepartmentId.StudentServices:
            title = "Student Services Management"
            form = <StudentServicesForm onClickCancel={onClose} onSaveSuccess={onSuccess} displayMode="panel" />
            break
        case BaseDepartmentId.CareerServices:
            title = "Career Services Management"
            form = <CareerServiceForm profileId={student.profileId} onSaveSuccess={onSuccess} displayMode="panel" />
            break
    }
    return (
        <PanelExpandedSection title={title} onClose={onClose}>
            {form}
        </PanelExpandedSection>
    )
}
