/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import cx from "classnames"
import {useLocation} from "react-router-dom"
import {useModel} from "hooks"
import HTMLTitle from "components/misc/HTMLTitle"
import {ScreenHeader, ScreenHeaderProps} from "../ScreenHeader"
import classes from "./Screen.module.css"
import {ScreenRightPanel, ScreenRightPanelProps} from "../ScreenRightPanel"
import {ActiveStudentProvider} from "context/ActiveStudentContext"

export type ScreenProps = {
    className?: string
    header: ScreenHeaderProps
    children: any
    htmlTitle?: string
    htmlSubtitle?: string
    backData?: any
    rightPanel?: ScreenRightPanelProps
    isReducingPaddingRight?: boolean
}

export const Screen = ({
    className,
    header,
    children,
    htmlTitle,
    htmlSubtitle,
    rightPanel,
    isReducingPaddingRight = false
}: ScreenProps) => {
    const location = useLocation()
    const model = useModel()
    const [isRightPanelOpen, setIsRightPanelOpen] = useState(false)

    const rightPanelDetected = rightPanel && !!rightPanel.data

    useEffect(() => {
        if (!model.prevLocation) {
            model.setPrevLocation(location)
            model.setCurrentLocation(location)
        } else if (model.currentLocation !== location) {
            model.setCurrentLocation(location)
        }
    }, [location])

    const renderScreen = (shouldReducePaddingRight: boolean = false) => {
        return (
            <div className={cx(classes.root, {[classes.reducePaddingRight]: shouldReducePaddingRight}, className)}>
                <ScreenHeader
                    {...header}
                    className={cx(classes.header, header.className)}
                    htmlTitle={htmlTitle}
                    htmlSubtitle={htmlSubtitle}
                    rightPanelDetected={rightPanelDetected && !isRightPanelOpen}
                />
                {!!htmlTitle && <HTMLTitle title={htmlTitle} />}
                {children}
            </div>
        )
    }

    if (rightPanelDetected) {
        return (
            <div className={classes.screenWrap}>
                {renderScreen(isRightPanelOpen)}
                <ActiveStudentProvider departmentId={rightPanel.data.departmentId}>
                    <ScreenRightPanel {...{...rightPanel, isRightPanelOpen, setIsRightPanelOpen}} />
                </ActiveStudentProvider>
            </div>
        )
    }

    return renderScreen(isReducingPaddingRight)
}
