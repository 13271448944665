import React from "react"
import SignatureCanvas from "react-signature-canvas"
import {BaseButton, SecondaryButton} from "components/buttons"
import {userService} from "services"
import {handleError, getBlobFromBase64String, loadImage, toastError} from "helpers"
import styles from "./SignSignatureEditForm.module.css"

type Props = {
    onSaveSuccessful: (data: any) => void
    onCancel: () => void
    onDelete: () => void
    canDeleteSignatureInitials: boolean
    userId: number
    profileId: number
    useOneTimeSignature?: boolean
}

export class SignSignatureEditForm extends React.Component<Props, {}> {
    sigPad: any = {}

    state = {
        isSubmitting: false
    }

    onClickClear = () => {
        this.sigPad?.clear()
    }

    onClickSave = async () => {
        if (this.sigPad?.isEmpty()) {
            await this.onDeleteSignature()
            return
        }
        const {useOneTimeSignature, profileId, userId} = this.props
        const trimmedDataURL = this.sigPad?.getTrimmedCanvas().toDataURL("image/png")
        const file = await getBlobFromBase64String(trimmedDataURL)
        try {
            this.setState({isSubmitting: true})
            const formData = new FormData()
            formData.append("file", file)
            if (userId) {
                formData.append("userId", userId.toString())
            }
            if (profileId) {
                formData.append("profileId", profileId.toString())
            }
            const {data} = await (useOneTimeSignature
                ? userService.createSignature(formData)
                : userService.updateCurrentSignature(formData))
            await loadImage(data, "imageUrl")
            this.onClickClear()
            this.props.onSaveSuccessful(data)
        } catch (error) {
            handleError(error)
        } finally {
            this.setState({isSubmitting: false})
        }
    }

    onDeleteSignature = async () => {
        if (!this.props.canDeleteSignatureInitials) {
            handleError(`You don't have permissions to delete a signature`, true)
        }
        try {
            const {userId} = this.props
            this.setState({isSubmitting: true})
            await userService.deleteUserSignature({userId})
            this.props.onDelete()
        } catch (error) {
            handleError(error)
        } finally {
            this.setState({isSubmitting: false})
        }
    }

    render() {
        const {isSubmitting} = this.state

        return (
            <div className={styles.wrap}>
                <SignatureCanvas
                    penColor="#000"
                    canvasProps={{className: styles.sigPad}}
                    ref={(ref) => {
                        this.sigPad = ref
                    }}
                />
                <div className={styles.action}>
                    <SecondaryButton title="Cancel" onClick={this.props.onCancel} />
                    <div className={styles.btnWrap}>
                        <SecondaryButton title="Clear" onClick={this.onClickClear} className={styles.clearBtn} />
                        <BaseButton title="Save" onClick={this.onClickSave} loading={isSubmitting} />
                    </div>
                </div>
            </div>
        )
    }
}
