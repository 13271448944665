import React, {useEffect, useState} from "react"
import {Col, Row} from "antd"
import {StatusCardItem} from "./StatusCardItem"
import styles from "./StatusCards.module.css"
import {formatCurrency} from "helpers"
import {studentPaymentService} from "services"
import {round} from "lodash"
import {Moment} from "moment"

const INITIAL_STATS = {
    ratio: 0,
    fedFunds: 0,
    nonFedFunds: 0,
    otherFunds: 0,
    nonIncluded: 0
}

type Props = {
    fiscalYearDates: Moment[] | null
}

export function StatusCards({fiscalYearDates}: Props) {
    const [stats, setStats] = useState(INITIAL_STATS)

    useEffect(() => {
        ;(async function loadStats() {
            if (!fiscalYearDates) {
                setStats(INITIAL_STATS)
                return
            }
            try {
                setStats(await studentPaymentService.getNinetyTenStats({fiscalYearDates}))
            } catch {
                setStats(INITIAL_STATS)
            }
        })()
    }, [fiscalYearDates])

    return (
        <div className={styles.wrap}>
            <Row gutter={[24, 12]} wrap={false}>
                <Col span={4}>
                    <StatusCardItem
                        item={{
                            title: (
                                <span>
                                    90/10
                                    <br />
                                    Ratio
                                </span>
                            ),
                            total: (
                                <>
                                    <div className="text-center">{round(stats.ratio || 0, 4)}</div>
                                    <div className="text-center mt-10 font-semibold">
                                        ({round((stats.ratio || 0) * 100, 2)}%)
                                    </div>
                                </>
                            )
                        }}
                    />
                </Col>
                <Col span={5}>
                    <StatusCardItem
                        item={{
                            title: (
                                <span>
                                    Title IV
                                    <br />
                                    Fed Funds
                                </span>
                            ),
                            total: formatCurrency(stats.fedFunds)
                        }}
                    />
                </Col>
                <Col span={5}>
                    <StatusCardItem
                        item={{
                            title: (
                                <span>
                                    Non Title IV
                                    <br />
                                    Fed Funds
                                </span>
                            ),
                            total: formatCurrency(stats.nonFedFunds)
                        }}
                    />
                </Col>
                <Col span={5}>
                    <StatusCardItem
                        item={{
                            title: (
                                <span>
                                    Rev From
                                    <br />
                                    Other Sources
                                </span>
                            ),
                            total: formatCurrency(stats.otherFunds)
                        }}
                    />
                </Col>
                <Col span={5}>
                    <StatusCardItem
                        item={{
                            title: (
                                <span>
                                    Exclude From
                                    <br />
                                    90/10 Calc
                                </span>
                            ),
                            total: formatCurrency(stats.nonIncluded)
                        }}
                    />
                </Col>
            </Row>
        </div>
    )
}
