import {get} from "lodash"
import {Retention} from "types/retention"
import BaseService from "./Base"

class RetentionService extends BaseService {
    async getListRetentions(params): Promise<{total: number; data: Retention.Retention[]}> {
        const response = await this.post("/v1/retentions/list", params)
        return get(response, "data", [])
    }

    async getRetentionDetail({id}): Promise<Retention.RetentionDetail> {
        const response = await this.post(`/v1/retentions/get`, {id})
        return get(response, "data.data", {})
    }

    async getMeasurements(): Promise<Retention.Measurement[]> {
        const response = await this.post("/v1/retentions/measurements/list", {})
        return get(response, "data.data", [])
    }

    async createRetention(params): Promise<Retention.Retention> {
        const response = await this.post("/v1/retentions/create", params)
        return get(response, "data.data", {})
    }

    async updateRetention(params): Promise<Retention.RetentionDetail> {
        const response = await this.post(`/v1/retentions/update`, params)
        return get(response, "data.data", {})
    }

    async getAcademicStage(): Promise<Retention.AcademicStage> {
        const response = await this.post("/v1/retentions/stages/get", {})
        return get(response, "data.data", {})
    }

    async updateAcademicStage(params): Promise<Retention.AcademicStage> {
        const response = await this.post(`/v1/retentions/stages/update`, params)
        return get(response, "data.data", {})
    }

    async getRetentionAcademicsStats(params): Promise<Retention.RetentionAcademicStats> {
        const response = await this.post("/v1/retentions/academics-stats", params)
        return get(response, "data.data", {})
    }

    async getRetentionRiskStats(params): Promise<Retention.RiskStats> {
        const response = await this.post("/v1/retentions/risk-stats", params)
        return get(response, "data.data", {})
    }

    async getRetentionRiskStatsByCampus(params): Promise<Retention.RiskStatsByEntity[]> {
        const response = await this.post("/v1/retentions/risk-stats-by-campus", params)
        return get(response, "data.data", [])
    }

    async getRetentionRiskStatsByProgram(params): Promise<Retention.RiskStatsByEntity[]> {
        const response = await this.post("/v1/retentions/risk-stats-by-program", params)
        return get(response, "data.data", [])
    }
}

export default RetentionService
