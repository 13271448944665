import {get} from "lodash"
import BaseService from "./Base"
import {
    CreateStripePaymentIntentApiPayload,
    CreateAuthorizeNetStudentPaymentApiPayload,
    CompleteAuthorizeNetStudentPaymentApiPayload,
    StudentPayment,
    CurrencyExchangeRates,
    StudentPaymentCharge
} from "types/student-account/student-payment"
import {DeepPartial} from "types/common"
import {StudentCharge} from "types/student-account/student-ledger"

export default class StudentPaymentService extends BaseService {
    async getPaymentProviderConfigs() {
        const response = await this.get("/v1/students/payments/providers/config")
        return get(response, "data.data", [])
    }

    async getCurrencyExchangeRates(): Promise<CurrencyExchangeRates> {
        const response = await this.get("/v1/students/payments/currency-exchange-rates")
        return get(response, "data.data", {})
    }

    async getNinetyTenStats(params = {}): Promise<any> {
        const response = await this.post("/v1/students/payments/90-10-stats", params)
        return get(response, "data.data", {})
    }

    async getPayments(params = {}): Promise<{total: number; data: StudentPayment[]}> {
        const response = await this.post("/v1/students/payments/list", params)
        return get(response, "data", {})
    }

    createPayment = async (
        data: DeepPartial<StudentPayment> & {
            isSendInvoice?: boolean
        }
    ): Promise<StudentPayment> => {
        const response = await this.post("/v1/students/payments/create", data)
        return get(response, "data.data", {})
    }

    updatePayment = async (
        data: DeepPartial<StudentPayment> & {
            isSendInvoice?: boolean
        }
    ): Promise<StudentCharge> => {
        const response = await this.post("/v1/students/payments/edit", data)
        return get(response, "data.data", {})
    }

    assignPaymentCharges = async (
        paymentId: number,
        paymentCharges: Partial<StudentPaymentCharge>[]
    ): Promise<StudentPayment> => {
        const response = await this.post("/v1/students/payments/assign-charges", {paymentId, paymentCharges})
        return get(response, "data.data", {})
    }

    deletePayments = async (ids: number[]): Promise<void> => {
        await this.post("/v1/students/payments/delete", {ids})
    }

    async createPaymentIntent(data: CreateStripePaymentIntentApiPayload) {
        const response = await this.post("/v1/students/payments/stripe/create-payment-intent", data)
        return get(response, "data.data", [])
    }

    async createAuthorizeNetStudentPayment(data: CreateAuthorizeNetStudentPaymentApiPayload) {
        const response = await this.post("/v1/students/payments/authorize-net/create", data)
        return get(response, "data.data", [])
    }

    async completeAuthorizeNetStudentPayment(data: CompleteAuthorizeNetStudentPaymentApiPayload) {
        const response = await this.post("/v1/students/payments/authorize-net/complete-student-payment", data)
        return get(response, "data.data", [])
    }
}
