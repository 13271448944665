import {useState} from "react"
import {Communication} from "types/communication"

export const useMessageCompose = () => {
    const [editMessage, setEditMessage] = useState<Communication.Message | undefined>()
    const [replyMessage, setReplyMessage] = useState<Communication.ReplyMessage | undefined>()

    const onEditMessage = (_message: Communication.Message) => {
        setReplyMessage(undefined)
        setEditMessage({..._message, editedAt: new Date().toDateString()})
    }

    const onReplyMessage = (_message: Communication.Message) => {
        const _replyMessage: Communication.ReplyMessage = {
            messageId: _message.messageId,
            authorFullName: _message.author.fullName,
            content: _message.content,
            file: _message.file,
            withEmail: _message.withEmail,
            withSms: _message.withSms
        }
        setReplyMessage(_replyMessage)
        setEditMessage(undefined)
    }

    return {
        editMessage,
        replyMessage,
        onEditMessage,
        onReplyMessage
    }
}
