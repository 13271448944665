import React, {useRef, useState} from "react"
import {Popover} from "antd"
import {BaseTextArea} from "components"
import {Icon} from "components/Icon"
import {DigitalDocumentFieldSetUp} from "types/tasks"
import styles from "./item.module.css"

type Props = {
    field: any
    onChangeRemarkField: (fieldId: string, comment: string) => void
    children: any
}

export function RemarkIndicator(props: Props) {
    const {field, onChangeRemarkField} = props
    const [comment, setComment] = useState("")
    const popoverRef: any = useRef()
    const {top, left} = field.placeInformation

    const onChangeComment = (comment: string) => {
        setComment(comment)
        if (
            (field.type === DigitalDocumentFieldSetUp.CHECKBOX || field.type === DigitalDocumentFieldSetUp.INITIAL) &&
            field.parentId
        ) {
            onChangeRemarkField(field.parentId, comment)
        } else {
            onChangeRemarkField(field.id, comment)
        }
    }

    const commentInput = (
        <div>
            <BaseTextArea
                value={comment}
                onChange={onChangeComment}
                placeholder="Comment"
                className={styles.commentInput}
            />
        </div>
    )

    const onClickRemarkIcon = () => {
        if (popoverRef.current && popoverRef.current.onClick) {
            popoverRef.current.onClick()
        }
    }

    return (
        <>
            <div className={styles.wrap} style={{left, top}}>
                <Popover ref={popoverRef} color="#ffe55b" content={commentInput} trigger="click">
                    {props.children}
                </Popover>
            </div>
            {comment && (
                <div className={styles.wrap} style={{left, top, zIndex: 9}} onClick={onClickRemarkIcon}>
                    <div className={styles.remarkIndicator}>
                        <Icon icon="STICKY_NOTE" className={styles.remarkIndicatorIcon} color="#FF8A38" />
                    </div>
                </div>
            )}
        </>
    )
}
