import {useCallback, useState} from "react"
import {useMutation} from "hooks/useMutation"
import {profileService, userServiceV3} from "services"
import {Auth} from "types/auth"
import {isEmpty} from "lodash"
import {validateEmail} from "helpers"

type NewStudent = {
    firstName: string
    lastName: string
    email: string
    phone: string
    enrollmentType?: {id: string; name: string} | null
    enrollmentStatus?: {id: string; name: string} | null
    currentMajor?: {id: string; name: string} | null
    campus?: {id: string; name: string} | null
}

const NEW_STUDENT_DEFAULT_VALUES: NewStudent = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    enrollmentType: null,
    enrollmentStatus: null,
    currentMajor: null,
    campus: null
}

export const useCreateNewStudent = () => {
    const [data, setData] = useState<NewStudent>(NEW_STUDENT_DEFAULT_VALUES)
    const [errors, setErrors] = useState<Partial<Record<keyof NewStudent, boolean>>>({})

    const createMutation = useMutation(
        async () => {
            const [{data: customUserId}, customProfileId] = await Promise.all([
                userServiceV3.nextCustomUserId(),
                profileService.getNextCustomProfileId()
            ])
            const payload = {
                firstName: data.firstName,
                lastName: data.lastName,
                customUserId,
                emails: [{email: data.email, isPrimary: true}],
                phones: !isEmpty(data.phone) ? [{phone: data.phone, isPrimary: true}] : undefined
            }
            const {
                data: [newUser]
            } = await userServiceV3.create(payload)
            const newProfile = {
                isDefault: true,
                userId: newUser.id,
                type: Auth.UserProfileType.Student,
                state: Auth.UserProfileState.Prospect,
                customProfileId,
                campuses: data.campus ? [{id: data.campus.id, name: data.campus.name}] : [],
                currentMajor: data.currentMajor.id,
                enrollmentStatus: data.enrollmentStatus.id,
                enrollmentType: data.enrollmentType.id
            }
            await profileService.create([newProfile])
            return await userServiceV3.getOne(newUser.id)
        },
        {onSuccess: () => reset()}
    )
    const set = useCallback((values: Partial<NewStudent>) => {
        setData((current) => ({...current, ...values}))
    }, [])

    const reset = useCallback(() => {
        setData(NEW_STUDENT_DEFAULT_VALUES)
    }, [])

    const checkEmailExisted = async () => {
        try {
            return await userServiceV3.isUserEmailAlreadyExist({email: data.email})
        } catch (error) {
            return true
        }
    }

    const validate = async () => {
        const requiredFields: (keyof NewStudent)[] = [
            "firstName",
            "lastName",
            "email",
            "enrollmentStatus",
            "enrollmentType",
            "campus",
            "currentMajor"
        ]
        const errorFields = {}
        requiredFields.forEach((field) => {
            const hasError = isEmpty(data[field])
            if (hasError) {
                errorFields[field] = hasError
            }
        })
        if (!isEmpty(errorFields)) {
            setErrors(errorFields)
            return "Please fill in all required fields"
        }
        if (!validateEmail(data.email)) {
            setErrors({email: true})
            return "Please enter a valid email"
        }
        const isEmailExisted = await checkEmailExisted()
        if (isEmailExisted) {
            setErrors({email: true})
            return "Email already exists"
        }
        setErrors({})
        return null
    }

    return {
        data,
        isCreating: createMutation.isLoading,
        errors,
        set,
        create: createMutation.mutateAsync,
        validate
    }
}
