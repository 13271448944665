import {get} from "lodash"
import BaseService from "./Base"
import {SMSChat} from "types/smsChat"

class SMSChatService extends BaseService {
    async list(params): Promise<{data: SMSChat.SMSChat[]; total: number}> {
        const response = await this.post("/v1/sms-chat/list", params)
        return get(response, "data", [])
    }

    async getMessages(params): Promise<{data: SMSChat.SMSChatMessage[]; total: number}> {
        const response = await this.post("/v1/sms-chat/get-messages", params)
        return get(response, "data", [])
    }

    async send(params): Promise<SMSChat.SMSChatMessage> {
        const response = await this.post("/v1/sms-chat/send", params)
        return get(response, "data.data", {})
    }

    async create(params): Promise<void> {
        const response = await this.post("/v1/sms-chat/create", params)
        return get(response, "data.data", {})
    }

    async markAsRead(params): Promise<void> {
        const response = await this.post("/v1/sms-chat/mark-as-read", params)
        return get(response, "data.data", {})
    }

    async markAsUnread(params): Promise<void> {
        const response = await this.post("/v1/sms-chat/mark-as-unread", params)
        return get(response, "data.data", {})
    }

    async getDetails(params): Promise<SMSChat.SMSChatMessage> {
        const response = await this.post("/v1/sms-chat/get", params)
        return get(response, "data.data", {})
    }

    async getUnreadCount(): Promise<{unreadCount: number}> {
        const response = await this.post("/v1/sms-chat/unread", {})
        return get(response, "data.data", {})
    }
}

export default SMSChatService
