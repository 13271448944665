import {get} from "lodash"
import {BaseService} from "services"

export default class ClientsService extends BaseService {
    async getClientDetail() {
        const response = await this.post("/v1/clients/get", {})
        return get(response, "data.data", {})
    }

    async editClient(data) {
        const response = await this.post(`/v1/clients/edit`, data)
        return get(response, "data.data", {})
    }

    async getSISList() {
        const response = await this.post("/v1/sis/list", {})
        return get(response, "data.data", [])
    }

    async editSIS(data) {
        const response = await this.post(`/v1/sis/edit`, data)
        return get(response, "data.data", [])
    }
}
