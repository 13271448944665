export const classes = [
    {
        id: "1",
        date: "22/02/2021",
        time: "9:00 - 11:30 AM",
        instructor: "John Jones"
    },
    {
        id: "2",
        date: "22/02/2021",
        time: "9:00 - 11:30 AM",
        instructor: "John Jones"
    },
    {
        id: "3",
        date: "22/02/2021",
        time: "9:00 - 11:30 AM",
        instructor: "John Jones"
    }
]
