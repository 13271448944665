import {head, orderBy} from "lodash"
import {taskService} from "services"
import {Auth} from "types/auth"
import {handleError} from "./errorHelper"

export async function reassignCustomTaskUser(userId: number, taskUserId: number, history, appType) {
    try {
        const {data} = await taskService.reassignTaskUser({userId, taskUserId}, `appType=${appType}`)
        window.location.assign(data)
    } catch (e) {
        handleError(e)
        history.replace("/")
    }
}

export function getFirstProfile(user: Auth.UserProfile, type?: Auth.UserProfileType): Auth.Profile {
    let profiles = user.profiles || []
    if (type) {
        profiles = profiles.filter((profile) => profile.type === type)
    }
    const sortedProfiles = orderBy(profiles, "active", "desc")
    return head(sortedProfiles)
}

export function getDefaultOrFirstProfile(user: Auth.UserProfile, type?: Auth.UserProfileType): Auth.Profile {
    let profiles = user.profiles || []
    if (type) {
        profiles = profiles.filter((profile) => profile.type === type)
    }
    const sortedProfiles = orderBy(profiles, "active", "desc")
    return sortedProfiles.find((profile) => profile.isDefault) || head(sortedProfiles)
}
