import React from "react"
import {withTranslation} from "react-i18next"
import {Row, Col} from "antd"
import {keyBy, get, isEmpty, head} from "lodash"
import debounce from "debounce-promise"
import {KlassappTable, KlassappTableHeader, BaseNewFilter} from "uiKit"
import {KlassappTableProps} from "types/common"
import {BaseButton} from "components/buttons"
import {Icon} from "components/Icon"
import DepartmentSubunitSelect from "components/DepartmentSubunitSelect"
import {KlassDropAsyncPaginate, KlassDropdown} from "components/Select"
import {PermissionType} from "types/permission"
import {handleError, getFullName, checkTaskDepartmentPermission} from "helpers"
import {TaskType, TaskStatus, TaskSubtype} from "types/tasks"
import {KlassappTableHOC} from "HOC"
import {taskService, departmentSubunitService, userServiceV3, userService} from "services"
import {Status} from "sections/Tasks/Common"
import {CardItem, ChooseCreateTaskPopup} from "sections/Tasks/parts"
import styles from "./Tasks.module.css"
import {Auth} from "types/auth"
import {Model} from "Model"
import {FilterKey} from "types/filter"

type PageProps = {
    t: Function
    studentId: number
    userId?: number
    history: any
    departmentId?: number
    fromPage?: {backUrl: string}
    model: Model
    backUrlFromPage?: string
}

type State = {
    departmentSubunits: any[]
    userId: number
    search: string
    filter: any
    statusInfos: any[]
    isShowPopup: boolean
}

type Props = KlassappTableProps & PageProps

class Tasks extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        const storageData = this.props.model.getStorageFilter(FilterKey.TasksUserPage)
        const filter = !isEmpty(storageData?.filter) ? storageData.filter : {status: {}, departments: [], staffs: []}
        this.state = {
            departmentSubunits: [],
            userId: null,
            search: !isEmpty(storageData) ? storageData.search : "",
            filter,
            isShowPopup: false,
            statusInfos: [
                {id: "all", title: "all", status: "all", count: 0},
                {id: "todo", title: "todo", status: "todo", count: 0},
                {id: "draft", title: "draft", status: "draft", count: 0},
                {id: "started", title: "started", status: "started", count: 0},
                {id: "overdue", title: "overdue", status: "overdue", count: 0},
                {id: "awaitingApproval", title: "awaiting_approval", status: "awaiting_approval", count: 0},
                {id: "done", title: "done", status: "done", count: 0}
            ]
        }
        this.onStaffSearchChange = debounce(this.onStaffSearchChange.bind(this), 300)
    }

    async componentDidMount() {
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getData},
            {key: "getFields", func: this.getFields},
            {key: "getTableHeaderActions", func: this.getTableHeaderActions},
            {key: "getColumns", func: this.getColumns},
            {key: "onClickDeleteMulti", func: this.onClickDeleteMulti},
            {key: "onClickEdit", func: this.onClickEdit},
            {key: "onClickDelete", func: this.onClickDelete},
            {key: "onClickRowItem", func: this.onClickRowItem}
        ])
        this.props.dispatch({isLoading: true})
        const [departmentSubunits, userId] = await Promise.all([this.getDepartmentSubunits(), this.getUserId()])
        this.getData(userId, departmentSubunits)
        this.getTaskDashboard(userId)
    }

    getUserId = async () => {
        try {
            const {data} = await userService.searchUsers({
                filters: {profileIds: [+this.props.studentId]},
                range: {page: 1, pageSize: 1}
            })
            const userId = get(head(data), "userId") || this.props.userId
            this.setState({userId})
            return userId
        } catch (error) {
            return ""
        }
    }

    getDepartmentSubunits = async () => {
        try {
            const params: any = {}
            const {departmentId} = this.props
            if (departmentId) {
                params.filter = {
                    departmentIds: [departmentId]
                }
            }
            const {data: departmentSubunits} = await departmentSubunitService.getSubunits(params)
            this.setState({departmentSubunits})
            return departmentSubunits
        } catch (error) {
            handleError(error)
            return []
        }
    }

    getPageTitle = () => {
        return this.props.t("tasks.task")
    }

    getFields = () => {
        const {t} = this.props
        return [
            t("tasks.code"),
            t("tasks.name"),
            t("tasks.type"),
            t("tasks.department"),
            t("tasks.responses"),
            t("tasks.dueDate"),
            t("tasks.status"),
            t("tasks.assignedBy"),
            t("tasks.assignedTo"),
            t("tasks.createdAt")
        ]
    }

    getTableHeaderActions = () => {
        const {t, onClickShowConfirmModal} = this.props
        return [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            }
        ]
    }

    getTaskDashboard = async (userId = null) => {
        try {
            const params = this.getParams(userId)
            const {statusInfos} = this.state
            const data = await taskService.getTaskDashboard(params.filter)
            const newStatusInfos = statusInfos.map((info) => {
                if (info.id === "all") {
                    info.count = data.all
                } else {
                    info.count = data[info.id]
                }
                return info
            })
            this.setState({statusInfos: newStatusInfos})
        } catch (error) {
            handleError(error)
        }
    }

    getData = async (userId = null, departmentSubunits = null) => {
        const {dispatch} = this.props
        const {departmentSubunits: departmentSubunitsState} = this.state
        const subunits = departmentSubunits || departmentSubunitsState
        const subunitsKeyById = keyBy(subunits, "subunitId")
        const params = this.getParams(userId)
        dispatch({isLoading: true})
        try {
            const {data, total} = await taskService.getAll(params)
            const newData = data.map((task: any) => {
                task.statusHtml = this.renderStatusHtml(task.status, task.allSubtaskRejected)
                task.typeHtml = this.renderTypeHtml(task.type, task.parentId)
                task.responses = task.responsesLabel
                task.departmentSubunitName = get(subunitsKeyById, [task.departmentId, "name"])
                task.assignedToHtml = this.renderAssignedToHtml(task.assignedTo)
                return task
            })
            this.props.dispatch({data: newData, total: total})
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    renderAssignedToHtml = ({assignedTo, customProfileId}) => {
        return (
            <div className={styles.assignedTo}>
                <span>{assignedTo}</span>
                {customProfileId && (
                    <span className={styles.enrollmentIds}>
                        <span className={styles.enrollmentIdItem} key={`{${customProfileId}}`}>
                            {customProfileId}
                        </span>
                    </span>
                )}
            </div>
        )
    }

    renderTypeHtml = (type, parentId) => {
        const {t} = this.props
        if (parentId) {
            return t("tasks.taskFromLibrary")
        }
        switch (type) {
            case TaskType.DIGITAL_DOCUMENT:
                return t("tasks.digitalDocument")
            case TaskType.SCAN_UPLOAD:
                return t("tasks.scanUpload")
            case TaskType.FROM_LIBRARY:
                return t("tasks.library")
            case TaskType.FORM:
                return t("tasks.form")
            case TaskType.CUSTOM:
                return t("tasks.custom")
            default:
                return ""
        }
    }

    renderStatusHtml = (status, allSubtaskRejected) => {
        return <Status status={status} doneWithRejected={allSubtaskRejected} />
    }

    getParams = (userId) => {
        const {page, pageSize, orderField} = this.props
        const {filter, search} = this.state
        const {status, staffs, departments} = filter
        const filterData: any = {
            search,
            participantUserId: userId || this.state.userId || -1
        }
        if (!isEmpty(staffs)) {
            filterData.staff = staffs.map((item) => item.id)
        }
        if (!isEmpty(departments)) {
            filterData.departmentIds = (departments || []).map((department) => department.subunitId)
        }
        if (!isEmpty(status)) {
            filterData.status =
                status.id === "all"
                    ? [
                          TaskStatus.TODO,
                          TaskStatus.DRAFT,
                          TaskStatus.STARTED,
                          TaskStatus.REJECTED,
                          TaskStatus.OVERDUE,
                          TaskStatus.AWAITING_APPROVAL
                      ]
                    : [status.status]
        }
        const params = {
            filter: filterData,
            range: {page, pageSize},
            sort: {orderBy: orderField.field ?? "updatedAt", orderDir: orderField.order ?? "DESC"}
        }
        return params
    }

    getColumns = () => {
        const {t} = this.props
        return [
            {
                title: t("tasks.code"),
                field: "code",
                style: {minWidth: "120px"},
                sortable: true
            },
            {
                title: t("tasks.name"),
                field: "name",
                style: {minWidth: "150px"},
                orderField: "name",
                sortable: true
            },
            {
                title: t("tasks.type"),
                field: "typeHtml",
                style: {minWidth: "150px"},
                orderField: "type",
                sortable: true
            },
            {
                title: t("tasks.department"),
                field: "departmentSubunitName",
                style: {minWidth: "120px"}
            },
            {
                title: t("tasks.responses"),
                field: "responses",
                style: {minWidth: "110px"},
                orderField: "responses"
            },
            {
                title: t("tasks.dueDate"),
                field: "dueDate",
                style: {minWidth: "130px"},
                orderField: "dueDate",
                fieldType: "date",
                format: "MM/DD/YYYY",
                sortable: true
            },
            {
                title: t("tasks.status"),
                field: "statusHtml",
                style: {minWidth: "150px"},
                orderField: "status",
                sortable: true
            },
            {
                title: t("tasks.assignedBy"),
                field: "assignedBy",
                style: {minWidth: "150px"},
                orderField: "assignedBy",
                sortable: true
            },
            {
                title: t("tasks.assignedTo"),
                field: "assignedToHtml",
                style: {minWidth: "150px"}
            },
            {
                title: t("tasks.createdAt"),
                field: "createdAt",
                fieldType: "dateTime",
                style: {minWidth: "160px"},
                sortable: true
            }
        ]
    }

    onClickDeleteMulti = async () => {
        const {data, dispatch} = this.props
        const checkedItems = data.filter((item) => item.isChecked)
        const payload = checkedItems.map((item) => item.id)
        try {
            this.props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await taskService.deleteTask(payload)
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    onClickRowItem = (data) => {
        const {history, fromPage, backUrlFromPage} = this.props
        const backParams = {
            pathname: `/tasks/detail`,
            search: `type=${data.type}&subtype=assigned&id=${data.id}`
        }
        if (fromPage) {
            history.push({
                ...backParams,
                state: {backUrl: fromPage.backUrl, ...(backUrlFromPage && {backData: {backUrlFromPage}})}
            })
            return
        }
        history.push(backParams)
    }

    onClickEdit = (editItem) => {
        const {history} = this.props
        history.push(`/tasks/detail?type=${editItem.type}&subtype=assigned&id=${editItem.id}`)
    }

    onClickDelete = async (deletedItem) => {
        const {dispatch} = this.props
        try {
            dispatch({isLoading: true})
            await taskService.deleteTask([deletedItem.id])
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    onChangeStatusFilter = (status) => {
        const {filter, statusInfos} = this.state
        const statusInfosKeyByValue = keyBy(statusInfos, "status")
        const newFilter: any = {...filter}
        newFilter.status = statusInfosKeyByValue[status]
        this.props.model.updateStorageFilter(FilterKey.TasksUserPage, {filter: newFilter})
        this.setState({filter: newFilter}, () => {
            this.getData()
            this.getTaskDashboard()
        })
    }

    onClickFilter = () => {
        const {filter} = this.state
        this.props.model.updateStorageFilter(FilterKey.TasksUserPage, {filter})
        this.getData()
        this.getTaskDashboard()
    }

    onClickClearFilter = () => {
        this.setState({filter: {status: null, departments: [], staffs: []}})
    }

    onChangeFilter = (key, value) => {
        const {filter} = this.state
        filter[key] = value
        this.setState({filter: {...filter}})
    }

    onSearchInput = (search) => {
        this.props.model.updateStorageFilter(FilterKey.TasksUserPage, {search})
        this.props.dispatch({page: 1}, () => {
            this.setState({search}, () => {
                this.getData()
                this.getTaskDashboard()
            })
        })
    }

    onStaffSearchChange = async (search, loadedOptions) => {
        try {
            const {data: staffs, total} = await userServiceV3.getAll({
                filter: {
                    type: [Auth.UserProfileType.Staff],
                    search,
                    active: 1
                },
                range: {
                    limit: 20,
                    offset: loadedOptions.length
                }
            })
            return {
                options: staffs.map(({id, firstName, middleName, lastName}) => ({id, firstName, middleName, lastName})),
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    onClickCreateTask = () => {
        this.setState({isShowPopup: true})
    }

    onClickCreateTaskWithType = (type) => {
        const {history, backUrlFromPage, fromPage} = this.props
        switch (type) {
            case TaskType.DIGITAL_DOCUMENT:
                history.push({
                    pathname: `/tasks/detail`,
                    search: `type=${TaskType.DIGITAL_DOCUMENT}&subtype=${TaskSubtype.ASSIGNED}`,
                    state: {
                        backUrl: fromPage
                            ? fromPage.backUrl
                            : `/users/${this.state.userId}?tab=${Auth.UserDetailTab.Tasks}`,
                        ...(backUrlFromPage && {backData: {backUrlFromPage}})
                    }
                })
                break
            case TaskType.SCAN_UPLOAD:
                history.push({
                    pathname: `/tasks/detail`,
                    search: `type=${TaskType.SCAN_UPLOAD}&subtype=${TaskSubtype.ASSIGNED}`,
                    state: {
                        backUrl: fromPage
                            ? fromPage.backUrl
                            : `/users/${this.state.userId}?tab=${Auth.UserDetailTab.Tasks}`,
                        ...(backUrlFromPage && {backData: {backUrlFromPage}})
                    }
                })
                break
            case TaskType.FROM_LIBRARY:
                history.push({
                    pathname: `/tasks/detail`,
                    search: `type=${TaskType.FROM_LIBRARY}`,
                    state: {
                        backUrl: fromPage
                            ? fromPage.backUrl
                            : `/users/${this.state.userId}?tab=${Auth.UserDetailTab.Tasks}`,
                        ...(backUrlFromPage && {backData: {backUrlFromPage}})
                    }
                })
                break
            default:
                break
        }
    }

    render() {
        const {
            page,
            total,
            pageSize,
            columns,
            data,
            menuActions,
            allFields,
            fields,
            tableHeaderActions,
            isLoading,
            isHideMenuActions,
            isShowTableHeaderAction,
            t,
            orderField,
            model
        } = this.props
        const {search, filter, statusInfos, departmentSubunits, isShowPopup} = this.state
        const canCreateTask = departmentSubunits.some((departmentSubunit) => {
            return checkTaskDepartmentPermission(
                PermissionType.Add,
                model,
                departmentSubunit.departmentId,
                departmentSubunit.subunitId
            )
        })

        return (
            <div>
                <BaseNewFilter
                    searchValue={search}
                    filter={filter}
                    onClick={this.onClickFilter}
                    onClickClear={this.onClickClearFilter}
                    onSearchInput={this.onSearchInput}
                    renderRightFilter={() =>
                        canCreateTask &&
                        ![Auth.UserProfileType.Others, Auth.UserProfileType.Student].includes(
                            model.getUserProfileType()
                        ) && (
                            <div className={styles.createBtnWrap}>
                                <BaseButton
                                    title={t("tasks.createTask").toUpperCase()}
                                    onClick={this.onClickCreateTask}
                                    icon={<Icon className={styles.plusIcon} icon="PLUS" color="#FFF" />}
                                    disabled={false}
                                />
                            </div>
                        )
                    }>
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <DepartmentSubunitSelect
                                isClearable={false}
                                value={filter.departments}
                                onChange={(newValue) => this.onChangeFilter("departments", newValue)}
                                placeholder={t("tasks.department")}
                                isMulti
                                shortly
                            />
                        </Col>
                        <Col span={24}>
                            <KlassDropAsyncPaginate
                                value={filter.staffs}
                                onChange={(newValue) => this.onChangeFilter("staffs", newValue)}
                                getOptionLabel={(option: any) => getFullName(option)}
                                loadOptions={this.onStaffSearchChange}
                                isMulti
                                placeholder={t("tasks.staff")}
                            />
                        </Col>
                    </Row>
                </BaseNewFilter>
                <div className={styles.statusInfoWrap}>
                    {statusInfos.map((statusInfo) => (
                        <div key={statusInfo.id} className={styles.statusColItem}>
                            <CardItem
                                item={statusInfo}
                                activeStatus={filter.status}
                                onChangeFilter={this.onChangeStatusFilter}
                            />
                        </div>
                    ))}
                </div>

                <KlassappTableHeader
                    isShowAction={isShowTableHeaderAction}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.props.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    data={data}
                    menuActions={isHideMenuActions ? [] : menuActions}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    isShowCheckedColumn
                    orderField={orderField}
                    onClickRowItem={this.onClickRowItem}
                    onChangeFields={this.props.onChangeFields}
                    onUpdateRowData={this.props.onUpdateRowData}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onClickSortColumn={this.props.onClickSortColumn}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onChangeAllFields={this.props.onChangeAllFields}
                />
                <ChooseCreateTaskPopup
                    isShow={isShowPopup}
                    onClose={() => {
                        this.setState({isShowPopup: false})
                    }}
                    isLibrary={false}
                    onClickItem={this.onClickCreateTaskWithType}
                />
            </div>
        )
    }
}

export default KlassappTableHOC(withTranslation(["tasks", "common"])(Tasks))
