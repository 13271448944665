import React from "react"
import {withTranslation} from "react-i18next"
import {get, size, groupBy} from "lodash"
import debounce from "debounce-promise"
import {KlassappTable, KlassappTableHeader, KlassappTableDropdown, KlassappTableDropdownAsyncPaginate} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {ParticipantType, UserType} from "types/tasks"
import {getFullName} from "helpers"
import {KlassappTableProps} from "types/common"
import {BaseButton} from "components/buttons"
import styles from "./StudentSigner.module.css"
import {Auth} from "types/auth"
import {Checkbox, Col, Row} from "antd"
import {termsService} from "services"

type PageProps = {
    t: Function
    history?: any
    signer: any
    primarySigner: any
    forceDeleteKey: number
    resetNonPrimarySignerId: string
    onUpdateSignerItem: Function
    onClickEditSigner: (signerData: any) => void
    onDeleteMultiUserSigner: (signer: any, userIds?: number[]) => void
    isWorkflowAction?: boolean
    workflowTargetType?: Auth.UserProfileType
}

type Props = KlassappTableProps & PageProps

type State = {}

class StudentSigner extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {}
        this.onTermsSearchChangeDebounce = debounce(this.onTermsSearchChangeDebounce.bind(this), 300)
    }

    componentDidMount() {
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getData},
            {key: "getFields", func: this.getFields},
            {key: "getColumns", func: this.getColumns},
            {key: "onClickRowItem", func: this.onClickRowItem},
            {key: "onClickDeleteMulti", func: this.onClickDeleteMulti},
            {key: "getTableHeaderActions", func: this.getTableHeaderActions}
        ])
        this.getData()
    }

    componentDidUpdate(prevProps) {
        if (
            (prevProps.forceDeleteKey !== this.props.forceDeleteKey &&
                this.props.signer.participantType !== ParticipantType.PrimarySigner) ||
            (prevProps.resetNonPrimarySignerId !== this.props.resetNonPrimarySignerId &&
                this.props.signer.id === this.props.resetNonPrimarySignerId)
        ) {
            this.props.dispatch({data: [], total: 0})
        } else if (prevProps.signer.students !== this.props.signer.students) {
            this.getData()
        }
    }

    onTermsSearchChangeDebounce = async (search = "", loadedOptions) => {
        try {
            const params = {
                text: search,
                limit: 20,
                offset: loadedOptions.length
            }
            const {data: terms, total} = await termsService.getAllTerms(params)
            return {
                options: terms.map((c) => ({
                    id: c.id,
                    name: c.name
                })),
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    getPageTitle = () => {
        return "Student"
    }

    getFields = () => {
        return ["Name", "Email", "User Id", "Enrollment ID", "Program", "Term"]
    }

    getTableHeaderActions = () => {
        const {t, onClickShowConfirmModal} = this.props
        return [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            }
        ]
    }

    onClickDeleteMulti = () => {
        const {signer, data, onDeleteMultiUserSigner, dispatch} = this.props
        dispatch({isShowTableHeaderAction: false})
        if (signer.participantType === ParticipantType.PrimarySigner) {
            const checkedStudentIds = data.filter((student) => student.isChecked).map((student) => student.id)
            const newSigner = {...signer}
            newSigner.students = signer.students.filter((item) => !checkedStudentIds.includes(item.id))
            onDeleteMultiUserSigner(newSigner, checkedStudentIds)
        } else {
            const newSigner = {...signer}
            newSigner.students = []
            onDeleteMultiUserSigner(newSigner)
        }
    }

    getData = async () => {
        const {signer, dispatch} = this.props
        const newData = (signer.students || []).map((student: any) => {
            student.enrollmentIdHtml = this.renderEnrollmentIdHtml(student)
            student.applicantIdHtml = this.renderApplicantIdHtml(student)
            student.nameHtml = this.renderNameHtml(student)
            student.studentEmailHtml = this.renderEmailHtml(student)
            student.program = this.renderProgramHtml(student)
            student.termHtml = this.renderTermHtml(student)
            return student
        })
        dispatch({data: newData, total: (signer.students || []).length})
    }

    renderNameHtml = (user) => {
        const avatarSrc = user.photo?.original
        return (
            <div className={styles.nameWrap}>
                {avatarSrc && <img src={avatarSrc} className={styles.avatar} alt="" />}
                <p className={styles.name}>{getFullName(user)}</p>
            </div>
        )
    }

    onChangeActiveStudentProfile = (id, newActiveProfile) => {
        const newData = this.props.data.map((student: any) => {
            if (student.id === id) {
                student.activeProfile = newActiveProfile
                student.profileId = newActiveProfile.id
                student.activeTerm = newActiveProfile?.currentTerm
                student.termHtml = this.renderTermHtml(student)
                student.program = this.renderProgramHtml(student)
                student.applicantIdHtml = this.renderApplicantIdHtml(student)
                student.enrollmentIdHtml = this.renderEnrollmentIdHtml(student)
                return student
            }
            return student
        })
        this.props.dispatch({data: newData, total: newData.length})
    }

    onChangeActiveTerm = (id, newTerm) => {
        const newData = this.props.data.map((student: any) => {
            if (student.id === id) {
                student.activeTerm = newTerm
                student.termHtml = this.renderTermHtml(student)
                return student
            }
            return student
        })
        this.props.dispatch({data: newData, total: newData.length})
    }

    renderApplicantIdHtml = (user) => {
        const options = (user.profiles || []).filter((item) => item.customProfileId)
        if (size(options) < 2) {
            return `${user.activeProfile.customProfileId || ""}`
        }
        return (
            <KlassappTableDropdown
                options={options}
                value={user.activeProfile}
                valueKey="id"
                getOptionLabel={(option: any) => `${option.applicantId || ""}`}
                onChange={(newValue) => this.onChangeActiveStudentProfile(user.id, newValue)}
            />
        )
    }

    renderEnrollmentIdHtml = (user) => {
        const options = (user.profiles || []).filter((item) => item.customProfileId)
        if (size(options) < 2) {
            return `${user.activeProfile.customProfileId || ""}`
        }
        const groupedProfile = groupBy(options, "customProfileId") || {}
        const filteredProfile = Object.values(groupedProfile).map((profile) => {
            if (profile.length >= 2) {
                return profile.filter(({state}) => state === Auth.StudentStateType.Student)
            }
            return profile
        })
        const filteredOptions = filteredProfile.flatMap((profile) => profile).filter((profile) => profile?.active)
        return (
            <KlassappTableDropdown
                options={filteredOptions}
                value={user.activeProfile}
                valueKey="id"
                getOptionLabel={(option: any) =>
                    `${option.customProfileId || ""} [${
                        ([Auth.StudentStateType.Student, Auth.UserProfileState.Alumni].includes(option.state) &&
                            "Student") ||
                        ([Auth.StudentStateType.Prospect, Auth.UserProfileState.Applicant].includes(option.state) &&
                            "Applicant")
                    }]`
                }
                onChange={(newValue) => this.onChangeActiveStudentProfile(user.id, newValue)}
            />
        )
    }

    renderTermHtml = (user) => {
        return (
            <KlassappTableDropdownAsyncPaginate
                loadOptions={this.onTermsSearchChangeDebounce}
                onChange={(newValue) => this.onChangeActiveTerm(user.id, newValue)}
                value={user.activeTerm}
            />
        )
    }

    renderProgramHtml = (user) => {
        const currentMajor = get(user.activeProfile, "currentMajor", "")
        return currentMajor ? currentMajor.name : ""
    }

    renderEmailHtml = (user) => {
        const emails = (user.emails || [])
            .map((email) => email.email)
            .filter(Boolean)
            .join("\n")
        return emails
    }

    getParams = () => {
        const {page, pageSize} = this.props
        const params = {
            range: {
                page,
                pageSize
            }
        }
        return params
    }

    getColumns = () => {
        return [
            {
                title: "Name",
                field: "nameHtml",
                style: {minWidth: "200px"}
            },
            {
                title: "Email",
                field: "studentEmailHtml",
                style: {whiteSpace: "pre"}
            },
            {
                title: "User Id",
                field: "customUserId",
                style: {minWidth: "90px"}
            },
            {
                title: "Enrollment ID",
                field: "enrollmentIdHtml",
                style: {minWidth: "200px"}
            },
            {
                title: "Program",
                field: "program",
                style: {minWidth: "150px"}
            },
            {
                title: "Term",
                field: "termHtml",
                style: {minWidth: "200px"}
            }
        ]
    }

    onClickRowItem = () => {}

    render() {
        const {
            page,
            total,
            pageSize,
            columns,
            data,
            allFields,
            fields,
            orderField,
            isLoading,
            isShowTableHeaderAction,
            tableHeaderActions,
            signer,
            primarySigner,
            t,
            onClickEditSigner,
            onUpdateSignerItem,
            isWorkflowAction,
            workflowTargetType
        } = this.props

        if (!data.length) {
            const type = primarySigner?.userType || primarySigner?.type
            let isActive =
                signer.id === primarySigner?.id ||
                signer.participantType === ParticipantType.UserDataOnly ||
                primarySigner?.useWorkflowAllTargets ||
                primarySigner?.useWorkflowSingleTarget
            if (!isActive && !primarySigner?.isSelecting && !primarySigner?.isForm) {
                switch (type) {
                    case UserType.STUDENT:
                        isActive = !!(primarySigner.students || []).length
                        break
                    case UserType.STAFF:
                        isActive = !!(primarySigner.staffs || []).length
                        break
                    case UserType.OTHERS:
                        isActive = !!(primarySigner.others || []).length
                        break
                    default:
                        break
                }
            }
            return (
                <div className="text-center">
                    {!!isWorkflowAction && workflowTargetType === Auth.UserProfileType.Student && (
                        <Row gutter={24} justify="center" align="middle">
                            {signer.id === primarySigner?.id ? (
                                <>
                                    <Col>
                                        <Checkbox
                                            checked={signer.useWorkflowAllTargets}
                                            onChange={(event) => {
                                                const checked = event.target.checked
                                                onUpdateSignerItem(signer.id, {
                                                    ...signer,
                                                    useWorkflowAllTargets: checked,
                                                    useWorkflowSingleTarget: false,
                                                    students: checked ? [] : signer.students
                                                })
                                            }}>
                                            Group all students in one task
                                        </Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox
                                            checked={signer.useWorkflowSingleTarget}
                                            onChange={(event) => {
                                                const checked = event.target.checked
                                                onUpdateSignerItem(signer.id, {
                                                    ...signer,
                                                    useWorkflowSingleTarget: checked,
                                                    useWorkflowAllTargets: false,
                                                    students: checked ? [] : signer.students
                                                })
                                            }}>
                                            Send each student with individual task
                                        </Checkbox>
                                    </Col>
                                </>
                            ) : (
                                <Col>
                                    <Checkbox
                                        checked={signer.useWorkflowAllTargets}
                                        onChange={(event) => {
                                            const checked = event.target.checked
                                            onUpdateSignerItem(signer.id, {
                                                ...signer,
                                                useWorkflowAllTargets: checked,
                                                useWorkflowSingleTarget: false,
                                                students: checked ? [] : signer.students
                                            })
                                        }}>
                                        Use workflow target audience
                                    </Checkbox>
                                </Col>
                            )}
                        </Row>
                    )}

                    {!signer.useWorkflowAllTargets && !signer.useWorkflowSingleTarget && (
                        <div className={styles.selectWrap}>
                            <BaseButton
                                title={t("tasks.select")}
                                variant="secondary"
                                onClick={() => onClickEditSigner(signer)}
                                isActive={isActive}
                            />
                        </div>
                    )}
                </div>
            )
        }

        return (
            <div className={styles.wrap}>
                <KlassappTableHeader
                    isShowAction={isShowTableHeaderAction}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.props.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    data={data}
                    menuActions={[]}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    orderField={orderField}
                    isShowCheckedColumn
                    onClickRowItem={this.onClickRowItem}
                    onChangeFields={this.props.onChangeFields}
                    onUpdateRowData={this.props.onUpdateRowData}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onClickSortColumn={this.props.onClickSortColumn}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onChangeAllFields={this.props.onChangeAllFields}
                />
            </div>
        )
    }
}

export default KlassappTableHOC(withTranslation(["tasks", "common"])(StudentSigner))
