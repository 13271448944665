import {useEffect, useState} from "react"
import {Row, Col} from "antd"
import moment from "moment"
import momentTz from "moment-timezone"
import {head, isEmpty, map} from "lodash"
import {BasePopup} from "components/popup"
import {BaseButton} from "components/buttons"
import {KlassDropAsyncPaginate, KlassDropdown} from "components/Select"
import {FormLabel} from "components/Form"
import {BaseDatePicker, BaseTimePicker} from "components/DateTimePicker"
import {CourseEvent} from "types/courseEvent"
import {Course} from "types/courses"
import {attendanceService, userService, campusesService} from "services"
import {getFullName, handleError, toastError, toastSuccess} from "helpers"
import {getDisabledEndHours, getDisabledEndMinutes, getScheduleEventColor} from "helpers/calendar"
import {useModel} from "hooks"
import {Auth} from "types/auth"
import styles from "./LessonPopup.module.css"
import {useTranslation} from "react-i18next"
import debounce from "debounce-promise"

type Props = {
    lessonInfo?: any
    activeSchedule?: any
    isShow: boolean
    onClose: () => void
    onSuccess: () => void
    campusIds: number[]
}

export function LessonPopup(props: Props) {
    const {isShow, lessonInfo, activeSchedule, onClose, onSuccess, campusIds = []} = props
    const [timezone, setTimezone] = useState(momentTz.tz.guess())
    const [isSaving, setIsSaving] = useState(false)
    const {t} = useTranslation(["settings", "common"])
    const [lesson, setLesson] = useState({
        startDate: null,
        startTime: null,
        endTime: null,
        scheduleEventType: null,
        instructors: []
    })
    const eventTypeOptions = [
        {id: CourseEvent.CourseEventType.Classroom, name: "Classroom"},
        {id: CourseEvent.CourseEventType.Lab, name: "Lab"},
        {id: CourseEvent.CourseEventType.Others, name: "Others"},
        {id: CourseEvent.CourseEventType.Test, name: "Test"}
    ]
    const model = useModel()

    useEffect(() => {
        if (lessonInfo) {
            initLesson()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const initLesson = async () => {
        const campusId = head(lessonInfo.campusIds)
        if (!campusId) {
            fillLesson(momentTz.tz.guess())
            return
        }
        try {
            const {data} = await campusesService.getAll({filter: {id: [campusId]}})
            const campus = head(data) as any
            const timezone = campus.timezone || momentTz.tz.guess()
            fillLesson(timezone)
        } catch (error) {
            handleError(error)
        }
    }

    const fillLesson = (timezone: string) => {
        setLesson({
            ...lesson,
            startDate: moment(lessonInfo.startsAt).tz(timezone),
            startTime: moment(lessonInfo.startsAt).tz(timezone),
            endTime: moment(lessonInfo.endsAt).tz(timezone),
            instructors:
                map(lessonInfo.instructors, (instructor) => ({
                    ...instructor,
                    isFixed: instructor.isInstructorSchedule
                })) || []
        })
    }

    const searchInstructors = async (search = "", loadedOptions) => {
        const pageSize = 20
        const page = Math.ceil(loadedOptions.length / pageSize) + 1
        const params: {
            filters: {
                campusIds: number[]
                type: Auth.UserProfileType[]
                search?: string
            }
            range: {page: number; pageSize: number}
        } = {
            filters: {
                campusIds: campusIds,
                search,
                type: [Auth.UserProfileType.Staff]
            },
            range: {
                page,
                pageSize
            }
        }

        try {
            const {data, total} = await userService.searchUsers(params)
            const instructors = data.map((user) => ({
                userId: user.userId,
                firstName: user.firstName,
                lastName: user.lastName,
                fullName: user.name,
                profileId: user.profileId
            }))
            return {
                options: instructors,
                hasMore: loadedOptions.length < total
            }
        } catch (e) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const getListOfInstructors = async (search, loadedOptions) => {
        try {
            const {options, hasMore} = await searchInstructors(search, loadedOptions)
            return {
                options,
                hasMore
            }
        } catch (e) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const debounceInstructors = debounce(getListOfInstructors, 500)

    const validateData = () => {
        const requiredFields = ["startDate", "startTime", "endTime"]
        if (isEmpty(lessonInfo)) {
            requiredFields.push("scheduleEventType")
        } else if (!lesson.instructors?.length) {
            return true
        }
        return requiredFields.some((field) => isEmpty(lesson[field]))
    }

    const onClickSave = () => {
        const hasError = validateData()
        if (hasError) {
            toastError("Please select all the required fields")
            return
        }
        if (isEmpty(lessonInfo)) {
            createLesson()
        } else {
            updateLesson()
        }
    }

    const setDateOfTimeValue = (timeValue) => {
        const startDate = moment(lesson.startDate)
        const date = startDate.get("date")
        const month = startDate.get("month")
        const year = startDate.get("year")
        return moment(timeValue)
            .tz(timezone)
            .set("date", date)
            .set("month", month)
            .set("year", year)
            .set("millisecond", 0)
    }

    const createLesson = async () => {
        try {
            setIsSaving(true)
            const data = {
                timezone,
                scheduleId: activeSchedule.id,
                startDate: setDateOfTimeValue(lesson.startTime).toISOString(),
                endDate: setDateOfTimeValue(lesson.endTime).toISOString(),
                scheduleEventType: lesson.scheduleEventType.id,
                color: getScheduleEventColor(lesson.scheduleEventType.id)
            }
            await attendanceService.createLesson(data)
            toastSuccess("Create lesson successful")
            setIsSaving(false)
            onSuccess()
        } catch (error) {
            setIsSaving(false)
            handleError(error)
        }
    }

    const updateLesson = async () => {
        try {
            setIsSaving(true)
            const data = {
                timezone,
                lessonId: lessonInfo.id,
                instructorIds: lesson.instructors.map((instructor) => instructor.profileId),
                startDate: setDateOfTimeValue(lesson.startTime).toISOString(),
                endDate: setDateOfTimeValue(lesson.endTime).toISOString()
            }
            await attendanceService.editLesson(data)
            toastSuccess("Update lesson successful")
            setIsSaving(false)
            onSuccess()
        } catch (error) {
            setIsSaving(false)
            handleError(error)
        }
    }

    const onChangeData = (key, value) => {
        const newData = {...lesson}
        newData[key] = value
        if (key === "startTime" && value) {
            const isSameDate = moment(value).add(1, "hour").startOf("date").isSame(moment(value).startOf("date"))
            newData.endTime = isSameDate ? moment(value).add(1, "hour") : moment(value).endOf("date")
        }
        setLesson(newData)
    }

    const renderCreateLessonContent = () => {
        return (
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <div>
                        <FormLabel label="Schedule Event Type" isRequired />
                        <KlassDropdown
                            options={eventTypeOptions}
                            value={lesson.scheduleEventType}
                            onChange={(newValue) => onChangeData("scheduleEventType", newValue)}
                        />
                    </div>
                </Col>
                <Col span={24}>
                    <div>
                        <FormLabel label="Date" isRequired />
                        <BaseDatePicker
                            value={lesson.startDate}
                            format={model.getUserDateFormat()}
                            onChange={(newValue) => onChangeData("startDate", newValue)}
                        />
                    </div>
                </Col>
                <Col span={12}>
                    <div>
                        <FormLabel label="Start Time" isRequired />
                        <BaseTimePicker
                            value={lesson.startTime}
                            format={model.getUserTimeFormat()}
                            onChange={(newValue) => onChangeData("startTime", newValue)}
                        />
                    </div>
                </Col>
                <Col span={12}>
                    <div>
                        <FormLabel label="End Time" isRequired />
                        <BaseTimePicker
                            value={lesson.endTime}
                            format={model.getUserTimeFormat()}
                            onChange={(newValue) => onChangeData("endTime", newValue)}
                            disabledHours={() => getDisabledEndHours(lesson.startTime)}
                            disabledMinutes={() => getDisabledEndMinutes(lesson.startTime, lesson.endTime)}
                        />
                    </div>
                </Col>
            </Row>
        )
    }

    const renderEditLessonContent = () => {
        return (
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <div>
                        <FormLabel label="Date" isRequired />
                        <BaseDatePicker
                            value={lesson.startDate}
                            format={model.getUserDateFormat()}
                            onChange={(newValue) => onChangeData("startDate", newValue)}
                        />
                    </div>
                </Col>
                <Col span={12}>
                    <div>
                        <FormLabel label="Start Time" isRequired />
                        <BaseTimePicker
                            value={lesson.startTime}
                            format={model.getUserTimeFormat()}
                            onChange={(newValue) => onChangeData("startTime", newValue)}
                        />
                    </div>
                </Col>
                <Col span={12}>
                    <div>
                        <FormLabel label="End Time" isRequired />
                        <BaseTimePicker
                            value={lesson.endTime}
                            format={model.getUserTimeFormat()}
                            onChange={(newValue) => onChangeData("endTime", newValue)}
                            disabledHours={() => getDisabledEndHours(lesson.startTime)}
                            disabledMinutes={() => getDisabledEndMinutes(lesson.startTime, lesson.endTime)}
                        />
                    </div>
                </Col>
                <Col span={12}>
                    <div>
                        <FormLabel label={t("settings:schedules.instructor")} isRequired />
                        <KlassDropAsyncPaginate
                            checkRemoveButton
                            value={lesson.instructors}
                            onChange={(newValue) => onChangeData("instructors", newValue)}
                            valueKey="profileId"
                            getOptionLabel={(option: any) => getFullName(option)}
                            loadOptions={debounceInstructors}
                            isMulti
                        />
                    </div>
                </Col>
            </Row>
        )
    }

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="EDIT_LINE" width="60vw">
            <div className={styles.wrap}>
                <p className={styles.title}>{isEmpty(lessonInfo) ? "Create lesson" : "Edit lesson"}</p>
                {isEmpty(lessonInfo) ? renderCreateLessonContent() : renderEditLessonContent()}
                <div className={styles.action}>
                    <BaseButton title="Cancel" variant="secondary" loading={isSaving} onClick={onClose} />
                    <BaseButton title="Save" loading={isSaving} onClick={onClickSave} />
                </div>
            </div>
        </BasePopup>
    )
}
