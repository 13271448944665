import {get} from "lodash"
import BaseService from "./Base"

class SettingLeadTypesService extends BaseService {
    async getAll(params = {}) {
        const response = await this.post("/v1/admissions/lead-types/list", params)
        return get(response, "data", [])
    }

    async create(params = {}) {
        const response = await this.post("/v1/admissions/lead-types/create", params)
        return get(response, "data.data", {})
    }

    async update(id, data, params = {}) {
        data.leadTypeId = id
        const response = await this.post(`/v1/admissions/lead-types/edit`, data, params)
        return get(response, "data", {})
    }

    async delete(params = {}) {
        const response = await this.post(`/v1/admissions/lead-types/delete`, params)
        return get(response, [], {})
    }

    async getAllActive(params = {}) {
        const response = await this.post("/v1/admissions/lead-types/list/active", params)
        return get(response, "data", [])
    }
}

export default SettingLeadTypesService
