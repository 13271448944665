import {get} from "lodash"
import BaseService from "./Base"

export default class AptitudeTestService extends BaseService {
    async getAll(params = {}) {
        const response = await this.post("/v1/admissions/aptitude-tests/list", params)
        return get(response, "data", [])
    }

    async getStats(params = {}) {
        const response = await this.post("/v1/admissions/aptitude-tests/stats/list", params)
        return get(response, "data.data", [])
    }

    async edit(params = {}) {
        const response = await this.post("/v1/admissions/aptitude-tests/edit", params)
        return get(response, "data", [])
    }

    async sendCertification(params = {}) {
        const response = await this.post(
            `${process.env.REACT_APP_ADMISSION_CERTIFICATION_BASE_URL}/edular/certificate/send`,
            params
        )
        return get(response, "data", {})
    }
}
