import React, {useState} from "react"
import {Col, Divider, Radio, Row} from "antd"
import moment from "moment"
import {BaseButton} from "components/buttons"
import {useModel} from "hooks"
import {formatCurrency, getFullName} from "helpers"
import styles from "./LoanDisbursementEligibilityCheck.module.css"
import classNames from "classnames"
import {Disbursement} from "types/fin-aid/disbursement"

type Props = {
    disbursement: Disbursement
    onClose: () => void
    onConfirm: () => void
}

const LoanDisbursementEligibilityCheck: React.FC<Props> = ({disbursement, onClose, onConfirm}) => {
    const model = useModel()
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const userDateFormat = model.getUserDateFormat()

    const [degreeKevekAppropriate, setDegreeKevekAppropriate] = useState(false)
    const [progressStatus, setProgressStatus] = useState("probation")
    const [enrollAtLeast, setEnrollAtLeast] = useState(false)
    const [afterDrop, setAfterDrop] = useState(false)
    const [canPay, setCanPay] = useState(false)
    const [attendAtLeast, setAttendAtLeast] = useState(false)

    return (
        <div className={styles.wrap}>
            <h3 className={styles.title}>Loan Disbursement Eligibility Check</h3>

            <Divider className={styles.divider} />

            <div className={styles.userWrap}>
                <div className={styles.header}>
                    <div className={styles.content}>
                        <div className={styles.contentItem}>
                            <Row justify="space-between" align="middle" className="w-full">
                                <Col flex={1}>
                                    <Row gutter={8} align="middle">
                                        <Col className="flex-shrink-0">
                                            <img
                                                src="/image/DefaultAvatar.png"
                                                className={styles.avatar}
                                                alt={getFullName(disbursement.studentProfile)}
                                            />
                                        </Col>
                                        <Col flex={1}>
                                            <div className={styles.userName}>
                                                {getFullName(disbursement.studentProfile)}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className={classNames(styles.userId, "flex-shrink-0")}>
                                    <Row justify="space-between" align="middle" gutter={4}>
                                        <Col>STUDENT ID:</Col>
                                        <Col className={styles.creditEarnedValue}>
                                            {disbursement.studentProfile?.profileId}
                                        </Col>
                                    </Row>
                                    <Row justify="space-between" align="middle" gutter={4}>
                                        <Col>ENROLLMENT ID:</Col>
                                        <Col className={styles.creditEarnedValue}>
                                            {disbursement.studentProfile?.profileId}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className={classNames(styles.contentItem, "justify-end")}>
                            <div className={styles.creditEarned}>
                                CREDITS EARNED:{" "}
                                <span className={styles.creditEarnedValue}>
                                    {disbursement.finAidPackage?.creditsEarned}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.body}>
                    <div className={styles.content}>
                        <div className={styles.contentItem}>
                            <span className={styles.content__title}>Start Date</span>
                            <span className={styles.content__value}>
                                {moment(disbursement.term?.start_date).format(userDateFormat)}
                            </span>
                        </div>
                        <div className={styles.contentItem}>
                            <span className={styles.content__title}>End Date</span>
                            <span className={styles.content__value}>
                                {moment(disbursement.term?.end_date).format(userDateFormat)}
                            </span>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.contentItem}>
                            <span className={styles.content__title}>Academic Track</span>
                            <span className={styles.content__value}>1</span>
                        </div>
                        <div className={styles.contentItem}>
                            <span className={styles.content__title}>Program</span>
                            <span className={styles.content__value}>{disbursement.finAidPackage?.programId}</span>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.contentItem}>
                            <span className={styles.content__title}>Current Term</span>
                            <span className={styles.content__value}>{disbursement.term?.name}</span>
                        </div>
                        <div className={styles.contentItem}>
                            <span className={styles.content__title}>Program Version</span>
                            <span className={styles.content__value}>
                                {disbursement.finAidPackage?.programVersionId}
                            </span>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.contentItem}>
                            <span className={styles.content__title}>Degree Level</span>
                            <span className={styles.content__value}>{disbursement.finAidPackage?.degreeLevelId}</span>
                        </div>
                        <div className={styles.contentItem}>
                            <span className={styles.content__title}>Campus</span>
                            <span className={styles.content__value}>{disbursement.finAidPackage?.campusId}</span>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.contentItem}>
                            <span className={styles.content__title}>Loan Code</span>
                            <span className={styles.content__value}>{disbursement.fundSourceId}</span>
                        </div>
                        <div className={styles.contentItem}>
                            <span className={styles.content__title}>Loan Period</span>
                            <span className={styles.content__value}>
                                {disbursement.paymentPeriodStartDate} - {disbursement.paymentPeriodEndDate}
                            </span>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.contentItem}>
                            <span className={styles.content__title}>Grade Level</span>
                            <span className={styles.content__value}>{disbursement.finAidPackage?.gradeLevel}</span>
                        </div>
                        <div className={styles.contentItem}>
                            <span className={styles.content__title}>Plus</span>
                            <span className={styles.content__value}></span>
                        </div>
                    </div>
                </div>
            </div>

            <table className={styles.table}>
                <thead className={styles.tableHead}>
                    <tr className={styles.tableRowHeader}>
                        <th className={classNames(styles.tableCell, styles.tableCellHeader)}>SEQ #</th>
                        <th className={classNames(styles.tableCell, styles.tableCellHeader)}>TYPE</th>
                        <th className={classNames(styles.tableCell, styles.tableCellHeader)}>DESCRIPTION</th>
                        <th className={classNames(styles.tableCell, styles.tableCellHeader)}>AMOUNT</th>
                        <th className={classNames(styles.tableCell, styles.tableCellHeader)}>DISB DATE</th>
                    </tr>
                </thead>
                <tbody className={styles.bodyTable}>
                    <tr className={classNames(styles.tableRow)}>
                        <td className={classNames(styles.tableCell, styles.tableCellBody)}>
                            {disbursement.disbursedSequenceNo}
                        </td>
                        <td className={classNames(styles.tableCell, styles.tableCellBody)}>
                            {disbursement.fundSource?.type}
                        </td>
                        <td className={classNames(styles.tableCell, styles.tableCellBody)}>
                            {disbursement.fundSource?.title}
                        </td>
                        <td className={classNames(styles.tableCell, styles.tableCellBody)}>
                            {formatCurrency(disbursement.grossAmount)}
                        </td>
                        <td className={classNames(styles.tableCell, styles.tableCellBody)}>
                            {moment().format(userDateTimeFormat)}
                        </td>
                    </tr>
                </tbody>
            </table>

            <Divider className={styles.divider} />

            <Row gutter={4} className="mb-16">
                <Col className={styles.startDateLabel}>START DATE:</Col>
                <Col className={styles.startDateValue}>
                    {moment(disbursement.paymentPeriodStartDate || undefined).format(userDateFormat)}
                </Col>
            </Row>

            <Row className={styles.infoBlock} align="middle" wrap={false} gutter={16}>
                <Col flex={1}>
                    <div className={styles.blockTitle}>Degree level appropriate</div>
                    <div className={styles.blockDescription}>
                        If no, Centaral FA revise loan or cancel as appropriate
                    </div>
                </Col>

                <Col className="flex-shrink-0">
                    <Radio.Group
                        className={styles.radioGroup}
                        onChange={({target: {value}}) => setDegreeKevekAppropriate(value)}
                        value={degreeKevekAppropriate}>
                        <Radio value={true}>YES</Radio>
                        <Radio value={false}>NO</Radio>
                    </Radio.Group>
                </Col>
            </Row>

            <Row className={styles.infoBlock} align="middle" wrap={false} gutter={16}>
                <Col flex={1}>
                    <div className={styles.blockTitle}>Satisfactory academic progress status</div>
                    <div className={styles.blockDescription}>
                        If any other status(Suspension, Suspension Self-Pay, Max time Frame) can not pay
                    </div>
                </Col>

                <Col className="flex-shrink-0">
                    <Radio.Group
                        className={styles.radioGroup}
                        onChange={({target: {value}}) => setProgressStatus(value)}
                        value={progressStatus}>
                        <Radio value="good">GOOD STANDING</Radio>
                        <Radio value="warning">WARNING</Radio>
                        <Radio value="probation">PROBATION</Radio>
                    </Radio.Group>
                </Col>
            </Row>

            <Row className={styles.infoBlock} align="middle" wrap={false} gutter={16}>
                <Col flex={1}>
                    <div className={styles.blockTitle}>
                        enrolled at least 1/2 time (6cr. undergrad, 4.5 cr. Masters, 3 cr. full term doctorall
                    </div>
                    <div className={styles.blockDescription}>
                        If no, check for Late Disbursement or can not pay disbursement
                    </div>
                </Col>

                <Col className="flex-shrink-0">
                    <Radio.Group
                        className={styles.radioGroup}
                        onChange={({target: {value}}) => setEnrollAtLeast(value)}
                        value={enrollAtLeast}>
                        <Radio value={true}>YES</Radio>
                        <Radio value={false}>NO</Radio>
                    </Radio.Group>
                </Col>
            </Row>

            <Row className={styles.infoBlock} align="middle" wrap={false} gutter={16}>
                <Col flex={1}>
                    <div className={styles.blockTitle}>
                        Disbursing loan after drop to less than half-time or after end of a loan period
                    </div>
                    <div className={styles.blockDescription}>
                        If yes, must complete Late Disbursement checklist, contact Senior FA Coordinator
                    </div>
                </Col>

                <Col className="flex-shrink-0">
                    <Radio.Group
                        className={styles.radioGroup}
                        onChange={({target: {value}}) => setAfterDrop(value)}
                        value={afterDrop}>
                        <Radio value={true}>YES</Radio>
                        <Radio value={false}>NO</Radio>
                    </Radio.Group>
                </Col>
            </Row>

            <Row className={styles.infoBlock} align="middle" wrap={false} gutter={16}>
                <Col flex={1}>
                    <div className={styles.blockTitle}>Can we pay the disbursement?</div>
                </Col>

                <Col>
                    <BaseButton title="Sign" variant="secondary" className={styles.cancelBtn} size="small" />
                </Col>
                <Col className="flex-shrink-0">
                    <Radio.Group
                        className={styles.radioGroup}
                        onChange={({target: {value}}) => setCanPay(value)}
                        value={canPay}>
                        <Radio value={true}>YES</Radio>
                        <Radio value={false}>NO</Radio>
                    </Radio.Group>
                </Col>
            </Row>

            <Row className={styles.infoBlock} align="middle" wrap={false} gutter={16}>
                <Col flex={1}>
                    <div className={styles.blockTitle}>
                        Did the student attend ar least 1/2 time on or after the disbursement date?
                    </div>
                    <div className={styles.blockDescription}>
                        If no, must complete Late Disbursement Checklist contact Snior FA Coordinator
                    </div>
                </Col>

                <Col className="flex-shrink-0">
                    <Radio.Group
                        className={styles.radioGroup}
                        onChange={({target: {value}}) => setAttendAtLeast(value)}
                        value={attendAtLeast}>
                        <Radio value={true}>YES</Radio>
                        <Radio value={false}>NO</Radio>
                    </Radio.Group>
                </Col>
            </Row>

            <div className={styles.actionBtnWrap}>
                <BaseButton title="Cancel" variant="secondary" className={styles.cancelBtn} onClick={onClose} />
                <BaseButton title="Submit" onClick={onConfirm} />
            </div>
        </div>
    )
}

export default LoanDisbursementEligibilityCheck
