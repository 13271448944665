/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from "react"
import {useTranslation} from "react-i18next"
import {KlassappTableHOC} from "HOC"
import {KlassappTableProps} from "types/common"
import {KlassappTable} from "uiKit"
import styles from "./ActivitiesTable.module.css"
import {getFullName, handleError} from "helpers"
import moment from "moment"
import {Icon} from "components"
import {BaseService, studentActivitiesService} from "services"
import {useCurrentProfilePermissions, useModel, useVisible} from "hooks"
import {ActivityPopup} from "../ActivityPopup"
import {
    EVENT_STUDENT_ACTIVITY_COMPLETION_TOGGLED,
    EVENT_STUDENT_ACTIVITY_CREATED,
    StudentActivity
} from "types/activity"
import {TriggeredWorkflowStatus} from "types/triggered-workflow"
import {useMutation} from "@tanstack/react-query"
import {emitEvent} from "helpers/eventHelper"
import CircularSpin from "components/CircularSpin"

export type Props = KlassappTableProps & {
    activity: StudentActivity
    studentId?: number
    departmentId?: number
    getData: () => void
    parentFields: string[]
    parentAllFields: string[]
}

const WorkflowActivitiesTable: React.FC<Props> = ({
    // table props
    page,
    total,
    pageSize,
    columns,
    data,
    menuActions,
    allFields,
    fields,
    tableHeaderActions,
    isShowTableHeaderAction,
    isHideMenuActions,
    isLoading,
    dispatch,
    dispatchFunc,
    getCurrentData,
    onClickRowItem,
    onChangeFields,
    onDraggableColumn,
    onChangeAllFields,
    onUpdateRowData,
    onUpdateTableData,
    onChangePage,
    onChangeRowPerPage,
    onClickShowConfirmModal,
    // component props
    parentFields,
    parentAllFields,
    activity,
    studentId,
    departmentId,
    getData
}) => {
    const {t} = useTranslation(["activities", "common"])
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const currentProfilePermissions = useCurrentProfilePermissions()
    const canEditActivity = currentProfilePermissions.departmentActivities.hasPermissionsToEdit(departmentId)
    const activityPopup = useVisible(false)
    const [formEdit, setFormEdit] = useState(null)

    React.useEffect(() => {
        dispatch({
            data: (activity.workflowActivities ?? []).map((activity) => ({
                ...activity,
                id: activity.studentActivityId
            }))
        })
    }, [activity.workflowActivities])

    const onClickEdit = React.useCallback(
        (data) => {
            if (!canEditActivity) return
            setFormEdit({...formEdit, ...data})
            activityPopup.open()
        },
        [canEditActivity, formEdit]
    )

    const handleRowClick = React.useCallback(
        (record: StudentActivity) => {
            onClickEdit(record)
        },
        [onClickEdit]
    )

    const getAllFields = React.useCallback(() => parentAllFields, [parentAllFields])

    const getFields = React.useCallback(() => parentFields, [parentFields])

    const renderIconHtml = React.useCallback((studentActivity) => {
        return <div className={styles.iconTableWrap} />
    }, [])

    const toggleActivity = useMutation(
        async ({
            profileId,
            studentActivityId,
            isCompleted
        }: {
            profileId: number
            studentActivityId: number
            isCompleted: boolean
        }) => {
            const completedDate = new Date()
            const completedBy = BaseService.profileId
            const params = isCompleted
                ? {
                      studentActivityId,
                      completed: true,
                      completedDate,
                      completedBy
                  }
                : {
                      studentActivityId,
                      completed: false
                  }
            await studentActivitiesService.editActivity(params)
            emitEvent(EVENT_STUDENT_ACTIVITY_COMPLETION_TOGGLED, {
                profileId,
                studentActivityId,
                isCompleted,
                completedBy,
                completedDate
            })
        },
        {
            onSuccess: () => getData(),
            onError: (error) => handleError(error)
        }
    )

    const renderCompletedHtml = React.useCallback(
        ({
            studentActivityId,
            profileId,
            completed,
            completedByProfile,
            completedDate,
            triggeredWorkflow,
            initialActivityId
        }: StudentActivity) => {
            const workflowCompleted = triggeredWorkflow?.status === TriggeredWorkflowStatus.Done
            if (completed) {
                return (
                    <div className={styles.completedDateWrap}>
                        <div
                            onClick={(e) => {
                                e.stopPropagation()
                                if (toggleActivity.isLoading) return
                                toggleActivity.mutate({isCompleted: false, studentActivityId, profileId})
                            }}>
                            {toggleActivity.isLoading ? (
                                <CircularSpin size="small" />
                            ) : (
                                <Icon
                                    icon="CHECKMARK_CIRCLE"
                                    color={workflowCompleted && !initialActivityId ? "#18A957" : "#1e90ff"}
                                />
                            )}
                        </div>
                        <div className={styles.completedDateInfo}>
                            <p className={styles.completedDateInfo__item}>{getFullName(completedByProfile)}</p>
                            <p className={styles.completedDateInfo__item}>
                                {completedDate ? moment(completedDate).format(userDateFormat) : ""}
                            </p>
                        </div>
                    </div>
                )
            }
            return (
                <div className={styles.completedDateWrap}>
                    <div
                        onClick={(e) => {
                            e.stopPropagation()
                            if (toggleActivity.isLoading) return
                            toggleActivity.mutate({isCompleted: true, studentActivityId, profileId})
                        }}>
                        {toggleActivity.isLoading ? (
                            <CircularSpin size="small" />
                        ) : (
                            <Icon icon="CHECK_CIRCLE" color="#939393" />
                        )}
                    </div>
                </div>
            )
        },
        []
    )

    const getColumns = React.useCallback(
        () => [
            {
                title: "",
                field: "iconHtml",
                style: {width: 56, minWidth: 56, textAlign: "right", padding: 0},
                render: (_, record) => renderIconHtml(record)
            },
            {
                title: t("activities.dateTime"),
                field: "activityDate",
                render: (value) => moment(value).format(userDateTimeFormat)
            },
            {
                title: t("activities.aging"),
                field: "activityDate",
                render: (value) => moment(value).fromNow(true)
            },
            {
                title: t("activities.activity"),
                field: "activity.name",
                style: {lineHeight: "1.2"}
            },
            {
                title: t("activities.student"),
                field: "student",
                render: (value) => getFullName(value)
            },
            {
                title: t("activities.assignedTo"),
                field: "assignedProfiles",
                style: {lineHeight: "1.2"},
                render: (value) =>
                    (value ?? []).map((profile) => (
                        <div key={profile.profileId}>
                            {getFullName(profile)} {profile.title ? `(${profile.title})` : ""}
                        </div>
                    ))
            },
            {
                title: t("activities.completed"),
                field: "completedDate",
                render: (_, record) => renderCompletedHtml(record)
            }
        ],
        [t, renderIconHtml, renderCompletedHtml]
    )

    React.useEffect(() => {
        dispatchFunc([
            {key: "getPageTitle", func: () => ""},
            {
                key: "getListData",
                func: () => {}
            },
            {
                key: "getFields",
                func: getFields
            },
            {key: "getColumns", func: getColumns},
            {key: "getTableHeaderActions", func: () => []},
            {
                key: "onClickEdit",
                func: () => {}
            },
            {
                key: "onClickDelete",
                func: () => {}
            },
            {key: "onClickRowItem", func: handleRowClick}
        ])
    }, [getColumns, getAllFields, getFields, handleRowClick])

    const handleActivityUpserted = React.useCallback(
        (activity: StudentActivity) => {
            emitEvent(EVENT_STUDENT_ACTIVITY_CREATED, {
                profileId: studentId,
                activity
            })
            getData()
        },
        [studentId, getData]
    )

    return (
        <div className={styles.workflowActivitiesTableContainer}>
            <KlassappTable
                columns={columns}
                data={data}
                isLoading={isLoading}
                menuActions={[]}
                fields={fields}
                allFields={allFields}
                isShowHeader={false}
                onClickRowItem={onClickRowItem}
                onChangeFields={onChangeFields}
                onUpdateRowData={onUpdateRowData}
                onUpdateTableData={onUpdateTableData}
                onDraggableColumn={onDraggableColumn}
                onChangeAllFields={onChangeAllFields}
                tableClassName={styles.workflowActivitiesTable}
                classNameActionMenu={styles.tableActionMenu}
            />

            {!!activityPopup.isVisible && (
                <ActivityPopup
                    isShow={activityPopup.isVisible}
                    onClose={activityPopup.close}
                    studentId={studentId}
                    departmentId={departmentId}
                    setReload={handleActivityUpserted}
                    formEditData={formEdit}
                />
            )}
        </div>
    )
}

export default KlassappTableHOC(WorkflowActivitiesTable)
