/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useReducer, useState} from "react"
import styles from "./TransferCredits.module.css"
import {useTranslation} from "react-i18next"
import {KlassappTable, KlassappTableDropdownAsyncPaginate, KlassappTableHeader, SignaturePopup} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {BaseButton, BaseInput, Icon} from "components"
import {v4 as uuidV4} from "uuid"
import {cloneDeep, isEqual, pick, uniqBy} from "lodash"
import {useCurrentProfilePermissions, useVisible} from "hooks"
import {academicPlansService, courseService, transferCreditsService, universityService} from "services"
import debounce from "debounce-promise"
import moment from "moment"
import classNames from "classnames"
import {getFullName, handleError, preventMinus, toastError} from "helpers"
import {Button} from "antd"
import {BaseDepartmentId} from "types/departments"
import {Permissions} from "@edular/permissions"
import {PermissionUserType} from "types/permission"
import {ParamCreateTransferCredits, TransferCredits as TransferCreditsType} from "types/transferCredits"
import cx from "classnames"
import {AcademicPlans} from "types/academicPlans"

let dataTransferCredits = []

function reducer(state, action) {
    return {...state, ...action}
}
enum ApprovalStatus {
    PENDING = "pending",
    APPROVED = "approved",
    REJECTED = "rejected"
}
enum ReviewItemType {
    APPROVAL = "approval",
    AUDIT = "audit"
}
const TransferCredits = (props) => {
    const initialState = {
        reviewItem: null,
        reviewType: null,
        isRejecting: false,
        isApproving: false
    }
    const [{reviewItem, reviewType, isRejecting, isApproving}, dispatchState] = useReducer(reducer, initialState)
    const currentProfilePermissions = useCurrentProfilePermissions()
    const hasPermissionReview = currentProfilePermissions.hasPermissions({
        staff: [Permissions.Staff.Academics.Registrar.Students.DegreeAudit.Edit]
    })
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        isHideMenuActions,
        menuActions,
        data,
        allFields,
        fields,
        studentId,
        refreshProgressReport,
        tableHeaderActions,
        orderField,
        isLoading,
        isShowTableHeaderAction,
        departmentId
    } = props
    const [dataTransfer, setDataTransfer] = useState([])
    const [newDataTransfer, setNewDataTransfer] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const signaturePopup = useVisible(false)
    const [errors, setErrors] = useState({})
    const [filters] = useState({
        university: null
    })

    const {t} = useTranslation(["course"])

    const getFields = () => {
        return [
            "ID",
            "TERM NAME",
            "COURSE NAME",
            "UNIVERSITY",
            "CREDITS",
            "GRADES",
            "GRADES POINTS",
            "QUALITY POINTS",
            "ID",
            "COURSE NAME",
            "CREDITS",
            "APPLIED\nCREDITS",
            "ACCEPTED"
        ]
    }

    const getPageTitle = () => {
        return "Transfer Credits"
    }
    const getColumns = () => {
        return [
            {
                title: "ID",
                field: "idTransfer",
                style: {minWidth: "100px", width: "100px"},
                classNameHeader: styles.titleHeader
            },
            {
                title: "TERM NAME",
                field: "termNameTransfer",
                style: {minWidth: "200px", width: "200px"},
                classNameHeader: styles.titleHeader
            },
            {
                title: "COURSE NAME",
                field: "courseNameTransfer",
                style: {minWidth: "200px", width: "200px"},
                classNameHeader: styles.titleHeader
            },
            {
                title: "UNIVERSITY",
                field: "university",
                style: {minWidth: "120px"},
                classNameHeader: styles.titleHeader
            },
            {
                title: "CREDITS",
                field: "creditsTransfer",
                style: {minWidth: "90px", width: "90px"},
                classNameHeader: styles.titleHeader
            },
            {
                title: "GRADES",
                field: "alphabetical",
                style: {minWidth: "90px", width: "90px"},
                classNameHeader: styles.titleHeader
            },
            {
                title: "GRADES POINTS",
                field: "grades",
                style: {minWidth: "90px", width: "90px"},
                classNameHeader: styles.titleHeader
            },
            {
                title: "QUALITY POINTS",
                field: "qualityPoints",
                style: {minWidth: "90px", width: "90px"},
                classNameHeader: styles.titleHeader
            },
            {
                title: "ID",
                field: "idEquivalent",
                style: {minWidth: "150px", width: "150px"},
                classNameHeader: styles.idEquivalentHeader
            },
            {
                title: "COURSE NAME",
                field: "courseNameEquivalent",
                style: {minWidth: "200px", width: "200px"},
                classNameHeader: styles.titleHeader
            },
            {
                title: "CREDITS",
                field: "creditsEquivalent",
                style: {minWidth: "90px", width: "90px"},
                classNameHeader: styles.titleHeader
            },
            {
                title: "APPLIED\nCREDITS",
                field: "appliedCredits",
                style: {minWidth: "90px", width: "90px"},
                classNameHeader: styles.titleHeader
            },
            {
                title: "ACCEPTED",
                field: "accepted",
                style: {minWidth: "100px"},
                classNameHeader: styles.titleHeader
            }
        ]
    }

    const getParams = () => {
        const params: any = {
            studentProfileId: studentId,
            range: {
                page,
                pageSize
            }
        }
        return params
    }

    const getData = async () => {
        dispatch({isLoading: true})
        try {
            const params = getParams()

            let {data, total} = await transferCreditsService.listTransferCredits(params)
            const newData = data.map((item) => ({
                ...item,
                idTransferValue: item.idTransfer,
                termNameTransferValue: item.termNameTransfer,
                termNameTransfer: renderInput(
                    "termNameTransfer",
                    "Term Name",
                    `${item.id}`,
                    item.termNameTransfer,
                    false,
                    "text"
                ),
                courseNameTransferValue: item.courseNameTransfer,
                university: renderInput("university", "Select", `${item.id}`, item.universityName, false, "text"),
                universityValue: {universityId: item.universityId, name: item.universityName},
                creditsTransferValue: item.creditsTransfer?.toString(),
                gradesValue: item.grades?.toString(),
                qualityPointsValue: item.qualityPoints?.toString(),
                alphabeticalValue: item.alphabetical ?? "",
                idEquivalentValue: {code: item.codeEquivalent, name: item.courseName, courseId: item.idEquivalent},
                courseNameEquivalentValue: {code: item.codeEquivalent, name: item.courseName},
                idEquivalent: renderInput("idEquivalent", "Id", `${item.id}`, item.codeEquivalent, false, "text"),
                codeEquivalentValue: item.codeEquivalent,
                courseNameEquivalent: renderInput(
                    "courseNameEquivalent",
                    "Select",
                    `${item.id}`,
                    item.courseName,
                    false,
                    "text"
                ),
                creditsEquivalentValue: item.creditsEquivalent?.toString(),
                appliedCreditsValue: item.appliedCredits?.toString(),
                approvalStatusValue: item.approvalStatus,
                approvalSignatureIdValue: item.approvalSignatureId,
                approvalStatusUpdatedAtValue: item.approvalStatusUpdatedAt,
                accepted: renderApprovalHtml(item, !hasPermissionReview),
                cellTableStyle: styles.tableRow
            }))
            dispatch({data: newData, total: total})
            setDataTransfer(newData)
            setNewDataTransfer(newData)
            dataTransferCredits = newData
        } catch (e) {
            console.log("e")
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClickRowItem = (row) => {}
    const onUpdateRowData = (updatedData) => {
        const data = props.getCurrentData()
        const newData = cloneDeep(data).map((item) => {
            if (item.id === updatedData.id) {
                return {
                    ...item,
                    isChecked: updatedData.isChecked
                }
            }
            return item
        })
        dataTransferCredits = cloneDeep(newData)
        dispatch({data: newData})
    }

    const onClickDeleteMulti = useCallback(async () => {
        try {
            dispatch({isLoading: true})
            const ids: number[] = dataTransferCredits
                .filter((item) => item.isChecked && typeof item.id === "number")
                .map((item) => item.id)
            dispatch({isLoading: true})
            await transferCreditsService.deleteTransferCredits(ids)
            await getData()
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
        refreshProgressReport()
    }, [data, hasPermissionReview])

    useCallback(async () => {}, [data])

    const onClickEdit = (editItem) => {
        const newData = dataTransferCredits.map((item) => {
            if (editItem.id !== item.id) return item
            const id = item.id
            return {
                ...item,
                idTransfer: renderInput("idTransfer", "Id", id, item.idTransferValue, true, "text"),
                termNameTransfer: renderInput(
                    "termNameTransfer",
                    "Term Name",
                    id,
                    item.termNameTransferValue,
                    true,
                    "text"
                ),
                courseNameTransfer: renderInput(
                    "courseNameTransfer",
                    "Course Name",
                    id,
                    item.courseNameTransferValue,
                    true,
                    "text"
                ),
                university: renderUniversityDropDown(id, item.universityValue),
                creditsTransfer: renderInput(
                    "creditsTransfer",
                    "Credits",
                    id,
                    item.creditsTransferValue,
                    true,
                    "number"
                ),
                alphabetical: renderInput("alphabetical", "Grade Points", id, item.alphabeticalValue, true, "text"),
                grades: renderInput("grades", "Grades", id, item.gradesValue, true, "number"),
                qualityPoints: renderInput(
                    "qualityPoints",
                    "Quality Points",
                    id,
                    item.qualityPointsValue,
                    true,
                    "number"
                ),
                idEquivalent: renderCourseIdDropDown(id, item.idEquivalentValue),
                courseNameEquivalent: renderCourseDropDown(id, item.courseNameEquivalentValue),
                creditsEquivalent: renderInput(
                    "creditsEquivalent",
                    "Credits",
                    id,
                    item.creditsEquivalentValue,
                    true,
                    "number"
                ),
                appliedCredits: renderInput(
                    "appliedCredits",
                    "Applied Credits",
                    id,
                    item.appliedCreditsValue,
                    true,
                    "number"
                ),
                accepted: renderApprovalHtml({id}, !hasPermissionReview)
            }
        })
        dataTransferCredits = [...newData]
        setNewDataTransfer([...newData])
    }

    const onClickDelete = useCallback(
        async (deletedItem) => {
            try {
                if (typeof deletedItem.id == "string") {
                    dataTransferCredits = dataTransferCredits.filter((item) => item.id !== deletedItem.id)
                    return setNewDataTransfer(dataTransferCredits)
                }
                dispatch({isLoading: true})
                await transferCreditsService.deleteTransferCredits([deletedItem.id])
                await getData()
            } catch (e) {
                handleError(e)
            } finally {
                dispatch({isLoading: false})
            }
            refreshProgressReport()
        },
        [hasPermissionReview]
    )

    const getTableHeaderActions = () => {
        const {t, onClickShowConfirmModal} = props
        return [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            }
        ]
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "getMenuActions", func: getMenuActions},
            {key: "getTableHeaderActions", func: getTableHeaderActions},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDelete},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [data])

    useEffect(() => {
        getData()
    }, [page, pageSize, hasPermissionReview])

    useEffect(() => {
        props.updateTableHeaderActions()
    }, [data])

    const getMenuActions = () => {
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: onClickEdit
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: onClickDelete
            }
        ]
    }

    const onChangeInput = (id, field, value, type: "number" | "text") => {
        const changeDataForm = dataTransferCredits.map((item) => {
            if (item.id !== id) {
                return item
            }
            const newValue = {
                ...item,
                [field]: renderInput(field, field, item.id, value, true, type),
                [`${field}Value`]: value
            }
            return newValue
        })

        dataTransferCredits = changeDataForm
        setNewDataTransfer(changeDataForm)
    }

    const renderInput = (
        field: string,
        nameField: string,
        id: string,
        value: string,
        isEditing: boolean,
        type: "number" | "text"
    ) => {
        if (!isEditing) {
            return (
                <div className={field === "idEquivalent" ? styles.titleWrapId : styles.titleWrap}>
                    <span className={styles.titleInput}>{value}</span>
                </div>
            )
        }
        return (
            <BaseInput
                type={type}
                placeholder={nameField}
                value={value}
                disabledArrow
                min={type === "number" ? 0 : undefined}
                onKeyPress={type === "number" ? preventMinus : undefined}
                onChange={(text) => onChangeInput(id, field, text, type)}
            />
        )
    }

    useEffect(() => {
        dataTransferCredits = [...newDataTransfer]
        dispatch({data: [...newDataTransfer], total: newDataTransfer.length})
    }, [newDataTransfer])

    function onClickReview(type, item) {
        if (hasPermissionReview) {
            signaturePopup.open()
            dispatchState({reviewItem: item, reviewType: type})
        }
    }

    function renderStatusApproved(item) {
        const approvalDate = item.approvalStatusUpdatedAt
        return (
            <div className={styles.statusWrap}>
                <div className={classNames(styles.signatureWrap, styles.signature__approval)}>
                    <Icon icon="CHECKMARK_CIRCLE" className={styles.signature__statusIcon} color="#18A957" />
                    <img src={item["approvalSignature"].imageUrl} alt="" className={styles.signature__img} />
                </div>
                <div className={styles.dateInfo}>
                    <p className={styles.dateInfo__text}>
                        {moment(approvalDate).local(true).format("MM/DD/YYYY hh:mm A")}
                    </p>
                    <p className={styles.dateInfo__text}>{getFullName(item["approvalSignature"])}</p>
                </div>
            </div>
        )
    }

    function renderStatusRejected(item) {
        const approvalDate = item.approvalStatusUpdatedAt
        return (
            <div className={styles.statusWrap}>
                <div className={classNames(styles.signatureWrap, styles.signature__reject)}>
                    <Icon icon="CROSS_CIRCLE" className={styles.signature__statusIcon} color="#DF1642" />
                    <img src={item["approvalSignature"].imageUrl} alt="" className={styles.signature__img} />
                </div>
                <div className={styles.dateInfo}>
                    <p className={styles.dateInfo__text}>
                        {moment(approvalDate).local(true).format("MM/DD/YYYY hh:mm A")}
                    </p>
                    <p className={styles.dateInfo__text}>{getFullName(item["approvalSignature"])}</p>
                </div>
            </div>
        )
    }

    function renderApprovalHtml(item, isDisabled: boolean = false) {
        if (isDisabled) {
            return (
                <div
                    className={cx(styles.review, {
                        [styles.disabledHtml]: isDisabled
                    })}>
                    REVIEW
                </div>
            )
        }
        switch (item.approvalStatus) {
            case "pending":
                return (
                    <div className={styles.review} onClick={() => onClickReview(ReviewItemType.APPROVAL, item)}>
                        Review
                    </div>
                )
            case "approved":
                return renderStatusApproved(item)
            case "rejected":
                return renderStatusRejected(item)
            default:
                return <></>
        }
    }

    function getKeyFromType() {
        return reviewType === ReviewItemType.APPROVAL ? "approvalStatus" : "auditStatus"
    }

    async function onClickReject() {
        if (isRejecting || isApproving) {
            return
        }
        const data = {
            studentChecklistItemId: reviewItem.studentChecklistItemId
        }
        const key = getKeyFromType()
        data[key] = "rejected"
        try {
            dispatchState({isRejecting: true})
            await transferCreditsService.updateTransferCredits(reviewItem.id, {
                approvalStatus: ApprovalStatus.REJECTED
            })
            dispatchState({reviewItem: null})
            signaturePopup.close()
            await getData()
        } catch (error) {
            handleError(error)
        } finally {
            dispatchState({isRejecting: false})
            refreshProgressReport()
        }
    }

    async function onClickApprove() {
        if (isRejecting || isApproving) {
            return
        }
        const key = getKeyFromType()
        data[key] = "approved"
        try {
            dispatchState({isApproving: true})
            await transferCreditsService.updateTransferCredits(reviewItem.id, {
                approvalStatus: ApprovalStatus.APPROVED
            })
            dispatchState({reviewItem: null})
            signaturePopup.close()
            await getData()
            // getStudentChecklistProgress()
        } catch (error) {
            handleError(error)
        } finally {
            dispatchState({isApproving: false})
            refreshProgressReport()
        }
    }

    const onAdd = useCallback(() => {
        const id = uuidV4()
        const newData = [
            ...dataTransferCredits,
            {
                id: id,
                idTransfer: renderInput("idTransfer", "Id", id, "", true, "text"),
                termNameTransfer: renderInput("termNameTransfer", "Term Name", id, "", true, "text"),
                courseNameTransfer: renderInput("courseNameTransfer", "Course Name", id, "", true, "text"),
                university: renderUniversityDropDown(id, null),
                creditsTransfer: renderInput("creditsTransfer", "Credits", id, "", true, "number"),
                grades: renderInput("grades", "Grades", id, "", true, "number"),
                qualityPoints: renderInput("qualityPoints", "Quality Points", id, "", true, "number"),
                alphabetical: renderInput("alphabetical", "Grade Points", id, "", true, "text"),
                idEquivalent: renderCourseIdDropDown(id, null),
                courseNameEquivalent: renderCourseDropDown(id, null),
                creditsEquivalent: renderInput("creditsEquivalent", "Credits", id, "", true, "number"),
                appliedCredits: renderInput("appliedCredits", "Applied Credits", id, "", true, "number"),
                accepted: renderApprovalHtml({id}, !hasPermissionReview)
            }
        ]
        dataTransferCredits = newData
        setNewDataTransfer(newData)
    }, [filters, hasPermissionReview])

    const onSearchUniversity = async (search = "", loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const {data, total} = await universityService.getListUniversity({
                filter: {
                    text: search
                },
                range: {
                    page,
                    pageSize
                }
            })
            return {
                options: data,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onSearchCourse = async (search = "", loadedOptions) => {
        try {
            const sections = await academicPlansService.getListCourseBySection({
                studentProfileId: studentId,
                viewType: AcademicPlans.TabKey.List,
                search
            })
            let courses = []
            for (let section of sections) {
                courses = [...courses, ...section.courses]
            }
            courses = uniqBy(courses, "courseId")
            return {
                options: courses,
                hasMore: false
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }
    const onCodeSearchCourse = async (search = "", loadedOptions) => {
        try {
            const sections = await academicPlansService.getListCourseBySection({
                studentProfileId: studentId,
                viewType: AcademicPlans.TabKey.List,
                search
            })
            let courses = []
            for (let section of sections) {
                courses = [...courses, ...section.courses]
            }
            courses = uniqBy(courses, "courseId")
            return {
                options: courses,
                hasMore: false
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onChangeUniversity = (id, value) => {
        const changeDataForm = dataTransferCredits.map((item) => {
            if (item.id !== id) {
                return item
            }
            const newValue = {
                ...item,
                university: renderUniversityDropDown(id, value),
                universityValue: value
            }
            return newValue
        })

        dataTransferCredits = changeDataForm
        setNewDataTransfer(changeDataForm)
    }

    const onChangeCourseName = (id, value) => {
        const changeDataForm = dataTransferCredits.map((item) => {
            if (item.id !== id) {
                return item
            }
            const newValue = {
                ...item,
                courseNameEquivalent: renderCourseDropDown(id, value),
                courseNameEquivalentValue: value,
                creditsEquivalentValue: value.credit?.toString(),
                creditsEquivalent: renderInput(
                    "creditsEquivalent",
                    "Credits",
                    id,
                    value.credit?.toString(),
                    true,
                    "number"
                ),
                idEquivalent: renderCourseIdDropDown(id, value),
                idEquivalentValue: value
            }
            return newValue
        })

        dataTransferCredits = changeDataForm
        setNewDataTransfer(changeDataForm)
    }

    const onChangeCourseId = (id, value) => {
        const changeDataForm = dataTransferCredits.map((item) => {
            if (item.id !== id) {
                return item
            }
            const newValue = {
                ...item,
                idEquivalent: renderCourseIdDropDown(id, value),
                idEquivalentValue: value,
                creditsEquivalentValue: value.credit?.toString(),
                creditsEquivalent: renderInput(
                    "creditsEquivalent",
                    "Credits",
                    id,
                    value.credit?.toString(),
                    true,
                    "number"
                ),
                courseNameEquivalent: renderCourseDropDown(id, value),
                courseNameEquivalentValue: value
            }
            return newValue
        })

        dataTransferCredits = changeDataForm
        setNewDataTransfer(changeDataForm)
    }

    const debounceUniversity = debounce(onSearchUniversity, 500)
    const debounceCourse = debounce(onSearchCourse, 500)
    const debounceCourseId = debounce(onCodeSearchCourse, 500)

    const renderUniversityDropDown = (id, currentValue, readOnly = false) => {
        return (
            <KlassappTableDropdownAsyncPaginate
                value={currentValue}
                readOnly={readOnly}
                valueKey="universityId"
                className={styles.dropDownCourse}
                labelKey="name"
                onChange={(value) => onChangeUniversity(id, value)}
                placeholder="Select"
                loadOptions={debounceUniversity}
            />
        )
    }
    const renderCourseDropDown = (id, currentValue, readOnly = false) => {
        return (
            <KlassappTableDropdownAsyncPaginate
                value={currentValue}
                readOnly={readOnly}
                className={styles.dropDownCourse}
                valueKey="courseId"
                labelKey="courseName"
                onChange={(value) => onChangeCourseName(id, value)}
                placeholder="Select"
                loadOptions={debounceCourse}
            />
        )
    }

    const renderCourseIdDropDown = (id, currentValue, readOnly = false) => {
        return (
            <div className={styles.titleWrapId}>
                <KlassappTableDropdownAsyncPaginate
                    value={currentValue}
                    className={styles.dropDownCourse}
                    valueKey="courseId"
                    readOnly={readOnly}
                    labelKey="courseCode"
                    onChange={(value) => onChangeCourseId(id, value)}
                    placeholder="Id"
                    loadOptions={debounceCourseId}
                />
            </div>
        )
    }

    const compareIsChanged = () => {
        const dataCheck = [
            "idTransferValue",
            "termNameTransferValue",
            "courseNameTransferValue",
            "universityValue",
            "creditsTransferValue",
            "gradesValue",
            "qualityPointsValue",
            "alphabeticalValue",
            "idEquivalentValue",
            "creditsEquivalentValue",
            "appliedCreditsValue",
            "approvalStatusValue",
            "approvalSignatureIdValue"
        ]
        if (dataTransfer.length < newDataTransfer.length) {
            return true
        }
        for (let i = 0; i < dataTransfer.length; i++) {
            const oldData = pick(dataTransfer[i], dataCheck)
            const newData = pick(newDataTransfer[i], dataCheck)

            if (!isEqual(oldData, newData)) {
                return true
            }
        }

        return false
    }

    const onClickDoneBtn = useCallback(async () => {
        let totalErrors = {...errors}

        if (Object.keys(totalErrors).length === 0) {
            let params: ParamCreateTransferCredits = {
                listTransferCredits: []
            }
            params.listTransferCredits = dataTransferCredits.map((item) => {
                const object: TransferCreditsType = {
                    idTransfer: item.idTransferValue,
                    termNameTransfer: item.termNameTransferValue,
                    studentProfileId: studentId,
                    courseNameTransfer: item.courseNameTransferValue,
                    universityId: item.universityValue.universityId,
                    creditsTransfer: item.creditsTransferValue?.length ? parseFloat(item.creditsTransferValue) : null,
                    grades: item.gradesValue?.length ? parseFloat(item.gradesValue) : null,
                    qualityPoints: item.qualityPointsValue?.length ? parseFloat(item.qualityPointsValue) : null,
                    alphabetical: item.alphabeticalValue ?? "",
                    idEquivalent: item.idEquivalentValue?.courseId ? item.idEquivalentValue.courseId : null,
                    creditsEquivalent: item.creditsEquivalentValue?.length
                        ? parseFloat(item.creditsEquivalentValue)
                        : null,
                    appliedCredits: item.appliedCreditsValue?.length ? parseFloat(item.appliedCreditsValue) : null,
                    approvalStatus: item.approvalStatus ? item.approvalStatus : "pending"
                    // approvalSignatureId: item.approvalSignatureId
                }
                if (typeof item.id === "number") {
                    object.id = item.id
                }
                return object
            })
            setIsSubmitting(true)
            try {
                await transferCreditsService.createTransferCredits(params)
                setIsSubmitting(false)
                getData()
            } catch (err) {
                setIsSubmitting(false)
                toastError("Update transfer credits failure")
            }
        } else {
            setErrors(totalErrors)
            toastError(t("common:validation.fieldRequired"))
        }
        refreshProgressReport()
    }, [studentId, hasPermissionReview])

    const onClickCancelBtn = useCallback(() => {
        setNewDataTransfer(cloneDeep(dataTransfer))
    }, [dataTransfer])

    let isChanged = compareIsChanged()

    return (
        <div>
            <span className={styles.title}>Transfer Credits</span>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                menuActions={isHideMenuActions ? [] : menuActions}
                isLoading={isLoading}
                tableRowParent={styles.tableRowParent}
                parentColumns={[
                    {title: "Transfer", classNameHeader: styles.parentTitleHeader},
                    {title: ""},
                    {title: ""},
                    {title: ""},
                    {title: ""},
                    {title: ""},
                    {title: ""},
                    {title: ""},
                    {title: "Equivalent", classNameHeader: styles.equivalentHeader},
                    {title: ""},
                    {title: ""},
                    {title: ""},
                    {title: ""}
                ]}
                allFields={allFields}
                isShowCheckedColumn={true}
                orderField={orderField}
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
            <button onClick={onAdd} className={styles.addButton}>
                <span className={styles.textAdd}>Add</span>
                <Icon icon="ADD_CIRCLE" color="#FF349B" className={styles.plusIcon} />
            </button>
            <div className={styles.actionWrap}>
                {isChanged && (
                    <BaseButton
                        title={t("common:action.cancel")}
                        variant="secondary"
                        className={styles.cancelBtn}
                        onClick={onClickCancelBtn}
                    />
                )}
                <div>
                    <BaseButton
                        title={isSubmitting ? t("common:action.saving") : t("common:action.save")}
                        onClick={onClickDoneBtn}
                        isActive={isChanged}
                        disabled={!!Object.keys(errors).length}
                        loading={isSubmitting}
                    />
                </div>
            </div>
            <SignaturePopup
                isShow={signaturePopup.isVisible}
                onClose={() => signaturePopup.close()}
                title="Please Review Item">
                <div className={styles.divider}></div>
                <div className={styles.action}>
                    <Button
                        className={cx(styles.actionBtn, styles.rejectBtn)}
                        onClick={onClickReject}
                        loading={isRejecting}>
                        <span>{t("common:action.reject")}</span>
                        <Icon icon="CROSS_CIRCLE" color="#df1642" className={styles.action__icon} />
                    </Button>
                    <Button
                        className={cx(styles.actionBtn, styles.approveBtn)}
                        onClick={onClickApprove}
                        loading={isApproving}>
                        <span>{t("common:action.approve")}</span>
                        <Icon icon="CHECKMARK_CIRCLE_1" color="#18a957" className={styles.action__icon} />
                    </Button>
                </div>
            </SignaturePopup>
        </div>
    )
}

export default KlassappTableHOC(TransferCredits)
