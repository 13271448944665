import Axios from "axios"
import {chatService} from "services"
import {handleError, handleErrorMessage} from "./errorHelper"
import {Communication} from "types/communication"
import {Auth} from "types/auth"
import {getFullName} from "./user"
import moment from "moment"
import {displaySince} from "./dateHelper"

export const getPreSignedUrl = async (fileExtension: string) => {
    const {result} = await chatService.getPreSignedUrl({file_extension: fileExtension})
    return result
}

export const uploadUsingPreSignedUrl = async (urlForUploading: string, file) => {
    try {
        if (file) {
            const headers = {
                "Content-Type": file.type
            }

            const result = await Axios.put(urlForUploading, file, {
                headers
            })
            return result
        } else {
            handleErrorMessage("Error uploading file")
            return false
        }
    } catch (e) {
        handleError(e)
        return false
    }
}

export const convertThread = (payload: any) => {
    let result: Communication.Message = {
        messageId: payload.roomId,
        content: payload.description,
        title: payload.name,
        isPinned: false,
        createdAt: payload.createdAt,
        type: "text",
        author: payload.owner,
        room: {
            roomId: payload.parentRoomId,
            name: payload.channelName,
            type: Communication.RoomTypes.Channel
        },
        scheduledAt: payload.scheduledAt,
        file: payload.file,
        withSms: payload.withSms,
        withEmail: payload.withEmail,
        totalComments: payload.totalComments,
        unread: payload.unread,
        isThread: true,
        jsonData: payload.jsonData
    }
    return result
}

export const convertFollowUpMessage = (payload: any) => {
    const {followUpId, userId, sourceType, sourceId, jsonData} = payload
    const {room, message} = jsonData
    let result: Communication.FollowUpMessage = {
        followUpId,
        userId,
        sourceType,
        sourceId
    }
    if (sourceType === Communication.FollowUpSourceType.Message) {
        result.message = message
    }
    if (room) {
        result.room = room
    }
    return result
}

export const convertUserToRoomUser = (user: Auth.UserProfile | Auth.Profile) => {
    const _convertUser: Communication.RoomUser = {
        userId: user.id,
        fullName: getFullName(user),
        photo: user["photo"]?.["64"]
    }
    return _convertUser
}

export const convertRoom = (roomType: Communication.RoomTypes, room: any) => {
    if (roomType === Communication.RoomTypes.DirectChat) {
        return {
            ...room,
            type: Communication.RoomTypes.DirectChat,
            isGroup: room.type === "group"
        }
    }

    if (roomType === Communication.RoomTypes.DirectMessage) {
        return {
            ...room,
            name: `${moment(room.createdAt).format("MM/DD/YYYY HH:mm")}${
                room.name ? `, ${room.name} participants` : ""
            }`
        }
    }

    return {
        ...room,
        type: [Communication.RoomTypes.OpenedSupportHub, Communication.RoomTypes.ClosedSupportHub].includes(roomType)
            ? roomType
            : room.type
    }
}

export const getOnlineStatus = (onlineAt: Communication.OnlineStatus, userDateTimeFormat: string) => {
    let onlineStatus: string = "Unseen"
    if (onlineAt) {
        onlineStatus = onlineAt === "online" ? "Online" : displaySince(onlineAt, userDateTimeFormat)
    }
    return onlineStatus
}
