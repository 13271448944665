import React from "react"
import classNames from "classnames"
import {Button, Spin} from "antd"
import {Icon} from "components/Icon"
import {get, isEqual, keyBy} from "lodash"
import PerfectScrollbar from "react-perfect-scrollbar"
import {FixedRowItem, ColumnPicker} from "./parts"
import {KlassappTableProps} from "types/common"
import styles from "./FixedKlassappTable.module.css"

interface State {
    virtualColWidth: number
    fixedColumns: any[]
    rightHeaderColumns: any[]
    columnsKeyByField: any
    tableWidth: number
    isShowChooseCols: boolean
}

export class FixedKlassappTable extends React.Component<KlassappTableProps, State> {
    private headerScrollRef = null
    private bodyScrollRef = null
    private virtualScrollRef = null
    private tableRef = null

    constructor(props) {
        super(props)
        this.headerScrollRef = React.createRef()
        this.bodyScrollRef = React.createRef()
        this.virtualScrollRef = React.createRef()
        this.tableRef = React.createRef()
        const {columns} = props
        const fixedColumns = columns.filter((column) => column.isFixed)
        const rightHeaderColumns = columns.filter((column) => !column.isFixed)
        const columnsKeyByField = keyBy(columns, "field")

        this.state = {
            tableWidth: 0,
            virtualColWidth: 0,
            fixedColumns,
            rightHeaderColumns,
            columnsKeyByField,
            isShowChooseCols: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {columns} = this.props
        if (!isEqual(prevProps.columns, columns)) {
            const fixedColumns = columns.filter((column) => column.isFixed)
            const rightHeaderColumns = columns.filter((column) => !column.isFixed)
            const columnsKeyByField = keyBy(columns, "field")
            this.setState({
                ...this.state,
                rightHeaderColumns,
                columnsKeyByField,
                fixedColumns
            })
        }
    }

    componentDidMount(): void {
        const tableWidth = get(this.tableRef, "current.clientWidth")
        this.setState({tableWidth})
    }

    handleScroll = (event, ref) => {
        const {
            target: {scrollLeft}
        } = event
        if (ref === "headerScroll") {
            this.headerScrollRef.current.scrollLeft = scrollLeft
        } else if (ref === "bodyScroll") {
            this.bodyScrollRef.current.scrollLeft = scrollLeft
        }
        this.virtualScrollRef.current.scrollLeft = scrollLeft
    }

    handleVirtualScroll = (event) => {
        const {
            target: {scrollLeft}
        } = event
        this.headerScrollRef.current.scrollLeft = scrollLeft
        this.bodyScrollRef.current.scrollLeft = scrollLeft
    }

    setIsShowChooseCols = (value) => {
        this.setState({isShowChooseCols: value})
    }

    onChangeFields = () => {
        //
    }

    renderHeader = () => {
        const {fixedColumns, rightHeaderColumns, tableWidth} = this.state
        const {fields, isShowColumnPicker} = this.props
        let leftWidth = 0
        fixedColumns.forEach((column) => {
            leftWidth += fields.includes(column.title) ? column.width * tableWidth : 0
        })

        return (
            <div className={styles.horizontalTableHeaderStructure}>
                <div className={styles.headerStructureLeft} style={{width: leftWidth}}>
                    {this.props.isShowCheckedColumn && <div className={styles.headerLeft} style={{width: 30}}></div>}
                    {fixedColumns.map(
                        (column, index) =>
                            fields.includes(column.title) && (
                                <div
                                    key={index}
                                    className={styles.headerLeft}
                                    style={{width: column.width * tableWidth}}>
                                    {column.titleHtml || column.title}
                                </div>
                            )
                    )}
                </div>
                <div className={styles.headerStructureRight} style={{left: leftWidth}}>
                    <div
                        className={styles.headerStructureScrollable}
                        ref={this.headerScrollRef}
                        onScroll={(e) => this.handleScroll(e, "bodyScroll")}>
                        <div className={styles.headerStructureScrollContent}>
                            {rightHeaderColumns.map(
                                (column, index) =>
                                    fields.includes(column.title) && (
                                        <div
                                            key={index}
                                            className={styles.headerRight}
                                            style={{
                                                width: column.width * tableWidth,
                                                maxWidth: column.maxWidth ? column.maxWidth : undefined
                                            }}>
                                            <div className={styles.headerRight__title}>
                                                {column.titleHtml || column.title}
                                            </div>
                                        </div>
                                    )
                            )}
                            <div className={styles.headerRight} style={{width: 40, flexGrow: 1}}>
                                <p className={styles.headerRight__title}></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classNames(styles.headerStructureLeft, styles.colsPickerHeader)} style={{width: 40}}>
                    {isShowColumnPicker && (
                        <div className={styles.flexEnd}>
                            <Button
                                className={styles.plusIconBtn}
                                icon={<Icon icon="COLUMNS" className={styles.iconPlus} />}
                                onClick={() => this.setIsShowChooseCols(true)}
                            />
                        </div>
                    )}
                </div>
            </div>
        )
    }

    render() {
        const {fixedColumns, rightHeaderColumns, columnsKeyByField, tableWidth, isShowChooseCols} = this.state
        const {fields, allFields, data, onChangeFields, ...rest} = this.props
        let leftWidth = 0
        fixedColumns.forEach((column) => {
            leftWidth += fields.includes(column.title) ? column.width * tableWidth : 0
        })
        let horizontalScrollbarWidth = leftWidth + 40
        rightHeaderColumns.forEach((column) => {
            horizontalScrollbarWidth += fields.includes(column.title) ? column.width * tableWidth : 0
        })

        return (
            <div ref={this.tableRef} className={styles.tableWrap}>
                <PerfectScrollbar className={classNames("custom-scroll", styles.horizontalTable)}>
                    {this.renderHeader()}
                    <div className={styles.verticalScroll}>
                        <div
                            className={styles.horizontalScroll}
                            ref={this.bodyScrollRef}
                            onScroll={(e) => this.handleScroll(e, "headerScroll")}>
                            {data.map((rowData, index) => (
                                <FixedRowItem
                                    key={rowData.id}
                                    rowIndex={index}
                                    rowData={rowData}
                                    leftWidth={leftWidth}
                                    fields={fields}
                                    fixedColumns={fixedColumns}
                                    rightHeaderColumns={rightHeaderColumns}
                                    columnsKeyByField={columnsKeyByField}
                                    tableWidth={tableWidth}
                                    {...rest}
                                />
                            ))}
                        </div>
                    </div>
                    <div className={styles.horizontalScrollPlaceholder}></div>
                    <div className={styles.horizontalScrollWrapper}>
                        <PerfectScrollbar
                            className={styles.horizontalScrollbar__scrollable}
                            ref={this.virtualScrollRef}
                            onScroll={this.handleVirtualScroll}>
                            <div
                                className={styles.horizontalScrollbar__Content}
                                style={{minWidth: horizontalScrollbarWidth}}></div>
                        </PerfectScrollbar>
                    </div>
                </PerfectScrollbar>
                {isShowChooseCols && (
                    <div>
                        <ColumnPicker
                            fields={fields}
                            allFields={allFields}
                            onChangeFields={onChangeFields}
                            setIsShowChooseCols={(value) => this.setIsShowChooseCols(value)}
                        />
                    </div>
                )}
                {this.props?.isLoading ? (
                    <div className={styles.loading}>
                        <Spin />
                    </div>
                ) : null}
            </div>
        )
    }
}
