/* eslint-disable react-hooks/exhaustive-deps */
import debounce from "debounce-promise"
import React from "react"
import {useTranslation} from "react-i18next"
import {workflowService} from "services"
import {KlassDropdownAsync, KlassDropdownAsyncProps} from "../Select"
import {getFormattedWorkflowName, WorkflowTriggerElement} from "types/workflow"

type Props = Partial<KlassDropdownAsyncProps> & {
    triggerElement?: WorkflowTriggerElement
}

const WorkflowSelect: React.FC<Props> = ({triggerElement, ...props}) => {
    const {t} = useTranslation(["common"])

    const search = React.useCallback(
        debounce(async (search: string = "") => {
            if (triggerElement) {
                const {data} = await workflowService.getAutomatedWorkflowsByTrigger(triggerElement)
                return data
            }

            const {data} = await workflowService.getAllWorkflows({
                filter: {
                    search,
                    isActive: true
                },
                range: {
                    limit: 20,
                    offset: 0
                },
                sort: {
                    orderBy: "name",
                    orderDir: "ASC"
                },
                tableOnly: true
            })
            return data
        }, 500),
        [triggerElement]
    )

    return (
        <KlassDropdownAsync
            {...props}
            placeholder={props.placeholder || t("selectField.placeholder")}
            loadOptions={search}
            defaultOptions={true}
            valueKey="id"
            labelKey="name"
            getOptionLabel={getFormattedWorkflowName}
        />
    )
}

export default WorkflowSelect
