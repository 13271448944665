import React, {useState, useRef, useCallback, useEffect} from "react"
import {useHistory} from "react-router-dom"
import cx from "classnames"
import FullCalendar from "@fullcalendar/react"
import {get, isEmpty, keyBy, uniq, uniqBy} from "lodash"
import moment from "moment"
import "moment/min/locales"
import {useModel, useVisible} from "hooks"
import {attendanceService, calendarService, courseScheduleService, courseService, userServiceV3} from "services"
import {getFullName, handleError, addAlpha} from "helpers"
import {Calendar} from "../Calendar"
import {ChooseRecurringPopup} from "components/Calendar/Common"
import {CalendarEventOption, RecurringPopupType} from "types/calendar"
import styles from "./HomeCalendar.module.css"
import {getScheduleEventColor} from "helpers/calendar"

type Props = {
    customCalendarHeight?: number
    userId?: number
    showStatsInfo?: boolean
    studentProfileId?: number
}
let isDateSetChanging = false

export const HomeCalendar = (props: Props) => {
    const {customCalendarHeight, userId, showStatsInfo, studentProfileId} = props
    const model = useModel()
    let currentUserId = userId ? userId : +model.user.id
    const [lessonStats, setLessonStats] = useState<{
        totalLessonAttended: number
        totalLessonMissed: number
        totalLesson: number
    }>()
    const calendarRef = useRef<FullCalendar>()
    const [allEvents, setAllEvents] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [calendarId, setCalendarId] = useState(null)
    const [tempEventId, setTempEventId] = useState(null)
    const [recurringPopupData, setRecurringPopupData] = useState({
        type: null,
        data: null
    })
    const chooseRecurringPopup = useVisible(false)
    const history = useHistory()

    const getLessonStats = useCallback(async () => {
        const params: any = {
            studentProfileIds: [studentProfileId]
        }
        const lessonsStats = await attendanceService.getRegistrarLessonStats(params)
        setLessonStats(lessonsStats)
    }, [studentProfileId])

    useEffect(() => {
        if (showStatsInfo && studentProfileId) {
            getLessonStats()
        }
    }, [showStatsInfo, getLessonStats, studentProfileId])

    const renderStatsInfo = useCallback(() => {
        const totalLesson = lessonStats?.totalLesson ?? 0
        const totalLessonAttended = lessonStats?.totalLessonAttended ?? 0
        const totalLessonMissed = lessonStats?.totalLessonMissed ?? 0
        return (
            <div className={styles.statsInfo}>
                <div className={styles.scheduled}>
                    <span className={styles.scheduledLabel}>Scheduled</span>
                    <div className={styles.scheduledStats}>
                        <span className={styles.scheduledText}>{totalLesson}</span>
                    </div>
                </div>
                <div className={styles.attended}>
                    <span className={styles.attendedLabel}>Attended</span>
                    <div className={styles.attendedStats}>
                        <span className={styles.attendedText}>{totalLessonAttended}</span>
                    </div>
                </div>
                <div className={styles.missed}>
                    <span className={styles.missedLabel}>Missed</span>
                    <div className={styles.missedStats}>
                        <span className={styles.missedText}>{totalLessonMissed}</span>
                    </div>
                </div>
                <div className={styles.remaining}>
                    <span className={styles.remainingLabel}>Remaining</span>
                    <div className={styles.remainingStats}>
                        <span className={styles.remainingText}>
                            {totalLesson - totalLessonAttended - totalLessonMissed}
                        </span>
                    </div>
                </div>
            </div>
        )
    }, [lessonStats])

    const getPayload = (calendarId, from, to) => {
        const utcFrom = moment(from).utc().format()
        const utcTo = moment(to).utc().format()
        const basePayload = {
            sort: {
                orderBy: "start_at_utc",
                order: "ASC"
            },
            filter: {
                from: utcFrom,
                to: utcTo
            },
            pagination: {
                page: 1,
                pageSize: 100 // TODO fix this
            }
        }
        const payload = {
            ...basePayload,
            filter: {
                ...basePayload.filter,
                owner_user_ids: [currentUserId],
                calendar_ids: [+calendarId],
                types: ["event", "course_schedule"]
            }
        }
        const invitesCalendarPayload = {
            sort: {
                orderBy: "start_at_utc",
                order: "ASC"
            },
            filter: {
                from,
                to,
                invited_user_ids: [currentUserId]
            },
            pagination: {
                page: 1,
                pageSize: 100 // TODO fix this
            }
        }
        const bookedPayload = {
            ...basePayload,
            filter: {
                ...basePayload.filter,
                appointment_slot_status: "booked"
            },
            or_filter: {
                owner_user_ids: [currentUserId],
                appointment_slot_status: "booked",
                booker_user_ids: [currentUserId],
                from,
                to
            }
        }
        return {payload, invitesCalendarPayload, bookedPayload}
    }

    const getUsers = async (userIds) => {
        if (userIds.length) {
            userIds = uniq(userIds)
            const userPayload = {
                filter: {id: userIds},
                range: {limit: userIds.length, offset: 0},
                linkedObjects: false
            }
            try {
                const {data} = await userServiceV3.getAll(userPayload)
                return data
            } catch (error) {
                return []
            }
        }
        return []
    }

    const getCourseSchedules = async (courseScheduleIds) => {
        if (courseScheduleIds.length) {
            courseScheduleIds = uniq(courseScheduleIds)
            try {
                const {data} = await courseScheduleService.courseScheduleGet({
                    filter: {courseScheduleIds: courseScheduleIds}
                })
                return data
            } catch (error) {
                return []
            }
        }
        return []
    }

    const getCourses = async (courseIds) => {
        if (courseIds.length) {
            courseIds = uniq(courseIds)
            try {
                const {data} = await courseService.list({filter: {courseIds}, linkedEntities: ["submodule"]})
                return data
            } catch (error) {
                return []
            }
        }
        return []
    }

    const getEvents = async (calendarId, from, to) => {
        const {payload, invitesCalendarPayload, bookedPayload} = getPayload(calendarId, from, to)
        const [{data: myEvents}, {data: myInvitesCalendar}, {data: bookedEvents}, {data: myInvites}] =
            await Promise.all([
                calendarService.getAll(payload),
                calendarService.getAllInvitesCalendar(invitesCalendarPayload),
                calendarService.getAll(bookedPayload),
                calendarService.getAllInvites(invitesCalendarPayload)
            ])
        const myInvitesCalendarEvents = myInvitesCalendar
            .filter((event) => event.invited_user_id !== event.object?.owner_user_id)
            .map((item) => ({...item.object, ...item.group, willGo: item.will_go}))
        const myInvitesEvents = myInvites
            .filter((event) => event.invited_user_id !== event.object?.owner_user_id)
            .map((item) => ({...item.object, ...item.group, willGo: item.will_go}))
        let userIds = []
        let courseScheduleIds = []
        let courseIds = []
        if (myEvents.length) {
            const newUserIds = myEvents
                .filter((item) => item.owner_user_id !== currentUserId)
                .map((event) => event.owner_user_id)
            userIds = [...userIds, ...newUserIds]
        }
        if (bookedEvents.length) {
            const newUserIds = bookedEvents
                .filter((item) => item.appointment_slot.booker_user_id !== currentUserId)
                .map((item) => item.appointment_slot.booker_user_id)
            userIds = [...userIds, ...newUserIds]
        }
        if (myInvitesCalendarEvents.length) {
            const newUserIds = myInvitesCalendarEvents.map((item) => item.owner_user_id)
            const scheduleIds = myInvitesCalendarEvents.map((item) => item.json_data.schedule_id).filter((item) => item)
            const courseIdsData = myInvitesCalendarEvents.map((item) => item.json_data.course_id).filter((item) => item)
            userIds = [...userIds, ...newUserIds]
            courseScheduleIds = [...courseScheduleIds, ...scheduleIds]
            courseIds = [...courseIds, ...courseIdsData]
        }
        if (myInvitesEvents.length) {
            const newUserIds = myInvitesEvents.map((item) => item.owner_user_id)
            const scheduleIds = myInvitesEvents.map((item) => item.json_data.schedule_id).filter((item) => item)
            const courseIdsData = myInvitesCalendarEvents.map((item) => item.json_data.course_id).filter((item) => item)
            courseScheduleIds = [...courseScheduleIds, ...scheduleIds]
            courseIds = [...courseIds, ...courseIdsData]
            userIds = [...userIds, ...newUserIds]
        }
        const [users, courseSchedules, courses] = await Promise.all([
            getUsers(userIds),
            getCourseSchedules(courseScheduleIds),
            getCourses(courseIds)
        ])
        const usersKeyById = keyBy(users, "id")
        const courseSchedulesById = keyBy(courseSchedules, "id")
        const coursesById = keyBy(courses, "courseId")
        const myInvitesKeyByObjectId = keyBy(myInvites, "object_id")
        myEvents.forEach((event) => {
            const isCurrentUser = event.owner_user_id === currentUserId
            if (myInvitesKeyByObjectId[event.object_id]) {
                event.willGo = get(myInvitesKeyByObjectId[event.object_id], "will_go")
            }
            event.ownerFullName = isCurrentUser
                ? getFullName(model.user)
                : getFullName(usersKeyById[event.owner_user_id])
        })
        bookedEvents.forEach((item) => {
            const isCurrentUser = item.appointment_slot.booker_user_id === currentUserId
            item.name = isCurrentUser
                ? getFullName(model.user)
                : getFullName(usersKeyById[item.appointment_slot.booker_user_id])
        })
        myInvitesCalendarEvents.forEach((event) => {
            const isCurrentUser = event.owner_user_id === currentUserId
            event.ownerFullName = isCurrentUser
                ? getFullName(model.user)
                : getFullName(usersKeyById[event.owner_user_id])
            if (event.json_data.schedule_id) {
                event.courseScheduleInfo = courseSchedulesById[event.json_data.schedule_id]
                event.courseInfo = coursesById[event.json_data.course_id]
            }
        })
        myInvitesEvents.forEach((event) => {
            const isCurrentUser = event.owner_user_id === currentUserId
            event.ownerFullName = isCurrentUser
                ? getFullName(model.user)
                : getFullName(usersKeyById[event.owner_user_id])
            if (event.json_data.schedule_id) {
                event.courseScheduleInfo = courseSchedulesById[event.json_data.schedule_id]
                event.courseInfo = coursesById[event.json_data.course_id]
            }
        })
        const events = [...myEvents, ...bookedEvents, ...myInvitesCalendarEvents, ...myInvitesEvents]
        return uniqBy(events, "object_id")
    }

    const reloadEvents = async () => {
        try {
            setIsLoading(true)
            const currentData = calendarRef.current.getApi().getCurrentData()
            const from = currentData.dateProfile.activeRange.start
            const to = currentData.dateProfile.activeRange.end
            const events = await getEvents(calendarId, from, to)
            convertEvents(events)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const onChangeDatesSet = async (date) => {
        if (isDateSetChanging) return
        isDateSetChanging = true
        try {
            setIsLoading(true)
            let events = []
            if (!calendarId) {
                const {data} = await calendarService.getMyPrimaryCalendar({}, {})
                const calendarId = data.calendar_id
                events = await getEvents(calendarId, date.startStr, date.endStr)
                setCalendarId(calendarId)
            } else {
                events = await getEvents(calendarId, date.startStr, date.endStr)
            }
            convertEvents(events)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
            isDateSetChanging = false
        }
    }

    const getEventColor = (event) => {
        let color = event?.json_data?.color || "#1e90ff"
        if (event.type === "course_schedule" && event?.json_data.eventType) {
            color = getScheduleEventColor(event?.json_data.eventType)
        }
        const isFutureEvent = moment(event.start_at_utc).isAfter(moment())
        const isVariableColor = color.includes("var")
        const isGoing = true
        if (!isGoing) {
            return {
                borderColor: color,
                color: "transparent"
            }
        }
        return {
            borderColor: "transparent",
            color: isFutureEvent || isVariableColor ? color : addAlpha(color, 0.5)
        }
    }

    const getModuleTitle = (eventType, courseInfo) => {
        if (!eventType || !courseInfo) {
            return ""
        }
        switch (eventType) {
            case "classroom":
                return courseInfo.theoryOrClassroomModuleTitle || "Classroom"
            case "lab":
                return courseInfo.labModuleTitle || "Lab"
            case "test":
                return courseInfo.testModuleTitle || "Test"
            case "others":
                return courseInfo.othersModuleTitle || "Others"
            default:
                return ""
        }
    }

    const getSubModuleTitle = (subModuleId, courseInfo) => {
        if (!subModuleId || !courseInfo) {
            return null
        }
        const result = courseInfo.submodules.find((item) => item.submoduleId === subModuleId)
        return result?.title
    }

    const convertEvents = (initialEvents) => {
        const events = (initialEvents || []).map((event, index) => {
            const originalColor = event?.json_data?.color || "#1e90ff"
            const reminders = (event?.json_data?.notifications ?? []).map((value) => ({
                value,
                label: `${moment.duration(value / 60, "minutes").humanize()} before`
            }))
            const withSMS = !!event?.json_data?.with_sms
            const eventSignInRequired = !!event?.json_data?.eventSignInRequired
            const attendedChangeToStatus = !!event?.json_data?.attendedChangeToStatus
            const notAttendedChangeToStatus = !!event?.json_data?.notAttendedChangeToStatus
            const completionWorkflow = !!event?.json_data?.completionWorkflow
            const {borderColor, color} = getEventColor(event)
            const allDay = moment(event.end_at_utc).diff(moment(event.start_at_utc), "seconds") === 24 * 3600
            return {
                id: event.object_id,
                start: moment(event.start_at_utc).local().format(),
                end: moment(event.end_at_utc).local().format(),
                originalColor,
                borderColor: borderColor,
                color: color,
                startDateTime: moment(event.start_at_utc).local(),
                endDateTime: moment(event.end_at_utc).local(),
                title: event.name,
                allDay,
                order: 1,
                classNames: "",
                initialEventIndex: index,
                description:
                    event.type === "appointment_slot"
                        ? get(event, "appointment_slot.booker_notes", event.description)
                        : event.description,
                isAppointmentSlot: event.type === "appointment_slot",
                ownerUserId: event.owner_user_id,
                ownerFullName: event.ownerFullName,
                willGo: event.willGo,
                reminders,
                withSMS,
                eventSignInRequired,
                attendedChangeToStatus,
                notAttendedChangeToStatus,
                completionWorkflow,
                isLesson: !isEmpty(event.courseScheduleInfo),
                schedule_suffix: event.courseScheduleInfo?.schedule_suffix,
                moduleTitle: getModuleTitle(event.json_data.eventType, event.courseInfo),
                subModuleTitle: getSubModuleTitle(event.json_data.sub_module_id, event.courseInfo),
                json_data: event.json_data,
                rrule: event.rrule
            }
        })
        setAllEvents(events)
    }

    const onClickDeleteEvent = async (event) => {
        const eventId = +event.id
        const rrule = get(event, "_def.extendedProps.rrule")
        if (rrule) {
            setTempEventId(eventId)
            chooseRecurringPopup.open()
            setRecurringPopupData({
                type: RecurringPopupType.Delete,
                data: null
            })
            return
        }
        await deleteEvent(eventId)
    }

    const deleteEvent = async (eventId, target_filter = "only_this") => {
        try {
            const newEvents = allEvents.filter((item) => item.id !== eventId)
            setAllEvents(newEvents)
            await calendarService.deleteEvent(eventId, {
                target_filter
            })
            reloadEvents()
        } catch (error) {
            handleError(error)
        }
    }

    const onClickUpdateGoingEvent = async (event, will_go) => {
        const eventId = +event.id
        const rrule = get(event, "_def.extendedProps.rrule")
        if (rrule) {
            setTempEventId(eventId)
            chooseRecurringPopup.open()
            setRecurringPopupData({
                type: RecurringPopupType.Update,
                data: {
                    will_go
                }
            })
            return
        }
        await updateGoingEvent(eventId, will_go)
    }

    const updateGoingEvent = async (object_id, will_go, target_filter = "only_this") => {
        try {
            await calendarService.updateStatus({
                object_id,
                target_filter,
                will_go
            })
            reloadEvents()
        } catch (error) {
            handleError(error)
        }
    }

    const onDateClick = () => {}

    const onClickEditEvent = (event) => {
        history.push(`/calendar-event/detail?id=${event.id}`)
    }

    const canShowEditIcon = (event) => {
        return !event?.extendedProps?.isLesson && !event?.extendedProps?.isAppointmentSlot
    }

    const canShowDelete = (event) => {
        return !event?.extendedProps?.isLesson
    }

    const getEventOptions = (): CalendarEventOption[] => {
        return [
            {
                title: "More detail",
                icon: "EDIT",
                action: onClickEditEvent,
                iconClassName: styles.eventPreviewHeaderEditIcon,
                canShow: canShowEditIcon
            },
            {title: "Delete event", icon: "DELETE", action: onClickDeleteEvent, canShow: canShowDelete},
            {title: "Going", icon: "CALENDAR_INPUT", action: onClickUpdateGoingEvent}
        ]
    }

    const onConfirmRecurring = async (target_filter) => {
        chooseRecurringPopup.close()
        if (recurringPopupData.type === RecurringPopupType.Delete) {
            await deleteEvent(tempEventId, target_filter)
        } else {
            await updateGoingEvent(tempEventId, recurringPopupData.data.will_go, target_filter)
        }
    }

    const eventOptions = getEventOptions()

    return (
        <div className={cx(styles.root, "home-calendar")}>
            <div className={styles.container}>
                {showStatsInfo ? renderStatsInfo() : null}
                <Calendar
                    calendarRef={calendarRef}
                    events={allEvents}
                    isLoading={isLoading}
                    eventOptions={eventOptions}
                    isShowGoing
                    onDateClick={onDateClick}
                    onChangeDatesSet={onChangeDatesSet}
                    dayHeaders={false}
                    customCalendarHeight={customCalendarHeight}
                />
            </div>
            <ChooseRecurringPopup
                isShow={chooseRecurringPopup.isVisible}
                onClose={() => {
                    chooseRecurringPopup.close()
                }}
                type={recurringPopupData.type}
                onClickSave={onConfirmRecurring}
            />
        </div>
    )
}
