import {DisplayMode} from "types/common"
import styles from "./NotAvailableForEmployment.module.css"
import {CareerServices} from "types/careerServices"
import {useMemo} from "react"
import {Col, Radio, Row} from "antd"
import {FormLabel} from "components/Form"
import {BaseDatePicker} from "components/DateTimePicker"
import {BaseInput} from "components"
import moment from "moment"

type Props = {
    student: CareerServices.StudentDetail
    displayMode?: DisplayMode
    updateStudent: (_student: CareerServices.StudentDetail) => void
}

export function NotAvailableForEmployment(props: Props) {
    const {displayMode = "normal", student, updateStudent} = props
    const isFullDisplay = displayMode === "normal"

    const onChangeData = (key, value) => {
        const _student = {...student}
        _student[key] = value
        if (key === "unemploymentType") {
            _student.isPlaced = false
            _student.isVerified = false
        }
        updateStudent(_student)
    }

    const isDisabled = useMemo(() => {
        return student.isPlaced
    }, [student])

    return (
        <div className={styles.formDetail}>
            <span className={styles.title}>Not available for employment</span>
            <Radio.Group
                value={student.unemploymentType}
                onChange={(event) => onChangeData("unemploymentType", event.target.value)}
                className={styles.radioWrap}>
                <Radio value={CareerServices.UnemploymentType.SelfEmployed} className={styles.inputCheck}>
                    Self-Employed
                </Radio>
                {student.unemploymentType === CareerServices.UnemploymentType.SelfEmployed && (
                    <Row gutter={[16, 16]} className={styles.inputWrap}>
                        <Col span={isFullDisplay ? 8 : 24}>
                            <FormLabel label="Name of Business" />
                            <BaseInput
                                placeholder="Enter name of business"
                                disabled={isDisabled}
                                value={student.selfEmployedBusinessName}
                                onChange={(_value) => onChangeData("selfEmployedBusinessName", _value)}
                            />
                        </Col>
                        <Col span={isFullDisplay ? 16 : 24}>
                            <FormLabel label="Business Description" />
                            <BaseInput
                                placeholder="Enter Business Description"
                                disabled={isDisabled}
                                value={student.selfEmployedBusinessDescription}
                                onChange={(_value) => onChangeData("selfEmployedBusinessDescription", _value)}
                            />
                        </Col>
                        <Col span={isFullDisplay ? 8 : 24}>
                            <FormLabel label="Phone" />
                            <BaseInput
                                placeholder="Phone"
                                disabled={isDisabled}
                                value={student.selfEmployedPhone}
                                onChange={(_value) => onChangeData("selfEmployedPhone", _value)}
                            />
                        </Col>
                        <Col span={isFullDisplay ? 8 : 24}>
                            <FormLabel label="Email" />
                            <BaseInput
                                placeholder="Email"
                                disabled={isDisabled}
                                value={student.selfEmployedEmail}
                                onChange={(_value) => onChangeData("selfEmployedEmail", _value)}
                            />
                        </Col>
                        <Col span={isFullDisplay ? 8 : 24}>
                            <FormLabel label="Website" />
                            <BaseInput
                                placeholder="Website"
                                disabled={isDisabled}
                                value={student.selfEmployedWebsite}
                                onChange={(_value) => onChangeData("selfEmployedWebsite", _value)}
                            />
                        </Col>
                        <Col span={isFullDisplay ? 8 : 24}>
                            <FormLabel label="Start Date" />
                            <BaseDatePicker
                                placeholder="dd/mm/yyyy"
                                disabled={isDisabled}
                                value={student.selfEmployedStartDate ? moment(student.selfEmployedStartDate) : null}
                                onChange={(_value) =>
                                    onChangeData("selfEmployedStartDate", _value ? _value.format("YYYY-MM-DD") : null)
                                }
                            />
                        </Col>
                    </Row>
                )}
                <Radio value={CareerServices.UnemploymentType.ContinuingEducation} className={styles.inputCheck}>
                    Continuing education as a full-time student
                </Radio>
                {student.unemploymentType === CareerServices.UnemploymentType.ContinuingEducation && (
                    <Row gutter={[16, 16]} className={styles.inputWrap}>
                        <Col span={isFullDisplay ? 8 : 24}>
                            <FormLabel label="Institution Name" />
                            <BaseInput
                                placeholder="Institution Name"
                                disabled={isDisabled}
                                value={student.contEduInstitutionName}
                                onChange={(_value) => onChangeData("contEduInstitutionName", _value)}
                            />
                        </Col>
                        <Col span={isFullDisplay ? 8 : 24}>
                            <FormLabel label="Program/Degree" />
                            <BaseInput
                                placeholder="Program/Degree"
                                disabled={isDisabled}
                                value={student.contEduProgramDegreeName}
                                onChange={(_value) => onChangeData("contEduProgramDegreeName", _value)}
                            />
                        </Col>
                        <Col span={isFullDisplay ? 8 : 24}>
                            <FormLabel label="Date Enrolled" />
                            <BaseDatePicker
                                placeholder="dd/mm/yyyy"
                                disabled={isDisabled}
                                value={student.contEduEnrolledDate ? moment(student.contEduEnrolledDate) : null}
                                onChange={(_value) =>
                                    onChangeData("contEduEnrolledDate", _value ? _value.format("YYYY-MM-DD") : null)
                                }
                            />
                        </Col>
                        <Col span={isFullDisplay ? 8 : 24}>
                            <FormLabel label="Expected Graduation Date" />
                            <BaseDatePicker
                                placeholder="dd/mm/yyyy"
                                disabled={isDisabled}
                                value={
                                    student.contEduExpectedGraduationDate
                                        ? moment(student.contEduExpectedGraduationDate)
                                        : null
                                }
                                onChange={(_value) =>
                                    onChangeData(
                                        "contEduExpectedGraduationDate",
                                        _value ? _value.format("YYYY-MM-DD") : null
                                    )
                                }
                            />
                        </Col>
                    </Row>
                )}
                <Radio value={CareerServices.UnemploymentType.MedicalConditions} className={styles.inputCheck}>
                    Medical condition which limits availability to obtain a career
                </Radio>
                {student.unemploymentType === CareerServices.UnemploymentType.MedicalConditions && (
                    <Row gutter={[16, 16]} className={styles.inputWrap}>
                        <Col span={isFullDisplay ? 8 : 24}>
                            <FormLabel label="List dates you will be unavailable for employment" />
                            <BaseDatePicker
                                placeholder="dd/mm/yyyy"
                                disabled={isDisabled}
                                value={student.medicalCondFromDate ? moment(student.medicalCondFromDate) : null}
                                onChange={(_value) =>
                                    onChangeData("medicalCondFromDate", _value ? _value.format("YYYY-MM-DD") : null)
                                }
                            />
                        </Col>
                        <Col span={isFullDisplay ? 8 : 24}>
                            <FormLabel label="To" />
                            <BaseDatePicker
                                placeholder="dd/mm/yyyy"
                                disabled={isDisabled}
                                value={student.medicalCondToDate ? moment(student.medicalCondToDate) : null}
                                onChange={(_value) =>
                                    onChangeData("medicalCondToDate", _value ? _value.format("YYYY-MM-DD") : null)
                                }
                            />
                        </Col>
                        <Col span={isFullDisplay ? 8 : 24}>
                            <FormLabel label="Death, include date" />
                            <BaseDatePicker
                                placeholder="dd/mm/yyyy"
                                disabled={isDisabled}
                                value={student.medicalCondDeathDate ? moment(student.medicalCondDeathDate) : null}
                                onChange={(_value) =>
                                    onChangeData("medicalCondDeathDate", _value ? _value.format("YYYY-MM-DD") : null)
                                }
                            />
                        </Col>
                        <Col span={24}>
                            <FormLabel label="Health-Related Situation (student or immediate family), please explain" />
                            <BaseInput
                                placeholder="Health-Related Situation"
                                disabled={isDisabled}
                                value={student.medicalCondSituation}
                                onChange={(_value) => onChangeData("medicalCondSituation", _value)}
                            />
                        </Col>
                    </Row>
                )}
                <Radio value={CareerServices.UnemploymentType.MilitaryAssignment} className={styles.inputCheck}>
                    Military assignment to active duty for yourself or your spouse (circle one)
                </Radio>
                {student.unemploymentType === CareerServices.UnemploymentType.MilitaryAssignment && (
                    <Row gutter={[16, 16]} className={styles.inputWrap}>
                        <Col span={isFullDisplay ? 8 : 24}>
                            <FormLabel label="Name and Birth date of Active Duty Military Member:" />
                            <BaseInput
                                placeholder=""
                                disabled={isDisabled}
                                value={student.militaryMemberName}
                                onChange={(_value) => onChangeData("militaryMemberName", _value)}
                            />
                        </Col>
                        <Col span={isFullDisplay ? 8 : 24}>
                            <FormLabel label="Duty Station:" />
                            <BaseInput
                                placeholder=""
                                disabled={isDisabled}
                                value={student.militaryDutyStation}
                                onChange={(_value) => onChangeData("militaryDutyStation", _value)}
                            />
                        </Col>
                        <Col span={isFullDisplay ? 8 : 24}>
                            <FormLabel label="Report Date:" />
                            <BaseDatePicker
                                placeholder="dd/mm/yyyy"
                                disabled={isDisabled}
                                value={student.militaryReportDate ? moment(student.militaryReportDate) : null}
                                onChange={(_value) =>
                                    onChangeData("militaryReportDate", _value ? _value.format("YYYY-MM-DD") : null)
                                }
                            />
                        </Col>
                    </Row>
                )}
                <Radio value={CareerServices.UnemploymentType.Incarcerated} className={styles.inputCheck}>
                    Incarcerated in a Federal, State, local penitentiary and/or including house arrest. Students
                    sentenced to serve for less than one month, only on weekends, or in a half-way house are not
                    eligible.
                </Radio>
                {student.unemploymentType === CareerServices.UnemploymentType.Incarcerated && (
                    <Row gutter={[16, 16]} className={styles.inputWrap}>
                        <Col span={isFullDisplay ? 8 : 24}>
                            <FormLabel label="Name of Institution" />
                            <BaseInput
                                placeholder=""
                                disabled={isDisabled}
                                value={student.incarceratedInstitutionName}
                                onChange={(_value) => onChangeData("incarceratedInstitutionName", _value)}
                            />
                        </Col>
                        <Col span={isFullDisplay ? 8 : 24}>
                            <FormLabel label="Length of sentencing" />
                            <BaseInput
                                placeholder=""
                                disabled={isDisabled}
                                value={student.incarceratedLengthOfSentencing}
                                onChange={(_value) => onChangeData("incarceratedLengthOfSentencing", _value)}
                            />
                        </Col>
                    </Row>
                )}
                <Radio value={CareerServices.UnemploymentType.Others} className={styles.inputCheck}>
                    Employed in different field / refused employment / unknown
                </Radio>
                {student.unemploymentType === CareerServices.UnemploymentType.Others && (
                    <Row gutter={[16, 16]} className={styles.inputWrap}>
                        <Col span={24}>
                            <FormLabel label="Comment" />
                            <BaseInput
                                placeholder=""
                                disabled={isDisabled}
                                value={student.othersComment}
                                onChange={(_value) => onChangeData("othersComment", _value)}
                            />
                        </Col>
                    </Row>
                )}
            </Radio.Group>
        </div>
    )
}
