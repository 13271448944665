/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from "react"
import styles from "./MessagePanel.module.css"
import {useModel} from "hooks"
import {chatServiceV2} from "services"
import {checkDepartmentPermission} from "helpers"
import {useTranslation} from "react-i18next"
import {Spin} from "antd"
import {DepartmentPermissionTarget, PermissionType} from "@edular/permissions"
import {Auth} from "types/auth"
import {intersection} from "lodash"
import {DirectChat} from "sections/NewCommunication/parts/DirectChat"
import {CommunicationContext} from "context/CommunicationContext"
import {Icon} from "components/Icon"

type Props = {
    student?: Auth.DepartmentStudent
    studentId: number
    departmentId?: number
    onClose?: () => void
}

export const MessagePanel = (props: Props) => {
    const model = useModel()
    const {changeRoom} = useContext(CommunicationContext)
    const {studentId, student, departmentId, onClose} = props
    const {t} = useTranslation(["chat", "user"])
    const [isLoading, setLoading] = useState(true)
    const [error, setError] = useState("")

    useEffect(() => {
        ;(async function findAndSwitchRoom() {
            try {
                setLoading(true)
                setError("")
                if (!departmentId || !studentId || !student) {
                    if (!model.chatOptions.activeRoomId) {
                        setError("No room found!!!")
                    }
                    return
                }

                const isStaff = model.isStaff()
                if (!isStaff) {
                    setError(`You do not have permission to start ${t(`common:moduleName.${departmentId}`)} chat`)
                    return
                }

                const departmentChats = model.departmentsChats.filter(
                    (dept) =>
                        checkDepartmentPermission(
                            model,
                            dept.id,
                            0,
                            DepartmentPermissionTarget.Chat,
                            PermissionType.Add
                        ) && dept.isDepartmentalChatEnabled
                )
                const departmentEnabledChat = departmentChats.map((x) => x.departmentId).includes(departmentId)
                if (!departmentEnabledChat) {
                    setError(`${t(`common:moduleName.${departmentId}`)} chat is currently not available.`)
                    return
                }

                const studentCampusIds = student?.campusIds ?? []
                const currentUser = await model.getOneCompleteUser(model.user.id)
                const userCampuses = currentUser.profiles.map(({campuses}) => campuses.map(({id}) => id))
                const userCampusIds = userCampuses.flatMap((id) => id)
                const sameCampusIds = intersection(userCampusIds, studentCampusIds)
                if (!sameCampusIds.length) {
                    setError(
                        `Please assign campus to this student to start ${t(`common:moduleName.${departmentId}`)} chat`
                    )
                    return
                }

                const departmentRoom = await chatServiceV2.addDepartmentCampusRoom(
                    departmentId,
                    sameCampusIds[0],
                    student?.userId
                )
                changeRoom(departmentRoom, undefined, false)
            } catch (error: any) {
                setError(error.response?.data?.message ?? error.message)
            } finally {
                setLoading(false)
            }
        })()
    }, [studentId, departmentId])

    if (isLoading) {
        return (
            <div className={styles.loading}>
                <Spin />
            </div>
        )
    }

    if (error) {
        return (
            <div className={styles.errorContainer}>
                <Icon icon="WARNING_TRIANGLE_FILL" className={styles.errorIcon} /> {error}
            </div>
        )
    }

    return <DirectChat hideHeading={true} />
}
