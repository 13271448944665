/* eslint-disable react-hooks/exhaustive-deps */
import debounce from "debounce-promise"
import React from "react"
import {useTranslation} from "react-i18next"
import {fundSourcesService} from "services"
import {KlassDropdownAsync, KlassDropdownAsyncProps} from "../Select"
import {Order} from "types/common"
import {FundSourceType} from "types/fin-aid/fund-source"

type Props = Partial<KlassDropdownAsyncProps> & {
    type?: FundSourceType | FundSourceType[]
    visibleInWorksheet?: boolean
}

const FundSourceSelect: React.FC<Props> = ({type, visibleInWorksheet, ...props}) => {
    const {t} = useTranslation(["common"])

    const search = React.useCallback(
        debounce(async (search: string = "") => {
            try {
                const {data} = await fundSourcesService.getFundSources({
                    filter: {
                        isActive: true,
                        type,
                        search
                    },
                    range: {
                        page: 1,
                        pageSize: 50
                    },
                    sort: {
                        orderBy: "position",
                        orderDir: Order.Asc
                    }
                })
                if (visibleInWorksheet != null) {
                    return data.filter((item) => item.visibleInWorksheet === visibleInWorksheet)
                }
                return data
            } catch {
                return []
            }
        }, 500),
        [type, visibleInWorksheet]
    )

    return (
        <KlassDropdownAsync
            {...props}
            placeholder={props.placeholder || t("selectField.placeholder")}
            loadOptions={search}
            defaultOptions={true}
            valueKey="fundSourceId"
            labelKey="title"
            getOptionLabel={(option) => (option.code ? `${option.title} (${option.code})` : option.title)}
        />
    )
}

export default FundSourceSelect
