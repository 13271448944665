import {BaseDepartmentId} from "types/departments"
import {Permissions, PermissionUserType} from "types/permission"

export function getPermissionsByDepartment(departmentId: number): PermissionUserType {
    switch (departmentId) {
        case BaseDepartmentId.Academics:
            return {staff: [Permissions.Staff.Settings.Modules.Academics.Edit]}
        case BaseDepartmentId.Admissions:
            return {staff: [Permissions.Staff.Settings.Modules.Admissions.Edit]}
        case BaseDepartmentId.CareerServices:
            return {staff: [Permissions.Staff.Settings.Modules.CareerServices.Edit]}
        case BaseDepartmentId.FinancialAid:
            return {staff: [Permissions.Staff.Settings.Modules.FinancialAid.Edit]}
        case BaseDepartmentId.StudentAccount:
            return {staff: [Permissions.Staff.Settings.Modules.StudentAccount.Edit]}
        case BaseDepartmentId.StudentServices:
            return {staff: [Permissions.Staff.Settings.Modules.StudentServices.Edit]}
        default:
            return {staff: []}
    }
}
