import React, {useMemo} from "react"
import {useTranslation} from "react-i18next"
import {KlassDropdown, KlassDropdownProps} from "../Select"
import {pick} from "lodash"
import {useAllShifts} from "hooks/useAllShifts"

type Props = Partial<KlassDropdownProps> & {}

const ShiftSelect: React.FC<Props> = ({value, ...props}) => {
    const {t} = useTranslation(["common"])
    const {shifts} = useAllShifts()
    const options = useMemo(() => shifts.map((item) => pick(item, ["shiftId", "name"])), [shifts])

    return (
        <KlassDropdown
            {...props}
            placeholder={props.placeholder || t("selectField.placeholder")}
            value={typeof value === "number" ? options.find((option) => option.shiftId === value) : value}
            options={options}
            valueKey="shiftId"
            labelKey="name"
        />
    )
}

export default ShiftSelect
