import {get} from "lodash"
import BaseService from "./Base"

export default class StudentSubmissionsService extends BaseService {
    getStudentSubmissions = async (params = {}): Promise<{total: number; data: any[]}> => {
        const response = await this.post("/v1/students/submissions/list", params)
        return get(response, "data", {})
    }

    getStudentSubmissionsStats = async (params = {}): Promise<{data: any}> => {
        const response = await this.post("/v1/students/submissions/stats", params)
        return get(response, "data", {})
    }

    createStudentSubmission = async (data: any): Promise<any> => {
        const response = await this.post("/v1/students/submissions/create", data)
        return get(response, "data.data", {})
    }

    updateStudentSubmission = async (data: any): Promise<any> => {
        const response = await this.post("/v1/students/submissions/update", data)
        return get(response, "data.data", {})
    }

    approveMultipleSubmissions = async (data: any): Promise<any> => {
        const response = await this.post("/v1/students/submissions/multiple-update", data)
        return get(response, "data.data", {})
    }

    studentSubmissionsSummary = async (data: any): Promise<any> => {
        const response = await this.post("/v1/students/submissions/summary", data)
        return get(response, "data", null)
    }

    getStudents = async (data: any): Promise<any> => {
        const response = await this.post("/v1/students/submissions/students-list", data)
        return get(response, "data", null)
    }
}
