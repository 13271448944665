/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useCallback, useEffect, useRef, useState} from "react"
import {Row, Col} from "antd"
import {BaseNewFilter, KlassappTableContext} from "uiKit"
import {KlassDropAsyncPaginate, KlassDropdown, KlassDropdownAsync} from "components/Select"
import {FormLabel} from "components/Form"
import {AddItemCircleButton, BaseButton, BaseInput, BaseLoading, Icon} from "components"
import {BaseDatePicker} from "components/DateTimePicker"
import GradeTable from "./GradeTable"
import styles from "./GradesTab.module.css"
import {useVisible} from "hooks"
import {academicCourseGradesService, academicGradingSystem} from "services"
import {handleError, toastError} from "helpers"
import {Course} from "types/courses"
import {AcademicInstructional} from "types/academicInstructional"
import {v4 as uuidV4} from "uuid"
import {PermissionsRequired} from "components/PermissionsRequired"
import {Permissions} from "@edular/permissions"
import {pick, isEqual, clone, cloneDeep} from "lodash"
import {useTranslation} from "react-i18next"
import {useDebounce} from "react-use"
import moment from "moment"

const initialFilter = {
    gradingItem: undefined,
    startDate: undefined
}

type GradingItem = {
    id: number
    gradingItem: string
    colorLabel: string
}
type SectionLessonGrade = {
    academicCourseGradeId: number
    createdAt: string
    date: string
    deletedAt: string
    gradingElementId: number
    lessonId: number
    maxPointValue: number
    title: string
    updatedAt: string
}
const dot = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
        backgroundColor: color,
        borderRadius: 5,
        content: '" "',
        display: "block",
        marginRight: 8,
        height: 20,
        width: 20
    }
})
const colourStyles = {
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {...styles, ...dot(data.colorLabel)}
    },
    input: (styles, {data}) => ({...styles, ...dot()}),
    placeholder: (styles) => ({...styles, ...dot()}),
    singleValue: (styles, {data}) => ({...styles, ...dot(data.colorLabel)})
}

type GradesTabProps = {
    courseInfo: Course.CourseTableItem
    academicCourseGradeId?: number
    lessonDetail: AcademicInstructional.LessonDetail
    termId: number
    gradeStats?: {attended: number}[]
    courseId: number
    allowAdd?: boolean
}

let initialDataGrading = {}
let initialSectionData = []
let newDataSection = []
let newDataGrading = {}

export function GradesTab(props: GradesTabProps) {
    const [errors, setErrors] = useState({})
    const [studentInClass, setStudentInClass] = useState<number>(0)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const {lessonDetail, courseInfo, termId, courseId, gradeStats = [], academicCourseGradeId, allowAdd = true} = props
    const [updatedDataGradingDetail, setUpdatedDataGradingDetail] = useState({})
    const [updatedDataSection, setUpdatedDataSection] = useState<SectionLessonGrade[]>([])
    const [initialData, setInitialData] = useState({})
    const [dataGradingItems, setDataGradingItems] = useState<GradingItem[]>([])
    const [listSection, setListSection] = useState<SectionLessonGrade[]>([])
    const [search, setSearch] = useState("")
    const [searchDebounce, setSearchDebounce] = useState("")
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState(clone(initialFilter))
    const [appliedFilter, setAppliedFilter] = useState(clone(initialFilter))

    const {t} = useTranslation(["common"])

    useDebounce(() => setSearchDebounce(search), 500, [search])

    const onApplyFilter = React.useCallback(() => {
        setAppliedFilter(filter)
    }, [filter])

    const onClearFilter = React.useCallback(() => {
        setFilter(clone(initialFilter))
        setAppliedFilter(clone(initialFilter))
    }, [])

    const getGradingItems = async () => {
        try {
            const {courseInfo} = props
            if (courseInfo?.gradingTemplateId) {
                const {data} = await academicGradingSystem.getDetailGradingSystem(courseInfo?.gradingTemplateId)
                const {gradingElements = []} = data || {}
                const gradingItems = gradingElements.map((item) => ({
                    id: item.id,
                    gradingItem: item.gradingItem,
                    colorLabel: item.colorLabel
                }))
                setDataGradingItems(gradingItems)
            }
        } catch (e) {
            handleError(e)
        }
    }

    const renderAttendanceHtml = (attended) => {
        if (attended) {
            return (
                <div className={styles.attendIconWrap}>
                    <Icon icon="CHECKMARK_CIRCLE_1" color="#18a957" className={styles.attendIcon} />
                </div>
            )
        }
        return (
            <div className={styles.attendIconWrap}>
                <Icon icon="CROSS_CIRCLE" color="#df1642" className={styles.attendIcon} />
            </div>
        )
    }

    const updateUpdatedData = (academicCourseGradeId, newDataGradingDetail) => {
        setUpdatedDataGradingDetail((previousData) => ({
            ...previousData,
            [academicCourseGradeId]: clone(
                newDataGradingDetail.map((item) => {
                    const id = item.academicCourseGradeStudentId ?? uuidV4()
                    return {
                        ...item,
                        id: id,
                        attendanceHtml: renderAttendanceHtml(item.attended),

                        academicCourseGradeId: academicCourseGradeId,
                        grade: renderInput(academicCourseGradeId, "grade", "Grade", id, item.grade, true, "number"),
                        notes: renderInput(academicCourseGradeId, "notes", "Notes", id, item.notes, true, "text"),
                        gradeValue: item.grade,
                        notesValue: item.notes,
                        studentProfileIdValue: item.studentProfileId,
                        studentNameValue: item.studentName
                    }
                })
            )
        }))
    }

    const updateInitialData = (academicCourseGradeId, newDataGradingDetail) => {
        setInitialData((previousData) => ({
            ...previousData,
            [academicCourseGradeId]: clone(
                newDataGradingDetail.map((item) => {
                    const id = item.academicCourseGradeStudentId ?? uuidV4()
                    return {
                        ...item,
                        id: id,
                        attendanceHtml: renderAttendanceHtml(item.attended),

                        academicCourseGradeId: academicCourseGradeId,
                        grade: renderInput(academicCourseGradeId, "grade", "Grade", id, item.grade, true, "number"),
                        notes: renderInput(academicCourseGradeId, "notes", "Notes", id, item.notes, true, "text"),
                        gradeValue: item.grade,
                        notesValue: item.notes,
                        studentProfileIdValue: item.studentProfileId,
                        studentNameValue: item.studentName
                    }
                })
            )
        }))
    }

    const getLessonGradeTable = async () => {
        try {
            setLoading(true)
            await getCardData()
            const {courseInfo} = props
            setUpdatedDataGradingDetail({})
            setListSection([])
            setUpdatedDataSection([])
            const filters: any = {
                courseId: courseId,
                startDate: appliedFilter?.startDate ? moment.utc(filter?.startDate).format("YYYY-MM-DD") : undefined,
                gradingElementId: appliedFilter?.gradingItem?.id
            }
            if (academicCourseGradeId) {
                filters.academicCourseGradeId = academicCourseGradeId
            }
            const params: any = {
                filter: filters,
                range: {
                    page: 1,
                    pageSize: 5000
                }
            }
            if (termId) {
                params.filter.termIds = [termId]
            }
            if (lessonDetail) {
                params.filter.lessonIds = [lessonDetail.id]
            }
            const {data = []} = await academicCourseGradesService.getLessonCourseGradeStudents(params)
            const courseGradesData = data.map((item) => ({
                ...item,
                maxPointValue: item.maxPointValue >= 0 ? `${item.maxPointValue}` : item.maxPointValue
            }))
            initialSectionData = cloneDeep(courseGradesData)
            setUpdatedDataSection(cloneDeep(courseGradesData))
            setListSection(cloneDeep(courseGradesData))
        } catch (e) {
            handleError(e)
        } finally {
            setLoading(false)
        }
    }

    const getCardData = async () => {
        try {
            const params: any = {
                range: {
                    pageSize: 1,
                    page: 1
                },
                filter: {
                    search: search,
                    courseId: courseId,
                    termIds: [termId]
                }
            }
            if (lessonDetail) {
                params.filter.lessonIds = [lessonDetail.id]
            }
            const {total = 0, data = []} = await academicCourseGradesService.getListStudent(params)
            setStudentInClass(total)
        } catch (err) {
            handleError(err)
        }
    }

    useEffect(() => {
        getGradingItems()
    }, [])

    useEffect(() => {
        if (courseInfo && termId) {
            getLessonGradeTable()
        }
    }, [courseInfo, lessonDetail, appliedFilter, termId])

    const onChangeFilter = (key: keyof typeof filter, value: any) => {
        setFilter((prev) => ({...prev, [key]: value}))
    }

    const renderFilter = () => {
        return (
            <BaseNewFilter
                filter={filter}
                onClick={onApplyFilter}
                onClickClear={onClearFilter}
                onSearchInput={setSearch}
                renderRightFilter={undefined}>
                <Row gutter={[40, 32]}>
                    <Col span={12}>
                        <FormLabel label="Start Date" />
                        <BaseDatePicker
                            value={filter?.startDate}
                            onChange={(option) => onChangeFilter("startDate", option)}
                            placeholder="Start Date"
                        />
                    </Col>
                    <Col span={12}>
                        <FormLabel label="Types" />
                        <KlassDropdown
                            value={filter?.gradingItem ?? null}
                            onChange={(option) => onChangeFilter("gradingItem", option)}
                            options={dataGradingItems}
                            labelKey="gradingItem"
                            valueKey="id"
                        />
                    </Col>
                </Row>
            </BaseNewFilter>
        )
    }

    const renderCards = () => {
        const maxParticipation = gradeStats?.length ?? 0
        const participation = gradeStats?.filter((item) => item.attended).length
        return (
            <div className={styles.cards}>
                <div className={styles.card}>
                    <div className={styles.cardIconWrap}>
                        <Icon icon="PEOPLE" className={styles.cardIcon} />
                    </div>
                    <div className={styles.cardContent}>
                        <p className={styles.cardLabel}>Students in class:</p>
                        <p className={styles.cardValue}>{studentInClass}</p>
                    </div>
                </div>
                <div className={styles.card}>
                    <div className={styles.cardIconWrap}>
                        <Icon icon="PEOPLE" className={styles.cardIcon} />
                    </div>
                    <div className={styles.cardContent}>
                        <p className={styles.cardLabel}>Participation:</p>
                        <p className={styles.cardValue}>
                            {participation}/{maxParticipation}
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    const onChangeElementData = (section, key, value) => {
        section[key] = value.id
        setUpdatedDataSection([...updatedDataSection])
    }

    const onClickDoneBtn = useCallback(async () => {
        const academicCourseGradeIds = Object.keys(newDataGrading)
        let grades = []
        let arrayDeleteGrades = []
        const isExistNegativeMaxPointValue = newDataSection.find(
            (item) => item.maxPointValue !== "" && parseFloat(item.maxPointValue) < 0
        )
        if (isExistNegativeMaxPointValue) {
            return toastError("Max point value must be a positive number ")
        }

        for (let i = 0; i < academicCourseGradeIds.length; i++) {
            const key = academicCourseGradeIds[i]
            const isExistNegativeGradeValue = (newDataGrading[key] ?? []).find(
                (item) => item.gradeValue !== "" && item.gradeValue && parseFloat(item.gradeValue) < 0
            )
            if (isExistNegativeGradeValue) {
                return toastError("Grade value must be a positive number. ")
            }
            const arrayGrade = (newDataGrading[key] ?? []).map((item) => {
                const data: any = {
                    grade: item.gradeValue !== "" && item.gradeValue ? parseFloat(item.gradeValue) : null,
                    notes: item.notesValue ?? "",
                    academicCourseGradeId: parseInt(key),
                    studentProfileId: parseInt(item.studentProfileIdValue),
                    courseId: courseId,
                    termId
                }
                if (typeof item.id === "number") {
                    data.academicCourseGradeStudentId = item.id
                }
                return data
            })
            const filterDeleteGrade = arrayGrade.filter(
                (item) => item.grade === null && item?.academicCourseGradeStudentId
            )
            const updatedGrade = arrayGrade.filter((item) => item.grade !== null)
            grades = [...grades, ...updatedGrade]
            arrayDeleteGrades = [...arrayDeleteGrades, ...filterDeleteGrade]
        }
        try {
            setIsSubmitting(true)
            await academicCourseGradesService.editLessonCourseGrade({
                grades,
                tableGrade: newDataSection.map((item) => ({
                    academicCourseGradeId: item.academicCourseGradeId,
                    gradingElementId: parseInt(item.gradingElementId),
                    maxPointValue: parseFloat(item.maxPointValue),
                    title: item.title
                }))
            })
            if (arrayDeleteGrades?.length) {
                await academicCourseGradesService.deleteLessonCourseGrade({
                    academicCourseGradeStudentIds: arrayDeleteGrades.map((item) => item.academicCourseGradeStudentId)
                })
            }

            getLessonGradeTable()
        } catch (err) {
            handleError(err)
        } finally {
            setIsSubmitting(false)
        }
    }, [courseInfo])

    useEffect(() => {
        newDataGrading = cloneDeep(updatedDataGradingDetail)
    }, [updatedDataGradingDetail])

    useEffect(() => {
        newDataSection = cloneDeep(updatedDataSection)
    }, [updatedDataSection])

    useEffect(() => {
        initialDataGrading = cloneDeep(initialData)
    }, [initialData])

    useEffect(() => {
        initialSectionData = cloneDeep(listSection)
    }, [listSection])

    // const onAdd = useCallback((academicCourseGradeId) => {
    //     const id = uuidV4()
    //     const newData = {
    //         id: id,
    //         academicCourseGradeId: academicCourseGradeId,
    //         grade: renderInput(academicCourseGradeId, "grade", "Grade", id, "", true, "number"),
    //         notes: renderInput(academicCourseGradeId, "notes", "Notes", id, "", true, "text"),
    //         studentName: renderSelectStudent(academicCourseGradeId, id, "studentName", "Select student", undefined),
    //         studentProfileId: renderSelectStudentId(
    //             academicCourseGradeId,
    //             id,
    //             "studentProfileId",
    //             "Student Id",
    //             undefined
    //         )
    //     }
    //     setUpdatedDataGradingDetail({
    //         ...newDataGrading,
    //         [academicCourseGradeId]: [...(newDataGrading[academicCourseGradeId] ?? []), newData]
    //     })
    // }, [])

    const onChangeInputHeader = (academicCourseGradeId, field, value) => {
        const changeDataForm = newDataSection.map((item) => {
            if (item.academicCourseGradeId !== academicCourseGradeId) {
                return item
            }
            const newValue = {
                ...item,
                [field]: value
            }
            return newValue
        })
        newDataSection = changeDataForm
        setUpdatedDataSection(cloneDeep(newDataSection))
    }

    const onChangeInput = (academicCourseGradeId, id, field, nameField, value, type) => {
        const changeDataForm = newDataGrading[academicCourseGradeId].map((item) => {
            if (item.id !== id) {
                return item
            }
            let newValue: any = {
                ...item
            }
            if (field === "studentProfileId") {
                newValue = {
                    ...newValue,
                    studentProfileId: renderSelectStudentId(
                        academicCourseGradeId,
                        id,
                        "studentProfileId",
                        nameField,
                        value
                    ),
                    studentProfileIdValue: value.studentProfileId
                }
            } else if (field === "studentName") {
                newValue = {
                    ...newValue,
                    studentName: renderSelectStudent(academicCourseGradeId, id, "studentName", nameField, value),
                    studentNameValue: value.studentName
                }
            } else {
                newValue = {
                    ...newValue,
                    [field]: renderInput(academicCourseGradeId, field, nameField, item.id, value, true, type),
                    [`${field}Value`]: value
                }
            }
            return newValue
        })
        newDataGrading[academicCourseGradeId] = changeDataForm
        setUpdatedDataGradingDetail(cloneDeep(newDataGrading))
    }

    const onClickEdit = (academicCourseGradeId, editItem) => {
        const newData = newDataGrading[academicCourseGradeId].map((item) => {
            if (editItem.id !== item.id) return item
            const data = {
                ...item,
                id: item.id,
                academicCourseGradeId: academicCourseGradeId,
                grade: renderInput(academicCourseGradeId, "grade", "Grade", item.id, item.gradeValue, true, "number"),
                notes: renderInput(academicCourseGradeId, "notes", "Notes", item.id, item.notesValue, true, "text"),
                studentName: renderSelectStudent(academicCourseGradeId, item.id, "studentName", "Select student", {
                    studentProfileId: item.studentProfileIdValue,
                    fullName: item.studentNameValue
                }),
                studentProfileId: renderSelectStudentId(
                    academicCourseGradeId,
                    item.id,
                    "studentProfileId",
                    "Student Id",
                    {studentProfileId: item.studentProfileIdValue, fullName: item.studentNameValue}
                )
            }
            return data
        })
        newDataGrading[academicCourseGradeId] = newData
        setUpdatedDataGradingDetail(cloneDeep(newDataGrading))
    }
    const onClickCancelBtn = useCallback(() => {
        newDataGrading = cloneDeep({...initialDataGrading})
        newDataSection = cloneDeep({...initialSectionData})
        setUpdatedDataGradingDetail(cloneDeep(initialDataGrading))
        setUpdatedDataSection(cloneDeep(initialSectionData))
    }, [])

    const onClickDelete = useCallback(
        async (academicCourseGradeId, deleteItem) => {
            const deleteItemId = deleteItem.id
            if (typeof deleteItemId !== "number") {
                const newData = newDataGrading[academicCourseGradeId].filter((item) => item.id !== deleteItemId)
                setUpdatedDataGradingDetail({
                    ...newDataGrading,
                    [academicCourseGradeId]: cloneDeep(newData)
                })
            } else {
                const newData = newDataGrading[academicCourseGradeId].filter(
                    (item) => deleteItem.id === item.id && typeof item.id === "number"
                )
                try {
                    const ids = newData.map((item) => item.id)
                    if (ids.length) {
                        await academicCourseGradesService.deleteLessonCourseGrade({academicCourseGradeStudentIds: ids})
                        getLessonGradeTable()
                    }
                    // onClickCancelBtn()
                } catch (err) {
                    onClickCancelBtn()
                }
            }
        },
        [academicCourseGradeId]
    )

    const renderInput = (
        academicCourseGradeId: number,
        field: string,
        nameField: string,
        id: string,
        value: string,
        isEditing: boolean,
        type: any = "number"
    ) => {
        if (!isEditing) {
            return (
                <div className={styles.titleWrap}>
                    <span className={styles.title}>{value}</span>
                </div>
            )
        }
        return (
            <BaseInput
                key={`${field}${id}`}
                type={type}
                disabledArrow
                placeholder={nameField}
                value={value}
                onChange={(text) => onChangeInput(academicCourseGradeId, id, field, nameField, text, type)}
            />
        )
    }

    const searchStudent = async (search: string = "", loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const params: any = {
                range: {
                    pageSize,
                    page
                },
                filter: {
                    search: search,
                    courseId: courseId,
                    termIds: [termId]
                }
            }
            if (lessonDetail) {
                params.filter.lessonIds = [lessonDetail.id]
            }
            const {data = [], total = 0} = await academicCourseGradesService.getListStudent(params)
            return {
                options: data,
                hasMore: loadedOptions.length < total
            }
        } catch (e) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onSelectStudent = useCallback((academicCourseGradeId, value, id, field, nameField) => {
        onChangeInput(academicCourseGradeId, id, field, nameField, value, "text")
        onChangeInput(academicCourseGradeId, id, "studentProfileId", "ID", value, "text")
    }, [])

    const onSelectStudentId = useCallback((academicCourseGradeId, value, id, field, nameField) => {
        onChangeInput(academicCourseGradeId, id, field, nameField, value, "text")
        onChangeInput(academicCourseGradeId, id, "studentName", "Student Name", value, "text")
    }, [])

    const renderSelectStudent = (academicCourseGradeId: number, id, field, nameField, value) => {
        return (
            <KlassappTableContext.Consumer>
                {({setIsShowScrollTable}) => (
                    <div onClick={() => setIsShowScrollTable(false)}>
                        <KlassDropAsyncPaginate
                            stylesCustom={{
                                container: (provided, state) => {
                                    return {
                                        ...provided,
                                        width: 300
                                        // width: "100%"
                                    }
                                }
                            }}
                            // getOptionLabel={formatCodeName}
                            value={value}
                            valueKey="studentProfileId"
                            onChange={(value) => onSelectStudent(academicCourseGradeId, value, id, field, nameField)}
                            labelKey="fullName"
                            loadOptions={searchStudent}
                            placeholder="Select Student Name"
                        />
                    </div>
                )}
            </KlassappTableContext.Consumer>
        )
    }
    const renderSelectStudentId = (academicCourseGradeId: number, id, field, nameField, value) => {
        return (
            <KlassappTableContext.Consumer>
                {({setIsShowScrollTable}) => (
                    <div>
                        <KlassDropAsyncPaginate
                            stylesCustom={{
                                container: (provided, state) => {
                                    return {
                                        ...provided,
                                        width: 150
                                        // width: "100%"
                                    }
                                }
                            }}
                            // getOptionLabel={formatCodeName}
                            value={value}
                            valueKey="fullName"
                            onChange={(value) => onSelectStudentId(academicCourseGradeId, value, id, field, nameField)}
                            labelKey="studentProfileId"
                            loadOptions={searchStudent}
                            placeholder="Student ID"
                        />
                    </div>
                )}
            </KlassappTableContext.Consumer>
        )
    }

    const compareIsChanged = () => {
        const dataCheck = ["maxPointValue", "title", "gradingElementId"]
        for (let j = 0; j < updatedDataSection.length; j++) {
            const oldData = pick(updatedDataSection[j], dataCheck)
            const newData = pick(listSection[j], dataCheck)
            if (!isEqual(oldData, newData)) {
                return true
            }
        }

        const academicCourseGradeIdKeys = Object.keys(initialData)
        const newAcademicCourseGradeIdKeys = Object.keys(updatedDataGradingDetail)
        if (academicCourseGradeIdKeys.length !== newAcademicCourseGradeIdKeys.length) {
            return true
        }
        for (let i = 0; i < newAcademicCourseGradeIdKeys.length; i++) {
            const key = newAcademicCourseGradeIdKeys[i]
            const newArrayCourseGrade = updatedDataGradingDetail[key] ?? []
            const oldArrayCourseGrade = initialData[key] ?? []
            if (newArrayCourseGrade.length !== oldArrayCourseGrade.length) {
                return true
            }
            const dataCheck = ["studentProfileIdValue", "gradeValue", "notesValue"]
            for (let j = 0; j < newArrayCourseGrade.length; j++) {
                const oldData = pick(newArrayCourseGrade[j], dataCheck)
                const newData = pick(oldArrayCourseGrade[j], dataCheck)
                if (!isEqual(oldData, newData)) {
                    return true
                }
            }
        }
        return false
    }

    const renderLessonDetail = (section: SectionLessonGrade, index: number) => {
        const currentGradingElement = dataGradingItems?.find((item) => item.id === section?.gradingElementId)
        return (
            <div className={styles.lessonDetailWrap}>
                <Row gutter={[40, 40]}>
                    <Col span={12}>
                        <div>
                            <FormLabel label="Title" />
                            <BaseInput
                                onChange={(text) => onChangeInputHeader(section.academicCourseGradeId, "title", text)}
                                value={section?.title}
                                placeholder="Title"
                            />
                        </div>
                    </Col>
                    <Col span={6}>
                        <div>
                            <FormLabel label="Type" />
                            <KlassDropdown
                                placeholder="Type"
                                closeMenuOnSelect={true}
                                value={currentGradingElement}
                                onChange={(newValue) => onChangeElementData(section, "gradingElementId", newValue)}
                                options={dataGradingItems}
                                labelKey="gradingItem"
                                valueKey="id"
                                stylesCustom={colourStyles}
                            />
                        </div>
                    </Col>
                    <Col span={6}>
                        <div>
                            <FormLabel label="Max Point Value" />
                            <BaseInput
                                onChange={(text) =>
                                    onChangeInputHeader(section.academicCourseGradeId, "maxPointValue", text)
                                }
                                disabledArrow
                                value={section?.maxPointValue}
                                type="number"
                                placeholder="Max Point Value"
                                min={0}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    const isChanged = compareIsChanged()

    return (
        <div>
            {renderFilter()}
            {renderCards()}
            {loading ? (
                <BaseLoading isShow />
            ) : (
                updatedDataSection.map((section, index) => {
                    return (
                        <div key={index.toString()}>
                            {renderLessonDetail(section, index)}
                            <GradeTable
                                onClickEdit={onClickEdit}
                                setInitialData={setInitialData}
                                termId={termId}
                                lessonDetail={lessonDetail}
                                updateUpdatedData={updateUpdatedData}
                                updateInitialData={updateInitialData}
                                courseInfo={courseInfo}
                                onClickDelete={onClickDelete}
                                updatedDataGradingDetail={updatedDataGradingDetail}
                                gradeTableData={section}
                                searchDebounce={searchDebounce}
                            />
                        </div>
                    )
                })
            )}
            {loading ? null : (
                <PermissionsRequired
                    permissions={{
                        staff: [
                            Permissions.Staff.Settings.General.Courses.Add,
                            Permissions.Staff.Settings.General.Courses.Edit
                        ]
                    }}>
                    <div className={styles.actionWrap}>
                        {isChanged && (
                            <BaseButton
                                title={t("common:action.cancel")}
                                variant="secondary"
                                className={styles.cancelBtn}
                                onClick={onClickCancelBtn}
                            />
                        )}
                        <div>
                            <BaseButton
                                title={isSubmitting ? t("common:action.saving") : t("common:action.save")}
                                onClick={onClickDoneBtn}
                                isActive={isChanged}
                                disabled={!!Object.keys(errors).length}
                                loading={isSubmitting}
                            />
                        </div>
                    </div>
                </PermissionsRequired>
            )}
        </div>
    )
}
