/* eslint-disable react-hooks/exhaustive-deps */
import {FilterList, FieldOperator, FilterKey} from "types/filter"
import {useCallback} from "react"
import {studentServicesService} from "services"
import {useTranslation} from "react-i18next"
import styles from "./SapActions.module.css"
import {BaseLoading, Icon} from "components"
import {useModel} from "hooks"
import {RetentionLevel, SAPCheckpoint, retentionLevelOptions} from "types/student-services/sap"
import {Col, Row} from "antd"
import {useQuery} from "@tanstack/react-query"
import {handleError} from "helpers"

type Props = {
    loadKey: string
    filters?: FilterList
    goToStudentTab: () => void
}

export const sapCheckpointOptions = [
    {id: SAPCheckpoint.GoodStanding, name: "Good Standing (Overall)"},
    {id: SAPCheckpoint.AcademicStanding, name: "Academic Standing"},
    {id: SAPCheckpoint.ProgramCompletion, name: "Program Completion"},
    {id: SAPCheckpoint.CompletionRateOfAttemptedUnits, name: "Completion Rate of Attempted Units"},
    {id: SAPCheckpoint.CumulativeGPA, name: "Cumulative GPA"},
    {id: SAPCheckpoint.GraduationRequirements, name: "Graduation Requirements"}
]

export function SapActions(props: Props) {
    const {loadKey, filters, goToStudentTab} = props
    const {t} = useTranslation(["studentServices"])
    const model = useModel()
    const filterKey = FilterKey.AcademicsPage_Analytics_Students

    const {data, isFetching} = useQuery({
        queryKey: ["student-sap", loadKey],
        enabled: !!loadKey,
        initialData: {
            actionsNeededSAPAtRisk: 0,
            actionsNeededSAPFailed: 0
        },
        queryFn: async () => {
            const data = await studentServicesService.getActionsNeededSAP({filters})
            return data
        },
        onError: (err) => {
            handleError(err)
        }
    })

    const handleSAPClick = useCallback(() => {
        let filters: any = {}
        filters.sapCheckpoints = {
            operator: FieldOperator.Equal,
            value: sapCheckpointOptions
        }
        filters.retentionLevels = {
            operator: FieldOperator.Equal,
            value: retentionLevelOptions.filter((option) => option.id !== RetentionLevel.GoodStanding)
        }

        model.updateStorageFilter(filterKey, {
            filters
        })
        goToStudentTab()
    }, [goToStudentTab])

    return (
        <div className={styles.cardWrap}>
            <div className={styles.titleWrap}>
                <Icon icon="ARCHIVE_FILL" className={styles.iconSize} />
                <p className={styles.cardTitle}>{t("actionsNeededSAP")}</p>
            </div>
            <Row gutter={8} align="middle" className="h-full my-8">
                <Col span={12}>
                    <div className={styles.cardBlock}>
                        <p className={styles.cardValue}>{data.actionsNeededSAPAtRisk}</p>
                        <p className={styles.cardSublabel}>{t("actionsNeededSAPAtRisk")}</p>
                    </div>
                </Col>
                <Col span={12}>
                    <div className={styles.cardBlock}>
                        <p className={styles.cardValue}>{data.actionsNeededSAPFailed}</p>
                        <p className={styles.cardSublabel}>{t("actionsNeededSAPFailed")}</p>
                    </div>
                </Col>
            </Row>
            <div className={styles.buttonWrapper} onClick={handleSAPClick}>
                <span className={styles.buttonLabel}>{t("viewAll")}</span>
                <Icon icon="ARROW_RIGHT_CIRCLE" />
            </div>
            <BaseLoading isShow={isFetching} />
        </div>
    )
}
