import styles from "./CareerServicesReadinessChecklist.module.css"
import {ListReadinessChecklist} from "./parts"

export const CareerServicesReadinessChecklist = () => {
    return (
        <div className={styles.checkListWrap}>
            <div className={styles.title}>Readiness Checklist</div>
            <ListReadinessChecklist />
        </div>
    )
}
