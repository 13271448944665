/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useMemo, useState} from "react"
import {useTranslation} from "react-i18next"
import {useLocation} from "react-router-dom"
import {Checkbox, Col, Divider, Radio, Row} from "antd"
import debounce from "debounce-promise"
import {KlassDropdown, KlassDropdownAsync} from "components/Select"
import {BaseDatePicker, BaseTimePicker} from "components/DateTimePicker"
import {Icon} from "components/Icon"
import {
    BaseService,
    calendarService,
    departmentsService,
    linksService,
    settingActivityService,
    studentActivitiesService,
    studentService,
    studentStaffContactRolesServiceV3,
    userService,
    workflowService
} from "services"
import {getFormattedWorkflowName, ProcessWorkflowStepNodeData, Workflow, WorkflowType} from "types/workflow"
import styles from "./ActivityForm.module.css"
import moment, {Moment} from "moment"
import momentTz from "moment-timezone"
import {Descendant} from "slate"
import {replaceFieldValues} from "components/RichTextEditor/slate-custom-types"
import RichTextEditor from "components/RichTextEditor/RichTextEditor"
import {BaseButton, BaseInput, BaseLoading, BaseTextArea} from "components"
import {useModel, useVisible} from "hooks"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {FormLabel} from "components/Form"
import {ContactRole, UserAutocomplete} from "types/user"
import {handleError, toastError, toastSuccess} from "helpers"
import {Auth} from "types/auth"
import DepartmentSubunitSelect from "components/DepartmentSubunitSelect"
import {DepartmentSubunit} from "types/departments"
import {StudentActivity} from "types/activity"
import {Task} from "types/tasks"
import {UserDocument} from "types/user-documents"
import LinksSection from "../LinksSection"
import UserSelect from "components/UserSelect"
import {ActivityCalendarEventPopup} from "./ActivityCalendarEventPopup"
import ActionRowItem from "uiKit/table/parts/ActionRowItem"
import {CreateEventPayload} from "types/calendar"
import {createRruleFromEvent} from "helpers/calendar"
import {UserOptionLabel} from "components/UserSelect/UserSelect"
import {Model} from "Model"
import StudentActivityLogsTable from "./StudentActivityLogsTable"
import {DATE_UNIT_CUSTOM_OPTIONS, DATE_UNIT_OPTIONS, DisplayMode} from "types/common"
import {pick, uniq} from "lodash"
import {NodeType} from "components/FlowChart/FlowChart.constants"
import {Node} from "reactflow"
import {DUE_COLOR_OPTIONS} from "types/contact-strategy"

const DEFAULT_ACTIVITY = {
    description: "",
    addStudentCalendar: false,
    visibleForStudent: false,
    completed: false,
    dueDate: null,
    activityDate: null,
    isFollowUp: false
}

const initForm = (model: Model) => {
    const currentProfileId = model.profileId
    const currentProfile = model.user.profiles.find((profile) => profile.id === currentProfileId)
    const form: Record<string, any> = {
        activities: [],
        activityDate: moment(),
        activity: null,
        student: null,
        departmentSubunit: null,
        linkedTasks: [],
        linkedChecklists: [],
        linkedDocuments: []
    }
    if (currentProfile) {
        form.assignedProfiles = [
            {
                userId: currentProfile.userId,
                profileId: currentProfile.id,
                customUserId: model.user.customUserId,
                firstName: model.user.firstName,
                lastName: model.user.lastName
            }
        ]
        form.assignedProfileIds = [currentProfile.id]
    }
    return form
}

type Props = {
    departmentId?: number
    studentId?: number
    formEditData?: Partial<StudentActivity>
    onDone?: (activity: StudentActivity) => void
    isCompactForm?: boolean
    displayMode?: DisplayMode
}

const ActivityForm: React.FC<Props> = ({
    departmentId,
    studentId,
    formEditData,
    onDone,
    isCompactForm = false,
    displayMode = "normal"
}) => {
    const {t} = useTranslation(["common", "workflow"])
    const location: any = useLocation()
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const userTimeFormat = model.getUserTimeFormat()
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const [isLoading, setLoading] = React.useState(false)
    const [isSaving, setSaving] = React.useState(false)
    const [form, setForm] = React.useState<Record<string, any>>(initForm(model))
    const [activity, setActivity] = React.useState<Partial<StudentActivity>>(DEFAULT_ACTIVITY)
    const [studentValues, setStudentValues] = React.useState<Record<string, any>>({})
    const [studentStatuses, setStudentStatuses] = React.useState<Auth.StudentDepartmentStatusDetails[]>([])
    const [staffRoles, setStaffRoles] = React.useState<ContactRole[]>([])
    const [workflows, setWorkflows] = React.useState<Workflow[]>([])
    const [selectedWorkflow, setSelectedWorkflow] = React.useState<Workflow | null>(null)
    const [initDepartmentId, setInitDepartmentId] = useState(departmentId)
    const [initStudentId, setInitStudentId] = useState(studentId)
    const [errors, setErrors] = useState({})
    const isFullDisplay = displayMode === "normal"

    const canEditActivity = model.isStaffOrAdmin()

    useEffect(() => {
        if (location?.state) {
            if (location.state?.departmentId) {
                setInitDepartmentId(location.state.departmentId)
            }
            if (location.state?.studentId) {
                setInitStudentId(location.state.studentId)
            }
        }
    }, [location])

    useEffect(() => setInitStudentId(studentId), [studentId])

    const handleActivityChange = React.useCallback(
        (values: {[key: string]: any}) => {
            setActivity((prev) => ({...prev, ...values}))
        },
        [setActivity]
    )

    React.useEffect(() => {
        ;(async function loadWorkflows() {
            if (form.triggeredWorkflow) {
                setWorkflows([form.triggeredWorkflow?.workflow])
                setSelectedWorkflow(form.triggeredWorkflow?.workflow)
                return
            }

            if (!form.departmentSubunit?.subunitId) {
                setWorkflows([])
                setSelectedWorkflow(null)
                return
            }

            try {
                const {data: workflows} = await workflowService.getAllWorkflows({
                    filter: {
                        type: WorkflowType.Process,
                        isActive: true,
                        departmentSubunitIds: [form.departmentSubunit?.subunitId]
                    },
                    range: {offset: 0, limit: UNLIMITED_PAGE_SIZE}
                })
                setWorkflows(workflows)
                setSelectedWorkflow(
                    workflows.find((workflow) => workflow.id === form.triggeredWorkflow?.workflowId) ?? null
                )
            } catch {
                setWorkflows([])
            }
        })()
    }, [form.departmentSubunit, form.triggeredWorkflow])

    useEffect(() => {
        if (form.departmentSubunit && canEditActivity) {
            ;(async function loadActivities() {
                try {
                    let moduleDepartmentId
                    const departmentId = form.departmentSubunit?.departmentId
                    if (departmentId) {
                        const moduleDepartment = await departmentsService.getOne(departmentId)
                        moduleDepartmentId = moduleDepartment?.useActivitiesFromDepartmentId
                    }
                    const {data: activities} = await settingActivityService.getAllActivities({
                        filter: {
                            departmentIds: moduleDepartmentId ? [moduleDepartmentId] : undefined,
                            isActive: true
                        },
                        range: {
                            page: 1,
                            pageSize: UNLIMITED_PAGE_SIZE
                        }
                    })
                    setForm((prev) => ({
                        ...prev,
                        activities: activities.map((item) => ({...item, id: item.activityId}))
                    }))
                } catch (error) {
                    handleError(error)
                }
            })()
        }
    }, [form.departmentSubunit?.departmentId])

    const studentDepartmentStatusId = React.useMemo(
        () => studentStatuses.find((status) => status.departmentId === form.departmentSubunit?.departmentId)?.statusId,
        [form.departmentSubunit?.departmentId, studentStatuses]
    )

    const activityTypes = React.useMemo(
        () =>
            form.activities.filter((activity) => {
                if (model.clientSetting.isNewStudentStatusesVisible) {
                    return (
                        !activity.studentStatuses?.length ||
                        activity.studentStatuses.find((status) => status.statusId === studentValues.statusId)
                    )
                }
                return (
                    !activity.statuses?.length ||
                    activity.statuses.find((status) => status.statusId === studentDepartmentStatusId)
                )
            }),
        [form.activities, studentDepartmentStatusId, studentValues.statusId]
    )

    useEffect(() => {
        ;(async function loadForm() {
            try {
                setLoading(true)

                if (!formEditData) {
                    setActivity({...DEFAULT_ACTIVITY})
                    setForm((prev) => ({
                        ...initForm(model),
                        ...pick(prev, ["departmentSubunit", "activities"])
                    }))
                    setSelectedWorkflow(null)
                    return
                }

                const newForm = {
                    ...form,
                    ...formEditData,
                    linkedChecklists: [],
                    linkedDocuments: [],
                    linkedTasks: []
                }

                if (formEditData.studentActivityId) {
                    try {
                        const [link] = await linksService.getStudentActivityLinks([formEditData.studentActivityId])
                        if (link) {
                            newForm.linkedTasks = link.tasks.filter(Boolean)
                            newForm.linkedDocuments = link.userDocuments.filter(Boolean)
                            newForm.linkedChecklists = link.studentChecklistItems.filter(Boolean)
                        }
                    } catch {}
                }

                if (newForm.calendarEventIds?.length) {
                    try {
                        const {data: events} = await calendarService.getAll({
                            filter: {
                                object_ids: newForm.calendarEventIds
                            },
                            pagination: {
                                page: 1,
                                pageSize: UNLIMITED_PAGE_SIZE
                            },
                            sort: {
                                orderBy: "start_at_utc",
                                order: "ASC"
                            }
                        })
                        setCalendarEvents(events)
                    } catch {}
                }

                const newActivity: Partial<StudentActivity> = {
                    ...DEFAULT_ACTIVITY,
                    description: formEditData.description,
                    addStudentCalendar: !!formEditData.addStudentCalendar,
                    visibleForStudent: !!formEditData.visibleForStudent,
                    completed: !!formEditData.completed,
                    studentDue: formEditData.studentDue,
                    dueDate: formEditData.dueDate ? moment(formEditData.dueDate).toISOString() : undefined,
                    activityDate: formEditData.activityDate
                        ? moment(formEditData.activityDate).toISOString()
                        : undefined,
                    assignedRole: !!formEditData.assignedRole,
                    assignedRoleIds: formEditData.assignedRoleIds || [],
                    assignedProfileIds: formEditData.assignedProfileIds || [],
                    assignedProfiles: formEditData.assignedProfiles || [],
                    notifyStaffRole: !!formEditData.notifyStaffRole,
                    notifyStaffRoleIds: formEditData.notifyStaffRoleIds || [],
                    notifyStaffProfileIds: formEditData.notifyStaffProfileIds || [],
                    notifyStaffProfiles: formEditData.notifyStaffProfiles || [],
                    template: formEditData.triggeredWorkflow
                        ? JSON.parse(formEditData.triggeredWorkflow?.template)
                        : undefined,
                    workflowNodeId: formEditData.workflowNodeId,
                    flow: formEditData.triggeredWorkflow?.workflow?.flow
                        ? JSON.parse(formEditData.triggeredWorkflow.workflow.flow)
                        : undefined
                }
                setActivity(newActivity)
                setForm(newForm)
            } catch {
            } finally {
                setLoading(false)
            }
        })()
    }, [formEditData, initStudentId])

    React.useEffect(() => {
        ;(async function loadStudent() {
            if (!initStudentId) return
            const {data} = await userService.searchUsers({
                filters: {
                    isActive: [true, false],
                    type: Auth.UserProfileType.Student,
                    profileIds: [initStudentId]
                }
            })
            const initialStudent = data.find(({profileId}) => profileId === initStudentId)
            if (initialStudent) {
                setForm((prev) => ({...prev, student: initialStudent}))
            }
        })()
    }, [initStudentId])

    React.useEffect(() => {
        ;(async function getStudentValues() {
            if (!form.student?.profileId) {
                setStudentValues({})
                return
            }
            const [studentValues] = await userService.getStudentData({
                filter: {
                    profileId: form.student.profileId
                }
            })
            setStudentValues(studentValues || {})
        })()
        ;(async function getStudentStatuses() {
            if (!form.student?.profileId) {
                setStudentStatuses([])
                return
            }
            const {data: statuses} = await studentService.getStudentStatuses(form.student.profileId)
            setStudentStatuses(statuses)
        })()
    }, [form.student?.profileId])

    React.useEffect(() => {
        ;(async function loadStaffRoles() {
            studentStaffContactRolesServiceV3.getAll().then(({data: roles}) => {
                setStaffRoles(roles.map((role) => ({...role, id: role.studentStaffContactRoleId})))
            })
        })()
    }, [])

    const handleWorkflowChange = React.useCallback(
        (workflow?: Workflow) => {
            setSelectedWorkflow(workflow ?? null)
            if (workflow?.template) {
                const template = replaceFieldValues(JSON.parse(workflow.template), studentValues)
                handleActivityChange({workflowId: workflow?.id, template})
            } else {
                handleActivityChange({workflowId: undefined, template: undefined})
            }
        },
        [handleActivityChange, studentValues]
    )

    const handleTemplateChange = React.useCallback(
        (template: Descendant[]) => {
            handleActivityChange({template})
        },
        [handleActivityChange]
    )

    const debounceSearchStaffs = useCallback(
        debounce(async (search = "") => {
            const {data} = await userService.searchUsers({
                filters: {
                    type: Auth.UserProfileType.Staff,
                    search
                },
                range: {
                    page: 1,
                    pageSize: 50
                }
            })
            return data
        }, 500),
        []
    )

    const debounceSearchStudents = useCallback(
        debounce(async (search = "") => {
            const {data} = await userService.searchUsers({
                filters: {
                    type: Auth.UserProfileType.Student,
                    search
                },
                range: {
                    page: 1,
                    pageSize: 50
                }
            })
            return data
        }, 500),
        []
    )

    function getActivity({...activity}) {
        if (activity.completed) {
            activity.completedBy = BaseService.profileId
            activity.completedDate = new Date()
        }

        if (!activity.dueDate) {
            delete activity.dueDate
        }

        delete activity.studentActivityId
        delete activity.profileId
        delete activity.isFollowUp
        delete activity.createdAt
        delete activity.createdBy
        delete activity.updatedAt
        delete activity.deletedAt
        delete activity.triggeredWorkflowId
        delete activity.triggeredWorkflow
        delete activity.workflowNodeId
        delete activity.initialActivityId
        // delete activity.assignedProfileIds
        delete activity.assignedProfiles
        delete activity.notifyStaffProfiles
        delete activity.flow

        return {
            ...activity,
            profileId: form.student.profileId,
            activityId: form.activity.id,
            createdBy: BaseService.profileId,
            departmentSubunitId: form.departmentSubunit?.subunitId,
            activityDate: form.activityDate,
            // assignedProfileIds: form.assignedProfileIds,
            template: JSON.stringify(activity.template)
        }
    }

    async function saveLinks(studentActivityId: number) {
        return await linksService.saveStudentActivityLink(studentActivityId, {
            userDocumentIds: form.linkedDocuments?.map((item) => item.userDocumentId),
            studentChecklistItemIds: form.linkedChecklists?.map((item) => item.studentChecklistItemId),
            taskIds: form.linkedTasks?.map((item) => item.taskId)
        })
    }

    async function saveCalendarEvents(studentActivity: Partial<StudentActivity>) {
        const deletedIds =
            form.calendarEventIds?.filter((id) => !calendarEvents.find((eventData) => eventData.object_id === id)) ?? []
        deletedIds.map((id) => model.removeEvent(id))

        if (!calendarEvents.length) return []

        return await Promise.all(
            calendarEvents.map(async (eventData) => {
                let {
                    object_id,
                    name,
                    allDay,
                    description,
                    eventType,
                    startDate,
                    startTime,
                    endTime,
                    group,
                    reminders,
                    withSMS,
                    eventSignInRequired,
                    attendedChangeToStatus,
                    notAttendedChangeToStatus,
                    completionWorkflow,
                    hostUsers,
                    invitedUsers
                } = eventData
                if (!object_id?.startsWith?.("NEW_")) return eventData

                const ownerUserId = +model.user.id
                const invitedUserIds: number[] = uniq((invitedUsers ?? []).map((user) => user.userId))
                const hostUserIds: number[] = uniq((hostUsers ?? []).map((user) => user.userId))
                const {data: primaryCalendar} = await calendarService.getMyPrimaryCalendar({
                    owner_user_id: ownerUserId
                })

                const rrule = createRruleFromEvent(eventData)
                const startTimeUpdated = `${moment(startDate).format("YYYY-MM-DD")} ${moment(startTime).format(
                    "HH:mm:ss"
                )}`
                const endTimeUpdated = `${moment(startDate).format("YYYY-MM-DD")} ${moment(endTime).format("HH:mm:ss")}`
                const duration = allDay ? 3600 * 24 : moment(endTimeUpdated).diff(moment(startTimeUpdated), "seconds")
                const payload: CreateEventPayload = {
                    calendar_id: primaryCalendar?.calendar_id,
                    type: "event",
                    name,
                    description: description || studentActivity.description || "",
                    duration,
                    owner_user_id: ownerUserId,
                    rrule,
                    start_at_timezone: momentTz.tz.guess(),
                    start_at_wall: allDay
                        ? moment(startDate).startOf("day").format("YYYY-MM-DD HH:mm:ss")
                        : `${moment(startDate).format("YYYY-MM-DD")} ${moment(startTime).format("HH:mm:ss")}`,
                    json_data: {
                        eventType: `${eventType.name} (${eventType.code})`,
                        eventTypeId: eventType.eventTypeId,
                        color: eventType.label,
                        allDay,
                        notifications: reminders.map((reminder) => reminder.value),
                        with_sms: withSMS,
                        eventSignInRequired,
                        attendedChangeToStatus,
                        notAttendedChangeToStatus,
                        completionWorkflow
                    }
                }
                if (group) {
                    payload.edular_group_id = group.groupId
                }
                if (invitedUserIds.length) {
                    payload.invited_user_ids = invitedUserIds
                }
                if (hostUserIds.length) {
                    payload.host_user_ids = hostUserIds
                }
                const [createdCalendarEvent] = await model.addEvent(payload)
                return createdCalendarEvent
            })
        )
    }

    const validateBeforeSubmit = () => {
        if (!form.student) {
            setErrors({student: "Required"})
            toastError(t("common:validation.cantEmpty", {field: "Student"}))
            return true
        }
        if (!form.departmentSubunit) {
            setErrors({departmentSubunit: "Required"})
            toastError(t("common:validation.cantEmpty", {field: "Department"}))
            return true
        }
        if (!form.activityDate) {
            setErrors({activityDate: "Required"})
            toastError(t("common:validation.cantEmpty", {field: "Activity Date"}))
            return true
        }
        if (!form.activity) {
            setErrors({activity: "Required"})
            toastError(t("common:validation.cantEmpty", {field: "Activity Type"}))
            return true
        }
        return false
    }

    async function createOrEditActivity() {
        if (validateBeforeSubmit()) {
            return
        }
        try {
            setSaving(true)
            const activityData: Omit<Partial<StudentActivity>, "template"> = getActivity(activity)
            const events = await saveCalendarEvents(activityData)
            activityData.calendarEventIds = events.map((event) => event?.object_id).filter(Boolean)
            let result: StudentActivity

            if (!form.studentActivityId) {
                ;[result] = await studentActivitiesService.createActivities([activityData])
                await saveLinks(result.studentActivityId)
                toastSuccess(t("activityPopup.createSuccess"))
            } else {
                const payload = {...activityData, studentActivityId: form.studentActivityId}
                delete payload.createdBy
                delete payload.profileId
                result = await studentActivitiesService.editActivity(payload)
                await saveLinks(form.studentActivityId)
                toastSuccess(t("activityPopup.updateSuccess"))
            }
            setActivity({...DEFAULT_ACTIVITY})
            onDone?.(result)
        } catch (error) {
            handleError(error)
            toastError(t("activityPopup.error"))
        } finally {
            setSaving(false)
        }
    }

    const eventPopup = useVisible(false)
    const [calendarEvents, setCalendarEvents] = useState<any[]>([])
    const [calendarEventToEdit, setCalendarEventToEdit] = useState<any | undefined>()
    const handleCreateCalendarEventClick = useCallback(() => {
        const currentProfileId = model.profileId
        const currentProfile = model.user.profiles.find((profile) => profile.id === currentProfileId)
        const profile = {
            profileId: model.profileId,
            userId: model.user.id,
            customUserId: model.user.customUserId,
            firstName: model.user.firstName,
            lastName: model.user.lastName,
            type: currentProfile?.type,
            state: currentProfile?.state,
            customProfileId: currentProfile?.customProfileId
        }
        setCalendarEventToEdit({
            hostUsers: [profile],
            invitedUsers: [form.student]
        })
        eventPopup.open()
    }, [model.profileId, form.student])
    const handleSaveCalendarEvent = useCallback((event) => {
        setCalendarEvents((events) => [...events.filter(({object_id}) => object_id !== event.id), event])
        eventPopup.close()
    }, [])

    useCallback((event) => {
        setCalendarEventToEdit(event)
        eventPopup.open()
    }, [])

    const handleDeleteCalendarEvent = useCallback((event) => {
        setCalendarEvents((events) => events.filter(({object_id}) => object_id !== event.object_id))
    }, [])

    const workflowSteps = useMemo(() => {
        if (!activity.workflowNodeId || !activity.flow) return []
        const {nodes, edges} = activity.flow
        const targetNodeIds = edges.filter((edge) => edge.source === activity.workflowNodeId).map((edge) => edge.target)
        const nextStepNodes = nodes.filter(
            (node) => targetNodeIds.includes(node.id) && node.type === NodeType.Step
        ) as Node<ProcessWorkflowStepNodeData>[]
        return nextStepNodes.map((node) => ({
            id: node.id,
            name: node.data.name
        }))
    }, [activity.workflowNodeId, activity.flow])

    useEffect(() => {
        if (form.triggeredWorkflow && activity.completed && workflowSteps.length && !activity.nextStepNodeId) {
            handleActivityChange({nextStepNodeId: workflowSteps[0].id})
        }
    }, [workflowSteps, activity.nextStepNodeId])

    const renderColorOption = React.useCallback(
        (option) => (
            <div className={styles.colorContainer}>
                <div className={styles.colorCode} style={{backgroundColor: option.id}} />
                <div className={styles.colorLabel}>{option.name}</div>
            </div>
        ),
        []
    )

    return (
        <div>
            <BaseLoading isShow={isLoading} />

            <Row gutter={[24, 16]} align="bottom">
                <Col span={isFullDisplay ? 16 : 24}>
                    <FormLabel label={t("activityPopup.student")} isRequired />
                    <KlassDropdownAsync
                        readOnly={!!form.studentActivityId || !!initStudentId}
                        isMulti={false}
                        isClearable={false}
                        valueKey="profileId"
                        labelKey="name"
                        getOptionLabel={UserOptionLabel}
                        loadOptions={debounceSearchStudents}
                        value={form.student}
                        onChange={(option: UserAutocomplete) => {
                            setForm((prev) => ({...prev, student: option}))
                        }}
                        error={!!errors["student"]}
                    />
                </Col>

                <Col span={isFullDisplay ? 8 : 24}>
                    <Checkbox
                        disabled={!canEditActivity}
                        checked={activity.visibleForStudent}
                        onChange={({target: {checked}}) => handleActivityChange({visibleForStudent: checked})}>
                        {t("activityPopup.studentVisible")}
                    </Checkbox>
                </Col>

                <Col span={isFullDisplay ? 12 : 24}>
                    <FormLabel label={t("activityPopup.department")} isRequired />
                    <DepartmentSubunitSelect
                        readOnly={!canEditActivity}
                        departmentIds={initDepartmentId ? [initDepartmentId] : undefined}
                        isMulti={false}
                        isClearable={false}
                        value={form.departmentSubunit}
                        onChange={(departmentSubunit?: DepartmentSubunit) => {
                            setForm((prev) => ({...prev, departmentSubunit}))
                        }}
                        setFirstAsPreSelected={true}
                        error={!!errors["departmentSubunit"]}
                    />
                </Col>

                <Col span={isFullDisplay ? 12 : 24}>
                    <FormLabel label={t("activityPopup.activityDate")} isRequired />
                    <BaseDatePicker
                        readOnly={!canEditActivity}
                        format={userDateTimeFormat}
                        showTime={{format: userTimeFormat}}
                        value={form.activityDate ? moment(form.activityDate) : undefined}
                        onChange={(activityDate) => {
                            setForm((prev) => ({...prev, activityDate}))
                        }}
                    />
                </Col>

                <Col span={24}>
                    <FormLabel label={t("activityPopup.activityType")} isRequired />
                    <KlassDropdown
                        readOnly={!canEditActivity}
                        options={activityTypes}
                        value={form.activity}
                        onChange={(activity) => {
                            setForm((prev) => ({...prev, activity}))
                        }}
                        placeholder="Select"
                        error={!!errors["activity"]}
                    />
                </Col>

                <Col span={24}>
                    <FormLabel label={t("activityPopup.descriptionActivity")} />
                    <BaseTextArea
                        readOnly={!canEditActivity}
                        onChange={(description) => handleActivityChange({description})}
                        value={activity.description}
                        placeholder="Enter your message"
                        className="resize-vertical"
                        rows={2}
                    />
                </Col>

                <Col span={24}>
                    <FormLabel label={t("activityPopup.initiateWorkflow")} />
                    <KlassDropdown
                        readOnly={!canEditActivity || !!formEditData?.studentActivityId}
                        isClearable={true}
                        placeholder="Select"
                        options={workflows}
                        valueKey="id"
                        labelKey="name"
                        getOptionLabel={getFormattedWorkflowName}
                        value={selectedWorkflow}
                        onChange={handleWorkflowChange}
                    />
                </Col>

                {!!form.triggeredWorkflowId && !!form.initialActivityId && (
                    <Col span={24}>
                        <FormLabel label={t("activityPopup.staffs")} />
                        <KlassDropdownAsync
                            readOnly={
                                !model.isAdmin() &&
                                !form.departmentSubunit?.department?.directorProfileIds?.includes(BaseService.profileId)
                            }
                            isMulti={true}
                            valueKey="profileId"
                            labelKey="name"
                            getOptionLabel={UserOptionLabel}
                            loadOptions={debounceSearchStaffs}
                            value={form.assignedProfiles ?? []}
                            onChange={(options?: UserAutocomplete[]) => {
                                setForm((prev) => ({
                                    ...prev,
                                    assignedProfileIds: options?.map((option) => option.profileId),
                                    assignedProfiles: options ?? []
                                }))
                            }}
                        />
                    </Col>
                )}

                {!!activity.template && (
                    <Col span={24}>
                        <FormLabel label={t("activityPopup.template")} />
                        <RichTextEditor
                            value={activity.template}
                            onChange={handleTemplateChange}
                            readOnly={true}
                            userDateFormat={userDateFormat}
                            userTimeFormat={userTimeFormat}
                        />
                    </Col>
                )}
            </Row>

            {(!form.triggeredWorkflowId || !form.initialActivityId) && (
                <>
                    <Divider className={styles.divider} />

                    <h4 className={styles.sectionTitle}>{t("activityPopup.assignedTo")}</h4>

                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <Radio.Group
                                value={!!activity.assignedRole}
                                onChange={(event) => handleActivityChange({assignedRole: event.target.value})}>
                                <Radio disabled={!canEditActivity} value={false}>
                                    {t("activityPopup.specificPeople")}
                                </Radio>
                                <Radio disabled={!canEditActivity} value={true}>
                                    {t("activityPopup.studentRelationship")}
                                </Radio>
                            </Radio.Group>
                        </Col>

                        {!!activity.assignedRole ? (
                            <Col span={24}>
                                <KlassDropdown
                                    placeholder={t("activityPopup.studentRelationship")}
                                    valueKey="id"
                                    labelKey="name"
                                    options={staffRoles}
                                    isMulti={true}
                                    value={staffRoles.filter((role) => activity.assignedRoleIds?.includes(role.id))}
                                    onChange={(options?: ContactRole[]) =>
                                        handleActivityChange({
                                            assignedRoleIds: (options ?? [])?.map((option) => option.id)
                                        })
                                    }
                                    readOnly={!canEditActivity}
                                />
                            </Col>
                        ) : (
                            <Col span={24}>
                                <UserSelect
                                    isMulti={true}
                                    type={Auth.UserProfileType.Staff}
                                    value={activity.assignedProfiles}
                                    onChange={(options?: UserAutocomplete[]) => {
                                        handleActivityChange({
                                            assignedProfileIds: (options ?? [])?.map((option) => option.profileId),
                                            assignedProfiles: options ?? []
                                        })
                                    }}
                                    readOnly={!canEditActivity}
                                />
                            </Col>
                        )}
                    </Row>
                </>
            )}

            <Divider className={styles.divider} />

            <h4 className={styles.sectionTitle}>{t("activityPopup.calendarEvent")}</h4>

            <Row gutter={[24, 24]}>
                {calendarEvents.map((eventData) => (
                    <Col span={24} key={eventData.object_id}>
                        <Row gutter={24} align="middle">
                            <Col flex={1}>
                                <BaseInput value={eventData.name} readOnly />
                            </Col>
                            <Col>
                                <ActionRowItem
                                    data={eventData}
                                    menuActions={[
                                        {
                                            title: t("common:action.remove"),
                                            icon: "DELETE",
                                            action: handleDeleteCalendarEvent
                                        }
                                    ]}
                                />
                            </Col>
                        </Row>
                    </Col>
                ))}
                <Col span={24}>
                    <BaseButton
                        title={t("common:create")}
                        variant="secondary"
                        onClick={handleCreateCalendarEventClick}
                        disabled={!canEditActivity}
                    />
                </Col>
            </Row>

            {eventPopup.isVisible && (
                <ActivityCalendarEventPopup
                    isShow={eventPopup.isVisible}
                    onClose={eventPopup.close}
                    initialData={calendarEventToEdit}
                    onSave={handleSaveCalendarEvent}
                />
            )}

            {!isCompactForm && (
                <>
                    {/*
                    <Row gutter={[24, 24]}>
                        <Col span={12}>
                            <div className={styles.formBody}>
                                <FormLabel label={t("activityPopup.dueDate")} />
                                <BaseDatePicker
                                    format={userDateTimeFormat}
                                    showTime={{format: userTimeFormat}}
                                    onChange={(dueDate) => handleActivityChange({dueDate})}
                                    value={activity.dueDate ? moment(activity.dueDate) : undefined}
                                />
                            </div>
                        </Col>
                    </Row>
*/}
                    <Divider className={styles.divider} />

                    <h4 className={styles.sectionTitle}>{t("activityPopup.notifyAdditionalPeople")}</h4>

                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <Radio.Group
                                value={!!activity.notifyStaffRole}
                                onChange={(event) => handleActivityChange({notifyStaffRole: event.target.value})}>
                                <Radio disabled={!canEditActivity} value={false}>
                                    {t("activityPopup.specificPeople")}
                                </Radio>
                                <Radio disabled={!canEditActivity} value={true}>
                                    {t("activityPopup.studentRelationship")}
                                </Radio>
                            </Radio.Group>
                        </Col>

                        {!!activity.notifyStaffRole ? (
                            <Col span={24}>
                                <KlassDropdown
                                    placeholder={t("activityPopup.studentRelationship")}
                                    valueKey="id"
                                    labelKey="name"
                                    options={staffRoles}
                                    isMulti={true}
                                    value={staffRoles.filter((role) => activity.notifyStaffRoleIds?.includes(role.id))}
                                    onChange={(options?: ContactRole[]) =>
                                        handleActivityChange({
                                            notifyStaffRoleIds: (options ?? [])?.map((option) => option.id)
                                        })
                                    }
                                    readOnly={!canEditActivity}
                                />
                            </Col>
                        ) : (
                            <Col span={24}>
                                <UserSelect
                                    isMulti={true}
                                    type={Auth.UserProfileType.Staff}
                                    value={activity.notifyStaffProfiles}
                                    onChange={(options?: UserAutocomplete[]) => {
                                        handleActivityChange({
                                            notifyStaffProfileIds: (options ?? [])?.map((option) => option.profileId),
                                            notifyStaffProfiles: options ?? []
                                        })
                                    }}
                                    readOnly={!canEditActivity}
                                />
                            </Col>
                        )}
                    </Row>

                    <Divider className={styles.divider} />

                    <LinksSection
                        profileIds={[form.student?.profileId]}
                        tasks={form.linkedTasks}
                        onTasksChange={(linkedTasks: Task[]) => {
                            setForm((prev) => ({...prev, linkedTasks}))
                        }}
                        checklists={form.linkedChecklists}
                        onChecklistsChange={(linkedChecklists: any[]) => {
                            setForm((prev) => ({...prev, linkedChecklists}))
                        }}
                        documents={form.linkedDocuments}
                        onDocumentsChange={(linkedDocuments: UserDocument[]) => {
                            setForm((prev) => ({...prev, linkedDocuments}))
                        }}
                        displayMode={displayMode}
                        canEditLinks={canEditActivity}
                    />
                </>
            )}

            <Divider className={styles.divider} />

            <div className={styles.checkboxWrapper}>
                <div
                    className={styles.iconCompleted}
                    onClick={() =>
                        canEditActivity ? handleActivityChange({completed: !activity.completed}) : () => {}
                    }>
                    <Icon
                        icon="CHECKMARK_CIRCLE"
                        color={activity.completed ? "var(--success-400-base)" : "var(--black-200)"}
                    />
                </div>
                <span>{t("activityPopup.completedActivity")}</span>
            </div>

            {!!form.triggeredWorkflow && !!activity.completed && !!workflowSteps.length && (
                <>
                    <FormLabel label={t("activityPopup.selectNextWorkflowStep")} isRequired />
                    <KlassDropdown
                        isClearable={false}
                        options={workflowSteps}
                        valueKey="id"
                        labelKey="name"
                        value={workflowSteps.find((step) => step.id === activity.nextStepNodeId)}
                        onChange={(option) => handleActivityChange({nextStepNodeId: option.id})}
                        readOnly={!canEditActivity}
                    />
                </>
            )}

            <Divider className={styles.divider} />

            <Row gutter={[24, 16]} align="middle">
                <Col span={isFullDisplay ? undefined : 11}>
                    <Checkbox
                        disabled={!canEditActivity}
                        checked={!!activity.studentDue}
                        onChange={(event) =>
                            handleActivityChange({
                                studentDue: event.target.checked
                                    ? {
                                          unit: DATE_UNIT_OPTIONS[0].value,
                                          color: DUE_COLOR_OPTIONS[0].id
                                      }
                                    : null
                            })
                        }>
                        Set due alert in:
                    </Checkbox>
                </Col>

                {!!activity.studentDue && (
                    <>
                        <Col span={isFullDisplay ? 4 : 8}>
                            <KlassDropdown
                                options={DATE_UNIT_CUSTOM_OPTIONS}
                                valueKey="value"
                                labelKey="label"
                                value={DATE_UNIT_CUSTOM_OPTIONS.find(
                                    (unit) => unit.value === activity.studentDue?.unit
                                )}
                                onChange={(option) =>
                                    handleActivityChange({
                                        studentDue: {
                                            ...activity.studentDue,
                                            unit: option.value,
                                            time: undefined
                                        }
                                    })
                                }
                                readOnly={!canEditActivity}
                            />
                        </Col>
                        {activity.studentDue?.unit !== "custom" && (
                            <Col span={isFullDisplay ? 3 : 5}>
                                <BaseInput
                                    value={activity.studentDue?.value}
                                    onChange={(value) =>
                                        handleActivityChange({
                                            studentDue: {
                                                ...activity.studentDue,
                                                value: value != null ? +value : undefined
                                            }
                                        })
                                    }
                                    type="number"
                                    min={0}
                                    placeholder="Value"
                                    disabledArrow={true}
                                    readOnly={!canEditActivity}
                                />
                            </Col>
                        )}
                        {activity.studentDue?.unit === "custom" && (
                            <Col span={isFullDisplay ? 6 : 24}>
                                <BaseDatePicker
                                    allowClear={false}
                                    value={activity.studentDue?.time ? moment(activity.studentDue.time) : undefined}
                                    placeholder="Date/Time"
                                    placement={isFullDisplay ? "bottomLeft" : "topRight"}
                                    format={userDateTimeFormat}
                                    showTime={{format: userTimeFormat}}
                                    onChange={(value?: Moment) =>
                                        handleActivityChange({
                                            studentDue: {...activity.studentDue, time: value?.toISOString()}
                                        })
                                    }
                                    readOnly={!canEditActivity}
                                />
                            </Col>
                        )}
                        {["days", "weeks", "months", "years"].includes(activity.studentDue?.unit) && (
                            <Col span={isFullDisplay ? 6 : 24}>
                                <BaseTimePicker
                                    allowClear={false}
                                    value={activity.studentDue?.time ? moment(activity.studentDue.time) : undefined}
                                    placeholder="Time"
                                    placement={isFullDisplay ? "bottomLeft" : "topLeft"}
                                    format={userTimeFormat}
                                    onChange={(value?: Moment) =>
                                        handleActivityChange({
                                            studentDue: {...activity.studentDue, time: value?.toISOString()}
                                        })
                                    }
                                    readOnly={!canEditActivity}
                                />
                            </Col>
                        )}
                        <Col span={isFullDisplay ? 4 : 24}>
                            <KlassDropdown
                                placeholder={t("activityPopup.color")}
                                options={DUE_COLOR_OPTIONS}
                                getOptionLabel={renderColorOption}
                                value={DUE_COLOR_OPTIONS.find((option) => option.id === activity.studentDue?.color)}
                                onChange={(option) => {
                                    handleActivityChange({
                                        studentDue: {...activity.studentDue, color: option.id}
                                    })
                                }}
                                readOnly={!canEditActivity}
                            />
                        </Col>
                    </>
                )}
            </Row>

            {!isCompactForm && !!form.studentActivityId && (
                <>
                    <Divider className={styles.divider} />
                    <StudentActivityLogsTable studentActivityId={form.studentActivityId} />
                </>
            )}

            <div className={styles.actionBtnWrap}>
                <BaseButton
                    title={t("activityPopup.save")}
                    onClick={createOrEditActivity}
                    loading={isSaving}
                    disabled={!canEditActivity}
                />
            </div>
        </div>
    )
}

export default ActivityForm
