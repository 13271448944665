/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {Model} from "Model"
import {useHistory, useLocation} from "react-router-dom"
import {authService} from "services"
import {BaseLoading} from "components"
import {handleError} from "helpers"
import {Screen, InfoBox} from "components/ui"
import {reassignCustomTaskUser} from "helpers/task"
import {Branding} from "types/branding"
import {get} from "lodash"
import DefaultLogo from "assets/images/png/EdularLogoRectangle.png"

type Props = {
    model: Model
}

export const Redirector = (props: Props) => {
    const history = useHistory()
    const {model} = props
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const token = query.get("token")
    const appType = query.get("appType")
    const [isLoading, setIsLoading] = useState(false)
    const [html, setHtml] = useState(null)

    useEffect(() => {
        getToken()
    }, [])

    const getToken = async () => {
        try {
            setIsLoading(true)
            if (!token) {
                history.replace(`/`)
            }
            await model.clearStorage()
            const data = await authService.getTokenFromRedirect({token})
            const {userId, redirectTo, redirectType} = data.data
            if (redirectTo) {
                window.location.assign(redirectTo)
                return
            }
            await model.setStorage(data.token, userId, data.profileId)

            if (redirectType) {
                await applyRedirectType(redirectType, data.data)
                return
            }
            history.replace("/")
        } catch (e: any) {
            if (e?.code?.includes("TokenExpiredError")) {
                showTokenExpired()
            } else {
                handleError(e)
            }
        } finally {
            setIsLoading(false)
        }
    }

    const applyRedirectType = async (redirectType: string, data: any) => {
        switch (redirectType) {
            case "assignTaskByRole":
                const {userId, taskUserId} = data
                await reassignCustomTaskUser(userId, taskUserId, history, appType)
                break
            case "answerTask":
                const {localUrl} = data
                history.replace(localUrl)
                break
        }
    }

    const getBrandingImg = () => {
        const rectangularImg = get(
            model,
            ["clientSetting", "branding", Branding.BrandingType.OfficialLogoRectangular],
            null
        )
        const squareImg = get(model, ["clientSetting", "branding", Branding.BrandingType.OfficialLogoSquare], null)
        return squareImg || rectangularImg || DefaultLogo
    }

    const showTokenExpired = () => {
        const logoUrl = getBrandingImg()
        const div = (
            <div
                style={{
                    display: "grid",
                    placeItems: "center"
                }}>
                <img src={logoUrl} alt="logo" width={250} />
                <br />
                <InfoBox
                    title="This email link has expired"
                    description="Please access to task from edular web portal or the mobile app."
                />
            </div>
        )
        setHtml(div)
    }

    return (
        <Screen htmlTitle={"Redirect"} header={{isShowNotification: false}}>
            <BaseLoading isShow={isLoading} />
            {html}
        </Screen>
    )
}
