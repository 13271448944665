import {get} from "lodash"
import BaseService from "./Base"

class SettingActivityService extends BaseService {
    async getAllActivities(params = {}) {
        const response = await this.post("/v1/settings/activities/list", params)
        return get(response, "data", [])
    }

    async createActivity(params: any = {}) {
        const response = await this.post("/v1/settings/activities/create", params)
        return get(response, "data.data", {})
    }

    async duplicateActivity(activities) {
        const response = await this.post(`/v1/settings/activities/create`, activities)
        return get(response, "data", {})
    }

    async updateActivity(id, data, params = {}) {
        data.activityId = id
        const response = await this.post(`/v1/settings/activities/edit`, data, params)
        return get(response, "data", {})
    }

    async deleteActivity(activityIds) {
        const response = await this.post(`/v1/settings/activities/delete`, {activityIds})
        return get(response, "data", {})
    }

    async getAllAdvisorActivityTarget(params = {}) {
        const response = await this.post("/v1/admissions/advisor-activity-target/list", params)
        return get(response, "data.data", {})
    }

    async updateAdvisorActivityTarget(data) {
        const response = await this.post(`/v1/admissions/advisor-activity-target/edit`, data)
        return get(response, "data.data", {})
    }

    async restoreActivity(activities) {
        const response = await this.post(`/v1/settings/activities/restore`, activities)
        return get(response, "data", {})
    }
}

export default SettingActivityService
