import React from "react"
import {MissedAttendanceSection} from "./../MissedAttendanceSection"
import styles from "./RegistrarMissedAttendance.module.css"
import {Course} from "types/courses"
type Props = {
    courseId?: number
    studentProfileId?: number
}

export function RegistrarMissedAttendance(props: Props) {
    const {studentProfileId} = props
    return (
        <>
            <span className={styles.title}>Classroom</span>
            <MissedAttendanceSection
                attendanceTrackingType={Course.ProgramAttendanceTrackingType.Course}
                title="classroom"
                studentProfileId={studentProfileId}
                courseId={props.courseId}
            />
            <span className={styles.title}>Daily</span>
            <MissedAttendanceSection
                attendanceTrackingType={Course.ProgramAttendanceTrackingType.Daily}
                title="classroom"
                studentProfileId={studentProfileId}
                courseId={props.courseId}
            />
        </>
    )
}
