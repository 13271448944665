import React from "react"
import styles from "./StudentProgress.module.css"
import {Col, Row} from "antd"
import {formatDecimal} from "helpers"

type Props = {
    title: string
    totalTitle: string
    totalHours: any[]
    totalContractedHours: number
    isRightPanel?: boolean
    showTotalHours?: boolean
}

export function ProgressItem(props: Props) {
    const {title, totalTitle, totalHours, totalContractedHours, isRightPanel = false, showTotalHours} = props
    const [
        totalTransferHours,
        completedHour,
        attemptedHour,
        remainingHour,
        distEdHour,
        hoursScheduled,
        totalMajorHours
    ] = totalHours
    const completedHourPercent = Math.ceil(((completedHour.hour || 0) / totalMajorHours.hour) * 100)
    let attemptedHourPercent = 0
    if (attemptedHour.isShow) {
        attemptedHourPercent =
            attemptedHour.hour > totalMajorHours.hour
                ? 100
                : Math.ceil(((attemptedHour?.hour || 0) / totalMajorHours.hour) * 100)
    }
    const transferHourPercent = Math.ceil((totalTransferHours.hour / totalMajorHours.hour) * 100)
    const remainingHourPercent = Math.ceil(100 - completedHourPercent - transferHourPercent)

    const distEdPercent = Math.ceil((((distEdHour.isShow && distEdHour?.hour) || 0) / totalMajorHours.hour) * 100)

    const getMinPercent = (percent: number) => {
        return percent > 0 ? percent : 0
    }

    const getMaxPercent = (percent: number) => {
        return percent <= 100 ? percent : 100
    }

    return (
        <>
            <div className={styles.totalProgressWrap}>
                {totalMajorHours.hour > 0 && (
                    <>
                        <p className={styles.totalProgressTitle}>{title}</p>
                        <div className={styles.totalProgressBarWrap}>
                            <div className={styles.attemptedHourWrap} style={{left: `${attemptedHourPercent - 1}%`}}>
                                <div className={styles.attemptedHourPointer}></div>
                                <div
                                    className={styles.attemptedHourLine}
                                    style={{background: attemptedHour.color}}></div>
                            </div>
                            {transferHourPercent > 0 && (
                                <div
                                    className={styles.secondItem}
                                    style={{
                                        width: `${transferHourPercent}%`,
                                        left: 0,
                                        background: totalTransferHours.color
                                    }}>
                                    <p className={styles.progressBarNumber}>{formatDecimal(totalTransferHours.hour)}</p>
                                </div>
                            )}
                            {!!distEdHour.isShow && distEdPercent > 0 && (
                                <div
                                    className={styles.fourthItem}
                                    style={{
                                        width: `${getMaxPercent(distEdPercent + 1)}%`,
                                        left: `${getMinPercent(transferHourPercent)}%`,
                                        height: 20,
                                        bottom: 0,
                                        background: distEdHour.color,
                                        zIndex: 9
                                    }}>
                                    <p className={styles.progressBarNumber} style={{fontSize: "12px"}}>
                                        {distEdPercent >= 5 && formatDecimal(distEdHour.hour)}
                                    </p>
                                </div>
                            )}
                            {completedHour.isShow && completedHourPercent > 0 && (
                                <div
                                    className={styles.firstItem}
                                    style={{
                                        width: `${getMaxPercent(completedHourPercent)}%`,
                                        left: `${getMinPercent(transferHourPercent)}%`,
                                        background: completedHour.color
                                    }}>
                                    <p className={styles.progressBarNumber} style={{position: "absolute", right: 6}}>
                                        {completedHourPercent >= 5 && formatDecimal(completedHour.hour)}
                                    </p>
                                </div>
                            )}
                            {remainingHour.isShow && remainingHourPercent > 0 && (
                                <div
                                    className={styles.thirdItem}
                                    style={{
                                        width: `${getMaxPercent(remainingHourPercent + 1)}%`,
                                        left: `${getMinPercent(completedHourPercent + transferHourPercent)}%`,
                                        background: remainingHour?.color
                                    }}>
                                    <p className={styles.progressBarNumber}>{formatDecimal(remainingHour?.hour)}</p>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>

            <Row gutter={[24, 8]}>
                {totalTransferHours.isShow && (
                    <Col span={isRightPanel ? 24 : 12}>
                        <div className={styles.hourItem}>
                            <div className={styles.hourTitleWrap}>
                                <p className={styles.hourStatus} style={{background: totalTransferHours.color}}></p>
                                <p className={styles.hourTitle}>{totalTransferHours.title}:</p>
                            </div>
                            <p className={styles.hourDescription}>{formatDecimal(totalTransferHours.hour)}</p>
                        </div>
                    </Col>
                )}
                {attemptedHour.isShow && (
                    <Col span={isRightPanel ? 24 : 12}>
                        <div className={styles.hourItem}>
                            <div className={styles.hourTitleWrap}>
                                <p className={styles.hourStatus} style={{background: attemptedHour.color}}></p>
                                <p className={styles.hourTitle}>{attemptedHour.title}:</p>
                            </div>
                            <p className={styles.hourDescription}>{formatDecimal(attemptedHour.hour)}</p>
                        </div>
                    </Col>
                )}
                {completedHour.isShow && (
                    <Col span={isRightPanel ? 24 : 12}>
                        <div className={styles.hourItem}>
                            <div className={styles.hourTitleWrap}>
                                <p className={styles.hourStatus} style={{background: completedHour.color}}></p>
                                <p className={styles.hourTitle}>{completedHour.title}:</p>
                            </div>
                            <p className={styles.hourDescription}>{formatDecimal(completedHour.hour)}</p>
                        </div>
                    </Col>
                )}
                {remainingHour.isShow && (
                    <Col span={isRightPanel ? 24 : 12}>
                        <div className={styles.hourItem}>
                            <div className={styles.hourTitleWrap}>
                                <p className={styles.hourStatus} style={{background: remainingHour.color}}></p>
                                <p className={styles.hourTitle}>{remainingHour.title}:</p>
                            </div>
                            <p className={styles.hourDescription}>{formatDecimal(remainingHour.hour)}</p>
                        </div>
                    </Col>
                )}
                {!!distEdHour.isShow && (
                    <Col span={isRightPanel ? 24 : 12}>
                        <div className={styles.hourItem}>
                            <div className={styles.hourTitleWrap}>
                                <p className={styles.hourStatus} style={{background: distEdHour.color}}></p>
                                <p className={styles.hourTitle}>{distEdHour.title}:</p>
                            </div>
                            <p className={styles.hourDescription}>{formatDecimal(distEdHour.hour)}</p>
                        </div>
                    </Col>
                )}
                {!!hoursScheduled.isShow && (
                    <Col span={isRightPanel ? 24 : 12}>
                        <div className={styles.hourItem}>
                            <div className={styles.hourTitleWrap}>
                                <p className={styles.hourStatus} style={{background: hoursScheduled.color}}></p>
                                <p className={styles.hourTitle}>{hoursScheduled.title}:</p>
                            </div>
                            <p className={styles.hourDescription}>{formatDecimal(hoursScheduled.hour)}</p>
                        </div>
                    </Col>
                )}
                {showTotalHours && (
                    <Col span={isRightPanel ? 24 : 12}>
                        <div className={styles.hourItem}>
                            <div className={styles.hourTitleWrap}>
                                <p className={styles.hourTitle}>{totalTitle}:</p>
                            </div>
                            <p className={styles.hourDescription}>{formatDecimal(totalContractedHours || 0)}</p>
                        </div>
                    </Col>
                )}
                {!!totalMajorHours.isShow && (
                    <Col span={isRightPanel ? 24 : 12}>
                        <div className={styles.hourItem}>
                            <div className={styles.hourTitleWrap}>
                                <p className={styles.hourTitle}>{totalMajorHours.title}:</p>
                            </div>
                            <p className={styles.hourDescription}>{formatDecimal(totalMajorHours.hour)}</p>
                        </div>
                    </Col>
                )}
            </Row>
        </>
    )
}
