import React from "react"
import {Bar} from "react-chartjs-2"
import "chartjs-plugin-datalabels"
import "chartjs-plugin-annotation"

const defaultOptions = {
    responsive: true,
    legend: {
        display: false
    },
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                    min: 0
                },
                gridLines: {
                    display: false
                }
            }
        ],
        xAxes: [
            {
                gridLines: {
                    display: false
                },
                ticks: {
                    autoSkip: true,
                    autoSkipPadding: 4
                }
            }
        ]
    },
    hover: {
        mode: "index",
        intersect: false
    },
    tooltips: {
        enabled: false
    },
    plugins: {
        datalabels: {
            display: true,
            anchor: "end",
            align: "top",
            color: function (ctx) {
                return ctx.dataset.backgroundColor
            }
        }
    }
}

export function BarChart(props) {
    const {forwardedRef, data, options: optionsProps, ...rest} = props
    const options = {...defaultOptions, ...optionsProps}
    const key = JSON.stringify(options)

    return <Bar key={key} ref={forwardedRef} data={data} options={options} {...rest} />
}
