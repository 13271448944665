/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useReducer} from "react"
import {useTranslation} from "react-i18next"
import {statsService} from "services"
import {handleError} from "helpers"
import styles from "./StatsFunnel.module.css"

function reducer(state, action) {
    return {...state, ...action}
}

export enum TitleFunnel {
    financialAidFunnel = "financialAidFunnel",
    admissionAidFunnel = "admissionAidFunnel"
}

type Props = {
    form: any
    title: TitleFunnel
    setState: any
    departmentId: number
}

export function StatsFunnel(props: Props) {
    const initialState = {
        stagesStats: [],
        showCompareIndex: -1
    }
    const [{showCompareIndex, stagesStats}, dispatch] = useReducer(reducer, initialState)

    const {t} = useTranslation(["common"])

    const dataSeq = React.useRef(0)

    useEffect(() => {
        if (props.form.applySearch) {
            getStatsInfo()
            props.setState({applySearch: false})
        }
    }, [props.form])

    useEffect(() => {
        getStatsInfo()
    }, [])

    function getFilter(form) {
        const {campus, program, advisor, term} = form
        let filter: any = {}
        if (term) {
            filter.termsIds = term.map(({id}) => id)
        }
        if (campus) {
            filter.campusIds = campus.map(({id}) => id)
        }
        if (program) {
            filter.programIds = program.map(({id}) => id)
        }
        if (advisor) {
            filter.advisorProfileIds = (advisor || []).map((item) => item?.profileId).filter((item) => item)
        }

        return filter
    }

    async function getStatsInfo() {
        try {
            dataSeq.current += 1
            const currentSeq = dataSeq.current
            const filter = getFilter(props.form)

            const stagesStats = await statsService.getDepartmentStatsStages({
                departmentId: props.departmentId,
                advisorProfileIds: [],
                ...filter
            })

            if (dataSeq.current !== currentSeq) return

            const total = stagesStats.reduce((total, stage) => (total += stage.count), 0)

            const stagesStatsExtended = stagesStats.map((stage, index) => {
                const topIncrease = 60
                const topPosition = 20 + index * topIncrease
                return {
                    ...stage,
                    percentage: total ? Math.round((stage.count / total) * 100) : 0,
                    topPosition
                }
            })
            dispatch({stagesStats: stagesStatsExtended})
        } catch (error) {
            handleError(error)
        }
    }

    function onClickStage(index) {
        let newShowCompareIndex = -1

        if (showCompareIndex !== index) {
            newShowCompareIndex = index
        }

        dispatch({showCompareIndex: newShowCompareIndex})
    }

    function CompareStages({stageStat, index}) {
        const nextStageIndex = index === stagesStats.length - 1 ? index - 1 : index + 1
        const nextStage = stagesStats[nextStageIndex]
        const comparedPercentage = Math.abs(nextStage.percentage - stageStat.percentage)
        const topPosition = index === stagesStats.length - 1 ? nextStage.topPosition : stageStat.topPosition

        return (
            <div
                className={styles.comparePercentWrap}
                style={{
                    top: `${topPosition}px`,
                    right: `75px`
                }}>
                <p className={styles.comparePercentValue} style={{top: "10px"}}>
                    {comparedPercentage}%
                </p>
            </div>
        )
    }

    function getColor(stage) {
        if (stage.name === "Lead") {
            return "--black-200"
        }

        if (stage.name === "Start") {
            return "--success-400-base"
        }

        return "--primary-400-base"
    }

    function ProgressBar({stageStat, index}) {
        const color = getColor(stageStat)

        return (
            <div className={styles.item} onClick={() => onClickStage(index)}>
                <div className={styles.circle} style={{background: `var(${color})`}}></div>
                <p className={styles.item__title}>{stageStat.name}</p>
                <p className={styles.percent}>{stageStat.percentage}%</p>
                <p className={styles.currentValue} style={{background: "var(--black-300)"}}>
                    {stageStat.count}
                </p>
                <div className={styles.progressWrap}>
                    <div
                        className={styles.progressValue}
                        style={{
                            width: `${stageStat.percentage}%`,
                            background: `var(${color})`
                        }}></div>
                </div>
                <div className={styles.targetValue}>0</div>
            </div>
        )
    }

    return (
        <div className={styles.wrap}>
            <p className={styles.title}>{t(`statsFunnel.${props.title}`).toUpperCase()}</p>
            <div className={styles.body}>
                <div className={styles.headerWrap}>
                    <p className={styles.headerTarget}>{t("statsFunnel.targetFunnel")}</p>
                </div>
                <div className={styles.itemWrap}>
                    {stagesStats.forEach((stageStat, index) => {
                        if (showCompareIndex === index) {
                            return <CompareStages key={stageStat.stageId} stageStat={stageStat} index={index} />
                        }
                    })}
                    {stagesStats.map((stageStat, index) => (
                        <ProgressBar key={stageStat.stageId} stageStat={stageStat} index={index} />
                    ))}
                </div>
            </div>
        </div>
    )
}
