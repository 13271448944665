/* eslint-disable react-hooks/exhaustive-deps */
import {Checkbox, Col, Input, Row, Select} from "antd"
import {FormLabel} from "components/Form"
import {CareerServices} from "types/careerServices"
import {DisplayMode} from "types/common"
import styles from "./FormDetail.module.css"
import cx from "classnames"
import {useMemo, useRef, useState} from "react"
import {ReactComponent as FileIcon} from "./assets/file.svg"
import {ReactComponent as RemoveIcon} from "./assets/remove.svg"
import {KlassDropAsyncPaginateCreatable} from "components/Select"
import {handleError, toastError, toastSuccess} from "helpers"
import {BaseButton, BaseInput, BaseTextArea} from "components"
import {careerService, documentService} from "services"
import moment from "moment"
import {BaseDatePicker} from "components/DateTimePicker"
import {BaseDepartmentId} from "types/departments"

type FormType = "placement" | "verification"

type Props = {
    student: CareerServices.StudentDetail
    displayMode?: DisplayMode
    isLoading: boolean
    isSubmitting: boolean
    formType?: FormType
    updateStudent: (_student: CareerServices.StudentDetail) => void
}

type PartialData = {
    [key: string]: Partial<{
        prefix: string
        controlField: string
        controlDate: string
        documentField: string
    }>
}

const FORM_ATTRS: PartialData = {
    placement: {
        prefix: "",
        controlField: "isPlaced",
        controlDate: "employmentDate",
        documentField: "offerDocumentUrl"
    },
    verification: {
        prefix: "verified",
        controlField: "isVerified",
        controlDate: "verifiedAt",
        documentField: "verifiedDocumentUrl"
    }
}

export function FormDetail(props: Props) {
    const {displayMode = "normal", student, isLoading, isSubmitting, formType = "placement", updateStudent} = props
    const fileRef = useRef<any>()
    const [isUploading, setIsUploading] = useState(false)

    const isFullDisplay = displayMode === "normal"
    const isPlacementForm = formType === "placement"

    const onChangeData = (key, value) => {
        const _student = {...student}
        _student[key] = value
        if (key === "isVerified" && value === false) {
            _student["isTaskVerified"] = false
            _student["isEmailVerified"] = false
            _student["isPhoneVerified"] = false
        }
        if (key === formAtt.controlField && value) {
            _student.unemploymentType = undefined
        }
        updateStudent(_student)
    }

    const onOpenChooseFile = () => {
        if (isLoading || isSubmitting || isUploading) {
            return
        }
        fileRef.current.click()
    }

    const onClickUpload = (e) => {
        e.target.value = ""
    }

    const onChangeFile = async (e) => {
        if (isLoading || isSubmitting || isUploading) {
            return
        }
        const file = e.target.files[0]
        if (file) {
            await submitDocument(file)
        }
    }

    const onClickRemoveDocument = async (e) => {
        if (isLoading || isSubmitting || isUploading) {
            return
        }
        e.stopPropagation()
        try {
            setIsUploading(true)
            onChangeData(formAtt.documentField, undefined)
            await careerService.updateStudentDocument({
                profileId: student.profileId,
                documentField: formAtt.documentField,
                documentUrl: null
            })
            toastSuccess("Remove document successfully.")
        } catch (e) {
            toastError("Remove document failure.")
        } finally {
            setIsUploading(false)
        }
    }

    const submitDocument = async (file) => {
        try {
            setIsUploading(true)
            const formData = new FormData()
            formData.append("document", file, file.name)
            const documentUrl = await documentService.uploadDocument(formData)
            const docName = `Career-Services ${isPlacementForm ? "Offer" : "Verification"} - ${student.profileId}`
            const {documentUrl: signedDocumentUrl} = await documentService.createDocument({
                documentUrl,
                code: student.profileId.toString(),
                departmentSubunitId: BaseDepartmentId.CareerServices,
                description: docName,
                isVisibleForUser: true,
                name: docName,
                ownerUserProfileId: student.profileId
            })
            onChangeData(formAtt.documentField, signedDocumentUrl)
            await careerService.updateStudentDocument({
                profileId: student.profileId,
                documentField: formAtt.documentField,
                documentUrl
            })
            toastSuccess("Update document successfully.")
        } catch (error) {
            toastError("Update document failure.")
        } finally {
            setIsUploading(false)
        }
    }

    const formAtt = useMemo(() => {
        return FORM_ATTRS[formType]
    }, [formType])

    const isDisabled = useMemo(() => {
        return !student[formAtt.controlField] || student.unemploymentType !== undefined
    }, [student, formAtt])

    const buildAttr = (prefix: string, field: string) => {
        if (prefix === "") {
            return field
        }
        return `${prefix}${field.charAt(0).toUpperCase()}${field.substring(1)}`
    }

    const salaryUnit = () => {
        return (
            <Select
                defaultValue="monthly"
                className={styles.salaryUnitWrap}
                value={student[buildAttr(formAtt.prefix, "salaryUnit")]}
                onChange={(_value) => onChangeData(buildAttr(formAtt.prefix, "salaryUnit"), _value)}
                disabled={isDisabled}>
                {["hourly", "weekly", "monthly", "annually"].map((x) => {
                    return <Select.Option value={x}>{x.charAt(0).toUpperCase() + x.substring(1)}</Select.Option>
                })}
            </Select>
        )
    }

    const onSearchPosition = async (search = "", loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            let {data, total} = await careerService.getPositions({
                filter: {
                    search
                },
                range: {
                    page,
                    pageSize
                }
            })
            return {
                options: data,
                hasMore: loadedOptions.length < total
            }
        } catch (e) {
            handleError(e)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    return (
        <Row gutter={[16, 16]} className={styles.formDetail}>
            <Col span={isFullDisplay ? 12 : 24}>
                <FormLabel label={isPlacementForm ? "Placement" : "Verification"} />
                <div className={styles.checkboxWrap}>
                    <div
                        className={cx(styles.checkbox, {[styles.checked]: student[formAtt.controlField]})}
                        onClick={() => onChangeData(formAtt.controlField, !student[formAtt.controlField])}>
                        <Checkbox checked={student[formAtt.controlField]} />
                        <span className={styles.checkboxTitle}>{isPlacementForm ? "Placed" : "Verified"}</span>
                    </div>
                </div>
                {!isPlacementForm && (
                    <div className={styles.checkboxGroupWrap}>
                        <div className={styles.checkboxWrap}>
                            <div
                                className={cx(styles.checkbox, {[styles.checked]: student.isTaskVerified})}
                                onClick={() => !isDisabled && onChangeData("isTaskVerified", !student.isTaskVerified)}>
                                <Checkbox checked={student.isTaskVerified} disabled={isDisabled} />
                                <span className={styles.checkboxTitle}>Task</span>
                            </div>
                        </div>
                        <div className={styles.checkboxWrap}>
                            <div
                                className={cx(styles.checkbox, {[styles.checked]: student.isEmailVerified})}
                                onClick={() =>
                                    !isDisabled && onChangeData("isEmailVerified", !student.isEmailVerified)
                                }>
                                <Checkbox checked={student.isEmailVerified} disabled={isDisabled} />
                                <span className={styles.checkboxTitle}>Email</span>
                            </div>
                        </div>
                        <div className={styles.checkboxWrap}>
                            <div
                                className={cx(styles.checkbox, {[styles.checked]: student.isPhoneVerified})}
                                onClick={() =>
                                    !isDisabled && onChangeData("isPhoneVerified", !student.isPhoneVerified)
                                }>
                                <Checkbox checked={student.isPhoneVerified} disabled={isDisabled} />
                                <span className={styles.checkboxTitle}>Phone</span>
                            </div>
                        </div>
                    </div>
                )}
            </Col>
            <Col span={isFullDisplay ? 12 : 24} className={cx({[styles.row]: isFullDisplay})}>
                <FormLabel label={isPlacementForm ? "Upload proof of employment" : "Upload employment verification"} />
                {student[formAtt.documentField] ? (
                    <div className={styles.offerWrap} onClick={() => window.open(student[formAtt.documentField])}>
                        <div className={styles.iconWrapper}>
                            <FileIcon className={styles.icon} width={32} height={32} />
                        </div>
                        <div className={styles.offerContent}>
                            <div className={styles.offerDesc}>{isPlacementForm ? "Offer Doc" : "Verification Doc"}</div>
                        </div>
                        <div className={styles.removeIconWrapper} onClick={onClickRemoveDocument}>
                            <RemoveIcon className={styles.removeIcon} width={24} height={24} />
                        </div>
                    </div>
                ) : (
                    <div className={styles.uploadWrap}>
                        <input
                            ref={fileRef}
                            style={{display: "none"}}
                            type="file"
                            accept="application/pdf"
                            onChange={onChangeFile}
                            onClick={onClickUpload}
                        />
                        <BaseButton
                            variant="secondary"
                            title="Upload"
                            onClick={onOpenChooseFile}
                            loading={isUploading}
                            disabled={isDisabled}
                        />
                    </div>
                )}
            </Col>
            <Col span={isFullDisplay ? 8 : 24}>
                <FormLabel label={isPlacementForm ? "Employment Date" : "Date verified"} />
                <BaseDatePicker
                    value={student[formAtt.controlDate] ? moment(student[formAtt.controlDate]) : null}
                    onChange={(_value) => onChangeData(formAtt.controlDate, _value)}
                    placeholder="Date"
                    disabled={isDisabled}
                />
            </Col>
            <Col span={isFullDisplay ? 8 : 24}>
                <FormLabel label="Employer" />
                <BaseInput
                    value={student[buildAttr(formAtt.prefix, "employer")]}
                    onChange={(_value) => onChangeData(buildAttr(formAtt.prefix, "employer"), _value)}
                    placeholder="Employer"
                    disabled={isDisabled}
                />
            </Col>
            <Col span={isFullDisplay ? 8 : 24}>
                <FormLabel label="Contact Person" />
                <BaseInput
                    value={student[buildAttr(formAtt.prefix, "contactPerson")]}
                    onChange={(_value) => onChangeData(buildAttr(formAtt.prefix, "contactPerson"), _value)}
                    placeholder="Contact Person"
                    disabled={isDisabled}
                />
            </Col>
            <Col span={isFullDisplay ? 8 : 24}>
                <FormLabel label="Address" />
                <BaseInput
                    value={student[buildAttr(formAtt.prefix, "address")]}
                    onChange={(_value) => onChangeData(buildAttr(formAtt.prefix, "address"), _value)}
                    placeholder="Address"
                    disabled={isDisabled}
                />
            </Col>
            <Col span={isFullDisplay ? 8 : 24}>
                <FormLabel label="City" />
                <BaseInput
                    value={student[buildAttr(formAtt.prefix, "city")]}
                    onChange={(_value) => onChangeData(buildAttr(formAtt.prefix, "city"), _value)}
                    placeholder="City"
                    disabled={isDisabled}
                />
            </Col>
            <Col span={isFullDisplay ? 8 : 24}>
                <FormLabel label="State" />
                <BaseInput
                    value={student[buildAttr(formAtt.prefix, "state")]}
                    onChange={(_value) => onChangeData(buildAttr(formAtt.prefix, "state"), _value)}
                    placeholder="State"
                    disabled={isDisabled}
                />
            </Col>
            <Col span={isFullDisplay ? 8 : 24}>
                <FormLabel label="Phone" />
                <BaseInput
                    value={student[buildAttr(formAtt.prefix, "phone")]}
                    onChange={(_value) => onChangeData(buildAttr(formAtt.prefix, "phone"), _value)}
                    placeholder="Phone"
                    disabled={isDisabled}
                />
            </Col>
            <Col span={isFullDisplay ? 8 : 24}>
                <FormLabel label="Email" />
                <BaseInput
                    value={student[buildAttr(formAtt.prefix, "email")]}
                    onChange={(_value) => onChangeData(buildAttr(formAtt.prefix, "email"), _value)}
                    placeholder="Email"
                    disabled={isDisabled}
                />
            </Col>
            <Col span={isFullDisplay ? 8 : 24}>
                <FormLabel label="Website" />
                <BaseInput
                    value={student[buildAttr(formAtt.prefix, "website")]}
                    onChange={(_value) => onChangeData(buildAttr(formAtt.prefix, "website"), _value)}
                    placeholder="Website"
                    disabled={isDisabled}
                />
            </Col>
            <Col span={isFullDisplay ? 8 : 24}>
                <FormLabel label="Position / Job Title" />
                <KlassDropAsyncPaginateCreatable
                    value={student[buildAttr(formAtt.prefix, "position")]}
                    onChange={(_value) => onChangeData(buildAttr(formAtt.prefix, "position"), _value)}
                    loadOptions={onSearchPosition}
                    readOnly={isDisabled}
                    className={cx({[styles.readOnly]: isDisabled})}
                />
            </Col>
            <Col span={isFullDisplay ? 8 : 24}>
                <FormLabel label="Employment Start Date" />
                <BaseDatePicker
                    value={
                        student[buildAttr(formAtt.prefix, "employmentStartDate")]
                            ? moment(student[buildAttr(formAtt.prefix, "employmentStartDate")])
                            : null
                    }
                    onChange={(_value) => onChangeData(buildAttr(formAtt.prefix, "employmentStartDate"), _value)}
                    placeholder="Employment Start Date"
                    disabled={isDisabled}
                />
            </Col>
            <Col span={isFullDisplay ? 8 : 24}>
                <FormLabel label="Salary" />
                <Input
                    value={student[buildAttr(formAtt.prefix, "salary")]}
                    onChange={(e) => onChangeData(buildAttr(formAtt.prefix, "salary"), e.target.value)}
                    placeholder="Salary"
                    type="number"
                    min={0}
                    disabled={isDisabled}
                    addonAfter={salaryUnit()}
                />
            </Col>
            <Col span={24}>
                <FormLabel label="Job Description" />
                <BaseTextArea
                    value={student[buildAttr(formAtt.prefix, "jobDescription")]}
                    onChange={(_value) => onChangeData(buildAttr(formAtt.prefix, "jobDescription"), _value)}
                    placeholder="Job description"
                    disabled={isDisabled}
                />
            </Col>
            {!isPlacementForm && (
                <Col span={isFullDisplay ? 8 : 24}>
                    <FormLabel label="Verified By" />
                    <BaseInput
                        value={student.verifiedBy}
                        onChange={(_value) => onChangeData("verifiedBy", _value)}
                        placeholder="Verified By"
                        disabled={isDisabled}
                    />
                </Col>
            )}
        </Row>
    )
}
