/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react"
import {Row, Col, Checkbox} from "antd"
import debounce from "debounce-promise"
import {campusesService, courseService} from "services"
import {KlassDropAsyncPaginate, KlassDropdown} from "components/Select"
import {BaseDatePicker} from "components/DateTimePicker"
import {BaseButton} from "components/buttons"
import {BaseNewFilter} from "uiKit"
import CourseGradeTable from "./CourseGradeTable"
import styles from "./CourseGradesTab.module.css"
import AddCourseGrade from "./AddCourseGrade"
import {getFullName} from "helpers"
import {useModel} from "hooks"
import {get} from "lodash"

const dropDownStyle = {
    control: (provided, state) => {
        return {
            ...provided,
            borderRadius: 12,
            minHeight: 44,
            borderWidth: 2,
            borderColor: "#1e90ff",
            fontSize: 14,
            fontWeight: "600"
        }
    }
}

export enum GradingItemsFilter {
    All = "all",
    AffectGPA = "affect_gpa",
    NotAffectGPA = "not_affect_gpa"
}

export enum SAPAlertFilter {
    All = "all",
    Pass = "pass",
    Fail = "fail"
}

const optionFilterGradingItems = [
    {
        name: "ALL",
        value: GradingItemsFilter.All
    },
    {
        name: "AFFECT GPA",
        value: GradingItemsFilter.AffectGPA
    },
    {
        name: "NOT AFFECT GPA",
        value: GradingItemsFilter.NotAffectGPA
    }
]
const optionFilterSapAlerts = [
    {
        name: "ALL",
        value: SAPAlertFilter.All
    },
    {
        name: "PASS",
        value: SAPAlertFilter.Pass
    },
    {
        name: "FAIL",
        value: SAPAlertFilter.Fail
    }
]

export function CourseGradesTab(props) {
    const {courseId, courseInfo, termId, scheduleId} = props
    const [students, setStudents] = useState([])
    const [, setLoading] = useState(false)
    const model = useModel()
    const [listMode, setListMode] = useState(false)
    const [filter, setFilter] = useState({
        campus: null,
        startDate: null,
        affectGpa: optionFilterGradingItems[0],
        spaAlerts: optionFilterSapAlerts[0],
        missingGrades: false
    })
    const [dataFilter, setDataFilter] = useState({
        campus: null,
        startDate: null,
        affectGpa: optionFilterGradingItems[0],
        spaAlerts: optionFilterSapAlerts[0],
        missingGrades: false
    })
    const [isAddGrade, setIsAddGrade] = useState(false)
    const [search, setSearch] = useState<string>("")

    const getListStudent = async () => {
        try {
            setLoading(true)
            const params: any = {
                filter: {courseIds: [courseId]},
                range: {page: 1, pageSize: 500}
            }
            if (termId) {
                params.filter.termIds = [termId]
            }
            const profileId = model.profileId
            if (!model.isStaffOrAdmin() && profileId) {
                params.filter.studentProfileIds = [profileId]
            }
            if (scheduleId) {
                params.filter.scheduleId = scheduleId
            }
            const {data: studentStats} = await courseService.listInstructionalStudents(params)

            const data = studentStats.map((item: any) => {
                item.id = item.student.profileId
                item.profileId = item.student.profileId
                item.fullname = getFullName(item.student)
                item.isMakeup = !!item.student.isMakeup
                item.isDropped = !!item.student.isDropped
                item.droppedDate = item.student.droppedDate
                item.addDropDate = item.student.addDropDate
                item.addDropHours = item.student.addDropHours
                item.addDropVariant = item.student.addDropVariant
                return item
            })
            setStudents(data)
            setLoading(false)
        } catch (e) {
            console.log("e")
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getListStudent()
    }, [termId, courseId, scheduleId])

    const onChangeFilter = (key, value) => {
        const newFilter = {...filter}
        newFilter[key] = value
        setFilter(newFilter)
    }

    const onApplyFilter = () => {
        setDataFilter(filter)
    }

    const onClickClearFilter = () => {
        setFilter({
            campus: null,
            startDate: null,
            affectGpa: optionFilterGradingItems[0],
            spaAlerts: optionFilterSapAlerts[0],
            missingGrades: false
        })
        setDataFilter({
            campus: null,
            startDate: null,
            affectGpa: optionFilterGradingItems[0],
            spaAlerts: optionFilterSapAlerts[0],
            missingGrades: false
        })
    }

    const onSearchInput = (input) => {
        setSearch(input)
    }

    const onCampusSearchChange = async (search = "", loadedOptions) => {
        try {
            const params = {
                filter: {
                    search
                },
                range: {
                    limit: 20,
                    offset: loadedOptions.length
                },
                sort: {
                    orderBy: "name",
                    orderDir: "asc"
                }
            }
            const {data: campuses, total} = await campusesService.getAll(params)
            return {
                options: campuses,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const cancelAddGrade = useCallback(() => {
        setIsAddGrade(false)
    }, [])

    const addGrade = useCallback(() => {
        setIsAddGrade(true)
    }, [])

    const onClickChangeMode = useCallback(() => {
        setListMode(!listMode)
    }, [listMode])

    const onChangeStartDate = useCallback((value: any, text: string) => {
        onChangeFilter("startDate", value)
    }, [])

    const renderRightFilter = () => {
        return (
            <div className={styles.actionWrap}>
                <BaseButton
                    onClick={onClickChangeMode}
                    className={styles.actionChangeMode}
                    title={!listMode ? "List Mode" : "Table Mode"}
                    variant="secondary"
                />
                <BaseButton title="Export" variant="secondary" />
                <BaseButton onClick={addGrade} className={styles.actionAdd} title="Add" variant="primary" />
            </div>
        )
    }
    const debounceCampuses = debounce(onCampusSearchChange, 300)

    if (isAddGrade) {
        return (
            <AddCourseGrade
                courseInfo={courseInfo}
                students={students}
                courseId={courseId}
                scheduleId={scheduleId}
                termId={termId}
                cancelAddGrade={cancelAddGrade}
            />
        )
    }
    return (
        <div>
            <BaseNewFilter
                filter={filter}
                searchValue={search}
                onClick={onApplyFilter}
                onClickClear={onClickClearFilter}
                onSearchInput={onSearchInput}
                renderRightFilter={() => renderRightFilter()}>
                <Row gutter={[40, 24]}>
                    <Col span={12}>
                        <BaseDatePicker
                            value={filter.startDate}
                            onChange={onChangeStartDate}
                            placeholder="Start Date"
                        />
                    </Col>
                    <Col span={12}>
                        <KlassDropAsyncPaginate
                            value={filter.campus}
                            onChange={(newValue) => onChangeFilter("campus", newValue)}
                            loadOptions={debounceCampuses}
                            isMulti
                            placeholder="Campus"
                        />
                    </Col>
                    <Col span={12}>
                        <div className={styles.filterRow}>
                            <span className={styles.filterTitle}>GRADING ITEMS</span>
                            <KlassDropdown
                                stylesCustom={dropDownStyle}
                                onChange={(value) => {
                                    onChangeFilter("affectGpa", value)
                                }}
                                options={optionFilterGradingItems}
                                value={filter.affectGpa}
                                valueKey="value"
                                labelKey="name"
                                placeholder="Select"
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className={styles.filterItem}>
                            <Checkbox
                                checked={!!filter.missingGrades}
                                onChange={(event) => {
                                    onChangeFilter("missingGrades", event.target.checked)
                                }}
                            />
                            <span className={styles.dataFilter} style={{marginLeft: 8}}>
                                MISSING GRADES
                            </span>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className={styles.filterRow}>
                            <span className={styles.filterTitle}>SAP ALERTS</span>
                            <KlassDropdown
                                stylesCustom={dropDownStyle}
                                onChange={(value) => {
                                    onChangeFilter("spaAlerts", value)
                                }}
                                options={optionFilterSapAlerts}
                                value={filter.spaAlerts}
                                valueKey="value"
                                labelKey="name"
                                placeholder="Select"
                            />
                        </div>
                    </Col>
                </Row>
            </BaseNewFilter>
            <CourseGradeTable
                courseInfo={courseInfo}
                scheduleId={scheduleId}
                termIds={[termId]}
                filter={dataFilter}
                search={search}
                listMode={listMode}
                students={students}
                courseId={courseId}
            />
        </div>
    )
}
