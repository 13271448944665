export namespace Branding {
    export enum BrandingType {
        LoginScreenPromoImageMobile = "login_screen_promo_image_mobile",
        LoginScreenPromoImageWeb = "login_screen_promo_image_web",
        OfficialLogoSquare = "official_logo_square",
        OfficialLogoRectangular = "official_logo_rectangular",
        MobileAppLaunchScreenImage = "mobile_app_launch_screen_image",
        MobileAppIntroPageVideo = "mobile_app_intro_page_video"
    }

    export type Branding = {
        brandingId: number
        type: BrandingType
        url: string
        createdAt: string
        createdByProfileId: number
        deletedAt: string
        updatedAt: string
        updatedByProfileId: number
    }

    export type GetBrandingParams = {}

    export type BrandingData = {
        [key: string]: {
            brandingId: number
            url: string
        }
    }
}
