export const tasks = [
    {id: 1, name: "Submitt Questionnaire", date: "2021-07-30 02:12:58.166Z"},
    {
        id: 2,
        name: "Lorem ipsum dolor sit amet, consectetur adipisce elit. Diam libero nulla ",
        date: "2020-07-30 02:12:58.166Z"
    },
    {id: 3, name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ", date: "2020-07-30 10:12:58.166Z"},
    {id: 4, name: "Course Name Quiz", date: "2021-07-30 02:12:58.166Z"},
    {id: 5, name: "Course Name Quiz#2", date: "2021-07-30 23:12:58.166Z"},
    {id: 6, name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.", date: "2020-07-30 02:12:58.166Z"},
    {id: 9, name: "Course Name Quiz #3", date: "2021-07-30 02:12:58.166Z"}
]
