import React, {createContext} from "react"

export const ActiveApplicationContext = createContext<any>({})

type Props = {
    children: React.ReactNode
    value: any
}

export const ActiveApplicationProvider: any = ({children, value}: Props) => {
    return <ActiveApplicationContext.Provider value={value}>{children}</ActiveApplicationContext.Provider>
}
