import {useQuery} from "@tanstack/react-query"
import {disbursementService} from "services"

const INITIAL_DATA: Array<{start: string; end: string}> = []

export const useDisbursementPeriods = () => {
    const query = useQuery(
        ["all-disbursement-periods"],
        async () => {
            return await disbursementService.getPeriods()
        },
        {
            refetchOnMount: false
        }
    )
    return {periods: query.data ?? INITIAL_DATA}
}
