import React, {useMemo} from "react"
import {useTranslation} from "react-i18next"
import {KlassDropdown, KlassDropdownProps} from "../Select"
import {pick} from "lodash"
import {useAllPhases} from "hooks/useAllPhases"

type Props = Partial<KlassDropdownProps> & {}

const PhaseSelect: React.FC<Props> = ({value, ...props}) => {
    const {t} = useTranslation(["common"])
    const {phases} = useAllPhases()
    const options = useMemo(() => phases.map((item) => pick(item, ["phaseId", "name"])), [phases])

    return (
        <KlassDropdown
            {...props}
            placeholder={props.placeholder || t("selectField.placeholder")}
            value={typeof value === "number" ? options.find((option) => option.phaseId === value) : value}
            options={options}
            valueKey="phaseId"
            labelKey="name"
        />
    )
}

export default PhaseSelect
