/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {CareerServices} from "types/careerServices"
import {handleError} from "helpers"
import {careerService} from "services"
import {BaseButton, BaseLoading, Icon} from "components"
import styles from "./JobInfoPanel.module.css"
import cx from "classnames"
import {PanelNavigation} from "uiKit/PanelNavigation"
import {MessagePanel} from "sections/NewCommunication/shared"
import {BaseDepartmentId} from "types/departments"
import {ReactComponent as LikeIcon} from "./assets/like.svg"
import {ReactComponent as FilledLikeIcon} from "./assets/like-filled.svg"
import {ReactComponent as DislikeIcon} from "./assets/dislike.svg"
import {ReactComponent as FilledDislikeIcon} from "./assets/dislike-filled.svg"
import {JobItemPanel} from "sections/shared/careerService/JobItemPanel"
import moment from "moment"
import {useModel, useVisible} from "hooks"
import {StudentCareerServiceNote} from "sections/shared/careerService/StudentCareerServiceNote"
import {ApplyJobForm} from "../ApplyJobForm"
import {useHistory} from "react-router-dom"

type DisplayType = "drawer" | "panel"

export type JobInfoPanelProps = {
    jobId: number
    activeSection?: string
    displayType?: DisplayType
    onClose?: () => void
    onOpenDrawer?: (activeSection: string) => void
    minimizeDrawerWidth?: () => void
    maximizeDrawerWidth?: () => void
}

export function JobInfoPanel(props: JobInfoPanelProps) {
    const {
        jobId,
        displayType = "panel",
        activeSection: initActiveSection,
        // onOpenDrawer,
        minimizeDrawerWidth,
        // maximizeDrawerWidth,
        onClose
    } = props

    const [job, setJob] = useState<CareerServices.Job>()
    const [isLoading, setIsLoading] = useState(false)
    const [activeSection, setActiveSection] = useState<string>(initActiveSection)
    const [reactionStat, setReactionStat] = useState<CareerServices.ReactionStat>()
    const model = useModel()
    const isStudent = model.isStudent()
    const [careerServiceStudent, setCareerServiceStudent] = useState<CareerServices.StudentDetail>(null)
    const applyFormVisible = useVisible()
    const [applyType, setApplyType] = useState<"email" | "system">()
    const history = useHistory()

    useEffect(() => {
        getJob()
    }, [jobId])

    useEffect(() => {
        getStudentReadiness()
    }, [])

    const getJob = async () => {
        try {
            setIsLoading(true)
            const _job = await careerService.getJob({
                id: jobId
            })
            setJob(_job)
            setReactionStat({
                totalLikes: _job.totalLikes,
                totalDislikes: _job.totalDislikes,
                isLiked: _job.isLiked,
                isDisliked: _job.isDisliked
            })
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const getStudentReadiness = async () => {
        try {
            if (isStudent) {
                const data = await careerService.getStudent({profileId: model.profileId})
                setCareerServiceStudent(data)
            }
        } catch (error) {
            handleError(error)
        }
    }

    if (isLoading || !job) {
        return <BaseLoading />
    }

    const onCollapsePanel = () => {
        handleCloseActiveSection()
        if (onClose) onClose()
    }

    // const handleAction = (_activeSection: string) => {
    //     if (displayType === "panel") {
    //         if (onOpenDrawer) onOpenDrawer(_activeSection)
    //         if (maximizeDrawerWidth) maximizeDrawerWidth()
    //         onCollapsePanel()
    //     } else {
    //         setActiveSection(_activeSection)
    //         if (maximizeDrawerWidth) maximizeDrawerWidth()
    //     }
    // }

    const handleCloseActiveSection = () => {
        if (displayType === "drawer") {
            setActiveSection("") // clear active action
            if (minimizeDrawerWidth) minimizeDrawerWidth()
        }
    }

    const handleReaction = async (type: CareerServices.ReactionType) => {
        try {
            if (
                (type === CareerServices.ReactionType.Like && reactionStat.isLiked) ||
                (type === CareerServices.ReactionType.Dislike && reactionStat.isDisliked)
            )
                return
            const payload = {
                jobId: job.id,
                reactionType: type
            }
            const _reactionStat = await careerService.reactJob(payload)
            if (_reactionStat) {
                setReactionStat(_reactionStat)
            }
        } catch (error) {
            handleError(error)
        }
    }

    const handleApplyJob = async () => {
        if (!!+job.isEmailApplied) {
            setApplyType("email")
        } else {
            setApplyType("system")
        }
        applyFormVisible.open()
    }

    const onSuccessApply = async (application: CareerServices.ApplicationEntity) => {
        applyFormVisible.close()
        history.push(`/career-services/applications/${application.id}`)
    }

    const renderExpandSection = () => {
        switch (activeSection) {
            case "message":
                return (
                    <MessagePanel
                        student={undefined} // To implement later
                        departmentId={BaseDepartmentId.CareerServices}
                        studentId={1}
                        onClose={handleCloseActiveSection}
                    />
                )
            default:
                return <div>{activeSection}</div>
        }
    }

    const renderAction = () => {
        return (
            <div className={styles.profileActionWrap}>
                <div
                    className={styles.reactionContainer}
                    onClick={() => handleReaction(CareerServices.ReactionType.Like)}>
                    {reactionStat.isLiked ? (
                        <FilledLikeIcon width={20} height={20} />
                    ) : (
                        <LikeIcon width={20} height={20} />
                    )}
                    <div className={styles.count}>({reactionStat.totalLikes})</div>
                </div>
                <div
                    className={styles.reactionContainer}
                    onClick={() => handleReaction(CareerServices.ReactionType.Dislike)}>
                    {reactionStat.isDisliked ? (
                        <FilledDislikeIcon width={20} height={20} />
                    ) : (
                        <DislikeIcon width={20} height={20} />
                    )}
                    <div className={styles.count}>({reactionStat.totalDislikes})</div>
                </div>
            </div>
        )
    }

    const renderJobDescription = () => {
        return (
            <div className={styles.jobDescription}>
                <div className={styles.descTitle}>Job Description</div>
                <div
                    className={cx(styles.descValue, {[styles.expandedDescValue]: !isStudent})}
                    dangerouslySetInnerHTML={{__html: job.description}}></div>
            </div>
        )
    }

    const renderMetadata = () => {
        return (
            <div className={styles.metadataWrap}>
                <div className={styles.metadata}>
                    {`Created on ${moment(job.createdAt).format(model.getUserDateFormat())}`}
                </div>
            </div>
        )
    }

    const renderActionForStudent = () => {
        if (job.userApplication) {
            return (
                <div className={styles.applicationStatusWrap}>
                    <Icon icon="CHECKMARK_CIRCLE" color="#18A957" className={styles.applicationAppliedIcon} />
                    <span className={styles.applicationStatus}>Application applied</span>
                </div>
            )
        }

        const isReady = !!(careerServiceStudent && careerServiceStudent.completedReadinessAt)
        return (
            <div className={styles.studentActionWrap}>
                <BaseButton
                    title="Apply"
                    className={styles.studentAction}
                    onClick={handleApplyJob}
                    disabled={!isReady}
                />
                {!!+job.isWebsiteApplied && (
                    <BaseButton
                        title="Apply on company site"
                        className={styles.studentAction}
                        variant="secondary"
                        onClick={() => {
                            window.open(job.websiteAppliedAddress, "_blank")
                        }}
                        disabled={!isReady}
                    />
                )}
                {!isReady && (
                    <span className={styles.readinessText}>
                        Your readiness score: {careerServiceStudent?.readinessPercentage || 0}%
                    </span>
                )}
            </div>
        )
    }

    return (
        <div className={styles.drawerWrap}>
            {/* Job Info */}
            <div className={styles.panelJobWrap}>
                <PanelNavigation onClose={onCollapsePanel} />
                <div className={styles.panelJobContainer}>
                    {isStudent && renderAction()}
                    <JobItemPanel job={job} />
                    {isStudent && renderActionForStudent()}
                    {renderJobDescription()}
                    {isStudent && <StudentCareerServiceNote notes={job.studentNotes} type="job" dataId={job.id} />}
                    {renderMetadata()}
                </div>
            </div>
            {/* Expanded action */}
            <div className={styles.panelWrap}>{renderExpandSection()}</div>
            {isStudent && (
                <ApplyJobForm
                    open={applyFormVisible.isVisible}
                    onClose={() => applyFormVisible.close()}
                    job={job}
                    onSuccess={onSuccessApply}
                    type={applyType}
                />
            )}
        </div>
    )
}
