import {get} from "lodash"
import {
    GradingSystemDetail,
    ParamsCreateGradingSystem,
    ParamsGradingSystem,
    ResponseDetailGradingSystem,
    ResponseGradingSystem
} from "types/gradingSystem"
import BaseService from "./Base"

class AcademicGradingSystem extends BaseService {
    async getListGradingSystem(params: ParamsGradingSystem): Promise<ResponseGradingSystem> {
        const url = "/v3/grading-template/list"
        const response = await this.post(url, params)
        return get(response, "data", {})
    }
    async deleteGradingSystem(ids: number[]): Promise<ResponseGradingSystem> {
        const url = "/v3/grading-template/delete"
        const response = await this.post(url, {gradingTemplateIds: ids})
        return get(response, "data", {})
    }
    async deleteGradingScales(ids: number[]): Promise<ResponseGradingSystem> {
        const url = "/v3/grading-template/deleteGradingScales"
        const response = await this.post(url, {ids: ids})
        return get(response, "data", {})
    }
    async deleteGradingElements(ids: number[]): Promise<ResponseGradingSystem> {
        const url = "/v3/grading-template/deleteGradingElements"
        const response = await this.post(url, {ids: ids})
        return get(response, "data", {})
    }
    async getDetailGradingSystem(id: number): Promise<ResponseDetailGradingSystem> {
        const url = `/v3/grading-template/get`
        const response = await this.post(url, {gradingTemplateId: id})
        return get(response, "data", {})
    }
    async updateGradingSystem(id: number, params: ParamsCreateGradingSystem): Promise<GradingSystemDetail> {
        const url = `/v3/grading-template/edit`
        const response = await this.post(url, {...params, gradingTemplateId: id})
        return get(response, "data", {})
    }
    async createGradingSystem(params: ParamsCreateGradingSystem): Promise<ResponseGradingSystem> {
        const url = "/v3/grading-template/create"
        const response = await this.post(url, params)
        return get(response, "data", {})
    }
}

export default AcademicGradingSystem
