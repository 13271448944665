import BaseService from "./Base"
import {ApiListResponse, ApiResponse} from "types/api"
import {AxiosResponse} from "axios"

class UserContactsServiceV3 extends BaseService {
    getAll = async (params = {}) => {
        const defaultParams = {
            range: {
                limit: 10,
                offset: 0
            },
            sort: {
                orderBy: "id",
                orderDir: "ASC"
            }
        }
        const {data: response}: AxiosResponse<ApiListResponse<any[]>> = await this.post("/v1/users/contacts/list", {
            ...defaultParams,
            ...params
        })
        if (!response.success) {
            throw response
        }
        response.data = response.data.map((contact) => {
            return contact
        })
        return response
    }

    getByUser = async (id: number): Promise<any | null> => {
        const {data: response}: AxiosResponse<ApiResponse<any[]>> = await this.post("/v1/users/contacts/list", {
            filter: {
                userId: [id]
            },
            linkedObjects: true
        })
        if (!response.success) {
            throw response
        }
        response.data = response.data.map((contact) => {
            return contact
        })
        return response
    }

    create = async (params) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/users/contacts/create", params)
        if (!response.success) {
            throw response
        }
        return response.data
    }

    update = async (id, params: any = {}) => {
        params.id = id
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/users/contacts/edit", params)
        if (!response.success) {
            throw response
        }
        return response.data
    }

    delete = async (params) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/users/contacts/delete", {
            userContactIds: [params]
        })
        if (!response.success) {
            throw response
        }
        return response.data
    }

    invite = async (params) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/users/contacts/invite", params)
        if (!response.success) {
            throw response
        }
        return response.data
    }
}
export default UserContactsServiceV3
