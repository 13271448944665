import React, {useState} from "react"
import {Popover} from "antd"
import cx from "classnames"
import moment from "moment"
import {getColorContrast} from "helpers"
import {EventPreviewContent} from "../EventContent"
import styles from "./MoreLinkContent.module.css"

export function MoreLinkContent(props) {
    const [isShowMoreEventPreview, setIsMoreShowEventPreview] = useState(false)
    const [activeEventIndex, setActiveEventIndex] = useState(0)
    const {
        moreLinkData,
        text,
        clickInfo,
        eventOptions,
        handleShowMoreEventPreview,
        handleShowEventPreview,
        onClickDateDetail,
        isWorkScheduleView,
        isShowGoing
    } = props

    const handleMoreEventVisibleChange = (visible) => {
        if (!visible) {
            clickInfo?.el?.parentElement?.classList?.remove(styles.highlightEventPreviewWrap)
            clickInfo?.el?.classList?.remove(styles.highlightEventPreview)
        } else {
            clickInfo?.el?.parentElement?.classList?.add(styles.highlightEventPreviewWrap)
            clickInfo?.el?.classList?.add(styles.highlightEventPreview)
        }
        setIsMoreShowEventPreview(visible)
        setTimeout(() => {
            handleShowMoreEventPreview(visible)
            // To trigger handleDateClick event happen before close
        }, 300)
    }

    const closeMoreEventPreview = () => {
        setTimeout(() => {
            setIsMoreShowEventPreview(false)
            handleShowMoreEventPreview(false)
            handleShowEventPreview(false)
            // To trigger handleDateClick event happen before close
        }, 300)
        clickInfo?.el?.parentElement?.classList?.remove(styles.highlightEventPreviewWrap)
        clickInfo?.el?.classList?.remove(styles.highlightEventPreview)
    }

    const handleClickDateDetail = (date) => {
        clickInfo?.el?.parentElement?.classList?.remove(styles.highlightEventPreviewWrap)
        clickInfo?.el?.classList?.remove(styles.highlightEventPreview)
        setTimeout(() => {
            handleShowMoreEventPreview(false)
            setIsMoreShowEventPreview(false)
            handleShowEventPreview(false)
            onClickDateDetail(date)
            // To trigger handleDateClick event happen before close
        }, 300)
    }

    const onChangeActiveEventIndex = (index) => {
        setActiveEventIndex(index)
    }

    const renderHeaderEventInfo = (eventInfo) => {
        const backgroundColor = "#fff"
        const {startDateTime} = eventInfo?.event?._def?.extendedProps || {}

        return (
            <>
                <div className={styles.dot} style={{background: eventInfo.event.backgroundColor}}></div>
                <div className={styles.headerEventInfoTitle} style={{color: getColorContrast(backgroundColor).color}}>
                    <span className={styles.headerEventInfoTitle__startTime}>
                        {moment(startDateTime).format("hh:mm A")}
                    </span>
                    <span>{eventInfo.event.title}</span>
                </div>
            </>
        )
    }

    const renderMoreEventContent = () => {
        if (!moreLinkData) {
            return null
        }
        const {allSegs, date} = moreLinkData
        return (
            <EventPreviewContent
                eventInfo={allSegs[activeEventIndex].event}
                eventOptions={eventOptions}
                closeEventPreview={closeMoreEventPreview}
                isWorkScheduleView={isWorkScheduleView}
                isShowGoing={isShowGoing}>
                <div className={styles.moreEventWrap}>
                    <div className={styles.moreEventHeaderLeft}>
                        <p className={styles.moreEventDayOfWeek}>{moment(date).format("ddd")}</p>
                        <p className={styles.moreEventDate} onClick={() => handleClickDateDetail(date)}>
                            {moment(date).format("DD")}
                        </p>
                    </div>
                    <div className={styles.moreEventHeaderRight}>
                        {allSegs.map((eventInfo, index) => (
                            <div
                                key={index}
                                className={cx(styles.moreEventHeaderRightItem, {
                                    [styles.moreEventHeaderRightItemActive]: index === activeEventIndex
                                })}
                                onClick={() => onChangeActiveEventIndex(index)}>
                                {renderHeaderEventInfo(eventInfo)}
                            </div>
                        ))}
                    </div>
                </div>
            </EventPreviewContent>
        )
    }

    return (
        <Popover
            overlayClassName="event-content-preview"
            visible={isShowMoreEventPreview}
            content={renderMoreEventContent}
            trigger="click"
            onVisibleChange={handleMoreEventVisibleChange}>
            {text}
        </Popover>
    )
}
