import BaseService from "./Base"
import {ApiListResponse, ApiListResponseResult, ApiResponse} from "types/api"
import {CoaTemplateDetails, CoaTemplateListItem, CreateCoaTemplatePayload, EditCoaTemplatePayload} from "types/coa"
import {AxiosResponse} from "axios"

export class CoaTemplatesService extends BaseService {
    getAll = async (params): Promise<ApiListResponseResult<(CoaTemplateListItem | CoaTemplateDetails)[]>> => {
        const {data: response}: AxiosResponse<ApiListResponse<(CoaTemplateListItem | CoaTemplateDetails)[]>> =
            await this.post("/v1/coa-templates/list", params)
        if (!response.success) {
            throw response
        }
        return {
            data: response.data,
            total: response.total
        }
    }

    search = async ({search}: {search: string}): Promise<CoaTemplateListItem[]> => {
        const {data: response}: AxiosResponse<ApiListResponse<CoaTemplateListItem[]>> = await this.post(
            "/v1/coa-templates/list",
            {
                filter: {search, status: "active"},
                range: {
                    page: 1,
                    pageSize: 20
                },
                sort: {
                    orderBy: "name",
                    orderDir: "ASC"
                }
            }
        )

        if (!response.success) {
            throw response
        }

        return response.data
    }

    getOne = async (coaTemplateId: number): Promise<CoaTemplateDetails | null> => {
        const {data: response}: AxiosResponse<ApiResponse<CoaTemplateDetails[]>> = await this.post(
            "/v1/coa-templates/list",
            {
                filter: {
                    id: [coaTemplateId]
                },
                linkedEntities: ["rates", "programs", "campuses"]
            }
        )

        if (!response.success) {
            throw response
        }

        return response.data[0] ?? null
    }

    create = async (payload: CreateCoaTemplatePayload) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/coa-templates/create", payload)

        if (!response.success) {
            throw response
        }
    }

    update = async (payload: EditCoaTemplatePayload) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/coa-templates/edit", payload)

        if (!response.success) {
            throw response
        }
    }

    deleteBulk = async (id: number[]) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/coa-templates/delete", {id})

        if (!response.success) {
            throw response
        }
    }
}
