export enum AddDropVariant {
    Date = "date",
    Hours = "hours"
}

export type TermDetails = {
    id: number
    name: string
    code: string
    sap: boolean | 0 | 1
    is_active: boolean | 0 | 1
    registration_start_date: string
    registration_end_date: string
    start_date: string
    end_date: string
    endDate?: string
    startDate?: string
    add_drop_variant: AddDropVariant
    add_drop_date: string
    numberOfStudents: number
    numberOfCourses: number
    totalCourseHours: number
    add_drop_hours: number
    sisId?: string | number | null
    vDeleted?: string | null
    campusIds: number[]
    academicTrackIds: number[]
    programs: [
        {
            program_id: number
            program_version_id: number
            projected_end_date: string
            startDate: string
        }
    ]
    courses: [
        {
            course_id: number
            academicTrackIds: number[]
        }
    ]
    parameterNumber: number | null
    academicTracks: any[]
    campuses: any[]
}

export type TermCourseDetails = {
    id: number
    termCourseId: number
    code: string
    name: string
    // @todo: add other properties
}

export type CourseScheduleData = Partial<{
    campusIds: number
    capacity: number
    counter: any
    course_last_date: string
    created_at: string
    id: number
    schedule_events: any
    schedule_rules: any
    instructors: any
    lead_instructors: any
    locationId: number
    online_url: string
    schedule_suffix: string
    status: string
    stream_key: string
    term_courses_id: number
    timezone: string
    event_actions: any
    schedule_rule: any
    term_id: number
    course_id: number
    campuses: any[]
    location: any
    key: string
    active: boolean
    selected: boolean
    isWeeklyLessonNotFixed?: boolean
    registrationHold: boolean
    lmsOverrideCode: string
    firstDayOfCourse: string
    academicTrackId: number
}>

export enum AddDropDate {
    DATE = "date",
    HOURS = "hours"
}

export type ScheduleInstructor = {
    userId: number
    firstName: string
    lastName: string
    fullName: string
    name: string
    type: string
    title: string
    email: string
    profileId: number
    photo: string
    programName: string
    currentMajor: string
    customProfileId: string
}

export type ScheduleCampus = {id: number; name: string}

export type ScheduleEvent = {
    object_id: number
    group_id: number
    calendar_id: number
    type: string
    name: string
    description: string
    owner_user_id: number
    created_at: string
    start_at_utc: string
    end_at_utc: string
    start_at_wall: string
    start_at_timezone: string
    duration: number
    json_data: {
        color: string
        term_id: number
        course_id: number
        eventType: string
        schedule_id: number
        sub_module_id: number
        daysOfCourse?: string
    }
    chat_channel_id: number
    rrule: string
    appointment_slot: number
    courseId: number
    courseCode: string
    courseName: string
}

export type ScheduleCounter = {
    classroom: number
    lab: number
    test: number
    others: number
}

export type Schedule = {
    key?: string
    termSisId: number
    courseSisId: number
    id: number
    term_id: number
    course_id: number
    termCourseCode: string
    academicTrackId: number
    created_at: string
    updated_at: string
    term_courses_id: number
    calendar_id: number
    locationId: number
    status: string
    timezone: string
    schedule_suffix: string
    course_last_date: string
    capacity: string
    online_url: string
    stream_key: string
    created_by: string
    updated_by: string
    deleted_at: string
    sisId: number
    vDeleted: string
    checked: number
    configured: number
    disabled: number
    crossScheduleHidden: number
    firstDayOfCourse: string
    lmsOverrideCode: null
    registrationHold: number
    instructors: ScheduleInstructor[]
    lead_instructors: ScheduleInstructor[]
    location: string
    campus: ScheduleCampus[]
    schedule_events: ScheduleEvent
    counter: ScheduleCounter
}

export type TermCalendarProgram = {
    id: number
    termId: number
    programId: number
    programVersionId: number
    createdAt: string
    updatedAt: string
    deletedAt: string
    name: string
    code: string
    version_name: string
    version_code: string
    campusName: string
    campusId: number
    academicTrackId: number
    academicTrackName: string
}

export type TermCourse = {
    termCourseId: number
    courseId: number
    code: string
    name: string
    updatedAt: string
    academicTrackIds: number[]
}
