/* eslint-disable react-hooks/exhaustive-deps */
import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState} from "react"
import {Button, Col, Row} from "antd"
import Text from "antd/lib/typography/Text"
import classNames from "classnames"
import {get} from "lodash"
import {degreeAuditService, generalSettingsService, studentServicesService, userProfileServiceV3} from "services"
import styles from "./ProgressReport.module.css"
import {DegreeAuditProgressData} from "types/degreeAudit"
import {useHistory} from "react-router-dom"
import {useModel, useVisible} from "hooks"
import {checkPermission, handleError, roundTwoDigits} from "helpers"
import {GeneralSettingField} from "types/settings/general-setting"
import {useQuery} from "@tanstack/react-query"
import {Course} from "types/courses"
import {YesNo} from "types/common"
import {BaseDatePicker} from "components/DateTimePicker"
import {BaseButton, BaseLoading} from "components"
import {ConfirmPopup} from "uiKit"
import {NewStudentStatusSelectFilter} from "components/NewStudentStatusSelect"
import {Auth} from "types/auth"
import moment from "moment"
import {KlassDropdown} from "components/Select"
import {StudentStatus} from "types/students"
import {toDateOnly} from "sections/academics/instructional/common/utils"
import {Permissions} from "@edular/permissions"

export type ProgressReportFunc = {
    getData: () => void
}

const degreeConferredStatusOptions = Object.keys(Auth.DegreeConferredStatus).map((key) => ({
    id: Auth.DegreeConferredStatus[key],
    name: key
}))

const ProgressReport = (props, ref) => {
    const [loading, setLoading] = useState(false)
    const [loadingPage, setLoadingPage] = useState(false)
    const {studentId, departmentId, profileInfo, getProfileInfo} = props
    const [temporaryConferredData, setTemporaryConferredData] = useState<{
        degreeConferredDate?: string
        degreeConferredStatus?: {
            id: Auth.DegreeConferredStatus
            name: string
        }
        status?: StudentStatus
    }>({
        status: undefined,
        degreeConferredDate: undefined,
        degreeConferredStatus: {
            id: Auth.DegreeConferredStatus.NA,
            name: "NA"
        }
    })
    const confirmGraduatedPopup = useVisible()

    useEffect(() => {
        if (profileInfo) {
            setTemporaryConferredData({
                degreeConferredDate: profileInfo.actualEndDate,
                degreeConferredStatus: profileInfo.degreeConferredStatus
                    ? degreeConferredStatusOptions.find((item) => item.id === profileInfo.degreeConferredStatus)
                    : undefined,
                status: profileInfo.status
            })
        }
    }, [profileInfo])

    const onClose = useCallback(() => {
        setTemporaryConferredData({
            degreeConferredDate: profileInfo.actualEndDate,
            degreeConferredStatus: profileInfo.degreeConferredStatus
                ? degreeConferredStatusOptions.find((item) => item.id === profileInfo.degreeConferredStatus)
                : undefined,
            status: profileInfo.status
        })
        confirmGraduatedPopup.close()
    }, [profileInfo])

    const model = useModel()
    const [progressData, setProgressData] = useState<DegreeAuditProgressData>({
        completed: 0,
        distEdHours: 0,
        attempted: 0,
        appliedToDegree: 0,
        degreeRequirement: 0,
        totalTransferApplied: 0
    })
    const [progressReportSettings, setProgressReportSettings] = useState({
        isSystemDefault: true,
        isBreakDownBySubmodules: false
    })
    const history = useHistory()
    const generalSettingsQuery = useQuery(
        ["sap-academic-setting"],
        async () => {
            return await generalSettingsService.getSettings([
                GeneralSettingField.ProgressReportSystemDefault,
                GeneralSettingField.ProgressReportSubmodulesBreakDown
            ])
        },
        {
            initialData: []
        }
    )

    useEffect(() => {
        if (!generalSettingsQuery.isFetched) return
        const selectedProgressReport = generalSettingsQuery.data?.filter((item) => item.value === YesNo.Yes)
        const systemDefault = !!selectedProgressReport.find(
            (item) => item.field === GeneralSettingField.ProgressReportSystemDefault
        )
        const submodulesBreakDown = !!selectedProgressReport.find(
            (item) => item.field === GeneralSettingField.ProgressReportSubmodulesBreakDown
        )
        setProgressReportSettings({
            isSystemDefault: systemDefault,
            isBreakDownBySubmodules: submodulesBreakDown
        })
    }, [generalSettingsQuery.isFetched, generalSettingsQuery.data])

    const getData = useCallback(async () => {
        try {
            const response = await degreeAuditService.getProgress({studentProfileId: studentId})
            const data = response.data
            setProgressData(data)
        } catch (err) {}
    }, [studentId])

    useImperativeHandle(
        ref,
        () => ({
            getData: getData
        }),
        []
    )

    useEffect(() => {
        getData()
    }, [])

    const onClickProgressReport = (
        transcriptType:
            | GeneralSettingField.ProgressReportSystemDefault
            | GeneralSettingField.ProgressReportSubmodulesBreakDown
    ) => {
        const type = get(profileInfo, ["currentMajorVersionId", "type"])
        history.push(
            `/academics/registrar/student/${studentId}/progress-report/${departmentId}/${type}/${transcriptType}`
        )
    }

    const onClickGenerateTranscript = (
        transcriptType:
            | GeneralSettingField.ProgressReportSystemDefault
            | GeneralSettingField.ProgressReportSubmodulesBreakDown
    ) => {
        const type = get(profileInfo, ["currentMajorVersionId", "type"])
        history.push(
            `/academics/registrar/student/${studentId}/generate-transcript/${departmentId}/${type}/${transcriptType}`
        )
    }

    const onClickGraduated = useCallback(async () => {
        try {
            setLoading(true)
            await studentServicesService.markStudentAsAlumniOrStudent({
                studentProfileId: studentId,
                studentStatusIdToAssign: temporaryConferredData.status.statusId,
                degreeConferredDate: temporaryConferredData.degreeConferredDate
                    ? moment(temporaryConferredData.degreeConferredDate).format("YYYY-MM-DD")
                    : null,
                degreeConferredStatus: temporaryConferredData.degreeConferredStatus.id
            })
            await getProfileInfo()
            setLoading(false)
            confirmGraduatedPopup.close()
        } catch (err) {
            handleError(err)
            setLoading(false)
        }
    }, [getProfileInfo, temporaryConferredData, studentId])

    const onUpdateConferredDate = useCallback(
        async (value: moment.Moment) => {
            try {
                setLoadingPage(true)
                await userProfileServiceV3.update({
                    id: studentId,
                    actualEndDate: value ? moment(value).format("YYYY-MM-DD") : null
                })
                await getProfileInfo()
                setLoadingPage(false)
            } catch (err) {
                handleError(err)
                setLoadingPage(false)
            }
        },
        [getProfileInfo, temporaryConferredData, studentId]
    )

    const type = get(profileInfo, ["currentMajorVersionId", "type"])
    const appliedToDegree = roundTwoDigits(get(progressData, ["appliedToDegree"]) ?? 0)
    const degreeRequirement = roundTwoDigits(get(progressData, ["degreeRequirement"]) ?? 0)
    const disabledGraduated =
        appliedToDegree < degreeRequirement ||
        ![
            Auth.StudentState.Student,
            Auth.StudentState.Alumni,
            Auth.StudentState.PermanentOut,
            Auth.StudentState.TemporaryOut
        ].includes(profileInfo?.state)
    const hasPermissionToViewProgressReport = useMemo(() => {
        return checkPermission({staff: [Permissions.Staff.Academics.Registrar.Students.ProgressReport.View]}, model)
    }, [model])
    const hasPermissionToViewOfficialTranscript = useMemo(() => {
        return checkPermission({staff: [Permissions.Staff.Academics.Registrar.Students.OfficialTranscript.View]}, model)
    }, [model])

    return (
        <div className={styles.wrap}>
            <div className={styles.headerProgressReport}>
                {model.isStaffOrAdmin() ? (
                    <div className={styles.graduatedContainer}>
                        <p className={styles.degreeConferred}>
                            Degree Conferred:{" "}
                            <span style={{fontWeight: "bold"}}>
                                {degreeConferredStatusOptions.find(
                                    (item) => item.id === profileInfo?.degreeConferredStatus
                                )?.name ?? "NA"}
                            </span>
                        </p>
                        {profileInfo?.actualEndDate ? (
                            <div className={styles.degreeConferredDateTitle}>
                                <p className={styles.degreeConferred}>Conferred Date: </p>
                                <BaseDatePicker
                                    value={
                                        profileInfo.actualEndDate
                                            ? moment(toDateOnly(profileInfo.actualEndDate))
                                            : undefined
                                    }
                                    disabled={!profileInfo.actualEndDate}
                                    placeholder="CONFERRED DATE"
                                    className={styles.degreeDate}
                                    onChange={onUpdateConferredDate}
                                    allowClear
                                />
                            </div>
                        ) : null}
                        <BaseButton
                            title={"EDIT"}
                            onClick={!disabledGraduated && confirmGraduatedPopup.open}
                            variant="secondary"
                            disabled={disabledGraduated}
                            className={classNames(styles.buttonReportProgress, {
                                [styles.disabledGraduated]: disabledGraduated
                            })}
                        />
                    </div>
                ) : (
                    <div className={styles.graduatedContainer} />
                )}
                <div className={styles.buttonProgress}>
                    <>
                        {progressReportSettings.isSystemDefault && hasPermissionToViewProgressReport ? (
                            <Button
                                onClick={() => onClickProgressReport(GeneralSettingField.ProgressReportSystemDefault)}
                                className={styles.buttonReportProgress}>
                                <span className={styles.titleReportProgress}>Progress Report</span>
                                <p className={styles.subTitleReportProgress}>System default</p>
                            </Button>
                        ) : null}
                        {type === Course.CourseType.Clock &&
                        progressReportSettings.isBreakDownBySubmodules &&
                        hasPermissionToViewProgressReport ? (
                            <Button
                                style={{marginLeft: 10}}
                                onClick={() =>
                                    onClickProgressReport(GeneralSettingField.ProgressReportSubmodulesBreakDown)
                                }
                                className={styles.buttonReportProgress}>
                                <span className={styles.titleReportProgress}>Progress Report</span>
                                <p className={styles.subTitleReportProgress}>
                                    With submodules breakdown (Clock hour only)
                                </p>
                            </Button>
                        ) : null}
                    </>
                    {hasPermissionToViewOfficialTranscript ? (
                        <>
                            {progressReportSettings.isSystemDefault ? (
                                <Button
                                    style={{marginLeft: 10}}
                                    onClick={() =>
                                        onClickGenerateTranscript(GeneralSettingField.ProgressReportSystemDefault)
                                    }
                                    className={styles.buttonReportProgress}>
                                    <span className={styles.titleReportProgress}>Generate Transcript</span>
                                    <p className={styles.subTitleReportProgress}>System default</p>
                                </Button>
                            ) : null}
                            {type === Course.CourseType.Clock && progressReportSettings.isBreakDownBySubmodules ? (
                                <Button
                                    style={{marginLeft: 10}}
                                    onClick={() =>
                                        onClickGenerateTranscript(GeneralSettingField.ProgressReportSubmodulesBreakDown)
                                    }
                                    className={styles.buttonReportProgress}>
                                    <span className={styles.titleReportProgress}>Generate Transcript</span>
                                    <p className={styles.subTitleReportProgress}>
                                        With submodules breakdown (Clock hour only)
                                    </p>
                                </Button>
                            ) : null}
                        </>
                    ) : null}
                </div>
            </div>
            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <div className={classNames(styles.itemWrap)} style={{flexDirection: "row"}}>
                        <div style={{marginRight: 10}}>
                            <div className={styles.itemHeader}>
                                <Text className={styles.titleProgress}>DEGREE REQUIREMENT</Text>
                            </div>
                            <div className={styles.itemProgress}>
                                <Text className={classNames(styles.progressNumber)}>{degreeRequirement}</Text>
                            </div>
                        </div>
                        <div>
                            <div className={styles.itemHeader}>
                                <Text className={styles.titleProgress}>APPLIED TO DEGREE</Text>
                            </div>
                            <div className={styles.itemProgress}>
                                <Text className={classNames(styles.progressNumber)}>{appliedToDegree}</Text>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={classNames(styles.itemWrap)}>
                        <div className={styles.itemHeader}>
                            <div className={styles.subHeader}>
                                <Text className={classNames(styles.titleProgress)}>ATTEMPTED</Text>
                                <div className={styles.itemProgress}>
                                    <Text className={classNames(styles.progressNumber)}>
                                        {roundTwoDigits(get(progressData, ["attempted"], 0))}
                                    </Text>
                                </div>
                            </div>
                            <div className={styles.subHeader}>
                                <Text className={classNames(styles.titleProgress)}>COMPLETED</Text>
                                <div className={styles.itemProgress}>
                                    <Text className={classNames(styles.progressNumber)}>
                                        {roundTwoDigits(get(progressData, ["completed"]) ?? 0)}
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={classNames(styles.itemWrap)}>
                        <div className={styles.itemHeader}>
                            <div className={styles.subHeader}>
                                <Text className={classNames(styles.titleProgress)}>TRANSFER</Text>
                                <div className={styles.itemProgress}>
                                    <Text className={classNames(styles.progressNumber)}>
                                        {roundTwoDigits(get(progressData, ["totalTransfer"]) ?? 0)}
                                    </Text>
                                </div>
                            </div>
                            <div className={styles.subHeader}>
                                <Text className={classNames(styles.titleProgress)}>APPLIED</Text>
                                <div className={styles.itemProgress}>
                                    <Text className={classNames(styles.progressNumber)}>
                                        {roundTwoDigits(get(progressData, ["totalTransferApplied"]) ?? 0)}
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={classNames(styles.itemWrap)}>
                        <div className={styles.itemHeader}>
                            <div className={styles.subHeader}>
                                <Text className={classNames(styles.titleProgress)}>PREVIOUSLY{"\n"}COMPLETED</Text>
                                <div className={styles.itemProgress}>
                                    <Text className={classNames(styles.progressNumber)}>
                                        {roundTwoDigits(get(progressData, ["totalPreviousCompleted"]) ?? 0)}
                                    </Text>
                                </div>
                            </div>
                            <div className={styles.subHeader}>
                                <Text className={classNames(styles.titleProgress)}>APPLIED</Text>
                                <div className={styles.itemProgress}>
                                    <Text className={classNames(styles.progressNumber)}>
                                        {roundTwoDigits(get(progressData, ["totalPreviousCompletedApplied"]) ?? 0)}
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <ConfirmPopup
                isVisible={confirmGraduatedPopup.isVisible}
                title={"EDIT"}
                onClose={onClose}
                loading={loading}
                onConfirm={onClickGraduated}>
                <div className={styles.graduatedStatus}>
                    <p className={styles.label}>Degree conferred</p>
                    <KlassDropdown
                        options={degreeConferredStatusOptions}
                        value={temporaryConferredData.degreeConferredStatus}
                        onChange={(newValue) =>
                            setTemporaryConferredData({...temporaryConferredData, degreeConferredStatus: newValue})
                        }
                    />
                    <span className={styles.label}>Change student status to</span>
                    <NewStudentStatusSelectFilter
                        value={temporaryConferredData.status}
                        onChange={(value) => setTemporaryConferredData({...temporaryConferredData, status: value})}
                        isMulti={false}
                        forState={[Auth.StudentState.Alumni, Auth.StudentState.Student]}
                    />
                    <span className={styles.label}>Conferred date</span>
                    <BaseDatePicker
                        value={
                            temporaryConferredData.degreeConferredDate
                                ? moment(toDateOnly(temporaryConferredData.degreeConferredDate))
                                : undefined
                        }
                        allowClear
                        className={styles.temporaryDegreeDate}
                        onChange={(value) =>
                            setTemporaryConferredData({
                                ...temporaryConferredData,
                                degreeConferredDate: value?.toISOString()
                            })
                        }
                    />
                </div>
            </ConfirmPopup>
            {loadingPage && <BaseLoading isShow />}
        </div>
    )
}

export default forwardRef(ProgressReport)
