import {useVisible} from "hooks"
import {useState} from "react"
import StudentInfoDrawer from "uiKit/StudentInfoDrawer/StudentInfoDrawer"
import {StudentInfoPanel, StudentInfoPanelProps} from "uiKit/StudentInfoPanel"
import {CommunicationProvider} from "context/CommunicationContext"
import {ChatRoomProvider} from "context/ChatRoomContext"

type Props = {
    data: StudentInfoPanelProps
    onClose: () => void
}

export const StudentInfoSidebar = (props: Props) => {
    const {data, onClose} = props
    const drawerVisible = useVisible(false)
    const [activeSection, setActiveSection] = useState<string>()

    const onOpenDrawer = (_activeSection: string) => {
        setActiveSection(_activeSection)
        drawerVisible.open()
    }

    return (
        <CommunicationProvider>
            <ChatRoomProvider>
                <StudentInfoPanel {...data} onClose={onClose} onOpenDrawer={onOpenDrawer} />
                <StudentInfoDrawer
                    {...data}
                    displayDrawer={drawerVisible.isVisible}
                    onCloseDrawer={drawerVisible.close}
                    activeSection={activeSection}
                />
            </ChatRoomProvider>
        </CommunicationProvider>
    )
}
