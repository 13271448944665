import React from "react"
import styles from "./ProgressReportPdf.module.css"
import {DegreeAuditProgressData} from "types/degreeAudit"
import {Auth} from "types/auth"
import {get} from "lodash"
import moment from "moment-timezone"
import {useModel} from "hooks"
import {toDateOnly} from "sections/academics/instructional/common/utils"
import {getFieldLabel, roundTwoDigits} from "helpers"
import {useTranslation} from "react-i18next"
import {Settings} from "types/settings"

type StudentInfoClockProps = {
    progressData: DegreeAuditProgressData
    studentInfo: Auth.DepartmentStudent
    missedStats: {
        makeUpHours: number
        missedHours: number
    }
    cgpa: number
}

const StudentInfoClock = (props: StudentInfoClockProps) => {
    const {studentInfo, progressData, missedStats, cgpa} = props
    const {isDropped, isCancelled, withdrawalDate, cancelDate} = studentInfo || {}
    const model = useModel()
    const startDate = get(studentInfo, ["startDate"])
    const academicStatus = get(studentInfo, ["studentStatus", "name"], "")
    const {t} = useTranslation(["common", "user"])

    return (
        <div className={styles.studentInfo}>
            <span className={styles.degreeText}>DEGREE</span>
            <div className={styles.studentInfoDetail}>
                <div className={styles.blockDetail}>
                    <div className={styles.sectionDetail}>
                        <span className={styles.titleInfo}>DOB</span>
                        <span className={styles.textInfo}>
                            {studentInfo?.birthDate
                                ? moment(toDateOnly(new Date(studentInfo.birthDate))).format(model.getUserDateFormat())
                                : ""}
                        </span>
                    </div>
                    <div className={styles.sectionDetail}>
                        <span className={styles.titleInfo}>
                            {getFieldLabel(model, Settings.GeneralLabel.Program, t("studentInfo.program"))}
                        </span>
                        <span className={styles.textInfo}>{get(studentInfo, ["programName"], "")}</span>
                    </div>
                    <div className={styles.sectionDetail}>
                        <span className={styles.titleInfo}>Degree credentials</span>
                        <span className={styles.textInfo}>{get(studentInfo, ["degreeCredential"], "")}</span>
                    </div>
                    <div className={styles.sectionDetail}>
                        <span className={styles.titleInfo}>
                            {getFieldLabel(model, Settings.GeneralLabel.Campus, t("studentInfo.campus"))}
                        </span>
                        <span className={styles.textInfo}>{get(studentInfo, ["campuses", 0], "")}</span>
                    </div>
                    <div className={styles.sectionDetail}>
                        <span className={styles.titleInfo}>Status</span>
                        {academicStatus?.length ? (
                            <div className={styles.statusInfo}>
                                <span className={styles.statusText}>{academicStatus}</span>
                            </div>
                        ) : (
                            <div />
                        )}
                    </div>
                    <div className={styles.sectionDetailWithoutBorder} />
                </div>

                <div className={styles.blockDetail} style={{marginLeft: 30, marginRight: 30}}>
                    <div className={styles.sectionDetail}>
                        <span className={styles.titleInfo}>
                            {getFieldLabel(model, Settings.GeneralLabel.StartDate, t("studentInfo.startDate"))}
                        </span>
                        <span className={styles.textInfo}>
                            {startDate ? moment(toDateOnly(startDate)).format(model.getUserDateFormat()) : ""}
                        </span>
                    </div>
                    <div className={styles.sectionDetail}>
                        <span className={styles.titleInfo}>Conferred Date</span>
                        <span className={styles.textInfo}>
                            {studentInfo?.degreeConferredDate
                                ? moment(toDateOnly(studentInfo.degreeConferredDate)).format(model.getUserDateFormat())
                                : "NA"}
                        </span>
                    </div>
                    {isDropped || isCancelled ? (
                        <div className={styles.sectionDetail}>
                            <span className={styles.titleInfo}>Withdrawal Date</span>
                            <span className={styles.textInfo}>
                                {withdrawalDate
                                    ? moment(toDateOnly(withdrawalDate)).format(model.getUserDateFormat())
                                    : moment(toDateOnly(cancelDate)).format(model.getUserDateFormat())}
                            </span>
                        </div>
                    ) : null}
                    <div className={styles.sectionDetail}>
                        <span className={styles.titleInfo}>
                            {getFieldLabel(model, Settings.GeneralLabel.Campus, t("studentInfo.campus"))} address
                        </span>
                        <span className={styles.textInfo}>{get(studentInfo, ["campusAddresses", 0], "")}</span>
                    </div>
                    <div className={styles.sectionDetail}>
                        <span className={styles.titleInfo}>
                            {getFieldLabel(model, Settings.AcademicLabel.Cgpa, "CGPA")}
                        </span>
                        <span className={styles.textInfo}>{cgpa}</span>
                    </div>
                    <div className={styles.sectionDetailWithoutBorder} />
                    <div className={styles.sectionDetailWithoutBorder} />
                </div>

                <div className={styles.blockDetail}>
                    <div className={styles.sectionDetail}>
                        <span className={styles.titleInfo}>Degree Requirement</span>
                        <span className={styles.textInfo}>{roundTwoDigits(progressData?.degreeRequirement ?? 0)}</span>
                    </div>
                    <div className={styles.sectionDetail}>
                        <span className={styles.titleInfo}>Applied To Degree</span>
                        <span className={styles.textInfo}>{roundTwoDigits(progressData?.appliedToDegree ?? 0)}</span>
                    </div>
                    <div className={styles.sectionDetail}>
                        <span className={styles.titleInfo}>
                            {getFieldLabel(model, Settings.AcademicLabel.AttemptedHours, "Total attempted hours")}
                        </span>
                        <span className={styles.textInfo}>{roundTwoDigits(progressData?.attempted ?? 0)}</span>
                    </div>
                    <div className={styles.sectionDetail}>
                        <span className={styles.titleInfo}>
                            {getFieldLabel(model, Settings.AcademicLabel.TotalHoursCompleted, "Total hours to date")}
                        </span>
                        <span className={styles.textInfo}>{roundTwoDigits(progressData?.completed ?? 0)}</span>
                    </div>
                    <div className={styles.sectionDetail}>
                        <span className={styles.titleInfo}>Transfer Credits</span>
                        <span className={styles.textInfo}>
                            {roundTwoDigits(progressData?.totalTransferApplied ?? 0)}
                        </span>
                    </div>
                    <div className={styles.sectionDetail}>
                        <span className={styles.titleInfo}>Previously Completed</span>
                        <span className={styles.textInfo}>
                            {roundTwoDigits(progressData?.totalPreviousCompletedApplied ?? 0)}
                        </span>
                    </div>

                    <div className={styles.sectionDetail}>
                        <span className={styles.titleInfo}>
                            {getFieldLabel(model, Settings.AcademicLabel.MissedAttendanceHours, "Total missed hours")}
                        </span>
                        <span className={styles.textInfo}>{roundTwoDigits(missedStats.missedHours ?? 0)}</span>
                    </div>
                    <div className={styles.sectionDetail}>
                        <span className={styles.titleInfo}>
                            {getFieldLabel(model, Settings.AcademicLabel.MakeUpAttendanceHours, "Total makeup hours")}
                        </span>
                        <span className={styles.textInfo}>{roundTwoDigits(missedStats.makeUpHours ?? 0)}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentInfoClock
