export const convertSvgElementToDataUrl = (svgElement: SVGElement): Promise<string> => {
    return new Promise((resolve) => {
        const canvas = document.createElement("canvas")
        const ctx = canvas.getContext("2d")
        const svgString = new XMLSerializer().serializeToString(svgElement)

        const img = new Image()
        img.onload = () => {
            canvas.width = img.width
            canvas.height = img.height
            ctx.drawImage(img, 0, 0)
            resolve(canvas.toDataURL("image/png"))
        }
        img.src = "data:image/svg+xml;base64," + btoa(svgString)
    })
}
