import React from "react"
import {useTranslation} from "react-i18next"
import {useEdularModulesContext, Modules} from "@edular/modules"
import {Icon} from "components/Icon"
import {BaseButton, BaseInput, BasePopup} from "components"
import {toastError} from "helpers"
import {UserDocument} from "types/user-documents"
import ActionRowItem from "uiKit/table/parts/ActionRowItem"
import TaskSelect from "components/TaskSelect"
import DocumentSelect from "components/DocumentSelect"
import StudentChecklistSelect from "components/StudentChecklistSelect"
import DocumentForm from "sections/Documents/DocumentForm"
import styles from "./LinksSection.module.css"
import {Task} from "types/tasks"
import {StudentActivity} from "types/activity"
import StudentActivitySelect from "components/StudentActivitySelect"
import classNames from "classnames"
import {DisplayMode} from "types/common"

type Props = {
    profileIds?: number[]
    maxTasks?: number
    tasks?: Task[]
    onTasksChange?: (tasks: Task[]) => void
    maxChecklists?: number
    checklists?: any[]
    onChecklistsChange?: (checklists: any[]) => void
    maxDocuments?: number
    documents?: UserDocument[]
    onDocumentsChange?: (documents: UserDocument[]) => void
    maxActivities?: number
    activities?: StudentActivity[]
    onActivitiesChange?: (activities: StudentActivity[]) => void
    displayMode?: DisplayMode
    canEditLinks?: boolean
}

const LinksSection = ({
    profileIds,
    maxTasks,
    tasks = [],
    onTasksChange,
    maxChecklists,
    checklists = [],
    onChecklistsChange,
    maxDocuments,
    documents = [],
    onDocumentsChange,
    maxActivities,
    activities = [],
    onActivitiesChange,
    displayMode = "normal",
    canEditLinks = true
}: Props) => {
    const {t} = useTranslation(["common"])
    const [isAddingActivity, setAddingActivity] = React.useState(false)
    const [isAddingTask, setAddingTask] = React.useState(false)
    const [isAddingChecklist, setAddingChecklist] = React.useState(false)
    const [isAddingDocument, setAddingDocument] = React.useState(false)
    const [isCreatingDocument, setCreatingDocument] = React.useState(false)
    const {isModuleEnable} = useEdularModulesContext()
    const isColumnDisplay = displayMode === "panel"
    const profileId = profileIds?.[0]

    const openSelectForAdding = React.useCallback(
        (type: "task" | "activity" | "checklist" | "document") => {
            if (!profileId) {
                toastError(t("links.profileIdRequired"))
                return
            }
            switch (type) {
                case "activity":
                    setAddingActivity(true)
                    break
                case "task":
                    setAddingTask(true)
                    break
                case "checklist":
                    setAddingChecklist(true)
                    break
                case "document":
                    setAddingDocument(true)
                    break
            }
        },
        [t, profileId]
    )

    const openDocumentModal = React.useCallback(() => {
        if (!profileId) {
            toastError(t("links.profileIdRequired"))
            return
        }
        setCreatingDocument(true)
    }, [t, profileId])

    const closeDocumentModal = React.useCallback(() => setCreatingDocument(false), [])

    const handleDocumentSave = React.useCallback(
        (document?: UserDocument) => {
            if (document) {
                if (!documents.find(({userDocumentId}) => userDocumentId === document.userDocumentId)) {
                    onDocumentsChange?.([...documents, document])
                }
            }
            closeDocumentModal()
        },
        [closeDocumentModal, documents, onDocumentsChange]
    )

    const renderTasks = () => {
        return (
            <>
                {tasks.map((task) =>
                    !task ? null : (
                        <div
                            className={classNames(styles.linkContent, {[styles.linkContentPanel]: isColumnDisplay})}
                            key={task.taskId}>
                            <div className={styles.linkTypeContainer}>
                                <Icon icon="LINK" className={styles.linkTypeIcon} color="var(--black-200)" />
                                <span className={styles.linkTypeText}>Task</span>
                            </div>
                            <div className={styles.linkValueContainer}>
                                <BaseInput value={task.name} className={styles.linkInput} readOnly />
                                {canEditLinks && (
                                    <ActionRowItem
                                        data={task}
                                        menuActions={[
                                            {
                                                title: t("links.removeLink"),
                                                icon: "DELETE",
                                                action: (item) =>
                                                    onTasksChange?.(tasks.filter(({taskId}) => taskId !== item.taskId))
                                            }
                                        ]}
                                    />
                                )}
                            </div>
                        </div>
                    )
                )}

                {(!maxTasks || maxTasks > tasks.length) && !!onTasksChange && canEditLinks && (
                    <div className={classNames(styles.linkContent, {[styles.linkContentPanel]: isColumnDisplay})}>
                        <div className={classNames(styles.linkTypeContainer, styles.add)}>
                            <Icon icon="LINK" className={styles.linkTypeIcon} color="var(--whitebase)" />
                            <span className={styles.linkTypeText}>Task</span>
                        </div>
                        <div className={styles.linkAddContainer}>
                            {isAddingTask ? (
                                <TaskSelect
                                    profileId={profileId}
                                    onChange={(task) => {
                                        if (!tasks.find(({taskId}) => taskId === task.taskId)) {
                                            onTasksChange?.([...tasks, task])
                                        }
                                        setAddingTask(false)
                                    }}
                                    seletedTaskIds={tasks.map((task) => task.taskId)}
                                />
                            ) : (
                                <BaseButton
                                    title={t("links.linkToExisting")}
                                    variant="secondary"
                                    onClick={() => openSelectForAdding("task")}
                                />
                            )}
                        </div>
                    </div>
                )}
            </>
        )
    }

    const renderActivities = () => {
        if (!isModuleEnable(Modules.ActivitiesAndFollowups)) {
            return null
        }
        return (
            <>
                {activities.map((activity) =>
                    !activity ? null : (
                        <div
                            className={classNames(styles.linkContent, {[styles.linkContentPanel]: isColumnDisplay})}
                            key={activity.studentActivityId}>
                            <div className={styles.linkTypeContainer}>
                                <Icon icon="LINK" className={styles.linkTypeIcon} color="var(--black-200)" />
                                <span className={styles.linkTypeText}>Activity</span>
                            </div>
                            <div className={styles.linkValueContainer}>
                                <BaseInput value={activity.name} className={styles.linkInput} readOnly />
                                {canEditLinks && (
                                    <ActionRowItem
                                        data={activity}
                                        menuActions={[
                                            {
                                                title: t("links.removeLink"),
                                                icon: "DELETE",
                                                action: (item) =>
                                                    onActivitiesChange?.(
                                                        activities.filter(
                                                            ({studentActivityId}) =>
                                                                studentActivityId !== item.studentActivityId
                                                        )
                                                    )
                                            }
                                        ]}
                                    />
                                )}
                            </div>
                        </div>
                    )
                )}

                {(!maxActivities || maxActivities > activities.length) && !!onActivitiesChange && canEditLinks && (
                    <div className={classNames(styles.linkContent, {[styles.linkContentPanel]: isColumnDisplay})}>
                        <div className={classNames(styles.linkTypeContainer, styles.add)}>
                            <Icon icon="LINK" className={styles.linkTypeIcon} color="var(--whitebase)" />
                            <span className={styles.linkTypeText}>Activity</span>
                        </div>
                        <div className={styles.linkAddContainer}>
                            {isAddingActivity ? (
                                <StudentActivitySelect
                                    profileId={profileId}
                                    onChange={(activity) => {
                                        if (
                                            !activities.find(
                                                ({studentActivityId}) =>
                                                    studentActivityId === activity.studentActivityId
                                            )
                                        ) {
                                            onActivitiesChange?.([...activities, activity])
                                        }
                                        setAddingActivity(false)
                                    }}
                                    selectedStudentActivityIds={activities.map(
                                        (activity) => activity.studentActivityId
                                    )}
                                />
                            ) : (
                                <BaseButton
                                    title={t("links.linkToExisting")}
                                    variant="secondary"
                                    onClick={() => openSelectForAdding("activity")}
                                />
                            )}
                        </div>
                    </div>
                )}
            </>
        )
    }

    const renderChecklists = () => {
        return (
            <>
                {checklists.map((checklist) =>
                    !checklist ? null : (
                        <div
                            className={classNames(styles.linkContent, {[styles.linkContentPanel]: isColumnDisplay})}
                            key={checklist.studentChecklistItemId}>
                            <div className={styles.linkTypeContainer}>
                                <Icon icon="LINK" className={styles.linkTypeIcon} color="var(--black-200)" />
                                <span className={styles.linkTypeText}>Checklist</span>
                            </div>
                            <div className={styles.linkValueContainer}>
                                <BaseInput
                                    value={`${checklist.profileId} - ${checklist.name}`}
                                    className={styles.linkInput}
                                    readOnly
                                />
                                {canEditLinks && (
                                    <ActionRowItem
                                        data={checklist}
                                        menuActions={[
                                            {
                                                title: t("links.removeLink"),
                                                icon: "DELETE",
                                                action: (item) =>
                                                    onChecklistsChange?.(
                                                        checklists.filter(
                                                            ({studentChecklistItemId}) =>
                                                                studentChecklistItemId !== item.studentChecklistItemId
                                                        )
                                                    )
                                            }
                                        ]}
                                    />
                                )}
                            </div>
                        </div>
                    )
                )}

                {(!maxChecklists || maxChecklists > checklists.length) && !!onChecklistsChange && canEditLinks && (
                    <div className={classNames(styles.linkContent, {[styles.linkContentPanel]: isColumnDisplay})}>
                        <div className={classNames(styles.linkTypeContainer, styles.add)}>
                            <Icon icon="LINK" className={styles.linkTypeIcon} color="var(--whitebase)" />
                            <span className={styles.linkTypeText}>Checklist</span>
                        </div>
                        <div className={styles.linkAddContainer}>
                            {isAddingChecklist ? (
                                <StudentChecklistSelect
                                    profileIds={profileIds}
                                    onChange={(checklist) => {
                                        if (
                                            !checklists.find(
                                                ({studentChecklistItemId}) =>
                                                    studentChecklistItemId === checklist.studentChecklistItemId
                                            )
                                        ) {
                                            onChecklistsChange?.([...checklists, checklist])
                                        }
                                        setAddingChecklist(false)
                                    }}
                                    selectedChecklistsSelectedIds={checklists.map(
                                        (checklist) => checklist.studentChecklistItemId
                                    )}
                                />
                            ) : (
                                <BaseButton
                                    title={t("links.linkToExisting")}
                                    variant="secondary"
                                    onClick={() => openSelectForAdding("checklist")}
                                />
                            )}
                        </div>
                    </div>
                )}
            </>
        )
    }

    const renderDocuments = () => {
        return (
            <>
                {documents.map((document) =>
                    !document ? null : (
                        <div
                            className={classNames(styles.linkContent, {[styles.linkContentPanel]: isColumnDisplay})}
                            key={document.userDocumentId}>
                            <div className={styles.linkTypeContainer}>
                                <Icon icon="LINK" className={styles.linkTypeIcon} color="var(--black-200)" />
                                <span className={styles.linkTypeText}>Document</span>
                            </div>
                            <div className={styles.linkValueContainer}>
                                <BaseInput value={document.name} className={styles.linkInput} readOnly />
                                {canEditLinks && (
                                    <ActionRowItem
                                        data={document}
                                        menuActions={[
                                            {
                                                title: t("links.removeLink"),
                                                icon: "DELETE",
                                                action: (item) =>
                                                    onDocumentsChange?.(
                                                        documents.filter(
                                                            ({userDocumentId}) => userDocumentId !== item.userDocumentId
                                                        )
                                                    )
                                            }
                                        ]}
                                    />
                                )}
                            </div>
                        </div>
                    )
                )}

                {(!maxDocuments || maxDocuments > documents.length) && !!onDocumentsChange && canEditLinks && (
                    <>
                        <div className={classNames(styles.linkContent, {[styles.linkContentPanel]: isColumnDisplay})}>
                            <div className={classNames(styles.linkTypeContainer, styles.add)}>
                                <Icon icon="LINK" className={styles.linkTypeIcon} color="var(--whitebase)" />
                                <span className={styles.linkTypeText}>Document</span>
                            </div>
                            <div className={styles.linkAddContainer}>
                                {isAddingDocument || isCreatingDocument ? (
                                    <DocumentSelect
                                        profileId={profileId}
                                        onChange={(document) => {
                                            if (
                                                !documents.find(
                                                    ({userDocumentId}) => userDocumentId === document.userDocumentId
                                                )
                                            ) {
                                                onDocumentsChange?.([...documents, document])
                                            }
                                            setAddingDocument(false)
                                        }}
                                        selectedDocumentIds={documents.map((document) => document.userDocumentId)}
                                    />
                                ) : (
                                    <>
                                        <BaseButton
                                            title={t("links.linkToExisting")}
                                            variant="secondary"
                                            onClick={() => openSelectForAdding("document")}
                                        />
                                        <BaseButton
                                            title={t("links.createNew")}
                                            variant="secondary"
                                            onClick={openDocumentModal}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                        <BasePopup
                            isShow={isCreatingDocument}
                            onClose={closeDocumentModal}
                            leftIcon="CLOUD_UPLOAD"
                            leftIconColor="#fff"
                            width="70vw">
                            {isCreatingDocument && (
                                <DocumentForm
                                    onCancel={closeDocumentModal}
                                    onSave={handleDocumentSave}
                                    profileId={profileId}
                                />
                            )}
                        </BasePopup>
                    </>
                )}
            </>
        )
    }

    return (
        <div>
            <h3 className={styles.linkSectionTitle}>LINKS</h3>
            {renderTasks()}
            {renderActivities()}
            {renderChecklists()}
            {renderDocuments()}
        </div>
    )
}

export default LinksSection
