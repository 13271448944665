import React, {useMemo} from "react"
import {BaseNewFilter} from "uiKit"
import styles from "./TermAndCoursesFilter.module.css"
import {Col, Row} from "antd"
import {KlassDropAsyncPaginate} from "components/Select"
import {useModel} from "hooks"
import {FilterKey} from "types/filter"
import {campusesService, userService} from "services"
import {formatCodeName, handleError} from "helpers"
import {Auth} from "types/auth"
import {TermAndCoursesTabs} from ".."

type TermAndCoursesFilterProps = {
    type: TermAndCoursesTabs
    tempFilter: {
        campus: number[]
        instructor: number[]
    }
    setTempFilter: (value: {campus: number[]; instructor: number[]}) => void
    textSearch: string
    setFilter: (value: {campus: number[]; instructor: number[]}) => void
    filter: {
        campus: number[]
        instructor: number[]
    }
    setTextSearch: (value: string) => void
}

const TermAndCoursesFilter = (props: TermAndCoursesFilterProps) => {
    const {tempFilter, setTempFilter, textSearch, setFilter, filter, setTextSearch, type} = props

    const model = useModel()

    const filterKey = useMemo(() => {
        return type === TermAndCoursesTabs.TermCoursesCourses
            ? FilterKey.RegistrarTermAndCourses
            : FilterKey.RegistrarTermAndMajors
    }, [type])

    const onChangeFilter = (key, value) => {
        const newFilter = {...tempFilter}
        newFilter[key] = value
        setTempFilter(newFilter)
    }

    const onApplyFilter = () => {
        model.updateStorageFilter(filterKey, {filter: tempFilter})
        setFilter(tempFilter)
    }

    const onClickClearFilter = () => {
        const defaultValue = {
            campus: null,
            instructor: null
        }
        setTempFilter(defaultValue)
        setFilter(defaultValue)
    }

    const onSearchInput = (text) => {
        model.updateStorageFilter(filterKey, {search: text})
        setTextSearch(text)
    }

    const onCampusSearchChange = async (search = "", loadedOptions) => {
        try {
            const params = {
                filter: {
                    search
                },
                range: {
                    limit: 20,
                    offset: loadedOptions.length
                },
                sort: {
                    orderBy: "name",
                    orderDir: "asc"
                }
            }
            const {data: campuses, total} = await campusesService.getAll(params)
            return {
                options: campuses,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            handleError(error)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onInstructorSearchChange = async (search = "", loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const {data: instructors, total} = await userService.searchUsers({
                filters: {
                    type: Auth.UserProfileType.Staff,
                    search,
                    excludeProfileIds: []
                },
                range: {
                    page,
                    pageSize
                }
            })
            return {
                options: instructors,
                hasMore: loadedOptions.length < total
            }
        } catch (e) {
            handleError(e)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    return (
        <BaseNewFilter
            className={styles.filterWrap}
            filter={filter}
            onClick={onApplyFilter}
            searchValue={textSearch}
            onClickClear={onClickClearFilter}
            onSearchInput={onSearchInput}>
            <Row gutter={[24, 24]}>
                <Col span={12}>
                    <KlassDropAsyncPaginate
                        value={tempFilter.campus}
                        onChange={(newValue) => onChangeFilter("campus", newValue)}
                        loadOptions={onCampusSearchChange}
                        isMulti
                        placeholder="Campus"
                        getOptionLabel={formatCodeName}
                    />
                </Col>
                {model.isStaff() ? null : (
                    <Col span={12}>
                        <KlassDropAsyncPaginate
                            value={tempFilter.instructor}
                            onChange={(newValue) => onChangeFilter("instructor", newValue)}
                            loadOptions={onInstructorSearchChange}
                            isMulti
                            valueKey={"profileId"}
                            labelKey="name"
                            placeholder="Instructor"
                        />
                    </Col>
                )}
            </Row>
        </BaseNewFilter>
    )
}

export default TermAndCoursesFilter
