import BaseService from "./Base"
import {ApiListResponse, ApiResponse} from "types/api"
import {AxiosResponse} from "axios"

export class CalendarSettingsCustomService extends BaseService {
    getAll = async (body = {}) => {
        const {data: response}: AxiosResponse<ApiListResponse<any[]>> = await this.post(
            "/v1/calendar-settings/custom/list",
            {
                ...body,
                linkedEntities: ["lastUpdatedBy", "campuses", "user"]
            }
        )
        if (!response.success) {
            throw response
        }

        return {
            data: response.data,
            total: response.total
        }
    }

    getOne = async (customId: number): Promise<any | null> => {
        const {data: response}: AxiosResponse<ApiResponse<any[]>> = await this.post(
            "/v1/calendar-settings/custom/list",
            {
                filter: {
                    id: [customId]
                },
                linkedEntities: ["lastUpdatedBy", "campuses", "user"]
            }
        )

        if (!response.success) {
            throw response
        }

        return response.data[0] ?? null
    }

    create = async (payload: any) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post(
            "/v1/calendar-settings/custom/create",
            payload
        )

        if (!response.success) {
            throw response
        }
    }
    duplicate = async (customIds: number[]) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/calendar-settings/custom/duplicate", {
            id: customIds
        })

        if (!response.success) {
            throw response
        }
    }

    update = async (payload: any) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post(
            "/v1/calendar-settings/custom/edit",
            payload
        )

        if (!response.success) {
            throw response
        }
    }

    deleteBulk = async (customIds: number[]) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/calendar-settings/custom/delete", {
            id: customIds
        })

        if (!response.success) {
            throw response
        }
    }
}
