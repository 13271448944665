import styles from "./EmployerNote.module.css"
import {useState} from "react"
import {CareerServices} from "types/careerServices"
import {displaySince, handleError} from "helpers"
import {BaseButton, BaseTextArea} from "components"
import {careerService} from "services"

interface IEmployerNoteProps {
    notes: CareerServices.ApplicationNoteEntity[]
    applicationId: number
}

export function EmployerNote({notes: _notes, applicationId}: IEmployerNoteProps) {
    const [notes, setNotes] = useState(_notes)
    const [comment, setComment] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleClick = async (e) => {
        e.preventDefault()
        try {
            setIsSubmitting(true)

            const newNote = await careerService.createApplicationNote({
                applicationId: applicationId,
                noteType: CareerServices.ApplicationNoteType.Employer,
                content: comment
            })
            setNotes([...notes, newNote])
            setComment("")
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <div className={styles.notesContainer}>
            <div className={styles.title}>Employer Notes</div>
            {notes &&
                notes.map((item) => {
                    return (
                        <div key={item.id} className={styles.noteItem}>
                            <div className={styles.noteContent}>{item.content}</div>
                            <div className={styles.noteDate}>{displaySince(item.createdAt, "DD/MM/YYYY hh:mm A")}</div>
                        </div>
                    )
                })}
            <BaseTextArea
                className={styles.noteInput}
                rows={3}
                placeholder="type"
                onChange={setComment}
                value={comment}
            />
            <div className={styles.noteBtn}>
                <BaseButton
                    variant="secondary"
                    title="Send"
                    size="small"
                    onClick={handleClick}
                    disabled={comment === ""}
                    loading={isSubmitting}
                />
            </div>
        </div>
    )
}
