import React from "react"
import {useTranslation} from "react-i18next"
import {BasePopup} from "components/popup"
import ActivityForm from "../ActivityForm"
import styles from "./ActivityPopup.module.css"
import {StudentActivity} from "types/activity"
import {useModel} from "hooks"

type ActivityPopupProps = {
    departmentId?: number
    studentId?: number
    formEditData?: Partial<StudentActivity>
    isShow: boolean
    onClose: () => void
    setReload?: (activity: StudentActivity) => void
    isCompactForm?: boolean
}

export function ActivityPopup(props: ActivityPopupProps) {
    const {departmentId, studentId, formEditData, isShow, onClose, setReload, isCompactForm = false} = props
    const {t} = useTranslation(["common"])
    const model = useModel()

    const handleDone = React.useCallback(
        (activity: StudentActivity) => {
            setReload?.(activity)
            onClose()
        },
        [onClose, setReload]
    )

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="EDIT_LINE" leftIconColor="#fff" width="70vw">
            <div className={styles.container}>
                <p className={styles.title}>
                    {!!formEditData?.studentActivityId
                        ? model.isStudent()
                            ? t("activityPopup.detailActivity")
                            : t("activityPopup.editActivity")
                        : t("activityPopup.addActivity")}
                </p>

                <ActivityForm
                    departmentId={departmentId}
                    studentId={studentId}
                    formEditData={formEditData}
                    onDone={handleDone}
                    isCompactForm={isCompactForm}
                />
            </div>
        </BasePopup>
    )
}
