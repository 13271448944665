import {BaseButton, BaseInput, BaseLoading, BasePopup} from "components"
import {useCallback, useEffect, useState} from "react"
import styles from "./SigninSapAction.module.css"
import {authService, profileService} from "services"
import {getFullName, handleError, toastError} from "helpers"
import {get} from "lodash"
import {Col, Row} from "antd"
import {FormLabel} from "components/Form"
import {useModel} from "hooks"
import {Auth} from "types/auth"

type Props = {
    isShow: boolean
    onClose: () => void
    studentProfileId?: number
    onSignInSuccess: () => void
    onRefreshData: () => Promise<void>
}

export function SignInSapAction(props: Props) {
    const {isShow, onClose, onRefreshData, studentProfileId, onSignInSuccess} = props
    const [isLoading, setIsLoading] = useState(false)
    const model = useModel()
    const {slug} = model
    const [values, setValues] = useState({
        email: "",
        password: "",
        student: undefined
    })
    const [errors, setErrors] = useState({
        email: "",
        password: ""
    })

    useEffect(() => {
        if (!isShow) {
            setValues({
                email: "",
                password: "",
                student: undefined
            })
        }
    }, [isShow])

    const getProfileInfo = async () => {
        const {data: result} = await profileService.getAll({
            linkedObjects: true,
            filter: {
                id: [studentProfileId]
            }
        })
        const student = get(result, [0], {})
        setValues({
            ...values,
            student: {
                ...student,
                profileId: student.id
            }
        })
    }

    useEffect(() => {
        if (isShow && studentProfileId) {
            getProfileInfo()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow, studentProfileId])

    const isValid = (payload) => {
        const newErrors: any = {
            email: "",
            password: ""
        }
        if (!payload.email?.length) {
            newErrors.email = "Email is required"
        }
        if (!payload.password?.length) {
            newErrors.title = "Password is required"
        }
        setErrors(newErrors)
        return !Object.keys(newErrors).find((k) => newErrors[k].length > 0)
    }

    const onSubmit = useCallback(async () => {
        if (!isValid(values)) {
            return
        }
        try {
            setIsLoading(true)
            const response = await authService.signIn({
                slug,
                email: values.email,
                password: values.password,
                device: "web"
            })
            const {user} = (response || {}) as Auth.SignInNormal
            const userId = user?.id
            if (userId === values.student.userId) {
                onClose()
                onSignInSuccess()
            } else {
                toastError(`Please login with student ${getFullName(values.student)}`)
            }
        } catch (err) {
            handleError(err)
        } finally {
            setIsLoading(false)
        }
    }, [onClose, onSignInSuccess, slug, values])

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="FILE_LINE_ALT" width="50vw">
            <div className={styles.wrap}>
                <Row>
                    <p className={styles.title}>Add signature</p>
                    <p className={styles.subTitle}>/student</p>
                </Row>

                <Col className={styles.container} span={24}>
                    <div className={styles.rowInput}>
                        <p className={styles.header}>Login to student account</p>
                        <p className={styles.subHeader}>to be able to sign</p>
                    </div>
                    <div className={styles.rowInput}>
                        <FormLabel label="EMAIL" />
                        <BaseInput
                            onChange={(value) => setValues({...values, email: value})}
                            placeholder={"Type your email"}
                            value={values?.email}
                        />
                    </div>
                    <div className={styles.rowInput}>
                        <FormLabel className={styles.fieldTitle} label="PASSWORD" />
                        <BaseInput
                            type="password"
                            onChange={(value) => setValues({...values, password: value})}
                            placeholder={"Type your password"}
                            value={values?.password}
                        />
                    </div>
                    {Object.keys(errors).find((k) => errors[k].length > 0) && (
                        <ul className={styles.errorOptionsList}>
                            {Object.keys(errors)
                                .filter((key) => errors[key].length)
                                .map((key, index) => (
                                    <li className={styles.textError} key={index}>
                                        {errors[key]}
                                    </li>
                                ))}
                        </ul>
                    )}
                    <div className={styles.submitContainer}>
                        <BaseButton
                            title={!isLoading ? "Login" : "Loading"}
                            onClick={onSubmit}
                            loading={isLoading}
                            style={{width: "100%"}}
                            disabled={isLoading}
                        />
                    </div>
                </Col>

                <BaseLoading isShow={isLoading} />
            </div>
        </BasePopup>
    )
}
