import {get} from "lodash"
import {
    ParamCreateUniversity,
    ParamsGetListUniversity,
    ParamUpdateUniversity,
    ResponseListUniversity,
    University
} from "types/university"
import BaseService from "./Base"

class UniversityService extends BaseService {
    async getListUniversity(params: ParamsGetListUniversity): Promise<ResponseListUniversity> {
        const url = "/v3/settings/university/list"
        const response = await this.post(url, params)
        return get(response, "data", {})
    }
    async deleteUniversity(ids: number[]): Promise<University> {
        const url = "/v3/settings/university/delete"
        const response = await this.post(url, {universityIds: ids})
        return get(response, "data", {})
    }
    async getDetailUniversity(id: number): Promise<University> {
        const url = `/v3/settings/university/get`
        const response = await this.post(url, {universityId: id})
        return get(response, "data", {})
    }
    async updateUniversity(id: number, params: ParamUpdateUniversity): Promise<University> {
        const url = `/v3/settings/university/edit`
        const response = await this.post(url, {...params, universityId: id})
        return get(response, "data", {})
    }
    async createUniversity(params: ParamCreateUniversity): Promise<University> {
        const url = "/v3/settings/university/create"
        const response = await this.post(url, params)
        return get(response, "data", {})
    }
}

export default UniversityService
