/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {Spin} from "antd"
import {Stripe} from "@stripe/stripe-js"
import {loadStripe} from "@stripe/stripe-js/pure"
import {Elements} from "@stripe/react-stripe-js"

import {CheckoutForm} from "./CheckoutForm"
import {handleError} from "helpers"
import {studentPaymentService} from "services"
import {GeneralPayment} from "types/student-account/ledger-accounts"
import {PaymentProvider} from "types/student-account/student-payment"
import {StudentCharge} from "types/student-account/student-ledger"
import {PaymentPlanItem} from "types/student-account/payment-plan"

type Props = {
    providerConfig
    studentId
    paymentAmount
    currency
    isCheckingOut: boolean
    selectedPayment?: GeneralPayment
    customNote: string
    setCurrencyMinAmount: Function
    payerFullName?: string
    selectedCharges?: StudentCharge[]
    selectedPaymentItems?: PaymentPlanItem[]
    academicYear?: number
}

export const StripePayment = (props: Props) => {
    const {
        providerConfig,
        studentId,
        paymentAmount,
        currency,
        isCheckingOut,
        selectedPayment,
        customNote,
        setCurrencyMinAmount,
        payerFullName,
        selectedCharges,
        selectedPaymentItems,
        academicYear
    } = props

    const [stripePromise, setStripePromise] = useState<Promise<Stripe> | undefined>()
    const [clientSecret, setClientSecret] = useState<string | undefined>()
    const [studentPayment, setStudentPayment] = useState<any | undefined>()

    useEffect(() => {
        if (providerConfig.provider === PaymentProvider.Stripe) {
            setStripePromise(loadStripe(providerConfig.publishableKey))
            setCurrencyMinAmount(providerConfig.minimumChargeAmountInCentsForCurrency)
        }
    }, [providerConfig])

    useEffect(() => {
        if (!studentId || !paymentAmount || !isCheckingOut) {
            setClientSecret(undefined)
            setStudentPayment(undefined)
            return
        }

        ;(async function initPaymentIntent() {
            try {
                const {clientSecret, studentPayment} = await studentPaymentService.createPaymentIntent({
                    amountInCents: Math.floor(paymentAmount * 100),
                    currency,
                    studentProfileId: studentId,
                    generalPaymentId: selectedPayment?.generalPaymentId,
                    payerFullName,
                    academicYear,
                    paymentPlanItems: selectedPaymentItems?.length
                        ? selectedPaymentItems
                              .filter((item) => item.paymentAmount)
                              .map((item) => ({
                                  paymentPlanId: item.paymentPlanId,
                                  paymentPlanItemId: item.paymentPlanItemId,
                                  amount: paymentAmount
                              }))
                        : undefined,
                    notes: `${selectedPayment?.name || ""}${customNote ? `: ${customNote}` : ""}`
                })
                if (selectedCharges?.length) {
                    await studentPaymentService.assignPaymentCharges(
                        studentPayment.paymentId,
                        selectedCharges.map((charge) => ({chargeId: charge.chargeId, amount: charge.paymentAmount}))
                    )
                }
                setClientSecret(clientSecret)
                setStudentPayment(studentPayment)
            } catch (error) {
                handleError(error)
            }
        })()
    }, [
        studentId,
        isCheckingOut,
        paymentAmount,
        currency,
        selectedPayment,
        customNote,
        payerFullName,
        selectedCharges,
        selectedPaymentItems
    ])

    return (
        <>
            {!stripePromise || !clientSecret || !studentPayment ? (
                <div>
                    <Spin />
                </div>
            ) : (
                <Elements
                    stripe={stripePromise}
                    options={{
                        clientSecret,
                        appearance: {
                            theme: "none",
                            rules: {
                                ".Label": {
                                    marginBottom: "8px",
                                    marginTop: "12px",
                                    color: "#666666"
                                },
                                ".Input": {
                                    boxShadow: "0 2px 12px 0 rgba(48, 49, 51, 0.1)",
                                    border: "solid 0 #000"
                                }
                            }
                        }
                    }}>
                    <CheckoutForm studentPayment={studentPayment} />
                </Elements>
            )}
        </>
    )
}
