import React, {useState, useRef} from "react"
import {useHistory} from "react-router-dom"
import FullCalendar from "@fullcalendar/react"
import {get, isEmpty, keyBy, uniq} from "lodash"
import moment from "moment"
import "moment/min/locales"
import {useModel, useVisible} from "hooks"
import {OPTIONS_FREQUENCY, OPTIONS_FREQUENCY_TYPE} from "sections/calendar/parts/common/constants"
import {calendarService, courseScheduleService, courseService, userServiceV3} from "services"
import {getFullName, handleError, addAlpha, toastError} from "helpers"
import {Calendar} from "components/Calendar"
import {ChooseRecurringPopup} from "components/Calendar/Common"
import {CalendarEvent, CalendarEventOption, CalendarEventType, RSVP, RecurringPopupType} from "types/calendar"
import {CalendarEventPopup} from "../EventPopup"
import styles from "./PersonalCalendar.module.css"
import cx from "classnames"
import {DisplayMode} from "types/common"
import {observer} from "mobx-react"
import {ViewOtherUserChatCalendarButton, ViewOtherUserChatCalendarPopup} from "components/SharedChatCalendar"
import {PermissionType} from "@edular/permissions"
import {getEndDateTime, getScheduleEventColor, getStartDateTime} from "helpers/calendar"

interface PersonalCalendarProps {
    displayMode?: DisplayMode
}

const PersonalCalendar = observer((props: PersonalCalendarProps) => {
    const {displayMode = "normal"} = props
    const model = useModel()
    const calendarRef = useRef<FullCalendar>()
    const [allEvents, setAllEvents] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [calendarId, setCalendarId] = useState(null)
    const [tempEventId, setTempEventId] = useState(null)
    const [recurringPopupData, setRecurringPopupData] = useState({
        type: null,
        data: null
    })
    const [initialEventData, setInitialEventData] = useState<any>({
        publicId: "",
        name: "",
        typeEvent: "",
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
        rsvp: RSVP.Yes,
        recurring: OPTIONS_FREQUENCY[0],
        end: OPTIONS_FREQUENCY_TYPE[0],
        executions: "",
        every: "",
        days: {},
        monthlyRecurring: 0,
        monthDay: {},
        monthWeek: {},
        monthWeekDay: {},
        notes: "",
        allDay: false,
        rrule: undefined
    })
    const eventPopup = useVisible(false)
    const chooseRecurringPopup = useVisible(false)
    const history = useHistory()
    const viewOtherUserCalendarPopup = useVisible(false)

    const closeViewOtherCalendar = async () => {
        model.viewOtherUserCalendar = null
        calendarService.setViewOtherUserId(null)
        await reloadEvents()
    }

    const isFullDisplay = displayMode === "normal"

    const getPayload = (calendarId, from, to) => {
        const utcFrom = moment(from).utc().format()
        const utcTo = moment(to).utc().format()
        const basePayload = {
            sort: {
                orderBy: "start_at_utc",
                order: "ASC"
            },
            filter: {
                from: utcFrom,
                to: utcTo
            },
            pagination: {
                page: 1,
                pageSize: 100 // TODO fix this
            }
        }
        const payload = {
            ...basePayload,
            filter: {
                ...basePayload.filter,
                public_terms: true,
                public_work_schedules: true,
                owner_user_ids: [+model.user.id],
                calendar_ids: [+calendarId],
                types: [CalendarEventType.Event, CalendarEventType.CourseSchedule]
            }
        }
        const invitesCalendarPayload = {
            sort: {
                orderBy: "start_at_utc",
                order: "ASC"
            },
            filter: {
                from,
                to,
                invited_user_ids: [model.user.id]
            },
            pagination: {
                page: 1,
                pageSize: 100 // TODO fix this
            }
        }
        const bookedPayload = {
            ...basePayload,
            filter: {
                ...basePayload.filter,
                appointment_slot_status: "booked"
            },
            or_filter: {
                owner_user_ids: [+model.user.id],
                appointment_slot_status: "booked",
                booker_user_ids: [model.user.id],
                from,
                to
            }
        }
        return {payload, invitesCalendarPayload, bookedPayload}
    }

    const getUsers = async (userIds) => {
        if (userIds.length) {
            userIds = uniq(userIds)
            const userPayload = {
                filter: {id: userIds},
                range: {limit: userIds.length, offset: 0},
                linkedObjects: false
            }
            try {
                const {data} = await userServiceV3.getAll(userPayload)
                return data
            } catch (error) {
                return []
            }
        }
        return []
    }

    const getCourseSchedules = async (courseScheduleIds) => {
        if (courseScheduleIds.length) {
            courseScheduleIds = uniq(courseScheduleIds)
            try {
                const {data} = await courseScheduleService.courseScheduleGet({
                    filter: {courseScheduleIds: courseScheduleIds}
                })
                return data
            } catch (error) {
                return []
            }
        }
        return []
    }

    const getCourses = async (courseIds) => {
        if (courseIds.length) {
            courseIds = uniq(courseIds)
            try {
                const {data} = await courseService.list({filter: {courseIds}, linkedEntities: ["submodule"]})
                return data
            } catch (error) {
                return []
            }
        }
        return []
    }

    const getEvents = async (calendarId, from, to) => {
        const {payload, invitesCalendarPayload, bookedPayload} = getPayload(calendarId, from, to)
        const [{data: myEvents}, {data: myInvitesCalendar}, {data: bookedEvents}, {data: myInvites}] =
            await Promise.all([
                calendarService.getAll(payload),
                calendarService.getAllInvitesCalendar(invitesCalendarPayload),
                calendarService.getAll(bookedPayload),
                calendarService.getAllInvites(invitesCalendarPayload)
            ])
        const myInvitesCalendarEvents = myInvitesCalendar
            .filter((event) => event.invited_user_id !== event.object?.owner_user_id)
            .map((item) => ({...item.object, ...item.group, willGo: item.will_go}))
        const inviteEventIds = myInvitesCalendarEvents.map((item) => item.object_id)
        const myInvitesEvents = myInvites
            .filter(
                (event) =>
                    event.invited_user_id !== event.object?.owner_user_id &&
                    !inviteEventIds.includes(event.object?.object_id)
            )
            .map((item) => ({...item.object, ...item.group, willGo: item.will_go}))
        let userIds = []
        let courseScheduleIds = []
        let courseIds = []
        if (myEvents.length) {
            const newUserIds = myEvents
                .filter((item) => item.owner_user_id && item.owner_user_id !== +model.user.id)
                .map((event) => event.owner_user_id)
            userIds = [...userIds, ...newUserIds]
        }
        if (bookedEvents.length) {
            const newUserIds = bookedEvents
                .filter((item) => item.appointment_slot.booker_user_id !== +model.user.id)
                .map((item) => item.appointment_slot.booker_user_id)
            userIds = [...userIds, ...newUserIds]
        }
        if (myInvitesCalendarEvents.length) {
            const newUserIds = myInvitesCalendarEvents.map((item) => item.owner_user_id)
            const scheduleIds = myInvitesCalendarEvents.map((item) => item.json_data.schedule_id).filter((item) => item)
            const courseIdsData = myInvitesCalendarEvents.map((item) => item.json_data.course_id).filter((item) => item)
            courseScheduleIds = [...courseScheduleIds, ...scheduleIds]
            courseIds = [...courseIds, ...courseIdsData]
            userIds = [...userIds, ...newUserIds]
        }
        if (myInvitesEvents.length) {
            const newUserIds = myInvitesEvents.map((item) => item.owner_user_id)
            const scheduleIds = myInvitesEvents.map((item) => item.json_data.schedule_id).filter((item) => item)
            const courseIdsData = myInvitesCalendarEvents.map((item) => item.json_data.course_id).filter((item) => item)
            courseScheduleIds = [...courseScheduleIds, ...scheduleIds]
            courseIds = [...courseIds, ...courseIdsData]
            userIds = [...userIds, ...newUserIds]
        }
        const [users, courseSchedules, courses] = await Promise.all([
            getUsers(userIds),
            getCourseSchedules(courseScheduleIds),
            getCourses(courseIds)
        ])
        const usersKeyById = keyBy(users, "id")
        const courseSchedulesById = keyBy(courseSchedules, "id")
        const coursesById = keyBy(courses, "courseId")
        const myInvitesKeyByObjectId = keyBy(myInvites, "object_id")
        myEvents.forEach((event) => {
            const isCurrentUser = event.owner_user_id === +model.user.id
            if (myInvitesKeyByObjectId[event.object_id]) {
                event.willGo = get(myInvitesKeyByObjectId[event.object_id], "will_go")
            }
            event.ownerFullName = isCurrentUser
                ? getFullName(model.user)
                : getFullName(usersKeyById[event.owner_user_id])
        })
        bookedEvents.forEach((item) => {
            const isCurrentUser = item.appointment_slot.booker_user_id === +model.user.id
            item.name = isCurrentUser
                ? getFullName(model.user)
                : getFullName(usersKeyById[item.appointment_slot.booker_user_id])
        })
        myInvitesCalendarEvents.forEach((event) => {
            const isCurrentUser = event.owner_user_id === +model.user.id
            event.ownerFullName = isCurrentUser
                ? getFullName(model.user)
                : getFullName(usersKeyById[event.owner_user_id])
            if (event.json_data.schedule_id) {
                event.courseScheduleInfo = courseSchedulesById[event.json_data.schedule_id]
                event.courseInfo = coursesById[event.json_data.course_id]
            }
        })
        myInvitesEvents.forEach((event) => {
            const isCurrentUser = event.owner_user_id === +model.user.id
            event.ownerFullName = isCurrentUser
                ? getFullName(model.user)
                : getFullName(usersKeyById[event.owner_user_id])
            if (event.json_data.schedule_id) {
                event.courseScheduleInfo = courseSchedulesById[event.json_data.schedule_id]
                event.courseInfo = coursesById[event.json_data.course_id]
            }
        })
        const events = [...myEvents, ...bookedEvents, ...myInvitesCalendarEvents, ...myInvitesEvents]
        return events
    }

    const reloadEvents = async () => {
        try {
            setIsLoading(true)
            const currentData = calendarRef.current.getApi().getCurrentData()
            const from = currentData.dateProfile.activeRange.start
            const to = currentData.dateProfile.activeRange.end
            const events = await getEvents(calendarId, from, to)
            convertEvents(events)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const onChangeDatesSet = async (date) => {
        try {
            setIsLoading(true)
            let events = []
            if (!calendarId) {
                const {data} = await calendarService.getMyPrimaryCalendar({}, {})
                const calendarId = data.calendar_id
                events = await getEvents(calendarId, date.startStr, date.endStr)
                setCalendarId(calendarId)
            } else {
                events = await getEvents(calendarId, date.startStr, date.endStr)
            }
            convertEvents(events)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const getEventColor = (event) => {
        let color = event?.json_data?.color || "#1e90ff"
        if (event.type === CalendarEventType.CourseSchedule && event?.json_data.eventType) {
            color = getScheduleEventColor(event?.json_data.eventType)
        }
        const isFutureEvent = moment(event.start_at_utc).isAfter(moment())
        const isVariableColor = color.includes("var")
        const isGoing = true
        if (!isGoing) {
            return {
                borderColor: color,
                color: "transparent"
            }
        }
        return {
            borderColor: "transparent",
            color: isFutureEvent || isVariableColor ? color : addAlpha(color, 0.5)
        }
    }

    const getModuleTitle = (eventType, courseInfo) => {
        if (!eventType || !courseInfo) {
            return ""
        }
        switch (eventType) {
            case "classroom":
                return courseInfo.theoryOrClassroomModuleTitle || "Classroom"
            case "lab":
                return courseInfo.labModuleTitle || "Lab"
            case "test":
                return courseInfo.testModuleTitle || "Test"
            case "others":
                return courseInfo.othersModuleTitle || "Others"
            default:
                return ""
        }
    }

    const getSubModuleTitle = (subModuleId, courseInfo) => {
        if (!subModuleId || !courseInfo) {
            return null
        }
        const result = courseInfo.submodules.find((item) => item.submoduleId === subModuleId)
        return result?.title
    }

    const convertEvents = (initialEvents) => {
        const events = (initialEvents || []).map((event, index) => {
            const originalColor = event?.json_data?.color || "#1e90ff"
            const reminders = (event?.json_data?.notifications ?? []).map((value) => ({
                value,
                label: `${moment.duration(value / 60, "minutes").humanize()} before`
            }))
            const withSMS = !!event?.json_data?.with_sms
            const eventSignInRequired = !!event?.json_data?.eventSignInRequired
            const attendedChangeToStatus = !!event?.json_data?.attendedChangeToStatus
            const notAttendedChangeToStatus = !!event?.json_data?.notAttendedChangeToStatus
            const completionWorkflow = !!event?.json_data?.completionWorkflow
            const {borderColor, color} = getEventColor(event)
            const allDay =
                [CalendarEventType.StartTermDate, CalendarEventType.EndTermDate].includes(event.type) ||
                moment(event.end_at_utc).diff(moment(event.start_at_utc), "seconds") === 24 * 3600
            const startDateTime = getStartDateTime(event)
            const endDateTime = getEndDateTime(event)
            return {
                id: event.object_id,
                start: startDateTime.format(),
                end: endDateTime.format(),
                originalColor,
                borderColor: borderColor,
                color: color,
                startDateTime,
                endDateTime,
                title: event.name,
                allDay,
                order: 1,
                classNames: "",
                initialEventIndex: index,
                description:
                    event.type === CalendarEventType.AppointmentSlot
                        ? get(event, "appointment_slot.booker_notes", event.description)
                        : event.description,
                isAppointmentSlot: event.type === CalendarEventType.AppointmentSlot,
                ownerUserId: event.owner_user_id,
                ownerFullName: event.ownerFullName,
                willGo: event.willGo,
                reminders,
                withSMS,
                eventSignInRequired,
                attendedChangeToStatus,
                notAttendedChangeToStatus,
                completionWorkflow,
                isLesson: !isEmpty(event.courseScheduleInfo),
                type: event.type,
                isHoliday: event.type === CalendarEventType.WorkSchedule,
                schedule_suffix: event.courseScheduleInfo?.schedule_suffix,
                moduleTitle: getModuleTitle(event.json_data.eventType, event.courseInfo),
                subModuleTitle: getSubModuleTitle(event.json_data.sub_module_id, event.courseInfo),
                json_data: event.json_data,
                rrule: event.rrule
            }
        })
        setAllEvents(events)
    }

    const onClickDeleteEvent = async (event) => {
        const eventId = +event.id
        const rrule = get(event, "_def.extendedProps.rrule")
        if (rrule) {
            setTempEventId(eventId)
            chooseRecurringPopup.open()
            setRecurringPopupData({
                type: RecurringPopupType.Delete,
                data: null
            })
            return
        }
        await deleteEvent(eventId)
    }

    const deleteEvent = async (eventId, target_filter = "only_this") => {
        try {
            const newEvents = allEvents.filter((item) => item.id !== eventId)
            setAllEvents(newEvents)
            await calendarService.deleteEvent(eventId, {
                target_filter
            })
            reloadEvents()
        } catch (error) {
            handleError(error)
        }
    }

    const onClickUpdateGoingEvent = async (event, will_go) => {
        const eventId = +event.id
        const rrule = get(event, "_def.extendedProps.rrule")
        if (rrule) {
            setTempEventId(eventId)
            chooseRecurringPopup.open()
            setRecurringPopupData({
                type: RecurringPopupType.Update,
                data: {
                    will_go
                }
            })
            return
        }
        await updateGoingEvent(eventId, will_go)
    }

    const updateGoingEvent = async (object_id, will_go, target_filter = "only_this") => {
        try {
            await calendarService.updateStatus({
                object_id,
                target_filter,
                will_go
            })
            reloadEvents()
        } catch (error) {
            handleError(error)
        }
    }

    const onCreateEventSuccess = async () => {
        await reloadEvents()
    }

    const onDateClick = (date) => {
        if (model.viewOtherUserCalendar?.id && !model.viewOtherUserCalendar?.hasPermissionToAdd) {
            return
        }
        setInitialEventData({
            ...initialEventData,
            allDay: date.allDay,
            startDate: moment(date.date).format(),
            startTime: moment(date.date),
            endTime: moment(date.date).add(1, "hour"),
            hostUsers: [{...(model.viewOtherUserCalendar ? model.viewOtherUserCalendar : model.user), isFixed: true}]
        })
        eventPopup.open()
    }

    const onClickEditEvent = (event) => {
        history.push(`/calendar-event/detail?id=${event.id}`)
    }

    const canShowEditIcon = (event) => {
        return !event?.extendedProps?.isLesson && !event?.extendedProps?.isAppointmentSlot
    }

    const canShowDelete = (event) => {
        return !event?.extendedProps?.isLesson
    }

    const getEventOptions = (): CalendarEventOption[] => {
        return [
            {
                title: "More detail",
                icon: "EDIT",
                action: onClickEditEvent,
                iconClassName: styles.eventPreviewHeaderEditIcon,
                canShow: canShowEditIcon
            },
            {title: "Delete event", icon: "DELETE", action: onClickDeleteEvent, canShow: canShowDelete},
            {title: "Going", icon: "CALENDAR_INPUT", action: onClickUpdateGoingEvent}
        ]
    }

    const onConfirmRecurring = async (target_filter) => {
        chooseRecurringPopup.close()
        if (recurringPopupData.type === RecurringPopupType.Delete) {
            await deleteEvent(tempEventId, target_filter)
        } else {
            await updateGoingEvent(tempEventId, recurringPopupData.data.will_go, target_filter)
        }
    }

    const onSelectStaff = async (selectedStaff) => {
        try {
            if (selectedStaff) {
                calendarService.setViewOtherUserId(selectedStaff.id)
                model.viewOtherUserCalendar = selectedStaff
                const promises: Array<Promise<any>> = []
                const commonPayload = {
                    filter: {
                        userIds: [selectedStaff.id],
                        sharedType: "calendar"
                    },
                    range: {
                        pageSize: 1,
                        page: 1
                    }
                }

                promises.push(
                    userServiceV3.getUsersViewOthersChatCalendar({
                        ...commonPayload,
                        filter: {
                            ...commonPayload.filter,
                            permissionType: PermissionType.Add
                        }
                    })
                )
                promises.push(
                    userServiceV3.getUsersViewOthersChatCalendar({
                        ...commonPayload,
                        filter: {
                            ...commonPayload.filter,
                            permissionType: PermissionType.Edit
                        }
                    })
                )
                promises.push(
                    userServiceV3.getUsersViewOthersChatCalendar({
                        ...commonPayload,
                        filter: {
                            ...commonPayload.filter,
                            permissionType: PermissionType.Delete
                        }
                    })
                )

                const [{data: dataAddPermissions}, {data: dataEditPermissions}, {data: dataDeletePermissions}] =
                    await Promise.all(promises)

                model.viewOtherUserCalendar.hasPermissionToAdd = Boolean(dataAddPermissions?.length)
                model.viewOtherUserCalendar.hasPermissionToEdit = Boolean(dataEditPermissions?.length)
                model.viewOtherUserCalendar.hasPermissionToDelete = Boolean(dataDeletePermissions?.length)

                viewOtherUserCalendarPopup.close()
                await reloadEvents()
            }
        } catch (e) {
            toastError("Error to show other calendar, please try again")
            await closeViewOtherCalendar()
        }
    }

    const eventOptions = getEventOptions()

    return (
        <>
            <div className={styles.viewOtherUserCalendar}>
                <ViewOtherUserChatCalendarButton
                    type="calendar"
                    displayMode={displayMode}
                    onGoBack={closeViewOtherCalendar}
                    onOpen={viewOtherUserCalendarPopup.open}
                    viewOtherUserIdCalendar={model.viewOtherUserCalendar?.id}
                />
            </div>
            <div className={cx(styles.root, {[styles.rootPanel]: !isFullDisplay})}>
                <Calendar
                    calendarRef={calendarRef}
                    events={allEvents}
                    isLoading={isLoading}
                    eventOptions={eventOptions}
                    isShowGoing
                    onDateClick={onDateClick}
                    onChangeDatesSet={onChangeDatesSet}
                    displayMode={displayMode}
                />
                {eventPopup.isVisible && (
                    <CalendarEventPopup
                        model={model}
                        calendarId={calendarId}
                        isShow={eventPopup.isVisible}
                        onClose={eventPopup.close}
                        initialEventData={initialEventData}
                        onCreateSuccess={onCreateEventSuccess}
                    />
                )}
                <ChooseRecurringPopup
                    isShow={chooseRecurringPopup.isVisible}
                    onClose={() => {
                        chooseRecurringPopup.close()
                    }}
                    type={recurringPopupData.type}
                    onClickSave={onConfirmRecurring}
                />
            </div>

            {viewOtherUserCalendarPopup.isVisible && (
                <ViewOtherUserChatCalendarPopup
                    isShow={viewOtherUserCalendarPopup.isVisible}
                    onClose={viewOtherUserCalendarPopup.close}
                    onConfirm={onSelectStaff}
                    type={"calendar"}
                />
            )}
        </>
    )
})

export default PersonalCalendar
