import React, {useState, useEffect} from "react"
import {Model} from "Model"
import classNames from "classnames"
import {useTranslation} from "react-i18next"
import momentTz from "moment-timezone"
import {Row, Col, Checkbox, Switch, Tooltip} from "antd"
import moment from "moment"
import {cloneDeep, get, pick, uniq} from "lodash"
import debounce from "debounce-promise"
import {useHistory} from "react-router-dom"
import {Icon} from "components/Icon"
import {BaseInput, BaseTextArea} from "components/inputs"
import {BaseButton, SecondaryButton} from "components"
import {KlassDropAsyncPaginate, KlassDropdown} from "components/Select"
import {BaseDatePicker, BaseTimePicker} from "components/DateTimePicker"
import {MonthlyRecurringBody, WeeklyRecurringBody, DailyRecurringBody} from "components/Calendar/Common/Recurring"
import {handleError, isInvalidValue, getFullName, translate, toastError} from "helpers"
import {createRruleFromEvent, getExtraEventData, getDisabledEndHours, getDisabledEndMinutes} from "helpers/calendar"
import {userServiceV3, eventTypeService, groupsService} from "services"
import {CreateEventPayload, CalendarRecurringType, RecurringEnd} from "types/calendar"
import {REMINDERS, OPTIONS_FREQUENCY as OPTIONS_FREQUENCY_ALL} from "sections/calendar/parts/common/constants"
import {EndTitle, EndBody} from "sections/calendar/parts/common"
import styles from "./CalendarEventForm.module.css"
import {DisplayMode} from "types/common"
import {Auth} from "types/auth"
import UserSelect from "components/UserSelect"
import {UserAutocomplete} from "types/user"
import StudentStatusSelect from "components/StudentStatusSelect"
import WorkflowSelect from "components/WorkflowSelect"
import {WorkflowTriggerElement} from "types/workflow"

const OPTIONS_FREQUENCY = OPTIONS_FREQUENCY_ALL.map((opt) => ({
    value: opt.value,
    originalValue: opt.originalValue,
    label: translate(`calendar:calendar.frequency.${opt.label}`)
}))

const renderEventTypeOption = (option: Readonly<any>) => {
    return (
        <div className={styles.eventType}>
            <div className={styles.eventType__color} style={{backgroundColor: option.label}} />
            <div className={styles.eventType__label}>
                {option.name} ({option.code})
            </div>
        </div>
    )
}

type CalendarEventFormProps = {
    model: Model
    calendarId: number
    isShow?: boolean
    initialEventData: any
    disabledTypeEvents?: string[]
    onCreateSuccess: Function
    onClose: () => void
    displayMode?: DisplayMode
}

const reminderOptions = REMINDERS.map((reminder) => ({value: reminder.id, label: reminder.name}))

export const CalendarEventForm = (props: CalendarEventFormProps) => {
    const {isShow, onClose, initialEventData, calendarId, model, onCreateSuccess, displayMode = "normal"} = props
    const [errors, setErrors] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [eventData, setEventData] = useState<any>({})
    const history = useHistory()
    const {t} = useTranslation(["calendar", "common"])
    const isFullDisplay = displayMode === "normal"

    useEffect(() => {
        setEventData({...initialEventData, reminders: [reminderOptions[1]]})
    }, [initialEventData])

    useEffect(() => {
        if (!isShow) return
        const handleKeyDown = (event) => {
            if (event.key === "Escape") onClose()
        }
        document.addEventListener("keydown", handleKeyDown)
        return () => {
            document.removeEventListener("keydown", handleKeyDown)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow])

    const onEventTypeSearchChange = async (search = "", loadedOptions) => {
        try {
            const {data, total} = await eventTypeService.getAll({
                range: {
                    limit: 20,
                    offset: loadedOptions.length
                },
                filter: {
                    search
                }
            })
            const eventTypes = data.map((et) => {
                return {
                    ...et,
                    name: `${et.name}`
                }
            })
            return {
                options: eventTypes,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onGroupSearchChange = async (search = "", loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const {data, total} = await groupsService.getAll({
                range: {
                    pageSize,
                    page
                },
                filter: {
                    search
                }
            })
            const groups = data.map((g) => {
                return {
                    ...g,
                    name: `${g.name} (${g.members})`
                }
            })
            return {
                options: groups,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onChangeEventDataMultiple = (values: {[key: string]: any}) => {
        const newErrors = {...errors}
        const newEventData = cloneDeep(eventData)
        for (const key of Object.keys(values)) {
            const value = values[key]
            if (!isInvalidValue(value)) {
                delete newErrors[key]
            }
            newEventData[key] = value
            if (key === "startTime" && value) {
                const isSameDate = moment(value).add(1, "hour").startOf("date").isSame(moment(value).startOf("date"))
                newEventData.endTime = isSameDate ? moment(value).add(1, "hour") : moment(value).endOf("date")
            }
        }
        setErrors(newErrors)
        setEventData(newEventData)
    }

    const onChangeEventData = (key, value) => {
        onChangeEventDataMultiple({[key]: value})
    }

    const getMandatoryFields = (event) => {
        const mandatoryFields = ["name", "eventType", "startTime", "endTime", "startDate", "recurring"]
        const recurring = typeof event.recurring === "string" ? event.recurring : event.recurring.originalValue
        switch (recurring) {
            case "YEARLY": {
                if (event.end?.value === RecurringEnd.AfterExecution) {
                    mandatoryFields.push("executions")
                } else if (event.end?.value === RecurringEnd.OnDate) {
                    mandatoryFields.push("endDate")
                }
                break
            }
            case "MONTHLY": {
                mandatoryFields.push("every")
                if (+event.monthlyRecurring === 0) {
                    /*day of the month*/
                    mandatoryFields.push("monthDay")
                } else {
                    /*day of the week*/
                    mandatoryFields.push("monthWeek", "monthWeekDay")
                }
                break
            }
            case "DAILY": {
                mandatoryFields.push("every")
                break
            }
            case "WEEKLY": {
                mandatoryFields.push("every", "days")
                break
            }
        }
        if (recurring !== "NEVER") {
            mandatoryFields.push("end")
            if (event.end?.value === RecurringEnd.AfterExecution) {
                mandatoryFields.push("executions")
            } else if (event.end?.value === RecurringEnd.OnDate) {
                mandatoryFields.push("endDate")
            }
        }
        return mandatoryFields
    }

    const validate = () => {
        const event = getExtraEventData(eventData)
        const mandatoryFields = getMandatoryFields(event)
        const errorsAux: any = {}
        mandatoryFields.forEach((field) => {
            if (isInvalidValue(event[field])) {
                errorsAux[field] = true
            }
        })
        const hasRequiredFieldError = Object.keys(errorsAux).length > 0
        if (hasRequiredFieldError) {
            setErrors(errorsAux)
            toastError(t("common:validation.fieldRequired"))
            return true
        }
        if (moment(event.startTime).isSameOrAfter(moment(event.endTime))) {
            errorsAux.startTime = true
            errorsAux.endTime = true
            toastError(t("message.endTimeGreaterStartTime"))
            return true
        }
        return false
    }

    const setDateOfTimeValue = (timeValue) => {
        const startDate = moment(eventData.startDate)
        const date = startDate.get("date")
        const month = startDate.get("month")
        const year = startDate.get("year")
        return moment(timeValue).set("date", date).set("month", month).set("year", year)
    }

    const handleOnSubmit = async () => {
        if (isSubmitting) {
            return
        }
        const newEventData = {...eventData}
        eventData.startTime = setDateOfTimeValue(eventData.startTime)
        eventData.endTime = setDateOfTimeValue(eventData.endTime)
        setEventData(newEventData)
        const hasError = validate()
        if (hasError) {
            return
        }
        await createEvent()
    }

    const getDataSubmit = () => {
        const {
            name,
            allDay,
            description,
            eventType,
            startDate,
            startTime,
            endTime,
            invitedUsers,
            group,
            reminders,
            withSMS,
            eventSignInRequired,
            attendedChangeToStatus,
            notAttendedChangeToStatus,
            completionWorkflow,
            hostUsers
        } = eventData
        const ownerUserId = model.viewOtherUserCalendar?.id ? model.viewOtherUserCalendar?.id : +model.user.id
        const rrule = createRruleFromEvent(eventData)
        const startTimeUpdated = `${moment(startDate).format("YYYY-MM-DD")} ${moment(startTime).format("HH:mm:ss")}`
        const endTimeUpdated = `${moment(startDate).format("YYYY-MM-DD")} ${moment(endTime).format("HH:mm:ss")}`
        const duration = allDay ? 3600 * 24 : moment(endTimeUpdated).diff(moment(startTimeUpdated), "seconds")
        const invited_user_ids = (invitedUsers || []).map((user) => user.userId)
        const payload: CreateEventPayload = {
            calendar_id: calendarId,
            type: "event",
            name,
            description: description || "",
            duration,
            owner_user_id: ownerUserId,
            rrule,
            start_at_timezone: momentTz.tz.guess(),
            host_user_ids: (hostUsers || []).map((user) => user.id),
            start_at_wall: allDay
                ? moment(startDate).startOf("day").format("YYYY-MM-DD HH:mm:ss")
                : `${moment(startDate).format("YYYY-MM-DD")} ${moment(startTime).format("HH:mm:ss")}`,
            json_data: {
                eventType: `${eventType.name} (${eventType.code})`,
                eventTypeId: eventType.eventTypeId,
                color: eventType.label,
                allDay,
                notifications: reminders.map((reminder) => reminder.value),
                with_sms: withSMS,
                eventSignInRequired,
                attendedChangeToStatus,
                notAttendedChangeToStatus,
                completionWorkflow
            }
        }
        if (invited_user_ids.length) {
            payload.invited_user_ids = invited_user_ids
        }
        if (group) {
            payload.edular_group_id = group.groupId
        }
        return payload
    }

    const createEvent = async () => {
        try {
            setIsSubmitting(true)
            const payload = getDataSubmit()
            await model.addEvent(payload)
            onClose()
            if (onCreateSuccess) {
                onCreateSuccess()
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitting(false)
        }
    }

    const getUsers = async (search = "", loadedOptions) => {
        try {
            const {data, total} = await userServiceV3.getAll({
                range: {
                    limit: 20,
                    offset: loadedOptions.length
                },
                filter: {
                    active: 1,
                    search
                },
                linkedObjects: true
            })
            return {
                options: data,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const getStaffs = async (search = "", loadedOptions) => {
        try {
            const {data, total} = await userServiceV3.getAll({
                range: {
                    limit: 20,
                    offset: loadedOptions.length
                },
                filter: {
                    type: [Auth.UserProfileType.Staff],
                    active: 1,
                    search
                },
                linkedObjects: true
            })
            return {
                options: data,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const renderInviteOption = (user) => {
        const type = get(user, "profiles[0].type", "")
        return (
            <div>
                <span className={styles.userId}>{user.customUserId}</span>
                {getFullName(user)} <span className={styles.userType}>({type})</span>
            </div>
        )
    }

    const onClickMoreDetail = () => {
        const {name, description, eventType, startTime, endTime, invitedUsers, group} = eventData
        history.push({
            pathname: "/calendar-event/detail",
            state: {
                eventData: {
                    name,
                    description,
                    eventType,
                    invitedUsers,
                    group,
                    timezone: momentTz.tz.guess(),
                    startTime: moment(startTime).format(),
                    endTime: moment(endTime).format()
                }
            }
        })
    }

    const renderEndBody = () => {
        return (
            <EndBody
                end={eventData.end}
                endDate={eventData.endDate}
                executions={eventData.executions}
                errors={errors}
                setEnd={(newValue) => onChangeEventData("end", newValue)}
                setEndDate={(newValue) => onChangeEventData("endDate", newValue)}
                setExecutions={(newValue) => onChangeEventData("executions", newValue)}
            />
        )
    }

    const renderYearlyRecurring = () => {
        return (
            <>
                <Col span={2} />
                <Col span={6}>
                    <EndTitle />
                </Col>
                <Col span={isFullDisplay ? 16 : 24}>{renderEndBody()}</Col>
            </>
        )
    }

    const renderMonthlyRecurring = () => {
        return (
            <>
                <Col span={8} />
                <Col span={isFullDisplay ? 16 : 24}>
                    <MonthlyRecurringBody eventData={eventData} errors={errors} onChangeEventData={onChangeEventData} />
                </Col>
                <Col span={2} />
                <Col span={6}>
                    <EndTitle />
                </Col>
                <Col span={isFullDisplay ? 16 : 24}>{renderEndBody()}</Col>
            </>
        )
    }

    const renderWeeklyRecurring = () => {
        return (
            <>
                <Col span={8} />
                <Col span={isFullDisplay ? 16 : 24}>
                    <WeeklyRecurringBody eventData={eventData} errors={errors} onChangeEventData={onChangeEventData} />
                </Col>
                <Col span={2} />
                <Col span={6}>
                    <EndTitle />
                </Col>
                <Col span={isFullDisplay ? 16 : 24}>{renderEndBody()}</Col>
            </>
        )
    }

    const renderDailyRecurring = () => {
        return (
            <>
                <Col span={8} />
                <Col span={isFullDisplay ? 16 : 24}>
                    <DailyRecurringBody eventData={eventData} errors={errors} onChangeEventData={onChangeEventData} />
                </Col>
                <Col span={2} />
                <Col span={6}>
                    <EndTitle />
                </Col>
                <Col span={isFullDisplay ? 16 : 24}>{renderEndBody()}</Col>
            </>
        )
    }

    const renderRecurring = () => {
        const originalValue = eventData.recurring?.originalValue
        switch (originalValue) {
            case CalendarRecurringType.Yearly:
                return renderYearlyRecurring()
            case CalendarRecurringType.Monthly:
                return renderMonthlyRecurring()
            case CalendarRecurringType.Weekly:
                return renderWeeklyRecurring()
            case CalendarRecurringType.Daily:
                return renderDailyRecurring()
            default:
                return null
        }
    }

    const renderAction = () => {
        if (isFullDisplay) {
            return (
                <div className={styles.actionBtn}>
                    <SecondaryButton title="More detail" onClick={onClickMoreDetail} />
                    <div className={styles.rightActionBtn}>
                        <SecondaryButton
                            title={t("common:action.cancel")}
                            onClick={onClose}
                            disabled={isSubmitting}
                            className={styles.cancelBtn}
                        />
                        <BaseButton title={t("common:action.save")} onClick={handleOnSubmit} loading={isSubmitting} />
                    </div>
                </div>
            )
        } else {
            return (
                <div className={styles.actionBtnPanel}>
                    <BaseButton
                        title="Create event"
                        onClick={handleOnSubmit}
                        loading={isSubmitting}
                        className={styles.saveBtn}
                    />
                </div>
            )
        }
    }

    const renderAllDayField = () => {
        if (isFullDisplay) {
            return (
                <Row gutter={[16, 6]} className={styles.rowItem}>
                    <Col span={2} className={styles.eventPopupLabel} />
                    <Col span={6} className={styles.eventPopupLabel}>
                        <label>{t("calendar.wholeDay")}</label>
                    </Col>
                    <Col span={16}>
                        <Switch
                            size="small"
                            onClick={(v) => onChangeEventData("allDay", v)}
                            checked={eventData.allDay}
                        />
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row gutter={[16, 6]} className={styles.rowItem}>
                    <div className={styles.checkboxWrap}>
                        <div
                            className={classNames(styles.checkbox, {[styles.checked]: eventData.allDay})}
                            onClick={() => onChangeEventData("allDay", !eventData.allDay)}>
                            <Checkbox checked={eventData.allDay} />
                            <span className={styles.checkboxTitle}>{t("calendar.wholeDay")}</span>
                        </div>
                    </div>
                </Row>
            )
        }
    }

    const renderPrivateField = () => {
        if (isFullDisplay) {
            return (
                <Row gutter={[16, 6]} className={styles.rowItem}>
                    <Col span={2} className={styles.eventPopupLabel}>
                        <Icon icon="LOCK_ACCESS_FILL" className={styles.eventPopupIcon} color="#62B1FF" />
                    </Col>
                    <Col span={6} className={styles.eventPopupLabel}>
                        <label>{t("calendar.private")}</label>
                    </Col>
                    <Col span={16}>
                        <Checkbox />
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row gutter={[16, 6]} className={styles.rowItem}>
                    <div className={styles.checkboxWrap}>
                        <div
                            className={classNames(styles.checkbox, {[styles.checked]: eventData.private})}
                            onClick={() => onChangeEventData("private", !eventData.private)}>
                            <Checkbox checked={eventData.private} />
                            <span className={styles.checkboxTitle}>{t("calendar.private")}</span>
                        </div>
                    </div>
                </Row>
            )
        }
    }

    const debounceUsers = debounce(getUsers, 300)
    const debounceStaffs = debounce(getStaffs, 300)
    const onGroupSearchChangeDebounce = debounce(onGroupSearchChange, 300)
    const onEvenTypeSearchChangeDebounce = debounce(onEventTypeSearchChange, 300)
    const isStudent = model.isStudent()

    return (
        <div className={styles.eventPopupWrap}>
            {isFullDisplay && (
                <div className={styles.eventPopupHeader}>
                    <h1 className={styles.eventPopupTitle}>{t("calendar.createEvent")}</h1>
                </div>
            )}
            <div className={classNames(styles.body, {[styles.bodyPanel]: !isFullDisplay})}>
                <Row gutter={[16, 6]} className={styles.rowItem}>
                    {isFullDisplay && (
                        <Col span={2} className={styles.eventPopupLabel}>
                            <Icon icon="PENCIL" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                    )}
                    <Col span={isFullDisplay ? 6 : 24} className={styles.eventPopupLabel}>
                        <label>{t("calendar.workSchedule.name")}</label>
                    </Col>
                    <Col span={isFullDisplay ? 16 : 24}>
                        <BaseInput
                            onChange={(newValue) => onChangeEventData("name", newValue)}
                            placeholder={t("calendar.workSchedule.name")}
                            value={eventData.name}
                            className={classNames(!!errors["name"] ? styles.error : "")}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 6]} className={styles.rowItem}>
                    {isFullDisplay && (
                        <Col span={2} className={styles.eventPopupLabel}>
                            <Icon icon="OUTLINE" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                    )}
                    <Col span={isFullDisplay ? 6 : 24} className={styles.eventPopupLabel}>
                        <label>{t("calendar.type")}</label>
                    </Col>
                    <Col span={isFullDisplay ? 16 : 24}>
                        <KlassDropAsyncPaginate
                            onChange={(newValue) => {
                                const newEventData: any = {eventType: newValue}
                                if (!eventData.object_id) {
                                    newEventData.eventSignInRequired = !!newValue?.signInRequired
                                }
                                onChangeEventDataMultiple(newEventData)
                            }}
                            loadOptions={onEvenTypeSearchChangeDebounce}
                            value={eventData.eventType}
                            placeholder={t("calendar.type")}
                            getOptionLabel={renderEventTypeOption}
                            valueKey="eventTypeId"
                            error={errors["eventType"]}
                        />
                    </Col>
                </Row>
                {renderAllDayField()}
                <Row gutter={[16, 6]} className={styles.rowItem}>
                    {isFullDisplay && (
                        <Col span={2} className={styles.eventPopupLabel}>
                            <Icon icon="CALENDAR_FILL" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                    )}
                    <Col span={isFullDisplay ? 6 : 24} className={styles.eventPopupLabel}>
                        <label>{t("calendar.date")}</label>
                    </Col>
                    <Col span={isFullDisplay ? 16 : 24}>
                        <BaseDatePicker
                            onChange={(newValue) => onChangeEventData("startDate", newValue)}
                            value={eventData.startDate ? moment(eventData.startDate) : ""}
                            className={classNames(styles.shadow, !!errors["startDate"] ? styles.error : "")}
                        />
                    </Col>
                </Row>
                {!eventData.allDay && (
                    <Row gutter={[16, 6]} className={styles.rowItem}>
                        {isFullDisplay && (
                            <Col span={2} className={styles.eventPopupLabel}>
                                <Icon icon="TIME_CLOCK_OUTLINE" className={styles.eventPopupIcon} color="#62B1FF" />
                            </Col>
                        )}
                        <Col span={isFullDisplay ? 6 : 24} className={styles.eventPopupLabel}>
                            <label>{t("calendar.time")}</label>
                        </Col>
                        <Col span={isFullDisplay ? 8 : 12}>
                            <BaseTimePicker
                                value={eventData.startTime}
                                placeholder="Time"
                                format={model.user.timeFormat === Auth.TimeFormat.Time_12 ? "hh:mm A" : "HH:mm"}
                                onChange={(newValue) => onChangeEventData("startTime", newValue)}
                                error={errors["startTime"]}
                            />
                        </Col>
                        <Col span={isFullDisplay ? 8 : 12}>
                            <BaseTimePicker
                                value={eventData.endTime}
                                placeholder="Time"
                                format={model.user.timeFormat === Auth.TimeFormat.Time_12 ? "hh:mm A" : "HH:mm"}
                                onChange={(newValue) => onChangeEventData("endTime", newValue)}
                                error={errors["endTime"]}
                                disabledHours={() => getDisabledEndHours(eventData.startTime)}
                                disabledMinutes={() => getDisabledEndMinutes(eventData.startTime, eventData.endTime)}
                            />
                        </Col>
                    </Row>
                )}
                {!isStudent && (
                    <Row gutter={[16, 6]} className={styles.rowItem}>
                        {isFullDisplay && (
                            <Col span={2} className={styles.eventPopupLabel}>
                                <Icon icon="PERSON_FILL" className={styles.eventPopupIcon} color="#62B1FF" />
                            </Col>
                        )}
                        <Col span={isFullDisplay ? 6 : 24} className={styles.eventPopupLabel}>
                            <label>{t("calendar.group")}</label>
                        </Col>

                        <Col span={isFullDisplay ? 16 : 24}>
                            <KlassDropAsyncPaginate
                                onChange={(newValue) => onChangeEventData("group", newValue)}
                                loadOptions={onGroupSearchChangeDebounce}
                                value={eventData.group}
                                placeholder={t("calendar.addGroup")}
                                valueKey="groupId"
                                error={errors["group"]}
                                isClearable
                            />
                        </Col>
                    </Row>
                )}
                <Row gutter={[16, 6]} className={styles.rowItem}>
                    {isFullDisplay && (
                        <Col span={2} className={styles.eventPopupLabel}>
                            <Icon icon="PERSON_ADD" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                    )}
                    <Col span={isFullDisplay ? 6 : 24} className={styles.eventPopupLabel}>
                        <label>{t("calendar.hosts")}</label>
                    </Col>
                    <Col span={isFullDisplay ? 16 : 24}>
                        <div className={styles.hostsWrap}>
                            <div className={styles.hostsDropdown}>
                                <KlassDropAsyncPaginate
                                    loadOptions={debounceStaffs}
                                    getOptionLabel={(option) => getFullName(option)}
                                    onChange={(newValue) => onChangeEventData("hostUsers", newValue)}
                                    placeholder="Select"
                                    value={eventData.hostUsers}
                                    error={!!errors["hostUsers"]}
                                    isMulti
                                    stylesCustom={{
                                        multiValueRemove: (base, state) =>
                                            state.data.isFixed
                                                ? {...base, display: "none"}
                                                : {...base, color: "var(--whitebase)"}
                                    }}
                                />
                            </div>
                            <Tooltip title="Hosts can edit and delete the event">
                                <div className={styles.infoIconWrap}>
                                    <Icon icon="INFO" />
                                </div>
                            </Tooltip>
                        </div>
                    </Col>
                </Row>
                <Row gutter={[16, 6]} className={styles.rowItem}>
                    {isFullDisplay && (
                        <Col span={2} className={styles.eventPopupLabel}>
                            <Icon icon="PERSON_ADD" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                    )}
                    <Col span={isFullDisplay ? 6 : 24} className={styles.eventPopupLabel}>
                        <label>{t("calendar.invite")}</label>
                    </Col>
                    <Col span={isFullDisplay ? 16 : 24}>
                        <UserSelect
                            isMulti={true}
                            placeholder={t("calendar.contactName")}
                            isActive={eventData.includeArchive ? [true, false] : true}
                            getOptionLabel={(option) => renderInviteOption(option)}
                            value={eventData.invitedUsers}
                            onChange={(options?: UserAutocomplete[]) => {
                                onChangeEventData("invitedUsers", options ?? [])
                            }}
                            className={classNames(!!errors["invitedUsers"] ? styles.error : "")}
                        />
                    </Col>
                    {isFullDisplay && <Col span={8} />}
                    <Col span={isFullDisplay ? 16 : 24}>
                        <Checkbox
                            checked={!!eventData.includeArchive}
                            onChange={(e) => onChangeEventData("includeArchive", e.target.checked)}>
                            {t("calendar.searchArchive")}
                        </Checkbox>
                    </Col>
                </Row>
                <Row gutter={[16, 12]} className={styles.rowItem}>
                    {isFullDisplay && (
                        <Col span={2} className={styles.eventPopupLabel}>
                            <Icon icon="REPEAT" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                    )}
                    <Col span={isFullDisplay ? 6 : 24} className={styles.eventPopupLabel}>
                        <label>{t("calendar.recurring")}</label>
                    </Col>
                    <Col span={isFullDisplay ? 16 : 24}>
                        <div className={styles.dropDown}>
                            <KlassDropdown
                                onChange={(newValue) => onChangeEventData("recurring", newValue)}
                                options={OPTIONS_FREQUENCY}
                                value={eventData.recurring}
                                labelKey="label"
                                valueKey="value"
                                placeholder={t("calendar.recurring")}
                            />
                        </div>
                    </Col>
                    {renderRecurring()}
                </Row>

                {/*renderPrivateField()*/}

                <Row gutter={[16, 12]} className={styles.rowItem}>
                    {isFullDisplay && (
                        <Col span={2} className={styles.eventPopupLabel}>
                            <Icon icon="NOTIFY" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                    )}
                    <Col span={isFullDisplay ? 6 : 24} className={styles.eventPopupLabel}>
                        <label>{t("calendar.reminder")}</label>
                    </Col>
                    <Col span={isFullDisplay ? 16 : 24}>
                        <KlassDropdown
                            creatable={true}
                            isMulti={true}
                            isClearable={true}
                            placeholder="Select or type reminder"
                            formatCreateLabel={(inputValue) => `Use "${inputValue} minutes"`}
                            options={reminderOptions}
                            valueKey="value"
                            labelKey="label"
                            onChange={(options) => {
                                onChangeEventData(
                                    "reminders",
                                    options
                                        .map((option) =>
                                            option.__isNew__
                                                ? isNaN(option.value)
                                                    ? null
                                                    : {
                                                          value: +option.value * 60,
                                                          label: `${option.value} minutes before`
                                                      }
                                                : option
                                        )
                                        .filter(Boolean) ?? []
                                )
                            }}
                            value={eventData.reminders}
                            error={!!errors["reminders"]}
                        />
                    </Col>
                    {isFullDisplay && <Col span={8} />}
                    <Col span={isFullDisplay ? 16 : 24}>
                        <Checkbox
                            checked={!!eventData.withSMS}
                            onChange={(e) => onChangeEventData("withSMS", e.target.checked)}>
                            {t("calendar.sendSmsReminder")}
                        </Checkbox>
                    </Col>
                </Row>
                <Row gutter={[16, 6]} className={styles.rowItem}>
                    {isFullDisplay && (
                        <Col span={2} className={styles.eventPopupLabel}>
                            <Icon icon="NOTES" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                    )}
                    <Col span={isFullDisplay ? 6 : 24} className={styles.eventPopupLabel}>
                        <label>{t("calendar.notes")}</label>
                    </Col>
                    <Col span={isFullDisplay ? 16 : 24}>
                        <BaseTextArea
                            onChange={(newValue) => onChangeEventData("description", newValue)}
                            placeholder={t("calendar.notes")}
                            value={eventData.description}
                        />
                    </Col>
                </Row>

                <Row gutter={[16, 12]} className={styles.rowItem}>
                    {isFullDisplay && <Col span={8} />}
                    <Col span={isFullDisplay ? 16 : 24}>
                        <Checkbox
                            checked={!!eventData.eventSignInRequired}
                            onChange={(e) => onChangeEventData("eventSignInRequired", e.target.checked)}>
                            Sign-in Required
                        </Checkbox>
                    </Col>
                    {!!eventData.eventSignInRequired && (
                        <>
                            <Col span={isFullDisplay ? 8 : 24}>
                                <label>For individuals that attended change status to</label>
                            </Col>
                            <Col span={isFullDisplay ? 16 : 24}>
                                <StudentStatusSelect
                                    value={eventData.attendedChangeToStatus}
                                    onChange={(newValue) => onChangeEventData("attendedChangeToStatus", newValue)}
                                />
                            </Col>
                            <Col span={isFullDisplay ? 8 : 24}>
                                <label>For individuals that didn’t attended change status to</label>
                            </Col>
                            <Col span={isFullDisplay ? 16 : 24}>
                                <StudentStatusSelect
                                    value={eventData.notAttendedChangeToStatus}
                                    onChange={(newValue) => onChangeEventData("notAttendedChangeToStatus", newValue)}
                                />
                            </Col>
                            <Col span={isFullDisplay ? 8 : 24}>
                                <label>Trigger workflow upon completion (for attendants)</label>
                            </Col>
                            <Col span={isFullDisplay ? 16 : 24}>
                                <WorkflowSelect
                                    isClearable={true}
                                    triggerElement={WorkflowTriggerElement.CalendarEventCompletion}
                                    value={eventData.completionWorkflow}
                                    onChange={(newValue) =>
                                        onChangeEventData(
                                            "completionWorkflow",
                                            newValue ? pick(newValue, ["id", "code", "name"]) : undefined
                                        )
                                    }
                                />
                            </Col>
                        </>
                    )}
                </Row>
            </div>
            {renderAction()}
        </div>
    )
}
