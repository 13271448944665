import React from "react"
import styles from "../KlassappTable.module.css"
import {useTranslation} from "react-i18next"

type Props = {
    colSpan: number
    emptyText?: string
}

export default function EmptyRow(props: Props) {
    const {t} = useTranslation(["common"])
    const {colSpan, emptyText} = props

    return (
        <tr className={styles.tableRow}>
            <td colSpan={colSpan}>
                <p className={styles.emptyRowData}>{emptyText ?? t("common:message.emptyData")}</p>
            </td>
        </tr>
    )
}
