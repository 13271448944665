import {get} from "lodash"
import BaseService from "./Base"
import {Zoom} from "types/zoom"
import {AutoDialer} from "types/auto-dialer"

class ZoomService extends BaseService {
    async getMyZoomAccount(): Promise<Zoom.ZoomAccount> {
        const response = await this.post("/v1/zoom/me", {})
        return get(response, "data.data", {})
    }

    async revokeAccount(): Promise<void> {
        const response = await this.post("/v1/zoom/revoke", {})
        return get(response, "data.data", {})
    }

    async grantAccount(params): Promise<AutoDialer.ZoomAuthorization> {
        const response = await this.post("/v1/zoom/grant", params)
        return get(response, "data.data", {})
    }
}

export default ZoomService
