import React, {useState} from "react"
import {Popover} from "antd"
import cx from "classnames"
import {Icon} from "components/Icon"
import {chatServiceV2} from "services"
import {handleError} from "helpers"
import {BaseLoading} from "components/Loading"
import {Notifications} from "./Notifications"
import styles from "./Notification.module.css"
import {useQuery} from "@tanstack/react-query"
import {Communication} from "types/communication"

export function Notification() {
    const [visible, setVisible] = useState(false)
    const [notifications, setNotifications] = useState<Communication.NotificationSummaryItem[]>([])
    const [unreadCount, setUnreadCount] = useState(0)

    const summaryQuery = useQuery(["notification-summary"], () => chatServiceV2.summaryNotifications(), {
        onSuccess: (resp) => {
            const {data} = resp
            let unread = 0
            data.forEach((item) => {
                unread += item.unread || 0
            })
            setUnreadCount(unread)
            setNotifications(data.filter((x) => x.unread > 0))
        },
        onError: (err) => {
            handleError(err)
        }
    })

    const updateUnreadCount = (readCount: number) => {
        const newUnreadCount = unreadCount - readCount
        setUnreadCount(newUnreadCount)
    }

    const onMarkedAllAsReadSuccess = () => {
        setNotifications([])
        setUnreadCount(0)
    }

    const renderNotifications = () => {
        if (summaryQuery.isLoading) {
            return (
                <div>
                    <BaseLoading isShow />
                </div>
            )
        }
        return (
            <Notifications
                notifications={notifications}
                updateUnreadCount={updateUnreadCount}
                onMarkedAllAsReadSuccess={onMarkedAllAsReadSuccess}
                onClose={() => onVisibleChange(false)}
            />
        )
    }

    const onVisibleChange = (newVisible: boolean) => {
        setVisible(newVisible)
    }

    return (
        <Popover
            visible={visible}
            trigger="click"
            placement="bottomRight"
            content={renderNotifications}
            onVisibleChange={onVisibleChange}>
            <div className={cx(styles.notificationWrap, {[styles.notificationWrap__opened]: visible})}>
                <Icon icon="BELL_LINE" className={styles.notificationIcon} color={visible ? "#fff" : "#1e90ff"} />
                {unreadCount > 0 && !visible && <div className={styles.notificationUnread} />}
            </div>
        </Popover>
    )
}
