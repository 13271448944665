/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo, useRef} from "react"
import classNames from "classnames"
import {Icon, IconType} from "components/Icon"
import styles from "./MessageOptions.module.css"
import {Dropdown, Menu, Tooltip} from "antd"
import {ReactComponent as MenuIcon} from "./assets/menu.svg"
import {ReactComponent as FollowUpIcon} from "./assets/followup.svg"
import {Communication} from "types/communication"

export type MessageOptionItem = {
    icon: IconType
    title: string
    visible: boolean
    onClick?: (data?: any) => void
}

type Props = {
    items: MessageOptionItem[]
    onClose: () => void
}

export const MessageOptions = (props: Props) => {
    const {items, onClose} = props
    const ref = useRef(null)

    const allItems = useMemo(() => {
        return items.filter((i) => i.visible)
    }, [items])

    const showedItems = useMemo(() => {
        return allItems.slice(0, 3)
    }, [allItems])

    const moreMenuItems = () => {
        const moreItems = allItems.slice(3)
        return (
            <Menu className={styles.dropdownMenu}>
                {moreItems.map((x, index) => {
                    if (x.icon === "FOLLOW_UP") {
                        return (
                            <Menu.SubMenu
                                title={x.title}
                                icon={<Icon icon={x.icon} className={styles.menuIcon} />}
                                key={index}>
                                {Communication.FOLLOW_UP_TYPES.map((y) => (
                                    <Menu.Item
                                        key={y.label}
                                        onClick={() => {
                                            x.onClick?.(y.value)
                                        }}>{`Remind me in ${y.label}`}</Menu.Item>
                                ))}
                            </Menu.SubMenu>
                        )
                    }
                    return (
                        <Menu.Item
                            key={index}
                            icon={<Icon icon={x.icon} className={styles.menuIcon} />}
                            onClick={(e) => {
                                x.onClick?.()
                            }}>
                            {x.title}
                        </Menu.Item>
                    )
                })}
            </Menu>
        )
    }

    return (
        <div
            className={styles.root}
            ref={ref}
            onMouseLeave={onClose}
            onClick={(e) => {
                e.stopPropagation()
            }}>
            {showedItems.map((item, index) => {
                if (item.icon === "FOLLOW_UP") {
                    return (
                        <Tooltip title={item.title} key={`options-${index}`}>
                            <Dropdown
                                overlay={
                                    <Menu className={styles.dropdownMenu}>
                                        {Communication.FOLLOW_UP_TYPES.map((x) => (
                                            <Menu.Item
                                                key={x.label}
                                                onClick={() => {
                                                    item.onClick?.(x.value)
                                                }}>{`Remind me in ${x.label}`}</Menu.Item>
                                        ))}
                                    </Menu>
                                }
                                trigger={["hover"]}
                                className={styles.iconWrap}
                                placement="bottomRight">
                                <FollowUpIcon className={styles.icon} />
                            </Dropdown>
                        </Tooltip>
                    )
                }
                return (
                    <Tooltip title={item.title} key={`options-${index}`}>
                        <span
                            className={styles.item}
                            onClick={(e) => {
                                e.stopPropagation()
                                item.onClick?.()
                            }}>
                            <Icon icon={item.icon} color="#939393" className={classNames(styles.icon)} />
                        </span>
                    </Tooltip>
                )
            })}
            {showedItems.length < allItems.length && (
                <>
                    <span className={styles.divider} />
                    <Dropdown
                        overlay={moreMenuItems}
                        trigger={["hover"]}
                        className={styles.iconWrap}
                        placement="bottomRight">
                        <MenuIcon className={styles.icon} />
                    </Dropdown>
                </>
            )}
        </div>
    )
}
