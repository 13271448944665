import {Icon} from "components"
import React, {useState} from "react"
import {SketchPicker} from "react-color"
import {KlassappTableContext} from "uiKit/table"
import styles from "./ColorPicker.module.css"

const ColorPicker = (props) => {
    const {onChange, value, disable = false} = props
    const [isShowSelect, setIsShowSelect] = useState(false)
    return (
        <KlassappTableContext.Consumer>
            {({setIsShowScrollTable}) => (
                <>
                    {isShowSelect && <div onClick={() => setIsShowSelect(false)} className={styles.backdrop} />}
                    <div className={styles.parent}>
                        <div
                            onClick={
                                disable
                                    ? undefined
                                    : () => {
                                          setIsShowSelect(!isShowSelect)
                                          setIsShowScrollTable(!isShowSelect === true ? false : true)
                                      }
                            }
                            className={styles.container}>
                            <div
                                className={styles.displayColor}
                                style={{
                                    background: value
                                }}
                            />
                            {disable ? (
                                <div className={styles.icon} />
                            ) : (
                                <Icon color="#000" icon={"ARROW_DOWN"} className={styles.icon} />
                            )}
                        </div>
                        {isShowSelect && (
                            <div className={styles.selectPicker}>
                                <SketchPicker
                                    color={value}
                                    disableAlpha={true}
                                    onChangeComplete={(newValue) => {
                                        onChange(newValue.hex)
                                        setIsShowSelect(false)
                                        setIsShowScrollTable(true)
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        </KlassappTableContext.Consumer>
    )
}

export default ColorPicker
