import React, {useState} from "react"
import styles from "./ScreenCapture.module.css"
import {attendanceService} from "services"
import {useModel} from "hooks"
import {Icon} from "components"
import {Popover} from "antd"
import classNames from "classnames"

export const ScreenCapture = () => {
    const [isCapturing, setIsCapturing] = useState(false)
    const model = useModel()

    const video: any = document.getElementById("hiddenVideo")

    const startCapture = async () => {
        try {
            //@ts-ignore: Property 'getDisplayMedia' does not exist on type 'MediaDevices'. TS2339
            video.srcObject = await navigator.mediaDevices.getDisplayMedia({video: true, audio: false})
            video.srcObject.getVideoTracks()[0].addEventListener("ended", stopCapture)

            setIsCapturing(true)
            model.screenCapture(async (base64Image) => {
                await attendanceService.uploadScreenShots(base64Image)
            })
        } catch (err) {
            console.error("Error: " + err)
            if (err.toString().includes("NotAllowedError: Permission denied by system")) {
                window.document.location.href =
                    "x-apple.systempreferences:com.apple.preference.security?Privacy_ScreenCapture"
                alert("Give your web browser permissions to share your screen and try again")
            }
        }
    }

    const stopCapture = async () => {
        model.stopScreenCapture()
        setIsCapturing(false)
        if (!isCapturing) {
            return
        }
        let tracks = video.srcObject.getTracks()
        tracks.forEach((track) => track.stop())
        video.srcObject = null
        model.stopScreenCapture()
    }

    return (
        <div className={styles.screenCapture}>
            <Popover
                trigger="hover"
                placement="bottomRight"
                content={<div>{isCapturing ? "Recording your screen..." : "Record your screen"}</div>}>
                {!isCapturing ? (
                    <div className={styles.recordWrap} onClick={startCapture}>
                        <Icon icon="RECORD_START" className={styles.recordIcon} />
                    </div>
                ) : (
                    <div className={classNames(styles.recordWrap, styles.recording)} onClick={stopCapture}>
                        <Icon icon="RECORD_STOP" className={styles.recordIcon} />
                    </div>
                )}
            </Popover>
        </div>
    )
}
