/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from "react"
import FullCalendar from "@fullcalendar/react"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import "moment/min/locales"
import {forEach, isEmpty} from "lodash"
import classNames from "classnames"
import {BaseButton, BaseLoading, Icon} from "components"
import moment from "moment-timezone"
import {useModel} from "hooks"
import {convertTZ, getDateInWeekString, handleError} from "helpers"
import {EventContent} from "./parts"
import styles from "./Calendar.module.css"
import {useTranslation} from "react-i18next"
import {Major} from "types/major"
import {getScheduleEventColor} from "helpers/calendar"

type Props = {
    term: Major.TermSequence
    termDetail: any
    workSchedule: any
}

const Calendar = (props: Props) => {
    const {term, termDetail, workSchedule} = props
    const [isLoading, setIsLoading] = useState(false)
    const [monthLabel, setMonthLabel] = useState("")
    const [events, setEvents] = useState([])
    const [clickInfo, setClickInfo] = useState<any>({})
    const calendarRef = useRef(null)
    const model = useModel()
    const locale = model.getLocale() === "en" ? "en-au" : "es"
    const {t} = useTranslation(["common"])
    const startDate = termDetail ? termDetail.start_date : term.start_date
    const endDate = termDetail ? termDetail.end_date : term.end_date
    const [calendarOptions, setCalendarOptions] = useState({
        isWeeklyLessonNotFixed: false,
        showHolidaysAndBreaks: true,
        showWorkHours: false
    })

    useEffect(() => {
        getAllEvents()
    }, [])

    useEffect(() => {
        getWorkScheduleEvents()
    }, [])

    useEffect(() => {
        if (calendarRef.current) {
            updateMonthLabel(calendarRef.current.getApi().getDate())
        }
    }, [])

    const getAllEvents = () => {
        try {
            setIsLoading(true)
            const addedScheduleIds = []
            for (let index = 0; index < term.courses.length; index += 1) {
                const course = term.courses[index]
                const {schedule} = course
                if (schedule && !addedScheduleIds.includes(schedule.id)) {
                    addedScheduleIds.push(schedule.id)
                    getEventsBySchedule(schedule)
                }
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const getEventsBySchedule = (schedule) => {
        const scheduleEvents = getScheduleEvents(schedule)
        const eventWorkSchedules = getEventsByWorkScheduleCampus(schedule)
        const events = [...scheduleEvents, ...eventWorkSchedules]
        setEvents((prev) => [...prev, ...events])
    }

    const getWorkScheduleEvents = () => {
        const workScheduleCampusGroup = {}
        forEach(workSchedule?.campuses, (campus) => {
            workScheduleCampusGroup[`${campus.id}`] = [
                ...(workScheduleCampusGroup[`${campus.id}`] || []),
                ...workSchedule.events
            ]
        })
        return workScheduleCampusGroup
    }

    const changeMonth = (action: "next" | "previous") => {
        if (calendarRef.current) {
            const date = calendarRef.current.getApi().getDate()
            if (date) {
                if (action === "previous") {
                    const prevMonth = moment(date).subtract(1, "month").toDate()
                    setMonthLabel(moment(prevMonth).format("MMMM YYYY"))
                    calendarRef.current.getApi().gotoDate(prevMonth)
                } else {
                    const nextMonth = moment(date).add(1, "month").toDate()
                    setMonthLabel(moment(nextMonth).format("MMMM YYYY"))
                    calendarRef.current.getApi().gotoDate(nextMonth)
                }
            }
        }
    }

    const updateMonthLabel = (date) => {
        if (moment(date).month() !== moment(date).add(6, "days").month()) {
            setMonthLabel(moment(date).locale(locale).add(6, "days").format("MMMM YYYY"))
        } else {
            setMonthLabel(moment(date).locale(locale).format("MMMM YYYY"))
        }
    }

    const changeWeek = (action: "next" | "previous") => {
        let method = "next"
        if (action === "previous") {
            method = "prev"
        }
        if (calendarRef.current) {
            calendarRef.current.getApi()[method]()
            updateMonthLabel(calendarRef.current.getApi().getDate())
        }
    }

    const onClickToday = () => {
        const today = new Date()
        calendarRef.current.getApi().gotoDate(today)
    }

    const getScheduleEvents = (schedule) => {
        const result = []
        const schedule_events = schedule?.schedule_events || []
        const instructorIds = (schedule.instructors || []).map((instructor) => instructor.userId)
        schedule_events.forEach((event) => {
            const {
                object_id,
                group_id,
                start_at_utc,
                end_at_utc,
                duration,
                description,
                rrule,
                start_at_timezone,
                json_data
            } = event
            const {eventType, sub_module_id} = json_data
            // const submodules = getSubmodules(eventType)
            // const submodule = submodules.find((item) => item.submoduleId === sub_module_id)
            result.push({
                id: object_id,
                groupId: group_id,
                start: moment(convertTZ(new Date(start_at_utc), start_at_timezone)).format(),
                end: moment(convertTZ(new Date(end_at_utc), start_at_timezone)).format(),
                startDateTime: moment(convertTZ(new Date(start_at_utc), start_at_timezone)).format(),
                endDateTime: moment(convertTZ(new Date(end_at_utc), start_at_timezone)).format(),
                color: getScheduleEventColor(eventType),
                scheduleKey: schedule.key,
                scheduleEventsType: eventType,
                submoduleId: sub_module_id,
                // submodule,
                title: `${schedule.schedule_suffix}`,
                course: {name: schedule.courseName, code: schedule.courseCode},
                description: description,
                instructorIds,
                instructors: schedule.instructors,
                order: 2,
                json_data,
                rrule
            })
        })
        return result
    }

    const getEventsByWorkScheduleCampus = (schedule) => {
        const result = []
        const workScheduleCampusData = getWorkScheduleEvents()
        if (!isEmpty(schedule.campus)) {
            const workSchedulesAdded = {}
            const instructorIds = (schedule.instructors || []).map((instructor) => instructor.userId)
            if (calendarOptions.showHolidaysAndBreaks) {
                schedule.campus.forEach((campus) => {
                    const events = workScheduleCampusData[`${campus.id}`] || []
                    events
                        .filter((event) => ["holiday", "break"].includes(event.description))
                        .forEach((event) => {
                            if (!workSchedulesAdded[event.object_id]) {
                                const {
                                    object_id,
                                    group_id,
                                    start_at_utc,
                                    end_at_utc,
                                    name,
                                    description,
                                    json_data,
                                    schedule_events_type,
                                    rrule
                                } = event
                                workSchedulesAdded[object_id] = true
                                result.push({
                                    id: object_id,
                                    groupId: group_id,
                                    start: moment(start_at_utc).local().format(),
                                    end: moment(end_at_utc).local().format(),
                                    startDateTime: moment(start_at_utc).local().format(),
                                    endDateTime: moment(end_at_utc).local().format(),
                                    color: json_data.color,
                                    scheduleKey: "",
                                    isWorkSchedule: true,
                                    scheduleEventsType: schedule_events_type,
                                    title: name || description,
                                    description,
                                    instructorIds,
                                    instructors: schedule.instructors,
                                    order: 1,
                                    display: "background",
                                    isHolidayBreak: true,
                                    rrule
                                })
                            }
                        })
                })
            }
            if (calendarOptions.showWorkHours) {
                schedule.campus.forEach((campus) => {
                    workScheduleCampusData[`${campus.id}`]
                        .filter((event) => ["workday"].includes(event.description))
                        .forEach((event) => {
                            if (!workSchedulesAdded[event.object_id]) {
                                const {
                                    object_id,
                                    group_id,
                                    start_at_utc,
                                    end_at_utc,
                                    name,
                                    description,
                                    json_data,
                                    schedule_events_type,
                                    rrule
                                } = event
                                workSchedulesAdded[event.object_id] = true
                                result.push({
                                    id: object_id,
                                    groupId: group_id,
                                    start: moment(start_at_utc).local().format(),
                                    end: moment(end_at_utc).local().format(),
                                    startDateTime: moment(start_at_utc).local().format(),
                                    endDateTime: moment(end_at_utc).local().format(),
                                    color: json_data.color,
                                    scheduleKey: "",
                                    isWorkSchedule: true,
                                    isWorkday: true,
                                    scheduleEventsType: schedule_events_type,
                                    title: name || description,
                                    description,
                                    instructorIds,
                                    instructors: schedule.instructors,
                                    order: 1,
                                    display: "background",
                                    isHolidayBreak: true,
                                    rrule: rrule
                                })
                            }
                        })
                })
            }
        }
        return result
    }

    const handleEventClick = (clickInfo) => {
        const {isHolidayBreak} = clickInfo.event?.extendedProps || {}
        if (!isHolidayBreak) {
            setClickInfo(clickInfo)
        }
    }

    const handleShowEventPreview = (visible) => {
        // setIsShowEventPreview(visible)
    }

    const renderEventContent = (eventInfo) => {
        return (
            <EventContent
                eventInfo={eventInfo}
                clickInfo={clickInfo}
                isShowGoing={false}
                handleShowEventPreview={handleShowEventPreview}
            />
        )
    }

    const renderDayHeaderContent = ({date, isToday}) => {
        const dateInWeek = getDateInWeekString(date)
        const isTermStartDate =
            moment(date).format("DD/MM/YYYY").toString() === moment(startDate).utc().format("DD/MM/YYYY").toString()
        const isTermEndDate =
            moment(date).format("DD/MM/YYYY").toString() === moment(endDate).utc().format("DD/MM/YYYY").toString()

        return (
            <div className={styles.calendarDayHeaderWrap}>
                <div
                    className={classNames(styles.calendarDayHeader__infoWrap, {
                        [styles.calendarDayHeader__today]: isToday
                    })}>
                    <span className={styles.calendarDayHeader__info}>{dateInWeek.slice(0, 3)}</span>
                    <span className={styles.calendarDayHeader__info}>{moment(date).format("MM/DD")}</span>
                </div>
                {isTermStartDate && (
                    <div
                        className={classNames(styles.calendarDayHeader__termDate, styles.calendarDayHeader__startDate)}>
                        <Icon icon="AWARD_FILL" color="#fff" />
                        <span>Term start date</span>
                    </div>
                )}
                {isTermEndDate && (
                    <div className={classNames(styles.calendarDayHeader__termDate, styles.calendarDayHeader__endDate)}>
                        <Icon icon="AWARD_FILL" color="#fff" />
                        <span>Term end date</span>
                    </div>
                )}
            </div>
        )
    }

    if (isLoading) {
        return <BaseLoading isShow />
    }

    return (
        <div className={styles.calendarContainer}>
            <div className={styles.header}>
                <div className={styles.arrowsContainer}>
                    <button className={styles.arrowButton} onClick={() => changeMonth("previous")} title="Prev Month">
                        <Icon icon="ARROW_LEFT" className={styles.headerIcon} color="var(--black-base)" />
                    </button>
                    <span className={styles.dateInfo}>{monthLabel}</span>
                    <button className={styles.arrowButton} onClick={() => changeMonth("next")} title="Next Month">
                        <Icon icon="ARROW_RIGHT" className={styles.headerIcon} color="var(--black-base)" />
                    </button>
                </div>
                <div className={styles.arrowsContainer}>
                    <BaseButton title={t("common:action.today")} onClick={onClickToday} variant="secondary" />
                    <button className={styles.arrowButton} onClick={() => changeWeek("previous")} title="Prev Week">
                        <Icon icon="ARROW_LEFT" className={styles.headerIcon} color="var(--black-base)" />
                    </button>
                    <button className={styles.arrowButton} onClick={() => changeWeek("next")} title="Next Week">
                        <Icon icon="ARROW_RIGHT" className={styles.headerIcon} color="var(--black-base)" />
                    </button>
                </div>
            </div>
            <div className={classNames(styles.fullCalendarContainer, "course-schedule-calendar")}>
                <FullCalendar
                    events={events}
                    initialView="timeGridWeek"
                    eventOverlap={false}
                    editable={false}
                    selectable={false}
                    selectMirror={true}
                    allDaySlot={false}
                    dayMaxEvents={2}
                    dayMaxEventRows={2}
                    slotDuration="01:00:00"
                    scrollTime="08:00:00"
                    firstDay={0}
                    eventOrder="order"
                    ref={calendarRef}
                    plugins={[timeGridPlugin, interactionPlugin]}
                    dayHeaderContent={renderDayHeaderContent}
                    headerToolbar={{
                        left: "",
                        center: "",
                        right: ""
                    }}
                    views={{
                        timeGridWeek: {
                            titleFormat: {year: "numeric", month: "long"}
                        }
                    }}
                    eventTimeFormat={{
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false
                    }}
                    slotLabelFormat={{
                        hour: "numeric",
                        minute: "2-digit",
                        hour12: model.user?.timeFormat === "12 H"
                    }}
                    scrollTimeReset={false}
                    initialDate={new Date(startDate)}
                    eventContent={renderEventContent}
                    eventClick={handleEventClick}
                />
            </div>
        </div>
    )
}

export default Calendar
