/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {StatusCards} from "./StatusCards/StatusCards"
import PaymentsTable from "sections/StudentAccount/Student/parts/StudentLedgerTab/parts/PaymentsTab/PaymentsTable/PaymentsTable"
import {Moment} from "moment/moment"
import {FormLabel} from "components/Form"
import {BaseRangePicker} from "components/DateTimePicker"
import {FilterKey} from "types/filter"
import {useModel} from "hooks"
import {isEmpty} from "lodash"
import moment from "moment"

const NinetyTenTab = () => {
    const model = useModel()

    const [fiscalYearDates, setFiscalYearDates] = useState<Moment[] | null>(() => {
        const storageData = model.getStorageFilter(FilterKey.StudentAccount9010Stats)
        if (!isEmpty(storageData)) {
            return [moment(storageData.fiscalYearDateStart), moment(storageData.fiscalYearDateEnd)]
        }
        return null
    })

    useEffect(() => {
        if (fiscalYearDates) {
            model.updateStorageFilter(FilterKey.StudentAccount9010Stats, {
                fiscalYearDateStart: fiscalYearDates[0]?.toISOString(),
                fiscalYearDateEnd: fiscalYearDates[1]?.toISOString()
            })
        }
    }, [fiscalYearDates])

    // const settingsQuery = useQuery(
    //     ["sa-setting"],
    //     async () => {
    //         return await generalSettingsService.getSettings([
    //             GeneralSettingField.SaFiscalYearStart,
    //             GeneralSettingField.SaFiscalYearEnd
    //         ])
    //     },
    //     {
    //         initialData: []
    //     }
    // )

    // useEffect(() => {
    //     if (!settingsQuery.isFetched) return
    //     const fiscalYearStart = settingsQuery.data.find(
    //         (setting) => setting.field === GeneralSettingField.SaFiscalYearStart
    //     )?.value
    //     const fiscalYearEnd = settingsQuery.data.find(
    //         (setting) => setting.field === GeneralSettingField.SaFiscalYearEnd
    //     )?.value
    //     if (fiscalYearStart && fiscalYearEnd) {
    //         setFiscalYearDates([moment(toDateOnly(fiscalYearStart)), moment(toDateOnly(fiscalYearEnd))])
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [settingsQuery.isFetched])

    return (
        <div>
            <div className="mb-24">
                <FormLabel label="Fiscal Year" />
                <BaseRangePicker
                    placeholder={["Start Date", "End Date"]}
                    value={fiscalYearDates}
                    onChange={setFiscalYearDates}
                />
            </div>

            <StatusCards fiscalYearDates={fiscalYearDates} />

            <PaymentsTable isNinetyTenReport={true} fiscalYearDates={fiscalYearDates} />
        </div>
    )
}

export default NinetyTenTab
