import {get} from "lodash"
import BaseService from "./Base"

class AdmissionService extends BaseService {
    async getAllLeadSources(params = {}) {
        const response = await this.post("/v1/admissions/lead-sources/list", params)
        return get(response, "data", [])
    }

    async getMaxIdLeadSources(params = {}) {
        const response = await this.post("/v1/admissions/lead-sources/get-max-id", params)
        return get(response, "data.data", 0)
    }

    async createLeadSource(params = {}) {
        const response = await this.post("/v1/admissions/lead-sources/create", params)
        return get(response, "data.data", {})
    }

    async duplicateLeadSource(leadSources) {
        const response = await this.post(`/v1/admissions/lead-sources/create`, leadSources)
        return get(response, "data", {})
    }

    async updateLeadSource(id, data, params = {}) {
        data.admissionsLeadSourceId = id
        const response = await this.post(`/v1/admissions/lead-sources/edit`, data, params)
        return get(response, "data", {})
    }

    async deleteLeadSource(admissionsLeadSourceIds) {
        const response = await this.post(`/v1/admissions/lead-sources/delete`, {admissionsLeadSourceIds})
        return get(response, "data", {})
    }

    async getAllLeadDistributions(params = {}) {
        const response = await this.post("/v1/admissions/lead-distributions/list", params)
        return get(response, "data", [])
    }

    async saveLeadDistributions(params = {}) {
        const response = await this.post("/v1/admissions/lead-distributions/save", params)
        return get(response, "data.data", {})
    }

    async deleteLeadDistributions(params = {}) {
        const response = await this.post("/v1/admissions/lead-distributions/delete", params)
        return get(response, "data.data", {})
    }

    async getLeadDistributionSettingsList(params = {}) {
        const response = await this.post("/v1/admissions/lead-distributions/settings/list", params)
        return get(response, "data", [])
    }

    async saveLeadDistributionSettings(params = {}) {
        const response = await this.post("/v1/admissions/lead-distributions/settings/save", params)
        return get(response, "data", [])
    }

    async getLeadList(params = {}) {
        const response = await this.post("/v1/leads/list", params)
        return get(response, "data", [])
    }

    async deleteLead(params = {}) {
        const response = await this.post("/v1/leads/delete", params)
        return get(response, "data", {})
    }

    async updateStatusLead(params = {}) {
        const response = await this.post("/v1/leads/update-processing-status", params)
        return get(response, "data", {})
    }

    async getMaxIdTargets(params = {}) {
        const response = await this.post("/v1/admissions/targets/get-max-id", params)
        return get(response, "data.data", 0)
    }

    async getAllTargets(params = {}) {
        const response = await this.post("/v1/admissions/targets/list", params)
        return get(response, "data", [])
    }

    async createTarget(params = {}) {
        const response = await this.post("/v1/admissions/targets/create", params)
        return get(response, "data.data", {})
    }

    async updateTarget(id, data, params = {}) {
        data.admissionsTargetId = id
        const response = await this.post(`/v1/admissions/targets/edit`, data, params)
        return get(response, "data", {})
    }

    async deleteTargets(params = {}) {
        const response = await this.post("/v1/admissions/targets/delete", params)
        return get(response, "data.data", {})
    }

    async getAllHowDidYouKnowAbouUs(params = {}) {
        const response = await this.post("/v1/admissions/how-did-you-know-about-us/list", params)
        return get(response, "data", [])
    }

    async createHowDidYouKnowAbouUs(params = {}) {
        const response = await this.post("/v1/admissions/how-did-you-know-about-us/create", params)
        return get(response, "data.data", {})
    }

    async updateHowDidYouKnowAbouUs(id, data, params = {}) {
        data.howDidYouKnowAboutUsId = id
        const response = await this.post(`/v1/admissions/how-did-you-know-about-us/edit`, data, params)
        return get(response, "data", {})
    }

    async deleteHowDidYouKnowAbouUs(howDidYouKnowAboutUsIds) {
        const response = await this.post(`/v1/admissions/how-did-you-know-about-us/delete`, {howDidYouKnowAboutUsIds})
        return get(response, "data", {})
    }

    async getAllFunnelStatuses(params = {}) {
        const response = await this.post("/v1/admissions/funnel-statuses/list", params)
        return get(response, "data", [])
    }

    async createFunnelStatus(params = {}) {
        const response = await this.post("/v1/admissions/funnel-statuses/create", params)
        return get(response, "data.data", {})
    }

    async updateFunnelStatus(params = {}) {
        const response = await this.post(`/v1/admissions/funnel-statuses/edit`, params)
        return get(response, "data", {})
    }

    async deleteFunnelStatus(params = {}) {
        const response = await this.post("/v1/admissions/funnel-statuses/delete", params)
        return get(response, "data.data", {})
    }

    async getFunnelStats(params = {}) {
        const response = await this.post("/v1/admissions/funnel-stats/list", params)
        return get(response, "data.data", [])
    }

    async getConverionRateStatus() {
        const response = await this.post("/v1/admissions/funnel-statuses/rates/get", {})
        return get(response, "data.data", {})
    }

    async updateConverionRateStatus(params = {}) {
        const response = await this.post("/v1/admissions/funnel-statuses/rates/update", params)
        return get(response, "data.data", {})
    }

    async listLeadSourceStats(params = {}) {
        const response = await this.post("/v1/admissions/lead-source-stats/list", params)
        return get(response, "data", [])
    }

    async listLeadSourceByProfileStats(params = {}) {
        const response = await this.post("/v1/admissions/lead-source-stats/by-profile-state", params)
        return get(response, "data", [])
    }

    async listLeadAdvisorByProfileStats(params = {}) {
        const response = await this.post("/v1/admissions/advisor-stats/by-profile-state", params)
        return get(response, "data", [])
    }

    async listAdvisorStats(params = {}) {
        const response = await this.post("/v1/admissions/advisor-stats/list", params)
        return get(response, "data", [])
    }

    async listAdvisorActivityStats(params = {}) {
        const response = await this.post("/v1/admissions/advisor-activity-stats/list", params)
        return get(response, "data", [])
    }
}

export default AdmissionService
