/* eslint-disable react-hooks/exhaustive-deps */
import debounce from "debounce-promise"
import React from "react"
import {useTranslation} from "react-i18next"
import {majorService} from "services"
import {KlassDropAsyncPaginate, KlassDropdownAsync, KlassDropdownAsyncProps} from "../Select"
import {formatCodeName} from "helpers"

type Props = Partial<KlassDropdownAsyncProps> & {
    campusId?: number | number[]
    degreeLevelId?: number | number[]
    termId?: number
    isActive?: boolean
    termIds?: number[]
}

const ProgramSelect: React.FC<Props> = ({campusId, termIds, degreeLevelId, termId, isActive, ...props}) => {
    const {t} = useTranslation(["common"])

    const search = React.useCallback(
        debounce(async (search: string = "", loadedOptions) => {
            if (termId) {
                const {data} = await majorService.getMajorByTerm(termId)
                return {
                    options: data,
                    hasMore: false
                }
            }
            if (termIds?.length) {
                const {data} = await majorService.getMajorByTerm(undefined, termIds)
                return {
                    options: data,
                    hasMore: false
                }
            }
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const {data, total} = await majorService.getAll({
                filter: {
                    search,
                    isActive,
                    campusIds: campusId ? (Array.isArray(campusId) ? campusId : [campusId]) : undefined,
                    degreeLevelIds: degreeLevelId
                        ? Array.isArray(degreeLevelId)
                            ? degreeLevelId
                            : [degreeLevelId]
                        : undefined
                },
                range: {
                    page,
                    pageSize
                }
            })
            return {
                options: data,
                hasMore: loadedOptions.length < total
            }
        }, 500),
        [campusId, degreeLevelId, termId, isActive]
    )

    return (
        <KlassDropAsyncPaginate
            {...props}
            key={`${JSON.stringify(campusId)}-${JSON.stringify(degreeLevelId)}-${termId}`}
            placeholder={props.placeholder || t("selectField.placeholder")}
            loadOptions={search}
            defaultOptions={true}
            valueKey="id"
            labelKey="name"
            getOptionLabel={formatCodeName}
        />
    )
}

export default ProgramSelect
