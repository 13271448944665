import React from "react"
import {last} from "lodash"
import TimezoneSelect, {i18nTimezones} from "react-timezone-select"
import momentTz from "moment-timezone"
import {dropdownStyle} from "./dropdownStyle"

const timezone = momentTz.tz.guess()
const city = last((timezone || "").split("/"))

export function TimezoneDropdown(props) {
    const {stylesCustom, ...rest} = props
    let styles = dropdownStyle
    if (stylesCustom) {
        styles = {...styles, ...stylesCustom}
    }
    const customProps = {...rest, styles}

    return (
        <TimezoneSelect
            timezones={{
                ...i18nTimezones,
                [timezone]: city
            }}
            {...customProps}
        />
    )
}
