import React, {useState} from "react"
import {CareerServices} from "types/careerServices"
import {displaySince, handleError} from "helpers"
import {BaseButton, BaseTextArea} from "components"
import {careerService} from "services"
import styles from "./StudentCareerServiceNote.module.css"

type NoteType = "application" | "job"

type StudentCareerServiceNoteProps = {
    type: NoteType
    dataId: number
    notes: CareerServices.ApplicationNoteEntity[] | CareerServices.JobNoteEntity[]
}

export function StudentCareerServiceNote(props: StudentCareerServiceNoteProps) {
    const {type, dataId, notes: initNotes} = props

    const [notes, setNotes] = useState<CareerServices.ApplicationNoteEntity[] | CareerServices.JobNoteEntity[]>(
        initNotes
    )
    const [comment, setComment] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleClick = async (e) => {
        e.preventDefault()

        try {
            setIsSubmitting(true)

            const content = comment.trim()
            let newNote: any
            if (type === "application") {
                newNote = await careerService.createApplicationNote({
                    applicationId: dataId,
                    noteType: CareerServices.ApplicationNoteType.ToStudent,
                    content
                })
            } else {
                newNote = await careerService.createJobNote({
                    jobId: dataId,
                    content
                })
            }
            setNotes([...notes, newNote])
            setComment("")
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <div className={styles.notesContainer}>
            <div className={styles.title}>Student Notes</div>
            {notes &&
                notes.map((item) => {
                    return (
                        <div key={item.id} className={styles.noteItem}>
                            <div className={styles.noteContent}>{item.content}</div>
                            <div className={styles.noteDate}>{displaySince(item.createdAt, "DD/MM/YYYY hh:mm A")}</div>
                        </div>
                    )
                })}
            <BaseTextArea
                className={styles.noteInput}
                rows={3}
                placeholder="type"
                onChange={setComment}
                value={comment}
            />
            <div className={styles.noteBtn}>
                <BaseButton
                    variant="secondary"
                    title="Send"
                    size="small"
                    onClick={handleClick}
                    disabled={comment.trim() === ""}
                    loading={isSubmitting}
                />
            </div>
        </div>
    )
}
