/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useMemo, useState} from "react"
import {head, isEmpty} from "lodash"
import moment from "moment"
import {useModel} from "hooks"
import {TotalProgress} from "uiKit"
import {useTranslation} from "react-i18next"
import {attendanceService, instructionalService, termsService} from "services"
import {Col, Row, Tabs} from "antd"
import styles from "./TermAndCourse.module.css"
import {TermAndCourseProgress} from "uiKit/StudentProgress/TermAndCourseProgress"
import {KlassDropAsyncPaginate} from "components/Select"
import {formatCodeName, roundTwoDigits} from "helpers"
import debounce from "debounce-promise"
import TermAndCourseStats from "./TermAndCourseStats"
import {TabHeader} from "components/Tab"
import CoursesTab from "./CoursesTab"
import {TermDetails} from "types/terms"
import MajorsTab from "./MajorsTab"
import {FilterKey} from "types/filter"
import {BaseLoading} from "components"

const {TabPane} = Tabs

export enum TermAndCoursesTabs {
    TermCoursesMajors = "TermCoursesMajors",
    TermCoursesCourses = "TermCoursesCourses"
}

const TermAndCourses = (props) => {
    const {t} = useTranslation(["course"])
    const model = useModel()
    const [activeTabKey, onChangeTab] = useState<string>(TermAndCoursesTabs.TermCoursesCourses)

    const [totalHours, setTotalHours] = useState([
        {title: "Total hours scheduled", hour: 0, color: "#e95c7b"},
        {title: "Total hours completed", hour: 0, color: "#18a957"},
        {title: "Total hours attempted", hour: 0, color: "#ff8a38"},
        {title: "Total hours remaining", hour: 0, color: "#939393"},
        {title: "Total DistEd Hours", hour: 0, color: "#11763d"},
        {title: "Total Hours", hour: 100, color: "#11763d"}
    ])
    const [percent, setPercent] = useState(0)
    const [term, setTerm] = useState<TermDetails>()
    const [termData, setTermData] = useState<TermDetails>()
    const [isLoadingTermData, setIsLoadingTermData] = useState(false)
    const isClockHourSchool = true //term?.type === Major.Unit.Clock

    const onChangeTerm = useCallback((value) => {
        model.updateStorageFilter(FilterKey.TermCourseFilterTerm, {term: value})
        setTerm(value)
        getTermInfo(value.id)
    }, [])

    const getTermInfo = async (termId: number) => {
        try {
            setIsLoadingTermData(true)
            const data = await termsService.getTerm(termId)
            setTermData(data)
        } catch (error) {
            //
        } finally {
            setIsLoadingTermData(false)
        }
    }

    const getCurrentTerm = async () => {
        try {
            const params: any = {filter: {}}
            const profileId = model.profileId
            if (model.isStaff()) {
                params.filter.instructorProfileIds = [profileId]
            }
            const {data = []} = await instructionalService.getCurrentTerm(params)
            const storageData = model.getStorageFilter(FilterKey.TermCourseFilterTerm)
            if (storageData && storageData.term) {
                setTerm(storageData.term)
                getTermInfo(storageData.term.id)
            } else if (data.length) {
                const term: TermDetails = head(data)
                getTermInfo(term.id)
                setTerm(term)
            }
        } catch (err) {}
    }

    const getProgress = useCallback(async () => {
        const response = await attendanceService.getCourseStatistics({
            studentProfileIds: [],
            termIds: [term.id]
        })
        const data = response.data
        const totalHours = data.totalHours ?? 0
        const attempted = data.progress.reduce((total, progress) => (total += progress?.total ?? 0), 0)
        const completed = data.progress.reduce((total, progress) => (total += progress?.completed ?? 0), 0)
        const progressData = {
            distEdHours: data.distEdHours,
            attempted,
            completed,
            appliedToDegree: totalHours
        }
        let remainingHour = (progressData?.appliedToDegree ?? 0) - (progressData?.completed ?? 0)
        remainingHour = Math.max(remainingHour, 0)

        setTotalHours([
            {
                title: "Total hours scheduled",
                hour: progressData?.attempted ? Math.round(progressData?.attempted) : 0,
                color: "#e95c7b"
            },
            {
                title: "Total hours completed",
                hour: progressData?.completed ? roundTwoDigits(progressData.completed) : 0,
                color: "#18a957"
            },
            {
                title: "Total hours attempted",
                hour: isClockHourSchool ? 0 : progressData.attempted ? Math.round(progressData.attempted) : 0,
                color: "#ff8a38"
            },
            {title: "Total hours remaining", hour: remainingHour ? roundTwoDigits(remainingHour) : 0, color: "#939393"},
            {
                title: "Total DistEd Hours",
                hour: progressData?.distEdHours ? Math.round(progressData?.distEdHours) : 0,
                color: "#11763d"
            },
            {
                title: "Total Hours",
                hour: progressData?.appliedToDegree ? Math.round(progressData?.appliedToDegree) : 0,
                color: "#11763d"
            }
        ])
        setPercent(
            Math.min(
                Math.round(
                    progressData?.appliedToDegree > 0
                        ? (progressData?.completed * 100) / progressData?.appliedToDegree
                        : 0
                ),
                100
            )
        )
    }, [isClockHourSchool, term])

    useEffect(() => {
        if (term) {
            getProgress()
        }
    }, [term])

    useEffect(() => {
        getCurrentTerm()
    }, [])

    const searchTerm = async (search: string = "", loadedOptions) => {
        try {
            const {data, total} = await termsService.getAllTerms({
                fields: ["id", "name"],
                text: search,
                limit: 20,
                offset: loadedOptions.length,
                orderBy: "start_date",
                orderDir: "desc"
            })
            return {
                options: data,
                hasMore: loadedOptions.length < total
            }
        } catch (e) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const renderTermInfo = () => {
        if (isEmpty(termData)) {
            return null
        }
        if (isLoadingTermData) {
            return (
                <div className={styles.termInfoWrap}>
                    <BaseLoading isShow />
                </div>
            )
        }
        return (
            <div className={styles.termInfoWrap}>
                <Row gutter={[24, 12]}>
                    <Col span={8}>
                        <div className={styles.termInfo}>
                            <div className={styles.termInfoItem}>
                                <span className={styles.termInfoItem__label}>Start Date</span>
                                <span className={styles.termInfoItem__value}>
                                    {termData.start_date
                                        ? moment(termData.start_date).utc().format(model.dateFormat)
                                        : ""}
                                </span>
                            </div>
                            <div className={styles.termInfoItem}>
                                <span className={styles.termInfoItem__label}>End Date</span>
                                <span className={styles.termInfoItem__value}>
                                    {termData.end_date ? moment(termData.end_date).utc().format(model.dateFormat) : ""}
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={styles.termInfo}>
                            <div className={styles.termInfoItem}>
                                <span className={styles.termInfoItem__label}>Registration Start</span>
                                <span className={styles.termInfoItem__value}>
                                    {termData.registration_start_date
                                        ? moment(termData.registration_start_date).utc().format(model.dateFormat)
                                        : ""}
                                </span>
                            </div>
                            <div className={styles.termInfoItem}>
                                <span className={styles.termInfoItem__label}>Registration End</span>
                                <span className={styles.termInfoItem__value}>
                                    {termData.registration_end_date
                                        ? moment(termData.registration_end_date).utc().format(model.dateFormat)
                                        : ""}
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={styles.termInfo}>
                            <div className={styles.termInfoItem}>
                                <span className={styles.termInfoItem__label}>Add drop</span>
                                <span className={styles.termInfoItem__value}>
                                    {termData.add_drop_variant === "date"
                                        ? termData.add_drop_date
                                            ? moment(termData.add_drop_date).utc().format(model.dateFormat)
                                            : ""
                                        : termData.add_drop_hours}
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    const onSearchTerm = debounce(searchTerm, 300)

    const TermAndCourseTabView = useMemo(() => {
        return (
            <Tabs
                className="fullwidth klassSubTab"
                style={{marginTop: 40, paddingBottom: 80}}
                activeKey={activeTabKey}
                onChange={onChangeTab}>
                <TabPane
                    tab={<TabHeader title={t("academics:registrar.courses")} />}
                    key={TermAndCoursesTabs.TermCoursesCourses}>
                    {activeTabKey === TermAndCoursesTabs.TermCoursesCourses && term ? (
                        <CoursesTab terms={[term]} />
                    ) : null}
                </TabPane>
                <TabPane
                    tab={<TabHeader title={t("academics:registrar.majors")} />}
                    key={TermAndCoursesTabs.TermCoursesMajors}>
                    {activeTabKey === TermAndCoursesTabs.TermCoursesMajors && term ? (
                        <MajorsTab terms={[term]} />
                    ) : null}
                </TabPane>
            </Tabs>
        )
    }, [activeTabKey, term])

    return (
        <div className={styles.container}>
            <KlassDropAsyncPaginate
                stylesCustom={{
                    container: (provided, state) => {
                        return {
                            ...provided,
                            width: "40%"
                        }
                    }
                }}
                getOptionLabel={formatCodeName}
                value={term}
                valueKey="id"
                onChange={onChangeTerm}
                labelKey="name"
                loadOptions={onSearchTerm}
                placeholder="Select Term"
            />
            {renderTermInfo()}
            {termData ? <TermAndCourseStats term={termData} /> : null}
            <Row className={styles.progressView}>
                <Col span={12}>
                    <div className={styles.progressWrapLeft}>
                        <TotalProgress
                            activeSchedule={{
                                firstDayOfCourse: termData?.start_date,
                                lastDayOfCourse: termData?.end_date
                            }}
                            termInfo={termData}
                            percent={percent}
                        />
                    </div>
                </Col>
                <Col span={12}>
                    <div className={styles.progressWrapRight}>
                        <TermAndCourseProgress
                            title="Total hours"
                            totalTitle="Total hours"
                            totalHours={totalHours}
                            programVersionTotalClockHours={0}
                        />
                    </div>
                </Col>
            </Row>
            {TermAndCourseTabView}
        </div>
    )
}

export default TermAndCourses
