/* eslint-disable react-hooks/exhaustive-deps */
import {KlassappTableHOC} from "HOC"
import ButtonCheckbox from "components/buttons/buttonCheckbox"
import {Search} from "components/inputs/Search"
import {handleError} from "helpers"
import {routing} from "helpers/routing"
import {useModel} from "hooks"
import {cloneDeep, get, isEmpty} from "lodash"
import moment from "moment-timezone"
import React, {useCallback, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {useHistory} from "react-router-dom"
import {useDebounce} from "react-use"
import {academicCourseGradesService} from "services"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import styles from "./ClassActivitiesTab.module.css"
import {BaseButton} from "components"
import PopupVerifyClassActivities from "./PopupVerifyClassActivities"

export enum ClassActivityType {
    LessonGrade = "lesson-grade",
    ManualGrade = "manual-grade",
    External = "external"
}

const ClassActivities = (props) => {
    const [search, setSearch] = useState("")
    const [searchDebounce, setSearchDebounce] = useState("")
    const [isShowConfirmVerify, setIsShowConfirmVerify] = useState(false)
    useDebounce(() => setSearchDebounce(search), 500, [search])
    const {
        dispatch,
        dispatchFunc,
        scheduleId,
        page,
        getCurrentData,
        total,
        pageSize,
        termId,
        courseId,
        columns,
        data,
        allFields,
        fields,
        orderField,
        isLoading,
        isShowTableHeaderAction,
        isLoadedTableFuncs
    } = props

    const history = useHistory()
    const model = useModel()
    const {t} = useTranslation(["campuses"])

    const getFields = () => {
        return [
            "ID",
            "Date",
            "Time",
            "Name",
            "Student ID",
            "Student Name",
            "Instructor Name",
            "Lesson Name",
            "Duration (Minutes)",
            "Grade",
            "Alphabetical",
            "Notes",
            "Description",
            "Type",
            "Verified"
        ]
    }

    const getPageTitle = () => {
        return t("campuses.campus")
    }

    const renderType = (type: ClassActivityType) => {
        if (type === ClassActivityType.LessonGrade) {
            return "LESSON GRADE"
        } else if (type === ClassActivityType.ManualGrade) {
            return "MANUAL GRADE"
        } else {
            return "EXTERNAL"
        }
    }

    const renderCheckBox = (id: string, isEditing: boolean, field: string, nameField: string, checked: boolean) => {
        return (
            <div className={styles.checkBoxAffectGPA} style={{pointerEvents: isEditing ? "auto" : "none"}}>
                <ButtonCheckbox
                    id={id}
                    name={nameField}
                    classNameCheckBox={styles.checkbox}
                    allChecked={checked}
                    onClick={undefined}
                />
            </div>
        )
    }

    const getData = async () => {
        dispatch({isLoading: true})
        try {
            dispatch({isLoading: true})
            let filter: any = {
                courseId
            }
            let sort
            if (!isEmpty(orderField)) {
                sort = {
                    orderBy: orderField.field,
                    orderDir: orderField.order
                }
            }
            if (scheduleId) {
                filter.scheduleId = scheduleId
            }
            if (props.studentProfileIds) {
                filter.studentProfileIds = props.studentProfileIds
            } else if (!model.isStaffOrAdmin() && model.profileId) {
                filter.studentProfileIds = [model.profileId]
            }
            if (searchDebounce && searchDebounce.length) {
                filter.search = searchDebounce
            }
            if (termId) {
                filter.termIds = [termId]
            }
            let response: any = {}
            response = await academicCourseGradesService.getClassActivities({
                filter: filter,
                range: {
                    page,
                    pageSize
                },
                sort
            })

            let {data = [], total = 0} = response

            for (let i = 0; i < data.length; i++) {
                data[i].instructors = data[i].instructors?.length
                    ? data[i].instructors
                          .map(function (instructor) {
                              return instructor.name
                          })
                          .join(",")
                    : ""
                data[i].dateFormat = moment(data[i].date).format(model.getUserDateFormat())
                data[i].timeFormat = moment(data[i].date).format(model.getUserTimeFormat())
                data[i].type = renderType(data[i].activityType)
                data[i].isVerifiedHtml = renderCheckBox(data[i].id, true, "isVerified", "Verified", data[i].isVerified)
                if (data[i].isVerified) {
                    data[i].isDisableCheckBox = true
                }
            }
            dispatch({data: data, total: total})
        } catch (err) {
            handleError(err)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClickRowItem = useCallback(
        (courseGrade) => {
            if (props.studentProfileIds?.length) {
                history.push(
                    routing.registrar.activityDetail({
                        courseId,
                        termId,
                        academicCourseGradeId: courseGrade.academicCourseGradeId,
                        scheduleId,
                        studentProfileId: get(props.studentProfileIds, [0], model.profileId)
                    })
                )
            } else {
                history.push(
                    routing.academics.activities.manualGrade(
                        courseId,
                        termId,
                        scheduleId,
                        courseGrade.academicCourseGradeId
                    )
                )
            }
        },
        [scheduleId]
    )

    useEffect(() => {
        if (isLoadedTableFuncs && courseId && termId) {
            getData()
        }
    }, [isLoadedTableFuncs, courseId, termId, page, pageSize, searchDebounce, scheduleId, orderField])

    const getColumns = () => {
        return [
            {
                title: "ID",
                field: "id",
                orderField: "id",
                sortable: true
            },
            {
                title: "Date",
                field: "dateFormat",
                style: {minWidth: "100px"},
                orderField: "date",
                sortable: true
            },
            {
                title: "Time",
                field: "timeFormat",
                orderField: "date",
                sortable: true,
                style: {minWidth: "80px"}
            },
            {
                title: "Name",
                field: "activityName",
                orderField: "activityName",
                sortable: true,
                style: {minWidth: "150px"}
            },
            {
                title: "Student ID",
                field: "customProfileId"
            },
            {
                title: "Student Name",
                field: "fullName"
            },
            {
                title: "Instructor Name",
                field: "instructors"
            },
            {
                title: "Duration (Minutes)",
                field: "duration"
            },
            {
                title: "Grade",
                field: "grade",
                orderField: "grade",
                sortable: true
            },
            {
                title: "Alphabetical",
                field: "alphabetical",
                orderField: "alphabetical",
                sortable: true
            },
            {
                title: "Notes",
                field: "notes",
                orderField: "notes",
                sortable: true
            },
            {
                title: "Description",
                field: "description",
                style: {minWidth: "150px"},
                orderField: "description",
                sortable: true
            },
            {
                title: "Type",
                field: "type",
                orderField: "activityType",
                sortable: true
            },
            {
                title: "Verified",
                field: "isVerifiedHtml"
            }
        ]
    }

    const onUpdateRowData = (updatedData) => {
        const data = getCurrentData()
        const newData = cloneDeep(data).map((item) => {
            if (item.id === updatedData.id) {
                return {
                    ...item,
                    isChecked: updatedData.isChecked
                }
            }
            return item
        })
        dispatch({data: newData})
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
    }, [])

    const onCancelConfirm = () => {
        setIsShowConfirmVerify(false)
    }

    const onShowConfirm = () => {
        setIsShowConfirmVerify(true)
    }

    const headerActions = () => {
        const actions: any = [
            {
                title: (
                    <div className={styles.actionWrap}>
                        <BaseButton
                            title="Verify"
                            variant="secondary"
                            className={styles.verifybtn}
                            onClick={onShowConfirm}
                            loading={false}
                        />
                    </div>
                ) as any,
                icon: null,
                action: () => {}
            }
        ]
        return actions
    }

    return (
        <div>
            <Search value={search} onChange={setSearch} placeholder="Search" />
            <KlassappTableHeader
                actions={headerActions()}
                isShowAction={!!data.find((item) => item.isChecked)}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                menuActions={[]}
                isLoading={isLoading}
                allFields={allFields}
                isShowCheckedColumn={true}
                onUpdateRowData={onUpdateRowData}
                isShowCheckedColumnHeader={false}
                orderField={orderField}
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onClickSortColumn={props.onClickSortColumn}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
            <PopupVerifyClassActivities
                data={data}
                isShowConfirmVerify={isShowConfirmVerify}
                onCancelConfirm={onCancelConfirm}
                refreshData={getData}
                scheduleId={scheduleId}
            />
        </div>
    )
}

export default KlassappTableHOC(ClassActivities)
