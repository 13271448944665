import {BaseButton, BaseLoading, BasePopup, BaseTextArea, Icon, KlassDropdown, SecondaryButton} from "components"
import {useCallback, useEffect, useState} from "react"
import styles from "./AddActionPlanPopup.module.css"
import {FormLabel} from "components/Form"
import {useTranslation} from "react-i18next"
import {KlassDropAsyncPaginate} from "components/Select"
import {BaseDatePicker} from "components/DateTimePicker"
import debounce from "debounce-promise"
import {
    academicPlansService,
    courseScheduleService,
    instructionalService,
    profileService,
    studentService,
    termsService
} from "services"
import {formatCodeName, getFullName, handleError} from "helpers"
import {get} from "lodash"
import moment from "moment"
import {Checkbox, Col, Row} from "antd"
import {BaseDepartmentId} from "types/departments"
import {useVisible} from "hooks"
import {SignaturePopup} from "uiKit"
import {SignInSapAction} from "./SigninSapAction"
import {ApprovalStatus} from "types/isir"

type Props = {
    isShow: boolean
    onClose: () => void
    studentProfileId: number
    scheduleId?: number
    onRefreshData: () => Promise<void>
}

export function AddActionPlanPopup(props: Props) {
    const [studentSignature, setStudentSignature] = useState<{signatureId: number; imageUrl: string}>()
    const [staffSignature, setStaffSignature] = useState<{signatureId: number; imageUrl: string}>()
    const signInSapActionPopup = useVisible(false)
    const studentSignaturePopup = useVisible(false)
    const staffSignaturePopup = useVisible(false)
    const {isShow, onClose, onRefreshData, studentProfileId} = props
    const [isLoading, setIsLoading] = useState(false)
    const [schedules, setSchedules] = useState([])
    const {t} = useTranslation(["common"])
    const [errors, setErrors] = useState({
        date: "",
        student: "",
        term: "",
        course: "",
        advisor: "",
        schedule: "",
        actionPlan: ""
    })
    const [values, setValues] = useState({
        date: undefined,
        student: undefined,
        studentRefusedToSign: false,
        advisor: undefined,
        term: undefined,
        course: undefined,
        schedule: undefined,
        actionPlan: ""
    })

    const isValid = useCallback(
        (payload) => {
            const newErrors: any = {
                date: "",
                student: "",
                term: "",
                course: "",
                schedule: "",
                actionPlan: "",
                studentSignature: "",
                staffSignature: ""
            }
            if (!payload.date) {
                newErrors.date = "Please select date"
            }
            if (!payload.term) {
                newErrors.term = "Please select term"
            }
            if (!payload.course) {
                newErrors.course = "Please select course"
            }
            if (!payload.schedule) {
                newErrors.schedule = "Please select schedule"
            }
            if (!payload.actionPlan?.length) {
                newErrors.actionPlan = "Please enter action plan"
            }
            if (!payload.student) {
                newErrors.student = "Please select student"
            }
            if (!values.studentRefusedToSign && !studentSignature) {
                newErrors.studentSignature = "Please add student signature"
            }
            if (!staffSignature) {
                newErrors.staffSignature = "Please add staff signature"
            }
            setErrors(newErrors)
            return !Object.keys(newErrors).find((k) => newErrors[k].length > 0)
        },
        [staffSignature, studentSignature, values]
    )

    const clearValues = useCallback(() => {
        setStaffSignature(undefined)
        setStudentSignature(undefined)
        setValues({
            date: undefined,
            student: undefined,
            advisor: undefined,
            term: undefined,
            course: undefined,
            schedule: undefined,
            studentRefusedToSign: false,
            actionPlan: ""
        })
    }, [])

    const onSubmit = useCallback(async () => {
        if (!isValid(values)) {
            return
        }
        try {
            setIsLoading(true)
            await academicPlansService.addSapActions({
                sapActions: [
                    {
                        scheduleId: values.schedule.id,
                        termId: values.term.id,
                        studentRefusedToSign: !!values.studentRefusedToSign,
                        courseId: values.course.courseId,
                        studentProfileId: values.student.profileId,
                        actionPlan: values.actionPlan,
                        date: moment(values.date).format("YYYY-MM-DD"),
                        approvalSignatureId: staffSignature.signatureId,
                        studentSignatureId: values.studentRefusedToSign ? null : studentSignature?.signatureId,
                        approvalStatus: ApprovalStatus.Approved
                    }
                ]
            })
            clearValues()
            onRefreshData()
            onClose()
        } catch (err) {
            handleError(err)
        } finally {
            setIsLoading(false)
        }
    }, [clearValues, isValid, onClose, onRefreshData, staffSignature, studentSignature, values])

    const getProfileInfo = async () => {
        const {data: result, total} = await profileService.getAll({
            linkedObjects: true,
            filter: {
                id: [studentProfileId]
            }
        })
        const student = get(result, [0], {})
        setValues({
            ...values,
            student: {
                ...student,
                profileId: student.id
            }
        })
    }

    useEffect(() => {
        if (isShow && studentProfileId) {
            getProfileInfo()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow, studentProfileId])

    const getStudentList = async (search: string, loadedOptions) => {
        try {
            const limit = 20
            const {data, total} = await courseScheduleService.getAllStudents({
                range: {offset: loadedOptions.length, limit: limit},
                filter: {
                    scheduleIds: [],
                    search
                }
            })
            return {
                options: data,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const getCourseList = async (search: string, loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const {data, total} = await instructionalService.getMyCourse({
                range: {page, pageSize},
                filter: {
                    search,
                    // campusIds,
                    termIds: [values.term.id],
                    isPracticalOrClinicalServices: false
                }
            })
            return {
                options: data.map((item) => ({
                    ...item,
                    name: item.courseName,
                    code: item.courseCode
                })),
                hasMore: loadedOptions.length < total
            }
        } catch (e) {
            handleError(e)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const getAdvisorList = async (search: string, loadedOptions) => {
        try {
            const {data, total} = await studentService.getAdvisors({
                filter: {
                    search,
                    departmentId: BaseDepartmentId.Academics
                }
            })
            return {
                options: data.map((item) => ({
                    ...item
                })),
                hasMore: loadedOptions.length < total
            }
        } catch (e) {
            handleError(e)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const getTermList = async (search: string, loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const params = {
                filter: {
                    search
                },
                range: {
                    pageSize,
                    page
                },
                sort: {
                    orderBy: "name",
                    orderDir: "asc"
                }
            }
            const {data: terms, total} = await termsService.getAllTerms(params)
            return {
                options: terms,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const getSchedules = useCallback(async () => {
        try {
            const {data} = await courseScheduleService.courseScheduleGet({
                filter: {
                    term_id: values.term?.id,
                    course_id: values.course?.courseId
                }
            })
            setSchedules(data)
        } catch (e) {
            handleError(e)
        }
    }, [values.term, values.course])

    useEffect(() => {
        if (values.course) {
            getSchedules()
        }
    }, [getSchedules, values.course])

    const debounceStudentList = debounce(getStudentList, 300)
    const debounceCourseList = debounce(getCourseList, 300)
    const debounceTermList = debounce(getTermList, 300)
    const debounceAdvisorList = debounce(getAdvisorList, 300)

    return (
        <BasePopup
            isShow={isShow}
            onClose={() => {
                clearValues()
                onClose()
            }}
            leftIcon="FILE_LINE_ALT"
            width="50vw">
            <div className={styles.wrap}>
                <p className={styles.title}>Add Action Plan</p>
                <Col span={24}>
                    <Row gutter={40}>
                        <Col span={12}>
                            <FormLabel className={styles.fieldTitle} label="Student" />
                            <KlassDropAsyncPaginate
                                loadOptions={debounceStudentList}
                                value={values.student}
                                valueKey="profileId"
                                getOptionLabel={(option) => getFullName(option)}
                                onChange={(value) => setValues({...values, student: value})}
                                placeholder="Select"
                                isDisabled={studentProfileId}
                                isClearable
                            />
                        </Col>
                        <Col span={12}>
                            <FormLabel className={styles.fieldTitle} label="Date" />
                            <BaseDatePicker
                                value={values.date}
                                onChange={(option) => setValues({...values, date: option})}
                                placeholder="Date"
                            />
                        </Col>
                    </Row>
                </Col>

                <Col span={24}>
                    <Row gutter={40}>
                        <Col span={12}>
                            <FormLabel className={styles.fieldTitle} label="Term" />
                            <KlassDropAsyncPaginate
                                loadOptions={debounceTermList}
                                value={values.term}
                                valueKey="id"
                                getOptionLabel={(option) => `${option.code} - ${option.name}`}
                                onChange={(value) => setValues({...values, term: value})}
                                placeholder="Term"
                            />
                        </Col>
                        <Col span={12}>
                            <FormLabel className={styles.fieldTitle} label="Course" />
                            <KlassDropAsyncPaginate
                                key={values.term?.id}
                                isDisabled={!values.term}
                                isClearable
                                getOptionLabel={formatCodeName}
                                value={values.course}
                                valueKey="courseId"
                                onChange={(value) => {
                                    setValues({...values, schedule: undefined, course: value})
                                }}
                                labelKey="courseName"
                                loadOptions={values.term ? debounceCourseList : undefined}
                                placeholder="Select"
                            />
                        </Col>
                    </Row>
                </Col>

                <Col span={24}>
                    <Row gutter={40}>
                        <Col span={12}>
                            <FormLabel className={styles.fieldTitle} label="Schedule" />
                            <KlassDropdown
                                key={`${values.term?.id} ${values.course?.courseId}`}
                                options={schedules}
                                value={values.schedule}
                                labelKey="schedule_suffix"
                                stylesCustom={{
                                    container: (provided, state) => {
                                        return {
                                            ...provided,
                                            width: "100%",
                                            marginTop: 10
                                        }
                                    }
                                }}
                                isDisabled={!values.course}
                                onChange={(value) => setValues({...values, schedule: value})}
                                isClearable
                            />
                        </Col>
                        <Col span={12}>
                            <FormLabel className={styles.fieldTitle} label="Advisor" />
                            <KlassDropAsyncPaginate
                                loadOptions={debounceAdvisorList}
                                value={values.advisor}
                                valueKey="profileId"
                                getOptionLabel={(option) => getFullName(option)}
                                onChange={(value) => setValues({...values, advisor: value})}
                                placeholder="Select"
                                isClearable
                            />
                        </Col>
                    </Row>
                </Col>

                <FormLabel className={styles.fieldTitle} label="Action Plan" />
                <BaseTextArea
                    style={{
                        resize: "vertical"
                    }}
                    onChange={(value) => setValues({...values, actionPlan: value})}
                    placeholder={"Action Plan"}
                    value={values?.actionPlan}
                />

                <hr className={styles.line} />

                <Row gutter={40}>
                    <Col span={12}>
                        <p className={styles.signature}>STAFF SIGNATURE</p>

                        {staffSignature && !staffSignaturePopup.isVisible ? (
                            <div onClick={staffSignaturePopup.open} className={styles.signatureButton}>
                                <img
                                    src={staffSignature.imageUrl}
                                    alt="studentSignature"
                                    className={styles.signatureImage}
                                />
                                <Icon icon="EDIT_SIGNATURE" className={styles.editButton} color="white" />
                            </div>
                        ) : (
                            <div onClick={staffSignaturePopup.open} className={styles.signatureButton}>
                                <p className={styles.addSignature}>Add signature</p>
                                <Icon icon="EDIT_SIGNATURE" className={styles.editButton} color="white" />
                            </div>
                        )}
                    </Col>
                    <Col span={12}>
                        <p className={styles.signature}>STUDENT SIGNATURE</p>
                        <Checkbox
                            style={{marginTop: 8}}
                            checked={values.studentRefusedToSign}
                            onChange={(event) => setValues({...values, studentRefusedToSign: event.target.checked})}>
                            Student refuse to sign
                        </Checkbox>
                        {!values.studentRefusedToSign && studentSignature ? (
                            <div onClick={signInSapActionPopup.open} className={styles.signatureButton}>
                                <img
                                    src={studentSignature.imageUrl}
                                    alt="studentSignature"
                                    className={styles.signatureImage}
                                />
                                <Icon icon="EDIT_SIGNATURE" className={styles.editButton} color="white" />
                            </div>
                        ) : !values.studentRefusedToSign ? (
                            <div onClick={signInSapActionPopup.open} className={styles.signatureButton}>
                                <p className={styles.addSignature}>Add signature</p>
                                <Icon icon="EDIT_SIGNATURE" className={styles.editButton} color="white" />
                            </div>
                        ) : null}
                    </Col>
                </Row>

                {Object.keys(errors).find((k) => errors[k].length > 0) && (
                    <ul className={styles.errorOptionsList}>
                        {Object.keys(errors)
                            .filter((key) => errors[key].length)
                            .map((key, index) => (
                                <li className={styles.textError} key={index}>
                                    {errors[key]}
                                </li>
                            ))}
                    </ul>
                )}
                <hr className={styles.line} style={{marginTop: 60}} />
                <div className={styles.submitContainer}>
                    <SecondaryButton
                        title={t("common:action.cancel")}
                        onClick={() => {
                            clearValues()
                            onClose()
                        }}
                        style={{marginRight: 15}}
                    />
                    <BaseButton
                        title={isLoading ? t("common:action.saving") : t("common:action.save")}
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>
                <BaseLoading isShow={isLoading} />
            </div>

            <SignInSapAction
                isShow={signInSapActionPopup.isVisible}
                onClose={signInSapActionPopup.close}
                onSignInSuccess={studentSignaturePopup.open}
                studentProfileId={studentProfileId}
                onRefreshData={onRefreshData}
            />

            {values.student?.userId && studentSignaturePopup.isVisible ? (
                <SignaturePopup
                    isShow={studentSignaturePopup.isVisible}
                    onClose={studentSignaturePopup.close}
                    targetUser={{
                        ...values.student,
                        id: values.student.userId,
                        profileId: values.student.profileId
                    }}
                    onSetNewSignature={setStudentSignature}
                    actions={{
                        onCancel: studentSignaturePopup.close,
                        onSign: (signature) => {
                            setStudentSignature(signature)
                            studentSignaturePopup.close()
                        }
                    }}
                    title="Go ahead and sign"
                />
            ) : null}

            {staffSignaturePopup.isVisible && (
                <SignaturePopup
                    isShow={staffSignaturePopup.isVisible}
                    onClose={staffSignaturePopup.close}
                    onSetNewSignature={setStaffSignature}
                    actions={{
                        onCancel: staffSignaturePopup.close,
                        onSign: (signature) => {
                            setStaffSignature(signature)
                            staffSignaturePopup.close()
                        }
                    }}
                    title="Go ahead and sign"
                />
            )}
        </BasePopup>
    )
}
