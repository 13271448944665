import {get} from "lodash"
import BaseService from "./Base"

class TagService extends BaseService {
    async getAllTags(params) {
        const response = await this.post("/v1/settings/tags/list", params)
        return get(response, "data", [])
    }

    async getTagDetail(id, params = {}) {
        const response = await this.post("/v1/settings/tags/list", {
            ...params,
            linkedEntities: true,
            filter: {tagIds: [id]}
        })
        return get(response, "data.data[0]", {})
    }

    async createTag(data = {}) {
        const response = await this.post("/v1/settings/tags/create", data)
        return get(response, "data.data", {})
    }

    async updateTag(id, data, params = {}) {
        data.tagId = id
        const response = await this.post(`/v1/settings/tags/edit`, data, params)
        return get(response, "data", {})
    }

    async deleteTag(tagIds) {
        const response = await this.post(`/v1/settings/tags/delete`, {tagIds})
        return get(response, "data", {})
    }
}

export default TagService
