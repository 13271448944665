/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {AddItemCircleButton, BaseInput} from "components"
import ButtonCheckbox from "components/buttons/buttonCheckbox"
import {KlassappTableHOC} from "HOC"
import React, {useCallback, useEffect, useState} from "react"
import {withTranslation} from "react-i18next"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import styles from "./GradeForm.module.css"
import {v4 as uuidV4} from "uuid"
import GradingElements from "./GradingElements"
import {cloneDeep} from "lodash"
import {academicGradingSystem} from "services"
import {formatDecimalWithoutRound} from "helpers"

const alphabeticalOptions = [
    {label: "A", value: "A"},
    {label: "B", value: "B"},
    {label: "C", value: "C"},
    {label: "D", value: "D"},
    {label: "E", value: "E"},
    {label: "F", value: "F"}
]

let dataGradingScale = []

const GradeForm = (props) => {
    const {t} = props
    const {
        onClickCancelBtn,
        getDetailGradingSystem,
        setInitialDataGradingScale,
        setInitialDataGradingElements,
        initialDataGradingScale,
        initialDataGradingElements,
        newDataGradingScale,
        newDataGradingElements,
        setNewDataGradingElements,
        setNewDataGradingScale,
        responseData,
        getCurrentData,
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        onChangeMinimumPassingGrade,
        minimumPassingGrade,
        columns,
        data,
        allFields,
        menuActions,
        fields,
        tableHeaderActions,
        orderField,
        isLoading,
        isShowTableHeaderAction,
        onChangeWeightingIsRequired,
        isWeightingRequired
    } = props

    const [isCheckAlphabeticalScale, setIsCheckAlphabeticalScale] = useState(false)
    const [isCheckFourScale, setIsCheckFourScale] = useState(false)

    const [currentColumn, setCurrentColumn] = useState([
        {
            title: t("academics:settings.numeric"),
            field: "low",
            sortable: true,
            style: {minWidth: "200px"}
        },
        {
            title: "",
            field: "high",
            sortable: true,
            style: {minWidth: "200px"}
        }
    ])
    const [currentField, setCurrentField] = useState([t("academics:settings.numeric"), ""])
    const [currentSubHeaders, setCurrentSubHeaders] = useState([{title: "LOW"}, {title: "HIGH"}])

    const getFields = () => {
        return currentField
    }

    const getPageTitle = () => {
        return "Grade"
    }

    const getColumns = () => {
        return currentColumn
    }

    const getData = async () => {
        dispatch({isLoading: true})
        try {
            dispatch({data: dataGradingScale, total: dataGradingScale.length})
        } catch (e) {
            console.log("e")
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClickEdit = (editItem) => {
        const newData = data.map((item) => {
            if (editItem.id !== item.id) return item
            return {
                ...item,
                low: renderInput("low", "LOW", item.id, item.lowValue, true),
                high: renderInput("high", "HIGH", item.id, item.highValue, true),
                fourPointScale: renderInput(
                    "fourPointScale",
                    "4.0",
                    item.id,
                    formatDecimalWithoutRound(item.fourPointScaleValue),
                    true
                ),
                alphabetical: renderInput("alphabetical", "ALPHABETICAL", item.id, item.alphabeticalValue, true, "text")
            }
        })
        dataGradingScale = [...newData]
        setNewDataGradingScale([...newData])
    }

    const onClickDelete = useCallback(
        async (deleteItem) => {
            const newData = dataGradingScale.filter((item) => deleteItem.id === item.id && typeof item.id === "number")
            try {
                const ids = newData.map((item) => item.id)
                if (ids.length) {
                    await academicGradingSystem.deleteGradingScales(ids)
                    getDetailGradingSystem()
                }
                onClickCancelBtn()
            } catch (err) {
                onClickCancelBtn()
            }
        },
        [dataGradingScale, dispatch]
    )

    const getMenuActions = useCallback(() => {
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: onClickEdit
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: onClickDelete
            }
        ]
    }, [onClickDelete, t, onClickEdit])

    const onClickDeleteMulti = async () => {
        const newData = getCurrentData().filter((item) => item.isChecked && typeof item.id === "number")
        try {
            const ids = newData.map((item) => item.id)
            if (ids.length) {
                await academicGradingSystem.deleteGradingScales(ids)
                getDetailGradingSystem()
            }
            onClickCancelBtn()
        } catch (err) {
            onClickCancelBtn()
        }
        dispatch({isShowTableHeaderAction: false})
    }

    const onClickDuplicateMulti = useCallback(async () => {
        const duplicateData = getCurrentData()
            .filter((item) => item.isChecked)
            .map((item) => ({
                ...item,
                isChecked: false,
                id: uuidV4()
            }))
        const oldData = dataGradingScale.map((item) => ({...item, isChecked: false}))
        const newData = [...oldData, ...duplicateData]
        dataGradingScale = newData
        setNewDataGradingScale(newData)
        dispatch({data: newData, total: newData.length, isShowTableHeaderAction: false})
    }, [])

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickDuplicateMulti", func: onClickDuplicateMulti},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDelete},
            {key: "onClickRowItem", func: onClickRowItem},
            {key: "getMenuActions", func: getMenuActions}
        ])
    }, [currentColumn, currentField, newDataGradingScale, initialDataGradingScale, data])

    const onClickRowItem = (row) => {}

    const turnOnAlphabeticalAndFourScale = () => {
        setIsCheckAlphabeticalScale(true)
        setIsCheckFourScale(true)
        const newCurrentColumn = currentColumn
            .concat({
                title: t("academics:settings.alphabetical"),
                field: "alphabetical",
                sortable: true,
                style: {minWidth: "200px"}
            })
            .concat({
                title: t("academics:settings.fourPoint"),
                field: "fourPointScale",
                sortable: true,
                style: {minWidth: "200px"}
            })
        const newCurrentSubHeaders = currentSubHeaders.concat({title: ""}).concat({title: ""})
        const newCurrentField = currentField
            .concat(t("academics:settings.alphabetical"))
            .concat(t("academics:settings.fourPoint"))
        setCurrentColumn(newCurrentColumn)
        setCurrentSubHeaders(newCurrentSubHeaders)
        setCurrentField(newCurrentField)
    }

    const toggleAlphabeticalScale = useCallback(() => {
        setIsCheckAlphabeticalScale(!isCheckAlphabeticalScale)
        if (!isCheckAlphabeticalScale === true) {
            setCurrentColumn(
                currentColumn.concat({
                    title: t("academics:settings.alphabetical"),
                    field: "alphabetical",
                    sortable: true,
                    style: {minWidth: "200px"}
                })
            )
            dataGradingScale = dataGradingScale.map((item) => ({
                ...item,
                alphabetical: null,
                alphabeticalValue: null
            }))
            setNewDataGradingScale(dataGradingScale)
            setCurrentSubHeaders(currentSubHeaders.concat({title: ""}))
            setCurrentField(currentField.concat(t("academics:settings.alphabetical")))
        } else {
            setCurrentSubHeaders(currentSubHeaders.slice(0, -1))
            setCurrentColumn(currentColumn.filter((item) => item.title !== t("academics:settings.alphabetical")))
            setCurrentField(currentField.filter((item) => item !== t("academics:settings.alphabetical")))
        }
    }, [isCheckAlphabeticalScale, currentColumn, currentField, currentSubHeaders])

    const toggleFourScale = useCallback(() => {
        setIsCheckFourScale(!isCheckFourScale)
        if (!isCheckFourScale === true) {
            setCurrentColumn(
                currentColumn.concat({
                    title: t("academics:settings.fourPoint"),
                    field: "fourPointScale",
                    sortable: true,
                    style: {minWidth: "200px"}
                })
            )
            dataGradingScale = dataGradingScale.map((item) => ({
                ...item,
                fourPointScale: null,
                fourPointScaleValue: null
            }))
            setNewDataGradingScale(dataGradingScale)
            setCurrentSubHeaders(currentSubHeaders.concat({title: ""}))
            setCurrentField(currentField.concat(t("academics:settings.fourPoint")))
        } else {
            setCurrentSubHeaders(currentSubHeaders.slice(0, -1))
            setCurrentColumn(currentColumn.filter((item) => item.title !== t("academics:settings.fourPoint")))
            setCurrentField(currentField.filter((item) => item !== t("academics:settings.fourPoint")))
        }
    }, [isCheckFourScale, currentColumn, currentField, currentSubHeaders])

    const renderInput = (
        field: string,
        nameField: string,
        id: string,
        value: string,
        isEditing: boolean,
        type: any = "number"
    ) => {
        if (!isEditing) {
            return (
                <div className={styles.titleWrap}>
                    <span className={styles.title}>{value}</span>
                </div>
            )
        }
        return (
            <BaseInput
                type={type}
                placeholder={nameField}
                value={value}
                onChange={(text) => onChangeInputGradingScale(id, field, text)}
            />
        )
    }

    const onChangeInputGradingScale = (id, field, value) => {
        const changeDataForm = dataGradingScale.map((item) => {
            if (item.id !== id) {
                return item
            }
            let newValue: any = {
                ...item
            }
            if (field === "alphabetical") {
                newValue = {
                    ...newValue,
                    alphabetical: renderInput("alphabetical", "ALPHABETICAL", item.id, value, true, "text"),
                    alphabeticalValue: value
                }
            } else {
                newValue = {
                    ...newValue,
                    [field]: renderInput(field, field, item.id, value, true),
                    [`${field}Value`]: value
                }
            }
            return newValue
        })
        dataGradingScale = changeDataForm
        setNewDataGradingScale(changeDataForm)
    }

    const onAdd = useCallback(() => {
        const id = uuidV4()
        const newData = [
            ...dataGradingScale,
            {
                id: id,
                low: renderInput("low", "LOW", id, "", true),
                lowValue: "",
                highValue: "",
                fourPointScaleValue: "",
                alphabeticalValue: "",
                high: renderInput("high", "HIGH", id, "", true),
                fourPointScale: renderInput("fourPointScale", "4.0", id, "", true),
                alphabetical: renderInput("alphabetical", "ALPHABETICAL", id, "", true, "text")
            }
        ]
        dataGradingScale = [...newData]
        setNewDataGradingScale([...newData])
    }, [dataGradingScale, renderInput])

    useEffect(() => {
        dispatch({data: initialDataGradingScale, total: initialDataGradingScale.length})
        dataGradingScale = initialDataGradingScale
    }, [initialDataGradingScale])

    useEffect(() => {
        dispatch({data: [...newDataGradingScale], total: newDataGradingScale.length})
        dataGradingScale = [...newDataGradingScale]
    }, [newDataGradingScale])

    useEffect(() => {
        if (responseData && responseData.gradingScale && responseData.gradingScale.length) {
            const gradingScale = responseData.gradingScale
            let isIncludeFourPoint = false
            let isIncludeAlphabetical = false
            const newData = gradingScale.map((item) => {
                if (item.fourPointScale && item.fourPointScale !== "") {
                    isIncludeFourPoint = true
                }
                if (item.alphabetical && item.alphabetical !== "") {
                    isIncludeAlphabetical = true
                }
                return {
                    id: item.id,
                    low: renderInput("low", "LOW", item.id, item.numericLow, false),
                    lowValue: item.numericLow,
                    highValue: item.numericHigh,
                    fourPointScaleValue: item.fourPointScale,
                    alphabeticalValue: item.alphabetical ?? "",
                    high: renderInput("high", "HIGH", item.id, item.numericHigh, false),
                    fourPointScale: renderInput(
                        "fourPointScale",
                        "4.0",
                        item.id,
                        formatDecimalWithoutRound(item.fourPointScale),
                        false
                    ),
                    alphabetical: renderInput("alphabetical", "ALPHABETICAL", item.id, item.alphabetical, false)
                }
            })
            dataGradingScale = [...newData]
            setNewDataGradingScale([...newData])
            setInitialDataGradingScale(cloneDeep(newData))
            if (isIncludeAlphabetical && isIncludeFourPoint) {
                turnOnAlphabeticalAndFourScale()
            } else if (isIncludeAlphabetical) {
                toggleAlphabeticalScale()
            } else if (isIncludeFourPoint) {
                toggleFourScale()
            }
        }
    }, [responseData])

    return (
        <>
            <span className={styles.titleGradingScale}>{t("academics:settings.gradingScale")}:</span>
            <div className={styles.rowCheckBox}>
                <div className={styles.checkBoxGroup}>
                    <ButtonCheckbox
                        id={"alphabeticalScale"}
                        name="Alphabetical Scale"
                        onClick={toggleAlphabeticalScale}
                        allChecked={isCheckAlphabeticalScale}
                    />
                    <span className={styles.textCheckBox}>Alphabetical Scale</span>
                </div>
                <div className={styles.checkBoxGroupGradeFour}>
                    <ButtonCheckbox
                        id={"4.0Scale"}
                        name="4.0 Scale"
                        onClick={toggleFourScale}
                        allChecked={isCheckFourScale}
                    />
                    <span className={styles.textCheckBox}>4.0 Scale</span>
                </div>
                <div className={styles.minimumPassingGrade}>
                    <span className={styles.textMinimumPassingGrade}>Minimum passing grade</span>
                    <BaseInput
                        type={"number"}
                        placeholder={"Type"}
                        value={minimumPassingGrade}
                        onChange={onChangeMinimumPassingGrade}
                    />
                </div>
            </div>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                menuActions={menuActions}
                data={data}
                isShowSubHeader={true}
                subHeaderColumns={currentSubHeaders}
                isLoading={isLoading}
                allFields={allFields}
                isShowCheckedColumn={true}
                orderField={orderField}
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
            <AddItemCircleButton className={styles.addItemButton} onClick={onAdd} />
            <div className={styles.divider}></div>
            <>
                <span className={styles.titleGradingScale}>{t("academics:settings.gradingElements")}:</span>
                <GradingElements
                    onChangeWeightingIsRequired={onChangeWeightingIsRequired}
                    isWeightingRequired={isWeightingRequired}
                    onClickCancelBtn={onClickCancelBtn}
                    getDetailGradingSystem={getDetailGradingSystem}
                    responseData={responseData}
                    setInitialDataGradingElements={setInitialDataGradingElements}
                    initialDataGradingElements={initialDataGradingElements}
                    newDataGradingElements={newDataGradingElements}
                    setNewDataGradingElements={setNewDataGradingElements}
                />
            </>
            {/* )} */}
        </>
    )
}

export default KlassappTableHOC(withTranslation(["academics", "common", "course"])(GradeForm))
