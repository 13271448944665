import {get} from "lodash"
import BaseService from "./Base"

const LIST = {
    data: {
        success: true,
        total: 22,
        data: [
            {
                legacyTestId: 22,
                name: "lagacy test 2",
                studentProfileId: 1640,
                advisorId: 7,
                programId: 13,
                termId: 154,
                campusId: 55,
                testDate: "2022-05-30T19:25:29.000Z",
                adjustedScore: null,
                reason: null,
                userDocumentId: 355,
                lastUpdatedByProfileId: null,
                enrollDate: null,
                createdAt: "2022-05-30T19:25:23.000Z",
                updatedAt: "2022-05-30T19:25:23.000Z",
                deletedAt: null,
                term: {
                    id: 154,
                    name: "TEST 2022-04-08",
                    code: "TERMTEST",
                    start_date: "2022-09-01T04:00:00.000Z"
                },
                campus: {
                    id: 55,
                    name: "NAA-New England",
                    code: "NE"
                },
                program: {
                    id: 13,
                    name: "Aviation Maintenance Technology",
                    code: "AMT"
                },
                document: {
                    userDocumentId: 355,
                    documentUrl:
                        "https://naa-edular-core.s3.amazonaws.com/qkyyjtzd1653924321446.pdf?AWSAccessKeyId=AKIA3UWL2BNF6ESANPOW&Expires=1664219501&Signature=gJiVqF0jhMtSq7Dm8R7%2BTY4TMqI%3D"
                },
                advisor: {
                    userId: 7,
                    firstName: "NE test",
                    lastName: "staff",
                    fullName: "NE test staff",
                    type: "staff",
                    title: null,
                    email: "teststaffne@yopmail.com",
                    profileId: 7,
                    photo: null,
                    programName: null,
                    currentMajor: null
                },
                student: {
                    userId: 3163,
                    firstName: "Mauricio",
                    lastName: "Testerman",
                    middleName: "",
                    fullName: "Mauricio Testerman",
                    type: "student",
                    title: "",
                    email: "testklassapp3@yopmail.com",
                    profileId: 1640,
                    photo: null,
                    programName: "AVT - Avionics",
                    currentMajor: 20
                },
                lastUpdatedByUser: null,
                primaryStatus: "dreamer",
                secondaryStatus: "processor"
            }
        ]
    }
}

const STATS = {
    data: {
        success: true,
        data: {
            all: 1,
            driver: 0,
            dreamer: 1,
            inspirer: 0,
            processor: 0,
            adventurer: 0
        },
        total: 1
    }
}

export default class LegacyTestService extends BaseService {
    async getAll(params = {}) {
        const response = await this.post("/v3/academics/legacy-tests/list", params)
        return get(response as any, "data", [])
    }

    async getStats(params = {}) {
        const response = await this.post("/v3/academics/legacy-tests/stats/list", params)
        return get(response as any, "data.data", [])
    }

    async edit(params = {}) {
        const response = await this.post("/v1/admissions/legacy-tests/edit", params)
        return get(response, "data", [])
    }

    async delete(params = {}) {
        const response = await this.post(`/v3/academics/legacy-tests/delete`, params)
        return get(response, "data", {})
    }
}
