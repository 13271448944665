import BaseService from "./Base"
import {ApiListResponse, ApiResponse} from "types/api"
import {AxiosResponse} from "axios"
import {DocumentType} from "types/settings/documentType"

class DocumentTypesService extends BaseService {
    getAll = async (body = {}) => {
        const {data: response}: AxiosResponse<ApiListResponse<Array<DocumentType>>> = await this.post(
            "/v1/settings/document-types/list",
            body
        )
        if (!response.success) {
            throw response
        }

        return {
            data: response.data,
            total: response.total
        }
    }

    create = async (payload: any) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post(
            "/v1/settings/document-types/create",
            payload
        )
        if (!response.success) {
            throw response
        }
    }

    update = async (payload: any) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post(
            "/v1/settings/document-types/edit",
            payload
        )

        if (!response.success) {
            throw response
        }
    }

    deleteBulk = async (body = {}) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/settings/document-types/delete", body)

        if (!response.success) {
            throw response
        }
    }
}

export default DocumentTypesService
