import React from "react"

import {BaseDepartmentId} from "types/departments"
import {Permissions} from "types/permission"
import {RouteWithPermissionsRequired} from "./RouteWithPermissionsRequired"
import {Modules, useEdularModulesContext} from "@edular/modules"

// FinancialAid
const FinancialAid = React.lazy(() => import("sections/FinancialAid"))
const ScholarshipDetail = React.lazy(() => import("sections/FinancialAid/parts/Scholarships/ScholarshipDetail"))
const DisbursementSettingDetail = React.lazy(
    () => import("sections/FinancialAid/parts/DisbursementSettings/DisbursementSettingDetail")
)
const LoansEnrollmentLevelDetail = React.lazy(
    () => import("sections/FinancialAid/parts/LoansEnrollmentLevel/LoansEnrollmentLevelDetail")
)
const FinancialAidStaff = React.lazy(() => import("sections/FinancialAid/FinancialAidStaff"))
const EnrollmentRoster = React.lazy(() => import("sections/EnrollmentRoster"))
const EnrollmentRosterDetails = React.lazy(() => import("sections/EnrollmentRosterDetails"))
const FinAidNoticeDetail = React.lazy(() => import("sections/FinancialAid/staffParts/Notes/FinAidNoticeDetail"))
const FinAidWorksheetDetail = React.lazy(
    () => import("sections/FinancialAid/FinancialAidStudent/parts/FinAidWorksheet/FinAidWorksheetDetail")
)
const FinAidOfferDetail = React.lazy(
    () => import("sections/FinancialAid/FinancialAidStudent/parts/FinAidOffer/FinAidOfferDetail")
)
const FinAidOfferDocumentDetail = React.lazy(
    () => import("sections/FinancialAid/FinancialAidStudent/parts/FinAidOffer/FinAidOfferDocumentDetail")
)
const CollegeFinancingPlanDetail = React.lazy(
    () => import("sections/FinancialAid/FinancialAidStudent/parts/CollegeFinancingPlan/CollegeFinancingPlanDetail")
)
const FinAidAwardSummaryDetail = React.lazy(
    () => import("sections/FinancialAid/FinancialAidStudent/parts/FinAidAward/AwardSummary/AwardSummaryDetail")
)
const DisbursementDetail = React.lazy(
    () => import("sections/FinancialAid/FinancialAidStudent/parts/FinAidAward/Disbursement/DisbursementDetail")
)
const RefundDetail = React.lazy(() => import("sections/FinancialAid/staffParts/RefundDetail/RefundDetail"))
const FinAidNoticeStudentDetail = React.lazy(
    () => import("sections/FinancialAid/FinancialAidStudent/parts/FinancialNotices/FinAidNoticeStudentDetail")
)

const CheckListDetail = React.lazy(() => import("sections/Settings/CheckList/CheckListDetail"))

export function FinAidRoutes(props) {
    const {model} = props
    const {isLoading} = useEdularModulesContext()

    if (isLoading) {
        return null
    }

    return (
        <>
            <RouteWithPermissionsRequired
                exact
                path="/settings/financial-aid"
                model={model}
                component={FinancialAid}
                module={Modules.FinancialAid}
                permissions={{
                    staff: [Permissions.Staff.Settings.Modules.FinancialAid.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/financial-aid/checklist/detail"
                model={model}
                departmentId={BaseDepartmentId.FinancialAid}
                component={CheckListDetail}
                module={Modules.FinancialAid}
                permissions={{
                    staff: [Permissions.Staff.Settings.Modules.FinancialAid.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/financial-aid/scholarship/detail"
                model={model}
                component={ScholarshipDetail}
                module={Modules.FinancialAid}
                permissions={{
                    staff: [Permissions.Staff.Settings.Modules.FinancialAid.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/financial-aid/disbursement/detail"
                model={model}
                component={DisbursementSettingDetail}
                module={Modules.FinancialAid}
                permissions={{
                    staff: [Permissions.Staff.Settings.Modules.FinancialAid.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/financial-aid/loan-enrollment-levels/detail"
                model={model}
                component={LoansEnrollmentLevelDetail}
                module={Modules.FinancialAid}
                permissions={{
                    staff: [Permissions.Staff.Settings.Modules.FinancialAid.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/financial-aid/staff"
                model={model}
                component={FinancialAidStaff}
                module={Modules.FinancialAid}
                permissions={{
                    staff: [Permissions.Staff.FinancialAid.FinancialAidStaff.View],
                    student: [Permissions.Student.FinancialAid.View],
                    others: [Permissions.Others.FinancialAid.View]
                }}
            />

            {/* Enrollment Roster */}
            <RouteWithPermissionsRequired
                exact
                path="/financial-aid/nslds"
                model={model}
                component={EnrollmentRoster}
                module={Modules.FinancialAid}
                permissions={{
                    staff: [Permissions.Staff.FinancialAid.FinancialAidStaff.View],
                    student: [Permissions.Student.FinancialAid.View],
                    others: [Permissions.Others.FinancialAid.View]
                }}
            />

            {/* Enrollment Roster Details */}
            <RouteWithPermissionsRequired
                exact
                path="/financial-aid/nslds/:fileId"
                model={model}
                component={EnrollmentRosterDetails}
                module={Modules.FinancialAid}
                permissions={{
                    staff: [Permissions.Staff.FinancialAid.FinancialAidStaff.View],
                    student: [Permissions.Student.FinancialAid.View],
                    others: [Permissions.Others.FinancialAid.View]
                }}
            />

            <RouteWithPermissionsRequired
                exact
                model={model}
                path="/financial-aid/staff/notice/detail"
                component={FinAidNoticeDetail}
                module={Modules.FinancialAid}
                permissions={{
                    staff: [
                        Permissions.Staff.FinancialAid.FinancialAidStaff.View,
                        Permissions.Staff.FinancialAid.Notices.View
                    ],
                    student: [Permissions.Student.FinancialAid.View],
                    others: [Permissions.Others.FinancialAid.View]
                }}
            />

            <RouteWithPermissionsRequired
                exact
                model={model}
                path="/financial-aid/student/:studentId/worksheets/:id"
                component={FinAidWorksheetDetail}
                module={Modules.FinancialAid}
                permissions={{
                    staff: [Permissions.Staff.FinancialAid.FinancialAidStaff.View]
                }}
            />

            <RouteWithPermissionsRequired
                exact
                model={model}
                path="/financial-aid/student/:studentId/offers/:id"
                component={FinAidOfferDetail}
                module={Modules.FinancialAid}
                permissions={{
                    staff: [Permissions.Staff.FinancialAid.FinancialAidStaff.View],
                    student: [Permissions.Student.FinancialAid.View],
                    others: [Permissions.Others.FinancialAid.View]
                }}
            />

            <RouteWithPermissionsRequired
                exact
                model={model}
                path="/financial-aid/student/:studentId/offer-docs/:id"
                component={FinAidOfferDocumentDetail}
                module={Modules.FinancialAid}
                permissions={{
                    staff: [Permissions.Staff.FinancialAid.FinancialAidStaff.View],
                    student: [Permissions.Student.FinancialAid.View],
                    others: [Permissions.Others.FinancialAid.View]
                }}
            />

            <RouteWithPermissionsRequired
                exact
                model={model}
                path="/financial-aid/student/:studentId/cfps/:id"
                component={CollegeFinancingPlanDetail}
                module={Modules.FinancialAid}
                permissions={{
                    staff: [Permissions.Staff.FinancialAid.FinancialAidStaff.View],
                    student: [Permissions.Student.FinancialAid.View],
                    others: [Permissions.Others.FinancialAid.View]
                }}
            />

            <RouteWithPermissionsRequired
                exact
                model={model}
                path="/financial-aid/student/:studentId/award-summaries/:awardSummaryId"
                component={FinAidAwardSummaryDetail}
                permissions={{
                    staff: [Permissions.Staff.FinancialAid.FinancialAidStaff.View],
                    student: [Permissions.Student.FinancialAid.View],
                    others: [Permissions.Others.FinancialAid.View]
                }}
            />

            <RouteWithPermissionsRequired
                exact
                model={model}
                path="/financial-aid/student/:studentId/disbursements/:disbursementId"
                component={DisbursementDetail}
                permissions={{
                    staff: [Permissions.Staff.FinancialAid.FinancialAidStaff.View],
                    student: [Permissions.Student.FinancialAid.View],
                    others: [Permissions.Others.FinancialAid.View]
                }}
            />

            <RouteWithPermissionsRequired
                exact
                model={model}
                path="/financial-aid/student/:studentId/refunds/:refundId"
                component={RefundDetail}
                permissions={{
                    staff: [Permissions.Staff.FinancialAid.FinancialAidStaff.View],
                    student: [Permissions.Student.FinancialAid.View],
                    others: [Permissions.Others.FinancialAid.View]
                }}
            />

            <RouteWithPermissionsRequired
                exact
                model={model}
                path="/financial-aid/student/notice/detail"
                component={FinAidNoticeStudentDetail}
                module={Modules.FinancialAid}
                permissions={{
                    staff: [
                        Permissions.Staff.FinancialAid.FinancialAidStaff.View,
                        Permissions.Staff.FinancialAid.Notices.View
                    ],
                    student: [Permissions.Student.FinancialAid.View],
                    others: [Permissions.Others.FinancialAid.View]
                }}
            />
        </>
    )
}
