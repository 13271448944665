import {Spin} from "antd"
import React, {useEffect, useState} from "react"
import {calendarService} from "services"
import {CreateEvent, ListEvents} from "./parts"
import styles from "./StudentInfoCalendarPanel.module.css"

type DisplayPart = "list" | "create"

type Props = {
    displayPart?: DisplayPart
    onClose?: () => void
}

export const StudentInfoCalendarPanel = (props: Props) => {
    const {onClose, displayPart = "list"} = props
    const [displayMode, setDisplayMode] = useState<DisplayPart>(displayPart)

    const [calendarId, setCalendarId] = useState(null)

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        const {data} = await calendarService.getMyPrimaryCalendar({}, {})
        const {calendar_id} = data
        setCalendarId(calendar_id)
    }

    const onNavigate = (_displayMode: DisplayPart) => {
        setDisplayMode(_displayMode)
    }

    if (!calendarId) {
        return (
            <div className={styles.loading}>
                <Spin />
            </div>
        )
    }

    return (
        <>
            {displayMode === "list" ? (
                <ListEvents onNavigate={onNavigate} onClose={onClose} />
            ) : (
                <CreateEvent calendarId={calendarId} onNavigate={onNavigate} />
            )}
        </>
    )
}
