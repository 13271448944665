import {BaseButton, BaseInput, BaseLoading, BasePopup, BaseTextArea, Icon, SecondaryButton} from "components"
import {useCallback, useEffect, useState} from "react"
import styles from "./AddMeetingPopup.module.css"
import {FormLabel} from "components/Form"
import {useTranslation} from "react-i18next"
import {BaseDatePicker} from "components/DateTimePicker"
import {KlassDropAsyncPaginate} from "components/Select"
import debounce from "debounce-promise"
import {academicPlansService, courseScheduleService, profileService} from "services"
import moment from "moment"
import {getFullName, handleError} from "helpers"
import {get} from "lodash"
import {Checkbox, Col, Row} from "antd"
import {useVisible} from "hooks"
import {SignInSapAction} from "./SigninSapAction"
import {SignaturePopup} from "uiKit"
import {ApprovalStatus} from "types/isir"

type Props = {
    isShow: boolean
    onClose: () => void
    studentProfileId?: number
    onRefreshData: () => Promise<void>
}

export function AddMeetingPopup(props: Props) {
    const {isShow, onClose, onRefreshData, studentProfileId} = props
    const [isLoading, setIsLoading] = useState(false)
    const [studentSignature, setStudentSignature] = useState<{signatureId: number; imageUrl: string}>()
    const [staffSignature, setStaffSignature] = useState<{signatureId: number; imageUrl: string}>()
    const {t} = useTranslation(["common"])
    const signInSapActionPopup = useVisible(false)
    const studentSignaturePopup = useVisible(false)
    const staffSignaturePopup = useVisible(false)
    const [values, setValues] = useState({
        title: "",
        description: "",
        studentRefusedToSign: false,
        student: undefined,
        date: undefined
    })
    const [errors, setErrors] = useState({
        title: "",
        description: "",
        student: "",
        date: ""
    })

    const isValid = useCallback(
        (payload) => {
            const newErrors: any = {
                title: "",
                description: "",
                student: "",
                date: "",
                staffSignature: "",
                studentSignature: ""
            }
            if (!payload.date) {
                newErrors.date = "Please select date"
            }
            if (!payload.title?.length) {
                newErrors.title = "Please enter title"
            }
            if (!payload.description?.length) {
                newErrors.description = "Please enter description"
            }
            if (!values.studentRefusedToSign && !studentSignature) {
                newErrors.studentSignature = "Please add student signature"
            }
            if (!staffSignature) {
                newErrors.staffSignature = "Please add staff signature"
            }
            if (!payload.student) {
                newErrors.student = "Please select student"
            }
            setErrors(newErrors)
            return !Object.keys(newErrors).find((k) => newErrors[k].length > 0)
        },
        [staffSignature, studentSignature, values]
    )

    const clearValues = useCallback(() => {
        setStaffSignature(undefined)
        setStudentSignature(undefined)
        setValues({
            title: "",
            description: "",
            student: undefined,
            studentRefusedToSign: false,
            date: undefined
        })
    }, [])

    const onSubmit = useCallback(async () => {
        if (!isValid(values)) {
            return
        }
        try {
            setIsLoading(true)
            await academicPlansService.addSapMeetings({
                sapMeetings: [
                    {
                        studentProfileId: values.student.profileId,
                        date: moment(values.date).format("YYYY-MM-DD"),
                        studentRefusedToSign: !!values.studentRefusedToSign,
                        title: values.title,
                        description: values.description,
                        approvalSignatureId: staffSignature.signatureId,
                        studentSignatureId: values.studentRefusedToSign ? null : studentSignature?.signatureId,
                        approvalStatus: ApprovalStatus.Approved
                    }
                ]
            })
            clearValues()
            onRefreshData()
            onClose()
        } catch (err) {
            handleError(err)
        } finally {
            setIsLoading(false)
        }
    }, [clearValues, isValid, onClose, onRefreshData, staffSignature, studentSignature, values])

    const getProfileInfo = async () => {
        const {data: result, total} = await profileService.getAll({
            linkedObjects: true,
            filter: {
                id: [studentProfileId]
            }
        })
        const student = get(result, [0], {})
        setValues({
            ...values,
            student: {
                ...student,
                profileId: student.id
            }
        })
    }

    useEffect(() => {
        if (isShow && studentProfileId) {
            getProfileInfo()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow, studentProfileId])

    const getStudentList = async (search: string, loadedOptions) => {
        try {
            const limit = 20
            const {data, total} = await courseScheduleService.getAllStudents({
                range: {offset: loadedOptions.length, limit: limit},
                filter: {
                    scheduleIds: [],
                    search
                }
            })
            return {
                options: data,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const debounceStudentList = debounce(getStudentList, 300)

    return (
        <BasePopup
            isShow={isShow}
            onClose={() => {
                clearValues()
                onClose()
            }}
            leftIcon="FILE_LINE_ALT"
            width="50vw">
            <div className={styles.wrap}>
                <p className={styles.title}>Add Meeting</p>

                <Row gutter={40}>
                    <Col span={12}>
                        <FormLabel className={styles.fieldTitle} label="Student" />
                        <KlassDropAsyncPaginate
                            loadOptions={debounceStudentList}
                            value={values.student}
                            valueKey="profileId"
                            getOptionLabel={(option) => getFullName(option)}
                            onChange={(value) => setValues({...values, student: value})}
                            placeholder="Select"
                            isDisabled={studentProfileId}
                            isClearable
                        />
                    </Col>
                    <Col span={12}>
                        <FormLabel className={styles.fieldTitle} label="Date" />
                        <BaseDatePicker
                            value={values.date}
                            onChange={(option) => setValues({...values, date: option})}
                            placeholder="Date"
                        />
                    </Col>
                </Row>
                <Col span={24}>
                    <FormLabel className={styles.fieldTitle} label="Title" />
                    <BaseInput
                        onChange={(value) => setValues({...values, title: value})}
                        placeholder={"Title"}
                        value={values?.title}
                    />
                </Col>
                <Col span={24}>
                    <FormLabel className={styles.fieldTitle} label="Description" />
                    <BaseTextArea
                        onChange={(value) => setValues({...values, description: value})}
                        placeholder={"Description"}
                        style={{
                            resize: "vertical"
                        }}
                        value={values?.description}
                    />
                </Col>

                <hr className={styles.line} />

                <Row gutter={40}>
                    <Col span={12}>
                        <p className={styles.signature}>STAFF SIGNATURE</p>
                        {staffSignature ? (
                            <div onClick={staffSignaturePopup.open} className={styles.signatureButton}>
                                <img
                                    src={staffSignature.imageUrl}
                                    alt="studentSignature"
                                    className={styles.signatureImage}
                                />
                                <Icon icon="EDIT_SIGNATURE" className={styles.editButton} color="white" />
                            </div>
                        ) : (
                            <div onClick={staffSignaturePopup.open} className={styles.signatureButton}>
                                <p className={styles.addSignature}>Add signature</p>
                                <Icon icon="EDIT_SIGNATURE" className={styles.editButton} color="white" />
                            </div>
                        )}
                    </Col>
                    <Col span={12}>
                        <p className={styles.signature}>STUDENT SIGNATURE</p>
                        <Checkbox
                            style={{marginTop: 8}}
                            checked={values.studentRefusedToSign}
                            onChange={(event) => setValues({...values, studentRefusedToSign: event.target.checked})}>
                            Student refuse to sign
                        </Checkbox>
                        {!values.studentRefusedToSign && studentSignature ? (
                            <div onClick={signInSapActionPopup.open} className={styles.signatureButton}>
                                <img
                                    src={studentSignature.imageUrl}
                                    alt="studentSignature"
                                    className={styles.signatureImage}
                                />
                                <Icon icon="EDIT_SIGNATURE" className={styles.editButton} color="white" />
                            </div>
                        ) : !values.studentRefusedToSign ? (
                            <div onClick={signInSapActionPopup.open} className={styles.signatureButton}>
                                <p className={styles.addSignature}>Add signature</p>
                                <Icon icon="EDIT_SIGNATURE" className={styles.editButton} color="white" />
                            </div>
                        ) : null}
                    </Col>
                </Row>

                {Object.keys(errors).find((k) => errors[k].length > 0) && (
                    <ul className={styles.errorOptionsList}>
                        {Object.keys(errors)
                            .filter((key) => errors[key].length)
                            .map((key, index) => (
                                <li className={styles.textError} key={index}>
                                    {errors[key]}
                                </li>
                            ))}
                    </ul>
                )}

                <hr className={styles.line} style={{marginTop: 60}} />
                <div className={styles.submitContainer}>
                    <SecondaryButton
                        title={t("common:action.cancel")}
                        onClick={() => {
                            clearValues()
                            onClose()
                        }}
                        style={{marginRight: 15}}
                    />
                    <BaseButton
                        title={isLoading ? t("common:action.saving") : t("common:action.save")}
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>
                <BaseLoading isShow={isLoading} />
            </div>
            <SignInSapAction
                isShow={signInSapActionPopup.isVisible}
                onClose={signInSapActionPopup.close}
                onSignInSuccess={studentSignaturePopup.open}
                studentProfileId={studentProfileId}
                onRefreshData={onRefreshData}
            />

            {values.student?.userId && studentSignaturePopup.isVisible ? (
                <SignaturePopup
                    isShow={studentSignaturePopup.isVisible}
                    onClose={studentSignaturePopup.close}
                    targetUser={{
                        ...values.student,
                        id: values.student.userId,
                        profileId: values.student.profileId
                    }}
                    onSetNewSignature={setStudentSignature}
                    title="Go ahead and sign"
                    actions={{
                        onCancel: studentSignaturePopup.close,
                        onSign: (signature) => {
                            setStudentSignature(signature)
                            studentSignaturePopup.close()
                        }
                    }}
                />
            ) : null}

            <SignaturePopup
                isShow={staffSignaturePopup.isVisible}
                onClose={staffSignaturePopup.close}
                onSetNewSignature={setStaffSignature}
                title="Go ahead and sign"
                actions={{
                    onCancel: staffSignaturePopup.close,
                    onSign: (signature) => {
                        setStaffSignature(signature)
                        staffSignaturePopup.close()
                    }
                }}
            />
        </BasePopup>
    )
}
