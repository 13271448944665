/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./UserAvatar.module.css"
import cx from "classnames"
import {head} from "lodash"
import {useMemo} from "react"
import {Communication} from "types/communication"

type Props = {
    user: Communication.RoomUser
    className?: string
}

export function UserAvatar(props: Props) {
    const {user, className} = props

    const userShortName = useMemo(() => `${head(user.fullName)}`.trim() || "A", [user.fullName])

    if (user.photo) {
        return <img src={user.photo} className={cx(styles.avatar, className)} alt="" />
    }

    return <span className={cx(styles.avatarWrap, className)}>{userShortName}</span>
}
