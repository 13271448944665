/* eslint-disable react-hooks/exhaustive-deps */
import {Divider, Modal, Spin} from "antd"
import {Icon} from "components/Icon"
import React from "react"
import {useTranslation} from "react-i18next"
import {Workflow} from "types/workflow"
import {getFullName} from "helpers"
import {useModel} from "hooks"
import styles from "../Workflow.module.css"
import {StudentActivity} from "types/activity"
import RichTextEditor from "components/RichTextEditor/RichTextEditor"
import {Descendant} from "slate"
import {FormLabel} from "components/Form"
import {BaseInput} from "components"
import ProcessWorkflowFlowChart from "components/FlowChart/ProcessWorkflowFlowChart"
import {useEdgesState, useNodesState} from "reactflow"
import {INITIAL_FLOWCHART_NODES} from "components/FlowChart/FlowChart.constants"

interface ProcessWorkflowModalProps {
    visible: boolean
    onClose: () => void
    activity?: StudentActivity | undefined
}

const ProcessWorkflowModal: React.FC<ProcessWorkflowModalProps> = ({visible, onClose, activity}) => {
    const {t} = useTranslation(["workflow", "common"])
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const userTimeFormat = model.getUserTimeFormat()
    const [workflow, setWorkflow] = React.useState<Workflow | undefined>()
    const [template, setTemplate] = React.useState<Descendant[]>([])
    const [nodes, setNodes, onNodesChange] = useNodesState(INITIAL_FLOWCHART_NODES)
    const [edges, setEdges, onEdgesChange] = useEdgesState([])

    React.useEffect(() => {
        ;(async function loadWorkflow() {
            if (!activity || !activity.triggeredWorkflow?.workflow) {
                setWorkflow(undefined)
                setTemplate([])
                setNodes([])
                setEdges([])
                return
            }
            const {nodes, edges} = JSON.parse(activity.triggeredWorkflow.workflow.flow || "{}")
            setNodes(nodes)
            setEdges(edges)
            setWorkflow(activity.triggeredWorkflow.workflow)
            setTemplate(JSON.parse(activity.triggeredWorkflow.template))
        })()
    }, [activity])

    return (
        <Modal
            keyboard={false}
            closable={true}
            visible={visible}
            onCancel={onClose}
            closeIcon={<Icon icon="CLOSE" color="#000" className={styles.closeIcon} />}
            maskClosable={false}
            footer={null}
            width="80%"
            centered
            className={styles.workflowModal}>
            <div className={styles.workflowModalContent}>
                <div className={styles.leftSidebar}>
                    <Icon icon="WORKFLOW" className={styles.workflowIcon} color="#ffffff" />
                </div>

                <div className={styles.mainContent}>
                    <div className={styles.headerTitle}>Workflow</div>

                    <Divider className="border-t-2 border-primary mt-10 mb-48" />

                    {!workflow || !activity ? (
                        <Spin />
                    ) : (
                        <>
                            <div className={styles.header}>
                                <span className={styles.headerTitle}>{getFullName(activity.student)}</span>
                                <span className={styles.headerCode}>{activity.student?.profileId}</span>
                            </div>

                            <div className={styles.activityDetail}>
                                <div className={styles.section}>
                                    <FormLabel label={t("common:activityPopup.activity")} />
                                    <BaseInput readOnly={true} value={workflow?.name} />
                                </div>

                                <div className={styles.section}>
                                    <FormLabel label={t("common:activityPopup.initiatedBy")} />
                                    <BaseInput readOnly={true} value={getFullName(activity.createdBy)} />
                                </div>

                                <div className={styles.section}>
                                    <FormLabel label={t("common:activityPopup.template")} />
                                    <RichTextEditor
                                        value={template}
                                        readOnly={true}
                                        fieldReadOnly={true}
                                        userDateFormat={userDateFormat}
                                        userTimeFormat={userTimeFormat}
                                    />
                                </div>
                            </div>

                            <div className={styles.flowChartContainer}>
                                <div className={styles.flowChartContent}>
                                    <ProcessWorkflowFlowChart
                                        {...{
                                            key: workflow?.id.toString(),
                                            nodes,
                                            setNodes,
                                            onNodesChange,
                                            edges,
                                            setEdges,
                                            onEdgesChange,
                                            readOnly: true,
                                            activity
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default ProcessWorkflowModal
