import styles from "./StudentAndEmployerComment.module.css"
import {useEffect, useState} from "react"
import {CareerServices} from "types/careerServices"
import {displaySince, handleError} from "helpers"
import {BaseButton, BaseTextArea, Icon} from "components"
import {careerService} from "services"
import {useModel, useVisible} from "hooks"
import cx from "classnames"
import {ReactComponent as FileIcon} from "./file.svg"
import {RespondOfferApplication} from "./parts"

type Props = {
    notes: CareerServices.ApplicationNote[]
    applicationId: number
    appicationProfileId: number
    employerLogo?: string
    studentPhoto?: string
}

export function StudentAndEmployerComment(props: Props) {
    const {notes: _notes, applicationId, appicationProfileId, employerLogo, studentPhoto} = props
    const [notes, setNotes] = useState<CareerServices.ApplicationNote[]>([])
    const [comment, setComment] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false)
    const model = useModel()
    const isOfCurrentStudent = model.profileId === appicationProfileId
    const isStaffOrAdmin = model.isStaffOrAdmin()
    const respondFormVisible = useVisible(false)
    const [respondType, setRespondType] = useState<"accepted" | "rejected">("accepted")
    const [respondNote, setRespondNote] = useState<CareerServices.ApplicationNote>()

    useEffect(() => {
        setNotes(_notes)
    }, [_notes])

    const handleClick = async (e) => {
        e.preventDefault()
        try {
            setIsSubmitting(true)

            const newNote = await careerService.createApplicationNote({
                applicationId: applicationId,
                noteType: CareerServices.ApplicationNoteType.ToStudent,
                authorType: isStaffOrAdmin ? "employer" : "student",
                content: comment
            })
            setNotes([...notes, newNote])
            setComment("")
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitting(false)
        }
    }

    const onRespondOffer = (newNote: CareerServices.ApplicationNote, respondNoteId: number) => {
        let _notes = [...notes]
        const respondNoteIndex = notes.findIndex((x) => x.id === respondNoteId)
        _notes[respondNoteIndex].isResponded = true
        setNotes([..._notes, newNote])
        respondFormVisible.close()
    }

    return (
        <div className={styles.notesContainer}>
            <div className={styles.title}>Student - Employer Comments</div>
            {notes &&
                notes.map((item) => {
                    return (
                        <div
                            key={item.id}
                            className={cx(styles.noteItem, {
                                [styles.noteStatus]: ["rejected", "job-offer"].includes(item.applicationStatus)
                            })}>
                            <div className={styles.noteHeader}>
                                {item.authorType === "employer" && employerLogo && (
                                    <img
                                        src={JSON.parse(employerLogo)["32"]}
                                        alt="Company logo"
                                        className={styles.photoWrap}
                                    />
                                )}
                                {item.authorType === "student" && studentPhoto && (
                                    <img src={studentPhoto} alt="Profile" className={styles.photoWrap} />
                                )}
                                <span className={styles.noteDate}>
                                    {displaySince(item.createdAt, "DD/MM/YYYY hh:mm A")}
                                </span>
                            </div>
                            <div className={styles.noteContent}>{item.content}</div>
                            {item.attachmentId && (
                                <div
                                    className={styles.attachmentsWrap}
                                    onClick={() => {
                                        window.open(item.attachment.attachmentUrl, "_blank")
                                    }}>
                                    <div className={styles.documentWrap}>
                                        <FileIcon className={styles.fileIcon} width={24} height={24} />
                                        <div className={styles.documentFileName}>
                                            {item.attachment.attachmentFileName}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {item.isResponded ? (
                                <div className={styles.respondWrap}>
                                    <Icon icon="CHECKMARK_CIRCLE" color="#18A957" className={styles.respondIcon} />
                                    <span className={styles.respondStatus}>Offer has been responded</span>
                                </div>
                            ) : (
                                ""
                            )}
                            {isOfCurrentStudent &&
                                item.authorType === "employer" &&
                                item.applicationStatus === "job-offer" &&
                                !item.isResponded && (
                                    <>
                                        <div className={styles.respondOfferWrap}>
                                            <BaseButton
                                                title="Accept"
                                                onClick={() => {
                                                    setRespondNote(item)
                                                    setRespondType("accepted")
                                                    respondFormVisible.open()
                                                }}
                                            />
                                            <BaseButton
                                                title="Reject"
                                                onClick={() => {
                                                    setRespondNote(item)
                                                    setRespondType("rejected")
                                                    respondFormVisible.open()
                                                }}
                                                variant="secondary"
                                            />
                                        </div>
                                        <RespondOfferApplication
                                            applicationId={applicationId}
                                            respondType={respondType}
                                            respondNoteId={respondNote?.id}
                                            open={respondFormVisible.isVisible}
                                            onClose={() => respondFormVisible.close()}
                                            onSuccess={onRespondOffer}
                                        />
                                    </>
                                )}
                        </div>
                    )
                })}
            {(isStaffOrAdmin || isOfCurrentStudent) && (
                <>
                    <BaseTextArea
                        className={styles.noteInput}
                        rows={3}
                        placeholder="type"
                        onChange={setComment}
                        value={comment}
                    />
                    <div className={styles.noteBtn}>
                        <BaseButton
                            variant="secondary"
                            title="Send"
                            size="small"
                            onClick={handleClick}
                            disabled={comment === ""}
                            loading={isSubmitting}
                        />
                    </div>
                </>
            )}
        </div>
    )
}
