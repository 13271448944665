export const dropdownStyle = {
    control: (provided, state) => {
        return {
            ...provided,
            borderRadius: "6px",
            border: "none",
            boxShadow: state?.selectProps?.error ? "none" : "1px 2px 3px 1px var(--black-100)",
            minHeight: "44px",
            fontSize: 16
        }
    },

    option: (provided) => ({
        ...provided,
        padding: "10px",
        cursor: "pointer"
    }),

    container: (provided, state) => ({
        ...provided,
        width: "100%",
        border: state?.selectProps?.error ? "1px solid var(--error-400-base) !important" : "none",
        borderRadius: 6
    }),

    indicatorSeparator: () => ({
        display: "none"
    }),

    dropdownIndicator: (provided, state) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        width: "40px",
        height: "100%",
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
        justifyContent: "center",
        color: "#666666",
        cursor: "pointer"
    }),

    placeholder: (provided) => ({
        ...provided,
        fontSize: "16px",
        color: "var(--black-200)"
    }),

    multiValue: (styles) => {
        return {
            ...styles,
            backgroundColor: "var(--primary-400-base)"
        }
    },

    multiValueLabel: (styles) => ({
        ...styles,
        color: "var(--whitebase)"
    }),

    multiValueRemove: (styles) => ({
        ...styles,
        color: "var(--whitebase)"
    }),

    menuList: () => ({
        maxHeight: "200px",
        overflow: "auto"
    }),

    menu: (provided) => ({
        ...provided,
        zIndex: 10
    }),

    groupHeading: (provided) => ({
        ...provided,
        backgroundColor: "var(--primary-100)",
        fontSize: 18,
        fontWeight: 700,
        lineHeight: 1.11,
        color: "var(--black-200)",
        paddingTop: 4,
        paddingBottom: 4
    })
}
