import React, {useState, useEffect} from "react"
import {Row, Col} from "antd"
import classNames from "classnames"
import {finAidService} from "services"
import {handleError} from "helpers"
import styles from "./Scholarship.module.css"
import NumberFormat from "react-number-format"
import {useTranslation} from "react-i18next"

export function Scholarships(props) {
    const [overallSummary, setOverallSummary] = useState({
        activeFunds: 0,
        availableFunds: 0,
        funded: {currentTerm: {amount: 0, students: 0}, currentYear: {amount: 0, students: 0}},
        reserved: {currentTerm: {amount: 0, students: 0}, currentYear: {amount: 0, students: 0}}
    })
    const {t} = useTranslation(["financialAid", "common"])

    const getTotalgetScholarshipOverallSummarySummary = async () => {
        try {
            const data = await finAidService.getScholarshipOverallSummary()
            setOverallSummary(data)
        } catch (error) {
            handleError(error)
        }
    }

    useEffect(() => {
        getTotalgetScholarshipOverallSummarySummary()
    }, [])

    return (
        <div>
            <p className={styles.title}>Scholarships</p>
            <Row gutter={[8, 8]}>
                <Col span={6}>
                    <div className={styles.cardWrap}>
                        <p className={styles.cardTitle}>{t("financialAid.scholarship.activeFunds")}</p>
                        <p className={styles.cardValue}>{overallSummary.activeFunds}</p>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.cardWrap}>
                        <p className={styles.cardTitle}>{t("financialAid.scholarship.funded")} </p>
                        <div className={styles.cardBodyWrap}>
                            <div className={classNames(styles.cardBodyItem, styles.cardBodyItemLeft)}>
                                <p className={styles.cardBodyItem__title}>{t("financialAid.scholarship.thisTerm")}</p>
                                <div className={styles.cardBodyItemInfo}>
                                    <p className={styles.cardBodyItemInfo__total}>
                                        {overallSummary.funded.currentTerm.students}
                                    </p>
                                    <p className={styles.cardBodyItemInfo__desc}>
                                        {t("financialAid.scholarship.students")}
                                    </p>
                                </div>
                                <NumberFormat
                                    thousandSeparator={true}
                                    prefix="$"
                                    value={overallSummary.reserved.currentTerm.amount}
                                    className={styles.cardBodyItemInfo__amount}
                                    displayType="text"
                                />
                            </div>
                            <div className={styles.cardBodyItem}>
                                <p className={styles.cardBodyItem__title}>{t("financialAid.scholarship.thisYear")}</p>
                                <div className={styles.cardBodyItemInfo}>
                                    <p className={styles.cardBodyItemInfo__total}>
                                        {overallSummary.funded.currentYear.students}
                                    </p>
                                    <p className={styles.cardBodyItemInfo__desc}>
                                        {t("financialAid.scholarship.students")}
                                    </p>
                                </div>
                                <NumberFormat
                                    thousandSeparator={true}
                                    prefix="$"
                                    value={overallSummary.reserved.currentYear.amount}
                                    className={styles.cardBodyItemInfo__amount}
                                    displayType="text"
                                />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.cardWrap}>
                        <p className={styles.cardTitle}>{t("financialAid.scholarship.reserved")}</p>
                        <div className={styles.cardBodyWrap}>
                            <div className={classNames(styles.cardBodyItem, styles.cardBodyItemLeft)}>
                                <p className={styles.cardBodyItem__title}>{t("financialAid.scholarship.thisTerm")}</p>
                                <div className={styles.cardBodyItemInfo}>
                                    <p className={styles.cardBodyItemInfo__total}>
                                        {overallSummary.reserved.currentTerm.students}
                                    </p>
                                    <p className={styles.cardBodyItemInfo__desc}>
                                        {t("financialAid.scholarship.students")}
                                    </p>
                                </div>
                                <NumberFormat
                                    thousandSeparator={true}
                                    prefix="$"
                                    value={overallSummary.reserved.currentTerm.amount}
                                    className={styles.cardBodyItemInfo__amount}
                                    displayType="text"
                                />
                            </div>
                            <div className={styles.cardBodyItem}>
                                <p className={styles.cardBodyItem__title}>{t("financialAid.scholarship.thisYear")}</p>
                                <div className={styles.cardBodyItemInfo}>
                                    <p className={styles.cardBodyItemInfo__total}>
                                        {overallSummary.reserved.currentYear.students}
                                    </p>
                                    <p className={styles.cardBodyItemInfo__desc}>
                                        {t("financialAid.scholarship.students")}
                                    </p>
                                </div>
                                <NumberFormat
                                    thousandSeparator={true}
                                    prefix="$"
                                    value={overallSummary.reserved.currentYear.amount}
                                    className={styles.cardBodyItemInfo__amount}
                                    displayType="text"
                                />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.cardWrap}>
                        <p className={styles.cardTitle}>{t("financialAid.scholarship.activeFunds")}</p>
                        <NumberFormat
                            thousandSeparator={true}
                            prefix="$"
                            value={overallSummary.availableFunds}
                            className={styles.cardValue}
                            displayType="text"
                        />
                    </div>
                </Col>
            </Row>
        </div>
    )
}
