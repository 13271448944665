import React from "react"
import cx from "classnames"
import styles from "./UserTextIcon.module.css"

type Props = {
    text: string
    small?: boolean
    className?: string
    onClick?: () => void
}

export function UserTextIcon(props: Props) {
    const {text, className, small, onClick} = props

    const onClickIcon = () => {
        onClick?.()
    }

    return (
        <div className={cx(styles.wrapper, className, {[styles.small]: small})} onClick={onClickIcon}>
            {text}
        </div>
    )
}
