import {AppliedFilter, FilterKey, FilterList} from "types/filter"
import {AcademicAffairsFilter} from "../../../AcademicAffairsFilter"
import {useCallback, useState} from "react"
import {StudentListTable} from "sections/shared/students"
import {BaseDepartmentId} from "types/departments"
import {ExportFileType} from "components/ui"

enum COLLECTIONS {
    LOA = "loa",
    DROP = "drop",
    SAP = "sap",
    ENROLL = "enroll",
    ACTIVE = "active"
}

const COLLECTION_LABELS: Record<COLLECTIONS, string> = {
    [COLLECTIONS.LOA]: "Leave of Absence",
    [COLLECTIONS.DROP]: "Drop",
    [COLLECTIONS.SAP]: "SAP",
    [COLLECTIONS.ENROLL]: "Enroll",
    [COLLECTIONS.ACTIVE]: "Active Student"
}

export function AcademicAffairsStudentList() {
    const [filters, setFilters] = useState<FilterList>({})
    const [activeKey, setActiveKey] = useState<COLLECTIONS>(COLLECTIONS.LOA)
    const [isExporting, setIsExporting] = useState<boolean>(false)
    const [exportFileType, setExportFileType] = useState<ExportFileType | null>()

    const onApplyFilter = (payload: AppliedFilter) => {
        const {filters} = payload
        setFilters(filters)
    }

    const handleExportFile = async (type: ExportFileType) => {
        setExportFileType(type)
    }

    const handleUpdateState = (payload: any) => {
        const {isExporting, exportFileType} = payload
        setIsExporting(isExporting)
        setExportFileType(exportFileType)
    }

    return (
        <div>
            <AcademicAffairsFilter
                onApplyFilter={onApplyFilter}
                filterKey={FilterKey.AcademicsPage_Analytics_Students}
                isStudentList
                showExport={true}
                isExporting={isExporting}
                handleExport={handleExportFile}
            />
            {/* <div className={styles.collectionWrap}>
                {Object.entries(COLLECTION_LABELS).map(([k, v]) => {
                    return (
                        <div
                            className={cx(styles.collectionItem, {[styles.activeCollection]: activeKey === k})}
                            onClick={() => setActiveKey(k as COLLECTIONS)}>
                            <span className={styles.title}>{v}</span>
                            <span className={styles.value}>20</span>
                        </div>
                    )
                })}
            </div> */}
            <StudentListTable
                departmentId={BaseDepartmentId.Academics}
                studentForm={filters}
                filterMemoryKey={FilterKey.AcademicsPage_Analytics_Students}
                useNewFilter={true}
                exportFileType={exportFileType}
                updateState={handleUpdateState}
            />
        </div>
    )
}
