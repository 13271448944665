/* eslint-disable react-hooks/exhaustive-deps */
import {
    AppliedFilter,
    FIELD_OPERATOR_LABELS,
    FieldOperator,
    FieldPayload,
    FieldType,
    FilterKey,
    FilterList,
    SavedFilter
} from "types/filter"
import styles from "./KlassappFilters.module.css"
import cx from "classnames"
import {Search} from "components/inputs/Search"
import {useCallback, useEffect, useState} from "react"
import {ReactComponent as FilterIcon} from "./assets/filter.svg"
import {ReactComponent as CollapseIcon} from "./assets/collapse.svg"
import {ReactComponent as RemoveIcon} from "./assets/remove.svg"
import {useModel, useVisible} from "hooks"
import {FilterItem, SavedFiltersSelect, SaveFilterForm} from "./parts"
import {BaseButton, BaseLoading} from "components"
import {useTranslation} from "react-i18next"
import {handleError, toastError} from "helpers"
import {BaseDatePicker, BaseRangePicker} from "components/DateTimePicker"
import moment from "moment"
import {isEmpty} from "lodash"
import debounce from "debounce-promise"

export type FilterField = {
    field: string
    label: string
    type: FieldType
    // extractValue?: (v: any) => any
    renderForm?: (props: any) => JSX.Element
}

export type KlassappFiltersProps = {
    filterKey: FilterKey
    className?: string
    showSearch?: boolean
    availableFilters: FilterField[]
    renderAdditionalFilter?: () => JSX.Element
    initialFilter?: AppliedFilter
    onApply?: (payload: AppliedFilter) => void
    onClear?: () => void
}

export function KlassappFilters(props: KlassappFiltersProps) {
    const {t} = useTranslation(["common"])
    const {
        filterKey,
        className,
        showSearch = true,
        renderAdditionalFilter,
        availableFilters,
        initialFilter,
        onApply,
        onClear
    } = props
    const [search, setSearch] = useState<string>("")
    const collapsed = useVisible(true)
    const [selectedFilters, setSelectedFilters] = useState<FilterList>({})
    const [appliedFilter, setAppliedFilter] = useState<AppliedFilter>({})
    const [errorFields, setErrorFields] = useState<Record<string, string[]>>({})
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const [isFiltering, setIsFiltering] = useState<boolean>(false)
    const [selectedSavedFilter, setSelectedSavedFilter] = useState<SavedFilter | null>(null)
    const [hasChange, setHasChange] = useState(false)
    const savedFilterForm = useVisible(false)
    const [savedFilterSelectKey, setSavedFilterSelectKey] = useState(new Date().toString())

    const validateFilters = (_selectedFilters: FilterList) => {
        let _errors: Record<string, string[]> = {}
        availableFilters.forEach((x) => {
            const f = _selectedFilters[x.field]
            if (f) {
                let e: string[] = []
                if (x.type !== FieldType.Flag) {
                    if (!!f.operator && (f.value === undefined || f.value === null)) {
                        e.push("value")
                    }
                    if ((f.operator === undefined || f.operator === null) && !!f.value) {
                        e.push("operator")
                    }
                }
                if (e.length) {
                    _errors[x.field] = e
                }
            }
        })
        setErrorFields(_errors)
        return Object.keys(_errors).length <= 0
    }

    const onApplyFilter = ({
        _selectedFilters,
        _searchText,
        skipStorage = false
    }: {
        _selectedFilters: FilterList
        _searchText: string
        skipStorage?: boolean
    }) => {
        if (!validateFilters(_selectedFilters)) {
            toastError("Missing filter information, please check.")
            return
        }
        const _filters: FilterList = {}
        Object.entries(_selectedFilters).forEach(([k, v]) => {
            if (v.value != null) {
                _filters[k] = v
            }
        })
        const _appliedFilter: AppliedFilter = {
            search: _searchText,
            filters: _filters
        }
        setAppliedFilter(_appliedFilter)
        try {
            setIsFiltering(true)
            !skipStorage && model.updateStorageFilter(filterKey, _appliedFilter)
            onApply(_appliedFilter)
        } catch (err) {
            handleError(err)
        } finally {
            collapsed.open()
            setIsFiltering(false)
        }
    }

    useEffect(() => {
        let search = ""
        let filters = {}

        const storageFilter = model.getStorageFilter(filterKey)
        if (!isEmpty(storageFilter)) {
            search = storageFilter.search
            filters = storageFilter.filters
        } else if (!isEmpty(initialFilter)) {
            search = initialFilter.search
            filters = initialFilter.filters
        }
        setSearch(search || "")
        setSelectedFilters(filters || {})
        onApplyFilter({
            _selectedFilters: filters || {},
            _searchText: search || "",
            skipStorage: true
        })
    }, [filterKey])

    // const getExtractedValue = (field: string, value: any) => {
    //     const f = availableFilters.find((x) => x.field === field)
    //     let extractedValue: any = value
    //     if (f && f.type !== FieldType.Flag) {
    //         if (f.extractValue) {
    //             extractedValue = Array.isArray(value) ? value.map((x) => f.extractValue(x)) : f.extractValue(value)
    //         } else {
    //             if (f.type === FieldType.Date) {
    //                 extractedValue = Array.isArray(value)
    //                     ? value.map((x) => moment(x).format("YYYY-MM-DD"))
    //                     : moment(value).format("YYYY-MM-DD")
    //             }
    //         }
    //     }
    //     return extractedValue
    // }

    const onChangeFieldOperator = (field: string, operator?: FieldOperator, isDateField?: boolean) => {
        setHasChange(true)
        setSelectedFilters((prev) => ({
            ...prev,
            [field]: {
                ...(prev[field] || {}),
                operator
            }
        }))
        if (isDateField) {
            onChangeFieldValue(field, null)
        }
    }

    const onChangeFieldValue = (field: string, value: any) => {
        setHasChange(true)
        setSelectedFilters((prev) => ({
            ...prev,
            [field]: {
                ...(prev[field] || {}),
                value
                // extractedValue: getExtractedValue(field, value)
            }
        }))
    }

    const removeFilterValue = (filter: FilterField, index?: number) => {
        const {type, field} = filter
        let _filters = appliedFilter?.filters
        if (type !== FieldType.List) {
            delete _filters[field]
        } else {
            const filterValues = _filters[field].value
            if (filterValues.length >= index) {
                filterValues.splice(index, 1)
            }
            if (filterValues.length <= 0) {
                delete _filters[field]
            } else {
                _filters[field] = {
                    ..._filters[field],
                    value: filterValues
                }
            }
        }
        setSelectedFilters(_filters)
        onApplyFilter({_selectedFilters: _filters, _searchText: search})
    }

    const onClearFilter = () => {
        setSelectedFilters({})
        setAppliedFilter({})
        setSearch("")
        setHasChange(false)
        onClear?.()
    }

    const handleSelectingSavedFilter = (_savedFilter: SavedFilter) => {
        setSelectedSavedFilter(_savedFilter)
        let _filters =
            typeof _savedFilter.content === "string" ? JSON.parse(_savedFilter.content) : _savedFilter.content
        const availableFields = availableFilters.map((x) => x.field)
        Object.keys(_filters).forEach((x) => {
            if (!availableFields.includes(x)) {
                delete _filters[x]
            }
        })
        setSelectedFilters(_filters)
        setHasChange(false)
    }

    const onSavedFilterSuccess = () => {
        savedFilterForm.close()
        setSavedFilterSelectKey(new Date().toString())
    }

    const onChangeSearchText = (_searchText: string, _selectedFilters: FilterList) => {
        setSearch(_searchText)
        debounceSearchValue(_searchText, _selectedFilters)
    }

    const debounceSearchValue = useCallback(
        debounce((_searchText: string, _selectedFilters: FilterList) => {
            onApplyFilter({_selectedFilters, _searchText})
        }, 500),
        []
    )

    const renderDateOptions = (field: string, operator?: FieldOperator) => {
        if (operator === undefined) return
        const dateValues = selectedFilters[field]?.value
        if (operator === FieldOperator.Between) {
            return (
                <BaseRangePicker
                    placeholder={["From", "To"]}
                    value={dateValues ? [moment(dateValues[0]), moment(dateValues[1])] : null}
                    onChange={(newValue) => onChangeFieldValue(field, newValue)}
                    error={(errorFields[field] || []).includes("value")}
                />
            )
        }
        return (
            <BaseDatePicker
                value={dateValues ? moment(dateValues) : null}
                onChange={(newValue) => onChangeFieldValue(field, newValue)}
                error={(errorFields[field] || []).includes("value")}
            />
        )
    }

    const renderDateLabels = (filter: FilterField, payload: FieldPayload) => {
        const {operator, value: dateValues} = payload
        if (operator === FieldOperator.Between) {
            return (
                <>
                    <div className={styles.itemValue}>
                        <span className={styles.itemValueLabel}>{moment(dateValues[0]).format(userDateFormat)}</span>
                    </div>
                    <span className={styles.itemOperator}>and</span>
                    <div className={styles.itemValue}>
                        <span className={styles.itemValueLabel}>{moment(dateValues[1]).format(userDateFormat)}</span>
                        <div
                            className={styles.removeWrap}
                            onClick={(e) => {
                                e.stopPropagation()
                                removeFilterValue(filter)
                            }}>
                            <RemoveIcon className={styles.icon} />
                        </div>
                    </div>
                </>
            )
        }
        return (
            <div className={styles.itemValue}>
                <span className={styles.itemValueLabel}>{moment(dateValues).format(userDateFormat)}</span>
                <div
                    className={styles.removeWrap}
                    onClick={(e) => {
                        e.stopPropagation()
                        removeFilterValue(filter)
                    }}>
                    <RemoveIcon className={styles.icon} />
                </div>
            </div>
        )
    }

    const renderTextLabel = (filter: FilterField, payload: FieldPayload) => {
        return (
            <div className={styles.itemValue}>
                <span className={styles.itemValueLabel}>{payload.value}</span>
                <div
                    className={styles.removeWrap}
                    onClick={(e) => {
                        e.stopPropagation()
                        removeFilterValue(filter)
                    }}>
                    <RemoveIcon className={styles.icon} />
                </div>
            </div>
        )
    }

    const renderBooleanLabel = (filter: FilterField, payload: FieldPayload) => {
        return (
            <div className={styles.itemValue}>
                <div
                    className={styles.removeWrap}
                    onClick={(e) => {
                        e.stopPropagation()
                        removeFilterValue(filter)
                    }}>
                    <RemoveIcon className={styles.icon} />
                </div>
            </div>
        )
    }

    const renderCollapsedHeading = () => {
        const filterEntries = Object.entries(appliedFilter?.filters ?? {}).filter(([k, v]) => {
            const f = availableFilters.find((x) => x.field === k)
            if (!f) return false
            if (f.type === FieldType.Flag && !v.value) return false
            return true
        })
        if (filterEntries.length) {
            return (
                <>
                    {filterEntries.map(([k, v]) => {
                        const f = availableFilters.find((x) => x.field === k)
                        const o = FIELD_OPERATOR_LABELS.find((x) => x.id === v.operator)
                        if (!f) return null
                        return (
                            <div key={k} className={styles.headingItem}>
                                <span className={styles.itemLabel}>{f.label}</span>
                                {f.type !== FieldType.Flag && <span className={styles.itemOperator}>{o?.name}</span>}
                                <div className={styles.itemValueWrap}>
                                    {f.type === FieldType.Date && renderDateLabels(f, v)}
                                    {f.type === FieldType.Text && renderTextLabel(f, v)}
                                    {f.type === FieldType.Flag && renderBooleanLabel(f, v)}

                                    {[FieldType.Single, FieldType.List, FieldType.Number].includes(f.type) &&
                                        (Array.isArray(v.value) ? v.value : [v.value]).map((y, idx) => {
                                            return (
                                                <div key={idx} className={styles.itemValue}>
                                                    <span className={styles.itemValueLabel}>{y.name}</span>
                                                    <div
                                                        className={styles.removeWrap}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            removeFilterValue(f, idx)
                                                        }}>
                                                        <RemoveIcon className={styles.icon} />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                        )
                    })}
                </>
            )
        }
        return <span className={styles.noFilter}>There is no filter selected.</span>
    }

    const renderFilter = () => {
        return (
            <div className={styles.filterWrap}>
                <div className={styles.fieldsWrap}>
                    {availableFilters.map((x) => {
                        return (
                            <FilterItem
                                {...x}
                                // key={`${x.field}-${JSON.stringify(selectedFilters[x.field] || "")}`}
                                key={x.field}
                                data={selectedFilters[x.field]}
                                onChangeOperator={(opr) =>
                                    onChangeFieldOperator(x.field, opr, x.type === FieldType.Date)
                                }
                                onChangeValue={(v) => onChangeFieldValue(x.field, v)}
                                errors={errorFields[x.field]}>
                                {x.type !== FieldType.Date
                                    ? x.renderForm?.({
                                          onChange: (v) => onChangeFieldValue(x.field, v),
                                          error: (errorFields[x.field] || []).includes("value"),
                                          value: selectedFilters[x.field]?.value ?? null
                                      })
                                    : renderDateOptions(x.field, selectedFilters[x.field]?.operator)}
                            </FilterItem>
                        )
                    })}
                </div>
                <div className={styles.actionsWrap}>
                    {savedFilterForm.isVisible ? (
                        <SaveFilterForm
                            filters={selectedFilters}
                            onValidateFilters={() => validateFilters(selectedFilters)}
                            onSaved={onSavedFilterSuccess}
                            onCancel={savedFilterForm.close}
                        />
                    ) : (
                        <BaseButton
                            variant="secondary"
                            title="Save Filter"
                            disabled={!hasChange}
                            onClick={savedFilterForm.open}
                        />
                    )}
                    <div className={styles.rightActions}>
                        <BaseButton title={t("common:action.clear")} variant="secondary" onClick={onClearFilter} />
                        <BaseButton
                            title={t("common:action.apply")}
                            onClick={() => onApplyFilter({_selectedFilters: selectedFilters, _searchText: search})}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const renderSavedFilters = () => {
        return (
            <div className={styles.savedFilters}>
                <span className={styles.title}>apply filters to see table</span>
                <span className={styles.orLabel}>or</span>
                <div className={styles.savedBox}>
                    <span className={styles.selectLabel}>Select a filter</span>
                    <div onClick={(e) => e.stopPropagation()}>
                        <SavedFiltersSelect
                            isClearable={true}
                            key={savedFilterSelectKey}
                            value={selectedSavedFilter ?? null}
                            onChange={handleSelectingSavedFilter}
                        />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={cx(styles.container, className)}>
            <div className={cx(styles.main, {[styles.mainCollapsed]: collapsed.isVisible})}>
                <div className={cx(styles.rowHeader, styles.headerPointer)} onClick={collapsed.toggle}>
                    <div className={styles.heading}>
                        <div className={styles.headingTilte}>
                            <FilterIcon className={styles.icon} />
                            {collapsed.isVisible ? (
                                <span className={styles.title}>Filters:</span>
                            ) : (
                                renderSavedFilters()
                            )}
                        </div>
                        {collapsed.isVisible && renderCollapsedHeading()}
                    </div>
                    <div className={styles.collapseWrap}>
                        {collapsed.isVisible ? (
                            <CollapseIcon className={styles.actionIcon} />
                        ) : (
                            <RemoveIcon className={styles.actionIcon} />
                        )}
                    </div>
                </div>
                {!collapsed.isVisible && renderFilter()}
            </div>
            {(showSearch || renderAdditionalFilter) && (
                <div className={styles.row}>
                    {showSearch && (
                        <Search
                            className={styles.search}
                            onChange={(v) => onChangeSearchText(v, selectedFilters)}
                            onPressEnter={() => onApplyFilter({_selectedFilters: selectedFilters, _searchText: search})}
                            value={search}
                            placeholder="Search"
                        />
                    )}
                    {renderAdditionalFilter && <div className={styles.additional}>{renderAdditionalFilter()}</div>}
                </div>
            )}
            <BaseLoading isShow={isFiltering} />
        </div>
    )
}
