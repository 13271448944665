import {translate} from "helpers/common"
import {DataType, DefaultDataType, CheckboxType, InitialType} from "types/tasks"

export const dataTypes = [
    {id: DataType.TEXT, name: translate("tasks:dataType.text")},
    {id: DataType.NUMERIC, name: translate("tasks:dataType.numeric")},
    {id: DataType.DATE, name: translate("tasks:dataType.date")},
    {id: DataType.SOCIAL_SECURITY_NUMBER, name: translate("tasks:dataType.socialSecurityNumber")}
]

export const defaultDataTypes = [
    {id: DefaultDataType.NO_DEFAULT, name: translate("tasks:defaultDataType.noDefault")},
    {id: DefaultDataType.MANUAL_TEXT, name: translate("tasks:defaultDataType.manualText")},
    {id: DefaultDataType.STUDENT_DATA, name: translate("tasks:defaultDataType.studentData")}
]

export const checkboxTypes = [
    {id: CheckboxType.SINGLE, name: translate("tasks:checkboxType.single")},
    {id: CheckboxType.MULTIPLE, name: translate("tasks:checkboxType.multiple")}
]

export const initialTypes = [
    {id: InitialType.SINGLE, name: translate("tasks:initialType.single")},
    {id: InitialType.MULTIPLE, name: translate("tasks:initialType.multiple")}
]

export const studentDataValues = [
    {
        label: translate("tasks:studentData.userInfo"),
        options: [
            {id: "fullName", name: translate("tasks:studentData.fullName")},
            {id: "firstName", name: translate("tasks:studentData.firstName")},
            {id: "lastName", name: translate("tasks:studentData.lastName")},
            {id: "middleName", name: translate("tasks:studentData.middleName")},
            {id: "nickNamePreferedName", name: translate("tasks:studentData.nickNamePreferedName")},
            {id: "email", name: translate("tasks:studentData.primaryEmail")},
            {id: "phone", name: translate("tasks:studentData.primaryPhone")},
            {id: "gender", name: translate("tasks:studentData.gender")},
            {id: "birthDate", name: translate("tasks:studentData.dob")},
            {id: "ssn", name: translate("tasks:studentData.ssn")},
            {id: "address", name: translate("tasks:studentData.address")},
            {id: "city", name: translate("tasks:studentData.city")},
            {id: "state", name: translate("tasks:studentData.state")},
            {id: "postalCode", name: translate("tasks:studentData.postalCode")},
            {id: "country", name: translate("tasks:studentData.country")},
            {id: "campuses", name: translate("tasks:studentData.campus")},

            {id: "campusAddress", name: translate("tasks:studentData.campusAddress")},
            {id: "campusEmail", name: translate("tasks:studentData.campusEmail")},
            {id: "campusPhone", name: translate("tasks:studentData.campusPhone")},

            {id: "program", name: translate("tasks:studentData.major")},
            {id: "customProfileId", name: translate("tasks:studentData.studentId")},
            {id: "customUserId", name: translate("tasks:studentData.userId")},
            {id: "startTerm", name: translate("tasks:studentData.startTerm")},
            {id: "start_date", name: translate("tasks:studentData.startDate")},
            {id: "term", name: translate("tasks:studentData.currentTerm")},
            {id: "graduationDate", name: translate("tasks:studentData.graduationDate")},
            {id: "contractGraduationDate", name: translate("tasks:studentData.contractGraduationDate")},
            {id: "documentsFolder", name: translate("tasks:studentData.documentsFolder")}
            // {id: "lastDateAttended", name: translate("tasks:studentData.lastDateAttended")},
        ]
    },
    {
        label: translate("tasks:studentData.admissions"),
        options: [
            {id: "admissionStatus", name: translate("tasks:studentData.admissionStatus")},
            {id: "admissionAdvisor", name: translate("tasks:studentData.admissionAdvisor")},
            {id: "enrollmentStatus", name: translate("tasks:studentData.enrollmentStatus")},
            {id: "admissionAdvisorEmail", name: translate("tasks:studentData.admissionAdvisorEmail")},
            {id: "admissionAdvisorPhone", name: translate("tasks:studentData.admissionAdvisorPhone")}
        ]
    },
    {
        label: translate("tasks:studentData.finAid"),
        options: [
            {id: "finAidStatus", name: translate("tasks:studentData.financialAidStatus")},
            {id: "finAidAdvisor", name: translate("tasks:studentData.financialAdiAdvisor")},
            {id: "efc", name: translate("tasks:studentData.efc")},
            {id: "ISIR", name: translate("tasks:studentData.isirDate")},
            {id: "dependencyStatus", name: translate("tasks:studentData.dependencyStatus")},
            {id: "awardYear", name: translate("tasks:studentData.awardYear")},
            {id: "finAidAdvisorEmail", name: translate("tasks:studentData.finAidAdvisorEmail")},
            {id: "finAidAdvisorPhone", name: translate("tasks:studentData.finAidAdvisorPhone")}
        ]
    },
    {
        label: translate("tasks:studentData.academics"),
        options: [
            {id: "academicAdvisor", name: translate("tasks:studentData.academicAdvisor")},
            {id: "academicAdvisorEmail", name: translate("tasks:studentData.academicAdvisorEmail")},
            {id: "academicAdvisorPhone", name: translate("tasks:studentData.academicAdvisorPhone")}
        ]
    }
]
