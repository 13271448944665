/* eslint-disable react-hooks/exhaustive-deps */
import React, {createContext, useCallback, useContext, useState} from "react"
import {CommunicationContext} from "./CommunicationContext"

type ContextProps = {
    activePageId?: string
    setActivePageId: React.Dispatch<React.SetStateAction<string>>
    rootPageId: string
    setRootPageId: React.Dispatch<React.SetStateAction<string>>
    activeTabId?: string
    setActiveTabId: (_activeTabId: string) => void
    activeCampus?: string
    setActiveCampus: React.Dispatch<React.SetStateAction<string>>
    pageProps: any
    setPageProps: React.Dispatch<React.SetStateAction<any>>
    goBack: (_activeTabId?: string) => void
    goHome: (overwrite?: boolean) => void
    goTo: (pageId: string, pageProps?: any, overwrite?: boolean) => void
}

export const ChatRoomContext = createContext<ContextProps>({} as ContextProps)

type ChatRoomProviderProps = {
    children: React.ReactNode
}

export const ChatRoomProvider: any = ({children}: ChatRoomProviderProps) => {
    const [activePageId, setActivePageId] = useState<string>("")
    const [rootPageId, setRootPageId] = useState<string>("")
    const [pageProps, setPageProps] = useState<any>({})
    const [activeTabId, setActiveTabId] = useState<string>("campaigns")
    const [activeCampus, setActiveCampus] = useState<string>("all")

    const [history, setHistory] = useState<{pageId: string; pageProps: any}[]>([])
    const {updateLastState} = useContext(CommunicationContext)

    const goHome = useCallback(
        (overwrite: boolean = true) => {
            setActivePageId(rootPageId)
            setHistory([])
            if (overwrite) {
                updateLastState({activePageId: rootPageId})
            }
        },
        [rootPageId]
    )

    const goTo = useCallback(
        (pageId: string, pageProps?: any, overwrite: boolean = true) => {
            if (pageId !== rootPageId) {
                history.push({pageId, pageProps})
                setHistory(history)
            } else {
                setHistory([])
            }
            setActivePageId(pageId)
            setPageProps(pageProps)
            if (overwrite) {
                updateLastState({activePageId: pageId, pageProps})
            }
        },
        [history, rootPageId]
    )

    const goBack = useCallback(
        (_activeTabId?: string) => {
            if (history.length <= 1) {
                goHome()
                _activeTabId && setActiveTabId(_activeTabId)
                return
            }
            const page = history[history.length - 2]
            if (page && page.pageId) {
                setActivePageId(page.pageId)
                setPageProps(page.pageProps)
                setHistory((prev) => prev.slice(0, prev.length - 1))
                updateLastState({activePageId: page.pageId, pageProps: page.pageProps})
            }
        },
        [history, goHome]
    )

    const onChangeActiveTab = (_activeTabId: string) => {
        setActiveCampus("all")
        setActiveTabId(_activeTabId)
    }

    return (
        <ChatRoomContext.Provider
            value={{
                activePageId,
                setActivePageId,
                rootPageId,
                setRootPageId,
                activeTabId,
                setActiveTabId: onChangeActiveTab,
                activeCampus,
                setActiveCampus,
                pageProps,
                setPageProps,
                goBack,
                goHome,
                goTo
            }}>
            {children}
        </ChatRoomContext.Provider>
    )
}
