import React from "react"
import {BaseInput} from "components/inputs"
import {BaseButton, SecondaryButton} from "components/buttons"
import {userService} from "services"
import {handleError, convertSignatureTextToImageString, getBlobFromBase64String, loadImage, toastError} from "helpers"
import styles from "./TextInitialsEditForm.module.css"

type Props = {
    onSaveSuccessful: (data: any) => void
    onCancel: () => void
    userId: number
}

export class TextInitialsEditForm extends React.Component<Props, {}> {
    state = {
        isSubmitting: false,
        text: ""
    }

    onChangeText = (newValue) => {
        this.setState({text: newValue})
    }

    onClickSave = async () => {
        const {text} = this.state
        if (!text) {
            toastError("Your initials cannot empty")
            return
        }
        const {userId} = this.props
        const imageString = await convertSignatureTextToImageString(text)
        const file = await getBlobFromBase64String(imageString)
        try {
            this.setState({isSubmitting: true})
            const formData = new FormData()
            formData.append("file", file)
            if (userId) {
                formData.append("userId", userId.toString())
            }
            const {data} = await userService.updateCurrentInitials(formData)
            await loadImage(data, "imageUrl")
            this.setState({text: ""})
            this.props.onSaveSuccessful(data)
        } catch (error) {
            handleError(error)
        } finally {
            this.setState({isSubmitting: false})
        }
    }

    render() {
        const {text, isSubmitting} = this.state

        return (
            <div className={styles.wrap}>
                <div className={styles.inputWrap}>
                    <BaseInput value={text} onChange={this.onChangeText} />
                </div>
                <div className={styles.action}>
                    <SecondaryButton title="Cancel" onClick={this.props.onCancel} className={styles.cancelBtn} />
                    <div className={styles.btnWrap}>
                        <BaseButton title="Save" onClick={this.onClickSave} loading={isSubmitting} />
                    </div>
                </div>
            </div>
        )
    }
}
