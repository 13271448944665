/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef, useEffect, useReducer, useCallback, useState, useMemo} from "react"
import moment from "moment"
import {useTranslation} from "react-i18next"
import {useHistory} from "react-router-dom"
import {v4 as uuidV4} from "uuid"
import {get, keyBy, head, groupBy, uniqBy, orderBy, isEmpty} from "lodash"
import QuickPinchZoom, {make2dTransformValue, make3dTransformValue} from "react-quick-pinch-zoom"
import {BrowserView, MobileView, isSafari} from "react-device-detect"
import {Icon} from "components/Icon"
import {BaseButton, SecondaryButton} from "components/buttons"
import {BaseLoading} from "components/Loading"
import {CreatedInfo} from "components/ui"
import HTMLTitle from "components/misc/HTMLTitle"
import {handleError, loadImage, toastError, toastSuccess, hasTranslate3DSupport} from "helpers"
import {
    DigitalDocumentFieldSetUp,
    DefaultDataType,
    TaskType,
    ParticipantType,
    InitialType,
    SignatureMethod
} from "types/tasks"
import {SignaturePopup, InitialsPopup} from "uiKit/popup"
import {useVisible} from "hooks"
import {taskService, userService} from "services"
import {RejectTaskPopup} from "../RejectTaskPopup"
import {FieldItem} from "./FieldItem"
import styles from "./TaskSubmission.module.css"
import {Iframe} from "components/Iframe"
import {stringifyUrl} from "query-string"
import cx from "classnames"
import {Closing} from "sections/Closing"

const makeTransformValue = hasTranslate3DSupport() && !isSafari ? make3dTransformValue : make2dTransformValue

function reducer(state, action) {
    return {...state, ...action}
}

const sendIframeObject = {
    type: "",
    data: {}
}

const DISABLE_BUTTON_FOR_WEB_FORMS = ["asher", "asher_enrollment_transfer", "asher_main_proposal_form"]

export function TaskSubmission(props) {
    const {
        taskData,
        user,
        subTaskStatus,
        subTaskAnswers,
        taskUserId,
        taskUserEncodedSecurityLevelWeb,
        taskUsers,
        canSign,
        currentSignature,
        currentInitials,
        subTaskId,
        signerId,
        onSetNewSignature,
        onSetNewInitials
    } = props
    const pageRef = useRef(null)
    const pagesRef = useRef(null)
    const iframeRef = useRef<HTMLIFrameElement>()
    const fieldRefs = useRef<Record<string, HTMLDivElement>>({})
    const [remarksData, setRemarksData] = useState(null)
    const initialState = {
        fields: [],
        studentDataValues: [],
        isLoading: false,
        isSubmitting: false,
        isLoadingIframe: true,
        webFormsUrl: `${process.env.REACT_APP_WEBFORMS_BASE_URL}/sign`,
        webFormSignatureField: "",
        webFormInitialsField: "",
        showSubmitButton: false,
        disableSubmitButton: DISABLE_BUTTON_FOR_WEB_FORMS.includes(taskData.form?.code)
    }
    const [
        {
            isLoading,
            fields,
            studentDataValues,
            isSubmitting,
            isLoadingIframe,
            webFormsUrl,
            webFormSignatureField,
            webFormInitialsField,
            showSubmitButton,
            disableSubmitButton
        },
        dispatch
    ] = useReducer(reducer, initialState)
    const [tempField, setTempField] = useState(null)
    const [errorFieldIds, setErrorFieldIds] = useState([])
    const signaturePopup = useVisible(false)
    const initialsPopup = useVisible(false)
    const rejectTaskPopup = useVisible(false)
    const {t} = useTranslation(["user", "tasks"])
    const history = useHistory()
    const {model} = props
    let url = `${webFormsUrl}/${taskUserId}/?token=${model.authToken}&profileId=${model.profileId}`
    if (model.isSubmittingTaskFromEmail) {
        url = url + `&sl=${model.securityLevelEncoded}`
    } else {
        url = url + `&sl=${taskUserEncodedSecurityLevelWeb}`
    }
    const dateFormat = model.getUserDateFormat()
    const isWebForm = taskData.type === TaskType.FORM
    const isCustomTask = taskData.type === TaskType.CUSTOM
    const margin = 10

    const signatureFields = useMemo(() => {
        return fields.filter((field) => field.type === DigitalDocumentFieldSetUp.SIGNATURE)
    }, [fields])

    const isShowSignSignature = useMemo(() => {
        if (isEmpty(signatureFields)) {
            return true
        }
        return signatureFields.some(
            (field) =>
                !field.signatureMethod ||
                field.signatureMethod === SignatureMethod.HAND ||
                field.signatureMethod === SignatureMethod.BOTH
        )
    }, [signatureFields])

    const isShowTextSignature = useMemo(() => {
        if (isEmpty(signatureFields)) {
            return true
        }
        return signatureFields.some(
            (field) =>
                !field.signatureMethod ||
                field.signatureMethod === SignatureMethod.KEYBOARD ||
                field.signatureMethod === SignatureMethod.BOTH
        )
    }, [signatureFields])

    useEffect(() => {
        if (!isWebForm && !isCustomTask) {
            initFieldData()
        }
    }, [])

    useEffect(() => {
        if (isWebForm) {
            const handler = (e) => {
                try {
                    const data = JSON.parse(e.data)
                    if (data?.type === "field" && data?.data.field === "signature") {
                        updateSignature(data.data.url)
                    }
                    if (data?.type === "field" && data?.data.field === "initials") {
                        updateInitials(data.data.url)
                    }
                    if (!data?.error && data.message === "submit") {
                        const url = stringifyUrl(
                            {
                                url: `/tasks/detail`,
                                query: {
                                    id: taskData.id,
                                    type: taskData.type
                                }
                            },
                            {skipNull: true}
                        )
                        history.push(url)
                    }
                    if (data?.type === "action" && data?.message === "create_signature") {
                        signaturePopup.open()
                        dispatch({webFormSignatureField: data?.field})
                    }
                    if (data?.type === "action" && data?.message === "create_initials") {
                        initialsPopup.open()
                        dispatch({webFormInitialsField: data?.field})
                    }
                    if (data?.type === "action" && data?.message === "show_submit_button") {
                        dispatch({showSubmitButton: true})
                    }
                    if (data?.type === "action" && data?.message === "end_submitting") {
                        dispatch({isSubmitting: false})
                    }
                    if (data?.type === "action" && data?.message === "enable_submit_button") {
                        dispatch({disableSubmitButton: false})
                    }
                } catch (e) {
                    return () => e
                }
            }
            window.addEventListener("message", handler)
            return () => window.removeEventListener("message", handler)
        }
        if (isCustomTask) {
            const taskUsersKeyByUserId = keyBy(taskUsers, "userId")
            const customTaskUrl = get(taskUsersKeyByUserId, [user.id, "customSignUrl"], "")
            if (customTaskUrl) {
                const newCustomTaskUrl = new URL(customTaskUrl)
                newCustomTaskUrl.searchParams.append("sl", taskUserEncodedSecurityLevelWeb)
                window.open(newCustomTaskUrl.toString())
                onClickCancel()
            } else {
                toastError("Sign url not found for this custom task.")
                onClickCancel()
            }
        }
    }, [])

    const initSignature = (
        field,
        {placeInformation, signatureType, newFieldValues, scaleHeight, scaleWidth, parentId, extraInfo, pageHeight}
    ) => {
        if (placeInformation && placeInformation["dateField"]) {
            const dateFieldPlaceInfo = scalePlaceInformation(
                placeInformation["dateField"],
                {scaleHeight, scaleWidth},
                false
            )
            dateFieldPlaceInfo.top = dateFieldPlaceInfo.top + (field.page - 1) * (pageHeight + margin)
            newFieldValues.push({
                id: uuidV4(),
                parentId,
                type: DigitalDocumentFieldSetUp.DATE_FIELD,
                signatureType,
                placeInformation: dateFieldPlaceInfo,
                ...extraInfo
            })
        }
    }

    const updateSignature = async (trimmedDataURL) => {
        dispatch({isLoading: true})
        try {
            const {data} = await userService.updateCurrentSignatureUsingBase64Image(trimmedDataURL)
            onSetNewSignature(data)
            toastSuccess(t("personal.signature.saveSignature"))
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const updateInitials = async (trimmedDataURL) => {
        dispatch({isLoading: true})
        try {
            const {data} = await userService.updateCurrentInitialsUsingBase64Image(trimmedDataURL)
            onSetNewInitials(data)
            toastSuccess(t("personal.signature.saveInitials"))
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const sendSignatureToIframe = async () => {
        sendIframeObject.type = "signature"
        let base64Img = ""
        if (currentSignature?.imageUrl) {
            try {
                const images = await userService.getBase64Img([currentSignature.imageUrl])
                base64Img = get(images, "[0].base64", "")
            } catch (error) {
                handleError(error)
            }
        }
        sendIframeObject.data = {
            field: webFormSignatureField,
            url: base64Img
        }
        iframeRef?.current?.contentWindow.postMessage(sendIframeObject, "*")
    }

    const sendInitialsToIframe = async () => {
        sendIframeObject.type = "initials"
        let base64Img = ""
        if (currentInitials?.imageUrl) {
            try {
                const images = await userService.getBase64Img([currentInitials.imageUrl])
                base64Img = get(images, "[0].base64", "")
            } catch (error) {
                handleError(error)
            }
        }
        sendIframeObject.data = {
            field: webFormInitialsField,
            url: base64Img
        }
        iframeRef?.current?.contentWindow.postMessage(sendIframeObject, "*")
    }

    useEffect(() => {
        if (isWebForm && currentSignature) {
            sendSignatureToIframe()
        }
    }, [currentSignature])

    useEffect(() => {
        if (isWebForm && currentInitials) {
            sendInitialsToIframe()
        }
    }, [currentInitials])

    useEffect(() => {
        if (isWebForm) {
            const taskUser = taskUsers.find((item) => item.id === taskUserId)
            setRemarksData({remarkedReason: taskUser?.remarkedReason})
        }
    }, [taskUsers])

    const initFieldData = async () => {
        try {
            dispatch({isLoading: true})
            await Promise.all(taskData.digitalDocument.pages.map((image) => loadImage(image)))
            const usersData = await getStudentData()
            const usersDataKeyByParticipantId = keyBy(usersData, "participantId")
            const {scaleHeight, scaleWidth, pageHeight} = getScaleInfo()
            const newFieldValues = []
            const taskUsersKeyBySignerId = keyBy(taskUsers, "signerId")
            const taskUser = taskUsers.find((item) => item.id === taskUserId)
            const remarksByFieldId = keyBy(taskUser?.remarks, "field")
            let oldAnswer = null
            if (taskUser) {
                oldAnswer = taskUser.answers
                setRemarksData({remarkedReason: taskUser?.remarkedReason, remarks: taskUser.remarks})
            }
            const newFields = taskData.digitalDocument.fields
                .map((field) => {
                    const placeInformation = field.placeInformation[0]
                    const fieldValues = field.fieldValues
                    field.required = !!field.required
                    const {signatureType} = field
                    const isYourField = (field.signerIds || []).includes(signerId)
                    const firstSignerId: any = head(field.signerIds)
                    const backgroundColor = get(taskUsersKeyBySignerId, [firstSignerId, "backgroundColor"], "")
                    if (placeInformation) {
                        const {height, left, top, width} = placeInformation
                        field.placeInformation = scalePlaceInformation(
                            {height, left, top, width},
                            {scaleHeight, scaleWidth},
                            false
                        )
                        field.placeInformation.top =
                            field.placeInformation.top + (field.page - 1) * (pageHeight + margin)
                    }
                    switch (field.type) {
                        case DigitalDocumentFieldSetUp.SIGNATURE: {
                            let extraInfo: any = {
                                isYourField
                            }
                            if (!canSign || !isYourField) {
                                const answer = get(subTaskAnswers, [field.id], "")
                                const hasSigned = answer === "signed"
                                field.isSigned = !!answer
                                field.value = hasSigned
                                    ? get(taskUsersKeyBySignerId, [firstSignerId, "signature"], "")
                                    : null
                                field.backgroundColor = backgroundColor
                                extraInfo = {
                                    isSigned: true,
                                    backgroundColor,
                                    date: hasSigned
                                        ? get(taskUsersKeyBySignerId, [firstSignerId, "submittedAt"], "")
                                        : null
                                }
                            } else if (oldAnswer) {
                                field.isSigned = true
                                field.value = get(oldAnswer, [field.id]) === "signed" ? taskUser.signature : null
                            }
                            initSignature(field, {
                                placeInformation,
                                signatureType,
                                newFieldValues,
                                scaleHeight,
                                scaleWidth,
                                parentId: field.id,
                                extraInfo,
                                pageHeight
                            })
                            break
                        }
                        case DigitalDocumentFieldSetUp.INITIAL: {
                            if (fieldValues) {
                                let answer = get(subTaskAnswers, [field.id], "")
                                answer = (answer || "").split(",")
                                const oldInitialAnswer =
                                    oldAnswer && oldAnswer[field.id] ? oldAnswer[field.id].split(",") : null
                                const initialFieldValues = fieldValues.map((fieldValue, index) => {
                                    const {height, left, top, width} = fieldValue.position
                                    const fieldValuePlaceInformation = scalePlaceInformation(
                                        {height, left, top, width},
                                        {scaleHeight, scaleWidth},
                                        false
                                    )
                                    const childFieldPage = fieldValue.page || field.page
                                    fieldValuePlaceInformation.top =
                                        fieldValuePlaceInformation.top + (childFieldPage - 1) * (pageHeight + margin)
                                    if (!canSign || !isYourField) {
                                        const hasSigned = answer[index] === "signed"
                                        return {
                                            id: fieldValue.id,
                                            type: DigitalDocumentFieldSetUp.INITIAL,
                                            parentId: field.id,
                                            signatureType,
                                            initialType: field.initialType,
                                            isSigned: true,
                                            value: hasSigned
                                                ? get(taskUsersKeyBySignerId, [firstSignerId, "initials"], "")
                                                : null,
                                            backgroundColor: backgroundColor,
                                            placeInformation: fieldValuePlaceInformation,
                                            isYourField
                                        }
                                    }
                                    const initialData: any = {
                                        id: fieldValue.id,
                                        type: DigitalDocumentFieldSetUp.INITIAL,
                                        parentId: field.id,
                                        signatureType,
                                        initialType: field.initialType,
                                        label: fieldValue.label,
                                        placeInformation: fieldValuePlaceInformation,
                                        required: !!field.required,
                                        isSigned: !isEmpty(answer),
                                        value: !isEmpty(answer) ? answer[index] === "signed" : undefined,
                                        isYourField
                                    }
                                    if (oldInitialAnswer) {
                                        initialData.isSigned = true
                                        initialData.value = answer[index] === "signed" ? taskUser.initials : null
                                        initialData.remarkComment =
                                            index === 0 ? get(remarksByFieldId, [field.id, "reason"]) : null
                                    }
                                    return initialData
                                })
                                newFieldValues.splice(newFieldValues.length, 0, ...initialFieldValues)
                            }
                            break
                        }
                        case DigitalDocumentFieldSetUp.CHECKBOX: {
                            if (fieldValues) {
                                const oldCheckboxAnswer =
                                    oldAnswer && oldAnswer[field.id] ? oldAnswer[field.id].split(",") : null
                                let answer = get(subTaskAnswers, [field.id], "")
                                answer = (answer || "").split(",")
                                const checkboxFieldValues = fieldValues.map((fieldValue, index) => {
                                    const {height, left, top, width} = fieldValue.position
                                    const fieldValuePlaceInformation = scalePlaceInformation(
                                        {height, left, top, width},
                                        {scaleHeight, scaleWidth},
                                        false
                                    )
                                    const childFieldPage = fieldValue.page || field.page
                                    fieldValuePlaceInformation.top =
                                        fieldValuePlaceInformation.top + (childFieldPage - 1) * (pageHeight + margin)
                                    if (!canSign || !isYourField) {
                                        const isChecked = answer[index] === "Yes"
                                        return {
                                            id: fieldValue.id,
                                            type: DigitalDocumentFieldSetUp.CHECKBOX,
                                            parentId: field.id,
                                            signatureType,
                                            checkboxType: field.checkboxType,
                                            isSigned: true,
                                            value: isChecked,
                                            backgroundColor: backgroundColor,
                                            placeInformation: fieldValuePlaceInformation,
                                            isYourField
                                        }
                                    }
                                    const checkboxData: any = {
                                        id: fieldValue.id,
                                        type: DigitalDocumentFieldSetUp.CHECKBOX,
                                        parentId: field.id,
                                        signatureType,
                                        checkboxType: field.checkboxType,
                                        label: fieldValue.label,
                                        placeInformation: fieldValuePlaceInformation,
                                        required: !!field.required,
                                        isSigned: !isEmpty(answer),
                                        value: !isEmpty(answer) ? answer[index] === "Yes" : undefined,
                                        isYourField
                                    }
                                    if (oldCheckboxAnswer) {
                                        checkboxData.isSigned = true
                                        checkboxData.value = oldCheckboxAnswer[index] === "Yes"
                                        checkboxData.remarkComment =
                                            index === 0 ? get(remarksByFieldId, [field.id, "reason"]) : null
                                    }
                                    return checkboxData
                                })
                                newFieldValues.splice(newFieldValues.length, 0, ...checkboxFieldValues)
                            }
                            break
                        }
                        case DigitalDocumentFieldSetUp.TEXT: {
                            const isAutocompletedField =
                                field.defaultDataType === DefaultDataType.STUDENT_DATA && field.defaultData
                            field.isAutocompletedField = isAutocompletedField
                            const answer = get(subTaskAnswers, [field.id], "")
                            if (!canSign || !isYourField) {
                                field.readOnly = true
                                field.isSigned = true
                                if (field.defaultDataType === DefaultDataType.STUDENT_DATA) {
                                    field.value = get(
                                        usersDataKeyByParticipantId,
                                        [field.defaultParticipant, field.defaultData],
                                        ""
                                    )
                                } else {
                                    field.value = answer
                                }
                                field.backgroundColor = backgroundColor
                            } else if (oldAnswer) {
                                field.isSigned = true
                                field.value = get(oldAnswer, [field.id])
                            } else if (field.defaultDataType === DefaultDataType.STUDENT_DATA) {
                                studentDataValues.push(field.defaultData)
                                field.isSigned = true
                                field.value = get(
                                    usersDataKeyByParticipantId,
                                    [field.defaultParticipant, field.defaultData],
                                    ""
                                )
                            } else if (answer) {
                                field.isSigned = true
                                field.value = answer
                            }
                            break
                        }
                        default:
                            break
                    }
                    field.isYourField = isYourField
                    field.remarkComment = get(remarksByFieldId, [field.id, "reason"])
                    return field
                })
                .filter((field) => {
                    if (field.isAutocompletedField) {
                        return true
                    }
                    if (!field.isYourField) {
                        return field.value
                    }
                    return (
                        field.type !== DigitalDocumentFieldSetUp.INITIAL &&
                        field.type !== DigitalDocumentFieldSetUp.CHECKBOX
                    )
                })
            const allFields = [...newFields, ...newFieldValues]
            const textFields = allFields.filter((field) => field.type === DigitalDocumentFieldSetUp.TEXT)
            const restFields = allFields.filter((field) => field.type !== DigitalDocumentFieldSetUp.TEXT)
            const sortedTextFields = orderBy(textFields, ["placeInformation.top", "placeInformation.left"])
            const sortedFields = [...restFields, ...sortedTextFields]
            dispatch({fields: sortedFields, studentDataValues})
        } catch (error) {
            console.log("error", error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const getStudentData = async () => {
        try {
            const signersKeyById = keyBy(taskData.digitalDocument.signers, "id")
            const taskUsersKeyBySignerId = keyBy(taskUsers, "signerId")
            const taskUserDataOnlyKeyBySubTaskId = keyBy(taskData.digitalDocument.taskUserDataOnly || [], "subtaskId")
            let userParticipants = taskData.digitalDocument.fields
                .filter((field) => field.type === DigitalDocumentFieldSetUp.TEXT && field.defaultParticipant)
                .map((field) => {
                    const participantType = get(signersKeyById, [+field.defaultParticipant, "participantType"], "")
                    let userId = ""
                    let profileId = ""
                    if (participantType === ParticipantType.UserDataOnly) {
                        userId = get(taskUserDataOnlyKeyBySubTaskId, [subTaskId, "userId"], "")
                        profileId = get(taskUserDataOnlyKeyBySubTaskId, [subTaskId, "profileId"], "")
                    } else {
                        profileId = get(taskUsersKeyBySignerId, [+field.defaultParticipant, "profileId"], "")
                        userId = get(taskUsersKeyBySignerId, [+field.defaultParticipant, "userId"], "")
                    }
                    return {
                        userId,
                        participantId: field.defaultParticipant,
                        profileId
                    }
                })
                .filter(({userId}) => userId)

            userParticipants = uniqBy(userParticipants, "userId")
            const profileIds = userParticipants.map(({profileId}) => profileId)
            if (isEmpty(profileIds)) {
                return []
            }
            const data = await userService.getStudentData({
                filter: {
                    profileId: profileIds
                }
            })
            const userParticipantByProfileId = keyBy(userParticipants, "profileId")
            const result = data.map((item) => {
                item.participantId = get(userParticipantByProfileId[item.profileId], "participantId")
                return item
            })
            return result
        } catch (error) {
            handleError(error)
        }
    }

    const scalePlaceInformation = (placeInformation, {scaleHeight, scaleWidth}, isSubmit = true) => {
        const {top, left, width, height} = placeInformation
        if (isSubmit) {
            return {
                width: width * scaleWidth,
                height: height * scaleHeight,
                top: top * scaleHeight,
                left: left * scaleWidth
            }
        }
        return {
            width: width / scaleWidth,
            height: height / scaleHeight,
            top: top / scaleHeight,
            left: left / scaleWidth
        }
    }

    const getScaleInfo = () => {
        const pageHeight = pageRef.current?.offsetHeight
        const pageWidth = pageRef.current?.offsetWidth
        const {width = pageWidth, height = pageHeight} = taskData.digitalDocument.pages?.[0] || {}
        const scaleHeight = height / pageHeight
        const scaleWidth = width / pageWidth
        return {scaleHeight, scaleWidth, pageHeight}
    }

    const onChangeField = (fieldId, newValue) => {
        const newFields = fields.map((field) => {
            if (field.id === fieldId) {
                return {...field, ...newValue}
            }
            if (
                field.parentId === fieldId &&
                field.type === DigitalDocumentFieldSetUp.DATE_FIELD &&
                newValue.isSignature
            ) {
                return {
                    ...field,
                    isSigned: newValue.isSigned,
                    date: newValue.isSigned ? moment().format(dateFormat) : null
                }
            }
            return field
        })
        dispatch({fields: newFields})
    }

    const onChangeSingleCheckboxField = ({fieldId, parentId}, newValue) => {
        const newFields = fields.map((field) => {
            if (field.id === fieldId) {
                return {...field, ...newValue}
            }
            if (parentId) {
                if (field.id === parentId || field.parentId === parentId) {
                    return {...field, ...newValue, value: false}
                }
            } else if (field.parentId === fieldId) {
                return {...field, ...newValue, value: false}
            }
            return field
        })
        dispatch({fields: newFields})
    }

    const onChangeSingleInitialsField = ({fieldId, parentId}, newValue) => {
        const newFields = fields.map((field) => {
            if (field.id === fieldId) {
                return {...field, ...newValue}
            }
            if (parentId) {
                if (field.id === parentId || field.parentId === parentId) {
                    return {...field, ...newValue, value: null}
                }
            } else if (field.parentId === fieldId) {
                return {...field, ...newValue, value: null}
            }
            return field
        })
        dispatch({fields: newFields})
    }

    const onClickCancel = (text = `The task was not answered`) => {
        if (model.isSubmittingTaskFromEmail) {
            history.push(`/closing`, {
                text,
                closeSessionStorage: true,
                closeLocalStorage: false
            })
        } else {
            history.push(`/tasks/detail?type=${taskData.type}&id=${taskData.id}`)
        }
    }

    const onClickRejectTask = () => {
        rejectTaskPopup.open()
    }

    const onCloseRejectTask = () => {
        rejectTaskPopup.close()
        if (model.isSubmittingTaskFromEmail) {
            history.push(`/closing`, {
                closeSessionStorage: true,
                closeLocalStorage: false
            })
        }
    }

    const validateFieldBeforeSubmit = () => {
        const fieldsGroupByParentId = groupBy(fields, "parentId")
        const errorFields = fields.filter((field) => {
            if (
                (field.type === DigitalDocumentFieldSetUp.CHECKBOX ||
                    field.type === DigitalDocumentFieldSetUp.INITIAL) &&
                field.isYourField &&
                field.required
            ) {
                const hasSigned = (fieldsGroupByParentId[field.parentId] || []).some(
                    (fieldItem) => fieldItem.isSigned && fieldItem.value
                )
                return !hasSigned
            }
            return field.isYourField && field.required && !field.value
        })
        const errorFieldIds: string[] = orderBy(errorFields, ["placeInformation.top", "placeInformation.left"]).map(
            (field) => field.id
        )
        setErrorFieldIds(errorFieldIds)
        if (errorFieldIds.length) {
            const firstErrorField = fieldRefs.current[head(errorFieldIds)]
            firstErrorField?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
            toastError("There are required fields need to be filled")
            return true
        }
        return false
    }

    const onClickSubmit = async () => {
        if (isLoading) {
            return
        }
        const hasError = validateFieldBeforeSubmit()
        if (hasError) {
            return
        }
        try {
            dispatch({isSubmitting: true})
            let answers: any = {}
            let hasSignature = false
            let hasInitials = false
            fields
                .filter((field) => field.isYourField)
                .forEach((field) => {
                    switch (field.type) {
                        case DigitalDocumentFieldSetUp.SIGNATURE:
                            if (!hasSignature && field.value) {
                                hasSignature = true
                            }
                            answers[field.id] = field.value ? "signed" : "not-signed"
                            break
                        case DigitalDocumentFieldSetUp.INITIAL:
                            if (field.parentId) {
                                answers[field.parentId] = answers[field.parentId] || ""
                                if (!answers[field.parentId]) {
                                    if (!hasInitials && field.value) {
                                        hasInitials = true
                                    }
                                    answers[field.parentId] += field.value ? "signed" : "not-signed"
                                } else {
                                    if (!hasInitials && field.value) {
                                        hasInitials = true
                                    }
                                    answers[field.parentId] += field.value ? ",signed" : ",not-signed"
                                }
                            }
                            break
                        case DigitalDocumentFieldSetUp.CHECKBOX:
                            if (field.parentId) {
                                answers[field.parentId] = answers[field.parentId] || ""
                                if (!answers[field.parentId]) {
                                    answers[field.parentId] = field.value ? "Yes" : "No"
                                } else {
                                    answers[field.parentId] += field.value ? ",Yes" : ",No"
                                }
                            }
                            break
                        case DigitalDocumentFieldSetUp.TEXT:
                            answers[field.id] = field.value || ""
                            if (field.dataType === "date" && field.value) {
                                answers[field.id] = moment(field.value).format(dateFormat)
                            }
                            break
                        default:
                            break
                    }
                })
            if (hasSignature) {
                try {
                    const images = await userService.getBase64Img([currentSignature.imageUrl])
                    answers.signature = get(images, "[0].base64", "")
                } catch (error) {
                    handleError(error)
                }
            }
            if (hasInitials) {
                try {
                    const images = await userService.getBase64Img([currentInitials.imageUrl])
                    answers.initials = get(images, "[0].base64", "")
                } catch (error) {
                    handleError(error)
                }
            }
            if ((hasSignature && !answers.signature) || (hasInitials && !answers.initials)) {
                return
            }
            try {
                let params = `sl=${taskUserEncodedSecurityLevelWeb}`
                if (model.isSubmittingTaskFromEmail) {
                    params = `sl=${model.securityLevelEncoded}`
                }
                await taskService.answerTask({taskUserId, answers}, params)
                toastSuccess("Submit successful", "", {duration: 1})
                setTimeout(() => {
                    onClickCancel("The task has been submitted successfully.")
                }, 1800)
            } catch (error) {
                handleError(error)
            }
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isSubmitting: false})
        }
    }

    const renderTaskHeader = () => {
        return (
            <div
                className={cx(styles.headerWrap, {
                    [styles.headerWrapFromEmail]: model.isSubmittingTaskFromEmail,
                    [styles.webFormHeader]: isWebForm
                })}>
                {!model.isSubmittingTaskFromEmail && (
                    <div className={styles.backBtn}>
                        <div className={styles.backBtnWrap} onClick={() => onClickCancel()}>
                            <Icon icon="BACK" className={styles.backIcon} color="#ff349b" />
                        </div>
                    </div>
                )}
                <div className={styles.headerTitleWrap}>
                    <p className={styles.headerTitle}>{taskData.name}</p>
                    <p className={styles.headerTaskType}>{isWebForm ? "Web Form" : "Digital document task"}</p>
                </div>
                <p className={styles.taskCode}>{taskData.code}</p>
            </div>
        )
    }

    const renderTaskInfo = () => {
        const sendIframe = (e) => {
            sendIframeObject.type = "action"
            sendIframeObject.data = "submit"
            if (isWebForm) {
                iframeRef.current.contentWindow.postMessage(sendIframeObject, "*")
                dispatch({isSubmitting: true})
            }
        }

        const renderSubmitButton = () => {
            if (isWebForm && taskData.form.code === "nau_isir_corrections_2023" && !showSubmitButton) {
                return
            }
            return (
                <BaseButton
                    title="Submit"
                    onClick={isWebForm ? sendIframe : onClickSubmit}
                    loading={isSubmitting}
                    className={styles.taskInfoActionItem}
                    isActive={!isLoading}
                    disabled={disableSubmitButton}
                />
            )
        }

        return (
            <div
                className={cx(styles.taskInfoWrap, {
                    [styles.taskInfoWrapFromEmail]: model.isSubmittingTaskFromEmail,
                    [styles.webFormHeader]: isWebForm
                })}>
                <div className={styles.taskInfo}>
                    <div className={styles.taskInfoManage}>
                        <p className={styles.taskInfoManage__title}>Manage</p>
                        <div className={styles.taskInfoManageAction}>
                            <div className={styles.taskInfoManageAction__item} onClick={() => signaturePopup.open()}>
                                <Icon icon="SIGN_HAND_DRAWN" color="#666" />
                                <p className={styles.taskInfoManageAction__item__text}>SIGNATURE</p>
                            </div>
                            <div className={styles.taskInfoManageAction__item} onClick={() => initialsPopup.open()}>
                                <Icon icon="INITIALS" color="#666" />
                                <p className={styles.taskInfoManageAction__item__text}>INITIALS</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.taskInfoDetail}>
                        <div className={styles.taskInfoDetailHeader}>
                            <div className={styles.taskInfoDetailHeaderItem}>
                                <p className={styles.taskInfoDetailHeaderItem__text}>Requested</p>
                                <p className={styles.taskInfoDetailHeaderItem__value}>
                                    {moment(taskData.createdAt).format(dateFormat)}
                                </p>
                            </div>
                            <div className={styles.taskInfoDetailHeaderItem}>
                                <p className={styles.taskInfoDetailHeaderItem__text}>{t("tasks:tasks.dueDate")}</p>
                                <p className={styles.taskInfoDetailHeaderItem__value}>
                                    {moment(taskData.dueDate).format(dateFormat)}
                                </p>
                            </div>
                            <div className={styles.taskInfoDetailHeaderItem}>
                                <p className={styles.taskInfoDetailHeaderItem__text}>Status</p>
                                <p className={styles.taskInfoDetailHeaderItem__value}>
                                    {t(`tasks:tasks.${subTaskStatus}`)}
                                </p>
                            </div>
                            {canSign && (
                                <div className={styles.taskInfoAction}>
                                    <SecondaryButton
                                        title="Cancel"
                                        className={styles.taskInfoActionItem}
                                        onClick={() => onClickCancel()}
                                        loading={isSubmitting}
                                    />
                                    <BaseButton
                                        title="Reject"
                                        className={styles.taskInfoActionItem}
                                        onClick={onClickRejectTask}
                                        isActive={!isLoading}
                                        loading={isSubmitting}
                                    />
                                    {renderSubmitButton()}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <CreatedInfo data={taskData} userInfoKey="createdByUser" />
                {remarksData?.remarkedReason && (
                    <p className={styles.reopenText}>
                        Reopen Reason: <strong className={styles.reasonContent}>{remarksData?.remarkedReason}</strong>
                    </p>
                )}
            </div>
        )
    }

    const onLoadIframe = async () => {
        const promises = []
        if (currentSignature) {
            promises.push(await sendSignatureToIframe())
        }
        if (currentInitials) {
            promises.push(await sendInitialsToIframe())
        }
        await Promise.all(promises)
        dispatch({isLoadingIframe: false})
    }

    const onZoomUpdate = useCallback(({x, y, scale}) => {
        if (pagesRef.current) {
            const value = makeTransformValue({x, y, scale})
            pagesRef.current.style.setProperty("transform", value)
        }
    }, [])

    const onClickSignatureFieldWithoutData = (field) => {
        setTempField(field)
        signaturePopup.open()
    }

    const onSaveNewSignature = (newSignature) => {
        if (tempField) {
            onChangeField(tempField.id, {
                isSigned: true,
                value: newSignature.imageUrl,
                isSignature: true
            })
            setTempField(null)
        }
        onSetNewSignature(newSignature)
    }

    const onClickInitialFieldWithoutData = (field) => {
        setTempField(field)
        initialsPopup.open()
    }

    const onSaveNewInitials = (newInitials) => {
        if (tempField) {
            const value = newInitials.imageUrl
            if (tempField.initialType === InitialType.MULTIPLE) {
                onChangeField(tempField.id, {isSigned: true, value})
            } else {
                onChangeSingleInitialsField(
                    {fieldId: tempField.id, parentId: tempField.parentId},
                    {isSigned: true, value}
                )
            }
            setTempField(null)
        }
        onSetNewInitials(newInitials)
    }

    const renderContent = () => {
        return (
            <div className={styles.pageWrap} ref={pagesRef}>
                {(taskData.digitalDocument.pages || []).map((page, index) => (
                    <img
                        ref={pageRef}
                        key={index}
                        className={cx(styles.pageImg, {
                            [styles.lastPage]: index === taskData.digitalDocument.pages.length - 1
                        })}
                        src={page.url}
                        style={{maxWidth: window.innerWidth}}
                        alt=""
                    />
                ))}
                {fields.map((field, index) => (
                    <FieldItem
                        key={index}
                        field={field}
                        currentSignature={currentSignature}
                        currentInitials={currentInitials}
                        errorFieldIds={errorFieldIds}
                        fieldRefs={fieldRefs}
                        openSignaturePopup={onClickSignatureFieldWithoutData}
                        openInitialPopup={onClickInitialFieldWithoutData}
                        canSign={canSign}
                        onChangeField={onChangeField}
                        onChangeSingleCheckboxField={onChangeSingleCheckboxField}
                        onChangeSingleInitialsField={onChangeSingleInitialsField}
                    />
                ))}
            </div>
        )
    }

    return (
        <div className={styles.wrap}>
            <HTMLTitle title={t("tasks:tasks.taskSubmissions")} />
            <div className={styles.headerContainer}>
                <BaseLoading isShow={isLoading} />
                <div style={{height: 12}}></div>
                {renderTaskHeader()}
                {renderTaskInfo()}
            </div>

            {isWebForm ? (
                taskUserId ? (
                    <>
                        <Iframe
                            id="web_forms"
                            className={styles.iframeWebForm}
                            iframe_url={url}
                            title={t("tasks:taskDetail.type.form")}
                            onLoad={onLoadIframe}
                            iframeRef={iframeRef}
                        />
                        <BaseLoading isShow={isLoadingIframe} />
                    </>
                ) : (
                    <Closing model={model} />
                )
            ) : (
                !isCustomTask && (
                    <div className={styles.body}>
                        <BrowserView>{renderContent()}</BrowserView>
                        <MobileView>
                            <QuickPinchZoom
                                minZoom={1}
                                onUpdate={onZoomUpdate}
                                containerProps={{style: {touchAction: "auto"}}}
                                draggableUnZoomed={false}>
                                {renderContent()}
                            </QuickPinchZoom>
                        </MobileView>
                    </div>
                )
            )}
            {signaturePopup.isVisible && (
                <SignaturePopup
                    isShow={signaturePopup.isVisible}
                    isShowSign={isShowSignSignature}
                    isShowText={isShowTextSignature}
                    onClose={() => signaturePopup.close()}
                    title={t("personal.signature.signature")}
                    onSetNewSignature={onSaveNewSignature}
                    currentSignatureLoaded={currentSignature}
                    isAnsweringATask
                />
            )}
            {initialsPopup.isVisible && (
                <InitialsPopup
                    isShow={initialsPopup.isVisible}
                    onClose={() => initialsPopup.close()}
                    title={t("personal.initials.initials")}
                    onSetNewInitials={onSaveNewInitials}
                    currentInitialLoaded={currentInitials}
                    isAnsweringATask
                />
            )}
            <RejectTaskPopup
                isShow={rejectTaskPopup.isVisible}
                onClose={onCloseRejectTask}
                taskUserIds={[taskUserId]}
                taskData={taskData}
                subTaskId={subTaskId}
            />
        </div>
    )
}
