import {jsPDF} from "jspdf"
import autoTable, {Styles} from "jspdf-autotable"

export type ExportExcelPayload = {
    worksheet?: string
    filename: string
    columns: string[]
    rows: string[][]
}

export type ExportCsvPayload = {
    filename: string
    columns: string[]
    headerColumns?: string[]
    headerRows?: string[]
    rows: string[][]
}

export type ExportMultiTableCsvPayload = {
    filename: string
    listTable: Array<{
        columns: string[]
        rows: string[][]
    }>
    headerColumns?: string[]
    headerRows?: string[]
}

export type ExportPdfPayload = {
    filename: string
    columns: string[]
    rows: string[][]
    styles?: Partial<Styles>
}

const createExcelDocumentMarkup = (worksheet: string, content: string) => `
  <html
    xmlns:o="urn:schemas-microsoft-com:office:office"
    xmlns:x="urn:schemas-microsoft-com:office:excel"
    xmlns="http://www.w3.org/TR/REC-html40"
  >
  <head>
    <!--[if gte mso 9]>
      <xml>
        <x:ExcelWorkbook>
          <x:ExcelWorksheets>
            <x:ExcelWorksheet>
              <x:Name>${worksheet}</x:Name>
              <x:WorksheetOptions>
                <x:DisplayGridlines/>
              </x:WorksheetOptions>
            </x:ExcelWorksheet>
          </x:ExcelWorksheets>
          </x:ExcelWorkbook>
      </xml>
    <![endif]-->
    <meta http-equiv="content-type" content="text/plain; charset=UTF-8"/>
  </head>
  <body>
    ${content}
  </body>
  </html>
`

const createTableMarkup = (columns: string[], rows: string[][]) => {
    const head = columns.map((c) => `<th>${c}</th>`).join("")
    const body = rows
        .map((row) => row.map((c) => `<td>${c}</td>`).join(""))
        .map((r) => `<tr>${r}</tr>`)
        .join("")

    return `
    <table>
      <thead>
        ${head}
      </thead>
      <tbody>
        ${body}
      </tbody>
    </table>
  `
}

const downloadBlob = (filename: string, blob: Blob) => {
    const link = document.createElement("a")
    const url = URL.createObjectURL(blob)

    link.setAttribute("href", url)
    link.setAttribute("download", filename)
    link.style.visibility = "hidden"

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export const exportExcel = ({columns, rows, worksheet = "Worksheet", filename}: ExportExcelPayload) => {
    const type = "application/vnd.ms-excel"
    const table = createTableMarkup(columns, rows)
    const markup = createExcelDocumentMarkup(worksheet, table)

    const blob = new Blob([markup], {type})

    downloadBlob(filename, blob)
}

export const exportCsv = ({headerColumns = [], headerRows = [], columns = [], rows, filename}: ExportCsvPayload) => {
    const type = "text/csv;charset=utf-8;"
    let content =
        headerColumns.length && headerRows.length
            ? [headerColumns, headerRows]
                  .map((r) => r.map((v) => (v.includes(",") ? JSON.stringify(v) : v)).join(","))
                  .join("\n")
            : ""
    content += [columns, ...rows]
        .map((r) =>
            r
                .map((v) => {
                    return (v || []).includes(",") ? JSON.stringify(v) : v
                })
                .join(",")
        )
        .join("\n")
    const blob = new Blob([content], {type})

    downloadBlob(filename, blob)
}

export const exportMultiTableToCsv = ({
    headerColumns = [],
    headerRows = [],
    listTable = [],
    filename
}: ExportMultiTableCsvPayload) => {
    const type = "text/csv;charset=utf-8;"
    let content =
        headerColumns.length && headerRows.length
            ? [headerColumns, headerRows]
                  .map((r) => r.map((v) => (v.includes(",") ? JSON.stringify(v) : v)).join(","))
                  .join("\n")
            : ""
    for (let i = 0; i < listTable.length; i++) {
        const table = listTable[i]
        const columns = table?.columns || []
        const rows = table?.rows || []
        content += [columns, ...rows]
            .map((r) =>
                r
                    .map((v) => {
                        return v?.includes(",") ? JSON.stringify(v) : v
                    })
                    .join(",")
            )
            .join("\n")
    }
    const blob = new Blob([content], {type})

    downloadBlob(filename, blob)
}

export const exportPdf = ({columns, rows, filename, styles}: ExportPdfPayload) => {
    const createData = (columns: string[], rows: string[][]) => {
        return rows.map((row) => {
            const data = row.reduce((acc, item, index) => {
                const key = columns[index]

                acc[key] = String(item)
                return acc
            }, {})

            return data
        })
    }

    const data = createData(columns, rows)

    const doc = new jsPDF({orientation: "landscape"})
    autoTable(doc, {
        styles: {fontSize: 8, ...styles},
        margin: {
            top: 5,
            bottom: 5,
            left: 5,
            right: 5
        },
        head: [columns],
        body: data.map((item) => Object.values(item))
    })
    doc.save(`${filename}.pdf`)
}
