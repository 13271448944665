/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import styles from "./ReadinessChecklist.module.css"
import {Checkbox, Col, Row} from "antd"
import {KlassappTableHOC} from "HOC"
import {handleError} from "helpers"
import {careerService} from "services"
import {CareerServices} from "types/careerServices"
import {ReactComponent as VideoIcon} from "./assets/video.svg"
import {ReactComponent as AddIcon} from "./assets/add.svg"
import {useVisible} from "hooks"
import {ChecklistForm} from "sections/Settings/CareerServicesReadinessChecklist/parts"

const CHECKLIST_TYPE = {
    video: "Video",
    document: "Create document",
    advisor: "Advisor meeting"
}

export function ReadinessChecklist(props) {
    const {data, page, total, pageSize, columns, fields, allFields, isLoading, menuActions, dispatch} = props
    const [currentChecklist, setCurrentChecklist] = useState<CareerServices.ReadinessChecklistEntity>()
    const checklistForm = useVisible()

    useEffect(() => {
        props.dispatch({isClassComponent: false})
        props.dispatchFunc([
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getMenuActions", func: getMenuActions},
            {key: "getColumns", func: getColumns}
        ])
    }, [])

    useEffect(() => {
        getData()
    }, [page, pageSize])

    async function getData() {
        try {
            dispatch({isLoading: true})
            const {data, total} = await careerService.getListReadinessChecklists({
                range: {page, pageSize}
            })
            const checklists = data.map((item) => {
                return {
                    ...item,
                    typeText: CHECKLIST_TYPE[item.type],
                    linkHtml: item.type === CareerServices.ReadinessChecklistType.Video && (
                        <div className={styles.videoContainer}>
                            <VideoIcon />
                            <a
                                href={item.videoUrl}
                                target="_blank"
                                className={styles.videoLink}
                                onClick={(e) => e.stopPropagation()}
                                rel="noreferrer">
                                Watch video
                            </a>
                        </div>
                    ),
                    mandatoryHtml: (
                        <div className={styles.visibleCheckboxWrap}>
                            <Checkbox checked={item.isRequired} />
                        </div>
                    )
                }
            })
            dispatch({data: checklists, total})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    function getColumns() {
        return [
            {
                title: "Order",
                field: "order",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: "Name",
                field: "name",
                headerStyle: {minWidth: "200px"}
            },
            {
                title: "Type",
                field: "typeText",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Mandatory",
                field: "mandatoryHtml",
                headerStyle: {minWidth: "100px"}
            },
            {
                title: "Link/File",
                field: "linkHtml",
                headerStyle: {minWidth: "100px"}
            }
        ]
    }

    function getFields() {
        return ["Order", "Name", "Type", "Mandatory", "Link/File"]
    }

    function getMenuActions() {
        return [
            {
                title: "Edit",
                icon: "EDIT",
                action: onClickEdit
            }
            // {
            //     title: "Delete",
            //     icon: "DELETE",
            //     action: onClickDelete
            // }
        ]
    }

    const onClickRowItem = (row) => {
        setCurrentChecklist(row)
        checklistForm.open()
    }

    const handleCreateChecklist = () => {
        setCurrentChecklist(undefined)
        checklistForm.open()
    }

    const handleSubmitSuccessfully = () => {
        setCurrentChecklist(undefined)
        checklistForm.close()
        getData()
    }

    const onClickEdit = (editChecklist) => {
        onClickRowItem(editChecklist)
    }

    const onClickDelete = () => {}

    return (
        <div>
            <Row className={styles.marginTop}>
                <Col span={24}>
                    <KlassappTableHeader
                        page={page}
                        total={total}
                        defaultPageSize={pageSize}
                        onChangePage={props.onChangePage}
                        onChangeRowPerPage={props.onChangeRowPerPage}
                    />
                    <KlassappTable
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        fields={fields}
                        allFields={allFields}
                        menuActions={menuActions}
                        onChangeFields={props.onChangeFields}
                        onUpdateRowData={props.onUpdateRowData}
                        onUpdateTableData={props.onUpdateTableData}
                        onClickRowItem={onClickRowItem}
                    />
                    <div className={styles.addContainer} onClick={handleCreateChecklist}>
                        <span className={styles.addText}>Add item</span>
                        <AddIcon />
                    </div>
                </Col>
            </Row>
            <ChecklistForm
                open={checklistForm.isVisible}
                onClose={() => checklistForm.close()}
                item={currentChecklist}
                onSuccess={handleSubmitSuccessfully}
            />
        </div>
    )
}

export const ListReadinessChecklist = KlassappTableHOC(ReadinessChecklist)
