/* eslint-disable react-hooks/exhaustive-deps */
import React, {MutableRefObject} from "react"
import classNames from "classnames"
import {Icon} from "components/Icon"
import styles from "./item.module.css"
import {RemarkIndicator} from "./RemarkIndicator"

type Props = {
    field: any
    canSign: boolean
    currentSignature: any
    errorFieldIds: string[]
    fieldRefs: MutableRefObject<Record<string, HTMLDivElement>>
    onChangeField: (fieldId: string, data) => void
    openSignaturePopup: (field) => void
}

export function Signature(props: Props) {
    const {field, canSign, currentSignature, errorFieldIds, fieldRefs, onChangeField, openSignaturePopup} = props
    const {top, left, width, height} = field.placeInformation
    const {isYourField} = field

    const onSignSignature = () => {
        if (!canSign || !isYourField) {
            return
        }
        if (!currentSignature || !currentSignature.imageUrl) {
            openSignaturePopup(field)
            return
        }
        onChangeField(field.id, {
            isSigned: !field.value,
            value: !field.value ? currentSignature.imageUrl : null,
            isSignature: true
        })
    }

    const renderField = () => {
        if (!field.isSigned || !field.value) {
            return (
                <div
                    ref={(ref) => (fieldRefs.current[field.id] = ref)}
                    className={classNames(styles.actionItem, {
                        [styles.actionItemRequired]: isYourField && field.required,
                        [styles.hasCommentBorder]: !!field.remarkComment,
                        [styles.actionItem_error]: errorFieldIds.includes(field.id)
                    })}
                    style={{width, height}}
                    onClick={onSignSignature}>
                    <div className={styles.textWrap}>
                        <p className={styles.label}>{field.label}</p>
                        <Icon icon="SIGN_HAND_DRAWN" className={styles.icon} />
                    </div>
                </div>
            )
        }
        if (currentSignature?.imageUrl) {
            return (
                <div
                    className={classNames(styles.actionItem, styles.flexCenter, {
                        [styles.actionItemRequired]: isYourField && field.required,
                        [styles.hasCommentBorder]: !!field.remarkComment
                    })}
                    style={{width, height}}
                    onClick={onSignSignature}>
                    <Icon icon="CHECKMARK_CIRCLE" className={styles.checkedIcon} color="var(--success-400-base)" />
                    <img className={styles.signedItem} src={currentSignature.imageUrl} alt="" />
                </div>
            )
        }
        return null
    }

    const renderBody = () => {
        if (!canSign || !isYourField) {
            return (
                <div
                    className={classNames(styles.actionItem, styles.flexCenter)}
                    style={{
                        width,
                        height,
                        backgroundColor: "transparent",
                        borderColor: "transparent"
                    }}>
                    {field.value && <img className={styles.signedItem} src={field.value} alt="" />}
                </div>
            )
        }
        return renderField()
    }

    return (
        <>
            <div className={styles.wrap} style={{left, top}}>
                {renderBody()}
            </div>
            {field.remarkComment && (
                <div className={styles.wrap} style={{left, top, zIndex: 9}}>
                    <RemarkIndicator field={field} />
                </div>
            )}
        </>
    )
}
