import React from "react"
import {Spin} from "antd"
import styles from "../KlassappTable.module.css"

type Props = {
    colSpan: number
}

export default function LoadingRow(props: Props) {
    const {colSpan} = props

    return (
        <tr className={styles.tableEmptyRow}>
            <td colSpan={colSpan}>
                <div className={styles.rowLoading}>
                    <Spin />
                </div>
            </td>
        </tr>
    )
}
