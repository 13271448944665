import {get} from "lodash"
import BaseService from "./Base"

class WebFormService extends BaseService {
    async getReportForms(params = {}) {
        const response = await this.post("/v1/admin-reports/1098T-form-report/list", params)
        return get(response, "data.data", {})
    }

    async getGenerateForms(params = {}) {
        const response = await this.post("/v1/admin-reports/1098T-form/save", params)
        return get(response, "data.data", {})
    }
}

export default WebFormService
