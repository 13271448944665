/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import styles from "./ProgressReportPdf.module.css"
import {degreeAuditService} from "services"
import {StatusTerm} from "../CurrentProgress/CurrentProgress"
import {formatDecimalWithoutRound, handleError} from "helpers"
import {AcademicPlans} from "types/academicPlans"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import moment from "moment-timezone"
import {toDateOnly} from "sections/academics/instructional/common/utils"
import {useModel} from "hooks"
import {Course} from "types/courses"
import {Spin} from "antd"

type CourseCreditProps = {
    termId: number
    profileId: number
    status: StatusTerm
}

const CourseCredit = (props: CourseCreditProps) => {
    const {termId, profileId, status} = props
    const model = useModel()
    const [listData, setListData] = useState([])
    const [loading, setLoading] = useState(true)
    const getParams = () => {
        const params: any = {
            studentProfileId: +profileId,
            range: {
                page: 1,
                pageSize: UNLIMITED_PAGE_SIZE
            }
        }
        if (termId) {
            params.termIds = [termId]
        }
        return params
    }

    const getData = async () => {
        try {
            const params = getParams()
            setLoading(true)
            const {data, total} = await degreeAuditService.getDetailCurrentProgress(params)
            const checkEarnedCredits = (data) => {
                if (data.status === AcademicPlans.AcademicPlanCourseReviewStatus.Dropped) {
                    return "Withdrawn"
                } else if (data.courseStatus === AcademicPlans.AcademicPlanCourseStatus.Suspended) {
                    return "Suspended"
                } else if (data.courseStatus === AcademicPlans.AcademicPlanCourseStatus.Completed) {
                    return data.earnedCredits
                } else if (data.courseStatus === AcademicPlans.AcademicPlanCourseStatus.Failed) {
                    return "Failed"
                } else if (data.courseStatus === AcademicPlans.AcademicPlanCourseStatus.Inprogress) {
                    return "In-progress"
                } else if (data.courseStatus === AcademicPlans.AcademicPlanCourseStatus.Incomplete) {
                    return "Incomplete"
                } else if (data.courseStatus === AcademicPlans.AcademicPlanCourseStatus.LoaIncomplete) {
                    return "Loa-Incomplete"
                } else if (data.status === AcademicPlans.AcademicPlanCourseReviewStatus.Registered) {
                    return data.earnedCredits
                } else {
                    return ""
                }
            }
            const listData = data.map((item) => ({
                ...item,
                completedDate: moment(toDateOnly(item.lastDayOfCourse)).format(model.getUserDateFormat()),
                alpha: typeof item?.alpha === "string" ? item?.alpha : "",
                earnedCredits: checkEarnedCredits(item)
            }))
            setLoading(false)
            setListData(listData)
        } catch (err) {
            setLoading(false)
            handleError(err)
        }
    }

    useEffect(() => {
        if (termId && profileId && status) {
            getData()
        }
    }, [termId, profileId, status])

    const totalTermGrade = listData.reduce((total, item) => {
        if (item?.totalGpa !== "" && typeof item.totalGpa == "number" && item?.totalGpa >= 0) {
            total += item.totalGpa
        }
        return total
    }, 0)

    const totalCredits = listData.reduce((total, item) => {
        if (typeof item.credit === "number" && item.credit) {
            total += item.credit
        }
        return total
    }, 0)
    const totalEarned = listData.reduce((total, item) => {
        if (typeof item.earnedCredits === "number" && item.earnedCredits) {
            total += item.earnedCredits
        }
        return total
    }, 0)

    const termAvg = formatDecimalWithoutRound(totalTermGrade >= 0 ? totalTermGrade / listData.length : 0)
    const totalCreditsValue = formatDecimalWithoutRound(totalCredits ?? 0)
    const totalEarnedValue = formatDecimalWithoutRound(totalEarned ?? 0)

    return (
        <div className={styles.tableContainerCourse}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th className={styles.tableHeaderText} style={{width: 130}}>
                            Course id
                        </th>
                        <th className={styles.tableHeaderText}>Name</th>
                        <th className={styles.tableHeaderText}>Completed Date</th>
                        <th className={styles.tableHeaderText} style={{width: 140}}>
                            Units Attempted
                        </th>
                        <th className={styles.tableHeaderText} style={{width: 115}}>
                            Units earned
                        </th>
                        <th className={styles.tableHeaderText} style={{width: 60}}>
                            Quality Points
                        </th>
                        <th className={styles.tableHeaderText} style={{width: 60}}>
                            Alphabetical
                        </th>
                        <th className={styles.tableHeaderText} style={{width: 114}}>
                            Grade Points
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {listData.map((data, index) => {
                        return (
                            <tr key={index.toString()}>
                                <td className={styles.tableColumn}>{data.codeForTranscript}</td>
                                <td className={styles.tableColumn}>{`${data.nameForTranscript}${
                                    data.role === Course.AcademicPlanCourseRole.ClassAssistance ? " (CA)" : ""
                                }`}</td>
                                <td className={styles.tableColumn}>{data.completedDate}</td>
                                <td className={styles.tableColumn}>{data.credit}</td>
                                <td className={styles.tableColumn}>{data.earnedCredits}</td>
                                <td className={styles.tableColumn}>{data.qualityPoints}</td>
                                <td className={styles.tableColumn}>{data.alpha}</td>
                                <td className={styles.tableColumn}>{data.totalGpa}</td>
                            </tr>
                        )
                    })}
                    <tr>
                        <td className={styles.termAvg}>TOTAL</td>
                        <td className={styles.termAvg}></td>
                        <td className={styles.termAvg} />
                        <td className={styles.termAvg}>{totalCreditsValue}</td>
                        <td className={styles.termAvg}>{totalEarnedValue}</td>
                        <td className={styles.termAvg} />
                        <td className={styles.termAvg} />
                        <td className={styles.termAvg}>{termAvg}</td>
                    </tr>
                </tbody>
            </table>
            {loading && <Spin />}
        </div>
    )
}

export default CourseCredit
