import {get} from "lodash"
import BaseService from "./Base"

class SmsService extends BaseService {
    async me(data, params = {}) {
        const response = await this.post(`/v3/sms/phone-numbers/me`, data, params)
        return get(response, "data.data", [])
    }

    async assignNumbers(data, params = {}) {
        const response = await this.post(`/v3/sms/phone-numbers/assign`, data, params)
        return get(response, "data.data", [])
    }

    async editNumbers(data, params = {}) {
        const response = await this.post(`/v3/sms/phone-numbers/edit`, data, params)
        return get(response, "data.data", [])
    }

    async getNumbers(data, params = {}) {
        const response = await this.post(`/v3/sms/phone-numbers/list`, data, params)
        return get(response, "data", [])
    }
}

export default SmsService
