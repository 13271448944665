import {Auth} from "./auth"

export namespace AcademicInstructional {
    export enum LessonType {
        Online = "Online",
        InClass = "InClass"
    }

    export enum LessonStatus {
        Inactive = "inactive",
        Active = "active",
        Paused = "paused",
        Ended = "ended"
    }

    export enum CheckInStatus {
        NotVerify = "NotVerify",
        Success = "success",
        Alert = "Alert"
    }

    export enum CheckAttendanceStatus {
        CheckIn = "check-in",
        AttendanceCheck = "attendance-check",
        CheckOut = "check-out"
    }

    export enum OnlineLesson {
        Yes = "yes",
        No = "no"
    }

    export enum BeaconType {
        Physical = "physical",
        Virtual = "virtual"
    }

    export enum AttendanceEventType {
        Checkin = "check-in",
        Checkout = "check-out",
        AttendanceCheck = "attendance-check",
        BreakStart = "break-start",
        BreakEnd = "break-end"
    }

    export enum LessonAttendanceStatus {
        NeedAction = "need_action",
        NotVerified = "not_verified",
        Verified = "verified",
        // used only for students
        Missed = "missed"
    }

    export type LessonInstructor = {
        userId: number
        profileId: number
        name: string
    }

    export type LessonDetailCounter = {
        attended: number
        missed: number
        irregular: number
    }

    export type LessonDetail = {
        id: number
        calendarEventId: number
        collegeId: number
        termCourseCode: string
        courseName: string
        termName: string
        scheduleSuffix: string
        name: string
        breakEvents: Array<{
            createdAt: string
            id: number
            type: AttendanceEventType.BreakStart | AttendanceEventType.BreakEnd
        }>
        code: string
        courseId: number
        status: LessonStatus
        startsAt: string
        startPausedAt?: string
        endsAt: string
        startGracePeriod: number
        endGracePeriod: number
        actualStartedAt: string
        actualEndedAt: string
        meetingUrl: string
        streamKey: string
        isOnline: OnlineLesson
        onlineVerificationFrequency: number
        createdAt: string
        updatedAt: string
        deletedAt: string
        beaconId: number
        scheduleId: number
        campusIds: number[]
        programIds: number[]
        type: string
        termId: number
        term: number
        termCode: string
        lessonAttendanceStatus: string
        instructors: LessonInstructor[]
        crossedWithLessonIds: number
        counters: LessonDetailCounter
        instructorNames?: string
    }

    export type StudentInfo = {
        s3Key: string
        collegeId: number
        customProfileId: string
        userId: number
        photo: Auth.UserPhotoDetails
        firstName: string
        lastName: string
        createdAt: string
        updatedAt: string
        deletedAt: string
        profileId: number
    }

    export type LessonEvent = {
        id: number
        collegeId: number
        attendanceId: number
        attendanceType: string
        eventType: string
        status: string
        createdByProfileId: number
        createdAt: string
        updatedAt: string
        deletedAt: string
        message: string
    }

    export type LessonStudent = {
        id: number
        status: string
        disabled?: boolean
        date: string
        lessonId: number
        student: StudentInfo
        events: LessonEvent[]
        isVerified: string
        duration: number
        message: string
    }

    export enum PassFailStatus {
        Pass = "pass",
        Fail = "fail",
        Withdrawn = "withdrawn",
        Incomplete = "incomplete",
        LoaIncomplete = "loa-incomplete"
    }
}
