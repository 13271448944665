import React, {useState} from "react"
import {CreateActivity, ListActivities} from "./parts"
import {StudentActivity} from "types/activity"

type DisplayPart = "list" | "create"

type Props = {
    studentId: number
    departmentId?: number
    displayPart?: DisplayPart
    onClose?: () => void
    onActivityCreated?: (activity: StudentActivity) => void
}

export const StudentInfoActivityPanel = (props: Props) => {
    const {studentId, departmentId, onClose, displayPart = "list", onActivityCreated} = props
    const [displayMode, setDisplayMode] = useState<DisplayPart>(displayPart)

    const onNavigate = (_displayMode: DisplayPart) => {
        setDisplayMode(_displayMode)
    }

    return (
        <>
            {displayMode === "list" ? (
                <ListActivities
                    studentId={studentId}
                    departmentId={departmentId}
                    onNavigate={onNavigate}
                    onClose={onClose}
                />
            ) : (
                <CreateActivity
                    studentId={studentId}
                    departmentId={departmentId}
                    onNavigate={onNavigate}
                    onDone={onActivityCreated}
                />
            )}
        </>
    )
}
