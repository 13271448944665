import {KlassappTableProps} from "types/common"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import styles from "./PopupAddMakeupAttendance.module.css"
import {cloneDeep} from "lodash"
import {useCallback, useEffect} from "react"
import {Course} from "types/courses"
import {Schedule, TermDetails} from "types/terms"
import {attendanceService} from "services"
import {convertTZ, getFullName, handleError, toastError} from "helpers"
import {useModel} from "hooks"
import moment from "moment"
import {KlassappTableHOC} from "HOC"
import {useTranslation} from "react-i18next"
import {LessonStatus} from "types/attendance"

type ListLessonsProps = {
    studentProfileId: number
    course: Course.Course
    isMissedAttendance?: boolean
    term: TermDetails
    setListLessons: (value: {id: number; selected: boolean}[]) => void
    schedule: Schedule
    timezone: string
} & KlassappTableProps

const ListLessons = (props: ListLessonsProps) => {
    const {
        data,
        page,
        isMissedAttendance,
        total,
        pageSize,
        columns,
        fields,
        allFields,
        isLoading,
        dispatch,
        timezone,
        setListLessons,
        schedule,
        term,
        course,
        studentProfileId
    } = props

    const model = useModel()
    const {t} = useTranslation(["studentServices", "common"])
    const dateFormat = model.getUserDateFormat()

    function getColumns() {
        return [
            {
                title: t("attendance.table.date"),
                field: "date",
                fieldType: "date",
                format: dateFormat
            },
            {
                title: "Time",
                field: "timeRange"
            },
            {
                title: t("attendance.table.name"),
                field: "name"
            },
            {
                title: t("attendance.table.instructor"),
                field: "instructorNames"
            },
            {
                title: "Status",
                field: "lessonStatus"
            }
        ]
    }

    function getFields() {
        return [
            t("attendance.table.date"),
            "Time",
            t("attendance.table.name"),
            t("attendance.table.instructor"),
            "Status"
        ]
    }

    useEffect(() => {
        props.dispatch({isClassComponent: false})
        props.dispatchFunc([
            {key: "getListData", func: getLessons},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getFilterParams = useCallback(() => {
        const params: any = {
            termIds: term?.id ? [term.id] : [],
            checkInvite: false,
            scheduleId: schedule?.id,
            isMissedAttendance: isMissedAttendance,
            courses: course ? [course.courseId] : []
        }
        return params
    }, [course, schedule, term, isMissedAttendance])

    const getLessons = useCallback(async () => {
        try {
            dispatch({isLoading: true})
            const params = getFilterParams()
            const {data = []} = await attendanceService.getLessonsAttendance(params)

            const lessons = data.map((item) => {
                const {startsAt, endsAt, status} = item.lesson
                item.id = item.lesson.id
                item.date = moment(convertTZ(new Date(startsAt), timezone)).format(model.getUserDateFormat())
                item.timeRange = `${moment(convertTZ(new Date(startsAt), timezone)).format(
                    model.getUserTimeFormat()
                )} - ${moment(convertTZ(new Date(endsAt), timezone)).format(model.getUserTimeFormat())}`
                item.name = item.lesson.name
                item.status = status
                item.lessonStatus = status.toUpperCase()
                item.calendarEventId = item.lesson.calendarEventId
                item.termId = item.lesson.termId
                item.courseId = item.lesson.courseId
                item.scheduleId = item.lesson.scheduleId
                item.instructorNames = item.lesson.instructors?.map((instructor) => getFullName(instructor))
                return item
            })
            dispatch({data: lessons, total: lessons.length ?? 0})
            setListLessons(lessons)
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }, [dispatch, getFilterParams, timezone, model, setListLessons])

    useEffect(() => {
        if (studentProfileId) {
            getLessons()
        }
    }, [page, pageSize, term, course, getLessons, studentProfileId])

    const onUpdateRowData = (updatedData) => {
        const data = props.getCurrentData()
        if (updatedData.status === LessonStatus.Inactive) {
            toastError("The lesson haven't started yet!")
            return
        }
        const newData = cloneDeep(data).map((item) => {
            if (item.id === updatedData.id) {
                return {
                    ...item,
                    isChecked: updatedData.isChecked
                }
            }
            return {
                ...item,
                isChecked: false
            }
        })
        setListLessons(newData)
        dispatch({data: newData})
    }

    return (
        <>
            <KlassappTableHeader
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                isShowAllRecords
                isShowAction={false}
                onChangeRowPerPage={props.onChangeRowPerPage}
            />
            <KlassappTable
                columns={columns}
                data={data}
                className={styles.tableLesson}
                isShowScrollTable
                isShowCheckedColumn
                isShowCheckedColumnHeader={false}
                isLoading={isLoading}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </>
    )
}

export default KlassappTableHOC(ListLessons)
