/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {Col, Row} from "antd"
import {SAPCheckPoints} from "./parts"
import {academicCourseGradesService, instructionalService} from "services"
import {ProfileScore} from "../Common"
import {handleError} from "helpers"
import styles from "./Academic.module.css"
import {useStores} from "stores/useStores"
import {useModel, useVisible} from "hooks"
import {EditSapStatusPopup} from "../Common/ProfileScore/EditSapStatusPopup"
import Courses from "./parts/Courses/Courses"
import {HomeCalendar} from "sections/Homepage/parts"

type Props = {
    studentId: number
    departmentId: number
    userId: number
}

export function Academic(props: Props) {
    const {studentId, userId} = props
    const [courses, setCourses] = useState([])
    const [academicData, setAcademicData] = useState({
        lda: undefined,
        sapStatus: undefined,
        sapDate: undefined,
        cgpa: undefined
    })
    const popupForEditingSapStatus = useVisible()
    const {
        academics: {instructional}
    } = useStores()
    const model = useModel()
    let studentProfileIds = []
    const profileId = model.profileId
    if (!model.isStaffOrAdmin() && profileId) {
        studentProfileIds = [profileId]
    } else if (studentId) {
        studentProfileIds = [studentId]
    }

    useEffect(() => {
        instructional.getAttendanceRatingByCourse(undefined, studentProfileIds, [])
        instructional.getMissedAttendanceStats({
            courseIds: undefined,
            termIds: [],
            studentProfileIds: studentProfileIds
        })
        getCourseGradeStudentStats()
        getCourses()
    }, [instructional, studentId])

    const getCourseGradeStudentStats = async () => {
        const profileId = model.profileId
        const params: {studentProfileId: number} = {
            studentProfileId: profileId
        }
        if (!model.isStaffOrAdmin() && profileId) {
            params.studentProfileId = profileId
        } else if (studentId) {
            params.studentProfileId = studentId
        }
        const {cgpa, lda, sapStatus, sapDate} = await academicCourseGradesService.getCourseGradeStudentStats(params)
        setAcademicData({
            lda,
            sapStatus,
            sapDate,
            cgpa
        })
    }

    const getCourses = async () => {
        try {
            const params: any = {
                range: {page: 1, pageSize: 1000},
                filter: {studentProfileIds, termIds: []}
            }
            const {data} = await instructionalService.getTermAndCourseList(params)
            setCourses(data)
        } catch (error) {
            handleError(error)
        }
    }

    return (
        <div>
            <ProfileScore
                lastDayAttendance={academicData.lda}
                sapDate={academicData.sapDate}
                onlyShowCgpa
                cgpa={academicData.cgpa}
                onEditSapStatus={popupForEditingSapStatus.open}
                isShowSapStatus
                isEditableSapStatus={model.isStaffOrAdmin()}
                sapStatus={academicData.sapStatus}
                missedHours={instructional.missedAttendanceStats?.value?.missedHours}
                attendanceRating={instructional.attendanceRatingByCourse.value?.percentage ?? 0}
            />
            <Row gutter={[10, 10]}>
                <Col span={12}>
                    <HomeCalendar
                        studentProfileId={studentId}
                        showStatsInfo
                        userId={userId}
                        customCalendarHeight={612}
                    />
                </Col>
                <Col span={12}>
                    <div className={styles.courseInfo}>
                        <Courses getCourses={getCourses} courses={courses} />
                        <SAPCheckPoints />
                    </div>
                </Col>
            </Row>

            <EditSapStatusPopup
                isShow={popupForEditingSapStatus.isVisible}
                onClose={popupForEditingSapStatus.close}
                studentProfileId={studentId}
                sapStatus={academicData.sapStatus}
                sapDate={academicData.sapDate}
                onRefreshLda={getCourseGradeStudentStats}
            />
        </div>
    )
}
