import {ApiErrorDetails, ApiErrorType} from "types/api"

export function filterTimestamps<T>(target: T): Omit<T, "createdAt" | "updatedAt" | "deletedAt"> {
    return Object.entries(target).reduce((acc, [key, value]) => {
        if (["createdAt", "updatedAt", "deletedAt"].includes(key)) {
            return acc
        }
        return {...acc, [key]: value}
    }, {} as Omit<T, "createdAt" | "updatedAt" | "deletedAt">)
}

const GENERIC_ERROR_MESSAGE = "Sorry, something went wrong, please try again"

export const extractErrorMessageFromApiError = (error: ApiErrorDetails | Error) => {
    if ((error as ApiErrorDetails).errorType === ApiErrorType.ValidationError) {
        // maybe try to extract information from error property
        return "Sorry, something wrong with your data. Please, check it and try again"
    }
    if ((error as ApiErrorDetails).errorType === ApiErrorType.GenericError) {
        return error.message ?? GENERIC_ERROR_MESSAGE
    }

    return GENERIC_ERROR_MESSAGE
}
