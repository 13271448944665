import React from "react"
import {Icon} from "components/Icon"
import {UserType} from "types/tasks"
import styles from "./DefinedUser.module.css"

export function DefinedUser(props) {
    const {definedUserTypes} = props

    const getDefineUserIcon = (type) => {
        switch (type) {
            case UserType.STUDENT:
                return "ACADEMIC"
            case UserType.STAFF:
                return "WORK"
            case UserType.OTHERS:
                return "PERSON"
            default:
                return "ACADEMIC"
        }
    }

    return (
        <div className={styles.definedUser}>
            <p className={styles.definedUserTitle}>Defined user types:</p>
            <div className={styles.definedUserBody}>
                {definedUserTypes.map((definedUserType) => (
                    <div key={definedUserType.id} className={styles.definedUserItem}>
                        <Icon
                            icon={getDefineUserIcon(definedUserType.type)}
                            className={styles.definedUserItem__icon}
                            color="#666"
                        />
                        <span className={styles.definedUserItem__text}>{definedUserType.name}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}
