import React from "react"
import {Row, Col} from "antd"
import {BasePopup} from "components/popup"
import {BaseButton} from "components/buttons"
import {Icon} from "components/Icon"
import styles from "./AcademicAdvisorPopup.module.css"

const phones = ["012 121 1212", "012 121 1212"]
const emails = ["aaaa@gmail.com"]

export function AcademicAdvisorPopup(props) {
    const {isShow, onClose} = props

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="CONTACT_1" leftIconColor="#fff" width="60vw">
            <div className={styles.wrap}>
                <p className={styles.headerTitle}>Academic Advisor</p>
                <div className={styles.body}>
                    <Row gutter={[42, 0]}>
                        <Col span={6}>
                            <div className={styles.avatarWrap}>
                                <img src="/image/DefaultAvatar.png" className={styles.avatar} alt="" />
                            </div>
                        </Col>
                        <Col span={18}>
                            <div>
                                <p className={styles.advisorName}>Prof. John Peterson</p>
                                <div className={styles.infoWrap}>
                                    <div className={styles.studentPhoneEmail}>
                                        <div className={styles.studentPhoneWrap}>
                                            <div className={styles.studentPhoneIconWrap}>
                                                <Icon icon="PHONE" className={styles.studentPhoneIcon} />
                                            </div>
                                            <div className={styles.studentPhoneInfo}>
                                                {phones.map((phone, index) => (
                                                    <p key={index} className={styles.studentPhone__value}>
                                                        {phone}
                                                    </p>
                                                ))}
                                            </div>
                                        </div>
                                        <div className={styles.studentEmailWrap}>
                                            <div className={styles.studentPhoneIconWrap}>
                                                <Icon icon="EMAIL" className={styles.studentPhoneIcon} />
                                            </div>
                                            <div className={styles.studentPhoneInfo}>
                                                {emails.map((email, index) => (
                                                    <p key={index} className={styles.studentPhone__value}>
                                                        {email}
                                                    </p>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.studentAddress}>
                                        <div className={styles.studentPhoneIconWrap}>
                                            <Icon icon="LOCATION_FILL" className={styles.studentPhoneIcon} />
                                        </div>
                                        <div className={styles.studentPhoneInfo}>
                                            <p className={styles.studentPhone__value}>
                                                794 Mcallister St San Francisco, California(CA), 94102
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className={styles.messageBtnWrap}>
                    {/* PopupMessageSender */}
                    <BaseButton title="Message" variant="secondary" />
                </div>
            </div>
        </BasePopup>
    )
}
