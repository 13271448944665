import React from "react"
import {withTranslation} from "react-i18next"
import {isEmpty, get, keyBy} from "lodash"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {BaseInput} from "components/inputs"
import {KlassappTableProps} from "types/common"
import {ParticipantType, SelectOthersFrom, SelectStaffFrom, UserType} from "types/tasks"
import {handleError, getFullName, validateEmail} from "helpers"
import {BaseButton} from "components/buttons"
import styles from "./OthersSigner.module.css"
import {Auth} from "types/auth"
import {Checkbox, Col, Row} from "antd"
import {EditInfoPopup} from "./EditInfoPopup"

type PageProps = {
    t: Function
    history?: any
    signer: any
    primarySigner: any
    forceDeleteKey: number
    resetNonPrimarySignerId: string
    userDataOnlySigner?: any
    onUpdateSignerItem: (signerId: string, data: any) => void
    onClickEditSigner: (signerData: any) => void
    onDeleteMultiUserSigner: (signer: any, userIds?: number[]) => void
    isWorkflowAction?: boolean
    workflowTargetType?: Auth.UserProfileType
}

type Props = KlassappTableProps & PageProps

export type EditContactInfo = {contactId: number; firstName: string; lastName: string; email: string}

type State = {
    otherInfos: any
    errors: any
    isShowEditPopup: boolean
    editingUser: EditContactInfo
    newContactInfo: Record<number, EditContactInfo>
}

class OthersSigner extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            otherInfos: {},
            errors: {},
            isShowEditPopup: false,
            editingUser: null,
            newContactInfo: {}
        }
    }

    componentDidMount() {
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getData},
            {key: "getFields", func: this.getFields},
            {key: "getColumns", func: this.getColumns},
            {key: "onClickRowItem", func: this.onClickRowItem},
            {key: "onClickDeleteMulti", func: this.onClickDeleteMulti},
            {key: "getTableHeaderActions", func: this.getTableHeaderActions},
            {key: "getMenuActions", func: this.getMenuActions}
        ])
        this.props.dispatch({isLoading: true})
        this.getData()
    }

    componentDidUpdate(prevProps) {
        if (
            (prevProps.forceDeleteKey !== this.props.forceDeleteKey &&
                this.props.signer.participantType !== ParticipantType.PrimarySigner) ||
            (prevProps.resetNonPrimarySignerId !== this.props.resetNonPrimarySignerId &&
                this.props.signer.id === this.props.resetNonPrimarySignerId)
        ) {
            this.props.dispatch({data: [], total: 0})
        } else if (prevProps.signer.others !== this.props.signer.others) {
            this.getData()
        }
    }

    getPageTitle = () => {
        return "Other"
    }

    getFields = () => {
        return ["Primary", "First Name", "Last Name", "Role", "Email"]
    }

    getTableHeaderActions = () => {
        const {t, onClickShowConfirmModal} = this.props
        return [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            }
        ]
    }

    canShowEdit = (user) => {
        const {signer} = this.props
        const othersKeyByUserId = keyBy(signer.others, "userId")
        const contactProfile = get(othersKeyByUserId[user.id], "contactProfile", null)
        return signer.selectedType === SelectOthersFrom.ROLE && !isEmpty(contactProfile)
    }

    getMenuActions = () => {
        return [
            {
                title: "Edit",
                icon: "EDIT",
                action: this.onClickEdit,
                canShow: this.canShowEdit
            }
        ]
    }

    onClickEdit = (user) => {
        const {newContactInfo} = this.state
        const othersKeyByUserId = keyBy(this.props.signer.others, "userId")
        const contactProfile = get(othersKeyByUserId[user.id], "contactProfile", null)
        const {email, firstName, lastName} = contactProfile
        const contactId = othersKeyByUserId[user.id].id

        this.setState({
            isShowEditPopup: true,
            editingUser: !isEmpty(newContactInfo[contactId])
                ? newContactInfo[contactId]
                : {contactId, firstName, lastName, email}
        })
    }

    onClickDeleteMulti = () => {
        const {signer, data, dispatch, onDeleteMultiUserSigner} = this.props
        dispatch({isShowTableHeaderAction: false})
        if (signer.participantType === ParticipantType.PrimarySigner) {
            const checkedUserIds = data.filter((user) => user.isChecked).map((user) => user.id)
            const newSigner = {...signer}
            newSigner.others = signer.others.filter((item) => !checkedUserIds.includes(item.id))
            onDeleteMultiUserSigner(newSigner, checkedUserIds)
        } else {
            const newSigner = {...signer}
            newSigner.others = []
            onDeleteMultiUserSigner(newSigner)
        }
    }

    getData = async () => {
        const {dispatch, signer, primarySigner, userDataOnlySigner} = this.props
        dispatch({isLoading: true})
        try {
            let tableData = []
            let key = ""
            if (primarySigner?.userType === UserType.STUDENT || userDataOnlySigner?.userType === UserType.STUDENT) {
                key = "students"
                tableData = primarySigner?.userType === UserType.STUDENT ? primarySigner : userDataOnlySigner
            } else if (primarySigner?.userType === UserType.STAFF || userDataOnlySigner?.userType === UserType.STAFF) {
                key = "staffs"
                tableData = primarySigner?.userType === UserType.STAFF ? primarySigner : userDataOnlySigner
            } else if (signer.tempStudents) {
                tableData = signer
                key = "tempStudents"
            } else {
                key = "others"
            }
            let newData = []
            if (
                signer.participantType === ParticipantType.PrimarySigner ||
                (primarySigner && signer.selectedType === SelectOthersFrom.ROLE) ||
                signer.others ||
                signer.tempStudents?.length > 0
            ) {
                newData = (tableData[key] || []).map((user: any, index) => {
                    user[`${signer.id}_primaryHtml`] = this.renderPrimaryHtml(user)
                    user[`${signer.id}_firstNameHtml`] = this.renderFirstNameHtml(user)
                    user[`${signer.id}_lastNameHtml`] = this.renderLastNameHtml(user)
                    user[`${signer.id}_roleHtml`] = this.renderRoleHtml(user)
                    user[`${signer.id}_emailHtml`] = this.renderEmailHtml(user)
                    user.primaryProfileId = get(signer, ["others", index, "primaryProfileId"])
                    user.primaryUserId = get(signer, ["others", index, "primaryUserId"])
                    return user
                })
            }
            dispatch({data: newData, total: (tableData[key] || []).length})
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    renderPrimaryHtml = (user) => {
        const avatarSrc = user.photo?.original
        return (
            <div className={styles.nameWrap}>
                {avatarSrc && <img src={avatarSrc} className={styles.avatar} alt="" />}
                <p className={styles.name}>
                    {getFullName(user)} {user.id}
                </p>
            </div>
        )
    }

    onChangeOtherInfo = (userId, key, newValue) => {
        const {otherInfos, errors} = this.state
        const {signer, onUpdateSignerItem, data, t} = this.props
        otherInfos[userId] = {...otherInfos[userId]} || {}
        otherInfos[userId][key] = newValue
        const othersKeyByUserId = keyBy(signer.others, "userId")
        const newSignerOthers = data.map((user) => {
            const contactProfile = get(othersKeyByUserId[user.id], "contactProfile", null)
            if (contactProfile) {
                return othersKeyByUserId[user.id]
            }
            const otherInfo = otherInfos[user.id] || {}
            return {
                primaryUserId: user.id,
                primaryProfileId: user.primaryProfileId,
                firstName: otherInfo.firstName,
                lastName: otherInfo.lastName,
                email: otherInfo.email
            }
        })
        onUpdateSignerItem(signer.id, {...signer, others: newSignerOthers})
        errors[userId] = {
            ...errors[userId],
            [key]: !newValue ? t("validation.fieldRequired") : ""
        }
        if (key === "email" && newValue) {
            errors[userId] = {
                ...errors[userId],
                email: !validateEmail(newValue) ? t("message.emailNotValid") : ""
            }
        }
        this.setState({otherInfos, errors}, () => {
            const newData = this.props.data.map((user) => {
                user[`${signer.id}_primaryHtml`] = this.renderPrimaryHtml(user)
                user[`${signer.id}_firstNameHtml`] = this.renderFirstNameHtml(user)
                user[`${signer.id}_lastNameHtml`] = this.renderLastNameHtml(user)
                user[`${signer.id}_roleHtml`] = this.renderRoleHtml(user)
                user[`${signer.id}_emailHtml`] = this.renderEmailHtml(user)
                return user
            })
            this.props.dispatch({data: newData})
        })
    }

    renderFirstNameHtml = (user) => {
        const {signer} = this.props
        const {otherInfos, errors, newContactInfo} = this.state
        if (signer.selectedType === SelectOthersFrom.MANUAL) {
            const othersKeyByUserId = keyBy(signer.others, "primaryUserId")
            const firstName = get(othersKeyByUserId, [user.id, "firstName"], "")
            return (
                <div className={styles.nameWrap}>
                    <p className={styles.name}>{firstName}</p>
                </div>
            )
        }
        const othersKeyByUserId = keyBy(signer.others, "userId")
        const contactProfile = get(othersKeyByUserId[user.id], "contactProfile", null)
        if (isEmpty(contactProfile)) {
            return (
                <div className={styles.inputWrap}>
                    <BaseInput
                        value={get(otherInfos[user.id], "firstName", "")}
                        onChange={(newValue) => this.onChangeOtherInfo(user.id, "firstName", newValue)}
                        placeholder="First Name"
                    />
                    {errors[user.id]?.firstName && <sub className={styles.errorText}>{errors[user.id].firstName}</sub>}
                </div>
            )
        }
        const contactId = othersKeyByUserId[user.id].id
        const firstName = !isEmpty(newContactInfo[contactId])
            ? newContactInfo[contactId].firstName
            : contactProfile.firstName

        return (
            <div className={styles.nameWrap}>
                <p className={styles.name}>{firstName}</p>
            </div>
        )
    }

    renderLastNameHtml = (user) => {
        const {signer} = this.props
        const {otherInfos, errors, newContactInfo} = this.state
        if (signer.selectedType === SelectOthersFrom.MANUAL) {
            const othersKeyByUserId = keyBy(signer.others, "primaryUserId")
            const lastName = get(othersKeyByUserId, [user.id, "lastName"], "")
            return (
                <div className={styles.nameWrap}>
                    <p className={styles.name}>{lastName}</p>
                </div>
            )
        }
        const othersKeyByUserId = keyBy(signer.others, "userId")
        const contactProfile = get(othersKeyByUserId[user.id], "contactProfile", null)
        if (isEmpty(contactProfile)) {
            return (
                <div className={styles.inputWrap}>
                    <BaseInput
                        value={get(otherInfos[user.id], "lastName", "")}
                        onChange={(newValue) => this.onChangeOtherInfo(user.id, "lastName", newValue)}
                        placeholder="Last Name"
                    />
                    {errors[user.id]?.lastName && <sub className={styles.errorText}>{errors[user.id].lastName}</sub>}
                </div>
            )
        }
        const contactId = othersKeyByUserId[user.id].id
        const lastName = !isEmpty(newContactInfo[contactId])
            ? newContactInfo[contactId].lastName
            : contactProfile.lastName
        return (
            <div className={styles.nameWrap}>
                <p className={styles.name}>{lastName}</p>
            </div>
        )
    }

    renderNameHtml = (item) => {
        return (
            <div className={styles.nameWrap}>
                <img src="/image/DefaultAvatar.png" className={styles.avatar} alt="" />
                <p className={styles.name}>{item.name}</p>
            </div>
        )
    }

    renderRoleHtml = (user) => {
        const {signer} = this.props
        if (signer.selectedType === SelectOthersFrom.MANUAL) {
            const othersKeyByUserId = keyBy(signer.others, "primaryUserId")
            const roleName = get(othersKeyByUserId, [user.id, "userPersonalContactRole", "name"], "")
            return roleName
        }
        return this.props.signer.personalContactRole?.name
    }

    renderEmailHtml = (user) => {
        const {signer} = this.props
        const {otherInfos, errors, newContactInfo} = this.state
        if (signer.selectedType === SelectOthersFrom.MANUAL) {
            const othersKeyByUserId = keyBy(signer.others, "primaryUserId")
            const email = get(othersKeyByUserId, [user.id, "email"], "")
            return (
                <div className={styles.nameWrap}>
                    <p className={styles.name}>{email}</p>
                </div>
            )
        }
        const othersKeyByUserId = keyBy(signer.others, "userId")
        const contactProfile = get(othersKeyByUserId[user.id], "contactProfile", null)
        if (isEmpty(contactProfile)) {
            return (
                <div className={styles.inputWrap}>
                    <BaseInput
                        value={get(otherInfos[user.id], "email", "")}
                        onChange={(newValue) => this.onChangeOtherInfo(user.id, "email", newValue)}
                        placeholder="Email"
                    />
                    {errors[user.id]?.email && <sub className={styles.errorText}>{errors[user.id].email}</sub>}
                </div>
            )
        }

        const contactId = othersKeyByUserId[user.id].id
        const email = !isEmpty(newContactInfo[contactId]) ? newContactInfo[contactId].email : contactProfile.email

        return (
            <div className={styles.nameWrap}>
                <p className={styles.name}>{email}</p>
            </div>
        )
    }

    getColumns = () => {
        const {signer} = this.props
        return [
            {
                title: "Primary",
                field: `${signer.id}_primaryHtml`,
                style: {minWidth: 250}
            },
            {
                title: "First Name",
                field: `${signer.id}_firstNameHtml`,
                style: {minWidth: 200}
            },
            {
                title: "Last Name",
                field: `${signer.id}_lastNameHtml`,
                style: {minWidth: 200}
            },
            {
                title: "Role",
                field: `${signer.id}_roleHtml`,
                style: {minWidth: 200}
            },
            {
                title: "Email",
                field: `${signer.id}_emailHtml`,
                style: {minWidth: 200}
            },
            {
                title: "Add to Contacts",
                field: `${signer.id}_addToContact`,
                style: {minWidth: 150}
            }
        ]
    }

    onClickRowItem = (data) => {}

    onUpdateContactSuccess = (userInfo: EditContactInfo) => {
        const {signer} = this.props
        const {newContactInfo} = this.state
        newContactInfo[userInfo.contactId] = userInfo
        this.setState({newContactInfo: {...newContactInfo}, isShowEditPopup: false}, () => {
            const newData = this.props.data.map((user) => {
                user[`${signer.id}_primaryHtml`] = this.renderPrimaryHtml(user)
                user[`${signer.id}_firstNameHtml`] = this.renderFirstNameHtml(user)
                user[`${signer.id}_lastNameHtml`] = this.renderLastNameHtml(user)
                user[`${signer.id}_roleHtml`] = this.renderRoleHtml(user)
                user[`${signer.id}_emailHtml`] = this.renderEmailHtml(user)
                return user
            })
            this.props.dispatch({data: newData})
        })
    }

    render() {
        const {
            page,
            total,
            pageSize,
            columns,
            data,
            allFields,
            fields,
            orderField,
            isLoading,
            isShowTableHeaderAction,
            tableHeaderActions,
            menuActions,
            isHideMenuActions,
            signer,
            primarySigner,
            t,
            onClickEditSigner,
            onUpdateSignerItem,
            isWorkflowAction,
            workflowTargetType
        } = this.props
        const {isShowEditPopup, editingUser} = this.state

        if (!!isWorkflowAction && signer.selectedType === SelectOthersFrom.ROLE) {
            return (
                <span>
                    Relationship to Student: <b>{signer.personalContactRole?.name}</b>
                </span>
            )
        }

        if (!data.length) {
            const type = primarySigner?.userType || primarySigner?.type
            let isActive =
                signer.id === primarySigner?.id ||
                primarySigner?.useWorkflowAllTargets ||
                primarySigner?.useWorkflowSingleTarget
            if (!isActive && !primarySigner?.isSelecting && !primarySigner?.isForm) {
                switch (type) {
                    case UserType.STUDENT:
                        isActive = !!(primarySigner?.students || []).length
                        break
                    case UserType.STAFF:
                        isActive = !!(primarySigner?.staffs || []).length
                        break
                    case UserType.OTHERS:
                        isActive = !!(primarySigner?.others || []).length
                        break
                    default:
                        break
                }
            }
            return (
                <div className="text-center">
                    {!!isWorkflowAction && workflowTargetType === Auth.UserProfileType.Others && (
                        <Row gutter={24} justify="center" align="middle">
                            {signer.id === primarySigner?.id ? (
                                <>
                                    <Col>
                                        <Checkbox
                                            checked={signer.useWorkflowAllTargets}
                                            onChange={(event) => {
                                                const checked = event.target.checked
                                                onUpdateSignerItem(signer.id, {
                                                    ...signer,
                                                    useWorkflowAllTargets: checked,
                                                    useWorkflowSingleTarget: false,
                                                    others: checked ? [] : signer.others
                                                })
                                            }}>
                                            Group all people in one task
                                        </Checkbox>
                                    </Col>
                                    <Col>
                                        <Checkbox
                                            checked={signer.useWorkflowSingleTarget}
                                            onChange={(event) => {
                                                const checked = event.target.checked
                                                onUpdateSignerItem(signer.id, {
                                                    ...signer,
                                                    useWorkflowSingleTarget: checked,
                                                    useWorkflowAllTargets: false,
                                                    others: checked ? [] : signer.others
                                                })
                                            }}>
                                            Send each person with individual task
                                        </Checkbox>
                                    </Col>
                                </>
                            ) : (
                                <Col>
                                    <Checkbox
                                        checked={signer.useWorkflowAllTargets}
                                        onChange={(event) => {
                                            const checked = event.target.checked
                                            onUpdateSignerItem(signer.id, {
                                                ...signer,
                                                useWorkflowAllTargets: checked,
                                                useWorkflowSingleTarget: false,
                                                others: checked ? [] : signer.others
                                            })
                                        }}>
                                        Use workflow target audience
                                    </Checkbox>
                                </Col>
                            )}
                        </Row>
                    )}

                    {!signer.useWorkflowAllTargets && !signer.useWorkflowSingleTarget && (
                        <div className={styles.selectWrap}>
                            <BaseButton
                                title={t("tasks:tasks.select")}
                                variant="secondary"
                                onClick={() => onClickEditSigner(signer)}
                                isActive={isActive}
                            />
                        </div>
                    )}
                </div>
            )
        }

        return (
            <div className={styles.wrap}>
                <KlassappTableHeader
                    isShowAction={isShowTableHeaderAction}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.props.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    data={data}
                    menuActions={isHideMenuActions ? [] : menuActions}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    orderField={orderField}
                    isShowCheckedColumn
                    onClickRowItem={this.onClickRowItem}
                    onChangeFields={this.props.onChangeFields}
                    onUpdateRowData={this.props.onUpdateRowData}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onClickSortColumn={this.props.onClickSortColumn}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onChangeAllFields={this.props.onChangeAllFields}
                />
                {isShowEditPopup && (
                    <EditInfoPopup
                        isShow
                        onClose={() => this.setState({isShowEditPopup: false})}
                        editingUser={editingUser}
                        onSubmitSuccess={this.onUpdateContactSuccess}
                    />
                )}
            </div>
        )
    }
}

export default KlassappTableHOC(withTranslation(["common", "tasks"])(OthersSigner))
