/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import {useTranslation} from "react-i18next"
import {useModel} from "hooks"
import {getFullName, handleError} from "helpers"
import {KlassappTableHOC} from "HOC"
import {KlassappTable} from "uiKit"
import {KlassappTableProps} from "types/common"
import {studentActivityLogService} from "services"
import moment from "moment"
import {ActivityLogType} from "types/student-activity-log"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import styles from "./ActivityForm.module.css"
import {isEqual} from "lodash"

interface Props extends KlassappTableProps {
    studentActivityId: number
}

const StudentActivityLogsTable: React.FC<Props> = ({
    columns,
    data,
    page,
    total,
    pageSize,
    allFields,
    fields,
    orderField,
    isLoading,
    isLoadedTableFuncs,
    dispatch,
    dispatchFunc,
    onChangeFields,
    onDraggableColumn,
    onChangeAllFields,
    onUpdateRowData,
    onUpdateTableData,
    studentActivityId
}) => {
    const {t} = useTranslation(["activities", "common"])
    const model = useModel()
    const userDateTimeFormat = model.getUserDateTimeFormat()

    const getData = React.useCallback(async () => {
        dispatch({isLoading: true})
        try {
            const {data, total} = await studentActivityLogService.getActivityLogs({
                filter: {
                    type: [ActivityLogType.ActivityCreated, ActivityLogType.ActivityUpdated],
                    targetId: studentActivityId
                },
                range: {
                    offset: 0,
                    limit: UNLIMITED_PAGE_SIZE
                },
                linkedEntities: true
            })
            dispatch({
                data: data
                    .filter((log) => log.profileId === log.data?.profile?.id)
                    .map((log) => ({...log, id: log.activityLogId})),
                total
            })
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }, [studentActivityId])

    const getFields = React.useCallback(() => {
        return [t("activities.log.column.time"), t("activities.log.column.user"), t("activities.log.column.change")]
    }, [])

    const getColumns = React.useCallback(
        () => [
            {
                title: t("activities.log.column.time"),
                field: "createdAt",
                style: {width: 130},
                render: (value) => moment(value).format(userDateTimeFormat)
            },
            {
                title: t("activities.log.column.user"),
                field: "user",
                style: {width: 150},
                render: (value) => getFullName(value)
            },
            {
                title: t("activities.log.column.change"),
                field: "data",
                render: (data, record) => {
                    const {eventType, activity, oldActivity} = data
                    switch (eventType) {
                        case "created":
                            return t("activities.log.change.created", {user: getFullName(record.user)})
                        case "updated":
                            return (
                                <>
                                    <div>
                                        {t("activities.log.change.updated", {
                                            user: getFullName(record.user)
                                        })}
                                    </div>
                                    {!!activity?.assignedProfiles &&
                                        !isEqual(activity?.assignedProfileIds, oldActivity?.assignedProfileIds) && (
                                            <div>
                                                {t("activities.log.change.reassigned", {
                                                    user: getFullName(record.user),
                                                    name: getFullName(activity?.assignedProfiles?.[0])
                                                })}
                                            </div>
                                        )}
                                </>
                            )
                        default:
                            return null
                    }
                }
            }
        ],
        [t, userDateTimeFormat]
    )

    React.useEffect(() => {
        dispatch({isClassComponent: false, isHideMenuActions: true})
        dispatchFunc([
            {key: "getPageTitle", func: () => ""},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
    }, [getColumns])

    React.useEffect(() => {
        if (isLoadedTableFuncs) {
            getData()
        }
    }, [isLoadedTableFuncs, page, pageSize, studentActivityId])

    // if (!data.length) return null

    return (
        <div>
            <h4 className={styles.sectionTitle}>{t("activities.log.title")}</h4>

            <KlassappTable
                columns={columns}
                menuActions={[]}
                data={data}
                isLoading={isLoading}
                fields={fields}
                allFields={allFields}
                orderField={orderField}
                isShowCheckedColumn={false}
                onChangeFields={onChangeFields}
                onUpdateRowData={onUpdateRowData}
                onUpdateTableData={onUpdateTableData}
                onDraggableColumn={onDraggableColumn}
                onChangeAllFields={onChangeAllFields}
            />
        </div>
    )
}

export default KlassappTableHOC(StudentActivityLogsTable)
